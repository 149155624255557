import formAPIModeldemogrpich from "./demograpich/demographic";
import formAPIModelchiefcomplaint from "./chiefcomplaint/chiefcomplaints";
import formAPIModelhistoryofpresentillness from "./historyofpresentillness/historyofpresentillnesses";
import formAPIModelphysicalexam from "./physicalexam/physicalexams";
// import formAPIModeltreatmentplan from "./treatmentplan/treatmentplans";
import formAPIModeldoctorassessment from "./doctorassessment/doctorassessements";
import formAPIModelpastmedicalhistory from "./pastmedicalhistory/pastmedicalhistories";
import formAPIModelpastsurgicalhistory from "./pastsurgicalhistory/pastsurgicalhistories";
import formAPIModelfamilyhistory from "./familyhistory/familyhistories";
import formAPIModelsocialhistory from "./socialhistory/socialhistories";
import formAPIModelcurrentmedications from "./currentmedications/currentmedications";
import formAPIModelvitals from "./vitals/vitals";
import formAPIModelvaccinations from "./vaccination/index";
import formAPIModelreviewofsystem from "./reviewofsystem/reviewofsystems";
import formAPIAllergies from "./allergies/index";
import formAPIModelinvestigation from "./investigations/investigations";
import formAPIModellabs from "./labs/labs";
import formAPIModelimaging from "./imaging/imagings";
import formAPIModeltreatmentteam from "./treatmentteam/treatmentteams";
import formAPIModelpatientinfo from "./patientinfo/patientinfo"
import formAPIPreviousvisits from "./previousvisits/previousvistis";

export default function formapimodalpatienthealt({
  demographics,
  chiefcomplaint,
  historyofpresentillness,
  physicalexam,
  // treatmentplan,
  doctorsassessment,
  medicalhistory,
  surgicalhistory,
  familyhistory,
  socialhistory,
  medications,
  vitals,
  vaccinations,
  reviewofsystem,
  allergy,
  investigations,
  labs,
  imaging,
  treatmentteam,
  patientinfo,
  Previousvisits
})
{
return Object.freeze({
  demographics:demographics !== null ? formAPIModeldemogrpich(demographics) : {},
  chiefcomplaint:chiefcomplaint !== null ? formAPIModelchiefcomplaint(chiefcomplaint) : [],
  // historyofpresentillness: historyofpresentillness !== null ? formAPIModelhistoryofpresentillness(historyofpresentillness) : [],
  historyofpresentillness: historyofpresentillness !== null ? formAPIModelhistoryofpresentillness(historyofpresentillness) : [],
  physicalexam : physicalexam != null ?  formAPIModelphysicalexam(physicalexam):[],
  // treatmentplan: treatmentplan !== null ? formAPIModeltreatmentplan(treatmentplan) : [],
  doctorsassessment: doctorsassessment !== null ? formAPIModeldoctorassessment(doctorsassessment) : [],
  medicalhistory: medicalhistory !== null ? formAPIModelpastmedicalhistory(medicalhistory) : [],
  surgicalhistory:surgicalhistory !== null ? formAPIModelpastsurgicalhistory(surgicalhistory) : [],
  familyhistory:familyhistory !== null ? formAPIModelfamilyhistory(familyhistory) : [],
  socialhistory:socialhistory !== null ? formAPIModelsocialhistory(socialhistory) : [],
  medications:medications !== null ? formAPIModelcurrentmedications(medications) : [],
  vitals:vitals !== null ? formAPIModelvitals(vitals) : [],
  vaccinations:vaccinations !== null ? formAPIModelvaccinations(vaccinations) : {},
  reviewofsystem:reviewofsystem !== null ? formAPIModelreviewofsystem(reviewofsystem) : [],
  allergy:allergy !== null ? formAPIAllergies(allergy) : [],
  investigations:investigations !== null ? formAPIModelinvestigation(investigations) : [],
  labs:labs !== null ? formAPIModellabs(labs) : [],
  imaging:imaging !== null ? formAPIModelimaging(imaging) : [],
  treatmentteam:treatmentteam !== null ? formAPIModeltreatmentteam(treatmentteam) : [],
  patientinfo:patientinfo !== null ? formAPIModelpatientinfo(patientinfo) : {},
  Previousvisits:Previousvisits !== null ? formAPIPreviousvisits(Previousvisits) : [],
 
});
}