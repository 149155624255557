import React, { useEffect } from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Toaster } from "../../../components";
import moment from 'moment';

const ExcelDownloadMonthly2 = ({ data, onDownloadComplete }) => {
  const { showSuccessToast, showErrorToast } = Toaster();
  const currentDate = new Date(); // Get the current date
  const options = { month: 'long', year: 'numeric' }; // Define formatting options
  const currentMonthYear = currentDate.toLocaleDateString('en-US', options); // Format as "Month Year"

  
  useEffect(() => {
     handleDownload(data);
   //  console.log(data , " data to Download month 2 ")
  }, [data]);

  // code woing fine here , with total calculation
  const handleDownload = (exceldata) => {
    if (!exceldata || Object.keys(exceldata).length === 0) {
      console.error("No data to download.");
      showErrorToast("No Data Available!");
      onDownloadComplete();
      return;
    }
  
    const workbook = XLSX.utils.book_new();
    let hasData = false;
  
    // Format the data dynamically for the Excel sheet
    const prepareSheetData = (data) => {
      const sheetData = [];
      const allBranches = [];
      const merges = []; // Array to hold merge ranges
    
      // Collect all unique branch names
      data?.forEach((entry) => {
        entry?.result?.forEach((branchData) => {
          allBranches.push(branchData?.branchname);
        });
      });
    
      // Convert branch names to a sorted array for column consistency
      const branchColumns = Array.from(allBranches).sort();
    
      // Prepare the header row
      const reportName = [`Monthly Report for Medicines Prescribed for  ${currentMonthYear}`];
      const emptyRow = [""];
      sheetData.push(reportName);
      merges.push({ s: { r: 0, c: 0 }, e: { r: 0, c: 6 } });
      
      
      sheetData.push(emptyRow)
      merges.push({ s: { r: 1, c: 0 }, e: { r: 1, c: 6 } });

      const header = ["Medicines Expenses", ...allBranches, "Total"];
      sheetData.push(header);
    
      // Initialize column totals array
      const columnTotals = Array(allBranches.length).fill(0);
      let grandTotal = 0; // Total of all totals across rows
    
      // Prepare the rows
      data?.forEach((entry) => {
        const startRow = sheetData.length; // Starting row for this category
        const row = [entry?.name];
    
        let rowTotal = 0; // Initialize the row total
    
        // Add branch values in the correct column order and calculate totals
        allBranches?.forEach((branch, index) => {
          const branchData = entry?.result?.find((b) => b.branchname === branch);
          const branchValue = branchData ? branchData.value : 0;
          rowTotal += branchValue; // Add to the row total
          columnTotals[index] += branchValue; // Add to the column total
          row.push(branchValue);
        });
    
        // Add the row total
        row.push(rowTotal);
        grandTotal += rowTotal; // Add to the grand total
        sheetData.push(row);
    
        // Merge cells for the "Category" column if there are multiple rows for the same category
        const endRow = sheetData.length - 1; // Ending row index
        if (endRow > startRow) {
          merges.push({ s: { r: startRow, c: 0 }, e: { r: endRow, c: 0 } }); // Merge "Category"
        }
      });
    
      // Prepare the totals row
      const totalsRow = ["Total", ...columnTotals, grandTotal];
      sheetData.push(totalsRow);
    
      // Merge cells for the "Total" label in the first two columns
      // merges.push({
      //   s: { r: sheetData.length - 1, c: 0 },
      //   e: { r: sheetData.length - 1, c: 1 },
      // });
    
      return { sheetData, merges };
    };
    
  
    // Generate the data and merges for the sheet
    const { sheetData, merges } = prepareSheetData(exceldata);
  
    // Convert to worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
  
    // Apply merges to the worksheet
    worksheet["!merges"] = merges;
  
    // Set column widths dynamically
    worksheet["!cols"] = [
      { wch: 20 }, // First column width (Diagnose)
      { wch: 30 }, // Second column width (Sub-Diagnose)
      ...Array(sheetData[2].length - 2).fill({ wch: 15 }), // Branch columns and Total
    ];
  
    // Add worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet);
  
    hasData = true;
  
    if (!hasData) {
      showErrorToast("No Data Available!");
      onDownloadComplete();
      return;
    }
  
    // Write to Excel file
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(dataBlob, `Monthly Report for Medicines Prescribed for  ${currentMonthYear}.xlsx`);
  
    showSuccessToast("Download successful!");
    onDownloadComplete();
  };  
  
  
  
  
  
  

  return null;
};

export default ExcelDownloadMonthly2;
