import MiniSideBar from "../../components/mini-sidebar";
import {
  Box,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react";
import SecondaryNav from "../../components/seconday-nav";
import Footer from "../../components/footer";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import React, { useState, useEffect, useRef } from "react";
import { acceptAppointment, FetchNotifications} from "../../middleware/services/notifications/index";
import { actionAccessMain, useScreenType } from "../../auth";
import MobileNavbar from "../../components/mobile-navbar";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../components";
import { FaCloudShowersWater } from "react-icons/fa6";





const NotificationsIndex = () => {
  var [doctorid, setDoctorid] = useState();
  var [data, setData] = useState(null);
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const screenType = useScreenType();
  const navigate = useNavigate();
  const [actionAccessVisits , setactionAccessVisits] = useState(null)
  const [actionAccessMessages , setactionAccessMessages] = useState(null)
  const [actionAccessAppointments, setActionAccessAppointments] = useState(null);

  useEffect(() => {
  showLoader();
  const fetchData = async () => {
    setactionAccessVisits(actionAccessMain(2))
    setactionAccessMessages(actionAccessMain(3))
   setActionAccessAppointments(actionAccessMain(15))
    try {
      const result = await FetchNotifications({
        doctorid,
      });

      //console.log(result, " result notifications");
    
      if (result != null) {
        setData(result);
      } else {
      }
    } catch (error) {
      console.error("Error in home:", error);
    }
  };
  dismissLoader();
  fetchData();
}, []);
  const Redirect = (item)=>{
    if(item.notificationfor == 'chat'){
      navigate("/messages")
    } else if(item.notificationfor == 'appointment'){
      navigate("/visits")
    }
  }

  const navigateToStartVisit = async (step)=>{
    showLoader();
    // console.log(step , " step")
    let appointmentId = step.entityid;
    let doctorId =  step.doctorid;
    let patientId =  step.patientid;

    let input = {
      "appointmentid":appointmentId
    }
    let result = await acceptAppointment(input);
    if(result.output.result == "success"){
      
        const url = `/startvisit?doctorId=${doctorId}&patientId=${patientId}&appointmentId=${appointmentId}&virtualVisit=${true}`
      navigate(url)
     
    }
    dismissLoader();
  }


  return (
    <>
      {isLoading && <Loader />}

      {screenType == 'web' ? (
        <Box w="100%">
          <Flex>
            <Box w="6rem">
              <MiniSideBar />
            </Box>
            <Box w="100%">
              <SecondaryNav customHeader="Notifications" />

              {/* Main container start */}
              <Flex w="calc(100vw - 6rem)" h='calc(100vh - 130px)'>
                <Box
                  p="1.5rem 8.25rem"
                  h="calc(100vh - 130px)"
                  overflow="hidden auto"
                  w="100%"
                >
                  <Box w="100%" display="flex" flexDir="column" gap="1.5rem">
                      <Box display="flex" flexDir="column" gap="1rem">
                      {data && data.length > 0 ? (
                        data.map((step, index) => (
                            <Flex key={index} alignItems="center" w="100%" gap="0.5rem" borderBottom="1px solid #E6E7E9" pb="1rem">
                                <Box display="flex" justifyContent="center" alignItems="center" boxSize="2.75rem" border="1px solid #E6E7E9" borderRadius="full">
                                    <Image src="/assets/svgs/calendar-edit.svg" />
                                </Box>
                                                    
                                <Box display="flex" flexDir="column" w="100%">
                                    <Box display="flex" justifyContent="space-between">
                                        <Text fontSize="0.75rem" fontWeight="500" color="#384052">
                                        {step.notificationfor 
                                        ? step.notificationfor.charAt(0).toUpperCase() + step.notificationfor.slice(1)
                                        : "-"}
                                        </Text>
                                        <Box display="flex" gap="0.25rem" alignItems="center">
                                            <Box boxSize="4px" bg="#1C4EC5" borderRadius="full"></Box>
                                            <Text fontSize="0.625rem" fontWeight="400" color="#384052">{step.timeDifference}</Text>
                                        </Box>
                                    </Box>
                                                    
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Text fontSize="0.875rem" fontWeight="500" color="#384052">{step.notificationcontent}</Text>
                                         {step.virtualappointmentstatus == 2 &&
                                          <Box  marginLeft="110px" >
                                                 <PrimaryButton
                                                                         onClick={()=>navigateToStartVisit(step)}
                                                                        variant="grayBtn"
                                                                        btnStyle={{ w:'100%',fontSize:"13px", h:"30px", fontWeight:"600",color:'#061027', }}
                                                                        buttonText="Accept Appointment"
                                                                        
                                                                         isDisabled={ actionAccessAppointments && (!actionAccessAppointments.create) ? true : false} 
                                                                      />
                                           </Box>
                                           } 
                                        <Box as="button"  opacity={(step.notificationfor == "appointment" && actionAccessVisits?.read) || 
                                                                    (step.notificationfor == "chat" && actionAccessMessages?.read)    
                                                                     ? 1 : 0.5}
                                                          disabled={(step.notificationfor == "appointment" && actionAccessVisits?.read) || 
                                                                      (step.notificationfor == "chat" && actionAccessMessages?.read)    
                                                                       ? false : true}                    
                                                                    >
                                                                 
                                        <Image src="/assets/imgs/right-icon.png" cursor='pointer' onClick={()=>Redirect(step)}  />
                                        </Box>
                                    </Box>
                                </Box>
                            </Flex>
                        ))
                      ) : (
                        <Box display="flex" justifyContent="center" flexDir="column" gap="10px" alignItems="center" h="100vh">
                        <Image src="/assets/svgs/notifications.svg"/>
                        <Text>No Notifications</Text>
                      </Box>
                      )}

                      </Box>
                    
                  </Box>
                </Box>
              </Flex>
              {/* Footer start */}
              <Footer footerStyle={{m:0,p:'1.5rem',bg:'white',zIndex:'999'}} />
              {/* Footer end */}
              {/* Main container end */}
            </Box>
          </Flex>
        </Box>
      ) : (
        <Box w="100%">
          <MobileNavbar />
          <MobileSecondaryNav
            header="Profile"
            handlebackarrow={false}
          />

          <Box
            p="1.5rem"
            h="calc(100vh - 73px)"
            overflow="hidden auto"
            w="100%"
          >
            <Box w="100%" display="flex" flexDir="column" gap="1.5rem">
                <Box display="flex" flexDir="column" gap="1rem">
                {data && data.length > 0 ? (
                  data.map((step, index) => (
                      <Flex key={index} alignItems="center" w="100%" gap="0.5rem" borderBottom="1px solid #E6E7E9" pb="1rem">
                          <Box display="flex" justifyContent="center" alignItems="center" boxSize="2.75rem" border="1px solid #E6E7E9" borderRadius="full">
                              <Image src="/assets/svgs/calendar-edit.svg" />
                          </Box>
                                              
                          <Box display="flex" flexDir="column" w="100%">
                              <Box display="flex" justifyContent="space-between">
                                  <Text fontSize="0.75rem" fontWeight="500" color="#384052">
                                  {step.notificationfor 
                                        ? step.notificationfor.charAt(0).toUpperCase() + step.notificationfor.slice(1)
                                        : "-"}
                                  </Text>
                                  <Box display="flex" gap="0.25rem" alignItems="center">
                                      <Box boxSize="4px" bg="#1C4EC5" borderRadius="full"></Box>
                                      <Text fontSize="0.625rem" fontWeight="400" color="#384052">{step.timeDifference}</Text>
                                  </Box>
                              </Box>
                                              
                              <Box display="flex" justifyContent="space-between" alignItems="center">
                                  <Text fontSize="0.875rem" fontWeight="500" color="#384052">{step.notificationcontent}</Text>
                                  {step.virtualappointmentstatus == 2 &&
                                          <Box>
                                                 <PrimaryButton
                                                                         onClick={()=>navigateToStartVisit(step)}
                                                                        variant="grayBtn"
                                                                        btnStyle={{ w:'100%',fontSize:"13px", h:"30px", fontWeight:"600",color:'#061027' }}
                                                                        buttonText="Accept Appointment"
                                                                        
                                                                         isDisabled={ actionAccessAppointments && (!actionAccessAppointments.create) ? true : false} 
                                                                      />
                                           </Box>
                                          }
                                  <Box as="button"  opacity={(step.notificationfor == "appointment" && actionAccessVisits?.read) || 
                                                                    (step.notificationfor == "chat" && actionAccessMessages?.read)    
                                                                     ? 1 : 0.5}
                                                          disabled={(step.notificationfor == "appointment" && actionAccessVisits?.read) || 
                                                                      (step.notificationfor == "chat" && actionAccessMessages?.read)    
                                                                       ? false : true}                    
                                                                    >
                                  <Image src="/assets/imgs/right-icon.png" cursor='pointer' onClick={()=>Redirect(step)} />
                              </Box>
                              </Box>
                          </Box>
                      </Flex>
                  ))
                ) : (
                  <Box display="flex" justifyContent="center" flexDir="column" gap="10px" alignItems="center" h="100vh">
                  <Image src="/assets/svgs/notifications.svg"/>
                  <Text>No Notifications</Text>
                </Box>
                )}

                </Box>
              {/* Footer start */}
              <Footer />
              {/* Footer end */}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default NotificationsIndex;
