import React from "react";
import { Box, Text, Image } from "@chakra-ui/react";

const statusColorMap = {
  Ok: "green.500",
  Low: "orange.300",
  High: "red.500",
};

const statusIconMap = {
  Ok: "/assets/svgs/ok.svg",
  Normal: "/assets/svgs/ok.svg",
  Low: "/assets//svgs/low.svg",
  High: "/assets//svgs/high.svg",
};

const PastVitalDisplayCard = ({
  vitalName,
  vitalValue,
  vitalUnit,
  vitalStatus,
}) => {
  const statusColor = statusColorMap[vitalStatus] || "gray.500";
  const statusIcon = statusIconMap[vitalStatus] || "";

  return (
    <Box
      border="1px"
      borderColor="#E6E7E9"
      borderRadius="md"
      p={4}
      minW="8.75rem"
      backgroundColor={"white"}
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
    >
      <Text fontSize="12px" fontWeight="500" color="#6A707D">
        {vitalName}
      </Text>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        width="100%"
      >
        <Text fontSize="22px" fontWeight="600">
          {vitalValue}{" "}
          <span style={{ fontSize: "15px", fontWeight: "400" }}>
            {vitalUnit}
          </span>
        </Text>
        <Box display="flex" alignItems="center" gap="0.25rem">
          {statusIcon && <Image w="12px" h="auto" src={statusIcon} />}
          <Text as="span" fontSize="12px" fontWeight="600" color={statusColor}>
            {vitalStatus}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default PastVitalDisplayCard;
