import moment from "moment";
export default function formAPIuser({
  firstname,
  lastname,
  fullname,
  gender,
  dob,
  regdate,
  profilepictureurl,
  mobileno,
  email,
  countryid,
  ratings,
  isuserdoctor,
  issuperuser,
  roleid,
  isfirsttimelogin,
  rolename,
  imagepath,
  doctorid,
  countrycode




}) {
  return Object.freeze({
    firstname,
    lastname,
    fullname,
    gender,
    dob,
    regdate,
    profilepictureurl,
    mobileno,
    email,
    countryid,
    ratings,
    isuserdoctor,
    issuperuser,
    roleid,
    isfirsttimelogin,
    rolename,
    imagepath,
    doctorid,
    countrycode

  });
}