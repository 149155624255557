import React from "react";
import { Box, Text, Image } from "@chakra-ui/react";
import moment from "moment";
import ReportViewCard from "../../report-card";

const formatDate = (dateString) => {
  if (!dateString) return "-";
  return moment(dateString).format("Do MMM, YYYY");
};

const calculateYearsDifference = (start, end) => {
  const startDate = new Date(start);
  const endDate = new Date(end);

  // Calculating difference in ms
  const diffInMs = endDate - startDate;

  // Milliseconds to years
  const msInYear = 1000 * 60 * 60 * 24 * 365.25;
  const diffInYears = diffInMs / msInYear;

  // if (diffInYears === 0) {
  //   return "Less than a year";
  // }

  return diffInYears.toFixed(0);
};

const formatDate_yyyy = (dateString) => {
  if (!dateString) return "-";
  return moment(dateString).format("yyyy");
};

const PastSocialHistory = ({ drugData }) => {
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      justifyContent="space-between"
      gap="20px"
      width="100%"
    >
      {drugData.map((drug, index) => (
        <Box key={index} flex="0 0 calc(50% - 10px)" mb="20px">
          {" "}
          {/* Adjusted width */}
          <ReportViewCard
            withBadge={
              <Text as="span" fontSize="10px" ml="2px">
                {drug.type}
              </Text>
            }
            withBadgeStyle={{ pb: "0" }}
            // editBtn={() => console.log("Edit clicked")} // Placeholder function
            // {/* editDeleteBtn={() => console.log("Delete clicked")} // Placeholder function */}
            BtnRightIcon={{ display: "none" }}
            withBadgeHeader={
              drug.type === "Illicit Drugs" && drug.iscurrent === 0
                ? "Illicit Drugs"
                : drug.values
            }
            CardHeader={drug.type}
            withBadgeSecondary={
              <Box display="flex">
                <Text fontSize="0.75rem" fontWeight="bold" color="#384052">
                  Status:{" "}
                </Text>
                <Text fontSize="0.75rem" fontWeight="500" color="#384052">
                  {/* 5 years */}
                  {/* {`${social.fromdate} - ${social.todate}`} */}
                  {/* {`${totalYears} years`} */}
                  {drug.iscurrent == 1
                    ? " Current"
                    : drug.iscurrent == 0
                    ? " Never"
                    : drug.iscurrent == 2
                    ? " Former"
                    : ""}
                </Text>
              </Box>
            }
            deleteBtn={false}
            editDeleteBtn={true}
            keepDeleteButton={false}
            cardBtn={false}
            cardText={
              // <Box width="100%" alignItems="left">
              //   {drug.frequency_display}
              // </Box>
              <Text fontSize="0.75rem" fontWeight="500" color="#384052">
                {/* 5x per day */}
                {/* {social.frequency}  {social.frequencytype} */}
                {/* {calculateYearsDifference(drug.fromdate, drug.todate)} */}
                {drug.frequency && drug.frequency !== 0
                  ? `${drug.frequency} 
                              ${
                                drug.type && drug.type == "Smoking"
                                  ? "Packs"
                                  : drug.type == "Alcohol"
                                  ? "Drinks"
                                  : drug.type == "Smokeless"
                                  ? "Cans"
                                  : drug.type == "Illicit Drugs"
                                  ? "Times"
                                  : "Packs"
                              } 
                              Per ${drug.frequencytype}   
                              x ${
                                calculateYearsDifference(
                                  drug.fromdate,
                                  drug.todate
                                ) == 1
                                  ? calculateYearsDifference(
                                      drug.fromdate,
                                      drug.todate
                                    ) + " year"
                                  : calculateYearsDifference(
                                      drug.fromdate,
                                      drug.todate
                                    ) > 1
                                  ? calculateYearsDifference(
                                      drug.fromdate,
                                      drug.todate
                                    ) + " years"
                                  : "less than a year"
                              }  
                            `
                  : "-"}
                {drug.iscurrent == 2 && (
                  <>
                    {` Until ${
                      drug.todate ? formatDate_yyyy(drug.todate) : ""
                    }`}
                  </>
                )}
              </Text>
            }
          />
        </Box>
      ))}
    </Box>
  );
};

export default PastSocialHistory;
