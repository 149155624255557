import React, { useState, useEffect, useRef } from "react";
import {
  Flex,
  Box,
  Image,
  Spacer,
  Radio,
  RadioGroup,
  Stack,
  Divider,
  InputGroup,
  InputRightElement,
  Input,
  Text,
  Heading,
  UnorderedList,
  ListItem
} from "@chakra-ui/react";
// import Navbar from "../../components/navbar";
import SecondaryNav from "../../components/seconday-nav";
import {
  DropdownInput,
  PrimaryButton,
  PrimaryInput,
  Toaster,
} from "../../components";
import SuccessUploadIndex from "./success";
import { useLocation } from "react-router-dom";
import {
  FetchPrescriptionData,
  FetchPrescriptionDataPDF,
  AddMedicalRecord,
  lablearnMore
} from "../../middleware/services/messages/uploaddocument";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import { useNavigate } from "react-router-dom";
import DatePicker from "../../components/date-picker";

import { format, isValid, parse } from "date-fns";
import moment from "moment";
import { formatDate_DD_MM_YYYY, 
  getDoctorId, 
  getLoginID, 
  getPatientId, 
  handleAlphaDotAndSpace, useScreenType} from "../../../src/auth";
import ImagePreview from '../../components/image-preview';
import { MessageToaster } from "../../components/manualmessage";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import MobileNavbar from "../../components/mobile-navbar";
import UploadPDFDocument from "./uploadPDFLabReport";
import DynamicModal from "../../components/dynamic-modal";
import MiniSideBar from "../../components/mini-sidebar";


function UploadDocument() {
  const location = useLocation();
  var navigate = useNavigate();
  const learnMoreModal = React.useRef();
  const { isLoading, showLoader, dismissLoader } = useLoader();
  var [data, setData] = useState(null);
  var [actualdata, setActualData] = useState(null);
  var [pdfdata, setPDFData] = useState(null);
  var [pdfactualdata, setPDFActualData] = useState(null);
  var [pdffile, setPDFFile] = useState(null);
  var [name, setName] = useState("");
  var [age, setAge] = useState("");
  var [doctorname, setDoctorName] = useState("");
  var [reportdate, setReportDate] = useState("");
  var [reportname, setReportName] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [showpatientdetails, setShowPatientDetails] = useState(true);
  const [showpreview, setShowPreview] = useState(true);
  const [hidenavbar, setHideNavbar] = useState(false);
  const [activeTestIndex, setActiveTestIndex] = useState(0);
  const [showcustominput, setShowCustomInput] = useState(false);
  const [showedittestname, setShowEditTestName] = useState(false);
  const [selectedRadioValues, setSelectedRadioValues] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [manualInput, setManualInput] = useState("");
  const [testNameInput, setTestNameInput] = useState("");
  const [abnormalTests, setAbnormalTests] = useState([]);
  const [showsucess, setShowSuccess] = useState(false);
  const { showSuccessToast, showErrorToast } = Toaster();
  const { showMessageToast, closeMessageToast  } = MessageToaster();
  const [AbnormalData, SetAbnormalData] = useState([]);
  const [testname, setTestName] = useState("");
  const [reupload, setReupload] = useState(false);
  const [file, setFile] = useState(location.state.file);
  const [patientid, setpatientid] = useState(location.state.patientid);
  const [ cleanedValue , setCleanedValue] = useState('');
  const [nameConfirmClick , setNameConfirmClick] = useState(false)
  const [type , setType] = useState("")
  const [learnmore, setLearnMoreData] = useState("")
  const consentForm = useRef();
  //const [file, setFile] = useState("");
  const ageinyears = [];
  const screenType = useScreenType();
  for (let i = 1; i <= 100; i++) {
    ageinyears.push(String(i));
  }

  let storedData = localStorage.getItem("dobAndGenderData");
  let parsedData = storedData ? JSON.parse(storedData) : {};
  let PatientDOB = parsedData.dob || "";
  const { result, filename} = location.state || {};

  // useEffect(() => {
  //   const reader = new FileReader();
  //   reader.onloadend = () => {
  //     const fileName = file.name.split('.').pop().toLowerCase(); // Use 'split' instead of 'Split', and 'pop' instead of 'Last'
  //     if (fileName === 'pdf') { // Compare with 'pdf' instead of '.pdf'
  //       setSelectedImage("/assets/imgs/pdf.jpeg");
  //       setType("pdf")
  //     } else {
  //       setSelectedImage(reader.result);
  //       setType("")
  //     }
  //     consentForm.current.openModal();
  //   };
  //   reader.readAsDataURL(file);
  //   setFile(file);
  //   console.log(file , " file  -- ")
  // }, []);

  useEffect(() => {
    const nameConfirm = document.getElementById("testNameConfirm");
    if (nameConfirm) {
      nameConfirm.click();
    } else {
    }
  }, [nameConfirmClick]);

  useEffect(() => {
    if (file && !filename) {
    const reader = new FileReader();
    reader.onloadend = () => {
      const fileName = file.name.split('.').pop().toLowerCase(); // Use 'split' instead of 'Split', and 'pop' instead of 'Last'
      if (fileName === 'pdf') { // Compare with 'pdf' instead of '.pdf'
        setSelectedImage("/assets/imgs/pdf.jpeg");
        setType("pdf")
      } else {
        setSelectedImage(reader.result);
        setType("")
      }
      consentForm.current.openModal();
    };
    reader.readAsDataURL(file);
    setFile(file);
  }
  }, [file, filename]);
 useEffect(() => {
    if (filename) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const fileName =
          filename && filename.name.split(".").pop().toLowerCase(); // Use 'split' instead of 'Split', and 'pop' instead of 'Last'
        if (fileName === "pdf") {
          // Compare with 'pdf' instead of '.pdf'
          setSelectedImage("/assets/imgs/pdf.jpeg");
          setType("pdf");
        } else {
          setSelectedImage(reader.result);
          setType("");
        }
      };
      reader.readAsDataURL(filename);
      setFile(filename);
      setShowPreview(false);
      if (result?.data?.length > 0) {
        setPDFData(result.data);
        setPDFActualData(result.data);
        setPDFFile(result.documenturl);
        closeMessageToast();
        setShowPreview(false);
        setHideNavbar(true);
        dismissLoader();
      } else if (result?.patient_info) {
        setData(result);
        setActualData(result);
        setName(result && result.patient_info && result.patient_info.name);
        //setAge(result && result.patient_info && result.patient_info.age);
        const age = result && result.patient_info && result.patient_info.age;
        // Regular expression to check for valid numeric age
        const isValidAge = /^\d+$/.test(age);
        const ageNumber = Number(age);

        // Check if age is valid and within the specified range
        if (isValidAge && ageNumber >= 1 && ageNumber <= 200) {
          setAge(ageNumber); // Set state to the valid age
        } else {
          setAge(null); // Set state to null if age is invalid
        }
        setDoctorName(
          result && result.patient_info && result.patient_info.doctor_name
        );
        const dateOfReport = result?.patient_info?.date_of_report;
        if (dateOfReport) {
          const formattedDate = formatDate_DD_MM_YYYY(dateOfReport);
          if (formattedDate === "-") {
            const today = new Date();
            setReportDate(today);
          } else {
            setReportDate(formattedDate);
          }
        } else {
          const today = new Date();
          setReportDate(today);
        }
        setReportName(result && result.report_info && result.report_info.name);
        if (result && result.test_list) {
          var cleanedSuggestions = result.test_list.map((item, index) => {
            return item.suggestions[0];
          });
          console.log(cleanedSuggestions, " cleaned suggestions ");
        }

        //filtering out the test list which contains single special character , in the test_list , suggestions
        if (result && result.test_list) {
          const filteredTestList = result.test_list.filter((test) => {
            const suggestion = test.suggestions[0];
            const lowerCaseSuggestion = suggestion.toLowerCase();
            const isSingleSpecialChar = /^[^\w\s]$/.test(suggestion);
            const containsSpecificSubstrings =
              lowerCaseSuggestion.includes("%6") ||
              lowerCaseSuggestion.includes("mg/dl");
            return !isSingleSpecialChar && !containsSpecificSubstrings;
          });
          setData({ ...result, test_list: filteredTestList });
        }
      }
    }
  }, [result]);

  const fetchData = async () => {
    showLoader();
    showMessageToast("Please wait while we are fetching data")
    let attempts = 0;
    const maxAttempts = 4;
  
    const makeFetchCall = async () => {
      try {
        if(type === "pdf"){
          const response = await FetchPrescriptionDataPDF(file, file.name, file.type);
          console.log(response , " response PDF ")
          if (response?.output?.message == "No credits are available"){
            showErrorToast("No sufficient tokens available");
            closeMessageToast();
            dismissLoader();
            return false;
          }
          if (
            response.data.length === 0 || 
            (response.data.length > 0 && Object.keys(response.data[0]).length === 0) || 
            !response?.documenturl
          ) {
            showErrorToast("Something went wrong! please try again");
            closeMessageToast();
            dismissLoader();
            return false;
          }
         if(response.data.length > 0){
          setPDFData(response.data);
          setPDFActualData(response.data);
          setPDFFile(response.documenturl)
          closeMessageToast();
          setShowPreview(false);
          setHideNavbar(true);
          dismissLoader();
         }else{
          showErrorToast("Something went wrong! please try again");
          dismissLoader();
         }
         
        }else{
        const result = await FetchPrescriptionData(file, file.name, file.type, patientid);
        console.log(result, "modified res");
        if (result?.output?.message == "No credits are available"){
          showErrorToast("No sufficient tokens available");
          closeMessageToast();
          dismissLoader();
          return false;
        }
        if (result.patient_info) {
          setActualData(result)
          setData(result);
          const reader = new FileReader();
          reader.onloadend = () => {
            const fileName = file.name.split('.').pop().toLowerCase(); // Use 'split' instead of 'Split', and 'pop' instead of 'Last'
            if (fileName === 'pdf') { // Compare with 'pdf' instead of '.pdf'
              setSelectedImage("/assets/imgs/pdf.jpeg");
            } else {
              setSelectedImage(reader.result);
            }
          };
          reader.readAsDataURL(file);
          if(result.patient_info.date_of_report == "" || result.patient_info.date_of_report == "0000-00-00"){
            const today = moment().format('YYYY-MM-DD');
            result.patient_info.date_of_report = today;

          }
          setName(result && result.patient_info && result.patient_info.name);
          //setAge(result && result.patient_info && result.patient_info.age);
          const age = result && result.patient_info && result.patient_info.age;
          // Regular expression to check for valid numeric age
         const isValidAge = /^\d+$/.test(age);
         const ageNumber = Number(age);

         // Check if age is valid and within the specified range
         if (isValidAge && ageNumber >= 1 && ageNumber <= 200) {
           setAge(ageNumber); // Set state to the valid age
         } else {
           setAge(null); // Set state to null if age is invalid
         }
          setDoctorName(
            result && result.patient_info && result.patient_info.doctor_name
          );
           const dateOfReport = result?.patient_info?.date_of_report;
          //   let formattedDate = '';
          //   if (dateOfReport && moment(dateOfReport, undefined, true).isValid()) {
          //     formattedDate = moment(dateOfReport).format('DD-MM-yyyy');
          //   }
          // setReportDate(formattedDate ? formattedDate : '');
          // if (dateOfReport) {
          //   // const dateFormats = [
          //   //   "yyyy-MM-dd",
          //   //   "ddMMMyyyy",
          //   //   "dd MMM yyyy",
          //   //   "MMMM dd, yyyy",
          //   //   "MM/dd/yyyy",
  
          //   //   "dd-MMM-yyyy",
  
          //   //   "MMM, YYYY", // Modify this format if necessary
          //   // ];
          //   // let parsedDate = null;
          //   // for (const format of dateFormats) {
          //   //   parsedDate = parse(dateOfReport, format, new Date());
          //   //   if (isValid(parsedDate)) {
          //   //     break;
          //   //   }
          //   // }
  
          //   // if (isValid(parsedDate)) {
          //   //   setReportDate(parsedDate);
          //   // } else {
          //   //   const today = new Date();
          //   //   setReportDate(today);
          //   // }
          //   setReportDate(formatDate_DD_MM_YYYY(dateOfReport));
          //   } else {
          //     const today = new Date();
          //     setReportDate(today);
          //   }
            if (dateOfReport) {
              const formattedDate = formatDate_DD_MM_YYYY(dateOfReport);
              if (formattedDate === '-') {
                const today = new Date();
                setReportDate(today);
              } else {
                setReportDate(formattedDate);
              }
            } else {
              const today = new Date();
              setReportDate(today);
            }
          if(result && result.test_list){
            var cleanedSuggestions = result.test_list.map((item , index)=>{
              return item.suggestions[0]
            })
            console.log(cleanedSuggestions , " cleaned suggestions ")
          }

          
        //filtering out the test list which contains single special character , in the test_list , suggestions
       if (result && result.test_list) {
        const filteredTestList = result.test_list.filter(test => {
          const suggestion = test.suggestions[0];
          const lowerCaseSuggestion = suggestion.toLowerCase();
          const isSingleSpecialChar = /^[^\w\s]$/.test(suggestion);
          const containsSpecificSubstrings = lowerCaseSuggestion.includes('%6') || lowerCaseSuggestion.includes('mg/dl');
          return !isSingleSpecialChar && !containsSpecificSubstrings;
        });
        setData({ ...result, test_list: filteredTestList });
        
      }


         
          setReportName(result && result.report_info && result.report_info.name);
          setShowPreview(false);
          dismissLoader();
          closeMessageToast();
          return; // Exit the function if patient_info is obtained
        } else {
          attempts++;
          if (attempts < maxAttempts) {
            await makeFetchCall(); // Retry the call
          } else {
            closeMessageToast();
            handleError(); // Handle the error after max attempts
          }
        }
       
      }
      } catch (error) {
        console.error("Error in Upload Documents:", error);
        handleError();
      }
    };
  
    const handleError = () => {
      showErrorToast("Something went wrong! please try again");
      setShowPreview(true);
      const reader = new FileReader();
      reader.onloadend = () => {
        const fileName = file.name.split('.').pop().toLowerCase();
        if (fileName === 'pdf') {
          setSelectedImage("/assets/imgs/pdf.jpeg");
        } else {
          setSelectedImage(reader.result);
        }
      };
      reader.readAsDataURL(file);
      dismissLoader();
      //navigate("/patients");
    };
  
    await makeFetchCall();
  };
  
  useEffect(() => {
    if (data) {
      // Iterate over all tests
      data.test_list.forEach((test, index) => {
        if (index === activeTestIndex) {
          // If the current test is the active test index, select its first suggestion if available
          if (test.suggestions && test.suggestions.length > 0) {
            const firstSuggestion = test.suggestions[0];
            handleRadioChange(firstSuggestion, index);
          } 
          // else {
          //   // If there are no suggestions, show the custom input
          //   setShowCustomInput(true);
          // }
        }
      });
    }
  }, [activeTestIndex, data]);
  
  
  const handleimageselection = async (e) => {
    showLoader();
    e.preventDefault();
    const file = e.target.files[0];
    const reader = new FileReader();
reader.onloadend = () => {
  const fileName = file.name.split('.').pop().toLowerCase(); // Use 'split' instead of 'Split', and 'pop' instead of 'Last'
  if (fileName === 'pdf') { // Compare with 'pdf' instead of '.pdf'
    setSelectedImage("/assets/imgs/pdf.jpeg");
    setType("pdf")
  } else {
    setSelectedImage(reader.result);
    setType("")
  }
};
reader.readAsDataURL(file);
setFile(file);
    dismissLoader();
  };
  const handlePatientDetailsConfirm = () => {
    setShowPatientDetails(false);
  };
  const handleRadioChange = (event, testIndex) => {
    //const { value } = event;
    if (event === "manual") {
      setShowCustomInput(true);
    } else {
      setShowCustomInput(false);
    }
    setSelectedRadioValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[testIndex] = event;
      return updatedValues;
    });
  };

  const handleedittestname = (index) => {
    var currentTestName = data.test_list[index].test_name;
    setTestNameInput(currentTestName);
    setShowEditTestName(true);
  };
  const handleManualInputChange = (event, index , isInput) => {
    
    const { value } = event.target;
    
    let value2 = value;
    if(isInput){
    let cleanedValue = event.target.value.replace(/[^0-9a-zA-Z.\s]/g, ''); 
    const decimalIndex = cleanedValue.indexOf('.');
    if (decimalIndex !== -1) {
      const parts = cleanedValue.split('.');
      cleanedValue = parts[0] + '.' + parts[1];
    }
    value2 = cleanedValue;
    setCleanedValue(value2);
  }

    if (value === 'manual') {
      setManualInput((prevInputs) => ({
        ...prevInputs,
        [index]: '',
      }));
    } else {
      setManualInput((prevInputs) => ({
        ...prevInputs,
        [index]: value2,
      }));
    }
  };
  const backFunction = () => {
    window.history.back();
  };
  const handletestnamechange = (event) => {
    setTestNameInput(event.target.value);
  };
  const handleTestNameUpdate = (index) => {
    setShowEditTestName(false);
    // Make sure testNameInput is not empty
    if (testNameInput.trim() !== "") {
      setData((prevData) => {
        const updatedData = { ...prevData };
        updatedData.test_list[index].test_name = testNameInput;
        return updatedData;
      });
      // Set showedittestname to false after updating test name
    }
  };
  const handlenamechange = (event) => {
    let { value } = event.target;
     value  = handleAlphaDotAndSpace(value, 50);
    setName(value)

    setData((prevData) => ({
      ...prevData,
      patient_info: {
        ...prevData.patient_info,
        name: value,
      },
    }));
  };
  const handledoctornamechange = (event) => {
    let { value } = event.target;
    value  = handleAlphaDotAndSpace(value, 50);
    setDoctorName(value);

    setData((prevData) => ({
      ...prevData,
      patient_info: {
        ...prevData.patient_info,
        doctor_name: value,
      },
    }));
  };
  const handlereportnamechange = (event) => {
    const { value } = event.target;
    setData((prevData) => ({
      ...prevData,
      patient_info: {
        ...prevData.report_info,
        name: value,
      },
      report_info: {
        ...prevData.report_info,
        name: value,
      },
    }));
  };
  const handledobChange = (selectedOption) => {
    setData((prevData) => ({
      ...prevData,
      patient_info: {
        ...prevData.patient_info,
        age: selectedOption,
      },
    }));
  };
  const handleDateChange = (selecteddate) => {
    const formattedDate = moment(selecteddate).format('YYYY-MM-DD');
    setReportDate(formattedDate); // Update state if needed
    setData(prevData => ({
      ...prevData,
      patient_info: {
        ...prevData.patient_info,
        date_of_report: formattedDate
      }
    }));
  };
  const today = moment().format("YYYY-MM-DD");
  function isValidDateFormat(dateString) {
    // Regular expressions to match yyyy-mm-dd and mm-yyyy-dd formats
    const dateFormat1 = /^\d{4}-\d{2}-\d{2}$/;
    const dateFormat2 = /^\d{2}-\d{4}-\d{2}$/;
    return dateFormat1.test(dateString) || dateFormat2.test(dateString);
  }

  const handleConfirm = async () => {
    setNameConfirmClick(!nameConfirmClick)
    setCleanedValue("")
    //console.log(manualInput[activeTestIndex],"manual ")
    setShowCustomInput(false);
    const updatedData = {
      ...data,
      patient_info: {
        ...data.patient_info,
        patientid: getPatientId(),
        // c: getDoctorId(),
        "doctorid":getLoginID(),
        documenturl: data.documenturl,
        date_of_report: isValidDateFormat(data.patient_info.date_of_report)
          ? data.patient_info.date_of_report
          : today,
      },
    };

    if (activeTestIndex === data.test_list.length - 1) {
      // Submit all the data
      setIsSubmitting(true);
      const newAbnormalTests = [];
      const updatedTestList = [...data.test_list];
      updatedData.test_list.forEach((test, index) => {
        let selectedValue = selectedRadioValues[index];    
        if (selectedValue === "manual") {
          selectedValue = manualInput[index];
          // Update resultvalue for manual input tests
          test.resultvalue = selectedValue;
          test.ismanuallyadded = "1";
          // Add test to abnormal array with resultvalue included
          const value = parseFloat(selectedValue);
          const lowerBound = parseFloat(test.lower_bound);
          const upperBound = parseFloat(test.upper_bound);
          if (isNaN(value) || value < lowerBound || value > upperBound) {
            newAbnormalTests.push(test);
          }
        } else {
          // Update the resultvalue for all tests
          test.resultvalue = selectedValue;
          test.ismanuallyadded = "0";
          const lowerBound = parseFloat(test.lower_bound);
          const upperBound = parseFloat(test.upper_bound);
          // Check if selectedValue (or manual input) is not between lower and upper bounds
          if (
            (typeof selectedValue === "number" &&
              (selectedValue < lowerBound || selectedValue > upperBound)) ||
            (typeof selectedValue === "string" &&
              (parseFloat(selectedValue) < lowerBound ||
                parseFloat(selectedValue) > upperBound))
          ) {
            // Add test to abnormal array with resultvalue included
            newAbnormalTests.push(test);
          }
        }
      });

      delete updatedData.index;
      delete updatedData.test_description_and_range_call;
      delete updatedData.documenturl;
      // Update abnormalTests state
      setAbnormalTests((prevAbnormalTests) => [
        ...prevAbnormalTests,
        ...newAbnormalTests,
      ]);

      // Update data state
      setData(updatedData);

      // Add abnormal tests to the updatedTestList array
      updatedTestList.push(...newAbnormalTests);

      // Update data state with the modified test_list
      setData((prevData) => ({
        ...prevData,
        test_list: updatedTestList,
      }));

      //console.log("Input Final", updatedData);
      //console.log("Abnormal Tests", newAbnormalTests);
      SetAbnormalData(newAbnormalTests);
      setTestName(updatedData.report_info.name);
      const FinalInput = { ...updatedData, actualinput: actualdata };
      showLoader();
      const result = await AddMedicalRecord(FinalInput);
      console.log(result, "Response");
      if (result.output.result === "success") {
        setShowSuccess(true);
        dismissLoader();
      } else {
        showErrorToast("Somthing went wrong");
        dismissLoader();
      }
    } else {
      if (
        !selectedRadioValues[activeTestIndex] &&
        !manualInput[activeTestIndex]
      ) {
        showErrorToast("Please select any value or enter manually");
        //setShowCustomInput(true);
        return; // Prevent further execution
      }
      if(selectedRadioValues[activeTestIndex] == "manual" && manualInput[activeTestIndex] == ""){
        
        showErrorToast("Please enter any value");
        setShowCustomInput(true);
        return; // Prevent further execution
      }
      // Increment activeTestIndex to display the next test object
      setTimeout(() => {
        
      setActiveTestIndex((prevIndex) => prevIndex + 1);
      }, 100);
      console.log(selectedRadioValues)
    }
  };
  const openLearnMoreModal = async (labdetails)=>{
   showLoader();
    const result = await lablearnMore({
      name: labdetails.test_name
    })
    if(result.information){
      setLearnMoreData(result)   
      learnMoreModal.current.openModal();
    }
    dismissLoader();
  }
  const submitconsentform = async ()=>{
    consentForm.current.closeModal();
   }
   const closeconsentform = async ()=>{
     window.history.back();
    }
  return (
    <>
      {!showsucess ? (
        <>
        {screenType == 'web' ? (
          <Box w="100%">
            {/* <SuccessIndex /> */}
           {!hidenavbar &&
              <Box top="0">
              {/* <MiniSideBar /> */}
              {isLoading && <Loader />}
              <SecondaryNav withBack="Upload document" goBack={backFunction} />
              </Box>
           }
           

            {showpreview && (
            <Box w="100%" p="32px" bg="#E6E7E9" height="calc(100vh - 70px)" overflowY="auto">
              <Box display="flex" pb="25px" justifyContent="center" alignItems="center">
                <Image w="calc(100vw - 70%)" boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px;" h="auto" src={selectedImage} />
              </Box>

              <Box w="calc(100vw - 70%)" display="flex" marginInline="auto" alignItems="center" gap="20px" pos="sticky" bottom="0">
                <input
                            type="file"
                            id="Labupload"
                            style={{ display: "none" }}
                            onChange={handleimageselection}
                          />
                          <PrimaryInput
                            inputType="file"
                            uploadText="Re-Upload"
                            id="Labupload"
                            onChange={handleimageselection}
                            variant="grayBtnFull"
                            buttonText="Reupload"
                            isDisabled={false}
                            inputStyleCss={{boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px;", borderRadius: "10px"}}
                          />
                <PrimaryButton
                  onClick={fetchData}
                  buttonText="Confirm"
                />
              </Box>
            </Box>
             )}
              {!showpreview && type === "pdf" && (
               <UploadPDFDocument pdfdata={pdfdata} previewimage={selectedImage} pdfurl={pdffile}/>
             )}
             {!showpreview && type !== "pdf" && (
               
            <Flex overflowY="auto" height="100vh" direction="row">
          
              {/* Image section start */}
              <Box flex="1" overflowY="scroll" p="32px" bg="#E6E7E9">
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  h="100vh"
                >
                  <Image w="calc(100vh - 25%)" src={selectedImage} />
                </Box>
              </Box>
              {/* Image section end */}

              <Box
                w="500px"
                h="100%"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                overflowY="auto"
                height="calc(100vh - 90px)"
              >
                {showpatientdetails && data &&  data.patient_info && (
                  <>
                    <Box overflowY="auto" p="24px 32px" h="100%">
                      <Box as="h1" fontSize="18px" pb="20px" fontWeight="600">
                        Please verify the details below
                      </Box>
                      <PrimaryInput
                        inputPlace="Name"
                        onChange={handlenamechange}
                        defaultValue={name && name ? name : ""}
                        variant="defaultSide"
                        inputValue={name}
                      />
                      <DropdownInput
                        variant="bothSide"
                        onClick={handledobChange}
                        defaultOption={age}
                        dropDownPlace="Age"
                        options={ageinyears}
                      />
                      <PrimaryInput
                        inputPlace="Doctor name"
                        onChange={handledoctornamechange}
                        defaultValue={doctorname}
                        variant="bothSide"
                        inputValue={doctorname}
                      />
              
                      {/* <PrimaryInput 
                        inputPlace="Date of document" 
                        defaultValue={reportdate} 
                        onChange={(e) => setReportDate(e.target.value)}  
                        variant="bothSide" 
                        inputType="date" 
                      /> */}
                      <DatePicker 
                      disableFutureDates={true}
                          defaultDate={reportdate} 
                          patientDOB={PatientDOB}
                            CUSTOM_DISABLE={true}
                          onDateChange={(selectedDateValue) =>
                            handleDateChange(selectedDateValue)
                          }
                          isEdit={true}
                          placeholderProp={"Date of document"} 
                          leftPos={{left: "3px"}}
                        />
                      <PrimaryInput
                        inputPlace="Document name"
                        onChange={handlereportnamechange}
                        defaultValue={reportname}
                        variant="downSide"
                      />
                    </Box>

                    <Box
                      w="100%"
                      display="flex"
                      alignItems="center"
                      p="24px 32px"
                      gap="20px"
                    >
                      {/* <input
                        type="file"
                        id="Labupload"
                        style={{ display: "none" }}
                        onChange={handleimageselection}
                      />
                      <PrimaryInput
                        inputType="file"
                        uploadText="Re-Upload"
                        id="Labupload"
                        onChange={handleimageselection}
                        variant="grayBtnFull"
                        buttonText="Reupload"
                        isDisabled={false}
                      /> */}
                      <PrimaryButton
                        onClick={handlePatientDetailsConfirm}
                        buttonText="Confirm"
                      />
                    </Box>
                  </>
              
                  
              )}
                {!showpatientdetails && (
                  <>
                    {data &&
                      data.test_list &&
                      data.test_list.map(
                        (step, index) =>
                          index === activeTestIndex && (
                            <Box overflowY="auto" key={index}>
                              <Box
                                bg="#061027"
                                minH={{sm: "140px", xl: "180px"}}
                                p="18px 28px"
                                display="flex"
                                flexDir="column"
                                gap="8px"
                              >
                                <Box display="flex" gap="8px">
                                  {data.test_list.map((_, i) => (
                                    <Box
                                      as="button"
                                      key={i}
                                      p="0"
                                      borderTop={`3px solid ${
                                        i === activeTestIndex
                                          ? "#14C585"
                                          : "#212b40"
                                      }`}
                                      w="16.666%"
                                      h="20px"
                                    ></Box>
                                  ))}
                                </Box>
                                <Box>
                                  <Box
                                    display="flex"
                                    flexDir="row"
                                    justifyContent="space-between"
                                    gap="10px"
                                    alignItems="center"
                                  >
                                     <Box display="flex" alignItems="center" gap="10px">
                                      {activeTestIndex > 0 && (
                                      <Box as="button" onClick={() => setActiveTestIndex((prevIndex) => prevIndex - 1)} display="flex" flexDirection="row" alignItems="center" gap="10px" sx={{bg: "none", _hover: {bg:"none"}}}>
                                        <Image w="15px" mr="5px" src="/assets/imgs/arrow_back.png"/>
                                    </Box>
                                    )}
                                    <Box
                                      fontSize="20px"
                                      fontWeight="600"
                                      color="#E6E7E9"
                                    >
                                      {!showedittestname && (
                                        <>
                                          {step.test_name ? step.test_name : "-"}
                                          <Image
                                            w="13px"
                                            ml="10px"
                                            onClick={()=>handleedittestname(index)}
                                            h="13px"
                                            display="inline-block"
                                            src="/assets/imgs/edit-white.png"
                                          />
                                        </>
                                      )}
                                      {showedittestname && (
                                        <>
                                          <Box>
                                            <Box
                                              display="flex"
                                              flexDir="row"
                                              justifyContent="space-between"
                                              gap="5px"
                                              alignItems="center"
                                            >
                                              <InputGroup>
                                                <Input
                                                  type="text"
                                                  defaultValue={step.test_name}
                                                  color="white"
                                                  fontSize="16px"
                                                  fontWeight="600"
                                                  border="2px solid white"
                                                  borderRadius="10px"
                                                  height="48px"
                                                  pr="48px"
                                                  minW="50px"
                                                  onChange={handletestnamechange}
                                                />
                                                <InputRightElement top="4px">
                                                  <Box
                                                    as="button"
                                                    bg="white"
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    height="48px"
                                                    minW="50px"
                                                    borderRadius="0px 10px 10px 0"
                                                    id="testNameConfirm"
                                                    onClick={() =>
                                                      handleTestNameUpdate(index)
                                                    }
                                                  >
                                                    <Image src="/assets/imgs/check-black.png" />
                                                  </Box>
                                                </InputRightElement>
                                              </InputGroup>
                                            </Box>
                                          </Box>
                                        </>
                                      )}
                                    </Box>
                                    </Box>
                                    <Box
                                      as="button"
                                      border="1px solid #0B1F4F"
                                      color="white"
                                      fontSize="12px"
                                      fontWeight="600"
                                      borderRadius="full"
                                      p="5px 10px"
                                      onClick={()=>openLearnMoreModal(step)}
                                    >
                                      Learn more
                                    </Box>
                                  </Box>
                                  <Box
                                    as="p"
                                    fontSize="12px"
                                    fontWeight="600"
                                    color="white"
                                  >
                                    NL: {step.lower_bound ? step.lower_bound : ""}{" "}
                                    to {step.upper_bound ? step.upper_bound : ""}{" "}
                                    {step.test_unit !== "None"
                                      ? step.test_unit
                                      : ""}
                                  </Box>
                                  <Box
                                    as="p"
                                    pt="15px"
                                    fontSize="14px"
                                    fontWeight="400"
                                    lineHeight="normal"
                                    color="white"
                                  >
                                    {step.test_description
                                      ? step.test_description
                                      : "-"}
                                  </Box>
                                </Box>
                              </Box>
                              <Box overflowY="auto" p="24px 32px">
                                <Box
                                  as="h1"
                                  fontSize="18px"
                                  pb="20px"
                                  fontWeight="600"
                                >
                                  Please verify the measurement
                                </Box>
                                <Box
                                  border="1px solid #E6E7E9"
                                  borderRadius="10px"
                                >
                                  <RadioGroup                                
                                  //defaultValue={step.suggestions.length > 0 ? step.suggestions[0] : "manual"}
                                  defaultValue={step.suggestions[0]}
                                    onChange={(event) =>
                                      handleRadioChange(event, activeTestIndex)
                                    }
                                  >
                                    <Stack direction="column">
                                      {step.suggestions &&
                                        step.suggestions.map((item, index) => (
                                          <Box
                                            key={index}
                                            as="button"
                                            cursor="pointer"
                                            borderBottom="1px solid #E6E7E9"
                                            p="16px"
                                            fontSize="14px"
                                            fontWeight="600"
                                          >
                                            <Flex
                                              as="label"
                                              justifyContent="space-between"
                                              alignItems="center"
                                            >
                                              <span>{item}</span>
                                              <Radio
                                                size="lg"
                                                value={item}
                                              />
                                            </Flex>
                                          </Box>
                                        ))}
                                      <Box
                                        key="manual"
                                        as="button"
                                        cursor="pointer"
                                        borderBottom="1px solid #E6E7E9"
                                        p="16px"
                                        fontSize="14px"
                                        fontWeight="600"
                                      >
                                        <Flex
                                          as="label"
                                          justifyContent="space-between"
                                          alignItems="center"
                                        >
                                          <span>Enter manually</span>
                                          <Radio
                                            size="lg"
                                            value={"manual"}
                                            id="manualentry"
                                            onChange={(event) =>
                                              handleManualInputChange(
                                                event,
                                                index,
                                                false
                                              )
                                            }
                                          />
                                        </Flex>
                                      </Box>
                                      {showcustominput && (
                                        <PrimaryInput
                                          inputPlace="Value"
                                          variant="defaultSide"
                                          value={manualInput[index]}
                                          inputValue={cleanedValue}
                                          onChange={(event) =>
                                            handleManualInputChange(event, index, true)
                                          }
                                        />
                                      )}
                                    </Stack>
                                  </RadioGroup>
                                </Box>
                              </Box>
                            </Box>
                          )
                      )}
                    <Box w="100%" p="18px 28px" mt="auto">
                      <PrimaryButton
                        buttonText={
                          activeTestIndex === data.test_list.length - 1
                            ? "Submit"
                            : "Confirm"
                        }
                        isDisabled={isSubmitting}
                        btnStyle={{my: "0"}} 
                        onClick={handleConfirm}
                      />
                    </Box>
                  </>
                )}
              </Box>
            </Flex>
            )}
          </Box>
        )  : (
          // <>
          //Mobile Responsive
          <Box>
            {isLoading && <Loader />}
            <MobileSecondaryNav 
              handlebackarrow={()=> navigate('/patients')}
              header="Upload document"
            />
            {showpreview &&
            <Box w="100%" p="32px" bg="#E6E7E9" height="calc(100vh - 70px)" overflowY="auto">
              <Box display="flex" pb="25px" justifyContent="center" alignItems="center">
                <Image w="calc(100vw - 20%)" boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px;" h="auto" src={selectedImage} />
              </Box>

              <Box w="calc(100vw - 20%)" display="flex" marginInline="auto" alignItems="center" gap="20px" pos="sticky" bottom="0">
                <input
                  type="file"
                  id="Labupload"
                  style={{ display: "none" }}
                  onChange={handleimageselection}
                />
                <PrimaryInput
                  inputType="file"
                  uploadText="Re-Upload"
                  id="Labupload"
                  onChange={handleimageselection}
                  variant="grayBtnFull"
                  buttonText="Reupload"
                  isDisabled={false}
                  inputStyleCss={{boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px;", borderRadius: "10px"}}
                />
                <PrimaryButton
                  onClick={fetchData}
                  buttonText="Confirm"
                />
              </Box>
            </Box>
            }
           {!showpreview && type === "pdf" && (
               <UploadPDFDocument pdfdata={pdfdata} actualdata={pdfactualdata} previewimage={selectedImage} pdfurl={pdffile}/>
            )}

          {!showpreview && type !== "pdf" && 
            <Flex overflowY="auto" height="100vh" direction="row">
              <Box
                w="100%"
                h="100%"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                overflowY="auto"
                height="calc(100vh - 90px)"
              >
                {showpatientdetails && data &&  data.patient_info && (
                  <>
                    <Box overflowY="auto" p="24px 32px" h="100%">
                      <Box as="h1" fontSize="18px" pb="20px" fontWeight="600">
                        Please verify the details below
                      </Box>
                      <PrimaryInput
                        inputPlace="Name"
                        onChange={handlenamechange}
                        defaultValue={name && name ? name : ""}
                        variant="defaultSide"
                        inputValue={name}
                      />
                      <DropdownInput
                        variant="bothSide"
                        onClick={handledobChange}
                        defaultOption={age}
                        dropDownPlace="Age"
                        options={ageinyears}
                      />
                      <PrimaryInput
                        inputPlace="Doctor name"
                        onChange={handledoctornamechange}
                        defaultValue={doctorname}
                        variant="bothSide"
                        inputValue={doctorname}
                      />
              
                      {/* <PrimaryInput 
                        inputPlace="Date of document" 
                        defaultValue={reportdate} 
                        onChange={(e) => setReportDate(e.target.value)}  
                        variant="bothSide" 
                        inputType="date" 
                      /> */}
                      <DatePicker 
                      disableFutureDates={true}
                          defaultDate={reportdate} 
                          onDateChange={(selectedDateValue) =>
                            handleDateChange(selectedDateValue)
                          }
                          patientDOB={PatientDOB}
                            CUSTOM_DISABLE={true}
                          isEdit={true}
                          placeholderProp={"Date of document"} 
                          leftPos={{left: "3px"}}
                        />
                      <PrimaryInput
                        inputPlace="Document name"
                        onChange={handlereportnamechange}
                        defaultValue={reportname}
                        variant="downSide"
                      />
                    </Box>

                    <Box
                      w="100%"
                      display="flex"
                      alignItems="center"
                      p="24px 32px"
                      gap="20px"
                    >
                      {/* <input
                        type="file"
                        id="Labupload"
                        style={{ display: "none" }}
                        onChange={handleimageselection}
                      />
                      <PrimaryInput
                        inputType="file"
                        uploadText="Re-Upload"
                        id="Labupload"
                        onChange={handleimageselection}
                        variant="grayBtnFull"
                        buttonText="Reupload"
                        isDisabled={false}
                      /> */}
                      <PrimaryButton
                        onClick={handlePatientDetailsConfirm}
                        buttonText="Confirm"
                      />
                    </Box>
                  </>
              
                  
              )}
                {!showpatientdetails && (
                  <>
                    {data &&
                      data.test_list &&
                      data.test_list.map(
                        (step, index) =>
                          index === activeTestIndex && (
                            <Box overflowY="auto" key={index}>
                              <Box
                                bg="#061027"
                                minH={{sm: "140px", xl: "180px"}}
                                p="18px 28px"
                                display="flex"
                                flexDir="column"
                                gap="8px"
                              >
                                <Box display="flex" gap="8px">
                                  {data.test_list.map((_, i) => (
                                    <Box
                                      as="button"
                                      key={i}
                                      p="0"
                                      borderTop={`3px solid ${
                                        i === activeTestIndex
                                          ? "#14C585"
                                          : "#212b40"
                                      }`}
                                      w="16.666%"
                                      h="20px"
                                    ></Box>
                                  ))}
                                </Box>
                                <Box>
                                  <Box
                                    display="flex"
                                    flexDir="row"
                                    justifyContent="space-between"
                                    gap="10px"
                                    alignItems="center"
                                  >
                                    <Box
                                      fontSize="20px"
                                      fontWeight="600"
                                      color="#E6E7E9"
                                    >
                                      {!showedittestname && (
                                        <>
                                          {step.test_name ? step.test_name : "-"}
                                          <Image
                                            w="13px"
                                            ml="10px"
                                            onClick={handleedittestname}
                                            h="13px"
                                            display="inline-block"
                                            src="/assets/imgs/edit-white.png"
                                          />
                                        </>
                                      )}
                                      {showedittestname && (
                                        <>
                                          <Box>
                                            <Box
                                              display="flex"
                                              flexDir="row"
                                              justifyContent="space-between"
                                              gap="5px"
                                              alignItems="center"
                                            >
                                              <InputGroup>
                                                <Input
                                                  type="text"
                                                  defaultValue={step.test_name}
                                                  color="white"
                                                  fontSize="16px"
                                                  fontWeight="600"
                                                  border="2px solid white"
                                                  borderRadius="10px"
                                                  height="48px"
                                                  pr="48px"
                                                  minW="50px"
                                                  onChange={handletestnamechange}
                                                />
                                                <InputRightElement top="4px">
                                                  <Box
                                                    as="button"
                                                    bg="white"
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    height="48px"
                                                    minW="50px"
                                                    borderRadius="0px 10px 10px 0"
                                                    id="testNameConfirm"
                                                    onClick={() =>
                                                      handleTestNameUpdate(index)
                                                    }
                                                  >
                                                    <Image src="/assets/imgs/check-black.png" />
                                                  </Box>
                                                </InputRightElement>
                                              </InputGroup>
                                            </Box>
                                          </Box>
                                        </>
                                      )}
                                    </Box>
                                    {/* <Box
                                      as="button"
                                      border="1px solid #0B1F4F"
                                      color="white"
                                      fontSize="12px"
                                      fontWeight="600"
                                      borderRadius="full"
                                      p="5px 10px"
                                    >
                                      Learn more
                                    </Box> */}
                                  </Box>
                                  <Box
                                    as="p"
                                    fontSize="12px"
                                    fontWeight="600"
                                    color="white"
                                  >
                                    NL: {step.lower_bound ? step.lower_bound : ""}{" "}
                                    to {step.upper_bound ? step.upper_bound : ""}{" "}
                                    {step.test_unit !== "None"
                                      ? step.test_unit
                                      : ""}
                                  </Box>
                                  <Box
                                    as="p"
                                    pt="15px"
                                    fontSize="14px"
                                    fontWeight="400"
                                    lineHeight="normal"
                                    color="white"
                                  >
                                    {step.test_description
                                      ? step.test_description
                                      : "-"}
                                  </Box>
                                </Box>
                              </Box>
                              <Box overflowY="auto" p="24px 32px">
                                <Box
                                  as="h1"
                                  fontSize="18px"
                                  pb="20px"
                                  fontWeight="600"
                                >
                                  Please verify the measurement
                                </Box>
                                <Box
                                  border="1px solid #E6E7E9"
                                  borderRadius="10px"
                                >
                                  <RadioGroup                                
                                  //defaultValue={step.suggestions.length > 0 ? step.suggestions[0] : "manual"}
                                  defaultValue={step.suggestions[0]}
                                    onChange={(event) =>
                                      handleRadioChange(event, activeTestIndex)
                                    }
                                  >
                                    <Stack direction="column">
                                      {step.suggestions &&
                                        step.suggestions.map((item, index) => (
                                          <Box
                                            key={index}
                                            as="button"
                                            cursor="pointer"
                                            borderBottom="1px solid #E6E7E9"
                                            p="16px"
                                            fontSize="14px"
                                            fontWeight="600"
                                          >
                                            <Flex
                                              as="label"
                                              justifyContent="space-between"
                                              alignItems="center"
                                            >
                                              <span>{item}</span>
                                              <Radio
                                                size="lg"
                                                value={item}
                                              />
                                            </Flex>
                                          </Box>
                                        ))}
                                      <Box
                                        key="manual"
                                        as="button"
                                        cursor="pointer"
                                        borderBottom="1px solid #E6E7E9"
                                        p="16px"
                                        fontSize="14px"
                                        fontWeight="600"
                                      >
                                        <Flex
                                          as="label"
                                          justifyContent="space-between"
                                          alignItems="center"
                                        >
                                          <span>Enter manually</span>
                                          <Radio
                                            size="lg"
                                            value={"manual"}
                                            id="manualentry"
                                            onChange={(event) =>
                                              handleManualInputChange(
                                                event,
                                                index,
                                                false
                                              )
                                            }
                                          />
                                        </Flex>
                                      </Box>
                                      {showcustominput && (
                                        <PrimaryInput
                                          inputPlace="Value"
                                          variant="defaultSide"
                                          value={manualInput[index]}
                                          inputValue={cleanedValue}
                                          onChange={(event) =>
                                            handleManualInputChange(event, index, true)
                                          }
                                        />
                                      )}
                                    </Stack>
                                  </RadioGroup>
                                </Box>
                              </Box>
                            </Box>
                          )
                      )}
                    <Box w="100%" p="18px 28px" mt="auto">
                      <PrimaryButton
                        buttonText={
                          activeTestIndex === data.test_list.length - 1
                            ? "Submit"
                            : "Confirm"
                        }
                        isDisabled={isSubmitting}
                        btnStyle={{my: "0"}} 
                        onClick={handleConfirm}
                      />
                    </Box>
                  </>
                )}
              </Box>
            </Flex>
            }
          </Box>
            )}
          

        </>
      ) : (
        <SuccessUploadIndex data={AbnormalData} testname={testname} />
      )}


      <DynamicModal
        ref={consentForm}
        modalAttributes={{closeOnOverlayClick: false}}
        hasCloseBtn={false}
        customClose={true}
        customCloseClick={closeconsentform}
        modalStyle={{minW:{base: "100%", lg: "600px"}, maxW:{base: "100%", lg: "600px"}, maxH:"100vh"}}
        modalHeader="Consent Form"
        modalBody={<>
          <Box style={{ height: '300px', overflowY: 'scroll' }}  h="calc(80vh - 100px)" overflow="hidden auto" lineHeight="1.6">
            <Heading as="h2" fontSize="1.5rem" mb={4}>
            Our Commitment to Privacy
            </Heading>

            <Text mb={4}>
            At Midoc, your privacy is paramount. We are committed to protecting the privacy of your personal information while providing you with the highest level of service. This Privacy Policy explains how Midoc, LLC ("Midoc," "we," or "us") may collect, use, and share your personal information when you access our services through the Midoc website or our mobile application (collectively, the "Services").            </Text>

            <Heading as="h3" size="md" mb={2}>
            Consent
            </Heading>

            <Text mb={4}>
            By using our Services, you consent to the terms outlined in this Privacy Policy. If you do not agree with any part of this policy, please do not use our Services. Continued use of the Services following any changes to this policy will signify your acceptance of those changes.            </Text>



            <Text mb={2}> Personal Information You Provide:</Text>
            <UnorderedList mb={4}>
              <ListItem><strong>Registration and Account Setup:</strong> We collect personal details when you register or set up an account, which may include your name, email address, phone number, and address.</ListItem>
              <ListItem><strong>Health Records:</strong> Through our mobile application, you may voluntarily provide medical and health-related information to enhance service customization.</ListItem>
              <ListItem><strong>Interactions:</strong> We gather information when you interact with our Services, such as customer service inquiries, responses to surveys, and participation in promotions.</ListItem>
              
            </UnorderedList>

            <Text mb={2}> Automated Information Collection:</Text>
            <UnorderedList mb={4}>
              <ListItem><strong>Cookies and Tracking Technologies:</strong> We use cookies to enhance your experience, personalize content and ads, and analyze our site traffic. We also employ web beacons to collect aggregate statistics about website usage.</ListItem>
              <ListItem><strong>Geo-location Data:</strong> With your permission, we may collect precise location data to provide location-specific services.</ListItem>              
            </UnorderedList>

            <Text mb={2}> How We Use Your Information</Text>
            <UnorderedList mb={4}>
              <ListItem><strong>Service Provision:</strong> To manage your account, provide customer support, process transactions, and send important account and service information.</ListItem>
              <ListItem><strong>Improvement and Personalization: </strong> To enhance and personalize your experience, and to develop new features and services that meet your needs.</ListItem>
              <ListItem><strong>Compliance and Protection:</strong>To comply with legal requirements and protect the rights and safety of Midoc and its users.</ListItem>
              
            </UnorderedList>

            <Text mb={2}> Sharing of Information</Text>
            <UnorderedList mb={4}>
              <ListItem><strong>With Service Providers:</strong>We share information with third-party service providers to facilitate services on our behalf, such as payment processing, data analysis, email delivery, and hosting services.</ListItem>
              <ListItem><strong>Legal and Safety Reasons:</strong>We may disclose information to respond to legal processes or to protect the rights, property, or safety of Midoc, our users, or others.</ListItem>
              <ListItem><strong>Business Transfers: </strong>In the event of a merger, sale, or asset transfer, your information may be disclosed and transferred as part of the transaction.</ListItem>
              
            </UnorderedList>

            <Text mb={2}> Your Rights and Choices</Text>
            <UnorderedList mb={4}>
              <ListItem><strong>Account Information: </strong>You can review, update, or delete your account information at any time.</ListItem>
              <ListItem><strong>Cookies: </strong> You can configure your browser to reject cookies or to alert you when cookies are being sent.</ListItem>
              <ListItem><strong>Opt-Out: </strong>You may opt out of receiving promotional communications from us by following the instructions in the communication.</ListItem>
              
            </UnorderedList>

            <Heading as="h3" size="md" mb={2}>
            Children’s Privacy
            </Heading>

            <Text mb={4}>
            We do not knowingly collect or solicit information from anyone under the age of 13. If you are under 13, please do not attempt to register for the Services or send any personal information about yourself to us.
          </Text>

          <Heading as="h3" size="md" mb={2}>
          International Users.
            </Heading>

            <Text mb={4}>
            Our Services are hosted in the United States and are intended for users located within the United States. If you are located outside of the United States, please be aware that any information you provide to us will be transferred to and processed in the United States.
             </Text>

             <Heading as="h3" size="md" mb={2}>
             Changes to This Policy
            </Heading>

            <Text mb={4}>
            We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. We encourage you to review our Privacy Policy whenever you access the Services to stay informed about our information practices and the ways you can help protect your privacy.
              </Text>

              <Text mb={4}>
              If you have any questions about this Privacy Policy or our privacy practices, 
              please contact us at: privacy@midoc.com.
                 </Text>


           
          </Box>
        </>}
        hasFooter={true}
        modalFooterBtn={<>
          <PrimaryButton buttonText="Agree" onClick={submitconsentform}/>
        </>}
      />

      <DynamicModal
        ref={learnMoreModal}
        modalHeader={learnmore.measure_name ? learnmore.measure_name +"("+learnmore.measure_unit+")" :""}

        modalStyle={{maxWidth: "calc(100vw - 45%)",maxHeight: "calc(100vh - 30%)", overflow:"hidden auto", w: "100%"}}
        modalBody={
          <>
          
          <Box mb="10px" >
            <Text fontSize="14px" fontWeight="600">
              Information  
            </Text>
            <Box as="span" >{learnmore.information ? learnmore.information : ''}</Box>
            </Box>
            
            <Box mb="10px" >

            <Text fontSize="14px" fontWeight="600">
              Upper Bound 
            </Text>
            <Box as="span"  > {learnmore.upper_bound && learnmore.upper_bound !== "N/A" ? learnmore.upper_bound : '-'}</Box>
            </Box>
            
          <Box mb="10px" >
            <Text fontSize="14px" fontWeight="600">
                Lower Bound
            </Text>
            <Box as="span" mb="10px">{learnmore.lower_bound && learnmore.lower_bound !== "N/A" ? learnmore.lower_bound : "-" }</Box>
            </Box>
          </>
        }
      />

    </>
  );
}

export default UploadDocument;
