import moment from "moment";
export default function formAPItreatmentteam({
    entitytype,
    doctorname,
    date,
   

})
{
  return Object.freeze({
    entitytype,
    doctorname,
    date: date,
   
   
  });
}