import formAPIModelupcomingvisits from "../upcomingvisits/upcomingvists";
import formAPIModelmypatients from "../mypatients/mypatients";
import formAPIModeltotalrevenuechart from "./totalrevenuechart/revenuecharts";
import formAPIModeltotalvisitschart from "./totalvisitschart/visitscharts";
import formAPIModeltotalfollowupchart from "./totalfollowupchart/followupcharts";
import formAPIModelpendingvisits from "../pendingvisits/pendingvisits";



export default function formapimodalhome({
  resultupcomingvisits,
  resultpendingvisits,
  resultmypatients,
  isavailable,
  resultscheduledvisits,
  resulttotalfollowupvisits,
  resulttotalrevenue,
  resulttotalvisithours,
  totalvisithourslastmonthcomparison,
  totalrevenuelastmonthcomparison,
  totalfollowupvisitslastmonthcomparison,
  resulttotalrevenueforcharts,
  resulttotalvisithoursforcharts,
  resulttotalfollowupvisitsforcharts,
  pageno,
  pagesize,
  count,
  isdoctor,
  isglobaladmin,

})
{
return Object.freeze({
  
 
  resultupcomingvisits:resultupcomingvisits !== null ? formAPIModelupcomingvisits(resultupcomingvisits) : [],
  resultpendingvisits:resultpendingvisits !== null ? formAPIModelpendingvisits(resultpendingvisits) : [],
  resultmypatients:resultmypatients !== null ? formAPIModelmypatients(resultmypatients) : [],
  isavailable:isavailable,
  resultscheduledvisits: resultscheduledvisits ?resultscheduledvisits:"0",
  resulttotalfollowupvisits:resulttotalfollowupvisits?resulttotalfollowupvisits:"0",
  resulttotalrevenue:resulttotalrevenue?resulttotalrevenue:"0.00",
  resulttotalvisithours:resulttotalvisithours?resulttotalvisithours:"0",
  totalvisithourslastmonthcomparison:totalvisithourslastmonthcomparison?totalvisithourslastmonthcomparison:"0",
  totalrevenuelastmonthcomparison:totalrevenuelastmonthcomparison?totalrevenuelastmonthcomparison:"0",
  totalfollowupvisitslastmonthcomparison:totalfollowupvisitslastmonthcomparison?totalfollowupvisitslastmonthcomparison:"0",
  resulttotalrevenueforcharts: resulttotalrevenueforcharts !== null ? formAPIModeltotalrevenuechart(resulttotalrevenueforcharts) : [],
  resulttotalvisithoursforcharts: resulttotalvisithoursforcharts !== null ? formAPIModeltotalvisitschart(resulttotalvisithoursforcharts) : [],
  resulttotalfollowupvisitsforcharts: resulttotalfollowupvisitsforcharts !== null ? formAPIModeltotalfollowupchart(resulttotalfollowupvisitsforcharts) : [],
  pageno,
  pagesize,
  count,
  isdoctor,
  isglobaladmin,
});
}