
import formAPIModelSubscription from "./subscription";
import formAPIModalSubscriptionDescription from "./subscriptiondescp";


var formattedOutput = null;
//var formattedArray = [];
const formAPISubscriptionDescription = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIModalSubscriptionDescription(item));

  return formattedOutput
};

export default formAPISubscriptionDescription


