// import {
//     Card,
//     CardHeader,
//     CardBody,
//     CardFooter,
//     Heading,
//     Stack,
//     StackDivider,
//     Box,
//     Image,
//     Spacer,
//   } from "@chakra-ui/react";

//   function DoctorCard({ hasFooter = true, cardBody, cardFooterText, bodyStyle,onClick, DocCardStyle  }) {
//     return (
//       <Card borderRadius="12px" w="100%" border="1px solid #E6E7E9" boxShadow="0px 8px 20px 0px #E6E7E966" {...DocCardStyle}>
  
//         <CardBody {...bodyStyle}>
//           {cardBody}
//         </CardBody>
  
//         {hasFooter && (
//           <CardFooter h="50px"  display="flex" flexDirection="row" alignItems="center" justifyContent="center" p="15px" borderTop="1px solid #E6E7E9">
//            <Box as="button" onClick={onClick} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
//             <Box color="#1C4EC5" mr="8px" fontSize="14px" fontWeight="600">{cardFooterText}</Box>
//             <Box><Image src="/assets/imgs/right-blue-arrow.png" /></Box>
//            </Box>
//           </CardFooter>
//         )}
//       </Card>
//     );
//   }
  
//   export default DoctorCard;
  
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Stack,
  StackDivider,
  Box,
  Image,
  Spacer,
} from "@chakra-ui/react";

function DoctorCard({ hasFooter = true, cardBody, cardFooterText, bodyStyle, onClick, DocCardStyle, isButtonDisabled = false }) {
  return (
    <Card borderRadius="12px" w="100%" border="1px solid #E6E7E9" boxShadow="0px 8px 20px 0px #E6E7E966" {...DocCardStyle}>

      <CardBody {...bodyStyle}>
        {cardBody}
      </CardBody>

      {hasFooter && (
        <CardFooter h="50px" display="flex" flexDirection="row" alignItems="center" justifyContent="center" p="15px" borderTop="1px solid #E6E7E9">
          {/* Active Button */}
          {!isButtonDisabled && (
            <Box as="button" onClick={onClick} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
              <Box color="#1C4EC5" mr="8px" fontSize="14px" fontWeight="600">{cardFooterText}</Box>
              <Box><Image src="/assets/imgs/right-blue-arrow.png" /></Box>
            </Box>
          )}
          {/* Disabled Button */}
          {isButtonDisabled && (
            <Box as="button" disabled={true} display="flex" flexDirection="row" alignItems="center" justifyContent="center" style={{ opacity: 0.5, cursor: 'not-allowed' }}>
              <Box color="#1C4EC5" mr="8px" fontSize="14px" fontWeight="600">{cardFooterText}</Box>
            </Box>
          )}
        </CardFooter>
      )}
    </Card>
  );
}

export default DoctorCard;
