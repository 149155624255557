import Prescription from "../../components/patient-health/doctor-notes/prescription-page";
import React, { useRef, useState, useEffect } from "react";
import { Box, VStack, HStack } from "@chakra-ui/react";
import { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useLocation } from "react-router-dom";
import moment from "moment";
import {
  GetDoctorNotes,
  UploadPrescription,
} from "../../middleware/services/visits/patient-overview/updatedoctornotes";
import { PrimaryButton } from "../../components";
import Loader from "../../components/loader/loader";
import useLoader from "../../components/loader/loaderstates";
import { COLORS } from "../../components/styles/colors";
import {
  extractDiagnosis,
  formatMedicalHistory,
  transformVitalData,
  calculateAge,
  formatMedicines,
  formatOrders,
} from "../../middleware/domains/visits/patient-health/prescriptions";
import { uploadImageCommonFunction } from "../../auth";
import VisitSummary from "../../components/patient-health/doctor-notes/summary-page";
import { GetPatientHealthData } from "../../middleware/services/visits/patient-overview";

const VisitSummaryDocument = () => {
  //-------Fetch Parameters

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const patientId = params.get("patientId");
  const appointmentId = params.get("appointmentId");
  const doctorId = params.get("doctorId");
  const fromEndVisit = Boolean(params.get("fromEndVisit"));

  // console.log(fromEndVisit);

  const [doctorNotesForSummary, setDoctorNotesForSummary] = useState(null);
  const [patientHistoryForSummary, setPatientHistoryForSummary] =
    useState(null);

  const { isLoading, showLoader, dismissLoader } = useLoader();
  //--------------------

  const fetchPatientHealthData = async () => {
    const inputToFetch = {
      input: {
        patientid: patientId,
        doctorid: doctorId,
        appointmentid: null,
        // patientid: 1866,
        // doctorid: 454,
        // appointmentid: null,
      },
    };
    showLoader();
    try {
      const data = await GetPatientHealthData(inputToFetch.input);
      console.log(data);
      setPatientHistoryForSummary(data);
    } catch (error) {
      console.error("Error fetching patient health data:", error);
    } finally {
      dismissLoader();
    }
  };

  const fetchDoctorNotes = async () => {
    const inputToFetch = {
      input: {
        patientid: patientId,
        doctorid: doctorId,
        appointmentid: appointmentId,
        // patientid: 1866,
        // doctorid: 454,
        // appointmentid: 3110,
      },
    };
    showLoader();
    try {
      // Sleep for 10 seconds
      // await new Promise((resolve) => setTimeout(resolve, 15000));

      const response = await GetDoctorNotes(inputToFetch.input);
      const data = response?.output?.data;
      console.log(data);
      setDoctorNotesForSummary(data);
    } catch (error) {
      console.error("Error fetching Doctor Notes data:", error);
    } finally {
      dismissLoader();
    }
  };

  useEffect(() => {
    fetchDoctorNotes();
    fetchPatientHealthData();
  }, [patientId, doctorId, appointmentId]);

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
      @page {
        size: A4;
        margin: 10mm;
      }
      @media print {
        body {
          -webkit-print-color-adjust: exact;
          margin: 0;
        }
      }
    `,
  });

  
  

  // Update handleDownloadLocal to handle multi-page PDF
  const handleDownloadLocal = async () => {
    const input = componentRef.current;
    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
  
    let yOffset = 0;
  
    while (yOffset < input.scrollHeight) {
      const canvas = await html2canvas(input, {
        scale: 2,
        y: yOffset,  // Move canvas starting point by yOffset for each new capture
        scrollX: 0,
        scrollY: -yOffset,  // Negative scroll to start from the next part of the content
      });
  
      const imgData = canvas.toDataURL("image/png");
      const imgHeight = (canvas.height * pdfWidth) / canvas.width;
  
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, imgHeight);
  
      yOffset += pdfHeight * (canvas.width / pdfWidth); // Move down by page height
  
      if (yOffset < input.scrollHeight) {
        pdf.addPage(); // Add a new page if there’s more content
      }
    }
  
    pdf.save("multi-page-content.pdf");
  };

  //   const handleDownloadLocal = async () => {
  //     const input = componentRef.current;
  //     const canvas = await html2canvas(input, { scale: 2 });
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF({
  //       orientation: "portrait",
  //       unit: "mm",
  //       format: "a4",
  //     });
  //     const imgProps = pdf.getImageProperties(imgData);
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

  //     pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
  //     pdf.save("download.pdf");
  //   };

  //   const handleDownload = async () => {
  //     const input = componentRef.current;
  //     const canvas = await html2canvas(input, { scale: 1, useCORS: true });
  //     const imgData = canvas.toDataURL("image/jpeg", 2);
  //     const pdf = new jsPDF({
  //       orientation: "portrait",
  //       unit: "mm",
  //       format: "a4",
  //     });
  //     const imgProps = pdf.getImageProperties(imgData);
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

  //     pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);

  //     const pdfBlob = pdf.output("blob");
  //     return pdfBlob; // Return the PDF as a blob
  //   };

  return (
    <Box height="100vh" overflowY="scroll">
      {isLoading && <Loader />}
      <VStack spacing={4} justify="center" align="center" p={4}>
        {/* <HStack spacing={4}>
          <PrimaryButton
            buttonText="Print"
            variant="grayBtn"
            btnStyle={{ w: "12rem", h: "3rem", color: COLORS.PRIMARY_COLOR }}
            onClick={handlePrint}
          />
          <PrimaryButton
            buttonText="Download"
            onClick={handleDownloadLocal}
            btnStyle={{ w: "12rem", h: "3rem" }}
          />
        </HStack> */}
        <VStack align="center">
          <Box ref={componentRef} width="100%" height={"80vh"} overflow={"auto"}>
            {doctorNotesForSummary && patientHistoryForSummary && (
              <VisitSummary
                doctorNotes={doctorNotesForSummary}
                patientHistory={patientHistoryForSummary}
              />
            )}
          </Box>
        </VStack>
      </VStack>
    </Box>
  );
};

export default VisitSummaryDocument;
