import React, { useState } from "react";
import {
  Box,
  Text,
  Image,
  Circle,
  Center,
  Link,
  Divider,
  Button,
  Collapse,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import moment from "moment";

const formatDate = (dateString) => {
  if (!dateString) return "-";
  return moment(dateString).format("Do MMM, YYYY");
};

const fileTypeMappings = {
  pdf: "/assets/svgs/pdf-icon.svg",
  png: "/assets/imgs/png-icon.png",
  jpg: "/assets/imgs/jpg-icon.png",
  jpeg: "/assets/imgs/jpg-icon.png",
};

const getFileIcon = (fileName) => {
  const extension = fileName.split(".").pop().toLowerCase();
  return fileTypeMappings[extension] || "/assets/svgs/file-icon.svg"; // fallback image
};

const PastImagingTests = ({
  date,
  name,
  summary,
  normal,
  abnormal,
  interpretation,
  id,
  attachments,
  onEdit,
  onDelete,
  scaleHeight = "auto",
  componentBg = "#fcfcfd",
  cardBg = "white",
}) => {
  const [showInsights, setShowInsights] = useState(false);

  return (
    <Box
      display="flex"
      flexDir="column"
      alignItems="center"
      w="100%"
      bg={componentBg}
    >
      <Box display="flex" alignItems="center" w="100%" mt={"4px"}>
        <Circle
          size="1rem"
          bg="white"
          border="2px solid #CDCFD4"
          borderRadius="full"
          color="black"
        />
        <Text
          fontSize="12px"
          fontWeight="500"
          textAlign="left"
          color="#384052"
          ml="0.5rem"
        >
          {formatDate(date)}
        </Text>
      </Box>
      <Box display="flex" w="100%" mt="10px">
        <Center
          w="100%"
          h="auto"
          ml="0.45rem"
          alignItems="start"
          borderLeft="2px solid #E6E7E9"
        >
          <Box w="100%">
            <Box
              p="20px"
              w="100%"
              ml="10px"
              display="flex"
              flexDirection="column"
              alignItems="start"
              justifyContent="space-between"
              border="1px solid #E6E7E9"
              borderRadius="12px"
              backgroundColor={cardBg}
            >
              <Box display="flex" alignItems="center" w="100%">
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  flex="1"
                >
                  <Text
                    title={name}
                    fontSize="16px"
                    textAlign="left"
                    fontWeight="600"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {name}
                  </Text>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" w="100%">
                <Box
                  mt={"3px"}
                  display="flex"
                  alignItems="center"
                  backgroundColor={"#f9fafb"}
                  borderLeft={"3px solid #14c585"}
                  w="100%"
                >
                  <Text
                    fontSize="14px"
                    fontWeight="500"
                    color="#384052"
                    pl={"4px"}
                  >
                    {summary}
                  </Text>
                </Box>
              </Box>
              <Divider my="10px" />

              <Button
                onClick={() => setShowInsights(!showInsights)}
                rightIcon={
                  showInsights ? <ChevronUpIcon /> : <ChevronDownIcon />
                }
                fontWeight="500"
                backgroundColor="#f9f9f9"
                border="1px solid #E6E7E9"
                borderRadius="8px"
                _hover={{ bg: "#e2e8f0" }}
                px="15px"
                py="10px"
                mb="10px"
              >
                <Image w="26px" src="/assets/svgs/ai-star-outline.svg" />
                Aira Insights
              </Button>
              <Collapse in={showInsights} animateOpacity>
                <Box
                  p="15px"
                  border="1px solid #E6E7E9"
                  borderRadius="8px"
                  bg="#f9fafb"
                >
                  <Text
                    fontSize="14px"
                    fontWeight="600"
                    color="#2b6cb0"
                    mb="4px"
                  >
                    Interpretation: {interpretation || "N/A"}
                  </Text>
                  <Text
                    fontSize="14px"
                    fontWeight="600"
                    color="#e53e3e"
                    mb="4px"
                  >
                    Abnormal: {abnormal || "N/A"}
                  </Text>
                  <Text fontSize="14px" fontWeight="600" color="#38a169">
                    Normal: {normal || "N/A"}
                  </Text>
                </Box>
              </Collapse>

              <Divider my="10px" />
              <Box display="flex" w="100%" flexWrap="wrap">
                {attachments.map((attachment, index) => (
                  <Box
                    key={index}
                    display="flex"
                    alignItems="center"
                    border="1px solid #E6E7E9"
                    borderRadius="8px"
                    p="5px 10px"
                    mr="10px"
                    backgroundColor="white"
                  >
                    <Box bg="#f9f9f9" p="5px" borderRadius="8px">
                      <Image src={getFileIcon(attachment.name)} w="16px" />
                    </Box>
                    <Link
                      href={attachment.link}
                      isExternal
                      fontSize="14px"
                      color="#2B6CB0"
                      ml="5px"
                    >
                      {attachment.name}
                    </Link>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Center>
      </Box>
    </Box>
  );
};

export default PastImagingTests;
