export default function formAPITotalRevenueView({
    patientname,
    patientimage,
    email,
    mobileno,
    doctorid,
    amount,
    paymenttype,
    paymentdate,
    imagepath
    
  }) {
   
  
    return Object.freeze({
        patientname,
        patientimage,
        email,
        mobileno,
        doctorid,
        amount,
        paymenttype,
        paymentdate,
        imagepath
    });
  }
  