const COLORS = {
  PRIMARY_COLOR: "#151619",
  SECONDARY_COLOR: "#3B5CEF",
  PRIMARY_WHITE: "#FFFFFF",
  PRIMARY_GRADIENT:
    "radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%), #151619",
  INPUT_PRIMARY: "#CDCFD4",
  INPUT_FOCUS_DARK: "#384052",
  INPUT_ERROR: "#CA3A31",
  INPUT_SELECT: "#109E6A",
  INPUT_PLACEHOLDER: "#6A707D",
  BTN_DISABLE: "#EAEDF5",
  BTN_DISABLE_TEXT: "#A4B8E8",
  BTN_GRADIENT_DARK: "rgba(255, 255, 255, 0.07)",
  BOX_SHADOW_LIGHT:
    "0px 0px 0px 3px rgba(230, 231, 233, 0.30), 0px 1px 2px 0px rgba(193, 194, 198, 0.50), 0px 8px 12px 0px rgba(216, 216, 216, 0.20) inset",
  TOASTR_BG:
    "radial-gradient(100% 100% at 50% 0%, rgba(18, 18, 18, 0.77) 0%, rgba(18, 18, 18, 0.90) 100%)",
  SUCCESS_BG:
    "linear-gradient(0deg, rgba(153,192,188,1) 0%, rgba(240,248,247,1) 70%)",
  ERROR_BG:
    "linear-gradient(0deg, rgba(217,152,148,1) 0%, rgba(255,255,255,1) 100%)",
  TOASTR_ICON_SUCCESS: "linear-gradient(180deg, #68D1BF 0%, #35746E 100%)",
  TOASTR_ICON_BG_SUCCESS:
    "linear-gradient(0deg, rgba(153,192,188,1) 0%, rgba(240,248,247,1) 70%)",
  TOASTR_ICON_ERROR:
    "linear-gradient(0deg, rgba(186,81,74,1) 0%, rgba(228,117,113,1) 70%)",
  TOASTR_ICON_BG_ERROR:
    "linear-gradient(0deg, rgba(217,152,148,1) 0%, rgba(255,255,255,1) 100%)",
  PROGRESS_DARK: "#0C7650",
  PROGRESS_LIGHT: "#14C585",
  DROPDOWN: "1C4EC5",
  GOLD_BADGE: "linear-gradient(180deg, #E6B22B 0%, #EBCC66 0.01%, #DA0 100%)",
  GOLD_REWARD:
    "linear-gradient(0deg, rgba(252,206,53,0) 85%, rgba(252,206,53,1) 110%)",
  BRONZE_REWARD:
    "linear-gradient(0deg, rgba(225,134,53,0) 85%, rgba(225,134,53,1) 110%)",
  SILVER_REWARD:
    "linear-gradient(0deg, rgba(174,189,194,0) 85%, rgba(174,189,194,1) 110%)",
  PLATINUM_REWARD:
    "linear-gradient(0deg, rgba(138,163,226,0) 85%, rgba(138,163,226,1) 110%)",
  PRO_PLAN:
    "linear-gradient(0deg, rgba(67,209,157,0) 75%, rgba(67,209,157,1) 100%)",
  SIGNUP_SUCCESS:
    "linear-gradient(180deg, rgba(103, 202, 185, 0.6) 0%, rgba(252, 252, 253, 0) 85.36%)",
  BOX_SHADOW_SUCCESS:
    "0px -4.488888263702393px 8.977776527404785px 0px #FFFFFF80 inset; box-shadow: 0px 0px 0px 2.2444441318511963px #0000001A inset",
  SHARE_BADGE_GRADIENT:
    "linear-gradient(257.86deg, #14C585 -1.55%, #1C4EC5 76.88%)",
  GRADIENT_WHITE:
    "linear-gradient(310deg, rgba(255,255,255,0) 20%, rgba(255,255,255,0.9976365546218487) 40%)",
  BORDER_GRADIENT:
    "radial-gradient(circle at 100% 100%, #ffffff 0, #ffffff 3px, transparent 3px) 0% 0%/8px 8px no-repeat, radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 3px, transparent 3px) 100% 0%/8px 8px no-repeat, radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 3px, transparent 3px) 0% 100%/8px 8px no-repeat, radial-gradient(circle at 0 0, #ffffff 0, #ffffff 3px, transparent 3px) 100% 100%/8px 8px no-repeat, linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 10px) calc(100% - 16px) no-repeat, linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 16px) calc(100% - 10px) no-repeat, linear-gradient(transparent 0%, #14C585 100%)",
  TOASTR_ICON_ALERT: "linear-gradient(180deg, #FFECB3 0%, #FFC107 100%)",
  TOASTR_ICON_BG_ALERT:
    "linear-gradient(0deg, rgba(255,236,179,0.3) 0%, rgba(255,193,7,0.3) 100%)",
  //   TOASTR_ICON_ALERT: "linear-gradient(180deg, #F9E79F 0%, #F7DC6F 100%)",
  //   TOASTR_ICON_BG_ALERT:
  //     "linear-gradient(0deg, rgba(153,192,188,1) 0%, rgba(240,248,247,1) 70%)",
  //   TOASTR_ICON_BG_ALERT:
  //     "linear-gradient(0deg, rgba(249,231,159,0.3) 0%, rgba(247,220,111,0.3) 100%)",
  //   TOASTR_BG_ALERT:
  //     "linear-gradient(0deg, rgba(255,243,205,1) 0%, rgba(255,255,225,1) 100%)",
  TOASTR_BG_ALERT:
    "linear-gradient(0deg, rgba(153,192,188,1) 0%, rgba(240,248,247,1) 70%)",
  FILTER_BLACK: "invert(50%) sepia(11%) saturate(374%) hue-rotate(183deg) brightness(90%) contrast(103%)",
  FILTER_WHITE:"brightness(0) saturate(100%) invert(100%) sepia(81%) saturate(6782%) hue-rotate(181deg) brightness(130%) contrast(105%)"
};

export { COLORS };
