import formapiorderdetails from "./orderdetail";


var formattedOutput = null;

const formapimodalorderdetails = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formapiorderdetails(item));


  return formattedOutput
};

export default formapimodalorderdetails