import {
  Box,
  HStack,
  Link,
  Button,
  Text,
  Spacer,
  Image,
  Center,
  Flex,
  Grid,
  GridItem,
  Divider,
  VStack,
  Circle,
  Step,
  StepIndicator,
  StepTitle,
  StepDescription,
  StepSeparator,
  Stepper,
  SimpleGrid,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import Footer from "../../components/footer";
import { doctorWebDetails } from "../../middleware/domains/doctorwebsite";
import moment from "moment";
import { Navigate, useNavigate } from "react-router-dom";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import { CONFIG } from "../../appconfig/appconfig";
import { useIsIOS } from "../../auth";
import PrimaryButton from '../../components/primary-button'
import { SwiperSlide, Swiper } from "swiper/react";

const DoctorWebsite = () => {
  const [data, setData] = useState(null);
  const [activeLink, setActiveLink] = useState(null);
  const [perosonalDetails, setPerosonalDetails] = useState(null);
  const [contactDetails, setContactDetails] = useState(null);
  const [qualificationDetails, setQualificationDetails] = useState([]);
  const [achievementsDetails, setAchievementsDetails] = useState([]);
  const [servicesDetails, setServicesDetails] = useState(null);
  const [addressDetails, setAddressDetails] = useState(null);
  const [availabilityDetails, setAvailabilityDetails] = useState(null);
  const [blogsDetails, setBlogsDetails] = useState(null);
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(false);
  const [week, setWeek] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [navigatedate, setNavigateDate] = useState(null);
  const [doctorId, setDoctorId] = useState();
  const swiperSlot = useRef()
  const isIOS = useIsIOS()

  const navigate = useNavigate()
  // API Calling
  useEffect(() => {
    showLoader();
    const currentURL = window.location.href;
    const url = new URL(currentURL);
    const uniqueId = url.searchParams.get("id");

    // Check if the ID parameter is present
    if (!uniqueId) {
      console.error('ID parameter is missing in the URL');
      return;
    }

    // Remove the additional text from URL
    if (window.location.hash) {
      url.hash = '';
      window.history.replaceState({}, '', url.toString());
    }

    var input = {
      doctoruniqueid: uniqueId
    }
    const fetchData = async () => {
      showLoader()
      try {
        var result = await doctorWebDetails(input);
        if (result.output) {
          setData(result.output)
          setPerosonalDetails(result.output.resultpersonaldetails)
          setContactDetails(result.output.resultcontactdetails)
          setAchievementsDetails(result.output.resultachievements)
          setServicesDetails(result.output.resultservicesandprocedures)
          setAddressDetails(result.output.resultaddressdetail)
          setAvailabilityDetails(result.output.resultavailability)
          setBlogsDetails(result.output.resultblogs)
          setDoctorId(result.output.resultpersonaldetails.doctorid)
          // splitting qualifiction into array
          var qualifications = result.output.resultqualifications.qualifications.split(',')
          setQualificationDetails(qualifications)
        }
        dismissLoader();
      } catch (error) {
        console.log(error, 'something wrong in doctor info')
      }
    }

    fetchData();
  }, [])

  useEffect(() => {
    setWeek(generateDatesToYearEnd());
  }, []);
  const generateDatesToYearEnd = () => {
    const today = new Date();
    const yearEnd = new Date(today.getFullYear(), 11, 31); // December 31 of the current year
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dates = [];

    for (let d = today; d <= yearEnd; d.setDate(d.getDate() + 1)) {
        const day = daysOfWeek[d.getDay()]; // Get the current day name
        dates.push({
            day: day,
            date: new Date(d) // Store the date object for formatting later if needed
        });
    }
    const daysOfWeekDefault = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday','Sunday'];
    setSelectedDay(daysOfWeekDefault[today.getDay()]);
    const formattedDate = formatDate_DD_MM(new Date(today))
    setSelectedDate(formattedDate);
    return dates;
};

  const formatTime = (dateString) => {
    if (!dateString) return "-";
    return moment(dateString, 'HH:mm:ss').format('h:mm A'); // Adjust format to match your needs
  };
  const formatTime24Hours = (dateString) => {
    if (!dateString) return "-";
    return moment(dateString, 'HH:mm:ss').format('HH:mm'); // 24-hour format without AM/PM
  };

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    return moment(dateString).format("Do MMM, YYYY");
  };

  const formatDate_DD_MM = (dateString) => {
    if (!dateString) return "-";
    return moment(dateString).format("ddd, D MMM");
  };



  const handleDayClick = (day, date) => {
    const formattedDate = formatDate_DD_MM(date)
    setSelectedDay(day);
    setSelectedDate(formattedDate);
    const navigatedate = moment(date).format("YYYY-MM-DD")
    setNavigateDate(navigatedate)
    setSelectedSlot(false)
  };

  const getSlotCount = (day) => {
    const slots = data?.slots[day.toLowerCase()];
    return slots ? slots.length : 0;
  };

  const renderSlots = (day) => {
    const slots = data?.slots[day.toLowerCase()];
    const currentTime = moment(); // Get current time

    if (!slots || slots.length === 0) {
        return (
            <Box textAlign="center" p="1rem" w="full" bg="white">
                <Text fontSize="0.875rem" color="gray.800">No slots available on this day</Text>
            </Box>
        );
    }

    return (
        <SimpleGrid columns={{ base: 2, md: 4, lg: 6 }} spacing="1rem" pr='1rem' maxH='150px' overflow='hidden auto'>
            {slots.map((slot, index) => {
                const slotTime = moment(slot.starttime, 'h:mm A'); // Parse the slot's start time
                const isToday = day.toLowerCase() === moment().format('dddd').toLowerCase(); // Check if the day is today
                const isPassed = isToday && slotTime.isBefore(currentTime, 'minute'); // Check if the slot time has passed today
                const isSelected = selectedSlot && moment(selectedSlot.slottime, 'h:mm A').format('HH:mm:ss') === slot.starttime;

                return (
                    <Box
                        key={index}
                        as="button"
                        textAlign="center"
                        onClick={!isPassed ? () => slotSelection(slot.day, slot.starttime) : null} // Disable click if the slot is passed
                        p="0.5rem"
                        bg={isSelected ? '#EAEDF5' : isPassed ? 'gray.300' : 'white'} // Change background if passed
                        border={isSelected ? '2px solid #1C4EC5' : isPassed ? '1px solid gray.400' : '1px solid #EAEDF5'}
                        borderRadius="md"
                        cursor={isPassed ? 'not-allowed' : 'pointer'} // Change cursor style if passed
                        opacity={isPassed ? 0.6 : 1} // Reduce opacity for disabled slots
                    >
                        <Text fontSize="0.875rem" color={isPassed ? 'gray.500' : 'gray.800'}>
                            {slot.starttime ? formatTime(slot.starttime) : 'N/A'}
                        </Text>
                    </Box>
                );
            })}
        </SimpleGrid>
    );
};
  // Handle functions
  const handleLinkClick = (section) => {
    setActiveLink(section);
  };

  const content = [
    {
      text: "Did you know that tooth enamel is the hardest substance in the human body? It’s even stronger than bones! Enamel is the outermost layer of your teeth and protects them from decay. Despite its strength, enamel can still be eroded by acidic foods and drinks, so it’s important to maintain good oral hygiene.",
      date: "10th Jan, 2023",
    },
    {
      text: "The earliest known toothpaste was used by the Egyptians around 5000 BC. It was made of crushed eggshells, ashes, and pumice. These ancient recipes might sound strange today, but they were surprisingly effective at cleaning teeth and freshening breath.",
      date: "10th Jan, 2023",
    },
    {
      text: "An average person produces around 25,000 quarts of saliva in their lifetime. That’s enough to fill two swimming pools! Saliva plays a crucial role in oral health by neutralizing acids, washing away food particles, and providing disease-fighting substances throughout your mouth. Without it, we would be at a higher risk of tooth decay and gum disease.",
      date: "10th Jan, 2023",
    },
    {
      text: "Just like fingerprints, everyone’s tongue print is unique. Dentists can even use them for identification purposes. This uniqueness is due to the individual shapes and textures of the tiny bumps and grooves on the tongue’s surface. Dental records, including tongue prints, can be invaluable in forensic investigations.",
      date: "10th Jan, 2023",
    },
  ];

  const imageUrls = [
    "/assets/imgs/img-1.png",
    "/assets/imgs/img-2.jpg",
    "/assets/imgs/img-3.jpg",
    "/assets/imgs/img-4.jpg",
    "/assets/imgs/img-5.jpg",
    "/assets/imgs/img-6.jpg",
    "/assets/imgs/img-7.jpg",
    "/assets/imgs/img-8.png",
    "/assets/imgs/img-9.png",
    "/assets/imgs/img-10.jpg",
  ];

  const getRandomHeight = () => {
    const minHeight = 180;
    const maxHeight = 360;
    return Math.floor(Math.random() * (maxHeight - minHeight + 1)) + minHeight;
  };

  const slotSelection = (slotDay, slotTime, slotDate) => {
    const slottime = formatTime(slotTime)
    const slottimeformatted = formatTime24Hours(slotTime)
    const slotday = slotDay.slice(0, 3)
    setSelectedSlot({ slotday, slottime, slotDate,slottimeformatted  })
    
  }
  const generateCurrentWeek = () => {
    const today = new Date();
    const currentDay = today.getDay();
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const week = [];

    for (let i = 0; i < 7; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() - currentDay + i);
      week.push({
        day: daysOfWeek[i],
        date: date // Format date as needed
      });
    }

    return week;
  };
  const handleSlot = (direction) => {
    if (swiperSlot.current && swiperSlot.current.swiper) {
      const swiper = swiperSlot.current.swiper;
      if (direction === "prev" && swiper.slidePrev) {
        swiper.slidePrev();
      } else if (direction === "next" && swiper.slideNext) {
        swiper.slideNext();
      }
    }
  };


  //CSS for mobile navbar
  const darkBtnCSS = `
  .darkBtn {
    filter: brightness(0) saturate(100%) invert(43%) sepia(12%) saturate(456%) hue-rotate(183deg) brightness(96%) contrast(83%);
  }

  .lightBtn {
    color: currenColor;
  }

  .pendingVisits {
    width: 100%;
  }

  .pendingVisits .swiper-container{
    height: 100%; 
    max-height: 100vw;
    min-height: 0;
    min-width: 0;
    max-width: 100vw; 
    width: 100%;
    overflow: hidden;
  }
  
  .pendingVisits .swiper-slide{
    width: 90px!important;
    flex-shrink: 0;
    display: block;
    height: 100%;
    max-height: 100%;
  }
  
  .pendingVisits .swiper {
    margin: 0!important;
  }
  
  .pendingVisits .swiper-wrapper{
    max-height: 100%;
    height: 100%t;
    display: flex;
    gap: 20px;  
  }
`
  return (
    <>
        {isLoading && <Loader />}
      <style>{darkBtnCSS}</style>
      <Box w="100%" h={{ base: "calc(100vh - 120px)", lg: "100vh" }} pb={isIOS ? '2rem' : ""} overflow="hidden auto" bg="#FAFAFA">
        {data && data.message && data.message == 'link is expired' ? (
          <>
            <Box
              display="flex"
              flexDir="column"
              gap="30px"
              justifyContent="center"
              alignItems="center"
              h="100vh"
              overflow="hidden"
            >
              <Image w="200px" h="200px" src="/assets/imgs/failed-icon-red.png" />
              <Text as="h1" fontSize="28px" fontWeight="600">
                The Link has Expired
              </Text>
            </Box>
          </>
        ) : (
          <>
            <Box>
              {/* Navbar section start */}
              <Box
                bg="#050D1F"
                px="1rem"
                py="1rem"
                borderRadius="full"
                position="sticky"
                top="1rem"
                zIndex="1000"
                w="fit-content"
                mx="auto"
                my="1rem"
              >
                <HStack spacing={8} alignItems="center" justifyContent="space-between">
                  <Box as="a" href="https://midoc.ai/" target="_blank">
                    <Image src="/assets/svgs/midoc-white.svg" alt="Midoc logo" h="2.5rem" />
                  </Box>
                  <HStack as="nav" spacing={4} display={{ base: 'none', md: 'flex' }} color="#6A707D">
                    <Link
                      fontWeight="600"
                      href="#profileSection"
                      color={activeLink === 'profile' ? '#FCFCFD' : '#6A707D'}
                      onClick={() => handleLinkClick('profile')}
                    >
                      Profile
                    </Link>
                    <Link
                      fontWeight="600"
                      href="#contactSection"
                      color={activeLink === 'contact' ? '#FCFCFD' : '#6A707D'}
                      onClick={() => handleLinkClick('contact')}
                    >
                      Contact
                    </Link>
                    <Link
                      fontWeight="600"
                      href="#qualificationSection"
                      color={activeLink === 'qualifications' ? '#FCFCFD' : '#6A707D'}
                      onClick={() => handleLinkClick('qualifications')}
                    >
                      Qualifications
                    </Link>
                    <Link
                      fontWeight="600"
                      href="#servicesSection"
                      color={activeLink === 'services' ? '#FCFCFD' : '#6A707D'}
                      onClick={() => handleLinkClick('services')}
                    >
                      Services
                    </Link>
                    <Link
                      fontWeight="600"
                      href="#availSection"
                      color={activeLink === 'availability' ? '#FCFCFD' : '#6A707D'}
                      onClick={() => handleLinkClick('availability')}
                    >
                      Availability
                    </Link>
                  </HStack>
                  <Button
                    bg="white"
                    as="a"
                    // target="_blank"
                    p="1rem"
                    boxShadow="0px 0px 0px 3px #E6E7E94D, 0px 1px 2px 0px #C1C2C680, 0px -8px 12px 0px #D8D8D833 inset"
                    color="#050D1F"
                    borderRadius="full"
                    _hover={{ bg: '#e0e0e0' }}
                    display={{ base: 'none', md: 'flex' }}
                    href="#availSection"
                    cursor="pointer"
                  >
                    Book now
                  </Button>
                </HStack>
              </Box>

              {/* Mobile navbar start */}
              <Box pos="relative">
                <Box as="nav" pos="fixed" zIndex="9999" alignItems="center" justifyContent="center" p="0.5rem" bottom="0" left="0" right="0" bg="#050D1F" spacing={4} display={{ base: 'flex', md: 'none' }}>
                  <Link
                    display="flex"
                    flexDir="column"
                    alignItems="center"
                    p="0.5rem"
                    fontSize="0.75rem"
                    fontWeight="600"
                    href="#profileSection"
                    color={activeLink === 'profile' ? '#FCFCFD' : '#6A707D'}
                    onClick={() => handleLinkClick('profile')}
                  >
                    <Image src='/assets/svgs/contact-white.svg' w="1.5rem" h="1.25rem" className={activeLink == 'profile' ? 'lightBtn' : 'darkBtn'} />
                    <Text letterSpacing="-0.03em">Profile</Text>
                  </Link>
                  <Link
                    display="flex"
                    flexDir="column"
                    alignItems="center"
                    p="0.5rem"
                    fontSize="0.75rem"
                    fontWeight="600"
                    href="#contactSection"
                    color={activeLink === 'contact' ? '#FCFCFD' : '#6A707D'}
                    onClick={() => handleLinkClick('contact')}
                  >

                    <Image src='/assets/svgs/contact-white.svg' w="1.5rem" h="1.25rem" className={activeLink == 'contact' ? 'lightBtn' : 'darkBtn'} />
                    <Text letterSpacing="-0.03em">Contact</Text>
                  </Link>
                  <Link
                    display="flex"
                    flexDir="column"
                    alignItems="center"
                    p="0.5rem"
                    fontSize="0.75rem"
                    fontWeight="600"
                    href="#qualificationSection"
                    color={activeLink === 'qualifications' ? '#FCFCFD' : '#6A707D'}
                    onClick={() => handleLinkClick('qualifications')}
                  >

                    <Image src="/assets/svgs/qualification-white.svg" w="1.5rem" h="1.25rem" className={activeLink == 'qualifications' ? 'lightBtn' : 'darkBtn'} />
                    <Text letterSpacing="-0.03em">Qualifications</Text>
                  </Link>
                  <Link
                    display="flex"
                    flexDir="column"
                    alignItems="center"
                    p="0.5rem"
                    fontSize="0.75rem"
                    fontWeight="600"
                    href="#servicesSection"
                    color={activeLink === 'services' ? '#FCFCFD' : '#6A707D'}
                    onClick={() => handleLinkClick('services')}
                  >

                    <Image src="/assets/svgs/services-white.svg" w="1.5rem" h="1.25rem" className={activeLink == 'services' ? 'lightBtn' : 'darkBtn'} />
                    <Text letterSpacing="-0.03em">Services</Text>
                  </Link>
                  <Link
                    display="flex"
                    flexDir="column"
                    alignItems="center"
                    p="0.5rem"
                    fontSize="0.75rem"
                    fontWeight="600"
                    href="#availSection"
                    color={activeLink === 'availability' ? '#FCFCFD' : '#6A707D'}
                    onClick={() => handleLinkClick('availability')}
                  >

                    <Image src="/assets/svgs/timer-white.svg" w="1.5rem" h="1.25rem" className={activeLink == 'availability' ? 'lightBtn' : 'darkBtn'} />
                    <Text letterSpacing="-0.03em">Availability</Text>
                  </Link>
                </Box>
              </Box>
              {/* Mobile navbar end */}

              {/* Navbar section end */}

              {/* Doctor hero section start */}
              <Box
                bgImage="url('/assets/imgs/white-bg-boxes.png')"
                bgSize="cover"
                bgPosition="center"
                m={{ base: "1rem", lg: "1rem 2rem 3rem 2rem" }}
                id="profileSection"
              >
                <Center>
                  <Box
                    maxW="50rem"
                    w="full"
                    bg="white"
                    boxShadow="md"
                    borderRadius="0.75rem"
                    overflow="hidden"
                    border="1px solid #E6E7E9"
                    p="1rem"
                  >
                    <Center pt="1rem">
                      <Image
                        src={perosonalDetails && perosonalDetails.profilepictureurl ? perosonalDetails.imagepath + perosonalDetails.profilepictureurl : "/assets/imgs/no-image.png"}
                        alt="Doctor Profile"
                        boxSize="6.25rem"
                        borderRadius="full"
                        border="0.1875rem solid white"
                        objectFit="cover"
                      />
                    </Center>
                    <Box pt="1.5rem" textAlign="center">
                      <Text fontSize="2rem" fontWeight="600" color="#6A707D">
                        Hello!
                      </Text>
                      <Text
                        mt="0.5rem"
                        fontSize="2rem"
                        fontWeight="bold"
                        color="#061027"
                      >
                        I'm {perosonalDetails && perosonalDetails.firstname ? perosonalDetails.firstname : ''} {perosonalDetails && perosonalDetails.lastname ? perosonalDetails.lastname : ''}
                      </Text>
                      <Text fontSize="2rem" color="#061027">
                        & I'm a {perosonalDetails && perosonalDetails.specality}
                      </Text>
                      <Text mt="0.5rem" fontSize="0.875rem" color="#384052">
                        {perosonalDetails && perosonalDetails.qualification}
                      </Text>

                      <Box
                        bg="#FAFAFA"
                        mt="15px"
                        borderRadius="0"
                        p="1rem"
                        display="flex"
                        justifyContent="space-around"
                      >
                        {/* <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Image
                            w="18px"
                            src="/assets/svgs/star-blue.svg"
                            mr="5px"
                          />
                          <Text color="#384052" fontSize="14px" fontWeight="500">
                            {perosonalDetails ? perosonalDetails.ratings : "-"}
                          </Text>
                        </Box>
                        <Box as="span" border="1px solid #E6E7E9"></Box> */}
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Image
                            w="18px"
                            src="/assets/svgs/pricing-blue.svg"
                            mr="5px"
                          />
                          <Text color="#384052" fontSize="14px" fontWeight="500">
                            ${perosonalDetails ? perosonalDetails.consultationfee + '.00' : '-'}
                          </Text>
                        </Box>
                        <Box as="span" border="1px solid #E6E7E9"></Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Image
                            w="18px"
                            src="/assets/svgs/work-blue-filled.svg"
                            mr="5px"
                          />
                          <Text color="#384052" fontSize="14px" fontWeight="500">
                            {perosonalDetails && perosonalDetails.yearsofexperience}+ yrs
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Center>
              </Box>
              {/* Doctor hero section end */}

              {/* Details section start */}
              <Box w="100%" maxW="50rem" mx="auto">

                {/* Contact section start */}
                <Box w="100%" p="1rem" scrollMarginTop='75px' id="contactSection">
                  <Box display="flex" gap="0.5rem" mb="1rem" alignItems="center">
                    <Box
                      boxSize="2.25rem"
                      bg="#1C4EC5"
                      p="0.675rem"
                      borderRadius="0.675rem"
                    >
                      <Image src="/assets/svgs/contact-white.svg" />
                    </Box>
                    <Text fontSize="1.25rem" fontWeight="600">
                      Contact
                    </Text>
                  </Box>
                  <Box
                    w="full"
                    bg="white"
                    boxShadow="sm"
                    borderRadius="0.75rem"
                    p="1.5rem"
                    display="flex"
                    flexDir="column"
                    gap="1rem"
                  >
                    <Box
                      display="flex"
                      boxSize="1rem"
                      w="100%"
                      alignItems="center"
                      mb="0.5rem"
                    >
                      <Image
                        src="/assets/svgs/telephone.svg"
                        alt="Phone Icon"
                        boxSize="1rem"
                        mr="1rem"
                      />
                      <Text fontSize="1rem" color="gray.600" fontWeight="500">
                        {/* {contactDetails && contactDetails.mobileno} */}
                        {`${contactDetails?.countrycode ? (contactDetails?.countrycode): ('')}${"  "}${contactDetails?.mobileno}`}
                      </Text>
                    </Box>
                    <Box display="flex" boxSize="1rem" w="100%" alignItems="center">
                      <Image
                        src="/assets/svgs/email.svg"
                        alt="Email Icon"
                        boxSize="1rem"
                        mr="1rem"
                      />
                      <Text fontSize="1rem" color="gray.600" fontWeight="500">
                        {contactDetails && contactDetails.email}
                      </Text>
                    </Box>
                  </Box>
                </Box>
                {/* Contact section end */}

                {/* Qualification section start */}
                <Box w="100%" p="1rem" scrollMarginTop='75px' id="qualificationSection">
                  <Box display="flex" gap="0.5rem" mb="1rem" alignItems="center">
                    <Box
                      boxSize="2.25rem"
                      bg="#1C4EC5"
                      p="0.675rem"
                      borderRadius="0.675rem"
                    >
                      <Image src="/assets/svgs/qualification-white.svg" />
                    </Box>
                    <Text fontSize="1.25rem" fontWeight="600">
                      Qualifications
                    </Text>
                  </Box>
                  <Box
                    w="full"
                    bg="white"
                    boxShadow="sm"
                    borderRadius="0.75rem"
                    p="1.5rem"
                    display="flex"
                    flexDir="column"
                    gap="1rem"
                  >
                    {qualificationDetails && qualificationDetails.map((item, index) => (
                      <Box
                        display="flex"
                        boxSize="1rem"
                        w="100%"
                        alignItems="center"
                        mb="0.5rem"
                        key={index}
                      >
                        <Image
                          src="/assets/svgs/check.svg"
                          alt="Phone Icon"
                          boxSize="1rem"
                          mr="1rem"
                        />
                        <Text fontSize="1rem" color="gray.600" fontWeight="500">
                          {item}
                        </Text>
                      </Box>
                    ))}

                  </Box>
                </Box>
                {/* Qualification section end */}

                {/* Achievements section start */}
                {achievementsDetails && (
                  <Box w="100%" p="1rem">
                    <Box display="flex" gap="0.5rem" mb="1rem" alignItems="center">
                      <Box
                        boxSize="2.25rem"
                        bg="#1C4EC5"
                        p="0.675rem"
                        borderRadius="0.675rem"
                      >
                        <Image src="/assets/svgs/achievements-white.svg" />
                      </Box>
                      <Text fontSize="1.25rem" fontWeight="600">
                        Achievements
                      </Text>
                    </Box>
                    <Box
                      w="full"
                      bg="white"
                      boxShadow="sm"
                      borderRadius="0.75rem"
                      p="1.5rem"
                      display="flex"
                      flexDir="column"
                      gap="1rem"
                    >
                      {achievementsDetails && achievementsDetails.map((step, index) => (
                        <Box
                          display="flex"
                          boxSize="1rem"
                          w="100%"
                          alignItems="center"
                          mb="0.5rem"
                          key={index}
                        >
                          <Image
                            src="/assets/svgs/check.svg"
                            alt="Phone Icon"
                            boxSize="1rem"
                            mr="1rem"
                          />
                          <Text fontSize="1rem" color="gray.600" fontWeight="500">
                            {step.achievement}
                          </Text>
                        </Box>
                      ))}

                    </Box>
                  </Box>
                )}

                {/* Achievements section end */}


                {/* Services section start */}
                <Box w="100%" p="1rem" scrollMarginTop='75px' id="servicesSection">
                  <Box display="flex" gap="0.5rem" mb="1rem" alignItems="center">
                    <Box
                      boxSize="2.25rem"
                      bg="#1C4EC5"
                      p="0.675rem"
                      borderRadius="0.675rem"
                    >
                      <Image src="/assets/svgs/services-white.svg" />
                    </Box>
                    <Text fontSize="1.25rem" fontWeight="600">
                      Services & procedures
                    </Text>
                  </Box>
                  <Box
                    w="100%"
                    bg="white"
                    boxShadow="sm"
                    borderRadius="0.75rem"
                    p="1.5rem"
                    display="flex"
                    flexWrap="wrap"
                    flexDir={{ base: "column", lg: "row" }}
                  >
                    {servicesDetails && servicesDetails.map((item, index) => (
                      <Box
                        display="flex"
                        alignItems="center"
                        w={{ base: "100%", lg: "50%" }}
                        mb="0.75rem"
                        key={index}
                      >
                        <Image
                          src="/assets/svgs/check.svg"
                          alt="Checkmark"
                          boxSize="1rem"
                          mr="1rem"
                        />
                        <Text fontSize="1rem" color="gray.600">
                          {item.servicename}
                        </Text>
                      </Box>
                    ))}
                  </Box>
                </Box>
                {/* Services section end */}

                {/* Address section start */}
                <Box w="100%" p="1rem" scrollMarginTop='75px' id="availSection">
                  <Box display="flex" gap="0.5rem" mb="1rem" alignItems="center">
                    <Box
                      boxSize="2.25rem"
                      bg="#1C4EC5"
                      p="0.675rem"
                      borderRadius="0.675rem"
                    >
                      <Image src="/assets/svgs/timer-white.svg" />
                    </Box>
                    <Text fontSize="1.25rem" fontWeight="600">
                      Address & availability
                    </Text>
                  </Box>
                  <Box
                    w="full"
                    bg="white"
                    boxShadow="sm"
                    rounded="lg"
                    p="1.5rem"
                  >
                    <Flex alignItems="center" mb="1rem">
                      <Box display="flex" justifyContent="center" mr="0.75rem" alignItems="center" boxSize="2.625rem" border="1px solid #E6E7E9" borderRadius="full">
                        <Image
                          src="/assets/imgs/location_on.png"
                          alt="Location Icon"
                          w="13px"
                          h="16px"
                        />
                      </Box>
                      <Box w='100%'>
                        <Text fontSize="1rem" fontWeight="bold" color="gray.800">
                          {perosonalDetails && perosonalDetails.hospitalname}
                        </Text>
                        <Text fontSize="0.875rem" color="gray.600" w="90%">
                        {addressDetails?.address || addressDetails?.city || addressDetails?.state || addressDetails?.postalcode || addressDetails?.country ? (
                                             <>
                          {addressDetails?.address && `${addressDetails?.address}, `}
                          {addressDetails?.city && `${addressDetails?.city}, `}
                          {addressDetails?.state && `${addressDetails?.state}, `}
                          {addressDetails?.postalcode && `${addressDetails?.postalcode}, `}
                          {addressDetails?.country && `${addressDetails?.country} `}
                          </>
                                           ) : '-' }
                        </Text>
                      </Box> 
                    </Flex>
                    <Divider mb="1rem" />
                    <Flex w="100%" mb="1rem" gap="0.5rem">
                      <Box
                        as="button"
                        onClick={() => handleSlot("prev")}
                      >
                        <Image w="10px" opacity='0.5' transform='rotate(180deg)' src="/assets/svgs/arrow-icon.svg" />
                      </Box>
                      <Swiper
                          onSwiper={handleSlot}
                          slidesPerView={6}
                          ref={swiperSlot}
                          className="pendingVisits"
                          style={{ position: 'relative', zIndex: 1 }}
                        >
                        {availabilityDetails && week?.map((item, index) => (
                          <SwiperSlide style={{ position: "relative", zIndex: -1 }}>
                            <Box
                              key={index}
                              as="button"
                              textAlign="center"
                              p="0.75rem 1rem"
                              bg={selectedDay === item.day ? 'EAEDF5' : 'white'}
                              border={selectedDay === item.day ? '2px solid #1C4EC5' : '1px solid #EAEDF5'}
                              borderRadius="0.625rem"
                              // w={{ base: "25%", lg: "20%", xl: "15%" }}
                              w='100%'
                              cursor="pointer"
                              onClick={() => handleDayClick(item.day, item.date)}
                            >
                              <Text fontSize="0.875rem" fontWeight="600" color={selectedDay === item.day ? '#1C4EC5' : '#384052'}>
                                {formatDate_DD_MM(item.date)}
                              </Text>
                            </Box>
                          </SwiperSlide>
                        ))}
                        </Swiper>
                      <Box
                        as="button"
                        onClick={() => handleSlot("next")}
                      >
                        <Image w="10px" opacity='0.5' src="/assets/svgs/arrow-icon.svg" />
                      </Box>
                    </Flex>


                    <Text fontSize="1rem" fontWeight="bold" color="gray.800" mb="1rem">
                      {selectedDay ? `${getSlotCount(selectedDay)} slots available` : 'Select a day to see available slots'}
                    </Text>

                    {selectedDay && renderSlots(selectedDay)}

                    {selectedSlot && (
                      <>
                        <Divider my='1rem' />

                        <Flex w="100%" justifyContent='space-between' flexWrap={{ base: "wrap", md: "nowrap" }} gap={{ base: "1rem", md: "0" }}>
                          <Box w={{ base: "100%" }} textAlign={{ base: "center", md: "unset" }}>
                            <Text color='#384052' fontSize='0.75rem'>Booking slot</Text>
                            <Text color='#061027' fontSize='0.875rem'>
                              {`${selectedDate} at ${selectedSlot?.slottime}`}
                            </Text>
                          </Box>

                          <Box w={{ base: "100%", md: '20%' }}>
                            <PrimaryButton
                              variant='mdBtn'
                              btnStyle={{ w: { base: "100%", md: "initial" } }}
                              buttonText={<>
                                <a href={`${CONFIG.PatientUrl}doctorprofile?id=${doctorId}&name=${perosonalDetails && perosonalDetails.firstname + ' ' + perosonalDetails.lastname}&date=${navigatedate}&time=${selectedSlot.slottimeformatted}`} target="_blank">Book visit</a>
                              </>}
                            />
                          </Box>
                        </Flex>
                      </>
                    )}
                  </Box>
                </Box>
                {/* Address section end */}

                {/* Blog section start */}
                {blogsDetails && (
                  <Box w="100%" p="1rem">
                    <Box display="flex" gap="0.5rem" mb="1rem" alignItems="center">
                      <Box
                        boxSize="2.25rem"
                        bg="#1C4EC5"
                        p="0.675rem"
                        borderRadius="0.675rem"
                      >
                        <Image src="/assets/svgs/services-white.svg" />
                      </Box>
                      <Text fontSize="1.25rem" fontWeight="600">
                        Blog
                      </Text>
                    </Box>

                    <Box
                      w="full"
                      bg="white"
                      boxShadow="sm"
                      rounded="lg"
                      p="1.5rem"
                    >
                      <Stepper orientation="vertical" size="xs" gap="0.625rem" w="100%" colorScheme="#CDCFD4">
                        {blogsDetails && blogsDetails.map((item, index) => (
                          <Step key={index} w="100%" pb="1rem">
                            <StepIndicator color="#E6E7E9" />
                            <Box flexShrink="0" w="95%">
                              <StepDescription bg="white" w="100%" borderRadius="0.65rem" p="0">
                                <Text fontSize="0.875rem" color="#061027">
                                  {item.blog}
                                </Text>
                              </StepDescription>
                              <StepTitle fontSize="0.75rem" color="#384052" mb="0.25rem">
                                {formatDate(item.createddate)}
                              </StepTitle>
                            </Box>
                            <StepSeparator />
                          </Step>
                        ))}
                      </Stepper>

                    </Box>
                  </Box>
                )}

                {/* Blog section end */}

              </Box>
              {/* Details section end */}

              {/* Summary section start */}
              <Box w="100%" m="auto" p="1rem" bg="white">
                <Box w="100%" maxW="42rem" m="auto" p="1rem" textAlign="center">
                  <Flex direction="column" align="center" spacing="1rem">
                    <Box w="10rem" h="auto" mt="3rem">
                      <Image
                        src="assets/imgs/midoc-black.png"
                        alt="Midoc logo"
                        w="100%"
                        h="auto"
                        mb="1rem"
                      />
                    </Box>
                    <Text fontSize={{ base: "1.5rem", lg: "2.5rem" }} fontWeight="600" color="#061027" mb="1rem">
                      It is the doctor’s no.1 recommendation
                    </Text>
                    <Text fontSize="0.875rem" color="#384052" mb="2rem">
                      midoc is a cutting-edge platform connecting patients with healthcare professionals, offering seamless appointment booking, personalized care, and easy access to medical records, all in one place.
                    </Text>
                    <Flex justify="space-around" flexWrap="wrap" w="100%" mb="2rem">
                      <Flex direction="column" align="center">
                        <Text fontSize={{ base: "1.5rem", lg: "2.5rem" }} fontWeight="600" color="#1C4EC5">
                          40+
                        </Text>
                        <Text fontSize="0.875rem" color="#6A707D">
                          DOCTORS ONBOARDED
                        </Text>
                      </Flex>
                      <Flex direction="column" align="center">
                        <Text fontSize={{ base: "1.5rem", lg: "2.5rem" }} fontWeight="600" color="#1C4EC5">
                          500+
                        </Text>
                        <Text fontSize="0.875rem" color="#6A707D">
                          VISITS COMPLETED
                        </Text>
                      </Flex>
                      <Flex direction="column" align="center">
                        <Text fontSize={{ base: "1.5rem", lg: "2.5rem" }} fontWeight="600" color="#1C4EC5">
                          100+
                        </Text>
                        <Text fontSize="0.875rem" color="#6A707D">
                          LIVES SAVED
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex justify="center" gap="0.75rem" mx="auto">
                      <Link href="https://apps.apple.com/in/app/midoc-members/id6476858168" target="_blank">
                        <Image
                          src="assets/svgs/apple-store.svg"
                          alt="App Store"
                          w="6.25rem"
                          h="auto"
                          mr="1rem"
                        />
                      </Link>
                      
                      <Link href="https://play.google.com/store/apps/details?id=com.midoc.app" target="_blank">
                        <Image
                          src="assets/svgs/play-store.svg "
                          alt="Google Play"
                          w="6.7rem"
                          h="auto"
                        />
                      </Link>
                    </Flex>
                  </Flex>
                </Box>

                {/* Image grid section start */}
                <Box w="100%" maxW="55rem" m="auto" p="1rem" maxH="500px" overflow="hidden" pos="relative">
                  <Box
                    sx={{
                      columnCount: [1, 2, 3, 4],
                      columnGap: '1rem',
                      '& > div': {
                        mb: '1rem',
                      },
                    }}
                  >
                    {imageUrls.map((url, index) => (
                      <Box
                        key={index}
                        w="100%"
                        h={`${getRandomHeight()}px`}
                        borderRadius="lg"
                        overflow="hidden"
                        boxShadow="md"
                        display="inline-block"
                        mb="1rem"
                      >
                        <Image src={url} w="100%" h="100%" alt={`image-${index}`} objectFit="cover" />
                      </Box>
                    ))}
                    <Box bg="linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(251,251,251,0.9976365546218487) 58%)"></Box>
                  </Box>
                  <Box
                    position="absolute"
                    bottom="0"
                    left="0"
                    right="0"
                    h="200px"
                    bg="linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)"
                  ></Box>
                </Box>
                {/* Image grid section end */}

                {/* Visit our HQ section start */}
                <Box
                  maxW="55rem"
                  w="100%"
                  bg="#fafafa"
                  border="1px solid #E6E7E9"
                  boxShadow="sm"
                  borderRadius="0.75rem"
                  p="1rem"
                  m="auto"
                  my="1rem"
                >
                  <Flex align="center" justifyContent={{ base: "center", lg: "space-between" }} w="100%" flexDir={{ base: "column", lg: "row" }} alignItems="center">
                    <Box display="flex" gap="0.5rem" alignItems="center">
                      <Box
                        boxSize="2.25rem"
                        bg="#1C4EC5"
                        p="0.675rem"
                        borderRadius="0.675rem"
                      >
                        <Image src="/assets/svgs/hq.svg" />
                      </Box>
                      <Text fontSize="1.25rem" fontWeight="600">
                        Visit us at our HQ
                      </Text>
                    </Box>
                    <Text fontSize="0.875rem" fontWeight="400" textAlign={{ base: "center", lg: "left" }} mt={{ base: "0.75rem", lg: "0" }} color="#384052">
                      12600 Deerfield Pkwy Ste 100, Alpharetta, GA - 30004
                    </Text>
                  </Flex>
                </Box>
                {/* Visit our HQ section end */}
              </Box>
              {/* Summary section end */}
            </Box>
            <Footer normalFooter={false} />
          </>
        )
        }
      </Box>
    </>
  );
};

export default DoctorWebsite;
