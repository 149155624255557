export default function processDemographicsData(demographics) {
  if (typeof demographics !== "object" || demographics === null) {
    return {};
  }

  return {
    height: demographics.height ? demographics.height : "",
    heightunit: demographics.heightunit ? demographics.heightunit : "",
    weight: demographics.weight ? demographics.weight : "",
    weightunit: demographics.weightunit ? demographics.weightunit : "",
    bmi: demographics.bmi ? demographics.bmi : "",
  };
}
