import  FetchData, { } from '../../client';
import {getLoginID}  from '../../../auth';
import formapimodalprescriptions from '../../domains/prescriptions/prescriptions';
import formapimodalprescriptionsPagination from '../../domains/prescriptions';

  const FetchPrescriptionViewDetails = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      const result = await FetchData("midoc_getdoctorprescription", inputData);
      // console.log(result, "Prescription View Result")
      if (result.output) {
        var finalOutput = formapimodalprescriptionsPagination(result.output)
        }

        //console.log(finalOutput, "finalOUTPUT")
      return finalOutput;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };

  const FetchFilteredPrescriptionsList = async (inputData) => {
    try{
        // inputData.loginid = getLoginID();
        const result = await FetchData('midoc_getdoctorprescription', inputData);
        // console.log(result, "Filtered Prescriptions View Result");

        if(result.output.data){
            var data = formapimodalprescriptions(result.output.data);

            let count = result?.output?.count;
            let pageno = result?.output?.pageno;
            let pagesize = result?.output?.pagesize;
            var finalOutput = {
                data,
                count,
                pageno,
                pagesize
            }
            // console.log(finalOutput, "final OUtuput");
            return finalOutput;
        }

    } catch(error) {
        console.error('Error fetching data in forms.js:', error);
        throw error;
    }
}

  

export {FetchPrescriptionViewDetails, FetchFilteredPrescriptionsList};
