import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  VStack,
  HStack,
  Text,
  RadioGroup,
  Radio,
  Stack,
  Checkbox,
  SimpleGrid,
  GridItem,
  Select,
  Divider,
  InputRightElement,
  InputGroup,
  Grid,
  Input,
} from "@chakra-ui/react";
import { updateDoctorNotes } from "../../../middleware/services/visits/patient-overview/updatedoctornotes";
import { COLORS } from "../../styles/colors";
import PrimaryButton from "../../primary-button";

const DoctorHPIAnalyzer = ({
  patientId,
  doctorId,
  appointmentId,
  type,
  siteOptions,
  onsetOptions,
  chronicityOptions,
  onsetQualityOptions,
  frequencyOptions,
  associatedSymptomsOptions,
  otherOptions = {},
  fetchDoctorNotes,
  showSuccessToast,
  showErrorToast,
  modalRef,
  editHPIData,
  isEditMode,
  fetchDoctorNotesEmptyIndication
}) => {
  const [selectedSites, setSelectedSites] = useState([]);
  const [rating, setRating] = useState(5);
  const [onsetDate, setOnsetDate] = useState("");
  const [chronicity, setChronicity] = useState("");
  const [onsetQuality, setOnsetQuality] = useState("");
  const [frequency, setFrequency] = useState("");
  const [selectedSymptoms, setAssociatedSymptoms] = useState([]);
  const [timePeriod, setTimePeriod] = useState("Hours");
  const [timeValue, setTimeValue] = useState("");
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);

  useEffect(() => {
    if (isEditMode && editHPIData) {
      setSelectedSites(
        editHPIData.details
          .filter((detail) => detail.attribute === "Site")
          .flatMap((detail) => detail.values.map((v) => v.value))
      );
      setOnsetDate(
        editHPIData.details.find((detail) => detail.attribute === "Onset")
          ?.value || ""
      );
      setChronicity(
        editHPIData.details.find((detail) => detail.attribute === "Chronicity")
          ?.value || ""
      );
      setOnsetQuality(
        editHPIData.details.find(
          (detail) => detail.attribute === "Onset Quality"
        )?.value || ""
      );
      setFrequency(
        editHPIData.details.find((detail) => detail.attribute === "Frequency")
          ?.value || ""
      );
      setAssociatedSymptoms(
        editHPIData.details
          .find((detail) => detail.attribute === "Associated Symptoms")
          ?.values.map((v) => v.value) || []
      );
      setRating(
        editHPIData.details.find(
          (detail) => detail.attribute === "Rating Scale"
        )?.value || 5
      );
      setTimePeriod(
        editHPIData.details.find((detail) => detail.attribute === "Time")
          ?.value || "Hours"
      );
      setTimeValue(
        editHPIData.details.find((detail) => detail.attribute === "Time Value")
          ?.value || ""
      );
      // Setting otherOptionsState for optional features
      const newOptionalFeatureSelections = {};

      editHPIData.details.forEach((detail) => {
        if (otherOptions[detail.attribute]) {
          // if the attribute is present in otherOptions, set the selected values
          newOptionalFeatureSelections[detail.attribute] = detail.values.map(
            (v) => v.value
          );
        }
      });

      // console.log(newOptionalFeatureSelections);

      setOtherOptionsState(newOptionalFeatureSelections);
      setIsSaveButtonDisabled(false); // Enable save button when editing
    }
  }, [isEditMode, editHPIData]);

  // const toggleSelection = (item, setFunction, list) => {
  //   setFunction(
  //     list.includes(item) ? list.filter((i) => i !== item) : [...list, item]
  //   );
  //   setIsSaveButtonDisabled(false);
  // };

  const [optionalFeatureSelections, setOptionalFeatureSelections] = useState(
    {}
  );

  const [otherOptionsState, setOtherOptionsState] = useState({});

  // const toggleOptionalFeatureSelection = (attribute, value) => {
  //   setOptionalFeatureSelections((prevSelections) => {
  //     const currentSelection = prevSelections[attribute] || [];

  //     const newSelection = currentSelection.includes(value)
  //       ? currentSelection.filter((item) => item !== value) // Unselect
  //       : [...currentSelection, value]; // Select

  //     return {
  //       ...prevSelections,
  //       [attribute]: newSelection,
  //     };
  //   });
  //   setIsSaveButtonDisabled(false);
  // };

  const toggleOtherOptionSelection = (attribute, value) => {
    setOtherOptionsState((prevState) => {
      const currentSelection = prevState[attribute] || [];

      const newSelection = currentSelection.includes(value)
        ? currentSelection.filter((item) => item !== value) // Unselect
        : [...currentSelection, value]; // Select

      return {
        ...prevState,
        [attribute]: newSelection,
      };
    });

    setIsSaveButtonDisabled(false); // Enable the save button
  };

  const [isNoneSelected, setIsNoneSelected] = useState(false);

  const toggleSelection = (item, setFunction, list) => {
    if (item === "None") {
      if (list.includes(item)) {
        // If "None" is already selected, unselect it and enable other checkboxes
        setFunction([]);
        setIsNoneSelected(false);
      } else {
        // If "None" is selected, clear other selections and disable other checkboxes
        setFunction(["None"]);
        setIsNoneSelected(true);
      }
    } else {
      // If any other item is selected, unselect "None" and toggle the item
      const newList = list.includes(item)
        ? list.filter((i) => i !== item)
        : [...list.filter((i) => i !== "None"), item];
      setFunction(newList);
      setIsNoneSelected(false);
    }
    setIsSaveButtonDisabled(false);
  };

  const handleRatingClick = (value) => {
    setRating(value);
    setIsSaveButtonDisabled(false);
  };

  const transformStateToAPIFormat = () => {
    const details = [];

    if (selectedSites.length > 0) {
      details.push({
        attribute: "Site",
        values: selectedSites.map((site) => ({ value: site })),
      });
    }

    if (onsetDate) {
      details.push({
        attribute: "Onset",
        value: onsetDate,
      });
    }

    if (chronicity) {
      details.push({
        attribute: "Chronicity",
        value: chronicity,
      });
    }

    if (onsetQuality) {
      details.push({
        attribute: "Onset Quality",
        value: onsetQuality,
      });
    }

    if (frequency) {
      details.push({
        attribute: "Frequency",
        value: frequency,
      });
    }

    if (selectedSymptoms.length > 0) {
      details.push({
        attribute: "Associated Symptoms",
        values: selectedSymptoms.map((symptom) => ({ value: symptom })),
      });
    }

    if (rating) {
      details.push({
        attribute: "Rating Scale",
        value: rating,
      });
    }

    if (timePeriod && timeValue) {
      details.push({
        attribute: "Time",
        value: timePeriod,
      });

      details.push({
        attribute: "Time Value",
        value: timeValue,
      });
    }

    // Object.keys(optionalFeatureSelections).forEach((attribute) => {
    //   const values = optionalFeatureSelections[attribute];
    //   if (values.length > 0) {
    //     details.push({
    //       attribute,
    //       values: values.map((value) => ({ value })),
    //     });
    //   }
    // });

    Object.keys(otherOptionsState).forEach((attribute) => {
      const values = otherOptionsState[attribute];
      if (values.length > 0) {
        details.push({
          attribute,
          values: values.map((value) => ({ value })),
        });
      }
    });

    return details.length > 0
      ? {
          patientid: patientId,
          doctorid: doctorId,
          appointmentid: appointmentId,
          hpi: [
            {
              id: isEditMode ? editHPIData.id : null,
              type,
              details,
            },
          ],
        }
      : null;
  };

  const UpdateHPI = async () => {
    const inputToSave = {
      input: transformStateToAPIFormat(),
    };

    if (!inputToSave.input) {
      return;
    }

    try {
      // console.log(inputToSave);
      const result = await updateDoctorNotes(inputToSave.input);
      if (result) {
        showSuccessToast("HPI Saved successfully");
        fetchDoctorNotes();
        fetchDoctorNotesEmptyIndication()
        modalRef.current.closeRight();
      }
    } catch (error) {
      console.error("Error in Update Doctor Notes:", error);
      showErrorToast("Error Happened While Saving.");
    }
  };

  const isSaveDisabled = () => {
    return isSaveButtonDisabled;
  };

  const checkBtn = {
    _hover: {
      bg: "#010308",
      color: "white",
    },
    _focus: {
      bg: "#010308",
      color: "white",
    },
  };

  return (
    <VStack spacing={4} align="start" width="full" bg="#fcfcfd">
      <Box width="full" pl="8px" pr="8px">
        <Text
          fontSize="0.75rem"
          letterSpacing="0.1rem"
          fontWeight="600"
          textTransform="uppercase"
          color="#061027"
        >
          Site
        </Text>
        <HStack spacing={{ base: 2, lg: 4 }} wrap="wrap" mt="8px">
          {siteOptions.map((site, index) => (
            <Button
              key={index}
              sx={checkBtn}
              onClick={() =>
                toggleSelection(site, setSelectedSites, selectedSites)
              }
              bg={selectedSites.includes(site) ? "black" : "white"}
              color={selectedSites.includes(site) ? "white" : "black"}
              borderRadius="full"
              border={"2px"}
              borderColor="gray.200"
            >
              {selectedSites.includes(site) && (
                <img
                  src="/assets/svgs/white-ok.svg"
                  alt="selected"
                  style={{ marginRight: "8px" }}
                />
              )}
              {site}
            </Button>
          ))}
        </HStack>
      </Box>

      <SimpleGrid
        columns={{ base: 1, lg: 2 }}
        spacing={4}
        width="full"
        pl="8px"
        pr="8px"
        pb="8px"
      >
        <GridItem>
          <Text
            fontSize="0.75rem"
            letterSpacing="0.1rem"
            fontWeight="600"
            textTransform="uppercase"
            color="#061027"
          >
            Onset
          </Text>
          <RadioGroup onChange={setOnsetDate} value={onsetDate}>
            <SimpleGrid columns={2} spacing={2} mb="8px" mt="8px">
              {onsetOptions.map((onset, index) => (
                <Radio key={index} value={onset}>
                  {onset}
                </Radio>
              ))}
            </SimpleGrid>
          </RadioGroup>
        </GridItem>

        <GridItem>
          <Text
            fontSize="0.75rem"
            letterSpacing="0.1rem"
            fontWeight="600"
            textTransform="uppercase"
            color="#061027"
          >
            Chronicity
          </Text>
          <RadioGroup onChange={setChronicity} value={chronicity}>
            <SimpleGrid columns={2} spacing={2} mb="8px" mt="8px">
              {chronicityOptions.map((chronicityOption, index) => (
                <Radio key={index} value={chronicityOption}>
                  {chronicityOption}
                </Radio>
              ))}
            </SimpleGrid>
          </RadioGroup>
        </GridItem>
      </SimpleGrid>

      <Divider />

      <SimpleGrid
        columns={{ base: 1, lg: 2 }}
        spacing={4}
        width="full"
        pl="8px"
        pr="8px"
      >
        <GridItem>
          <Text
            fontSize="0.75rem"
            letterSpacing="0.1rem"
            fontWeight="600"
            textTransform="uppercase"
            color="#061027"
          >
            Onset Quality
          </Text>
          <RadioGroup onChange={setOnsetQuality} value={onsetQuality}>
            <SimpleGrid columns={2} spacing={2} mb="8px" mt="8px">
              {onsetQualityOptions.map((qualityOption, index) => (
                <Radio key={index} value={qualityOption}>
                  {qualityOption}
                </Radio>
              ))}
            </SimpleGrid>
          </RadioGroup>
        </GridItem>

        <GridItem>
          <Text
            fontSize="0.75rem"
            letterSpacing="0.1rem"
            fontWeight="600"
            textTransform="uppercase"
            color="#061027"
          >
            Frequency
          </Text>
          <RadioGroup onChange={setFrequency} value={frequency}>
            <SimpleGrid columns={2} spacing={2} mb="8px" mt="8px">
              {frequencyOptions.map((frequencyOption, index) => (
                <Radio key={index} value={frequencyOption}>
                  {frequencyOption}
                </Radio>
              ))}
            </SimpleGrid>
          </RadioGroup>
        </GridItem>
      </SimpleGrid>

      <Divider />

      <Box width="full" pl="8px" pr="8px">
        <Text
          fontSize="0.75rem"
          letterSpacing="0.1rem"
          fontWeight="600"
          textTransform="uppercase"
          color="#061027"
        >
          Rating Scale
        </Text>
        <HStack spacing={0} width="full" mb="8px" mt="8px">
          {[...Array(10).keys()].map((i) => (
            <Button
              key={i + 1}
              onClick={() => handleRatingClick(i + 1)}
              bg={rating >= i + 1 ? "black" : "white"}
              color={rating >= i + 1 ? "white" : "black"}
              borderRadius={
                i === 0 ? "10px 0 0 10px" : i === 9 ? "0 10px 10px 0" : "0"
              }
              flex="1"
              sx={checkBtn}
              px={{ base: "0.5rem", lg: "1rem" }}
              minW={{ base: "1rem", lg: "2.5rem" }}
              border="1px solid white"
            >
              {i + 1}
            </Button>
          ))}
        </HStack>
        <SimpleGrid columns={10} spacing={0} mt={2} width="full">
          <GridItem
            colSpan={2}
            borderTop="2px solid blue"
            ml={"6px"}
            mr={"6px"}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Text fontSize={rating >= 1 && rating <= 2 ? "4xl" : "lg"}>😁</Text>
          </GridItem>
          <GridItem
            colSpan={2}
            borderTop="2px solid green"
            ml={"6px"}
            mr={"6px"}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Text fontSize={rating >= 3 && rating <= 4 ? "4xl" : "lg"}>😊</Text>
          </GridItem>
          <GridItem
            colSpan={2}
            borderTop="2px solid yellow"
            ml={"6px"}
            mr={"6px"}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Text fontSize={rating >= 5 && rating <= 6 ? "4xl" : "lg"}>😐</Text>
          </GridItem>
          <GridItem
            colSpan={2}
            borderTop="2px solid orange"
            ml={"6px"}
            mr={"6px"}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Text fontSize={rating >= 7 && rating <= 8 ? "4xl" : "lg"}>😞</Text>
          </GridItem>
          <GridItem
            colSpan={2}
            borderTop="2px solid red"
            ml={"6px"}
            mr={"6px"}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Text fontSize={rating >= 9 && rating <= 10 ? "4xl" : "lg"}>
              😢
            </Text>
          </GridItem>
        </SimpleGrid>
      </Box>

      <Divider />

      <Box width="full" pl="8px" pr="8px">
        <Text
          fontSize="0.75rem"
          letterSpacing="0.1rem"
          fontWeight="600"
          textTransform="uppercase"
          color="#061027"
        >
          Associated Symptoms
        </Text>
        <SimpleGrid
          columns={{ base: 2, lg: 4 }}
          spacing={{ base: 2, lg: 4 }}
          width="full"
          mb="8px"
          mt="8px"
        >
          {associatedSymptomsOptions.map((symptom, index) => (
            <Checkbox
              key={index}
              isChecked={selectedSymptoms.includes(symptom)}
              onChange={() =>
                toggleSelection(
                  symptom,
                  setAssociatedSymptoms,
                  selectedSymptoms
                )
              }
              isDisabled={isNoneSelected && symptom !== "None"}
            >
              {symptom}
            </Checkbox>
          ))}
        </SimpleGrid>
      </Box>

      {/* {otherOptions &&
        Object.entries(otherOptions).length > 0 &&
        Object.entries(otherOptions).map(([attribute, values], index) => (
          <Box key={index} width="full" pl="8px" pr="8px">
            <Text
              fontSize="0.75rem"
              letterSpacing="0.1rem"
              fontWeight="600"
              textTransform="uppercase"
              color="#061027"
            >
              {attribute}
            </Text>
            <SimpleGrid
              columns={{ base: 2, lg: 4 }}
              spacing={{ base: 2, lg: 4 }}
              width="full"
              mb="8px"
              mt="8px"
            >
              {values.map((value, valueIndex) => (
                <Checkbox
                  key={valueIndex}
                  isChecked={optionalFeatureSelections[attribute]?.includes(
                    value
                  )}
                  onChange={() =>
                    toggleOptionalFeatureSelection(attribute, value)
                  }
                >
                  {value}
                </Checkbox>
              ))}
            </SimpleGrid>
          </Box>
        ))} */}

      {otherOptions &&
        Object.entries(otherOptions).length > 0 &&
        Object.entries(otherOptions).map(([attribute, values], index) => (
          <Box key={index} width="full" pl="8px" pr="8px">
            <Text
              fontSize="0.75rem"
              letterSpacing="0.1rem"
              fontWeight="600"
              textTransform="uppercase"
              color="#061027"
            >
              {attribute}
            </Text>
            <SimpleGrid
              columns={{ base: 2, lg: 4 }}
              spacing={{ base: 2, lg: 4 }}
              width="full"
              mb="8px"
              mt="8px"
            >
              {values.map((value, valueIndex) => (
                <Checkbox
                  key={valueIndex}
                  isChecked={otherOptionsState[attribute]?.includes(value)}
                  onChange={() => toggleOtherOptionSelection(attribute, value)}
                >
                  {value}
                </Checkbox>
              ))}
            </SimpleGrid>
          </Box>
        ))}

      <Divider />

      <SimpleGrid columns={4} spacing={4} width="full" pl="8px" pr="8px">
        <GridItem colSpan={2}>
          <Text
            fontSize="0.75rem"
            letterSpacing="0.1rem"
            fontWeight="600"
            textTransform="uppercase"
            color="#061027"
          >
            Time Period
          </Text>
          <HStack>
            <InputGroup mb="8px" mt="8px">
              <Input
                type="number"
                value={timeValue}
                onChange={(e) => {
                  setTimeValue(e.target.value);
                  setIsSaveButtonDisabled(false);
                }}
              />
              <InputRightElement width="8rem">
                <Select
                  onChange={(e) => {
                    setTimePeriod(e.target.value);
                    setIsSaveButtonDisabled(false);
                  }}
                  value={timePeriod}
                  size="sm"
                  border="none"
                  bg="transparent"
                  cursor="pointer"
                >
                  <option value="Hours">Hours</option>
                  <option value="Days">Days</option>
                  <option value="Weeks">Weeks</option>
                  <option value="Months">Months</option>
                </Select>
              </InputRightElement>
            </InputGroup>
          </HStack>
        </GridItem>

        <GridItem colSpan={2} alignSelf="flex-end">
          <PrimaryButton
            buttonText="Save HPI"
            isDisabled={isSaveDisabled()}
            onClick={UpdateHPI}
            btnStyle={{
              h: "2.5rem",
              w: "100%",
              p: "0",
              mr: "12px",
              ml: "12px",
              bg: COLORS.PRIMARY_GRADIENT,
              color: COLORS.PRIMARY_WHITE,
              _hover: { bg: COLORS.PRIMARY_COLOR },
            }}
          />
        </GridItem>
      </SimpleGrid>
    </VStack>
  );
};

export default DoctorHPIAnalyzer;
