import React, { useEffect, useState, useRef } from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  Image,
  FormControl,
  FormLabel
} from '@chakra-ui/react';

import { styles, variantStyles } from "./styles";
import { inputDesign, activeLabelStyles } from "../styles/theme";

const DropdownInput = ({
  dropDownPlace,
  options,
  variant,
  onClick,
  maxLength,
  onKeyDown,
  onKeyPress,
  id,
  defaultOption,
  inputStyleCss,
  readOnly = "readonly",
  isLabel = true,
  optionStyle,
  dropPlaceStyle,
  menuList,
  value, // New prop to control input value externally
  toggleDropdown,
  isOpen
}) => {
  const variantStyle = variantStyles[variant] || variantStyles.defaultSide;
  const [inputValue, setInputValue] = useState(value || ""); // Use value prop
  const [showMenu, setShowMenu] = useState(false);
  const isLabelActive = inputValue !== "";
  const inputRef = useRef(null);  // Add ref to input
  const menuRef = useRef(null);

  useEffect(() => {
    if (defaultOption) {
      setInputValue(defaultOption);
    }
  }, [defaultOption]);

  useEffect(() => {
    if (value !== undefined) {
      setInputValue(value);
    }
  }, [value]); // Update inputValue when value prop changes

  const handleBlur = (event) => {
    if (!menuRef.current.contains(event.relatedTarget)) {
      toggleDropdown(); // Close dropdown if the next focus target is not inside the dropdown
    }
  };

  const handleOptionClick = (option) => {
    setInputValue(option);
    onClick(option);
    setShowMenu(false);
    // toggleDropdown();
    inputRef.current.focus();  // Focus the input field
  };

  return (
    <Menu isOpen={showMenu} onClose={() => setShowMenu(false)} pos="relative">
      <FormControl sx={{ ...inputDesign.formStyles.container, ...inputStyleCss }}>
        <InputGroup>
          <Input
            placeholder=" "
            value={inputValue}
            sx={{ ...styles, ...variantStyle, ...inputDesign.formStyles.input, ...optionStyle }}
            onChange={(e) => setInputValue(e.target.value)}
            // onFocus={toggleDropdown}
            onFocus={() => setShowMenu(true)}
            // onBlur={handleBlur}
            readOnly={readOnly}
            cursor="pointer"
            onKeyDown={(e) => {
              onKeyDown && onKeyDown(e);
            }}
            onKeyPress={onKeyPress}
            id={id}
            maxLength={maxLength}
            p={isLabel ? "12px 10px 0 10px" : "12px 10px 10px 10px"}
            {...optionStyle}
            ref={inputRef}  // Attach ref to input
          />
          <InputRightElement width="3rem">
            <MenuButton
              as={Button}
              h="1.75rem"
              bg="none"
              sx={{ top: "5px", _hover: { bg: "none" }, _active: { bg: "none" } }}
              size="sm"
              onClick={() => setShowMenu(!showMenu)}
            >
              <Image w="15px" src="/assets/imgs/double-arrow.png" />
            </MenuButton>
          </InputRightElement>
        </InputGroup>
        {isLabel && (
          <FormLabel sx={{
            ...inputDesign.formStyles.label,
            ...(isLabelActive && activeLabelStyles),
            ...dropPlaceStyle
          }}>{dropDownPlace}</FormLabel>
        )}
      </FormControl>
      <MenuList zIndex="999" maxH="200px" overflowY="auto" ref={menuRef} {...menuList}>
        {options.map((option, index) => (
          <MenuItem key={index} onClick={() => handleOptionClick(option)}>
            {option}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default DropdownInput;
