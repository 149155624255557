import formAPIPatientsView from "./patientView";

var formattedOutput = null;

const formapimodalpatientsview = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIPatientsView(item));


  return formattedOutput
};

export default formapimodalpatientsview