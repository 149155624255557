import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Button,
  Text,
  Image,
  VStack,
  HStack,
  Radio,
  RadioGroup,
  Wrap,
  WrapItem,
  SimpleGrid,
} from "@chakra-ui/react";
import useLoader from "../../loader/loaderstates";
import Loader from "../../loader/loader";
import {
  GetDoctorMasterData,
  GetDoctorNotes,
  updateDoctorNotes,
} from "../../../middleware/services/visits/patient-overview/updatedoctornotes";
import { COLORS } from "../../styles/colors";
import PrimaryButton from "../../primary-button";
import { actionAccessMain } from "../../../auth";

const DoctorPhysicalExamCard = ({
  patientId,
  doctorId,
  appointmentId,
  showSuccessToast,
  showErrorToast,
  fetchDoctorNotesEmptyIndication,
}) => {
  const [selectedConditions, setSelectedConditions] = useState([]);
  const [initiallySelectedConditions, setInitiallySelectedConditions] =
    useState([]);

  const [doctorMasterPhysicalExamData, setDoctorMasterPhysicalExamData] =
    useState([]);
  const { isLoading, showLoader, dismissLoader } = useLoader();

  useEffect(() => {
    fetchDoctorNotes().then(() => {
      fetchPhysicalExamMasterData();
    });
  }, []);

  const transformPhysicalExamData = (inputData) => {
    const transformedData = [];

    // Iterate through each group of physical exams
    inputData.forEach((group) => {
      const { type, physicalexam } = group;

      // Map through physicalexam array and transform it to the desired structure
      physicalexam.forEach((exam) => {
        const { examvalue, subexamkey, nestedexamkey, indicator } = exam;

        // Push each transformed object to the result array
        transformedData.push({
          examkey: type, // Type from the group
          subexamkey: subexamkey || null, // If subexamkey is null, set it as null
          nestedKey: nestedexamkey || null, // If nestedexamkey is null, set it as null
          indicator: indicator || 0, // Set indicator default to 0 if not provided
          examvalue: examvalue, // Exam value remains unchanged
        });
      });
    });

    return transformedData;
  };

  const fetchDoctorNotes = async () => {
    const inputToFetch = {
      input: {
        patientid: patientId,
        doctorid: doctorId,
        appointmentid: appointmentId,
      },
    };
    showLoader();
    try {
      const data = await GetDoctorNotes(inputToFetch.input);
      const physicalExam = data?.output?.data?.physicalexam || [];
      const initialSelectedConditions = transformPhysicalExamData(physicalExam);
      // console.log("Initially Selected: ", initialSelectedConditions);
      setSelectedConditions(initialSelectedConditions);
      setInitiallySelectedConditions(initialSelectedConditions);
    } catch (error) {
      console.error("Error fetching Doctor Notes data:", error);
    } finally {
      dismissLoader();
    }
  };

  const fetchPhysicalExamMasterData = async () => {
    showLoader();
    try {
      const inputData = {
        input: { type: "physicalexam" },
      };
      const data = await GetDoctorMasterData(inputData.input);
      setDoctorMasterPhysicalExamData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      dismissLoader();
    }
  };

  // Group data by examkey, subexamkey, and nestedexamkey
  const groupedData = doctorMasterPhysicalExamData.reduce((acc, item) => {
    const { examkey, subexamkey, nestedexamkey, examvalue, isindicator } = item;

    if (!acc[examkey]) acc[examkey] = {};
    if (subexamkey) {
      if (!acc[examkey][subexamkey]) acc[examkey][subexamkey] = {};
      if (nestedexamkey) {
        if (!acc[examkey][subexamkey][nestedexamkey])
          acc[examkey][subexamkey][nestedexamkey] = [];
        acc[examkey][subexamkey][nestedexamkey].push({
          examvalue,
          isindicator,
        });
      } else {
        if (!acc[examkey][subexamkey].items)
          acc[examkey][subexamkey].items = [];
        acc[examkey][subexamkey].items.push({ examvalue, isindicator });
      }
    } else {
      if (!acc[examkey].items) acc[examkey].items = [];
      acc[examkey].items.push({ examvalue, isindicator });
    }
    return acc;
  }, {});

  // console.log(groupedData);

  const transformStateToAPIFormat = (selectedConditions) => {
    // Step 1: Group by examkey
    const groupedConditions = selectedConditions.reduce((acc, condition) => {
      const { examkey, subexamkey, nestedKey, indicator, examvalue } =
        condition;

      // If the examkey is not in the accumulator, add it
      if (!acc[examkey]) {
        acc[examkey] = [];
      }

      // Push the condition into the corresponding group
      acc[examkey].push({
        examvalue,
        subexamkey,
        nestedexamkey: nestedKey,
        indicator,
      });

      return acc;
    }, {});

    // Step 2: Convert to the required format
    const result = Object.keys(groupedConditions).map((examkey) => {
      return {
        id: null, // ID is always null
        type: examkey, // Use the examkey as the type
        value: groupedConditions[examkey], // The grouped value
      };
    });

    return result;
  };

  const UpdatePhysicalExam = async () => {
    const inputToSave = {
      input: {
        doctorid: doctorId,
        patientid: patientId,
        appointmentid: appointmentId,
        physicalexam: transformStateToAPIFormat(selectedConditions),
      },
    };
    showLoader();
    try {
      console.log(inputToSave);
      const result = await updateDoctorNotes(inputToSave.input);
      // console.log(result);
      if (result) {
        showSuccessToast("Physical Exam Saved successfully");
        fetchDoctorNotesEmptyIndication();
        // setChangesMade(false); // Reset changesMade state
        setSelectedConditions([]); // Reset selectedConditions
        fetchDoctorNotes();
      }
    } catch (error) {
      console.error("Error in Update Doctor Notes:", error);
      showErrorToast("Error Happened While Saving.");
    } finally {
      dismissLoader();
    }
  };

  return (
    <Box
      // position="relative"
      height="100%"
      // display="flex"
      // flexDirection="column"
    >
      {isLoading && (
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex="10"
          bg="rgba(255, 255, 255, 0.8)"
        >
          <Loader miniLoader={true} />
        </Box>
      )}
      <Accordion allowToggle>
        {Object.keys(groupedData).map((examkey) => (
          <AccordionItem key={examkey}>
            <h2>
              <AccordionButton
                _expanded={{ bg: "#e6e7e9", color: "black" }} // highlight selected button
              >
                <Box flex="1" textAlign="left">
                  {examkey}
                </Box>
              </AccordionButton>
            </h2>
            <AccordionPanel pb={2}>
              {Object.keys(groupedData[examkey]).map((subexamkey) =>
                subexamkey === "items" ? (
                  <Box key={subexamkey} mb={2}>
                    {/* Items without indicators */}
                    <Box maxWidth="100%" mb={2}>
                      {groupedData[examkey][subexamkey]
                        .filter((item) => item.isindicator === 0)
                        .map((item, idx) => (
                          <Box key={idx} display="inline-block" mr={1} mb={1}>
                            <ExamButton
                              item={item}
                              size={"xs"}
                              otherValues={{
                                examkey: examkey,
                                subexamkey:
                                  subexamkey !== "items" ? subexamkey : null,
                                nestedKey: null,
                                indicator: item.isindicator,
                                examvalue: item.examvalue,
                              }}
                              selectedConditions={selectedConditions}
                              setSelectedConditions={setSelectedConditions}
                            />
                          </Box>
                        ))}
                    </Box>

                    {/* Items with indicators */}
                    <Box maxWidth="100%" mt={0}>
                      {groupedData[examkey][subexamkey]
                        .filter((item) => item.isindicator !== 0)
                        .map((item, idx) => (
                          <Box key={idx} display="inline-block" mr={1} mb={1}>
                            <ExamButton
                              item={item}
                              size={"xs"}
                              otherValues={{
                                examkey: examkey,
                                subexamkey:
                                  subexamkey !== "items" ? subexamkey : null,
                                nestedKey: null,
                                indicator: item.isindicator,
                                examvalue: item.examvalue,
                              }}
                              selectedConditions={selectedConditions}
                              setSelectedConditions={setSelectedConditions}
                            />
                          </Box>
                        ))}
                    </Box>
                  </Box>
                ) : (
                  <Box key={subexamkey} mb={4}>
                    <Text
                      fontWeight="bold"
                      mb={2}
                      bg="#c6c7c7"
                      w="100%"
                      pl="8px"
                    >
                      {subexamkey}
                    </Text>
                    <Box>
                      {Object.keys(groupedData[examkey][subexamkey]).map(
                        (nestedKey) =>
                          nestedKey === "items" ? (
                            <Box key={nestedKey}>
                              {/* Items without indicators */}
                              <Box maxWidth="100%" mb={2}>
                                {groupedData[examkey][subexamkey][nestedKey]
                                  .filter((item) => item.isindicator === 0)
                                  .map((item, idx) => (
                                    <Box
                                      key={idx}
                                      display="inline-block"
                                      mr={1}
                                      mb={1}
                                    >
                                      <ExamButton
                                        item={item}
                                        size={"xs"}
                                        otherValues={{
                                          examkey: examkey,
                                          subexamkey:
                                            subexamkey !== "items"
                                              ? subexamkey
                                              : null,
                                          nestedKey:
                                            nestedKey !== "items"
                                              ? nestedKey
                                              : null,
                                          indicator: item.isindicator,
                                          examvalue: item.examvalue,
                                        }}
                                        selectedConditions={selectedConditions}
                                        setSelectedConditions={
                                          setSelectedConditions
                                        }
                                      />
                                    </Box>
                                  ))}
                              </Box>

                              {/* Items with indicators */}
                              <Box maxWidth="100%" mt={2}>
                                {groupedData[examkey][subexamkey][nestedKey]
                                  .filter((item) => item.isindicator !== 0)
                                  .map((item, idx) => (
                                    <Box
                                      key={idx}
                                      display="inline-block"
                                      mr={1}
                                      mb={1}
                                    >
                                      <ExamButton
                                        item={item}
                                        size={"xs"}
                                        otherValues={{
                                          examkey: examkey,
                                          subexamkey:
                                            subexamkey !== "items"
                                              ? subexamkey
                                              : null,
                                          nestedKey:
                                            nestedKey !== "items"
                                              ? nestedKey
                                              : null,
                                          indicator: item.isindicator,
                                          examvalue: item.examvalue,
                                        }}
                                        selectedConditions={selectedConditions}
                                        setSelectedConditions={
                                          setSelectedConditions
                                        }
                                      />
                                    </Box>
                                  ))}
                              </Box>
                            </Box>
                          ) : null
                      )}
                    </Box>

                    <SimpleGrid columns={2} spacing={4}>
                      {Object.keys(groupedData[examkey][subexamkey]).map(
                        (nestedKey) =>
                          nestedKey !== "items" ? (
                            <Box key={nestedKey} bg={"#e6e7e9"} mb={2}>
                              <Text
                                fontWeight="bold"
                                bg="white"
                                w="100%"
                                pl="8px"
                              >
                                {nestedKey}
                              </Text>
                              {/* Items without indicators */}
                              <Box maxWidth="100%" mb={2}>
                                {groupedData[examkey][subexamkey][nestedKey]
                                  .filter((item) => item.isindicator === 0)
                                  .map((item, idx) => (
                                    <Box
                                      key={idx}
                                      display="inline-block"
                                      mr={1}
                                      mb={1}
                                    >
                                      <ExamButton
                                        item={item}
                                        size={"xs"}
                                        otherValues={{
                                          examkey: examkey,
                                          subexamkey:
                                            subexamkey !== "items"
                                              ? subexamkey
                                              : null,
                                          nestedKey:
                                            nestedKey !== "items"
                                              ? nestedKey
                                              : null,
                                          indicator: item.isindicator,
                                          examvalue: item.examvalue,
                                        }}
                                        selectedConditions={selectedConditions}
                                        setSelectedConditions={
                                          setSelectedConditions
                                        }
                                      />
                                    </Box>
                                  ))}
                              </Box>

                              {/* Items with indicators */}
                              <Box maxWidth="100%" mt={2} mb={4}>
                                {groupedData[examkey][subexamkey][nestedKey]
                                  .filter((item) => item.isindicator !== 0)
                                  .map((item, idx) => (
                                    <Box
                                      key={idx}
                                      display="inline-block"
                                      mr={1}
                                      mb={1}
                                    >
                                      <ExamButton
                                        item={item}
                                        size={"xs"}
                                        otherValues={{
                                          examkey: examkey,
                                          subexamkey:
                                            subexamkey !== "items"
                                              ? subexamkey
                                              : null,
                                          nestedKey:
                                            nestedKey !== "items"
                                              ? nestedKey
                                              : null,
                                          indicator: item.isindicator,
                                          examvalue: item.examvalue,
                                        }}
                                        selectedConditions={selectedConditions}
                                        setSelectedConditions={
                                          setSelectedConditions
                                        }
                                      />
                                    </Box>
                                  ))}
                              </Box>
                            </Box>
                          ) : null
                      )}
                    </SimpleGrid>
                  </Box>
                )
              )}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
      <PrimaryButton
        buttonText="Save Physical Exam"
        onClick={UpdatePhysicalExam}
        isDisabled={
          initiallySelectedConditions === selectedConditions ||
          !selectedConditions.length ||
          !actionAccessMain(20)?.create
        }
        btnStyle={{
          h: "2.5rem",
          w: "95%",
          p: "0",
          // mr: "12px",
          // ml: "12px",
          position: "sticky",
          bottom: "0",
          mt: "3rem",
          mx: "2.5%",
          zIndex: "1",
          bg: COLORS.PRIMARY_GRADIENT,
          color: COLORS.PRIMARY_WHITE,
          _hover: { bg: COLORS.PRIMARY_COLOR },
        }}
      />
    </Box>
  );
};

const ExamButton = ({
  item,
  size = "sm",
  otherValues,
  selectedConditions,
  setSelectedConditions,
}) => {
  // console.log(otherValues);

  const { examvalue, isindicator } = item;

  const [isSelected, setIsSelected] = useState(() => {
    return selectedConditions.some(
      (condition) =>
        condition.examvalue === examvalue &&
        condition.nestedKey === otherValues.nestedKey &&
        condition.subexamkey === otherValues.subexamkey &&
        condition.examkey === otherValues.examkey
    );
  });

  // State to track selection status for different values
  const [selectedValue, setSelectedValue] = useState(null);

  // State to track selection of the center, plus, and minus buttons
  // const [selected, setSelected] = useState("");
  // Initialize the selected state based on the existing selectedConditions
  const initialSelected =
    selectedConditions.find(
      (condition) =>
        condition.examvalue === examvalue &&
        condition.nestedKey === otherValues.nestedKey &&
        condition.subexamkey === otherValues.subexamkey &&
        condition.examkey === otherValues.examkey
    )?.indicator || "";

  // Set the initial selected state based on the indicator (-1, 0, 1)
  const [selected, setSelected] = useState(() => {
    // If indicator is 1, set selected as "+"
    // If indicator is -1, set selected as "-"
    // If indicator is 0 or null, set selected as "-"
    if (initialSelected === 1) {
      return "+";
    } else if (initialSelected === -1) {
      return "-";
    } else {
      return "";
    }
  });

  const handleSelectCenter = (value) => {
    setSelected((prev) => {
      const isToggledOff = prev === "center";
      const updatedIndicator = isToggledOff ? null : 0;
      const updatedConditions = isToggledOff
        ? selectedConditions.filter(
            (condition) => condition.examvalue !== examvalue
          )
        : [
            ...selectedConditions,
            { ...otherValues, indicator: updatedIndicator },
          ];

      setSelectedConditions(updatedConditions);
      return isToggledOff ? "" : value;
    });
  };

  const handleSelectPlusIndicator = (value) => {
    setSelected((prev) => {
      // Early return if `prev` is "-"
      if (prev === "-") {
        const conditionIndex = selectedConditions.findIndex(
          (condition) =>
            condition.examvalue === examvalue &&
            condition.nestedKey === otherValues.nestedKey &&
            condition.subexamkey === otherValues.subexamkey &&
            condition.examkey === otherValues.examkey
        );

        const updatedConditions = [...selectedConditions];

        if (conditionIndex !== -1) {
          // Update the existing condition's indicator
          updatedConditions[conditionIndex] = {
            ...updatedConditions[conditionIndex],
            indicator: 1,
          };
        } else {
          // Add the condition if it doesn't exist
          updatedConditions.push({ ...otherValues, indicator: 1 });
        }

        setSelectedConditions(updatedConditions);
        return value;
      }

      const isToggledOff = prev === "+";
      const updatedIndicator = isToggledOff ? null : 1;
      const updatedConditions = isToggledOff
        ? selectedConditions.filter(
            (condition) =>
              condition.examvalue !== examvalue &&
              condition.nestedKey !== otherValues.nestedKey &&
              condition.subexamkey !== otherValues.subexamkey &&
              condition.examkey !== otherValues.examkey
          )
        : [
            ...selectedConditions,
            { ...otherValues, indicator: updatedIndicator },
          ];

      setSelectedConditions(updatedConditions);
      return isToggledOff ? "" : value;
    });
  };

  const handleSelectMinusIndicator = (value) => {
    setSelected((prev) => {
      // Early return if `prev` is "+"
      if (prev === "+") {
        const conditionIndex = selectedConditions.findIndex(
          (condition) =>
            condition.examvalue === examvalue &&
            condition.nestedKey === otherValues.nestedKey &&
            condition.subexamkey === otherValues.subexamkey &&
            condition.examkey === otherValues.examkey
        );

        const updatedConditions = [...selectedConditions];

        if (conditionIndex !== -1) {
          // Update the existing condition's indicator
          updatedConditions[conditionIndex] = {
            ...updatedConditions[conditionIndex],
            indicator: -1,
          };
        } else {
          // Add the condition if it doesn't exist
          updatedConditions.push({ ...otherValues, indicator: -1 });
        }

        setSelectedConditions(updatedConditions);
        return value;
      }

      const isToggledOff = prev === "-";
      const updatedIndicator = isToggledOff ? null : -1;
      const updatedConditions = isToggledOff
        ? selectedConditions.filter(
            (condition) =>
              condition.examvalue !== examvalue &&
              condition.nestedKey !== otherValues.nestedKey &&
              condition.subexamkey !== otherValues.subexamkey &&
              condition.examkey !== otherValues.examkey
          )
        : [
            ...selectedConditions,
            { ...otherValues, indicator: updatedIndicator },
          ];

      setSelectedConditions(updatedConditions);
      return isToggledOff ? "" : value;
    });
  };

  const handleNormalButtonClick = () => {
    setIsSelected(!isSelected);
    setSelectedConditions((prevConditions) => {
      const conditionExists = prevConditions.some(
        (condition) =>
          condition.examvalue === examvalue &&
          condition.nestedKey === otherValues.nestedKey &&
          condition.subexamkey === otherValues.subexamkey &&
          condition.examkey === otherValues.examkey
      );

      if (conditionExists) {
        // Remove the condition if it already exists
        return prevConditions.filter(
          (condition) =>
            condition.examvalue !== examvalue &&
            condition.nestedKey !== otherValues.nestedKey &&
            condition.subexamkey !== otherValues.subexamkey &&
            condition.examkey !== otherValues.examkey
        );
      } else {
        // Add the condition with indicator as null
        return [...prevConditions, { ...otherValues, indicator: null }];
      }
    });
  };

  return isindicator === 0 ? (
    <Button
      key={examvalue}
      borderRadius="full"
      onClick={handleNormalButtonClick}
      bg={isSelected ? "black" : "white"}
      color={isSelected ? "white" : "black"}
      leftIcon={
        isSelected ? (
          <Image src="/assets/svgs/white-ok.svg" boxSize="1em" />
        ) : null
      }
      variant="solid"
      border={isSelected ? "none" : "1px solid black"}
      size={size}
      m={1}
      // isDisabled={isSelected} // Disable button if it's prepopulated
    >
      {examvalue}
    </Button>
  ) : (
    <HStack
      spacing={0}
      border="1px solid black"
      borderRadius="md"
      p={0}
      alignItems="center"
      color={selected === "-" ? "white" : selected === "+" ? "white" : "black"}
      bg={
        selected === "-"
          ? "#f5a947"
          : selected === "+"
            ? "#f55b47"
            : selected === "center"
              ? "white"
              : selected === ""
                ? "white"
                : "transparent"
      }
    >
      {/* Plus button */}
      <Text
        as="button"
        // onClick={() => handleSelectCorner("+")}
        onClick={() => handleSelectPlusIndicator("+")}
        color={selected === "+" ? "white" : "black"}
        bg={selected === "+" ? "#f55b47" : "transparent"}
        borderRadius="md"
        p={1}
        _hover={{ cursor: "pointer" }}
      >
        +
      </Text>

      {/* Center button */}
      <Button
        variant="solid"
        size={size}
        // onClick={() => handleSelectCenter("center")}
        bg={
          selected === "center"
            ? "black"
            : selected === "+"
              ? "#f55b47"
              : selected === "-"
                ? "#f5a947"
                : "white"
        }
        color={selected === "center" ? "white" : "black"}
        _hover={{
          bg:
            selected === "center"
              ? "black"
              : selected === "+"
                ? "#f55b47"
                : selected === "-"
                  ? "#f5a947"
                  : "white", // Disable hover background change
          color: selected === "center" ? "white" : "black", // Disable hover text color change
        }}
      >
        {examvalue}
      </Button>

      {/* Minus button */}
      <Text
        as="button"
        onClick={() => handleSelectMinusIndicator("-")}
        // onClick={() => handleSelectCorner("-")}
        color={selected === "-" ? "white" : "black"}
        bg={selected === "-" ? "#f5a947" : "transparent"}
        borderRadius="md"
        p={1}
        _hover={{ cursor: "pointer" }}
      >
        -
      </Text>
    </HStack>
  );
};

export default DoctorPhysicalExamCard;
