export default function processVaccinationsData(vaccinations, visitDate) {
  if (!Array.isArray(vaccinations)) {
    return [];
  }

  const filteredData = visitDate
    ? vaccinations.filter(
        (vaccine) => vaccine.admindate.split("T")[0] === visitDate.split("T")[0]
      )
    : vaccinations;

  const processedData = filteredData.map((vaccine) => {
    return {
      id: vaccine.id,
      name: vaccine.vaccinename,
      isactive: vaccine.isactive,
      adminDate: vaccine.admindate,
      dueDate: vaccine.duedate,
      vaccine_cd: vaccine.vaccine_cd,
      attachments: vaccine.documents || [],
    };
  });

  // Sort the processed data by admin date in ascending order
  processedData.sort((a, b) => new Date(b.adminDate) - new Date(a.adminDate));

  return processedData;
}
