import React from "react";
import { Box, Text, Image } from "@chakra-ui/react";
import moment from "moment";

const statusColorMap = {
  Ok: "green.500",
  Low: "orange.300",
  High: "red.500",
};

const statusIconMap = {
  Ok: "/assets/svgs/ok.svg",
  Normal: "/assets/svgs/ok.svg",
  Low: "/assets//svgs/low.svg",
  High: "/assets//svgs/high.svg",
};

const formatDate = (dateString) => {
  if (!dateString) return "-";
  return moment(dateString).format("Do MMM, YYYY");
};

const InvestigationsDisplayCard = ({
  vitalName,
  vitalValue,
  vitalUnit,
  vitalDate,
  vitalStatus,
  status,
  trendColor
}) => {
  const statusColor = statusColorMap[vitalStatus] || "gray.500";
  const statusIcon = statusIconMap[vitalStatus] || "";

  // console.log(status, trendColor)

  return (
    <Box
      border="1px"
      borderColor="#E6E7E9"
      borderRadius="md"
      p={4}
      minW="8.75rem"
      backgroundColor={"white"}
    >
      <Text fontSize="12px" fontWeight="500" color="#6A707D">
        {vitalName}
      </Text>
      <Box display="flex" alignItems="baseline">
        <Text fontSize={{ base: "18px", md: "22px" }} fontWeight="600">
          {vitalValue}
        </Text>
        <Text fontSize={{ base: "12px", md: "15px" }} pl="5px" fontWeight="400">
          {vitalUnit}
        </Text>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt="0.5rem"
      >
        <Text fontSize="12px" fontWeight="500" color="#6A707D">
          {formatDate(vitalDate)}
        </Text>
        {/* <Box display="flex" alignItems="center" gap="0.25rem">
          {statusIcon && <Image w="12px" h="auto" src={statusIcon} />}
          <Text as="span" fontSize="12px" fontWeight="600" color={statusColor}>
            {vitalStatus}
          </Text>
        </Box> */}
        {status === "low" &&
        trendColor === "red" ? (
          <Box display="flex" alignItems="center" transform="scaleY(-1)">
            <Image
              w="12px"
              h="auto"
              src="/assets/imgs/red-chart.png"
              alt="Normal"
            />
          </Box>
        ) : status === "high" &&
          trendColor === "red" ? (
          <Box display="flex" alignItems="center">
            <Image
              w="12px"
              h="auto"
              src="/assets/imgs/red-chart.png"
              alt="Normal"
            />
          </Box>
        ) : status === "low" &&
          trendColor === "green" ? (
          <Box display="flex" alignItems="center">
            <Image
              w="12px"
              h="auto"
              src="/assets/imgs/green-chart.png"
              alt="Normal"
            />
          </Box>
        ) : status === "high" &&
          trendColor === "green" ? (
          <Box display="flex" alignItems="center" transform="scaleY(-1)">
            <Image
              w="12px"
              h="auto"
              src="/assets/imgs/green-chart.png"
              alt="Normal"
            />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default InvestigationsDisplayCard;
