import {
  Box,
  Flex,
  Image,
  Step,
  StepDescription,
  StepIndicator,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FetchVisitDetails } from "../../middleware/services/messages";
import moment from "moment";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import { useLocation, useNavigate } from "react-router-dom";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
// import VisibleSidebar from "../../components/visible-sidebar";


const PatientVisitsIndex = ({id, backFunction}) => {

  const location = useLocation();
  const [patientid, setPatientid] = useState("");
  const [visitData, setVisitData] = useState(null);
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const navigate = useNavigate()

  useEffect(() => {
    const currentUrl = window.location.href;
    const searchParams = new URLSearchParams(currentUrl.split('?') [1]);
    const id = searchParams.get('id');
    setPatientid(id);
    
    fetchvisitsdata(id)   
  }, []);
  
  const fetchvisitsdata = async (id) => {
    showLoader()
        try {
          const result = await FetchVisitDetails({
            patientid: id,
          });

          if (result != null) {
            console.log(result)
            setVisitData(result);
          } else {
          }
          dismissLoader()
      } catch (error) {
          console.error("Error in home:", error);
        }
      };

      const formatDate = (dateString) => {
        if (!dateString) return "-";
        return moment(dateString).format("Do MMM, YYYY");
      };

      const handleClick = async (visit) => {
        // if (visit && visit.appointmentid) {
          navigate(
            `/visitdetails?patientId=${patientid}&appointmentId=${visit}`
          );
        // } else {
        //   console.error("Error: Visit details are incomplete.");
        // }
      };

      // console.log(patientid, "Visit IDDDDD")
      // console.log(visitData, "Visit Dataaaaaaa")

  return (
    <>
      {isLoading && <Loader />}
      <Box w="100%" display={{base: "none", lg: "block"}}>
        <Stepper
          orientation="vertical"
          height="400px"
          size="xs"
          colorScheme="#CDCFD4"
          gap="0"
          w="100%"
        >
          {visitData && visitData.length>0 ? (
          visitData.map((visit,index) => (
            <Step w="100%" pb="1rem">
              <StepIndicator color="#E6E7E9" />

              <Box flexShrink="0" w="95%">
                {/* <StepTitle fontSize="0.875rem" color="#384052" mb="0.5rem">
                  {formatDate(visit.appointmentdate)}
                </StepTitle> */}
                <StepTitle as="div">
                <Box display='flex' gap='1rem'>
                    <Text fontWeight='600' fontSize="0.875rem" color="#384052" mb="0.5rem">{formatDate(visit.appointmentdate)}</Text>
                    {/* <Box w='50px' h='25px' border='1px solid #CDCFD4' borderRadius='20px' textAlign='center'>
                    <Text 
                    as='button' 
                    fontWeight='500' 
                    fontSize="0.875rem" 
                    color="#384052"
                    onClick={() => handleClick(visit.appointmentid)}
                    >View</Text>
                    </Box> */}
                  </Box>
                </StepTitle>
                <StepDescription
                  bg="white"
                  w="100%"
                  border="1px solid #E6E7E9"
                  borderRadius="0.65rem"
                  p="0.75rem"
                >
                  <Flex justifyContent='space-between' alignItems='center'>
                    <Text fontWeight='600' fontSize="0.875rem" color="#384052" mb="0.5rem">{`${visit.doctorname ? visit.doctorname: ("-")} , ${visit.bookingid ? visit.bookingid : ('-')}`}</Text>
                    <Image cursor='pointer' src="/assets/imgs/right-icon.png" onClick={() => handleClick(visit.appointmentid)} />
                  </Flex>
                  <Box
                    display="flex"
                    // justifyContent="flex-end"
                    alignItems="center"
                    p="0.5rem"
                    pl="0"
                  >
                    <Text fontSize="0.875rem" color="#384052" mb="0.5rem">{visit.specality}</Text>     
                    {/* <Text fontSize="0.875rem" color="#061027">
                      Visited general physician
                      Visited {visit.specality}
                    </Text> */}
                  </Box>
                  <Box bg="#F9FAFB" borderLeft="2px solid #14C585" p="0.5rem">
                    <Text fontSize="0.875rem" fontWeight="500">
                      {/* Reason: Headache, stomach ache, coughing and vomiting */}
                      Reason: {visit.chiefcomplaint}
                    </Text>
                  </Box>
                  {/* <Box display="flex" p="0.5rem" pl="0" alignItems="center">
                    <Image w="0.75rem" h="auto" src="/assets/svgs/update.svg" />
                    <Text
                      fontSize="0.875rem"
                      fontWeight="500"
                      color="#6A707D"
                      pl="0.5rem"
                    >
                      Reason: Headache, stomach ache, coughing and vomiting
                      {''}
                    </Text>
                  </Box> */}
                </StepDescription>
              </Box>
          <StepSeparator colorScheme="red" />

            </Step>
        ))
          ) : (
            <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            gap="10px"
                            alignItems="center"
                            h="calc(100vh - 100px)"
                            w="100%"
                          >
                            <Box textAlign="left" fontSize="16px" fontWeight="600">
                              <Image
                                w="26px"
                                opacity="0.5"
                                src="/assets/imgs/visit.png"
                              />
                            </Box>
                            <Text textAlign="left" fontSize="12px" fontWeight="500">
                              No Data available
                            </Text>
                          </Box>
          )}
 
        </Stepper>
      </Box>

      {/* Mobile responsive */}
      <Box w="100%" display={{base: "block", lg: "none"}}>
      <MobileSecondaryNav
        header="Visits"
        handlebackarrow={backFunction}
      />
      <Box h="calc(100vh - 120px)" overflow="hidden auto" p="1rem">
        <Stepper
            orientation="vertical"
            height="400px"
            size="xs"
            colorScheme="#CDCFD4"
            gap="0"
            w="100%"
          >
            {visitData && visitData.length>0 ? (
            visitData.map((visit,index) => (
              <Step w="100%" pb="1rem">
                <StepIndicator color="#E6E7E9" />

                <Box flexShrink="0" w="95%">
                  <StepTitle fontSize="0.875rem" color="#384052" mb="0.5rem">
                    {/* 10th Jan, 2024 */}
                    {formatDate(visit.appointmentdate)}
                  </StepTitle>
                  <StepDescription
                    bg="white"
                    w="100%"
                    border="1px solid #E6E7E9"
                    borderRadius="0.65rem"
                    p="0.75rem"
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      p="0.5rem"
                      pl="0"
                    >
                      <Text fontSize="0.875rem" color="#061027">
                        {/* Visited general physician */}
                        Visited {visit.specality}
                      </Text>
                      <Image src="/assets/imgs/right-icon.png" />
                    </Box>
                    <Box bg="#F9FAFB" borderLeft="2px solid #14C585" p="0.5rem">
                      <Text fontSize="0.875rem" fontWeight="500">
                        {/* Reason: Headache, stomach ache, coughing and vomiting */}
                        Reason: {visit.chiefcomplaint}
                      </Text>
                    </Box>
                    <Box display="flex" p="0.5rem" pl="0" alignItems="center">
                      {/* <Image w="0.75rem" h="auto" src="/assets/svgs/update.svg" /> */}
                      <Text
                        fontSize="0.875rem"
                        fontWeight="500"
                        color="#6A707D"
                        pl="0.5rem"
                      >
                        {/* Reason: Headache, stomach ache, coughing and vomiting */}
                        {''}
                      </Text>
                    </Box>
                  </StepDescription>
                </Box>
            <StepSeparator colorScheme="red" />

              </Step>
          ))
            ) : (
              <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                              gap="10px"
                              alignItems="center"
                              h="calc(100vh - 100px)"
                              w="100%"
                            >
                              <Box textAlign="left" fontSize="16px" fontWeight="600">
                                <Image
                                  w="26px"
                                  opacity="0.5"
                                  src="/assets/imgs/visit.png"
                                />
                              </Box>
                              <Text textAlign="left" fontSize="12px" fontWeight="500">
                                No Data available
                              </Text>
                            </Box>
            )}
  
        </Stepper>
      </Box>
      </Box>
    </>
  );
};

export default PatientVisitsIndex;
