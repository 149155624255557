import formAPIrole from "./doctorrole";


var formattedOutput = null;

const formAPIModelrole = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIrole(item));


  return formattedOutput
};

export default formAPIModelrole