import { Box, Center, Flex, Grid, Image, SimpleGrid, VStack, useToast } from '@chakra-ui/react'
import React,{useState,useEffect, useRef} from 'react'
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import { PrimaryButton, PrimaryInput } from '../../components'
import { SubmitDoctorSpeciality, FetchDoctorSpecialities } from '../../middleware/services/finalsteps';
import Toaster from "../../components/toaster";
import { signUpProfessionalDetails } from '../../middleware/services/signup';
import DynamicModal from '../../components/dynamic-modal';
import { COLORS } from '../../components/styles/colors';


const Speciality = ({onSubmit}) => {
  const { isLoading, showLoader, dismissLoader } = useLoader();
    const [data, setData] = useState("");
    const [selectedOptions, setSelectedOptions] = useState([]);
  const [doctorid, setDoctorid] = useState('')
    const { showSuccessToast, showErrorToast } = Toaster();
    const toast = useToast();

    const editCustomSpeciality = useRef();
    const [editedSpecialityName, setEditedSpecialityName] = useState("");
  const [specialityList, setSpecialityList] = useState([])
  const [showCustomField, setShowCustomField] = useState(false);




  const handleOptionToggle = (text, name) => {
    if (text === 16 && name === 'Other') {
      setShowCustomField(true);
    }
  
    setSelectedOptions(prevOptions => {
      const isSelected = prevOptions.includes(text);
      const updatedOptions = isSelected 
        ? prevOptions.filter(option => option !== text) 
        : [...prevOptions, text];
      
      // Store updated selected options in local storage
      localStorage.setItem('selectedSpecialities', JSON.stringify(updatedOptions));
  
      return updatedOptions;
    });
  };
    const findDoctor = [
        {
          "imageUrl": "/assets/imgs/cardiology-final.png",
          "text": "Cardiology",
          "url": "/orthopedist"
        },
        {
          "imageUrl": "/assets/imgs/dentist-final.png",
          "text": "Dentist",
          "url": "/dentist"
        },
        {
          "imageUrl": "/assets/imgs/endocrinology-final.png",
          "text": "Endocrinology",
          "url": "/endocrinology"
        },
        {
          "imageUrl": "/assets/imgs/family-medicine-final.png",
          "text": "Family medicine",
          "url": "/family-medicine"
        },
        {
          "imageUrl": "/assets/imgs/gastroenterology-final.png",
          "text": "Gastroenterology",
          "url": "/gastroenterology"
        },
        {
          "imageUrl": "/assets/imgs/neurology-final.png",
          "text": "Neurology",
          "url": "/neurology"
        },
        {
          "imageUrl": "/assets/imgs/gynecology-final.png",
          "text": "Gynecology",
          "url": "/gynecology"
        },
        {
          "imageUrl": "/assets/imgs/nephrology-final.png",
          "text": "Nephrologist",
          "url": "/nephrologist"
        },
        {
          "imageUrl": "/assets/imgs/oncology-final.png",
          "text": "Oncology",
          "url": "/oncology"
        },
        {
          "imageUrl": "/assets/imgs/ophthalmology-final.png",
          "text": "Ophthalmology",
          "url": "/ophthalmology"
        },
        {
          "imageUrl": "/assets/imgs/orthopedics-final.png",
          "text": "Orthopedics",
          "url": "/orthopedics"
        },
        {
          "imageUrl": "/assets/imgs/pediatrics-final.png",
          "text": "Pediatrics",
          "url": "/pediatrics"
        },
        {
          "imageUrl": "/assets/imgs/psychiatry-final.png",
          "text": "Psychiatry",
          "url": "/psychiatry"
        },
        {
          "imageUrl": "/assets/imgs/pulmonology-final.png",
          "text": "Pulmonology",
          "url": "/pulmonology"
        },
        {
          "imageUrl": "/assets/imgs/surgery-final.png",
          "text": "Surgery",
          "url": "/surgery"
        },
        // {
        //   "imageUrl": "/assets/imgs/other-final.png",
        //   "text": "Other",
        //   "url": "/other"
        // },
      ]
      
      useEffect(() => {
        let doctorId = localStorage.getItem('doctorid');
        setDoctorid(doctorId)

        const choosedoptions = localStorage.getItem("selectedspecialities")
        if (choosedoptions) {
          const selectedOptionsArray = choosedoptions.split(",").map(option => parseInt(option));
          setSelectedOptions(selectedOptionsArray);
        }

        
        FetchSpecialities();
       }, []);
       const FetchSpecialities = async ()=>{
         showLoader()
         try {
           var result = await FetchDoctorSpecialities({
            "type":"speciality"
           });
           if (result) {
             setData(result)
           }
           dismissLoader();
         } catch (error) {
           console.log(error, "Something went wrong in doctor profile")
         }
       }

       const specialityNameChange = (e) => {
        const inpValue = e.target.value;
  const cleanVal = inpValue.replace(/[^a-zA-Z\s,]/g, '');
  setEditedSpecialityName(cleanVal);
      };

      const handleCustomSpecialitySubmit = () => {
        if (!editedSpecialityName.trim()) {
          showErrorToast("Please enter a valid speciality");
          return;
        }
        // saveSpecialityData(specialityInput)
        SubmitFunction()
      };
 
     let input = {"doctorid":"","speciality":[{"specialtyid":1}]}
     
       const SubmitFunction = async () =>{
        if(selectedOptions.length === 0){
          showErrorToast("Please select your speciality.")
          return;
        }else{
            showLoader();
            try{

                localStorage.setItem("selectedspecialities", selectedOptions)
                console.log(selectedOptions)
                const input = {
                  doctorid,
                  // speciality: selectedOptions.map(option => {
                  //     return { specialtyid: option };
                  // })
                  "speciality": selectedOptions.map((option) => {
    if (option === 16) {  // Speciality ID 16 represents "Other"
      return {
        specialtyid: option,
        specialityname: 'Other',
        othervalue: editedSpecialityName // Use the custom edited name
        
      };
    } 
    // else {
      // Return the selected specialities as normal
      const selectedSpeciality = specialityList.find(speciality => speciality.id === option);
      return {
        specialtyid: option,
        specialityname: selectedSpeciality?.specialityname // Use the speciality name from the list
      };
    // }
  })
                }
                localStorage.setItem("specialities",JSON.stringify(input))
        
                const result = await signUpProfessionalDetails(input);
                if(result.output.result == 'Successfully Inserted'){
                    //showSuccessToast("Successfully Updated")
                    setTimeout(() => {
                      const newActiveComponent = "documents";
                      onSubmit(newActiveComponent);
                    }, 1000);
                }else {
                    console.error(result, "Something went wrong in Submit.")
                    showErrorToast("Something went wrong!")
                } 
            } catch (error) {
                console.error('Error in SubmitFunction:', error);
              }
              dismissLoader();
        }
      }
    //    const SubmitFunction = () =>{
    //     if(selectedOptions.length === 0){
    //       showErrorToast("Please select your speciality.")
    //       return;
    //     }
    //     localStorage.setItem("selectedspecialities", selectedOptions)
    //     console.log(selectedOptions)
    //     const input = {
    //       "doctorid":"",
    //       speciality: selectedOptions.map(option => {
    //           return { specialtyid: option };
    //       })
    //     }
    //     localStorage.setItem("specialities",JSON.stringify(input))

    //     const result = await signUpProfessionalDetails(input);
    //     if(result.output.result == 'Successfully Inserted'){
    //         showSuccessToast("Successfully Updated")
    //         setTimeout(() => {
    //           const newActiveComponent = "documents";
    //           onSubmit(newActiveComponent);
    //         }, 1000);
    //     }else {
    //         console.error(result, "Something went wrong in Submit.")
    //         showErrorToast("Something went wrong!")
    //     }
    //   }
  return (
    <>
    <Flex display={{base: "none", lg: "flex"}}>
        {isLoading && <Loader />}
        <Box w="100%" p={4}>
          <Center>
            <VStack spacing={0} w="450px">
              <Box as="p" fontSize="22px" fontWeight="600" mb={5}>
                Select your specialization
              </Box>
                <Box w="100%">
                    <Box w="100%" px="30px" py="10px" mb="20px">
                    <Box w="100%" mb="20px">
                    <Grid
                    templateColumns={{ base: "repeat(auto-fill, minmax(180px, 1fr))", md: "repeat(3, 1fr)" }}
                    gap="20px"
                    alignItems="center"
                    >
                    {data && data.map((profile, index) => (
                        <>
                        <Box display="flex" flexDir="column" alignItems="center">
                            <Box
                            key={index}
                            as="button"
                            // border="2px solid #0C7650"
                            w="7.5rem"
                            h="3.75rem"
                            borderRadius="8px"
                            display="flex"
                            flexDirection="column"
                            bg="#FCFCFD"
                            lineHeight="normal"
                            alignItems="center"
                            justifyContent="center"
                            //onClick={()=> handleselection(profile.id)}
                            border={selectedOptions.includes(profile.id) ? "2px solid #0C7650" : "none"}
                            onClick={() => handleOptionToggle(profile.id, profile.specialityname)}
                            boxShadow="0px 1px 2px 0px #C1C2C680, 0px -8px 12px 0px #D8D8D833 inset"
                            >
                            <Image src={profile.url? profile.imagepath+profile.url : "/assets/imgs/cardiology-final.png"} />
                            </Box>
                            <Box as="p" color="black" mt="8px" textAlign="center" fontSize="14px" whiteSpace="nowrap" fontWeight="500">
                                {profile.specialityname}
                            </Box>
                        </Box>
                        </>
                    ))}
                    </Grid>
                    </Box>
                    {
                      showCustomField && (
                      <Box>
                        <PrimaryInput
                          inputPlace="Edit Other Speciality"
                          defaultValue={editedSpecialityName}
                          value={editedSpecialityName}
                          onChange={specialityNameChange}

                        />
                      </Box>
                      )
                    }
                </Box>
              </Box>
              <Box pos='fixed' bottom='0' w='35%' p='1rem'>
                <PrimaryButton buttonText="Continue" id="submitButton" 
                 onClick={SubmitFunction}
                 />
              </Box>
            </VStack>
          </Center>
        </Box>
    </Flex>

    {/* Mobile responsive start */}
    <Flex flexDir="column" h="100vh" display={{base: "flex", lg: "none"}}>
    {isLoading && <Loader />}
        <Box w="100%" p={4}>
          {/* <Center> */}
            <VStack spacing={0} w="100%">
              <Box as="p" fontSize="22px" fontWeight="600" mb={5}>
                Select your specialization
              </Box>
                <Box w="100%">
                    <Box w="100%" px="30px" py="10px" mb="20px">
                    <Box w="100%" mb="20px">
                      <SimpleGrid
                        // templateColumns={{ base: "repeat(auto-fill, minmax(180px, 1fr))", md: "repeat(3, 1fr)" }}
                        columns={2}
                        gap="1rem"
                        alignItems="center"
                        >
                        {data && data.map((profile, index) => (
                            <>
                            <Box display="flex" flexDir="column" alignItems="center">
                                <Box
                                key={index}
                                as="button"
                                // border="2px solid #0C7650"
                                w="7.5rem"
                                h="3.75rem"
                                borderRadius="8px"
                                display="flex"
                                flexDirection="column"
                                bg="#FCFCFD"
                                lineHeight="normal"
                                alignItems="center"
                                justifyContent="center"
                                //onClick={()=> handleselection(profile.id)}
                                border={selectedOptions.includes(profile.id) ? "2px solid #0C7650" : "none"}
                                onClick={() => handleOptionToggle(profile.id, profile.specialityname)}
                                boxShadow="0px 1px 2px 0px #C1C2C680, 0px -8px 12px 0px #D8D8D833 inset"
                                >
                                <Image src={profile.url? profile.imagepath+profile.url : "/assets/imgs/cardiology-final.png"} />
                                </Box>
                                <Box as="p" color="black" mt="8px" textAlign="center" fontSize="14px" whiteSpace="nowrap" fontWeight="500">
                                    {profile.specialityname}
                                </Box>
                            </Box>
                            </>
                        ))}
                        </SimpleGrid>                    
                    </Box>
                </Box>
              </Box>
              <Box w="100%">
                <PrimaryButton buttonText="Continue" id="submitButton" 
                 onClick={SubmitFunction}
                 />
              </Box>
            </VStack>
          {/* </Center> */}
        </Box>
    </Flex>

    <DynamicModal
        ref={editCustomSpeciality}
        modalHeader="Edit Specialization"
        modalWidth="42rem"
        modalBody={
          <>
            <Box>

              <PrimaryInput
                inputPlace="Speciality"
                // defaultValue={personalData.specality}
                // defaultValue={editedSpecialityName}
                value={editedSpecialityName}
                onChange={specialityNameChange}
              />
              
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton buttonText="Update Specialization" 
            onClick={handleCustomSpecialitySubmit} 
            />
            {/* <PrimaryButton buttonText="Update Specialization" onClick={handleSpecialityChanges} /> */}
          </>
        }
      />

    </>
  )
}

export default Speciality

