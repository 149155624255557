import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useRef,
  useEffect,
} from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Image,
  Box,
  Text,
} from "@chakra-ui/react";

const DynamicModalForTwo = forwardRef(
  (
    {
      modalHeader,
      modalBody,
      modalBodyStyle,
      modalFooterBtn,
      footerStyle,
      onClick,
      modalWidth = "28rem",
      modalMaxWidth,
      hasHeader = true,
      hasCloseBtn = true,
      hasFooter = true,
      modalHeight,
      hasBackBtn = false,
      withBackHeader,
      backOnClick,
    },
    ref
  ) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    // useImperativeHandle(ref, () => ({
    //   openModal: () => onOpen(),
    //   closeModal: () => onClose(),
    // }));
    const [open, setOpen] = useState(false);

    useImperativeHandle(ref, () => ({
      openModal: () => {
        setOpen(true);
        onOpen();
      },
      closeModal: () => {
        setOpen(false);
        onClose();
      },
      // closeRight: (callback) => {
      //   setIsOpen(false);
      //   if (callback && typeof callback === 'function') {
      //     callback();
      //   }
      // }
    }));
    const bodyRef = useRef(document.body);

    useEffect(() => {
      if (open) {
        bodyRef.current.style.overflow = "hidden";
        bodyRef.current.style.touchAction = "none";
      } else {
        bodyRef.current.style.overflow = "";
        bodyRef.current.style.touchAction = "";
      }
    }, [open]);

    return (
      <>
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          isCentered
          blockScrollOnMount={false}
        >
          <ModalOverlay backdropFilter="blur(10px)" />
          <ModalContent
            maxW={modalMaxWidth ? "max-content" : ""}
            w={modalWidth}
            h={modalHeight}
            borderRadius="0.75rem"
          >
            {hasBackBtn && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="1rem"
                borderBottom="1px solid #E6E7E9"
              >
                <Box as="button" onClick={backOnClick}>
                  <Image src="/assets/imgs/back-arrow.png" />
                </Box>
                <Text fontSize="1.125rem" fontWeight="600">
                  {withBackHeader}
                </Text>
                <ModalCloseButton pos="unset" />
              </Box>
            )}

            {hasHeader && (
              <ModalHeader
                display={hasBackBtn ? "none" : ""}
                fontSize="1.125rem"
                fontWeight="600"
                borderBottom="1px solid #E6E7E9"
              >
                {modalHeader}
              </ModalHeader>
            )}

            {hasCloseBtn && (
              <ModalCloseButton display={hasBackBtn ? "none" : ""} />
            )}
            <ModalBody p="1.5rem" {...modalBodyStyle}>
              {modalBody}
            </ModalBody>

            {hasFooter && (
              <ModalFooter
                p="1.5rem"
                borderTop="1px solid #E6E7E9"
                bg="white"
                borderRadius="0 0 12px 12px"
                {...footerStyle}
              >
                {modalFooterBtn}
              </ModalFooter>
            )}
          </ModalContent>
        </Modal>
      </>
    );
  }
);

export default DynamicModalForTwo;
