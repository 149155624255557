import React, { forwardRef, useImperativeHandle } from 'react';
import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, Image, Box, Text } from '@chakra-ui/react';

const DynamicModal = forwardRef(({modalHeader,modalBody,modalBodyStyle,modalFooterBtn,footerStyle, onClick, modalWidth = "28rem",modalMaxWidth, hasHeader = true, hasCloseBtn = true, hasFooter = true, modalHeight, hasBackBtn = false, withBackHeader, backOnClick }, ref) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useImperativeHandle(ref, () => ({
    openModal: () => onOpen(),
    closeModal: () => onClose(),
  }));

  return (
    <>
      <Modal isOpen={isOpen}  onClose={onClose} isCentered> 
        <ModalOverlay backdropFilter="blur(10px)" />
        <ModalContent maxW={modalMaxWidth ? "max-content" : ""} w={modalWidth} h={modalHeight} borderRadius="0.75rem">

          {hasBackBtn && (
            <Box display="flex" justifyContent="space-between" alignItems="center" p="1rem" borderBottom="1px solid #E6E7E9">
              <Box as='button' onClick={backOnClick}><Image src="/assets/imgs/back-arrow.png" /></Box>
              <Text fontSize="1.125rem" fontWeight="600">{withBackHeader}</Text>
              <ModalCloseButton pos="unset" />
            </Box>
          )}

          {hasHeader && (
            <ModalHeader display={hasBackBtn ? "none" : ""} fontSize="1.125rem" fontWeight="600" borderBottom="1px solid #E6E7E9">{modalHeader}</ModalHeader>
          )}

          {hasCloseBtn && (
            <ModalCloseButton display={hasBackBtn ? "none" : ""} />
          )}
          <ModalBody p="1.5rem" {...modalBodyStyle}>
            {modalBody}
          </ModalBody>

          {hasFooter && (

            <ModalFooter p="1.5rem" borderTop="1px solid #E6E7E9" bg="white" borderRadius="0 0 12px 12px" {...footerStyle}>
                {modalFooterBtn}
          </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    </>
  );
});

export default DynamicModal;
