import moment from "moment";

// -------------------------------------------Calendar on Visits Page--------------------------------------

/**
 * Maps the keys of appointment objects to the event format.
 *
 * @param {Array} appointments - The array of appointment objects to be mapped.
 * @returns {Array} - A new array (event array) with the mapped appointment objects.
 */
export function mapAppointmentDetails(appointments) {
  const keyMapping = {
    patientname: "title",
    appointmenttime: "start",
    patientid: "patientid",
    appointmentdate: "date",
    appointmentid: "appointmentid",
  };

  // Map each appointment object to a new object with the updated keys
  return appointments.map((appointment) => {
    const mappedAppointment = {};

    for (const oldKey in appointment) {
      if (keyMapping.hasOwnProperty(oldKey)) {
        const newKey = keyMapping[oldKey];
        mappedAppointment[newKey] = appointment[oldKey];
      }
    }

    // Add cancelled property if appointmentstatus is 2
    if (appointment.appointmentstatus === 2) {
      mappedAppointment.cancelled = true;
    } else {
      mappedAppointment.cancelled = false; // Optional: to explicitly set cancelled to false for other statuses
    }

    if (appointment.appointmentstatus === 4) {
      mappedAppointment.noshow = true;
    } else {
      mappedAppointment.noshow = false;
    }

    if (appointment.appointmentstatus === 3) {
      mappedAppointment.completed = true;
    } else {
      mappedAppointment.completed = false;
    }

    return mappedAppointment;
  });
}

// -------------------------------------------Side Panel on Visits Page--------------------------------------

/**
 * Used for Side Panel in Visits page
 * Adds additional properties to each appointment object and sorts the array.
 *
 * @param {Array} appointments - The array of appointment objects.
 * @param {boolean} reverseSort - Flag to indicate if the sorting should be in reverse order.
 * @returns {Array} - A new array with appointment objects having additional properties and sorted accordingly.
 */
export function enhanceAndSortAppointmentDetails(
  appointments,
  reverseSort = false
) {
  const enhancedAppointments = appointments.map((appointment) => {
     const visitDate = new Date(`${appointment.appointmentdate}T00:00:00`);
    // const formattedVisitDate =
    //   visitDate.getDate() +
    //   getDaySuffix(visitDate.getDate()) +
    //   " " +
    //   getMonthName(visitDate.getMonth()) +
    //   ", " +
    //   visitDate.getFullYear();

      const [year, month, day] = appointment.appointmentdate.split("-");
      const formattedVisitDate =
  parseInt(day, 10) + getDaySuffix(day) + " " +
  getMonthName(parseInt(month)-1) + ", " + year;
    const formattedVisitTime = formatTime(appointment.appointmenttime);
    const today = isToday(visitDate);
    const tomorrow = isTomorrow(visitDate);
    const cancelled = appointment.appointmentstatus === 2;
    const completed = appointment.appointmentstatus === 3;
    const noshow = appointment.appointmentstatus === 4;
    const color = getColor(today, tomorrow, cancelled, completed, noshow);

    return {
      ...appointment,
      visitDate: formattedVisitDate,
      visitTime: formattedVisitTime,
      today: today,
      tomorrow: tomorrow,
      cancelled: cancelled,
      completed: completed,
      color: color,
    };
  });

  // Sort the enhanced appointments array by increasing time and date or in reverse order if reverseSort is enabled
  enhancedAppointments.sort((a, b) => {
    const dateComparison =
      new Date(a.appointmentdate) - new Date(b.appointmentdate);
    if (dateComparison !== 0) {
      return reverseSort ? dateComparison * -1 : dateComparison;
    } else {
      const timeA = new Date(a.appointmenttime);
      const timeB = new Date(b.appointmenttime);
      return reverseSort ? (timeA - timeB) * -1 : timeA - timeB;
    }
  });

  return enhancedAppointments;
}

// Helper function to get the suffix for the day (e.g., "st", "nd", "rd", "th")
function getDaySuffix(day) {
  if (day >= 11 && day <= 13) {
    return "th";
  }
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

function getMonthName(month) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return months[month];
}

// Helper function to format time to 12-hour format (e.g., "09:00 AM")
function formatTime(time) {
  const date = new Date(time);
  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  return formattedTime;
}

function isToday(date) {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}

function isTomorrow(date) {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return (
    date.getDate() === tomorrow.getDate() &&
    date.getMonth() === tomorrow.getMonth() &&
    date.getFullYear() === tomorrow.getFullYear()
  );
}

function getColor(today, tomorrow, cancelled, completed, noshow) {
  if (cancelled) return "red";
  if (completed) return "gray";
  if (noshow) return "#f78003";
  if (today) return "green";
  if (tomorrow) return "blue";
  return "blue"; // Default color
}

// -------------------------------------------For API call of current month (for side panel) on Visits Page--------------------------------------

export function getCurrentMonthDates() {
  const currentDate = moment();
  const startDate = currentDate.startOf("month").format("YYYY-MM-DD");
  const endDate = currentDate.endOf("month").format("YYYY-MM-DD");

  // console.log(startDate);
  // console.log(endDate);

  return [startDate, endDate];
}

// -------------------------------------------For API call of Calendar on Visits Page--------------------------------------

export function parseDateString(inputString) {
  // Define the months for mapping month names to their numerical representation
  const months = {
    Jan: 0,
    January: 0,
    Feb: 1,
    February: 1,
    Mar: 2,
    March: 2,
    Apr: 3,
    April: 3,
    May: 4,
    Jun: 5,
    June: 5,
    Jul: 6,
    July: 6,
    Aug: 7,
    August: 7,
    Sep: 8,
    September: 8,
    Oct: 9,
    October: 9,
    Nov: 10,
    November: 10,
    Dec: 11,
    December: 11,
  };

  // Split the input string by comma and hyphen, then reverse the array
  const parts = inputString.split(/[\s,-]+/).reverse();

  // Extract the year from the input string
  const year = parseInt(parts.find((part) => /^\d{4}$/.test(part)));

  // Check if year is found
  if (!year) {
    return {
      calendarStart: null,
      calendarEnd: null,
    };
  }

  // Find the first occurrence of a month in the reversed parts
  const monthPart = parts.find((part) => months[part]);

  // Check if month is found
  if (!monthPart) {
    return {
      calendarStart: null,
      calendarEnd: null,
    };
  }

  const month = months[monthPart];
  const monthString = `${month + 1 < 10 ? "0" : ""}${month + 1}`;

  const monthStart = "01";
  const lastDayOfMonth = new Date(year, month + 1, 0).getDate();
  const monthEnd = `${lastDayOfMonth < 10 ? "0" : ""}${lastDayOfMonth}`;

  // Return the parsed dates
  return {
    calendarStart: `${year}-${monthString}-${monthStart}`,
    calendarEnd: `${year}-${monthString}-${monthEnd}`,
  };
}

export function getStartAndEndDate(dateString) {
  const moment = require("moment");

  let calendarStart, calendarEnd;

  // Check if it's a month view
  if (moment(dateString, "MMMM YYYY", true).isValid()) {
    calendarStart = moment(dateString, "MMMM YYYY")
      .startOf("month")
      .format("YYYY-MM-DD");
    calendarEnd = moment(dateString, "MMMM YYYY")
      .endOf("month")
      .format("YYYY-MM-DD");
  }
  // Check if it's a week view
  else if (dateString.match(/–/)) {
    let [start, end] = dateString.split("–").map((d) => d.trim());

    // Extract the year from the end date if present
    let endYear = moment().year(); // Default to current year
    if (/\d{4}/.test(end)) {
      endYear = moment(end, "MMM D, YYYY").year();
    }

    // Parse the start date
    let startDate = moment(start, "MMM D").year(endYear);
    calendarStart = startDate.format("YYYY-MM-DD");

    // Check if the end date contains any alphabets (indicating the presence of a month)
    let endDate;
    if (/[a-zA-Z]/.test(end)) {
      // If end contains alphabets, parse it normally
      endDate = moment(end, "MMM D").year(endYear);
    } else {
      // If end does not contain alphabets, assume same month as start date
      endDate = moment(end, "D").month(startDate.month()).year(endYear);
    }

    calendarEnd = endDate.format("YYYY-MM-DD");
    // console.log("Start Date:", calendarStart);
    // console.log("End Date:", calendarEnd);
  }

  // Check if it's a day view
  else if (moment(dateString, "MMMM D, YYYY", true).isValid()) {
    calendarStart = moment(dateString, "MMMM D, YYYY").format("YYYY-MM-DD");
    calendarEnd = calendarStart;
  } else {
    throw new Error("Invalid date format");
  }

  return { calendarStart, calendarEnd };
}
