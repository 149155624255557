import { Box, Image, InputGroup, Text, VStack } from "@chakra-ui/react";
import React,{ useEffect, useState } from "react";
import PrimaryInput from "../../components/primary-input";
import PrimaryButton from "../../components/primary-button";
import PasswordInput from "../../components/password-input";
import { useNavigate } from "react-router";
import Loader from "../../components/loader/loader";
import useLoader from "../../components/loader/loaderstates";
import { Toaster } from "../../components";
import {
  FetchLogin,
  passwordupdate
} from "../../middleware/services/login/index";
import { SubmitLastCallofFinalStep } from "../../middleware/services/finalsteps";
import { savecurrency, saveFirstName, saveLastName, saveLoginID, saveToken, saveAddressInfo, saveEmail, saveMobile, saveLogo, saveHospitalName } from "../../auth";


const NewPassword = ({email, isUser , userTypeId}) => {

  const [patientid, setPatientid] = useState('')
  const [password, setPassword] = useState('')
  const [epassword, setEpassword] = useState('')
  const [disableBtn , setDisableBtn] = useState(true)
  
  const { showSuccessToast, showErrorToast } = Toaster();
  const { isLoading, showLoader, dismissLoader } = useLoader();

  useEffect(()=>{
    if (password == '' || epassword == ''){
     setDisableBtn(true)
   
    }else {
     setDisableBtn(false)
    }
  },[password, epassword])

  const handlePasswordChange = (e, inputType) => {
    const newPassword = e.target.value;

    const storedPatientId = localStorage.getItem('patientId');

    if (storedPatientId) {
      setPatientid(storedPatientId);
    }
    if (inputType === 'password') {
      setPassword(newPassword);
    } else if (inputType === 'confirmPassword') {
      setEpassword(newPassword);
    }
  };

  let navigate = useNavigate();
  const SubmitFunction = async ()=>{

    if(password == ''){
      showErrorToast("Please Enter Password")
    }else if(epassword == ''){
      showErrorToast("Please Enter Confirm Password")
    }else if(password.length < 6 || epassword.length < 6){
      showErrorToast("Password should contain minimum 6 characters.")
    }else if( password.trim() !== epassword.trim()){
      
      showErrorToast("Password does not match!")
    }else{
      showLoader();
    var input = {
      password, 
      email 
    };
    var result = await passwordupdate(input);
    if(result.output.result === "success"){
     // alert(userTypeId)
      if(userTypeId == 3){ 
        const response = await FetchLogin({
        email,
      //  mobile,
        password,
      });
      const allPagesShowHideMainFunc = ()=>{
        // let pagesAccessObject = {
        //   disablehome: response.data.disablehome,
        //   disablevisits: response.data.disablevisits,
        //   disablemessages: response.data.disablemessages,
        //   disablepatients: response.data.disablepatients,
        //   disableprescriptions: response.data.disableprescriptions,
        //   disablemedicines: response.data.disablemedicines,
        //   disablereports: response.data.disablereports,
        //   disablenotifications: response.data.disablenotifications,
        //   disableroles: response.data.disableroles,
        //   disableusers: response.data.disableusers,
        //   disableinventory : response?.data?.disableinventory,
        //   disablefinance: response?.data?.disablefinance,
        // };
        let pagesAccessObject = {};

        if (response && response.data) {
          // Loop through the keys in the response.data object
          Object.keys(response.data).forEach(key => {
            if (key.startsWith('disable')) {
              // Add each disable key to pagesAccessObject
              pagesAccessObject[key] = response.data[key];
            }
          });
        }
        
        localStorage.setItem("pagesAccess" ,JSON.stringify(pagesAccessObject));
        localStorage.setItem("actionAccess" , JSON.stringify(response.data.screens));
    
       }
       allPagesShowHideMainFunc();
      if (response.data.firstname) {
        saveFirstName(response.data.firstname);
        saveLastName(response.data.lastname)
      }
      // Save the token
      if (response.token) {
        saveToken(response.token);
      }
      if(response.data.currencyname){
        savecurrency(response.data.currencyname)
      }
      if (response.data.loginid) {
        saveLoginID(response.data.loginid);
      }
      if (response.data.addressdetail) {
        saveAddressInfo(response.data.addressdetail);
      }
      if (response.data.hospitallogourl) {
        if(response.data.imagepath){
          saveLogo(response.data.imagepath + response.data.hospitallogourl);
        }
      }
      if (response.data.hospitalname) {
        saveHospitalName(response.data.hospitalname);
      }
     // setTimeout(async() => {
        
          navigate("/verification-success");
    
         // }, 200);

      }
      if(userTypeId == 1 || userTypeId == 2  || userTypeId == 4 ){
        
    let inputforLastCall = {"doctorid":"","firsttimelogin":"0","issignupcompleted":"1"};
        const response = await FetchLogin({
          email,
        //  mobile,
          password,
        });
        if (response.data.firstname) {
          saveFirstName(response.data.firstname);
          saveLastName(response.data.lastname)
        }
        // Save the token
        if (response.token) {
          saveToken(response.token);
        }
        if (response.data.loginid) {
          saveLoginID(response.data.loginid);
        }
        if (response.data.mobileno) {
          saveMobile(response.data.countrycode +" "+ response.data.mobileno);
        }
        if (response.data.email) {
          saveEmail(response.data.email);
        }
        if (response.data.addressdetail) {
          saveAddressInfo(response.data.addressdetail);
        }
        if (response.data.hospitallogourl) {
          if(response.data.imagepath){
            saveLogo(response.data.imagepath + response.data.hospitallogourl);
          }
        }
        if (response.data.hospitalname) {
          saveHospitalName(response.data.hospitalname);
        }
        setTimeout(async() => {
       
      var resultLastCall = await SubmitLastCallofFinalStep(inputforLastCall);
      if(resultLastCall.output.success == 1){
  
        showSuccessToast("Password Updated Successfully");
        window.location.reload();
  
        }
        }, 200);
      }else{
        showSuccessToast("Password Updated Successfully");
    localStorage.removeItem("email")
      
     window.location.reload();
      }
  
      
    }else{
      showErrorToast("something went wrong.")
    }
    
    
  }
  dismissLoader();
  }

  var redirect = useNavigate();

  const handleMobilepassKeyPress = (e) => {
    if (e.key === "Enter") {
      SubmitFunction();
    }
  };
    return (
        <>
          <VStack
            spacing={4}
            p={8}
            m={4}
            width="400px"
            gap="0"
          >
            {isLoading && <Loader />}
            <Box mb="20px">
              <Image
                src="/assets/imgs/forgot-pass.png"
                w="58px"
                h="auto"
                borderRadius="10px"
              />
            </Box>
            <Box as="h1" mb="10px">{isUser ? "Change Password" : "New Password"}</Box>
    
            <Box mb="1.875rem">
              <Text textAlign="center" lineHeight="normal">
                 Create a new password to continue
              </Text>
            </Box>
    
            <InputGroup>
              <PasswordInput 
              variant="defaultSide"
               passInputPlace="Enter New Password"
               onChange={(e) => handlePasswordChange(e, 'password')}
               />
            </InputGroup>

            <InputGroup mb="1.875rem">
              <PasswordInput
               variant="downSide"
                passInputPlace="Re-Enter New Password"
                onChange={(e) => handlePasswordChange(e, 'confirmPassword')}
                onKeyPress={handleMobilepassKeyPress}
                />
            </InputGroup>
            <PrimaryButton colorScheme="blue" width="full" buttonText="Continue" 
            isDisabled={disableBtn} onClick={SubmitFunction}/>
          </VStack>
        </>
      );
}

export default NewPassword