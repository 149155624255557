import React, { useEffect } from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Toaster } from "../../../components";
import moment from 'moment';

const ExcelDownloadQuaterly = ({ data, onDownloadComplete }) => {
  const { showSuccessToast, showErrorToast } = Toaster();
  const currentDate = new Date(); // Get the current date
const options = { month: 'long', year: 'numeric' }; // Define formatting options
const currentMonthYear = currentDate.toLocaleDateString('en-US', options); // Format as "Month Year"


  useEffect(() => {
     handleDownload(data);
    // console.log(data , " data to Download quaterly")
  }, [data]);

  // code woing fine here , with total calculation
  const handleDownload = (exceldata) => {
    if (!exceldata || Object.keys(exceldata).length === 0) {
      console.error("No data to download.");
      showErrorToast("No Data Available!");
      onDownloadComplete();
      return;
    }
  
    const workbook = XLSX.utils.book_new();
    let hasData = false;
  
    // Format the data dynamically for the Excel sheet
   
    const prepareSheetData = (data) => {
      const sheetData = [];
      const allBranches = [];
      const merges = []; // Array to hold merge ranges
  
      // Collect all unique branch names
      if (data && data[0]?.subdiagnosis1?.length > 0) {
          const firstSubdiagnosis = data[0].subdiagnosis1[0]; // Access the first object of subdiagnosis1
          firstSubdiagnosis?.branches?.forEach((branch) => {
                  allBranches.push(branch?.branchname); // Add branch name
          });
      }
  
      // Prepare the header row
      const reportName = [`Monthly Disease wise Consolidated Report ${currentMonthYear}`];
      const emptyRow = [""];
      sheetData.push(reportName);
      merges.push({ s: { r: 0, c: 0 }, e: { r: 0, c: 7 } });
  
      sheetData.push(emptyRow);
      merges.push({ s: { r: 1, c: 0 }, e: { r: 1, c: 7 } });
  
      const header = ["Sub-Diagnose", "Sub-Diagnose1", ...allBranches, "Total", "Percentage"];
      sheetData.push(header);
  
      // Initialize column totals array
      const columnTotals = Array(allBranches.length).fill(0);
      let grandTotal = 0; // Total of all totals across rows
  
      // Prepare the rows
      data?.forEach((diagnose, diagnoseIndex) => {
          const startRow = sheetData.length; // Starting row
  
          let subdiagnoseTotals = Array(allBranches.length).fill(0); // Totals for this subdiagnose
  
          diagnose?.subdiagnosis1?.forEach((sub) => {
              const row = [diagnose?.subdiagnose, sub?.name];
              let rowTotal = 0; // Initialize the row total
  
              // Add branch values in the correct column order and calculate totals
              allBranches?.forEach((branch, index) => {
                  const branchData = sub?.branches.filter((b) => b.branchname === branch);
                  const branchValue = branchData.reduce((sum, b) => sum + b.value, 0);
                  rowTotal += branchValue; // Add to the row total
                  columnTotals[index] += branchValue; // Add to the column total
                  subdiagnoseTotals[index] += branchValue; // Add to subdiagnose total
                  row.push(branchValue);
              });
  
              // Add the row total
              row.push(rowTotal);
              row.push(""); // Empty cell for percentage
              grandTotal += rowTotal; // Add to the grand total
              sheetData.push(row);
          });
  
          // Add a column for Subdiagnose Totals
          const subdiagnoseTotalRow = [
              `No. of total in ${diagnose?.subdiagnose}`, // Label for subdiagnose total
              "",
              ...subdiagnoseTotals, // Subdiagnose totals for each branch
              subdiagnoseTotals.reduce((sum, val) => sum + val, 0), // Total for all branches
              ((subdiagnoseTotals.reduce((sum, val) => sum + val, 0) / grandTotal) * 100).toFixed(1) + "%", // Subdiagnose percentage
          ];
          sheetData.push(subdiagnoseTotalRow);
  
          // Merge cells for the "Diagnose" column if there are multiple rows
          const endRow = sheetData.length - 2; // Ending row index
          if (endRow > startRow) {
              merges.push({ s: { r: startRow, c: 0 }, e: { r: endRow, c: 0 } }); // Merge "Diagnose"
          }
      });
  
      // Prepare the overall totals row
      const totalsRow = ["Total Diagnosis Covered", "", ...columnTotals, grandTotal, ""];
      sheetData.push(totalsRow);
  
      // Add percentages column
      sheetData.forEach((row, rowIndex) => {
          if (rowIndex > 2 && rowIndex < sheetData.length - 1) {
              const totalValue = row[row.length - 2]; // Get the total value from the row
              const percentage = totalValue ? ((totalValue / grandTotal) * 100).toFixed(1) + "%" : "0.0%";
              row[row.length - 1] = percentage; // Set percentage in the last column
          }
      });
  
      // Merge cells for the "Total" label in the first two columns
      merges.push({
          s: { r: sheetData.length - 1, c: 0 },
          e: { r: sheetData.length - 1, c: 1 },
      });
  
      return { sheetData, merges };
  };
    
    
    
  
    // Generate the data and merges for the sheet
    const { sheetData, merges } = prepareSheetData(exceldata);
  
    // Convert to worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
  

// Align the percentage column (last column) to the right
const percentageColumnIndex = sheetData[0].length - 1;  // The index of the Percentage column

sheetData.forEach((row, rowIndex) => {
  const cell = worksheet[XLSX.utils.encode_cell({ r: rowIndex, c: percentageColumnIndex })];
  if (cell) {
    cell.s = { alignment: { horizontal: 'center' } }; // Change 'center' to 'right' if you prefer right alignment
  }
});
    // Apply merges to the worksheet
    worksheet["!merges"] = merges;
  
    // Set column widths dynamically
    worksheet["!cols"] = [
      { wch: 30 }, // First column width (Diagnose)
      { wch: 40 }, // Second column width (Sub-Diagnose)
      ...Array(sheetData[2].length - 2).fill({ wch: 15 }), // Branch columns and Total
    ];
  
    // Add worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet);
  
    hasData = true;
  
    if (!hasData) {
      showErrorToast("No Data Available!");
      onDownloadComplete();
      return;
    }
  
    // Write to Excel file
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(dataBlob, `Monthly Disease wise Consolidated Report ${currentMonthYear}.xlsx`);
  
    showSuccessToast("Download successful!");
    onDownloadComplete();
  };  
  



  
  
  
  

  return null;
};

export default ExcelDownloadQuaterly;
