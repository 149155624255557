import moment from "moment";
export default function formAPIImaging({
    doctorid,
    orderid,
    entityid,
    entitytype,
    appointmentid,
    orderdate,
    ordername,
    doctorname,
    specialization,
    doctorimage,
    bookingid,
    hospital,
    address,
    city,
    state,
    country,
    imagepath
    


}) {
  return Object.freeze({
    doctorid,
    orderid,
    entityid,
    entitytype,
    appointmentid,
    orderdate,
    ordername,
    doctorname,
    specialization,
    doctorimage,
    bookingid,
    hospital,
    address,
    city,
    state,
    country,
    imagepath
    


  });
}