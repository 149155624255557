import {
    Box,
    Image,
    Step,
    StepDescription,
    StepIndicator,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    Text,
  } from "@chakra-ui/react";
  import React, { useEffect, useState } from "react";
  import { FetchPhysicalExamDetails, FetchSocialHistoryDetails } from "../../middleware/services/messages";
  import moment from "moment";
  import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import { useLocation } from "react-router-dom";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import { useScreenType } from "../../auth";
import { wrap } from "framer-motion";


const PhysicalExam = ({id,backFunction}) => {

      const location = useLocation();
      const [patientid, setPatientid] = useState("");
      const [physicalExamData, setPhysicalExamData] = useState(null);
      const { isLoading, showLoader, dismissLoader } = useLoader();
      const screenType = useScreenType()

      useEffect(() => {
        const currentUrl = window.location.href;
        const searchParams = new URLSearchParams(currentUrl.split('?') [1]);
        const id = searchParams.get('id');
        setPatientid(id);
        fetchexamdata(id)   
      }, [location.search]);
      
      const fetchexamdata = async (id) => {
        showLoader()
            try {
              const result = await FetchPhysicalExamDetails({
                patientid: id,
              });
    
              if (result != null) {
                // console.log(result)
                setPhysicalExamData(result);
              } else {
                setPhysicalExamData('')
              }
              dismissLoader()
          } catch (error) {
              console.error("Error in home:", error);
            }
          };
    
          const formatDate = (dateString) => {
            if (!dateString) return "-";
            return moment(dateString).format("Do MMM, YYYY");
          };
    
          // console.log(patientid, "physical exam IDDDDD")
          // console.log(physicalExamData, "physical exam Dataaaaaaa")
    
      return (
        <>
        {isLoading && <Loader />}
        {screenType == 'web' ? (
          <Box w="100%">
            <Stepper
              orientation="vertical"
              height="100%"
              size="xs"
              colorScheme="#CDCFD4"
              gap="0"
              w="100%"
            >
              {physicalExamData && physicalExamData.length>0 ? (
                physicalExamData.map((exam,index) => (
                <Step key={index} w="100%" >
                  <StepIndicator color="#E6E7E9" />
    
                  <Box flexShrink="0" w="95%">
                    {/* <StepTitle fontSize="0.75rem" fontWeight="500" color="#384052" mb="0.5rem">
                      {exam.Date ? formatDate(exam.Date) : '-'}
                    </StepTitle> */}
                    <StepTitle as="div">
                    <Text fontWeight='600' fontSize="0.875rem" color="#384052" mb="0.5rem">{formatDate(exam.DATE)}</Text>
                    <Text fontWeight='600' fontSize="0.875rem" color="#384052" mb="0.5rem">{`${exam.doctorname} , ${exam.bookingid}`}</Text>
                    <Text fontSize="0.875rem" color="#384052" mb="0.5rem">{exam.specialization}</Text>
                  </StepTitle>
                    <StepDescription
                      bg="white"
                      w="100%"
                      border="1px solid #E6E7E9"
                      borderRadius="0.65rem"
                      p="0.75rem"
                      mb="1.5rem"
                      boxShadow="0px 8px 20px 0px #E6E7E966"
                    >
                      <Box w="fit-content" display="flex" gap="0.5rem" flexWrap='wrap' className="textOverflow">
                        <Text fontSize="0.625rem" w="max-content" fontWeight="600" color="#384052" p="0.25rem 0.5rem" bg="#E6E7E966" border="1px solid #E6E7E9" borderRadius="full">
                          {/* Constitutional */}
                          {exam.type}
                        </Text>
                        {exam && exam.physicalexam.length > 0 ? (
                          <Text fontSize="0.875rem" color="#061027">
                            {exam.physicalexam
                              // .map(item => item.value) // existing code
                              .map(item => item.examvalue) 
                              // .filter(Boolean) 
                              .join(', ')} 
                          </Text>
                          ) : ('-')}
                        {/* {exam && exam.physicalexam.length > 0 ? (
                          exam.physicalexam.map((item,subIndex) => (
                        <Text fontSize="0.875rem" color="#061027" key={subIndex}>
                          {item.value ? (item.value +",") : ('')}
                        </Text>
                          ))
                        ) : ('-')} */}
                      </Box>
                    </StepDescription>
                  </Box>
    
                  <StepSeparator colorScheme="red" />
                </Step>

                ))
              ) : (
                <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            gap="10px"
                            alignItems="center"
                            h="calc(100vh - 100px)"
                            w="100%"
                          >
                            <Box textAlign="left" fontSize="16px" fontWeight="600">
                              <Image
                                w="26px"
                                opacity="0.5"
                                src="/assets/imgs/physical-exam.png"
                              />
                            </Box>
                            <Text textAlign="left" fontSize="12px" fontWeight="500">
                              No Data available
                            </Text>
                          </Box>
              )}
            </Stepper>
          </Box>
        ) : (
        //Mobile responsive
        <Box w="100%">
          <MobileSecondaryNav
            header="Physical exam"
            handlebackarrow={backFunction}
          />

          <Box w="100%" h="calc(100vh - 120px)" overflow='hidden auto' p="1rem">
          <Stepper
              orientation="vertical"
              height="100%"
              size="xs"
              colorScheme="#CDCFD4"
              gap="0"
              w="100%"
            >
              {physicalExamData && physicalExamData.length>0 ? (
                physicalExamData.map((exam,index) => (
                <Step key={index} w="100%" >
                  <StepIndicator color="#E6E7E9" />
    
                  <Box flexShrink="0" w="95%">
                    {/* <StepTitle fontSize="0.75rem" fontWeight="500" color="#384052" mb="0.5rem">
                      {exam.Date ? formatDate(exam.Date) : '-'}
                    </StepTitle> */}
                    <StepTitle as="div">
                    <Text fontWeight='600' fontSize="0.875rem" color="#384052" mb="0.5rem">{formatDate(exam.DATE)}</Text>
                    <Text fontWeight='600' fontSize="0.875rem" color="#384052" mb="0.5rem">{`${exam.doctorname} , ${exam.bookingid}`}</Text>
                    <Text fontSize="0.875rem" color="#384052" mb="0.5rem">{exam.specialization}</Text>
                  </StepTitle>
                    <StepDescription
                      bg="white"
                      w="100%"
                      border="1px solid #E6E7E9"
                      borderRadius="0.65rem"
                      p="0.75rem"
                      mb="1.5rem"
                      boxShadow="0px 8px 20px 0px #E6E7E966"
                    >
                      <Box w="fit-content" display="flex" flexDir='column' gap="0.5rem" className="textOverflow">
                        <Text fontSize="0.625rem" w="max-content" fontWeight="600" color="#384052" p="0.25rem 0.5rem" bg="#E6E7E966" border="1px solid #E6E7E9" borderRadius="full">
                          {/* Constitutional */}
                          {exam.type}
                        </Text>
                        {exam && exam.physicalexam.length > 0 ? (
                        <Box display='flex' flexDir='column'>
                          {exam.physicalexam.map((item,subIndex) => (
                        <Text fontSize="0.875rem" color="#061027" key={subIndex}>
                          {/* Chills, Fever and Fatigue */}
                          {item.value},
                        </Text>

                        ))}
                        </Box>
                        ) : ('-')}
                      </Box>
                    </StepDescription>
                  </Box>
    
                  <StepSeparator colorScheme="red" />
                </Step>

                ))
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  gap="10px"
                  alignItems="center"
                  h="calc(100vh - 100px)"
                  w="100%"
                >
                  <Box textAlign="left" fontSize="16px" fontWeight="600">
                    <Image
                      w="26px"
                      opacity="0.5"
                      src="/assets/imgs/physical-exam.png"
                    />
                  </Box>
                  <Text textAlign="left" fontSize="12px" fontWeight="500">
                    No Data available
                  </Text>
                </Box>
              )}
            </Stepper>
          </Box>
        </Box>
      )}
        </>
      );
}

export default PhysicalExam