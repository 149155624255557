import {
    Box,
    Image,
    Step,
    StepDescription,
    StepIndicator,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    Text,
  } from "@chakra-ui/react";
  import React, { useEffect, useState } from "react";
import { FetchReviewSystemsDetails } from "../../middleware/services/messages";
import moment from "moment";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import { useLocation } from "react-router-dom";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";

const ReviewSystem = ({id, backFunction}) => {

  const location = useLocation();
  const [patientid, setPatientid] = useState("");
  const [rosData, setRosData] = useState(null);
  const { isLoading, showLoader, dismissLoader } = useLoader();

  useEffect(() => {
    const currentUrl = window.location.href;
    const searchParams = new URLSearchParams(currentUrl.split('?') [1]);
    const id = searchParams.get('id');
    setPatientid(id);
    fetchreviewsystemdata(id)   
  }, []);
  
  const fetchreviewsystemdata = async (id) => {
    showLoader()
        try {
          const result = await FetchReviewSystemsDetails({
            patientid: id,
          });

          // console.log(result, "pagessss review")

          if (result != null) {
            console.log(result)
            setRosData(result);
          } else {
            setRosData('')
          }
          dismissLoader()
      } catch (error) {
          console.error("Error in home:", error);
        }
      };

      const formatDate = (dateString) => {
        if (!dateString) return "-";
        return moment(dateString).format("Do MMM, YYYY");
      };

      // console.log(patientid, "ROS IDDDDD")
      // console.log(rosData, "ROS Dataaaaaaa")

      return (
        <>
        {isLoading && <Loader />}
          <Box w="100%" display={{base: "none", lg: "block"}}>
            <Stepper
              orientation="vertical"
              height="100%"
              size="xs"
              colorScheme="#CDCFD4"
              gap="0"
              w="100%"
            >
              {rosData && rosData.length>0 ? (
                  rosData.map((ros,index) => (
                <Step w="100%" >
                  <StepIndicator color="#E6E7E9" />
                  <Box flexShrink="0" w="95%" key={index}>
                    {/* <StepTitle fontSize="0.75rem" fontWeight="500" color="#384052" mb="0.5rem">
                      {ros.DATE ? formatDate(ros.DATE) : ('-')}
                    </StepTitle> */}

                    <StepTitle as="div">
                    <Text fontWeight='600' fontSize="0.875rem" color="#384052" mb="0.5rem">{formatDate(ros.DATE)}</Text>
                    <Text fontWeight='600' fontSize="0.875rem" color="#384052" mb="0.5rem">{`${ros.doctorname} , ${ros.bookingid}`}</Text>
                    <Text fontSize="0.875rem" color="#384052" mb="0.5rem">{ros.specialization}</Text>
                  </StepTitle>
                    <StepDescription
                      bg="white"
                      w="100%"
                      border="1px solid #E6E7E9"
                      borderRadius="0.65rem"
                      p="0.75rem"
                      mb="1.5rem"
                      boxShadow="0px 8px 20px 0px #E6E7E966"
                    >
                      <Box w="fit-content" display="flex" gap="0.5rem" flexWrap='wrap' className="textOverflow">
                      <Text fontSize="0.625rem" w="max-content" fontWeight="600" color="#384052" p="0.25rem 0.5rem" bg="#E6E7E966" border="1px solid #E6E7E9" borderRadius="full">
                        {/* Alive */}
                        {ros.rosname}
                      </Text>
                      {ros.rosvalue && ros.rosvalue.length>0 ? (
                        ros.rosvalue.map((item,subIndex) => (
                        <Text fontSize="0.875rem" color="#061027" key={subIndex}>
                        {/* A-fib (CMS/HCC) */}
                        {item.value}
                        </Text>

                        ))
                      ) : ('NULL')}
                      </Box>
                    </StepDescription>
                  </Box>

    
                  <StepSeparator colorScheme="red" />
                </Step>
                  ))

              ) : (
                <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            gap="10px"
                            alignItems="center"
                            h="calc(100vh - 100px)"
                            w="100%"
                          >
                            <Box textAlign="left" fontSize="16px" fontWeight="600">
                              <Image
                                w="26px"
                                opacity="0.5"
                                src="/assets/imgs/protection.png"
                              />
                            </Box>
                            <Text textAlign="left" fontSize="12px" fontWeight="500">
                              No Data available
                            </Text>
                          </Box>
              )}
            </Stepper>
          </Box>

          {/* Mobile responsive */}
          <Box w="100%" display={{base: "block", lg: "none"}}>
            <MobileSecondaryNav
              header="Review of system"
              handlebackarrow={backFunction}
            />
            
            <Box h="calc(100vh - 120px)" overflow='hidden auto' p="1rem">
            <Stepper
              orientation="vertical"
              height="100%"
              size="xs"
              colorScheme="#CDCFD4"
              gap="0"
              w="100%"
            >
              {rosData && rosData.length>0 ? (
                  rosData.map((ros,index) => (
                <Step w="100%" >
                  <StepIndicator color="#E6E7E9" />
                  <Box flexShrink="0" w="95%" key={index}>
                    {/* <StepTitle fontSize="0.75rem" fontWeight="500" color="#384052" mb="0.5rem">
                      {ros.DATE ? formatDate(ros.DATE) : ('-')}
                    </StepTitle> */}

                    <StepTitle as="div">
                    <Text fontWeight='600' fontSize="0.875rem" color="#384052" mb="0.5rem">{formatDate(ros.DATE)}</Text>
                    <Text fontWeight='600' fontSize="0.875rem" color="#384052" mb="0.5rem">{`${ros.doctorname} , ${ros.bookingid}`}</Text>
                    <Text fontSize="0.875rem" color="#384052" mb="0.5rem">{ros.specialization}</Text>
                  </StepTitle>
                    <StepDescription
                      bg="white"
                      w="100%"
                      border="1px solid #E6E7E9"
                      borderRadius="0.65rem"
                      p="0.75rem"
                      mb="1.5rem"
                      boxShadow="0px 8px 20px 0px #E6E7E966"
                    >
                      <Box w="fit-content" display="flex" flexDir='column' gap="0.5rem" className="textOverflow">
                      <Text fontSize="0.625rem" w="max-content" fontWeight="600" color="#384052" p="0.25rem 0.5rem" bg="#E6E7E966" border="1px solid #E6E7E9" borderRadius="full">
                        {/* Alive */}
                        {ros.rosname}
                      </Text>
                      {ros.rosvalue && ros.rosvalue.length>0 ? (
                        <Box display='flex' flexWrap='wrap' gap='0.5rem'>
                          {ros.rosvalue.map((item,subIndex) => (
                          <Text title={item.value} fontSize="0.875rem" color="#061027" key={subIndex}>
                          {/* A-fib (CMS/HCC) */}
                          {item.value},
                          </Text>
                        ))}
                        </Box>
                      ) : ('-')}
                      </Box>
                    </StepDescription>
                  </Box>

    
                  <StepSeparator colorScheme="red" />
                </Step>
                  ))

              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  gap="10px"
                  alignItems="center"
                  h="calc(100vh - 100px)"
                  w="100%"
                >
                  <Box textAlign="left" fontSize="16px" fontWeight="600">
                    <Image
                      w="26px"
                      opacity="0.5"
                      src="/assets/imgs/protection.png"
                    />
                  </Box>
                  <Text textAlign="left" fontSize="12px" fontWeight="500">
                    No Data available
                  </Text>
                </Box>
              )}
            </Stepper>
            </Box>
          </Box>
        </>
      );
}

export default ReviewSystem