import formAPIappointments from "./appointment";


var formattedOutput = null;

const formAPIModelappointments = (data, type, activeTab, selecteddate) => {
  // console.log('selecteddate fourth fiel: ', selecteddate)
  // console.log('type fourth: ', type)
  // console.log('activeTab fourth: ', activeTab)
  // console.log('item.label: ', data)
  // console.log('type: ', type)
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIappointments(item, type, activeTab, selecteddate));
  const transformedData = [];

  formattedOutput.forEach(item => {
    // console.log('item: ', item)
    const completedObj = {
      label: item.label,
      value: item.completed,
      type: "Completed"
  };
  
  // Object for medicine count
  const cancelledObj = {
      label: item.label,
      value: item.canceled,
      type: "Cancelled"
  };

  transformedData.push(completedObj, cancelledObj);
});
return transformedData;
};

export default formAPIModelappointments