import React, { useEffect, useState } from "react";
import { Flex, VStack, Box, Image, Center, useEditable, Text, RadioGroup, Stack, Checkbox, Divider } from "@chakra-ui/react";
import { DropdownInput, PrimaryButton, PrimaryInput, SearchBar, Toaster } from "../../components";
import useLoader from "../../components/loader/loaderstates";
import { signUpProfessionalDetails } from "../../middleware/services/signup";
import Loader from "../../components/loader/loader";
import ModalRight from "../../components/modal-right";
import { getDefaultData } from "../../middleware/services/profile";

const ProfessionalDetails = ({onSubmit}) => {
  const [hospitalname, setHospitalName] = useState('')
  const [degree, setdegree] = useState('')
  const [specialization, setSpecialization] = useState('')
  const [yearsofexperience, setExperience] = useState('')
  const [doctorid, setDoctorid] = useState('')
  
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const { showSuccessToast, showErrorToast } = Toaster();
  
  const [degreeOptions, setDegreeOptions] = useState([]);
  const [editedDegreeName, setEditedDegreeName] = useState("");
   
const [selectedConditions, setSelectedConditions] = useState([]);
const [searchValue, setSearchValue] = useState('');
  const addConditionModalInsideEdit = React.useRef();
  var [conditionsList, setConditionsList] = useState(null);

  const experienceInYears = [];

  for (let i = 0; i <= 80; i++) {
    experienceInYears.push(String(i));
  }
  useEffect(()=>{
   let doctorId =  localStorage.getItem("doctorid")
   setDoctorid(doctorId)

   var storedProfessionalDetails = localStorage.getItem("professionalDetails");

   // Parse the string back into an object
   var detailsFromLocal = JSON.parse(storedProfessionalDetails);
   if(detailsFromLocal){
    setHospitalName(detailsFromLocal.hospitalname)
    setdegree(detailsFromLocal.degree)
//     if(detailsFromLocal?.degree){
//       let options;
//       let getData = async ()=>{
//         const degreesList = await getDefaultData({"type":"degree"});
//         console.log(degreesList , " degrees list ")
//         options = degreesList?.map((item) => ({
//           "label":item.degreename,
//           "id":item.id
//         }))
//       }
   
//       getData();
//       const initialSelectedConditions = detailsFromLocal?.degree.map((degreename) => options.find((option) => option.label === degreename))
//   .filter((condition) => condition); // Remove any undefined entries if no match found

// setSelectedConditions(initialSelectedConditions);
//     }
    // setSpecialization(detailsFromLocal.specialization)
    setExperience(detailsFromLocal.yearsofexperience)
    
     
   }
  },[])


  const handleChangeHospitalName = (event) => {
    let inputValue = event.target.value;
    //inputValue = inputValue.replace(/[^A-Za-z\s]/g, '').slice(0, 50);
    inputValue = inputValue.slice(0, 50);
    event.target.value = inputValue;
    setHospitalName(event.target.value);
  };
  const handleChangedegree = (event) => {
    let inputValue = event.target.value;
    // inputValue = inputValue.replace(/[^A-Za-z\s]/g, '').slice(0, 100);
    inputValue = inputValue.slice(0, 100);
    event.target.value = inputValue;
    setdegree(event.target.value);
  };
  const handleChangeSpecialization = (event) => {
    let inputValue = event.target.value;
    // inputValue = inputValue.replace(/[^A-Za-z\s]/g, '').slice(0, 50);
     inputValue = inputValue.slice(0, 50);
    event.target.value = inputValue;
    setSpecialization(event.target.value);
  };
  const handleExperience = (event) => {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[^0-9]/g, "").slice(0, 3);
     inputValue = inputValue.slice(0, 50);
    event.target.value = inputValue;
    setExperience(event.target.value);
  }
  const handleExpSelect = (selectedOption) => {
    setExperience(selectedOption);
  };

  var inputForSignUpProfessionalDetails = {
    specialization,
    doctorid,
    yearsofexperience,
    hospitalname,
    "degree": selectedConditions.map(item => ({
      "degreeid": item.id,
      "degreename": item.label,
      "othervalue": null
    })),
    //here
    "type": null,
    // "planid": 1,
    // "documents": [
    //   {
    //     "documenturl": "https://example.com/document1.pdf",
    //     "documenttype": "Certificate"
    //   },
    //   {
    //     "documenturl": "https://example.com/document2.pdf",
    //     "documenttype": "License"
    //   }
    // ]
  }


  const SubmitFunction = async () => {
    if(hospitalname == ""){
      showErrorToast("Please enter Hospital/Clinic name")
      return false;
    }else if(selectedConditions.length <= 0 ){
      showErrorToast("Please Select Degree!")
      return false;
    }
    // else if(specialization == ""){
    //   showErrorToast("Please enter a specialization")
    //   return false;
    // }
    else if(yearsofexperience == ""){
      showErrorToast("Please enter years of experience")
      return false;
    }
    else{
      
      showLoader();
      try {
        localStorage.setItem("professionalDetails", JSON.stringify(inputForSignUpProfessionalDetails));
      // console.log(inputForSignUpProfessionalDetails , " inputForSignUpProfessionalDetails")
        const result = await signUpProfessionalDetails(inputForSignUpProfessionalDetails);
       // console.log(result , " result of professiontl details")

        if (result.output.result == "Successfully Inserted") {

          //showSuccessToast("Successfully Updated")
          setTimeout(() => {

            const newActiveComponent = "speciality";
            onSubmit(newActiveComponent);
          }, 1000);

        
        }else {
          console.error(result, "Something went wrong in Submit.")
          showErrorToast("Something went wrong!")
        }

      } catch (error) {
        console.error('Error in SubmitFunction:', error);
      }
      dismissLoader();
      
    }
    
  };
  const submitOnEnter = (e) => {
    if (e.key === "Enter") {
      SubmitFunction();
    }
  };

  const handleDegreeEdit = async (degName) => {
    showLoader();
    setSearchValue("")
    const degreesList = await getDefaultData({"type":"degree"});
    console.log(degreesList , " degrees list ")
    let options = degreesList?.map((item) => ({
      "label": item.degreefullname +" ("+item.degreename+")",
      "id":item.id
    }))
    if(options){
      setConditionsList(options);
      // setDegreeOptions(options)
    }
    setEditedDegreeName(degName);
    // editDegree.current.openModal();
    // Split the degName string and find matching options
//   const initialSelectedConditions = degName
//   .split(', ')
//   .map((name) => options.find((option) => option.label === name))
//   .filter((condition) => condition); // Remove any undefined entries if no match found

// setSelectedConditions(initialSelectedConditions);

   addConditionModalInsideEdit.current.openRight();
   dismissLoader();
  }

const handleCheckboxChangeForEdit = (selectedCondition) => {
  setSelectedConditions((prevSelectedConditions) => {
    const isAlreadySelected = prevSelectedConditions.some(
      (item) => item.id === selectedCondition.id
    );

    if (isAlreadySelected) {
      // If the condition is already selected, remove it
      return prevSelectedConditions.filter(
        (item) => item.id !== selectedCondition.id
      );
    } else {
      // If the condition is not selected, add it
      return [
        ...prevSelectedConditions,
        {
          id: selectedCondition.id,
          label: selectedCondition.label,
        },
      ];
    }
  });
};
const handleDegreeChanges = () => {
//   const sanitizedDegreeName = sanitizeDegreeName(editedDegreeName);

// if (!sanitizedDegreeName.trim()) {
//   showErrorToast("Degree required");
//   return;
// }

const degreeInput = {
  // 'qualification': sanitizedDegreeName,
  degree: selectedConditions.map(item => ({
    "degreeid": item.id,
    "degreename": item.label,
    "othervalue": null
  })),
  doctorid,
};
addConditionModalInsideEdit.current.closeRight();
      
}


  return (
    <>
      <Flex display={{base: "none", lg: "flex"}}>
      {isLoading && <Loader />}
        <Box w="100%" p={4}>
          <Center>
            <VStack spacing={0} w="450px">
              <Box as="p" fontSize="22px" textAlign="center" fontWeight="600" mb={5}>
                Tell us a bit about <br /> your profession
              </Box>
              <PrimaryInput
                variant="defaultSide"
                inputPlace="Hospital/Clinic name*"
                id="hospitalName"
                onChange={handleChangeHospitalName}
                inputValue={hospitalname}
              />
              {/* <PrimaryInput
                variant="bothSide"
                inputPlace="Degree*"
                id="degree"
                onChange={handleChangedegree}
                
                inputValue={degree}
              /> */}
              <Box
                  as="div"
                  role="button"
                  aria-haspopup="dialog"
                   onClick={handleDegreeEdit}
                  // onKeyDown={handleKeyDown}
                  tabIndex={0}
                  // borderRadius="10px 10px 0 0"
                  background="white"
                  border="1px solid #CDCFD4"
                  p="2"
                  w="100%"
                  display="flex"
                  alignItems="center"
                  // justifyContent='space-between'
                  gap="2"
                  height="auto"
                  minHeight="48px"
                  maxHeight="100px"
                  overflowY="auto"
                  flexWrap="wrap"
                  position="relative"
                >
                  {selectedConditions.length === 0 && (
                    <Text pl="2" color="gray.400">
                      Degree*
                    </Text>
                 )} 
                   {selectedConditions.map((tag, index) => (
  <Text fontSize="sm" key={tag.id}>
    {tag.label}{index < selectedConditions.length - 1 ? ',' : ''}
  </Text>
))}
{/* new code */}
<Box 
                            as="button" 
                            // w="100%"
                            // onClick={handleDegreeEdit}
                            mr='10px'
                            position="absolute"
                            right='10px'
                            >
                              <Image
                                float="right"
                                src="/assets/imgs/right-icon.png"
                              />
                            </Box>

                </Box>
              {/* <PrimaryInput
                variant="bothSide"
                inputPlace="Specialization*"
                id="specialization"
                
                onChange={handleChangeSpecialization}
                inputValue={specialization}
              /> */}
              {/* <PrimaryInput
                variant="downSide"
                inputPlace="Years of experience*"
                id="degree"
                onChange={handleExperience}
                onKeyPress={submitOnEnter}
                inputValue={yearsofexperience}
              /> */}
              <DropdownInput
                variant="downSide"
                defaultOption={yearsofexperience}
                dropDownPlace="Years of experience*"
                options={experienceInYears}
                onClick={handleExpSelect}
                onKeyPress={submitOnEnter}
                menuList={{pos: "absolute", right: "-45px", minW: "115px"}}
              />

              <Box
                display="flex"
                gap="8px"
                my={5}
                flexDirection="row"
                alignItems="center"
              >
                <Box as="span">
                  <Image w="18px" src="/assets/imgs/info-safe.png" />
                </Box>
                <Box as="p" lineHeight="normal" fontSize="12px">
                  Your information is safe with us.
                </Box>
              </Box>
              <Box w="100%">
                <PrimaryButton buttonText="Continue" id="submitButton"  onClick={SubmitFunction} />
              </Box>
            </VStack>
          </Center>
        </Box>
      </Flex>

      {/* Mobile responsive start */}
      <Flex flexDir="column" display={{base: "flex", lg: "none"}}>
        <Box w="100%" bg="black" h="6px" borderRadius="full" my="1rem">
          <Box as="span" w="50%" bg='#14C585' h="100%" borderRadius="full" display="flex"></Box>
        </Box>
        <Box w="100%" mb={5}>
          <Box as="p" fontSize="1.25rem" lineHeight="normal" letterSpacing="1px" mt="0.75rem" textAlign="center" fontWeight="600" mb={2}>
          Tell us a bit about your profession
          </Box>

          <PrimaryInput
            variant="defaultSide"
            inputPlace="Hospital/Clinic name*"
            id="hospitalName"
            onChange={handleChangeHospitalName}
            inputValue={hospitalname}
          />
          {/* <PrimaryInput
            variant="bothSide"
            inputPlace="Degree*"
            id="degree"
            onChange={handleChangedegree}
            inputValue={degree}
          /> */}
           <Box
                  as="div"
                  role="button"
                  aria-haspopup="dialog"
                   onClick={handleDegreeEdit}
                  // onKeyDown={handleKeyDown}
                  tabIndex={0}
                  // borderRadius="10px 10px 0 0"
                  border="1px solid #CDCFD4"
                  p="2"
                  w="100%"
                  display="flex"
                  alignItems="center"
                  gap="2"
                  height="auto"
                  minHeight="48px"
                  maxHeight="100px"
                  overflowY="auto"
                  flexWrap="wrap"
                >
                  {selectedConditions.length === 0 && (
                    <Text pl="2" color="gray.400">
                      Degree*
                    </Text>
                 )} 
                   {selectedConditions.map((tag, index) => (
  <Text fontSize="sm" key={tag.id}>
    {tag.label}{index < selectedConditions.length - 1 ? ',' : ''}
  </Text>
))}
                </Box>
          
          {/* <PrimaryInput
            variant="bothSide"
            inputPlace="Specialization*"
            id="specialization"
            
            onChange={handleChangeSpecialization}
            inputValue={specialization}
          /> */}
          {/* <PrimaryInput
            variant="downSide"
            inputPlace="Years of experience*"
            id="degree"
            onChange={handleExperience}
            onKeyPress={submitOnEnter}
            inputValue={yearsofexperience}
          /> */}
            <DropdownInput
                      variant="downSide"
                      defaultOption={yearsofexperience}
                      dropDownPlace="Years of experience*"
                      options={experienceInYears}
                      onClick={handleExpSelect}
                      onKeyPress={submitOnEnter}
                    />

          <Box
            display="flex"
            gap="8px"
            my={5}
            flexDirection="row"
            alignItems="center"
            w="100%"
            justifyContent="center"
          >
            <Box as="span">
              <Image w="18px" src="/assets/imgs/info-safe.png" />
            </Box>
            <Box as="p" lineHeight="normal" fontSize="12px">
              Your information is safe with us.
            </Box>
          </Box>
          <Box w="100%">
            <PrimaryButton buttonText="Continue" id="submitButton"  onClick={SubmitFunction} />
          </Box>
          
        </Box>
      </Flex>


      <ModalRight
    ref={addConditionModalInsideEdit}
    modalWidth="360px"
    header="Update Degree"
    body={
      <>
        <SearchBar passInputPlace="Search Degree" onChange={(e) => setSearchValue(e.target.value)} />
        <RadioGroup>
          <Stack direction="column" gap="0">
            <Box
              display="flex"
              justifyContent="space-between"
              borderBottom="1px solid #e2e8f0"
            >
            <Box display="flex" justifyContent="space-between" w="100%">
<Stack direction="column" w="100%" gap="0">
{conditionsList && conditionsList.length > 0 ? (
<>
  {/* Filter and display conditions */}
  {conditionsList.filter((condition) =>
        condition.label.toLowerCase().includes(searchValue.toLowerCase())
      ).length === 0 ? (
        <Text fontSize="16px" color="gray.500" p="15px">
          No Degree found!
        </Text>
      ) : (
        conditionsList
          .filter((condition) =>
            condition.label.toLowerCase().includes(searchValue.toLowerCase())
          )
          .map((condition) => (
            <label key={condition.id}>
              <Box
                display="flex"
                justifyContent="space-between"
                borderBottom="1px solid #e2e8f0"
                p="15px"
                pl="5px"
                minH="50px"
                width="100%"
                sx={{
                  _hover: { border: "2px solid #384052", borderRadius: "10px" },
                }}
              >
                <Box display="flex" alignItems="center">
                  <Text fontSize="16px" lineHeight="normal" fontWeight="600">
                    {condition.label}
                  </Text>
                </Box>
                <Box display="flex" justifyContent="end">
                  <Checkbox
                    colorScheme="green"
                    sx={{
                      ".chakra-checkbox__control": {
                        width: "20px",
                        height: "20px",
                        borderRadius: "5px",
                      },
                    }}
                    onChange={() => handleCheckboxChangeForEdit(condition)}
                    isChecked={selectedConditions.some(
                      (item) => item.label === condition.label
                    )}
                  ></Checkbox>
                </Box>
              </Box>
            </label>
          ))
      )}

  {/* Display "No matching conditions found" only if no conditions were found in any category */}
  {/* {conditionsList.every((category) =>
    category.data.every((condition) =>
      !condition.condition.toLowerCase().includes(searchValue.toLowerCase())
    )
  ) && (
    <Box p="15px" fontSize="16px" fontWeight="600" textAlign="center">
    No matching conditions found!
  </Box>
  )} */}
</>
) : (
<p>Loading...</p>
)}
<Divider/>
    <br/>
    {/* <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
              Other Condition
            </Heading>
  <PrimaryInput
    inputPlace="Condition"
    variant="bothSide"
          onChange={handleCondition} inputValue={typedCondition}
          />
          <br/> */}
</Stack>
</Box>

            </Box>
          </Stack>
        </RadioGroup>
        
            
      </>
      
    }
    footerActions={<>
      <Box
        w="100%"
        display="flex"
        flexDirection="row"
        gap="15px">

        <PrimaryButton
          buttonText="Update Degree"
          isDisabled={false}
            onClick={handleDegreeChanges}
        />
      </Box>
    </>}
  />
           

    </>
  );
};

export default ProfessionalDetails;
