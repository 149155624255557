import formAPIModelroledetails from "../roles/roledetails/doctorroledetails";

// export default function formAPIModalLogin({
//     loginid,
//     firstname,
//     lastname,
//     fullname,
//     currencyname,
//     email,
//     password,
//     epassword,
//     masterpassword,
//     gender,
//     countrycode,
//     mobileno,
//     Preferredlang,
//     specality,
//     ratings,
//     lastlogindate,
//     createdDate,
//     createdBy,
//     modifiedDate,
//     modifiedBy,
//     isactive,
//     countryid,
//     isavailable,
//     firsttimelogin,
//     isverified,
//     roleid,
   
//     disablehome,
//     disablemedicines,
//     disablemessages,
//     disablenotifications,
//     disablepatients,
//     disableprescriptions,
//     disablereports,
//     disablevisits,
//     disableroles,
//     disableusers,
//     disableinventory,
//     disablefinance,
//     disableinvoices,

//     screens,
//     addressdetail,
//     usertypeid,
//     issuperuser,
//     isdoctor,
//     hospitallogourl,
//     imagepath,
//     hospitalname,
// },) {
//     return Object.freeze({
//         loginid,
//         firstname,
//         lastname,
//         fullname,
//         currencyname,
//         email,
//         password,
//         epassword,
//         masterpassword,
//         gender,
//         countrycode,
//         mobileno,
//         Preferredlang,
//         specality,
//         ratings,
//         lastlogindate,
//         createdDate,
//         createdBy,
//         modifiedDate,
//         modifiedBy,
//         isactive,
//         countryid,
//         isavailable,
//         firsttimelogin,
//         isverified,
//         roleid,

//         disablehome,
//         disablemedicines,
//         disablemessages,
//         disablenotifications,
//         disablepatients,
//         disableprescriptions,
//         disablereports,
//         disablevisits,
//         disableroles,
//         disableusers,
//         disableinventory,
//         disablefinance,
//         disableinvoices,

//         screens: screens ? formAPIModelroledetails(screens) : null,
//         addressdetail: addressdetail,

//         usertypeid,
//         issuperuser,
//         isdoctor,
//         hospitallogourl,
//         imagepath,
//         hospitalname,
//     });
// }


export default function formAPIModalLogin(data) {
    // Destructure the known fields you may want to handle manually
    const { 
        screens, 
        addressdetail 
    } = data;

    // Use the spread operator to copy the rest of the properties dynamically
    return Object.freeze({
        ...data, // Dynamically include all properties from the `data` object

        // Handle screens separately if it exists, to apply additional transformations
        screens: screens ? formAPIModelroledetails(screens) : null,

        // Keep any additional logic like addressdetail
        addressdetail: addressdetail,
    });
}