import React from "react";
import { COLORS } from "../../components/styles/colors";
import { Box, Center, Divider, Image, Text } from "@chakra-ui/react";
import { PrimaryButton, Toaster } from "../../components";
import { useNavigate } from "react-router-dom";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";

const RequestSubmit = () => {
  var navigate = useNavigate();
  
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const { showSuccessToast, showErrorToast } = Toaster();

  const SubmitFunction = ()=>{
    showLoader();
    setTimeout(() => {
    localStorage.clear()
    sessionStorage.clear()
    navigate('/login')
    }, 1500);
    dismissLoader();
  }
  return (
    <>
      <Box
        w="100vw"
        h="100vh"
        bg="#0B0B0D"
        bgImage="url('/assets/imgs/login-bg.png')"
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover"
      >
        
      {isLoading && <Loader />}
        <Box
          bgImage="url('/assets/imgs/login-bg.png')"
          boxShadow={COLORS.BOX_SHADOW_SUCCESS}
          bgPosition="center"
          bg="#061027"
          bgRepeat="no-repeat"
          bgSize="cover"
        ></Box>
        <Center
          h="100vh"
          display="flex"
          flexDirection="column"
          alignItems="center"
          mt={{base: "-3rem", lg: "0"}}
        >
          <Box mb={{base: "35px", lg: "50px"}}>
            <Image src="/assets/imgs/midoc-logo.png" />
          </Box>
          <Box
            bg="#151619"
            border="1px solid #232325"
            borderRadius="12px"
            display="flex"
            justifyContent="center"
            flexDir="column"
            w={{base: "300px", lg: "400px"}}
            p="20px"
          >
            <Image
              w={{base: "75px", lg: "100px"}}
              h="auto"
              m="auto"
              src="assets/svgs/request-submitted.svg"
            />
            <Box
              as="h1"
              color="white"
              fontSize="24px"
              textAlign="center"
              fontWeight="600"
              mt="20px"
            >
              Request submitted
            </Box>
            <Box color="white" textAlign="center" fontWeight="400">
              <Text fontSize="14px" fontWeight="400">
                Your verification is in-progress and will <br /> take up to 48
                hours for approval.
              </Text>
            </Box>
            <Divider my="20px" />
            <Box color="white" mb="10px" textAlign="center" fontWeight="400">
              <Text fontSize="14px" fontWeight="400" color="#E6E7E9">
                Verification taking too long?
              </Text>
            </Box>
            <Box>
              <PrimaryButton
                btnStyle={{
                  bg: "#FCFCFD",
                  color: "#061027",
                  fontSize: "14px",
                  fontWeight: "600",
                  boxShadow: "0px 0px 0px 3px #E6E7E94D, 0px 1px 2px 0px #C1C2C680, 0px -8px 12px 0px #D8D8D833 inset",
                  _hover: {bg: COLORS.PRIMARY_WHITE}}}
                buttonText="Get in touch"
                onClick={SubmitFunction}
              />
            </Box>
          </Box>
        <Box mt="1rem">
          <Text 
            fontSize="14px"
            fontWeight="500"
            letterSpacing="1px"
            color="#898D97"
          >© 2024 midoc, Inc.</Text>
        </Box>
        </Center>
        <Box
          position="absolute"
          bottom="0"
          w="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding="20px"
          gap="10px"
        >
          <Text
            fontSize="10px"
            fontWeight="500"
            letterSpacing="1px"
            color="#898D97"
          >
            A PRODUCT OF
          </Text>
          <Image src="/assets/svgs/tci-logo.svg" />
        </Box>
      </Box>
    </>
  );
};

export default RequestSubmit;
