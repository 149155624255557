import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Text,
  Image,
  Circle,
  Flex,
  Center,
  Link,
  Divider,
} from "@chakra-ui/react";
import {
  DropdownInput,
  PrimaryButton,
  PrimaryButtonIcon,
  PrimaryInput,
  SearchBar,
  Toaster,
} from "../../../components";

import ReactMarkdown from "react-markdown";
import DatePicker from "../../../components/date-picker";
import { format, parseISO } from "date-fns";
import moment from "moment";

import ModalRight from "../../../components/modal-right";
import { updateCondition } from "../../../middleware/services/visits/patient-overview/updatehealth";

import DynamicModal from "../../../components/dynamic-modal";

import {
  FetchSurgeriesAIPrompts,
  FetchSurgeriesAIAnswer,
  FetchMyHealthAIData,
} from "../../../middleware/services/visits/aicalls";
import TypingLoader from "../../../components/typing-loader";
import AiModal from "../../../components/ai-modal/index";

const formatDate = (dateString) => {
  if (!dateString) return "-";
  return moment(dateString).format("Do MMM, YYYY");
};

const fileTypeMappings = {
  pdf: "/assets/svgs/pdf-icon.svg",
  png: "/assets/imgs/png-icon.png",
  jpg: "/assets/imgs/jpg-icon.png",
  jpeg: "/assets/imgs/jpg-icon.png",
};
const getFileIcon = (fileName) => {
  const extension = fileName.split(".").pop().toLowerCase();
  return fileTypeMappings[extension] || "/assets/svgs/file-icon.svg"; // fallback image
};

const SurgicalHistory = ({
  date,
  surgeryName,
  doctorName,
  hospital,
  attachments,
  surgery_id,
  appointmentId,
  patientId,
  patientname,
  patientgender,
  patientage,
  doctorId,
  fetchPatientHealthData,
  showLoader,
  dismissLoader,
  showSuccessToast,
  showErrorToast,
  scaleHeight = "auto",
  componentBg = "#fcfcfd",
  cardBg = "white",
}) => {
  const [surgeonName, setSurgeonName] = useState("");
  const [hospitalName, setHospitalName] = useState("");
  const [procedureDate, setProcedureDate] = useState("");
  const [procedure, setProcedure] = useState("");
  const [procedureTitle, setProcedureTitle] = useState("");

  const [id, setId] = useState("");

  const editModal = React.useRef();

  //---------------AI Insights

  const chatContainerRef = useRef(null);
  const [aianswers, setAIAnswers] = useState([]);
  const [remainingquestions, setRemainingQuestions] = useState([]);
  const [aiquestion, setAIAnalysis] = useState(null);
  const [AiInput, setAiInput] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  const [animate, setAnimate] = useState(false);
  const AIChatModel = useRef();

  useEffect(() => {
    if (animate) {
      const timer = setTimeout(() => {
        setAnimate(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [animate]);

  useEffect(() => {
    // Scroll to bottom whenever aianswers changes
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [aianswers]);
  const fetchAiDoctorPrompts = async (surgeryname) => {
    showLoader();
    setRemainingQuestions([]);
    setAiInput(null);
    setAIAnswers([]);
    setInitialRender(true);
    const result = await FetchMyHealthAIData(
      {},
      patientId,
      patientname,
      patientgender,
      patientage
    );
    let Aiprompts = { ...result, surgeryname };
    setAiInput(Aiprompts);
    const response = await FetchSurgeriesAIPrompts(Aiprompts);
    if (response.generated_question) {
      setAIAnalysis(response);
      setRemainingQuestions(response.generated_question);
      AIChatModel.current.openRight();
    } else {
      showErrorToast("Ai tokens are not available");
      AIChatModel.current.closeRight();
    }
    dismissLoader();
  };

  const GetAIAnswer = async (prompt) => {
    // Display question immediately
    const newQuestion = { question: prompt, answer: "" };
    setAIAnswers((prevState) => [...prevState, newQuestion]);

    // Remove the question from remaining questions immediately
    setRemainingQuestions((prevState) =>
      prevState.filter((question) => question.value !== prompt)
    );

    // Trigger animation
    setAnimate(true);

    // Set initialRender to false after the first question is clicked
    setInitialRender(false);
    let AichatInput = { ...AiInput, query: prompt };

    const response = await FetchSurgeriesAIAnswer(AichatInput);

    if (response) {
      // Replace "typing" with actual answer
      setAIAnswers((prevState) =>
        prevState.map((item) =>
          item.question === prompt && item.answer === ""
            ? { question: item.question, answer: response.answer }
            : item
        )
      );
    } else {
      showErrorToast("Ai tokens are not available");
      AIChatModel.current.closeRight();
    }
  };

  //---------------Other functionalities

  const handleChangeHospitalName = (event) => {
    var value = event.target.value;

    setHospitalName(value);
  };
  const handleChangeDate = (selectedDate) => {
    const formattedDate = format(selectedDate, "dd-MM-yyyy");
    setProcedureDate(formattedDate);
  };
  const handleChangeSurgeonName = (event) => {
    var value = event.target.value;

    setSurgeonName(value);
  };

  const openEditModal = (
    id,
    doctorName,
    hospitalname,
    proceduredate,
    proceduretitle
  ) => {
    var formatedDate = moment(proceduredate).format("DD-MM-yyyy");

    const date = new Date(proceduredate);

    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());

    // const formatedDate = date.toISOString().split("T")[0];

    setId(id);
    setSurgeonName(doctorName);
    setHospitalName(hospitalname);
    setProcedureTitle(proceduretitle);
    setProcedureDate(formatedDate);
    editModal.current.openRight();
  };

  const updateSurgicalHistory = async () => {
    const inputForEdit = {
      input: {
        doctorid: doctorId,
        patientid: patientId,
        type: "surgicalhistory",
        surgicalhistoryid: id,
        proceduredate: procedureDate,
        // procedure_cd: 3,
        // proceduretype_cd: 4,
        proceduretitle: procedureTitle,
        // nomenclatureid: 5,
        // hospitalid: 6,
        surgeryhospitalname: hospitalName,
        surgeonname: surgeonName,
        surgicalhistoryactive: 1,
      },
    };

    if (
      moment(inputForEdit.input.proceduredate, "DD-MM-YYYY", true).isValid()
    ) {
      inputForEdit.input.proceduredate = moment(
        inputForEdit.input.proceduredate,
        "DD-MM-YYYY"
      ).format("YYYY-MM-DD");
    }

    if (inputForEdit.input.proceduredate === "") {
      showErrorToast("Please select the dates");
      return false;
    } else {
      showLoader();
      try {
        // console.log(inputForEdit.input);
        const result = await updateCondition(inputForEdit.input);
        // console.log(result);
        if (result) {
          showSuccessToast("Surgery updated successfully");
          fetchPatientHealthData();
          editModal.current.closeRight();
        }
      } catch (error) {
        console.error("Error in updateSurgicalHistory:", error);
      }
      dismissLoader();
    }
  };

  //-------------------------------

  const dynamicModal = React.useRef();

  const openDeleteModal = (id, proceduretitle) => {
    setId(id);
    setProcedure(proceduretitle);

    dynamicModal.current.openModal();
  };

  const deleteSurgery = async () => {
    const inputForDelete = {
      input: {
        doctorid: doctorId,
        patientid: patientId,
        type: "surgicalhistory",
        appointmentid: appointmentId,
        surgicalhistoryid: id,
        surgicalhistoryactive: 0,
      },
    };

    try {
      showLoader();
      // console.log("input to delete", inputForDelete.input);
      const result = await updateCondition(inputForDelete.input);
      // console.log("delete result", result);
      if (result.output.result === "success") {
        showSuccessToast(`${procedure} has been deleted`);
        fetchPatientHealthData();
        dynamicModal.current.closeModal();
      } else {
        showErrorToast("Something went wrong!");
      }
    } catch (error) {
      console.error("Error deleting Surgery:", error);
      showErrorToast("An error occurred while deleting Surgery");
    } finally {
      dismissLoader();
    }
  };

  return (
    <Box
      display="flex"
      flexDir="column"
      alignItems="center"
      w="100%"
      bg={componentBg}
    >
      <Box display="flex" alignItems="center" w="100%">
        <Circle
          size="1rem"
          bg="white"
          border="2px solid #CDCFD4"
          borderRadius="full"
          color="black"
        />
        <Text
          fontSize="12px"
          fontWeight="500"
          textAlign="left"
          color="#384052"
          ml="0.5rem"
        >
          {formatDate(date)}
        </Text>
      </Box>
      <Box display="flex" w="100%" mt="10px">
        <Center
          w="100%"
          h="auto"
          ml="0.45rem"
          alignItems="start"
          borderLeft="2px solid #E6E7E9"
        >
          <Box w="100%">
            <Box
              p="20px"
              w="100%"
              ml="10px"
              display="flex"
              flexDirection="column"
              alignItems="start"
              justifyContent="space-between"
              border="1px solid #E6E7E9"
              borderRadius="12px"
              backgroundColor={cardBg}
            >
              <Box display="flex" alignItems="center" w="100%">
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  flex="1"
                >
                  <Text
                    title={surgeryName}
                    fontSize="16px"
                    textAlign="left"
                    fontWeight="600"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    w={{ base: "100px", md: "230px" }}
                  >
                    {surgeryName}
                  </Text>
                </Box>
                <Box display="flex" alignItems="center">
                  <Box
                    as="button"
                    // onClick={}
                    border="1px solid #E6E7E9"
                    borderRadius="full"
                    w="36px"
                    h="auto"
                    p="10px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    onClick={() => fetchAiDoctorPrompts(surgeryName)}
                  >
                    <Image w="26px" src="/assets/svgs/ai-star-outline.svg" />
                  </Box>
                  <Box
                    as="button"
                    border="1px solid #E6E7E9"
                    borderRadius="full"
                    w="36px"
                    h="auto"
                    p="10px"
                    ml="10px"
                    onClick={() =>
                      openEditModal(
                        surgery_id,
                        doctorName,
                        hospital,
                        date,
                        surgeryName
                      )
                    }
                  >
                    <Image w="13px" src="/assets/svgs/edit-gray.svg" />
                  </Box>
                  <Box
                    as="button"
                    border="1px solid #E6E7E9"
                    borderRadius="full"
                    w="36px"
                    h="auto"
                    p="10px"
                    ml="10px"
                    onClick={() => openDeleteModal(surgery_id, surgeryName)}
                  >
                    <Image w="13px" src="/assets/imgs/delete.png" />
                  </Box>
                </Box>
              </Box>
              <Divider my="10px" />
              <Box display="flex" alignItems="center" w="100%">
                <Box display="flex" alignItems="center">
                  <Image src="/assets/svgs/stethoscope.svg" w="16px" mr="5px" />
                  <Text
                    fontSize="14px"
                    fontWeight="500"
                    color="#384052"
                    whiteSpace={"nowrap"}
                  >
                    {doctorName}
                  </Text>
                </Box>
                <Box flex="1" />
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  w="100%"
                >
                  <Image src="/assets/svgs/hospital.svg" w="16px" mr="5px" />
                  <Text fontSize="14px" fontWeight="500" color="#384052">
                    {hospital}
                  </Text>
                </Box>
              </Box>
            </Box>
            <Box mt="4px" p="10px" w="100%">
              <Box display="flex" w="100%" flexWrap="wrap">
                {attachments.map((attachment, index) => (
                  <Box
                    key={index}
                    display="flex"
                    alignItems="center"
                    border="1px solid #E6E7E9"
                    borderRadius="8px"
                    p="5px 10px"
                    mr="10px"
                    mb="10px"
                    backgroundColor="white"
                  >
                    <Box bg="#f9f9f9" p="5px" borderRadius="8px">
                      <Image src={getFileIcon(attachment.name)} w="16px" />
                    </Box>
                    <Link
                      href={attachment.link}
                      isExternal
                      fontSize="14px"
                      color="#2B6CB0"
                      ml="5px"
                    >
                      {attachment.name}
                    </Link>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Center>
      </Box>

      {/* Delete Functionality */}
      <DynamicModal
        ref={dynamicModal}
        modalHeader="Delete Surgery?"
        modalBody={
          <>
            <Text fontSize="14px" fontWeight="600">
              Are you sure you want to delete Surgery "{procedure}"
            </Text>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="deleteBtn"
              buttonText="Delete"
              onClick={deleteSurgery}
            />
          </>
        }
      />

      {/* Edit Functionality  */}
      <ModalRight
        ref={editModal}
        header="Edit Surgical History "
        body={
          <>
            <Box display="flex" flexDir="column" gap="20px">
              <Box>
                <Text fontSize="16px" py="10px" fontWeight="600">
                  {procedureTitle}
                </Text>
                <DatePicker
                  disableFutureDates={true}
                  defaultDate={procedureDate}
                  onDateChange={(selectedDateValue) =>
                    handleChangeDate(selectedDateValue)
                  }
                  placeholderProp=" Date"
                  datePickerInputStyle={{ borderRadius: "10px 10px 0 0" }}
                />

                <PrimaryInput
                  variant="bothSide"
                  inputType="text"
                  inputPlace="Surgeon Name"
                  defaultValue={surgeonName}
                  onChange={handleChangeSurgeonName}
                />
                <PrimaryInput
                  variant="downSide"
                  inputType="text"
                  inputPlace="Hospital Name"
                  defaultValue={hospitalName}
                  onChange={handleChangeHospitalName}
                />
              </Box>
            </Box>
          </>
        }
        footerActions={
          <>
            <Box w="100%" display="flex" flexDirection="row" gap="15px">
              <PrimaryButton
                buttonText="Update Surgery"
                isDisabled={false}
                onClick={updateSurgicalHistory}
              />
            </Box>
          </>
        }
      />

      <AiModal
        ref={AIChatModel}
        modalSize="540px"
        //icon="/assets/imgs/ai-doctor.png"
        header={
          <>
            <Box display="flex" flexDir="column">
              <Text
                fontSize="1rem"
                fontWeight="700"
                backgroundImage="linear-gradient(to right top, #0d7e54, #007774, #006d94, #005ea7, #1146a0)"
                color="transparent"
                backgroundClip="text"
              >
                Aira - AI
              </Text>
              <Text fontSize="0.75rem" fontWeight="400">
                Chat with your AI health guide
              </Text>
            </Box>
          </>
        }
        headerStyle={{ p: "24px 32px", lineHeight: "normal" }}
        closeBtnStyle={{ top: "18px" }}
        footerStyle={{
          maxH: "35%",
          overflow: "hidden auto",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        }}
        withoutBg={true}
        backDropBlur="10px"
        body={
          <>
            <Flex flexDirection="column" height="100%">
              <Box
                p="24px 32px"
                ref={chatContainerRef}
                className="chat-container"
                style={{ overflowY: "auto", maxHeight: "calc(100vh - 160px)" }}
              >
                {initialRender && remainingquestions.length > 0 && (
                  <Box>
                    {remainingquestions.map((step, index) => (
                      <Box
                        key={index}
                        as="button"
                        w="100%"
                        p="16px"
                        mb="16px"
                        fontSize="14px"
                        fontWeight="600"
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        borderRadius="10px"
                        border="1px solid #7795DC"
                        bg="#EAEDF5"
                        color="#163E9E"
                        onClick={() => GetAIAnswer(step.value)}
                        className={`question-box ${animate ? "slide-in" : ""}`}
                      >
                        {step.value ? step.value : "-"}
                        <Image src="/assets/imgs/right-icon.png" ml={"10px"} />
                      </Box>
                    ))}
                  </Box>
                )}
                {aianswers.map((step, index) => (
                  <React.Fragment key={index}>
                    <Box
                      as="div"
                      bg="#061027"
                      p="12px 16px"
                      w="fit-content"
                      float="right"
                      borderRadius="10px 10px 1px 10px"
                      className={`answer-box ${animate ? "slide-in" : ""}`}
                    >
                      <Text fontSize="14px" fontWeight="500" color="white">
                        {step.question ? step.question : "-"}
                      </Text>
                    </Box>

                    <Box
                      as="div"
                      my="20px"
                      w="fit-content"
                      display="flex"
                      alignItems="end"
                      float="left"
                      className={`answer-box ${animate ? "slide-in" : ""}`}
                    >
                      <Image
                        bg="#061027"
                        p="4px"
                        mr="8px"
                        borderRadius="6px"
                        src="/assets/imgs/ai-chat-logo.png"
                      />
                      <Text
                        bg="#E6E7E980"
                        p="12px 16px"
                        fontSize="14px"
                        fontWeight="500"
                        color="#061027"
                        borderRadius="10px 10px 10px 1px"
                      >
                        {/* {step.answer ? step.answer : <TypingLoader />} */}
                        {step.answer ? (
                          <ReactMarkdown>{step.answer}</ReactMarkdown>
                        ) : (
                          <TypingLoader />
                        )}
                      </Text>
                    </Box>
                  </React.Fragment>
                ))}
              </Box>
            </Flex>
          </>
        }
        footerActions={
          !initialRender && (
            <Box
              p="24px 32px"
              display="flex"
              w="100%"
              h="100%"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Box w="100%">
                {remainingquestions.map((step, index) => (
                  <Box
                    key={index}
                    as="button"
                    w="100%"
                    p="16px"
                    mb="16px"
                    fontSize="14px"
                    fontWeight="600"
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    borderRadius="10px"
                    border="1px solid #7795DC"
                    bg="#EAEDF5"
                    color="#163E9E"
                    onClick={() => GetAIAnswer(step.value)}
                    className={`question-box ${animate ? "slide-in" : ""}`}
                  >
                    {step.value ? step.value : "-"}
                    <Image src="/assets/imgs/right-icon.png" ml={"10px"} />
                  </Box>
                ))}
              </Box>
            </Box>
          )
        }
      />
    </Box>
  );
};

export default SurgicalHistory;
