import {
  Box,
  Checkbox,
  Divider,
  Flex,
  Heading,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  RadioGroup,
  Spacer,
  Stack,
  Step,
  StepDescription,
  StepIndicator,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Text,
  Button,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import {
  actionAccessMain,
  handleAlphaNumericDotAndSpace,
  handleAlphaDotAndSpace,
} from "../../../auth";
import ModalRight from "../../modal-right";
import DatePicker from "../../date-picker";
import { format } from "date-fns";
import SearchBar from "../../search-bar";
import {
  addSurgeries,
  getmedicalcondition,
} from "../../../middleware/services/messages";
import moment from "moment";
import PrimaryButton from "../../primary-button";
import PrimaryInput from "../../primary-input";
import { uploadImageInProfile } from "../../../middleware/services/profile";

const NoSurgicalHistoryDataScreen = forwardRef(({
  width,
  height,
  patientId,
  showErrorToast,
  showSuccessToast,
  showLoader,
  dismissLoader,
  fetchPatientHealthData,
}, ref) => {


  const openFromStartVisitOverview = () => {
    console.log("Function called!");
    openSurgeriesModal()
  };

  useImperativeHandle(ref, () => ({
    openFromStartVisitOverview
  }));

  const [actionAccessPatients, setActionAccessPatients] = useState(null);
  useEffect(() => {
    setActionAccessPatients(actionAccessMain(4));
  }, []);

  const addCondition = React.useRef();
  const addSurgeriesModal = React.useRef();

  var [surgeriesList, setSurgeriesList] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [selectedSurgeries, setSelectedSurgeries] = useState([]);
  const [selectedDates, setSelectedDates] = useState({});
  const indexMap = {};
  const [documentURLs, setDocumentURLs] = useState([]);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [surgeonName, setSurgeonName] = useState("");
  const [hospitalName, setHospitalName] = useState("");
  const [procedureDate, setProcedureDate] = useState("");

  const [treatmentteamdata, setTreatmentTeamData] = useState("");

  const [todaysDate, setTodaysDate] = useState(moment().format("DD-MM-YYYY"));
  const [procedure, setProcedure] = useState("");
  const [procedureTitle, setProcedureTitle] = useState("");

  const [suggestions, setSuggestions] = useState({});

  const [inputValues, setInputValues] = useState({});

  const [typedCondition, setTypedCondition] = useState("");

  const fileInputRef = useRef(null);
  const fileInputRefUpdate = useRef(null);

  useEffect(() => {
    setDocumentURLs(uploadedDocuments);
  }, [uploadedDocuments]);

  const addSurgeriesUploadDocument = (condition) => {
    // alert(condition.condition_cd)
    if (condition.condition_cd) {
      fileInputRef.current.setAttribute(
        "data-surgery-cd",
        condition.condition_cd
      );
      fileInputRef.current.setAttribute("data-surgery", condition.condition);
      fileInputRef.current.click();
    } else {
      fileInputRef.current.setAttribute("data-surgery-cd", null);
      fileInputRef.current.setAttribute("data-surgery", "other");
      fileInputRef.current.click();
    }
  };

  const handleDelete = (index) => {
    const updatedUrls = [...uploadedDocuments];
    updatedUrls.splice(index, 1);
    setUploadedDocuments(updatedUrls);
  };

  const handleSurgeriesUpload = async (e) => {
    e.preventDefault();
    showLoader();
    if (e.target.files.length == 0) {
      dismissLoader();
      return;
    }
    const file2 = e.target.files[0];
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg", "image/webp"];
    if (!allowedTypes.includes(file2.type)) {
      showErrorToast(
        "Invalid file type. Please select a PNG, JPG or JPEG file."
      );
      setTimeout(() => {
        dismissLoader();
      }, 1000);
      return false;
    }
    const condition = e.target.getAttribute("data-surgery");
    let condition_cd = e.target.getAttribute("data-surgery-cd");
    if (condition_cd === "null") {
      condition_cd = null;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file2);
    const result = await uploadImageInProfile(file2, file2.name);
    const filename = result.name;
    setSelectedDates((prevDates) => ({
      ...prevDates,
      [condition]: {
        date: prevDates[condition]?.date || "",
        surgeonName: prevDates[condition]?.surgeonName || "",
        hospitalName: prevDates[condition]?.hospitalName || "",
        fileName: filename,
      },
    }));
    setUploadedDocuments((prevDocuments) => [
      ...prevDocuments,
      { condition_cd, filename },
    ]);
    e.target.value = "";
    // fetchPatientHealthData()
    dismissLoader();
  };

  const fetchSurgeriesList = async () => {
    try {
      const result = await getmedicalcondition({
        type: "surgical",
      });
      // console.log(result)
      if (result.output.result == "success") {
        setSurgeriesList(result.output.data);
      } else {
        showErrorToast("Something went wrong!");
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  const handleSelect = (conditionCd, suggestion) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [conditionCd]: suggestion.doctorname,
    }));
    setSuggestions((prevSuggestions) => ({
      ...prevSuggestions,
      [conditionCd]: [],
    }));

    setBoolValForSuggestions(!boolValForSuggestions);
    setSelectedDates((prevDates) => ({
      ...prevDates,
      [conditionCd]: {
        date: prevDates[conditionCd]?.date || "",
        surgeonName: suggestion.doctorname,
        hospitalName: prevDates[conditionCd]?.hospitalName || "",
        fileName: prevDates[conditionCd]?.fileName || "",
      },
    }));
    setShowDocNamesDropdown(!showDocNamesDropdown);
  };

  const handleCheckboxChange = (condition) => {
    const isSelected = selectedSurgeries.some(
      (item) => item.condition_cd === condition.condition_cd
    );

    if (isSelected) {
      setSelectedSurgeries((prevConditions) =>
        prevConditions.filter(
          (item) => item.condition_cd !== condition.condition_cd
        )
      );
    } else {
      setSelectedSurgeries([...selectedSurgeries, condition]);
    }
  };

  const [inputId, setInputId] = useState("");
  const [boolValForSuggestions, setBoolValForSuggestions] = useState(true);
  const [showDocNamesDropdown, setShowDocNamesDropdown] = useState(true);

  const handleHospitalNameChange = (conditionCd, hospitalName) => {
    setSelectedDates((prevDates) => ({
      ...prevDates,
      [conditionCd]: {
        date: prevDates[conditionCd]?.date || "",
        surgeonName: prevDates[conditionCd]?.surgeonName || "",
        hospitalName,
        fileName: prevDates[conditionCd]?.fileName || "",
      },
    }));
  };

  const handleSurgeonNameChange = (conditionCd, surgeonName) => {
    setInputId(conditionCd);
    setInputValues((prevValues) => ({
      ...prevValues,
      [conditionCd]: surgeonName,
    }));

    // Filter surgeon names based on input
    if (surgeonName) {
      filterSurgeonNames(conditionCd, surgeonName);
    } else {
      setSuggestions((prevSuggestions) => ({
        ...prevSuggestions,
        [conditionCd]: [],
      }));

      setBoolValForSuggestions(!boolValForSuggestions);
    }

    // Update selectedDates state
    setSelectedDates((prevDates) => ({
      ...prevDates,
      [conditionCd]: {
        date: prevDates[conditionCd]?.date || "",
        surgeonName: surgeonName,
        hospitalName: prevDates[conditionCd]?.hospitalName || "",
        fileName: prevDates[conditionCd]?.fileName || "",
      },
    }));
  };

  const handleCondition = (e) => {
    const inputText = e.target.value;
    const maxLength = 80;

    if (inputText.length <= maxLength) {
      let formatted = inputText.replace(/[^0-9a-zA-Z\s.]/g, "");
      setTypedCondition(formatted);
    }
  };

  const filterSurgeonNames = (conditionCd, surgeonName) => {
    const filtered =
      treatmentteamdata &&
      treatmentteamdata.filter((surgeon) =>
        surgeon.doctorname?.toLowerCase().includes(surgeonName.toLowerCase())
      );
    setSuggestions((prevSuggestions) => ({
      ...prevSuggestions,
      [conditionCd]: filtered,
    }));
    setBoolValForSuggestions(!boolValForSuggestions);
    if (filtered) {
      setShowDocNamesDropdown(true);
    }
  };

  const listOfSurgeonNames = (conditionCd, isImage) => {
    const allSuggestions = treatmentteamdata && treatmentteamdata;

    setSuggestions((prevSuggestions) => ({
      ...prevSuggestions,
      [conditionCd]: allSuggestions,
    }));
    // setBoolValForSuggestions(!boolValForSuggestions)
    if (isImage) {
      setShowDocNamesDropdown(!showDocNamesDropdown);
    }
  };

  const [fileName, setFileName] = useState("");
  const handleDatePickerChange = (conditionCd, selectedDate) => {
    const formattedDate = format(selectedDate, "yyyy-MM-dd");
    setSelectedDates((prevDates) => ({
      ...prevDates,
      [conditionCd]: {
        date: formattedDate,
        surgeonName: prevDates[conditionCd]?.surgeonName || "",
        hospitalName: prevDates[conditionCd]?.hospitalName || "",
        fileName,
      },
    }));
  };

  const openSurgeriesModal = () => {
    if (surgeriesList == null) {
      fetchSurgeriesList();
    }
    setSearchValue("");
    setSelectedSurgeries([]);
    setUploadedDocuments([]);
    setTypedCondition("");
    addCondition.current.openRight();
  };
  const formatDate = (dateString) => {
    if (!dateString) return "-";
    return moment(dateString).format("Do MMM, YYYY");
  };

  //------------------saving
  var inputForAdd = {
    patientid: patientId,
    procedures: selectedSurgeries.map((condition, index) => {
      const procedure = {
        proceduredate:
          selectedDates[condition.condition_cd]?.date || todaysDate,
        proceduretype_cd: "1",
        procedure_cd: condition.condition_cd,
        proceduretitle: condition.condition,
        nomenclatureid: condition.condition_cd,
        hospitalid: "1",
        hospitalname: selectedDates[condition.condition_cd]?.hospitalName || "",
        surgeonname: selectedDates[condition.condition_cd]?.surgeonName || "",
        documenttype_cd: "",
        documentcategory_cd: "",
        documentname: "",
        documentsize: "",
        documenturl: "www.google.com",
        documents: uploadedDocuments
          .filter((doc) => doc.condition_cd == condition.condition_cd)
          .map(({ condition_cd, filename }) => ({
            condition_cd,
            filename,
          })),
      };

      if (condition.conditiontype) {
        procedure.proceduretype = condition.conditiontype;
      }

      return procedure;
    }),
  };

  const AddMedicalConditions = () => {
    setInputValues({});
    if (selectedSurgeries.length === 0 && typedCondition == "") {
      showErrorToast("Please select a surgery to continue");
    } else {
      if (typedCondition !== "") {
        const newSelectedCondition = {
          condition: typedCondition,
          condition_cd: null,
          conditiontype: "other",
        };

        setSelectedSurgeries((prevConditions) => {
          // Filter out any existing condition with the same type
          const filteredConditions = prevConditions.filter(
            (item) => item?.conditiontype !== "other"
          );
          // Add the new condition
          return [...filteredConditions, newSelectedCondition];
        });
      }

      setSelectedDates({});
      setSurgeonName("");
      setBoolValForSuggestions(false);
      addSurgeriesModal.current.openRight();
    }
  };
  const AddSurgeriesCall = () => {
    const addSurgeriesFunction = async () => {
      showLoader();
      try {
        // inputForAdd.procedures.forEach((procedure, index) => {
        //   if (moment(procedure.proceduredate, ['DD-MM-YYYY', 'YYYY-MM-DD'], true).isValid()) {
        //     inputForAdd.procedures[index].proceduredate = moment(procedure.proceduredate, 'DD-MM-YYYY').format('YYYY-MM-DD');
        //   }
        // });
        inputForAdd.procedures.forEach((procedure, index) => {
          const inputFormat = ["DD-MM-YYYY", "YYYY-MM-DD"];
          const parsedDate = moment(procedure.proceduredate, inputFormat, true);
          //alert(procedure.proceduredate)
          if (parsedDate.isValid()) {
            // Use the detected format to parse the date string
            const detectedFormat = parsedDate.format("YYYY-MM-DD");
            // alert(detectedFormat)
            // If the detected format matches the expected 'DD-MM-YYYY', convert to 'YYYY-MM-DD'
            if (detectedFormat) {
              inputForAdd.procedures[index].proceduredate =
                parsedDate.format("YYYY-MM-DD");
            }
          }
        });

        //  console.log(inputForAdd , " input for add surgeries")
        const result = await addSurgeries(inputForAdd);
        //console.log(result)
        if (result.output.result == "Successfully Inserted") {
          showSuccessToast("Surgery added Successfully.");
          setTimeout(() => {
            if (addSurgeriesModal?.current) {
              addSurgeriesModal.current.closeRight();
            }
            if (addCondition?.current) {
              addCondition.current.closeRight();
            }
          }, 700);
          fetchPatientHealthData();
        } else {
          showErrorToast("Something went wrong!");
        }
        dismissLoader();
      } catch (error) {
        console.error("Error in add Surgeries:", error);
      }
    };
    const isDateMissing = selectedSurgeries.some(
      (condition) => !selectedDates[condition.condition_cd]?.date
    );

    const isSurgeonNameMissing = selectedSurgeries.some(
      (condition) => !selectedDates[condition.condition_cd]?.surgeonName
    );

    const isHospitalNameMissing = selectedSurgeries.some(
      (condition) => !selectedDates[condition.condition_cd]?.hospitalName
    );

    // if (isDateMissing) {
    //   showErrorToast("Please select the Date.");
    //   return false;
    // } else if (isSurgeonNameMissing) {
    //   showErrorToast("Please Enter Surgeon Name");
    //   return false;
    // } else if (isHospitalNameMissing) {
    //   showErrorToast("Please Enter Hospital Name");
    //   return false;
    // } else {
    showLoader();
    addSurgeriesFunction();

    //}
  };

  return (
    <Box h='100%'>
      <Box
        bg="#fcfcfd"
        width={width}
        height={height}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDir='column'
        h='100%'
        gap='1rem'
      >
        <PrimaryButton
          fontSize="14px"
          variant="mdBtn"
          btnIcon="/assets/imgs/add.png"
          buttonText="Add Surgery"
          onClick={openSurgeriesModal}
          isDisabled={actionAccessPatients?.create ? false : true}
        />
        <Text textAlign="center">
          No Surgical History Data Available
        </Text>
      </Box>
      <ModalRight
        ref={addCondition}
        header="Add Surgical History"
        body={
          <>
            <SearchBar
              passInputPlace="Search"
              onChange={(e) =>
                setSearchValue(handleAlphaNumericDotAndSpace(e.target.value))
              }
              searchValue={searchValue}
            />
            <RadioGroup>
              <Stack direction="column" gap="0">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  borderBottom="1px solid #e2e8f0"
                  py="15px"
                >
                  <Box display="flex" justifyContent="end" w="100%">
                    <Stack direction="column" gap="0" w="100%">
                      {surgeriesList && surgeriesList.length > 0 ? (
                        surgeriesList.flatMap((category) =>
                          category.data.filter((condition) =>
                            condition.condition
                              .toLowerCase()
                              .includes(searchValue.toLowerCase())
                          )
                        ).length > 0 ? (
                          surgeriesList
                            .flatMap((category) =>
                              category.data.filter((condition) =>
                                condition.condition
                                  .toLowerCase()
                                  .includes(searchValue.toLowerCase())
                              )
                            )
                            .map((condition) => (
                              <label key={condition.condition_cd}>
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  borderBottom="1px solid #e2e8f0"
                                  p="15px"
                                  pl="5px"
                                  minH="50px"
                                  width="100%"
                                  sx={{
                                    _hover: {
                                      border: "2px solid #384052",
                                      borderRadius: "10px",
                                    },
                                  }}
                                >
                                  <Box
                                    key={condition.condition_cd}
                                    display="flex"
                                    alignItems="center"
                                  >
                                    <Text
                                      fontSize="16px"
                                      lineHeight="normal"
                                      fontWeight="600"
                                    >
                                      {condition.condition}
                                      <br />
                                      <Box
                                        as="span"
                                        fontSize="12px"
                                        fontWeight="500"
                                        color="#6A707D"
                                      ></Box>
                                    </Text>
                                  </Box>
                                  <Box display="flex" justifyContent="end">
                                    <Checkbox
                                      colorScheme="green"
                                      sx={{
                                        ".chakra-checkbox__control": {
                                          width: "20px",
                                          height: "20px",
                                          borderRadius: "5px",
                                        },
                                      }}
                                      onChange={() =>
                                        handleCheckboxChange(condition)
                                      }
                                      isChecked={selectedSurgeries.some(
                                        (item) =>
                                          item.condition_cd ===
                                          condition.condition_cd
                                      )}
                                    ></Checkbox>
                                  </Box>
                                </Box>
                              </label>
                            ))
                        ) : (
                          <Box
                            p="15px"
                            fontSize="16px"
                            fontWeight="600"
                            textAlign="center"
                          >
                            No matching surgeries found!
                          </Box>
                        )
                      ) : (
                        <p>Loading...</p>
                      )}
                      <Divider />
                      <br />
                      <Heading
                        as="h2"
                        fontSize="12px"
                        pl="5px"
                        textTransform="uppercase"
                        fontWeight="600"
                        my="10px"
                      >
                        Other Surgical History
                      </Heading>
                      <PrimaryInput
                        inputPlace="Surgical History"
                        variant="bothSide"
                        onChange={handleCondition}
                        inputValue={typedCondition}
                      />
                      <br />
                    </Stack>
                  </Box>
                </Box>
              </Stack>
            </RadioGroup>
            <ModalRight
              ref={addSurgeriesModal}
              header="Add Surgical History"
              body={
                <>
                  <Box display="flex" flexDir="column" gap="20px">
                    {selectedSurgeries.map((condition, index) => (
                      <Box key={index}>
                        <Text fontSize="16px" py="10px" fontWeight="600">
                          {condition.condition}
                        </Text>

                        <DatePicker
                          disableFutureDates={true}
                          placeholderProp="Date"
                          defaultDate={todaysDate}
                          onDateChange={(e) =>
                            handleDatePickerChange(condition.condition_cd, e)
                          }
                          calendarStlye={{
                            transform: "scale(0.9)",
                            right: "-22px",
                            top: "30px",
                          }}
                        />
                        <Box w="100%" pos="relative" cursor="pointer">
                          <PrimaryInput
                            variant="bothSide"
                            inputType="text"
                            inputPlace="Surgeon Name"
                            inputValue={
                              inputValues[condition.condition_cd] || ""
                            }
                            onChange={(e) =>
                              handleSurgeonNameChange(
                                condition.condition_cd,
                                handleAlphaDotAndSpace(e.target.value, 50)
                              )
                            }
                            onClick={() =>
                              listOfSurgeonNames(condition.condition_cd, false)
                            }
                            //if the inputIdProp have id then it will focus, if id is null it will not focus,
                            inputIdProp={
                              inputId == condition.condition_cd
                                ? "Active"
                                : null
                            }
                            boolProp={boolValForSuggestions}
                          />

                          <Image
                            top="33%"
                            right="6.5%"
                            w="15px"
                            onClick={() =>
                              listOfSurgeonNames(condition.condition_cd, true)
                            }
                            pos="absolute"
                            src="/assets/imgs/double-arrow.png"
                          />

                          {/* {suggestions[condition.condition_cd] && suggestions[condition.condition_cd].length > 0 && (
                    <List pos="absolute" w="85%" maxH="150px" overflow="hidden auto" bg="white" zIndex="999" borderTop="none" border="1px solid #E6E7E9" borderRadius="0 0 12px 12px">
                      {suggestions[condition.condition_cd].map((suggestion, index) => (
                        <ListItem
                          key={index}
                          onClick={() => handleSelect(condition.condition_cd, suggestion)}
                          cursor="pointer"
                          p="1rem"
                          py="0.5rem"
                          borderBottom="1px solid #E6E7E9"
                        >
                          <Text fontSize="0.875rem" fontWeight="500">{suggestion.doctorname}</Text>
                        </ListItem>
                      ))}
                    </List>
                  )} */}
                          {showDocNamesDropdown &&
                            suggestions[condition.condition_cd] &&
                            suggestions[condition.condition_cd].length > 0 && (
                              <Menu isOpen={true}>
                                <MenuButton
                                  as={Box}
                                  w="100%"
                                  display="block"
                                  pos="relative"
                                  zIndex="999"
                                >
                                  <Box
                                    pos="absolute"
                                    top="100%"
                                    left="0"
                                    w="100%"
                                    bg="white"
                                    borderTop="none"
                                    border="1px solid #E6E7E9"
                                    borderRadius="0 0 12px 12px"
                                  />
                                </MenuButton>
                                <MenuList
                                  w="100%"
                                  borderRadius="0 0 12px 12px"
                                  zIndex="999"
                                  bg="white"
                                  maxH="150px"
                                  overflow="hidden auto"
                                >
                                  {suggestions[condition.condition_cd].map(
                                    (suggestion, index) => (
                                      <MenuItem
                                        key={index}
                                        onClick={() =>
                                          handleSelect(
                                            condition.condition_cd,
                                            suggestion
                                          )
                                        }
                                        cursor="pointer"
                                        p="1rem"
                                        w="100%"
                                        py="0.5rem"
                                        borderBottom="1px solid #E6E7E9"
                                      >
                                        <Text
                                          fontSize="0.875rem"
                                          fontWeight="500"
                                        >
                                          {suggestion.doctorname}
                                        </Text>
                                      </MenuItem>
                                    )
                                  )}
                                </MenuList>
                              </Menu>
                            )}
                        </Box>
                        <PrimaryInput
                          variant="downSide"
                          inputType="text"
                          inputPlace="Hospital Name"
                          onChange={(e) =>
                            handleHospitalNameChange(
                              condition.condition_cd,
                              e.target.value
                            )
                          }
                        />
                        {documentURLs?.map((document, index) => {
                          if (
                            document.condition_cd == condition.condition_cd ||
                            (document.condition_cd == null &&
                              condition.condition_cd == null)
                          ) {
                            if (!indexMap[document.condition_cd]) {
                              indexMap[document.condition_cd] = 1;
                            }
                            const docIndex = indexMap[document.condition_cd];
                            indexMap[document.condition_cd]++; // Increment index for next document with same condition_cd

                            return (
                              <Box key={index} mb="2" mt="2">
                                <Button
                                  as="a"
                                  rel="noopener noreferrer"
                                  w="100%"
                                  h="48px"
                                  borderRadius="10px"
                                  border="1px solid #E6E7E9"
                                  display="flex"
                                  justifyContent="space-between"
                                  bg="white"
                                  color="#1C4EC5"
                                  sx={{ _hover: { bg: "none" } }}
                                  style={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    fontSize: "12px",
                                  }}
                                >
                                  Document {docIndex}
                                  <Box
                                    as="span"
                                    onClick={() => handleDelete(index)}
                                  >
                                    <Image src="/assets/imgs/delete.png" />
                                  </Box>
                                </Button>
                              </Box>
                            );
                          }
                        })}
                        <Box
                          onClick={() => addSurgeriesUploadDocument(condition)}
                          as="button"
                          display="flex"
                          alignItems="center"
                          gap="10px"
                          mt="5px"
                          fontSize="14px"
                          fontWeight="600"
                          color="#1C4EC5"
                          textAlign="left"
                        >
                          <Image w="11px" src="/assets/imgs/add-blue.png" />
                          Add Document
                        </Box>

                        <input
                          type="file"
                          id="file-upload"
                          onChange={(e) => handleSurgeriesUpload(e)}
                          ref={fileInputRef}
                          style={{ display: "none" }}
                        />
                      </Box>
                    ))}
                  </Box>
                </>
              }
              footerActions={
                <>
                  <Box w="100%" display="flex" flexDirection="row" gap="15px">
                    <PrimaryButton
                      buttonText="Add Surgery"
                      onClick={AddSurgeriesCall}
                      isDisabled={actionAccessPatients?.create ? false : true}
                    />
                  </Box>
                </>
              }
            />
          </>
        }
        footerActions={
          <>
            <Box w="100%" display="flex" flexDirection="row" gap="15px">
              <PrimaryButton
                buttonText="Continue"
                isDisabled={false}
                onClick={AddMedicalConditions}
              />
            </Box>
          </>
        }
      />
    </Box>
  );
});

export default NoSurgicalHistoryDataScreen;
