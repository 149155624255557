import { Box, Divider, Flex, Image, Input, ListItem, Radio, RadioGroup, Slider, Text, UnorderedList } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import PrimaryInput from '../../components/primary-input'
import { addQuestions, FetchMentalScreenQuestions } from '../../middleware/services/home/mentalhealthscreen'
import { getLoginID } from '../../auth'
import { PrimaryButton, Toaster } from '../../components'
import useLoader from '../../components/loader/loaderstates'
import { useNavigate } from 'react-router-dom'

const MentalHealthScreen = ({ patientid, appointmentid, patientData }) => {

  const navigate = useNavigate();

  const { showSuccessToast, showErrorToast } = Toaster();
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const [questionsListData, setQuestionsListData] = useState([])


  // Add Questions
  const [answers, setAnswers] = useState({});
  const [followUps, setFollowUps] = useState({}); 

  // Retrieve and set data from localStorage
  useEffect(() => {
    // console.log('patientData: ', patientData)
    const storedAnswers = JSON.parse(localStorage.getItem("answers")) || {};
    const storedFollowUps = JSON.parse(localStorage.getItem("followUps")) || {};
    setAnswers(storedAnswers);
    setFollowUps(storedFollowUps);
  }, []);

  const arrayToBind = [
    {
      title: 'Lifestyle Habits',
      questions: [
        { 
          id: 1, 
          text: 'Do you follow a balanced diet?', 
          options: ['Yes', 'No'], 
          defaultOption:'Yes',
          answer: (!answers || !answers["Lifestyle Habits"] || !answers["Lifestyle Habits"]["1"]) 
          ? null 
          : (answers["Lifestyle Habits"]["1"]),
        },
        {
          id: 2,
          text: 'Do you consume at least 5 servings of fruits and vegetables daily?',
          options: ['Yes', 'No'],
          defaultOption:'Yes',
          answer: (!answers || !answers["Lifestyle Habits"] || !answers["Lifestyle Habits"]["2"]) 
          ? null 
          : (answers["Lifestyle Habits"]["2"]),
        },
        {
          id: 3,
          text: 'How often do you consume fast food or processed food?',
          options: ['Frequently', 'Sometimes', 'Rarely', 'Never'],
          defaultOption:'Sometimes',
          answer: (!answers || !answers["Lifestyle Habits"] || !answers["Lifestyle Habits"]["3"]) 
          ? null 
          : (answers["Lifestyle Habits"]["3"]),
        },
        {
          id: 4,
          text: 'How much water do you drink daily?',
          options: ['Less than 1 ltr', '1-2 ltrs', 'More than 2 ltrs'],
          defaultOption:'1-2 ltrs',
          answer: (!answers || !answers["Lifestyle Habits"] || !answers["Lifestyle Habits"]["4"]) 
          ? null 
          : (answers["Lifestyle Habits"]["4"]),
        },
        {
          id: 5,
          text: 'Do you engage in regular physical activity (at least 30 minutes a day)?',
          options: ['Yes', 'No'],
          defaultOption:'Yes',
          answer: (!answers || !answers["Lifestyle Habits"] || !answers["Lifestyle Habits"]["5"]) 
          ? null 
          : (answers["Lifestyle Habits"]["5"]),
          followUp: "If yes, specify the type and frequency",
          DefaultFollowup: (!answers || !answers["Lifestyle Habits"] || !answers["Lifestyle Habits"]["5"]) 
          ? "30 minutes" 
          : null 
        },
      ],
    },

    {
      title: 'Mental Health and Well-being',
      questions: [
        {
          id: 6,
          text: 'In the past month, have you felt low, down, or hopeless?',
          options: ['Yes', 'No'],
          defaultOption:'No',
          answer: (!answers || !answers["Mental Health and Well-being"] || !answers["Mental Health and Well-being"]["6"]) 
          ? null 
          : (answers["Mental Health and Well-being"]["6"]),
        },
        {
          id: 7,
          text: 'In the past month, have you experienced anxiety, excessive worry, or panic attacks?',
          options: ['Yes', 'No'],
          defaultOption:'No',
          answer: (!answers || !answers["Mental Health and Well-being"] || !answers["Mental Health and Well-being"]["7"]) 
          ? null 
          : (answers["Mental Health and Well-being"]["7"]),
        },
        {
          id: 8,
          text: 'Have you ever been diagnosed with any mental health conditions (e.g., depression, anxiety, bipolar disorder)?',
          options: ['Yes', 'No'],
          defaultOption:'No',
          answer: (!answers || !answers["Mental Health and Well-being"] || !answers["Mental Health and Well-being"]["8"]) 
          ? null 
          : (answers["Mental Health and Well-being"]["8"]),
          followUp: "If yes, please specify",
        },
        {
          id: 9,
          text: 'Are you currently undergoing any mental health treatments (e.g., therapy, medications)?',
          options: ['Yes', 'No'],
          defaultOption:'No',
          answer: (!answers || !answers["Mental Health and Well-being"] || !answers["Mental Health and Well-being"]["9"]) 
          ? null 
          : (answers["Mental Health and Well-being"]["9"]),
          followUp: "If yes, specify",
        },
      ],
    },

    {
      title: 'Sleep Patterns',
      questions: [
        {
          id: 10,
          text: 'How many hours of sleep do you get per night on average?',
          options: ['Less than 4 hours', '4-6 hours', '6-8 hours', 'More than 8 hours'],
          // answer: answers["Sleep Patterns"]["10"]
          defaultOption:'6-8 hours',
          answer: (!answers || !answers["Sleep Patterns"] || !answers["Sleep Patterns"]["10"]) 
          ? null 
          : (answers["Sleep Patterns"]["10"])
        },
        {
          id: 11,
          text: 'Do you have trouble falling asleep or staying asleep?',
          options: ['Yes', 'No'],
          defaultOption:'No',
          answer: (!answers || !answers["Sleep Patterns"] || !answers["Sleep Patterns"]["11"]) 
          ? null 
          : (answers["Sleep Patterns"]["11"])
        },
        {
          id: 12,
          text: 'Do you feel rested when you wake up?',
          options: ['Yes', 'No'],
          defaultOption:'No',
          answer: (!answers || !answers["Sleep Patterns"] || !answers["Sleep Patterns"]["12"]) 
          ? null 
          : (answers["Sleep Patterns"]["12"])
        },
      ],
    },

    {
      title: 'Stress and Coping Mechanisms',
      questions: [
        {
          id: 13,
          text: 'Do you feel stressed or overwhelmed in your daily life?',
          options: ['Yes', 'No'],
          defaultOption:'No',
          answer: (!answers || !answers["Stress and Coping Mechanisms"] || !answers["Stress and Coping Mechanisms"]["13"]) 
          ? null 
          : (answers["Stress and Coping Mechanisms"]["13"])
        },
        {
          id: 14,
          text: 'How do you usually cope with stress?',
          options: ['Talking to friends/family', 'Exercise', 'Hobbies', 'Professional counseling', 'None of the above'],
          defaultOption:'Exercise',
          answer: (!answers || !answers["Stress and Coping Mechanisms"] || !answers["Stress and Coping Mechanisms"]["14"]) 
          ? null 
          : (answers["Stress and Coping Mechanisms"]["14"])
        },
        {
          id: 15,
          text: 'Do you feel supported by family and friends?',
          options: ['Yes', 'No'],
          defaultOption:'Yes',
          answer: (!answers || !answers["Stress and Coping Mechanisms"] || !answers["Stress and Coping Mechanisms"]["15"]) 
          ? null 
          : (answers["Stress and Coping Mechanisms"]["15"])
        },
      ],
    },
  ]; 

  // const handleAnswerChange = (sectionTitle, questionId, answer) => {
  //   setAnswers((prev) => ({
  //     ...prev,
  //     [sectionTitle]: {
  //       ...prev[sectionTitle],
  //       [questionId]: answer,
  //     },
  //   }));

  //   // Reset follow-up answer if the main answer is not "Yes"
  //   if (answer !== 'Yes') {
  //       setFollowUps((prev) => ({
  //         ...prev,
  //         [sectionTitle]: {
  //           ...prev[sectionTitle],
  //           [questionId]: '',
  //         },
  //       }));
  //     }
  // };

  // const handleFollowUpChange = (sectionTitle, questionId, followUpAnswer) => {
  //   setFollowUps((prev) => ({
  //     ...prev,
  //     [sectionTitle]: {
  //       ...prev[sectionTitle],
  //       [questionId]: followUpAnswer,
  //     },
  //   }));
  // };


  const handleAnswerChange = (stepTitle, questionId, value) => {
    setAnswers((prev) => {
      const updated = { ...prev };
      if (!updated[stepTitle]) updated[stepTitle] = {};
      updated[stepTitle][questionId] = value;

      // Save to localStorage
      localStorage.setItem("answers", JSON.stringify(updated));
      return updated;
    });
  };

  const handleFollowUpChange = (stepTitle, questionId, value) => {
    setFollowUps((prev) => {
      const updated = { ...prev };
      if (!updated[stepTitle]) updated[stepTitle] = {};
      updated[stepTitle][questionId] = value;

      // Save to localStorage
      localStorage.setItem("followUps", JSON.stringify(updated));
      return updated;
    });
  };


  const handleSubmit = () => {
    // console.log('answers: ', answers)
    // console.log('followUps: ', followUps)

    // localStorage.setItem("answers", JSON.stringify(answers));
    // localStorage.setItem("followUps", JSON.stringify(followUps));

    handleAddQuestions()
    const doctorid = getLoginID()
    if(patientData?.type === "startvisit"){
      navigate(`/startvisit?doctorId=${doctorid}&patientId=${patientid}&appointmentId=${appointmentid}`)
    }else{
    navigate("/patientprofile?id="+ patientid+"&page=overview")
    }
  }

  const handleAddQuestions = async () => {
    const addQuestionsInput = {
      "input": {
        "doctorid": getLoginID(),
        "patientid": patientid,
        "visitid": appointmentid,
        "visitformdata": [
          {
            "sectionid": 5,
            "sectionname": "Lifestyle and Mental Health Screening",
            "subsectionid": 5.1,
            "questionnumber": 1,
            "subsectionname": "Lifestyle Habits",
            "question": "Do you follow a balanced diet?",
            "subquestion": null,
            "nestedquestion": null,
            "value": (!answers || !answers["Lifestyle Habits"] || !answers["Lifestyle Habits"]["1"]) 
            ? 'Yes' 
            : (answers["Lifestyle Habits"]["1"]),
            "description": null
          },
          {
            "sectionid": 5,
            "sectionname": "Lifestyle and Mental Health Screening",
            "subsectionid": 5.1,
            "questionnumber": 2,
            "subsectionname": "Lifestyle Habits",
            "question": "Do you consume at least 5 servings of fruits and vegetables daily?",
            "subquestion": null,
            "nestedquestion": null,
            "value": (!answers || !answers["Lifestyle Habits"] || !answers["Lifestyle Habits"]["2"]) 
            ? 'Yes'  
            : (answers["Lifestyle Habits"]["2"]),
            "description": null
          },
          {
            "sectionid": 5,
            "sectionname": "Lifestyle and Mental Health Screening",
            "subsectionid": 5.1,
            "questionnumber": 3,
            "subsectionname": "Lifestyle Habits",
            "question": "How often do you consume fast food or processed food?",
            "subquestion": null,
            "nestedquestion": null,
            "value": (!answers || !answers["Lifestyle Habits"] || !answers["Lifestyle Habits"]["3"]) 
            ? 'Sometimes' 
            : (answers["Lifestyle Habits"]["3"]),
            "description": null
          },
          {
            "sectionid": 5,
            "sectionname": "Lifestyle and Mental Health Screening",
            "subsectionid": 5.1,
            "questionnumber": 4,
            "subsectionname": "Lifestyle Habits",
            "question": "How much water do you drink daily?",
            "subquestion": null,
            "nestedquestion": null,
            "value": (!answers || !answers["Lifestyle Habits"] || !answers["Lifestyle Habits"]["4"]) 
            ? '1-2 ltrs' 
            : (answers["Lifestyle Habits"]["4"]),
            "description": null
          },
          {
            "sectionid": 5,
            "sectionname": "Lifestyle and Mental Health Screening",
            "subsectionid": 5.1,
            "questionnumber": 5,
            "subsectionname": "Lifestyle Habits",
            "question": "Do you engage in regular physical activity (at least 30 minutes a day)?",
            "subquestion": null,
            "nestedquestion": null,
            "value": (!answers || !answers["Lifestyle Habits"] || !answers["Lifestyle Habits"]["5"]) 
            ? 'Yes' 
            : (answers["Lifestyle Habits"]["5"]),
            "description": (!followUps || !followUps["Lifestyle Habits"] || !followUps["Lifestyle Habits"]["5"]) 
            ? '30 minutes' 
            : (followUps["Lifestyle Habits"]["5"]),
          },

          {
            "sectionid": 5,
            "sectionname": "Lifestyle and Mental Health Screening",
            "subsectionid": 5.2,
            "questionnumber": 6,
            "subsectionname": "Mental Health and Well-being",
            "question": "In the past month, have you felt low, down, or hopeless?",
            "subquestion": null,
            "nestedquestion": null,
            "value": (!answers || !answers["Mental Health and Well-being"] || !answers["Mental Health and Well-being"]["6"]) 
            ? 'No' 
            : (answers["Mental Health and Well-being"]["6"]),
            "description": null
          },
          {
            "sectionid": 5,
            "sectionname": "Lifestyle and Mental Health Screening",
            "subsectionid": 5.2,
            "questionnumber": 7,
            "subsectionname": "Mental Health and Well-being",
            "question": "In the past month, have you experienced anxiety, excessive worry, or panic attacks?",
            "subquestion": null,
            "nestedquestion": null,
            "value": (!answers || !answers["Mental Health and Well-being"] || !answers["Mental Health and Well-being"]["7"]) 
            ? 'No' 
            : (answers["Mental Health and Well-being"]["7"]),
            "description": null
          },
          {
            "sectionid": 5,
            "sectionname": "Lifestyle and Mental Health Screening",
            "subsectionid": 5.2,
            "questionnumber": 8,
            "subsectionname": "Mental Health and Well-being",
            "question": "Have you ever been diagnosed with any mental health conditions (e.g., depression, anxiety, bipolar disorder)?",
            "subquestion": null,
            "nestedquestion": null,
            "value": (!answers || !answers["Mental Health and Well-being"] || !answers["Mental Health and Well-being"]["8"]) 
            ? 'No' 
            : (answers["Mental Health and Well-being"]["8"]),
            "description": (!followUps || !followUps["Mental Health and Well-being"] || !followUps["Mental Health and Well-being"]["8"]) 
            ? null 
            : (followUps["Mental Health and Well-being"]["8"]),
          },
          {
            "sectionid": 5,
            "sectionname": "Lifestyle and Mental Health Screening",
            "subsectionid": 5.2,
            "questionnumber": 9,
            "subsectionname": "Mental Health and Well-being",
            "question": "Are you currently undergoing any mental health treatments (e.g., therapy, medications)?",
            "subquestion": null,
            "nestedquestion": null,
            "value": (!answers || !answers["Mental Health and Well-being"] || !answers["Mental Health and Well-being"]["9"]) 
            ? 'No' 
            : (answers["Mental Health and Well-being"]["9"]),
            "description": (!followUps || !followUps["Mental Health and Well-being"] || !followUps["Mental Health and Well-being"]["9"]) 
            ? null 
            : (followUps["Mental Health and Well-being"]["9"]),
          },

          {
            "sectionid": 5,
            "sectionname": "Lifestyle and Mental Health Screening",
            "subsectionid": 5.3,
            "questionnumber": 10,
            "subsectionname": "Sleep Patterns",
            "question": "How many hours of sleep do you get per night on average?",
            "subquestion": null,
            "nestedquestion": null,
            "value": (!answers || !answers["Sleep Patterns"] || !answers["Sleep Patterns"]["10"]) 
            ? '6-8 hours' 
            : (answers["Sleep Patterns"]["10"]),
            "description": null
          },
          {
            "sectionid": 5,
            "sectionname": "Lifestyle and Mental Health Screening",
            "subsectionid": 5.3,
            "questionnumber": 11,
            "subsectionname": "Sleep Patterns",
            "question": "Do you have trouble falling asleep or staying asleep?",
            "subquestion": null,
            "nestedquestion": null,
            "value": (!answers || !answers["Sleep Patterns"] || !answers["Sleep Patterns"]["11"]) 
            ? 'No' 
            : (answers["Sleep Patterns"]["11"]),
            "description": null
          },
          {
            "sectionid": 5,
            "sectionname": "Lifestyle and Mental Health Screening",
            "subsectionid": 5.3,
            "questionnumber": 12,
            "subsectionname": "Sleep Patterns",
            "question": "Do you feel rested when you wake up?",
            "subquestion": null,
            "nestedquestion": null,
            "value": (!answers || !answers["Sleep Patterns"] || !answers["Sleep Patterns"]["12"]) 
            ? 'No' 
            : (answers["Sleep Patterns"]["12"]),
            "description": null
          },

          {
            "sectionid": 5,
            "sectionname": "Lifestyle and Mental Health Screening",
            "subsectionid": 5.4,
            "questionnumber": 13,
            "subsectionname": "Stress and Coping Mechanisms",
            "question": "Do you feel stressed or overwhelmed in your daily life?",
            "subquestion": null,
            "nestedquestion": null,
            "value": (!answers || !answers["Stress and Coping Mechanisms"] || !answers["Stress and Coping Mechanisms"]["13"]) 
            ? 'No' 
            : (answers["Stress and Coping Mechanisms"]["13"]),
            "description": null
          },
          {
            "sectionid": 5,
            "sectionname": "Lifestyle and Mental Health Screening",
            "subsectionid": 5.4,
            "questionnumber": 14,
            "subsectionname": "Stress and Coping Mechanisms",
            "question": "How do you usually cope with stress?",
            "subquestion": null,
            "nestedquestion": null,
            "value": (!answers || !answers["Stress and Coping Mechanisms"] || !answers["Stress and Coping Mechanisms"]["14"]) 
            ? 'Exercise' 
            : (answers["Stress and Coping Mechanisms"]["14"]),
            "description": null
          },
          {
            "sectionid": 5,
            "sectionname": "Lifestyle and Mental Health Screening",
            "subsectionid": 5.4,
            "questionnumber": 15,
            "subsectionname": "Stress and Coping Mechanisms",
            "question": "Do you feel supported by family and friends?",
            "subquestion": null,
            "nestedquestion": null,
            "value": (!answers || !answers["Stress and Coping Mechanisms"] || !answers["Stress and Coping Mechanisms"]["15"]) 
            ? 'Yes' 
            : (answers["Stress and Coping Mechanisms"]["15"]),
            "description": null
          }
        ]
      }
    }
      try {
        showLoader();
        const result = await addQuestions(addQuestionsInput.input);
        if (result.output.result == 'success') {
            //showSuccessToast("Questions Added Successfully")
            // getQuestionsList();
            localStorage.removeItem('formState');
            localStorage.removeItem('saveStates');
            localStorage.removeItem('formData');
            localStorage.removeItem('answers')
            localStorage.removeItem('followUps')
        }
        else {
            showErrorToast("Something went wrong!")
           dismissLoader();
        }
        dismissLoader();
    } catch (error) {
        console.log(error, "error addQuestion")
    }
    }

  return (
    <>
      <Flex gap="1rem" flexDir="column" w="100%">
      {arrayToBind.map((step) => (
        <>
        <Box key={step.title}>
          <Text fontSize="1rem" fontWeight="600">
            {step.title}
          </Text>
          {step.questions.map((question) => (
            <>
            <Flex
              key={question.id}
              gap={question.id === 14 ? '0.5rem' : '1rem'}
              mt="0.875rem"
              justifyContent="space-between"
              flexWrap='wrap'
            >
              <Text fontSize="0.875rem">
                {question.text}
              </Text>
              <RadioGroup
                colorScheme='green'
                display="flex"
                gap="1rem"
                value={answers[step.title]?.[question.id] || question.defaultOption}
                onChange={(value) =>
                  handleAnswerChange(step.title, question.id, value)
                }
              >
                {question.options.map((option) => (
                  <Radio key={option} value={option}>
                    <Text fontSize="0.875rem">{option}</Text>
                  </Radio>
                ))}
              </RadioGroup>
            </Flex>
              {/* Render follow-up question if "Yes" is selected */}
              {((answers[step.title]?.[question.id] === 'Yes') || (question.defaultOption == 'Yes')) &&
                (question.followUp) && (
                  <PrimaryInput
                    inputPlace  ={question.followUp}
                    inputStyleCss={{mt:'0.5rem', w:'50%'}}
                    variant='fullRound'
                    defaultValue={
                      followUps[step.title]?.[question.id] !== undefined
                        ? followUps[step.title]?.[question.id]
                        : question.DefaultFollowup
                    }
                    onChange={(e) =>
                      handleFollowUpChange(
                        step.title,
                        question.id,
                        e.target.value
                      )
                    }
                  />
                )}
                <Divider mt='0.75rem'/>
            </>
          ))}
        </Box>
        {/* <Divider /> */}
        </>
      ))}
      <Box pos='fixed' w='calc(100% - 6rem)' left='5.75rem' bottom='0' p='1rem' bg='white' boxShadow='rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px'>
          <PrimaryButton buttonText='Submit' onClick={handleSubmit} btnStyle={{w:'20%', float:'right'}} />
        </Box>
      {/* <PrimaryButton buttonText='Submit' mt="1rem" onClick={handleSubmit}>
        Submit
      </PrimaryButton> */}
    </Flex>
    </>
  )
};

export default MentalHealthScreen