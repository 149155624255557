import formapinotifications from "./notification";


var formattedOutput = null;

const formapimodalnotifications = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formapinotifications(item));


  return formattedOutput
};

export default formapimodalnotifications