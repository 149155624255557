export default function processPastVisitsData(pastVisits) {
  if (!pastVisits || pastVisits.length === 0) {
    return []; // Return an empty list if pastVisits is null or empty
  }
  return pastVisits.map((visit, index) => {
    return {
      appointmentid: visit.appointmentid,
      chiefcomplaint: visit.chiefcomplaint,
      appointmentdate: visit.appointmentdate,
      specality: visit.specality,
    };
  });
}
