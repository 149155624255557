import formAPItotalvisitschart from "./visitschart";


var formattedOutput = null;
var index = 0;
const formAPIModeltotalvisitschart = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPItotalvisitschart(item, index=index+1));


  return formattedOutput
};

export default formAPIModeltotalvisitschart