export default function processFamilyHistoryData(familyHistory, visitDate) {
  if (!Array.isArray(familyHistory)) {
    return [];
  }

  const filteredData = familyHistory.filter((entry) => {
    if (visitDate) {
      const entryDate = new Date(entry.dob);
      const visitDateObj = new Date(visitDate);
      return entryDate < visitDateObj;
    }
    return true;
  });

  function formatMedicalConditions(medicalConditions) {
    if (!Array.isArray(medicalConditions)) {
      return [];
    }
    return medicalConditions.map((condition) => {
      return { ...condition };
    });
  }

  const processedData = filteredData.map((entry) => {
    const medicalConditionProblems =
      entry.medicalcondition && entry.medicalcondition.length > 0
        ? entry?.medicalcondition
            .map((condition) => condition.condition)
            .join(", ")
        : "None";

    return {
      familyid: entry.familyid,
      firstname: entry.firstname,
      lastname: entry.lastname,
      relation: entry.relation !== null ? entry.relation : "Unknown",
      relation_cd: entry.relation_cd,
      isdeceased: entry.isdeceased,
      isadopted: entry.isadopted,
      dob: entry.dob,
      maritalstatus: entry.maritalstatus,
      medicalcondition: formatMedicalConditions(entry.medicalcondition),
      medicalconditionproblems: medicalConditionProblems,
      allergies: entry.allergies || [],
    };
  });

  return processedData.sort((a, b) => new Date(b.dob) - new Date(a.dob));
}
