import React, { useState, useEffect, useRef } from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  FormControl,
  Box,
  Input,
  Text,
  Image
} from "@chakra-ui/react";
import { COLORS } from "../styles/colors";
import PrimaryInput from "../primary-input";

const SearchableDropdown = ({
  options,
  onClick,
  placeholder,
  value,
  inputStyleCss,
  menuList,
  labelKey = "label", // Default label key
  idKey = "id", // Default id key
  onInputChange,
  defaultValue,
  isMatchWidth = false
}) => {
  const [selectedLabel, setSelectedLabel] = useState(defaultValue ||"");
  const [selectedId, setSelectedId] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);
  const inputRef = useRef();

  useEffect(() => {
    setSelectedLabel(value?.[labelKey] || ""); // Dynamically get label
    setSelectedId(value?.[idKey] || ""); // Dynamically get id
  }, [value, labelKey, idKey]);

  const handleSelect = (option) => {

    setSelectedLabel(option[labelKey]);
    setSelectedId(option[idKey]);
    if (onClick) {
      onClick(option); // Pass the entire option object for flexibility
      setTimeout(() => {
        setSearchValue("")
        setFilteredOptions(options)
      }, 500);
    }
  };

  useEffect(() => {
    if(defaultValue){
      setSelectedLabel(defaultValue)
    }
    setFilteredOptions(options)
  }, [options])

  const defaultFilter = (e) => {
    let value = e.target.value;
    console.log(options , " options")
    console.log(value , " value")
    setSearchValue(value)
    let filteredOptions = options.filter(user => user[labelKey].toLowerCase().includes(value.toLowerCase()));
    setFilteredOptions(filteredOptions);

    // Ensure input remains focused when options are updated
    if (inputRef.current || (value || filteredOptions.length === 0)) {
      inputRef.current.focus();
      setTimeout(() => {
        inputRef.current.focus();
      }, 10);
    }
  }

  return (
    <Menu   {...(isMatchWidth ? { matchWidth: true } : {})}>
      <MenuButton
        as={Button}
        w="100%"
        h="48px"
        fontSize="14px"
        fontWeight="500"
        color="#384052"
        borderRadius="10px"
        px="0.7rem"
        textAlign="left"
        bg="white"
        border="1px solid #E6E7E9"
        _active={{ bg: "none" }}
        _hover={{ bg: "none", outline: "none", border: `2px solid ${COLORS.INPUT_FOCUS_DARK}` }}
        _focus={{ bg: "none", outline: "none", border: "none", boxShadow: `0 0 0 2px ${COLORS.INPUT_FOCUS_DARK}` }}
        {...inputStyleCss}
      >
        {selectedLabel || placeholder}
        {/* <Box 
                            as="button" 
                            // w="100%"
                            // onClick={handleDegreeEdit}
                            mr='10px'
                            > */}
                          
              <Image w="15px" position="absolute" right="15px" top="15px" src="/assets/imgs/double-arrow.png" />
                            {/* </Box> */}
      </MenuButton>
      <MenuList maxH="230px" w="100%" minW='400px' overflow="hidden"  {...menuList}>
        <Box position="sticky" top="0" bg="white" zIndex="1" p="10px">
          <Input ref={inputRef} onChange={onInputChange || defaultFilter} value={searchValue} defaultValue={defaultValue} placeholder='Search...' />
        </Box>
        <Box overflowY="auto" maxH="150px" pos='relative'>
          {filteredOptions.length > 0 ? (filteredOptions.map((option, index) => (
            <MenuItem
              key={index}
              fontSize="14px"
              lineHeight="normal"
              fontWeight="500"
              color="#384052"
              onClick={() => handleSelect(option)}
              data-id={option[idKey]} // Dynamically use id key
            >
              {option[labelKey]} {/* Dynamically use label key */}
            </MenuItem>
          ))) :
            (
              <Box p="10px">
                <Text fontSize="12px" >Option Not Found!</Text>
              </Box>
            )}
        </Box>
      </MenuList>
    </Menu>
  );
};

export default SearchableDropdown;