export default function processSurgicalHistoryData(surgicalHistory, visitDate) {
  if (!Array.isArray(surgicalHistory)) {
    return [];
  }

  const filteredData = surgicalHistory.filter((surgery) => {
    if (visitDate) {
      const surgeryDate = new Date(surgery.proceduredate);
      const visitDateObj = new Date(visitDate);
      return surgeryDate < visitDateObj;
    }
    return true;
  });

  const processedData = filteredData.map((surgery) => {
    let attachmentCounter = 1;

    return {
      id: surgery.id,
      patientid: surgery.patientid,
      proceduretype_cd: surgery.proceduretype_cd,
      procedure_cd: surgery.procedure_cd,
      nomenclatureid: surgery.nomenclatureid,
      date: surgery.proceduredate,
      surgeryName: surgery.proceduretitle,
      doctorName: surgery.surgeonname,
      hospital: surgery.hospitalname,
      hospitalid: surgery.hospitalid,
      attachments: (surgery.documents || []).map((doc) => {
        const extension = doc?.documenturl?.split(".").pop();
        const name = `Surgery-${attachmentCounter++}.${extension}`;

        return {
          link: doc?.imagepath + doc?.documenturl,
          name: name,
        };
      }),
    };
  });

  return processedData.sort((a, b) => new Date(b.date) - new Date(a.date));
}
