export default function processROSData(reviewOfSystem, visitDate) {
  if (!Array.isArray(reviewOfSystem)) {
    return [];
  }

  const filteredData = visitDate
    ? reviewOfSystem.filter((ros) => {
        const rosDate = ros.date.split("T")[0]; // Extract date part
        const visitDateOnly = visitDate.split("T")[0]; // Extract date part
        return rosDate === visitDateOnly;
      })
    : reviewOfSystem;

  const processedData = filteredData.map((ros) => ({
    id: ros.id,
    patientid: ros.patientid,
    doctorid: ros.doctorid,
    appointmentid: ros.appointmentid,
    type: ros.rosname,
    condition:
      ros.rosvalue && Array.isArray(ros.rosvalue)
        ? ros.rosvalue.map((value) => value.value).join(", ")
        : "",
    date: ros.date,
  }));

  // Sort the processed data by date in ascending order
  processedData.sort((a, b) => new Date(b.date) - new Date(a.date));

  return processedData;
}
