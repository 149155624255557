import React from "react";
import { Spinner } from "@chakra-ui/react";
import useLoader from "./loaderstates"; // Importing the useLoader hook
import LottieAnimation from "../lottie-animation";

const Loader = ({ miniLoader = false }) => {
  const { showLoader, dismissLoader } = useLoader(); // Using the useLoader hook

  return (
    <>
      <div
        style={{
          position: miniLoader ? "relative" : "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: miniLoader
            ? "rgba(0, 0, 0, 0)" // Fully transparent background
            : "rgba(0, 0, 0, 0.5)", // Adjust the opacity as needed
          backdropFilter: miniLoader ? "none" : "blur(5px)", // No blur when miniLoader is true
          zIndex: miniLoader ? "0" : "9999", // Adjust as needed
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <img src="/assets/imgs/Loader.gif" alt="Loading..." visibility={showLoader? "visible":"hidden"}/> */}
        {/* <Spinner
      thickness="4px"
      speed="0.65s"
      emptyColor="gray.200"
      color="blue.500"
      size="xl"
      visibility={showLoader? "visible":"hidden"}
    /> */}
        {showLoader && (
          <LottieAnimation
            animationPath="/assets/loader.json"
            lottieWidth="10em"
          />
        )}
      </div>
    </>
  );
};

export default Loader;
