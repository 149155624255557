import formAPIimaging from "./imaging";


var formattedOutput = null;

const formAPIModelimaging = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIimaging(item));


  return formattedOutput
};

export default formAPIModelimaging