import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";

const ToggleTab = ({ title, body }) => {
  return (
    <>
      <Accordion allowToggle>
        <AccordionItem>
            <AccordionButton p="1rem">
              <Box as="h2" fontSize="0.75rem" fontWeight="600" flex="1" textTransform="uppercase" textAlign="left">
                {title}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          <AccordionPanel pb={4}>{body}</AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
};

export default ToggleTab;
