import { Box, Center, Flex, Grid, Image, SimpleGrid, VStack, useToast } from '@chakra-ui/react'
import React,{useState,useEffect} from 'react'
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import { PrimaryButton } from '../../components'
import { SubmitDoctorSpeciality, FetchDoctorSpecialities } from '../../middleware/services/finalsteps';
import Toaster from "../../components/toaster";


const Specialty = ({onSubmit}) => {
  const { isLoading, showLoader, dismissLoader } = useLoader();
    const [data, setData] = useState("");
    const [selectedOptions, setSelectedOptions] = useState([]);
    const { showSuccessToast, showErrorToast } = Toaster();
    const toast = useToast();


    const handleOptionToggle = (text) => {
      setSelectedOptions(prevOptions => {
          const isSelected = prevOptions.includes(text);
          if (isSelected) {
              // Remove the option if it's already selected
              return prevOptions.filter(option => option !== text);
          } else {
              // Add the option if it's not selected
              return [...prevOptions, text];
          }
      });
  };
    const findDoctor = [
        {
          "imageUrl": "/assets/imgs/cardiology-final.png",
          "text": "Cardiology",
          "url": "/orthopedist"
        },
        {
          "imageUrl": "/assets/imgs/dentist-final.png",
          "text": "Dentist",
          "url": "/dentist"
        },
        {
          "imageUrl": "/assets/imgs/endocrinology-final.png",
          "text": "Endocrinology",
          "url": "/endocrinology"
        },
        {
          "imageUrl": "/assets/imgs/family-medicine-final.png",
          "text": "Family medicine",
          "url": "/family-medicine"
        },
        {
          "imageUrl": "/assets/imgs/gastroenterology-final.png",
          "text": "Gastroenterology",
          "url": "/gastroenterology"
        },
        {
          "imageUrl": "/assets/imgs/neurology-final.png",
          "text": "Neurology",
          "url": "/neurology"
        },
        {
          "imageUrl": "/assets/imgs/gynecology-final.png",
          "text": "Gynecology",
          "url": "/gynecology"
        },
        {
          "imageUrl": "/assets/imgs/nephrology-final.png",
          "text": "Nephrologist",
          "url": "/nephrologist"
        },
        {
          "imageUrl": "/assets/imgs/oncology-final.png",
          "text": "Oncology",
          "url": "/oncology"
        },
        {
          "imageUrl": "/assets/imgs/ophthalmology-final.png",
          "text": "Ophthalmology",
          "url": "/ophthalmology"
        },
        {
          "imageUrl": "/assets/imgs/orthopedics-final.png",
          "text": "Orthopedics",
          "url": "/orthopedics"
        },
        {
          "imageUrl": "/assets/imgs/pediatrics-final.png",
          "text": "Pediatrics",
          "url": "/pediatrics"
        },
        {
          "imageUrl": "/assets/imgs/psychiatry-final.png",
          "text": "Psychiatry",
          "url": "/psychiatry"
        },
        {
          "imageUrl": "/assets/imgs/pulmonology-final.png",
          "text": "Pulmonology",
          "url": "/pulmonology"
        },
        {
          "imageUrl": "/assets/imgs/surgery-final.png",
          "text": "Surgery",
          "url": "/surgery"
        },
        // {
        //   "imageUrl": "/assets/imgs/other-final.png",
        //   "text": "Other",
        //   "url": "/other"
        // },
      ]
      
      useEffect(() => {
        const choosedoptions = localStorage.getItem("selectedspecialities")
        if (choosedoptions) {
          const selectedOptionsArray = choosedoptions.split(",").map(option => parseInt(option));
          setSelectedOptions(selectedOptionsArray);
        }

        
        FetchSpecialities();
       }, []);
       const FetchSpecialities = async ()=>{
         showLoader()
         try {
           var result = await FetchDoctorSpecialities({
            "type":"speciality"
           });
           if (result) {
             setData(result)
           }
           dismissLoader();
         } catch (error) {
           console.log(error, "Something went wrong in doctor profile")
         }
       }
 
     let input = {"doctorid":"","speciality":[{"specialtyid":1}]}
     
       const SubmitFunction = () =>{
        if(selectedOptions.length === 0){
          showErrorToast("Please select your speciality.")
          return;
        }
        localStorage.setItem("selectedspecialities", selectedOptions)
        console.log(selectedOptions)
        const input = {
          "doctorid":"",
          speciality: selectedOptions.map(option => {
              return { specialtyid: option };
          })
        }
        localStorage.setItem("specialities",JSON.stringify(input))
        setTimeout(() => {
          const newActiveComponent = "review";
          onSubmit(newActiveComponent);
        }, 1000);
      }
  return (
    <>
    <Flex display={{base: "none", lg: "flex"}}>
        {isLoading && <Loader />}
        <Box w="100%" p={4}>
          <Center>
            <VStack spacing={0} w="450px">
              <Box as="p" fontSize="22px" fontWeight="600" mb={5}>
                Select your specialization
              </Box>
                <Box w="100%">
                    <Box w="100%" px="30px" py="10px" mb="20px">
                    <Box w="100%" mb="20px">
                    <Grid
                    templateColumns={{ base: "repeat(auto-fill, minmax(180px, 1fr))", md: "repeat(3, 1fr)" }}
                    gap="20px"
                    alignItems="center"
                    >
                    {data && data.map((profile, index) => (
                        <>
                        <Box display="flex" flexDir="column" alignItems="center">
                            <Box
                            key={index}
                            as="button"
                            // border="2px solid #0C7650"
                            w="7.5rem"
                            h="3.75rem"
                            borderRadius="8px"
                            display="flex"
                            flexDirection="column"
                            bg="#FCFCFD"
                            lineHeight="normal"
                            alignItems="center"
                            justifyContent="center"
                            //onClick={()=> handleselection(profile.id)}
                            border={selectedOptions.includes(profile.id) ? "2px solid #0C7650" : "none"}
                            onClick={() => handleOptionToggle(profile.id, profile.specialityname)}
                            boxShadow="0px 1px 2px 0px #C1C2C680, 0px -8px 12px 0px #D8D8D833 inset"
                            >
                            <Image src={profile.url? profile.imagepath+profile.url : "/assets/imgs/cardiology-final.png"} />
                            </Box>
                            <Box as="p" color="black" mt="8px" textAlign="center" fontSize="14px" whiteSpace="nowrap" fontWeight="500">
                                {profile.specialityname}
                            </Box>
                        </Box>
                        </>
                    ))}
                    </Grid>
                    </Box>
                </Box>
              </Box>
              <Box w="100%">
                <PrimaryButton buttonText="Continue" id="submitButton" 
                 onClick={SubmitFunction}
                 />
              </Box>
            </VStack>
          </Center>
        </Box>
    </Flex>

    {/* Mobile responsive start */}
    <Flex flexDir="column" h="100vh" display={{base: "flex", lg: "none"}}>
    {isLoading && <Loader />}
        <Box w="100%" p={4}>
          {/* <Center> */}
            <VStack spacing={0} w="100%">
              <Box as="p" fontSize="22px" fontWeight="600" mb={5}>
                Select your specialization
              </Box>
                <Box w="100%">
                    <Box w="100%" px="30px" py="10px" mb="20px">
                    <Box w="100%" mb="20px">
                      <SimpleGrid
                        // templateColumns={{ base: "repeat(auto-fill, minmax(180px, 1fr))", md: "repeat(3, 1fr)" }}
                        columns={2}
                        gap="1rem"
                        alignItems="center"
                        >
                        {data && data.map((profile, index) => (
                            <>
                            <Box display="flex" flexDir="column" alignItems="center">
                                <Box
                                key={index}
                                as="button"
                                // border="2px solid #0C7650"
                                w="7.5rem"
                                h="3.75rem"
                                borderRadius="8px"
                                display="flex"
                                flexDirection="column"
                                bg="#FCFCFD"
                                lineHeight="normal"
                                alignItems="center"
                                justifyContent="center"
                                //onClick={()=> handleselection(profile.id)}
                                border={selectedOptions.includes(profile.id) ? "2px solid #0C7650" : "none"}
                                onClick={() => handleOptionToggle(profile.id, profile.specialityname)}
                                boxShadow="0px 1px 2px 0px #C1C2C680, 0px -8px 12px 0px #D8D8D833 inset"
                                >
                                <Image src={profile.url? profile.imagepath+profile.url : "/assets/imgs/cardiology-final.png"} />
                                </Box>
                                <Box as="p" color="black" mt="8px" textAlign="center" fontSize="14px" whiteSpace="nowrap" fontWeight="500">
                                    {profile.specialityname}
                                </Box>
                            </Box>
                            </>
                        ))}
                        </SimpleGrid>                    
                    </Box>
                </Box>
              </Box>
              <Box w="100%">
                <PrimaryButton buttonText="Continue" id="submitButton" 
                 onClick={SubmitFunction}
                 />
              </Box>
            </VStack>
          {/* </Center> */}
        </Box>
    </Flex>

    </>
  )
}

export default Specialty

