import formAPIupcomingvisits from "./upcomingvisit";


var formattedOutput = null;

const formAPIModelupcomingvisits = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIupcomingvisits(item));


  return formattedOutput
};

export default formAPIModelupcomingvisits