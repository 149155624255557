export default function processLabTestsData(labs, visitDate) {
  if (!Array.isArray(labs)) {
    return [];
  }

  const filteredData = visitDate
    ? labs.filter(
        (lab) => lab.verifieddate.split("T")[0] === visitDate.split("T")[0]
      )
    : labs;

  const processedData = filteredData.map((lab) => {
    let attachmentCounter = 1;

    return {
      name: lab.reportname,
      summary: lab.reportdescription,
      date: lab.verifieddate,
      documenturl: lab?.documenturl,
      attachments: (lab.documents || []).map((document) => {
        const extension = document?.documenturl?.split(".").pop();
        const name = `Report-${attachmentCounter++}.${extension}`;

        return {
          link: document?.imagepath + document?.documenturl,
          name: name,
        };
      }),
    };
  });

  // Sort the processed data by date in reverse order (descending)
  processedData.sort((a, b) => new Date(b.date) - new Date(a.date));

  return processedData;
}
