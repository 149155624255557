import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
  Flex,
  Divider,
  FormErrorMessage,
} from "@chakra-ui/react";



import { Controller, useWatch } from "react-hook-form";

import { COLORS } from "../../../../styles/colors";
import { actionAccessMain } from "../../../../../auth";
import Loader from "../../../../loader/loader";
import useLoader from "../../../../loader/loaderstates";
import PrimaryButton from "../../../../primary-button";
import { GetDoctorNotes, updateDoctorNotes } from "../../../../../middleware/services/visits/patient-overview/updatedoctornotes";


const AIRAVitalsForm = ({
  patientId,
  doctorId,
  appointmentId,
  showSuccessToast,
  showErrorToast,
  AiVitals
}) => {
  const {
    control,
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { isDirty, errors },
  } = useForm();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const { isLoading, showLoader, dismissLoader } = useLoader();
  const temperatureUnit = useWatch({ control, name: "temperatureUnit" });

  const fetchDoctorNotes = async (AiVitals) => {
    const inputToFetch = {
      input: {
        patientid: patientId,
        doctorid: doctorId,
        appointmentid: appointmentId,
      },
    };
    showLoader();
    try {
    //   const data = await GetDoctorNotes(inputToFetch.input);
      const vitals = AiVitals || [];
      const formValues = {};
      vitals.forEach((vital) => {
        let vitalName = vital.vitalname;

        // Check and adjust vital names if needed
        if (vitalName === "Body temperature") {
          vitalName = "Body Temperature";
        } else if (vitalName === "Respiratory rate") {
          vitalName = "Respiratory Rate";
        }
        formValues[vitalName] = vital.vitalvalue;
      });
      const temperatureUnit =
        vitals.find(
          (vital) =>
            vital.vitalname === "Body temperature" ||
            vital.vitalname === "Body Temperature"
        )?.vitalunit || "°F";
      formValues["temperatureUnit"] = temperatureUnit;

      // Reset form with fetched values
      reset(formValues);

      // console.log("Fetched form values: ", formValues);
    } catch (error) {
      console.error("Error fetching Doctor Notes data:", error);
    } finally {
      dismissLoader();
    }
  };

  const onSubmit = async (data) => {
    const vitals = [];

    const fields = [
      { name: "Systolic Blood Pressure", unit: "mmHg" },
      { name: "Diastolic Blood Pressure", unit: "mmHg" },
      { name: "Pulse", unit: "bpm" },
      { name: "Body Temperature", unit: data.temperatureUnit },
      { name: "Respiratory Rate", unit: "bpm" },
      { name: "Oxygen Saturation", unit: "%" },
    ];

    fields.forEach((field) => {
      const value = data[field.name];
      const padZero = (num) => num.toString().padStart(2, "0");

      const now = new Date();
      const formattedDate = `${now.getFullYear()}-${padZero(
        now.getMonth() + 1
      )}-${padZero(now.getDate())} ${padZero(now.getHours())}:${padZero(
        now.getMinutes()
      )}:${padZero(now.getSeconds())}`;

      if (value !== undefined && value !== "") {
        vitals.push({
          vitalname: field.name,
          vitalvalue: value,
          vitalunit: field.unit,
          vitaltype_cd: "",
          // vitaladdeddate: new Date().toISOString(),
          vitaladdeddate: formattedDate,
        });
      }
    });

    const inputToSave = {
      input: {
        doctorid: doctorId,
        patientid: patientId,
        appointmentid: appointmentId,
        vitals: vitals,
      },
    };
    console.log(inputToSave);
    showLoader();
    try {
      const result = await updateDoctorNotes(inputToSave.input);
      if (result) {
        showSuccessToast("Vitals saved successfully");
        setIsButtonDisabled(true); // Disable button after successful submission
      }
    } catch (error) {
      console.error("Error in saving vitals:", error);
      showErrorToast("Error happened while saving vitals.");
    } finally {
      dismissLoader();
    }
  };

  useEffect(() => {
    fetchDoctorNotes(AiVitals);

  }, []);

  useEffect(() => {
    setIsButtonDisabled(!isDirty);
  }, [isDirty]);

  return (
    <Box
      position="relative"
      height="70%"
      display="flex"
      flexDirection="column"
    >
      {isLoading && (
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex="10"
          bg="rgba(255, 255, 255, 0.8)"
        >
          <Loader miniLoader={true} />
        </Box>
      )}
      <Box
        bg="#fcfcfd"
        p={{ base: 4, md: 3 }}
        pt={1}
        borderRadius="md"
        boxShadow="md"
        height="auto"
      >
        <form onSubmit={handleSubmit(onSubmit)} style={{ height: "100%" }}>
          <VStack spacing={4} align="stretch" height="100%">
            {/* Row 1 */}
            <FormControl isInvalid={errors["Body Temperature"]}>
              <Flex height="60px" align="center">
                <Flex direction="column" width={{ base: "50%", md: "30%" }}>
                  <FormLabel mb="0px">Body Temperature</FormLabel>
                  <Controller
                    name="temperatureUnit"
                    control={control}
                    defaultValue="°F"
                    render={({ field }) => (
                      <Select
                        {...field}
                        height="50%"
                        width={{ base: "5%", md: "45%" }}
                        fontSize={"xs"}
                        size={"xs"}
                        border={"none"}
                        // style={{ textAlignLast: "left", ml: "0px" }}
                        style={{
                          textAlignLast: "left",
                          marginLeft: "0px",
                          minWidth: "60px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          // backgroundColor: "red",
                        }}
                      >
                        <option value="°F" style={{ textAlignLast: "left" }}>
                          °F
                        </option>
                        <option value="°C">°C</option>
                      </Select>
                    )}
                  />
                </Flex>
                <Box width={{ base: "50%", md: "70%" }} textAlign="right">
                  <Input
                    type="number"
                    step="0.01"
                    {...register("Body Temperature", {
                      maxLength: {
                        value: 6,
                        message: "Maximum 6 characters allowed",
                      },
                      pattern: {
                        value: /^(\d{1,3}(\.\d{0,2})?)$/,
                        message: "Invalid input",
                      },
                      validate: (value) => {
                        if (value) {
                          const temp = parseFloat(value);
                          if (temperatureUnit === "°F") {
                            return (
                              (temp >= 77 && temp <= 108) ||
                              "Invalid input: Temperature should be between 77°F and 108°F"
                            );
                          } else {
                            return (
                              (temp >= 24 && temp <= 42) ||
                              "Invalid input: Temperature should be between 24°C and 42°C"
                            );
                          }
                        }
                      },
                    })}
                    onWheel={(e) => e.target.blur()} // Prevent value change on scrolling
                    placeholder="Enter value"
                    height="48px"
                    p="0 10px"
                    width={{ base: "100px", md: "200px" }}
                    bg={"white"}
                    textAlign={"center"}
                    ml="auto"
                    onChange={() => setIsButtonDisabled(false)}
                  />
                  <FormErrorMessage>
                    {errors["Body Temperature"] &&
                      errors["Body Temperature"].message}
                  </FormErrorMessage>
                </Box>
              </Flex>
            </FormControl>

            {/* Row 2 */}
            <FormControl isInvalid={errors["Pulse"]}>
              <Flex height="60px" align="center">
                <Flex direction="column" width={{ base: "50%", md: "100%" }}>
                  <FormLabel width={{ base: "100%", md: "30%" }} mb="0px">
                    Heart Rate
                  </FormLabel>
                  <Box fontSize={"xs"} pt="2px">
                    beats per minute
                  </Box>
                </Flex>
                <Box width={{ base: "50%", md: "70%" }} textAlign="right">
                  <Input
                    type="number"
                    {...register("Pulse", {
                      maxLength: {
                        value: 3,
                        message: "Maximum 3 digits allowed",
                      },
                      pattern: {
                        value: /^[0-9]{1,3}$/,
                        message: "Invalid input",
                      },
                      validate: (value) => {
                        if (value) {
                          const pulse = parseInt(value);
                          return (
                            (pulse >= 20 && pulse <= 300) ||
                            "Invalid input: Heart rate should be between 20 and 300 bpm"
                          );
                        }
                      },
                    })}
                    onWheel={(e) => e.target.blur()} // Prevent value change on scrolling
                    placeholder="Enter value"
                    height="48px"
                    width={{ base: "100px", md: "200px" }}
                    bg={"white"}
                    textAlign={"center"}
                    ml="auto"
                    onChange={() => setIsButtonDisabled(false)}
                    p="0 10px"
                  />
                  <FormErrorMessage>
                    {errors["Pulse"] && errors["Pulse"].message}
                  </FormErrorMessage>
                </Box>
              </Flex>
            </FormControl>

            {/* Row 3 */}
            <FormControl isInvalid={errors["Respiratory Rate"]}>
              <Flex height="60px" align="center">
                <Flex direction="column" width="100%">
                  <FormLabel width={{ base: "100%", md: "30%" }} mb="0px">
                    Respiratory Rate
                  </FormLabel>
                  <Box fontSize={"xs"} pt="2px">
                    breaths per minute
                  </Box>
                </Flex>
                <Box width="70%" textAlign="right">
                  <Input
                    type="number"
                    {...register("Respiratory Rate", {
                      maxLength: {
                        value: 3,
                        message: "Maximum 3 digits allowed",
                      },
                      pattern: {
                        value: /^[0-9][0-9]{0,2}$/,
                        message: "Invalid input",
                      },
                      validate: (value) => {
                        if (value) {
                          const rate = parseInt(value);
                          return (
                            (rate >= 5 && rate <= 60) ||
                            "Invalid input: Respiratory rate should be between 5 and 60 breaths per minute"
                          );
                        }
                      },
                    })}
                    onWheel={(e) => e.target.blur()} // Prevent value change on scrolling
                    placeholder="Enter value"
                    height="48px"
                    width={{ base: "100px", md: "200px" }}
                    p="0 10px"
                    bg={"white"}
                    textAlign={"center"}
                    ml="auto"
                    onChange={() => setIsButtonDisabled(false)}
                  />
                  <FormErrorMessage>
                    {errors["Respiratory Rate"] &&
                      errors["Respiratory Rate"].message}
                  </FormErrorMessage>
                </Box>
              </Flex>
            </FormControl>

            <Divider />

            {/* Row 4 */}
            {/* <FormControl isInvalid={errors["Systolic Blood Pressure"]}>
              <Flex height="60px" align="center">
                <Flex direction="column" width="30%">
                  <FormLabel mb="0px">Systolic</FormLabel>
                  <Box fontSize={"xs"} pt="2px">
                    mmHg
                  </Box>
                </Flex>
                <Box width="70%" textAlign="right">
                  <Input
                    type="number"
                    {...register("Systolic Blood Pressure", {
                      maxLength: {
                        value: 3,
                        message: "Maximum 3 digits allowed",
                      },
                      pattern: {
                        value: /^[0-9]{1,3}$/,
                        message: "Invalid input",
                      },
                      validate: (value) => {
                        if (value) {
                          const systolic = parseInt(value);
                          return (
                            (systolic >= 70 && systolic <= 200) ||
                            "Invalid input: Systolic BP should be between 70 and 200 mmHg"
                          );
                        }
                      },
                    })}
                    onWheel={(e) => e.target.blur()} // Prevent value change on scrolling
                    placeholder="Enter value"
                    height="48px"
                    width={{ base: "100px", md: "200px" }}
                    bg={"white"}
                    textAlign={"center"}
                    ml="auto"
                    p="0 10px"
                    onChange={() => setIsButtonDisabled(false)}
                  />
                  <FormErrorMessage>
                    {errors["Systolic Blood Pressure"] &&
                      errors["Systolic Blood Pressure"].message}
                  </FormErrorMessage>
                </Box>
              </Flex>
            </FormControl> */}

            {/* Row 5 */}
            {/* <FormControl isInvalid={errors["Diastolic Blood Pressure"]}>
              <Flex height="60px" align="center">
                <Flex direction="column" width="30%">
                  <FormLabel mb="0px">Diastolic</FormLabel>
                  <Box fontSize={"xs"} pt="2px">
                    mmHg
                  </Box>
                </Flex>
                <Box width="70%" textAlign="right">
                  <Input
                    type="number"
                    {...register("Diastolic Blood Pressure", {
                      maxLength: {
                        value: 3,
                        message: "Maximum 3 digits allowed",
                      },
                      pattern: {
                        value: /^[0-9]{1,3}$/,
                        message: "Invalid input",
                      },
                      validate: (value) => {
                        if (value) {
                          const diastolic = parseInt(value);
                          return (
                            (diastolic >= 40 && diastolic <= 120) ||
                            "Invalid input: Diastolic BP should be between 40 and 120 mmHg"
                          );
                        }
                      },
                    })}
                    onWheel={(e) => e.target.blur()} // Prevent value change on scrolling
                    placeholder="Enter value"
                    height="48px"
                    width={{ base: "100px", md: "200px" }}
                    p="0 10px"
                    bg={"white"}
                    textAlign={"center"}
                    ml="auto"
                    onChange={() => setIsButtonDisabled(false)}
                  />
                  <FormErrorMessage>
                    {errors["Diastolic Blood Pressure"] &&
                      errors["Diastolic Blood Pressure"].message}
                  </FormErrorMessage>
                </Box>
              </Flex>
            </FormControl> */}

            {/* Row 4: Merged Systolic and Diastolic BP Inputs */}
            <FormControl
              isInvalid={
                errors["Systolic Blood Pressure"] ||
                errors["Diastolic Blood Pressure"]
              }
            >
              <Flex height="60px" align="center">
                {/* Single Label for both Systolic and Diastolic */}
                <Flex direction="column" width="30%">
                  <FormLabel mb="0px">Blood Pressure</FormLabel>
                  <Box fontSize={"xs"} pt="2px">
                    mmHg
                  </Box>
                </Flex>

                {/* Systolic and Diastolic Input Fields Aligned Right */}
                <Box width="70%" textAlign="right">
                  <Flex justify="flex-end" align="center">
                    {/* Systolic Input */}
                    <Input
                      type="number"
                      {...register("Systolic Blood Pressure", {
                        maxLength: {
                          value: 3,
                          message: "Maximum 3 digits allowed",
                        },
                        pattern: {
                          value: /^[0-9]{1,3}$/,
                          message: "Invalid input",
                        },
                        validate: (value) => {
                          if (value) {
                            const systolic = parseInt(value);
                            return (
                              (systolic >= 60 && systolic <= 300) ||
                              "Invalid input: Systolic BP should be between 60 and 300 mmHg"
                            );
                          }
                        },
                      })}
                      onWheel={(e) => e.target.blur()} // Prevent value change on scrolling
                      placeholder="Systolic"
                      height="48px"
                      width={{ base: "90px", md: "90px" }}
                      bg={"white"}
                      textAlign={"center"}
                      p="0 10px"
                      onChange={() => setIsButtonDisabled(false)}
                    />

                    {/* Separator */}
                    <Box mx={2}>/</Box>

                    {/* Diastolic Input */}
                    <Input
                      type="number"
                      {...register("Diastolic Blood Pressure", {
                        maxLength: {
                          value: 3,
                          message: "Maximum 3 digits allowed",
                        },
                        pattern: {
                          value: /^[0-9]{1,3}$/,
                          message: "Invalid input",
                        },
                        validate: (value) => {
                          if (value) {
                            const diastolic = parseInt(value);
                            return (
                              (diastolic >= 30 && diastolic <= 200) ||
                              "Invalid input: Diastolic BP should be between 30 and 200 mmHg"
                            );
                          }
                        },
                      })}
                      onWheel={(e) => e.target.blur()} // Prevent value change on scrolling
                      placeholder="Diastolic"
                      height="48px"
                      width={{ base: "90px", md: "90px" }}
                      bg={"white"}
                      textAlign={"center"}
                      p="0 10px"
                      onChange={() => setIsButtonDisabled(false)}
                    />
                  </Flex>

                  {/* Error Messages */}
                  <FormErrorMessage>
                    {errors["Systolic Blood Pressure"]?.message ||
                      errors["Diastolic Blood Pressure"]?.message}
                  </FormErrorMessage>
                </Box>
              </Flex>
            </FormControl>

            <Divider />

            {/* Row 6 */}
            <FormControl isInvalid={errors["Oxygen Saturation"]}>
              <Flex height="60px" align="center">
                <Flex direction="column" width="100%">
                  <FormLabel width="30%" mb="0px">
                    SPO2
                  </FormLabel>
                  <Box fontSize={"xs"} pt="2px">
                    %
                  </Box>
                </Flex>
                <Box width="70%" textAlign="right">
                  <Input
                    type="number"
                    step="0.01"
                    {...register("Oxygen Saturation", {
                      maxLength: {
                        value: 6, // Adjusted to accommodate decimal places
                        message: "Enter value between 0 to 100",
                      },
                      pattern: {
                        value: /^(100(\.0{1,2})?|(\d{1,2})(\.\d{1,2})?)$/,
                        message: "Enter value between 0 to 100",
                      },
                      validate: (value) => {
                        if (value) {
                          const o2 = parseInt(value);
                          return (
                            (o2 >= 50 && o2 <= 100) ||
                            "Invalid input: SPO2 should be between 50 and 100 %"
                          );
                        }
                      },
                    })}
                    onWheel={(e) => e.target.blur()} // Prevent value change on scrolling
                    placeholder="Enter value"
                    height="48px"
                    width={{ base: "100px", md: "200px" }}
                    p="0 10px"
                    bg={"white"}
                    textAlign={"center"}
                    ml="auto"
                    onChange={() => setIsButtonDisabled(false)}
                  />
                  <FormErrorMessage mb={"1.5rem"}>
                    {errors["Oxygen Saturation"] &&
                      errors["Oxygen Saturation"].message}
                  </FormErrorMessage>
                </Box>
              </Flex>
            </FormControl>

            <PrimaryButton
              type="submit"
              buttonText="Save Vitals"
            //   isDisabled={isButtonDisabled || !actionAccessMain(19)?.create}
              btnStyle={{
                h: "2.5rem",
                w: "100%",
                p: "0",
                mr: "0px",
                ml: "0px",
                // position: "sticky",
                // bottom: "0",
                zIndex: "1",
                mx: "auto",
                mt: "auto",
                bg: COLORS.PRIMARY_GRADIENT,
                color: COLORS.PRIMARY_WHITE,
                _hover: { bg: COLORS.PRIMARY_COLOR },
              }}
            />

            {/* <PrimaryButton
              type="submit"
              buttonText="Submit Vitals"
              isDisabled={isButtonDisabled}
              btnStyle={{
                h: "2.5rem",
                w: "100%",
                p: "0",
                mr: "12px",
                ml: "12px",
                position: "sticky",
                bottom: "0",
                zIndex: "1",
                mx: "auto",
                bg: COLORS.PRIMARY_GRADIENT,
                color: COLORS.PRIMARY_WHITE,
                _hover: { bg: COLORS.PRIMARY_COLOR },
              }}
            /> */}
          </VStack>
        </form>
      </Box>
    </Box>
  );
};

export default AIRAVitalsForm;
