import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  IconButton,
  Input,
  VStack,
  Text,
  HStack,
  Heading,
  Image,
  Spinner,
  Icon,
  Textarea,
  SkeletonText,
} from "@chakra-ui/react";
import { CloseIcon, MinusIcon } from "@chakra-ui/icons";
import { useWebSocket } from "./connector";
import { useTranscriber } from "./audio/useTranscriber";
import { ChatAudioManager } from "./audio/chatVoiceManager";
import { BiMicrophoneOff } from "react-icons/bi";
import { IoMdSend } from "react-icons/io";
import { useGPUTranscriber } from "./audio/useGPUTranscriber";
import { GPUChatAudioManager } from "./audio/gpuChatVoiceManager";
import ReactMarkdown from "react-markdown";
import { AiOutlineClear } from "react-icons/ai";
import AIFeedbackFooter from "../patient-health/doctor-notes/ai-feedback";

const GPUChatbot = ({
  closeSocketCommand,
  resetCloseSocketCommand,
  patientId,
  doctorId,
  appointmentId,
  patientname,
  patientgender,
  patientage,
}) => {
  const IS_WEBGPU_AVAILABLE = !!navigator.gpu;

  // Call both hooks unconditionally
  const gpuTranscriber = useGPUTranscriber();
  const transcriber = useTranscriber();

  // Choose which transcriber to use based on the condition
  // const activeTranscriber = IS_WEBGPU_AVAILABLE ? gpuTranscriber : transcriber;
  const activeTranscriber = gpuTranscriber;

  //-------------------------audio

  const [voiceInput, setVoiceInput] = useState("");

  const [placeholder, setPlaceholder] = useState("Ask Aira anything...");
  const [inputHeight, setInputHeight] = useState(40)
  const headerHeightRef = useRef();
  const bottomInputRef = useRef();

  // useEffect(() => {
  //   if(inputValue == ''){
  //     setInputHeight(36)
  //   } else { 
  //     setInputHeight(headerHeightRef?.current.height + bottomInputRef?.current.height)
  //   }
  // },[bottomInputRef, inputHeight])

  useEffect(() => {
    // console.log("triggered");
    if (activeTranscriber?.output?.text) {
      setInputValue(activeTranscriber.output.text);
      // if (textareaRef.current){
      // textareaRef.current.style.height = "auto"; // Reset the height to auto to calculate new height
      // textareaRef.current.style.height = `${Math.min(
      //   textareaRef.current.scrollHeight || 36,
      //   150
      // )}px`; // Adjust height based on content
      // }
      setPlaceholder("Ask Aira anything...");
      setTyping(false); // it was set true in chatvoicemanager
    }
    // if (!activeTranscriber?.output) {
    //   console.log(activeTranscriber?.output);
    // }
  }, [activeTranscriber?.output]);

  //-------------------------chatbot

  const [isOpen, setIsOpen] = useState(false);
  const [isSocketInitialized, setIsSocketInitialized] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [aiInputForFeedback, setAiInputForFeedback] = useState(false);
  const [typing, setTyping] = useState(false); // Typing indicator state

  const {
    messages,
    llmCalls,
    sendMessage,
    closeConnection,
    initializeWebSocket,
  } = useWebSocket(
    `https://vm-midocai.eastus.cloudapp.azure.com:8080/chatbot/ws/doctor-${doctorId}`,
    // `ws://127.0.0.1:8000/chatbot/ws/doctor-${doctorId}`,
    () => {
      setIsInputDisabled(false);
    },
    patientId,
    doctorId,
    appointmentId,
    patientname,
    patientgender,
    patientage,
    setAiInputForFeedback
  );

  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (closeSocketCommand) {
      console.log("visit interrupted");
      handleClose();
      resetCloseSocketCommand(); // Reset the command after handling it
    }
  }, [closeSocketCommand]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const handleOpen = async () => {
    if (!isSocketInitialized) {
      setTyping(false);
      await initializeWebSocket(); // Ensure WebSocket is initialized before proceeding
      setIsSocketInitialized(true);
      setInputValue("");
      if (activeTranscriber?.output) {
        activeTranscriber.output = {
          ...activeTranscriber.output,
          text: "",
          isBusy: false,
        };
      }
    }
    setIsOpen(true);

    // Reset textarea and container heights after reopening
    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.style.height = "40px"; 
      }
      if (chatContainerRef.current) {
        chatContainerRef.current.style.height = "calc(88vh - 40px - 8.25rem)";
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;

      }
    }, 0);
  };

  // const handleSend = () => {
  //   if (inputValue.trim() !== "") {
  //     const question = { question: inputValue };
  //     setMessages((prevMessages) => [...prevMessages, question]); // Show the question immediately
  //     setInputValue("");
  //     setTyping(true); // Show typing indicator
  //     sendMessage(question);
  //   }
  // };

  useEffect(() => {
    if (messages.length > 0 && messages[messages.length - 1].answer) {
      setTyping(false); // Disable typing indicator when an answer is added
    }
  }, [messages]);

  const handleSend = (overrideInputValue) => {
    // console.log("sending....");

    // Use overrideInputValue if provided, otherwise use inputValue
    const valueToSend = overrideInputValue || inputValue;

    if (valueToSend.trim() !== "") {
      const question = { question: valueToSend };
      setTyping(true); // Show typing indicator
      sendMessage(question);
      setInputValue(""); // Clear input only if inputValue is used
    } else {
      console.log("empty");
    }
  };

  const handleClose = () => {
    closeConnection();
    setIsOpen(false);
    setIsSocketInitialized(false);
  };

  const handleMinimize = () => {
    setIsOpen(false);
    setInputValue("");
    if (activeTranscriber?.output) {
      activeTranscriber.output = {
        ...activeTranscriber.output,
        text: "",
        isBusy: false,
      };
    }
  };

  const chatContainerRef = useRef(null); 
  const textareaRef = useRef(null); // Create a ref for the Textarea

  const instructionsData = {
    "Perform differential diagnosis":
      "List potential differential diagnoses based on the patient's symptoms.",
    "List risk factors":
      "Assess potential risk factors considering the patient's age & demographics (if abnormal), pre-existing conditions, past medical history, and lifestyle factors. Exclude any non-relevant factors. Response should not contain anything which does not pose any risk to patient's health, based on the patient's symptoms.",
    "Assess organ system health probabilities":
      "Analyze the likelihood of significant health conditions impacting vital organ systems, including the heart, kidneys, liver, and lungs, based on the patient's clinical records, laboratory results, and physician notes. Prioritize organs with abnormalities followed by any healthy organ systems. Response should have two categories, Abnormalities and Healthy organs, based on the patient's symptoms.",
    "Predict high-probability conditions":
      "Generate a list of the most probable medical conditions the patient may have, given their symptoms, medical history, and lifestyle factors.",
    "Visit vital analysis":
      "Analyze available vitals from doctor notes, highlighting any abnormalities and providing relevant comments. Indicate any connections between the abnormalities and the patient's current symptoms or past medical history.",
    "Cross-specialty consultation insights":
      "Offer insights and key points from multiple specialties that may apply to the patient's condition, providing a well-rounded assessment.",
    "Predict long-term outcomes":
      "Predict the patient's long-term health outcomes based on current clinical data, including projected life expectancy, disease progression, and quality of life metrics. Focus on relevant abnormalities in health conditions and organ function.",
    "Suggest medications":
      "Recommend appropriate medications for the patient's condition, based on the symptoms.",
    "Recommend orders":
      "Suggest necessary medications, lab tests, procedures, and imaging studies for the patient, based on the symptoms.",
    "Health overview":
      "Provide a comprehensive overview of the patient's health status.",
    "Investigation analysis":
      "Summarize findings from the patient's recent investigations.",
    "Current medications": "Outline the patient's current medication regimen.",
    "Lifestyle recommendations":
      "Advise on lifestyle modifications to improve health.",
    "Patient education":
      "Provide educational materials relevant to the patient's condition.",
    "Symptom management":
      "Suggest strategies for managing the patient's symptoms.",
    "Follow-up recommendations":
      "Outline necessary follow-up steps for the patient's care.",
    "Referral recommendations":
      "Identify specialists or services for patient referral.",
    // Add more options as needed
  };

  let suggestions = Object.keys(instructionsData);

  const HorizontalScrollHStack = () => {
    const hstackRef = useRef(null);
    const [isHovered, setIsHovered] = useState(false);
    // const [suggestions, setSuggestions] = useState(
    //   Object.keys(instructionsData)
    // ); // Use keys from instructionsData

    useEffect(() => {
      const handleWheelScroll = (event) => {
        if (isHovered && event.deltaY !== 0) {
          event.preventDefault();
          hstackRef.current.scrollLeft += event.deltaY;
        }
      };

      const handleMouseEnter = () => setIsHovered(true);
      const handleMouseLeave = () => setIsHovered(false);

      const hstackElement = hstackRef.current;
      hstackElement.addEventListener("mouseenter", handleMouseEnter);
      hstackElement.addEventListener("mouseleave", handleMouseLeave);
      hstackElement.addEventListener("wheel", handleWheelScroll);

      return () => {
        hstackElement.removeEventListener("mouseenter", handleMouseEnter);
        hstackElement.removeEventListener("mouseleave", handleMouseLeave);
        hstackElement.removeEventListener("wheel", handleWheelScroll);
      };
    }, [isHovered]);

    const handleSuggestionClick = (suggestion) => {
      // issue: asynchronous state update of suggestions
      // setSuggestions((prevSuggestions) => {
      //   const updatedSuggestions = prevSuggestions.filter(
      //     (item) => item !== suggestion
      //   );
      //   return updatedSuggestions;
      // });
      const updatedSuggestions = suggestions.filter(
        (item) => item !== suggestion
      );
      suggestions = updatedSuggestions;
      const instructions = instructionsData[suggestion] || ""; // Fetch instructions

      handleSend(instructions);

      // comment above line and uncomment below 2 lines to have option of editing suggestion before sending
      // setInputValue(instructions);
      // handleSend()
    };

    return (
      <HStack
        ref={hstackRef}
        spacing={2}
        overflowX="scroll"
        overflowY={"hidden"}
        height="3rem"
        width="100%"
        p='2rem'
        pl='1rem'
        // sx={{scrollbarWidth:'5px', scrollbarHeight:'5px'}}
        borderTop='1px solid #d1d1d1'
        bg="#f4f4f4"
        whiteSpace="nowrap"
      >
        {/* Quick Search */}
        {/* <Image src="/assets/svgs/search.svg" h={"24px"} w={"24px"} /> */}
        {suggestions.map((suggestion, index) => (
          <Button
            key={suggestion}
            bg='white' // Slight transparency for a glossy effect
            borderRadius="full" // Fully rounded buttons
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.2)" // Slight shadow for depth
            _hover={{
              bg: 'white', // Darker when hovered
            }}
            _active={{
              bg: 'white', // Slightly dim when clicked
            }}
            color="#000" // Button text color
            flexShrink={0}
            fontSize='14px'
            fontWeight='500'
            p='0.25rem 0.75rem'
            onClick={() => handleSuggestionClick(suggestion)} // Call the click handler
          >
            {suggestion}
          </Button>
        ))}
      </HStack>
    );
  };

  useEffect(() => {
    if (textareaRef.current) {
      // console.log("A")
      if (inputValue === "") {
        // console.log("C")
        textareaRef.current.style.height = "40px"; // Reset height when input is empty
        chatContainerRef.current.style.height = "calc(88vh - 40px - 8.25rem)";
        // textareaRef.current.scrollTop = 0;
      } else {
        // console.log("D")
        textareaRef.current.style.height = "auto"; // Reset the height to auto to calculate new height
        textareaRef.current.style.height = `${Math.min(
          textareaRef.current.scrollHeight || 40,
          65
        )}px`; // Adjust height based on content
        chatContainerRef.current.style.height = `calc(88vh - ${Math.min(
          textareaRef.current?.scrollHeight || textareaRef.current?.height,
          65
        )}px - 8.25rem)`
      }
    }
    // else {
    //   console.log("B")
    // }
  }, [inputValue]);

  const handleClearInputClick = () => {
    setInputValue("");
    if (textareaRef.current) {
      // console.log("textareaRef.current", textareaRef.current);
      textareaRef.current.style.height = "40px"; // Reset textarea height
      chatContainerRef.current.style.height = "calc(88vh - 40px - 8.25rem)";
    }
  };

  return (
    <Box
      position={isOpen ? "fixed" : "relative"} // When open, position fixed to overlap parent
      bottom={isOpen ? "0" : "auto"} // Adjust top value as needed
      right={isOpen ? "0" : "auto"} // Adjust right value as needed
      width={isOpen ? "calc(80vw - 6rem)" : "auto"}
      // height={isOpen ? "calc(90vh - 9rem)" : "auto"}
      height={isOpen ? "88vh" : "auto"}
      bg="#f4f4f4"
      color="white"
      borderRadius="md"
      zIndex={isOpen ? "1000" : "auto"} // Bring it to the front when open
      overflow="hidden"
      border='1px solid #e4e4e4'
    >
      {isOpen && (
        <>
          <HStack
            justifyContent="space-between"
            bg="#151619"
            p='18px 12px 18px 18px'
            // pb='0'
            borderBottom='1px solid #e6e7e9'
            height="3rem"
            alignItems='center'
            // ref={headerHeightRef}
          >
            <Text color='#fff' mt='-1px' fontWeight='600'>Chat with Aira</Text>
            <HStack>
              <IconButton
                icon={<MinusIcon />}
                size="sm"
                // bg="#17181b"
                color={"#fff"}
                onClick={handleMinimize}
                bg='none'
                _hover={{ bg: "none" }}
              />
              <IconButton
                icon={<CloseIcon />}
                size="sm"
                // bg="#17181b"
                color={"#fff"}
                _hover={{ bg: "none" }}
                onClick={handleClose}
                bg='none'
              />
            </HStack>
          </HStack>
          <VStack
            spacing={3}
            p={3}
            pb={"3rem"}
            flex="1"
            overflowY="auto"
            // height={`calc(80vh - ${inputHeight}px)`}
            height={`calc(88vh - ${Math.min(
              textareaRef.current?.scrollHeight || textareaRef.current?.height,
              65
            )}px - 8.25rem)`}
            // height="auto"
            border='1px solid #e6e7e9'
            ref={chatContainerRef}
          >
            <Box
              alignSelf="flex-start"
              // bg="gray.600"
              bg="white"
              color="black"
              p={2}
              borderRadius="md"
              maxW="80%"
              mt={1}
              ml={"6px"}
              boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
            >
              <Box
                p={4}
                borderRadius="md"
                // bg="gray.100"
                // boxShadow="md"
              >
                <Heading as="h2" size="lg" mb={2}>
                  Welcome to AIRA Chat!
                </Heading>
                <Text fontWeight="bold" fontSize="lg" mb={2}>
                  Your AI-Powered Medical Assistant
                </Text>
                <Text fontSize="md" mb={4}>
                  Effortlessly access clinical insights, tailored to {aiInputForFeedback?.patient_data?.patient_info?.name}'s needs, with AIRA’s text and voice-enabled interface.
                  Ask any medical question and receive well-organized,
                  context-rich responses designed to streamline your workflow.
                </Text>
                <Text fontSize="sm" color="gray.600" fontStyle="italic">
                  <strong>Disclaimer:</strong> While AIRA strives for precision,
                  occasional inaccuracies may occur. Please exercise
                  professional judgment when interpreting responses.
                </Text>
              </Box>
            </Box>
            {messages.map((msg, index) => (
              <React.Fragment key={index}>
                {msg.question && (
                  <Box
                    alignSelf="flex-end"
                    bg="gray.800"
                    color="white"
                    p={2}
                    borderRadius="10px 10px 0 10px"
                    maxW="80%"
                  >
                    <Text>{msg.question}</Text>
                  </Box>
                )}
                {msg.answer && (
                  <Box
                    alignSelf="flex-start"
                    // bg="gray.600"
                    bg="white"
                    color="black"
                    p={2}
                    borderRadius="10px 10px 10px 0"
                    boxShadow="rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px"
                    maxW="80%"
                    mt={1}
                    ml={"6px"}
                  >
                    {/* <ReactMarkdown>{msg.answer}</ReactMarkdown> */}
                    <Box ml="16px">
                      <ReactMarkdown
                        components={{
                          h1: ({ node, ...props }) => (
                            <h1
                              style={{
                                fontSize: "32px",
                                fontWeight: "bold",
                                margin: "24px 0",
                              }}
                              {...props}
                            />
                          ),
                          h2: ({ node, ...props }) => (
                            <h2
                              style={{
                                fontSize: "24px",
                                fontWeight: "bold",
                                margin: "20px 0",
                              }}
                              {...props}
                            />
                          ),
                          h3: ({ node, ...props }) => (
                            <h3
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                margin: "16px 0",
                              }}
                              {...props}
                            />
                          ),
                          table: ({ node, ...props }) => (
                            <table
                              style={{
                                borderCollapse: "collapse",
                                width: "100%",
                                margin: "16px 0",
                              }}
                              {...props}
                            />
                          ),
                          th: ({ node, ...props }) => (
                            <th
                              style={{
                                border: "1px solid black",
                                padding: "8px",
                                fontWeight: "bold",
                                background: "#f0f0f0",
                              }}
                              {...props}
                            />
                          ),
                          td: ({ node, ...props }) => (
                            <td
                              style={{
                                border: "1px solid black",
                                padding: "8px",
                              }}
                              {...props}
                            />
                          ),
                          tr: ({ node, ...props }) => (
                            <tr style={{ textAlign: "left" }} {...props} />
                          ),
                        }}
                      >
                        {msg.answer}
                      </ReactMarkdown>
                    </Box>
                    <AIFeedbackFooter
                      disclaimer={
                        "AIRA strives to be as accurate as possible, but occasional errors may occur. Please verify its responses to ensure the information meets your needs. Your discretion will ensure the best experience."
                      }
                      doctorId={doctorId}
                      patientId={patientId}
                      appointmentId={appointmentId}
                      aiInput={aiInputForFeedback}
                      aiOutput={{
                        history: messages,
                        selectedresponse: msg.answer,
                      }}
                      featureType={"AIRA Chatbot"}
                    />
                  </Box>
                )}
              </React.Fragment>
            ))}
            {typing && (
              // <Box alignSelf="flex-start" mt={1} maxW="80%" ml="16px">
              //   <Spinner size="md" color="#17181b" />{" "}
              //   {/* Simple typing animation */}
              // </Box>
              <Box display='flex' ml='6px' borderRadius='10px 10px 10px 0' alignSelf='flex-start' w='100%' maxW='80%' flexDir='column' padding='6' bg='white' boxShadow="rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px">
                <SkeletonText noOfLines={3} spacing='4' skeletonHeight='2' />
              </Box>
            )}
            <div ref={messagesEndRef} />
          </VStack>

          {/* Quick search suggestions  */}
          <HorizontalScrollHStack />

          <HStack
            p={2}
            // position="absolute"
            bottom="0"
            width="100%"
            borderTop='1px solid #d7d7d7'
            // bg="gray.800"
            // sx={{alignItems: {inputValue == "" || voiceInput == "" ? "center" : "end"}}}
            sx={{alignItems: inputValue == '' || voiceInput == '' ? "center" : "end"}}
            // alignItems="center"
            // alignItems={inputValue == "" || voiceInput == "" ? "center" : "end"}
            // ref={bottomInputRef}
          >
            <GPUChatAudioManager
              transcriber={activeTranscriber}
              setPlaceholder={setPlaceholder}
              setTyping={setTyping}
            />
            {/* <Icon
              as={AiOutlineClear}
              onClick={handleClearInputClick}
              h={"36px"}
              w={"36px"}
              bg={"#1a202c"}
              // color={"green"}
              cursor="pointer"
              // border={"2px solid"}
              // borderColor={"blue"}
              // borderRadius={"full"}
            /> */}
            {inputValue == "" || voiceInput == "" && (
            <Box display='flex' justifyContent='center' alignItems='center'>
              <Image 
                src='/assets/svgs/clear-msg.svg'
                onClick={handleClearInputClick}
                cursor='pointer'
                w="40px"
              />
            </Box>
            )}
            <Textarea
              ref={textareaRef}
              value={inputValue} // Use inputValue or fallback to voiceInput
              defaultValue={voiceInput}
              // onChange={(e) => setInputValue(e.target.value)}
              onChange={(e) => {
                e.target.style.height = "auto"; // Reset the height
                e.target.style.height = `${Math.min(
                  e.target.scrollHeight,
                  65
                )}px`; // Adjust height dynamically
                setInputValue(e.target.value);
              }}
              onKeyPress={(e) => e.key === "Enter" && handleSend()}
              isDisabled={typing}
              placeholder={placeholder} // dynamic placeholder, changes from chataudiomanager
              bg="white"
              color="black"
              resize="none"
              overflowY="auto"
              lineHeight='normal'
              maxH="65px"
              minHeight="40px"
              w="100%"
              border="1px solid #d7d7d7"
              _active='1px solid #d7d7d7'
              borderRadius='10px'
              focusBorderColor="transparent"
              _focusVisible='1px solid #d7d7d7'
            />
            {/* <Icon
              as={IoMdSend}
              onClick={() => {
                if (textareaRef.current) {
                  textareaRef.current.style.height = "40px";
                }
                handleSend();
              }}
              isDisabled={typing}
              h={"36px"}
              w={"36px"}
              cursor="pointer"
            /> */}
           <Box p='10px'>
            <Image
              src="/assets/svgs/send-button.svg"
              onClick={() => {
                if (textareaRef.current) {
                  textareaRef.current.style.height = "40px";
                }
                handleSend();
              }}
              isDisabled={typing}
              h={"20px"}
              w={"20px"}
              transform='rotate(90deg)'
              // mx='8px'
              cursor="pointer"
              filter="invert(9%) sepia(34%) saturate(1%) hue-rotate(171deg) brightness(12%) contrast(101%)"
            />
            </Box>

          </HStack>
        </>
      )}
      {!isOpen && (
        <div>
          <Box
            as="button"
            onClick={handleOpen}
            zIndex="5"
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap="5px"
            w="100px"
            h="20px"
            left={{ lg: "80%", xl: "80%" }}
            backgroundImage="linear-gradient(to right top, #0d7e54, #007774, #006d94, #005ea7, #1146a0)"
          >
            <Image w="16px" src="/assets/imgs/white-spark.png" />
            <Box display="flex" flexDir="column">
              <Text
                textAlign="left"
                fontSize="10px"
                fontWeight="700"
                color="white"
              >
                Aira - Chat
              </Text>
            </Box>
          </Box>
        </div>
      )}
    </Box>
  );
};

export default GPUChatbot;
