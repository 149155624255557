export default function formAPIchiefcomplaint({
    chiefcomplaint,
    date,
    appointmentid,
    doctorname,
    specialization,
    appointmentdate,
    bookingid
})
{
  return Object.freeze({
    chiefcomplaint,
    date,
    appointmentid,
    doctorname,
    specialization,
    appointmentdate,
    bookingid
  });
}