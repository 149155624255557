import formAPIpatienttime from "./patienttime";


var formattedOutput = null;

const formAPIModelpatienttime = (data, type, activeTab,selecteddate) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIpatienttime(item, type, activeTab,selecteddate));
  const transformedData = [];

  formattedOutput.forEach(item => {
    const patientObj = {
      label: item.label,
      value: item.patientcount,
      type: "Patients"
  };
  
  // Object for medicine count
  const timeObj = {
      label: item.label,
      value: item.spenttime,
      type: "Minutes"
  };

  transformedData.push(timeObj, patientObj);
  });
  return transformedData;
};

export default formAPIModelpatienttime