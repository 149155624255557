import React from "react";
import { Box, Text } from "@chakra-ui/react";

const NoDataScreen = ({ width, height }) => {
  return (
    <Box
      bg="#fcfcfd"
      width={width}
      height={height}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Text textAlign="center" position="absolute" top="50%" left="40%">
        No Data Available
      </Text>
    </Box>
  );
};

export default NoDataScreen;
