import React, { useEffect, useState } from "react";
import {
  Flex,
  VStack,
  Box,
  Image,
  Center,
  Text,
  Input,
  Progress,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  Divider,
  Radio,
  RadioGroup,
} from "@chakra-ui/react";
import { PrimaryButton, PrimaryInput, Toaster } from "../../components";
import { getDoctorPlans, signUpProfessionalDetails, updateSubscriptionPlanforsignup } from "../../middleware/services/signup";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  CreatePaymentIntent,
  SavePaymentInitialCall,
} from "../../middleware/services/payment/payment";
import CheckoutForm from "./checkout-form";
import { CONFIG } from "../../appconfig/appconfig";

const SubscriptionPlan = () => {
  const [doctorid, setDoctorId] = useState("")
  const [selectedPlan, setSelectedPlan] = useState("1")
  const [documents, setDocuments] = useState([])
  const [planid, setPlanId] = useState("1")
  const [clientSecret, setClientSecret] = useState(null);
  const [stripePromise, setStripePromise] = useState(null);
  const [showFullBasic, setShowFullBasic] = useState(true)
  const [showFullPremium, setShowFullPremium] = useState(false)
  const [showFullPro, setShowFullPro] = useState(false)
  const [plansdata, setPlansData] = useState("");
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const { showSuccessToast, showErrorToast } = Toaster();

  const [data, setData] = useState([])
  const handleClick = () => {
    document.getElementById("uploadDegree").click();
  };
  useEffect(() => {
    let doctorid = localStorage.getItem("doctorid")
    setDoctorId(doctorid)
    getDoctorPlan(doctorid);
  }, [])

  const getDoctorPlan = async (id) => {
    try {
    var result = await getDoctorPlans({
      doctorid:id,
    });
    if(result){
      setData(result)
    }else{
      setData("")
    }
    } catch (error) {
      console.log(error , "Something went wrong in subscriptions update")
    }
  }

  const handleRadioChange = (planid) => {
    setPlanId(planid)
    setSelectedPlan(planid)
    if (planid == 1) {
      setShowFullBasic(true)
      setShowFullPremium(false)
      setShowFullPro(false)

    } else if (planid == 2) {
      setShowFullBasic(false)
      setShowFullPremium(true)
      setShowFullPro(false)

    } else if (planid == 3) {
      setShowFullBasic(false)
      setShowFullPremium(false)
      setShowFullPro(true)
    }
    const filteredPlans = data.filter(item => item.subscriptionplanid == planid);
    setPlansData(filteredPlans);
  }
  var inputForSignUpProfessionalDetails = {
    doctorid,
    "type": "document",
    documents
  }
  var inputForSuscription = {
    planid,
    doctorid
  }
  var navigate = useNavigate();

  useEffect(() => {
    const getDocuments = JSON.parse(localStorage.getItem('documents'));
    setDocuments(getDocuments)
  }, [])

  const SubmitFunction = async () => {    
      showLoader();
        if(planid === "1"){
          try {
            const response = await signUpProfessionalDetails(inputForSignUpProfessionalDetails)
        
          const result = await updateSubscriptionPlanforsignup(inputForSuscription);
          //console.log(result , " result of professiontl details")
  
          if (response.output.result == "Successfully Inserted" && result.output.result == "success") {
            //showSuccessToast("Successfully Updated")
            // setTimeout(() => {
  
            //   const newActiveComponent = "documents";
            //   onSubmit(newActiveComponent);
            // }, 1000);
            //localStorage.removeItem("personalDetails")
            localStorage.removeItem("professionalDetails")
            
            if(result?.output?.id){
              localStorage.setItem("loginid", result.output.id)
            }
            navigate('/finalsteps')
          
          }else {
            console.error(result, "Something went wrong in Submit.")
            showErrorToast("Something went wrong!")
          }
        } catch (error) {
          console.error('Error in SubmitFunction:', error);
        }
        }else{
          const amount = parseFloat(plansdata && plansdata[0].subscriptionprice) * 100;
          try {
              const response = await signUpProfessionalDetails(inputForSignUpProfessionalDetails)

              const result = await CreatePaymentIntent({
                amount: amount,
              });
              //console.log(result,"Initial resp");
              result.amount = plansdata[0].subscriptionprice;
              result.paymenttypeid = "1";
              const resp = await SavePaymentInitialCall(result, doctorid);
              //console.log(resp,"Initial db resp");
              //setClientSecret("pi_3PAucsIpkkS0IXSH0Y2lFxG8_secret_2YLi2vpWMmcIfVkjUM4i52GU6");
              setClientSecret(result.client_secret);
              setStripePromise(loadStripe(CONFIG.StripePublicKey));
            }
            catch (error) {
              console.error("Error in Payment:", error);
            }
        }
      dismissLoader();      
      //to comment below line
      //navigate('/requestsubmit')
      
      //localStorage.removeItem("personalDetails")
      localStorage.removeItem("professionalDetails")
    
  };
  return (
    <>
      <Flex pt={{md: "5rem",lg: "10rem", xl: "5rem"}} display={{base: "none", lg: "flex"}}>
        
      {isLoading && <Loader />}
        <Box w="100%" p={4}>
          <Center>
            <VStack spacing={0} w="450px" transform={{ md: 'scale(0.85)', lg: 'scale(0.85)' }}>
              <Box
                as="p"
                fontSize="22px"
                textAlign="center"
                fontWeight="600"
                mb={5}
              >
                Select your <br /> subscription plan
              </Box>

              {/* uploaded section start */}
              <Flex flexDir="column" gap="15px" w="100%">
                <RadioGroup onChange={handleRadioChange} value={selectedPlan}>
                  {data && data[0] &&
                  <Box as="label" cursor="pointer" htmlFor={`radio-${data[0].subscriptionname}`}> 
                    <Card
                      border={showFullBasic ? "2px solid #0C7650" : ""}
                      borderRadius="12px"
                      mb="20px"
                      boxShadow="0px 8px 24px 0px #B4B4B452"
                    >
                      <CardHeader borderRadius="12px">
                        <Flex flexDir="column" justifyContent="end" h="100%">
                          <Flex
                            justifyContent="space-between"
                            w="100%"
                            alignItems="center"
                          >
                            <Text fontSize="24px" fontWeight="600">
                              {data[0].subscriptionname}
                            </Text>

                            <Radio colorScheme='green' size='lg'
                              value="1" id={`radio-${data[0].subscriptionname}`}></Radio>
                          </Flex>
                          <Text color="#384052" fontSize="20px" fontWeight="500">
                            {data[0].subscriptionpriceunit == "USD" ? "$" : ""} {data[0].subscriptionprice}/{data[0].duration == "Monthly" ? "month" : ""}
                            <Box as="span" pl="5px" fontSize="8px" fontWeight="500">
                              *Billed anually
                            </Box>
                          </Text>
                        </Flex>
                        <Divider mt="15px" />
                      </CardHeader>
                      {showFullBasic &&
                        <CardBody py="0" pb="15px">
                          <Flex flexDir="column" gap="10px">
                            {data && data[0] && data[0].subscriptiondescription.map(item => (
                              <Box key={item.name} display="flex" gap="10px" alignItems="center">
                                <Image
                                  w="12px"
                                  h="auto"
                                  src="/assets/imgs/check-black.png"
                                />
                                <Text fontSize="14px" fontWeight="700">
                                  {item.name}
                                </Text>
                              </Box>
                            ))}
                          </Flex>
                        </CardBody>
                      }
                    </Card>
                    </Box>
                  }

                  {data && data[1] &&
                  <Box as="label" cursor="pointer" htmlFor={`radio-${data[1].subscriptionname}`}>
                    <Card
                      border={showFullPremium ? "2px solid #0C7650" : ""}
                      className="premium-plan"
                      mb="20px"
                      boxShadow="0px 8px 24px 0px #B4B4B452"
                    >
                      <CardHeader
                        backgroundImage="linear-gradient(180deg, #72DCB6 -675%, rgba(114, 220, 182, 0) 100%)"
                        borderRadius="12px"
                      >
                        <Flex flexDir="column" justifyContent="end" h="100%">
                          <Flex
                            justifyContent="space-between"
                            w="100%"
                            alignItems="center"
                          >
                            <Text fontSize="24px" fontWeight="600">
                              {data[1].subscriptionname}
                            </Text>

                            <Radio colorScheme='green' size='lg'
                              value="2" id={`radio-${data[1].subscriptionname}`}></Radio>
                          </Flex>
                          <Text color="#384052" fontSize="20px" fontWeight="500">
                            {data[1].subscriptionpriceunit == "USD" ? "$" : ""} {data[1].subscriptionprice}/{data[1].duration == "Monthly" ? "month" : ""}
                            <Box as="span" pl="5px" fontSize="8px" fontWeight="500">
                              *Billed anually
                            </Box>
                          </Text>
                        </Flex>
                      </CardHeader>
                      {showFullPremium &&
                        <CardBody py="0" pb="15px">
                          <Flex flexDir="column" gap="10px">
                            {data && data[1] && data[1].subscriptiondescription.map(item => (
                              <Box key={item.name} display="flex" gap="10px" alignItems="center">
                                <Image
                                  w="12px"
                                  h="auto"
                                  src="/assets/imgs/check-black.png"
                                />
                                <Text fontSize="14px" fontWeight="700">
                                  {item.name}
                                </Text>
                              </Box>
                            ))}
                          </Flex>
                        </CardBody>
                      }
                    </Card>
                    </Box>
                  }


                  {data && data[2] &&
                  <Box as="label" cursor="pointer" htmlFor={`radio-${data[2].subscriptionname}`}>
                    <Card
                      border={showFullPro ? "2px solid #0C7650" : ""}
                      className={!showFullPro ? "border-pro-plan" : ""}
                      mb="20px"
                      boxShadow="0px 8px 24px 0px #B4B4B452"
                    >
                      <CardHeader
                        backgroundImage="linear-gradient(180deg, #72DCB6 -675%, rgba(114, 220, 182, 0) 100%)"
                        borderRadius="12px"
                      >
                        <Flex flexDir="column" justifyContent="end" h="100%">
                          <Flex
                            justifyContent="space-between"
                            w="100%"
                            alignItems="center"
                          >
                            <Text fontSize="24px" fontWeight="600">
                              {data[2].subscriptionname}
                            </Text>

                            <Radio colorScheme='green' size='lg'
                              value="3" id={`radio-${data[2].subscriptionname}`}></Radio>
                          </Flex>
                          <Text color="#384052" fontSize="20px" fontWeight="500">
                            {data[2].subscriptionpriceunit == "USD" ? "$" : ""} {data[2].subscriptionprice}/{data[2].duration == "Monthly" ? "month" : ""}
                            <Box as="span" pl="5px" fontSize="8px" fontWeight="500">
                              *Billed anually
                            </Box>
                          </Text>
                        </Flex>
                      </CardHeader>
                      {showFullPro &&
                        <CardBody py="0" pb="15px">
                          <Flex flexDir="column" gap="10px">
                            {data && data[2] && data[2].subscriptiondescription.map(item => (
                              <Box key={item.name} display="flex" gap="10px" alignItems="center">
                                <Image
                                  w="12px"
                                  h="auto"
                                  src="/assets/imgs/check-black.png"
                                />
                                <Text fontSize="14px" fontWeight="700">
                                  {item.name}
                                </Text>
                              </Box>
                            ))}
                          </Flex>
                        </CardBody>
                      }
                    </Card>
                    </Box>
                  }
                </RadioGroup>
              </Flex>
              {/* uploaded section end */}
              <Box
                display="flex"
                gap="8px"
                my={5}
                flexDirection="row"
                alignItems="center"
              >
                <Box as="span">
                  <Image w="18px" src="/assets/imgs/info-safe.png" />
                </Box>
                <Box as="p" lineHeight="normal" fontSize="12px">
                  Your information is safe with us.
                </Box>
              </Box>
              
              {clientSecret && stripePromise && (
                <Elements stripe={stripePromise} options={{ clientSecret }}>
                  <CheckoutForm planid={planid} clientSecret={clientSecret} />
                </Elements>
              )}
            </VStack>
            <Box pos='fixed' bottom='0' w='35%' p='1rem'>
              <PrimaryButton buttonText="Continue" id="submitButton" onClick={SubmitFunction} />
            </Box>
          </Center>
        </Box>
      </Flex>

      {/* Mobile responsive start */}
      <Flex flexDir="column" display={{base: "flex", lg: "none"}}>
        <Box w="100%" bg="black" h="6px" borderRadius="full" my="1rem">
          <Box as="span" w="100%" bg='#14C585' h="100%" borderRadius="full" display="flex"></Box>
        </Box>
        <Box w="100%" mb={5}>
          <Box as="p" fontSize="1.25rem" lineHeight="normal" letterSpacing="1px" mt="0.75rem" textAlign="center" fontWeight="600" mb={2}>
            Select your <br /> subscription plan
          </Box>
          
          {/* uploaded section start */}
          <Flex flexDir="column" gap="15px" w="100%">
            <RadioGroup onChange={handleRadioChange} value={selectedPlan}>
              {data && data[0] &&
              <Box as="label" cursor="pointer" htmlFor={`radio-${data[0].subscriptionname}`}> 
                <Card
                  border={showFullBasic ? "2px solid #0C7650" : ""}
                  borderRadius="12px"
                  mb="20px"
                  boxShadow="0px 8px 24px 0px #B4B4B452"
                >
                  <CardHeader borderRadius="12px">
                    <Flex flexDir="column" justifyContent="end" h="100%">
                      <Flex
                        justifyContent="space-between"
                        w="100%"
                        alignItems="center"
                      >
                        <Text fontSize="24px" fontWeight="600">
                          {data[0].subscriptionname}
                        </Text>

                        <Radio colorScheme='green' size='lg'
                          value="1" id={`radio-${data[0].subscriptionname}`}></Radio>
                      </Flex>
                      <Text color="#384052" fontSize="20px" fontWeight="500">
                        {data[0].subscriptionpriceunit == "USD" ? "$" : ""} {data[0].subscriptionprice}/{data[0].duration == "Monthly" ? "month" : ""}
                        <Box as="span" pl="5px" fontSize="8px" fontWeight="500">
                          *Billed anually
                        </Box>
                      </Text>
                    </Flex>
                    <Divider mt="15px" />
                  </CardHeader>
                  {showFullBasic &&
                    <CardBody py="0" pb="15px">
                      <Flex flexDir="column" gap="10px">
                        {data && data[0] && data[0].subscriptiondescription.map(item => (
                          <Box key={item.name} display="flex" gap="10px" alignItems="center">
                            <Image
                              w="12px"
                              h="auto"
                              src="/assets/imgs/check-black.png"
                            />
                            <Text fontSize="14px" fontWeight="700">
                              {item.name}
                            </Text>
                          </Box>
                        ))}
                      </Flex>
                    </CardBody>
                  }
                </Card>
                </Box>
              }

              {data && data[1] &&
              <Box as="label" cursor="pointer" htmlFor={`radio-${data[1].subscriptionname}`}>
                <Card
                  border={showFullPremium ? "2px solid #0C7650" : ""}
                  className="premium-plan"
                  mb="20px"
                  boxShadow="0px 8px 24px 0px #B4B4B452"
                >
                  <CardHeader
                    backgroundImage="linear-gradient(180deg, #72DCB6 -675%, rgba(114, 220, 182, 0) 100%)"
                    borderRadius="12px"
                  >
                    <Flex flexDir="column" justifyContent="end" h="100%">
                      <Flex
                        justifyContent="space-between"
                        w="100%"
                        alignItems="center"
                      >
                        <Text fontSize="24px" fontWeight="600">
                          {data[1].subscriptionname}
                        </Text>

                        <Radio colorScheme='green' size='lg'
                          value="2" id={`radio-${data[1].subscriptionname}`}></Radio>
                      </Flex>
                      <Text color="#384052" fontSize="20px" fontWeight="500">
                        {data[1].subscriptionpriceunit == "USD" ? "$" : ""} {data[1].subscriptionprice}/{data[1].duration == "Monthly" ? "month" : ""}
                        <Box as="span" pl="5px" fontSize="8px" fontWeight="500">
                          *Billed anually
                        </Box>
                      </Text>
                    </Flex>
                  </CardHeader>
                  {showFullPremium &&
                    <CardBody py="0" pb="15px">
                      <Flex flexDir="column" gap="10px">
                        {data && data[1] && data[1].subscriptiondescription.map(item => (
                          <Box key={item.name} display="flex" gap="10px" alignItems="center">
                            <Image
                              w="12px"
                              h="auto"
                              src="/assets/imgs/check-black.png"
                            />
                            <Text fontSize="14px" fontWeight="700">
                              {item.name}
                            </Text>
                          </Box>
                        ))}
                      </Flex>
                    </CardBody>
                  }
                </Card>
                </Box>
              }


              {data && data[2] &&
              <Box as="label" cursor="pointer" htmlFor={`radio-${data[2].subscriptionname}`}>
                <Card
                  border={showFullPro ? "2px solid #0C7650" : ""}
                  className={!showFullPro ? "border-pro-plan" : ""}
                  mb="20px"
                  boxShadow="0px 8px 24px 0px #B4B4B452"
                >
                  <CardHeader
                    backgroundImage="linear-gradient(180deg, #72DCB6 -675%, rgba(114, 220, 182, 0) 100%)"
                    borderRadius="12px"
                  >
                    <Flex flexDir="column" justifyContent="end" h="100%">
                      <Flex
                        justifyContent="space-between"
                        w="100%"
                        alignItems="center"
                      >
                        <Text fontSize="24px" fontWeight="600">
                          {data[2].subscriptionname}
                        </Text>

                        <Radio colorScheme='green' size='lg'
                          value="3" id={`radio-${data[2].subscriptionname}`}></Radio>
                      </Flex>
                      <Text color="#384052" fontSize="20px" fontWeight="500">
                        {data[2].subscriptionpriceunit == "USD" ? "$" : ""} {data[2].subscriptionprice}/{data[2].duration == "Monthly" ? "month" : ""}
                        <Box as="span" pl="5px" fontSize="8px" fontWeight="500">
                          *Billed anually
                        </Box>
                      </Text>
                    </Flex>
                  </CardHeader>
                  {showFullPro &&
                    <CardBody py="0" pb="15px">
                      <Flex flexDir="column" gap="10px">
                        {data && data[2] && data[2].subscriptiondescription.map(item => (
                          <Box key={item.name} display="flex" gap="10px" alignItems="center">
                            <Image
                              w="12px"
                              h="auto"
                              src="/assets/imgs/check-black.png"
                            />
                            <Text fontSize="14px" fontWeight="700">
                              {item.name}
                            </Text>
                          </Box>
                        ))}
                      </Flex>
                    </CardBody>
                  }
                </Card>
                </Box>
              }
            </RadioGroup>
          </Flex>
          {/* uploaded section end */}
          <Box
            display="flex"
            gap="8px"
            my={5}
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <Box as="span">
              <Image w="18px" src="/assets/imgs/info-safe.png" />
            </Box>
            <Box as="p" lineHeight="normal" fontSize="12px">
              Your information is safe with us.
            </Box>
          </Box>
          
          {clientSecret && stripePromise && (
            <Elements key={clientSecret} stripe={stripePromise} options={{ clientSecret }}>
              <CheckoutForm planid={planid} clientSecret={clientSecret} />
            </Elements>
          )}
        </Box>
        <Box w="100%">
          <PrimaryButton buttonText="Continue" id="submitButton" onClick={SubmitFunction} />
        </Box>
      </Flex>
    </>
    
  );
};

export default SubscriptionPlan;
