import React, { useState } from 'react'
import useLoader from '../../components/loader/loaderstates';
import MiniSideBar from '../../components/mini-sidebar';
import SecondaryNav from '../../components/seconday-nav';
import { Box, Flex, Image, Progress, Stack, Step, StepIcon, StepIndicator, Stepper, StepStatus, Text, useSteps } from '@chakra-ui/react';
import Loader from '../../components/loader/loader';
import { css, Global } from '@emotion/react';
import PatientScreening from './patientscreening';
import ChildScreening from './childscreening';
import MentalHealthScreen from './mentalhealthscreen';
import WomenHealthScreen from './womenhealthscreen';
import { useLocation, useNavigate } from "react-router-dom";
import { COLORS } from '../../components/styles/colors';
import moment from 'moment';

const AdvanceDetails = () => {
    const { isLoading, showLoader, dismissLoader } = useLoader();
    const location = useLocation();
    const { input, patientid, appointmentid } = location.state || {};
    const getInitialTab = (input) => {
      if (input?.age > 18) {
        return "patient-screening";
      } else if (input?.age <= 18) {
        return "child-screening";
      } else if (input?.gender === 1) {
        return "women-screening";
      } else {
        return "mental-screening";
      }
    };
    
    const [activeTab, setActiveTab] = useState(() => getInitialTab(input));
    const handleActiveComponentChange = (newActiveComponent) => {
      let nextTab = "";
    
      // Determine the next valid tab based on the current active tab and requirements
      if (newActiveComponent === "patient-screening") {
        if (input?.age <= 18) {
          nextTab = "child-screening"; // Age below 18
        } else if (input?.gender === 1) {
          nextTab = "women-screening"; // Gender is female
        } else {
          nextTab = "mental-screening"; // Default to mental screening
        }
      } 
      // else if (newActiveComponent === "child-screening") {
      //   if (input?.age > 18) {
      //     nextTab = "patient-screening"; // Age above 18
      //   } else if (input?.gender === 1) {
      //     nextTab = "women-screening"; // Gender is female
      //   } else {
      //     nextTab = "mental-screening"; // Default to mental screening
      //   }
      // } 
      else if (newActiveComponent === "women-screening") {
        // if (input?.age <= 18) {
        //   nextTab = "child-screening"; // Age below 18
        // } else if (input?.age > 18) {
        //   nextTab = "patient-screening"; // Age above 18
        // }
         if (input?.age > 18) {
          nextTab = "mental-screening"; // Default to mental screening
        }
      } 
      // else if (newActiveComponent === "mental-screening") {
      //   if (input?.age <= 18) {
      //     nextTab = "child-screening"; // Age below 18
      //   } else if (input?.age > 18) {
      //     nextTab = "patient-screening"; // Age above 18
      //   } else if (input?.gender === 1) {
      //     nextTab = "women-screening"; // Gender is female
      //   }
      //}
    
      // Validate if the next tab meets the requirements
      if (nextTab) {
        setActiveTab(nextTab);
        console.log(`Switched to tab: ${nextTab}`);
      } else {
        console.log("No valid tab to switch to based on the conditions.");
      }
    };

    const formatDate = (dateString) => {
      if (!dateString) return "-";
      return moment(dateString).format("Do MMM, YYYY");
    };

  return (
    <>
    <Global
        styles={css`
          .activeTab .whiteBorder::after {
            content: '';
            position: absolute;
            left: 0%;
            transform: translateX(-20%);
            transform: translateY(440%);
            border-radius: 5px 5px 0 0;
            top: 120%;
            background: #0B1F4F;
            height: 3px;
            width: 100%;
            z-index: 1;
          }
        `}
      />
    {isLoading && <Loader />}
      <Box w="100%">
        <Flex>
          <Box w="6rem">
            <MiniSideBar />
          </Box>
          {/* {isLoading && <Loader />} */}
          <Box w="100%">
            <SecondaryNav customHeader="Advanced Details" />
                <Box>
                    <Flex>
                        <Flex bg="#fcfcfd" flexDirection="column" w="100%">
                          
                            {/* Tab buttons start */}
                            <Box display='flex' gap="1rem" borderBottom="1px solid #E6E7E9" bg="white" p="1.25rem 1.5rem">
                              {Number(input?.age) > 18 && (
                            <Box as="button" display="flex" justifyContent="center" alignItems="center" pos="relative" className={activeTab == "patient-screening" ? "activeTab" : ""} fontSize="0.87rem" fontWeight="600" onClick={() => setActiveTab('patient-screening')} color={activeTab == "patient-screening" ? "#061027" : "#898D97"}><Text>Adult Patient Screening</Text><Box as="span" className="whiteBorder"/></Box>
                              )} 
                              {Number(input?.age) <= 18 && (
                            <Box as="button" display="flex" justifyContent="center" alignItems="center" pos="relative" className={activeTab == "child-screening" ? "activeTab" : ""} fontSize="0.87rem" fontWeight="600" onClick={() => setActiveTab('child-screening')} color={activeTab == "child-screening" ? "#061027" : "#898D97"}><Text>Child Screening</Text><Box as="span" className="whiteBorder"/></Box>
                              )} 
                               {Number(input?.gender) === 1 &&( 
                            <Box as="button" display="flex" justifyContent="center" alignItems="center" pos="relative" className={activeTab == "women-screening" ? "activeTab" : ""} fontSize="0.87rem" fontWeight="600" onClick={() => setActiveTab('women-screening')} color={activeTab == "women-screening" ? "#061027" : "#898D97"}><Text>Women’s Health Screening</Text><Box as="span" className="whiteBorder"/></Box>
                              )}
                              {Number(input?.age) > 18 && (
                            <Box as="button" display="flex" justifyContent="center" alignItems="center" pos="relative" className={activeTab == "mental-screening" ? "activeTab" : ""} fontSize="0.87rem" fontWeight="600" onClick={() => setActiveTab('mental-screening')} color={activeTab == "mental-screening" ? "#061027" : "#898D97"}><Text>Mental Health Screening</Text><Box as="span" className="whiteBorder"/></Box>
                              )}
                            </Box>
                            {/* Tabs button end */}
                            
                            <Box w="100%" p="1.5rem" h="calc(100vh - 215px)" overflow="hidden auto">
                                <Box w="100%" display='flex' gap='1rem' h="auto" bg="white" borderRadius="10px" border='1px solid #E6E7E9' p="1.5rem">
                                    <Box w='80%'>
                                    {activeTab == "patient-screening" && <PatientScreening patientid={patientid} appointmentid={appointmentid} patientData={input} onSubmit={handleActiveComponentChange}/>}
                                    {activeTab == "child-screening" && <ChildScreening patientid={patientid} appointmentid={appointmentid} patientData={input}/>}
                                    {activeTab == "mental-screening" && <MentalHealthScreen patientid={patientid} appointmentid={appointmentid} patientData={input}/>}
                                    {activeTab == "women-screening" && <WomenHealthScreen patientid={patientid} appointmentid={appointmentid} patientData={input} onSubmit={handleActiveComponentChange}/>}
                                
                                </Box>
                                <Box w='20%' borderLeft='1px solid #E6E7E9'>
                                      <Stack
                                        pl={{lg: "1rem", xl: "1.5rem"}}
                                        p='0'
                                        direction="column"
                                        gap="0.75rem"
                                        w="100%"
                                        pos='sticky'
                                        top='0'
                                      >
                                        <Text fontSize='1rem' fontWeight='600'>Patient details</Text>
                                        <Box display="flex" alignItems="center">
                                          <Image src="/assets/svgs/name.svg" />
                                          <Text
                                            fontSize="0.875rem"
                                            fontWeight="500"
                                            ml="0.5rem"
                                          >
                                           {input?.fullname? input?.fullname: "-"}
                                          </Text>
                                        </Box>

                                        <Box display="flex" alignItems="center">
                                          <Image src="/assets/svgs/email.svg" />
                                          <Text
                                            fontSize="0.875rem"
                                            fontWeight="500"
                                            ml="0.5rem"
                                          >
                                            {input.email? input.email:"-"}
                                          </Text>
                                        </Box>

                                        <Box display="flex" alignItems="center">
                                          <Image src="/assets/svgs/phone-number.svg" />
                                          <Text
                                            fontSize="0.875rem"
                                            fontWeight="500"
                                            ml="0.5rem"
                                          >
                                             {input?.mobileno?input?.countrycode+" "+input?.mobileno:"-"}
                                          </Text>
                                        </Box>

                                        {input.gender != null && (
                                        <Box display="flex" alignItems="center">
                                          <Image src="/assets/svgs/gender.svg" />
                                          <Text
                                            fontSize="0.875rem"
                                            fontWeight="500"
                                            ml="0.5rem"
                                          >
                                            {input.gender === 1? "Female": input.gender === 2? "Male":"Others"}
                                          </Text>
                                        </Box>
                                        )}

                                        {input.dob != null && (
                                        <Box display="flex" alignItems="center">
                                          <Image src="/assets/svgs/cake.svg" />
                                          <Text
                                            fontSize="0.875rem"
                                            fontWeight="500"
                                            ml="0.5rem"
                                          >
                                            {input.dob?formatDate(input.dob):""} {input.age? " ("+input.age+" years)":"-"}
                                          </Text>
                                        </Box>
                                        )}
                                        
                                        {input.address != null && (
                                        <Box display="flex" alignItems="center">
                                        <Image filter={COLORS.FILTER_BLACK} boxSize='18px' src="/assets/svgs/location-blue.svg" />
                                          <Text
                                            fontSize="0.875rem"
                                            fontWeight="500"
                                            ml="0.5rem"
                                            lineHeight='normal'
                                          >
                                            {input.address?input.address:"-"}
                                          </Text>
                                        </Box>
                                        )}

                                        {input.emergencycontactphone != null && (
                                        <Box display="flex" alignItems="center">
                                        <Image boxSize='1rem' filter={COLORS.FILTER_BLACK} src="/assets/svgs/emergency-contact.svg" />
                                          <Text
                                            fontSize="0.875rem"
                                            fontWeight="500"
                                            ml="0.5rem"
                                          >
                                            {input.emergencycontactphone? input?.emergencycontactcountrycode +" "+input.emergencycontactphone:"-"}
                                          </Text>
                                        </Box>
                                        )}

                                      </Stack>
                                    </Box>
                            </Box>
                            </Box>
                        </Flex>
                    </Flex>
            </Box>
          </Box>
        </Flex>
      </Box>
    </>
  )
}

export default AdvanceDetails