import formAPIbloodpressure from "./bloodpressure";

const formAPIModelbloodpressure = (data, type) => {
  // Apply formAPIModel to each item in the data array
  const formattedOutput = data.map(item => formAPIbloodpressure(item, type));
  const transformedData = [];

  formattedOutput.forEach(item => {
    const existingDayIndex = transformedData.findIndex(obj => obj.x === item.vitaladdedday);
    
    if (existingDayIndex !== -1) {
      
      // If the day already exists in transformedData, update its y array
      transformedData[existingDayIndex].value.push(parseInt(item.systolicbloodpressure), parseInt(item.diastolicbloodpressure));
      transformedData[existingDayIndex].color = item.color;
    } else {
      // If the day does not exist in transformedData, create a new object
      const newObj = {
        value: [parseInt(item.systolicbloodpressure), parseInt(item.diastolicbloodpressure)], // Converting to integers
        x: item.vitaladdedday, // Extracting the first three characters for day abbreviation
        color: item.color,
        tooltipLabel: item.tooltipLabel ? item.tooltipLabel : '',
      };
      
      transformedData.push(newObj);
    }
  });
  return transformedData;
};

export default formAPIModelbloodpressure;
