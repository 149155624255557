import  FetchData, { } from '../../client';
import formAPIModalLogin from '../../domains/login';
const FetchLogin = async (inputData) => {
  try {

    const result = await FetchData("midoc_doctorsignin", inputData);
    console.log(result,"login result DB ");
    if(result.output.result == "success"){
      let finalOutput = formAPIModalLogin(result.output.data);
      let finalOutput2 = {
        "data": finalOutput,
        "result":result.output.result,
        "token":result.output.token,
        "message":result.output.message,
      }
      return finalOutput2;
    }else{
      return result;
    }

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const FetchMobileLogin = async (inputData) => {
  try {

    const result = await FetchData("midoc_doctormobilesignin", inputData);
    if(result.output.result == "success"){
      let finalOutput = formAPIModalLogin(result.output.data);
      let finalOutput2 = {
        "data": finalOutput,
        "result":result.output.result,
        "token":result.output.token,
        "message":result.output.message,
      }
      return finalOutput2;
    }else{
      return result;
    }

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};


//Sign Up 
const signUp = async (inputData) => {
  try {

    const result = await FetchData("midoc_doctorsignup", inputData);
    //console.log(result,"signUp");
    
    //var finalOutput = appointmentsOutput(result)

    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};



//OTP Generate 
const generateOtp = async (inputData) => {
  try {
    inputData.loginid = localStorage.getItem("loginid");
    const result = await FetchData("midoc_generateotp", inputData);
    //console.log(result,"Generate OTP");
    

    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};

//Validated OTP

const ValidatedOtp = async (inputData) => {
  try {
    inputData.loginid = localStorage.getItem("loginid");
    const result = await FetchData("midoc_validateotp", inputData);
    //console.log(result,"ValidatedOtp");
    

    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
 

//Forgot Password 
const passwordupdate = async (inputData) => {
  try {
    inputData.loginid = localStorage.getItem("loginid");
    const result = await FetchData("midoc_doctorforgotpassword", inputData);
    //console.log(result,"forgot Password");
    
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};


export {FetchLogin,FetchMobileLogin, signUp, passwordupdate, generateOtp, ValidatedOtp};
