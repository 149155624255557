import React from "react";
import { Button, Image, Box, Text } from '@chakra-ui/react';
import { styles, submitStyle, iconStyle, iconReset } from './style';


const PrimaryButtonIcon = ({ buttonText,iconSrc,onClick,disabled,sx,iconImg,btnClass,btnStyle, ...props }) => {
//   const buttonState = isLoading ? `${buttonText}...` : buttonText;
const iconVal = buttonText ? iconStyle : iconReset

  return (
    <Button isDisabled={disabled} className={btnClass} onClick={onClick} sx={{ ...styles, ...sx, ...btnStyle }} {...btnStyle}>
      <Box className={iconImg} as="span" sx={iconVal}>
        <Image src={iconSrc} />
      </Box>
      <Text>{buttonText}</Text>
    </Button>
  );
};

export default PrimaryButtonIcon;