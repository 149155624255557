import React from 'react';
import { Box, Flex, Text, VStack } from '@chakra-ui/react';

const ChatDisplay = ({ chatMessages }) => {
  return (
    <Box w="full" maxW="800px" mx="auto" p={4} bg="gray.50" borderRadius="lg" shadow="md" h="40vh" overflowY="auto">
      <VStack spacing={4} align="stretch">
        {chatMessages.map((message, index) => {
          const isPatient = message.patient !== undefined;
          const messageText = isPatient ? message.patient : message.doctor;

          return (
            <Flex
              key={index}
              justify={isPatient ? 'flex-start' : 'flex-end'}
            >
              <Box
                bg={isPatient ? 'blue.100' : 'green.100'}
                color={isPatient ? 'blue.800' : 'green.800'}
                px={4}
                py={2}
                borderRadius="lg"
                maxW="70%"
                textAlign="left"
                mr={isPatient ? 'auto' :0 } // Ensure the blue box touches the right edge
                ml={isPatient ?  0:'auto' } // Ensure the green box stays on the left edge
              >
                <Text fontSize="sm">{messageText}</Text>
              </Box>
            </Flex>
          );
        })}
      </VStack>
    </Box>
  );
};

export default ChatDisplay;
