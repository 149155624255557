import {
  Box,
  Progress as ChakraProgress,
  useColorModeValue,
} from "@chakra-ui/react";

function formatBytes(size) {
  const i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return (
    +(size / Math.pow(1024, i)).toFixed(2) * 1 +
    ["B", "kB", "MB", "GB", "TB"][i]
  );
}

export default function Progress({
  text,
  percentage = 0,
  total,
  total_required = false,
}) {
  const bgColor = useColorModeValue("gray.100", "gray.700");
  const progressColor = "blue.400";

  return (
    <Box
      width="100%"
      bg={bgColor}
      textAlign="left"
      rounded="lg"
      overflow="hidden"
      mb={0.5}
    >
      <ChakraProgress value={percentage} colorScheme="blue" size="sm">
        <Box
          whiteSpace="nowrap"
          px={1}
          fontSize="sm"
          style={{ width: `${percentage}%` }}
        >
          {text} ({percentage.toFixed(2)}%
          {total_required && isNaN(total) ? "" : ` of ${formatBytes(total)}`})
        </Box>
      </ChakraProgress>
    </Box>
  );
}
