import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  Input,
  Text,
  VStack,
  Flex,
  HStack,
} from "@chakra-ui/react";
import {
  updateDoctorNotes,
  GetDoctorNotes,
} from "../../../middleware/services/visits/patient-overview/updatedoctornotes";
import PrimaryButton from "../../primary-button";
import { COLORS } from "../../styles/colors";
import useLoader from "../../loader/loaderstates";
import Loader from "../../loader/loader";
import { ChatAudioManager } from "./cheif-complaint-audio/chatVoiceManager";
import { useTranscriber } from "./cheif-complaint-audio/useTranscriber";
import { actionAccessMain } from "../../../auth";


const DoctorComplaintInputCPUCard = ({
  patientId,
  doctorId,
  appointmentId,
  showSuccessToast,
  showErrorToast,
  fetchDoctorNotesEmptyIndication,
}) => {
  //------------------speech to text
  const activeTranscriber = useTranscriber();

  const [typing, setTyping] = useState(false); // Typing indicator state

  const [placeholder, setPlaceholder] = useState(
    "Click anywhere to start typing...."
  );

  useEffect(() => {
    // console.log("triggered");
    if (activeTranscriber?.output?.text) {
      setValue("textField", activeTranscriber.output.text);

      setPlaceholder("Click anywhere to start typing....");
      setTyping(false);
    }
  }, [activeTranscriber?.output]);

  //------------------other functionality
  const { register, handleSubmit, watch, setValue } = useForm();
  const textFieldValue = watch("textField", "");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [initialText, setInitialText] = useState("");

  const { isLoading, showLoader, dismissLoader } = useLoader();

  const fetchDoctorNotes = async () => {
    const inputToFetch = {
      input: {
        patientid: patientId,
        doctorid: doctorId,
        appointmentid: appointmentId,
      },
    };

    showLoader();
    try {
      const data = await GetDoctorNotes(inputToFetch.input);
      const chiefComplaint =
        data?.output?.data?.chiefcomplaint?.chiefcomplaint || "";
      // console.log(chiefComplaint);
      setInitialText(chiefComplaint);
      setValue("textField", chiefComplaint);
    } catch (error) {
      console.error("Error fetching Doctor Notes data:", error);
    } finally {
      dismissLoader();
    }
  };

  const UpdateChiefComplaint = async (data) => {
    const inputToSave = {
      input: {
        doctorid: doctorId,
        patientid: patientId,
        appointmentid: appointmentId,
        chiefcomplaint: data.textField,
      },
    };

    showLoader();
    try {
      const result = await updateDoctorNotes(inputToSave.input);
      if (result) {
        showSuccessToast("Chief Complaint Saved successfully");
      }
    } catch (error) {
      console.error("Error in Update Doctor Notes:", error);
      showErrorToast("Error Happened While Saving.");
    } finally {
      dismissLoader();
    }
  };

  const onSubmit = (data) => {
    setIsButtonDisabled(true); // Disable button on submit
    UpdateChiefComplaint(data);
    fetchDoctorNotesEmptyIndication();
  };

  useEffect(() => {
    fetchDoctorNotes();
  }, []);

  useEffect(() => {
    if (textFieldValue !== initialText) {
      setIsButtonDisabled(false); // Enable button if text field value changes
    } else {
      setIsButtonDisabled(true); // Disable button if text field value is unchanged
    }
  }, [textFieldValue, initialText]);

  return (
    <Flex
      direction="column"
      height="calc(100vh - 4rem)"
      align="center"
      justify="center"
      bg="white"
    >
      {isLoading && <Loader miniLoader={true} />}
      <Box
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        padding={{ base: "0", md: "4" }}
        width={{ base: "100%", md: "98%" }}
        height="95%"
        position={{ base: "unset", md: "absolute" }}
        bg="white"
        pt={"2px"}
      >
        <VStack spacing="4" height="85%">
          <Box flex="1" width="100%" position="relative" bg={"#f9fafb"}>
            <Input
              {...register("textField")}
              height="100%"
              padding="2"
              resize="none"
              as="textarea"
              disabled={typing} // Disable when AI typing is true
              style={{ overflowWrap: "break-word", whiteSpace: "pre-wrap" }}
            />
            <Text
              position="absolute"
              bottom="2"
              left="50%"
              transform="translateX(-50%)"
              fontSize="sm"
              color="gray.500"
              w={{ base: "100%", md: "unset" }}
              textAlign={{ base: "center", md: "unset" }}
            >
              {placeholder}
            </Text>
          </Box>
          <HStack
            p={2}
            // position="absolute"
            bottom="0"
            width="100%"
            alignItems="flex-end"
          >
            <ChatAudioManager
              transcriber={activeTranscriber}
              setPlaceholder={setPlaceholder}
              setTyping={setTyping}
            />
            <PrimaryButton
              type="submit"
              buttonText="Submit"
              variant="grayBtn"
              isDisabled={isButtonDisabled || !textFieldValue|| !actionAccessMain(16)?.create}
              btnStyle={{
                h: "2.5rem",
                w: "90%",
                p: "0",
                mr: "12px",
                ml: "12px",
                position: "sticky",
                bottom: "0",
                zIndex: "1",
                mx: "auto",
                bg: COLORS.PRIMARY_GRADIENT,
                color: COLORS.PRIMARY_WHITE,
                _hover: { bg: COLORS.PRIMARY_COLOR },
              }}
            />
          </HStack>
        </VStack>
      </Box>
    </Flex>
  );
};

export default DoctorComplaintInputCPUCard;
