import moment from "moment";
import formAPIModelRoleDescriptions from "./roledetails";
export default function formAPIroledetails({
    masterscreenid,
    name,
    description,
    create,
    update,
    get,
    delete: deleteRole, // Renamed delete to deleteOperation
    getdetail,
    createddate,
    id,
    roleid,
    roledetails
   
    
   

})
{
  return Object.freeze({
    masterscreenid,
    name,
    description,
    create,
    update,
    read: get,
    delete : deleteRole,
    getdetail,
    createddate,
    id,
    roleid,
    roledetails : roledetails ? formAPIModelRoleDescriptions(roledetails) : [],
  });
}