export default function formAPIcurrentmedications({
    //medicineid,
    medicinename,
    medicinetype,
    prescribedby,
    prescribedhospitalname,
    medicationenddate,
    medicationstartdate,
    dosageunit,
    dosage,
    night,
    morning,
    evening,
    frequency,
    documents,
    chemicalcomposition,
    
   

})
{
 
  return Object.freeze({
    //medicineid,
    medicinename,
    medicinetype,
    prescribedby,
    prescribedhospitalname,
    medicationenddate,
    medicationstartdate,
    dosageunit,
    dosage,
    night,
    morning,
    evening,
    frequency,
    documents,
    chemicalcomposition
   
  });
}