import {
    Box,
    Flex,
    Tr,
    Td,
    Image,
    Text,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Divider,
    Card,
    CardHeader,
    Spacer,
    Switch,
    CardBody,
    Stack,
    FormLabel,
    SimpleGrid,
    Checkbox,
  } from "@chakra-ui/react";
  import React, { useEffect, useRef, useState,  } from "react";
  import MiniSideBar from "../../components/mini-sidebar";
  import SecondaryNav from "../../components/seconday-nav";
  import SimpleTable from "../../components/simple-table";
  import Loader from "../../components/loader/loader";
  import useLoader from "../../components/loader/loaderstates";
  import PrimaryButton from "../../components/primary-button";
  import ModalRight from "../../components/modal-right";
  import SearchBar from "../../components/search-bar";
  import PrimaryInput from "../../components/primary-input";
  import DoctorCard from "../../components/doctor-card";
  import DynamicModal from "../../components/dynamic-modal";
  
  import { PasswordInput, Toaster } from "../../components";
  import { createDoctorRole, deleteDoctorRole, getDoctorRole, getDoctorRoleDetail, updateDoctorRole } from "../../middleware/services/roles";
  import { actionAccessMain, formatDate_DD_MM_YYYY, formatDate_Do_MMM_YYYY, formatDate_YYYY_MM_DD, getCroppedImg, handleAlphaDotAndSpace, useScreenType } from "../../auth";
import { createDoctorUser, deleteDoctorUser, getDoctorUser, getDoctorUserDetail, updateDoctorUser } from "../../middleware/services/users";
import { DropdownInput, PrimaryInputDropdown } from "../../components";
import DatePicker from "../../components/date-picker";
import { format } from "date-fns";
import ImageCropper from "../profile/ImageCropper";
import { updatePatientProfileOnChange, uploadImageInProfile } from "../../middleware/services/profile";
import { COLORS } from "../../components/styles/colors";
import MobileNavbar from "../../components/mobile-navbar";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import { useNavigate } from "react-router-dom";
  
  const UsersIndex = () => {
    const { isLoading, showLoader, dismissLoader } = useLoader();
    const { showSuccessToast, showErrorToast } = Toaster();
    const addNewUser = useRef();
    const editNewUser = useRef();
    const deleteUser = useRef();
    const inputRef = useRef();
  
    const editPhoto = useRef();
    
  const [showCropper, setShowCropper] = useState(false);
  
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    const TableHeader = [
      { key: "patient", label: "Patient" },
      { key: "email", label: "Email" },
      { key: "mobile", label: "Mobile No" },
      { key: "appointmentDate", label: "Appointment Date" },
      { key: "actions", label: "Actions" },
    ];
  
  
  
    const [refresh, setRefresh] = useState(false)
  
    const [data, setData] = useState([]);
    const [roleList, setRoleList] = useState([]);
    const [roleDetailData, setRoleDetailData] = useState("");
  
  
     // add states
     const [loginid, setloginid] = useState(null);
     const [mobileno, setmobileno] = useState(null);
     const [email, setEmail] = useState(null);
     const [countryid, setcountryid] = useState(null);
     const [countrycode, setcountrycode] = useState(null);
     const [firstname, setfirstname] = useState(null);
     const [lastname, setlastname] = useState(null);
     const [fullname, setfullname] = useState(null);
     const [gender, setGender] = useState(null);
     const [age, setAge] = useState(null);
     const [dob, setDob] = useState(null);
     const [password, setPassword] = useState(null);
     const [doctorphoto, setdoctorphoto] = useState(null);
     const [idtoken, setidtoken] = useState(null);
     const [type, setType] = useState(null);
     const [id, setId] = useState(null);
     const [isdoctor, setisdoctor] = useState(0);
     const [issuperuser, setissuperuser] = useState(null);
     const [roleid, setroleid] = useState(null);
     const [userTypeId, setUserTypeId] = useState(null);
 
     // additional states edit
     const [regDate, setRegDate] = useState(null);
     const [profilePictureUrl, setProfilePictureUrl] = useState(null);
     const [ratings, setRatings] = useState(null);
     const [isUserDoctor, setIsUserDoctor] = useState(null);
     const [isFirstTimeLogin, setIsFirstTimeLogin] = useState(null);
     const [roleName, setRoleName] = useState(null);
     const [imagePath, setImagePath] = useState("https://midocfileserver.blob.core.windows.net/midocdev/");
  
    const [homePage, setHomePage] = useState("")
    const [visitsPage, setVisitsPage] = useState("")
  
    const [searchValue, setSearchValue] = useState("")
    const [search, setSearch] = useState(false)
  
    const [filteredData, setFilteredData] = useState([]);
  
    const [showFilteration, setshowFilteration] = useState(false);
    
    const [editId, setEditId] = useState("")
    
    const [deleteId, setDeleteId] = useState("")

    
  const [defaultGender, setDefaultGender] = useState(null)
  const [genderId, setGenderId] = useState(null)
  const screenType = useScreenType()
  const navigate = useNavigate();
  
  const [actionAccess, setActionAccess] = useState(null);
  const [actionAccessRoles, setActionAccessRoles] = useState(null);
  
    //   getDoctorRole   initial call  ------------- bind in main page ----
    useEffect(() => {
      setActionAccess(actionAccessMain(10))
      setActionAccessRoles(actionAccessMain(9))
      showLoader();
      var input = {};
      var getDoctorUserFunction = async () => {
          try {
              var result = await getDoctorUser(input)
              if (result) {
                  setData(result)
                  const filtered = result.filter(item =>
                      item.fullname?.toLowerCase().includes(searchValue.toLowerCase())
                  );
                  
                  setFilteredData(filtered);
              } else if (result && result.output && result.output.message == "No roleid are available for this doctorid") {
                  setData("")
                  //  showErrorToast("Something went wrong!")
              } else {
                  setData("")
                  //  showErrorToast("Something went wrong!")
              }

          } catch (error) {
              console.log(error, "error in fetching account data")
          }
          dismissLoader();
      }

      getDoctorUserFunction();
      
  getDoctorRoleFunction();

  }, [refresh]);
  const [ roleIdFromUsersPage , setRoleIdFromUsersPage] = useState(null)
  useEffect(()=>{
    let navigatedFromRoles = localStorage.getItem("addUserFromRoles")
    let roleIdFromRolesPage = localStorage.getItem("selectedRoleId")
    if(navigatedFromRoles){
      openAddModal();
      setRoleIdFromUsersPage(roleIdFromRolesPage);
      setroleid(roleIdFromRolesPage)
      setTimeout(() => {
        localStorage.removeItem("addUserFromRoles");
         localStorage.removeItem("selectedRoleId");
      }, 1000);
    }
  },[])

  var getDoctorRoleFunction = async () => {
    
    var input = {};
    try {
      var result = await getDoctorRole(input)
      if (result) {
        let list = result.map((item) => ({"role" : item.rolename  , "id":item.id}))
        // console.log(result , "result?.output?.data" )
       setRoleList(result)
        //setroleidList(result)
        // const filtered = data.filter(item =>
        //   item.rolename?.toLowerCase().includes(searchValue.toLowerCase())
        // );
        // setFilteredData(filtered);
      } else if (result && result.output && result.output.message == "No roleid are available for this doctorid") {
        setRoleList("")
        //  showErrorToast("Something went wrong!")
      } else {
        setRoleList("")
        //  showErrorToast("Something went wrong!")
      }

    } catch (error) {
      console.log(error, "error in fetching account data")
    }
    dismissLoader();
  }

  
  useEffect(() => {
    if (searchValue === "") {
        setFilteredData(data);
    } else {
        if(data.length > 0){
          const filtered = data.filter(item =>
            item.fullname?.toLowerCase().includes(searchValue.toLowerCase())
        );
        setFilteredData(filtered);
        }else{
          setFilteredData([])
        }
    }
}, [search, data, searchValue]);

useEffect(() => {
    if (searchValue === "") {
        setFilteredData(data);
        setshowFilteration(false)
    }
}, [searchValue]);


const clearState = () => {
    setloginid(null);
    setmobileno(null);
    setEmail(null);
    setcountryid(null);
    setcountrycode(null);
    setfirstname(null);
    setlastname(null);
    setfullname(null);
    setGender(null);
    setAge(null);
    setDob(null);
    setPassword(null);
    setdoctorphoto(null);
    setidtoken(null);
    setType(null);
    setId(null);
    setisdoctor(null);
    setissuperuser(null);
    setroleid(null);
    setGenderId(null);

    
    setRoleIdFromUsersPage(null)
}


//   createDoctorUser    ------------------------------------ in create page  ----

const openAddModal = () => {
    clearState();
      addNewUser.current.openRight()
}
const closeAddModal = () => {
    clearState();
      addNewUser.current.closeRight()
}

const switchFunctionAdd = (e, page, action) => {

    //   const isChecked = e.target.checked;

    //   setPages(prev =>
    //     prev.map(item =>
    //       item.screenname == page ? { ...item, [action]: isChecked ? 1 : 0 } : item
    //     )
    //   )
}



const createDoctorUserFunction = async () => {
    const inputForcreateDoctorUser = {
        "mobileno": mobileno ? mobileno : null ,
        email,
        countryid,
        countrycode,
        firstname,
        lastname,
        "fullname" : firstname + " " + lastname,
        "gender" : genderId,
        age,
        dob,
       // password,
        doctorphoto,
        idtoken,
        type,
        id,
        "isdoctor": isdoctor ? 1 : 0,
        issuperuser,
        roleid,
        "usertypeid":userTypeId
    };

    if(!firstname){
      showErrorToast("Please enter first name!")
      return false;
    }else if (!email){
      showErrorToast("Please enter email")
      return false;
    }else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      showErrorToast("Please Enter a Valid Email Address");
      return false;
    }else if (!gender){
      showErrorToast("Please select gender")
      return false;
    }else if (!dob){
      showErrorToast("Please select Date of Birth")
      return false;
    }else if (!mobileno){
      showErrorToast("Please enter Phone Number!")
      return false;
    }
    else if (mobileno && mobileno.length < 7){
      showErrorToast("Please Ente a valid Phone Number!")
      return false;
    }
    // else if (!password){
    //   showErrorToast("Please enter password")
    //   return false;
    // }
    else if (!roleid){
      showErrorToast("Please select a Role")
      return false;
    }else{
      try {
        
        var countryCode = localStorage.getItem("countrycode")
        var countryId = localStorage.getItem("countryid")
        
        inputForcreateDoctorUser.countrycode = countryId
        inputForcreateDoctorUser.countryid = countryCode
        showLoader();
        const result = await createDoctorUser(inputForcreateDoctorUser);
        if (result.output.result == "success") {
            setRefresh(!refresh)
            showSuccessToast("User Created Successfully!")
            addNewUser.current.closeRight()
        }else if (result?.output?.message == "Do not have the access to add users"){

          showErrorToast("You dont have the access to add users")
          addNewUser.current.closeRight();
          
         dismissLoader();
          return false;
        }else if (result?.output?.message == "doctor user already exists"){
          if(email && !mobileno){
            showErrorToast("Email already exists!")
          }else{
            showErrorToast("Email/Mobile already exists")
          }
         // addNewUser.current.closeRight();
          
         dismissLoader();
          return false;
        } else {
            showErrorToast("Something went wrong!")
           dismissLoader();
        }
    } catch (error) {
        console.log(error, "error createDoctorUserFunction")

    }
    }
 
    dismissLoader();
}


// ------------------
const openEditModal = (id) => {
    setEditId(id)
    getDoctorUserDetailFunction(id)
}

const closeEditModal = () => {
    clearState();
      editNewUser.current.closeRight()
}

const updateDoctorUserFunction = async () => {
    const inputForupdateDoctorUser = {
       // loginid,
        "mobileno": mobileno ? mobileno : null ,
        email,
        countryid,
        countrycode,
        firstname,
        lastname,
        fullname,
        "gender" : genderId,
        age,
        "dob" : dob ? formatDate_YYYY_MM_DD(dob) : null,
        //password,
        doctorphoto,
        idtoken,
        type,
        id,
        "isdoctor": isdoctor ? 1 : 0,
        issuperuser,
        roleid,
        "doctorid": editId,
        "usertypeid":userTypeId
    };
    if(!firstname){
      showErrorToast("Please enter first name!")
      return false;
    }else if (!email){
      showErrorToast("Please enter email")
      return false;
    }else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      showErrorToast("Please Enter a Valid Email Address");
      return false;
    }else if (!gender){
      showErrorToast("Please select gender")
      return false;
    }else if (!dob){
      showErrorToast("Please select Date of Birth")
      return false;
    }else if (!mobileno){
      showErrorToast("Please enter Phone Number!")
      return false;
    }else if (mobileno && mobileno.length < 7){
      showErrorToast("Please Ente a valid Phone Number!")
      return false;
    }else if (!roleid){
      showErrorToast("Please select a Role")
      return false;
    }
    try {
      
      var countryCode = localStorage.getItem("countrycode")
      var countryId = localStorage.getItem("countryid")
      
      inputForupdateDoctorUser.countrycode = countryId
      inputForupdateDoctorUser.countryid = countryCode
        showLoader();
        const result = await updateDoctorUser(inputForupdateDoctorUser);
        if (result.output) {
            setRefresh(!refresh)
            showSuccessToast("User Updated Successfully!")
            //here
          editNewUser.current.closeRight()
        } else {
            showErrorToast("Something went wrong!")
        }
    } catch (error) {
        console.log(error, "error updateDoctorUserFunction")

    }
    dismissLoader();
}

const openDeleteModal = (id) => {
    setDeleteId(id)
      deleteUser.current.openModal()
}
const deleteDoctorUserFunction = async () => {
    let inputFordeleteDoctorUser = {
        "id": deleteId
    };
    try {
        showLoader();
        const result = await deleteDoctorUser(inputFordeleteDoctorUser);
        console.log(result  , " result of delte user")
        if(result.output.message == "Unable to delete as the user have active appointments"){
          showErrorToast("Unable to delete user, as the user have active appointments");
          deleteUser.current.closeModal()
          dismissLoader();  
          return false;
        }
        if (result.output.result == "success") {
            setRefresh(!refresh)
          deleteUser.current.closeModal()
            showErrorToast("User Deleted Successfully!")
        } else {
             deleteUser.current.closeModal()
             dismissLoader();
            showErrorToast("Something went wrong!")
        }
    } catch (error) {
        console.log(error, "error deleteDoctorUserFunction")

    }
    dismissLoader();
}


var getDoctorUserDetailFunction = async (id) => {

    var inputForDoctorRoleDetail = {
        id
    };
    try {
        var result = await getDoctorUserDetail(inputForDoctorRoleDetail)
        if (result?.output?.message == "No users are available for this doctorid") {
            showErrorToast("No users are available for this doctorid")

        } else {
            // setRoleDetailData(result.data)
            // doctorRoleDetailSetStateFunction(result.data , result.roleData)
            result = result[0]
            if (result) {
                setloginid(result.loginid);
                setmobileno(result.mobileno);
                setEmail(result.email);
                setcountryid(result.countryid);
                setfirstname(result.firstname);
                setlastname(result.lastname);
                setfullname(result.fullname);
                setGenderId(result.gender)
                if (result.gender == 1) {
                  setGender("Female")
                } else if (result.gender == 2) {
                  setGender("Male")
                } else if (result.gender == 3) {
                  setGender("Others")
                }

                setDob(result.dob ? formatDate_DD_MM_YYYY(result.dob) : null);

                setissuperuser(result.issuperuser);
               // alert(result.roleid)
                setroleid(result.roleid);
                setisdoctor(result.isuserdoctor)
                setRegDate(result.regdate);
                setProfilePictureUrl(result.profilepictureurl);
                setdoctorphoto(result.profilepictureurl);
                setRatings(result.ratings);
                setIsUserDoctor(result.isuserdoctor);
                setIsFirstTimeLogin(result.isfirsttimelogin);
                setRoleName(result.rolename);
                setImagePath(result.imagepath);
            }


            editNewUser.current.openRight()
        }

    } catch (error) {
        console.log(error, "error getDoctorUserDetailFunction")
    }
    dismissLoader();
}


const clearFilters = () => {
    setSearchValue("")
    setSearch(!search)
}

const searchFunction = () => {
    setSearch(!search)
    setshowFilteration(true)
}

const handleMobileChange = (e) => {
  // var countrycode = localStorage.getItem("countryid")
  // if (countrycode == "+1") {
  //   setcountrycode("+1")
  //   setcountryid("229")
  // } else if (countrycode == "+93") {
  //   setcountrycode("+93")
  //   setcountryid("1")
  // } else if (countrycode == "+91") {
  //   setcountrycode("+91")
  //   setcountryid("95")
  // }
  var countryCode = localStorage.getItem("countrycode")
  var countryId = localStorage.getItem("countryid")
  
  setcountryid(countryCode)
  setcountrycode(countryId)

  setmobileno(e.target.value.replace(/[^0-9]/g, "").slice(0, 11));

}

const selectGender = (selectedOption) => {
  if (selectedOption == "Male") {
    setGender("Male")
    setGenderId("2")
  } else if (selectedOption == "Female") {
    setGender("Female")
    setGenderId("1")
  } else if (selectedOption == "Others") {
    setGender("Others")
    setGenderId("3")
  }
};

const selectRole = (selectedOption) => {
  const selectedRole = roleList.find(item => item.rolename === selectedOption);
 // console.log(roleList , " roleList")
  if (selectedRole) {
    setroleid(selectedRole.id);
  }
 
};
useEffect(()=>{
 // console.log(roleid, " roleid")
  if(roleid){
    const selectedRoleUserTypeId = roleList.find(item =>  item.id === roleid);
  //  console.log(selectedRoleUserTypeId , ' selectedRoleUserTypeId')
    if (selectedRoleUserTypeId) {
      setUserTypeId(selectedRoleUserTypeId.usertypeid);
    }
  }
 
},[roleid])
const handleDateChange = (date) => {
  const formattedDate = format(date, "yyyy-MM-dd");
  setDob(formattedDate);
};
const changeImage = () => {
  if (inputRef.current) {
    inputRef.current.click(); 
  }
};

const handleImageSelection = (e) => {
  e.preventDefault();
  const file = e.target.files[0];

  const allowedTypes = ["image/png", "image/jpeg", "image/jpg","image/webp"];
  if (!allowedTypes.includes(file.type)) {

    showErrorToast("Invalid file type. Please select a PNG, JPG or JPEG file.");
    return false;
  }

 
  

  if (file) {
    const reader = new FileReader();
    reader.onloadend = () => {
      setdoctorphoto(reader.result);
      setShowCropper(true);
      editPhoto.current.openModal(); // Open the modal when image is selected
    };
    reader.readAsDataURL(file);
  e.target.value = '';
  }
};

const handleCrop = async () => {
  showLoader()
  try {
    const croppedImage = await getCroppedImg(doctorphoto, croppedAreaPixels);
    if (!croppedImage) {
      console.error('Cropped image is null or undefined');
      return;
    }
    const croppedUrl = URL.createObjectURL(croppedImage);
    setdoctorphoto(croppedUrl);

    const result = await uploadImageInProfile(croppedImage, 'cropped.jpg');
    const srcInResult = result.name;
    setdoctorphoto(srcInResult);

    // const imageUpload = await updatePatientProfileOnChange({
    //   profilepictureurl: srcInResult,
    // });

    // if (imageUpload.output.success === 1) {
    //   window.location.reload();
    // }

    setShowCropper(false);
    editPhoto.current.closeModal(); // Close the modal after cropping
  } catch (error) {
    console.error('Error handling cropped image:', error);
  }
  dismissLoader()
};

const handleCropComplete = (croppedArea, croppedAreaPixels) => {
  setCroppedAreaPixels(croppedAreaPixels);
};
const isDoctorFunction= (e) =>{
  setisdoctor(e.target.checked);
}
const gobackfunction = ()=>{
  window.history.back();
 }
    return (
      <>
      {screenType == "web" ? (
        <Box w="100%">
        <Flex>
          <Box w="6rem">
            <MiniSideBar />
          </Box>
          {isLoading && <Loader />}
          <Box w="100%" display="flex" flexDir="column">
            <SecondaryNav withBack="Users" goBack={gobackfunction}  />
            <Box
              w="100%"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p="1rem"
            >
              <SearchBar
                passInputPlace="Search users"
                searchStyle={{ w: "25%" }}
                onChange={(e) => setSearchValue(e.target.value)}
                handleClick={() => searchFunction()}
                searchValue={searchValue}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setSearch(!search);
                    setshowFilteration(true)
                  }
                }}
              />
              <Box display='flex' gap='10px'>
               <PrimaryButton
                  onClick={()=>navigate('/roles')}
                  variant="mdBtn"
                  btnStyle={{ w: "100px", float: "right" }}
                  buttonText="Roles"
                  btnIcon='/assets/svgs/new-tab.svg'
                  iconSrc={true}
                  iconSrcStyle={{w:'14px'}}
                  isDisabled={ actionAccessRoles && (!actionAccessRoles.create) ? true : false} 
                />
               
              <PrimaryButton
                onClick={openAddModal}
                variant="mdBtn"
                btnStyle={{ w: "130px", float: "right" }}
                buttonText="Add New User"
                isDisabled={ actionAccess && (!actionAccess.create) ? true : false} 
              />
              </Box>
            </Box>
            {/* <Divider />
            {searchValue &&
              <Box
                w="100%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="1rem"
              >
                <Text fontSize="0.875rem" fontWeight="600">
                  {filteredData.length > 1 ? filteredData.length + " Users found" :
                  filteredData.length == 1 ? filteredData.length + " User found" :
                    "No User found"}
                </Text>
                <Text fontSize="0.875rem" color="red" fontWeight="600" cursor='pointer' onClick={clearFilters}>
                  Clear Filters
                </Text>
              </Box>
            } */}
            <Box
              h="calc(100vh - 145px)"
              overflow="hidden auto"
              border="1px solid #E6E7E9"
            >
              {filteredData && filteredData.length > 0 ?
                <Box w="100%">
                  <SimpleGrid columns={{sm: 2, md: 3}} spacing="1rem" p="1rem">
                    {filteredData.map((item, index) => (
                    <Card
                      borderRadius="12px"
                      key={index}
                      w="100%"
                      border="1px solid #E6E7E9"
                      boxShadow="0px 8px 20px 0px #E6E7E966"
                    >
                      <CardBody p={{base: "0.875rem",lg: "0.875rem", xl: "1.25rem"}}>
                        <Box
                          pb="10px"
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >                  
                          <Box cursor="pointer" display="flex" alignItems="center" gap='0.5rem'>
                            <Image boxSize='45px' src={item.profilepictureurl ? item.imagepath + item.profilepictureurl : "/assets/imgs/no-image.png"} />
                            <Box display="flex" flexDirection="column" justifyContent="center">
                              <Text fontSize="15px" fontWeight="600" className="textOverflow" maxW={{base: "180px", xl:"220px"}}>
                                {item.firstname ? item.firstname : "-"} {item.lastname ? item.lastname : ""}
                              </Text>
                              <Text fontSize="12px" fontWeight="500" color="#384052">
                              {item.rolename ? item.rolename : "-"}
                              </Text>
                            </Box>
                          </Box>
                          {item.issuperuser != 1 &&
                            <Box display="flex" gap="10px">
                                <Box
                                  as="button"
                                  onClick={() => openEditModal(item.doctorid ? item.doctorid : "")}
                                  disabled={ actionAccess && (!actionAccess.update) ? true : false} 
                                  border="1px solid #E6E7E9"
                                  borderRadius="full"
                                  w="36px"
                                  h="auto"
                                  p="10px"
                                >
                                  <Image w="13px" src="/assets/imgs/edit-gray.png"
                                  opacity={ actionAccess && (!actionAccess.update) ? "0.5" : ""}  />
                                </Box>
  
                                <Box
                                  as="button"
                                  // onClick={editDeleteBtn}
                                  border="1px solid #E6E7E9"
                                  borderRadius="full"
                                  w="36px"
                                  h="auto"
                                  p="10px"
                                  onClick={() => openDeleteModal(item.doctorid ? item.doctorid : "")}
                                  disabled={ actionAccess && (!actionAccess.delete) ? true : false} 
                                >
                                  <Image w="13px" src="/assets/imgs/delete.png"
                                  
                                  opacity={ actionAccess && (!actionAccess.delete) ? "0.5" : ""}  />
                                </Box>
                              </Box>
                          }   
                        </Box>
       
                            <Divider my="10px" />
                            <Flex w='100%' gap='1rem'>
                              <Box display='flex' flexDir='column' w='100%'>
                                <Flex gap="8px" align="center">
                                  <Image src="/assets/svgs/phone.svg" boxSize="14px" filter={COLORS.FILTER_BLACK} />
                                  <Text fontSize="12px" fontWeight="600">
                                  {item.countrycode ? item.countrycode : ""} &nbsp; {item.mobileno ? item.mobileno : "-"}
                                  </Text>
                                </Flex>
                                <Flex gap="8px" align="center">
                                  <Image src="/assets/svgs/mail.svg" boxSize="14px" filter={COLORS.FILTER_BLACK} />
                                  <Text fontSize="12px" fontWeight="600" title={item.email ? item.email : "-"} className="textOverflow">
                                  {item.email ? item.email : "-"}
                                  </Text>
                                </Flex>
                              </Box>
  
                              <Box w='100%' display='flex' flexDir='column' alignItems='flex-end'>
                                  <Text fontSize="10px" color="gray.500">
                                    Created Date
                                  </Text>
                                  <Text fontSize="12px" fontWeight="600">
                                    {item.regdate ? formatDate_Do_MMM_YYYY(item.regdate) : ""}
                                  </Text>
                                </Box>
                            </Flex>
                        
                      </CardBody>
  
                    </Card>
                    ))}

                  </SimpleGrid>
                </Box>

                :
                <Box display="flex" justifyContent="center" flexDir="column" gap="10px" alignItems="center" h="100vh">
                  <Image src="/assets/svgs/users-inactive.svg" opacity="0.5" />
                  <Text>No User</Text>
                </Box>
              }

                
            </Box>
          </Box>
        </Flex>
      </Box>
      ) : (
        <>
        <MobileNavbar />
          <MobileSecondaryNav
            header="Users"
            handlebackarrow={() => navigate(`/doctorprofile`)}
          />
        <Box w="100%">
          <Flex>
            {isLoading && <Loader />}
            <Box w="100%" display="flex" flexDir="column">
              <Box
                w="100%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="1rem"
                gap='0.75rem'
              >
                <SearchBar
                  passInputPlace="Search users"
                  // searchStyle={{ w: "25%" }}
                  onChange={(e) => setSearchValue(e.target.value)}
                  handleClick={() => searchFunction()}
                  searchValue={searchValue}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setSearch(!search);
                      setshowFilteration(true)
                    }
                  }}
                />
                <PrimaryButton
                  onClick={openAddModal}
                  variant="mdBtn"
                  // btnStyle={{ w: "10%", float: "right" }}
                  buttonText="Add New User"
                  isDisabled={ actionAccess && (!actionAccess.create) ? true : false} 
                />
              </Box>
              
              <Box
                h="calc(100vh - 210px)"
                overflow="hidden auto"
                border="1px solid #E6E7E9"
              >
                {filteredData && filteredData.length > 0 ?
                  <Box w="100%">
                    <SimpleGrid columns={1} spacing="1rem" p="1rem">
                      {filteredData.map((item, index) => (
                      <Card
                        borderRadius="12px"
                        key={index}
                        w="100%"
                        border="1px solid #E6E7E9"
                        boxShadow="0px 8px 20px 0px #E6E7E966"
                      >
                        <CardBody p={{base: "0.875rem",lg: "0.875rem", xl: "1.25rem"}}>
                          <Box
                            // pb="10px"
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >                  
                            <Box cursor="pointer" display="flex" alignItems="center" gap='0.5rem'>
                              <Image boxSize='45px' src={item.profilepictureurl ? item.imagepath + item.profilepictureurl : "/assets/imgs/no-image.png"} />
                              <Box display="flex" flexDirection="column" justifyContent="center">
                                <Text fontSize="15px" fontWeight="600" className="textOverflow" maxW={{base: "180px", xl:"220px"}}>
                                  {item.firstname ? item.firstname : "-"} {item.lastname ? item.lastname : ""}
                                </Text>
                                <Text fontSize="12px" fontWeight="500" color="#384052">
                                {item.rolename ? item.rolename : "-"}
                                </Text>
                              </Box>
                            </Box>
                             {item.issuperuser != 1 &&
                              <Box display="flex" gap="10px">
                                  <Box
                                    as="button"
                                    onClick={() => openEditModal(item.doctorid ? item.doctorid : "")}
                                    disabled={ actionAccess && (!actionAccess.update) ? true : false} 
                                    border="1px solid #E6E7E9"
                                    borderRadius="full"
                                    w="36px"
                                    h="auto"
                                    p="10px"
                                  >
                                    <Image w="13px" src="/assets/imgs/edit-gray.png"
                                    opacity={ actionAccess && (!actionAccess.update) ? "0.5" : ""}  />
                                  </Box>
    
                                  <Box
                                    as="button"
                                    // onClick={editDeleteBtn}
                                    border="1px solid #E6E7E9"
                                    borderRadius="full"
                                    w="36px"
                                    h="auto"
                                    p="10px"
                                    onClick={() => openDeleteModal(item.doctorid ? item.doctorid : "")}
                                    disabled={ actionAccess && (!actionAccess.delete) ? true : false} 
                                  >
                                    <Image w="13px" src="/assets/imgs/delete.png"
                                    
                                    opacity={ actionAccess && (!actionAccess.delete) ? "0.5" : ""}  />
                                  </Box>
                                </Box>
                              }
                          </Box>
         
                              <Divider my="10px" />
                              <Flex w='100%' gap='1rem'>
                                <Box display='flex' flexDir='column' w='100%'>
                                  <Flex gap="8px" align="center">
                                    <Image src="/assets/svgs/phone.svg" boxSize="14px" filter={COLORS.FILTER_BLACK} />
                                    <Text fontSize="12px" fontWeight="600">
                                    {item.mobileno ? item.mobileno : "-"}
                                    </Text>
                                  </Flex>
                                  <Flex gap="8px" align="center">
                                    <Image src="/assets/svgs/mail.svg" boxSize="14px" filter={COLORS.FILTER_BLACK} />
                                    <Text fontSize="12px" fontWeight="600" title={item.email ? item.email : "-"} className="textOverflow">
                                    {item.email ? item.email : "-"}
                                    </Text>
                                  </Flex>
                                </Box>
    
                                <Box w='100%' display='flex' flexDir='column' alignItems='flex-end'>
                                    <Text fontSize="10px" color="gray.500">
                                      Created Date
                                    </Text>
                                    <Text fontSize="12px" fontWeight="600">
                                      {item.regdate ? formatDate_Do_MMM_YYYY(item.regdate) : ""}
                                    </Text>
                                  </Box>
                              </Flex>
                          
                        </CardBody>
    
                      </Card>
                      ))}
  
                    </SimpleGrid>
                  </Box>
  
                  :
                  <Box display="flex" justifyContent="center" flexDir="column" gap="10px" alignItems="center" h="100vh">
                    <Image src="/assets/svgs/users-inactive.svg" opacity="0.5" />
                    <Text>No User</Text>
                  </Box>
                }

                  
              </Box>
            </Box>
          </Flex>
        </Box>
        </>
      )}
        
  
        <ModalRight
          ref={addNewUser}
          header="Add New User"
          modalWidth={{ base: "100%", lg: "calc(100vw - 70%)" }}
          body={
            <>
              <Box w="100%" py="1rem">
                <Box display='flex' w='100%' justifyContent='center'>
                  <Box>
                    <Image border="1px solid #E6E7E9" w="85px" h="85px" borderRadius="full" src={doctorphoto ? imagePath + doctorphoto : "/assets/imgs/no-image.png"} />
                    <Box as="Button" pos="relative" bottom="25px" left="58px" bg="#121316" p="6px" border="2px solid white" borderRadius="full" onClick={changeImage}>
                      <input type="file" ref={inputRef} style={{ display: 'none' }} onChange={handleImageSelection} />
                      <Image w="11px" h="auto" src='/assets/svgs/edit-white.svg' />
                    </Box>
                  </Box>
                </Box>
                <Box w="100%" display="flex">
                  <PrimaryInput inputPlace="Enter First Name*" variant="leftSide"
                    inputValue={firstname} onChange={(e) => setfirstname(handleAlphaDotAndSpace(e.target.value))} />
  
                  <PrimaryInput
                    inputPlace="Enter Last Name"
                    variant="rightSide"
                    inputValue={lastname} onChange={(e) => setlastname(handleAlphaDotAndSpace(e.target.value))}
                  />
                </Box>
                
                <Box w="100%" display="flex">
                  <PrimaryInput inputPlace="Enter Email*" variant="bothSide"
                    inputValue={email} onChange={(e) => setEmail(e.target.value)} 
                  />
                </Box>
                
                <Box w="100%" display="flex">
                  <DropdownInput
                    variant="bothSide"
                    dropDownPlace="Gender*"
                    options={["Male", "Female", "Others"]}
                    readOnly={true}
                    id="gender"
                     onClick={selectGender}
                   //  defaultOption={gender}
                    menuList={{pos: "absolute", right: "-45px", minW: "115px"}}
                  />


                  <DatePicker
                    disableFutureDates={true}
                    placeholderProp="Date of Birth*"
                    leftPos={{ left: "5px" }}
                    datePickerInputStyle={{ borderRadius: "0!important" }}
                     onDateChange={(e) => handleDateChange(e)}
                    //defaultDate={dob}
                  />
                </Box>
                
                <Box w="100%" display="flex">
                      <PrimaryInputDropdown
                         onChange={(e) => handleMobileChange(e)}
                         inputValue={mobileno}
                        id="mobile" variant="downSide" 
                        placeholderProp="Phone number*"
                      />
  
                </Box>

                {/* <Box w="100%" display="flex">
                  <PasswordInput
                    passInputPlace="Enter Password*"
                    variant="downSide"
                    inputValue={password} onChange={(e) => setPassword(e.target.value)}
                  />
                </Box> */}
  
              </Box>
              
              <Box w='100%'>
                <DropdownInput
                    variant="fullSide"
                    dropDownPlace="Role*"
                    defaultOption={roleList && roleList.find(item => item.id == roleIdFromUsersPage)?.rolename}
                    options={roleList && roleList.map(item => item.rolename)} 
                    readOnly={true}
                    id="role"
                     onClick={selectRole}
                    menuList={{pos: "absolute", right: "-45px", minW: "300px"}}
                  />
              </Box>
              {/* <Box display="flex" gap="30px" my="15px">
                <Checkbox
                  colorScheme="green"
                  sx={{
                    ".chakra-checkbox__control": {
                      width: "20px",
                      height: "20px",
                      borderRadius: "5px",
                    },
                  }}
                //  defaultChecked={isDoctor === true}
                  onChange={isDoctorFunction}
                >
                  Doctor
                </Checkbox>
                </Box> */}
            </>
          }
          footerActions={
            <>
              <Flex gap="1.5rem" w="100%">
                <PrimaryButton
                  buttonText="Cancel"
                  variant="grayBtnFull"
                  btnStyle={{ color: "#AA2F27" }}
                  onClick={closeAddModal}
                />
                <PrimaryButton buttonText="Save" onClick={createDoctorUserFunction} />
              </Flex>
            </>
          }
        />
        
        <ModalRight
          ref={editNewUser}
          header="Edit User"
          modalWidth={{ base: "100%", lg: "calc(100vw - 70%)" }}
          body={
            <>
              <Box w="100%" py="1rem">
                <Box display='flex' w='100%' justifyContent='center'>
                  <Box>
                  <Image border="1px solid #E6E7E9" w="85px" h="85px" borderRadius="full" src={doctorphoto ? imagePath + doctorphoto : "/assets/imgs/no-image.png"} />
                    <Box as="Button" pos="relative" bottom="25px" left="58px" bg="#121316" p="6px" border="2px solid white" borderRadius="full" onClick={changeImage}>
                      <input type="file" ref={inputRef} style={{ display: 'none' }} onChange={handleImageSelection} />
                      <Image w="11px" h="auto" src='/assets/svgs/edit-white.svg' />
                    </Box>
                  </Box>
                </Box>
                <Box w="100%" display="flex">
                  <PrimaryInput inputPlace="Enter First Name*" variant="leftSide"
                  defaultValue={firstname}
                    inputValue={firstname} onChange={(e) => setfirstname(handleAlphaDotAndSpace(e.target.value))} />
  
                  <PrimaryInput
                    inputPlace="Enter Last Name"
                    variant="rightSide"
                    defaultValue={lastname}
                    inputValue={lastname} onChange={(e) => setlastname(handleAlphaDotAndSpace(e.target.value))}
                  />
                </Box>
                
                <Box w="100%" display="flex">
                  <PrimaryInput inputPlace="Enter Email*" variant="bothSide"
                  defaultValue={email}
                    inputValue={email} onChange={(e) => setEmail(e.target.value)} 
                  />
                </Box>
                
                <Box w="100%" display="flex">
                  <DropdownInput
                    variant="bothSide"
                    dropDownPlace="Gender*"
                    options={["Male", "Female", "Others"]}
                    readOnly={true}
                    id="gender"
                     onClick={selectGender}
                     defaultOption={gender}
                    menuList={{pos: "absolute", right: "-45px", minW: "115px"}}
                  />


                  <DatePicker
                    disableFutureDates={true}
                    placeholderProp="Date of Birth*"
                    leftPos={{ left: "5px" }}
                    datePickerInputStyle={{ borderRadius: "0!important" }}
                     onDateChange={(e) => handleDateChange(e)}
                    defaultDate={dob}
                  />
                </Box>
                
                <Box w="100%" display="flex">
                      <PrimaryInputDropdown
                         onChange={(e) => handleMobileChange(e)}
                         inputValue={mobileno}
                         defaultValue={mobileno}
                        id="mobile" variant="bothSide" 
                        placeholderProp="Phone number*"
                      />
  
                </Box>

                {/* <Box w="100%" display="flex">
                  <PasswordInput
                    passInputPlace="Enter Password"
                    variant="downSide"
                    inputValue={password} onChange={(e) => setPassword(e.target.value)}
                  />
                </Box> */}
  
              </Box>
              
              <Box w='100%'>
                <DropdownInput
                    variant="fullSide"
                    dropDownPlace="Role*"
                    options={roleList && roleList.map(item => item.rolename)} 
                    readOnly={true}
                    id="Role"
                     onClick={selectRole}
                     defaultOption={roleName}
                    menuList={{pos: "absolute", right: "-45px", minW: "300px"}}
                  />
              </Box>
              {/* <Box display="flex" gap="30px" my="15px">
                <Checkbox
                  colorScheme="green"
                  sx={{
                    ".chakra-checkbox__control": {
                      width: "20px",
                      height: "20px",
                      borderRadius: "5px",
                    },
                  }}
                  defaultChecked={isdoctor == 1}
                  onChange={isDoctorFunction}
                >
                  Doctor
                </Checkbox>
                </Box> */}
            </>
          }
          footerActions={
            <>
              <Flex gap="1.5rem" w="100%">
                <PrimaryButton
                  buttonText="Cancel"
                  variant="grayBtnFull"
                  btnStyle={{ color: "#AA2F27" }}
                  onClick={closeEditModal}
                />
                <PrimaryButton buttonText="Save" onClick={updateDoctorUserFunction} />
              </Flex>
            </>
          }
        />
  
        <DynamicModal
          ref={deleteUser}
          modalHeader="Delete User"
          modalBody={
            <>
              <Text fontSize="14px" fontWeight="600">
                Are you sure you want to delete user?
              </Text>
            </>
          }
          modalFooterBtn={
            <>
              <PrimaryButton variant="deleteBtn" buttonText="Delete" onClick={deleteDoctorUserFunction} />
            </>
          }
        />

<DynamicModal
        ref={editPhoto}
        modalHeader="Upload Photo"
        modalBody={
          <>
            {showCropper && doctorphoto &&  (
              <Box display="flex" justifyContent="center" alignItems="center" h='400px' w='400px'>
              <ImageCropper
                src={doctorphoto}
                onCropComplete={handleCropComplete}
              />
              </Box>
            )}
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton 
            buttonText="Upload" 
            mr={3} 
            onClick={() => {
              handleCrop();
              editPhoto.current.closeModal();
            }} 
            />
            <PrimaryButton buttonText="Cancel" onClick={() => editPhoto.current.closeModal() } />
          </>
        }
        size="lg"
      />
      </>
    );
  };
  
  export default UsersIndex;
  