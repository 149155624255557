import moment from "moment";
import formAPIModalSubscriptionDescription from "./subscriptiondescp";
import formAPISubscriptionDescription from "./subscriptiondescps";
export default function formAPIModelSubscription({
    subscriptionplanid,
    subscriptionname,
 
    subscriptionprice,
    subscriptionpriceunit,
    subscriptionstartdate,
    subscriptionenddate,
    duration,
    durationinmonths,
    createddate,
    isactive,
    iscurrent,
    subscriptiondescription,
   

})
{
  return Object.freeze({
    subscriptionplanid,
    subscriptionname,
    subscriptionprice,
    subscriptionpriceunit,
    subscriptionstartdate,
    subscriptionenddate,
    duration,
    durationinmonths,
    createddate,
    isactive,
    iscurrent,
    subscriptiondescription: subscriptiondescription != [] ? formAPISubscriptionDescription(subscriptiondescription) : [],


   
  });
}