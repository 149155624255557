import  FetchData, { } from '../../client';
import {getLoginID}  from '../../../auth';
import formAPIModelinventory from '../../domains/inventory/inventories';
import axios from 'axios';
import { CONFIG } from '../../../appconfig/appconfig';

// without formatting output

export const addInventory = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    const result = await FetchData("midoc_addinventory", inputData);
    
    return result;

  } catch (error) {
    console.error('Error in addInventory :', error);
    throw error;
  }
};


export const updateInventory = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      const result = await FetchData("midoc_updateinventory", inputData);
      
      return result;
  
    } catch (error) {
      console.error('Error in updateInventory:', error);
      throw error;
    }
  };

  export const deleteInventory = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      const result = await FetchData("midoc_deleteinventory", inputData);
      
      return result;
  
    } catch (error) {
      console.error('Error in deleteInventory:', error);
      throw error;
    }
  };


  
// with formatting output
export const getInventory = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      const result = await FetchData("midoc_getinventory", inputData);
      //console.log(result,"Home db response")
      if(result.output.message == "No inventory found"){
        return result
      }else if(result.output.data) {
      var data = formAPIModelinventory(result.output.data)
      //let pageData = {
        let count = result?.output?.count;
        let pageno  = result?.output?.pageno;
        let pagesize =  result?.output?.pagesize;
     // }
      var finalOutput = {
          data,
          count,
          pageno,
          pagesize
      }
      return finalOutput;
      }
  
    } catch (error) {
      console.error('Error in getInventory:', error);
      throw error;
    }
  };


  // Fetch Calls (AI) ---------------------

  export const GetAIMedicineComposition = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      const result = await FetchData("midoc_ai_medicinebyname", inputData);
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };


  export const GetMedicineName = async (uri,name,type) => {
    // alert(uri)
     try {     
       const formData = new FormData();
       formData.append("file", uri);
       // form.append('image', {
       //   uri: uri,
       //   type: type ? 'image/jpeg' : 'image/pdf',
       //   name: name ? 'image.jpg' : 'image.pdf',
       // });
        let doctorid = getLoginID();
        formData.append("doctorid", doctorid);
       //console.log(formData)
       const response = await axios.post(`${CONFIG.APIBaseUrl}midoc_ai_medicinename`,formData, {    
         headers: {
           'Content-Type': 'multipart/form-data', 
         },
       });
       //console.log('@@response',response)
       if (response.statusText === "OK") { 
         const result = await response.data;
         //console.log('@@result',result)
         return result
       } else {
         const error = "failed" ;
         return error
       }
     } catch (err) {
       console.log(err); 
       const error = "failed" ;
       return error
     }
   };
