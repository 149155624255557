import moment from "moment";
import formAPIModelImage from "./images/images";
import formAPIModelVoice from "./voice/voices";
import formAPIModelDocument from "./documents/documents";
import formAPIModelLink from "./links/links";



export default function formapimodalchatmedia({
  patientname,
    patientimage, 
    imagepath,
    appointmentdate,
    appointmenttime,
    appointmentid,
    image,
    voice,
    link,
    document
})
{
  return Object.freeze({
    patientname,
    patientimage, 
    imagepath,
    appointmentdate,
    appointmenttime,
    appointmentid,
    image: image !== null ? formAPIModelImage(image):[],
    voice: voice !== null ? formAPIModelVoice(voice):[],
    link:  link !== null ? formAPIModelLink(link):[],
    document: document !== null ? formAPIModelDocument(document):[],
  });
}