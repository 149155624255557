import { Box, Center, Flex, Text, VStack } from '@chakra-ui/react'
import React,{useState,useEffect} from 'react'
import { PrimaryButton, Toaster } from '../../components'
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import { redirect, useLocation, useNavigate } from "react-router-dom";
import { SubmitLastCallofFinalStep, FetchFinalStepsData, SubmitAvailbility, SubmitDoctorSpeciality,FetchDoctorSpecialities } from '../../middleware/services/finalsteps';
import { useScreenType } from '../../auth';
import { saveToken,savePatientDetails,saveUsername,saveFirstName,saveLastName,saveLoginID, savePatientImage, savePatientdob, savePatientgender, saveFirstTimeLogin, savecurrency, saveAddressInfo, saveEmail, saveMobile, saveLogo, saveHospitalName, saveIsDoctor } from "../../auth";
import {
  FetchLogin,
} from "../../middleware/services/login/index";


const ReviewProfile = () => {
  
    const { isLoading, showLoader, dismissLoader } = useLoader();
    const [data, setData] = useState("");
    const [specialities, setSpecialities] = useState("");
    const [AvailabilityData, setAvailabilityData] = useState("");
    const [SpecialitiesData, setSpecialitiesData] = useState("");
    const [SpecialitiesName, setSpecialitiesName] = useState("");
    const [Email, setEmail] = useState("");
    const [Password, setPassword] = useState("");
    const [userTypeId, setUserTypeId] = useState(null);
    var [doctorid, setDoctorid] = useState();
    const navigate = useNavigate();
    const screenType = useScreenType()
    let specialityNames = "";
    
  const { showSuccessToast, showErrorToast } = Toaster();

    useEffect(() => {
      var storedavailabilitydetails = localStorage.getItem("availabilitydetails");
      var storedspecialitydetails = localStorage.getItem("specialities");
      var availability = JSON.parse(storedavailabilitydetails);
      if(availability){
        setAvailabilityData(availability);
      }
      var speciality = JSON.parse(storedspecialitydetails);
      if(speciality){
        setSpecialitiesData(speciality);
        specialityNames = speciality;
      }
      var storedPersonalDetails = localStorage.getItem("personalDetails");
      // Parse the string back into an object
      var detailsFromLocal = JSON.parse(storedPersonalDetails);
      if (detailsFromLocal) {
        setEmail(detailsFromLocal.email);
        setPassword(detailsFromLocal.password);
      }
       FetchStepsData();
       FetchSpecialities();
      }, []);
      const FetchStepsData = async ()=>{
        showLoader()
        try {
          var result = await FetchFinalStepsData({
            doctorid,
          });
          if (result) {
            console.log(result);
            setData(result)
            // setSpecialities(result.doctorspeciality && result.doctorspeciality.map(doctor => doctor.specialityname).join(", "))
          }
          dismissLoader();
        } catch (error) {
          console.log(error, "Something went wrong in doctor profile")
        }
      }
      const FetchSpecialities = async ()=>{
        showLoader()
        try {
          var result = await FetchDoctorSpecialities({
           "type":"speciality"
          });
          if (result) {
            console.log(result)
            console.log(specialityNames)
            const specialtyNames = specialityNames.speciality.map(item => {
              const specialty = result.find(spec => spec.id === item.specialtyid);
              return specialty ? specialty.specialityname : ''; // If specialty not found, return an empty string
            });
            console.log(specialtyNames)
            if(specialtyNames){
              setSpecialitiesName(specialtyNames.join(",")); // Output the filtered specialty names
            }
            
          }
          dismissLoader();
        } catch (error) {
          console.log(error, "Something went wrong in doctor profile")
        }
      }

    let input = {"doctorid":"","firsttimelogin":"0","issignupcompleted":"1", "isfinalstepcomplete":"1"};
    const SubmitFunction = async ()=>{
        showLoader()
        try {
            const result = await SubmitAvailbility(AvailabilityData);
            if (result) {
              console.log(result);
            }
            // const resultspeciality = await SubmitDoctorSpeciality(SpecialitiesData);
            // if (resultspeciality) {
            //   console.log(resultspeciality);
            // }
          var resp = await SubmitLastCallofFinalStep(input);
          if (resp) {
            console.log(result);
            localStorage.removeItem("clinicvisit");
            localStorage.removeItem("videovisit");
            localStorage.removeItem("availabilitydetails");
            localStorage.removeItem("specialities");
            localStorage.removeItem("slots");
            localStorage.removeItem("selectedspecialities");
            // showSuccessToast("Please login to continue!")
            // navigate("/login")
            try {
              const response = await FetchLogin({
                email: Email.trim(),
                password: Password.trim(),
              });
              if (response.result == "success") {                
                setUserTypeId(response?.data?.usertypeid)                
                localStorage.setItem("usertypeid" ,response?.data?.usertypeid);
                localStorage.setItem("rolename" ,response?.data?.rolename);
                 const allPagesShowHideMainFunc = ()=>{
                  if(!response?.data?.disablehome){
                    navigate("/home");
                  }else if(!response?.data?.disablevisits){
                    navigate("/visits");
                  }else if(!response?.data?.disablemessages){
                    navigate("/messages");
                  }else if(!response?.data?.disablepatients){
                    navigate("/patients");
                  }else if(!response?.data?.disableprescriptions){
                    navigate("/prescriptions");
                  }else if(!response?.data?.disablemedicines){
                    navigate("/medicines");
                  }else if(!response?.data?.disablereports){
                    navigate("/reports");
                  }else if(!response?.data?.disablenotifications){
                    navigate("/notifications");
                  }else if(!response?.data?.disableroles){
                    navigate("/doctorprofile");
                  }else if(!response?.data?.disableusers){
                    navigate("/doctorprofile");
                  }else if(!response?.data?.disableinventory){
                    navigate("/doctorprofile");
                  }else if(!response?.data?.disablefinance){
                    navigate("/doctorprofile");
                  }else if(!response?.data?.disableinvoices){
                    navigate("/doctorprofile");
                  }else{
                    navigate("/doctorprofile");
                  }
                }
                  let setInlocal = () =>{
                    let pagesAccessObject = {};
        
                    if (response && response.data) {
                      // Loop through the keys in the response.data object
                      Object.keys(response.data).forEach(key => {
                        if (key.startsWith('disable')) {
                          // Add each disable key to pagesAccessObject
                          pagesAccessObject[key] = response.data[key];
                        }
                      });
                    }
                  
                    localStorage.setItem("pagesAccess" ,JSON.stringify(pagesAccessObject));
                    localStorage.setItem("actionAccess" , JSON.stringify(response.data.screens));
                 }
                    if (response.data.firstname) {
                      saveFirstName(response.data.firstname);
                      saveLastName(response.data.lastname)
                    }
                    if(response.data.currencyname){
                      savecurrency(response.data.currencyname)
                    }
                    // Save the token
                    if (response.token) {
                      saveToken(response.token);
                    }
                    if (response.data.loginid) {
                      saveLoginID(response.data.loginid);
                    }
        
                    if (response.data.mobileno) {
                      saveMobile(response.data.countrycode +" "+ response.data.mobileno);
                    }
                    if (response.data.email) {
                      saveEmail(response.data.email);
                    }
                    if (response.data.addressdetail) {
                      saveAddressInfo(response.data.addressdetail);
                    }
                    if (response.data.hospitallogourl) {
                      if(response.data.imagepath){
                        saveLogo(response.data.imagepath + response.data.hospitallogourl);
                      }
                    }
                    if (response.data.hospitalname) {
                      saveHospitalName(response.data.hospitalname);
                    }
                    if (response.data.isdoctor) {
                      saveIsDoctor(response.data.isdoctor);
                    }
                        allPagesShowHideMainFunc();
                        setInlocal()
              }
            } catch (error) {
              console.error("Login failed:", error.response.data);
            }

          }
          dismissLoader();
        } catch (error) {
          console.log(error, "Something went wrong in doctor profile")
        }
      }
  return (
    <>
    {screenType == 'web' ? (

    
    <Flex flexDir="column" display={{base: "none", lg: "flex"}}>
        {isLoading && <Loader />}
        <Box w="100%" p={4} h="calc(100vh - 130px)" overflow="hidden auto">
          <Center>
            <VStack spacing={0} w="450px">
              <Box as="p" fontSize="22px" fontWeight="600" mb={5}>
                Review your profile settings
              </Box>
              
              <Box w="100%" display="flex" gap="1rem" flexDir="column">
                <Box display="flex" justifyContent="space-between" alignItems="center" w="100%" border="1px solid #E6E7E9" borderRadius="0.75rem" p="1.25rem 1.5rem">
                    <Text fontSize="0.75rem" fontWeight="500" color="#384052">Service type</Text>
                    <Text fontSize="0.75rem" fontWeight="600" color="#061027">{(data && data.doctortimingsetup && data.doctortimingsetup.isvideocall === 1 ? "Video call" : "") + (data && data.doctortimingsetup && data.doctortimingsetup.isvideocall === 1 && data.doctortimingsetup.isclinicalvisit === 1 ? " & " : "") + (data && data.doctortimingsetup && data.doctortimingsetup.isclinicalvisit === 1 ? " Clinic visit" : "")}  </Text>
                </Box>

                <Box w="100%" border="1px solid #E6E7E9" borderRadius="0.75rem" >
                    <Box display="flex" justifyContent="space-between" borderBottom="1px solid #E6E7E9" alignItems="start" w="100%" p="1.25rem 1.5rem">
                        <Box w="30%">
                            <Text fontSize="0.75rem" fontWeight="500" color="#384052">Working hours  (For clinic visit)</Text>
                        </Box>
                        <Box w="70%">
                        {AvailabilityData &&
                          AvailabilityData.clinicalvisits.reduce((acc, item) => {
                              if (item.timings && item.timings.length > 0) {
                                  item.timings.forEach(timing => {
                                      const existingIndex = acc.findIndex(
                                          elem =>
                                              elem.fromtime === timing.fromtime &&
                                              elem.totime === timing.totime
                                      );

                                      if (existingIndex !== -1) {
                                          acc[existingIndex].days.push(item.day);
                                      } else {
                                          acc.push({
                                              fromtime: timing.fromtime,
                                              totime: timing.totime,
                                              days: [item.day],
                                          });
                                      }
                                  });
                              } else if (item.isavailable === 0 || item.timings.length === 0) {
                                acc.push({
                                    fromtime: '',
                                    totime: '',
                                    days: [item.day],
                                });
                            }
                              return acc;
                          }, []).map((item, index) => (
                              <Text key={index} fontSize="0.75rem" fontWeight="600" color="#061027">
                                  {item.days.join(', ')} - {item.fromtime} - {item.totime}
                              </Text>
                          ))
                      }


                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between" borderBottom="1px solid #E6E7E9" alignItems="start" w="100%" p="1.25rem 1.5rem">
                        <Box w="30%">
                            <Text fontSize="0.75rem" fontWeight="500" color="#384052">Working hours  (For video calls)</Text>
                        </Box>
                        <Box w="70%">
                        {AvailabilityData &&
                        AvailabilityData.videovisit.reduce((acc, item) => {
                            if (item.timings && item.timings.length > 0) {
                                item.timings.forEach(timing => {
                                    const existingIndex = acc.findIndex(
                                        elem =>
                                            elem.fromtime === timing.fromtime &&
                                            elem.totime === timing.totime
                                    );

                                    if (existingIndex !== -1) {
                                        acc[existingIndex].days.push(item.day);
                                    } else {
                                        acc.push({
                                            fromtime: timing.fromtime,
                                            totime: timing.totime,
                                            days: [item.day],
                                        });
                                    }
                                });
                            } else if (item.isavailable === 0 || item.timings.length === 0) {
                              acc.push({
                                  fromtime: '',
                                  totime: '',
                                  days: [item.day],
                              });
                          }
                            return acc;
                        }, []).map((item, index) => (
                            <Text key={index} fontSize="0.75rem" fontWeight="600" color="#061027">
                                {item.days.join(', ')} - {item.fromtime} - {item.totime}
                            </Text>
                        ))
                    }


                          </Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between" borderBottom="1px solid #E6E7E9" alignItems="center" w="100%" p="1.25rem 1.5rem">
                        <Text fontSize="0.75rem" fontWeight="500" color="#384052">Duration of calls</Text>
                        <Text fontSize="0.75rem" fontWeight="600" color="#061027">{data && data.doctortimingsetup && data.doctortimingsetup.durationofcall ?data.doctortimingsetup.durationofcall:"-"} {data && data.doctortimingsetup && data.doctortimingsetup.durationofcallvalue}</Text>
                    </Box>
                    <Box display="flex" justifyContent="space-between" borderBottom="1px solid #E6E7E9" alignItems="center" w="100%" p="1.25rem 1.5rem">
                        <Text fontSize="0.75rem" fontWeight="500" color="#384052">Minimum notice</Text>
                        <Text fontSize="0.75rem" fontWeight="600" color="#061027">{data && data.doctortimingsetup && data.doctortimingsetup.minimumnotice ?data.doctortimingsetup.minimumnotice:"-"} {data && data.doctortimingsetup && data.doctortimingsetup.minimumnoticevalue}</Text>
                    </Box>
                    <Box display="flex" justifyContent="space-between" borderBottom="1px solid #E6E7E9" alignItems="center" w="100%" p="1.25rem 1.5rem">
                        <Text fontSize="0.75rem" fontWeight="500" color="#384052">Before call buffer</Text>
                        <Text fontSize="0.75rem" fontWeight="600" color="#061027">{data && data.doctortimingsetup && (data.doctortimingsetup.beforecallbuffer || data.doctortimingsetup.beforecallbuffer == 0) ?data.doctortimingsetup.beforecallbuffer:"-"} {data && data.doctortimingsetup && data.doctortimingsetup.beforecallbuffervalue}</Text>
                    </Box>
                    <Box display="flex" justifyContent="space-between" borderBottom="1px solid #E6E7E9" alignItems="center" w="100%" p="1.25rem 1.5rem">
                        <Text fontSize="0.75rem" fontWeight="500" color="#384052">After call buffer</Text>
                        <Text fontSize="0.75rem" fontWeight="600" color="#061027">{data && data.doctortimingsetup && (data.doctortimingsetup.aftercallbuffer || data.doctortimingsetup.aftercallbuffer == 0) ?data.doctortimingsetup.aftercallbuffer:"-"} {data && data.doctortimingsetup && data.doctortimingsetup.aftercallbuffervalue}</Text>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" w="100%" p="1.25rem 1.5rem">
                        <Text fontSize="0.75rem" fontWeight="500" color="#384052">Limit future bookings</Text>
                        <Text fontSize="0.75rem" fontWeight="600" color="#061027">{data && data.doctortimingsetup && data.doctortimingsetup.bookinupto ?data.doctortimingsetup.bookinupto:"-"} {data && data.doctortimingsetup && data.doctortimingsetup.bookinuptovalue}</Text>
                    </Box>
                </Box>

                {/* <Box display="flex" justifyContent="space-between" alignItems="center" w="100%" border="1px solid #E6E7E9" borderRadius="0.75rem" p="1.25rem 1.5rem">
                    <Text fontSize="0.75rem" w="30%" fontWeight="500" color="#384052">Specialty & diagnose</Text>
                    <Text fontSize="0.75rem" w="70%" fontWeight="600" color="#061027"> {SpecialitiesName}</Text>
                </Box> */}
              </Box>
            </VStack>
          </Center>
        </Box>
            <Box p="1.5rem 2rem" display="flex" justifyContent="end" w="100%" borderTop="1px solid #E6E7E9">
                <PrimaryButton buttonText="Continue" btnStyle={{w: "30%"}} onClick={SubmitFunction}/>
            </Box>
    </Flex>
    ) : (

    <Flex flexDir="column" display={{base: "flex", lg: "none"}}>
    {isLoading && <Loader />}
        <Box w="100%" p={4} h="calc(100vh - 180px)" overflow="hidden auto">
          <Center>
            <VStack spacing={0} w="100%">
              <Box as="p" fontSize="22px" fontWeight="600" mb={5}>
                Review your profile settings
              </Box>
              
              <Box w="100%" display="flex" gap="1rem" flexDir="column">
                <Box display="flex" justifyContent="space-between" alignItems="center" w="100%" border="1px solid #E6E7E9" borderRadius="0.75rem" p="1.25rem 1.5rem">
                    <Text fontSize="0.75rem" fontWeight="500" color="#384052">Service type</Text>
                    <Text fontSize="0.75rem" fontWeight="600" color="#061027">{(data && data.doctortimingsetup && data.doctortimingsetup.isvideocall === 1 ? "Video call" : "") + (data && data.doctortimingsetup && data.doctortimingsetup.isvideocall === 1 && data.doctortimingsetup.isclinicalvisit === 1 ? " & " : "") + (data && data.doctortimingsetup && data.doctortimingsetup.isclinicalvisit === 1 ? " Clinic visit" : "")}  </Text>
                </Box>

                <Box w="100%" border="1px solid #E6E7E9" borderRadius="0.75rem" >
                    <Box display="flex" justifyContent="space-between" borderBottom="1px solid #E6E7E9" alignItems="start" w="100%" p="1.25rem 1.5rem">
                        <Box w="30%">
                            <Text fontSize="0.75rem" fontWeight="500" color="#384052">Working hours  (For clinic visit)</Text>
                        </Box>
                        <Box w="70%">
                        {AvailabilityData &&
                          AvailabilityData.clinicalvisits.reduce((acc, item) => {
                              if (item.timings && item.timings.length > 0) {
                                  item.timings.forEach(timing => {
                                      const existingIndex = acc.findIndex(
                                          elem =>
                                              elem.fromtime === timing.fromtime &&
                                              elem.totime === timing.totime
                                      );

                                      if (existingIndex !== -1) {
                                          acc[existingIndex].days.push(item.day);
                                      } else {
                                          acc.push({
                                              fromtime: timing.fromtime,
                                              totime: timing.totime,
                                              days: [item.day],
                                          });
                                      }
                                  });
                              } else if (item.isavailable === 0 || item.timings.length === 0) {
                                acc.push({
                                    fromtime: '',
                                    totime: '',
                                    days: [item.day],
                                });
                            }
                              return acc;
                          }, []).map((item, index) => (
                              <Text key={index} fontSize="0.75rem" fontWeight="600" color="#061027">
                                  {item.days.join(', ')} - {item.fromtime} - {item.totime}
                              </Text>
                          ))
                      }


                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between" borderBottom="1px solid #E6E7E9" alignItems="start" w="100%" p="1.25rem 1.5rem">
                        <Box w="30%">
                            <Text fontSize="0.75rem" fontWeight="500" color="#384052">Working hours  (For video calls)</Text>
                        </Box>
                        <Box w="70%">
                        {AvailabilityData &&
                        AvailabilityData.videovisit.reduce((acc, item) => {
                            if (item.timings && item.timings.length > 0) {
                                item.timings.forEach(timing => {
                                    const existingIndex = acc.findIndex(
                                        elem =>
                                            elem.fromtime === timing.fromtime &&
                                            elem.totime === timing.totime
                                    );

                                    if (existingIndex !== -1) {
                                        acc[existingIndex].days.push(item.day);
                                    } else {
                                        acc.push({
                                            fromtime: timing.fromtime,
                                            totime: timing.totime,
                                            days: [item.day],
                                        });
                                    }
                                });
                            } else if (item.isavailable === 0 || item.timings.length === 0) {
                              acc.push({
                                  fromtime: '',
                                  totime: '',
                                  days: [item.day],
                              });
                          }
                            return acc;
                        }, []).map((item, index) => (
                            <Text key={index} fontSize="0.75rem" fontWeight="600" color="#061027">
                                {item.days.join(', ')} - {item.fromtime} - {item.totime}
                            </Text>
                        ))
                    }


                          </Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between" borderBottom="1px solid #E6E7E9" alignItems="center" w="100%" p="1.25rem 1.5rem">
                        <Text fontSize="0.75rem" fontWeight="500" color="#384052">Duration of calls</Text>
                        <Text fontSize="0.75rem" fontWeight="600" color="#061027">{data && data.doctortimingsetup && data.doctortimingsetup.durationofcall ?data.doctortimingsetup.durationofcall:"-"} {data && data.doctortimingsetup && data.doctortimingsetup.durationofcallvalue}</Text>
                    </Box>
                    <Box display="flex" justifyContent="space-between" borderBottom="1px solid #E6E7E9" alignItems="center" w="100%" p="1.25rem 1.5rem">
                        <Text fontSize="0.75rem" fontWeight="500" color="#384052">Minimum notice</Text>
                        <Text fontSize="0.75rem" fontWeight="600" color="#061027">{data && data.doctortimingsetup && data.doctortimingsetup.minimumnotice ?data.doctortimingsetup.minimumnotice:"-"} {data && data.doctortimingsetup && data.doctortimingsetup.minimumnoticevalue}</Text>
                    </Box>
                    <Box display="flex" justifyContent="space-between" borderBottom="1px solid #E6E7E9" alignItems="center" w="100%" p="1.25rem 1.5rem">
                        <Text fontSize="0.75rem" fontWeight="500" color="#384052">Before call buffer</Text>
                        <Text fontSize="0.75rem" fontWeight="600" color="#061027">{data && data.doctortimingsetup && data.doctortimingsetup.beforecallbuffer ?data.doctortimingsetup.beforecallbuffer:"-"} {data && data.doctortimingsetup && data.doctortimingsetup.beforecallbuffervalue}</Text>
                    </Box>
                    <Box display="flex" justifyContent="space-between" borderBottom="1px solid #E6E7E9" alignItems="center" w="100%" p="1.25rem 1.5rem">
                        <Text fontSize="0.75rem" fontWeight="500" color="#384052">After call buffer</Text>
                        <Text fontSize="0.75rem" fontWeight="600" color="#061027">{data && data.doctortimingsetup && data.doctortimingsetup.aftercallbuffer ?data.doctortimingsetup.aftercallbuffer:"-"} {data && data.doctortimingsetup && data.doctortimingsetup.aftercallbuffervalue}</Text>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" w="100%" p="1.25rem 1.5rem">
                        <Text fontSize="0.75rem" fontWeight="500" color="#384052">Limit future bookings</Text>
                        <Text fontSize="0.75rem" fontWeight="600" color="#061027">{data && data.doctortimingsetup && data.doctortimingsetup.bookinupto ?data.doctortimingsetup.bookinupto:"-"} {data && data.doctortimingsetup && data.doctortimingsetup.bookinuptovalue}</Text>
                    </Box>
                </Box>

                <Box display="flex" justifyContent="space-between" alignItems="center" w="100%" border="1px solid #E6E7E9" borderRadius="0.75rem" p="1.25rem 1.5rem">
                    <Text fontSize="0.75rem" w="30%" fontWeight="500" color="#384052">Specialty & diagnose</Text>
                    <Text fontSize="0.75rem" w="70%" fontWeight="600" color="#061027"> {SpecialitiesName}</Text>
                </Box>
              </Box>
            </VStack>
          </Center>
            <Box p="0" display="flex" justifyContent="end" w="100%" pt='1rem'>
                <PrimaryButton buttonText="Continue" onClick={SubmitFunction}/>
            </Box>
        </Box>
    </Flex>
    )}
    </>
  )
}

export default ReviewProfile