export default function formapiInvoice({
    id,
    invoicenumber,
    invoiceurl,
    saletype,
    subtype,
    subtotal,
    tax,
    totalamount,
    createddate,
    patientname,
    patientmobile,
    patientemail,
    doctorname,
    imagepath,
    currency
}) {
    return Object.freeze({
        id,
        invoicenumber,
        invoiceurl,
        saletype,
        subtype,
        subtotal,
        tax,
        totalamount,
        createddate,
        patientname,
        patientmobile,
        patientemail,
        doctorname, 
        imagepath,
        currency
    });
}