import formAPItotalfollowupchart from "./followupchart";


var formattedOutput = null;
var index = 0;
const formAPIModeltotalfollowupchart = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPItotalfollowupchart(item, index=index+1));


  return formattedOutput
};

export default formAPIModeltotalfollowupchart