import React, { useState, useEffect } from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  FormControl,
} from "@chakra-ui/react";
import { COLORS } from "../styles/colors";

const Dropdown = ({ options, onClick, placeholder, value, inputStyleCss }) => {
  const [selectedValue, setSelectedValue] = useState("");

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  return (
      <Menu>
        <MenuButton
          as={Button}
          w="100%"
          h="48px"
          fontSize="14px"
          fontWeight="500"
          color="#384052"
          borderRadius="10px"
          px="0.7rem"
          textAlign="left"
          bg="white"
          border="1px solid #E6E7E9"
          _active={{bg: "none"}}
          _hover={{ bg: "none",outline: "none", border: `2px solid ${COLORS.INPUT_FOCUS_DARK}` }}
          _focus={{ bg: "none",outline: "none",border: "none", boxShadow: `0 0 0 2px ${COLORS.INPUT_FOCUS_DARK}` }}
          {...inputStyleCss}
        >
          {selectedValue || placeholder}
        </MenuButton>
        <MenuList minW="80%" maxH="200px" overflowY="auto" >
          {options.map((option, index) => (
            <MenuItem
              key={index}
              fontSize="14px"
              fontWeight="500"
              color="#384052"
              onClick={() => {
                setSelectedValue(option);
                onClick(option);
              }}
            >
              {option}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
  );
};

export default Dropdown;