import React from "react";
import { Button, Image } from '@chakra-ui/react';
import { styles, submitStyle, variantStyles } from './style';

const PrimaryButton = ({ buttonText,onClick,isDisabled,variant,btnIcon,btnStyle, btnDisabledIcon,iconSrcStyle,onMouseLeave,onMouseEnter, ...props }) => {
  const variantStyle = variantStyles[variant] || variantStyles.defaultSide;
  const combinedStyles = { ...styles, ...variantStyle, ...btnStyle };
  const iconSrc = isDisabled && btnDisabledIcon ? btnDisabledIcon : btnIcon;

  return (
    <Button w={'100%'} sx={combinedStyles} fontSize="0.875rem" fontWeight="600" {...props} {...btnStyle} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} isDisabled={isDisabled}>
      {iconSrc && <Image mr="5px" src={iconSrc} sx={{...iconSrcStyle}} />}
      {buttonText}
    </Button>
  );
};

PrimaryButton.defaultProps = {
  btnIcon: null,
  buttonText: null,
};

export default PrimaryButton;
