import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Button,
  Badge,
  Image,
} from "@chakra-ui/react";

import {
  updateDoctorNotes,
  GetDoctorMasterData,
  GetDoctorNotes,
} from "../../../../../middleware/services/visits/patient-overview/updatedoctornotes";
import PrimaryButton from "../../../../primary-button";
import { COLORS } from "../../../../styles/colors";
import Loader from "../../../../loader/loader";
import useLoader from "../../../../loader/loaderstates";

const AiROSCard = ({
  patientId,
  doctorId,
  appointmentId,
  showSuccessToast,
  showErrorToast,
  aiResponseROS,
}) => {
  const [doctorMasterROSData, setDoctorMasterROSData] = useState([]);
  const [selectedConditions, setSelectedConditions] = useState({});
  const [changesMade, setChangesMade] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  const { isLoading, showLoader, dismissLoader } = useLoader();

  useEffect(() => {
    fetchRosMasterData();
    fetchDoctorNotes();
  }, []);

  const fetchRosMasterData = async () => {
    showLoader();
    try {
      const inputData = {
        input: { type: "ros" },
      };
      const data = await GetDoctorMasterData(inputData.input);
      setDoctorMasterROSData(data);
    } catch (error) {
      console.error("Error fetching Master ROS data:", error);
    } finally {
      dismissLoader();
    }
  };

  function transformROS(inputROS) {
    // console.log(inputROS);
    return inputROS.map((item) => {
      const rosname = Object.keys(item)[0]; // Get the first key as the rosname
      const conditions = item[rosname]; // Get the array of conditions

      // Ensure that conditions is an array before mapping
      return {
        rosname: rosname,
        rosvalue: Array.isArray(conditions)
          ? conditions.map((condition) => ({ value: condition }))
          : [], // If conditions is not an array, return an empty array
      };
    });
  }

  const fetchDoctorNotes = async () => {
    // const inputToFetch = {
    //   input: {
    //     patientid: patientId,
    //     doctorid: doctorId,
    //     appointmentid: appointmentId,
    //   },
    // };
    // showLoader();
    try {
      //   const data = await GetDoctorNotes(inputToFetch.input);
      const reviewOfSystem = transformROS(aiResponseROS) || [];
      const initialSelectedConditions = reviewOfSystem.reduce(
        (acc, { rosname, rosvalue }) => {
          acc[rosname] = rosvalue.reduce((acc, { value }) => {
            acc[value] = { condition: value }; // Use condition value as key
            return acc;
          }, {});
          return acc;
        },
        {}
      );
      setSelectedConditions(initialSelectedConditions);
      // setInitialLoad(false); // Mark the initial load as complete
    } catch (error) {
      console.error("Error fetching Doctor Notes data:", error);
    } finally {
      dismissLoader();
    }
  };

  const transformStateToAPIFormat = (state) => {
    return Object.keys(state)
      .map((conditionType) => ({
        rosname: conditionType,
        rosvalue: Object.values(state[conditionType]).map((condition) => ({
          value: condition.condition,
        })),
      }))
      .filter((condition) => condition.rosvalue.length > 0); // Remove entries with empty rosvalue
  };

  const UpdateROS = async () => {
    const inputToSave = {
      input: {
        doctorid: doctorId,
        patientid: patientId,
        appointmentid: appointmentId,
        ros: transformStateToAPIFormat(selectedConditions),
      },
    };
    showLoader();
    try {
      const result = await updateDoctorNotes(inputToSave.input);
      if (result) {
        showSuccessToast("ROS Saved successfully");
        setChangesMade(false); // Reset changesMade state
        setSelectedConditions({}); // Reset selectedConditions
        fetchDoctorNotes();
      }
    } catch (error) {
      console.error("Error in Update Doctor Notes:", error);
      showErrorToast("Error Happened While Saving.");
    } finally {
      dismissLoader();
    }
  };

  const handleConditionButtonClick = (type, condition) => {
    setSelectedConditions((prev) => {
      const currentTypeSelections = prev[type] || {};
      const isSelected = currentTypeSelections[condition.condition];

      let newState;
      if (isSelected) {
        const { [condition.condition]: removed, ...rest } =
          currentTypeSelections;
        newState = { ...prev, [type]: rest };
      } else {
        newState = {
          ...prev,
          [type]: {
            ...currentTypeSelections,
            [condition.condition]: condition,
          },
        };
      }

      const hasSelections = Object.values(newState).some(
        (conditions) => Object.keys(conditions).length > 0
      );

      setChangesMade(hasSelections);

      return newState;
    });
  };

  // Code to expand panels with content initially

  const [expandedIndices, setExpandedIndices] = useState([]);
  useEffect(() => {
    const getInitialExpandedIndices = () => {
      const expandedIndices = [];
      if (doctorMasterROSData && doctorMasterROSData.length > 0) {
        doctorMasterROSData.forEach((section, index) => {
          const hasData = section.data.some(
            (condition) => selectedConditions[section.conditiontype]
          );
          if (hasData) expandedIndices.push(index);
        });
      }
      return expandedIndices;
    };

    setExpandedIndices(getInitialExpandedIndices());
  }, [doctorMasterROSData, selectedConditions]);

  return (
    <Box
      position="relative"
      height="100%"
      display="flex"
      flexDirection="column"
    >
      {isLoading && (
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex="10"
          bg="rgba(255, 255, 255, 0.8)"
        >
          <Loader miniLoader={true} />
        </Box>
      )}
      <Accordion
        allowMultiple
        index={expandedIndices}
        onChange={(indices) => setExpandedIndices(indices)}
        flex="1"
        overflowY="auto"
      >
        {doctorMasterROSData.map((section) => (
          <AccordionItem key={section.conditiontype}>
            <AccordionButton
              bg="white"
              fontSize="md"
              position="relative"
              _expanded={{ bg: "#e6e7e9", color: "black" }}
            >
              <Box flex="1" textAlign="left">
                {section.conditiontype}
                {selectedConditions[section.conditiontype] &&
                  Object.keys(selectedConditions[section.conditiontype])
                    .length > 0 && (
                    <Badge
                      color="gray"
                      position="absolute"
                      right="2.8rem"
                      top="50%"
                      transform="translateY(-50%)"
                      bg="white"
                    >
                      {
                        Object.keys(selectedConditions[section.conditiontype])
                          .length
                      }{" "}
                      Selected
                    </Badge>
                  )}
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4} bg="#fcfcfd">
              {section.data.map((condition) => {
                const isSelected =
                  selectedConditions[section.conditiontype] &&
                  selectedConditions[section.conditiontype][
                    condition.condition
                  ];
                return (
                  <Button
                    key={condition.condition}
                    borderRadius="full"
                    onClick={() =>
                      handleConditionButtonClick(
                        section.conditiontype,
                        condition
                      )
                    }
                    bg={isSelected ? "black" : "white"}
                    color={isSelected ? "white" : "black"}
                    leftIcon={
                      isSelected ? (
                        <Image src="/assets/svgs/white-ok.svg" boxSize="1em" />
                      ) : null
                    }
                    variant="solid"
                    border={isSelected ? "none" : "1px solid black"}
                    size="sm"
                    m={1}
                  >
                    {condition.condition}
                  </Button>
                );
              })}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
      {/* <Button
        position="sticky"
        bottom="0"
        width="95%"
        colorScheme="teal"
        onClick={UpdateROS}
        zIndex={1}
        isDisabled={!changesMade || initialLoad} // Disable if no changes or initial load not complete
        mx="auto"
      >
        Save ROS
      </Button> */}
      <PrimaryButton
        buttonText="Save ROS"
        // isDisabled={!changesMade}
        onClick={UpdateROS}
        btnStyle={{
          h: "2.5rem",
          w: "200px",
          mt: "2rem",
          mx: "auto",
          p: "0",
          // mr: "12px",
          // ml: "12px",
          //   position: "sticky",
          // bottom: "0",
          // zIndex: "1",
          // mx: "auto",
          bg: COLORS.PRIMARY_GRADIENT,
          color: COLORS.PRIMARY_WHITE,
          _hover: { bg: COLORS.PRIMARY_COLOR },
        }}
      />
    </Box>
  );
};

export default AiROSCard;
