import  FetchData, { } from '../../client';
import axios from 'axios';
import { CONFIG } from '../../../appconfig/appconfig';
import formAPIModeltreatmentteam from '../../domains/treatmentteam/treatmentteams';

const GetSocialHistoryTypes = async (inputData) => {
  try {
    
    const result = await FetchData("midoc_getdefaultdata", inputData);
    //console.log(result,"db res type social")
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const AddSocialHistory = async (inputData) => {
  try {
    
    const result = await FetchData("midoc_addpatientsocialhistory", inputData);
    //console.log(result,"db res add social")
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const addAllergies = async (inputData) => {
    try {
      
          const result = await FetchData("midoc_addpatientallergy", inputData);
       console.log(result,"addAllergies db response")
      
      return result;
  
    } catch (error) {
      console.error('Error fetching medical conditions data:', error);
      throw error;
    }
  };
  const uploadImageInProfile = async (file, filename) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('filename', filename);
  
      const response = await axios.post(`${CONFIG.APIBaseUrl}midoc_documentupload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      if (response.statusText === 'OK') {
        const result = await response.data;
        return result;
      } else {
        const error = 'failed';
        return error;
      }
    } catch (err) {
      console.log(err);
      const error = 'failed';
      return error;
    }
  };
  const getmedicalcondition = async (inputData) => {
    try {
      
      const result = await FetchData("midoc_getdefaultdata", inputData);
  
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  const addMedicalConditions = async (inputData) => {
    try {
      const result = await FetchData("midoc_addmedicalcondition", inputData);
      return result;
  
    } catch (error) {
      console.error('Error fetching medical conditions data:', error);
      throw error;
    }
  };
  const addSurgeries = async (inputData) => {
    try {

          const result = await FetchData("midoc_addpatientprocedure", inputData);
      
      return result;
  
    } catch (error) {
      console.error('Error fetching medical conditions data:', error);
      throw error;
    }
  };
  const FetchTreatmentTeam = async (inputData) => {
    try {

      const result = await FetchData("midoc_getpatienttreatmentteam", inputData);
      //console.log(result,"Home db response")
      if (result.output.data) {
        //console.log(result.output.data)
      var finalOutput = formAPIModeltreatmentteam(result.output.data)
      }
      return finalOutput;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  const AddMedicine = async (inputData) => {
    try {
      
            const result = await FetchData("midoc_addpatientmedicine", inputData);
     //console.log(result , " db response of add medicane")
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  const GetMedicineName = async (uri,name,type) => {
   // alert(uri)
    try {     
      const formData = new FormData();
      formData.append("file", uri);
      // form.append('image', {
      //   uri: uri,
      //   type: type ? 'image/jpeg' : 'image/pdf',
      //   name: name ? 'image.jpg' : 'image.pdf',
      // });

      //console.log(formData)
      const response = await axios.post(`${CONFIG.APIBaseUrl}midoc_ai_medicinename`,formData, {    
        headers: {
          'Content-Type': 'multipart/form-data', 
        },
      });
      //console.log('@@response',response)
      if (response.statusText === "OK") { 
        const result = await response.data;
        //console.log('@@result',result)
        return result
      } else {
        const error = "failed" ;
        return error
      }
    } catch (err) {
      console.log(err); 
      const error = "failed" ;
      return error
    }
  };
  const GetAIMedicineComposition = async (inputData) => {
    try {
      const result = await FetchData("midoc_ai_medicinebyname", inputData);
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  const AddFamilyHistory = async (inputData) => {
    try {
      
          const result = await FetchData("midoc_addpatientfamily", inputData);
      //console.log(result,"Family add db response")
      // if (result.output.data) {
      // var finalOutput = formAPIModelFamilyHistory(result.output.data)
      // console.log(finalOutput)
      // }
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  const getmartialstatus = async (inputData) => {
    try {
      
      const result = await FetchData("midoc_getdefaultdata", inputData);
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  const getrelations = async (inputData) => {
    try {
      
      const result = await FetchData("midoc_getdefaultdata", inputData);
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  const FetchAdvanceDetails = async (inputData) => {
    try {
      
      const result = await FetchData("midoc_getvisitformdetail", inputData);
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  const DeleteSocialHistory = async (inputData) => {
    try {
      
      const result = await FetchData("midoc_deletepatientsocialhistory", inputData);
      //console.log(result,"db res add social")
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  const getVaccineList = async (inputData) => {
    try {
      const result = await FetchData("midoc_getdefaultdata", inputData);
  
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  
  
  const addVaccination = async (inputData) => {
    try {
      
          const result = await FetchData("midoc_addpatientvaccination", inputData);
      
      return result;
  
    } catch (error) {
      console.error('Error fetching medical conditions data:', error);
      throw error;
    }
  };
  const deleteAllergies = async (inputData) => {
    try {
      
          const result = await FetchData("midoc_deletepatientallergy", inputData);
      
      return result;
  
    } catch (error) {
      console.error('Error in deletepatientallergy :', error);
      throw error;
    }
  };
  const deleteVacinations = async (inputData) => {
    try {

          const result = await FetchData("midoc_deletepatientvaccination", inputData);
      
      return result;
  
    } catch (error) {
      console.error('Error in delete Vacinations data:', error);
      throw error;
    }
  };
  const editVaccinations = async (inputData) => {
    try {
          const result = await FetchData("midoc_updatepatientvaccination", inputData);
      
      return result;
  
    } catch (error) {
      console.error('Error in delete vacinations data:', error);
      throw error;
    }
  };
  const editAllergies = async (inputData) => {
    try {
          const result = await FetchData("midoc_updatepatientallergy", inputData);
      
      return result;
  
    } catch (error) {
      console.error('Error fetching medical conditions data:', error);
      throw error;
    }
  };
  const EditFamilyHistory = async (inputData) => {
    try {
      
          const result = await FetchData("midoc_updatepatientfamily", inputData);
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  const DeleteFamilyHistory = async (inputData) => {
    try {

      const result = await FetchData("midoc_deletepatientfamily", inputData);
      //console.log(result,"Family add db response")
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  const DeleteMedicine = async (inputData) => {
    try {

      const result = await FetchData("midoc_deletepatientmedicine", inputData);
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  const deleteSurgeries = async (inputData) => {
    try {
      
          const result = await FetchData("midoc_deletepatientprocedure", inputData);
      
      return result;
  
    } catch (error) {
      console.error('Error in delete medical conditions data:', error);
      throw error;
    }
  };
  const deleteMedicalConditions = async (inputData) => {
    try {
          const result = await FetchData("midoc_deletemedicalcondition", inputData);
      
      return result;
  
    } catch (error) {
      console.error('Error in delete medical conditions data:', error);
      throw error;
    }
  };
  const UpdateSocialHistory = async (inputData) => {
    try {

      const result = await FetchData("midoc_updatepatientsocialhistory", inputData);
      //console.log(result,"db res edit social")
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  const EditCondition = async (inputData) => {
    try {
      
      // inputData.patientid = getLoginInfo();
      // inputData.activeuserid = getUserID();
          const result = await FetchData("midoc_updatemedicalcondition", inputData);
      
      return result;
  
    } catch (error) {
      console.error('Error fetching medical conditions data:', error);
      throw error;
    }
  };
  const editSurgeries = async (inputData) => {
    try {
      
          const result = await FetchData("midoc_updatepatientprocedure", inputData);
      
      return result;
  
    } catch (error) {
      console.error('Error in delete medical conditions data:', error);
      throw error;
    }
  };
  const EditMedicine = async (inputData) => {
    try {
      
      const result = await FetchData("midoc_updatepatientmedicine", inputData);
     
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
export {AddSocialHistory,GetSocialHistoryTypes, addAllergies, uploadImageInProfile, 
  getmedicalcondition, addMedicalConditions, addSurgeries, FetchTreatmentTeam,
  GetMedicineName, AddMedicine, GetAIMedicineComposition, AddFamilyHistory,getmartialstatus,
  getrelations, FetchAdvanceDetails, DeleteSocialHistory, getVaccineList, addVaccination,
  deleteAllergies, deleteVacinations,editVaccinations,editAllergies, EditFamilyHistory, DeleteFamilyHistory,
  DeleteMedicine,deleteSurgeries,deleteMedicalConditions,UpdateSocialHistory,EditCondition,editSurgeries,EditMedicine};
