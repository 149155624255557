import formAPIchiefcomplaint from "./chiefcomplaint";



var formattedOutput = null;

const formAPIModelchiefcomplaint = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIchiefcomplaint(item));


  return formattedOutput
};

export default formAPIModelchiefcomplaint