import  FetchData, { } from '../../client';
import {getLoginID}  from '../../../auth';
import formapimodalnotifications from '../../domains/notifications/notifications';

//reac the file

const FetchNotifications = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    const result = await FetchData("midoc_getnotificationbydoctorid", inputData);
    //console.log(result,"Home db response")
    if (result.output.data) {
    var finalOutput = formapimodalnotifications(result.output.data)
    }
    return finalOutput;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};

export const acceptAppointment = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    const result = await FetchData("midoc_acceptappointment", inputData);
    //console.log(result,"Home db response")

    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};

export {FetchNotifications};
