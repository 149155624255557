
import formAPIuserdetails from "./doctoruserdetail";


var formattedOutput = null;

const formAPIModeluserdetails = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIuserdetails(item));


  return formattedOutput
};

export default formAPIModeluserdetails