import axios from 'axios';
import { getToken } from '../auth';
import { CONFIG } from '../appconfig/appconfig';

const FetchData = async (apiEndpoint, input) => {
  try {
    // const loginid = localStorage.getItem("loginid");
    const token = getToken();
    const headers = {
      'Content-Type': 'application/json',
    };
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    const response = await axios.post(`${CONFIG.APIBaseUrl}${apiEndpoint}`, 
    {
      input,
    }, 
    {
      headers: headers,
    });

    return response.data;

  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export default FetchData;
