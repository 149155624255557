import React, { useEffect, useRef } from "react";
import { Area } from "@antv/g2plot";

const AreaPlotChart = ({ originalData, colors,lineColor, height , chartName , dotColor }) => {
  const css = `
  .${chartName} .g2-tooltip-marker {
    background: ${dotColor} !important;
  }`
  
  const chartContainerRef = useRef(null);
  const areaPlotRef = useRef(null);
  // Function to add unique identifiers to labels that are the same
  const modifyData = (data) => {
    const labelCounts = {};
    return data.map((item) => {
      const count = labelCounts[item.label] || 0;
      labelCounts[item.label] = count + 1;
      return { ...item, label: `${item.label}_${count}` , tooltipLabel : item.tooltipLabel};
    });
  };
  useEffect(() => {
    if (!chartContainerRef.current || !originalData) {
      return;
    }

    const data = modifyData(originalData);
 
    const areaPlot = new Area(chartContainerRef.current, {
      data,
      xField: "label",
      yField: "value",
      smooth: true,
      areaStyle: () => ({
        fill: colors || "l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff",
      }),
      line: {
        style: {
          stroke: lineColor||'#D80027',
        },
      },      
      // point: {
      //   size: 3,
      //   shape: 'circle',
      //   style: {
      //     fill: lineColor||  '#D80027',
      //     stroke: lineColor||  '#D80027',
      //     lineWidth: 2,
      //   },
      //   state: {
      //     active: {
      //       style: {
      //         lineWidth: 2,
      //         stroke: lineColor || '#D80027',
      //         fill: '#D80027' // fill color when the point is active (hover)
      //       }
      //     }
      //   }
      // },
      tooltip: {
         marker: {
           fill: dotColor,
         },
        shared: true, // enable shared tooltip
        type: 'value',
        follow: true, 
        customItems: (originalItems) => {
          // Filter the dataset for all items that match the label of the hovered item
          const itemsToShow = data.filter(d => d.label === originalItems[0]?.data?.label);
          
          // Sort the filtered items in descending order by value
          const sortedItems = itemsToShow.sort((a, b) => b.value - a.value);
      
          // Map them to the tooltip format
          return sortedItems.map(d => ({
            ...originalItems[0], // take the style from the original item
            value: d.value, // override with individual value
            name: d.tooltipLabel
          }));
        },
        // customContent: (title, items) => {
        //   return `<div style="padding: 10px;">
        //             ${items.map(item => `<div style="display: flex; justify-content: space-between;"><div style={background:red}>${item.name} -</div> <div>${item.value}</div></div>`).join('')}
        //           </div>`;
        // },
        
         customContent : (title, items) => {
          return `
            <div style="padding: 5px;" >
              ${items.map(item => `
                <div style="display: flex; justify-content: space-between; align-items:center;">
                 <span style="background-color:${lineColor}; style="padding: 5px;" class="g2-tooltip-marker"></span>
                  <div style="padding: 5px; margin-right: 10px;">${item.name} -</div>
                  <div style="padding: 5px;">${item.value}</div>
                </div>
              `).join('')}
            </div>
          `;
        },
        
        
      },   

      xAxis: {
        type: "cat", // Set x-axis type to categorical
        label: {
          formatter: (text, item, index) => {
            // Extract only the day part (strip the unique identifier)
            return text.split('_')[0];
          }
        }
      },
    });

    areaPlotRef.current = areaPlot;
    areaPlot.render();

    // Cleanup function to prevent memory leaks
    return () => {
      areaPlot.destroy();
    };
  }, [originalData, colors, lineColor, height]); // Dependencies array

 
  return <>
  <style>{css}</style>
  <div ref={chartContainerRef} style={{ height }} className={chartName}/>
  </>
  ;
};

export default AreaPlotChart;