import { format, parseISO } from "date-fns";

export default function processMedicationsData(medications, visitDate) {
  if (!Array.isArray(medications)) {
    return [];
  }

  const filteredMedications = visitDate
    ? medications.filter((medication) => {
        if (!medication.createddate) return false;
        const medicationDate = format(
          parseISO(medication.createddate),
          "yyyy-MM-dd"
        );
        return medicationDate === format(parseISO(visitDate), "yyyy-MM-dd");
      })
    : medications;

  const medicinesData = filteredMedications.reduce((acc, medication) => {
    if (!medication.createddate) {
      return acc; // Skip if dates are not valid
    }

    const date = format(parseISO(medication.createddate), "yyyy-MM-dd");
    const existingGroup = acc.find((group) => group.date === date);

    const medicationInfo = {
      name: medication.medicinename,
      type: medication.medicinetype,
      // duration: `${
      //   Math.abs(
      //     new Date(medication.medicationstartdate) -
      //       new Date(medication.medicationenddate)
      //   ) /
      //   (1000 * 60 * 60 * 24)
      // } days`,
      duration: medication.iscurrentmedicine === 1 ? "Current" : "Inactive",
      frequency: medication.frequency,
      inMorning: medication.morning ? 1 : 0,
      inAfternoon: medication.afternoon ? 1 : 0,
      inEvening: medication.evening ? 1 : 0,
      inNight: medication.night ? 1 : 0,
      id: medication.id,
      patientid: medication.patientid,
      medicineid: medication.medicineid,
      chemicalcomposition: medication.chemicalcomposition,
      medicationstatus_cd: medication.medicationstatus_cd,
      dosage: medication.dosage,
      dosageunit: medication.dosageunit,
      startdate: medication.medicationstartdate,
      enddate: medication.medicationenddate,
      prescribedby: medication.prescribedby,
      beforefood: medication.beforefood,
      afterfood: medication.afterfood,
      prescribedhospitalname: medication.prescribedhospitalname,
      iscurrentmedicine: medication.iscurrentmedicine,
    };

    // Safely handle medication.documents
    const documents = Array.isArray(medication.documents)
      ? medication.documents
      : [];

    if (existingGroup) {
      const attachmentExists = existingGroup.attachments.some(
        (attachment) =>
          attachment.link ===
            documents.map((doc) => doc?.imagepath + doc?.documenturl)[0] &&
          attachment.name.startsWith("Prescription-")
      );

      if (!attachmentExists) {
        documents.forEach((document) => {
          const extension = document?.documenturl?.split(".").pop();
          existingGroup.attachments.push({
            link: document?.imagepath + document?.documenturl,
            name: `Prescription-${
              existingGroup.attachments.length + 1
            }.${extension}`,
          });
        });

        existingGroup.attachments.sort((a, b) => a.name.localeCompare(b.name));
      }

      existingGroup.medicines.push(medicationInfo);
    } else {
      const attachments = documents.map((document) => {
        const extension = document?.documenturl?.split(".").pop();
        return {
          link: document?.imagepath + document?.documenturl,
          name: `Prescription-1.${extension}`,
        };
      });

      attachments.sort((a, b) => a.name.localeCompare(b.name));

      acc.push({
        date: date,
        medicines: [medicationInfo],
        attachments: attachments,
      });
    }

    return acc;
  }, []);

  medicinesData.sort((a, b) => new Date(b.date) - new Date(a.date));

  return medicinesData;
}
