import React, { useEffect } from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Toaster } from "../../components";
import moment from 'moment';

const ExcelDownload = ({ data, onDownloadComplete }) => {
  const { showSuccessToast, showErrorToast } = Toaster();

  useEffect(() => {
    handleDownload(data);
  }, [data]);

  const handleDownload = (exceldata) => {
    if (!exceldata || Object.keys(exceldata).length === 0) {
      console.error("No data to download.");
      showErrorToast("No data for this filter.");
      onDownloadComplete();
      return;
    }

    const workbook = XLSX.utils.book_new();
    let hasData = false;

    const formatDate = (date) => {
      return moment(date).format('DD-MM-YYYY');
    };

    const addSheetWithCustomHeaders = (sheetData, sheetName) => {
      const formattedData = sheetData.map(item => ({
        ...item,
        appointmentdate: item.appointmentdate ? formatDate(item.appointmentdate) : item.appointmentdate,
        prescribeddate: item.prescribeddate ? formatDate(item.prescribeddate) : item.prescribeddate
      }));
      // console.log('formattedData: ', formattedData)

      const worksheet = XLSX.utils.json_to_sheet(formattedData);
      const range = XLSX.utils.decode_range(worksheet['!ref']);

      // Define custom styles
      const headerStyle = {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "00FFFF" } // Cyan color
        },
        font: {
          bold: true
        }
      };

      // Apply custom styles to the headers
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cellAddress = XLSX.utils.encode_cell({ c: C, r: 0 });
        if (!worksheet[cellAddress]) continue;
        worksheet[cellAddress].s = headerStyle;
      }

      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
      hasData = true;
    };

    if (exceldata.resultpatientturnup && exceldata.resultpatientturnup.length > 0) {
      addSheetWithCustomHeaders(exceldata.resultpatientturnup, 'Patients_Turnup');
    }

    if (exceldata.resultmedicinesprescribed && exceldata.resultmedicinesprescribed.length > 0) {
      addSheetWithCustomHeaders(exceldata.resultmedicinesprescribed, 'Medicine_Prescribed');
    }

    if (exceldata.resultpatienttime && exceldata.resultpatienttime.length > 0) {
      addSheetWithCustomHeaders(exceldata.resultpatienttime, 'Patient_Time');
    }

    if (exceldata.resultappointments && exceldata.resultappointments.length > 0) {
      addSheetWithCustomHeaders(exceldata.resultappointments, 'Appointments');
    }

    if (!hasData) {
      showErrorToast("No data for this filter.");
      onDownloadComplete();
      return;
    }

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(data, 'Doctor_Report.xlsx');

    showSuccessToast("Download successful!");
    onDownloadComplete();
  };

  return null;
};

export default ExcelDownload;
