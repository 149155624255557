import { Flex, VStack, Box, Text, Image, Divider, Spacer } from "@chakra-ui/react";
import { NavLink, useNavigate } from "react-router-dom";
import DynamicModal from "../dynamic-modal";
import PrimaryButton from "../primary-button";
import Toaster from "../toaster";
import useLoader from "../loader/loaderstates";
import Loader from "../loader/loader";
import { logOutCall } from "../../middleware/services/profile";
import { useEffect, useRef, useState } from "react";
import { actionAccessMain } from "../../auth";

function VisibleSidebar({ navItems, onNavItemClick, activeSection, isLogout=true, visibleSidebarStyle, isSetting = false, isdoctor }) {
  const linkStyle = {
    textDecoration: "none",
    color: "black",
    display: "flex",
    alignItems: "center",
    padding: "0 15px",
    fontSize: "14px",
    fontWeight: "600",
    height: "48px",
    cursor: "pointer",
  };

  const activeLinkStyle = {
    ...linkStyle,
    border: "1px solid #E6E7E9",
    background: "white",
    borderRadius: "12px",
    color: "#1C4EC5",
  };

  const filteredNavItems = isdoctor === 0 ? [navItems[0]] : navItems;

const logoutModal = useRef()
const { showSuccessToast, showErrorToast } = Toaster();
const { isLoading, showLoader, dismissLoader } = useLoader();
var navigate = useNavigate();

const [disablePages, setDisablePages] = useState("");
// const [actionAccess, setActionAccess] = useState("");

useEffect(() => {
  let disablePages = JSON.parse(localStorage.getItem("pagesAccess"));
  setDisablePages(disablePages)
  // let actionAccessLocal = JSON.parse(localStorage.getItem("actionAccess"));
  // setActionAccess(actionAccessLocal)
}, []);

let inputForLogOut = {

}
  const handleLogOut = async ()=>{
    try {
      showLoader();
      const result = await logOutCall(inputForLogOut);  
      //console.log(result , " log out result")
      if (result.output.result == "success") {
      //  setRefresh(!refresh)
        //showSuccessToast("Updated Successfully.")
        localStorage.clear()
        sessionStorage.clear()
        navigate("/login")
      } else {
        showErrorToast("Something went wrong!")
      }
    } catch (error) {
      console.log(error, "error in updating patient profile")

    }
    dismissLoader();
  }

  const linkStyleDoctorProfile = {
    textDecoration: "none",
    color: "black",
    display: "flex",
    alignItems: "center",
    padding: "0 15px",
    fontSize: "14px",
    fontWeight: "600",
    height: "40px",
    cursor: "pointer",
    paddingLeft: "10px"
  };
  const addInventory = ()=>{
   localStorage.setItem("openModal", true)
    navigate("/inventory")
  }

  return (
    <>
    
    <VStack
      minW="20rem"
      h="100%"
      bg="white"
      p="1rem"
      align="stretch"
      {...visibleSidebarStyle}
      >
      <Flex as="aside" display="flex" flexDir="column" h="100%" justifyContent="space-between">
        <Box>
          {
          // isdoctor && isdoctor == 1 ? 
          filteredNavItems.map((item, index) => (
            <Box
            key={index}
            onClick={() => onNavItemClick(item.to)}
            style={activeSection === item.to ? activeLinkStyle : linkStyle}
            >
            <Image
                boxSize="16px"
                objectFit="contain"
                src={item.iconSrc}
                alt={item.text}
                />
              <Box as="span" fontSize="14px" ml="3">
                {item.text}
              </Box>
            </Box>
          ))
          }

{isSetting && (
            <>
              <Divider />
              <Box w='100%' mt='1rem'>
              { disablePages &&  (!disablePages?.disableroles || !disablePages?.disableusers) ? (
  
              
                <Box w='100%' textAlign='left'>
                  <Text fontSize="14px" fontWeight='700'>User Management</Text>
                </Box>
    ): null
              }

              <Box borderLeft='1px solid #e2e8f0' h='auto' ml='5px' mt='5px'>
                {disablePages &&  !disablePages?.disableroles && actionAccessMain(9)?.read ?
                <NavLink to="/roles" style={linkStyleDoctorProfile}>
                  <Image
                    boxSize="16px"
                    objectFit="contain"
                    src="/assets/svgs/roles-inactive.svg"
                    alt='roles'
                  />
                  <Text as="span" fontSize="14px" ml="3">
                    Roles
                  </Text>
                </NavLink>
                : null
              }
              {disablePages &&  !disablePages?.disableusers && actionAccessMain(10)?.read ?
              <>
                {/* <Divider /> */}
                <NavLink to="/users" style={linkStyleDoctorProfile}>
                  <Image
                    boxSize="16px"
                    objectFit="contain"
                    src="/assets/svgs/users-inactive.svg"
                    alt='roles'
                  />
                  <Text as="span" fontSize="14px" ml="3">
                    Users
                  </Text>
                </NavLink>
                </>
                 : null
                }
                </Box>
                { actionAccessMain(11)?.read ?
              <>
                <Box w='100%' textAlign='left' mt="0.5rem">
                {/* <Divider /> */}
                  <Text fontSize="14px" fontWeight='700'>Inventory Management</Text>
                </Box>

                <Box borderLeft='1px solid #e2e8f0' h='auto' ml='5px' mt='5px'>
                <NavLink to="/inventory" style={linkStyleDoctorProfile}>
                  <Image
                    boxSize="16px"
                    objectFit="contain"
                    src="/assets/svgs/inventory.svg"
                    alt='roles'
                  />
                  <Text as="span" fontSize="14px" ml="3">
                    Inventory
                  </Text>
                </NavLink>
                </Box>

                {actionAccessMain(11)?.create ? (
              <>
                <Box borderLeft='1px solid #e2e8f0' h='auto' ml='5px' mt='5px'  onClick={addInventory} >
                <NavLink  style={linkStyleDoctorProfile}>
                  <Image
                    boxSize="16px"
                    objectFit="contain"
                    src="/assets/svgs/add-inventory.svg"
                    alt='roles'
                  />
                  <Text as="span" fontSize="14px" ml="3">
                    Add Inventory
                  </Text>
                </NavLink>
                </Box>
                </>
                ):(null)
               }
                
                <Box borderLeft='1px solid #e2e8f0' h='auto' ml='5px' mt='5px'>
                <NavLink to="/inventorylogs" style={linkStyleDoctorProfile}>
                  <Image
                    boxSize="16px"
                    objectFit="contain"
                    src="/assets/svgs/inventory-logs.svg"
                    alt='roles'
                  />
                  <Text as="span" fontSize="14px" ml="3">
                    Inventory Logs
                  </Text>
                </NavLink>
                </Box>
                </>
                 : null
                }
                {actionAccessMain(12)?.read || actionAccessMain(13)?.read ?
              <>
                {/* <Divider /> */}
                <Box w='100%' textAlign='left' mt="0.5rem">
                  <Text fontSize="14px" fontWeight='700'>Billing Management</Text>
                </Box>
                </>
                 : null
                }
                <Box borderLeft='1px solid #e2e8f0' h='auto' ml='5px' mt='5px'>
                { actionAccessMain(12)?.read ?
              <>
                <NavLink to="/billing" style={linkStyleDoctorProfile}>
                  <Image
                    boxSize="16px"
                    objectFit="contain"
                    src="/assets/svgs/billing.svg"
                    alt='roles'
                  />
                  <Text as="span" fontSize="14px" ml="3">
                  Sales-Pending
                  </Text>
                </NavLink>
                </>
                 : null
                }

                {actionAccessMain(13)?.read ?
              <>
                {/* <Divider /> */}
                <NavLink to="/invoices" style={linkStyleDoctorProfile}>
                  <Image
                    boxSize="16px"
                    objectFit="contain"
                    src="/assets/svgs/sales.svg"
                    alt='invoices'
                  />
                  <Text as="span" fontSize="14px" ml="3">
                    Sales
                  </Text>
                </NavLink>
                </>
                 : null
                }
                { actionAccessMain(12)?.read ?
              <>
                {/* <Divider /> */}
                <NavLink to="/labpricing" style={linkStyleDoctorProfile}>
                  <Image
                    boxSize="16px"
                    objectFit="contain"
                    src="/assets/svgs/lab-setup.svg"
                    alt='Pricing'
                  />
                  <Text as="span" fontSize="14px" ml="3">
                    Lab Setup
                  </Text>
                </NavLink>
                </>
                 : null
                }
                { actionAccessMain(12)?.read ?
              <>
                {/* <Divider /> */}
                <NavLink to="/imagepricing" style={linkStyleDoctorProfile}>
                  <Image
                    boxSize="16px"
                    objectFit="contain"
                    src="/assets/svgs/imaging-setup.svg"
                    alt='Pricing'
                  />
                  <Text as="span" fontSize="14px" ml="3">
                    Imaging Setup
                  </Text>
                </NavLink>
                </>
                 : null
                }
              { actionAccessMain(14)?.read ?
              <>
                <NavLink to="/totalrevenue" style={linkStyleDoctorProfile}>
                  <Image
                    boxSize="16px"
                    objectFit="contain"
                    src="/assets/svgs/total-revenue.svg"
                    alt='Total Revenue'
                  />
                  <Text as="span" fontSize="14px" ml="3">
                    Revenue
                  </Text>
                </NavLink>
                </>
                 : null
                }
                </Box>
                
              </Box>
            </>
          )}
        </Box>
        {isLogout && (
          <>
          <Spacer/>
        <Box
            style={linkStyle}
            onClick={()=> logoutModal.current.openModal()}
            py='2rem!important'
            >
            <Image
              boxSize="18px"
              objectFit="contain"
              src="/assets/imgs/logout.png"
              alt="Logout"
              />
            <Box
              as="span"
              color="#AA2E26"
              fontSize="14px"
              ml="3"
              >
              Logout
            </Box>
          </Box>
          </>
        )}
      </Flex>
    </VStack>
    
    <DynamicModal
        ref={logoutModal}
        modalHeader="Logout"
        modalBody={
          <>
            <Text fontSize="0.875rem" fontWeight="400">Are you sure you want to logout?</Text>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="deleteBtn"
              buttonText="Logout"
              onClick={handleLogOut}
            />
          </>
        }
      />
</>
  );
}

export default VisibleSidebar;
