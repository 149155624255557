import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Badge,
  Input,
  Text,
  VStack,
  HStack
} from "@chakra-ui/react";
import ModalRight from "../../../../../components/modal-right";
import DoctorHPIAnalyzer from "./hpi-analyze";
import {
  GetDoctorMasterData,
  GetDoctorNotes,
  DeleteOrder,
  updateDoctorNotes,
} from "../../../../../middleware/services/visits/patient-overview/updatedoctornotes";
import { formatHPIMasterData } from "../../../../../middleware/domains/visits/doctornotes";
import HPIViewCard from "./hpi-view-card";
import DynamicModal from "../../../../../components/dynamic-modal";
import PrimaryButton from "../../../../primary-button";
import useLoader from "../../../../loader/loaderstates";
import Loader from "../../../../loader/loader";
import { COLORS } from "../../../../styles/colors";
import { actionAccessMain } from "../../../../../auth";



function AIHpiNotesCard({
  patientId,
  doctorId,
  appointmentId,
  showSuccessToast,
  showErrorToast,
  AiHpi,
}) {
  //----------------------------AIRA NOTES HPI
  function transformHPI(inputHPI) {
    let idCounter = 1; // Start the counter from 1
    return inputHPI.map((item) => {
      const type = Object.keys(item)[0]; // Get the type (e.g., "Pain", "Gastrointestinal")
      const details = item[type]; // Get the details for that type

      // Create an array to hold the transformed details
      const transformedDetails = Object.keys(details).map((attribute) => {
        const value = details[attribute];
        if (Array.isArray(value)) {
          // If the value is an array, transform it into a 'values' array
          return {
            attribute: attribute,
            values: value.map((v) => ({ value: v })),
          };
        } else {
          // Otherwise, treat it as a single value
          return {
            attribute: attribute,
            value: value,
          };
        }
      });

      // Return the transformed object
      return {
        id: idCounter++, // Increment the id for each item
        type: type,
        details: transformedDetails,
      };
    });
  }

  //----------------------------- HPI Master Data
  const [doctorMasterHPIData, setDoctorMasterHPIData] = useState([]);
  const [doctorNotesHPIData, setDoctorNotesHPIData] = useState([]);
  const [doctorNotesHPITextData, setDoctorNotesHPITextData] = useState([]);
  const [siteOptions, setSiteOptions] = useState([]);
  const [onsetOptions, setOnsetOptions] = useState([]);
  const [chronicityOptions, setChronicityOptions] = useState([]);
  const [onsetQualityOptions, setOnsetQualityOptions] = useState([]);
  const [frequencyOptions, setFrequencyOptions] = useState([]);
  const [associatedSymptomsOptions, setAssociatedSymptomsOptions] = useState(
    []
  );
  const [isEditMode, setIsEditMode] = useState(false);
  const [editHPIData, setEditHPIData] = useState(null);

  const { isLoading, showLoader, dismissLoader } = useLoader();

  useEffect(() => {
    fetchHPIMasterData();
    fetchDoctorNotes();
  }, []);

  const fetchHPIMasterData = async () => {
    showLoader();
    try {
      const inputData = { input: { type: "hpi" } };
      const data = await GetDoctorMasterData(inputData.input);
      const formattedData = formatHPIMasterData(data);
      setDoctorMasterHPIData(formattedData);
    } catch (error) {
      console.error("Error fetching Master HPI data:", error);
    } finally {
      dismissLoader();
    }
  };

  const { register, handleSubmit, watch, setValue } = useForm();
  const textFieldValue = watch("textField", "");
  
  const [initialText, setInitialText] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [placeholder, setPlaceholder] = useState(
    "Click anywhere to start typing...."
  );

  const UpdateHpiTextBox = async (data) => {
    const inputToSave = {
      input: {
        doctorid: doctorId,
        patientid: patientId,
        appointmentid: appointmentId,
        hpitext: {
          description: data.textField,
          type: "other",
        },
      },
    };

    showLoader();
    try {
      const result = await updateDoctorNotes(inputToSave.input);
      if (result) {
        showSuccessToast("HPI Saved successfully");
      }
    } catch (error) {
      console.error("Error in Update Doctor Notes:", error);
      showErrorToast("Error Happened While Saving.");
    } finally {
      dismissLoader();
    }
  };

  const onSubmit = (data) => {
    setIsButtonDisabled(true); // Disable button on submit
    UpdateHpiTextBox(data);
  };

  useEffect(() => {
    if (textFieldValue !== initialText) {
      setIsButtonDisabled(false); // Enable button if text field value changes
    } else {
      setIsButtonDisabled(true); // Disable button if text field value is unchanged
    }
  }, [textFieldValue, initialText]);

  const fetchDoctorNotes = async () => {
    const inputToFetch = {
      input: {
        patientid: patientId,
        doctorid: doctorId,
        appointmentid: appointmentId,
      },
    };
    showLoader();
    try {
        const data = await GetDoctorNotes(inputToFetch.input);
      // const hpi = transformHPI(AiHpi) || [];
      
      const hpi = data?.output?.data?.historyofpresentillness || [];
      const hpitext = AiHpi
      console.log(hpi);
      setDoctorNotesHPIData(hpi);
      // setInitialText(hpitext);
      
      setValue("textField", hpitext);
    } catch (error) {
      console.error("Error fetching Doctor Notes data:", error);
    } finally {
      dismissLoader();
    }
  };

  // ---------------- Add or Edit HPI -------------------

  const addHpi = useRef();
  const [addHPIType, setAaddHPIType] = useState("");

  const handleAddHPI = (event, type) => {
    event.stopPropagation();
    prepareAndOpenModal(type);
  };

  const prepareAndOpenModal = (type, editData = null) => {
    console.log("initiating panel", type.toLowerCase());
    const selectedItem = doctorMasterHPIData.find(
      (item) => item.type === type.toLowerCase()
    );
    console.log(selectedItem);
    console.log(doctorMasterHPIData);
    if (selectedItem) {
      // S-Site Filter Master Data
      const filteredsiteOptions = selectedItem.value
        .filter((item) => item.type === "Site")
        .flatMap((item) => item.subtype.map((subItem) => subItem.value));

      // Onset Filter Master Data
      const filteredOnsetOptions = selectedItem.value
        .filter((item) => item.type === "Onset")
        .flatMap((item) => item.subtype.map((subItem) => subItem.value));

      // Chronicity Filter Master Data
      const filteredChronicityOptions = selectedItem.value
        .filter((item) => item.type === "Chronicity")
        .flatMap((item) => item.subtype.map((subItem) => subItem.value));

      // Onset Quality Filter Master Data
      const filteredOnsetQualityOptions = selectedItem.value
        .filter((item) => item.type === "Onset Quality")
        .flatMap((item) => item.subtype.map((subItem) => subItem.value));

      // Onset Filter Master Data
      const filteredFrequencyOptions = selectedItem.value
        .filter((item) => item.type === "Frequency")
        .flatMap((item) => item.subtype.map((subItem) => subItem.value));

      // S-Site Filter Master Data
      const filteredAssociatedSymptomsOptions = selectedItem.value
        .filter((item) => item.type === "Associated Symptoms")
        .flatMap((item) => item.subtype.map((subItem) => subItem.value));

      setSiteOptions(filteredsiteOptions);
      setOnsetOptions(filteredOnsetOptions);
      setChronicityOptions(filteredChronicityOptions);
      setOnsetQualityOptions(filteredOnsetQualityOptions);
      setFrequencyOptions(filteredFrequencyOptions);
      setAssociatedSymptomsOptions(filteredAssociatedSymptomsOptions);

      setAaddHPIType(type);

      setIsEditMode(!!editData);
      setEditHPIData(editData);

      // console.log(editData);

      console.log("initiating panel");

      // open Modal Call
      openEditModal();
    }
  };

  const openEditModal = () => {
    console.log("opening panel");
    if (addHpi.current) {
      addHpi.current.openRight();
    } else {
      console.log("ref not exist");
    }
  };

  const editHPI = (note) => {
    console.log("Trigerred");
    const type = note.type;
    prepareAndOpenModal(type, note);
  };

  //-------------------Delete HPI-------------------

  const dynamicModalDelteHPI = useRef();

  const [doctorNotesHPIDeleteId, setDoctorNotesHPIDeleteId] = useState("");

  const openDeleteModal = (orderId) => {
    setDoctorNotesHPIDeleteId(orderId);
    if (dynamicModalDelteHPI.current) {
      dynamicModalDelteHPI.current.openModal();
    }
  };

  function removeItemById(array, id) {
    setDoctorNotesHPIData(array.filter((item) => item.id !== id));
  }

  const deleteSelectedHPI = async () => {
    const inputForDelete = {
      input: {
        id: doctorNotesHPIDeleteId,
        patientid: patientId,
        type: "hpi",
      },
    };

    const deleteOrder = async () => {
      try {
        const result = await DeleteOrder(inputForDelete.input);
        if (result.output.message === "Successfully Deleted") {
          showSuccessToast("Order has been deleted");
          fetchDoctorNotes();
          dynamicModalDelteHPI.current.closeModal();
        } else {
          console.log(result.output.message);
          showErrorToast("Something went wrong!");
        }
      } catch (error) {
        console.error("Error in deleteMedicalCondition:", error);
      }
    };
    deleteOrder();
  };

  //-------------Initial Expansion
  const [expandedIndices, setExpandedIndices] = useState([]);

  useEffect(() => {
    const getInitialExpandedIndices = () => {
      const expandedIndices = [];
      if (doctorNotesHPIData && doctorNotesHPIData.length > 0) {
        doctorMasterHPIData.forEach((item, index) => {
          const hasData = doctorNotesHPIData.some(
            (note) => note.type.toLowerCase() === item.type.toLowerCase()
          );
          if (hasData) expandedIndices.push(index);
        });
      }
      return expandedIndices;
    };

    setExpandedIndices(getInitialExpandedIndices());
  }, [doctorNotesHPIData, doctorMasterHPIData]);

  return (
    <Box
      position="relative"
      height="100%"
      display="flex"
      flexDirection="column"
    >
      {isLoading && (
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex="10"
          bg="rgba(255, 255, 255, 0.8)"
        >
          <Loader miniLoader={true} />
        </Box>
      )}
      <Box bg="white" height={"100%"}>
        <Accordion
          allowMultiple
          index={expandedIndices}
          onChange={(indices) => setExpandedIndices(indices)}
        >
          {doctorMasterHPIData.map((item) => (
            <AccordionItem key={item.type}>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    {item.type.toUpperCase()}
                  </Box>
                  <Badge
                    ml="1"
                    color="blue"
                    bg="white"
                    cursor="pointer"
                    onClick={(event) => handleAddHPI(event, item.type)}
                  >
                    + Add
                  </Badge>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} bg="#f9fafb">
                {doctorNotesHPIData
                  .filter(
                    (note) =>
                      note.type.toLowerCase() === item.type.toLowerCase()
                  )
                  .map((note) => (
                    <Box p={{ base: "2", md: "4" }} key={note.id}>
                      <HPIViewCard
                        id={note.id}
                        site={
                          note.details
                            .find((detail) => detail.attribute === "Site")
                            ?.values.map((v) => v.value) || []
                        }
                        onset={
                          note.details.find(
                            (detail) => detail.attribute === "Onset"
                          )?.value || ""
                        }
                        chronicity={
                          note.details.find(
                            (detail) => detail.attribute === "Chronicity"
                          )?.value || ""
                        }
                        onsetQuality={
                          note.details.find(
                            (detail) => detail.attribute === "Onset Quality"
                          )?.value || ""
                        }
                        frequency={
                          note.details.find(
                            (detail) => detail.attribute === "Frequency"
                          )?.value || ""
                        }
                        ratingScale={
                          note.details.find(
                            (detail) => detail.attribute === "Rating Scale"
                          )?.value || ""
                        }
                        associatedSymptoms={
                          note.details
                            .find(
                              (detail) =>
                                detail.attribute === "Associated Symptoms"
                            )
                            ?.values.map((v) => v.value) || []
                        }
                        time={
                          note.details.find(
                            (detail) => detail.attribute === "Time"
                          )?.value || ""
                        }
                        timeValue={
                          note.details.find(
                            (detail) => detail.attribute === "Time Value"
                          )?.value || ""
                        }
                        deleteHPI={() =>
                          removeItemById(doctorNotesHPIData, note.id)
                        }
                        editHPI={() => editHPI(note)}
                      />
                    </Box>
                  ))}
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>

        <Box
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          padding={{ base: "0", md: "4" }}
          width={{ base: "100%", md: "98%" }}
          // height="55%"
          // position={{ base: "unset", md: "absolute" }}
          bg="white"
          pt={"2px"}
          mx="auto"
        >
          <VStack spacing="4">
            <Box flex="1" width="100%" position="relative" bg={"#f9fafb"}>
              <Input
                {...register("textField")}
                height="250px"
                padding="2"
                resize="none"
                as="textarea"
                // disabled={typing} // Disable when AI typing is true
                style={{ overflowWrap: "break-word", whiteSpace: "pre-wrap" }}
              />
              <Text
                position="absolute"
                bottom="2"
                left="50%"
                transform="translateX(-50%)"
                fontSize="sm"
                color="gray.500"
                w={{ base: "100%", md: "unset" }}
                textAlign={{ base: "center", md: "unset" }}
              >
                {placeholder}
              </Text>
            </Box>
            <HStack
              p={2}
              // position="absolute"
              bottom="0"
              width="100%"
              alignItems="flex-end"
            >
              <PrimaryButton
                type="submit"
                buttonText="Save HPI Text"
                variant="grayBtn"
                // isDisabled={isButtonDisabled || !textFieldValue || !actionAccessMain(17)?.create}
                btnStyle={{
                  h: "2.5rem",
                  w: "200px",
                  mt: "2rem",
                  mx: "auto",
                  p: "0",
                  // mr: "12px",
                  // ml: "12px",
                  //   position: "sticky",
                  bg: COLORS.PRIMARY_GRADIENT,
                  color: COLORS.PRIMARY_WHITE,
                  _hover: { bg: COLORS.PRIMARY_COLOR },
                }}
              />
            </HStack>
          </VStack>
        </Box>

        {/* Add or Edit HPI Functionality */}
        <ModalRight
          ref={addHpi}
          modalWidth={{ base: "100%", lg: "calc(100vw - 30%)" }}
          header={isEditMode ? "Edit HPI" : "Add HPI"}
          bodyStyle={{
            p: { base: "0.75rem", lg: "0.75rem 1.5rem" },
            overflow: "hidden auto",
          }}
          body={
            <>
              <Box display="flex" flexDir="column" gap="20px">
                <Box>
                  <DoctorHPIAnalyzer
                    patientId={patientId}
                    doctorId={doctorId}
                    appointmentId={appointmentId}
                    type={addHPIType}
                    siteOptions={siteOptions}
                    onsetOptions={onsetOptions}
                    chronicityOptions={chronicityOptions}
                    onsetQualityOptions={onsetQualityOptions}
                    frequencyOptions={frequencyOptions}
                    associatedSymptomsOptions={associatedSymptomsOptions}
                    fetchDoctorNotes={fetchDoctorNotes}
                    showSuccessToast={showSuccessToast}
                    showErrorToast={showErrorToast}
                    modalRef={addHpi}
                    editHPIData={editHPIData}
                    isEditMode={isEditMode}
                  />
                </Box>
              </Box>
            </>
          }
        />

        {/* DynamicModal for deletion */}
        <DynamicModal
          ref={dynamicModalDelteHPI}
          modalHeader={`Delete HPI?`}
          modalBody={
            <>
              <Text fontSize="14px" fontWeight="600">
                Are you sure you want to delete?
              </Text>
            </>
          }
          modalFooterBtn={
            <>
              <PrimaryButton
                variant="deleteBtn"
                buttonText="Delete"
                onClick={deleteSelectedHPI}
              />
            </>
          }
        />
      </Box>
    </Box>
  );
}

export default AIHpiNotesCard;
