import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useRef,
  useEffect,
} from "react";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Divider,
  Box,
  Image,
} from "@chakra-ui/react";

// Reusable Drawer Component
const AiModal = forwardRef(
  (
    {
      header,
      body,
      bodyStyle,
      handlechatclose,
      footerActions,
      withBg = true,
      withoutBg = false,
      modalSize,
      secondaryHeader,
      id,
      icon,
      measure,
      headerStyle,
      closeBtnStyle,
      backDropBlur,
      footerStyle,
      iconStyle,
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);

    useImperativeHandle(ref, () => ({
      openRight: () => setIsOpen(true),
      closeRight: () => setIsOpen(false),
      // closeRight: (callback) => {
      //   setIsOpen(false);
      //   if (callback && typeof callback === 'function') {
      //     callback();
      //   }
      // }
    }));
    const bodyRef = useRef(document.body);

    useEffect(() => {
      if (isOpen) {
        bodyRef.current.style.overflow = "hidden";
        bodyRef.current.style.touchAction = "none";
      } else {
        bodyRef.current.style.overflow = "";
        bodyRef.current.style.touchAction = "";
      }
    }, [isOpen]);

    // const handlechatclose = ()=>{
    //   setIsOpen(true);
    //   window.location.reload();
    // }
    return (
      <>
        <Drawer
          id={id}
          isOpen={isOpen}
          placement="right"
          onClose={() => setIsOpen(false)}
          closeOnOverlayClick={false}
          blockScrollOnMount={false}
        >
          <DrawerOverlay sx={{ backdropFilter: `blur(${backDropBlur})` }} />
          {withBg && (
            <DrawerContent bg="#061027" maxW={modalSize}>
              <Box p="24px">
                <Image w="10px" h="auto" src={icon} {...iconStyle} />
                <DrawerHeader p="0" fontSize="24px" color="white">
                  {header}
                  <Box as="span" ml={2} fontSize="14px">
                    {measure}
                  </Box>
                </DrawerHeader>
                <Box
                  as="span"
                  mt="-5px"
                  fontWeight="500"
                  color="white"
                  fontSize="14px"
                >
                  {secondaryHeader}
                </Box>
              </Box>
              <Divider />
              <DrawerBody bg="#FCFCFD" {...bodyStyle}>
                {body}
              </DrawerBody>
              {footerActions && <DrawerFooter>{footerActions}</DrawerFooter>}
            </DrawerContent>
          )}

          {withoutBg && (
            <DrawerContent maxW={modalSize}>
              <DrawerCloseButton {...closeBtnStyle} onClick={handlechatclose} />
              <DrawerHeader fontSize="24px" {...headerStyle}>
                {" "}
                <Image src={icon} />
                {header}
                <Box as="span" fontSize="14px">
                  {measure}
                </Box>
              </DrawerHeader>
              <Divider />
              <DrawerBody bg="#FCFCFD" p="0" {...bodyStyle}>
                {body}
              </DrawerBody>
              {footerActions && (
                <DrawerFooter p="0" {...footerStyle}>
                  {footerActions}
                </DrawerFooter>
              )}
            </DrawerContent>
          )}
        </Drawer>
      </>
    );
  }
);

export default AiModal;
