export default function processPhysicalExamData(physicalExam, visitDate) {
  if (!Array.isArray(physicalExam)) {
    return [];
  }

  return physicalExam
    .filter((exam) => {
      if (visitDate) {
        const examDate = exam.DATE ? exam.DATE.split("T")[0] : null; // Extract date part if DATE exists
        return examDate === visitDate.split("T")[0];
      }
      return true; // Include all exams if visitDate is not provided
    })
    .map((exam) => {
      // Determine if the input is old or new based on the first entry of physicalexam
      const isOldFormat =
        exam.physicalexam &&
        Array.isArray(exam.physicalexam) &&
        exam.physicalexam[0]?.value !== undefined;

      let condition = "";
      if (isOldFormat) {
        // Old format
        condition = exam.physicalexam
          ? exam.physicalexam.map((pe) => pe.value).join(", ")
          : "";
      } else {
        // New format
        condition = exam.physicalexam
  ? Object.entries(
      exam.physicalexam.reduce((acc, pe) => {
        const { examvalue, subexamkey, nestedexamkey, indicator } = pe;
        const prefix =
          indicator === 1
            ? "High "
            : indicator === -1
            ? "Low "
            : ""; // Prefix only if indicator exists

        const nestedKeyPart =
          nestedexamkey !== null ? ` (${nestedexamkey})` : ""; // Nested key only if not null

        const entry = `${prefix}${examvalue}${nestedKeyPart}`;
        if (subexamkey) {
          acc[subexamkey] = acc[subexamkey]
            ? [...acc[subexamkey], entry]
            : [entry];
        } else {
          acc[""] = acc[""] ? [...acc[""], entry] : [entry];
        }
        return acc;
      }, {})
    )
      .map(([key, values]) =>
        key ? `${key}: ${values.join(", ")}` : values.join(", ")
      )
      .join(", ")
  : "";

      }

      return {
        id: exam.physicalexamid,
        type: exam.type,
        physicalexam: exam.physicalexam,
        condition,
        date: exam.DATE,
      };
    })
    .sort((a, b) => new Date(b.date) - new Date(a.date));
}
