import { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  VStack,
  useColorMode,
} from "@chakra-ui/react";
import { AudioDisplay } from "./utils/audio-display";
import Progress from "./utils/progress";

// const IS_WEBGPU_AVAILABLE = !!navigator.gpu;
const IS_WEBGPU_AVAILABLE = true;

const WHISPER_SAMPLING_RATE = 16000;
const MAX_AUDIO_LENGTH = 30; // seconds
const MAX_SAMPLES = WHISPER_SAMPLING_RATE * MAX_AUDIO_LENGTH;

function GPUTranscribeNotes() {
  //-----------------------GPU Inference
  const worker = useRef(null);
  const recorderRef = useRef(null);

  const [status, setStatus] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [progressItems, setProgressItems] = useState([]);
  const [text, setText] = useState("");
  const [combinedText, setCombinedText] = useState("");
  const [tps, setTps] = useState(null);
  const [language, setLanguage] = useState("en");
  const [recording, setRecording] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [chunks, setChunks] = useState([]);
  const [stream, setStream] = useState(null);
  const audioContextRef = useRef(null);

  useEffect(() => {
    if (!worker.current) {
      worker.current = new Worker(
        new URL("./utils/gpu-worker.js", import.meta.url),
        {
          type: "module",
        }
      );
    }

    const onMessageReceived = (e) => {
      switch (e.data.status) {
        case "loading":
          setStatus("loading");
          setLoadingMessage(e.data.data);
          break;

        case "initiate":
          setProgressItems((prev) => [...prev, e.data]);
          break;

        case "progress":
          setProgressItems((prev) =>
            prev.map((item) => {
              if (item.file === e.data.file) {
                return { ...item, ...e.data };
              }
              return item;
            })
          );
          break;

        case "done":
          setProgressItems((prev) =>
            prev.filter((item) => item.file !== e.data.file)
          );
          break;

        case "ready":
          setStatus("ready");
          recorderRef.current?.start();
          break;

        case "start":
          setIsProcessing(true);
          recorderRef.current?.requestData();
          break;

        case "update":
          setTps(e.data.tps);
          break;

        case "complete":
          setIsProcessing(false);
          setText(e.data.output);
          //   setCombinedText(
          //     (prevCombinedText) => prevCombinedText + e.data.output
          //   );
          setCombinedText(e.data.output + "\n");
          console.log(e.data.output);
          break;
      }
    };

    worker.current.addEventListener("message", onMessageReceived);

    return () => {
      worker.current.removeEventListener("message", onMessageReceived);
    };
  }, []);

  useEffect(() => {
    if (recorderRef.current) return;

    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          setStream(stream);

          recorderRef.current = new MediaRecorder(stream);
          audioContextRef.current = new AudioContext({
            sampleRate: WHISPER_SAMPLING_RATE,
          });

          recorderRef.current.onstart = () => {
            setRecording(true);
            setChunks([]);
          };
          recorderRef.current.ondataavailable = (e) => {
            if (e.data.size > 0) {
              setChunks((prev) => [...prev, e.data]);
            } else {
              setTimeout(() => {
                recorderRef.current.requestData();
              }, 25);
            }
          };

          recorderRef.current.onstop = () => {
            setRecording(false);
          };
        })
        .catch((err) => console.error("The following error occurred: ", err));
    } else {
      console.error("getUserMedia not supported on your browser!");
    }

    return () => {
      recorderRef.current?.stop();
      recorderRef.current = null;
    };
  }, []);

  useEffect(() => {
    if (!recorderRef.current) return;
    if (!recording) return;
    if (isProcessing) return;
    if (status !== "ready") return;

    if (chunks.length > 0) {
      const blob = new Blob(chunks, { type: recorderRef.current.mimeType });

      const fileReader = new FileReader();

      fileReader.onloadend = async () => {
        const arrayBuffer = fileReader.result;
        const decoded = await audioContextRef.current.decodeAudioData(
          arrayBuffer
        );
        let audio = decoded.getChannelData(0);
        if (audio.length > MAX_SAMPLES) {
          audio = audio.slice(-MAX_SAMPLES);
        }

        worker.current.postMessage({
          type: "generate",
          data: { audio, language },
        });
      };
      fileReader.readAsArrayBuffer(blob);
    } else {
      recorderRef.current?.requestData();
    }
  }, [status, recording, isProcessing, chunks, language]);

  const { colorMode } = useColorMode();
  const textColor = colorMode === "light" ? "gray.800" : "gray.200";
  const bgColor = colorMode === "light" ? "white" : "gray.900";

  return (
    <Flex
      direction="column"
      h="auto"
      justify="end"
      mx="auto"
      bg={bgColor}
      color={textColor}
    >
      <Flex
        h="100%"
        overflow="auto"
        justify="center"
        align="center"
        direction="column"
        position="relative"
      >
        <Flex
          direction="column"
          align="center"
          mb={1}
          maxW="auto"
          textAlign="center"
        >
          <Heading as="h1" size="2xl" fontWeight="bold" mb={1}>
            AIRA - Notes
          </Heading>
          <Text size="md" textAlign={"left"} mt="8px">
            Hi Doctor, AIRA is always ready to assist. Just hit the start
            button, and the assistant will start listening to your conversation.
          </Text>
        </Flex>

        <VStack spacing={4} align="center" px={4}>
          {status === null && (
            <>
              <Text maxW="480px" mb={4}></Text>

              <Button
                colorScheme="blue"
                onClick={() => {
                  worker.current.postMessage({ type: "load" });
                  setStatus("loading");
                }}
                isDisabled={status !== null}
              >
                Start
              </Button>
            </>
          )}

          <Box w="500px" p={2}>
            <AudioDisplay w="full" borderRadius="lg" stream={stream} />
            {status === "ready" && (
              <Box position="relative">
                <Text
                  w="auto"
                  h="240px"
                  overflowY="auto"
                  border="1px"
                  borderRadius="lg"
                  p={2}
                  whiteSpace="pre-wrap" // This will honor the line breaks
                >
                  {combinedText}
                </Text>
                {/* {tps && (
                  <Text position="absolute" bottom={0} right={0} px={1}>
                    {tps.toFixed(2)} tok/s
                  </Text>
                )} */}
              </Box>
            )}
          </Box>
          {status === "ready" && (
            <Flex position="relative" w="full" justify="center">
              <Button
                position="absolute"
                right={2}
                onClick={() => {
                  recorderRef.current?.stop();
                  recorderRef.current?.start();
                }}
              >
                Reset
              </Button>
            </Flex>
          )}
          {status === "loading" && (
            <Box w="full" maxW="500px" textAlign="left" mx="auto" p={4}>
              <Text textAlign="center">{loadingMessage}</Text>
              {progressItems.map(({ file, progress, total }, i) => (
                <Progress
                  key={i}
                  text={file}
                  percentage={progress}
                  total={total}
                  total_required={true}
                />
              ))}
            </Box>
          )}
        </VStack>
      </Flex>
    </Flex>
  );
}

export default GPUTranscribeNotes;
