import { Box, Center, Flex, Switch, Text, VStack, useToast } from '@chakra-ui/react'
import React,{useState,useEffect} from 'react'
import { DropdownInput, PrimaryButton, PrimaryInput } from '../../components'
import { FaArrowRotateLeft } from 'react-icons/fa6'
import { SubmitServicetype } from '../../middleware/services/finalsteps';
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import Toaster from "../../components/toaster";

const ConfigureTimings = ({onSubmit}) => {
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const [SwitchChecked, setSwitchChecked] = useState(false);
  const [DurationofCalls, setDurationofCalls] = useState("10");
  const [DurationofCallsType, setDurationofCallsType] = useState("695");
  const [DurationofCallsTypeedit, setDurationofCallsTypeEdit] = useState("Mins");
  const [MinimumNotice, setMinimumNotice] = useState("30");
  const [MinimumNoticeType, setMinimumNoticeType] = useState("695");
  const [MinimumNoticeTypeedit, setMinimumNoticeTypeEdit] = useState("Mins");
  const [BeforeCallBuffer, setBeforeCallBuffer] = useState("0");
  const [BeforeCallBufferType, setBeforeCallBufferType] = useState("695");
  const [BeforeCallBufferTypeedit, setBeforeCallBufferTypeEdit] = useState("Mins");
  const [AfterCallBuffer, setAfterCallBuffer] = useState("0");
  const [AfterCallBufferType, setAfterCallBufferType] = useState("695");
  const [AfterCallBufferTypeedit, setAfterCallBufferTypeEdit] = useState("Mins");
  const [FutureBooking, setFutureBooking] = useState("");
  const [FutureBookingType, setFutureBookingType] = useState("");
  const [FutureBookingTypeedit, setFutureBookingTypeEdit] = useState("");
  const [FutureBookingsLimit, setFutureBookingsLimit] = useState(0);
  const { showSuccessToast, showErrorToast } = Toaster();
  const toast = useToast();
  const [openDropdown, setOpenDropdown] = useState(null);  // Track which dropdown is open

  const handleDropdownToggle = (dropdownId) => {
    if (openDropdown === dropdownId) {
      setOpenDropdown(null);  // Close the dropdown if it's already open
    } else {
      setOpenDropdown(dropdownId);  // Open the clicked dropdown
    }
  };
  
  useEffect(() => {
    const detailsFromLocal = localStorage.getItem("slots");
    var slotdetails = JSON.parse(detailsFromLocal);
    if (slotdetails) {
      setDurationofCalls(slotdetails.durationofcall)
      setDurationofCallsType(slotdetails.durationofcall_cd)
      setDurationofCallsTypeEdit(slotdetails.durationofcall_cd === "695" ? "Mins" : slotdetails.durationofcall_cd === "325" ? "Hours" : "")

      setMinimumNotice(slotdetails.minimumnotice)
      setMinimumNoticeType(slotdetails.minimumnotice_cd)
      setMinimumNoticeTypeEdit(slotdetails.minimumnotice_cd === "695" ? "Mins" : slotdetails.minimumnotice_cd === "325" ? "Hours" : "")

      setBeforeCallBuffer(slotdetails.beforecallbuffer)
      setBeforeCallBufferType(slotdetails.beforecallbuffer_cd)
      setBeforeCallBufferTypeEdit(slotdetails.beforecallbuffer_cd === "695" ? "Mins" : slotdetails.beforecallbuffer_cd === "325" ? "Hours" : "")

      setAfterCallBuffer(slotdetails.aftercallbuffer)
      setAfterCallBufferType(slotdetails.aftercallbuffer_cd)
      setAfterCallBufferTypeEdit(slotdetails.aftercallbuffer_cd === "695" ? "Mins" : slotdetails.aftercallbuffer_cd === "325" ? "Hours" : "")

      if (slotdetails.islimitfuturebooking === 1) {
        setSwitchChecked(true)
        setFutureBookingsLimit(1)
      } else {
        setSwitchChecked(false);
        setFutureBookingsLimit(0)
      }

      setFutureBooking(slotdetails.bookinupto)
      setFutureBookingType(slotdetails.bookinupto_cd)
      setFutureBookingTypeEdit(slotdetails.bookinupto_cd === "326" ? "Days" : slotdetails.bookinupto_cd === "328" ? "Months" : "")
    }
  }, [])

  const switchclicked = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSwitchChecked(true)
      setFutureBookingsLimit(1)
    } else {
      setSwitchChecked(false);
      setFutureBookingsLimit(0)
    }
  }

  const handleChangeDuratonofcalls = (event) => {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[^0-9.]/g, '').slice(0, 6);
    event.target.value = inputValue;
    setDurationofCalls(event.target.value);
  };

  const selectDurationofcallstype = (selectedOption) => {
    if (selectedOption === "Mins") {
      setDurationofCallsType("695")
    } else if (selectedOption === "Hours") {
      setDurationofCallsType("325")
    }
    setDurationofCallsTypeEdit(selectedOption)
  };

  const handleChangeMinimumnotice = (event) => {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[^0-9.]/g, '').slice(0, 6);
    event.target.value = inputValue;
    setMinimumNotice(event.target.value);
  };

  const selectMinimumNoticeType = (selectedOption) => {
    if (selectedOption === "Mins") {
      setMinimumNoticeType("695")
    } else if (selectedOption === "Hours") {
      setMinimumNoticeType("325")
    }
    setMinimumNoticeTypeEdit(selectedOption)
  };

  const handleChangeBeforecallbuffer = (event) => {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[^0-9.]/g, '').slice(0, 6);
    event.target.value = inputValue;
    setBeforeCallBuffer(event.target.value);
  };

  const selectBeforeCallBuffer = (selectedOption) => {
    if (selectedOption === "Mins") {
      setBeforeCallBufferType("695")
    } else if (selectedOption === "Hours") {
      setBeforeCallBufferType("325")
    }
    setBeforeCallBufferTypeEdit(selectedOption)
  };

  const handleChangeaftercallbuffer = (event) => {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[^0-9.]/g, '').slice(0, 6);
    event.target.value = inputValue;
    setAfterCallBuffer(event.target.value);
  };

  const selectAfterCalBuffer = (selectedOption) => {
    if (selectedOption === "Mins") {
      setAfterCallBufferType("695")
    } else if (selectedOption === "Hours") {
      setAfterCallBufferType("325")
    }
    setAfterCallBufferTypeEdit(selectedOption)
  };

  const handleChangefuturbookingsupto = (event) => {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[^0-9.]/g, '').slice(0, 6);
    event.target.value = inputValue;
    setFutureBooking(event.target.value);
  };

  const selectFutureBooking = (selectedOption) => {
    if (selectedOption === "Days") {
      setFutureBookingType("326")
    } else if (selectedOption === "Months") {
      setFutureBookingType("328")
    }
    setFutureBookingTypeEdit(selectedOption)
  };

  let input = {  
    "doctorid": "",
    "durationofcall": DurationofCalls,
    "durationofcall_cd": DurationofCallsType,
    "minimumnotice": MinimumNotice,
    "minimumnotice_cd": MinimumNoticeType,
    "beforecallbuffer": BeforeCallBuffer,
    "aftercallbuffer": AfterCallBuffer,
    "beforecallbuffer_cd": BeforeCallBufferType,
    "aftercallbuffer_cd": AfterCallBufferType,
    "islimitfuturebooking": FutureBookingsLimit,
    "bookinupto": FutureBooking,
    "bookinupto_cd": FutureBookingType
  }

  const SubmitFunction = async () => {
    if (!DurationofCalls) {
      showErrorToast("Please enter the Duration of calls.")
      return;
    }
    if(!DurationofCallsType){
      showErrorToast('Please select your range.');
      return;
    }
    // if (!DurationofCallsType || !MinimumNoticeType || !BeforeCallBufferType || !AfterCallBufferType || !FutureBookingType) {
    //   showErrorToast("please select your range.")
    //   return;
    // }

    showLoader()
    try {
      var result = await SubmitServicetype(input);
      if (result) {
        console.log(result);
        localStorage.setItem("slots", JSON.stringify(input))
        setTimeout(() => {
          const newActiveComponent = "review";
          onSubmit(newActiveComponent);
        }, 1000);
      }
      dismissLoader();
    } catch (error) {
      console.log(error, "Something went wrong in doctor profile")
    }
  }

  return (
    <>
      <Flex justifyContent="center">
        {isLoading && <Loader />}
        <Box w="100%" p={4}>
          <Center>
            <VStack spacing={0} w="450px">
              <Box as="p" fontSize="22px" fontWeight="600" mb={5}>
                Configure your timings
              </Box>
              
              <Flex w="100%" flexDir="column" gap="1rem">
                <Box w="100%">
                    <Box w="100%" display="flex">
                        <PrimaryInput variant="leftSide" inputPlace="Duration of calls"
                        inputValue={DurationofCalls} 
                        onChange={handleChangeDuratonofcalls}
                        />
                        <DropdownInput
                            inputStyleCss={{w:{base: "50%", lg: "30%"}}}
                            dropDownPlace="Range"
                            dropPlaceStyle={{top: "5px"}}
                            defaultOption={DurationofCallsTypeedit}
                            variant="rightSide"
                            options={["Mins", "Hours"]} 
                            // isOpen={openDropdown === 'dropdown1'}  // Control which dropdown is open
                            // toggleDropdown={() => handleDropdownToggle('dropdown1')}  // Toggle the dropdown
                            onClick={selectDurationofcallstype}/>
                    </Box>
                    <Box w="100%" display="flex">
                        <PrimaryInput variant="leftDown" inputPlace="Minimum notice"
                        inputValue={MinimumNotice} 
                        onChange={handleChangeMinimumnotice}
                        />
                        <DropdownInput
                            inputStyleCss={{w:{base: "50%", lg: "30%"}}}
                            dropDownPlace="Range"
                            dropPlaceStyle={{top: "5px"}}
                            defaultOption={MinimumNoticeTypeedit}
                            variant="rightDown"
                            options={["Mins", "Hours"]} 
                            isOpen={openDropdown === 'dropdown2'}  // Control which dropdown is open
                            toggleDropdown={() => handleDropdownToggle('dropdown2')}  // Toggle the dropdown
                            onClick={selectMinimumNoticeType}/>
                    </Box>
                </Box>

                <Box w="100%">
                    <Box w="100%" display="flex">
                        <PrimaryInput variant="leftSide" inputPlace="Before call buffer"
                        inputValue={BeforeCallBuffer} 
                        onChange={handleChangeBeforecallbuffer}
                        />
                        <DropdownInput
                            inputStyleCss={{w:{base: "50%", lg: "30%"}}}
                            dropDownPlace="Range"
                            dropPlaceStyle={{top: "5px"}}
                            defaultOption={BeforeCallBufferTypeedit}
                            variant="rightSide"
                            options={["Mins", "Hours"]} 
                            isOpen={openDropdown === 'dropdown3'}  // Control which dropdown is open
                            toggleDropdown={() => handleDropdownToggle('dropdown3')}  // Toggle the dropdown
                            onClick={selectBeforeCallBuffer}/>
                    </Box>
                    <Box w="100%" display="flex">
                        <PrimaryInput variant="leftDown" inputPlace="After call buffer" 
                        inputValue={AfterCallBuffer}
                        onChange={handleChangeaftercallbuffer}
                        />
                        <DropdownInput
                            inputStyleCss={{w:{base: "50%", lg: "30%"}}}
                            dropDownPlace="Range"
                            dropPlaceStyle={{top: "5px"}}
                            defaultOption={AfterCallBufferTypeedit}
                            variant="rightDown"
                            options={["Mins", "Hours"]} 
                            isOpen={openDropdown === 'dropdown4'}  // Control which dropdown is open
                            toggleDropdown={() => handleDropdownToggle('dropdown4')}  // Toggle the dropdown
                            onClick={selectAfterCalBuffer}/>
                    </Box>
                </Box>
                
                <Box borderRadius="0.75rem" border="1px solid #E6E7E9">
                    <Box w="100%" display="flex" justifyContent="space-between" alignItems="center" p="1rem 1.5rem" borderBottom="1px solid #E6E7E9">
                        <Text fontSize="0.75rem" fontWeight="600">LIMIT FUTURE BOOKING</Text>
                        <Switch size="lg" colorScheme="green" isChecked={FutureBookingsLimit} onChange={(e) => switchclicked(e)}/>
                    </Box>
                    {SwitchChecked &&
                    <Box p="1.5rem" w="100%" display="flex">
                      <PrimaryInput variant="fullLeft" inputPlace="Upto"
                        inputValue={FutureBooking} 
                        onChange={handleChangefuturbookingsupto}
                      />
                      <DropdownInput
                        dropDownPlace="Range"
                        dropPlaceStyle={{top: "5px"}}
                        variant="fullRight"
                        options={["Days", "Months"]}
                        defaultOption={FutureBookingTypeedit}
                        onClick={selectFutureBooking}
                        isOpen={openDropdown === 'dropdown5'}  // Control which dropdown is open
                        toggleDropdown={() => handleDropdownToggle('dropdown5')}  // Toggle the dropdown
                      />
                    </Box>
                    }
                </Box>
              </Flex>
              <Box w="100%" mt="1rem">
                <PrimaryButton buttonText="Continue" id="submitButton" 
                 onClick={SubmitFunction}
                 />
              </Box>
            </VStack>
          </Center>
        </Box>
      </Flex>
    </>
  )
}

export default ConfigureTimings
