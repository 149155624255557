import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { DateRange } from 'react-date-range';
import { FaCalendarAlt } from 'react-icons/fa'; // Calendar icon from react-icons
import 'react-date-range/dist/styles.css'; // default styling
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Box, useOutsideClick } from '@chakra-ui/react';
import PrimaryButton from '../primary-button';
import moment from 'moment';


const DateRangeIndex = forwardRef((props, ref) => {
  const today = new Date();
  let startDate = new Date(today);
  startDate.setDate(1); // Setting start date to the first day of the current month  
  const endDate = new Date();
  
  const [state, setState] = useState([
    {
      startDate: today,
      endDate: today,
      key: 'selection',
    },
  ]);
  

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const calendarRef = React.useRef();

  useImperativeHandle(ref, () => ({
    toggleCalendar: () => setIsCalendarOpen(prev => !prev),
  }));

  function handleSelect(ranges) {
      setState([ranges.selection]);
      setIsCalendarOpen(true);
  }

  function resetDateRange() {
    const today = new Date();
    setState([
      {
        startDate: startDate,
        endDate: endDate,
        key: 'selection',
      },
    ]);
  }

  function applyDateRange() {
    setIsCalendarOpen(false);
    if (props.onDateChange && state[0]) {
      const formattedStartDate = moment(state[0].startDate).format('YYYY-MM-DD HH:mm:ss.SSS');
      const formattedEndDate = moment(state[0].endDate).format('YYYY-MM-DD HH:mm:ss.SSS');
      const formattedData = {
        startDate: formattedStartDate,
        endDate: formattedEndDate
      };
      props.onDateChange(formattedData);
    }
  }

  function toggleCalendar() {
    setIsCalendarOpen(!isCalendarOpen);
  }

useOutsideClick({
  ref: calendarRef, 
  handler: (e) => {
    if (!calendarRef.current.contains(e.target)) {
      setIsCalendarOpen(false);
    }
  }
});

  const css = `
  .rdrDateDisplayWrapper {
    background-color: rgb(255 255 255);
  }
  
  .rdrDateDisplayItemActive, .rdrDateDisplayItem {
    border-color: #E6E7E9;
  }
  
  .rdrSelected, .rdrStartEdge, .rdrEndEdge {
    background: #061027!important;
    color: #061027!important;
    z-index: 2;
    border-radius: 15px;
}

.rdrInRange {
    background: #E6E7E9!important;
    width: 60px;
    margin-left: -10px;
    z-index: 0;
  }
  
  .rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after {
    background: unset!important;
  }
  
  .rdrDayToday .rdrDayNumber span:after {
    background: unset!important;
  }
  
  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
    color: #061027!important;
  }
  
  .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span {
    z-index: 2;
  }
  
  .rdrDayEndOfWeek .rdrInRange {
    border-radius: unset;
  }
  
  .rdrCalendarWrapper {
    width: auto !important;
  }

  .rdrMonthPicker select,
  .rdrYearPicker select {
    width: 100%;
  }
  `

  return (
    <Box position="relative"  ref={calendarRef}>
    <style>{css}</style>
      {isCalendarOpen && (
        <Box position="absolute" p="15px" right="5px" top="40px" zIndex="999" bg="white" borderRadius="10px" boxShadow="0px 10px 15px -3px rgba(0,0,0,0.1)" {...props.calendarStyle}>
          <DateRange
            editableDateInputs={false}
            onChange={handleSelect}
            onInputClick={() => setIsCalendarOpen(true)}
            moveRangeOnFirstSelection={false}
            retainEndDateOnFirstSelection={false}
            ranges={state}
            selectsRange={true}
            dragSelectionEnabled={true}
            preventSnapRefocus={true}
            shouldCloseOnSelect={false}
            showSelectionPreview={true}
            calendarFocus="backwards"
          />
          <Box display="flex" justifyContent="center" gap="10px">
            <PrimaryButton buttonText="Reset" btnStyle={{color: "#061027", boxShadow: "0px 0px 0px 3px #E6E7E94D, 0px 1px 2px 0px #C1C2C680, 0px -8px 12px 0px #D8D8D833 inset"}} onClick={resetDateRange} variant="grayBtn" className="custom-reset-button" />
            <PrimaryButton buttonText="Apply" onClick={applyDateRange} variant="mdBtn" className="custom-apply-button" />
          </Box>
        </Box>
      )}
    </Box>
  );
});

export default DateRangeIndex;
