import formAPImedicinesprescribed from "./prescribedmedicine";


var formattedOutput = null;

const formAPIModelmedicinesprescribed = (data, type, activeTab,selecteddate) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPImedicinesprescribed(item, type, activeTab,selecteddate));
  const transformedData = [];

  formattedOutput.forEach(item => {
  //   const patientObj = {
  //     label: item.label,
  //     value: item.patientcount,
  //     color:"#14C585",
  //     name: "Patients"
  // };
  
  // Object for medicine count
  const medicineObj = {
      time: item.label,
      medicines: item.medicinecount,
      patients:item.patientcount,
      index: item.time,
  };

  transformedData.push(medicineObj);
  });
  return transformedData;
};

export default formAPIModelmedicinesprescribed