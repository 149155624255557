import formAPItotalrevenuechart from "./revenuechart";


var formattedOutput = null;

const formAPIModeltotalrevenuechart = (data, type) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPItotalrevenuechart(item));


  return formattedOutput
};

export default formAPIModeltotalrevenuechart