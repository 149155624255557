export default function formAPIPatientsView({
    age,
    appointmentdate,
    appointmentid,
    appointmenttime,
    dob,
    bloodtype_cd,
    email,
    fullname,
    gender,
    height,
    heightunit_cd,
    imagepath,
    isactive,
    mobileno,
    weight,
    weightunit_cd,
    patientaddress,
    patientimage,
    rn,
    vaccinated,
    patientid,
    appointmentstatus,
    countrycode,
    countryid,



    
  }) {
   
  
    return Object.freeze({
        age,
    appointmentdate,
    appointmentid,
    appointmenttime,
    dob,
    bloodtype_cd,
    email,
    fullname,
    gender,
    height,
    heightunit_cd,
    imagepath,
    isactive,
    mobileno,
    weight,
    weightunit_cd,
    patientaddress,
    patientimage,
    rn,
    vaccinated,
    patientid,
    appointmentstatus,
    countrycode,
    countryid,
    });
  }
  