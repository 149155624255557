import React, { useEffect, useRef } from 'react';
import { Line } from '@antv/g2plot';

const TrendChart = ({data, color}) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      //  const data = [
      //    { label: '2023-01-01', value: 30, index: 1 },
      //   { label: '2023-02-01', value: 35, index: 2 },
      //   { label: '2023-03-01', value: 28, index: 3 },
      //   { label: '2023-04-01', value: 40, index: 4 },
      //   { label: '2023-05-01', value: 45, index: 5 },
      //   { label: '2023-06-01', value: 50, index: 6 },
      //   { label: '2023-07-01', value: 48, index: 7 },
      //   { label: '2023-08-01', value: 60, index: 8 },
      //   { label: '2023-09-01', value: 65, index: 9 },
      //   { label: '2023-10-01', value: 70, index: 10 },
      //   { label: '2023-10-01', value: 75, index: 11 },
      //  ];
      const filteredData = data.filter(item => item.value !== null);
      const linePlot = new Line(containerRef.current, {
        data: filteredData,
        xField: 'label', 
        yField: 'value',
        seriesField: 'value',
        smooth: true,
       
        lineStyle: {
          stroke: color || '#17B26A', // Set the line color to desired green
          lineWidth: 2, // Set the line width
        },
        theme: {
          styleSheet: {
            backgroundColor: '#0b0b0d', // Set the background color to black
          },
        },
        xAxis: {
            // type: 'timeCat',
          visible: false, // Hide the x-axis
        },
        yAxis: {
          visible: false, // Hide the y-axis
          grid: {
            visible: false, // Hide the y-axis grid lines
          },
        },
        tooltip: {
            showMarkers: true,
            shared: true,
            customItems: (items) => {
                // Group items by date
                const groupedItems = items.reduce((acc, item) => {
                  if (!acc[item.data.label]) {
                    acc[item.data.label] = [];
                  }
                  acc[item.data.label].push(item);
                  return acc;
                }, {});
    
                // Flatten the grouped items
                return Object.values(groupedItems).flat();
              },
            marker: {
              style: {
                fill: color || '#17B26A', // Set the tooltip marker color to green
                stroke: color ||  '#17B26A',
              },
            },
          domStyles: {
            'g2-tooltip': {
              backgroundColor: '#fff',
              borderColor: color || '#17B26A',
              //transform: 'translateY(-5px)',
            },
            'g2-tooltip-title': {
              color: '#000',
            },
            'g2-tooltip-list-item': {
              color: '#000',
            },
            'g2-tooltip-marker': {
              backgroundColor: color || '#17B26A',
            },
            'g2-tooltip-value': {
              marginLeft: '18px'
            }
          },
        },
        legend: {
          visible: false, // Hide the legend
        },
        interactions: [
          {
            type: 'marker-active',
            cfg: {
              markerStyle: {
                fill: color || '#17B26A',
                stroke: color || '#17B26A',
              },
            },
          },
        ],
      });

      linePlot.render();
    }
  }, []);

  return <div ref={containerRef} style={{ width: '130px', height: '100px' }} />;
};

export default TrendChart;
