import React, { useEffect, useState } from "react";
import {
  Flex,
  VStack,
  Box,
  Image,
  Text,
  Spacer,
  Center,
} from "@chakra-ui/react";
import PersonalDetails from "./personal-details";
import ProfessionalDetails from "./professional-details";
import Speciality from "./speciality";
import UploadDocuments from "./upload-documents";
import SubscriptionPlan from "./subscription-plan";
import RequestSubmit from "./request-submit";
import { useNavigate } from "react-router-dom";

const SignUpIndex = () => {
  const [activeComponent, setActiveComponent] = useState('personal'); 

  const [textColor, setTextColor] = useState({
    personal: 'white',
    professional: '#686869',
    speciality: '#686869',
    documents: "#686869",
    subscription:"#686869",
  });
  const [circleColor, setcircleColor] = useState({
    personal: 'white',
    professional: '#151619',
    speciality: '#151619',
    documents: "#151619",
    subscription: "#151619",
  });
  const [borderColor, setBorderColor] = useState({
    personal: '#232325',
    professional: '',
    speciality: '',
    documents: "",
    subscription: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    const storedActiveComponent = localStorage.getItem('activeComponent');
    
    if(storedActiveComponent){
      setActiveComponent(storedActiveComponent)
    
    setTextColor((prevColors) => {
      const updatedColors = {};
      Object.keys(prevColors).forEach((key) => {
        updatedColors[key] = '#686869';
      });
      updatedColors[storedActiveComponent] = 'white';
    
      return updatedColors;
    });
    setcircleColor((prevColors) => {
      const updatedColors = {};
      Object.keys(prevColors).forEach((key) => {
        updatedColors[key] = '#151619'; // Set default border color
      });
      updatedColors[storedActiveComponent] = 'white';
      return updatedColors;
    });
    setBorderColor((prevColors) => {
      const updatedColors = {};
      Object.keys(prevColors).forEach((key) => {
        updatedColors[key] = ''; // Set default border color
      });
      updatedColors[storedActiveComponent] = '#232325';
      return updatedColors;
    });

    setTimeout(() => {
      
    localStorage.removeItem('activeComponent');
    }, 2000);
  }else{
    
    setTextColor((prevColors) => {
      const updatedColors = {};
      Object.keys(prevColors).forEach((key) => {
        updatedColors[key] = '#686869';
      });
      updatedColors['personal'] = 'white';
    
      return updatedColors;
    });
  }
  }, []);

  const handleBoxClick = (component) => {
    localStorage.removeItem('activeComponent');
    setActiveComponent(component);
    setTextColor((prevColors) => {
      const updatedColors = {};
      Object.keys(prevColors).forEach((key) => {
        updatedColors[key] = '#686869';
      });
      updatedColors[component] = 'white';
    
      return updatedColors;
    });
    setcircleColor((prevColors) => {
      const updatedColors = {};
      Object.keys(prevColors).forEach((key) => {
        updatedColors[key] = '#151619'; // Set default border color
      });
      updatedColors[component] = 'white';
      return updatedColors;
    });
    setBorderColor((prevColors) => {
      const updatedColors = {};
      Object.keys(prevColors).forEach((key) => {
        updatedColors[key] = ''; // Set default border color
      });
      updatedColors[component] = '#232325';
      return updatedColors;
    });
  };
  const handleActiveComponentChange = (newActiveComponent) => {
    setTimeout(() => {
      
    setActiveComponent(newActiveComponent);
    setTextColor((prevColors) => {
      const updatedColors = {};
      Object.keys(prevColors).forEach((key) => {
        updatedColors[key] = '#686869';
      });
      updatedColors[newActiveComponent] = 'white';
    
      return updatedColors;
    });
    setcircleColor((prevColors) => {
      const updatedColors = {};
      Object.keys(prevColors).forEach((key) => {
        updatedColors[key] = '#151619'; // Set default border color
      });
      updatedColors[newActiveComponent] = 'white';
      return updatedColors;
    });
    setBorderColor((prevColors) => {
      const updatedColors = {};
      Object.keys(prevColors).forEach((key) => {
        updatedColors[key] = ''; // Set default border color
      });
      updatedColors[newActiveComponent] = '#232325';
      return updatedColors;
    });
    }, 1000);
  };
  const backFunction = ()=>{
   // alert(activeComponent);
    if(activeComponent == "personal"){
      localStorage.removeItem("personalDetails")
      localStorage.removeItem("professionalDetails")
      navigate("/login")
    }else if(activeComponent == "professional"){
      handleActiveComponentChange("personal")
    }else if(activeComponent == "speciality"){
      handleActiveComponentChange("professional")
    }else if(activeComponent == "documents"){
      handleActiveComponentChange("speciality")
    }else if(activeComponent == "subscription"){
      // localStorage.setItem('SubscriptionBackClicked', true)
      handleActiveComponentChange("documents")
    }
  }
  const removeItemFromLocalStorage = () => {
    localStorage.removeItem('personalDetails');
    localStorage.removeItem("professionalDetails")
  };
  useEffect(() => {
    // Add event listener for beforeunload event
    window.addEventListener('beforeunload', removeItemFromLocalStorage);
    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('beforeunload', removeItemFromLocalStorage);
    };
  }, []);
  return (
    <>
      <Flex h="100vh" display={{base: "none", lg: "flex"}}>
        <VStack w="25%" bg="#121316" p={{lg: "40px", xl: "64px 56px"}} h="100vh" align="stretch">
          <Box mb="20px">
            <Image w="120px" src="/assets/imgs/midoc-logo.png" />
          </Box>
          <VStack spacing={4} mb="20px" align="start">
            <Text color="white" fontSize="20px" fontWeight="600">
              Signup with us in <br /> five simple steps
            </Text>

            <Box
              display="flex"
              alignItems="center"
              gap="10px"
            //  bg="#232325"
              bg={borderColor.personal}
              borderRadius="16px"
              p="12px"
              w="100%"

            //    as="button"
            //  onClick={() => handleBoxClick('personal')}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                border="1px solid #232325"
                bg={circleColor.personal}
                borderRadius="full"
                p="14px"
                h="18px"
                w="18px"
                color="#686869"
                fontSize="14px"
                fontWeight="600"

              >
                <Text>1</Text>
              </Box>
              <Text
                textAlign="left"
                fontSize="14px"
                fontWeight="600"
               // color="white"
               color={textColor.personal} 
              >
                Personal details
              </Text>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              gap="10px"
              p="8px"
              w="100%"
              
              bg={borderColor.professional}
              borderRadius="16px"

              // as="button"
              // onClick={() => handleBoxClick('professional')}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                border="1px solid #232325"
                borderRadius="full"
                p="14px"
                h="18px"
                w="18px"
                color="#686869"
               // bg="#151619"
                
                bg={circleColor.professional}
                fontSize="14px"
                fontWeight="600"
              >
                <Text>2</Text>
              </Box>
              <Text
                textAlign="left"
                fontSize="14px"
                fontWeight="600"
              //  color="#686869"
              color={textColor.professional} 
              >
                Professional details
              </Text>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              gap="10px"
              p="8px"
              w="100%"
              
              bg={borderColor.speciality}
              borderRadius="16px"

              // as="button"
              // onClick={() => handleBoxClick('professional')}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                border="1px solid #232325"
                borderRadius="full"
                p="14px"
                h="18px"
                w="18px"
                color="#686869"
               // bg="#151619"
                
                bg={circleColor.speciality}
                fontSize="14px"
                fontWeight="600"
              >
                <Text>3</Text>
              </Box>
              <Text
                textAlign="left"
                fontSize="14px"
                fontWeight="600"
              //  color="#686869"
              color={textColor.speciality} 
              >
                Speciality & Diagnose
              </Text>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              gap="10px"
              p="8px"
              w="100%"
              
              bg={borderColor.documents}
              borderRadius="16px"
              
              // as="button"
              // onClick={() => handleBoxClick('documents')}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                border="1px solid #232325"
                borderRadius="full"
                p="14px"
                h="18px"
                w="18px"
                color="#686869"
               // bg="#151619"
               
               bg={circleColor.documents}
                fontSize="14px"
                fontWeight="600"
              >
                <Text>4</Text>
              </Box>
              <Text
                textAlign="left"
                fontSize="14px"
                fontWeight="600"
               // color="#686869"
               
               color={textColor.documents} 
              >
                Upload documents
              </Text>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              gap="10px"
              p="8px"
              w="100%"
              
              bg={borderColor.subscription}
              borderRadius="16px"

              // as="button"
              // onClick={() => handleBoxClick('subscription')}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                border="1px solid #232325"
                borderRadius="full"
                p="14px"
                h="18px"
                w="18px"
                color="#686869"
                //bg="#151619"
                
                bg={circleColor.subscription}
                fontSize="14px"
                fontWeight="600"
              >
                <Text>5</Text>
              </Box>
              <Text
                textAlign="left"
                fontSize="14px"
                fontWeight="600"
               // color="#686869"
               
               color={textColor.subscription} 
              >
                Subscription plan
              </Text>
            </Box>
          </VStack>
          <Spacer />
          <Box as="p" fontSize="12px" color="#898D97">
            © 2024 midoc, Inc.
          </Box>
        </VStack>

        <Box
          w="75%"
          bg="#FCFCFD"
          display="flex"
          justifyContent={activeComponent === 'speciality' || activeComponent === 'subscription' ? "flex-start" : "center"}
          alignItems={activeComponent === 'speciality' || activeComponent === 'subscription' ? "baseline" : "center"}
          flexDir={activeComponent === 'speciality' || activeComponent === 'subscription' ? "row" : "column"}
          h={activeComponent === 'speciality' || activeComponent === 'subscription' ? "calc(100% - 80px)" : "100%"}
          overflow="hidden auto"
        >
          <Box w={activeComponent === 'speciality' || activeComponent === 'subscription' ? "25%" : "100%"} pos="relative" top="5%" left="5%">
            <Box
              as="button"
              bg="white"
              color="white"
              display="flex"
              justifyContent="center"
              alignItems="center"
              p="10px 16px"
              border="1px solid #E6E7E9"
              borderRadius="full"
              w="64px"
              boxShadow="0px 8px 20px 0px #E6E7E966"
              onClick={backFunction}
            >
              <Image w="20px" src="/assets/imgs/back-arrow.png"  />
            </Box>
          </Box>

          <Center h="100vh" flexDir="column">
            

            {/* Personal details start */}
               
                
          {activeComponent === 'personal' &&  <PersonalDetails  onSubmit={handleActiveComponentChange}  />}
            {/* Personal details end */}

            {/* Professional details start */}
                {activeComponent === 'professional' &&  <ProfessionalDetails  onSubmit={handleActiveComponentChange}  />}
            {/* Professional details end */}

            {/* Speciality details start */}
                {activeComponent === 'speciality' &&  <Speciality  onSubmit={handleActiveComponentChange}  />}
            {/* Speciality details end */}

            {/* upload documents start */}
              
        {activeComponent === 'documents' && <UploadDocuments  onSubmit={handleActiveComponentChange} />}
            {/* upload documents end */}

            {/* subscription plan start */}
              
        {activeComponent === 'subscription' &&<SubscriptionPlan />}
            {/* subscription plan end */}

            {/* success page start */}
              {/* <RequestSubmit /> */}
            {/* success page end */}
          </Center>
        </Box>
      </Flex>

      {/* Mobile responsive */}
      <Flex display={{base: "flex", lg: "none"}} flexDir="column" w="100%" h="calc(100vh - 50px)" overflow="hidden auto">
        <Box w="100%" bg="#061027" p={6} h="100px">
            <Box w="100%" as="button" mb="1rem" onClick={backFunction}>
              <Image w="1rem" h="auto" transform="rotate(270deg)" src="/assets/imgs/arrow-up.png" />
            </Box>

            <Box display="flex" flexDir="column" gap="0.75rem">
              <Text fontSize="0.875rem" color="white">SIGN UP</Text>
            </Box>
        </Box>

        <Box w="100%" p="0 1.5rem" mt="-30px">
            {/* Personal details start */}
            {activeComponent === 'personal' &&  <PersonalDetails  onSubmit={handleActiveComponentChange}  />}
            {/* Personal details end */}

            {/* Professional details start */}
            {activeComponent === 'professional' &&  <ProfessionalDetails  onSubmit={handleActiveComponentChange}  />}
            {/* Professional details end */}

            {/* upload documents start */}
            {activeComponent === 'documents' && <UploadDocuments  onSubmit={handleActiveComponentChange} />}
            {/* upload documents end */}

            {/* subscription plan start */}
            {activeComponent === 'subscription' &&<SubscriptionPlan />}
            {/* subscription plan end */}

        </Box>
      </Flex>
    </>
  );
};

export default SignUpIndex;
