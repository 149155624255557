import React, { useEffect, useRef } from 'react';
import { Column } from '@antv/g2plot';

const PatientTimeChart = ({data, height}) => {
  const chartRef = useRef(null);
  useEffect(() => {
    // Sample data based on the provided chart
    // const data = [
    //   { time: '1:00', type: 'Mins', value: 10 },
    //   { time: '1:00', type: 'Patients', value: 5 },
    //   { time: '2:00', type: 'Mins', value: 15 },
    //   { time: '2:00', type: 'Patients', value: 10 },
    //   { time: '3:00', type: 'Mins', value: 15 },
    //   { time: '3:00', type: 'Patients', value: 5 },
    //   { time: '4:00', type: 'Mins', value: 20 },
    //   { time: '4:00', type: 'Patients', value: 5 },
    //   { time: '5:00', type: 'Mins', value: 10 },
    //   { time: '5:00', type: 'Patients', value: 5 },
    //   { time: '6:00', type: 'Mins', value: 10 },
    //   { time: '6:00', type: 'Patients', value: 5 },
    //   { time: '7:00', type: 'Mins', value: 15 },
    //   { time: '7:00', type: 'Patients', value: 5 },
    //   { time: '8:00', type: 'Mins', value: 20 },
    //   { time: '8:00', type: 'Patients', value: 5 },
    //   { time: '9:00', type: 'Mins', value: 15 },
    //   { time: '9:00', type: 'Patients', value: 5 },
    //   { time: '10:00', type: 'Mins', value: 10 },
    //   { time: '10:00', type: 'Patients', value: 5 },
    // ];

    // console.log('PatientTime data: ', data)
    const processedData = data.map(item => ({
      ...item,
      value: isNaN(item.value) ? 0 : item.value,
    }));
    const columnPlot = new Column(chartRef.current, {
      data: processedData,
      isStack: true,
      xField: 'label', //label -- > time
      yField: 'value',
      seriesField: 'type',
      yAxis: {
        // min: 0,
        // max: 100,
        // tickInterval: 10,
        label: {
          formatter: (v) => `${v}`,
        },
        title: {
          text: 'Minutes / No. of patients',
        },
      },
      tooltip: {
        shared: true,
        showMarkers: false,
      },
      legend: {
        position: 'top-right',
      },
      columnStyle: {
        radius: [5, 5, 0, 0],
        
      },
      columnWidthRatio: 0.6,
      label: {
        position: 'middle',
        layout: [{ type: 'interval-adjust-position' }],
      },
      theme: {
        colors10: ['#1C4EC5', '#14C585'], // Blue for Mins, Green for Patients
      },
    });

    columnPlot.render();

    return () => columnPlot.destroy();
  }, [data]);

  return <div ref={chartRef} style={{ height }}></div>;
};

export default PatientTimeChart;
