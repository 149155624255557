import moment from "moment";
export default function formAPIOrdersFollowUp({
  appointmentid,
  doctorid,
  doctorimage,
  doctorname,
  followupdate,
  imagepath,
  isfollowup,
  isfreevisit,
  specality,


}) {
  return Object.freeze({
    appointmentid,
    doctorid,
    doctorimage,
    doctorname,
    followupdate,
    imagepath,
    isfollowup,
    isfreevisit,
    specality,

  });
}