import { Box, Image, Text, Link } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { getProfile} from "../../middleware/services/profile";

const MobileNavbar = () => {
  const [activeLink, setActiveLink] = useState(null);
  const [profilePictureUrl, setProfilePictureUrl] = useState('');
  const [imagePath, setImagePath] = useState('');
  const [DoctorAvailability, setDoctorAvailability] = useState("")

  var navigate = useNavigate();
  const location = useLocation();

  // Handle functions
  const handleLinkClick = (section) => {
    setActiveLink(section);
  };  


  // useEffect(() => {
  //   getDoctorProfile();
  // }, []);

  var inputForGet = {
  }
  const getDoctorProfile = async () => {

    try {
      var result = await getProfile(inputForGet);
      if (result && result.ProfileOutput) {
        setDoctorAvailability(result.ProfileOutput)
      }
    } catch (error) {
      console.log(error, "Something went wrong in doctor profile")
    }
  }
  const navigatetohome = ()=>{
    navigate("/home")
  }

  const linkStyle = {
    textDecoration: 'none',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    padding: '0 5px',
    position: 'relative',
    width: '100%',
  };

  const activeLinkStyle = {
    ...linkStyle,
    _after: {
      content: '""',
      position: 'absolute',
      right: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      width: '5px',
      height: '100%',
      backgroundColor: 'green',
      borderRadius: '5px 0 0 5px',
    },
  };
  return (
    <>
      <Box pos="relative">
        <Box
          as="nav"
          pos="fixed"
          zIndex="999"
          alignItems="center"
          justifyContent="space-between"
          p="0.5rem"
          bottom="0"
          left="0"
          right="0"
          borderTop="1px solid #E6E7E9"
          bg="#0B0B0D"
          spacing={4}
          display='flex'
        >
          <Link
            display="flex"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
            p="0.5rem"
            fontSize="0.75rem"
            fontWeight="600"
            href="/home"
            color={activeLink === "home" ? "#061027" : "#6A707D"}
            onClick={() => handleLinkClick("home")}
          >
            {/* <Image
              src="/assets/svgs/mobile-home-active.svg"
              w="1.125rem"
              h="1.25rem"
              className={activeLink == "home" ? "lightBtn" : "darkBtn"}
            /> */}
            <Image w="1rem" src={location.pathname == "/home" ? '/assets/svgs/home-white.svg' : '/assets/svgs/home.svg'}/>
            <Text letterSpacing="-0.03em" fontSize="0.625rem">Dashboard</Text>
          </Link>
          <Link
            display="flex"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
            p="0.5rem"
            fontSize="0.75rem"
            fontWeight="600"
            href="/visits"
            color={activeLink === "visits" ? "#061027" : "#6A707D"}
            onClick={() => handleLinkClick("visits")}
          >
            {/* <Image
              src="/assets/svgs/mobile-appointments-inactive.svg"
              w="1.125rem"
              h="1.25rem"
              className={activeLink == "appointments" ? "lightBtn" : "darkBtn"}
            /> */}
            <Image w="1rem" src={location.pathname == "/visits" ? '/assets/svgs/visits-white.svg' : '/assets/svgs/calendar-visits.svg'}/>
            <Text letterSpacing="-0.03em" fontSize="0.625rem">Visits</Text>
          </Link>
          <Link
            display="flex"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
            p="0.5rem"
            fontSize="0.75rem"
            fontWeight="600"
            href="/messages"
            color={activeLink === "messages" ? "#061027" : "#6A707D"}
            onClick={() => handleLinkClick("messages")}
          >
            {/* <Image
              // src="/assets/svgs/mobile-myhealth-inactive.svg"
              src="/assets/svgs/my-health.svg"
              w="1.125rem"
              h="1.25rem"
              className={
                activeLink == "myhealth" ? "lightBtn" : "darkBtn"
              }
            /> */}
            <Image w="1rem" src={location.pathname == "/messages" ? '/assets/svgs/messages-white.svg' : '/assets/svgs/messages-filled.svg'}/>
            <Text letterSpacing="-0.03em" fontSize="0.625rem">Messages</Text>
          </Link>
          <Link
            display="flex"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
            p="0.5rem"
            fontSize="0.75rem"
            fontWeight="600"
            href="/reports"
            color={activeLink === "reports" ? "#061027" : "#6A707D"}
            onClick={() => handleLinkClick("reports")}
          >
            {/* <Image
              src="/assets/svgs/mobile-chat-inactive.svg"
              w="1.125rem"
              h="1.25rem"
              className={activeLink == "chat" ? "lightBtn" : "darkBtn"}
            /> */}
            <Image w="1rem" src={location.pathname == "/reports" ? '/assets/svgs/report-white.svg' : '/assets/svgs/reports-filled.svg'}/>
            <Text letterSpacing="-0.03em" fontSize="0.625rem">Reports</Text>
          </Link>
          <Link
            display="flex"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
            p="0.5rem"
            fontSize="0.75rem"
            fontWeight="600"
            href="/profile"
            filter={location.pathname == "/profile" ? "brightness(0) saturate(100%) invert(99%) sepia(1%) saturate(7364%) hue-rotate(186deg) brightness(111%) contrast(97%)" : " "}
            color={activeLink === "more" ? "#061027" : "#6A707D"}
            onClick={() => handleLinkClick("more")}
          >
            {/* <Image
              src="/assets/svgs/mobile-profile-inactive.svg"
              w="1.125rem"
              h="1.25rem"
              className={activeLink == "profile" ? "lightBtn" : "darkBtn"}
            /> */}
            <Image w="1.125rem" src='/assets/svgs/more-gray.svg'/>
            <Text letterSpacing="-0.03em" fontSize="0.625rem">More</Text>
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default MobileNavbar;
