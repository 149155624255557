import logo from './logo.svg';
import './App.css';
import Routing from './routing/routing'

import { ChakraProvider } from '@chakra-ui/react'
import { theme } from './components/styles/theme';

function App() {
  return (
    <div>
    <ChakraProvider theme={theme}>
    <Routing />
    </ChakraProvider>
    </div>
  );
}

export default App;
