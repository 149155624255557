import React from "react";
import {
  Box,
  Text,
  HStack,
  VStack,
  Divider,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Grid,
  Image,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { format, parseISO } from "date-fns";
import { formatMedicines } from "../../../middleware/domains/visits/patient-health/prescriptions";
import processPhysicalExamData from "../../../middleware/domains/visits/patient-health/physical-exam";

const VisitSummary = ({ doctorNotes, patientHistory }) => {
  const {
    chiefcomplaint,
    _,
    doctorsassessment,
    vitals,
    reviewofsystem,
    orders,
    info,
  } = doctorNotes;

  const physicalexam = processPhysicalExamData(doctorNotes?.physicalexam);

  const generateOptions = (followupDetail) => {
    // Ensure followupDetail is a valid object
    followupDetail = followupDetail || {};

    const mappings = {
      referraltohighercenters: "Referral to Higher Centers",
      nutritionpacksforpregnantwomen: "Nutrition packs for pregnant women",
      ecg: "ECG",
      labs: "LABs",
      usgscanningforantenatalothers: "USG Scanning for Ante Natal/others",
      ivfluids: "IV Fluids",
      injectiblesforseasonaldiseases: "Injectibles for Seasonal Diseases",
      nebulizations: "Nebulizations",
      motprocedures: "MOT Procedures",
      otherminorot: "Other Minor OT",
      ipcases: "IP Cases",
      physiotherapycare: "Physiotherapy Care",
      ambulanceservice: "Ambulance Service",
    };

    return Object.entries(mappings)
      .filter(([key]) => followupDetail[key] === 1)
      .map(([, value]) => value);
  };

  const options = generateOptions(doctorNotes?.followupdetail);

  const services = options?.length > 0;

  const {
    hospitallogo,
    bookingid,
    hospitaladdress,
    patientname,
    patientgender,
    patientage,
    patientmrnid,
    patientphone,
    appointmentdate,
    drname,
    doctorqualification,
  } = info;

  const { medication, procedure, imaging, lab } = orders;

  const prescribedMedicines = medication ? formatMedicines(medication) : [];

  // Helper to get day from date
  const visitDate = new Date(info.appointmentdate);
  const visitDay = format(visitDate, "EEEE");

  function calculateAge(dob) {
    const dobDate = new Date(dob);
    const today = new Date();

    let age = today.getFullYear() - dobDate.getFullYear();
    const monthDifference = today.getMonth() - dobDate.getMonth();
    const dayDifference = today.getDate() - dobDate.getDate();

    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }

    return age;
  }

  const generateDynamicTextForHPI = (value, patientGender, patientDob) => {
    let outputTexts = [];

    value.forEach((item, index) => {
      // Extract patient details
      let gender = patientGender || "";
      let age = calculateAge(patientDob) || "";

      // Initialize attributes
      let attributes = {};
      item.details.forEach((attribute) => {
        switch (attribute.attribute) {
          case "Site":
            attributes.site = attribute.values.map((v) => v.value).join(", ");
            break;
          case "Onset":
            attributes.onset = attribute.value;
            break;
          case "Chronicity":
            attributes.chronicity = attribute.value;
            break;
          case "Onset Quality":
            attributes.onsetQuality = attribute.value;
            break;
          case "Frequency":
            attributes.frequency = attribute.value;
            break;
          case "Associated Symptoms":
            attributes.symptoms = attribute.values
              .map((v) => v.value)
              .join(", ");
            break;
          case "Rating Scale":
            attributes.ratingScale = attribute.value;
            break;
          case "Time":
            attributes.time = attribute.value;
            break;
          case "Time Value":
            attributes.timeValue = attribute.value;
            break;
          default:
            break;
        }
      });

      // Construct patient text (only for the first item)
      let patientText =
        index === 0
          ? `The patient is a ${age}-year-old individual (${gender}) `
          : "";

      // Construct template based on available data, skipping sentences where data is missing
      let template = `${patientText}`;

      if (attributes.site) {
        template += `presenting with ${attributes.site} ${item.type}. `;
      }

      if (attributes.onset) {
        template += `The ${item.type} started ${attributes.onset} and `;
        if (attributes.chronicity) {
          template += `is described as ${attributes.chronicity} in nature. `;
        }
      }

      if (attributes.onsetQuality) {
        template += `The onset of the ${item.type} was ${attributes.onsetQuality}. `;
      }

      if (attributes.frequency) {
        template += `The ${item.type} is ${attributes.frequency} and `;
      }

      if (attributes.ratingScale) {
        template += `is rated as ${attributes.ratingScale} out of 10 in intensity. `;
      }

      if (attributes.symptoms) {
        template += `Associated with ${attributes.symptoms}.`;
      }

      // Push the constructed template to the outputTexts array
      outputTexts.push(template.trim());
    });

    return outputTexts;
  };

  const historyOfPresentIllness = generateDynamicTextForHPI(
    Array.isArray(doctorNotes?.historyofpresentillness)
      ? doctorNotes.historyofpresentillness
      : [],
    patientgender,
    patientage
  );

  const currentMedicines =
    patientHistory?.medicationForOverview?.length > 0
      ? patientHistory?.medicationForOverview.filter(
          (medicine) => medicine.iscurrentmedicine === 1
        )
      : [];

  return (
    <Box maxW="210mm" p={5} fontFamily="Times New Roman">
      {/* Header */}
      <VStack align="start" spacing={2}>
        <HStack w="full" justify="space-between">
          <Text fontSize="18px" fontWeight="bold">
            Booking ID: {bookingid}
          </Text>
          {hospitallogo && (
            <Image src={hospitallogo} alt="Hospital Logo" boxSize="50px" />
          )}
        </HStack>
        <Divider borderColor="black" />
        {hospitaladdress && (
          <HStack>
            <Image src="assets/svgs/hospital-location-on.svg" alt="Location" />
            <Text fontSize="12px">
              {hospitaladdress.address}, {hospitaladdress.city},{" "}
              {hospitaladdress.STATE}, {hospitaladdress.country}
            </Text>
          </HStack>
          //   <HStack justify="flex-start" mt={1} px={4}>
          //   <Image src="assets/svgs/hospital-location-on.svg" alt="Location" />
          //   <Text fontSize={"xs"}>{hospitalAddress}</Text>
          // </HStack>
        )}
        <Text fontSize="12px" textAlign="center" w="full" mt="2rem">
          {`Booking ID: ${bookingid}, Visit Date: ${format(
            visitDate,
            "dd MMM yyyy"
          )}, Visit Time: ${format(parseISO(doctorNotes.time), "hh:mm a")}`}
        </Text>
      </VStack>

      {/* Title */}
      <Text fontSize="24px" fontWeight="bold" mt={4} textAlign={"center"}>
        AFTER VISIT SUMMARY
      </Text>

      {/* Patient Details */}
      <VStack align="start" spacing={3} mt={4}>
        <Text fontSize="16px" fontWeight="bold">
          PATIENT DETAILS
        </Text>
        <HStack spacing={5}>
          {patientHistory?.patientinfo?.patientimage ? (
            <Image
              borderRadius="full"
              w="50px"
              h="50px"
              src={
                patientHistory?.patientinfo?.imagepath +
                patientHistory?.patientinfo?.patientimage
              }
            />
          ) : (
            <Box boxSize="50px" bg="gray.200" borderRadius="full" />
          )}{" "}
          {/* Patient image */}
          <VStack align="start" spacing={1}>
            <HStack spacing="14px">
              <Text fontSize="12px" fontWeight="semibold">
                Name: {patientname}
              </Text>
              <Text fontSize="12px" fontWeight="semibold">
                |
              </Text>
              <Text fontSize="12px" fontWeight="semibold">
                Date Of Birth: {format(new Date(patientage), "dd MMM yyyy")}
              </Text>
              <Text fontSize="12px" fontWeight="semibold">
                |
              </Text>
              <Text fontSize="12px" fontWeight="semibold">
                Gender: {patientgender}
              </Text>
            </HStack>
            <HStack spacing="14px">
              <Text fontSize="12px" fontWeight="semibold">
                Phone: {info.patientcountrycode} {patientphone}
              </Text>
              <Text fontSize="12px" fontWeight="semibold">
                |
              </Text>
              <Text fontSize="12px" fontWeight="semibold">
                MRN: {patientHistory?.patientinfo?.uniqueid}
              </Text>
            </HStack>
            {/* <Text fontSize="12px" textAlign="justify">
              {hospitaladdress.address}, {hospitaladdress.city},{" "}
              {hospitaladdress.STATE}, {hospitaladdress.country}
            </Text> */}
            {/* {hospitaladdress && (
              <HStack>
                <Image
                  src="assets/svgs/hospital-location-on.svg"
                  alt="Location"
                />
                <Text fontSize="12px" textAlign="justify">
                  {hospitaladdress.address}, {hospitaladdress.city},{" "}
                  {hospitaladdress.STATE}, {hospitaladdress.country}
                </Text>
              </HStack>
            )} */}
          </VStack>
        </HStack>
      </VStack>

      {/* This Visit */}
      <VStack align="start" spacing={2} mt={4}>
        <Text fontSize="16px" fontWeight="bold">
          THIS VISIT
        </Text>
        {/* <Text fontSize="12px" textAlign="justify">
          You saw Dr. {drname}, {doctorqualification} on <Text fontWeight="semibold" >{visitDay}{" "}
          {format(visitDate, "dd MMM yyyy")} </Text>. The following issue was addressed:{" "}
          <Text fontWeight="bold" >{chiefcomplaint.chiefcomplaint}.</Text>
        </Text> */}
        <Text fontSize="12px" textAlign="justify">
          You saw{" "}
          <Text as="span" fontWeight="semibold">
            Dr. {drname}
          </Text>
          ,{" "}
          <Text as="span" fontWeight="semibold">
            {doctorqualification}
          </Text>{" "}
          on{" "}
          <Text as="span" fontWeight="semibold">
            {visitDay}
          </Text>{" "}
          <Text as="span" fontWeight="semibold">
            {format(visitDate, "dd MMM yyyy")}
          </Text>
          . The following issue was addressed:{" "}
          <Text as="span" fontWeight="bold">
            {chiefcomplaint.chiefcomplaint}
          </Text>
          .
        </Text>
      </VStack>

      {services && (
        <VStack align="start" spacing={2} mt={4}>
          <Text fontSize="16px"
          fontWeight="bold"
          textColor={"#056cff"}
          width={"100%"}>
            SERVICES
          </Text>
          <Text
            fontSize="12px"
          >
            You have opted for the following services: {options.join(", ")}
          </Text>
        </VStack>
      )}

      {/* Progress Notes */}
      <VStack align="start" spacing={4} mt={4}>
        <Text
          fontSize="16px"
          fontWeight="bold"
          textColor={"#056cff"}
          width={"100%"}
        >
          PROGRESS NOTES
        </Text>

        <VStack align="start" width={"100%"}>
          <Text
            fontSize="14px"
            fontWeight="bold"
            bg={"#fff7f6"}
            textColor={"#a71b11"}
            width={"100%"}
          >
            HISTORY OF PRESENT ILLNESS
          </Text>
          <VStack align="start" spacing={1}>
            {historyOfPresentIllness.map((note, index) => (
              <Text
                key={index}
                fontSize="12px"
                textAlign="justify"
                mt={index !== 0 ? "1rem" : null}
              >
                {note}
              </Text>
            ))}
          </VStack>
        </VStack>

        {vitals && (
          <VStack align="start" width={"100%"}>
            <Text
              fontSize="14px"
              fontWeight="bold"
              bg={"#fff7f6"}
              textColor={"#a71b11"}
              width={"100%"}
            >
              VISIT VITALS
            </Text>
            {vitals.map((vital, index) => (
              <Text key={index} fontSize="12px" textAlign="justify">
                {vital.vitalname}: {vital.vitalvalue} {vital.vitalunit}
              </Text>
            ))}
          </VStack>
        )}

        {physicalexam && (
          <VStack align="start" width={"100%"}>
            <Text
              fontSize="14px"
              fontWeight="bold"
              bg={"#fff7f6"}
              textColor={"#a71b11"}
              width={"100%"}
            >
              PHYSICAL EXAM
            </Text>
            {physicalexam.map((exam, index) => (
              <Text key={index} fontSize="12px" textAlign="justify">
                <Text fontSize="12px" fontWeight="semibold">
                  {exam.type}:
                </Text>{" "}
                {exam.condition}
              </Text>
            ))}
          </VStack>
        )}

        {reviewofsystem && (
          <VStack align="start" width={"100%"}>
            <Text
              fontSize="14px"
              fontWeight="bold"
              bg={"#fff7f6"}
              textColor={"#a71b11"}
              width={"100%"}
            >
              REVIEW OF SYSTEM
            </Text>
            {reviewofsystem.map((ros, index) => (
              <Text key={index} fontSize="12px" textAlign="justify">
                <Text fontSize="12px" fontWeight="semibold">
                  {ros.rosname}:
                </Text>{" "}
                {ros.rosvalue.map((r) => r.value).join(", ")}
              </Text>
            ))}
          </VStack>
        )}

        {doctorsassessment && (
          <VStack align="start" width={"100%"}>
            <Text
              fontSize="14px"
              fontWeight="bold"
              bg={"#fff7f6"}
              textColor={"#a71b11"}
              width={"100%"}
            >
              ASSESSMENT PLAN
            </Text>
            {doctorsassessment.map((assessment, index) => (
              <Text key={index} fontSize="12px" textAlign="justify">
                <Text fontSize="12px" fontWeight="semibold">
                  {assessment.diagnosis}:
                </Text>{" "}
                {assessment.plan}
              </Text>
            ))}
          </VStack>
        )}

        {/* Prescribed Medicines */}
        {prescribedMedicines && (
          <VStack align="start" mt={4} fontWeight="bold" width={"100%"}>
            <Text
              fontSize="14px"
              fontWeight="bold"
              bg={"#fff7f6"}
              textColor={"#a71b11"}
              width={"100%"}
            >
              PRESCRIBED MEDICINES
            </Text>
            <Table
              variant="simple"
              width="full"
              size="sm"
              border="1px solid black"
            >
              <Thead>
                <Tr>
                  <Th fontSize="12px">Medicine</Th>
                  <Th fontSize="12px">Dose</Th>
                  <Th fontSize="12px">Timing - Freq. - Duration</Th>
                </Tr>
              </Thead>
              <Tbody>
                {prescribedMedicines.map((med, index) => (
                  <Tr key={index}>
                    <Td
                      fontWeight="bold"
                      // width={"60%"}
                      // fontSize={scaleOut ? "sm" : "md"}
                    >
                      {index + 1}. {med.name}{" "}
                      {med.dosageforprescription ? (
                        <>({med.dosageforprescription})</>
                      ) : null}
                      <Text fontSize="xs" color="gray.500" pl={3}>
                        Composition:{" "}
                        {med.chemicalcomposition
                          ? med.chemicalcomposition
                          : "-/-"}
                      </Text>
                    </Td>
                    <Td fontSize="12px">
                      {med.morning} - {med.evening} - {med.night}
                    </Td>
                    <Td fontSize="12px">
                      {med.timing} - {med.frequency}{" "}
                      {med?.duration !== -1 ? (
                        <>({med.duration} days)</>
                      ) : (
                        "Regular"
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </VStack>
        )}

        {/* Follow-Up */}
        <VStack align="start" mt={4}>
          <Text fontSize="16px" fontWeight="bold" textColor={"#056cff"}>
            FOLLOW UP
          </Text>
          <Text fontSize="12px" textAlign="justify">
            Doctor:{" "}
            {doctorNotes?.followupdetail?.referraldoctor
              ? doctorNotes?.followupdetail?.referraldoctor
              : "-"}{" "}
            | Follow-up Date:{" "}
            {info.followupdate
              ? format(new Date(info.followupdate), "dd MMM yyyy")
              : "N/A"}{" "}
            | Free Visit:{" "}
            {doctorNotes?.followupdetail?.isfreevisit ? "Yes" : "No"}
          </Text>
        </VStack>

        <VStack align="start" spacing={4} mt={4} width={"100%"}>
          <Text fontSize="16px" fontWeight="bold" textColor={"#056cff"}>
            HISTORY
          </Text>

          {patientHistory?.surgicalhistory?.length > 0 ? (
            <VStack align="start" width={"100%"}>
              <Text
                fontSize="14px"
                fontWeight="bold"
                bg={"#fff7f6"}
                textColor={"#a71b11"}
                width={"100%"}
              >
                SURGICAL PROCEDURES
              </Text>
              <UnorderedList spacing={2}>
                {patientHistory?.surgicalhistory.map((surgery) => (
                  <ListItem
                    key={surgery.id}
                    fontSize="12px"
                    textAlign="justify"
                  >
                    <Text as="span" fontWeight="semibold">
                      {surgery.surgeryName}
                    </Text>
                    {surgery.doctorName && surgery.hospital ? (
                      <>
                        {" "}
                        performed by {surgery.doctorName} at {surgery.hospital}{" "}
                        on{" "}
                        <Text as="span">
                          {new Date(surgery.date).toLocaleDateString()}
                        </Text>
                      </>
                    ) : surgery.doctorName ? (
                      <>
                        {" "}
                        performed by {surgery.doctorName} on{" "}
                        <Text as="span">
                          {new Date(surgery.date).toLocaleDateString()}
                        </Text>
                      </>
                    ) : surgery.hospital ? (
                      <>
                        {" "}
                        at {surgery.hospital} on{" "}
                        <Text as="span">
                          {new Date(surgery.date).toLocaleDateString()}
                        </Text>
                      </>
                    ) : (
                      <Text as="span">
                        {" "}
                        on {new Date(surgery.date).toLocaleDateString()}
                      </Text>
                    )}
                  </ListItem>
                ))}
              </UnorderedList>
            </VStack>
          ) : null}

          {patientHistory?.medicalhistory?.length > 0 ? (
            <VStack align="start" width={"100%"}>
              <Text
                fontSize="14px"
                fontWeight="bold"
                bg={"#fff7f6"}
                textColor={"#a71b11"}
                width={"100%"}
              >
                MEDICAL CONDITIONS
              </Text>
              <UnorderedList spacing={2}>
                {patientHistory?.medicalhistory.map((condition) => (
                  <ListItem
                    key={condition.id}
                    fontSize="12px"
                    textAlign="justify"
                  >
                    <Text as="span" fontWeight="semibold">
                      {condition.condition}
                    </Text>
                    {condition.conditiontype ? (
                      <>
                        {" "}
                        belonging to type {condition.conditiontype} identified
                        on{" "}
                        <Text as="span">
                          {new Date(
                            condition.conditionidentifieddate
                          ).toLocaleDateString()}
                        </Text>
                      </>
                    ) : (
                      <>
                        {" "}
                        identified on{" "}
                        <Text as="span">
                          {new Date(
                            condition.conditionidentifieddate
                          ).toLocaleDateString()}
                        </Text>
                      </>
                    )}
                  </ListItem>
                ))}
              </UnorderedList>
            </VStack>
          ) : null}

          {patientHistory?.allergy?.length > 0 ? (
            <VStack align="start" width={"100%"}>
              <Text
                fontSize="14px"
                fontWeight="bold"
                bg={"#fff7f6"}
                textColor={"#a71b11"}
                width={"100%"}
              >
                ALLERGIES
              </Text>
              <UnorderedList spacing={2}>
                {patientHistory?.allergy.map((allergy) => (
                  <ListItem
                    key={allergy.id}
                    fontSize="12px"
                    textAlign="justify"
                  >
                    From{" "}
                    <Text as="span" fontWeight="semibold">
                      {allergy.allergyFrom}
                    </Text>
                    , problem{" "}
                    <Text as="span" fontWeight="semibold">
                      {allergy.problems}
                    </Text>{" "}
                    onset on{" "}
                    <Text as="span">
                      {new Date(allergy.onSet).toLocaleDateString()}
                    </Text>
                    .
                  </ListItem>
                ))}
              </UnorderedList>
            </VStack>
          ) : null}

          {currentMedicines.length > 0 ? (
            <VStack align="start" width={"100%"}>
              <Text
                fontSize="14px"
                fontWeight="bold"
                bg={"#fff7f6"}
                textColor={"#a71b11"}
                width={"100%"}
              >
                CURRENT MEDICATIONS
              </Text>
              {currentMedicines.map((medicine) => {
                const drName = medicine.prescribedby;
                const hospitalName = medicine.prescribedhospitalname;

                return (
                  <Text
                    key={medicine.id}
                    fontSize="12px"
                    textAlign="justify"
                    marginBottom={2}
                  >
                    <Text as="span" fontWeight="semibold">
                      {medicine.medicinetype && medicine.medicinetype + ":"}{" "}
                      {medicine.medicinename}
                    </Text>{" "}
                    {medicine.chemicalcomposition ? (
                      <Text as="span">,{medicine.chemicalcomposition}</Text>
                    ) : null}
                    {drName && (
                      <span>
                        {" "}
                        Prescribed by {drName}
                        {hospitalName && " at "}
                        {hospitalName}.
                      </span>
                    )}
                  </Text>
                );
              })}
            </VStack>
          ) : null}
        </VStack>

        <HStack align="center" mt="2.5rem" spacing="8px" mx="auto">
          <Text fontSize="12px" textAlign="center">
            Summary generated by
          </Text>
          <Image
            src="assets/svgs/miDocLogo.svg"
            alt="miDoc Logo"
            height="40px"
          />
        </HStack>
      </VStack>
    </Box>
  );
};

export default VisitSummary;
