import moment from "moment";

import formAPIModelAllergiesForFamilyHistory from "../familyhistoryallergies/allergies";
import formAPIModelMedicalCondition from '../medicalconditions/medicalconditions.';
export default function formAPIfamilyhistory({
  familyid,
  patientid,
  firstname,
  lastname,
  fullname,
  mobileno,
  email,
  age,
  height,
  heightunit_cd,
  weight,
  weightunit_cd,
  dob,
  bloodtype_cd,
  vaccinated,
  gender_cd,
  relation_cd,
  maritalstatus_cd,
  countrycode,
  countryid,
  gender,
  relation,
  maritalstatus,
  familyimage,
  imagepath,
  medicalcondition,
  medications,
  allergies,
  isadopted,
  isdeceased, 

})
{ 
  return Object.freeze({
    familyid,
  patientid,
  firstname,
  lastname,
  fullname,
  mobileno,
  email,
  age,
  height,
  heightunit_cd,
  weight,
  weightunit_cd,
  dob,
  bloodtype_cd,
  vaccinated,
  gender_cd,
  relation_cd,
  maritalstatus_cd,
  countrycode,
  countryid,
  gender,
  relation,
  maritalstatus,
  familyimage,
  imagepath,
medicalcondition : medicalcondition !== null ? formAPIModelMedicalCondition(medicalcondition) : [],
allergies : allergies !== null ? formAPIModelAllergiesForFamilyHistory(allergies) : [],
  medications,
  isadopted,
  isdeceased,
  });
}