import moment from "moment";
export function ProfileAPIModel({
  id,
  doctorid,
  firstname,
  lastname,
  fullname,
  email,
  password,
  epassword,
  masterpassword,
  gender,
  countrycode,
  mobileno,
  Preferredlang,
  specality,
  specialistname,
  ratings,
  yearsofexperience,
  lastlogindate,
  logoutdatetime,
  qualification,
  latitude,
  longitude,
  postalcode,
  dob,
  imagepath,
  profilepictureurl,
  isavailable,
  hospitalname,
  countryid,
  isdoctor,
  hospitallogourl,
  preferredcurrency,
  isglobaladmin,
}) {
  const formattedDate = moment.utc(lastlogindate).local().format("YYYY-MM-DD HH:mm");

  return Object.freeze({
    id,
    doctorid,
    firstname,
    lastname,
    fullname,
    email,
    password,
    epassword,
    masterpassword,
    gender,
    countrycode,
    mobileno,
    Preferredlang,
    specality,
    specialistname,
    ratings,
    yearsofexperience,
    lastlogindate: formattedDate,
    logoutdatetime,
    qualification,
    latitude,
    longitude,
    postalcode,
    dob,
    imagepath,
  profilepictureurl,
  isavailable,
  hospitalname,
  countryid,
  isdoctor,
  hospitallogourl,
  preferredcurrency,
  isglobaladmin
  });
}

export function AddressProfileAPIModel({
  id,
  doctoraddressid,
  doctorid,
  address1,
  address2,
  latitude,
  longitude,
  postalcode,
  pincode,
  city,
  state,
  country,
  countrycode,
  createddate,
  modifieddate,
  isactive,
}) {
  return Object.freeze({
    id,
    doctoraddressid,
    doctorid,
    address1,
    address2,
    latitude,
    longitude,
    postalcode,
    pincode,
    city,
    state,
    country,
    countrycode,
    createddate,
    modifieddate,
    isactive,
  });
}


export function FeeStructureAPIModel({
  doctorid,
  fee,
  paymenttypeid,
  description
}) {
  return Object.freeze({
    doctorid,
    fee,
    paymenttypeid,
    description
  });
}

export function resultbranchAPIModel({
  branchid,
  firstname,
  lastname,
  clinicname,
  mobileno,
  email,
  createddate,
  modifieddate,
  addressid,
  address,
  address2,
  city,
  state,
  country,
  postalcode,
  countrycode,
  latitude,
  longitude,
  dob,
  gender,
}) {
  return Object.freeze({
    branchid,
  firstname,
  lastname,
  clinicname,
  mobileno,
  email,
  createddate,
  modifieddate,
  addressid,
  address,
  address2,
  city,
  state,
  country,
  postalcode,
  countrycode,
  latitude,
  longitude,
  dob,
  gender,
  });
}

export function DocumentProfileAPIModel({
  doctorid,
  documentcategory_cd,
  documentdate,
  documentname,
  documenttype,
  id,
  documenturl,
  imagepath,
  documentsize
}) { 
  return Object.freeze({
    doctorid,
    documentcategory_cd,
    documentdate,
    documentname,
    documenttype,
    id,
    documenturl,
    imagepath,
    documentsize
  });
}

export function SubscriptionProfileAPIModel({
  subscriptionplanid,
  subscriptionname,
  subscriptiondescription,
  subscriptionprice,
  subscriptionpriceunit,
  subscriptionstartdate,
  subscriptionenddate,
  renewaldate,
  duration,
  durationinmonths,
  cancelplan
}) {
  const formattedDateSince = moment.utc(subscriptionstartdate).local().format("Do MMMM YYYY");
  const formattedDateRenewal = moment.utc(renewaldate).local().format("Do MMMM YYYY");


  return Object.freeze({
    subscriptionplanid,
    subscriptionname,
    subscriptiondescription,
    subscriptionprice,
    subscriptionpriceunit,
    subscriptionstartdate: formattedDateSince,
    subscriptionenddate,
    renewaldate: formattedDateRenewal,
    duration,
    durationinmonths,
    cancelplan
  });
}

export function InvoiceProfileAPIModel({
  isactive,
  id,
  doctorid,
  createddate,
  amount,
  paymenttype,
  imagepath,
  invoiceurl
}) {
 
  return Object.freeze({
  isactive,
  id,
  doctorid,
  createddate,
  amount,
  paymenttype,
  imagepath,
  invoiceurl
  });
}

export function SpecialityProfileAPIModel({
  doctorid,
  specialtyid,
  specialityname,
  othervalue,
  imagepath,
  url
}) {
 
  return Object.freeze({
    doctorid,
    specialtyid,
    specialityname,
    othervalue,
    imagepath,
    url
  });
}
