import formapimodalpatientsview from "./patientViews";

export default function formapimodalpatientsPagination({
    data,
    pageno,
    pagesize,
    count
  })
  {
  return Object.freeze({
    
    data:data !== null ? formapimodalpatientsview(data) : [],
    pageno,
    pagesize,
    count,
  });
  }