import React, { useEffect } from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Toaster } from "../../../components";
import moment from 'moment';

const ExcelDownloadMonthly3 = ({ data, onDownloadComplete }) => {
  const { showSuccessToast, showErrorToast } = Toaster();
  const currentDate = new Date(); // Get the current date
  const options = { month: 'long', year: 'numeric' }; // Define formatting options
  const currentMonthYear = currentDate.toLocaleDateString('en-US', options); // Format as "Month Year"

  
  useEffect(() => {
     handleDownload(data);
    // console.log(data , " data to Download  month 3 ")
  }, [data]);

  // code woing fine here , with total calculation
  const handleDownload = (exceldata) => {
    if (!exceldata || Object.keys(exceldata).length === 0) {
      console.error("No data to download.");
      showErrorToast("No Data Available!");
      onDownloadComplete();
      return;
    }
  
    const workbook = XLSX.utils.book_new();
    let hasData = false;
  
    // Format the data dynamically for the Excel sheet
  
    const prepareSheetData = (data) => {
      const sheetData = [];
      const merges = []; // Array to hold merge ranges
    
      const allBranches = []; // Initialize an empty array to store branch names
      const branchCounts = {}; // Object to track branch name occurrences
      
      if (data?.length > 0) {
        const firstDiagnose = data[0]; // Access the first object in the data array
        
        firstDiagnose?.branches?.forEach((branch) => {
          // Track occurrences of branch names
          if (branchCounts[branch?.branchname]) {
            branchCounts[branch?.branchname] += 1;
          } else {
            branchCounts[branch?.branchname] = 1;
          }
          
          // If a branch name has appeared more than once, add the suffix
          let branchName = branch?.branchname;
          if (branchCounts[branchName] > 1) {
            branchName = `${branch?.branchname} (${branchCounts[branchName]})`;
          }
          
          allBranches.push(branchName); // Add the branch name to the list
        });
      }
      
      // Convert branch names to a sorted array for column consistency
      const branchColumns = Array.from(allBranches).sort((a, b) => {
        const numA = parseInt(a.replace(/\D/g, ""));
        const numB = parseInt(b.replace(/\D/g, ""));
        return numA - numB; // Sort numerically
      });
    
      // Prepare the header row
      const reportName = [`Monthly Report for Adjuvant Services provisioned for ${currentMonthYear}`];
      const emptyRow = [""];
      sheetData.push(reportName);
      merges.push({ s: { r: 0, c: 0 }, e: { r: 0, c: 6 } });
    
      sheetData.push(emptyRow);
      merges.push({ s: { r: 1, c: 0 }, e: { r: 1, c: 6 } });
    
      const header = ["Diagnose", ...allBranches, "Total"];
      sheetData.push(header);
    
      // Initialize column totals array
      const columnTotals = Array(allBranches.length).fill(0);
      let grandTotal = 0; // Total of all totals across rows
    
      // Calculate totals for all branches
      data?.forEach((diagnose) => {
        diagnose?.branches?.forEach((branch) => {
          const branchIndex = allBranches.indexOf(branch?.branchname);
          if (branchIndex !== -1) {
            columnTotals[branchIndex] += branch?.value || 0;
          }
          grandTotal += branch?.value || 0;
        });
      });
    
      // Add the totals row just below the header
      const totalsRow = ["Total Patients Services Provisioned", ...columnTotals, grandTotal];
      sheetData.push(totalsRow);
    
      // Prepare the individual rows
      data?.forEach((diagnose) => {
        const row = [diagnose.name];
        let rowTotal = 0; // Initialize the row total
    
        // Add branch values in the correct column order and calculate row totals
        allBranches?.forEach((branch) => {
          const branchData = diagnose?.branches.find((b) => b.branchname === branch);
          const branchValue = branchData ? branchData.value : 0;
          rowTotal += branchValue;
          row.push(branchValue);
        });
    
        // Add the row total
        row.push(rowTotal);
        sheetData.push(row);
      });
    
      // Calculate column widths dynamically based on text length
      const columnWidths = sheetData[0].map((_, colIndex) => {
        let maxLength = Math.max(
          ...sheetData.map((row) => row[colIndex]?.toString()?.length || 0) // Get length of longest cell in column
        );
        
        if (maxLength < 5) maxLength = 5; // Minimum width for small text
        return { wch: maxLength + 2 }; // Add extra space for readability
      });
    
      // Ensure that the first two columns (Diagnose and Sub-Diagnose) have a minimum width
      columnWidths[0] = { wch: Math.max(columnWidths[0].wch, 20) }; // Diagnose column
      
      return { sheetData, merges, columnWidths };
    };
    
    
    
  
  
  
  
    
  
    // Generate the data and merges for the sheet
    const { sheetData, merges } = prepareSheetData(exceldata);
  
    // Convert to worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
  
    // Apply merges to the worksheet
    worksheet["!merges"] = merges;
  
    // Set column widths dynamically
    worksheet["!cols"] = [
      { wch: 20 }, // First column width (Diagnose)
      { wch: 30 }, // Second column width (Sub-Diagnose)
      ...Array(sheetData[2].length - 2).fill({ wch: 15 }), // Branch columns and Total
    ];
  
    // Add worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet);
  
    hasData = true;
  
    if (!hasData) {
      showErrorToast("No Data Available!");
      onDownloadComplete();
      return;
    }
  
    // Write to Excel file
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(dataBlob, `Monthly Report for Adjuvant Services provisioned for ${currentMonthYear}.xlsx`);
  
    showSuccessToast("Download successful!");
    onDownloadComplete();
  };  
  
  
  
  
  
  

  return null;
};

export default ExcelDownloadMonthly3;
