import  FetchData, { } from '../../client';
import {getLoginID}  from '../../../auth';
// import axios from 'axios';
// import { getToken } from '../../../auth';


// const CreatePaymentIntent = async (apiEndpoint, inputData) => {
//   // inputData.patientid = getLoginInfo();
//   // inputData.activeuserid = getUserID();
//   try {
//     // const loginid = localStorage.getItem("loginid");
//     const token = getToken();
//     const headers = {
//       'Content-Type': 'application/json',
//     };
//     if (token) {
//       headers['Authorization'] = `Bearer ${token}`;
//     }
//     const response = await axios.post(`http://localhost:5252/create-payment-intent`, 
//     {
//       inputData,
//     }, 
//     {
//       headers: headers,
//     });
//     var res = await response.json();
//     return res;

//   } catch (error) {
//     console.error('Error fetching data:', error);
//     throw error;
//   }
// };
  const CreatePaymentIntent = async (inputData) => {
    try {
      console.log(inputData)
      // inputData.patientid = getLoginInfo();
      // inputData.activeuserid = getUserID();
      // to change API Name here
      const result = await FetchData("midoc_create_payment_intent", inputData);
     // console.log(result , " payment Intent res ")
      return result;
  
    } catch (error) {
      console.error('Error payment initial call :', error);
      throw error;
    }
  };
  const SavePaymentInitialCall = async (inputData, doctorid) => {
    try {
      if(doctorid){
        inputData.doctorid = doctorid;
      }else{
        inputData.doctorid = getLoginID();
      }
      
      // to change API Name here
      const result = await FetchData("midoc_initializepayment", inputData);
      //console.log(result , " db response payment initial call ")
      return result;
  
    } catch (error) {
      console.error('Error payment initial call :', error);
      throw error;
    }
  };
  const SavePayment = async (inputData, doctorid) => {
    try {
      console.log(doctorid)
      if(doctorid){
        inputData.doctorid = doctorid;
      }else{
        inputData.doctorid = getLoginID();
      }
      // to change API Name here
      const result = await FetchData("midoc_updatepaymentdoctor", inputData);
      //console.log(result , " db response payment final call ")
      return result;
  
    } catch (error) {
      console.error('Error payment final call :', error);
      throw error;
    }
  };

export {CreatePaymentIntent, SavePaymentInitialCall, SavePayment};
