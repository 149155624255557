import FetchData from "../../client";
import { getDoctorId, getLoginID } from "../../../auth";
import {
  formapimodalappointment,
  formapimodalappointmentdetails,
} from "../../domains/visits/visits";

const getPatientAppointmentListForDoctor = async (inputData) => {
  try {
    // console.log(getDoctorId());
    // console.log(getLoginID());
    inputData.doctorid = getLoginID();
    const result = await FetchData(
      "midoc_getpatientappointmentlistfordoctor",
      inputData
    );
    // console.log(result);

    if (result.output.data) {
      let appointments = result.output.data;
      // console.log(appointments.length);
      let finalOutput = appointments.map(formapimodalappointment);
      return finalOutput;
    }
  } catch (error) {
    console.error("Error fetching data in visits.js:", error);
    throw error;
  }
};

const getPatientAppointmentDetails = async (inputData) => {
  try {
    // console.log(getDoctorId());
    // console.log(getLoginID());
    inputData.doctorid = getLoginID();
    const result = await FetchData("midoc_getvisitdetail", inputData);
    // console.log(result);

    if (result.output.data) {
      let appointmentDetail = formapimodalappointmentdetails(
        result.output.data
      );
      return appointmentDetail;
    }
  } catch (error) {
    console.error("Error fetching data in visits.js:", error);
    throw error;
  }
};

const UpdateAppointmentByDoctor = async (inputData) => {
  try {
    const result = await FetchData("midoc_doctorupdateappointment", inputData);
    // console.log("api", result);

    return result;
  } catch (error) {
    console.error("Error fetching data in visits.js:", error);
    throw error;
  }
};

const CreateAppointmentByDoctor = async (inputData) => {
  try {
    const result = await FetchData("midoc_bookappointment", inputData);
    // console.log("api", result);

    return result;
  } catch (error) {
    console.error("Error fetching data in visits.js:", error);
    throw error;
  }
};

export {
  getPatientAppointmentListForDoctor,
  getPatientAppointmentDetails,
  UpdateAppointmentByDoctor,
  CreateAppointmentByDoctor,
};
