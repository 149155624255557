import { Box, Center, Circle, Divider, Flex, Image, ListItem, SimpleGrid, Spacer, Stack, Text, UnorderedList, VStack,useBreakpointValue } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import MiniSideBar from '../../components/mini-sidebar'
import SecondaryNav from '../../components/seconday-nav'
import SideBar from '../../components/sidebar'

import GrayCard from '../../components/card'
import {
    BrowserRouter as Router,
    generatePath,
    Routes,
    Route,
    useNavigate,
    useParams,
    useLocation,
  } from "react-router-dom";
import { FetchPatientDetails } from '../../middleware/services/messages'
import { P } from '@antv/g2plot'
import moment from 'moment'
import SimpleTable from "../../components/simple-table";

import VisibleSidebar from '../../components/visible-sidebar'
import PatientVisitsIndex from './patientvisits'
import PatientVitalsIndex from './patientvitals'
import MedicalHistory from './medicalhistory'
import SurgicalHistory from './surgicalhistory'
import FamilyHistory from './familyhistory'
import SocialHistory from './socialhistory'
import PhysicalExam from './physicalexam'
// import TreatmentPlan from './treatmentplan'
import DoctorAssessment from './doctorassessment'
import ReviewSystem from './reviewsystem'
import Allergies from './allergies'
import Vaccinations from './vaccinations'
import Investigations from './investigations'
import LabTests from './labtest'
import OverviewIndex from './overview'
import ChiefComplaint from './chiefcomplaint'
import HistoryOfPresentIllness from './historyofpresentillness'
import OrdersIndex from './orders'
import MobileSecondaryNav from '../../components/mobile-secondary-navbar'
import MedicationIndex from './medications'
import Imaging from './imaging'
import { PrimaryInput } from '../../components'
import useLoader from '../../components/loader/loaderstates'
import { actionAccessMain } from '../../auth'


const PatientProfileIndex = () => {
  const navigate = useNavigate();
  
  
  const location = useLocation();
    const [activeSection, setActiveSection] = useState(null)
    const [backNavigate, setBackNavigate] = useState(null)
    const [patientId, setPatientId] = useState(null);
    const [patienData, setPatienData] = useState(null);
    const [screenType, setScreenType] = useState('web');
    const [mobileActive, setMobileActive] = useState(null)

    const { isLoading, showLoader, dismissLoader } = useLoader();
  const [actionAccessPatients , setActionAccessPatients] = useState(null);
    useEffect(() => {
      setActionAccessPatients(actionAccessMain(4))
      const handleResize = () => {
        if (window.innerWidth < 768) {
          setScreenType('mobile');
        } else {
          setScreenType('web');
        }
      };
  
      handleResize(); // Initial check
      window.addEventListener('resize', handleResize);
  
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    

    const dataTable = {
        "White blood cells": "",
        Hemoglobin: "",
        Hematocrit: "",
        "Platelet count": "",
        Chloride: "",
        Sodium: "",
        Potassium: "",
        CO2: "",
        BUN: "",
        Creatinine: "",
        Glucose: "",
      };
    
      const dataEntries = Object.entries(dataTable);


      const formatDate = (dateString) => {
        if (!dateString) return "-";
        return moment(dateString).format("Do MMM, YYYY");
      };
      const formatTime = (time) => {
        if (!time) {
          return ""; 
        }

        const [hours, minutes] = time.split(':');
      
        const date = new Date();
        date.setHours(hours, minutes);
      
        const formattedTime = date.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });
      
        return formattedTime;
      };


      const navItems = [
        {
          to: "overview",
          iconSrc: "/assets/imgs/overview.png",
          text: "Overview",
        },
        {
          to: "visits",
          iconSrc: "/assets/imgs/visit.png",
          text: "Visits",
        },
        {
          to: "chiefComplaint",
          iconSrc: "/assets/imgs/cheif-complaint.png",
          text: "Chief Complaint",
        },
        {
          to: "historyOfPresentIllness",
          iconSrc: "/assets/svgs/coronavirus.svg",
          text: "History of Present Illness",
        },
        {
          to: "orders",
          iconSrc: "/assets/svgs/clinical_notes.svg",
          text: "Orders",
        },
        {
          to: "medications",
          iconSrc: "/assets/svgs/clinical_notes.svg",
          text: "Medications",
        },
        {
          to: "vitals",
          iconSrc: "/assets/imgs/heart.png",
          text: "Vitals",
        },
        {
          to: "medicalHistory",
          iconSrc: "/assets/imgs/medical-history.png",
          text: "Medical history",
        },
        {
          to: "surgicalHistory",
          iconSrc: "/assets/imgs/surgeries.png",
          text: "Surgical history",
        },
        {
          to: "familyHistory",
          iconSrc: "/assets/imgs/family-history.png",
          text: "Family history",
        },
        {
          to: "socialHistory",
          iconSrc: "/assets/imgs/social-history.png",
          text: "Social history",
        },
        {
          to: "physicalExam",
          iconSrc: "/assets/imgs/physical-exam.png",
          text: "Physical exam",
        },
        // {
        //   to: "treatmentPlan",
        //   iconSrc: "/assets/imgs/treatment-plan.png",
        //   text: "Treatment plan",
        // },
        {
          to: "doctorAssessment",
          iconSrc: "/assets/imgs/treatment-plan.png",
          text: "Doctor Assessment",
        },
        {
          to: "reviewSystem",
          iconSrc: "/assets/imgs/protection.png",
          text: "Review of systems",
        },
        {
          to: "allergies",
          iconSrc: "/assets/imgs/allergy.png",
          text: "Allergies",
        },
        {
          to: "vaccinations",
          iconSrc: "/assets/imgs/syringe.png",
          text: "Vaccinations",
        },
        {
          to: "investigations",
          iconSrc: "/assets/imgs/investigation.png",
          text: "Investigations",
        },
        {
          to: "labTest",
          iconSrc: "/assets/imgs/biotech.png",
          text: "Lab tests",
        },
        {
          to: "imaging",
          iconSrc: "/assets/imgs/hand_bones.png",
          text: "Imaging",
        },
      ];
    const renderComponent = (sectionId)=> {
      // console.log('sectionID: ', sectionId)
      setActiveSection(sectionId)
    }
    
    useEffect(() => {
      const currentUrl = window.location.href
      const searchParams = new URLSearchParams(currentUrl.split('?')[1]);
      const id = searchParams.get('id');
      const pageUrl = searchParams.get('page');

      setPatientId(id);
      if(screenType == 'web'){
        setActiveSection(pageUrl)
      }
      fetchpatientdata(id)   
      localStorage.setItem("patientid" , id)
    }, []);
    
    const fetchpatientdata = async (id) => {
          try {
            const result = await FetchPatientDetails({
              patientid: id,
            });

            if (result != null) {
              setPatienData(result);
            } else {
              setPatienData(null)
            }
        } catch (error) {
            console.error("Error in home:", error);
          }
        };

        // console.log(patientId, "IDDD")
        // console.log(patienData, "DAAAAAAA")


        const formatDateTime = (dateTimeString) => {
          const date = new Date(dateTimeString);
        
          let hours = date.getHours();
          const minutes = date.getMinutes();
          const ampm = hours >= 12 ? 'PM' : 'AM';
        
          hours = hours % 12;
          hours = hours ? hours : 12; // the hour '0' should be '12'
        
          const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
        
          const formattedTime = hours + ':' + formattedMinutes + ' ' + ampm;
          return formattedTime;
        };


        const formatDateAndTime = (dateTimeString) => {
          const date = new Date(dateTimeString);
          
          const formattedTime = formatDateTime(dateTimeString);
          return ` ${formattedTime}`;
        };

        const backFunction = () => {
          setActiveSection(null); // Ensure the section resets
          navigate('/patientprofile?id=' + patientId);
        };

        const GenericUpload = async (e) => {
          e.preventDefault();
          const file = e.target.files[0];
          showLoader();
          navigate("/docupload", { state: { file } });
          dismissLoader();
        };


        const navigateBack =()=>{
          let previousPage = localStorage.getItem("previousPage");
          if(previousPage){
            navigate(`/${previousPage}`);
          }else{
            navigate("/patients");
          }
        };

  return (
    <>
    {screenType == 'web' ? (

    
      <Box w="100%">
        <Flex>
          <Box w="6rem">
            <MiniSideBar />
          </Box>
          <Box w="100%">
            <SecondaryNav customHeader={<>
                <Box as='button' display="flex" alignItems="center" gap="1rem">
                    <Image src="/assets/imgs/back-arrow.png"
                    onClick={navigateBack}
                    />
                    <Text fontSize="1.25rem" fontWeight="600">Patient profile</Text>
                </Box>
            </>} />
            {/* Main container start */}
            <Flex w="calc(100vw - 6rem)">
              <Box
                // p="1.5rem 8.25rem"
                display="flex"
                // h="calc(100vh - 50px)"
                // overflow="hidden auto"
                w="100%"
              >
                 
                {/* Left area start */}
                <Box w={{lg: "13rem", xl: "16rem"}} h="calc(100vh - 75px)" overflow="hidden auto" borderRight="1px solid #E6E7E9">
                <VisibleSidebar navItems={navItems}  activeSection={activeSection} onNavItemClick={renderComponent} isLogout={false} visibleSidebarStyle={{minW: "unset", w: "100%"}} />
                </Box>
                {/* Left area end */}

                {/* Main container start */}
                <Box w={{lg: "calc(100vw - 28rem)", xl: "calc(100vw - 36rem)"}} bg="#ffffff" overflow="hidden">
                <Box flexDir="column" p={activeSection == 'orders' || activeSection == 'vaccinations' || activeSection == "socialHistory" || activeSection == 'allergies' || activeSection == "labTest" || activeSection == "medicalHistory" || activeSection == "surgicalHistory" || activeSection == "medications" || activeSection == "familyHistory" ? '0' : "1.5rem"} bg="#fcfcfd" h={activeSection == 'vaccinations' || activeSection == 'allergies' || activeSection == "labTest" || activeSection == "socialHistory" || activeSection == "medicalHistory" || activeSection == "surgicalHistory" || activeSection == "medications" || activeSection == "familyHistory" ? '' : "calc(100vh - 75px)"} w="100%" overflow="hidden auto">
                    <Flex>

                    {activeSection == "overview" && (
                        <OverviewIndex pid={patientId} />
                      )}
                        {activeSection == "visits" && (
                          <PatientVisitsIndex id={patientId} />
                        )}

                        {activeSection == "chiefComplaint" && (
                          <ChiefComplaint id={patientId} />
                        )}

                        {activeSection == "historyOfPresentIllness" && (
                          <HistoryOfPresentIllness id={patientId} />
                        )}

                        {activeSection == "orders" && (
                          <OrdersIndex id={patientId} />
                        )}

                        {activeSection == "medications" && (
                          <MedicationIndex id={patientId} />
                        )}

                        {activeSection == "vitals" && (
                          <PatientVitalsIndex id={patientId} />
                        )}

                        {activeSection == "medicalHistory" && (
                          <MedicalHistory id={patientId} />
                        )}

                        {activeSection == "surgicalHistory" && (
                          <SurgicalHistory id={patientId} />
                        )}

                        {activeSection == "familyHistory" && (
                          <FamilyHistory id={patientId} />
                        )}

                        {activeSection == "socialHistory" && (
                          <SocialHistory id={patientId} />
                        )}
                        
                        {activeSection == "physicalExam" && (
                          <PhysicalExam id={patientId} />
                        )}

                        {/* {activeSection == "treatmentPlan" && (
                          <TreatmentPlan id={patientId} />
                        )} */}
                        {activeSection == "doctorAssessment" && (
                          <DoctorAssessment id={patientId} />
                        )}

                        {activeSection == "reviewSystem" && (
                          <ReviewSystem id={patientId} />
                        )}
                        {activeSection == "allergies" && (
                          <Allergies id={patientId} />
                        )}
                        {activeSection == "vaccinations" && (
                          <Vaccinations id={patientId} />
                        )}
                        {activeSection == "investigations" && (
                          <Investigations id={patientId} />
                        )}
                        {activeSection == "labTest" && (
                          <LabTests id={patientId} />
                        )}
                        {activeSection == "imaging" && (
                          <Imaging id={patientId} />
                        )}

                    </Flex>
                </Box>
                </Box>
                {/* Main container end */}

                {/* Right area start */}
                    {/* Recent Activity start */}


                    <Box w={{lg: "15rem", xl: "20rem"}} borderLeft="1px solid #E6E7E9">
                    {
                        patienData && patienData.patientinfo && (

                        <VStack p="1.5rem" align="stretch" gap="1rem" overflow="hidden auto" bg="#fcfcfd" h="100vh">
                            <Box>
                                <Box display="flex" flexDir="column" gap="0.65rem">
                                    <Box w="5rem" h="5rem">
                                        <Image 
                                        w="100%" 
                                        h="100%"
                                        borderRadius="full"
                                        src={
                                            patienData.patientinfo.patientimage? 
                                            (`${patienData.patientinfo.imagepath}${patienData.patientinfo.patientimage}`) : ("/assets/imgs/no-image.png")
                                        }
                                            
                                        />
                                        </Box>
                                    <Box display="flex" flexDir="column">
                                        <Text fontSize="1rem" fontWeight="600">
                                            {/* Dr. Samantha Raulf */}
                                            {`${patienData.patientinfo.firstname}  ${patienData.patientinfo.lastname}`}
                                            </Text>
                                        <Box display="flex" gap="0.5rem">
                                            <Image src="/assets/svgs/calendar-filled.svg" />
                                            <Text fontSize="0.75rem" fontWeight="400" color="#384052">
                                              {/* Upcoming: 10th Jan, 2024 at 9:30 AM */}

                                              {patienData.patientinfo.nextappointmentdate && patienData.patientinfo.nextappointmenttime ? (
                                              `Upcoming: ${formatDate(patienData.patientinfo.nextappointmentdate)} at ${formatTime(patienData.patientinfo.nextappointmenttime)}`
                                              ) : (
                                                "-"
                                              )}

                                              {/* {`${formatDate(patienData.patientinfo.lastupdatedate)} at ${formatDateAndTime(patienData.patientinfo.lastupdatedate)}`} */}
                                              </Text>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>   
                            <Divider />
                            <Stack direction="column" gap="0.75rem" w="100%">
                                <Box display="flex" alignItems="center">
                                <Image src="/assets/svgs/name.svg" />
                                <Text fontSize="0.875rem" fontWeight="500" ml="0.5rem">
                                    {/* Adnan Jaweed */}
                                    {`${patienData.patientinfo.firstname}  ${patienData.patientinfo.lastname}`}
                                </Text>
                                </Box>

                                <Box display="flex" alignItems="center">
                                <Image src="/assets/svgs/email.svg" />
                                <Text fontSize="0.875rem" fontWeight="500" ml="0.5rem">
                                    {/* adnan@supastellar.co */}
                                    {patienData.patientinfo.email}
                                </Text>
                                </Box>

                                <Box display="flex" alignItems="center">
                                <Image src="/assets/svgs/phone-number.svg" />
                                <Text fontSize="0.875rem" fontWeight="500" ml="0.5rem">
                                    {/* +1 (647) 523-9797 */}
                                    {/* {`${patienData.patientinfo.countrycode} ${patienData.patientinfo.mobileno}`} */}
                                    {patienData &&
                              patienData.patientinfo &&
                              patienData.patientinfo.mobileno
                                ? (`${patienData.patientinfo.countrycode} ${"  "}${patienData.patientinfo.mobileno}`)
                                : "-"}
                                </Text>
                                </Box>

                                <Box display="flex" alignItems="center">
                                <Image src="/assets/svgs/gender.svg" />
                                <Text fontSize="0.875rem" fontWeight="500" ml="0.5rem">
                                    {/* Male */}
                                    {patienData.patientinfo.gender ? patienData.patientinfo.gender : ('-')}
                                    </Text>
                                </Box>

                                <Box display="flex" alignItems="center">
                                <Image src="/assets/svgs/height.svg" />
                                <Text fontSize="0.875rem" fontWeight="500" ml="1rem">
                                    {/* 6ft 3in */}
                                    {patienData.patientinfo.height? (patienData.patientinfo.height+ " " + patienData.patientinfo.heightunit): ('0') }
                                    </Text>
                                </Box>

                                <Box display="flex" alignItems="center">
                                <Image src="/assets/svgs/weight.svg" />
                                <Text fontSize="0.875rem" fontWeight="500" ml="0.5rem">
                                    {/* 75 kg */}
                                    {/* {patienData.patientinfo.weight + " " + patienData.patientinfo.weightunit } */}
                                    {patienData.patientinfo.weight? (patienData.patientinfo.weight+ " " + patienData.patientinfo.weightunit): ('0') }
                                    </Text>
                                </Box>
                                
                                <Box display="flex" alignItems="center">
                                <Image src="/assets/svgs/cake.svg" />
                                <Text fontSize="0.875rem" fontWeight="500" ml="0.5rem">
                                    {/* 29 August, 1997 */}
                                    {/* {patienData.patientinfo.dob} */}
                                    {patienData &&
                              patienData.patientinfo &&
                              patienData.patientinfo.mobileno
                                ? (`${patienData.patientinfo.dob}`)
                                : "-"}
                                    </Text>
                                </Box>

                                <Box display="flex" alignItems="center">
                                <Image src="/assets/svgs/name.svg" />
                                <Text fontSize="0.875rem" fontWeight="500" ml="0.5rem">
                                    {`${patienData.patientinfo.uniqueid}`}
                                </Text>
                                </Box>

                                <Box display="flex" alignItems="center" pr="20px" mt="15px"
               opacity={actionAccessPatients?.create ? 1 : 0.5}>

              <input
                type="file"
                id="genericupload"
                style={{ display: 'none' }}
                accept="image/jpeg,image/jpg,image/png,image/tiff,application/pdf" // Specify accepted file types
                onChange={GenericUpload}
              />
              <PrimaryInput
               inputType="file"
               uploadText="Upload document"
               id="genericupload"
               docBtnWidth="195px"
               docBtnColor="#1C4EC5"
               iconImg="/assets/svgs/prescriptions.svg"
               iconImgCss={{width:"30px" , height:"auto" , color:"#1C4EC5" , 
                filter: "brightness(0) saturate(100%) invert(24%) sepia(84%) saturate(2247%) hue-rotate(215deg) brightness(83%) contrast(96%)"
                }}
               onChange={GenericUpload}
               variant="grayBtnFull"
               disableFileInput={actionAccessPatients?.create ? false : true}
             />
             
             </Box>
                            </Stack>
                        </VStack>
                        ) 
                        // : ("No records found")
                    }
                    </Box>

                    {/* Recent Activity end */}
                {/* Right area end */}
              </Box>
            </Flex>
            {/* Main container end */}
          </Box>
        </Flex>
      </Box>
    ) : (
<>
    
      {/* Mobile responsive */}
      <Box w="100%">
        {activeSection == null && (
        <>
        <MobileSecondaryNav
          header="Patient profile"
          handlebackarrow={()=> navigate('/messages')}
        />
        <Box w="100%" h="calc(100vh - 120px)" overflow="hidden auto">
          {/* Patient details start */}
          <Box w="100%" borderLeft="1px solid #E6E7E9">
          {
              patienData && patienData.patientinfo && (

              <VStack p="1.5rem" align="stretch" gap="1rem"bg="white">
                  <Box>
                      <Box display="flex" flexDir="column" gap="0.65rem">
                          <Box w="5rem" h="5rem">
                              <Image 
                              w="100%" 
                              h="100%"
                              borderRadius="full"
                              src={
                                  patienData.patientinfo.patientimage? 
                                  (`${patienData.patientinfo.imagepath}${patienData.patientinfo.patientimage}`) : ("/assets/imgs/no-image.png")
                              }
                                  
                              />
                              </Box>
                          <Box display="flex" flexDir="column">
                              <Text fontSize="1rem" fontWeight="600">
                                  {/* Dr. Samantha Raulf */}
                                  {`${patienData.patientinfo.firstname}  ${patienData.patientinfo.lastname}`}
                                  </Text>
                              <Box display="flex" gap="0.5rem">
                                  <Image src="/assets/svgs/calendar-filled.svg" />
                                  <Text fontSize="0.75rem" fontWeight="400" color="#384052">
                                    {/* Upcoming: 10th Jan, 2024 at 9:30 AM */}

                                    {patienData.patientinfo.nextappointmentdate && patienData.patientinfo.nextappointmenttime ? (
                                    `Upcoming: ${formatDate(patienData.patientinfo.nextappointmentdate)} at ${formatTime(patienData.patientinfo.nextappointmenttime)}`
                                    ) : (
                                      "-"
                                    )}

                                    {/* {`${formatDate(patienData.patientinfo.lastupdatedate)} at ${formatDateAndTime(patienData.patientinfo.lastupdatedate)}`} */}
                                    </Text>
                              </Box>
                          </Box>
                      </Box>
                  </Box>   
                  <Divider />
                  <Stack direction="column" gap="0.75rem" w="100%">
                      <Box display="flex" alignItems="center">
                      <Image src="/assets/svgs/name.svg" />
                      <Text fontSize="0.875rem" fontWeight="500" ml="0.5rem">
                          {/* Adnan Jaweed */}
                          {`${patienData.patientinfo.firstname}  ${patienData.patientinfo.lastname}`}
                      </Text>
                      </Box>

                      <Box display="flex" alignItems="center">
                      <Image src="/assets/svgs/email.svg" />
                      <Text fontSize="0.875rem" fontWeight="500" ml="0.5rem">
                          {/* adnan@supastellar.co */}
                          {patienData.patientinfo.email}
                      </Text>
                      </Box>

                      <Box display="flex" alignItems="center">
                      <Image src="/assets/svgs/phone-number.svg" />
                      <Text fontSize="0.875rem" fontWeight="500" ml="0.5rem">
                          {/* +1 (647) 523-9797 */}
                          {`${patienData.patientinfo.countrycode} ${patienData.patientinfo.mobileno}`}
                      </Text>
                      </Box>

                      <Box display="flex" w="100%" justifyContent="space-between" alignItems="center">
                        <Box display="flex" w="50%">
                          <Image src="/assets/svgs/gender.svg" />
                          <Text fontSize="0.875rem" fontWeight="500" ml="0.5rem">
                            {patienData.patientinfo.gender}
                          </Text>
                        </Box>

                        <Box display="flex" w="50%">
                          <Image src="/assets/svgs/height.svg" />
                          <Text fontSize="0.875rem" fontWeight="500" ml="1rem">
                            {patienData.patientinfo.height? (patienData.patientinfo.height+ " " + patienData.patientinfo.heightunit): ('0') }
                          </Text>
                        </Box>
                      </Box>

                      <Box display="flex" w="100%" justifyContent="space-between" alignItems="center">
                        <Box display="flex" w="50%">
                            <Image src="/assets/svgs/weight.svg" />
                            <Text fontSize="0.875rem" fontWeight="500" ml="0.5rem">
                              {patienData.patientinfo.weight? (patienData.patientinfo.weight+ " " + patienData.patientinfo.weightunit): ('0') }
                            </Text>
                        </Box>
                        
                        <Box display="flex" w="50%">
                            <Image src="/assets/svgs/cake.svg" />
                            <Text fontSize="0.875rem" fontWeight="500" ml="0.5rem">
                            {patienData.patientinfo.dob}
                            </Text>
                        </Box>
                        
                      </Box>

                  </Stack>
              </VStack>
              ) 
              // : ("No records found")
          }
          </Box>
          {/* Patient details end */}
          <Divider />
          {/* Menu section start */}
          <Box p="1.5rem" bg="white">
            <Box w="100%">
              <SimpleGrid columns={{base: 4, sm: 6}} spacing="1rem">
                {navItems.map((item, index)=> (
                  <Box key={index} zIndex="3" display="flex" justifyContent="center" alignItems="center" textAlign='center' flexDir='column' gap="0.5rem">
                  <Box as="button" boxSize="4rem"  onClick={()=> setActiveSection(item.to)} borderRadius="8px" bg="#F9FAFB" border="1px solid #0B1F4F4D" display="flex" justifyContent="center" alignItems="center">
                    <Image src={item.iconSrc} alt={item.text} />
                  </Box>
                  <Text fontSize="0.75rem" letterSpacing="-0.05rem" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden" wordBreak="" w="-webkit-fill-available">{item.text}</Text>
                  </Box>
                ))}
              </SimpleGrid>
            </Box>
          </Box>
          {/* Menu section end */}
          
          <Divider />

          {/* <Box w="100%">
            <Flex w="100%" flexDir='column'>
            {navItems.map((item, index)=> (
              <Box key={index} w="100%" p="1rem" display='flex' borderBottom='1px solid #e2e8f0' gap='1rem' alignItems='center'>
                <Image src={item.iconSrc} />
                <Text>{item.text}</Text>
                <Spacer />
                <Image src="/assets/imgs/right-icon.png" />
              </Box>
              ))}
            </Flex>
          </Box> */}


        </Box>
        </>
        )}
          <Flex>
            {activeSection && activeSection == "overview" && (
                <OverviewIndex pid={patientId} backFunction={backFunction} />
              )}
                {activeSection == "visits" && (
                  <PatientVisitsIndex id={patientId} backFunction={backFunction} />
                )}

                {activeSection == "chiefComplaint" && (
                  <ChiefComplaint id={patientId} backFunction={backFunction} />
                )}

                {activeSection == "historyOfPresentIllness" && (
                  <HistoryOfPresentIllness id={patientId} backFunction={backFunction} />
                )}

                {activeSection == "orders" && (
                  <OrdersIndex id={patientId} backFunction={backFunction} />
                )}

                {activeSection == "vitals" && (
                  <PatientVitalsIndex id={patientId} backFunction={backFunction} />
                )}

                {activeSection == "medicalHistory" && (
                  <MedicalHistory id={patientId} backFunction={backFunction} />
                )}

                {activeSection == "surgicalHistory" && (
                  <SurgicalHistory id={patientId} backFunction={backFunction} />
                )}

                {activeSection == "familyHistory" && (
                  <FamilyHistory id={patientId} backFunction={backFunction} />
                )}

                {activeSection == "socialHistory" && (
                  <SocialHistory id={patientId} backFunction={backFunction} />
                )}
                
                {activeSection == "physicalExam" && (
                  <PhysicalExam id={patientId} backFunction={backFunction} />
                )}

                {/* {activeSection == "treatmentPlan" && (
                  <TreatmentPlan id={patientId} backFunction={backFunction} />
                )} */}
                {activeSection == "doctorAssessment" && (
                  <DoctorAssessment id={patientId} backFunction={backFunction} />
                )}

                {activeSection == "reviewSystem" && (
                  <ReviewSystem id={patientId} backFunction={backFunction} />
                )}
                {activeSection == "allergies" && (
                  <Allergies id={patientId} backFunction={backFunction} />
                )}
                {activeSection == "vaccinations" && (
                  <Vaccinations id={patientId} backFunction={backFunction} />
                )}
                {activeSection == "investigations" && (
                  <Investigations id={patientId} backFunction={backFunction} />
                )}
                {activeSection == "labTest" && (
                  <LabTests id={patientId} backFunction={backFunction} />
                )}
                {activeSection == "imaging" && (
                  <Imaging id={patientId} backFunction={backFunction} />
                )}

            </Flex>
      </Box>
      </>
    )}
    </>
  )
}

export default PatientProfileIndex