import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Flex,
  Box,
  Card,
  CardBody,
  Checkbox,
  Divider,
  Image,
  Radio,
  RadioGroup,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import ModalRight from "../../modal-right";
import SearchBar from "../../search-bar";
import PrimaryButton from "../../primary-button";
import PrimaryInput from "../../primary-input";
import DatePicker from "../../date-picker";
import {
  AddSocialHistory,
  DeleteSocialHistory,
  FetchSocialHistoryDetails,
  GetSocialHistoryTypes,
  UpdateSocialHistory,
} from "../../../middleware/services/messages";
import { format } from "date-fns";
import { actionAccessMain, getLoginID } from "../../../auth";
import moment from "moment";
import DropdownInput from "../../dropdown-input";


const NoSocialHistoryDataScreen = forwardRef(
  (
    {
      width,
      height,
      patientId,
      showErrorToast,
      showSuccessToast,
      showLoader,
      dismissLoader,
      fetchPatientHealthData,
    },
    ref
  ) => {
    const openFromStartVisitOverview = () => {
      console.log("Function called!");
      OpenAddSocialHistory();
    };

    useImperativeHandle(ref, () => ({
      openFromStartVisitOverview,
    }));

    const [actionAccessPatients, setActionAccessPatients] = useState(null);
    useEffect(() => {
      setActionAccessPatients(actionAccessMain(4));
    }, []);

    var [data, setData] = useState(null);
    var [typedata, setTypeData] = useState([]);
    var [socialtypedata, setSocialTypeData] = useState([]);

    var [id, setID] = useState();
    var [rowId, setRowId] = useState("");
    var [title, setTitle] = useState();
    const addsocialhistory = React.useRef();
    const editsocialhistory = React.useRef();
    const dynamicModal = React.useRef();
    const [type, setType] = useState("");
    const [duration, setDuration] = useState("");
    const [durationtimespan, setDurationTime] = useState("");
    const [frequency, setFrequency] = useState("");
    const [frequencytimespan, setFrequencyTime] = useState("");
    const [frequency_cd, setFrequencyTimeID] = useState("327");
    const [duration_cd, setDurationTimeID] = useState("");
    const [type_cd, setTypeID] = useState("");
    const [current, setCurrent] = useState(0);
    const [smokingSelected, setSmokingSelected] = useState(false);
    const [smokeLessSelected, setSmokeLessSelected] = useState(false);
    const [hideFrequency, setHideFrequency] = useState(false);
    const [showDateSelection, setShowDateSelection] = useState(false);
    const [yearsDiff, setYearsDiff] = useState("");
    const [showQuitCheckBox, setShowQuitCheckBox] = useState(false);
    const [showIsCurentCheckBox, setShowIsCurentCheckBox] = useState(false);
    const [text, setText] = useState("");
    const [sendSmoklessInInput, setSendSmoklessInInput] = useState(false);
    const [sendDrugTypeInInput, setSendDrugTypeInInput] = useState(false);
    const [smokelessType, setSmokelessType] = useState(null);
    const [drugType, setDrugType] = useState(null);
    const [drugsSelected, setDrugsSelected] = useState(false);
    const [showFrequencyCalculation, setShowFrequencyCalculation] =
      useState(false);

    const addCondition3 = React.useRef();

    const [searchValue, setSearchValue] = useState("");

    const [reasonsArray, setReasonsArray] = useState([]);
    const [reactionsContinueBtnDisabled, setReactionsContinueBtnDisabled] =
      useState(true);
    const [allergyID, setAllergieID] = useState("");
    const [selectedReactions, setSelectedReactions] = useState([]);
    const floatLabel = {
      color: "#384052",
      fontSize: "0.775rem",
      position: "relative",
      top: "-4px",
      left: "-4px",
      opacity: "0.5",
      width: "100%",
    };

    var [fromYearOptions, setFromYearOptions] = useState([]);
    var [toYearOptions, setToYearOptions] = useState([]);
    var [showFrequencyTag, setShowFrequencyTag] = useState(true);
    const [radioValue, setRadioValue] = useState("");

    const [fromdate, setFromDate] = useState(moment().format("DD-MM-yyyy"));
    const [todate, setToDate] = useState("");
    const [showFrequencyInEditModal, setShowFrequencyInEditModal] =
      useState(true);
    const today = moment().format("YYYY-MM-DD HH:mm:ss.SSS");

    const [fromdateYear, setFromDateYear] = useState("");
    const [toDateYear, setToDateYear] = useState("");

    const [fromYearForValidation, setFromYearForValidation] = useState("");
    const [toYearForValidation, setToYearForValidation] = useState("");

    const OpenAddSocialHistory = async () => {
      clearState();
      const result = await GetSocialHistoryTypes({
        type: "socialhistorytype",
      });
      if (result) {
        // Iterate through the options and create an array of formatted strings
        const formattedOptions = result.output.data[0].data.map((option) => [
          option.condition,
        ]);

        setTypeData(formattedOptions);
      }
      const currentYear = new Date().getFullYear();
      const fromYears = Array.from(
        { length: 101 },
        (_, index) => currentYear - index
      );
      const fromOptions = fromYears.map((year) => String(year));
      setFromYearOptions(fromOptions);

      const toYears = Array.from(
        { length: 101 },
        (_, index) => currentYear - index
      );
      const toOptions = toYears.map((year) => String(year));
      setToYearOptions(toOptions);

      setSocialTypeData(result.output.data[0].data);
      setDuration("");
      setFrequency("");
      addsocialhistory.current.openRight();
    };
    useEffect(() => {
      setFromYearForValidation(fromdateYear);
      setToYearForValidation(toDateYear);
    }, [fromdateYear, toDateYear]);
    useEffect(() => {
      if (fromYearForValidation) {
        const currentYear = new Date().getFullYear();
        const fromYear = parseInt(fromYearForValidation); // Convert to number
        const toYears = Array.from(
          { length: currentYear - fromYear + 1 },
          (_, index) => fromYear + index
        );
        const toOptions = toYears.map((year) => String(year));
        setToYearOptions(toOptions);
      }
    }, [fromYearForValidation]);
    useEffect(() => {
      if (toYearForValidation) {
        const toYears = Array.from(
          { length: 101 },
          (_, index) => toYearForValidation - index
        );
        const toOptions = toYears.map((year) => String(year));
        setFromYearOptions(toOptions);
      }
    }, [toYearForValidation, id]);

    const clearState = () => {
      setTypeID("");
      setHideFrequency(false);
      setSmokeLessSelected(false);
      setSmokingSelected(false);

      setNeverSelected(false);
      setShowDateSelection(false);
      setShowQuitCheckBox(false);
      setShowIsCurentCheckBox(false);
      setShowQuitCheckBox(false);
      setYearsDiff("");
      setReasonsArray([]);
      setDrugsSelected(false);
      setToDateYear("");
      setToDate("");
      setFromDateYear("");
      setFromDate("");
      setShowYearsDiffTag(false);
      setSmokelessType(null);
      setDrugType(null);
      setCalculatedConsumption("");
      setFrequency("");
    };

    var [reactionList, setReactionList] = useState(null);
    const getDrugList = async () => {
      const result = await GetSocialHistoryTypes({
        type: "drug",
      });
      setReactionList(result.output.data[0].data);
    };
    const handleTypeInputChange = (selectedOption) => {
      var foundObject =
        socialtypedata &&
        socialtypedata.find((x) => x.condition == selectedOption);

      clearState();
      setType(foundObject.condition);
      if (foundObject) {
        setRadioValue("");
        setShowQuitCheckBox(true);
        if (foundObject.condition == "Smoking") {
          setText("Packs");
          setSmokingSelected(true);
          setShowFrequencyCalculation(true);

          setSmokeLessSelected(false);
          setDrugsSelected(false);
        } else if (foundObject.condition == "Alcohol") {
          setText("Drinks");
          setSmokingSelected(true);
          setShowFrequencyCalculation(false);

          setDrugsSelected(false);

          setSmokeLessSelected(false);
        } else if (foundObject.condition == "Smokeless") {
          setText("Cans");
          setSmokingSelected(true);
          // setHideFrequency(true)
          setSmokeLessSelected(true);
          setSendSmoklessInInput(true);
          setShowFrequencyCalculation(false);

          setDrugsSelected(false);
        } else if (foundObject.condition == "Illicit Drugs") {
          //setText("drinks")

          setText("Times");
          setSmokingSelected(true);
          setDrugsSelected(true);
          setSmokeLessSelected(false);
          setShowFrequencyCalculation(false);
          setSendDrugTypeInInput(true);
          // getDrugList();
        } else {
          setSmokingSelected(false);
        }
        setTypeID(foundObject.condition_cd);
      }
    };
    const handledurationInputChange = (selectedOption) => {
      let value;
      switch (selectedOption) {
        case "Hour":
          value = 325;
          break;
        case "Day":
          value = 326;
          break;
        case "Week":
          value = 327;
          break;
        case "Month":
          value = 328;
          break;
        case "Year":
          value = 329;
          break;
        default:
          value = 0; // Default value if no match is found
      }
      setDurationTimeID(value);
    };

    const handlefrequencyInputChange = (selectedOption) => {
      let value;
      switch (selectedOption) {
        case "Hour":
          value = 325;
          break;
        case "Day":
          value = 326;
          break;
        case "Week":
          value = 327;
          break;
        case "Month":
          value = 328;
          break;
        case "Year":
          value = 329;
          break;
        default:
          value = 0; // Default value if no match is found
      }
      setFrequencyTimeID(value);
    };

    const handleCheckboxChange = (event) => {
      if (event.target.checked == true) {
        setCurrent(1);
      } else {
        setCurrent(0);
      }
    };
    const handleCheckboxChangeQuit = (event) => {
      if (event.target.checked == true) {
        setCurrent(0);
      }
    };

    const handleFrequency = (e) => {
      let inputText = e.target.value;
      const maxLength = 3;
      const regex = /^\d+$/;

      inputText = inputText.replace(/\./g, "");
      if (inputText === "" || regex.test(inputText)) {
        if (inputText.length <= maxLength) {
          if (inputText.startsWith(0)) {
            inputText = inputText.substring(1);
          }
          setFrequency(inputText);
        }
      }
    };

    const [calculatedConsumption, setCalculatedConsumption] = useState("");
    const [calculatedYearsForEdit, setcalculatedYearsForEdit] = useState("");
    useEffect(() => {
      //var consumptionPreYear = (frequency / 7) * 365;
      //
      if (yearsDiff) {
        var consumptionPreYear = (frequency / 7) * yearsDiff;
        //var consumptionByYears = consumptionPreYear ;
        const finalCalculation = consumptionPreYear.toFixed(2);

        if (finalCalculation > 0) {
          setCalculatedConsumption(`${text} per year : ` + finalCalculation);
        } else {
          setCalculatedConsumption("");
        }
      }
    }, [frequency]);

    const [neverSelected, setNeverSelected] = useState(false);
    const handleYesSelected = (value) => {
      if (value == "yes") {
        setRadioValue("yes");
        setShowQuitCheckBox(false);
        setShowDateSelection(true);
        setShowIsCurentCheckBox(true);

        const currentYear = new Date().getFullYear();

        var janOne = "-01-01";
        var fullDate = currentYear + janOne;
        setToDate(fullDate);
        setCurrent(1);
        setNeverSelected(false);
        setFrequencyTimeID("327");

        if (type == "Smokeless") {
          setSmokeLessSelected(true);
        }
        if (type == "Illicit Drugs") {
          setDrugsSelected(true);
        } else {
          setDrugsSelected(false);
        }
      } else if (value == "currentlyNot") {
        setRadioValue("currentlyNot");
        setShowQuitCheckBox(true);
        setShowDateSelection(true);
        // setFromDate("")
        // setFromDateYear("")
        const currentYear = new Date().getFullYear();
        var janOne = "-01-01";
        var fullDate = currentYear + janOne;
        // setToDate(fullDate)
        setToDate("");

        setNeverSelected(false);
        setShowIsCurentCheckBox(false);
        setCurrent(2);

        setFrequencyTimeID("327");
        if (type == "Smokeless") {
          setSmokeLessSelected(true);
        }
        if (type == "Illicit Drugs") {
          setDrugsSelected(true);
        } else {
          setDrugsSelected(false);
        }
      } else if (value == "never") {
        setNeverSelected(true);
        setRadioValue("never");
        setShowDateSelection(false);
        setShowQuitCheckBox(false);
        setCurrent(0);
        setShowIsCurentCheckBox(false);
        setYearsDiff("");
        setToDate("");

        setFrequency("");
        setFrequencyTimeID("");
        setFromDateYear("");
        setFromDate("");

        setCalculatedConsumption("");
        setSmokeLessSelected(false);

        setDrugsSelected(false);
        setDrugType(null);
        setSendDrugTypeInInput(false);
        if (type == "Illicit Drugs") {
          setDrugsSelected(false);
          // setSendDrugTypeInInput(false)
          setDrugType(null);
          setReasonsArray([]);
        } else {
          setDrugsSelected(false);
        }
      }
    };
    const handleSmokelessType = (value) => {
      if (value == "Chew") {
        setSmokelessType("chew");
      } else if (value == "Snuff") {
        setSmokelessType("snuff");
      }
    };
    const handleDrugTypeType = (value) => {
      if (value == "Smoke") {
        setDrugType("smoke");
      } else if (value == "Snort") {
        setDrugType("snort");
      } else if (value == "ivDrug") {
        setDrugType("ivDrug");
      }
    };
    const handleFromDateChange = (selecteddate) => {
      const formattedDate = moment(selecteddate).format("YYYY-MM-DD");
      setFromDate(formattedDate);
    };
    const handleFromYearChange = (selecteddate) => {
      setFromDateYear(selecteddate);

      var janOne = "-01-01";
      var yearWithAddedDayAndMonth = selecteddate + janOne;
      setFromDate(yearWithAddedDayAndMonth);
    };

    const handleToDateChange = (selecteddate) => {
      const formattedDate = moment(selecteddate).format("YYYY-MM-DD");
      setToDate(formattedDate);
    };
    const handleToYearChange = (selecteddate) => {
      setToDateYear(selecteddate);

      var janOne = "-01-01";
      var yearWithAddedDayAndMonth = selecteddate + janOne;
      setToDate(yearWithAddedDayAndMonth);
    };
    const [showYearsDiffTag, setShowYearsDiffTag] = useState(false);
    useEffect(() => {
      if (fromdate && todate) {
        const diffYears = moment(todate).diff(moment(fromdate), "years");

        if (diffYears > 0) {
          setcalculatedYearsForEdit("Consumption Years : " + diffYears);
          setYearsDiff(diffYears);
          // not showing the "Consuming Years:3years" tag in Add so commenting below line :
          // setShowYearsDiffTag(true)
        } else {
          setYearsDiff("");
          setShowYearsDiffTag(false);
        }
      }
    }, [todate, fromdate]);
    const closeDrugsModal = () => {
      addCondition3.current.closeRight();
    };
    const openDrugListModal = () => {
      getDrugList();
      //here
      setSearchValue("");
      addCondition3.current.openRight();
    };

    useEffect(() => {
      if (reasonsArray.length === 0) {
        setReactionsContinueBtnDisabled(true);
      } else {
        setReactionsContinueBtnDisabled(false);
      }
    }, [reasonsArray]);

    const handleReactionCheckboxChange = (condition) => {
      const isSelected = reasonsArray.some(
        (item) => item.condition === condition.condition
      );

      if (isSelected) {
        // If already selected, remove the item from reasonsArray
        setReasonsArray((prevConditions) =>
          prevConditions.filter(
            (item) => item.condition !== condition.condition
          )
        );
      } else {
        // If not selected, add the item to reasonsArray
        setReasonsArray([...reasonsArray, { ...condition }]);
      }
    };
    const removeTag = (index) => {
      const updatedReasonsArray = [...reasonsArray];
      updatedReasonsArray.splice(index, 1);
      setReasonsArray(updatedReasonsArray);
    };

    const css = `
  .socialBtn {
    right: 0;
  }
  `;

    const handleaddsocialhistory = async () => {
      //setCurrent(current === false ? 0 : 1);
      if (type_cd == "") {
        showErrorToast("Please select any type");
        return false;
      } else if (radioValue == "") {
        showErrorToast("Please select a value");
        return false;
      }
      //  else if(type_cd == 533){
      //   if(smokelessType == null){
      //   showErrorToast("Please select smokeless type")
      //   }

      // }
      else if (radioValue != "never" && type_cd == "322" && drugType == null) {
        showErrorToast("Please select drug type");
        return false;
      } else if (
        radioValue != "never" &&
        type_cd == "322" &&
        reasonsArray.length == 0
      ) {
        showErrorToast("Please select a drug");
        return false;
      } else if (
        radioValue != "never" &&
        type_cd == "533" &&
        smokelessType == null
      ) {
        showErrorToast("Please select smokeless type");
        return false;
      } else if (radioValue != "never" && fromdateYear == "") {
        showErrorToast("Please select start year");
        return false;
      } else if (radioValue != "never" && toDateYear == "" && todate == "") {
        showErrorToast("Please select end year");
        return false;
      } else if (radioValue != "never" && type_cd != 533 && frequency == "") {
        showErrorToast("Please enter frequency");
        return false;
      } else if (radioValue != "never" && frequency == "") {
        showErrorToast("Please enter frequency");
        return false;
      }

      //  else if (duration == "") {
      //   showErrorToast("Please enter the duration")
      //   return false;
      // }
      else {
        showLoader();
        const formData = {
          loginid: patientId,
          doctorid: getLoginID(),
          socialhistory: [
            {
              socialhistorytype_cd: type_cd,
              //"duration": duration,
              frequency: frequency,
              iscurrent: current,
              frequencytype_cd: frequency_cd,
              //"durationtype_cd": duration_cd,
              fromdate: fromdate ? fromdate : null,
              todate: todate ? todate : null,
              ...(sendSmoklessInInput && { smokelesstype: smokelessType }),
              ...(sendDrugTypeInInput && { drugtype: drugType }),
              ...(reasonsArray.length > 0 && {
                drugnames: reasonsArray.map((reason) => ({
                  name: reason.condition,
                })),
              }),
            },
          ],
        };
        // if(sendSmoklessInInput){
        //   if (formData.socialhistory.length > 0) {
        //     alert("hit")
        //     formData.socialhistory[0].push("smokelessType");
        //   }
        // }
        //  console.log(formData, " input for add")
        const result = await AddSocialHistory(formData);

        if (result) {
          showSuccessToast("Social history added successfully");
          // fetchData(activeTab === 1 ? "0" : "1");
          fetchPatientHealthData();
          //setActiveTab(0);
          //window.location.reload();
          addsocialhistory.current.closeRight();
        }
      }
      dismissLoader();
    };

    return (
      <Box h='100%'>
        <Box
          bg="#fcfcfd"
          width={width}
          height={height}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDir='column'
          h='100%'
          gap='1rem'
        >
          <PrimaryButton
            fontSize="14px"
            variant="mdBtn"
            btnStyle={{w:"160px"}}
            btnIcon="/assets/imgs/add.png"
            buttonText="Add Social History"
            onClick={OpenAddSocialHistory}
            isDisabled={actionAccessPatients?.create ? false : true}
          />
          <Text textAlign="center">
            No Social History Data Available
          </Text>
        </Box>
        {/* Add Modal Here  */}
        <ModalRight
          ref={addsocialhistory}
          bodyStyle={{ p: "24px 32px" }}
          modalWidth={"360px"}
          header="Add Social History"
          body={
            <>
              <Box display="flex" flexDir="column">
                <DropdownInput
                  variant="defaultSide"
                  dropDownPlace="Type*"
                  options={typedata}
                  onClick={handleTypeInputChange}
                />
                {smokingSelected && (
                  <RadioGroup onChange={handleYesSelected} value={radioValue}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      py="20px"
                    >
                      <Radio value="yes" colorScheme="green">
                        Current
                      </Radio>

                      <Radio value="currentlyNot" colorScheme="green">
                        Former
                      </Radio>

                      <Radio value="never" colorScheme="green">
                        Never
                      </Radio>
                    </Box>
                  </RadioGroup>
                )}

                {showDateSelection && (
                  <>
                    {smokeLessSelected && (
                      <>
                        <Divider />
                        <RadioGroup onChange={handleSmokelessType}>
                          <Box
                            display="flex"
                            justifyContent="start"
                            gap="15px"
                            py="20px"
                          >
                            <Radio value="Chew" colorScheme="green">
                              Chew
                            </Radio>

                            <Radio value="Snuff" colorScheme="green">
                              Snuff
                            </Radio>
                          </Box>
                        </RadioGroup>
                      </>
                    )}
                    {drugsSelected && (
                      <>
                        <Divider />
                        <RadioGroup onChange={handleDrugTypeType}>
                          <Box
                            display="flex"
                            justifyContent="space-around"
                            py="20px"
                          >
                            <Radio value="Smoke" colorScheme="green">
                              Smoke
                            </Radio>

                            <Radio value="Snort" colorScheme="green">
                              Snort
                            </Radio>
                            <Radio value="ivDrug" colorScheme="green">
                              IV Drug use
                            </Radio>
                          </Box>
                        </RadioGroup>
                      </>
                    )}
                    {drugsSelected && (
                      <Box
                        as="div"
                        role="button"
                        aria-haspopup="dialog"
                        display="flex"
                        alignItems="center"
                        tabIndex={0}
                        border="1px solid #e2e8f0"
                        borderRadius="10px 10px 0 0"
                        p="10px"
                        height="auto"
                        width="100%"
                        sx={{
                          _hover: { border: "2px solid #384052" },
                        }}
                        // onClick={() => showReactionList(condition.condition_cd)}
                        onClick={openDrugListModal}
                        minHeight="48px"
                        flexWrap="wrap"
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          flexWrap="wrap"
                          gap="5px"
                          w="100%"
                          justifyContent={
                            reasonsArray.length === 0
                              ? "space-between"
                              : "start"
                          }
                        >
                          {reasonsArray.length === 0 ? (
                            <Text pl="2" color="gray.400">
                              Drugs<sup>*</sup>
                            </Text>
                          ) : reasonsArray.some(
                              (item) => item.condition_cd === item.condition_cd
                            ) ? (
                            <Text
                              pl="2"
                              color="gray.400"
                              sx={{
                                ...(reasonsArray.length === 0
                                  ? {}
                                  : floatLabel),
                              }}
                            >
                              Drugs<sup>*</sup>
                            </Text>
                          ) : (
                            <Text pl="2" color="gray.400">
                              Drugs<sup>*</sup>
                            </Text>
                          )}
                          {reasonsArray.length === 0 && (
                            <Image src="/assets/imgs/right-icon.png" />
                          )}
                          {reasonsArray.map((tag, index) => (
                            <Box
                              key={index}
                              display="flex"
                              alignItems="center"
                              gap="5px"
                              bg="gray.100"
                              borderRadius="full"
                              p="1"
                            >
                              <Text fontSize="sm" px="5px">
                                {tag.condition}
                              </Text>
                              <Box
                                as="button"
                                p="5px"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  removeTag(index);
                                }}
                                aria-label="Remove condition"
                              >
                                <Image src="/assets/imgs/close.png" />
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    )}
                    {/* <Box display="flex" flexDir="row">
                      <DatePicker
                        disableFutureDates={true}
                        // defaultDate={fromdate} 
                        onDateChange={(selectedDateValue) =>
                          handleFromDateChange(selectedDateValue)
                        }
                        placeholderProp={"Start Date"}
                        //calendarStlye={{transform: "scale(0.9)", left:"-15px", top: "30px"}}
                        calendarStlye={{ left: "-18px", right: "unset" }}
                      />
                      <DatePicker
                        disableFutureDates={true}
                        //defaultDate={todate} 
                        onDateChange={(selectedDateValue) =>
                          handleToDateChange(selectedDateValue)
                        }
                        placeholderProp={"End Date"}
                        calendarStlye={{ transform: "scale(0.9)", right: "-22px", top: "30px" }}
                      />
                    </Box> */}
                    <Box display="flex" flexDir="row">
                      <DropdownInput
                        variant="defaultSide"
                        dropDownPlace="Start year*"
                        options={fromYearOptions}
                        onClick={(selectedDateValue) =>
                          handleFromYearChange(selectedDateValue)
                        }
                      />
                      {!showIsCurentCheckBox && (
                        <DropdownInput
                          variant="defaultSide"
                          dropDownPlace="End year*"
                          options={toYearOptions}
                          onClick={(selectedDateValue) =>
                            handleToYearChange(selectedDateValue)
                          }
                        />
                      )}
                    </Box>

                    {showYearsDiffTag && (
                      <Box my="2px">
                        <p>Consuming Years : {yearsDiff}</p>
                      </Box>
                    )}

                    {!hideFrequency && (
                      <Box display="flex" flexDir="row">
                        <PrimaryInput
                          variant="leftDown"
                          inputPlace={`${text} per week*`}
                          onChange={handleFrequency}
                          inputValue={frequency}
                        />
                        {/* <DropdownInput
                          variant="rightDown" dropDownPlace="Time Span" options={["Week"]}
                          defaultOption={"Week"}
                          onClick={handlefrequencyInputChange}
                        /> */}
                      </Box>
                    )}
                    {showFrequencyCalculation && (
                      <Box my="2px">
                        <p>{calculatedConsumption} </p>
                      </Box>
                    )}

                    {showQuitCheckBox && (
                      <Box display="flex" justifyContent="start" py="20px">
                        <Checkbox
                          colorScheme="green"
                          sx={{
                            ".chakra-checkbox__control": {
                              width: "20px",
                              height: "20px",
                              borderRadius: "5px",
                            },
                          }}
                          defaultChecked={current === 2}
                          isDisabled={true}
                        >
                          I Quit
                        </Checkbox>
                      </Box>
                    )}

                    {showIsCurentCheckBox && (
                      <Box display="flex" justifyContent="start" py="20px">
                        <Checkbox
                          colorScheme="green"
                          sx={{
                            ".chakra-checkbox__control": {
                              width: "20px",
                              height: "20px",
                              borderRadius: "5px",
                            },
                          }}
                          defaultChecked={current === 1}
                          isDisabled={true}
                        >
                          Current
                        </Checkbox>
                      </Box>
                    )}
                  </>
                )}
                {/* <Box display="flex" flexDir="row">
                    <PrimaryInput variant="bothSide" inputStyleCss={{w: "60%"}} inputType="number" inputPlace="Duration*"
                    onChange={handleDuration} inputValue={duration}/>
              <DropdownInput 
                    variant="bothSide" dropDownPlace="Time Span" inputStyleCss={{w: "40%"}} options={["Hour", "Day","Week","Month", "Year"]}
                    onClick={handledurationInputChange}/>
                    </Box>
                    <Box display="flex" flexDir="row">
                    <PrimaryInput variant="leftDown" inputType="number" inputStyleCss={{w: "60%"}} inputPlace="Frequency"
                    onChange={handleFrequency} inputValue={frequency}/>             
              <DropdownInput 
                    variant="rightDown" dropDownPlace="Time Span" inputStyleCss={{w: "40%"}} options={["Hour", "Day","Week","Month", "Year"]}
                    onClick={handlefrequencyInputChange}/>
                     </Box> */}
                {/* <Box display="flex" justifyContent="start" py="20px">
                  <Checkbox
                    colorScheme="green"
                    sx={{
                      ".chakra-checkbox__control": {
                        width: "20px",
                        height: "20px",
                        borderRadius: "5px",
                      },
                    }}
                    onChange={handleCheckboxChange}>
                    Current
                  </Checkbox>
                </Box> */}
                <Divider />
                {/* <Box as="button" display="flex" alignItems="center" gap="10px" py="20px" fontSize="14px" fontWeight="600" color="#1C4EC5" textAlign="left">
                    <Image w="11px" src="/assets/imgs/add-blue.png"/> Add social history</Box> */}
              </Box>
            </>
          }
          footerActions={
            <>
              <Box w="100%" display="flex" flexDirection="row" gap="15px">
                <PrimaryButton
                  buttonText="Add Social History"
                  onClick={handleaddsocialhistory}
                  isDisabled={actionAccessPatients?.create ? false : true}
                />
              </Box>
            </>
          }
        />

        <ModalRight
          ref={addCondition3}
          handlebackarrow={() => addCondition3.current.closeRight()}
          withBackBtn={true}
          withBackHeader="Drugs"
          body={
            <>
              <SearchBar
                passInputPlace="Search Drugs"
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <RadioGroup>
                <Stack direction="column" gap="0">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    borderBottom="1px solid #e2e8f0"
                    py="15px"
                  >
                    {/* <Box display="flex" justifyContent="end" w="100%">
                            <Stack direction="column" gap="0" w="100%">
                              {reactionList && reactionList.length > 0 ? (
                                    reactionList.map((condition) => (
                                      <label key={condition.condition_cd} >

                                        <Box
                                          display="flex"
                                          justifyContent="space-between"
                                          borderBottom="1px solid #e2e8f0"
                                          p="15px"
                                          height="50px"
                                          width="100%"
                                          sx={{
                                            _hover: {
                                              border: "2px solid #384052",
                                              borderRadius: "10px",
                                            },
                                          }}
                                        >
                                          <Box
                                            key={condition.condition_cd}
                                            display="flex"
                                            alignItems="center"
                                          >
                                            <Text fontSize="16px" fontWeight="600">
                                              {condition.condition}
                                              <br />
                                              <Box
                                                as="span"
                                                fontSize="12px"
                                                fontWeight="500"
                                                color="#6A707D"
                                              ></Box>
                                            </Text>
                                          </Box>
                                          <Box display="flex" justifyContent="end">
                                            <Checkbox
                                              colorScheme="green"
                                              sx={{
                                                ".chakra-checkbox__control": {
                                                  width: "20px",
                                                  height: "20px",
                                                  borderRadius: "5px",
                                                },
                                              }}
                                              onChange={() =>
                                                handleReactionCheckboxChange(
                                                  condition
                                                )
                                              }
                                              isChecked={reasonsArray.some(
                                                (item) =>
                                                  item.condition_cd ===
                                                  condition.condition_cd
                                              )}
                                            ></Checkbox>
                                          </Box>
                                        </Box>

                                      </label>
                                    ))
                                
                              ) : (
                                <p>Loading...</p>
                              )}
                            </Stack>
                          </Box> */}
                    <Box display="flex" justifyContent="end" w="100%">
                      <Stack direction="column" gap="0" w="100%">
                        {reactionList && reactionList.length > 0 ? (
                          reactionList
                            .flatMap((category) => category)
                            .filter((condition) =>
                              condition.condition
                                .toLowerCase()
                                .includes(searchValue.toLowerCase())
                            ).length === 0 ? (
                            <Box
                              p="15px"
                              fontSize="16px"
                              fontWeight="600"
                              textAlign="center"
                            >
                              No matching drugs found!
                            </Box>
                          ) : (
                            reactionList
                              .flatMap((category) => category)
                              .filter((condition) =>
                                condition.condition
                                  .toLowerCase()
                                  .includes(searchValue.toLowerCase())
                              )
                              .map((condition) => (
                                <label key={condition.condition_cd}>
                                  <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    borderBottom="1px solid #e2e8f0"
                                    p="15px"
                                    height="50px"
                                    width="100%"
                                    sx={{
                                      _hover: {
                                        border: "2px solid #384052",
                                        borderRadius: "10px",
                                      },
                                    }}
                                  >
                                    <Box
                                      key={condition.condition_cd}
                                      display="flex"
                                      alignItems="center"
                                    >
                                      <Text fontSize="16px" fontWeight="600">
                                        {condition.condition}
                                        <br />
                                        <Box
                                          as="span"
                                          fontSize="12px"
                                          fontWeight="500"
                                          color="#6A707D"
                                        ></Box>
                                      </Text>
                                    </Box>
                                    <Box display="flex" justifyContent="end">
                                      <Checkbox
                                        colorScheme="green"
                                        sx={{
                                          ".chakra-checkbox__control": {
                                            width: "20px",
                                            height: "20px",
                                            borderRadius: "5px",
                                          },
                                        }}
                                        onChange={() =>
                                          handleReactionCheckboxChange(
                                            condition
                                          )
                                        }
                                        isChecked={reasonsArray.some(
                                          (item) =>
                                            item.condition ===
                                            condition.condition
                                        )}
                                      ></Checkbox>
                                    </Box>
                                  </Box>
                                </label>
                              ))
                          )
                        ) : (
                          <p>Loading...</p>
                        )}
                      </Stack>
                    </Box>
                  </Box>
                </Stack>
              </RadioGroup>
            </>
          }
          footerActions={
            <>
              <Box w="100%" display="flex" flexDirection="row" gap="15px">
                <PrimaryButton
                  buttonText="Add Drugs"
                  isDisabled={reactionsContinueBtnDisabled}
                  onClick={() => closeDrugsModal()}
                />
              </Box>
            </>
          }
        />
      </Box>
    );
  }
);

export default NoSocialHistoryDataScreen;
