import React, { useRef, useState,useEffect, useLayoutEffect } from "react";
import MiniSideBar from "../../components/mini-sidebar";
import {
  Box,
  Card,
  CardFooter,
  Center,
  Circle,
  Divider,
  Flex,
  Image,
  InputGroup,
  Menu,
  MenuButton,
  Spacer,
  Stack,
  Text,
  Textarea,
  VStack,
  MenuList,
  MenuItem,
  InputRightElement,
  Input,
  Td,
  Tr,
  Step,
  StepIndicator,
  StepTitle,
  StepDescription,
  Stepper,
  StepSeparator,
  UnorderedList,
  ListItem,
  CardBody,
  position,
  SimpleGrid,
  Collapse,
  useDisclosure,
} from "@chakra-ui/react";
import SecondaryNav from "../../components/seconday-nav";
import PrimaryButton from "../../components/primary-button";
import GrayCard from "../../components/card";
import SearchBar from "../../components/search-bar";
import TypingLoader from "../../components/typing-loader";
import {COLORS} from "../../components/styles/colors";
import ToggleTab from "../../components/accordion";
import DynamicModal from "../../components/dynamic-modal";
import ModalRight from "../../components/modal-right";
import { Link, NavLink, useLocation } from "react-router-dom";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import SimpleTable from "../../components/simple-table";
import moment from "moment";

import {
    BrowserRouter as Router,
    generatePath,
    Routes,
    Route,
    useNavigate,
    useParams,
} from "react-router-dom";
import { formatDate_YYYY_MM_DD, formatToDecimal, getLoginID, useScreenType } from "../../auth";
import MobileNavbar from "../../components/mobile-navbar";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import {
  DropdownInput,
  PasswordInput,
  PrimaryInput,
  Toaster,
} from "../../components";
import DatePicker from "../../components/date-picker";
import { format } from "date-fns";
import { FetchFilteredTotalRevenueList, FetchTotalRevenueDetails } from "../../middleware/services/totalrevenue";


const TotalRevenueIndex = () => {

  var [doctorid, setDoctorid] = useState(getLoginID());
  var [patientid, setPatientID] = useState("");
  var [appointmentid, setAppointmentID] = useState("");
  var [data, setData] = useState(null);
  var [totalRevenueData, setTotalRevenueData] = useState(null);
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const { showSuccessToast, showErrorToast } = Toaster();
  const screenType = useScreenType()
  const navigate = useNavigate();
  const { isOpen, onToggle } = useDisclosure()


  const [patientName, setPatientName] = useState('')
  const [email, setEmail] = useState('')
  const [mobile, setMobile] = useState('')
  const [patientPlan, setPatientPlan] = useState([])
  const [plan, setPlan] = useState([])
  const [noData, setNoData] = useState(false);

  const [name, setName] = useState("");
  // const [visitDate, setVisitDate] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [totalItemCount, setTotalItemCount] = useState(0);
  
  let itemPerPageCommonValue = 50;

  let [pagesize, setPagesize] = useState(itemPerPageCommonValue);
  let [pageno, setPageno] = useState(1);

  // states for item count display
  let [countTo, setCountTo] = useState(itemPerPageCommonValue);
  let [countFrom, setCountFrom] = useState(1);
  let [totalExceedsPageCount, setTotalExceedsPageCount] = useState(false);

  const [showFilterCount, setShowFilterCount] = useState(false);
  
  const [ toDateMinMax , setToDateMinMax] = useState("")
  const [ fromDateMinMax , setFromDateMinMax] = useState("")
  
  const [pageLoad, setPageLoad] = useState(true);
  // useEffect(() => {
  //   fetchPatientsView();       
  // }, []);
  // const fetchPatientsView = async () => {
  //   showLoader()
  //   try {
  //     const result = await FetchPatientViewDetails({
  //       doctorid,
  //     });
  //     if (result != null) {
  //       setPatientViewData(result);
  //     } else {
  //       //setPatientData("");
  //       dismissLoader()
  //     }
  //     dismissLoader()
  //   } catch (error) {
  //     console.error("Error in home:", error);
  //   }
  // };

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    return moment(dateString).format("Do MMM, YYYY");
  };
  const formatTime = (time) => {
    if (!time) {
      return ""; 
    }

    const [hours, minutes] = time.split(':');
  
    const date = new Date();
    date.setHours(hours, minutes);
  
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  
    return formattedTime;
  };

  const TableHeader = [
    { key: "patient", label: "Patient" },
    { key: "email", label: "Email" },
    { key: "mobile", label: "Mobile No" },
    { key: "amount", label: "Amount" },
    { key: "paymentdate", label: "Payment Date" },
    { key: "paymenttype", label: "Payment Type" },
    // { key: "actions", label: "Actions" },
  ];

  // Pagination Functionality & Page Load

  const handleChangeName = (event) => {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[^A-Za-z0-9\s@.]/g, '').slice(0, 50);
    event.target.value = inputValue;
    setName(event.target.value);
  };


  const handleFromDate = (selectedDate) => {
    const formattedDate = format(selectedDate, "dd-MM-yyyy");
   // console.log('formattedDAte: ', formattedDate)
    setFromDate(formattedDate);

    setFromDateMinMax(selectedDate);
    // setFromDateMinMax(null); // Allow all dates up to today for "From Date"
    // setToDateMinMax(selectedDate);
  };
  const handleToDate = (selectedDate) => {
    const formattedDate = format(selectedDate, "dd-MM-yyyy");
    setToDate(formattedDate);

    setToDateMinMax(selectedDate);
  //   setToDateMinMax(null); // Allow selection of any valid date for "To Date"
  // setFromDateMinMax(selectedDate);
  };

  // const handleAppointmentDate = (selectedDate) => {
  //   const formattedDate = format(selectedDate, "dd-MM-yyyy");
  //   console.log('formattedDAte: ', formattedDate)
  //   setVisitDate(formattedDate);
  // };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // ApplyFunction();
    // handleApplyFilters()
  };

  useEffect(() => {
    var input = {
      "doctorid": getLoginID(),
      "pageno": 1,
      pagesize
    }
    var getRevenueList = async () => {
      showLoader();
      try{
        var result = await FetchTotalRevenueDetails(input);

        if(result?.data){
          setTotalRevenueData(result.data);
          // setNoData(result.data.length === 0);
          let count = result?.count;
          let pageno = result?.pageno;
          let pagesize = result?.pagesize;
          setTotalItemCount(count)
          let totalPagesCalc = count / pagesize;
          let totalPagesCount = Math.ceil(totalPagesCalc);

          setTotalPages(totalPagesCount);

          // if(count < itemPerPageCommonValue){
          //   setCountTo(count)
          //   setTotalExceedsPageCount(true)
          // }else if(count > itemPerPageCommonValue && pageno == 1) {
          //   setCountTo(itemPerPageCommonValue);
          //   setTotalExceedsPageCount(false);
          // }

          setTotalItemCount(count)
          if(result && result.count  < itemPerPageCommonValue){
            setCountTo(result.count)
            setTotalExceedsPageCount(true)
          }else if(count > itemPerPageCommonValue && pageno == 1) {
            setCountTo(itemPerPageCommonValue);
            setTotalExceedsPageCount(false);
          }
        } else{
          setTotalRevenueData([])
        }
        
      // dismissLoader();
      } catch (error) {
        console.error("Error in home:", error);
      }
      
      dismissLoader();
    };

    getRevenueList();
  }, [refresh])

  const getFilteredResults = () => {
    // if(!name && !email && !specialization && (!status || status== '')){
    //       showErrorToast('Please enter the inputs to filter.')
    //       return;
    //     }
    const tableContainer = document.querySelector('.tableContainer');
    if (tableContainer) {
      tableContainer.scrollTop = 0;
    }
    var input = {
      "doctorid": getLoginID(),
        "searchtext": name,
        "startdate": formatDate_YYYY_MM_DD(fromDate),
        "enddate": formatDate_YYYY_MM_DD(toDate),
        "pageno": pageno,
        pagesize
    };
    var getRevenueList = async () => {
      showLoader();
      try {
        var result = await FetchFilteredTotalRevenueList(input);

        if (result?.data) {
          setTotalRevenueData(result.data);
          // setNoData(result.data.length === 0);
          let count = result?.count;
          let pageno = result?.pageno;
          let pagesize = result?.pagesize;
          setTotalItemCount(count)
          let totalPagesCalc = count / pagesize;
          let totalPagesCount = Math.ceil(totalPagesCalc);
          // alert(totalPages)
          setTotalPages(totalPagesCount);
          // setFilteredData(result.data);

          if (count < itemPerPageCommonValue) {
            setCountTo(count);
            setTotalExceedsPageCount(true);
          }else if(count > itemPerPageCommonValue && pageno == 1) {
            setCountTo(itemPerPageCommonValue);
            setTotalExceedsPageCount(false);
          }

          // setTotalItemCount(count)
          // if(result && result.count  < itemPerPageCommonValue){
          //   setCountTo(result.count)
          //   setTotalExceedsPageCount(true)
          // }else if(count > itemPerPageCommonValue && pageno == 1) {
          //   setCountTo(itemPerPageCommonValue);
          //   setTotalExceedsPageCount(false);
          // }
        } else{
          setTotalRevenueData([])
        }
      dismissLoader();
      } catch (error) {
        console.log(error, "error in getDoctorsList");
      }
    };

    getRevenueList();
  };

  const handleApplyFilters = () => {
    // if(!patientName && !email && !mobile && (!plan || plan== '')){
    //   showErrorToast('Please enter the inputs to filter.')
    //   return;
    // }
    setShowFilterCount(true);
    setCountFrom(1)
    setPageno(1)
    setCurrentPage(1)
    getFilteredResults();
    if (isOpen) {
      onToggle(); // This will close the filter section if it's currently open
    }
  };

  const resetFields = () => {
    setName('')
    setFromDate('')
    setToDate('')
    setFromDateMinMax(null)
    setToDateMinMax(null)
    setPageno(1);
    setPagesize(itemPerPageCommonValue);
    setCountFrom(1)
    setCountTo(itemPerPageCommonValue)
    setCurrentPage(1)
    setShowFilterCount(false);

    setRefresh(!refresh);
    if (isOpen) {
      onToggle(); // This will close the filter section if it's currently open
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      // console.log('Enter key pressed');
      handleApplyFilters();
    }
  };

  useEffect(()=>{
    if(pageno  && !pageLoad){
      
       getFilteredResults();
       }
       
    setPageLoad(false);
  },[pageno])

  const handleNextClick = () => {
    let page = pageno +1 ;
  //  alert(page)
    setPageno(page);
    setCurrentPage(currentPage + 1);
   // getFilteredResults();
   
   // item count logic for next
   if(countTo + pagesize < totalItemCount){
    setCountTo(countTo + pagesize)
   }else{
     setCountTo(totalItemCount)
   }
   
   setCountFrom(countFrom + pagesize)
  };
  const handlePrevClick = () => {
    let page = pageno -1 ;
    //alert(page)
    setPageno(page);
   // setPageno(pageno - 1);
    setCurrentPage(currentPage - 1);
   // getFilteredResults();
   // item count logic for previous
   setCurrentPage(currentPage - 1);
   if(countTo == totalItemCount){
        //setCountTo(Math.floor(totalItemCount / 10) * 10)
      let updatedCountUnroundedValue  = totalItemCount - pagesize;
      setCountTo(Math.ceil(updatedCountUnroundedValue / itemPerPageCommonValue ) * itemPerPageCommonValue )

   }else{
     setCountTo(countTo - pagesize)
   }
   
   setCountFrom(countFrom - pagesize)
  };

  // View Profile Functionality
  const handleClick = async (appointment, patient) => {
    // if (appointment && patient) {
      navigate(
        `/patientprofile?id=${patient}&page=overview`
      );
      // navigate(
      //   `/visitdetails?patientId=${patient}&appointmentId=${appointment}`
      // );
    // } else {
    //   console.error("Error: Visit details are incomplete.");
    // }
  };

  const gobackfunction = ()=>{
    window.history.back();
   }

  return (
    <>
          {isLoading && <Loader />}
      {screenType == 'web' ? (
        <Box w="100%">
        <Flex>
          <Box w="6rem">
            <MiniSideBar />
          </Box>
          <Box w="100%">
            <SecondaryNav withBack="Revenue" goBack={gobackfunction} />
            <Box gap='10px' my='1rem' pl='2rem' w='90vw' display='flex' flexDirection='row' justifyContent='center' alignItems='center'>
              <PrimaryInput
                variant="fullRound"
                title="Search by name, email"
                inputPlace="Search by name, email"
                id="yourName"
                onKeyDown={handleKeyDown}
                onChange={handleChangeName}
                inputValue={name}
              />
              {/* <Box w='800px'>
              <DatePicker
                  disableFutureDates={true}
                  // defaultDate={procedureDate}
                  onDateChange={(selectedDateValue) =>
                    handleAppointmentDate(selectedDateValue)
                  }
                  placeholderProp="Appointment Date"
                  datePickerInputStyle={{ borderRadius: "10px 10px 10px 10px" }}
                  value={visitDate} 
                  clearValue={true}
                />
              </Box>
                <Box w='800px'>
                </Box> */}

<Box w='800px'>
              <DatePicker
                  // defaultDate={procedureDate}
                  onDateChange={(selectedDateValue) =>
                    handleFromDate(selectedDateValue)
                  }
                  placeholderProp="From Date"
                  datePickerInputStyle={{ borderRadius: "10px 10px 10px 10px" }}
                  value={fromDate} 
                  clearValue={true}

                  // CUSTOM_DISABLE={true}
                  disableFutureDates={true}
                  maxDate={toDateMinMax}
                />
              </Box>
                <Box w='800px'>
              <DatePicker
                  onDateChange={(selectedDateValue) =>
                    handleToDate(selectedDateValue)
                  }
                  placeholderProp="To Date"
                  datePickerInputStyle={{ borderRadius: "10px 10px 10px 10px " }}
                  value={toDate} 
                  clearValue={true}
                  
                  
                  CUSTOM_DISABLE={true}
                  maxDate={new Date().toISOString()} 
                  minDate={fromDateMinMax}
                />
                </Box>

              <Box gap='10px' pl={{md:'1rem', xl:'1.5rem'}} w='70vw' display='flex' flexDirection='row' justifyContent='center' alignItems='center'>
                <PrimaryButton buttonText="Filter" id="filterButton" onClick={handleApplyFilters}  />
                <PrimaryButton buttonText="Reset" id="resetButton" variant="grayBtnFull" btnStyle={{ color: "#AA2F27" }} onClick={resetFields} />
              </Box>
              {/* {noData && <p>No data available</p>} */}
              </Box>
            {totalRevenueData && totalRevenueData.length > 0 ? (
                        <Box my="1rem">
                          <Box
                          h="calc(100vh - 90px)"
                          overflow='hidden auto'
                          border="1px solid #E6E7E9"
                          >
                            <SimpleTable
                              headers={TableHeader}
                              isThCss={{px:{md:'1rem', xl:'1.5rem'}}}
                              className='tableContainer'
                              isDataPresent={true}
                              currentPage={currentPage} onPageChange={handlePageChange}
                              footerStyle={{pos: "fixed", bottom: "0px",boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px", zIndex: "99", w: "100%"}}
                              tableStyle={{maxH:"calc(100vh - 230px)",overflowY: "auto",overflowX: "hidden", tableLayout: "fixed", width: "100%"}}
                              tableBody={
                                <>
                                  {totalRevenueData &&
                                    totalRevenueData.map((step, index) => (
                                      <Tr w="100%" key={index}>
                                        <Td px={{md:'1rem', xl:'1.5rem'}} w="100x">
                                          <Box w="100%" display="flex" gap="0.5rem" alignItems="center">
                                            <Box boxSize="2.75rem">
                                              <Image 
                                                boxSize="40px" 
                                                h="40px" 
                                                objectFit="cover"
                                                src={
                                                  step.patientimage && step.patientimage != 'null'
                                                    ? (step.patientimage.startsWith('http')|| step.patientimage.startsWith('https'))
                                                      ? step.patientimage
                                                      : `${step.imagepath}${step.patientimage}`
                                                    : '/assets/imgs/no-image.png'
                                                }
                                                borderRadius="full" 
                                              />
                                            </Box>
                                            <Box w="80%" maxW="8rem" isTruncated>
                                              <Text
                                              className="textOverflow"
                                                fontSize=".875rem"
                                                fontWeight="600"
                                                title={step.patientname}
                                              >
                                                {step.patientname
                                                  ? step.patientname
                                                  : "-"}
                                              </Text>
                                            </Box>
                                          </Box>
                                        </Td>

                                        <Td px={{md:'1rem', xl:'1.5rem'}}>
                                          <Text fontSize=".875rem" fontWeight="500">
                                            {step.email ? step.email : '-'}
                                          </Text>
                                        </Td>

                                        <Td px={{md:'1rem', xl:'1.5rem'}}>
                                          <Box
                                            p=".25rem .5rem"
                                            borderRadius="full"
                                          >
                                            <Text
                                              fontSize=".75rem"
                                              fontWeight="600"
                                              //color="#109E6A"
                                            >
                                              {step.mobileno ? (step.mobileno): ('-')}
                                            </Text>
                                          </Box>
                                        </Td>

                                        <Td px={{md:'1rem', xl:'1.5rem'}}>
                                          <Text fontSize=".875rem" fontWeight="500">

                                          {step.amount ? `$ ${formatToDecimal(step.amount)}` : ('-')}
                                          </Text>
                                        </Td>

                                        <Td px={{md:'1rem', xl:'1.5rem'}}>
                                          <Text fontSize=".875rem" fontWeight="500">

                                          {step.paymentdate ? formatDate(step.paymentdate) : ('-')}
                                          </Text>
                                        </Td>

                                        <Td px={{md:'1rem', xl:'1.5rem'}}>
                                          <Text fontSize=".875rem" fontWeight="500">

                                          {step.paymenttype ? (step.paymenttype) : ('-')}
                                          </Text>
                                        </Td>

                                        

                                        {/* <Td pos='relative'>
                            <Menu>
                              <MenuButton as='button'  zIndex={1}>
                                <Image w="1.5rem" src="/assets/svgs/more-options.svg" />
                              </MenuButton>
                              <MenuList zIndex={9} pos="relative" right="-33px" minW="125px">
                                <MenuItem onClick={() => handleClick(step.appointmentid, step.patientid)}>
                                  View Profile
                                </MenuItem>
                                <MenuItem onClick={() => openScheduleModal(step.patientid, step.appointmentid)}>
                                  Schedule Visit
                                </MenuItem>
                                {(step.appointmentstatus ==0 || step.appointmentstatus == 1)  && (
                                <MenuItem onClick={() => openRescheduleModal(step.patientid, step.appointmentid)}>
                                  Reschedule Visit
                                </MenuItem>
                                )}
                                {(step.appointmentstatus ==0 || step.appointmentstatus == 1) && (
                                <MenuItem 
                                // onClick={cancelVisit.current.openModal}
                                onClick={() => openCancelModal(step.fullname, step.appointmentdate, step.appointmenttime, step.patientid, step.appointmentid)}
                                >
                                  Cancel Visit
                                </MenuItem>
                                )}
                              </MenuList>
                            </Menu>
                          </Td> */}
                                      </Tr>
                                    ))}
                                </>
                              }
                              footerSection={
                                <>
                                  <Tr w='100%'>
                                    <Td colSpan={7} w="calc(100vw - 6.5rem)">
                                      <Box
                                        w="100%"
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                      >
                                          <Box 
                         visibility={(totalItemCount < countTo && !totalExceedsPageCount)  ? "hidden" : "visible"}
                         >
                            <Text>Showing  <b>{countFrom}</b> to <b>{countTo}</b>  of {totalItemCount} results</Text>
                            </Box>
                            <Box  
                          gap="2.5rem"
                           display="flex"
                          justifyContent="space-between"
                          alignItems="center" >
                                        <Box as="button" onClick={handlePrevClick} disabled={currentPage === 1} visibility={currentPage === 1 ? 'hidden' : 'visible'} p="0.5rem 0.75rem" border="0.0625rem solid #E6E7E9" borderRadius="0.75rem" fontSize="1rem" fontWeight="500">
                                            Previous
                                          </Box>
                                        <Box>
                                          <Text textAlign="center" fontSize=".875rem" fontWeight="600">
                                          Page {currentPage} of {totalPages}
                                          </Text>
                                        </Box>
                                        {(totalItemCount >= countTo && !totalExceedsPageCount)  && 
                                          <Box as="button" p="0.5rem 0.75rem" mr='2.5rem' border="0.0625rem solid #E6E7E9" borderRadius="0.75rem" onClick={handleNextClick} 
                                          disabled={currentPage === totalPages} 
                                          visibility={currentPage === totalPages ? 'hidden' : 'visible'} 
                                          fontSize="1rem" fontWeight="500">
                                            Next
                                          </Box>
                                        }
                                          </Box>
                                      </Box>
                                    </Td>
                                  </Tr>
                                </>
                              }
                            />
                          </Box>
                        </Box>
                      ) : (
                        <SimpleTable
                          headers={TableHeader}
                          isDataPresent={false}
                        />
                      )}
          </Box>
        </Flex>
        </Box>
      ) : (
        <>
          <MobileNavbar />
          <MobileSecondaryNav
            header="Revenue"
            handlebackarrow={false}
          />
          <Box
            w="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p="1rem"
            gap='0.75rem'
          >
            <PrimaryInput
              variant="fullRound"
              title="Search name, email"
              inputPlace="Search name, email"
              id="yourName"
              onKeyDown={handleKeyDown}
              onChange={handleChangeName}
              inputValue={name}
              inputStyleCss={{w:'60%'}}
            />
            <Box onClick={onToggle}>
              <Image
                w='25px'
                cursor='pointer'
                src={isOpen ? "assets/svgs/outline-f.svg" : "assets/svgs/filled-f.svg" }
              />
            </Box>
          <Box w="30%" display="flex" gap="10px">
            <PrimaryButton buttonText={<Image filter={COLORS.FILTER_WHITE} src='/assets/svgs/search.svg' />} id="filterButton" variant='mdBtn' onClick={handleApplyFilters} btnStyle={{p:'0'}} />
            <PrimaryButton buttonText={<Image src='/assets/svgs/refresh.svg' />} id="resetButton" variant="grayBtn" btnStyle={{ color: "#AA2F27",p:'0' }} onClick={resetFields} />
          </Box>
          </Box>
        {/* {filterOpen && (
          <> */}
          <Collapse in={isOpen} animateOpacity zIndex='9999' pos='relative' style={{overflow:'visible'}}>
            <Box w="100%" display="flex" p='1rem' pt='0' flexDir='column' zIndex='9999' pos='relative' gap="10px">
              <DatePicker
                // defaultDate={procedureDate}
                onDateChange={(selectedDateValue) =>
                  handleFromDate(selectedDateValue)
                }
                placeholderProp="From Date"
                datePickerInputStyle={{ borderRadius: "10px 10px 10px 10px" }}
                value={fromDate} 
                clearValue={true}

                // CUSTOM_DISABLE={true}
                disableFutureDates={true}
                maxDate={toDateMinMax}
              />
              <DatePicker
                onDateChange={(selectedDateValue) =>
                  handleToDate(selectedDateValue)
                }
                placeholderProp="To Date"
                datePickerInputStyle={{ borderRadius: "10px 10px 10px 10px " }}
                value={toDate} 
                clearValue={true}
                CUSTOM_DISABLE={true}
                maxDate={new Date().toISOString()} 
                minDate={fromDateMinMax}
              />
            </Box>
          </Collapse>
          {/* </>
        )} */}
        <Divider />
        <Flex w='100%' gap='1rem' zIndex='1' p='1rem' flexDir='column' maxH="calc(100vh - 275px)" overflow='hidden auto'>
        {totalRevenueData && totalRevenueData.length > 0 ? (
          totalRevenueData.map((step, index) => (
          <Card
            borderRadius="12px"
            key={index}
            w="100%"
            border="1px solid #E6E7E9"
            boxShadow="0px 8px 20px 0px #E6E7E966"
          >
            <CardBody p={{base: "0.875rem",lg: "0.875rem", xl: "1.25rem"}}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb="0.75rem"
              >                  
                <Box cursor="pointer" display="flex" alignItems="center">
                  <Box boxSize="2.75rem">
                    <Image 
                      boxSize="40px" 
                      h="40px" 
                      objectFit="cover"
                      src={
                        step.patientimage && step.patientimage != 'null'
                          ? (step.patientimage.startsWith('http')|| step.patientimage.startsWith('https'))
                            ? step.patientimage
                            : `${step.imagepath}${step.patientimage}`
                          : '/assets/imgs/no-image.png'
                      }
                      borderRadius="full" 
                    />
                  </Box>
                  <Box display="flex" flexDirection="column" justifyContent="center">
                    <Text fontSize="15px" fontWeight="600" className="textOverflow" title={step.patientname} maxW={{base: "180px", xl:"110px"}}>
                    {step.patientname ? step.patientname : "-"}
                    </Text>
                  </Box>
                </Box>
                  
              </Box>
              <Flex w='100%' mb="0.75rem" justifyContent='space-between'>
                <Box display='flex' flexDir='column'>
                
                    <Text fontSize="10px" color="gray.500">
                      Email
                    </Text>
                    <Text fontSize="12px" fontWeight="600">
                      {step.email ? step.email : '-'}
                    </Text>
                    
                  </Box>
                  <Box display='flex' flexDir='column' alignItems="center">
                
                <Text fontSize="10px" color="gray.500">
                  Mobile No.
                </Text>
                <Box
                    as="button"
                  >
                    <Text fontSize="12px" fontWeight="600">
                    {step.mobileno ? (step.mobileno): ('-')}
                </Text>
                  </Box>
                
              
              </Box>
              </Flex>
              
              <Flex w='100%'>
                <Box w='100%' display='flex' flexDir='column'>
                
                    <Text fontSize="10px" color="gray.500">
                      Amount
                    </Text>
                    <Text fontSize="12px" fontWeight="600">
                      {step.amount ? `$ ${formatToDecimal(step.amount)}` : ('-')}
                    </Text>
                    
                  </Box>
                  <Box w='100%' display='flex' flexDir='column' alignItems="center">
                
                <Text fontSize="10px" color="gray.500">
                  Payment date
                </Text>
                <Box
                    as="button"
                  >
                    <Text fontSize="12px" fontWeight="600">
                    {step.paymentdate ? formatDate(step.paymentdate) : ('-')}
                </Text>
                  </Box>
                
              
              </Box>
                  

                <Box w='100%' display='flex' flexDir='column' alignItems='flex-end'>
                    <Text fontSize="10px" color="gray.500">
                      Payment type
                    </Text>
                    <Text fontSize="12px" fontWeight="600">
                      {step.paymenttype ? (step.paymenttype) : ('-')}
                    </Text>
                  </Box>
              </Flex>
            
            </CardBody>

          </Card>
        ))) : (
          <Text>No data available</Text>
        )}
        </Flex>
        </>
      )}

    </>
  )
}

export default TotalRevenueIndex;
