import {FeeStructureAPIModel, InvoiceProfileAPIModel, ProfileAPIModel, resultbranchAPIModel, SpecialityProfileAPIModel, SubscriptionProfileAPIModel} from "./profile";
import  {DocumentProfileAPIModel } from "./profile";
import { getLoginID } from "../../../auth";
import FetchData from "../../client";



var outputdata = null;
var ProfileOutput = null;
var DocumentOutput = null;
var SpecialityOutput = null;
var SubscriptionOutput = null;
var InvoiceOutput = null;
var DoctorProfileOutput = null;
var FeeOutput = null;


//Profile data get
const getProfileOutput = (data) => {
  outputdata = data;
  console.log(outputdata, "outputdata");

  // Apply ProfileAPIModel to each item in the data array
  ProfileOutput = outputdata.output.data.map(item => ProfileAPIModel(item));
  // console.log(ProfileOutput, "ProfileOutput");

  return ProfileOutput;
};

//Document data get
const getDocumentOutput = (data) => {
  outputdata = data;
  // console.log(outputdata, "outputdata");

  // Apply DocumentProfileAPIModel to each item in the document array
  DocumentOutput = outputdata.map(item => DocumentProfileAPIModel(item));
  // console.log(DocumentOutput, "DocumentOutput");

  return DocumentOutput;
};

export const feeStrucutreAPIMODAL = (data) => {
  outputdata = data;
  FeeOutput = outputdata.map(item => FeeStructureAPIModel(item));
  return FeeOutput;
};
export const resultbranchAPIMODAL = (data) => {
  outputdata = data;
  FeeOutput = outputdata.map(item => resultbranchAPIModel(item));
  return FeeOutput;
};
//Speciality data get
const getSpecialityOutput = (data) => {
  outputdata = data;
  // console.log(outputdata, "outputdata");

  // Apply DocumentProfileAPIModel to each item in the document array
  SpecialityOutput = outputdata.map(item => SpecialityProfileAPIModel(item));
  // console.log(SpecialityOutput, "SpecialityOutput");

  return SpecialityOutput;
};

//Subscription data get
const getSubscriptionOutput = (data) => {
  outputdata = data;
  // console.log(outputdata, "outputdata");

  // Apply DocumentProfileAPIModel to each item in the document array
  SubscriptionOutput = outputdata.map(item => SubscriptionProfileAPIModel(item));
  // console.log(SubscriptionOutput, "Subscription Output");

  return SubscriptionOutput;
};

//Invoice data get
const getInvoiceOutput = (data) => {
  outputdata = data;
  // console.log(outputdata, "outputdata");

  // Apply InvoiceProfileAPIModel to each item in the document array
  InvoiceOutput = outputdata.map(item => InvoiceProfileAPIModel(item));
  // console.log(InvoiceOutput, "Invoice Output");

  return InvoiceOutput;
};

//Profile data update
const updateProfileOutput = (data) => {
  // Assuming you have a similar structure for the update function
  // Apply UpdateProfileAPIModel to each item in the data array
  const updatedProfileOutput = data.output.data.map(item => ProfileAPIModel(item));
  console.log(updatedProfileOutput, "Updated Profile Output");

  return updatedProfileOutput;
};

//Profile Address get 
const AddressProfileOutput = (data) => {
  outputdata = data;
  console.log(outputdata, "outputdata");

  // Apply ProfileAPIModel to each item in the data array
  ProfileOutput = outputdata.output.data.map(item => ProfileAPIModel(item));
  console.log(ProfileOutput, "ProfileOutput");

  return ProfileOutput;
};

// Doctor unique id for doctor website
const DoctorIdOutput = (data) => {
  outputdata = data
  DoctorProfileOutput = outputdata.uniqueid;
  // console.log(DoctorProfileOutput, 'doctor unique id check')
  return DoctorProfileOutput;
}


const uploadDocumentInProfile = async (inputData) => {
  try {
    inputData.loginid = getLoginID();
    inputData.doctorid = getLoginID();
    const result = await FetchData("midoc_adddoctordocument", inputData);

    //console.log(result , " db response sign up professional detaisl")
    return result;

  } catch (error) {
    console.error('Error fetching data from DB signup professional details', error);
    throw error;
  }
};

export { getProfileOutput,getDocumentOutput,getSpecialityOutput,getSubscriptionOutput,getInvoiceOutput, updateProfileOutput, AddressProfileOutput , uploadDocumentInProfile,DoctorIdOutput};

