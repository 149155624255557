import FetchData from "../../client";

// Doctor website details
const doctorWebDetails = async (inputData) => {
    try {
        // console.log(inputData, 'input data check');
        const result = await FetchData("midoc_getdoctorwebsite", inputData);
        // console.log(result, 'website details');
        return result;
    } catch (error) {
        console.error('Error fetching doctor website details:', error);
        throw error;
    }
}

export { doctorWebDetails };
