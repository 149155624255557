import React, { useState, useEffect, useRef } from "react";
import styles from "../../../../../pages/visits/styles/startvisit.module.css";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Button,
  Badge,
  Image,
  Flex,
} from "@chakra-ui/react";

import {
  updateDoctorNotes,
  GetDoctorMasterData,
  GetDoctorNotes,
} from "../../../../../middleware/services/visits/patient-overview/updatedoctornotes";
import PrimaryButton from "../../../../primary-button";
import { COLORS } from "../../../../styles/colors";
import Loader from "../../../../loader/loader";
import useLoader from "../../../../loader/loaderstates";

const AIPhysicalExamCard = ({
  patientId,
  doctorId,
  appointmentId,
  showSuccessToast,
  showErrorToast,
  aiResponsePhysicalExam,
}) => {
  const [doctorMasterPhysicalExamData, setDoctorMasterPhysicalExamData] =
    useState([]);
  const [selectedConditions, setSelectedConditions] = useState({});
  const [changesMade, setChangesMade] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true); // Track initial load
  const [timestamp, setTimestamp] = useState(null); // State to store timestamp of button click
  // const [expandedSections, setExpandedSections] = useState([]);

  const tabListRefPhysicalExam = useRef(null);

  const { isLoading, showLoader, dismissLoader } = useLoader();

  useEffect(() => {
    fetchPhysicalExamMasterData();
    fetchDoctorNotes();
  }, []);

  const fetchPhysicalExamMasterData = async () => {
    showLoader();
    try {
      const inputData = {
        input: { type: "physicalexam" },
      };
      const data = await GetDoctorMasterData(inputData.input);
      setDoctorMasterPhysicalExamData(data);
    } catch (error) {
      console.error("Error fetching Physical Exam Master data:", error);
    } finally {
      dismissLoader();
    }
  };

  function transformPhysicalExam(inputPhysicalExam) {
    return inputPhysicalExam.map((item) => {
      const type = Object.keys(item)[0];
      const conditions = item[type];
      return {
        type: type,
        physicalexam: conditions.map((condition) => ({ value: condition })),
      };
    });
  }

  const fetchDoctorNotes = async () => {
    const inputToFetch = {
      input: {
        patientid: patientId,
        doctorid: doctorId,
        appointmentid: appointmentId,
      },
    };
    showLoader();
    try {
      //   const data = await GetDoctorNotes(inputToFetch.input);
      const physicalExam = transformPhysicalExam(aiResponsePhysicalExam) || [];
      const initialSelectedConditions = physicalExam.reduce(
        (acc, { type, physicalexam }) => {
          acc[type] = physicalexam.reduce((acc, { value }) => {
            acc[value] = { condition: value }; // Use condition value as key
            return acc;
          }, {});
          return acc;
        },
        {}
      );
      setSelectedConditions(initialSelectedConditions);
      setInitialLoad(false); // Mark the initial load as complete
    } catch (error) {
      console.error("Error fetching Doctor Notes data:", error);
    } finally {
      dismissLoader();
    }
  };

  const transformStateToAPIFormat = (state, timestamp) => {
    return Object.keys(state)
      .map((conditionType) => ({
        type: conditionType,
        value: Object.values(state[conditionType]).map((condition) => ({
          value: condition.condition,
        })),
        // DATE: timestamp, // uncomment it to save the current time stamp
      }))
      .filter((condition) => condition.value.length > 0); // Remove entries with empty value
  };

  const UpdatePhysicalExam = async () => {
    const currentTimestamp = new Date().toISOString(); // Capture the current timestamp
    setTimestamp(currentTimestamp);

    const inputToSave = {
      input: {
        doctorid: doctorId,
        patientid: patientId,
        appointmentid: appointmentId,
        physicalexam: transformStateToAPIFormat(
          selectedConditions,
          currentTimestamp
        ),
      },
    };
    showLoader();
    try {
      // console.log(inputToSave);
      const result = await updateDoctorNotes(inputToSave.input);
      // console.log(result);
      if (result) {
        showSuccessToast("Physical Exam Saved successfully");
        setChangesMade(false); // Reset changesMade state
        setSelectedConditions({}); // Reset selectedConditions
        fetchDoctorNotes();
      }
    } catch (error) {
      console.error("Error in Update Doctor Notes:", error);
      showErrorToast("Error Happened While Saving.");
    } finally {
      dismissLoader();
    }
  };

  const handleConditionButtonClick = (type, condition) => {
    setSelectedConditions((prev) => {
      const currentTypeSelections = prev[type] || {};
      const isSelected = currentTypeSelections[condition.condition];

      let newState;
      if (isSelected) {
        const { [condition.condition]: removed, ...rest } =
          currentTypeSelections;
        newState = { ...prev, [type]: rest };
      } else {
        newState = {
          ...prev,
          [type]: {
            ...currentTypeSelections,
            [condition.condition]: condition,
          },
        };
      }

      const hasSelections = Object.values(newState).some(
        (conditions) => Object.keys(conditions).length > 0
      );

      setChangesMade(hasSelections);

      return newState;
    });
  };

  const handleTabClick = (index) => {
    setExpandedSections((prev) => {
      const isExpanded = prev.includes(index);
      if (isExpanded) {
        return prev.filter((i) => i !== index);
      } else {
        return [...prev, index];
      }
    });

    // if (tabListRefPhysicalExam.current) {
    //   tabListRefPhysicalExam.current.scrollTo({ left: 0, behavior: "smooth" });
    // }
  };

  // Code to expand panels on initial loading
  const [expandedSections, setExpandedSections] = useState([]);

  useEffect(() => {
    const getInitialExpandedSections = () => {
      const expandedIndices = [];
      if (
        doctorMasterPhysicalExamData &&
        doctorMasterPhysicalExamData.length > 0
      ) {
        doctorMasterPhysicalExamData.forEach((section, index) => {
          const hasData = section.data.some(
            (condition) => selectedConditions[section.conditiontype]
          );
          if (hasData) expandedIndices.push(index);
        });
      }
      return expandedIndices;
    };

    setExpandedSections(getInitialExpandedSections());
  }, [doctorMasterPhysicalExamData, selectedConditions]);

  // Code for scroll tabs
  const scrollLeft = () => {
    if (tabListRefPhysicalExam.current) {
      tabListRefPhysicalExam.current.scrollBy({
        left: -200,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (tabListRefPhysicalExam.current) {
      tabListRefPhysicalExam.current.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    }
  };

  return (
    <Box
      position="relative"
      height="100%"
      display="flex"
      flexDirection="column"
    >
      {isLoading && (
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex="10"
          bg="rgba(255, 255, 255, 0.8)"
        >
          <Loader miniLoader={true} />
        </Box>
      )}
      <Box
        overflow="hidden"
        position="relative"
        border="1px solid #e2e8f0"
        bg="#fcfcfd"
      >
        <Box
          as="button"
          className={`${styles.tabArrow} ${styles.tabArrowLeft}`}
          onClick={scrollLeft}
        >
          <Image src="/assets/svgs/left-tab-icon.svg" />
        </Box>
        <Box
          as="button"
          className={`${styles.tabArrow} ${styles.tabArrowRight}`}
          onClick={scrollRight}
        >
          <Image src="/assets/svgs/right-tab-icon.svg" />
        </Box>
        <Box
          ref={tabListRefPhysicalExam}
          ml="32px"
          mr="32px"
          mb="12px"
          mt="12px"
          className="scrollContainer"
          display="flex"
          overflowX="hidden"
          bg="#fcfcfd"
        >
          <Flex>
            {doctorMasterPhysicalExamData.map((section, index) => (
              <Box
                key={index}
                bg="#fcfcfd"
                className={`${styles.tabButton} ${
                  expandedSections.includes(index)
                    ? styles.physicalExamDoctorNotesActiveTb
                    : ""
                }`}
                onClick={() => handleTabClick(index)}
                mx="2"
                p="2"
                border="1px solid #e2e8f0"
                borderRadius="full"
                cursor="pointer"
                fontSize="sm"
                _hover={{ borderColor: "black" }}
              >
                {section.conditiontype}
              </Box>
            ))}
          </Flex>
        </Box>
      </Box>
      <Accordion
        flex="1"
        overflowY="auto"
        allowMultiple // Allow multiple sections to be expanded simultaneously
        index={expandedSections}
        onChange={setExpandedSections}
      >
        {doctorMasterPhysicalExamData.map((section, index) => (
          <AccordionItem key={index}>
            <AccordionButton
              bg="white"
              fontSize="md"
              position="relative"
              _expanded={{ bg: "#e6e7e9", color: "black" }} // to highlight selected Button
            >
              <Box flex="1" textAlign="left">
                {section.conditiontype}
                {selectedConditions[section.conditiontype] &&
                  Object.keys(selectedConditions[section.conditiontype])
                    .length > 0 && (
                    <Badge
                      color="gray"
                      position="absolute"
                      right="2.8rem"
                      top="50%"
                      transform="translateY(-50%)"
                      bg="white"
                    >
                      {
                        Object.keys(selectedConditions[section.conditiontype])
                          .length
                      }{" "}
                      Selected
                    </Badge>
                  )}
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4} bg="#fcfcfd">
              {section.data.map((condition) => {
                const isSelected =
                  selectedConditions[section.conditiontype] &&
                  selectedConditions[section.conditiontype][
                    condition.condition
                  ];
                return (
                  <Button
                    key={condition.condition}
                    borderRadius="full"
                    onClick={() =>
                      handleConditionButtonClick(
                        section.conditiontype,
                        condition
                      )
                    }
                    bg={isSelected ? "black" : "white"}
                    color={isSelected ? "white" : "black"}
                    leftIcon={
                      isSelected ? (
                        <Image src="/assets/svgs/white-ok.svg" boxSize="1em" />
                      ) : null
                    }
                    variant="solid"
                    border={isSelected ? "none" : "1px solid black"}
                    size="sm"
                    m={1}
                    isDisabled={initialLoad && isSelected} // Disable button if it's prepopulated
                  >
                    {condition.condition}
                  </Button>
                );
              })}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
      {/* <Button
        position="sticky"
        bottom="0"
        width="95%"
        colorScheme="teal"
        onClick={UpdatePhysicalExam}
        zIndex={1}
        isDisabled={!changesMade}
        mx="auto"
      >
        Save Physical Exam
      </Button> */}
      <PrimaryButton
        buttonText="Save Physical Exam"
        onClick={UpdatePhysicalExam}
        // isDisabled={!changesMade}
        btnStyle={{
          h: "2.5rem",
          w: "200px",
          mt: "2rem",
          mx: "auto",
          p: "0",
          // mr: "12px",
          // ml: "12px",
          //   position: "sticky",
          // bottom: "0",
          // mx: "auto",
          // zIndex: "1",
          bg: COLORS.PRIMARY_GRADIENT,
          color: COLORS.PRIMARY_WHITE,
          _hover: { bg: COLORS.PRIMARY_COLOR },
        }}
      />
    </Box>
  );
};

export default AIPhysicalExamCard;
