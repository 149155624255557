import React, { useEffect, useRef } from 'react';
import { Column } from '@antv/g2plot';

const PatientTurnUpChart = ({ data, height }) => {
  // console.log(data);
  const chartRef = useRef(null);

  useEffect(() => {
    // const data = [
    //   { time: '1:00', patients: 15 },
    //   { time: '2:00', patients: 18 },
    //   { time: '3:00', patients: 17 },
    //   { time: '4:00', patients: 22 },
    //   { time: '5:00', patients: 11 },
    //   { time: '6:00', patients: 14 },
    //   { time: '7:00', patients: 5 },
    //   { time: '8:00', patients: 18 },
    //   { time: '9:00', patients: 16 },
    //   { time: '10:00', patients: 8 },
    // ];
    // console.log('PatientTUrnup Data: ', data)
    // Add an index to each data point to ensure uniqueness
    const indexedData = data.map((item, index) => ({
      ...item,
      index,
    }));

    const columnPlot = new Column(chartRef.current, {
      data: indexedData,
      xField: 'label', // Use index as the xField to ensure uniqueness
      yField: 'patients', // value --> patients
      label: {
        position: 'middle',
        style: {
          fill: '#FFFFFF',
          opacity: 0.6,
        },
      },
      xAxis: {
        label: {
          formatter: (text, item, index) => data[index].label, // Show original label on the x-axis
          autoHide: true,
          autoRotate: false,
        },
      },
      yAxis: {
       
        title: {
          text: 'No. of patients',
        },
      },
      columnStyle: {
        radius: [7, 7, 0, 0],
      },
      columnWidthRatio: 0.6,
      color: '#1C4EC5',
      slider: {
        start: 0,
        end: 1,
      },
      interactions: [{ type: 'active-region' }],
    });

    columnPlot.render();

    return () => {
      columnPlot.destroy();
    };
  }, [data]);

  return <div ref={chartRef} style={{ height }} />;
};

export default PatientTurnUpChart;
