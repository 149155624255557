export default function formAPIMedicinesView({
    id,
    doctorid,
    medicineid,
    medicinename,
    medicinetype,
    dosage,
    dosageunit,
    medicationstartdate,
    medicationenddate,
    frequency,
    morning,
    afternoon,
    evening,
    beforefood,
    afterfood,
    prescribedby,
    prescribedhospitalname,
    iscurrentmedicine,
    medicationstatus_cd,
    medicineunit,
    night,
    chemicalcomposition,
    benefit,
    sideeffects,
    modifieddate
    
  }) {
   
  
    return Object.freeze({
        id,
    doctorid,
    medicineid,
    medicinename,
    medicinetype,
    dosage,
    dosageunit,
    medicationstartdate,
    medicationenddate,
    frequency,
    morning,
    afternoon,
    evening,
    beforefood,
    afterfood,
    prescribedby,
    prescribedhospitalname,
    iscurrentmedicine,
    medicationstatus_cd,
    medicineunit,
    night,
    chemicalcomposition,
    benefit,
    sideeffects,
    modifieddate
    
    });
  }
  