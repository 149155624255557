import React, { useState, useRef, useEffect } from "react";
import { Box, Text, Image, Circle, Center, Flex } from "@chakra-ui/react";
import moment from "moment";
import { PrimaryButton, Toaster, PrimaryInput } from "../../../components";
import DynamicModal from "../../../components/dynamic-modal";
import DatePicker from "../../../components/date-picker";
import ModalRight from "../../../components/modal-right";
import { updateCondition } from "../../../middleware/services/visits/patient-overview/updatehealth";

import ReactMarkdown from "react-markdown";
import {
  FetchConditionAIPrompts,
  FetchConditionAIAnswer,
  FetchMyHealthAIData,
} from "../../../middleware/services/visits/aicalls";
import TypingLoader from "../../../components/typing-loader";
import AiModal from "../../../components/ai-modal/index";

const formatDate = (dateString) => {
  if (!dateString) return "-";
  return moment(dateString).format("Do MMM, YYYY");
};

const MedicalHistory = ({
  patientId,
  patientname,
  patientgender,
  patientage,
  doctorId,
  appointmentId,
  condition,
  date,
  id,
  condition_cd,
  conditiontype_cd,
  conditiontype,
  fetchPatientHealthData,
  showLoader,
  dismissLoader,
  showSuccessToast,
  showErrorToast,
  scaleHeight = "100px",
  componentBg = "#fcfcfd",
  cardBg = "white",
}) => {
  const dynamicModalMedicalCondition = useRef();
  const editMedicalConditionModal = useRef();

  const [patientMedicalConditionId, setPatientMedicalConditionId] =
    useState(id);
  const [patientMedicalConditionName, setPatientMedicalConditionName] =
    useState(condition);
  const [patientMedicalConditionDate, setPatientMedicalConditionDate] =
    useState("");
  const [patientMedicalConditionTypeID, setPatientMedicalConditionTypeID] =
    useState(conditiontype_cd);
  const [
    patientMedicalConditionConditionID,
    setPatientMedicalConditionConditionID,
  ] = useState(condition_cd);

  //-------------AI Insights

  const chatContainerRef = useRef(null);
  const [aianswers, setAIAnswers] = useState([]);
  const [remainingquestions, setRemainingQuestions] = useState([]);
  const [aiquestion, setAIAnalysis] = useState(null);
  const [AiInput, setAiInput] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  const [animate, setAnimate] = useState(false);
  const AIChatModel = useRef();

  useEffect(() => {
    if (animate) {
      const timer = setTimeout(() => {
        setAnimate(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [animate]);

  useEffect(() => {
    // Scroll to bottom whenever aianswers changes
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [aianswers]);
  const fetchAiDoctorPrompts = async (conditionname) => {
    showLoader();
    setRemainingQuestions([]);
    setAiInput(null);
    setAIAnswers([]);
    setInitialRender(true);
    const result = await FetchMyHealthAIData(
      {},
      patientId,
      patientname,
      patientgender,
      patientage
    );
    // console.log("result send", result);
    let Aiprompts = { ...result, conditionname };
    setAiInput(Aiprompts);
    const response = await FetchConditionAIPrompts(Aiprompts);
    if (response.generated_question) {
      setAIAnalysis(response);
      setRemainingQuestions(response.generated_question);
      AIChatModel.current.openRight();
    } else {
      showErrorToast("Ai tokens are not available");
      AIChatModel.current.closeRight();
    }
    dismissLoader();
  };

  const GetAIAnswer = async (prompt) => {
    // Display question immediately
    const newQuestion = { question: prompt, answer: "" };
    setAIAnswers((prevState) => [...prevState, newQuestion]);

    // Remove the question from remaining questions immediately
    setRemainingQuestions((prevState) =>
      prevState.filter((question) => question.value !== prompt)
    );

    // Trigger animation
    setAnimate(true);

    // Set initialRender to false after the first question is clicked
    setInitialRender(false);
    let AichatInput = { ...AiInput, query: prompt };

    const response = await FetchConditionAIAnswer(AichatInput);

    if (response) {
      // Replace "typing" with actual answer
      setAIAnswers((prevState) =>
        prevState.map((item) =>
          item.question === prompt && item.answer === ""
            ? { question: item.question, answer: response.answer }
            : item
        )
      );
    } else {
      showErrorToast("Ai tokens are not available");
      AIChatModel.current.closeRight();
    }
  };

  //-------------Other functionalities

  const openEditModal = (
    condition,
    conditionid,
    date,
    id,
    conditiontype_cd
  ) => {
    setPatientMedicalConditionId(id);
    setPatientMedicalConditionTypeID(conditiontype_cd);
    setPatientMedicalConditionName(condition);
    setPatientMedicalConditionConditionID(conditionid);
    const conditiondate = moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
    setPatientMedicalConditionDate(conditiondate);
    if (editMedicalConditionModal.current) {
      editMedicalConditionModal.current.openRight();
    }
  };

  const openDeleteModal = (id, condition) => {
    setPatientMedicalConditionId(id);
    setPatientMedicalConditionName(condition);
    if (dynamicModalMedicalCondition.current) {
      dynamicModalMedicalCondition.current.openModal();
    }
  };

  const UpdateCondition = async () => {
    const inputForEdit = {
      input: {
        doctorid: doctorId,
        patientid: patientId,
        type: "medicalhistory",
        appointmentid: appointmentId,
        medicalhistoryid: patientMedicalConditionId,
        conditiontype_cd: patientMedicalConditionTypeID,
        condition_cd: patientMedicalConditionConditionID,
        conditionidentifieddate: patientMedicalConditionDate,
        medicalhistoryactive: 1,
      },
    };

    // console.log("Input send", inputForEdit.input);

    if (
      moment(
        inputForEdit.input.conditionidentifieddate,
        "DD-MM-YYYY",
        true
      ).isValid()
    ) {
      inputForEdit.input.conditionidentifieddate = moment(
        inputForEdit.input.conditionidentifieddate,
        "DD-MM-YYYY"
      ).format("YYYY-MM-DD");
    }

    if (inputForEdit.input.conditionidentifieddate === "") {
      showErrorToast("Please select any date");
      return false;
    } else {
      showLoader();
      try {
        const result = await updateCondition(inputForEdit.input);
        if (result) {
          showSuccessToast("Condition updated successfully");
          fetchPatientHealthData();
          editMedicalConditionModal.current.closeRight();
        }
      } catch (error) {
        console.error("Error in UpdateCondition:", error);
      }
      dismissLoader();
    }
  };

  const deleteMedicalCondition = async () => {
    const inputForDelete = {
      input: {
        doctorid: 255,
        patientid: 917,
        type: "medicalhistory",
        appointmentid: 517,
        medicalhistoryid: patientMedicalConditionId,
        medicalhistoryactive: 0,
      },
    };

    const deleteCondition = async () => {
      showLoader();
      try {
        const result = await updateCondition(inputForDelete.input);
        if (result.output.result === "success") {
          showSuccessToast(patientMedicalConditionName + " has been deleted");
          fetchPatientHealthData();
          dynamicModalMedicalCondition.current.closeModal();
        } else {
          console.log(result.output.result);
          showErrorToast("Something went wrong!");
        }
      } catch (error) {
        console.error("Error in deleteMedicalCondition:", error);
      }
      dismissLoader();
    };
    deleteCondition();
  };

  return (
    <Box
      display="flex"
      flexDir="column"
      alignItems="center"
      h={scaleHeight}
      w="100%"
      bg={componentBg}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        w="100%"
      >
        <Box display="flex" alignItems="center" w="100%">
          <Circle
            size="1rem"
            bg="white"
            border="2px solid #CDCFD4"
            borderRadius="full"
            color="black"
          />
          <Text
            fontSize="12px"
            fontWeight="500"
            textAlign="left"
            color="#384052"
            ml="0.5rem"
          >
            {formatDate(date)}
          </Text>
        </Box>
      </Box>
      <Box display="flex" w="100%" mt="10px">
        <Center
          w="100%"
          h="6.125rem"
          ml="0.45rem"
          alignItems="start"
          borderLeft="2px solid #E6E7E9"
        >
          <Box
            p="20px"
            w="100%"
            h="50px"
            ml="10px"
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            border="1px solid #E6E7E9"
            borderRadius="12px"
            backgroundColor={cardBg}
          >
            <Box display="flex" alignItems="center">
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                maxW="350px"
              >
                <Text
                  title={condition}
                  fontSize="16px"
                  textAlign="left"
                  fontWeight="600"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  w={{ base: "100px", md: "230px" }}
                >
                  {condition}
                </Text>
              </Box>
            </Box>
            <Box display="flex" alignItems="center">
              <Box
                as="button"
                // onClick={}
                border="1px solid #E6E7E9"
                borderRadius="full"
                w="36px"
                h="auto"
                p="10px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                onClick={() => fetchAiDoctorPrompts(condition)}
              >
                <Image w="26px" src="/assets/svgs/ai-star-outline.svg" />
              </Box>
              <Box
                as="button"
                border="1px solid #E6E7E9"
                borderRadius="full"
                w="36px"
                h="auto"
                p="10px"
                ml="10px"
                onClick={() =>
                  openEditModal(
                    condition,
                    patientMedicalConditionConditionID,
                    date,
                    id,
                    patientMedicalConditionTypeID
                  )
                }
              >
                <Image w="13px" src="/assets/svgs/edit-gray.svg" />
              </Box>
              <Box
                as="button"
                border="1px solid #E6E7E9"
                borderRadius="full"
                w="36px"
                h="auto"
                p="10px"
                ml="10px"
                onClick={() => openDeleteModal(id, condition)}
              >
                <Image w="13px" src="/assets/imgs/delete.png" />
              </Box>
            </Box>
          </Box>
        </Center>
      </Box>

      {/* DynamicModal for deletion */}
      <DynamicModal
        ref={dynamicModalMedicalCondition}
        modalHeader="Delete report?"
        modalBody={
          <>
            <Text fontSize="14px" fontWeight="600">
              Are you sure you want to delete "{patientMedicalConditionName}"?
            </Text>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="deleteBtn"
              buttonText="Delete"
              onClick={deleteMedicalCondition}
            />
          </>
        }
      />

      {/* ModalRight for editing */}
      <ModalRight
        ref={editMedicalConditionModal}
        header="Edit condition"
        body={
          <>
            <Box display="flex" flexDir="column" gap="20px">
              <Box>
                <Text fontSize="16px" py="10px" fontWeight="600">
                  {patientMedicalConditionName}
                </Text>
                <DatePicker
                  disableFutureDates={true}
                  defaultDate={patientMedicalConditionDate}
                  onDateChange={(e) => setPatientMedicalConditionDate(e)}
                  placeholderProp="Select a Date"
                  calendarStyle={{
                    transform: "scale(0.9)",
                    right: "-20px",
                    top: "30px",
                  }}
                />
              </Box>
            </Box>
          </>
        }
        footerActions={
          <>
            <Box w="100%" display="flex" flexDirection="row" gap="15px">
              <PrimaryButton
                buttonText="Update Condition"
                isDisabled={false}
                onClick={UpdateCondition}
              />
            </Box>
          </>
        }
      />
      <AiModal
        ref={AIChatModel}
        modalSize="540px"
        //icon="/assets/imgs/ai-doctor.png"
        header={
          <>
            <Box display="flex" flexDir="column">
              <Text
                fontSize="1rem"
                fontWeight="700"
                backgroundImage="linear-gradient(to right top, #0d7e54, #007774, #006d94, #005ea7, #1146a0)"
                color="transparent"
                backgroundClip="text"
              >
                Aira - AI
              </Text>
              <Text fontSize="0.75rem" fontWeight="400">
                Chat with your AI health guide
              </Text>
            </Box>
          </>
        }
        headerStyle={{ p: "24px 32px", lineHeight: "normal" }}
        closeBtnStyle={{ top: "18px" }}
        footerStyle={{
          maxH: "35%",
          overflow: "hidden auto",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        }}
        withoutBg={true}
        backDropBlur="10px"
        body={
          <>
            <Flex flexDirection="column" height="100%">
              <Box
                p="24px 32px"
                ref={chatContainerRef}
                className="chat-container"
                style={{ overflowY: "auto", maxHeight: "calc(100vh - 160px)" }}
              >
                {initialRender && remainingquestions.length > 0 && (
                  <Box>
                    {remainingquestions.map((step, index) => (
                      <Box
                        key={index}
                        as="button"
                        w="100%"
                        p="16px"
                        mb="16px"
                        fontSize="14px"
                        fontWeight="600"
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        borderRadius="10px"
                        border="1px solid #7795DC"
                        bg="#EAEDF5"
                        color="#163E9E"
                        onClick={() => GetAIAnswer(step.value)}
                        className={`question-box ${animate ? "slide-in" : ""}`}
                      >
                        {step.value ? step.value : "-"}
                        <Image src="/assets/imgs/right-icon.png" ml={"10px"} />
                      </Box>
                    ))}
                  </Box>
                )}
                {aianswers.map((step, index) => (
                  <React.Fragment key={index}>
                    <Box
                      as="div"
                      bg="#061027"
                      p="12px 16px"
                      w="fit-content"
                      float="right"
                      borderRadius="10px 10px 1px 10px"
                      className={`answer-box ${animate ? "slide-in" : ""}`}
                    >
                      <Text fontSize="14px" fontWeight="500" color="white">
                        {step.question ? step.question : "-"}
                      </Text>
                    </Box>

                    <Box
                      as="div"
                      my="20px"
                      w="fit-content"
                      display="flex"
                      alignItems="end"
                      float="left"
                      className={`answer-box ${animate ? "slide-in" : ""}`}
                    >
                      <Image
                        bg="#061027"
                        p="4px"
                        mr="8px"
                        borderRadius="6px"
                        src="/assets/imgs/ai-chat-logo.png"
                      />
                      <Text
                        bg="#E6E7E980"
                        p="12px 16px"
                        fontSize="14px"
                        fontWeight="500"
                        color="#061027"
                        borderRadius="10px 10px 10px 1px"
                      >
                        {/* {step.answer ? step.answer : <TypingLoader />} */}
                        {step.answer ? (
                          <ReactMarkdown>{step.answer}</ReactMarkdown>
                        ) : (
                          <TypingLoader />
                        )}
                      </Text>
                    </Box>
                  </React.Fragment>
                ))}
              </Box>
            </Flex>
          </>
        }
        footerActions={
          !initialRender && (
            <Box
              p="24px 32px"
              display="flex"
              w="100%"
              h="100%"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Box w="100%">
                {remainingquestions.map((step, index) => (
                  <Box
                    key={index}
                    as="button"
                    w="100%"
                    p="16px"
                    mb="16px"
                    fontSize="14px"
                    fontWeight="600"
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    borderRadius="10px"
                    border="1px solid #7795DC"
                    bg="#EAEDF5"
                    color="#163E9E"
                    onClick={() => GetAIAnswer(step.value)}
                    className={`question-box ${animate ? "slide-in" : ""}`}
                  >
                    {step.value ? step.value : "-"}
                    <Image src="/assets/imgs/right-icon.png" ml={"10px"} />
                  </Box>
                ))}
              </Box>
            </Box>
          )
        }
      />
    </Box>
  );
};

export default MedicalHistory;
