import formAPIModelappointments from "../reportappointments/appointments";
import formAPIModelmedicinesprescribed from "../medicinesprescribed/prescribedmedicines";
import formAPIModelpatienttime from "../patienttime/patienttimes";
import formAPIModelpatientturnup from "../patientturnup/patientturnups";


export default function formapimodalreports({
    resultappointments,
    resultmedicinesprescribed,
    resultpatienttime,
    resultpatientturnup,
 
}, type, activeTab, selecteddate)
{
return Object.freeze({
    resultappointments:resultappointments !== null ? formAPIModelappointments(resultappointments, type, activeTab, selecteddate) : [],    
    resultmedicinesprescribed: resultmedicinesprescribed !== null ? formAPIModelmedicinesprescribed(resultmedicinesprescribed, type, activeTab,selecteddate) : [],
    resultpatienttime : resultpatienttime != null ?  formAPIModelpatienttime(resultpatienttime, type, activeTab,selecteddate):[],
    resultpatientturnup : resultpatientturnup !== null ? formAPIModelpatientturnup(resultpatientturnup, type, activeTab,selecteddate) : [],
  
   
});
}