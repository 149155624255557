export default function processSocialHistoryData(socialhistory, visitDate) {
  if (!Array.isArray(socialhistory)) {
    return [];
  }

  const filteredData = socialhistory.filter((history) => {
    if (visitDate) {
      const historyDate = new Date(history.fromdate);
      const visitDateObj = new Date(visitDate);
      return historyDate < visitDateObj;
    }
    return true;
  });

  const processedData = filteredData.map((history) => {
    let values = history.socialhistory;
    if (history.drugnames && history.drugnames.length > 0) {
      values = history.drugnames.map((drug) => drug.name).join(", ");
    }

    return {
      id: history.id,
      type: history.socialhistory,
      socialhistory: history.socialhistory,
      onset: history.fromdate,
      frequency: history.frequency,
      frequencytype: history.frequencytype,
      frequency_display: history.frequency + " per " + history.frequencytype,
      values: values,
      iscurrent: history.iscurrent,
      frequencytype_cd: history.frequencytype_cd,
      fromdate: history.fromdate,
      todate: history.todate,
      patientid: history.patientid,
      familyid: history.familyid,
      socialhistorytype_cd: history.socialhistorytype_cd,
      duration: history.duration,
      durationtype_cd: history.durationtype_cd,
      durationtype: history.durationtype,
      drugtype: history.drugtype,
      drugnames: history.drugnames,
    };
  });

  return processedData.sort(
    (a, b) => new Date(b.fromdate) - new Date(a.fromdate)
  );
}
