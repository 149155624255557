
import formAPIroledetails from "./doctorroledetail";


var formattedOutput = null;

const formAPIModelroledetails = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIroledetails(item));


  return formattedOutput
};

export default formAPIModelroledetails