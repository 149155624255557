// AuthService.js

import axios from "axios";
import { CONFIG } from "./appconfig/appconfig";
import { saveAs } from "file-saver";
import moment from "moment";
import { useBreakpointValue } from '@chakra-ui/react';
import { useEffect, useState } from "react";
import { getCountry } from "./middleware/services/country";

export const saveToken = (token) => {
    localStorage.setItem('token', token);
  };
  
  export const getToken = () => {
    return localStorage.getItem('token');
  };
  
  export const removeToken = () => {
    localStorage.removeItem('token');
  };
  
  export const saveUsername = (username) => {
    localStorage.setItem('username', username);
  };
  export const saveFirstName = (firstname) => {
    localStorage.setItem('firstname', firstname);
  };
  export const saveLastName = (lastname) => {
    localStorage.setItem('lastname', lastname);
  };
  export const savecurrency = (currencyname) => {
    localStorage.setItem('currency', currencyname);
  };
  //Get Name
  export const getFirstName = (firstname) => {
    localStorage.getItem('firstname', firstname);
  };
  export const getLastName = (lastname) => {
    localStorage.getItem('lastname', lastname);
  };
  function getDoctorName () {
    const firstname = localStorage.getItem('firstname');
    const lastname = localStorage.getItem('lastname');
    const doctorname = firstname ? firstname +" "+lastname : "-";  
    return  doctorname ;
  };
  function getCurrency () {
    const jsonData = localStorage.getItem('currency');
    const currency = jsonData ? jsonData : null;  
    return  currency ;
  };
  export function formatToDecimal(value) {
    const strValue = value.toString(); // Convert to string if it's not already
    return strValue.includes('.') ? strValue : strValue + '.00';
  }
  
  export const saveLoginID = (loginid) => {
    localStorage.setItem('loginid', loginid);
  };

  export const getDoctorId = () => {
    return localStorage.getItem('doctorid');
  };
  export const getPatientId = () => {
    return localStorage.getItem('patientid');
  };
  export function getDob() {
    const jsonData = localStorage.getItem('patientDetails');
    const data = JSON.parse(jsonData);  
    const dob = data ? data.dob : null;  
    return  dob ;
  }
  export function getusername() {
    const jsonData = localStorage.getItem('patientDetails');
    const data = JSON.parse(jsonData);
    const patientname = data ? data.firstname : null;
    
    return  patientname ;
  }
  export function getgender() {
    const jsonData = localStorage.getItem('patientDetails');
    const data = JSON.parse(jsonData);
    const gender = data ? data.gender : 2;
    
    return  gender ;
  }
  export function getLoginInfo() {
    const jsonData = localStorage.getItem('patientDetails');
    const data = JSON.parse(jsonData);
    const loginId = data ? data.id : null;
    
    return  loginId ;
  }

  // export const saveFirstTimeLogin = () => {
  //   return localStorage.setItem('firsttimelogin');
  // };
  function getLoginID() {
    const jsonData = localStorage.getItem('loginid');
    const loginId = jsonData ? jsonData : null;
    
    return  loginId ;
  }
  function getUserName() {
    const firstname = localStorage.getItem('firstname');
    const lastname = localStorage.getItem('lastname');
    
      // Check for both null and "null" (as a string)
  const validFirstName = (firstname && firstname !== "null") ? firstname : "";
  const validLastName = (lastname && lastname !== "null") ? lastname : "";
  
  const username = validFirstName + " " + validLastName;
   
    return  username ;
  }

 export const imagePath = "https://midocfileserverdev.blob.core.windows.net/midocdev/"
  export const validationOfNumberWithOnly1Decimal = (inputValue, maxLength) => {
    // Remove all characters that are not digits or a decimal point
    let cleanedValue = inputValue.replace(/[^0-9.]/g, '');
  
    // Remove leading decimal point if present
    if (cleanedValue.startsWith(".")) {
      cleanedValue = cleanedValue.substring(1);
    }
  
    // Handle multiple decimal points
    const decimalIndex = cleanedValue.indexOf('.');
    if (decimalIndex !== -1) {
      const parts = cleanedValue.split('.');
      // Keep only the first two digits after the decimal point
      cleanedValue = parts[0] + '.' + parts[1].slice(0, 2);
    }
  
    // Enforce the max length after cleaning and truncating
    if (cleanedValue.length > maxLength) {
      cleanedValue = cleanedValue.substring(0, maxLength);
    }
  
    return cleanedValue;
  };
  export const customDateValidation = (inputValue,maxLength) => {
    let cleanedValue = inputValue.replace(/[^0-9-/]/g, '');
  
    if (cleanedValue.startsWith('-') || cleanedValue.startsWith('/')) {
      cleanedValue = cleanedValue.substring(1);
    }
  
    
    return cleanedValue;
  };
  

  export const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  export const handleAlphaNumericDotAndSpace = (value) => {
    return value.replace(/[^0-9a-zA-Z\s.]/g, "");
  };

  export const handleAlphaDotAndSpace = (value) => {
    return value.replace(/[^a-zA-Z\s.]/g, "");
  };

  export const handleNumericDotAndSpace = (value) => {
    return value.replace(/[^0-9\s.]/g, "");
  };

   

export const displayMillion_ThoudandValues = (value)=>{
  let updatedValue = value;
  let valueToString = value.toString();
  
  let length =  value.toString().length;
   if(length == 7){
    if(valueToString.charAt(1) == 0){
      updatedValue = valueToString.charAt(0) + "Million"

    } else {
      updatedValue = valueToString.charAt(0) + "." + valueToString.slice(1,3) + "Million"
    }
  
   }else if(length == 6){
      if(valueToString.charAt(4) == 0){
    updatedValue = valueToString.slice(0,3) + "k"

      } else {
      updatedValue = valueToString.slice(0,3)  + "." + valueToString.charAt(3) + "k"
      }
   }else if(length == 5){
    if(valueToString.charAt(2) == 0){
  
      updatedValue = valueToString.slice(0,1)  +  "k"
        } else {
      updatedValue = valueToString.slice(0,2)  + "." + valueToString.charAt(2) + "k"
        }
    
   }else if(length == 4){
    if(valueToString.charAt(1) == 0){
  
      updatedValue = valueToString.charAt(0)  +  "k"
        } else {
      updatedValue = valueToString.charAt(0) + "." + valueToString.charAt(1) + "k"
        }
    
   }else {
    updatedValue = value
   }
   return updatedValue;
}
  
export const uploadImageCommonFunction = async (file, filename) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('filename', filename);

    const response = await axios.post(`${CONFIG.APIBaseUrl}midoc_documentupload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response.statusText === 'OK') {
      const result = await response.data;
      return result;
    } else {
      const error = 'failed';
      return error;
    }
  } catch (err) {
    console.log(err);
    const error = 'failed';
    return error;
  }
};
// Document Download
export const downloadDocument = async (url, name) => {
  try {
    // let testurl = "https://midocfileserver.blob.core.windows.net/midocdev/a2816fdf-2b18-4b75-932a-35361e988a11.jpg";
    // let testurl2 = "https://upload.wikimedia.org/wikipedia/en/6/6b/Hello_Web_Series_%28Wordmark%29_Logo.png";
    
    let filename = name;
    const response = await fetch(url, {
      mode: 'cors' 
    });
    console.log(response, " fetch result")
    const blob = await response.blob();
    
    
    saveAs(blob, filename);
  } catch (error) {
    console.error('Failed to download the image:', error);
  }
};
export const getCroppedImg = async (imageSrc, croppedAreaPixels) => {
  const image = new Image();
  image.src = imageSrc;
  await new Promise((resolve) => {
    image.onload = resolve;
  });

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  canvas.width = croppedAreaPixels.width;
  canvas.height = croppedAreaPixels.height;

  ctx.drawImage(
    image,
    croppedAreaPixels.x,
    croppedAreaPixels.y,
    croppedAreaPixels.width,
    croppedAreaPixels.height,
    0,
    0,
    croppedAreaPixels.width,
    croppedAreaPixels.height
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob) {
        resolve(blob);
      } else {
        reject(new Error('Canvas is empty'));
      }
    }, 'image/jpeg');
  });
};

export const formatDate_Do_MMM_YYYY = (dateString) => {
  if (!dateString) return '-';
  return moment(dateString).format('Do MMM, YYYY');
};

export const formatDate_DD_MM_YYYY = (dateString) => {
  if (!dateString) return '-';
  return moment(dateString).format('DD-MM-YYYY');
};
export const formatDate_YYYY_MM_DD = (dateString) => {
  if (!dateString) return null;
  return moment(dateString, 'DD-MM-YYYY').format('YYYY-MM-DD');
};
export const formatDate_yyyy = (dateString) => {
  if (!dateString) return '-';
  return moment(dateString).format("yyyy");
};



export const getCountryCodeFromCountriesList = async (countryid) => {
  try {
    var input = {};
    var result = await getCountry(input)
    if (result) {
      let sepcificCountryData = result.find(item => item.countryid == countryid)
      let countryCode = sepcificCountryData ? sepcificCountryData.countrycode : null;
      // console.log('returnCountryCode: ', countryCode)
      return countryCode;
    }  else {
      return null;
    }

  } catch (error) {
    console.log(error, "error in Countries Auth.js main function")
  }
}

export const useScreenType = () => {
  const screenType = useBreakpointValue({
    base: 'mobile',
    sm: 'mobile',
    md: 'tablet',
    lg: 'web',
    xl: 'web',
  },{ssr: false});

  return screenType;
};

export const useIsIOS = () => {
  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    const ua = navigator.userAgent || navigator.vendor || window.opera;

    // iOS detection
    if (/iPad|iPhone|iPod/.test(ua) && !window.MSStream) {
      setIsIOS(true);
    }
  }, []);

  return isIOS;
};
export const saveAddressInfo = (data) => {
  // Stringify the data object into JSON format
  const jsonData = JSON.stringify(data);
  // Set the JSON string in local storage
  localStorage.setItem('doctoraddress', jsonData);
};
function getAddressInfo() {
const jsonData = localStorage.getItem('doctoraddress');
const data = JSON.parse(jsonData);
return data ;
}
export const saveEmail = (email) => {
  localStorage.setItem('email', email);
};
export const saveMobile = (mobile) => {
  localStorage.setItem('mobile', mobile);
};
export const saveLogo = (logo) => {
  localStorage.setItem('logo', logo);
};
export const saveHospitalName = (hospitalname) => {
  localStorage.setItem('hospitalname', hospitalname);
};
export const saveIsDoctor = (isdoctor) => {
  localStorage.setItem('isdoctor', isdoctor);
};
function getEmail() {
  const jsonData = localStorage.getItem('email');
  const email = jsonData ? jsonData : null;
  return  email ;
}
function getMobile() {
  const jsonData = localStorage.getItem('mobile');
  const mobile = jsonData ? jsonData : null;
  return  mobile ;
}
function getLogo() {
  const jsonData = localStorage.getItem('logo');
  const logo = jsonData ? jsonData : null;
  return  logo ;
}
function getHospitalName() {
  const jsonData = localStorage.getItem('hospitalname');
  const hospitalname = jsonData ? jsonData : null;
  return  hospitalname ;
}
export const getIsDoctor = () => {
  return localStorage.getItem('isdoctor');
};


export  function kgToLbConverter(weightInKg) {
  let value = null;
  let valueToCalculate = null;
  
  if(weightInKg){

    const conversionFactor = 2.20462;

    value = (weightInKg * conversionFactor).toFixed(2).toString();
    valueToCalculate = weightInKg * conversionFactor;
  }
  return {
    value,
    valueToCalculate
  }
}
export function lbToKgConverter(weightInLb) {
let value = null;
let valueToCalculate = null;

if(weightInLb){
  
  const conversionFactor = 0.453592;
   value = (Math.floor(weightInLb * conversionFactor * 100) / 100).toFixed(2);
  // value = (weightInLb * conversionFactor).toFixed(2).toString();
  
   valueToCalculate = weightInLb * conversionFactor;
}
return {
value,
valueToCalculate
}
}

export function ftToCmConverter(feetStr, inchesStr = 0) {
  let finalValue = null;
  if(feetStr || inchesStr){
    const feet = parseInt(feetStr, 10);
    const inches = parseFloat(inchesStr, 10);
  
    const totalInches = feet * 12 + inches;
    
  let fixedValue = totalInches * 2.54 // 2.54 cm per inch
    finalValue = fixedValue.toFixed(2)
  }
  
  return finalValue; 
}
export function ftToCmConverterBasic(heightInFt) {
  // alert(heightInFt)
   const conversionFactor = 30.48;
   return (heightInFt * conversionFactor).toFixed(2);
 }
export function cmToFtConverter(heightInCm) {
  let feet = null ; 
  let inches = null ; 
  let inchesToCalculate = null; 
  
  if(heightInCm){
    const cmToInchesFactor = 0.393701; // 1 cm = 0.393701 inches
    const inchesPerFoot = 12; // 1 foot = 12 inches
  
    // Convert cm to total inches
    const totalInches = heightInCm / 2.54;
  
    // Calculate feet
     feet = Math.floor(totalInches / inchesPerFoot);
  
    // Calculate remaining inches
    // const inches = (totalInches % inchesPerFoot);
    // const incheValueForDisplay = Math.round(totalInches % inchesPerFoot);
    
     inches = Math.round(totalInches % inchesPerFoot);
     inchesToCalculate = (totalInches % inchesPerFoot);
  }
 
  return {
      feet: feet,
      inches: inches,
     // incheValueForDisplay: incheValueForDisplay
     inchesToCalculate: inchesToCalculate,
  };
} 
export function lbToKgConverterUsingDBValue(weightInLb) {
  let value = null;
  let valueToCalculate = null;

  if (weightInLb) {
    
    value = (Math.floor(weightInLb / 2.20462 * 100) / 100).toFixed(2);
    //value = (weightInLb / 2.20462).toFixed(2).toString(); // Divide by factor to convert lb to kg
    valueToCalculate = weightInLb / 2.20462;
  }
  return {
    value,
    valueToCalculate,
  };
}

export const validationMobileNumber = (inputValue, maxLength) => {
  // Remove all characters that are not digits or a decimal point
  let cleanedValue = inputValue.replace(/[^0-9]/g, '');
  
  // Enforce the max length after cleaning and truncating
  if (cleanedValue.length > maxLength) {
    cleanedValue = cleanedValue.substring(0, maxLength);
  }

  return cleanedValue;
};



export function actionAccessMain( masterscreenidFromPage){
  
let actionAccessLocal = JSON.parse(localStorage.getItem("actionAccess")); 
if(actionAccessLocal){
  
  if( masterscreenidFromPage){
    const matchedScreen = actionAccessLocal.find(item => item.masterscreenid == masterscreenidFromPage);
    return matchedScreen || null;

  }else{
    return null;
  }
  
}else{
  return null;
}
  
}


export {getLoginID, getUserName, getCurrency, getDoctorName, getAddressInfo, getEmail, getMobile, getLogo, getHospitalName};