import React from "react";
import {
  Box,
  Text,
  Image,
  Circle,
  Center,
  Link,
  Divider,
} from "@chakra-ui/react";
import moment from "moment";

const formatDate = (dateString) => {
  if (!dateString) return "-";
  return moment(dateString).format("Do MMM, YYYY");
};

const fileTypeMappings = {
  pdf: "/assets/svgs/pdf-icon.svg",
  png: "/assets/imgs/png-icon.png",
  jpg: "/assets/imgs/jpg-icon.png",
  jpeg: "/assets/imgs/jpg-icon.png",
};
const getFileIcon = (fileName) => {
  const extension = fileName.split(".").pop().toLowerCase();
  return fileTypeMappings[extension] || "/assets/svgs/file-icon.svg"; // fallback image
};

const PastSurgicalHistory = ({
  date,
  surgeryName,
  doctorName,
  hospital,
  attachments,
  id,
  onEdit,
  onDelete,
  scaleHeight = "auto",
  componentBg = "#fcfcfd",
  cardBg = "white",
}) => {
  return (
    <Box
      display="flex"
      flexDir="column"
      alignItems="center"
      w="100%"
      bg={componentBg}
    >
      <Box display="flex" alignItems="center" w="100%">
        <Circle
          size="1rem"
          bg="white"
          border="2px solid #CDCFD4"
          borderRadius="full"
          color="black"
        />
        <Text
          fontSize="12px"
          fontWeight="500"
          textAlign="left"
          color="#384052"
          ml="0.5rem"
        >
          {formatDate(date)}
        </Text>
      </Box>
      <Box display="flex" w="100%" mt="10px">
        <Center
          w="100%"
          h="auto"
          ml="0.45rem"
          alignItems="start"
          borderLeft="2px solid #E6E7E9"
        >
          <Box w="100%">
            <Box
              p="20px"
              w="100%"
              ml="10px"
              display="flex"
              flexDirection="column"
              alignItems="start"
              justifyContent="space-between"
              border="1px solid #E6E7E9"
              borderRadius="12px"
              backgroundColor={cardBg}
            >
              <Box display="flex" alignItems="center" w="100%">
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  flex="1"
                >
                  <Text
                    title={surgeryName}
                    fontSize="16px"
                    textAlign="left"
                    fontWeight="600"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {surgeryName}
                  </Text>
                </Box>
              </Box>
              <Divider my="10px" />
              <Box display="flex" alignItems="center" w="100%">
                <Box display="flex" alignItems="center">
                  <Image src="/assets/svgs/stethoscope.svg" w="16px" mr="5px" />
                  <Text
                    fontSize="14px"
                    fontWeight="500"
                    color="#384052"
                    whiteSpace={"nowrap"}
                  >
                    {doctorName}
                  </Text>
                </Box>
                <Box flex="1" />
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  w="100%"
                >
                  <Image src="/assets/svgs/hospital.svg" w="16px" mr="5px" />
                  <Text fontSize="14px" fontWeight="500" color="#384052">
                    {hospital}
                  </Text>
                </Box>
              </Box>
            </Box>
            <Box mt="4px" p="10px" w="100%">
              <Box display="flex" w="100%" flexWrap="wrap">
                {attachments.map((attachment, index) => (
                  <Box
                    key={index}
                    display="flex"
                    alignItems="center"
                    border="1px solid #E6E7E9"
                    borderRadius="8px"
                    p="5px 10px"
                    mr="10px"
                    mb="10px"
                    backgroundColor="white"
                  >
                    <Box bg="#f9f9f9" p="5px" borderRadius="8px">
                      <Image src={getFileIcon(attachment.name)} w="16px" />
                    </Box>
                    <Link
                      href={attachment.link}
                      isExternal
                      fontSize="14px"
                      color="#2B6CB0"
                      ml="5px"
                    >
                      {attachment.name}
                    </Link>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Center>
      </Box>
    </Box>
  );
};

export default PastSurgicalHistory;
