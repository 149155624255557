import {
    Box,
    Checkbox,
    Divider,
    Flex,
    Heading,
    Image,
    RadioGroup,
    Stack,
    Step,
    StepDescription,
    StepIndicator,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    Text,
  } from "@chakra-ui/react";
  import React, { useEffect, useState, useRef } from "react";
  import {  addVaccination, deleteVacinations, editVaccinations, FetchVaccinationDetails, getVaccineList } from "../../middleware/services/messages";
  import moment from "moment";
  import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import { useNavigate } from "react-router-dom";
import { PrimaryButton, PrimaryInput, SearchBar, Toaster } from "../../components";
import { format } from "date-fns";
import ModalRight from "../../components/modal-right";
import DynamicModal from "../../components/dynamic-modal";
import DatePicker from "../../components/date-picker";
import { actionAccessMain, getLoginID } from "../../auth";
import DatePickerCombined from "../../components/date-picker-new";

const Vaccinations = ({id, backFunction}) => {

  const [patientid, setPatientid] = useState("");
  const [vaccinationData, setVaccinationData] = useState(null);
  const { isLoading, showLoader, dismissLoader } = useLoader();

  const [actionAccessPatients , setActionAccessPatients] = useState(null);
  let storedData = localStorage.getItem("dobAndGenderData");
  let parsedData = storedData ? JSON.parse(storedData) : {};
  let PatientDOB = parsedData.dob || "";
  useEffect(() => {
    
    setActionAccessPatients(actionAccessMain(4))
    fetchvaccinationdata(id)   

  }, []);
  
  const fetchvaccinationdata = async (id) => {
    showLoader()
        try {
          const result = await FetchVaccinationDetails({
            patientid: id,
          });

          if (result != null) {
            console.log(result)
            setVaccinationData(result);
          } else {
            setVaccinationData(null)
          }
          dismissLoader()
      } catch (error) {
          console.error("Error in home:", error);
        }
      };

      const formatDate = (dateString) => {
        if (!dateString) return "-";
        return moment(dateString).format("Do MMM, YYYY");
      };

      // console.log(patientid, "vaccination IDDDDD")
      // console.log(vaccinationData, "vaccination Dataaaaaaa")


      //new code -----------------------------------------------------------------------------------------
      const dynamicModal = React.useRef();
      const addCondition = React.useRef();
      const addCondition2 = React.useRef();
    
      const addCondition3 = React.useRef();
    
      const [data, setData] = useState('')
      var [conditionsList, setConditionsList] = useState(null);
      const [searchValue, setSearchValue] = useState('');
      const [selectedConditions, setSelectedConditions] = useState([]);
      const [selectedDates, setSelectedDates] = useState({});
      const [selectedDueDates, setSelectedDueDates] = useState({});
      const [condition, setCondition] = useState('')
      const [vaccineid, setVaccineid] = useState('')
      const [idPatient, setIdPatient] = useState('')
      const [vaccineName, setVaccineName] = useState("")
      const [adminDate, setAdminDate] = useState("")
      const [dueDate, setDueDate] = useState("")
    
      
      const scrollDiv = useRef(null);
      const navigate = useNavigate()
      const chatContainerRef = useRef(null);
      const [aianswers, setAIAnswers] = useState([]);
      const [remainingquestions, setRemainingQuestions] = useState([]);
      const [aiquestion, setAIAnalysis] = useState(null);
      const [AiInput, setAiInput] = useState(null);
      const [initialRender, setInitialRender] = useState(true);
      const [animate, setAnimate] = useState(false);
      const AIChatModel = useRef();


      
      let patientidFromLocal = localStorage.getItem("patientid")

  const fetchMedicalConditionList = async () => {
    try {
      const result = await getVaccineList({
        type: "vaccine",
        "patientid":patientidFromLocal,
      });
      if (result.output.result == "success") {
        setConditionsList(result.output.data);
      } else {
        showErrorToast("Something went wrong!")
      }
    } catch (error) {
      console.error("Error", error);
    }
  };




  const handleCheckboxChange = (condition) => {
    const isSelected = selectedConditions.some(
      (item) => item.condition_cd === condition.condition_cd
    );

    if (isSelected) {
      setSelectedConditions((prevConditions) =>
        prevConditions.filter(
          (item) => item.condition_cd !== condition.condition_cd
        )
      );
    } else {
      setSelectedConditions([...selectedConditions, condition]);
    }
  };
  const [minDateState , setMinDateState] = useState('')
  const [maxDateState , setMaxDateState] = useState('')
  const handleDateChange = (conditionCd, date) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    setMinDateState(date)
    setSelectedDates((prevDates) => ({
      ...prevDates,
      [conditionCd]: formattedDate,
    }));
  };
  const handleDueDateChange = (conditionCd, date) => {
    setMaxDateState(date)
    const formattedDate = format(date, "yyyy-MM-dd");
    setSelectedDueDates((prevDates) => ({
      ...prevDates,
      [conditionCd]: formattedDate,
    }));
  };

  var inputForAdd = {
    "patientid":patientidFromLocal,
    "doctorid":getLoginID(),
    "vaccination": selectedConditions.map((condition, index) => ({
      "vaccine_cd": condition.condition_cd,
      "vaccinename": condition.condition,
      "admindate": selectedDates[condition.condition_cd] || null,
      "duedate": selectedDueDates[condition.condition_cd] || null,

    })),
  }

  const AddMedicalConditions = () => {
    if (selectedConditions.length === 0 && typedCondition == "") {
      showErrorToast("Please Select a Vaccine to Continue")
    } else {

      // enter manually 
      if (typedCondition !== "") {
        const newSelectedCondition = {
          condition:typedCondition,
          condition_cd:null,
          conditiontype:"other"
        };
    
        setSelectedConditions((prevConditions) => {
          // Filter out any existing condition with the same type
          const filteredConditions = prevConditions.filter(
            (item) => item?.conditiontype !== "other"
          );
          // Add the new condition
          return [...filteredConditions, newSelectedCondition];
        });
      }

      //--------------------------------
      setSelectedDates({})
      setSelectedDueDates({})
      addCondition2.current.openRight()
    }

  }


  const AddMedicalConditionsCall = () => {
    const addConditions = async () => {
      showLoader();
      try {
        const result = await addVaccination(inputForAdd);
        if (result.output.result == "success") {
          showSuccessToast("Successfully Added")
          if (scrollDiv.current) {
           scrollDiv.current.scrollTop = 0;
         }
          
         fetchvaccinationdata(patientidFromLocal)  

          addCondition2.current.closeRight();
          addCondition.current.closeRight();
        } else {
          showErrorToast("Something went wrong!")
        }
      } catch (error) {
        console.error("Error in DashboardIndex:", error);
      }
      dismissLoader();
    };

    const isDateMissing = selectedConditions.some(
      // (condition) => !selectedDates[condition.condition_cd] || !selectedDueDates[condition.condition_cd]
      (condition) => !selectedDates[condition.condition_cd] 
    );

    if (isDateMissing) {
      showErrorToast("Please select admin date");
      return false;
    } else {
      addConditions();
    }

  }

  const { showSuccessToast, showErrorToast } = Toaster();
  var inputForDelete = {
    vaccineid,
    "patientid":patientidFromLocal,
    "doctorid":getLoginID(),
  }
  const deleteMedicalCondition = async () => {

    const deleteCondition = async () => {
      showLoader();
      try {
        const result = await deleteVacinations(inputForDelete);
        if (result.output.result == "success") {
          showErrorToast(condition + " has been Deleted")
          fetchvaccinationdata(patientidFromLocal)  
          dynamicModal.current.closeModal();
        } else {
          showErrorToast("Something went wrong!")
        }
        dismissLoader();
      } catch (error) {
        console.error("Error in vaciations:", error);
      }
    };
    deleteCondition();
  }

  const openDeleteModal = (id, condition) => {

    setVaccineid(id)
    setCondition(condition)

    dynamicModal.current.openModal();

  }
  const openVaccinationsModal = () => {
   // if (conditionsList == null) {
      fetchMedicalConditionList();
   // }
    setSearchValue('')
    setSelectedConditions([])
    setSelectedDueDates({})
    setTypedCondition("")
    addCondition.current.openRight()
  }

  const openEditModal = (id, vaccinename, admindate, duedate) => {
    // const formattedAdminDate = admindate.split('T')[0];
    // const formattedDueDate = duedate.split('T')[0];

    var formatedAdminDate = moment(admindate).format("DD-MM-yyyy");
    if(duedate){
      var formatedDueDate = moment(duedate).format("DD-MM-yyyy");
    setDueDate(formatedDueDate);
    }
    setIdPatient(id);
    setVaccineName(vaccinename);
    setAdminDate(formatedAdminDate);

    addCondition3.current.openRight()

  }




  const EditVaccineDates = async () => {

    const editVaccineDatesFunction = async () => {
      let formatedAdminDate = moment(adminDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
      let formatedDueDate = moment(dueDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
      //  alert(adminDate)

      var inputForEdit = {
        "patientid":patientidFromLocal,
        "doctorid":getLoginID(),
        "vaccine": [
          {
            "vaccineid": idPatient,
            "admindate": formatedAdminDate,
            "duedate": formatedDueDate,
          },
        ]
      }
      try {
        showLoader();
        const result = await editVaccinations(inputForEdit);
        if (result.output.result == "success") {
          showSuccessToast("Updated Successfully")
          fetchvaccinationdata(patientidFromLocal)  
          addCondition3.current.closeRight()
        } else {
          showErrorToast("Something went wrong!")
        }
      } catch (error) {
        console.error("Error in editVaccineDatesFunction:", error);
      }
    };
    editVaccineDatesFunction();

    fetchvaccinationdata(patientidFromLocal)  

  }
  const handleEditAdminDate = (selectedDate) => {
    //  const formattedDate = format(selectedDate, "yyyy-MM-dd");
    setAdminDate(selectedDate)
  }
  const handleEditDueDate = (selectedDate) => {

    //  const formattedDate = format(selectedDate, "yyyy-MM-dd");
    setDueDate(selectedDate)
  }


  const [typedCondition , setTypedCondition] = useState("")
  const handleCondition = (e) => {
    const inputText = e.target.value;
    const maxLength = 80;
  
    if (inputText.length <= maxLength) {
      setTypedCondition(inputText);
    }
  
  }


      return (
        <>
        {isLoading && <Loader />}
          <Box w="100%" display={{base: "none", lg: "block"}}>
          <Box pos='sticky' p='1rem 1.5rem' alignItems='center' top='0' zIndex='999' bg='white' borderBottom='1px solid #E6E7E9' display="flex" justifyContent="space-between" mb="10px">
          <Text fontWeight='600'>Vaccination</Text>
          <PrimaryButton
            fontSize="14px"
            variant="mdBtn"
            buttonText="Add Vaccine"
            btnIcon="/assets/imgs/add.png"
            onClick={openVaccinationsModal}
            
            isDisabled={actionAccessPatients?.create ? false : true}
          />
          
      </Box>
            <Stepper
              padding='1.5rem'
              orientation="vertical"
              size="xs"
              colorScheme="#CDCFD4"
              gap="0"
              w="100%"
              maxH='calc(100vh - 160px)'
              overflow='hidden auto'
            >
              {vaccinationData && vaccinationData.length>0 ? (
                vaccinationData.map((vaccine,index) => (
                <Step w="100%" >
                  <StepIndicator color="#E6E7E9" />
                  
                  <Box flexShrink="0" w="95%" key={index}>
                    <StepTitle fontSize="0.75rem" fontWeight="500" color="#384052" mb="0.5rem">
                      {/* 10th Jan, 2024 */}
                      {formatDate(vaccine.admindate)}
                    </StepTitle>
                    <StepDescription
                      bg="white"
                      w="100%"
                      border="1px solid #E6E7E9"
                      borderRadius="0.65rem"
                      p="1rem"
                      mb="1.5rem"
                      boxShadow="0px 8px 20px 0px #E6E7E966"
                    >
                       
                      <Box w="100%" className="textOverflow">
                        <Flex w='100%' justifyContent="space-between" alignItems='center'>
                        <Text fontSize="0.875rem" fontWeight="500" color="#061027" className="textOverflow" w='200px'>
                          {/* Chills, Fever and Fatigue */}
                          {vaccine.vaccinename}
                        </Text>
                        <Box display='flex'  gap='0.5rem' justifyContent='flex-end'>
                        <Box
                              as="button"
                              border="1px solid #E6E7E9"
                              borderRadius="full"
                              w="36px"
                              h="auto"
                              p="10px"
                              onClick={() => openEditModal(vaccine.id, vaccine.vaccinename , vaccine.admindate , vaccine.duedate)}
                            
                              disabled={actionAccessPatients?.update ? false : true}
                                   opacity={actionAccessPatients?.update ? 1 : 0.5}
                            >
                              <Image w="13px" src="/assets/imgs/edit-gray.png" />
                            </Box>
                            <Box
                              as="button"
                              border="1px solid #E6E7E9"
                              borderRadius="full"
                              w="36px"
                              h="auto"
                              p="10px"
                              onClick={() => openDeleteModal(vaccine.id, vaccine.vaccinename)}

                              disabled={actionAccessPatients?.delete ? false : true}
                              opacity={actionAccessPatients?.delete ? 1 : 0.5}
                            >
                              <Image w="13px" src="/assets/imgs/delete.png" />
                            </Box>
                          </Box>
                        </Flex> 
                        <Divider my="1rem" />
                        <Text fontSize="0.75rem" fontWeight="500" color="#898D97">
                            {/* Next dosage: 10th Feb, 2024  */}
                            Next dosage: {formatDate(vaccine.duedate)}
                        </Text>
                      </Box>
                    </StepDescription>
                  </Box>

                 
    
                  <StepSeparator colorScheme="red" />
                </Step>
 ))
              ) : (
                <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            gap="10px"
                            alignItems="center"
                            h="calc(100vh - 100px)"
                            w="100%"
                          >
                            <Box textAlign="left" fontSize="16px" fontWeight="600">
                              <Image
                                w="26px"
                                opacity="0.5"
                                src="/assets/imgs/syringe.png"
                              />
                            </Box>
                            <Text textAlign="left" fontSize="12px" fontWeight="500">
                              No Data available
                            </Text>
                          </Box>
              )}
            </Stepper>
          </Box>



          <DynamicModal
          ref={dynamicModal}
          modalHeader="Delete Vaccination?"
          modalBody={
            <>
              <Text fontSize="14px" fontWeight="600">
                Are you sure you want to delete "{condition}"
              </Text>
            </>
          }
          modalFooterBtn={
            <>
              <PrimaryButton
                variant="deleteBtn"
                buttonText="Delete"
                  onClick={deleteMedicalCondition}
              />
            </>
          }
        />

        <ModalRight
          ref={addCondition}
          header="Add vaccine"
          body={
            <>
                  <SearchBar passInputPlace="Search condition" onChange={(e) => setSearchValue(e.target.value)} />
                  <RadioGroup>
                    <Stack direction="column" gap="0">
                    <Box display="flex" justifyContent="end" w="100%">
  <Stack direction="column" gap="0" w="100%">
    {conditionsList && conditionsList.length > 0 ? (
      conditionsList.some(category =>
        category.data.some(condition =>
          condition.condition.toLowerCase().includes(searchValue.toLowerCase())
        )
      ) ? (
        conditionsList.map(category => (
          <React.Fragment key={category.category_id}>
            {category.data
              .filter(condition =>
                condition.condition.toLowerCase().includes(searchValue.toLowerCase())
              )
              .map(condition => (
                <label key={condition.condition_cd}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    borderBottom="1px solid #e2e8f0"
                    p="15px"
                                    pl="5px"
                                    minH="50px"
                    width="100%"
                    sx={{
                                      _hover: { border: "2px solid #384052", borderRadius: "10px" },
                    }}
                  >
                    <Box
                      key={condition.condition_cd}
                      display="flex"
                      alignItems="center"
                    >
                      <Text fontSize="16px" lineHeight="normal" fontWeight="600">
                        {condition.condition}
                      </Text>
                    </Box>
                    <Box display="flex" justifyContent="end">
                      <Checkbox
                        colorScheme="green"
                        sx={{
                          ".chakra-checkbox__control": {
                            width: "20px",
                            height: "20px",
                            borderRadius: "5px",
                          },
                        }}
                                        onChange={() =>
                                          handleCheckboxChange(condition)
                                        }
                        isChecked={selectedConditions.some(
                                          (item) =>
                                            item.condition_cd ===
                                            condition.condition_cd
                        )}
                      ></Checkbox>
                    </Box>
                  </Box>
                </label>
              ))}
          </React.Fragment>
        ))
      ) : (
        <Box p="15px" fontSize="16px" fontWeight="600" textAlign="center">
         No matching vaccinations found!
        </Box>
      )
    ) : (
      <p>Loading...</p>
    )}
     <Divider/>
          <br/>
          <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
                   Other Vaccination
                  </Heading>
        <PrimaryInput
         inputPlace="Vaccination"
         variant="bothSide"
                onChange={handleCondition} inputValue={typedCondition}
                />
                <br/>
  </Stack>
</Box>
                    </Stack>
                  </RadioGroup>


                  <ModalRight
          ref={addCondition2}
          header="Add Vaccination"
          body={
            <>
    <Box display="flex" flexDir="column" gap="20px">
      {selectedConditions.map((condition, index) => (
        <Box key={index}>
          <Text fontSize="16px" py="10px" fontWeight="600">
            {condition.condition}
          </Text>

             <DatePickerCombined 
                disableFutureDates={true}
                //minDate={null} 
                maxDate={maxDateState} 
                patientDOB={PatientDOB}
                CUSTOM_DISABLE={true}
                onDateChange={(selectedDateValue) => handleDateChange(condition.condition_cd, selectedDateValue)}
                placeholderProp="Admin Date*" /> 
            
            <DatePickerCombined 
            disablePastDates={true}
                //minDate={minDateState}
                patientDOB={PatientDOB}
                            CUSTOM_DISABLE={true}
                onDateChange={(selectedDateValue) => handleDueDateChange(condition.condition_cd, selectedDateValue)}
                placeholderProp="Due Date" 

                minDate={new Date().toISOString()}
                />  
        </Box>
      ))}
    </Box>
  </>
          }
          footerActions={<>
             <Box
                w="100%"
                display="flex"
                flexDirection="row"
                gap="15px">

                <PrimaryButton
                buttonText="Add Vaccine"
               
              isDisabled={actionAccessPatients?.create ? false : true}
                onClick={AddMedicalConditionsCall}
                />
            </Box>
          </>}
        />
                </>
          }
          footerActions={<>
             <Box
                w="100%"
                display="flex"
                flexDirection="row"
                gap="15px">

                <PrimaryButton
                buttonText="Continue"
                isDisabled={false}
                onClick={AddMedicalConditions}
                />
            </Box>
          </>}
        />



<ModalRight
          ref={addCondition3}
          header="Edit Vaccination "
          body={
            <>
    <Box display="flex" flexDir="column" gap="20px">
        <Box >
          <Text fontSize="16px" py="10px" fontWeight="600">
            {vaccineName}
          </Text>
         
              <DatePickerCombined 
                disableFutureDates={true}
                minDate={null} 
                maxDate={new Date().toISOString()} 
                defaultDate={adminDate}
                patientDOB={PatientDOB}
                CUSTOM_DISABLE={true}
                onDateChange={(selectedDateValue) => handleEditAdminDate( selectedDateValue)}
                placeholderProp="Admin Date*"
                inputStyle={{borderRadius: "10px 10px 0 0"}} /> 

              <DatePickerCombined 
                defaultDate={dueDate}
                minDate={new Date().toISOString()}
                patientDOB={PatientDOB}
                CUSTOM_DISABLE={true}
                onDateChange={(selectedDateValue) => handleEditDueDate( selectedDateValue)}
                placeholderProp="Due Date"
                inputStyle={{borderRadius: "0 0 10px 10px"}} />  

         

             

        </Box>
    
    </Box>
  </>
          }
          footerActions={<>
             <Box
                w="100%"
                display="flex"
                flexDirection="row"
                gap="15px">

                <PrimaryButton
                buttonText="Update Vaccine"
                isDisabled={false}
                onClick={EditVaccineDates}
                />
            </Box>
          </>}
        />






          {/* Mobile responsive */}
          <Box w="100%" display={{base: "block", lg: "none"}}>
            <MobileSecondaryNav
              header="Vaccinations"
              handlebackarrow={backFunction}
            />
            
            <Box h="calc(100vh - 120px)" overflow='hidden auto' p="1rem">
              <Stepper
                orientation="vertical"
                height="100%"
                size="xs"
                colorScheme="#CDCFD4"
                gap="0"
                w="100%"
              >
                {vaccinationData && vaccinationData.length>0 ? (
                  vaccinationData.map((vaccine,index) => (
                  <Step w="100%" >
                    <StepIndicator color="#E6E7E9" />
                    
                    <Box flexShrink="0" w="95%" key={index}>
                      <StepTitle fontSize="0.75rem" fontWeight="500" color="#384052" mb="0.5rem">
                        {/* 10th Jan, 2024 */}
                        {formatDate(vaccine.admindate)}
                      </StepTitle>
                      <StepDescription
                        bg="white"
                        w="100%"
                        border="1px solid #E6E7E9"
                        borderRadius="0.65rem"
                        p="1rem"
                        mb="1.5rem"
                        boxShadow="0px 8px 20px 0px #E6E7E966"
                      >
                        <Box w="100%" className="textOverflow">
                          <Text fontSize="0.875rem" fontWeight="500" color="#061027">
                            {/* Chills, Fever and Fatigue */}
                            {vaccine.vaccinename}
                          </Text>
                          <Divider my="1rem" />
                          <Text fontSize="0.75rem" fontWeight="500" color="#898D97">
                              {/* Next dosage: 10th Feb, 2024  */}
                              Next dosage: {formatDate(vaccine.duedate)}
                          </Text>
                        </Box>
                      </StepDescription>
                    </Box>

                  
      
                    <StepSeparator colorScheme="red" />
                  </Step>
                  ))
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    gap="10px"
                    alignItems="center"
                    h="calc(100vh - 100px)"
                    w="100%"
                  >
                    <Box textAlign="left" fontSize="16px" fontWeight="600">
                      <Image
                        w="26px"
                        opacity="0.5"
                        src="/assets/imgs/syringe.png"
                      />
                    </Box>
                    <Text textAlign="left" fontSize="12px" fontWeight="500">
                      No Data available
                    </Text>
                  </Box>
                )}
              </Stepper>
            </Box>
          </Box>
        </>
      );
}

export default Vaccinations