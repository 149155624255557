import formAPIPrescriptions from "./prescription";

var formattedOutput = null;

const formapimodalprescriptions = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIPrescriptions(item));


  return formattedOutput
};

export default formapimodalprescriptions