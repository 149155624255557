import moment from "moment";
export default function formAPIpastmedicalhistory({
  condition,
  conditionidentifieddate,
  conditiontype,
  conditiontype_cd,
  condition_cd,
  contiondata
 

})
{
return Object.freeze({
condition,
   conditionidentifieddate,
  conditiontype,
  conditiontype_cd,
  condition_cd, 
  contiondata
 
});
}