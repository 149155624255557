import {
  formApiModalPatientHealthDetailsForDoctor,
  formApiModalPatientPreviousVisitDetailForDoctor,
} from "../../../domains/visits/patient-health";
import FetchData from "../../../client";

const GetPatientHealthData = async (inputData) => {
  try {
    //inputData.doctorid = getLoginID();
    const result = await FetchData(
      "midoc_getpatienthealthdetailfordoctor",
      inputData
    );
    // console.log(inputData);
    // console.log(result, "db response");

    let finalOutput = null;
    if (result.output.data) {
      finalOutput = formApiModalPatientHealthDetailsForDoctor(
        result.output.data
      );
    }
    return finalOutput;
  } catch (error) {
    console.error("Error fetching data in forms.js:", error);
    throw error;
  }
};


const GetPatientTabularLabData = async (inputData) => {
  try {
    inputData.activeuserid = null;
    const result = await FetchData(
      "midoc_getpatienthealthreport",
      inputData
    );
    // console.log(inputData);
    // console.log(result, "db response");

    return result.output.data;
  } catch (error) {
    console.error("Error fetching data in forms.js:", error);
    throw error;
  }
};

const GetPatientSelectedVisitData = async (inputData, visitDate) => {
  try {
    const result = await FetchData(
      "midoc_getpatienthealthdetailfordoctor",
      inputData
    );
    // console.log("api", result);

    if (result.output.data) {
      let appointmentDetail = formApiModalPatientHealthDetailsForDoctor(
        result.output.data
      );
      return appointmentDetail;
    }
  } catch (error) {
    console.error("Error fetching data in visits.js:", error);
    throw error;
  }
};

const UpdateAppointmentStatus = async (inputData) => {
  try {
    const result = await FetchData("midoc_updateappointmenttime", inputData);
    // console.log("api", result);

    return result;
  } catch (error) {
    console.error("Error fetching data in visits.js:", error);
    throw error;
  }
};

export {
  GetPatientHealthData,
  GetPatientSelectedVisitData,
  UpdateAppointmentStatus,
  GetPatientTabularLabData
};
