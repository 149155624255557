import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Divider,
  Box,
  Image,
  Text,
} from "@chakra-ui/react";

// Reusable Drawer Component
const ModalRight = forwardRef(
  (
    {
      placement,
      header,
      body,
      modalWidth,
      bodyStyle,
      footerStyle,
      footerActions,
      modalSize,
      subHeader,
      secondaryHeader,
      id,
      backDropBlur = "10px",
      withBackBtn = false,
      handlebackarrow,
      withBackHeader = false,
      isBg = false,
      closeAction,
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);

    const btnRef = React.useRef();
    const bodyRef = React.useRef(document.body);
    useImperativeHandle(ref, () => ({
      openRight: () => setIsOpen(true),
      closeRight: () => setIsOpen(false),
    }));

    useEffect(() => {
      if (isOpen) {
        bodyRef.current.style.overflow = 'hidden';
        bodyRef.current.style.touchAction = 'none';
      } else {
        bodyRef.current.style.overflow = '';
        bodyRef.current.style.touchAction = '';
      }
    }, [isOpen]);

    const handleClose = () => {
      if (closeAction) {
        closeAction();
      } else {
        setIsOpen(false);
      }
    };

    return (
      <>
        <Drawer
          id={id}
          isOpen={isOpen}
          placement="right"
          size={modalSize}
          onClose={handleClose}
          closeOnOverlayClick={false}
          blockScrollOnMount={false}
        >
          <DrawerOverlay sx={{ backdropFilter: `blur(${backDropBlur})` }} />
          <DrawerContent maxW={modalWidth} bg="#fcfcfd" p="0">
            {withBackBtn ? (
              <Box position="relative">
                <Box
                  as="button"
                  onClick={handlebackarrow}
                  pos="absolute"
                  top="23px"
                  left="25px"
                >
                  <Image src="/assets/imgs/left-arrow-icon.png" />
                </Box>
                <DrawerCloseButton
                  position="absolute"
                  right="12px"
                  top="15px"
                  onClick={closeAction ? closeAction : () => setIsOpen(false)}
                />
              </Box>
            ) : (
              <DrawerCloseButton
                color={isBg ? "white" : ""}
                onClick={closeAction ? closeAction : () => setIsOpen(false)}
              />
            )}

            {withBackBtn && (
              <Box>
                <DrawerHeader
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  {withBackHeader}
                </DrawerHeader>
              </Box>
            )}
            {/* <DrawerCloseButton /> */}
            {header && (
              <Box>
                <DrawerHeader>{header}</DrawerHeader>
              </Box>
            )}

            {subHeader && (
              <Box>
                <DrawerHeader>
                  <Text
                    fontSize="1.25rem"
                    fontWeight="600"
                    pr="15px"
                    lineHeight="normal"
                  >
                    {secondaryHeader}
                  </Text>
                  <br />
                  <Box
                    as="span"
                    mt="-5px"
                    fontSize="12px"
                    fontWeight="500"
                    lineHeight="normal"
                    color="#384052"
                  >
                    {subHeader}
                  </Box>
                </DrawerHeader>
              </Box>
            )}
            {header && <Divider />}
            <DrawerBody bg="#FCFCFD" overflowY="auto" {...bodyStyle}>
              {body}
            </DrawerBody>
            {footerActions && (
              <DrawerFooter
                {...footerStyle}
                bg="white"
                boxShadow="0px -8px 20px 0px #E6E7E966"
              >
                {footerActions}
              </DrawerFooter>
            )}
          </DrawerContent>
        </Drawer>
      </>
    );
  }
);

export default ModalRight;
