import  FetchData, { } from '../../client';
import formapimodalhome from '../../domains/home/home';
import {getLoginID}  from '../../../auth';
import formAPIModelrole from '../../domains/roles/role/doctorroles';
import formAPIModelroledetails from '../../domains/roles/roledetails/doctorroledetails';
import formAPIModelScreensList from '../../domains/roles/screenlist/screenlists';

// without formatting output

export const createDoctorRole = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    const result = await FetchData("midoc_createdoctorrole", inputData);
    
    return result;

  } catch (error) {
    console.error('Error in createDoctorRole :', error);
    throw error;
  }
};


export const updateDoctorRole = async (inputData) => {
    try {
      inputData.loginid = getLoginID();
      const result = await FetchData("midoc_updatedoctorrole", inputData);
      
      return result;
  
    } catch (error) {
      console.error('Error in updateDoctorRole:', error);
      throw error;
    }
  };

  export const deleteDoctorRole = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      const result = await FetchData("midoc_deletedoctorrole", inputData);
      
      return result;
  
    } catch (error) {
      console.error('Error in deleteDoctorRole:', error);
      throw error;
    }
  };


  
// with formatting output
export const getDoctorRole = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      const result = await FetchData("midoc_getdoctorrole", inputData);
      //console.log(result,"Home db response")
      if(result.output.message == "No roles are available for this doctorid"){
        return result
      }else if(result.output.data) {
      var finalOutput = formAPIModelrole(result.output.data)
      
      return finalOutput;
      }
  
    } catch (error) {
      console.error('Error in getDoctorRole:', error);
      throw error;
    }
  };

 export const getDoctorRoleDetail = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      const result = await FetchData("midoc_getdoctorroledetail", inputData);
      //console.log(result,"Home db response")
      if (result.output.message == "No roles are available for this doctorid") {
        return result;
        }else if (result.output.data) {
      var data = formAPIModelroledetails(result.output.data)
      var roleData = formAPIModelrole(result.output.roledata)
      var finalOutput = {data, roleData}
      }
      return finalOutput;
  
    } catch (error) {
      console.error('Error in getDoctorRoleDetail:', error);
      throw error;
    }
  };


  export const getDoctorScreenList = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      const result = await FetchData("midoc_getdoctorscreenlist", inputData);
      //console.log(result,"Home db response")
      // if(result.output.message == "No roles are available for this doctorid"){
      //   return result
      // }else
       if(result?.output?.data) {
      var finalOutput = formAPIModelScreensList(result.output.data)
      
      return finalOutput;
      }
  
    } catch (error) {
      console.error('Error in getDoctorRole:', error);
      throw error;
    }
  };
