import formapiInvoice from "./invoice";

var formattedOutput = null;

const formapimodalInvoices = (data) => {
    formattedOutput = data.map(item => formapiInvoice(item));

    return formattedOutput;
}

export default formapimodalInvoices;