import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  useRef,
} from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Image,
  Box,
  Text,
  VStack,
} from "@chakra-ui/react";
import Prescription from "./prescription-page";
import { GetDoctorNotes } from "../../../middleware/services/visits/patient-overview/updatedoctornotes";
import moment from "moment";
import {
  extractDiagnosis,
  formatMedicalHistory,
  transformVitalData,
  calculateAge,
  formatMedicines,
  formatOrders,
} from "../../../middleware/domains/visits/patient-health/prescriptions";
import useLoader from "../../loader/loaderstates";
import Loader from "../../loader/loader";

const EndVisitDynamicModal = forwardRef(
  (
    {
      modalHeader,
      modalBody,
      modalBodyStyle,
      modalFooterBtn,
      footerStyle,
      modalWidth = "28rem",
      modalMaxWidth,
      hasHeader = true,
      hasCloseBtn = true,
      hasFooter = true,
      modalHeight,
      hasBackBtn = false,
      withBackHeader,
      backOnClick,
      patientId,
      doctorId,
      appointmentId,
    },
    ref
  ) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
      isOpen: isSecondOpen,
      onOpen: onSecondOpen,
      onClose: onSecondClose,
    } = useDisclosure();

    const [secondModalData, setSecondModalData] = useState({
      followUpDate: "",
      referralDoctor: "",
    });

    useImperativeHandle(ref, () => ({
      openModal: () => onOpen(),
      closeModal: () => {
        onClose();
        onSecondClose();
      },
      openSecondModal: (data) => {
        setSecondModalData(data);
        onSecondOpen();
      },
      closeSecondModal: () => onSecondClose(),
    }));

    const [inputToPrescription, setInputToPrescription] = useState(null);

    const { isLoading, showLoader, dismissLoader } = useLoader();

    useEffect(() => {
      if (isSecondOpen) {
        fetchDoctorNotes();
      }
    }, [isSecondOpen, patientId, doctorId, appointmentId]);

    const fetchDoctorNotes = async () => {
      const inputToFetch = {
        input: {
          patientid: patientId,
          doctorid: doctorId,
          appointmentid: appointmentId,
        },
      };

      showLoader();
      try {
        const response = await GetDoctorNotes(inputToFetch.input);
        const data = response?.output?.data;
        const fetchedData = {
          drName: data?.info?.drname,
          hospitalLogo: data?.info?.hospitallogo,
          doctorQualification: data?.info?.doctorqualification,
          doctorSpeciality: data?.info?.doctorspeciality,
          doctorPhone: data?.info?.doctorphone,
          doctorPhoneCountryCode: data?.info?.doctorcountrycode,
          doctorMail: data?.info?.doctormail,
          hospitalAddress: data?.info?.hospitaladdress?.address,
          patientBookingId: data?.info?.bookingid,
          patientName: data?.info?.patientname,
          patientGender: data?.info?.patientgender
            ? data.info.patientgender.charAt(0).toUpperCase() +
              data.info.patientgender.slice(1)
            : null,
          patientAge: calculateAge(data?.info?.patientage),
          patientPhone: data?.info?.patientphone,
          patientPhoneCountryCode: data?.info?.patientcountrycode,
          appointmentDate: data?.info?.appointmentdate
            ? moment(data?.info?.appointmentdate).format("D MMM YYYY")
            : "-",
          vitalData: transformVitalData(data.vitals || []),
          chiefcomplaint: data?.chiefcomplaint?.chiefcomplaint,
          patientdiagnosis: data?.patientdiagnosis,
          diagnosisData: extractDiagnosis(data.doctorsassessment || []),
          medicalHistory: formatMedicalHistory(data.medicalhistory || []),
          prescribedMedicines: formatMedicines(data.orders.medication || []),
          scaleOut:
            formatMedicines(data.orders.medication || []).length > 5
              ? true
              : false,
          recommendedTests: formatOrders(data.orders || []),
          followUpDate: secondModalData.followUpDate || "-",
          referralDoctor: secondModalData.referralDoctor || "-",
          doctorSignImage: null,
        };

        setInputToPrescription(fetchedData);
      } catch (error) {
        console.error("Error fetching Doctor Notes data:", error);
      } finally {
        dismissLoader();
      }
    };

    // Manage scroll lock
    const scrollRef = useRef(document.body);
    useEffect(() => {
      scrollRef.current.style.overflow = "hidden";
      scrollRef.current.style.touchAction = "none";
      return () => {
        scrollRef.current.style.overflow = "";
        scrollRef.current.style.touchAction = "";
      };
    }, [isOpen, isSecondOpen]);

    return (
      <>
        <Box position="relative">
          {isLoading && <Loader />}
          <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            blockScrollOnMount={false}
          >
            <ModalOverlay backdropFilter="blur(10px)" />
            <ModalContent
              maxW={modalMaxWidth ? "max-content" : ""}
              w={modalWidth}
              h={modalHeight}
              borderRadius="0.75rem"
            >
              {hasBackBtn && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p="1rem"
                  borderBottom="1px solid #E6E7E9"
                >
                  <Box as="button" onClick={backOnClick}>
                    <Image src="/assets/imgs/back-arrow.png" />
                  </Box>
                  <Text fontSize="1.125rem" fontWeight="600">
                    {withBackHeader}
                  </Text>
                  <ModalCloseButton pos="unset" />
                </Box>
              )}

              {hasHeader && (
                <ModalHeader
                  display={hasBackBtn ? "none" : ""}
                  fontSize="1.125rem"
                  fontWeight="600"
                  borderBottom="1px solid #E6E7E9"
                >
                  {modalHeader}
                </ModalHeader>
              )}

              {hasCloseBtn && (
                <ModalCloseButton display={hasBackBtn ? "none" : ""} />
              )}
              <ModalBody p="1.5rem" {...modalBodyStyle}>
                {modalBody}
              </ModalBody>

              {hasFooter && (
                <ModalFooter
                  p="1.5rem"
                  borderTop="1px solid #E6E7E9"
                  bg="white"
                  borderRadius="0 0 12px 12px"
                  {...footerStyle}
                >
                  {modalFooterBtn}
                </ModalFooter>
              )}
            </ModalContent>
          </Modal>

          <Modal
            isOpen={isSecondOpen}
            onClose={onSecondClose}
            isCentered
            blockScrollOnMount={false}
          >
            <ModalOverlay backdropFilter="blur(10px)" />
            <ModalContent
              maxW={modalMaxWidth ? "max-content" : ""}
              w={"70vw"}
              h={modalHeight}
              borderRadius="0.75rem"
              mt="2rem"
            >
              <ModalHeader>Preview Prescription</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <VStack align="center">
                  <Box mt="1rem" overflowY={"auto"}>
                    <Prescription {...inputToPrescription} />
                  </Box>
                </VStack>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Box>
      </>
    );
  }
);

export default EndVisitDynamicModal;
