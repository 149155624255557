import moment from "moment";
export default function formAPIPatientDetails({
    patientname,
    mobileno,
    email,
    doctorname,
    appointmentdate,
    invoicenumber,
    
})
{
  return Object.freeze({
    patientname,
    mobileno,
    email,
    doctorname,
    appointmentdate,
    invoicenumber,
  });
}