import formapibilling from "./billing";


var formattedOutput = null;

const formapimodalbilling = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formapibilling(item));


  return formattedOutput
};

export default formapimodalbilling