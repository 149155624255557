import React, { useState, useRef, useEffect } from 'react';
import { Box, Input, FormControl, Image, InputRightElement, FormLabel, InputGroup, useOutsideClick } from '@chakra-ui/react';
import 'react-day-picker/dist/style.css';
import { DayPicker } from 'react-day-picker';
import format from 'date-fns/format';
import { isValid, isAfter, isBefore } from 'date-fns';
import moment from 'moment';


const COLORS = {
  INPUT_FOCUS_DARK: "#384052",
  PRIMARY_WHITE: "#ffffff",
  BTN_DISABLE: "#f0f0f0",
  BTN_DISABLE_TEXT: "#a0a0a0",
};

const activeLabelStyles = {
  transform: "scale(0.85) translateY(-24px)",
  fontSize: "sm",
  color: COLORS.INPUT_FOCUS_DARK,
  opacity: "50%",
};

const inputStyles = {
  borderRadius: "0px",
  background: COLORS.PRIMARY_WHITE,
  boxShadow: "none",
  width: "100%",
  height: "48px",
  _disabled: {
    bg: COLORS.BTN_DISABLE,
    color: COLORS.BTN_DISABLE_TEXT,
    cursor: "not-allowed",
  },
  _hover: {
    borderRadius: "10px",
    border: `2px solid`,
    borderColor: COLORS.INPUT_FOCUS_DARK,
    outline: "none",
    bg: "none",
  },
  _focus: {
    borderRadius: "10px",
    border: "2px solid",
    borderColor: COLORS.INPUT_FOCUS_DARK,
    outline: "none",
  },
};

function DatePicker({
  id,
  onDateChange,
  placeholderProp,
  defaultDate,
  value,  // Accept the value prop to control the date picker
  clearValue = false,
  dateWidth = "100%",
  onChange,
  leftPos,
  calendarStlye,
  datePickerStyle,
  disableFutureDates,
  disablePastDates,
  disableDatePicker = false,
  minDate,
  maxDate,
  datePickerInputStyle,
  CUSTOM_DISABLE = false,
  patientDOB,
}) {
 
  const [selected, setSelected] = useState(defaultDate ? moment(defaultDate, 'DD-MM-yyyy').toDate() : null);
  const [isDatePickerVisible, setDatePickerVisible] = useState(false);
  const outSideClick = useRef(null);
  const minDateFromDOB = patientDOB ? moment(patientDOB, 'Do MMMM, YYYY').toDate() : null; // Parse DOB



  // useEffect(() => {
  //   setSelected(value ? moment(value, 'DD-MM-yyyy').toDate() : null); // Update internal state when the value prop changes
  // }, [value]);
  useEffect(() => {
    if (clearValue) {
      setSelected(value ? moment(value, 'DD-MM-yyyy').toDate() : null); // Update internal state when the value prop changes
    }
  }, [value]);


  const handleInputClick = () => {
    disableDatePicker ? setDatePickerVisible(false) : setDatePickerVisible(prev => !prev);
  };

  const handleDateSelect = (date) => {
    if (date) {
      setSelected(date);
      if (onDateChange) {
        onDateChange(date);
      }
    }
    setDatePickerVisible(false);

  };

  const isLabelActive = selected != null;

  useOutsideClick({
    ref: outSideClick,
    handler: () => isDatePickerVisible && setDatePickerVisible(false)
  });

  const css = `
  .rdp-day_selected {
    background-color: #1F50C6
  }`

  // const disableDates = date => {
  //   // Disable future dates
  //   return isAfter(date, new Date());
  // };



  const disableDates = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize today's date for comparison

    if (CUSTOM_DISABLE == false) {

      // this is previous code *(REASON THIS COULD NOT BE USED FOR FILTERATION):-

      if (disableFutureDates) {
        return isAfter(date, today); // Disable all future dates
      }

      // *The issue is in below line, mindate is always stuck on today, as in this line we are giving today in isBefore
      if (minDate && isBefore(date, today)) { 
        return true; // Disable all dates before the today date
      }

      if (maxDate && isAfter(date, new Date(maxDate))) {
        return true; // Disable all dates after the maximum date
      }



    } else  if (CUSTOM_DISABLE == true) {
      // BELOW CODE IS MOSTLY USED FOR FILTERATION - 

      // if CUSTOM_DISABLE is true - in FromDate- we need to send disableFutureDates and maxDate
      //                           - in toDate  - we need to send maxDate and minDate

      //initially minDate and maxDate will be emty "" as they are the Values of the fromDate and ToDate
      //so the below code will work :

      let shouldDisable = false; // Flag to determine if the date should be disabled

      // Always check the user's date of birth first
      if (minDateFromDOB && isBefore(date, minDateFromDOB)) {
          shouldDisable = true; // Disable all dates before the user's date of birth
      }
  // Check if maxDate is defined
    if (!maxDate) {
        if (disableFutureDates && isAfter(date, today)) {
            shouldDisable = true; // Disable all future dates
        }
    }

    // Check if minDate is defined
    if (!minDate) {
        if (disablePastDates && isBefore(date, today)) {
            shouldDisable = true; // Disable all past dates
        }
    }
      
      
      // once the Fromdate and toDate are selected then, this below code will work
      // it will disable dates befor minDate value
      // and disable the dates after maxDate value

     
    

    // If minDate is defined
    if (minDate && isBefore(date, new Date(minDate))) {
        shouldDisable = true; // Disable all dates before the minimum date
    }

    // If maxDate is defined
    if (maxDate && isAfter(date, new Date(maxDate))) {
        shouldDisable = true; // Disable all dates after the maximum date
    }

    return shouldDisable; // Return the final result



    } 

    return false;
  };


  return (
    <>
      <style>{css}</style>
      <FormControl position="relative" width={dateWidth} ref={outSideClick} {...datePickerStyle}>
        <InputGroup borderRadius="0">
          <Input
            sx={{ ...inputStyles, ...datePickerInputStyle }}
            type="text"
            id={id}
            defaultValue={defaultDate}
            value={selected ? moment(selected).format('DD-MM-yyyy') : ''}
            onClick={handleInputClick}
            onChange={onChange}
            readOnly
            placeholder={!isLabelActive ? placeholderProp : ''}
            p={isLabelActive ? "12px 10px 0 10px" : "0px 10px 0 10px"}
            textOverflow="ellipsis"
            paddingRight="20px"
          />
          <InputRightElement
            h="100%"
            children={
              <Image
                mr="8px"
                cursor="pointer"
                onClick={handleInputClick}
                w="15px"
                src="/assets/imgs/double-arrow.png"
                alt=""
              />
            }
          />
        </InputGroup>
        <FormLabel
          sx={{
            ...activeLabelStyles,
            position: "absolute",
            top: "25px",
            pointerEvents: "none",
            opacity: isLabelActive ? 0.5 : 0,
            zIndex: isLabelActive ? 1 : -1,
            transition: "all 0.2s ease-out",
            left: "6px",
            ...leftPos
          }}
        >
          {placeholderProp}
        </FormLabel>
        {isDatePickerVisible && (
          <Box position="absolute" borderRadius="0 0 15px 15px" zIndex="9999" bg="white" boxShadow="0px 10px 15px -3px rgba(0,0,0,0.1)" transform="scale(0.9)" right="-18px" top="32px"  {...calendarStlye}>

            <DayPicker

              mode="single"
              fromYear={1901}
              toYear={2060}
              captionLayout="dropdown-buttons"
              selected={selected}
              onSelect={handleDateSelect}
              disabled={disableDates}
            />
          </Box>
        )}
      </FormControl>
    </>
  );
}

export default DatePicker;
