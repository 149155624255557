import { useState } from "react";

const useSecondLoader = () => {
  const [isSecondLoading, setIsSecondLoading] = useState(false);

  const showSecondLoader = () => {
    setIsSecondLoading(true);
  };

  const dismissSecondLoader = () => {
    setIsSecondLoading(false);
  };

  return { isSecondLoading, showSecondLoader, dismissSecondLoader };
};

export default useSecondLoader;
