import React,{useState, useEffect} from 'react'
import { Box, Divider, Flex, Image, ListItem, Radio, RadioGroup, Slider, Text, UnorderedList } from '@chakra-ui/react'
import PrimaryInput from '../../components/primary-input'
import { PrimaryButton,Toaster } from '../../components'
import { addVisitFormDetail, getVisitFormDetail } from '../../middleware/services/home/childscreening';
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import { validationMobileNumber, validationOfNumberWithOnly1Decimal } from '../../auth';

const WomenHealthScreen = ({patientid, appointmentid,patientData, onSubmit}) => {
    const { isLoading, showLoader, dismissLoader } = useLoader();
    const {showSuccessToast, showErrorToast} = Toaster();

    const menstrualIssues = [
        { displayName: 'Heavy periods', stateKey: 'heavyPeriods' },
        { displayName: 'Irregular periods', stateKey: 'irregularPeriods' },
        { displayName: 'Painful periods (dysmenorrhea)', stateKey: 'painfulPeriods' },
        { displayName: 'Missed periods (amenorrhea)', stateKey: 'missedPeriods' },
        { displayName: 'Menorrhagia (Prolonged Bleeding)', stateKey: 'menorrhagia' },
      ];
      const menopausalSymptoms = [
        { displayName: 'Hot flashes', stateKey: 'hotFlashes' },
        { displayName: 'Night sweats', stateKey: 'nightSweats' },
        { displayName: 'Mood swings', stateKey: 'moodSwings' },
        { displayName: 'Vaginal dryness', stateKey: 'vaginalDryness' },
        { displayName: 'Sleep disturbances', stateKey: 'sleepDisturbances' },
      ];
    const [formState, setFormState] = useState({
        regularPeriods: '',
        menstrualDetails:'',
        heavyPeriods: '',
        irregularPeriods: '',
        painfulPeriods: '',
        missedPeriods: '',
        menorrhagia: '',
        married: '',
        pregnant: '',
        pregnancyWeeks: '',
        numPregnancies: '',
        numLiveBirths: '',
        miscarriages: '',
        miscarriageDetails: '',
        gynecologicalConditions: '',
        gynecologicalConditionDetails: '',
        papSmear: '',
        papSmearDetails: '',
        abnormalPapSmear: '',
        hpvVaccination: '',
        stds: '',
        stdDetails: '',
        breastexaminations:'',
        mammogram:'',
        mammogramdetails:'',
        breastchanges :'',
        breastchangedetails :'',
        contraceptives: '',
        contraceptiveDetails: '',
        infertility: '',
        infertilityDetails: '',
        menopause: '',
        menopauseAge: '',
        menopausalSymptoms: {
          hotFlashes: '',
          nightSweats: '',
          moodSwings: '',
          vaginalDryness: '',
          sleepDisturbances: ''
        },
        hrt: ''
      });
      let inputForAdd = {
        "patientid": patientid,
        "visitid": appointmentid,
        "visitformdata": [
            // Women's Preventive Health Screening - Menstrual Health
            {
                "sectionid": 4,
                "sectionname": "Women’s Preventive Health Screening",
                "subsectionid": 4.1,
                "subsectionname": "Menstrual Health",
                "question": "Do you have regular menstrual periods?",
                "subquestion": null,
                "nestedquestion": null,
                "value": formState.heavyPeriods,
                "description": formState.menstrualDetails,
            },
            {
                "sectionid": 4,
                "sectionname": "Women’s Preventive Health Screening",
                "subsectionid": 4.1,
                "subsectionname": "Menstrual Health",
                "question": "Have you experienced any of the following menstrual issues?",
                "subquestion": "Heavy periods",
                "nestedquestion": null,
                "value": formState.heavyPeriods,
                "description": null
            },
            {
                "sectionid": 4,
                "sectionname": "Women’s Preventive Health Screening",
                "subsectionid": 4.1,
                "subsectionname": "Menstrual Health",
                "question": "Have you experienced any of the following menstrual issues?",
                "subquestion": "Irregular periods",
                "nestedquestion": null,
                "value": formState.irregularPeriods,
                "description": null
            },
            {
                "sectionid": 4,
                "sectionname": "Women’s Preventive Health Screening",
                "subsectionid": 4.1,
                "subsectionname": "Menstrual Health",
                "question": "Have you experienced any of the following menstrual issues?",
                "subquestion": "Painful periods (dysmenorrhea): ",
                "nestedquestion": null,
                "value": formState.painfulPeriods,
                "description": null
            },
            {
                "sectionid": 4,
                "sectionname": "Women’s Preventive Health Screening",
                "subsectionid": 4.1,
                "subsectionname": "Menstrual Health",
                "question": "Have you experienced any of the following menstrual issues?",
                "subquestion": "Missed periods (amenorrhea)",
                "nestedquestion": null,
                "value": formState.missedPeriods,
                "description": null
            },
            {
                "sectionid": 4,
                "sectionname": "Women’s Preventive Health Screening",
                "subsectionid": 4.1,
                "subsectionname": "Menstrual Health",
                "question": "Have you experienced any of the following menstrual issues?",
                "subquestion": "Menorrhagia (Prolonged bleeding)",
                "nestedquestion": null,
                "value": formState.menorrhagia,
                "description": null
            },
            {
                "sectionid": 4,
                "sectionname": "Women’s Preventive Health Screening",
                "subsectionid": 4.1,
                "subsectionname": "Menstrual Health",
                "question": "Are you married?",
                "subquestion": null,
                "nestedquestion": null,
                "value": formState.married,
                "description": null
            },
            {
                "sectionid": 4,
                "sectionname": "Women’s Preventive Health Screening",
                "subsectionid": 4.1,
                "subsectionname": "Menstrual Health",
                "question": "Are you currently pregnant?",
                "subquestion": null,
                "nestedquestion": null,
                "value": formState.pregnant,
                "description": formState.pregnancyWeeks,
            },
            {
                "sectionid": 4,
                "sectionname": "Women’s Preventive Health Screening",
                "subsectionid": 4.1,
                "subsectionname": "Menstrual Health",
                "question": "Number of pregnancies:",
                "subquestion": null,
                "nestedquestion": null,
                "value": formState.numPregnancies,
                "description": null
            },
            {
                "sectionid": 4,
                "sectionname": "Women’s Preventive Health Screening",
                "subsectionid": 4.1,
                "subsectionname": "Menstrual Health",
                "question": "Number of live births:",
                "subquestion": null,
                "nestedquestion": null,
                "value": formState.numLiveBirths,
                "description": null
            },
            {
                "sectionid": 4,
                "sectionname": "Women’s Preventive Health Screening",
                "subsectionid": 4.1,
                "subsectionname": "Menstrual Health",
                "question": "Any history of miscarriages or stillbirths?",
                "subquestion": null,
                "nestedquestion": null,
                "value": formState.miscarriages,
                "description": formState.miscarriageDetails,
            },
    
            // Gynecological Health
            {
                "sectionid": 4,
                "sectionname": "Women’s Preventive Health Screening",
                "subsectionid": 4.2,
                "subsectionname": "Gynecological Health",
                "question": "Have you ever been diagnosed with any gynecological conditions (e.g., PCOS, endometriosis, uterine fibroids)?",
                "subquestion": null,
                "nestedquestion": null,
                "value": formState.gynecologicalConditions,
                "description": formState.gynecologicalConditionDetails,
            },
            {
                "sectionid": 4,
                "sectionname": "Women’s Preventive Health Screening",
                "subsectionid": 4.2,
                "subsectionname": "Gynecological Health",
                "question": "Have you undergone a Pap smear test for cervix cancer screening?",
                "subquestion": null,
                "nestedquestion": null,
                "value": formState.papSmear,
                "description": formState.papSmearDetails,
            },
            {
                "sectionid": 4,
                "sectionname": "Women’s Preventive Health Screening",
                "subsectionid": 4.2,
                "subsectionname": "Gynecological Health",
                "question": "Have you ever had an abnormal Pap smear result?",
                "subquestion": null,
                "nestedquestion": null,
                "value":  formState.abnormalPapSmear,
                "description": null
            },
            {
                "sectionid": 4,
                "sectionname": "Women’s Preventive Health Screening",
                "subsectionid": 4.2,
                "subsectionname": "Gynecological Health",
                "question": "Have you undergone an HPV vaccination?",
                "subquestion": null,
                "nestedquestion": null,
                "value": formState.hpvVaccination,
                "description": null
            },
            {
                "sectionid": 4,
                "sectionname": "Women’s Preventive Health Screening",
                "subsectionid": 4.2,
                "subsectionname": "Gynecological Health",
                "question": "Have you ever been diagnosed with any sexually transmitted diseases (STDs)?",
                "subquestion": null,
                "nestedquestion": null,
                "value": formState.stds,
                "description": formState.stdDetails,
            },
    
            // Breast Health
            {
                "sectionid": 4,
                "sectionname": "Women’s Preventive Health Screening",
                "subsectionid": 4.3,
                "subsectionname": "Breast Health",
                "question": "Do you perform regular breast self-examinations?",
                "subquestion": null,
                "nestedquestion": null,
                "value": formState.breastexaminations,
                "description": null
            },
            {
                "sectionid": 4,
                "sectionname": "Women’s Preventive Health Screening",
                "subsectionid": 4.3,
                "subsectionname": "Breast Health",
                "question": "Have you ever had a mammogram?",
                "subquestion": null,
                "nestedquestion": null,
                "value": formState.mammogram,
                "description": formState.mammogramdetails,
            },
            {
                "sectionid": 4,
                "sectionname": "Women’s Preventive Health Screening",
                "subsectionid": 4.3,
                "subsectionname": "Breast Health",
                "question": "Have you experienced any breast changes (e.g., lumps, pain, discharge)?",
                "subquestion": null,
                "nestedquestion": null,
                "value": formState.breastchanges,
                "description": formState.breastchangedetails,
            },
    
            // Reproductive Health and Family Planning
            {
                "sectionid": 4,
                "sectionname": "Women’s Preventive Health Screening",
                "subsectionid": 4.4,
                "subsectionname": "Reproductive Health and Family Planning",
                "question": "Are you currently using any contraceptives?",
                "subquestion": null,
                "nestedquestion": null,
                "value": formState.contraceptives,
                "description": formState.contraceptiveDetails,
            },
            {
                "sectionid": 4,
                "sectionname": "Women’s Preventive Health Screening",
                "subsectionid": 4.4,
                "subsectionname": "Reproductive Health and Family Planning",
                "question": "Have you ever had difficulty conceiving (infertility)?",
                "subquestion": null,
                "nestedquestion": null,
                "value": formState.infertility,
                "description": formState.infertilityDetails,
            },
    
            // Menopause
            {
                "sectionid": 4,
                "sectionname": "Women’s Preventive Health Screening",
                "subsectionid": 4.5,
                "subsectionname": "Menopause",
                "question": "Have you entered menopause (cessation of periods for more than 12 months)?",
                "subquestion": null,
                "nestedquestion": null,
                "value": formState.menopause,
                "description": formState.menopauseAge,
            },
            {
                "sectionid": 4,
                "sectionname": "Women’s Preventive Health Screening",
                "subsectionid": 4.5,
                "subsectionname": "Menopause",
                "question": "Are you experiencing any of the following menopausal symptoms?",
                "subquestion": "Hot flashes",
                "nestedquestion": null,
                "value": formState.menopausalSymptoms.hotFlashes,
                "description": null
            },
            {
                "sectionid": 4,
                "sectionname": "Women’s Preventive Health Screening",
                "subsectionid": 4.5,
                "subsectionname": "Menopause",
                "question": "Are you experiencing any of the following menopausal symptoms?",
                "subquestion": "Night sweats",
                "nestedquestion": null,
                "value": formState.menopausalSymptoms.nightSweats,
                "description": null
            },
            {
                "sectionid": 4,
                "sectionname": "Women’s Preventive Health Screening",
                "subsectionid": 4.5,
                "subsectionname": "Menopause",
                "question": "Are you experiencing any of the following menopausal symptoms?",
                "subquestion": "Mood swings",
                "nestedquestion": null,
                "value": formState.menopausalSymptoms.moodSwings,
                "description": null
            },
            {
                "sectionid": 4,
                "sectionname": "Women’s Preventive Health Screening",
                "subsectionid": 4.5,
                "subsectionname": "Menopause",
                "question": "Are you experiencing any of the following menopausal symptoms?",
                "subquestion": "Vaginal dryness",
                "nestedquestion": null,
                "value": formState.menopausalSymptoms.vaginalDryness,
                "description": null
            },
            {
                "sectionid": 4,
                "sectionname": "Women’s Preventive Health Screening",
                "subsectionid": 4.5,
                "subsectionname": "Menopause",
                "question": "Are you experiencing any of the following menopausal symptoms?",
                "subquestion": "Sleep disturbances",
                "nestedquestion": null,
                "value": formState.menopausalSymptoms.sleepDisturbances,
                "description": null
            },
            {
                "sectionid": 4,
                "sectionname": "Women’s Preventive Health Screening",
                "subsectionid": 4.5,
                "subsectionname": "Menopause",
                "question": "Are you currently taking hormone replacement therapy (HRT)?",
                "subquestion": null,
                "nestedquestion": null,
                "value": formState.hrt,
                "description": null
            },
        ]
    }

    const handleChange = (field, value, subField = null) => {
        setFormState((prevState) => {
            const updatedState = subField
                ? {
                      ...prevState,
                      [field]: {
                          ...prevState[field],
                          [subField]: value,
                      },
                  }
                : {
                      ...prevState,
                      [field]: value,
                  };
    
            // Save updated state to local storage
            localStorage.setItem('formState', JSON.stringify(updatedState));
    
            return updatedState;
        });
    };
    useEffect(() => {
        // Retrieve data from local storage
        const storedFormState = JSON.parse(localStorage.getItem("formState")) || {};
    
        // Merge the stored data with the default state
        setFormState((prevState) => ({
          ...prevState,
          ...storedFormState, // Override default values with stored values
          menopausalSymptoms: {
            ...prevState.menopausalSymptoms,
            ...storedFormState.menopausalSymptoms // Handle nested object separately
          }
        }));
      }, []);   
        
    const saveSectionfour = async ()=>{
          // Remove the last 7 objects if patientData.age < 45
            let filteredVisitFormData = inputForAdd.visitformdata;
            if (patientData?.age < 45) {
                filteredVisitFormData = inputForAdd.visitformdata.slice(0, -7);
            }

            // Add question number starting from 1
            inputForAdd.visitformdata = filteredVisitFormData.map((item, index) => ({
                ...item,
                questionnumber: index + 1,
            }));
        console.log(inputForAdd);
        showLoader();
      try{
        var result = await addVisitFormDetail(inputForAdd);
        if(result.output.message == "Successfully Inserted"){
          //showSuccessToast("Details added Successfully!");
            setTimeout(() => {
            const newActiveComponent = "women-screening";
            onSubmit(newActiveComponent);
            }, 1000);
        }
      
      } catch (error) {
        console.error("Error in home:", error);
      }
      dismissLoader();
        
      }
  return (
    <>
    {isLoading && <Loader miniLoader={true} />}
      <Flex gap='1rem' flexDir='column' w='100%'>
            {/* Women’s Preventive Health Screening start */}
            <Box>
                <Text fontSize='1rem' fontWeight='600'>Menstrual Health</Text>
                <Box display='flex' flexDirection='column'>
                    <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                        <Text fontSize='0.875rem'>Do you have regular menstrual periods?</Text>
                        <RadioGroup
                         colorScheme='green' display='flex' gap='1rem'
                         value={formState.regularPeriods}
                         onChange={(value) => handleChange('regularPeriods', value)}>
                            <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                            <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                        </RadioGroup>
                    </Flex>
                    {formState.regularPeriods === 'no' && (
                    <PrimaryInput 
                        variant='fullRound'
                        inputPlace='If no, please specify' 
                        inputStyleCss={{mt:'0.875rem',w:'50%'}} 
                        inputValue={formState.menstrualDetails || ''}
                        onChange={(e) => handleChange('menstrualDetails', e.target.value)}
                        maxLength={250}
                    />
                )}
                </Box>
                <Divider mt='0.75rem'/>
            </Box>
            <Box>
            <Text fontSize="0.875rem">Have you experienced any of the following menstrual issues?</Text>
            <UnorderedList display="flex" flexDir="column" gap="0.5rem">
                {menstrualIssues.map(({ displayName, stateKey }) => (
                <ListItem key={stateKey} m='0' mt={stateKey == 'heavyPeriods' ? 2 : 0}>
                    <Flex justifyContent="space-between" alignItems="center">
                    <Text fontSize="0.875rem">{displayName}:</Text>
                    <RadioGroup
                        colorScheme="green"
                        display="flex"
                        gap="1rem"
                        value={formState[stateKey]} // Use the correct state key
                        onChange={(value) => handleChange(stateKey, value)} // Update using the state key
                    >
                        <Radio value="yes"><Text fontSize="0.875rem">Yes</Text></Radio>
                        <Radio value="no"><Text fontSize="0.875rem">No</Text></Radio>
                    </RadioGroup>
                    </Flex>
                </ListItem>
                ))}
            </UnorderedList>
            </Box>
            {/* <Box>
                <Text fontSize='0.875rem'>Have you experienced any of the following menstrual issues?</Text>
                <UnorderedList display='flex' flexDir='column' gap='0.875rem'>
                    <ListItem m='0'>
                        <Flex justifyContent='space-between' alignItems='center'>
                            <Text fontSize="0.875rem">Heavy periods:</Text>
                            <RadioGroup colorScheme='green' display='flex' gap='1rem'
                            >
                                <Radio value='yes'><Text fontSize='0.875rem'>Yes </Text></Radio>
                                <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                            </RadioGroup>
                        </Flex>
                    </ListItem>
                    <ListItem m='0'>
                        <Flex justifyContent='space-between' alignItems='center'>
                            <Text fontSize="0.875rem">Irregular periods:</Text>
                            <RadioGroup colorScheme='green' display='flex' gap='1rem'>
                                <Radio><Text fontSize='0.875rem'>Yes </Text></Radio>
                                <Radio><Text fontSize='0.875rem'>No</Text></Radio>
                            </RadioGroup>
                        </Flex>
                    </ListItem>
                    <ListItem m='0'>
                        <Flex justifyContent='space-between' alignItems='center'>
                            <Text fontSize="0.875rem">Missed periods (amenorrhea):</Text>
                            <RadioGroup colorScheme='green' display='flex' gap='1rem'>
                                <Radio><Text fontSize='0.875rem'>Yes </Text></Radio>
                                <Radio><Text fontSize='0.875rem'>No</Text></Radio>
                            </RadioGroup>
                        </Flex>
                    </ListItem>
                    <ListItem m='0'>
                        <Flex justifyContent='space-between' alignItems='center'>
                            <Text fontSize="0.875rem">Menorrhagia (Prolong Bleeding):</Text>
                            <RadioGroup colorScheme='green' display='flex' gap='1rem'>
                                <Radio><Text fontSize='0.875rem'>Yes </Text></Radio>
                                <Radio><Text fontSize='0.875rem'>No</Text></Radio>
                            </RadioGroup>
                        </Flex>
                    </ListItem>
                </UnorderedList>
            </Box> */}
             <Divider mt='0.75rem'/>
            <Box display='flex' flexDirection='column'>
                <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                    <Text fontSize='0.875rem'>Are you married?</Text>
                    <RadioGroup colorScheme='green' display='flex' gap='1rem'
                    value={formState.married}
                    onChange={(value) => handleChange('married', value)}>
                        <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                        <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                    </RadioGroup>
                </Flex>
            </Box>
            
            <Divider mt='0.75rem'/>
            <Box display='flex' flexDirection='column'>
                <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                    <Text fontSize='0.875rem'>Are you currently pregnant?</Text>
                    <RadioGroup colorScheme='green' display='flex' gap='1rem'
                    value={formState.pregnant}
                    onChange={(value) => handleChange('pregnant', value)}>
                        <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                        <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                    </RadioGroup>
                </Flex>
                {formState.pregnant === 'yes' && (
                <PrimaryInput 
                    variant='fullRound'
                    inputPlace='If yes, how many weeks?' 
                    inputStyleCss={{mt:'0.875rem',w:'50%'}}
                    inputValue={formState.pregnancyWeeks || ''}
                    onChange={(e) => handleChange('pregnancyWeeks', e.target.value)}
                    maxLength={250}
                />
            )}
            </Box>
            <Divider mt='0.75rem'/>
            <Box display='flex' alignItems='center' justifyContent='space-between' w='100%'>
                <Text fontSize='0.875rem'>Number of pregnancies:</Text>
                <PrimaryInput variant='fullRound' inputPlace='Value' inputStyleCss={{w:'25%'}}
                inputValue={formState.numPregnancies || ''}
                onChange={(e) => handleChange('numPregnancies', validationMobileNumber(e.target.value, 5))}/>
            </Box>
            <Divider mt='0.75rem'/>
            <Box display='flex' alignItems='center' justifyContent='space-between' w='100%'>
                <Text fontSize='0.875rem'>Number of live births:</Text>
                <PrimaryInput variant='fullRound' inputPlace='Value' inputStyleCss={{w:'25%'}}
                inputValue={formState.numLiveBirths || ''}
                onChange={(e) => handleChange('numLiveBirths',  validationMobileNumber(e.target.value, 5))}/>
            </Box>
            <Divider mt='0.75rem'/>
            <Box display='flex' flexDirection='column'>
                <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                    <Text fontSize='0.875rem'>Any history of miscarriages or stillbirths?</Text>
                    <RadioGroup colorScheme='green' display='flex' gap='1rem'
                    value={formState.miscarriages}
                    onChange={(value) => handleChange('miscarriages', value)}>
                        <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                        <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                    </RadioGroup>
                </Flex>
                {formState.miscarriages === 'yes' && (
                <PrimaryInput 
                    variant='fullRound'
                    inputPlace='If yes, please describe' 
                    inputStyleCss={{mt:'0.875rem',w:'50%'}}
                    inputValue={formState.miscarriageDetails || ''}
                    onChange={(e) => handleChange('miscarriageDetails', e.target.value)}
                    maxLength={250}
                />
            )}
            </Box>
            {/* Women’s Preventive Health Screening end */}       

            <Divider />

            {/* Gynecological Health start */}
            <Box>
                <Text fontSize='1rem' fontWeight='600'>Gynecological Health</Text>
                <Box display='flex' flexDirection='column'>
                    <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                        <Text fontSize='0.875rem'>Have you ever been diagnosed with any gynecological conditions (e.g., PCOS, endometriosis, uterine fibroids)?</Text>
                        <RadioGroup colorScheme='green' display='flex' gap='1rem'
                        value={formState.gynecologicalConditions}
                        onChange={(value) => handleChange('gynecologicalConditions', value)}>
                            <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                            <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                        </RadioGroup>
                    </Flex>
                    {formState.gynecologicalConditions === 'yes' && (
                    <PrimaryInput 
                        variant='fullRound'
                        inputPlace='If yes, please specify' 
                        inputStyleCss={{mt:'0.875rem',w:'50%'}}
                        inputValue={formState.gynecologicalConditionDetails || ''}
                        onChange={(e) => handleChange('gynecologicalConditionDetails', e.target.value)}
                        maxLength={250}
                    />
                )}
                </Box>
                <Divider mt='0.75rem'/>
                <Box display='flex' flexDirection='column'>
                    <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                        <Text fontSize='0.875rem'>Have you undergone a Pap smear test for cervix cancer screening?</Text>
                        <RadioGroup colorScheme='green' display='flex' gap='1rem'
                        value={formState.papSmear}
                        onChange={(value) => handleChange('papSmear', value)}>
                            <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                            <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                        </RadioGroup>
                    </Flex>
                    {formState.papSmear === 'yes' && (
                    <PrimaryInput 
                        variant='fullRound'
                        inputPlace='If yes, when was the last test?' 
                        inputStyleCss={{mt:'0.875rem',w:'50%'}}
                        inputValue={formState.papSmearDetails || ''}
                        onChange={(e) => handleChange('papSmearDetails', e.target.value)}
                        maxLength={250}
                    />
                )}
                </Box>
                <Divider mt='0.75rem'/>
                <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                    <Text fontSize='0.875rem'>Have you ever had an abnormal Pap smear result?</Text>
                    <RadioGroup colorScheme='green' display='flex' gap='1rem'
                    value={formState.abnormalPapSmear}
                    onChange={(value) => handleChange('abnormalPapSmear', value)}>
                        <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                        <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                    </RadioGroup>
                </Flex>
                <Divider mt='0.75rem'/>
                <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                    <Text fontSize='0.875rem'>Have you undergone an HPV vaccination?</Text>
                    <RadioGroup colorScheme='green' display='flex' gap='1rem'
                    value={formState.hpvVaccination}
                    onChange={(value) => handleChange('hpvVaccination', value)}>
                        <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                        <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                    </RadioGroup>
                </Flex>
                <Divider mt='0.75rem'/>
                <Box display='flex' flexDirection='column'>
                    <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                        <Text fontSize='0.875rem'>Have you ever been diagnosed with any sexually transmitted Diseases (STDs)?</Text>
                        <RadioGroup colorScheme='green' display='flex' gap='1rem'
                        value={formState.stds}
                        onChange={(value) => handleChange('stds', value)}>
                            <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                            <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                        </RadioGroup>
                    </Flex>
                    {formState.stds === 'yes' && (
                    <PrimaryInput 
                        variant='fullRound'
                        inputPlace='If yes, specify' 
                        inputStyleCss={{mt:'0.875rem',w:'50%'}}
                        inputValue={formState.stdDetails || ''}
                        onChange={(e) => handleChange('stdDetails', e.target.value)}
                        maxLength={250}
                    />
                )}
                </Box>
            </Box>
            {/* Gynecological Health end */}

            <Divider />

            {/* Breast Health start */}
            <Box>
              <Text fontSize='1rem' fontWeight='600'>Breast Health</Text>
                <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                    <Text fontSize='0.875rem'>Do you perform regular breast self-examinations?</Text>
                    <RadioGroup colorScheme='green' display='flex' gap='1rem'
                    value={formState.breastexaminations}
                    onChange={(value) => handleChange('breastexaminations', value)}>
                        <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                        <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                    </RadioGroup>
                </Flex>
                <Divider mt='0.75rem'/>
                <Box display='flex' flexDirection='column'>
                    <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                        <Text fontSize='0.875rem'>Have you ever had a mammogram?</Text>
                        <RadioGroup colorScheme='green' display='flex' gap='1rem'
                        value={formState.mammogram}
                        onChange={(value) => handleChange('mammogram', value)}>
                            <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                            <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                        </RadioGroup>
                    </Flex>
                    {formState.mammogram === 'yes' && (
                    <PrimaryInput 
                        variant='fullRound'
                        inputPlace='If yes, when was the last one?' 
                        inputStyleCss={{mt:'0.875rem',w:'50%'}}
                        inputValue={formState.mammogramdetails || ''}
                        onChange={(e) => handleChange('mammogramdetails', e.target.value)}
                        maxLength={250}
                    />
                    )}
                </Box>
                <Divider mt='0.75rem'/>
                <Box display='flex' flexDirection='column'>
                    <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                        <Text fontSize='0.875rem'>Have you experienced any breast changes (e.g., lumps, pain, discharge)?</Text>
                        <RadioGroup colorScheme='green' display='flex' gap='1rem'
                        value={formState.breastchanges}
                        onChange={(value) => handleChange('breastchanges', value)}>
                            <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                            <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                        </RadioGroup>
                    </Flex>
                    {formState.breastchanges === 'yes' && (
                    <PrimaryInput 
                        variant='fullRound'
                        inputPlace='If yes, please specify' 
                        inputStyleCss={{mt:'0.875rem',w:'50%'}}
                        inputValue={formState.breastchangedetails || ''}
                        onChange={(e) => handleChange('breastchangedetails', e.target.value)}
                        maxLength={250}
                    />
                )}
                </Box>
            </Box>
            {/* Breast Health end */}

            <Divider />

            {/* Reproductive Health and Family Planning start */}
            <Box>
              <Text fontSize='1rem' fontWeight='600'>Reproductive Health and Family Planning</Text>
                <Box display='flex' flexDirection='column'>
                    <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                        <Text fontSize='0.875rem'>Are you currently using any contraceptives?</Text>
                        <RadioGroup colorScheme='green' display='flex' gap='1rem'
                        value={formState.contraceptives}
                        onChange={(value) => handleChange('contraceptives', value)}>
                            <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                            <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                        </RadioGroup>
                    </Flex>
                    {formState.contraceptives === 'yes' && (
                    <PrimaryInput 
                        variant='fullRound'
                        inputPlace='If yes, which type?' 
                        inputStyleCss={{mt:'0.875rem',w:'50%'}}
                        inputValue={formState.contraceptiveDetails || ''}
                        onChange={(e) => handleChange('contraceptiveDetails', e.target.value)}
                        maxLength={250}
                    />
                    )}
                </Box>
                <Divider mt='0.75rem'/>
                <Box display='flex' flexDirection='column'>
                    <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                        <Text fontSize='0.875rem'>Have you ever had difficulty conceiving (infertility)?</Text>
                        <RadioGroup colorScheme='green' display='flex' gap='1rem'
                        value={formState.infertility}
                        onChange={(value) => handleChange('infertility', value)}>
                            <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                            <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                        </RadioGroup>
                    </Flex>
                    {formState.infertility === 'yes' && (
                    <PrimaryInput 
                        variant='fullRound'
                        inputPlace='If yes, specify the duration' 
                        inputStyleCss={{mt:'0.875rem',w:'50%'}}
                        inputValue={formState.infertilityDetails || ''}
                        onChange={(e) => handleChange('infertilityDetails', e.target.value)}
                        maxLength={250}
                    />
                )}
                </Box>
            </Box>
            {/* Reproductive Health and Family Planning end */}

            <Divider />

            {/* Menopause (For women above 45) start */}
            {patientData?.age > 45 &&
            <Box>
              <Text fontSize='1rem' fontWeight='600'>Menopause (For women above 45)</Text>
                <Box display='flex' flexDirection='column'>
                    <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                        <Text fontSize='0.875rem'>Have you entered menopause (cessation of periods for more than 12 months)?</Text>
                        <RadioGroup colorScheme='green' display='flex' gap='1rem'
                        value={formState.menopause}
                        onChange={(value) => handleChange('menopause', value)}>
                            <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                            <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                        </RadioGroup>
                    </Flex>
                    {formState.menopause === 'yes' && (
                    <PrimaryInput 
                        variant='fullRound'
                        inputPlace='If yes, at what age?' 
                        inputStyleCss={{mt:'0.875rem',w:'50%'}}
                        inputValue={formState.menopauseAge || ''}
                        onChange={(e) => handleChange('menopauseAge', e.target.value)}
                        maxLength={250}
                    />
                )}
                </Box>
                <Divider mt='0.75rem'/>
                <Box>
                <Text fontSize="0.875rem">Have you experienced any of the following menopausal symptoms?</Text>
                <UnorderedList display="flex" flexDir="column" gap="0.5rem">
                    {menopausalSymptoms.map(({ displayName, stateKey }) => (
                    <ListItem key={stateKey} m='0' mt={stateKey == 'hotFlashes' ? 2 : 0}>
                        <Flex justifyContent="space-between" alignItems="center">
                        <Text fontSize="0.875rem">{displayName}:</Text>
                        <RadioGroup
                            colorScheme="green"
                            display="flex"
                            gap="1rem"
                            value={formState.menopausalSymptoms[stateKey]} // Access nested state
                            onChange={(value) =>
                            handleChange('menopausalSymptoms', value, stateKey) // Update nested field
                            }
                        >
                            <Radio value="yes"><Text fontSize="0.875rem">Yes</Text></Radio>
                            <Radio value="no"><Text fontSize="0.875rem">No</Text></Radio>
                        </RadioGroup>
                        </Flex>
                    </ListItem>
                    ))}
                </UnorderedList>
                </Box>
                {/* <Box>
                    <Text fontSize='0.875rem'>Are you experiencing any of the following menopausal symptoms?</Text>
                    <UnorderedList display='flex' flexDir='column' gap='0.875rem'>
                        <ListItem m='0'>
                            <Flex justifyContent='space-between' alignItems='center'>
                                <Text fontSize="0.875rem">Hot flashes:</Text>
                                <RadioGroup colorScheme='green' display='flex' gap='1rem'>
                                    <Radio><Text fontSize='0.875rem'>Yes </Text></Radio>
                                    <Radio><Text fontSize='0.875rem'>No</Text></Radio>
                                </RadioGroup>
                            </Flex>
                        </ListItem>
                        <ListItem m='0'>
                            <Flex justifyContent='space-between' alignItems='center'>
                                <Text fontSize="0.875rem">Night sweats:</Text>
                                <RadioGroup colorScheme='green' display='flex' gap='1rem'>
                                    <Radio><Text fontSize='0.875rem'>Yes </Text></Radio>
                                    <Radio><Text fontSize='0.875rem'>No</Text></Radio>
                                </RadioGroup>
                            </Flex>
                        </ListItem>
                       
                        <ListItem m='0'>
                            <Flex justifyContent='space-between' alignItems='center'>
                                <Text fontSize="0.875rem">Mood swings:</Text>
                                <RadioGroup colorScheme='green' display='flex' gap='1rem'>
                                    <Radio><Text fontSize='0.875rem'>Yes </Text></Radio>
                                    <Radio><Text fontSize='0.875rem'>No</Text></Radio>
                                </RadioGroup>
                            </Flex>
                        </ListItem>
                        
                        <ListItem m='0'>
                            <Flex justifyContent='space-between' alignItems='center'>
                                <Text fontSize="0.875rem">Vaginal dryness:</Text>
                                <RadioGroup colorScheme='green' display='flex' gap='1rem'>
                                    <Radio><Text fontSize='0.875rem'>Yes </Text></Radio>
                                    <Radio><Text fontSize='0.875rem'>No</Text></Radio>
                                </RadioGroup>
                            </Flex>
                        </ListItem>
                        
                        <ListItem m='0'>
                            <Flex justifyContent='space-between' alignItems='center'>
                                <Text fontSize="0.875rem">Sleep disturbances:</Text>
                                <RadioGroup colorScheme='green' display='flex' gap='1rem'>
                                    <Radio><Text fontSize='0.875rem'>Yes </Text></Radio>
                                    <Radio><Text fontSize='0.875rem'>No</Text></Radio>
                                </RadioGroup>
                            </Flex>
                        </ListItem>
                    </UnorderedList>
                </Box> */}
                 <Divider mt='0.75rem'/>
                <Box display='flex' flexDirection='column'>
                    <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                        <Text fontSize='0.875rem'>Are you currently taking hormone replacement therapy (HRT)?</Text>
                        <RadioGroup colorScheme='green' display='flex' gap='1rem'
                        value={formState.hrt}
                        onChange={(value) => handleChange('hrt', value)}>
                            <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                            <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                        </RadioGroup>
                    </Flex>
                </Box>
            </Box>
            }
            {/* Menopause (For women above 45) end */}
        </Flex>
        <Box pos='fixed' w='calc(100% - 6rem)' left='5.75rem' bottom='0' p='1rem' bg='white' boxShadow='rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px'>
          <PrimaryButton buttonText='Save' onClick={saveSectionfour} btnStyle={{w:'25%', float:'right'}} />
        </Box>
    </>
  )
}

export default WomenHealthScreen