import formapimodalorderdetails from './orderdetails';
import formAPIPatientDetails from './patientdetails';
export default function formapiorderdetailsdata({
    data,
    patientdetail,

})
{
  return Object.freeze({
    data : data !== null ? formapimodalorderdetails(data):[],
    patientdetail:patientdetail !== null ? formAPIPatientDetails(patientdetail):{},
  });
}