import React from 'react';
import { Table, TableContainer, Thead, Tbody, Tfoot, Tr, Th, Td } from '@chakra-ui/react';

const SimpleTable = ({onClick, headers, tableBody, footerSection, tableStyle, tableHeader, isTh = true, tableHeaders,  footerVisible = true, isDataPresent, ThStyle, footerStyle, className, tableCss,isThCss }) => {
  return (
    <TableContainer {...tableStyle} className={className}>
      <Table {...tableCss}> 
        <Thead {...tableHeader} pos='sticky' top='0' bg='white' borderBottom="1px solid #E6E7E9" zIndex='99'>
        {isTh ? (
          <Tr>
            {headers.map((header, index) => (
              <Th key={index} onClick={onClick} fontSize="0.87rem" fontWeight="500" {...isThCss}>{header.label}</Th>
            ))}
          </Tr>
          ) : (
            tableHeaders
          )}
        </Thead>
        <Tbody bg="#ffffff">
        {isDataPresent ? (
            tableBody
          ) : (
            <Tr>
              <Td colSpan={headers.length} textAlign="center" height="18rem">No data available...</Td>
            </Tr>
          )}
        </Tbody>
        {footerVisible && (
          <Tfoot bg="#ffffff" {...footerStyle}>
          {footerSection}
        </Tfoot>
        )}
      </Table>
    </TableContainer>
  );
};

export default SimpleTable;
