import React, { useRef, useState, useEffect } from "react";
import useLoader from "../../components/loader/loaderstates";
import MiniSideBar from "../../components/mini-sidebar";
import Loader from "../../components/loader/loader";
import SecondaryNav from "../../components/seconday-nav";
import PrimaryButton from "../../components/primary-button";
import {
  Box,
  Divider,
  Flex,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import SimpleTable from "../../components/simple-table";
import { DropdownInput, PrimaryInput, Toaster } from "../../components";
import { COLORS } from "../../components/styles/colors";
import DynamicModal from "../../components/dynamic-modal";
import { useLocation, useNavigate } from "react-router-dom";
import { FetchOrderDetails,FetchInvoice,FetchDoctorsList, GetMasterData, FetchMedicineList, SaveGenerateInvoice } from "../../middleware/services/billing/index";
import { uploadImageInProfile } from "../../middleware/services/profile";
import moment from "moment";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { FetchPatientViewDetails } from "../../middleware/services/patients";
import { getAddressInfo, getCurrency, getDoctorName,getMobile, getEmail, getLogo, getHospitalName } from "../../auth";
import { useReactToPrint } from "react-to-print";


const initialRow = {
  medicinename: '',
  medicinenamedisplay: '',
  batchnumber: '',
  mrp: '',
  quantity: '',
  totalamount: '',
  inventoryid: '',
  prescriptionid: '',
  labname: '',
  doctorname: '',
  remainingquantity:'',
  totalCgst:'',
  totalSgst:'',
  sgst:'',
  cgst:'',
};

const OTCSale = () => {
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const location = useLocation();
  const { type } = location.state || {};
  const [doctorid, setDoctorid] = useState();
  const [data, setData] = useState(null);
  const [patientsList, setPatientsList] = useState(null);
  const [doctorslist, setDoctorsList] = useState(null);
  const [patientdata, setPatientData] = useState(null);
  const [InvoiceNumber, setInvoiceNumber] = useState(null);
  const [medicineList, setMedicineList] = useState([]);
  const today = moment().format("YYYY-MM-DD HH:mm:ss.SSS");
  const [suggestions, setSuggestions] = useState([]);
  const [patientssuggestions, setPatientsSuggestions] = useState([]);
  const [showMedicineNamesDropdown, setShowMedicineNamesDropdown] = useState(-1);
  const [showLabNamesDropdown, setShowLabNamesDropdown] = useState(-1);
  const [showPatientNamedropdown, setShowPatientsNamesDropdown] = useState(false);
  const [ishospitalsale, setIsHospitalsale] = useState(false);
  const [showAppointmentNamesDropdown, setShowAppointmentNamesDropdown] = useState(-1);
  const deleteRowModal = useRef();
  const generatepopup = useRef();
  const [rows, setRows] = useState([initialRow]);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [QuantityInStrip, setQuantityInStrip] = useState(0);
  const [isprintclicked, setIsPrintClicked] = useState("false");
  const [ShowPrescription, setShowPrescription] = useState(true);
  const [ShowLab, setShowLab] = useState(false);
  const [ShowAppointment, setShowAppointment] = useState(false);
  const [labList, setLabList] = useState([]);
  const [boolValForSuggestions, setBoolValForSuggestions] = useState(true);
  const [TabValue, setTabValue] = useState("Prescription");
  const [PatientMobile, setPatientMobile] = useState("");
  const [PatientName, setPatientName] = useState("");
  const [currency, setCurrency] = useState("");
  const [hospitallogo, setHospitalLogo] = useState("");
  const [Address, setAddressDetails] = useState([]);
  const [doctorname, SetDoctorName] = useState(getDoctorName());
  const [hospitalname, setHospitalName] = useState("");
  const [focusedItemIndex, setFocusedItemIndex] = useState(-1);
  const suggestionsMenuRef = useRef(null);
  const navigate = useNavigate();
  const quantityRef = useRef(null);
  const { showSuccessToast, showErrorToast } = Toaster();
  const [ inputId , setInputId ] = useState("");
  const [ invoiceheader , setInvoiceHeader ] = useState("Invoice");
  const printRef = useRef();


  useEffect(() => {
    fetchMedicines();
    fetchInvoiceNumber();
    setRows([{...initialRow}]);
    const currency = getCurrency();
    if(currency == "USD"){
      const uscurrency = '$'
      setCurrency(uscurrency);
    }else{
    setCurrency(currency);
    }
    const add = getAddressInfo();
    setAddressDetails(add);
    var hospitallogo = getLogo();
    //var imagepath = "https://midocfileserver.blob.core.windows.net/midocdev/";
    if(hospitallogo){
      setHospitalLogo(hospitallogo)
    }
    var hospitalname = getHospitalName();
    if(hospitalname){
      setHospitalName(hospitalname)
    }
    
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    return moment(dateString).format("ddd, D MMM YYYY");
  };
  const fetchInvoiceNumber = async () => {
    try {
      const result = await FetchInvoice({ doctorid });
      if (result.output.invoicenumber) {
        setInvoiceNumber(result.output.invoicenumber)
      }

      dismissLoader();
    } catch (error) {
      console.error("Error in home:", error);
    }
  };
  const fetchMedicines = async () => {
    try {
      const result = await FetchMedicineList({
        doctorid,
        "saletype":"OTC sale"
       });
      if (result.output.data.length > 0) {
        setMedicineList(result.output.data);
        setShowPrescription(true);
      }
      dismissLoader();
    } catch (error) {
      console.error("Error in home:", error);
    }
  };

  const filterMedicineNames = (medicine) => {
    const filtered = medicineList.filter((obj) =>
      obj.productname?.toLowerCase().includes(medicine.toLowerCase())
    );
    setSuggestions(filtered);
    setBoolValForSuggestions(!boolValForSuggestions);
  };

  const handleMedicineNameChange = (id, name, index) => {
    setInputId(index)
    const newRows = [...rows];
    newRows[index].medicinename = name;
    if (!name) {
      newRows[index] = { ...initialRow }; // Reset the entire row if medicine name is cleared
    }
    setRows(newRows);

    if (name.length >= 3) { // Check if the length of the name is 3 or more characters
      filterMedicineNames(name);
      setShowMedicineNamesDropdown(index);
      setBoolValForSuggestions(!boolValForSuggestions);
    } else {
      setSuggestions([]);
      setShowMedicineNamesDropdown(-1);
      setBoolValForSuggestions(!boolValForSuggestions);
    }
  };


  const handleBatchNumberChange = (batch, index) => {
    const newRows = [...rows];
    newRows[index].batchnumber = batch;
    setRows(newRows);
  };

  const handleMRPChange = (mrp, index) => {
    const newRows = [...rows];
    newRows[index].mrp = mrp;
    setRows(newRows);
    calculateTotalAmount(index);
  };

  const handleQuantityChange = (quantity, index) => {
    const newRows = [...rows];

    // Check if the quantity is greater than remainingQuantity
    if (newRows[index].remainingquantity != '' && quantity > newRows[index].remainingquantity) {
      // If it is, set the quantity to the remainingQuantity
      showErrorToast("Quantity should be less than the stock quantity ("+newRows[index].remainingquantity +")")
      newRows[index].quantity = newRows[index].remainingquantity;
    } else {
      // Otherwise, set the quantity to the provided value
      newRows[index].quantity = quantity;
    }

    setRows(newRows);
    calculateTotalAmount(index);
    //calculateGST(index);
    calculateTotalCgstSumForDisplay();
    calculateTotalSgstSumForDisplay();
  };

  const handleTotalAmountChange = (amount, index) => {
    const newRows = [...rows];
    newRows[index].totalamount = amount;
    setRows(newRows);
  };

  const calculateTotalAmount = (index) => {
    const newRows = [...rows];
    if (QuantityInStrip && newRows[index].mrp) {
      newRows[index].totalamount = ((newRows[index].mrp / QuantityInStrip) * newRows[index].quantity).toFixed(2);
    } else {
      newRows[index].totalamount = ((newRows[index].mrp / 10) * newRows[index].quantity).toFixed(2);
    }
    setRows(newRows);
};
// const calculateGST = (index) => {
//   const newRows = [...rows];
//   const quantityInStrip = QuantityInStrip || 10; // Fallback to 10 if QuantityInStrip is not defined

//   // Calculate SGST and CGST per unit
//   const sgstPerUnit = (newRows[index].sgst / quantityInStrip).toFixed(2);
//   const cgstPerUnit = (newRows[index].cgst / quantityInStrip).toFixed(2);

//   // Calculate the total SGST and CGST based on the updated quantity
//   const totalSgst = (sgstPerUnit * newRows[index].quantity).toFixed(2);
//   const totalCgst = (cgstPerUnit * newRows[index].quantity).toFixed(2);

//   // Update the row with the calculated GST
//   newRows[index].totalSgst = totalSgst;
//   newRows[index].totalCgst = totalCgst;
//   // Update the rows state with the new GST values
//   setRows(newRows);
// };
  const handleSelect = (suggestion, index) => {
    const newRows = [...rows];
    newRows[index].medicinename = suggestion.productname  + " ("+suggestion.remainingquantity+" Qty)";
    newRows[index].medicinenamedisplay = suggestion.productname;
    newRows[index].batchnumber = suggestion.batchnumber;
    newRows[index].mrp = suggestion.costperstrip;
    newRows[index].inventoryid = suggestion.inventoryid;
    newRows[index].remainingquantity = suggestion.remainingquantity;
    newRows[index].sgst = suggestion.sgst;
    newRows[index].cgst = suggestion.cgst;
    setQuantityInStrip(suggestion.quantityineachstrip);
    calculateTotalAmount(index);
    setRows(newRows);
    setSuggestions([]);
    setShowMedicineNamesDropdown(-1);
    setFocusedItemIndex(-1);  // Reset the focused item index after selection
    focusInput("quantity"); // Focus logic
  };

  const AddAnotherRowPrescription = () => {
    const initialRow = {
      medicinename: '',
      batchnumber: '',
      mrp: '',
      quantity: '',
      totalamount: '',
      inventoryid: '',
      prescriptionid: '',
      labname: '',
    };
    setRows((prevRows) => [...prevRows, { ...initialRow }]);
    focusInput("medicinename"); // Focus logic
  };
  const AddAnotherRowLab = () => {
    const initialRow = {
      medicinename: '',
      batchnumber: '',
      mrp: '',
      quantity: '',
      totalamount: '',
      inventoryid: '',
      prescriptionid: '',
      labname: '',
    };
    setRows((prevRows) => [...prevRows, { ...initialRow }]);
    focusInput("LabNameInAdd"); // Focus logic
  };
  const deleteRowHandler = () => {
    const newRows = rows.filter((_, i) => i !== rowToDelete);
    setRows(newRows);
    deleteRowModal.current.closeModal();
  };

  const confirmDeleteRow = (index) => {
    setRowToDelete(index);
    deleteRowModal.current.openModal();
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'ArrowDown') {
      setFocusedItemIndex((prevIndex) => (prevIndex + 1) % suggestions.length);
      const itemHeight = suggestionsMenuRef.current?.children[0]?.clientHeight || 0;
      if (suggestionsMenuRef.current) {
        suggestionsMenuRef.current.scrollTop = focusedItemIndex * itemHeight;
      }
    } else if (e.key === 'ArrowUp') {
      setFocusedItemIndex((prevIndex) =>
        prevIndex === 0 ? suggestions.length - 1 : prevIndex - 1
      );
      const itemHeight = suggestionsMenuRef.current?.children[0]?.clientHeight || 0;
      if (suggestionsMenuRef.current) {
        suggestionsMenuRef.current.scrollTop = focusedItemIndex * itemHeight;
      }
    } else if (e.key === 'Enter' && focusedItemIndex !== -1) {
      const selectedItem = suggestions[focusedItemIndex];
      handleSelect(selectedItem, index);
    }
  };
// This is to calculate and send it to DB
  const calculateTotalAmountSum = () => {
    const total = rows.reduce((total, row) => total + parseFloat(row.totalamount || 0), 0);
    return total.toFixed(2);
  };
  const calculateTotalCgstSum = () => {
    //const totalCgst = rows.reduce((total, row) => total + parseFloat(row.totalCgst || 0), 0);
    const totalCgst = rows.reduce((total, row) => {
      const totalAmount = parseFloat(row.totalamount || 0);
      const cgstPercentage = parseFloat(row.cgst || 0);
      const cgstValue = (cgstPercentage / 100) * totalAmount;
      return total + cgstValue;
    }, 0);
    return totalCgst.toFixed(2);
  };
  const calculateTotalSgstSum = () => {
    //const totalSgst = rows.reduce((total, row) => total + parseFloat(row.totalSgst || 0), 0);
    const totalSgst = rows.reduce((total, row) => {
      const totalAmount = parseFloat(row.totalamount || 0);
      const sgstPercentage = parseFloat(row.sgst || 0);
      const sgstValue = (sgstPercentage / 100) * totalAmount;
      return total + sgstValue;
    }, 0);
    return totalSgst.toFixed(2);
  };
  const calculateGrandTotal = () => {
    const grandTotal = rows.reduce((total, row) => {
      const totalAmount = parseFloat(row.totalamount || 0);
      const totalSgst = parseFloat(row.sgst || 0);
      const totalCgst = parseFloat(row.cgst || 0);
  
      // Calculate SGST and CGST as percentages of the total amount
      const sgstAmount = (totalSgst / 100) * totalAmount;
      const cgstAmount = (totalCgst / 100) * totalAmount;
  
      // Add totalAmount and calculated GST values
      return total + totalAmount + sgstAmount + cgstAmount;
    }, 0);

    return grandTotal.toFixed(2);
  };
// This is to calculate and display in UI
  const calculateTotalAmountSumForDisplay = () => {
    const total = rows.reduce((total, row) => total + parseFloat(row.totalamount || 0), 0);
    return `${currency} ${total.toFixed(2)}`;
  };
  const calculateTotalSgstSumForDisplay = () => {
    //const totalSgst = rows.reduce((total, row) => total + parseFloat(row.totalSgst || 0), 0);
    const totalSgst = rows.reduce((total, row) => {
      const totalAmount = parseFloat(row.totalamount || 0);
      const sgstPercentage = parseFloat(row.sgst || 0);
      const sgstValue = (sgstPercentage / 100) * totalAmount;
      return total + sgstValue;
    }, 0);
    return `${currency} ${totalSgst.toFixed(2)}`;
  };
  const calculateTotalCgstSumForDisplay = () => {
    //const totalCgst = rows.reduce((total, row) => total + parseFloat(row.totalCgst || 0), 0);
    const totalCgst = rows.reduce((total, row) => {
      const totalAmount = parseFloat(row.totalamount || 0);
      const cgstPercentage = parseFloat(row.cgst || 0);
      const cgstValue = (cgstPercentage / 100) * totalAmount;
      return total + cgstValue;
    }, 0);
    return `${currency} ${totalCgst.toFixed(2)}`;
  };
  const calculateGrandTotalForDisplay = () => {
    const grandTotal = rows.reduce((total, row) => {
      const totalAmount = parseFloat(row.totalamount || 0);
      const totalSgst = parseFloat(row.sgst || 0);
      const totalCgst = parseFloat(row.cgst || 0);
  
      // Calculate SGST and CGST as percentages of the total amount
      const sgstAmount = (totalSgst / 100) * totalAmount;
      const cgstAmount = (totalCgst / 100) * totalAmount;
  
      // Add totalAmount and calculated GST values
      return total + totalAmount + sgstAmount + cgstAmount;
    }, 0);
  
    return `${currency} ${grandTotal.toFixed(2)}`;
  };

  const prepareInvoiceInput = async () => {
    showLoader();
    const isValidRows = (rows, fieldNames) => {
      for (const row of rows) {
        for (const fieldName of fieldNames) {
          if (!row[fieldName]) {
            dismissLoader();
            // Assuming you have a function to show toast messages
            showErrorToast(`Please fill in the ${fieldName}`);
            generatepopup.current.closeModal()
            return false;
          }
        }
      }
      return true;
    };
    if (TabValue === "Prescription") {
      if (!isValidRows(rows, ['medicinename', 'quantity'])) return;
      let invoiceInput = {
        loginid: "",
        invoiceurl: "",
        invoicenumber: InvoiceNumber,
        subtotal: calculateTotalAmountSum(),
        tax: "0.00",
        cgst:calculateTotalCgstSum(),
        sgst:calculateTotalSgstSum(),
        totalamount: calculateGrandTotal(),
        saletype: type,
        subtype: "prescription",
        patientname: PatientName,
        patientmobile: PatientMobile,
        doctorid: "",
        details: rows.map((row) => ({
          inventoryid: row.inventoryid,
          productname: row.medicinename,
          numberoftabletssold: row.quantity,
          finalprice: parseFloat(row.totalamount || 0).toFixed(2),
        })),
        "currency":getCurrency(),
      };
      const generatePNG = async () => {
        const input = printRef.current;
          // Apply manual styles directly to the `input` or wrap in a container with these styles
        input.style.padding = '10mm'; // Simulating margin as @page is not supported
        input.style.width = '210mm'; // A4 page width in mm
        input.style.height = '297mm'; 
        const canvas = await html2canvas(input, { scale: 4, useCORS: true });
        const imgData = canvas.toDataURL("image/jpeg", 4);
        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "mm",
          format: "a4",
        });
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    
        pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
    
        const pdfBlob = pdf.output("blob");
        // Upload the PNG image
        const result = await uploadImageInProfile(pdfBlob);
        invoiceInput.invoiceurl = result.name;

        return imgData; // Return the base64 image data
      };
      const saveAndPrint = async (imgData) => {
        const response = await SaveGenerateInvoice(invoiceInput);
        if (response.output.result === "success") {
          dismissLoader();
          handlePrint();
          navigate("/billing");
        }

      };

      generatePNG()
        .then(imgData => saveAndPrint(imgData))
        .catch(error => console.error("Error generating invoice:", error));


    }
    else if(TabValue === "Lab"){
      if (!isValidRows(rows, ['labname','totalamount'])) return;
      let invoiceInput = {
        loginid: "",
        invoiceurl: "",
        invoicenumber: InvoiceNumber,
        subtotal: calculateTotalAmountSum(),
        tax: "0.00",
        cgst:calculateTotalCgstSum(),
        sgst:calculateTotalSgstSum(),
        totalamount: calculateGrandTotal(),
        saletype: type,
        subtype:"lab",
        patientname: PatientName,
        patientmobile: PatientMobile,
        doctorid: "",
        appointmentid: "",
        details: rows.map((row) => ({
          inventoryid: row.inventoryid,
          productname: row.labname,
          finalprice: parseFloat(row.totalamount || 0).toFixed(2),
        })),
        "currency":getCurrency(),
      };
      const generatePNG = async () => {
        const input = printRef.current;
          // Apply manual styles directly to the `input` or wrap in a container with these styles
        input.style.padding = '10mm'; // Simulating margin as @page is not supported
        input.style.width = '210mm'; // A4 page width in mm
        input.style.height = '297mm'; 
        const canvas = await html2canvas(input, { scale: 4, useCORS: true });
        const imgData = canvas.toDataURL("image/jpeg", 4);
        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "mm",
          format: "a4",
        });
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    
        pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
    
        const pdfBlob = pdf.output("blob");
        // Upload the PNG image
        const result = await uploadImageInProfile(pdfBlob);
        invoiceInput.invoiceurl = result.name;

        return imgData; // Return the base64 image data
      };

      const saveAndPrint = async (imgData) => {
        const response = await SaveGenerateInvoice(invoiceInput);
        if (response.output.result === "success") {
          dismissLoader();
          handlePrint();
          navigate("/billing");
        }

      };

      generatePNG()
        .then(imgData => saveAndPrint(imgData))
        .catch(error => console.error("Error generating invoice:", error));
    }
    else if(TabValue === "Appointment"){
      if (!isValidRows(rows, ['doctorname','totalamount'])) return;
      let invoiceInput = {
        loginid: "",
        invoiceurl: "",
        invoicenumber: InvoiceNumber,
        subtotal: calculateTotalAmountSum(),
        tax: "0.00",
        cgst:calculateTotalCgstSum(),
        sgst:calculateTotalSgstSum(),
        totalamount: calculateGrandTotal(),
        saletype: type,
        subtype:"appointment",
        patientname: PatientName,
        patientmobile: PatientMobile,
        doctorid: "",
        appointmentid: "",
        details: rows.map((row) => ({
          inventoryid: row.inventoryid,
          productname: row.doctorname,
          finalprice: parseFloat(row.totalamount || 0).toFixed(2),
        })),
        "currency":getCurrency(),
      };
      const generatePNG = async () => {
        const input = printRef.current;
          // Apply manual styles directly to the `input` or wrap in a container with these styles
        input.style.padding = '10mm'; // Simulating margin as @page is not supported
        input.style.width = '210mm'; // A4 page width in mm
        input.style.height = '297mm'; 
        const canvas = await html2canvas(input, { scale: 4, useCORS: true });
        const imgData = canvas.toDataURL("image/jpeg", 4);
        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "mm",
          format: "a4",
        });
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    
        pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
    
        const pdfBlob = pdf.output("blob");
        // Upload the PNG image
        const result = await uploadImageInProfile(pdfBlob);
        invoiceInput.invoiceurl = result.name;

        return imgData; // Return the base64 image data
      };

      const saveAndPrint = async (imgData) => {
        const response = await SaveGenerateInvoice(invoiceInput);
        if (response.output.result === "success") {
          dismissLoader();
          handlePrint();
          navigate("/billing");
        }

      };

      generatePNG()
        .then(imgData => saveAndPrint(imgData))
        .catch(error => console.error("Error generating invoice:", error));

    }
  };
  //   setIsPrintClicked(true);

  //   const printContents = document.getElementById('printinvoice').innerHTML;

  //   const iframe = document.createElement('iframe');
  //   iframe.style.position = 'absolute';
  //   iframe.style.width = '0';
  //   iframe.style.height = '0';
  //   iframe.style.border = 'none';

  //   document.body.appendChild(iframe);

  //   const doc = iframe.contentWindow.document;
  //   doc.open();
  //   doc.write(`
  //     <html>
  //       <head>
  //         <style>
  //           @media print {
  //             @page {
  //               size: A4;
  //               margin: 10mm;
  //             }
  //             body {
  //               margin: 0;
  //               padding: 0;
  //               width: 100%;
  //               font-size: 12px; /* Adjust font size for better fit */
  //               overflow: hidden !important;
  //             }
  //             .page-break {
  //               page-break-after: always;
  //             }
  //             header, footer {
  //               display: none;
  //             }
  //             img {
  //               max-width: 100%;
  //               height: auto;
  //             }
  //             /* Ensure table does not overflow */
  //             table {
  //               width: 100%;
  //               table-layout: fixed; /* Ensure the table doesn't overflow */
  //             }
  //             td, th {
  //               word-wrap: break-word; /* Wrap content inside table cells */
  //             }
  //           }
  //         </style>
  //       </head>
  //       <body>${printContents}</body>
  //     </html>
  //   `);

  //   Array.from(document.styleSheets).forEach(styleSheet => {
  //     try {
  //       if (styleSheet.cssRules) {
  //         const newStyleEl = document.createElement('style');
  //         Array.from(styleSheet.cssRules).forEach(rule => {
  //           newStyleEl.appendChild(document.createTextNode(rule.cssText));
  //         });
  //         doc.head.appendChild(newStyleEl);
  //       }
  //     } catch (e) {
  //       console.error(e);
  //     }
  //   });

  //   doc.close();

  //   iframe.onload = () => {
  //     iframe.contentWindow.focus();
  //     iframe.contentWindow.print();
  //     document.body.removeChild(iframe);
  //   };
  // };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: `
      @page {
        size: A4;
        margin: 5mm;
      }
    `,
    onBeforeGetContent: () => {
      // Set the invoice header before printing
      setInvoiceHeader("Proforma Invoice");
      return Promise.resolve(); // Ensure that printing waits for this action
    },
  });
  const tabchange = (value) =>{
    initialRow.totalamount = "";
    initialRow.medicinename = "";
    initialRow.labname = "";
    initialRow.doctorname = "";
    setTabValue(value);
    if(value === "Prescription"){
      setShowPrescription(true);
      setShowLab(false);
      setShowAppointment(false);
      setRows([{...initialRow}]);
      focusInput("patientname"); // Focus logic
      fetchInvoiceNumber();
    } else if(value === "Lab"){
      setShowPrescription(false);
      setShowLab(true);
      setShowAppointment(false);
      fetchLabs();
      setRows([{...initialRow}]);
      focusInput("patientname"); // Focus logic
      fetchInvoiceNumber();
    } else if(value === "Appointment"){
      setShowPrescription(false);
      setShowLab(false);
      setShowAppointment(true);
      fetchDoctorsData();
      setRows([{...initialRow}]);
      focusInput("patientname"); // Focus logic
      fetchInvoiceNumber();
    }
  };
  const focusInput = (inputId) => {
    setTimeout(() => {
      const element = document.getElementById(inputId);
      if (element) {
        element.focus();
      }
    }, 100);
  };
// Labs
const fetchLabs = async () => {
  showLoader();
  try {
    const labResult = await GetMasterData({ type: "labs" });
    const imagingResult = await GetMasterData({ type: "imaging" });

    // Create an empty array to hold the merged data
    const mergedData = [];

    // Check if lab data exists and push it to mergedData
    if (labResult.output.data.length > 0) {
      mergedData.push(...labResult.output.data);
    }

    // Check if imaging data exists and push it to mergedData
    if (imagingResult.output.data.length > 0) {
      mergedData.push(...imagingResult.output.data);
    }

    // Set the merged data to the state
    setLabList(mergedData);

    dismissLoader();
  } catch (error) {
    console.error("Error in home:", error);
    dismissLoader(); // Ensure loader is dismissed in case of an error
  }
};


  const filterLabNames = (medicine) => {
    const filtered = labList.filter((obj) =>
      obj.subtype?.toLowerCase().includes(medicine.toLowerCase())
    );
    setSuggestions(filtered);
    setBoolValForSuggestions(!boolValForSuggestions);
  };

  const handleLabNameChange = (id, name, index) => {
    setInputId(index)
    const newRows = [...rows];
    newRows[index].labname = name;
    if (!name) {
      newRows[index] = { ...initialRow }; // Reset the entire row if lab name is cleared
    }
    setRows(newRows);
    if (name.length >= 3) {
      filterLabNames(name);
      setShowLabNamesDropdown(index);
      setBoolValForSuggestions(!boolValForSuggestions);
    } else {
      setSuggestions([]);
      setShowLabNamesDropdown(-1);
      setBoolValForSuggestions(!boolValForSuggestions);
    }
  };

  const handleSelectForLab = (suggestion, index) => {
    const newRows = [...rows];
    newRows[index].labname = suggestion.subtype;
    newRows[index].inventoryid = suggestion.id;
    newRows[index].totalamount = suggestion.totalamount;
    newRows[index].totalSgst = suggestion.sgst;
    newRows[index].totalCgst = suggestion.cgst;
    newRows[index].sgst = suggestion.sgst;
    newRows[index].cgst = suggestion.cgst;

    setRows(newRows);
    setSuggestions([]);
    setBoolValForSuggestions(!boolValForSuggestions);
    setShowLabNamesDropdown(-1);
  };
  const handleKeyDownLab = (e, index) => {
    if (e.key === 'ArrowDown') {
      setFocusedItemIndex((prevIndex) => (prevIndex + 1) % suggestions.length);
      const itemHeight = suggestionsMenuRef.current?.children[0]?.clientHeight || 0;
      if (suggestionsMenuRef.current) {
        suggestionsMenuRef.current.scrollTop = focusedItemIndex * itemHeight;
      }
    } else if (e.key === 'ArrowUp') {
      setFocusedItemIndex((prevIndex) =>
        prevIndex === 0 ? suggestions.length - 1 : prevIndex - 1
      );
      const itemHeight = suggestionsMenuRef.current?.children[0]?.clientHeight || 0;
      if (suggestionsMenuRef.current) {
        suggestionsMenuRef.current.scrollTop = focusedItemIndex * itemHeight;
      }
    } else if (e.key === 'Enter' && focusedItemIndex !== -1) {
      const selectedItem = suggestions[focusedItemIndex];
      handleSelectForLab(selectedItem, index);
    }
  };

  //Appointment
  const fetchDoctorsData = async () => {
    showLoader();
    try {
      const result = await FetchDoctorsList({
        doctorid,
      });
      if (result.output.data.length > 0) {
        setDoctorsList(result.output.data);
      }
    } catch (error) {
      console.error("Error in home:", error);
    }
    dismissLoader();
  };
  const filterDoctorNames = (doctor) => {
    const filtered = doctorslist.filter((obj) =>
      obj.fullname?.toLowerCase().includes(doctor.toLowerCase())
    );
    setSuggestions(filtered);
    setBoolValForSuggestions(!boolValForSuggestions);
  };

  const handleDoctorNameChange = (id, name, index) => {
    setInputId(id)
    const newRows = [...rows];
    newRows[index].doctorname = name;
    if (!name) {
      newRows[index] = { ...initialRow }; // Reset the entire row if lab name is cleared
    }
    setRows(newRows);
    if (name.length >= 3) {
      filterDoctorNames(name);
      setShowAppointmentNamesDropdown(index);
      setBoolValForSuggestions(!boolValForSuggestions);
    } else {
      setSuggestions([]);
      setShowAppointmentNamesDropdown(-1);
      setBoolValForSuggestions(!boolValForSuggestions);
    }
  };

  const handleSelectForAppointment = (suggestion, index) => {
    const newRows = [...rows];
    newRows[index].doctorname = suggestion.fullname;
    newRows[index].inventoryid = suggestion.doctorid;
    newRows[index].totalamount = suggestion.fee;
    setRows(newRows);
    setSuggestions([]);
    setBoolValForSuggestions(!boolValForSuggestions);
    setShowAppointmentNamesDropdown(-1);
  };
  const handleKeyDownAppointment = (e, index) => {
    if (e.key === 'ArrowDown') {
      setFocusedItemIndex((prevIndex) => (prevIndex + 1) % suggestions.length);
      const itemHeight = suggestionsMenuRef.current?.children[0]?.clientHeight || 0;
      if (suggestionsMenuRef.current) {
        suggestionsMenuRef.current.scrollTop = focusedItemIndex * itemHeight;
      }
    } else if (e.key === 'ArrowUp') {
      setFocusedItemIndex((prevIndex) =>
        prevIndex === 0 ? suggestions.length - 1 : prevIndex - 1
      );
      const itemHeight = suggestionsMenuRef.current?.children[0]?.clientHeight || 0;
      if (suggestionsMenuRef.current) {
        suggestionsMenuRef.current.scrollTop = focusedItemIndex * itemHeight;
      }
    } else if (e.key === 'Enter' && focusedItemIndex !== -1) {
      const selectedItem = suggestions[focusedItemIndex];
      handleSelectForAppointment(selectedItem, index);
    }
  };
  //Hospital Sale
  useEffect(() => {
    if(type === "Hospital sale"){
      setIsHospitalsale(true);
      fetchPatients();
    }
  }, []);
  const fetchPatients = async () => {
    try {
      const result = await FetchPatientViewDetails({
        doctorid,
      });
      if (result != null) {
        setPatientsList(result);
      } else {
        setPatientsList("");
        dismissLoader()
      }
      dismissLoader();
    } catch (error) {
      console.error("Error in home:", error);
    }
  };

  const filterPatientNames = (patient) => {
    const filtered = patientsList.filter((obj) =>
      obj.fullname?.toLowerCase().includes(patient.toLowerCase())
    );
    setPatientsSuggestions(filtered);
    setBoolValForSuggestions(!boolValForSuggestions);
  };
  const handlePatientNameChange = (id, name) => {
    setInputId(id)
    setPatientName(name);
    if (name.length >= 3) {
      filterPatientNames(name);
      setShowPatientsNamesDropdown(true);
      setBoolValForSuggestions(!boolValForSuggestions);
    } else {
      setPatientsSuggestions([]);
      setBoolValForSuggestions(!boolValForSuggestions);
      setShowPatientsNamesDropdown(false);
    }
  };
  const handleSelectPatient = (suggestion) => {
    setPatientName(suggestion.fullname);
    setPatientMobile(suggestion.mobileno);
    setPatientsSuggestions([]);
    setShowPatientsNamesDropdown(false);
  };
  const handleKeyDownPatient = (e) => {
    if (e.key === 'ArrowDown') {
      setFocusedItemIndex((prevIndex) => (prevIndex + 1) % patientssuggestions.length);
      const itemHeight = suggestionsMenuRef.current?.children[0]?.clientHeight || 0;
      if (suggestionsMenuRef.current) {
        suggestionsMenuRef.current.scrollTop = focusedItemIndex * itemHeight;
      }
    } else if (e.key === 'ArrowUp') {
      setFocusedItemIndex((prevIndex) =>
        prevIndex === 0 ? patientssuggestions.length - 1 : prevIndex - 1
      );
      const itemHeight = suggestionsMenuRef.current?.children[0]?.clientHeight || 0;
      if (suggestionsMenuRef.current) {
        suggestionsMenuRef.current.scrollTop = focusedItemIndex * itemHeight;
      }
    } else if (e.key === 'Enter' && focusedItemIndex !== -1) {
      const selectedItem = patientssuggestions[focusedItemIndex];
      handleSelectPatient(selectedItem);
    }
  };
   const gobackfunction = ()=>{
    window.history.back();
   }
   const middleSectionHeaderWidth = 20;
   const scaleOut = rows.length > 5 ?  true:false;
   const labelStyle = {
    textAlign:'center',
    width:'50px',
    height:'48px',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    border:"1px solid #e2e8f0",
    color:'#acaeb3',
  }
  return (
    <>
      <Flex>
        <Box w="6rem" className="no-print">
          <MiniSideBar />
        </Box>
        {isLoading && <Loader />}
        <Box
          w="calc(100vw - 6.25rem)"
          display="flex"
          flexDir="column"
          id="print-content"
        >
          <SecondaryNav withBack={type} goBack={gobackfunction} className="no-print" />        
          <Box
            gap="10px"
            my="1rem"
            px="1rem"
            w="100%"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <Box w="100%" display="flex" gap="10px">
              {ishospitalsale ? (
              <>
               <Box w="100%" pos="relative" cursor="pointer">
              <PrimaryInput
                variant="fullRound"
                inputPlace="Patient Name"
                id="patientname"
                // onChange={(e) =>
                //   handlePatientNameChange("patientname", e.target.value)
                //  }
                 onChange={(e) => {
                  const value = e.target.value;
                  // Allow only alphabetic characters (a-z, A-Z, spaces)
                  if (/^[a-zA-Z\s]*$/.test(value)) {
                    handlePatientNameChange("patientname", value);
                  }
                }}
                boolProp={boolValForSuggestions}
                inputIdProp={inputId == "patientname" ? "Active" : null}
                onKeyDown={(e) => handleKeyDownPatient(e)}
                inputValue={PatientName}
                maxLength={50}
                autofocus={true}
              />
              <Image
                top="33%"
                right="6.5%"
                w="15px"
                pos="absolute"
                src="/assets/imgs/search.png"
              />
              {showPatientNamedropdown && patientssuggestions.length > 0 && (
                <Menu isOpen={true}>
                  <MenuButton
                    as={Box}
                    w="100%"
                    display="block"
                    pos="relative"
                    zIndex="999"
                  >
                    <Box
                      pos="absolute"
                      top="100%"
                      left="0"
                      w="100%"
                      bg="white"
                      borderTop="none"
                      border="1px solid #E6E7E9"
                      borderRadius="0 0 12px 12px"
                    />
                  </MenuButton>
                  <MenuList
                    w="100%"
                    borderRadius="0 0 12px 12px"
                    zIndex="999"
                    bg="white"
                    maxH="150px"
                    overflow="hidden auto"
                    ref={suggestionsMenuRef}
                  >
                    {patientssuggestions.map((suggestion, sIndex) => (
                      <MenuItem
                        key={sIndex}
                        onClick={() => handleSelectPatient(suggestion)}
                        cursor="pointer"
                        p="1rem"
                        py="0.5rem"
                        borderBottom="1px solid #E6E7E9"
                        bg={focusedItemIndex === sIndex ? "#f0f0f0" : "white"}
                      >
                        <Text fontSize="0.875rem" fontWeight="500">
                          {suggestion.fullname}
                        </Text>
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              )}
              </Box>
               <PrimaryInput
                variant="fullRound"
                inputPlace="Mobile Number"
                id="productname"
                // onChange={(e) => setPatientMobile(e.target.value)}
                onChange={(e) => {
                  const value = e.target.value;
                  // Allow only digits (no decimals, no characters)
                  if (/^\d*$/.test(value)) {
                    setPatientMobile(value);
                  }
                }}
                inputValue={PatientMobile}
                maxLength={10}
              />
              </>
              ):(
                <>
               <PrimaryInput
                variant="fullRound"
                inputPlace="Patient Name"
                id="patientname"
                // onChange={(e) => setPatientName(e.target.value)}
                onChange={(e) => {
                  const value = e.target.value;
                  // Allow only alphabetic characters (a-z, A-Z, spaces)
                  if (/^[a-zA-Z\s]*$/.test(value)) {
                    setPatientName(value);
                  }
                }}
                inputValue={PatientName}
                maxLength={50}
                autofocus={true}
                inputIdProp="patientname"
                boolProp={true}
              />
              <PrimaryInput
                variant="fullRound"
                inputPlace="Mobile Number"
                id="productname"
                // onChange={(e) => setPatientMobile(e.target.value)}
                onChange={(e) => {
                  const value = e.target.value;
                  // Allow only digits (no decimals, no characters)
                  if (/^\d*$/.test(value)) {
                    setPatientMobile(value);
                  }
                }}
                inputValue={PatientMobile}
                maxLength={10}
              />
              </>
              )}
              <DropdownInput
                variant="fullSide"
                dropDownPlace="Type"
                options={["Prescription", "Lab", "Appointment"]}
                readOnly={true}
                id="type"
                onClick={tabchange}
                defaultOption={"Prescription"}
                menuList={{ pos: "absolute", right: "48px", minW: "285px" }}
              />
            </Box>
          </Box>
          <Box
            id="printableDiv2"
            w="100%"
            p="1rem 1.5rem"
            minH="calc(100vh - 320px)"
            maxH="calc(100vh - 320px)"
            overflow="hidden auto"
          >
            {ShowPrescription && !ShowLab && !ShowAppointment && (
              <SimpleTable
                isTh={false}
                isDataPresent={true}
                ThStyle={{ textAlign: "center", bg: "#FAFAFA" }}
                footerStyle={{
                  pos: "sticky",
                  bottom: "-1px",
                  boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
                }}
                tableStyle={{
                  tableLayout: "fixed",
                  width: "100%",
                  border: "1px solid #E6E7E9",
                  borderBottom: "none",
                }}
                tableHeaders={
                  <Tr>
                    <Th fontSize="0.87rem" fontWeight="500" pl="10px">
                      Medicine name
                    </Th>
                    <Th fontSize="0.87rem" fontWeight="500" pl="10px">
                      Batch no
                    </Th>
                    <Th fontSize="0.87rem" fontWeight="500" pl="10px">
                      MRP ({currency}) (Per Strip)
                    </Th>
                    <Th fontSize="0.87rem" fontWeight="500" pl="10px">
                      Quantity (In Units)
                    </Th>
                    <Th fontSize="0.87rem" fontWeight="500" pl="10px">
                      Amount ({currency})
                    </Th>
                    <Th fontSize="0.87rem" fontWeight="500" pl="10px">
                      <Flex
                        w="100%"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <PrimaryButton
                          btnIcon="/assets/svgs/add-white.svg"
                          iconSrc={true}
                          btnStyle={{ w: "25px", h: "25px", p: "0" }}
                          iconSrcStyle={{ mr: "0", w: "10px" }}
                          onClick={AddAnotherRowPrescription}
                        />
                      </Flex>
                    </Th>
                  </Tr>
                }
                tableBody={
                  <>
                    {rows.map((row, index) => (
                      <Tr w="100%" key={index}>
                        <Td p="0">
                          <Box w="100%" pos="relative" cursor="pointer">
                            <PrimaryInput
                              variant="bothSide"
                              inputPlace="Medicine name*"
                              id="medicinename"
                              inputValue={row.medicinename}
                              onChange={(e) =>
                                handleMedicineNameChange(
                                  row.inventoryid,
                                  e.target.value,
                                  index
                                )
                              }
                              //inputIdProp={"MedicineNameInAdd" + index}
                              inputIdProp={
                                inputId == index ? "Active" : null
                              }
                              boolProp={boolValForSuggestions}
                              onKeyDown={(e) => handleKeyDown(e, index)}
                              autocomplete="off"
                              inputCssStyle={{pr:"36px",_hover:{border: "1px solid", borderRadius: "10px"}}}
                              maxLength={50}
                            />
                            <Image
                              top="33%"
                              right="6.5%"
                              w="15px"
                              pos="absolute"
                              src="/assets/imgs/search.png"
                            />
                          </Box>
                          {showMedicineNamesDropdown === index &&
                            suggestions.length > 0 && (
                              <Menu isOpen={true}>
                                <MenuButton
                                  as={Box}
                                  w="100%"
                                  display="block"
                                  pos="relative"
                                  zIndex="999"
                                >
                                  <Box
                                    pos="absolute"
                                    top="100%"
                                    left="0"
                                    w="100%"
                                    bg="white"
                                    borderTop="none"
                                    border="1px solid #E6E7E9"
                                    borderRadius="0 0 12px 12px"
                                  />
                                </MenuButton>
                                <MenuList
                                  w="100%"
                                  borderRadius="0 0 12px 12px"
                                  zIndex="999"
                                  bg="white"
                                  maxH="150px"
                                  overflow="hidden auto"
                                  ref={suggestionsMenuRef}
                                >
                                  {suggestions.map((suggestion, sIndex) => (
                                    <MenuItem
                                      key={sIndex}
                                      onClick={() =>
                                        handleSelect(suggestion, index)
                                      }
                                      cursor="pointer"
                                      p="1rem"
                                      py="0.5rem"
                                      borderBottom="1px solid #E6E7E9"
                                      bg={
                                        focusedItemIndex === sIndex
                                          ? "#f0f0f0"
                                          : "white"
                                      } // Highlight focused item
                                    >
                                      <Text
                                        fontSize="0.875rem"
                                        fontWeight="500"
                                      >
                                        {suggestion.productname +
                                          " (" +
                                          suggestion.remainingquantity +
                                          " Qty)"}
                                      </Text>
                                    </MenuItem>
                                  ))}
                                </MenuList>
                              </Menu>
                            )}
                        </Td>

                        <Td p="0">
                          <PrimaryInput
                            variant="bothSide"
                            inputPlace="Batch No"
                            inputValue={row.batchnumber}
                            onChange={(e) =>
                              handleBatchNumberChange(e.target.value, index)
                            }
                            inputCssStyle={{_hover:{border: "1px solid", borderRadius: "10px"}}}
                            //isDisabled={true}
                            maxLength={30}
                          />
                        </Td>
                        <Td p="0">
                          <PrimaryInput
                            variant="bothSide"
                            inputPlace={`MRP (${currency})`}
                            inputValue={row.mrp}
                            // onChange={(e) =>
                            //   handleMRPChange(e.target.value, index)
                            // }
                            onChange={(e) => {
                              const value = e.target.value;
                              // Allow only numbers and one decimal point
                              if (/^\d*\.?\d*$/.test(value)) {
                                handleMRPChange(value, index);
                              }
                            }}
                            maxLength={7}
                            inputCssStyle={{_hover:{border: "1px solid", borderRadius: "10px"}}}
                          />
                        </Td>
                        <Td p="0">
                          <PrimaryInput
                            ref={quantityRef}
                            variant="bothSide"
                            inputPlace="Quantity*"
                            id="quantity"
                            inputValue={row.quantity}
                            // onChange={(e) =>
                            //   handleQuantityChange(e.target.value, index)
                            // }
                            onChange={(e) => {
                              const value = e.target.value;
                              // Allow only digits (no decimals, no characters)
                              if (/^\d*$/.test(value)) {
                                handleQuantityChange(value, index);
                              }
                            }}
                            maxLength={7}
                            inputCssStyle={{_hover:{border: "1px solid", borderRadius: "10px"}}}
                          />
                        </Td>

                        <Td p="0">
                          <PrimaryInput
                            variant="bothSide"
                            inputPlace={`Total Amount (${currency})`}
                            inputValue={row.totalamount}
                            onChange={(e) =>
                              handleTotalAmountChange(e.target.value, index)
                            }
                            maxLength={7}
                            inputCssStyle={{_hover:{border: "1px solid", borderRadius: "10px"}}}
                            isDisabled={true}
                          />
                        </Td>

                        <Td p="0">
                          <Box
                            as="button"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            w="100%"
                            onClick={() => confirmDeleteRow(index)}
                          >
                            <Image
                              src="/assets/svgs/delete.svg"
                              opacity="0.7"
                            />
                          </Box>
                        </Td>
                      </Tr>
                    ))}
                  </>
                }
              />
            )}
            {ShowLab && !ShowPrescription && !ShowAppointment && (
              <SimpleTable
                isTh={false}
                isDataPresent={true}
                ThStyle={{ textAlign: "center", bg: "#FAFAFA" }}
                footerStyle={{
                  pos: "sticky",
                  bottom: "-1px",
                  boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
                }}
                tableStyle={{
                  tableLayout: "fixed",
                  width: "100%",
                  border: "1px solid #E6E7E9",
                  borderBottom: "none",
                }}
                tableHeaders={
                  <Tr>
                    <Th fontSize="0.87rem" fontWeight="500" pl="10px">
                      Lab name
                    </Th>

                    <Th fontSize="0.87rem" fontWeight="500" pl="10px">
                      Amount ({currency})
                    </Th>
                    <Th fontSize="0.87rem" fontWeight="500" pl="10px">
                      <Flex
                        w="100%"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <PrimaryButton
                          btnIcon="/assets/svgs/add-white.svg"
                          iconSrc={true}
                          btnStyle={{ w: "25px", h: "25px", p: "0" }}
                          iconSrcStyle={{ mr: "0", w: "10px" }}
                          onClick={AddAnotherRowLab}
                        />
                      </Flex>
                    </Th>
                  </Tr>
                }
                tableBody={
                  <>
                    {rows.map((row, index) => (
                      <Tr w="100%" key={index}>
                        <Td p="0">
                          <Box w="100%" pos="relative" cursor="pointer">
                            <PrimaryInput
                              variant="bothSide"
                              inputPlace="Lab name*"
                              id="LabNameInAdd"
                              inputValue={row.labname}
                              onChange={(e) =>
                                handleLabNameChange(
                                  row.inventoryid,
                                  e.target.value,
                                  index
                                )
                              }
                              //inputIdProp={"LabNameInAdd" + index}
                              inputIdProp={
                                inputId == index ? "Active" : null
                              }
                              boolProp={boolValForSuggestions}
                              onKeyDown={(e) => handleKeyDownLab(e, index)}
                              autocomplete="off"
                              inputCssStyle={{_hover:{border: "1px solid", borderRadius: "10px"}}}
                              maxLength={50}
                            />
                            <Image
                              top="33%"
                              right="6.5%"
                              w="15px"
                              pos="absolute"
                              src="/assets/imgs/search.png"
                            />
                          </Box>
                          {showLabNamesDropdown === index &&
                            suggestions.length > 0 && (
                              <Menu isOpen={true}>
                                <MenuButton
                                  as={Box}
                                  w="100%"
                                  display="block"
                                  pos="relative"
                                  zIndex="999"
                                >
                                  <Box
                                    pos="absolute"
                                    top="100%"
                                    left="0"
                                    w="100%"
                                    bg="white"
                                    borderTop="none"
                                    border="1px solid #E6E7E9"
                                    borderRadius="0 0 12px 12px"
                                  />
                                </MenuButton>
                                <MenuList
                                  w="100%"
                                  borderRadius="0 0 12px 12px"
                                  zIndex="999"
                                  bg="white"
                                  maxH="150px"
                                  overflow="hidden auto"
                                  ref={suggestionsMenuRef}
                                >
                                  {suggestions.map((suggestion, ssIndex) => (
                                    <MenuItem
                                      key={ssIndex}
                                      onClick={() =>
                                        handleSelectForLab(suggestion, index)
                                      }
                                      cursor="pointer"
                                      p="1rem"
                                      py="0.5rem"
                                      borderBottom="1px solid #E6E7E9"
                                      bg={
                                        focusedItemIndex === ssIndex
                                          ? "#f0f0f0"
                                          : "white"
                                      } // Highlight focused item
                                    >
                                      <Text
                                        fontSize="0.875rem"
                                        fontWeight="500"
                                      >
                                        {suggestion.subtype}
                                      </Text>
                                    </MenuItem>
                                  ))}
                                </MenuList>
                              </Menu>
                            )}
                        </Td>
                        <Td p="0">
                          <PrimaryInput
                            variant="bothSide"
                            inputPlace={`Total Amount* (${currency})`}
                            inputValue={row.totalamount}
                            onChange={(e) =>
                              handleTotalAmountChange(e.target.value, index)
                            }
                            maxLength={7}
                            inputCssStyle={{_hover:{border: "1px solid", borderRadius: "10px"}}}
                            isDisabled={true}
                          />
                        </Td>

                        <Td p="0">
                          <Box
                            as="button"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            w="100%"
                            onClick={() => confirmDeleteRow(index)}
                          >
                            <Image
                              src="/assets/svgs/delete.svg"
                              opacity="0.7"
                            />
                          </Box>
                        </Td>
                      </Tr>
                    ))}
                  </>
                }
              />
            )}
            {ShowAppointment && !ShowPrescription && !ShowLab && (
              <SimpleTable
                isTh={false}
                // headers={TableHeader}
                isDataPresent={true}
                ThStyle={{ textAlign: "center", bg: "#FAFAFA" }}
                footerStyle={{
                  pos: "sticky",
                  bottom: "-1px",
                  boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
                }}
                tableStyle={{
                  tableLayout: "fixed",
                  width: "100%",
                  border: "1px solid #E6E7E9",
                  borderBottom: "none",
                }}
                tableHeaders={
                  <Tr>
                    <Th fontSize="0.87rem" fontWeight="500" pl="10px">
                      Doctor Name
                    </Th>

                    <Th fontSize="0.87rem" fontWeight="500" pl="10px">
                      Total Amount ({currency})
                    </Th>
                  </Tr>
                }
                tableBody={
                  <>
                    {rows.map((row, index) => (
                      <Tr w="100%" key={index}>
                        <Td p="0">
                          <Box w="100%" pos="relative" cursor="pointer">
                            <PrimaryInput
                              variant="bothSide"
                              inputPlace="Doctor name*"
                              inputValue={row.doctorname}
                              onChange={(e) =>
                                handleDoctorNameChange(
                                  row.inventoryid,
                                  e.target.value,
                                  index
                                )
                              }
                              //inputIdProp={"AppointmwntNameInAdd" + index}
                              inputIdProp={
                                inputId == row.inventoryid ? "Active" : null
                              }
                              boolProp={boolValForSuggestions}
                              onKeyDown={(e) =>
                                handleKeyDownAppointment(e, index)
                              }
                              autocomplete="off"
                              inputCssStyle={{_hover:{border: "1px solid", borderRadius: "10px"}}}
                              maxLength={50}
                            />
                            <Image
                              top="33%"
                              right="6.5%"
                              w="15px"
                              pos="absolute"
                              src="/assets/imgs/search.png"
                            />
                          </Box>
                          {showAppointmentNamesDropdown === index &&
                            suggestions.length > 0 && (
                              <Menu isOpen={true}>
                                <MenuButton
                                  as={Box}
                                  w="100%"
                                  display="block"
                                  pos="relative"
                                  zIndex="999"
                                >
                                  <Box
                                    pos="absolute"
                                    top="100%"
                                    left="0"
                                    w="100%"
                                    bg="white"
                                    borderTop="none"
                                    border="1px solid #E6E7E9"
                                    borderRadius="0 0 12px 12px"
                                  />
                                </MenuButton>
                                <MenuList
                                  w="100%"
                                  borderRadius="0 0 12px 12px"
                                  zIndex="999"
                                  bg="white"
                                  maxH="150px"
                                  overflow="hidden auto"
                                  ref={suggestionsMenuRef}
                                >
                                  {suggestions.map((suggestion, ssIndex) => (
                                    <MenuItem
                                      key={ssIndex}
                                      onClick={() =>
                                        handleSelectForAppointment(
                                          suggestion,
                                          index
                                        )
                                      }
                                      cursor="pointer"
                                      p="1rem"
                                      py="0.5rem"
                                      borderBottom="1px solid #E6E7E9"
                                      bg={
                                        focusedItemIndex === ssIndex
                                          ? "#f0f0f0"
                                          : "white"
                                      } // Highlight focused item
                                    >
                                      <Text
                                        fontSize="0.875rem"
                                        fontWeight="500"
                                      >
                                        {suggestion.fullname}
                                      </Text>
                                    </MenuItem>
                                  ))}
                                </MenuList>
                              </Menu>
                            )}
                        </Td>
                        <Td p="0">
                          <PrimaryInput
                            variant="bothSide"
                            inputPlace={`Total Amount* (${currency})`}
                            inputValue={row.totalamount}
                            onChange={(e) =>
                              handleTotalAmountChange(e.target.value, index)
                            }
                            isDisabled={true}
                            maxLength={7}
                            inputCssStyle={{_hover:{border: "1px solid", borderRadius: "10px"}}}
                          />
                        </Td>
                      </Tr>
                    ))}
                  </>
                }
              />
            )}
          </Box>

          <Box
            w="100%"
            px="1.5rem"
            boxShadow="rgba(0, 0, 0, 0.15) 0px -10px 11px -8px"
            zIndex="99"
          >
            {/* Footer area start */}
            <Box w="100%" display="flex" pt="1rem">
              <Spacer />

              <Box w="19%" display="flex" gap="2rem" id="printableDiv3">
                <Flex w="75px" flexDir="column" alignItems="start">
                  <Text fontSize="0.875rem" className="textOverflow">
                    Total Amount
                  </Text>
                  <Text fontSize="0.875rem">SGST</Text>
                  <Text fontSize="0.875rem">CGST</Text>
                  <Text fontSize="0.875rem">Total:</Text>
                </Flex>

                <Flex w="75px" flexDir="column" alignItems="start">
                  <Text fontSize="0.875rem" fontWeight="600" whiteSpace='nowrap'>
                    {calculateTotalAmountSumForDisplay()}
                  </Text>
                  <Text fontSize="0.875rem" fontWeight="600" whiteSpace='nowrap'>
                    {calculateTotalSgstSumForDisplay()}
                  </Text>
                  <Text fontSize="0.875rem" fontWeight="600" whiteSpace='nowrap'>
                    {calculateTotalCgstSumForDisplay()}
                  </Text>
                  <Text fontSize="0.875rem" fontWeight="600" whiteSpace='nowrap'>
                    {calculateGrandTotalForDisplay()}
                  </Text>
                </Flex>
              </Box>
            </Box>
            {/* Footer area end */}

            {/* Generate invoice buttons start */}
            <Flex w="100%" mt="1rem" className="no-print">
              <Spacer />

              <Box w="30%" display="flex" gap="1rem" justifyContent="end">
                <PrimaryButton
                  buttonText="Proforma invoice"
                  variant="grayBtn"
                  btnStyle={{ color: COLORS.PRIMARY_COLOR }}
                  onClick={handlePrint}
                />
                <PrimaryButton
                  buttonText="Generate invoice"
                  variant="mdBtn"
                  onClick={()=> generatepopup.current.openModal()}
                />
              </Box>
            </Flex>
            {/* Generate invoice buttons end */}
          </Box>
        </Box>
      </Flex>
      
      {/* For Print */}
      {isprintclicked && (
       <Flex width="215mm" height="297mm" id="printinvoice" ref={printRef} direction="column">
        {/* Blue Strip */}
       <Box
         mt={"0px"}
         width={"100%"}
         top={0}
         left={0}
         right={0}
         height="8px"
         bg="blue.600"
         zIndex={1}
       />

       <Box w="100%" display="flex" flexDir="column" height="100%">
       <Box bg={"#fafafa"} p='0.75rem' display='flex' justifyContent='' gap='1.5rem' w='100%'>
         {/* Header */}
         <Box w='130%'>
          <HStack>
            <VStack align="flex-start" spacing={1}>
              <Text fontWeight="bold" fontSize="xl">
              {hospitalname?hospitalname : "-"}
              </Text>
            </VStack>
            
          </HStack>

          {/* Contact Information */}
          <HStack justify="flex-start" mt={1}>
            <HStack>
              <Image src="assets/svgs/call.svg" alt="Call" />
              <Text fontSize={"xs"}>
              {getMobile()}
              </Text>
            </HStack>
            <HStack>
              <Image src="assets/svgs/smallLinePhoneMail.svg" alt="Divider" />
              <Image src="assets/svgs/mail-gray.svg" alt="Mail" />
              <Text fontSize={"xs"}>{getEmail()}</Text>
            </HStack>
          </HStack>

          {/* Hospital Address */}
          <HStack justify="flex-start" mt={1}>
            <Image src="assets/svgs/hospital-location-on.svg" alt="Location" />
            <Text fontSize={"xs"}>
            {Address && Address.length > 0 ? 
              (Address[0].address1 ? Address[0].address1 : "") + ", " +
              (Address[0].address2 ? Address[0].address2 : "") + ", " +
              (Address[0].city ? Address[0].city : "") + ", " +
              (Address[0].state ? Address[0].state : "") + ", " +
              (Address[0].postalcode ? Address[0].postalcode : "") + ", " +
              (Address[0].country ? Address[0].country : "") 
              : "-"
            }
              </Text>
          </HStack>
         </Box>

         <Box w='30%' mt='-1rem'>
            {hospitallogo != "" && (
               <Image
               src={hospitallogo}
               height="auto"
               w='100px'
               mr={"36px"}
             />
            )}
         </Box>
       </Box>
       <Divider my={scaleOut ? 1 : 4} />
         {/* Header Section */}
         <Box w="100%" display="flex" ml="-2rem" alignItems="center" justifyContent="center">
           <Box w="100%" p="1rem">
             <Text fontSize="1.5rem" textAlign="center" fontWeight="700">
               {invoiceheader}
             </Text>
           </Box>
         </Box>
         <VStack align="flex-start" mt={scaleOut ? "1px" : "4px"}>
          {/* Patient Details */}
          <Flex w="100%" p="1rem" id="printableDiv1">
            <Box w="50%" gap='1rem' display="flex" justifyContent='normal'>
              <Flex flexDir="column">
                <Text fontSize="0.875rem">Patient Name:</Text>
                <Text fontSize="0.875rem">Patient Mobile:</Text>
                <Text fontSize="0.875rem">Doctor Name:</Text>
              </Flex>

              <Flex flexDir="column">
                <Text fontSize="0.875rem" fontWeight="600" className="textOverflow" maxW='200px'>
                {PatientName? PatientName:"-"}
                </Text>
                <Text fontSize="0.875rem" fontWeight="600">
                {PatientMobile? PatientMobile:"-"}
                </Text>
                <Text fontSize="0.875rem" fontWeight="600" className="textOverflow" maxW='200px'>
                {doctorname? doctorname:"-"}
                </Text>
              </Flex>
            </Box>

            {/* <Spacer /> */}

            <Box w="50%" gap='1rem' display="flex">
              <Flex flexDir="column">
                <Text fontSize="0.875rem">Invoice Number:</Text>
                <Text fontSize="0.875rem">Date</Text>
                {/* <Text fontSize="0.875rem">Preferred by:</Text> */}
              </Flex>

              <Flex flexDir="column">
                <Text fontSize="0.875rem" fontWeight="600">
                {InvoiceNumber?InvoiceNumber:"-"}
                </Text>
                <Text fontSize="0.875rem" fontWeight="600">
                  {formatDate(today)}
                </Text>
                {/* <Text fontSize="0.875rem" fontWeight="600">

                </Text> */}
              </Flex>
            </Box>
          </Flex>

          <Divider my={scaleOut ? 1 : 4} />
        </VStack>

         {/* Content Section (Table) */}
         <Box w="93%"  p="0.5rem 0.5rem" flex="1 0 auto" border="1px solid #E6E7E9" borderRadius="4px">
          {ShowPrescription && !ShowLab && (
            <SimpleTable
              isTh={false}
              isDataPresent={true}
              ThStyle={{
                textAlign: "center",
                bg: "#FAFAFA",
                border: "1px solid #E6E7E9",
                padding: "4px 8px", // Adjusted padding for smaller header rows
                fontSize: "0.8rem", // Reduced font size for headers
              }}
              footerStyle={{
                pos: "sticky",
                bottom: "-1px",
                boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
              }}
              tableStyle={{
                tableLayout: "fixed",
                width: "100%",
                border: "1px solid #E6E7E9",
                borderBottom: "collapse",
              }}
              tableHeaders={
                <Tr>
                  <Th fontSize="0.8rem" fontWeight="500" pl="8px" pr="8px">
                    Medicine name
                  </Th>
                  <Th fontSize="0.8rem" fontWeight="500" pl="8px" pr="8px">
                    Batch no.
                  </Th>
                  <Th fontSize="0.8rem" fontWeight="500" pl="8px" pr="8px">
                    MRP <Box as="span" fontSize='0.6rem'>(Per Strip)</Box>
                  </Th>
                  <Th fontSize="0.8rem" fontWeight="500" pl="8px" pr="8px">
                    Quantity <Box as="span" fontSize='0.6rem'>(In Units)</Box>
                  </Th>
                  <Th fontSize="0.8rem" fontWeight="500" pl="8px" pr="8px">
                    Amount <Box as="span" fontSize='0.6rem'>({currency})</Box>
                  </Th>
                </Tr>
              }
              tableBody={
                <>
                  {rows.map((row, index) => (
                    <Tr w="100%" key={index}>
                      <Td border="1px solid #E6E7E9" padding="4px 8px" maxW='175px'> {/* Adjusted padding for smaller row height */}
                        <Text w="100%" fontSize="0.8rem" pos="relative" cursor="pointer" className="textOverflow">
                          {row.medicinenamedisplay ? row.medicinenamedisplay : "-"}
                        </Text>
                      </Td>

                      <Td border="1px solid #E6E7E9" padding="4px 8px">
                        <Text w="100%" fontSize="0.8rem" pos="relative" cursor="pointer">
                          {row.batchnumber ? row.batchnumber : "-"}
                        </Text>
                      </Td>
                      <Td border="1px solid #E6E7E9" padding="4px 8px">
                        <Text w="100%" fontSize="0.8rem" pos="relative" cursor="pointer">
                          {row.mrp ? row.mrp : "0"}
                        </Text>
                      </Td>
                      <Td border="1px solid #E6E7E9" padding="4px 8px">
                        <Text w="100%" fontSize="0.8rem" pos="relative" cursor="pointer">
                          {row.quantity ? row.quantity : "0"}
                        </Text>
                      </Td>

                      <Td border="1px solid #E6E7E9" padding="4px 8px">
                        <Text w="100%" fontSize="0.8rem" pos="relative" cursor="pointer">
                          {row.totalamount ? currency + " " + row.totalamount : "0"}
                        </Text>
                      </Td>
                    </Tr>
                  ))}
                </>
              }
            />
          )}
           {ShowLab && !ShowPrescription && (
                <SimpleTable
                  isTh={false}
                  // headers={TableHeader}
                  isDataPresent={true}
                  ThStyle={{ textAlign: "center",
                     bg: "#FAFAFA" ,
                    border: "1px solid #E6E7E9",
                padding: "4px 8px", // Adjusted padding for smaller header rows
                fontSize: "0.8rem", // Reduced font size for headers
                  }}
                  footerStyle={{
                    pos: "sticky",
                    bottom: "-1px",
                    boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
                  }}
                  tableStyle={{
                    tableLayout: "fixed",
                    width: "100%",
                    border: "1px solid #E6E7E9",
                    borderBottom: "collapse",
                  }}
                  tableHeaders={
                    <Tr>
                      <Th fontSize="0.8rem" fontWeight="500" pl="8px" pr="8px">
                        Lab name
                      </Th>

                      <Th fontSize="0.8rem" fontWeight="500" pl="8px" pr="8px">
                        Amount  ({currency})
                      </Th>
                    </Tr>
                  }
                  tableBody={
                    <>
                      {rows.map((row, index) => (
                        <Tr w="100%" key={index}>
                          <Td border="1px solid #E6E7E9" padding="4px 8px">
                            <Text
                              w="100%"
                              fontSize="0.8rem"
                              pos="relative"
                              cursor="pointer"
                            >
                              {row.labname ? row.labname : "-"}
                            </Text>
                          </Td>
                          <Td border="1px solid #E6E7E9" padding="4px 8px">
                            <Text
                              w="100%"
                              fontSize="0.8rem"
                              pos="relative"
                              cursor="pointer"
                            >
                              {row.totalamount ?currency+ " " + row.totalamount : "-"}
                            </Text>
                          </Td>
                        </Tr>
                      ))}
                    </>
                  }
                />
              )}
              {ShowAppointment && (
                <SimpleTable
                  isTh={false}
                  // headers={TableHeader}
                  isDataPresent={true}
                  ThStyle={{ textAlign: "center", bg: "#FAFAFA",
                    border: "1px solid #E6E7E9",
                padding: "4px 8px", // Adjusted padding for smaller header rows
                fontSize: "0.8rem", // Reduced font size for headers
                   }}
                  footerStyle={{
                    pos: "sticky",
                    bottom: "-1px",
                    boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
                  }}
                  tableStyle={{
                    tableLayout: "fixed",
                    width: "100%",
                    border: "1px solid #E6E7E9",
                    borderBottom: "collapse",
                  }}
                  tableHeaders={
                    <Tr>
                      <Th fontSize="0.8rem" fontWeight="500" pl="8px" pr="8px">
                        Doctor Name
                      </Th>

                      <Th fontSize="0.8rem" fontWeight="500" pl="8px" pr="8px">
                        Total Amount  ({currency})
                      </Th>
                    </Tr>
                  }
                  tableBody={
                    <>
                      {rows.map((row, index) => (
                        <Tr w="100%" key={index}>
                          <Td border="1px solid #E6E7E9" padding="4px 8px">
                            <Text
                              w="100%"
                              fontSize="0.8rem"
                              pos="relative"
                              cursor="pointer"
                            >
                              {row.doctorname ? row.doctorname : "-"}
                            </Text>
                          </Td>
                          <Td border="1px solid #E6E7E9" padding="4px 8px">
                            <Text
                              w="100%"
                              fontSize="0.8rem"
                              pos="relative"
                              cursor="pointer"
                            >
                              {row.totalamount ? currency+ " " + row.totalamount : "-"}
                            </Text>
                          </Td>
                        </Tr>
                      ))}
                    </>
                  }
                />
              )}
        </Box>


         {/* Footer Section */}
         <Divider />

         <Box w="100%" display="flex" p="3rem 5rem" justifyContent="end">
           <Box w="100%" display="flex" gap="2rem" justifyContent="end">
             <Flex w="75px" flexDir="column" alignItems="start">
               <Text fontSize="0.875rem" className="textOverflow">Total Amount</Text>
               <Text fontSize="0.875rem">SGST</Text>
               <Text fontSize="0.875rem">CGST</Text>
               <Text fontSize="0.875rem">Total:</Text>
             </Flex>

             <Flex w="75px" flexDir="column" alignItems="start">
               <Text fontSize="0.875rem" fontWeight="600" whiteSpace="nowrap">
                 {calculateTotalAmountSumForDisplay()}
               </Text>
               <Text fontSize="0.875rem" fontWeight="600" whiteSpace="nowrap">
                 {calculateTotalSgstSumForDisplay()}
               </Text>
               <Text fontSize="0.875rem" fontWeight="600" whiteSpace="nowrap">
                 {calculateTotalCgstSumForDisplay()}
               </Text>
               <Text fontSize="0.875rem" fontWeight="600" whiteSpace="nowrap">
                 {calculateGrandTotalForDisplay()}
               </Text>
             </Flex>
           </Box>
         </Box>
       </Box>
       </Flex>
      )}
      <DynamicModal
        ref={deleteRowModal}
        modalHeader="Delete row"
        modalBody={
          <>
            <Text fontSize="14px" fontWeight="600">
              Are you sure you want to delete?
            </Text>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="deleteBtn"
              buttonText="Delete"
              onClick={deleteRowHandler}
            />
          </>
        }
      />
       <DynamicModal
        ref={generatepopup}
        modalHeader="Generate Invoice"
        modalBody={
          <>
            <Text fontSize="14px" fontWeight="600">
              Are you sure you want to generate invoice?
            </Text>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              // variant="deleteBtn"
              buttonText="Generate"
              onClick={prepareInvoiceInput}
            />
          </>
        }
      />
    </>
  );
};

export default OTCSale;
