import { getLoginID } from "../../../auth";
import FetchData from "../../client";
import formAPIModelcountry from "../../domains/country/countries";

// with formatting output
export const getCountry = async (inputData) => {
    try {
      inputData.loginid = getLoginID();
      const result = await FetchData("midoc_getcountry", inputData);
      // console.log(result,"Home db response")
       if(result?.output?.data) {
      var finalOutput = formAPIModelcountry(result.output.data)
      
      return finalOutput;
      }
  
    } catch (error) {
      console.error('Error in getDoctorRole:', error);
      throw error;
    }
  };