import {
    Box,
    Divider,
    Flex,
    Text,
    Tr,
    Td,
    Menu,
    MenuItem,
    MenuList,
    MenuButton,
    Image,
    Spacer,
    FormLabel,
    Input,
    Button,
    useEditableContext,
    useDisclosure,
    Collapse,
    Card,
    CardBody,
  } from "@chakra-ui/react";
  import React, { useEffect, useRef, useState } from "react";
  import MiniSideBar from "../../components/mini-sidebar";
  import Loader from "../../components/loader/loader";
  import SecondaryNav from "../../components/seconday-nav";
  import useLoader from "../../components/loader/loaderstates";
  import {
    DropdownInput,
    PrimaryButton,
    PrimaryInput,
    Toaster,
  } from "../../components";
  import SimpleTable from "../../components/simple-table";
  import ModalRight from "../../components/modal-right";
  import { getDoctorRole } from "../../middleware/services/roles";
  import {
    addInventory,
    deleteInventory,
    GetAIMedicineComposition,
    getInventory,
    GetMedicineName,
    updateInventory,
  } from "../../middleware/services/inventory";
  import { actionAccessMain, customDateValidation, formatDate_Do_MMM_YYYY, formatDate_YYYY_MM_DD, handleAlphaNumericDotAndSpace, handleNumericDotAndSpace, imagePath, useScreenType, validationOfNumberWithOnly1Decimal } from "../../auth";
  import DatePicker from "../../components/date-picker";
  import { format, isValid, parse } from "date-fns";
  import DynamicModal from "../../components/dynamic-modal";
  import moment from "moment";
  import MiniDropdownInput from "../../components/mini-dropdown";
  import MonthYearPicker from "../../components/date-monthyear";
  import { uploadImageInProfile } from "../../middleware/services/profile";
  import { COLORS } from "../../components/styles/colors";
import { useLocation, useNavigate } from "react-router-dom";
import { getInventoryLogDetail, getInventoryLogs } from "../../middleware/services/inventorylogs";
import MobileNavbar from "../../components/mobile-navbar";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
  
  const InventoryLogsIndex = () => {
    const { isLoading, showLoader, dismissLoader } = useLoader();
    const { showSuccessToast, showErrorToast } = Toaster();
    const addInventoryModal = useRef();
    const editInventoryModal = useRef();
    const updateStockModal = useRef();
    const deleteInventoryModal = useRef();
    
const [sortOrder, setSortOrder] = useState("ascending");
const [sortOrderDate, setSortOrderDate] = useState("ascending");
const [sortedData, setSortedData] = useState([]); // To store sorted data

    const TableHeader = [
      { key: "product-name",
        label: `Product Name ${sortOrder === 'ascending' ? '▲' : '▼'}`, // Dynamically add arrow based on sortOrder

         },
      { key: "product-type", label: "Product Type" },
      { key: "batch-no", label: "Batch no." },
      { key: "Manufacturer-Name", label: "Mfg Name" },
       { key: "Received", label: `Received Date ${sortOrderDate === 'ascending' ? '▲' : '▼'}` },
      { key: "Supplier-Name", label: "Supplier Name" },
      // { key: "stock-location", label: "Stock location" },
      { key: "expiry-date", label: "Expiry Date" },
      { key: "quantity", label: "Quantity" },
      { key: "Stock-In", label: "Stock-In" },
      { key: "Stock-Out", label: "Stock-Out" },
      //{ key: "actions", label: "Actions" },
    ];
    const [storageConditions, setStorageConditions] = useState([
      { id: 1, name: "Room Temperature" },
      { id: 2, name: "Cool" },
      { id: 3, name: "Refrigerated" },
      { id: 4, name: "Freezer" },
      { id: 5, name: "Deep Freezer" },
      { id: 6, name: "Controlled Room Temperature" },
      { id: 7, name: "Protection from Light" },
      { id: 8, name: "Protection from Moisture" },
      { id: 8, name: "Controlled Humidity Storage" },
      { id: 8, name: "Flammable Storage" },
    ]);
    
    const [data, setData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [search, setSearch] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
  
    // filteration states --------------------------
    const [batchnumberFilter, setBatchnumberFilter] = useState("");
    const [productnameFilter, setProductnameFilter] = useState("");
    const [producttypeFilter, setProducttypeFilter] = useState("");
    const [manufacturernameFilter, setManufacturernameFilter] = useState("");
    const [suppliernameFilter, setSuppliernameFilter] = useState("");
  
    const [productcodeid, setProductCodeId] = useState(null);
    const [batchnumber, setBatchnumber] = useState(null);
    const [productname, setProductname] = useState(null);
    const [producttype, setProducttype] = useState(null);
    const [manufacturername, setManufacturername] = useState(null);
    const [suppliername, setSuppliername] = useState(null);

    
    let itemPerPageCommonValue = 50;

    let [pageno, setPageno] = useState(1);
    let [pagesize, setPagesize] = useState(itemPerPageCommonValue);

      // states for item count display
    let [countTo, setCountTo] = useState(itemPerPageCommonValue);
    let [countFrom, setCountFrom] = useState(1);
    let [totalExceedsPageCount, setTotalExceedsPageCount] = useState(false);
  
    let [currentPage, setCurrentPage] = useState(1);
    let [totalPages, setTotalPages] = useState(1);
  
    const [showFilterCount, setShowFilterCount] = useState(false);
  
    // aditional states for add -
    const [dosage, setDosage] = useState(null);
    const [receivedby, setReceivedby] = useState(null);
    const [receiveddate, setReceiveddate] = useState(null);
    const [manufactureddate, setManufactureddate] = useState(null);
    const [supplierdate, setSupplierdate] = useState(null);
    const [expirydate, setExpirydate] = useState(null);
    const [costperunit, setCostperunit] = useState(null);
    const [costperstrip, setCostperstrip] = useState(null);
    const [costperbox, setCostperbox] = useState(null);
    const [cgst, setCgst] = useState(null);
    const [sgst, setSgst] = useState(null);
    const [location, setLocation] = useState(null);
    const [category, setCategory] = useState(null);
    const [reorderlevel, setReorderlevel] = useState(null);
    const [prescriptionrequirement, setPrescriptionrequirement] = useState(null);
    const [otcrequirement, setOtcrequirement] = useState(null);
    const [regulatoryinformation, setRegulatoryinformation] = useState(null);
    const [usageinstructions, setUsageinstructions] = useState(null);
    const [stocklocation, setStocklocation] = useState(null);
    const [supplierleadtime, setSupplierleadtime] = useState(null);
    const [stockin, setStockin] = useState(null);
    const [stockout, setStockout] = useState(null);
    const [numberofboxes, setNumberofboxes] = useState(null);
    const [numberofcontainers, setNumberofcontainers] = useState(null);
    const [numberofstrips, setNumberofstrips] = useState(null);
    const [numberofsyrups, setNumberofsyrups] = useState(null);
    const [quantityineachstrip, setQuantityineachstrip] = useState(null);
    const [storagecondition, setStorageCondition] = useState(null);
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [chemicalcomposition, setChemicalComposition] = useState("");
  
    const [initialRender, setInitialRender] = useState(true);
    
    const [actionAccess, setActionAccess] = useState(null);
    const regexPattern = /^(0[1-9]|1[0-2])-(\d{4})$/;
    const screenType = useScreenType()
    const navigate = useNavigate();
    const { isOpen, onToggle } = useDisclosure()
  
    const [pageLoad, setPageLoad] = useState(true);
    
    const clearState = () => {
      setBatchnumber(null);
      setProductname(null);
      setProducttype(null);
      setManufacturername(null);
      setSuppliername(null);
      setPageno(1);
     
      setCurrentPage(1)

      setShowFilterCount(false);
  
      setStorageCondition(null);
      setNumberofcontainers(null);
      setNumberofboxes(null);
      setStockout(null);
      setStockin(null);
      setStocklocation(null);
      setUsageinstructions(null);
      setOtcrequirement(null);
      setPrescriptionrequirement(null);
      setManufactureddate(null);
      setExpirydate(null);
  
      setReceiveddate(null);
      setReceivedby(null);
      setDosage(null);
  
      setProductCodeId(null);
      setChemicalComposition("");
      setSupplierdate(null);
      setCostperunit(null);
      setCostperstrip(null);
      setCostperbox(null);
      setCgst(null);
      setSgst(null);
      setLocation("");
      setCategory("");
      setReorderlevel("");
      setRegulatoryinformation("");
      setSupplierleadtime("");
      setNumberofstrips("");
      setNumberofsyrups("");
      setQuantityineachstrip("");
  
  
      setBatchnumberFilter("");
      setProductnameFilter("");
      setProducttypeFilter("");
      setManufacturernameFilter("");
      setSuppliernameFilter("");
  
      setFromDate("")
      setToDate("")
      
    };
  
    // ---------------- Page Load - Functionality Start ---------------------
    useEffect(() => {
      
    setActionAccess(actionAccessMain(11))
      showLoader();
    //  clearState();
      var input = {
        batchnumber: null,
        productname: null,
        producttype: null,
        manufacturername: null,
        suppliername: null,
        fromdate: null,
        todate: null,
        pageno: 1,
        pagesize,
      };
      var getInventoryFunction = async () => {
        try {
          var result = await getInventoryLogs(input);
  
          if (result?.data) {
            setData(result.data);
            let count = result?.count;
            let pageno = result?.pageno;
            let pagesize = result?.pagesize;
            setTotalItemCount(count)
            let totalPagesCalc = count / pagesize;
            let totalPagesCount = Math.ceil(totalPagesCalc);
             //alert(totalPages)
            setTotalPages(totalPagesCount);
            // const filtered = data.filter(item =>
            //   item.productname?.toLowerCase().includes(searchValue.toLowerCase())
            // );
            setFilteredData(result.data);
            
          if(count < itemPerPageCommonValue){
            setCountTo(count)
            setTotalExceedsPageCount(true)
          }else if (count > itemPerPageCommonValue && pageno == 1) {
            setCountTo(itemPerPageCommonValue);
            setTotalExceedsPageCount(false);
          }
          
          }else if(result?.output?.message == "No inventory logs found"){
            setData([])
          }else{
            console.log("Something went wrong")
          }
          // else if (result && result.output && result.output.message == "No roles are available for this doctorid") {
          //   setData("")
          //   //  showErrorToast("Something went wrong!")
          // }
          // else {
          //   //setData("")
          //   //  showErrorToast("Something went wrong!")
          // }
        } catch (error) {
          console.log(error, "error in getInventoryFunction");
        }
        dismissLoader();
      };
  
      getInventoryFunction();
    }, [refresh]);
  
    const getFilteredResults = () => {
      const tableContainer = document.querySelector('.inventoryTable');
      if (tableContainer) {
        tableContainer.scrollTop = 0;
      }
      showLoader();
      var input = {
        batchnumber: batchnumberFilter,
        productname: productnameFilter,
        producttype: producttypeFilter,
        manufacturername: manufacturernameFilter,
        suppliername: suppliernameFilter,
        "fromdate": fromdate ? formatDate_YYYY_MM_DD(fromdate) : null,
        "todate": todate ? formatDate_YYYY_MM_DD(todate) : null,
        pageno,
        pagesize,
      };
      var getInventoryFunction = async () => {
        try {
          var result = await getInventoryLogs(input);
  
         if (result?.data) {
            setData(result.data);
            let count = result?.count;
            let pageno = result?.pageno;
            let pagesize = result?.pagesize;
            setTotalItemCount(count)
            let totalPagesCalc = count / pagesize;
            let totalPagesCount = Math.ceil(totalPagesCalc);
            // alert(totalPages)
            setTotalPages(totalPagesCount);
            // const filtered = data.filter(item =>
            //   item.productname?.toLowerCase().includes(searchValue.toLowerCase())
            // );
            // if (count < itemPerPageCommonValue) {
            //   setCountTo(count);
            //   setTotalExceedsPageCount(true);
            // }
            if (count < itemPerPageCommonValue) {
              setCountTo(count);
              setTotalExceedsPageCount(true);
            }else if(count > itemPerPageCommonValue && pageno == 1) {
              setCountTo(itemPerPageCommonValue);
              setTotalExceedsPageCount(false);
            }
            setFilteredData(result.data);
          }else if(result?.output?.message == "No inventory logs found"){
            setData([]);
            setTotalItemCount(0)
            showErrorToast("No inventory logs found!")
          }
          // else if (result && result.output && result.output.message == "No roles are available for this doctorid") {
          //   setData("")
          //   //  showErrorToast("Something went wrong!")
          // }
          // else {
          //   //setData("")
          //   //  showErrorToast("Something went wrong!")
          // }
        } catch (error) {
          console.log(error, "error in getInventoryFunction");
        }
        dismissLoader();
      };
      
      getInventoryFunction();
    };
  
    const handleApplyFilters = () => {
      setShowFilterCount(true);
      setPageno(1);
    setPagesize(itemPerPageCommonValue);
    setCountFrom(1);
    setCountTo(itemPerPageCommonValue);
    setCurrentPage(1)
      getFilteredResults();
      if (isOpen) {
        onToggle(); // This will close the filter section if it's currently open
      }
    };
  
    const handleResetFields = () => {
      clearState();
      //clear states for correct item count
      setPagesize(itemPerPageCommonValue);
      setCountFrom(1)
      setCountTo(itemPerPageCommonValue)
      setProducttypeFilter("")
        setRefresh(!refresh);
        if (isOpen) {
          onToggle(); // This will close the filter section if it's currently open
        }
    };
    const handleKeyDown = (e) => {
      if (e.key == "Enter") {
        handleApplyFilters();
      }
    };
    useEffect(()=>{
      // if(pageno  && !initialRender){
        
      // getFilteredResults();
      // setInitialRender(false)
      // }
      if(pageno && !pageLoad){
        
         getFilteredResults();
         }
         setPageLoad(false)
    },[pageno])
  
    const handleNextClick = () => {
      let page = pageno +1 ;
    //  alert(page)
      setPageno(page);
      setCurrentPage(currentPage + 1);
     // getFilteredResults();

           
      // item count logic for next
      if(countTo + pagesize < totalItemCount){
       setCountTo(countTo + pagesize)
      }else{
        setCountTo(totalItemCount)
      }
      
      setCountFrom(countFrom + pagesize)
  
    };
    const handlePrevClick = () => {
      let page = pageno -1 ;
      //alert(page)
      setPageno(page);
     // setPageno(pageno - 1);
      setCurrentPage(currentPage - 1);
     // getFilteredResults();

     // item count logic for previous
       setCurrentPage(currentPage - 1);
       if(countTo == totalItemCount){
         let updatedCountUnroundedValue  = totalItemCount - pagesize;
         setCountTo(Math.ceil(updatedCountUnroundedValue / itemPerPageCommonValue ) * itemPerPageCommonValue )
   
       }else{
         setCountTo(countTo - pagesize)
       }
       
       setCountFrom(countFrom - pagesize)
    };
  
  
  
   
  
  
     //                             Update only stock code      - -----------           -------
  
    const [inventoryid, setinventoryid] = useState("");

    const openUpdateStockModal = (item)=>{
      
      showLoader();
      var input = {
        "inventoryid": item.inventoryid
      };
      var getInventoryFunction = async () => {
        try {
          var result = await getInventoryLogDetail(input);
  
          if (result?.data) {
            //setData(result.data);
            console.log("getting details data")
          }else if(result?.output?.message == "No inventory logs found"){
            setData([]);
            setTotalItemCount(0)
            showErrorToast("No inventory logs found!")
          }else{
            console.log("Something went wrong")
          }
          // else if (result && result.output && result.output.message == "No roles are available for this doctorid") {
          //   setData("")
          //   //  showErrorToast("Something went wrong!")
          // }
          // else {
          //   //setData("")
          //   //  showErrorToast("Something went wrong!")
          // }
        } catch (error) {
          console.log(error, "error in getInventoryFunction");
        }
        dismissLoader();
      };
  
      getInventoryFunction();

  
      updateStockModal.current.openRight();
    }
  
  
  
/// Sorting ---------------------------- Updated code

const sortByProductName = (e) => {
  let tableHeader = e.target.innerText;
//  alert(tableHeader)
  if(tableHeader.includes("PRODUCT NAME")){
    
    setSortOrder((prevOrder) => (prevOrder === "ascending" ? "descending" : "ascending"));
  }
  if(tableHeader.includes("RECEIVED DATE ")){
    
    setSortOrderDate((prevOrder) => (prevOrder === "ascending" ? "descending" : "ascending"));
  }
};

useEffect(() => {
  if (data) {
    // Make a copy of data before sorting to avoid mutating the original array
    const sortedArray = [...data].sort((a, b) => {
      if (sortOrder === "ascending") {
        return a.productname.localeCompare(b.productname);
      } else {
        return b.productname.localeCompare(a.productname);
      }
    });
    //setSortedData(sortedArray);
    setData(sortedArray)
    console.log(sortedArray, sortOrder === "ascending" ? "ascending" : "descending");
  }
}, [sortOrder]); // Trigger sorting when sortOrder or data changes

useEffect(() => {
  if (data) {
    // Make a copy of data before sorting to avoid mutating the original array
    const sortedArray = [...data].sort((a, b) => {
      if (sortOrderDate === "ascending") {
        return a.receiveddate.localeCompare(b.receiveddate);
      } else {
        return b.receiveddate.localeCompare(a.receiveddate);
      }
    });
    //setSortedData(sortedArray);
    setData(sortedArray)
    console.log(sortedArray, sortOrder === "ascending" ? "ascending" : "descending");
  }
}, [sortOrderDate]); // Trigger sorting when sortOrder or data changes


// From date and to date functions code here 

const [ fromdate , setFromDate] = useState("")
const [ todate , setToDate] = useState("")
const [ toDateMinMax , setToDateMinMax] = useState("")
const [ fromDateMinMax , setFromDateMinMax] = useState("")

const handleFromDateChange = (date) => {
  const formattedDate = format(date, "dd-MM-yyyy");
  setFromDate(formattedDate);

//const formattedDateForPopulate = format(date, "dd-MM-yyyy");
setFromDateMinMax(date);
};

const handleToDateChange = (date) => {
  const formattedDate = format(date, "dd-MM-yyyy");
  setToDate(formattedDate);

//const formattedDateForPopulate = format(date, "dd-MM-yyyy");
setToDateMinMax(date);
};


const gobackfunction = ()=>{
  window.history.back();
 }

 const productTypeFilterChange = (value) => {
  setProducttypeFilter(value);
};
  
  
    return (
      <>
      {screenType == 'web' ? (  
        <Box w="100%">
          <Flex>
            <Box w="6rem">
              <MiniSideBar />
            </Box>
            {isLoading && <Loader />}
            <Box w="calc(100vw - 6.25rem)" display="flex" flexDir="column">
              <SecondaryNav withBack="Inventory Logs" goBack={gobackfunction}  />
              <Box
                gap="10px"
                my="1rem"
                px="1rem"
                w="100%"
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                <Box w="80%" display="flex" gap="10px">
                 
                  <PrimaryInput
                    variant="fullRound"
                    inputPlace="Product name"
                    id="productname"
                    onChange={(e) => setProductnameFilter(e.target.value)}
                    inputValue={productnameFilter}
                    onKeyDown={handleKeyDown}
                    maxLength={50}
                  />
                 <DropdownInput
                  variant="fullSide"
                  dropDownPlace="Product type"
                  options={[
                    "Tablet",
                    "Capsule",
                    "Syrup",
                    "Injection",
                    "Ointment",
                  ]}
                  readOnly={true}
                  id="Producttype"
                  onClick={productTypeFilterChange}
                   value={producttypeFilter}
                  menuList={{ pos: "absolute", right: "-45px", minW: "115px" }}
                />
                   <PrimaryInput
                    variant="fullRound"
                    inputPlace="Batch number"
                    id="batchnumber"
                    onChange={(e) => setBatchnumberFilter(e.target.value)}
                    inputValue={batchnumberFilter}
                    onKeyDown={handleKeyDown}
                    maxLength={50}
                  />
                 
                  <PrimaryInput
                    variant="fullRound"
                    inputPlace="Mfg name"
                    id="manufacturername"
                    onChange={(e) => setManufacturernameFilter(e.target.value)}
                    inputValue={manufacturernameFilter}
                    onKeyDown={handleKeyDown}
                    maxLength={50}
                  />
                   {/* <PrimaryInput
                    variant="fullRound"
                    inputPlace="Supplier name"
                    id="suppliername"
                    onChange={(e) => setSuppliernameFilter(e.target.value)}
                    inputValue={suppliernameFilter}
                    onKeyDown={handleKeyDown}
                    maxLength={50}
                  /> */}
                     <DatePicker
                    placeholderProp="From Date"
                   datePickerInputStyle={{ borderRadius: "10px 10px 10px 10px" }}
                    onDateChange={(e) => handleFromDateChange(e)}
                   value={fromdate}
                   clearValue={true}

                   CUSTOM_DISABLE={true}
                   disableFutureDates={true}
                   maxDate={toDateMinMax}
                  />

                     <DatePicker
                    placeholderProp="To Date"
                    leftPos={{ left: "5px" }}
                    datePickerInputStyle={{ borderRadius: "10px 10px 10px 10px" }}
                    onDateChange={(e) => handleToDateChange(e)}
                    value={todate}
                   clearValue={true}

                   CUSTOM_DISABLE={true}
                   maxDate={new Date().toISOString()} 
                   minDate={fromDateMinMax}
                   
                  />
                </Box>
  
                <Box w="20%" display="flex" gap="10px">
                  <PrimaryButton
                    buttonText="Filter"
                    id="filterButton"
                    variant="mdBtn"
                    onClick={handleApplyFilters}
                  />
                  <PrimaryButton
                    buttonText="Reset"
                    id="resetButton"
                    variant="grayBtn"
                    btnStyle={{ color: "#AA2F27" }}
                    onClick={handleResetFields}
                  />
                </Box>
              </Box>
  
              {/* <Divider />
         
                <Box
                  w="100%"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p="1rem"
                  minH="52px"
                >
                       {showFilterCount && (
                        <>
                  <Text fontSize="0.875rem" fontWeight="600">
                    { totalItemCount  > 1
                      ? totalItemCount + " Items found"
                      : totalItemCount == 1
                      ? totalItemCount + " Item found"
                      : "No Items found!"}
                  </Text>
                  <Text
                    fontSize="0.875rem"
                    color="red"
                    fontWeight="600"
                    onClick={handleResetFields}
                    cursor="pointer"
                  >
                    Clear Filters
                  </Text>
                  </>
                )}
                </Box> */}
            
              <Divider />
              <Flex w="100%" pr="1rem" >
                <Spacer />
                {/* <PrimaryButton
                  buttonText="Add"
                //  onClick={openAddModal}
                  variant="mdBtn"
                  btnStyle={{ w: "10%", my: "1rem" }}
                  
                  isDisabled={ actionAccess && (!actionAccess.create) ? true : false} 
                /> */}
              </Flex>
              <Box>
                <Box border="1px solid #E6E7E9">
                <SimpleTable
                    headers={TableHeader}
                    onClick={(e)=>sortByProductName(e)}
                    className="inventoryTable"
                    isDataPresent={true}
                    footerStyle={{
                      pos: "fixed",
                      bottom: "0",
                      boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
                      width: "100%"
                    }}
                    tableStyle={{
                      h: "calc(100vh - 230px)",
                      maxH: "calc(100vh - 200px)",
                      overflowY: "auto",
                      tableLayout: "fixed",
                      width: "100%",
                      cursor:"pointer"
                    }}
                    tableHeader={{pos:"sticky" , top:"0" , bg:"white"}}
                  //  tableCss={{h:"100%"}}
                    tableBody={
                      <>
                        {data && data.length > 0 ? (
                          data.map((item, index) => (
                            <Tr w="100%" key={index}>
                              <Td w="250px">
                                <Box
                                  w="100%"
                                  display="flex"
                                  gap="0.5rem"
                                  alignItems="center"
                                >
                                  <Box w="80%" maxW="8rem" isTruncated>
                                    <Text
                                      className="textOverflow"
                                      fontSize=".875rem"
                                      fontWeight="600"
                                    >
                                      {item.productname ? item.productname : "-"}
                                    </Text>
                                  </Box>
                                </Box>
                              </Td>
  
                              <Td w="250px">
                                <Box
                                  w="100%"
                                  display="flex"
                                  gap="0.5rem"
                                  alignItems="center"
                                >
                                  <Box w="80%" maxW="8rem" isTruncated>
                                    <Text
                                      className="textOverflow"
                                      fontSize=".875rem"
                                      fontWeight="600"
                                    >
                                      {item.producttype ? item.producttype : "-"}
                                    </Text>
                                  </Box>
                                </Box>
                              </Td>
  
                              <Td pl='1rem' pr='0.5rem' maxW="150px">
                                <Text fontSize=".875rem" fontWeight="500" className="textOverflow" title={item?.batchnumber}>
                                  {item.batchnumber ? item.batchnumber : "-"}
                                </Text>
                              </Td>
                              <Td>
                                <Text fontSize=".875rem" fontWeight="500">
                                  {item.manufacturername
                                    ? item.manufacturername
                                    : "-"}
                                </Text>
                              </Td>
  
                              <Td>
                                <Text fontSize=".875rem" fontWeight="500">
                                  {item.receiveddate
                                    ? formatDate_Do_MMM_YYYY(item.receiveddate)
                                    : "-"}
                                </Text>
                              </Td>

                              <Td>
                                <Text fontSize=".875rem" fontWeight="500">
                                  {item.suppliername
                                    ? item.suppliername
                                    : "-"}
                                </Text>
                              </Td>
                              {/* <Td>
                                <Text fontSize=".875rem" fontWeight="500">
                                  {item.stocklocation ? item.stocklocation : "-"}
                                </Text>
                              </Td> */}
  
                              <Td>
                                <Text fontSize=".875rem" fontWeight="500">
                                  {item.expirydate
                                    ? item.expirydate
                                    : "-"}
                                </Text>
                              </Td>
  
                              <Td>
                                <Box p=".25rem .5rem" borderRadius="full">
                                  <Text fontSize=".75rem" fontWeight="600">
                                    {item.remainingquantity
                                      ? item.remainingquantity
                                      : "-"}
                                  </Text>
                                </Box>
                              </Td>
  
                              {/* <Td>
                                <Text fontSize=".875rem" fontWeight="500">
                                  {item.createddate
                                    ? formatDate_Do_MMM_YYYY(item.createddate)
                                    : "-"}
                                </Text>
                              </Td> */}
                             
                              <Td>
                                <Text fontSize=".875rem" fontWeight="500">
                                  {item.stockin
                                    ? item.stockin
                                    : "-"}
                                </Text>
                              </Td>
  
                              <Td>
                                <Text fontSize=".875rem" fontWeight="500">
                                  {item.stockout
                                    ? item.stockout
                                    : "-"}
                                </Text>
                              </Td>
                              {/* <Td>
                                <Menu>
                                  <MenuButton as="button">
                                    <Image
                                      w="1.5rem"
                                      src="/assets/svgs/more-options.svg"
                                    />
                                  </MenuButton>
                                  <MenuList
                                    zIndex={9999}
                                    pos="absolute"
                                    right="-33px"
                                    minW="125px"
                                  >
                                     <MenuItem onClick={() => openEditModal(item)}
                                    isDisabled={ actionAccess && (!actionAccess.update) ? true : false} >
                                      View/Edit
                                    </MenuItem> 

                                    <MenuItem onClick={() => openUpdateStockModal(item)}
                                    isDisabled={ actionAccess && (!actionAccess.read) ? true : false}>
                                      View
                                    </MenuItem>

                                    <MenuItem onClick={() => openDeleteModal(item)}
                                    isDisabled={ actionAccess && (!actionAccess.delete) ? true : false}
                                    >Delete</MenuItem> 
                                    
                                  </MenuList>
                                </Menu>
                              </Td> */}
                            </Tr>
                          ))
                        ) : (
                          // <Tr>
                            
                          // <Td
                          //   display="flex"
                          //   justifyContent="center"
                          //   flexDir="column"
                          //   gap="10px"
                          //   alignItems="center"
                          //   h="100%"
                          //   colSpan={7}
                          //   textAlign="center"
                          // >
                          //   <Image src="/assets/svgs/inventory.svg" opacity="0.5"/>
                          //   <Text>No Data Available</Text>
                          // </Td>
                          
                          // </Tr>
                          <Tr>
                          <Td colSpan={7} h="400px" textAlign='center'>No Data Available!</Td>
                        </Tr>
                        )}
                      </>
                    }
                    footerSection={
                      <>
                      {data && data.length > 0 &&
                          
                        <Tr w="100%">
                        <Td colSpan={11}  w="calc(100vw - 6.5rem)">
                          <Box
                            w="100%"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Box  visibility={(totalItemCount < countTo && !totalExceedsPageCount)  ? "hidden" : "visible"}>
                            <Text>Showing  <b>{countFrom}</b> to <b>{countTo}</b>  of {totalItemCount} results</Text>
                            </Box>


                          <Box  
                          gap="2.5rem"
                           display="flex"
                          justifyContent="space-between"
                          alignItems="center" >
                            <Box
                              as="button"
                              onClick={handlePrevClick}
                              //  disabled={currentPage == 1}
                              visibility={
                                currentPage == 1 ? "hidden" : "visible"
                              }
                              p="0.5rem 0.75rem"
                              border="0.0625rem solid #E6E7E9"
                              borderRadius="0.75rem"
                              fontSize=".875rem"
                              fontWeight="500"
                            >
                              Previous
                            </Box>
                            <Box>
                              <Text
                                textAlign="center"
                                fontSize=".875rem"
                                fontWeight="600"
                              >
                                Page {currentPage} of {totalPages}
                              </Text>
                            </Box>
                            {(totalItemCount >= countTo && !totalExceedsPageCount)  && 
                            <Box
                              as="button"
                              p="0.5rem 0.75rem"
                              mr="2.5rem"
                              border="0.0625rem solid #E6E7E9"
                              borderRadius="0.75rem"
                              onClick={handleNextClick}
                              // disabled={currentPage == totalPages}
                              visibility={
                                currentPage == totalPages ? "hidden" : "visible"
                              }
                              fontSize=".875rem"
                              fontWeight="500"
                            >
                              Next
                            </Box>
                           }
                            
                          </Box>
                          </Box>
                        </Td>
                      </Tr>
                      }
                      </>
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Flex>
        </Box>
      ) : (
        <>
          <MobileNavbar />
            <MobileSecondaryNav
              header="Inventory logs"
              handlebackarrow={() => navigate(`/doctorprofile`)}
            />

          <Box
            w="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p="1rem"
            gap='0.75rem'
          >
            <PrimaryInput
              variant="fullRound"
              inputPlace="Product name"
              id="productname"
              onChange={(e) => setProductnameFilter(e.target.value)}
              inputValue={productnameFilter}
              onKeyDown={handleKeyDown}
              maxLength={50}
            />
            <Box onClick={onToggle}>
              <Image
                w='50px'
                cursor='pointer'
                src={isOpen ? "assets/svgs/outline-f.svg" : "assets/svgs/filled-f.svg" }
              />
            </Box>
            <PrimaryButton
              buttonText="Filter"
              id="filterButton"
              variant="mdBtn"
              onClick={handleApplyFilters}
            />
            <PrimaryButton
              id="resetButton"
              btnIcon="/assets/svgs/refresh.svg"
              iconSrc={true}
              variant="grayBtn"
              btnStyle={{w: "50px",p:'0'}}
              iconSrcStyle={{mr: "0"}}
              onClick={handleResetFields}
            />
          </Box>

          <Collapse in={isOpen} animateOpacity style={{overflow:'visible'}}>
            <Box w="100%" display="flex" p='1rem' pt='0' flexDir='column' gap="10px">
            <DropdownInput
            variant="fullSide"
            dropDownPlace="Product type"
            options={[
              "Tablet",
              "Capsule",
              "Syrup",
              "Injection",
              "Ointment",
            ]}
            readOnly={true}
            id="Producttype"
            onClick={productTypeFilterChange}
              value={producttypeFilter}
            menuList={{ pos: "absolute", right: "-45px", minW: "115px" }}
          />
              <PrimaryInput
              variant="fullRound"
              inputPlace="Batch number"
              id="batchnumber"
              onChange={(e) => setBatchnumberFilter(e.target.value)}
              inputValue={batchnumberFilter}
              onKeyDown={handleKeyDown}
              maxLength={50}
            />
            
            <PrimaryInput
              variant="fullRound"
              inputPlace="Mfg name"
              id="manufacturername"
              onChange={(e) => setManufacturernameFilter(e.target.value)}
              inputValue={manufacturernameFilter}
              onKeyDown={handleKeyDown}
              maxLength={50}
            />
              {/* <PrimaryInput
              variant="fullRound"
              inputPlace="Supplier name"
              id="suppliername"
              onChange={(e) => setSuppliernameFilter(e.target.value)}
              inputValue={suppliernameFilter}
              onKeyDown={handleKeyDown}
              maxLength={50}
            /> */}
                <DatePicker
              placeholderProp="From Date"
              datePickerInputStyle={{ borderRadius: "10px 10px 10px 10px" }}
              onDateChange={(e) => handleFromDateChange(e)}
              value={fromdate}
              clearValue={true}

              CUSTOM_DISABLE={true}
              disableFutureDates={true}
              maxDate={toDateMinMax}
            />

                <DatePicker
              placeholderProp="To Date"
              leftPos={{ left: "5px" }}
              datePickerInputStyle={{ borderRadius: "10px 10px 10px 10px" }}
              onDateChange={(e) => handleToDateChange(e)}
              value={todate}
              clearValue={true}

              CUSTOM_DISABLE={true}
              maxDate={new Date().toISOString()} 
              minDate={fromDateMinMax}
              
            />
            </Box>
          </Collapse>
          <Divider />

          <Flex w='100%' gap='1rem' p='1rem' flexDir='column' maxH="calc(100vh - 275px)" overflow='hidden auto'>
            {data && data.length > 0 ? (
              data.map((item, index) => (
              <Card
                borderRadius="12px"
                key={index}
                w="100%"
                border="1px solid #E6E7E9"
                boxShadow="0px 8px 20px 0px #E6E7E966"
              >
                <CardBody p={{base: "0.875rem",lg: "0.875rem", xl: "1.25rem"}}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mb="0.75rem"
                  >                  
                    <Box cursor="pointer" display="flex" alignItems="center">
                      <Box display="flex" flexDirection="column" justifyContent="center">
                        <Text fontSize="15px" fontWeight="600" className="textOverflow" title={item.productname ? item.productname : "-"} maxW={{base: "180px", xl:"110px"}}>
                        {item.productname ? item.productname : "-"}
                        </Text>
                      </Box>
                    </Box>

                      
                  </Box>
                  <Flex w='100%' mb="0.75rem" justifyContent='space-between'>
                    <Box display='flex' flexDir='column'>
                    
                        <Text fontSize="10px" color="gray.500">
                          Product type
                        </Text>
                        <Text fontSize="12px" fontWeight="600">
                          {item.producttype ? item.producttype : "-"}
                        </Text>
                        
                      </Box>
                      <Box display='flex' flexDir='column' alignItems="center">
                    
                    <Text fontSize="10px" color="gray.500">
                      Batch no.
                    </Text>
                    <Box
                        as="button"
                      >
                        <Text fontSize="12px" fontWeight="600" className="textOverflow" title={item?.batchnumber}>
                        {item.batchnumber ? item.batchnumber : "-"}
                    </Text>
                      </Box>
                    
                  
                  </Box>
                  </Flex>
                  
                  <Flex w='100%'>
                    <Box w='100%' display='flex' flexDir='column'>
                    
                        <Text fontSize="10px" color="gray.500">
                          Expiry date
                        </Text>
                        <Text fontSize="12px" fontWeight="600">
                          {item.expirydate ? item.expirydate : "-"}
                        </Text>
                        
                      </Box>
                      <Box w='100%' display='flex' flexDir='column' alignItems="center">
                    
                    <Text fontSize="10px" color="gray.500">
                      Stock-In
                    </Text>
                    <Box
                        as="button"
                      >
                        <Text fontSize="12px" fontWeight="600">
                        {item.stockin ? item.stockin : "-"}
                    </Text>
                      </Box>
                    
                  
                  </Box>
                      

                    <Box w='100%' display='flex' flexDir='column' alignItems='flex-end'>
                        <Text fontSize="10px" color="gray.500">
                          Quantity
                        </Text>
                        <Text fontSize="12px" fontWeight="600">
                          {item.remainingquantity ? item.remainingquantity : "-"}
                        </Text>
                      </Box>
                  </Flex>
                
                </CardBody>

              </Card>
            ))) : (
              <Text>No data available</Text>
            )}
          </Flex>
        </>
      )}
       
  
       
  
  <ModalRight
          ref={updateStockModal}
          header="Update Stock"
          modalWidth={{ base: "100%", lg: "calc(100vw - 60%)" }}
          body={
            <>
              <Box w="100%" mt="1rem">
  
              <Box display="flex" mt="20px" mb="10px">
                <Text fontSize="0.875rem" fontWeight="500">
              Product Details:
            </Text>
            </Box>
                <Box w="100%" display="flex">
                  <PrimaryInput
                    variant="leftSide"
                    inputPlace="Product name*"
                    onChange={(e) => setProductname(e.target.value)}
                    inputValue={productname}
                    defaultValue={productname}
                    isDisabled={true}
                    maxLength={100}
                  />
                  <PrimaryInput
                    variant="rightSide"
                    inputPlace="Batch no.*"
                    onChange={(e) => setBatchnumber(e.target.value)}
                    inputValue={batchnumber}
                    defaultValue={batchnumber}
                    
                    isDisabled={true}
                    maxLength={100}
                  />
                </Box>
  
  
                <Box w="100%" display="flex">
                  {/* <PrimaryInput
                    variant="bothSide"
                    inputPlace="Cost Per each Unit*"
                    onChange={handleCostPerUnit}
                    inputValue={costperunit}
                    defaultValue={costperunit}
                    isDisabled={true}
                    maxLength={15}
                  /> */}
                  <PrimaryInput
                    variant="leftDown"
                    inputPlace="Cost Per Unit*"
                   // onChange={handleCostPerStrip}
                    inputValue={costperstrip}
                    defaultValue={costperstrip}
                    isDisabled={true}
                    maxLength={15}
                  />
                  <PrimaryInput
                    variant="rightDown"
                    inputPlace="Cost Per Box*"
                  //  onChange={handleCostPerBox}
                    inputValue={costperbox}
                    defaultValue={costperbox}
                    isDisabled={true}
                    maxLength={15}
                  />
                </Box>
  
                <Box display="flex" mt="20px" mb="10px">
                <Text fontSize="0.875rem" fontWeight="500">
              Stock:
            </Text>
            </Box>
                
                <Box w="100%" display="flex">
                  
                   {/* <DatePicker
                  // disableFutureDates={true}
                   minDate={moment(new Date()).format("DD-MM-YYYY")}
                    placeholderProp="Expiry Date*"
                    leftPos={{ left: "5px" }}
                    datePickerInputStyle={{ borderRadius: "0!important" }}
                    onDateChange={(e) => handleExpiryDateChange(e)}
                   defaultDate={expirydate}
                  />  */}
                    {/* <PrimaryInput
                    variant="leftSide"
                    inputPlace="Expiry Date*"
                    onChange={(e) => setExpirydate(e.target.value)}
                    inputValue={expirydate}
                    defaultValue={expirydate}
                    maxLength={20}
                  /> */}
                       <MonthYearPicker
          placeholderProp="Expiry Date*"
          value={expirydate}
         // onDateChange={handleMonthYearChange}
          datePickerInputStyle={{ borderRadius: "0!important" }}
         // minYear={mfgYear ? mfgYear : moment().year()}
         // defaultDate={expirydate}
  
        />
                   <PrimaryInput
                    variant="rightSide"
                    inputPlace="Stock in*"
                    onChange={(e) => setStockin(e.target.value)}
                    inputValue={stockin}
                    defaultValue={stockin}
                    maxLength={100}
                  />
                 
                </Box>
  
  
  
  
                <Box w="100%" display="flex">
                  {/* <DatePicker
                    disableFutureDates={true}
                    placeholderProp="Manufactured Date"
                    leftPos={{ left: "5px" }}
                    datePickerInputStyle={{ borderRadius: "0!important" }}
                    onDateChange={(e) => handleMfgDateChange(e)}
                    defaultDate={manufactureddate}
                  /> */}
                  
                </Box>
  
  
  
  
                {/* <Box w="100%" display="flex">
                  <PrimaryInput
                    variant="bothSide"
                    inputPlace="No. of boxes*"
                    onChange={handleNoOfBoxes}
                    inputValue={numberofboxes}
                    defaultValue={numberofboxes}
                    maxLength={15}
                  />
                  <PrimaryInput
                    variant="bothSide"
                    inputPlace="No. of containers*"
                    onChange={handleNoOfContainers}
                    inputValue={numberofcontainers}
                    defaultValue={numberofcontainers}
                    maxLength={15}
                  />
                </Box> */}
  
                <Box w="100%" display="flex">
                  <PrimaryInput
                    variant="leftDown"
                    inputPlace="Number of Units*"
                   // onChange={handleNoOfStrips}
                    inputValue={numberofstrips}
                    defaultValue={numberofstrips}
                    maxLength={15}
                  />
                  <PrimaryInput
                    variant="rightDown"
                    inputPlace="Quantity in each Unit*"
                   // onChange={handleQuantityInEachStrip}
                    inputValue={quantityineachstrip}
                    defaultValue={quantityineachstrip}
                    maxLength={15}
                  />
                </Box>
  
                <Box w="100%" display="flex">
                  {/* <PrimaryInput
                    variant="bothSide"
                    inputPlace="Number of Syrup*"
                    onChange={handleNoOfSyrups}
                    inputValue={numberofsyrups}
                    defaultValue={numberofsyrups}
                    maxLength={15}
                  /> */}
                  
                </Box>
  
               
  
  
              </Box>
            </>
          }
          footerActions={
            <>
              <Box w="100%" display="flex" gap="1rem">
                <PrimaryButton
                  buttonText="Cancel"
                  variant="grayBtnFull"
                  btnStyle={{ color: "#AA2F27" }}
                  
                  onClick={() => updateStockModal.current.closeRight()}
                />
  
              
              </Box>
            </>
          }
        />
  
 
      </>
    );
  };
  
  export default InventoryLogsIndex;
  