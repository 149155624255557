import { Box, Center, Circle, Divider, Flex, Image, Spacer, Stack, Switch, Td, Text, Tr, VStack } from '@chakra-ui/react'
import React, { useRef, useState,useEffect } from 'react'
import MiniSideBar from '../../components/mini-sidebar'
import { COLORS } from '../../components/styles/colors';
import SecondaryNav from '../../components/seconday-nav';
import DateRangeIndex from '../../components/date-range';
import PrimaryButton from '../../components/primary-button';
import TabSection from '../../components/tabs';
import DynamicModal from '../../components/dynamic-modal';
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import moment from 'moment';
import { FetchReportsData, FetchExcelReportsData } from '../../middleware/services/reports';
import PatientTurnUpChart from './barchart';
import MedicineChart from './groupedcolumnchart';
import PatientTimeChart from './stackedcolumnchart';
import AppointmentsChart from './dualaxischart';
import ExcelDownload from './downloadexcel';
import MobileSecondaryNav from '../../components/mobile-secondary-navbar';
import MobileNavbar from '../../components/mobile-navbar';
import { transform } from 'framer-motion';
import { useScreenType } from '../../auth';



const ReportIndex = () => {
    const openCalendar = useRef(null)
    const downloadReport = useRef(null)
    const [data, setData] = useState(null);
    const [reportdata, setReportData] = useState(null);
    const [doctorid, setDoctorid] = useState();
    const today = moment().format("YYYY-MM-DD HH:mm:ss.SSS");
  const thisweekstartdate = moment()
    .startOf("week")
    .format("YYYY-MM-DD HH:mm:ss.SSS");
  const thismonthstartdate = moment()
    .startOf("month")
    .format("YYYY-MM-DD HH:mm:ss.SSS");
  const thisyearstartdate = moment()
    .startOf("year")
    .format("YYYY-MM-DD HH:mm:ss.SSS");
    const currentDate = moment();
    const lastYearStartDate = moment(currentDate).subtract(1, 'year').startOf('year').format("YYYY-MM-DD HH:mm:ss.SSS");
    const lastYearEndDate = moment(currentDate).subtract(1, 'year').endOf('year').format("YYYY-MM-DD HH:mm:ss.SSS");
    const lastMonthStartDate = moment(currentDate).subtract(1, 'month').startOf('month').format("YYYY-MM-DD HH:mm:ss.SSS");
    const lastMonthEndDate = moment(currentDate).subtract(1, 'month').endOf('month').format("YYYY-MM-DD HH:mm:ss.SSS");
    const [activeTab, setActiveTab] = useState(thisweekstartdate);
    const [activeFilter, setActiveFilter] = useState('Week');
    const { isLoading, showLoader, dismissLoader } = useLoader();
    const [downloadExcel, setdownloadExcel] = useState(false);
    const [activeTabexcel, setActiveTabExcel] = useState("week")
    const screenType = useScreenType()

    useEffect(() => {
        fetchData();
      }, []);
      const fetchData = async () => {
        showLoader();
        try {
          const result = await FetchReportsData({
            doctorid,
            fromdate: thisweekstartdate,
            todate: today,
          }, "Week");
          // }, "Week",activeFilter);
           // console.log(result,"charts result")
          if (result != null) {
            setData(result);           
          } else {
            //console.log("No data");
          }
          dismissLoader();
        } catch (error) {
          console.error("Error in reports:", error);
        }
      };
      const handleTabClick = (index) => {
        switch (index) {
          case 0:
            setActiveTab(today);
            fetchFilterData(today, 'Today');
            setActiveFilter('Today')
            break;
          case 1:
            setActiveTab(thisweekstartdate);
            fetchFilterData(thisweekstartdate, 'Week');
            setActiveFilter('Week')
            break;
          case 2:
            setActiveTab(thismonthstartdate);
            fetchFilterData(thismonthstartdate, 'Month');
            setActiveFilter('Month')
            break;
          case 3:
            setActiveTab(thisyearstartdate);
            fetchFilterData(thisyearstartdate, 'Year');
            setActiveFilter('Year')
            break;
           
          default:
            break;
        }
      };
    
      const fetchFilterData = async (fromdate, type) => {
        setData("")
        showLoader();
        try {
          const result = await FetchReportsData({
            doctorid,
            fromdate: fromdate,
            todate: today,
          }, type);
          if (result != null) {
            setData(result);
           
          } else {
            console.log("No data");
          }
          dismissLoader();
        } catch (error) {
          console.error("Error in DashboardIndex:", error);
        }
      };
      const handlerangedate = async (selecteddate, date, activeFilter)=>{
        // console.log('selecteddate Pages: ', selecteddate)
        // console.log('date Pages: ', date)
        // console.log('activeFilter Pages: ', activeFilter)
        
        showLoader();
        try {
          const result = await FetchReportsData({
            doctorid,
            fromdate: selecteddate.startDate,
            todate: selecteddate.endDate,
          }, date, activeFilter, selecteddate);
        //   console.log('date PagesResult: ', date)
        // console.log('activeFilter PagesResult: ', activeFilter)
        // console.log('selecteddate PagesResult: ', selecteddate)
          // console.log('rangedDate: ',  result)
          if (result != null) {
            setData(result);
           
          } else {
            console.log("No data");
          }
          dismissLoader();
        } catch (error) {
          console.error("Error in DashboardIndex:", error);
        }
      }
      let exceldata = "";
      let rendercomponent = false;
      const generateExcelReport = async () => {
        setdownloadExcel(false);
        setReportData("");
        const tab = activeTabexcel;
        let input = {
          doctorid,
          fromdate: thisweekstartdate,
          todate: today,
          type: "exceldownload",
        };
      
        switch (tab) {
          case "day":
            input.fromdate = today;
            break;
          case "week":
            input.fromdate = thisweekstartdate;
            break;
          case "month":
            input.fromdate = thismonthstartdate;
            break;
          case "lastmonth":
            input.fromdate = lastMonthStartDate;
            input.todate = lastMonthEndDate;
            break;
          case "thisyear":
            input.fromdate = thisyearstartdate;
            break;
          case "lastyear":
            input.fromdate = lastYearStartDate;
            input.todate = lastYearEndDate;
            break;
          default:
            break;
        }
      
        showLoader();
      
        try {
          const result = await FetchExcelReportsData(input);
          if (result) {
            //exceldata = result.output
            //rendercomponent = true;
            console.log(exceldata)
             setReportData(result.output);
             setdownloadExcel(true);
          } else {
            // Handle no data case
          }
        } catch (error) {
          console.error("Error in reports:", error);
        } finally {
          dismissLoader();
        }
      };
      
      // Debounce function to prevent multiple rapid clicks
      const debounce = (func, wait) => {
        let timeout;
        return (...args) => {
          clearTimeout(timeout);
          timeout = setTimeout(() => func.apply(this, args), wait);
        };
      };

      const handleClick = debounce(() => {
        generateExcelReport();
      }, 500);
      const handleDownloadComplete = () => {
        // Reset the download state after completion
        setdownloadExcel(false);
      };
  return (
    <>
     {isLoading && <Loader />}

     {screenType == 'web' ? (

     
        <Box w="100%">
            <Flex>
                <Box w="6rem">
                    <MiniSideBar />
                </Box>
                <Box w="100%">
                    <SecondaryNav customHeader="Reports" />
                    <Box w="calc(100vw - 6rem)" overflow="hidden auto" h="calc(100vh - 75px)">
                        <Flex>
                            {/* Main container start */}
                            <Box w="100%" bg="#fafafa" overflowX="auto">
                                <Box w="100%">
                                    {/* filter section start */}
                                    <Box display="flex" pos="sticky" top="0" zIndex='99' p="1.5rem" border="1px solid #E6E7E9" justifyContent="space-between" gap="10px" w="100%" alignItems="center" pr="25px">
                                        <TabSection
                                            defaultIndex={1}
                                            tabs={["Today","This week", "This month", "This year"]}
                                            onClickTab={handleTabClick}
                                        />

                                        <Box display="flex" gap="0.5rem">
                                            <Box
                                                as="button"
                                                p="0.625rem"
                                                bg="white"
                                                w="2.5rem"
                                                h="2.5rem"
                                                border="1px solid #E6E7E9"
                                                borderRadius="0.625rem"
                                                onClick={()=> openCalendar.current?.toggleCalendar()}
                                            >
                                                <Image w="1.125rem" src="/assets/imgs/calendar.png" />                  
                                            </Box>
                                            <DateRangeIndex ref={openCalendar} 
                                             onDateChange={(selectedDateValue) =>
                                                handlerangedate(selectedDateValue, 'date', activeFilter)
                                              }
                                             />
                                            <PrimaryButton
                                                fontSize="0.875rem"
                                                variant="mdBtn"
                                                buttonText="Download report"
                                                btnIcon="/assets/svgs/download-white.svg"
                                                btnStyle={{w: "100%"}}
                                                onClick={()=> downloadReport.current.openModal()}
                                            />
                                        </Box>
                                    </Box>
                                    {/* filter section end */}

                                    {/* charts section start */}
                                    <Flex w="100%" flexWrap="wrap" gap="2rem" p="1.5rem">
                                    {data && data.resultpatientturnup.length > 0 ? (
                                        <Box w="calc(50% - 2rem)">
                                            <Box p="1rem" bg="white" border="1px solid #E6E7E9" borderRadius="0.625rem">
                                                <Box p="1.25rem 1rem" display="flex" justifyContent="space-between">
                                                    <Text fontSize="1rem" fontWeight="500">Patient turn up</Text>
                                                </Box>
                                                <PatientTurnUpChart 
                                                data={data.resultpatientturnup}
                                                height={"344px"}
                                                />  
                                                
                                            </Box>
                                        </Box>
                                    ) : (
                                        <Box w="calc(50% - 2rem)">
                                        <Box bg="white" border="1px solid #E6E7E9" borderRadius="0.625rem">
                                            <Box p="1.25rem 1rem" display="flex" justifyContent="space-between">
                                                <Text fontSize="1rem" fontWeight="500">Patient turn up</Text>
                                                <Text fontSize="0.875rem" fontWeight="500" color="#6A707D">No data available</Text>
                                            </Box>

                                            <Box>
                                                <Image w="100%" borderRadius="0.625rem" src='/assets/imgs/no-data-chart.png' />
                                            </Box>
                                        </Box>
                                    </Box>
                                    )}
                                    {data && data.resultmedicinesprescribed.length > 0 ? (
                                        <Box w="calc(50% - 2rem)">
                                            <Box p="1rem" bg="white" border="1px solid #E6E7E9" borderRadius="0.625rem">
                                                <Box p="1.25rem 1rem" display="flex" justifyContent="space-between">
                                                    <Text fontSize="1rem" fontWeight="500">Medicines Prescribed</Text>
                                                </Box>
                                             <MedicineChart data={data.resultmedicinesprescribed} height={"344px"}/>
                                            </Box>
                                        </Box>
                                    ) : (
                                        <Box w="calc(50% - 2rem)">
                                        <Box bg="white" border="1px solid #E6E7E9" borderRadius="0.625rem">
                                            <Box p="1.25rem 1rem" display="flex" justifyContent="space-between">
                                                <Text fontSize="1rem" fontWeight="500">Medicines Prescribed</Text>
                                                <Text fontSize="0.875rem" fontWeight="500" color="#6A707D">No data available</Text>
                                            </Box>

                                            <Box>
                                                <Image w="100%" borderRadius="0.625rem" src='/assets/imgs/no-data-chart.png' />
                                            </Box>
                                        </Box>
                                    </Box>
                                    )}
                                    {/* Patient Time Chart */}
                                     {data && data.resultpatienttime.length > 0 ? (
                                        <Box w="calc(50% - 2rem)">
                                            <Box p="1rem" bg="white" border="1px solid #E6E7E9" borderRadius="0.625rem">
                                                <Box p="1.25rem 1rem" display="flex" justifyContent="space-between">
                                                    <Text fontSize="1rem" fontWeight="500">Patient time</Text>
                                                </Box>
                                            <PatientTimeChart data={data.resultpatienttime} height={"344px"}/>
                                              
                                            </Box>
                                        </Box>
                                     ):(
                                        <Box w="calc(50% - 2rem)">
                                        <Box bg="white" border="1px solid #E6E7E9" borderRadius="0.625rem">
                                            <Box p="1.25rem 1rem" display="flex" justifyContent="space-between">
                                                <Text fontSize="1rem" fontWeight="500">Patient time</Text>
                                                <Text fontSize="0.875rem" fontWeight="500" color="#6A707D">No data available</Text>
                                            </Box>

                                            <Box>
                                                <Image w="100%" borderRadius="0.625rem" src='/assets/imgs/no-data-chart.png' />
                                            </Box>
                                        </Box>
                                    </Box>
                                     )}
                                      {/* Appointments Chart */}
                                      {data && data.resultappointments.length > 0 ? (
                                        <Box w="calc(50% - 2rem)">
                                            <Box p="1rem" bg="white" border="1px solid #E6E7E9" borderRadius="0.625rem">
                                                <Box p="1.25rem 1rem" display="flex" justifyContent="space-between">
                                                    <Text fontSize="1rem" fontWeight="500">Appointments</Text>
                                                </Box>

                                                <AppointmentsChart data={data.resultappointments} height={"344px"}/>
                                            </Box>
                                        </Box>
                                      ):(
                                        <Box w="calc(50% - 2rem)">
                                            <Box bg="white" border="1px solid #E6E7E9" borderRadius="0.625rem">
                                                <Box p="1.25rem 1rem" display="flex" justifyContent="space-between">
                                                    <Text fontSize="1rem" fontWeight="500">Appointments</Text>
                                                    <Text fontSize="0.875rem" fontWeight="500" color="#6A707D">No data available</Text>
                                                </Box>

                                                <Box>
                                                    <Image w="100%" borderRadius="0.625rem" src='/assets/imgs/no-data-chart.png' />
                                                </Box>
                                            </Box>
                                        </Box>
                                      )}
                                    </Flex>
                                    {/* charts section end */}
                                </Box>
                                {/* footer section start */}
                                <Box display="flex" my="2rem" alignItems="center" w="100%" justifyContent="center" gap="10px">
                                    <Text fontSize="0.625rem" fontWeight="400">
                                        A PRODUCT OF
                                    </Text>
                                    <Image src="/assets/svgs/tci-logo.svg" />
                                </Box>
                                {/* footer section end */}
                            </Box>
                            {/* Main container end */}
                        </Flex>

                    </Box>
                {/* Main container start */}
                </Box>
            </Flex>
        </Box>
      ) : (
      
      // Mobile responsive
      <Box w="100%">
        <MobileNavbar />
        <MobileSecondaryNav
          header="Reports"
          handlebackarrow={false}
          bottomArea={<>
            <Box display="flex" gap="0.5rem" flexDir='row-reverse' justifyContent='end'>
              <Box
                  as="button"
                  p="0.625rem"
                  bg="white"
                  w="2.5rem"
                  h="2.5rem"
                  border="1px solid #E6E7E9"
                  borderRadius="0.625rem"
                  onClick={()=> openCalendar.current?.toggleCalendar()}
              >
                  <Image w="1.125rem" src="/assets/imgs/calendar.png" />                  
              </Box>
              <DateRangeIndex ref={openCalendar} 
                onDateChange={(selectedDateValue) =>
                  handlerangedate(selectedDateValue)
                }
                calendarStyle={{right: "-70px", transform: "scale(0.9)", top: "20px"}}
                />
              <PrimaryButton
                  fontSize="0.875rem"
                  variant="mdBtn"
                  buttonText="Download report"
                  btnIcon="/assets/svgs/download-white.svg"
                  onClick={()=> downloadReport.current.openModal()}
              />
            </Box>
          </>}
        />
        <Box w="100%" p='1rem'>
          <TabSection
              defaultIndex={1}
              tabs={["Today","Week", "Month", "Year"]}
              onClickTab={handleTabClick}
              tabBtnStyle={{minWidth: "unset", fontSize: '14px', px: "14px"}}
          />
        </Box>

        {/* Main container start */}
        <Box w="100%" bg="#fafafa" h="calc(100vh - 300px)" overflow="hidden auto">
            <Box w="100%">
                {/* charts section start */}
                <Flex w="100%" flexWrap="wrap" gap="2rem" pb="0" p="1.5rem">
                {data && data.resultpatientturnup.length > 0 ? (
                    <Box w="100%">
                        <Box p="1rem" bg="white" border="1px solid #E6E7E9" borderRadius="0.625rem">
                            <Box p="1.25rem 1rem" display="flex" justifyContent="space-between">
                                <Text fontSize="1rem" fontWeight="500">Patient turn up</Text>
                            </Box>
                            <PatientTurnUpChart 
                            data={data.resultpatientturnup}
                            height={"344px"}
                            />  
                            
                        </Box>
                    </Box>
                ) : (
                    <Box w="100%">
                    <Box bg="white" border="1px solid #E6E7E9" borderRadius="0.625rem">
                        <Box p="1.25rem 1rem" display="flex" justifyContent="space-between">
                            <Text fontSize="1rem" fontWeight="500">Patient turn up</Text>
                            <Text fontSize="0.875rem" fontWeight="500" color="#6A707D">No data available</Text>
                        </Box>

                        <Box>
                            <Image w="100%" borderRadius="0.625rem" src='/assets/imgs/no-data-chart.png' />
                        </Box>
                    </Box>
                </Box>
                )}
                {data && data.resultmedicinesprescribed.length > 0 ? (
                    <Box w="100%">
                        <Box p="1rem" bg="white" border="1px solid #E6E7E9" borderRadius="0.625rem">
                            <Box p="1.25rem 1rem" display="flex" justifyContent="space-between">
                                <Text fontSize="1rem" fontWeight="500">Medicines Prescribed</Text>
                            </Box>
                          <MedicineChart data={data.resultmedicinesprescribed} height={"344px"}/>
                        </Box>
                    </Box>
                ) : (
                    <Box w="100%">
                    <Box bg="white" border="1px solid #E6E7E9" borderRadius="0.625rem">
                        <Box p="1.25rem 1rem" display="flex" justifyContent="space-between">
                            <Text fontSize="1rem" fontWeight="500">Medicines Prescribed</Text>
                            <Text fontSize="0.875rem" fontWeight="500" color="#6A707D">No data available</Text>
                        </Box>

                        <Box>
                            <Image w="100%" borderRadius="0.625rem" src='/assets/imgs/no-data-chart.png' />
                        </Box>
                    </Box>
                </Box>
                )}
                {/* Patient Time Chart */}
                  {data && data.resultpatienttime.length > 0 ? (
                    <Box w="100%">
                        <Box p="1rem" bg="white" border="1px solid #E6E7E9" borderRadius="0.625rem">
                            <Box p="1.25rem 1rem" display="flex" justifyContent="space-between">
                                <Text fontSize="1rem" fontWeight="500">Patient time</Text>
                            </Box>
                        <PatientTimeChart data={data.resultpatienttime} height={"344px"}/>
                          
                        </Box>
                    </Box>
                  ):(
                    <Box w="100%">
                    <Box bg="white" border="1px solid #E6E7E9" borderRadius="0.625rem">
                        <Box p="1.25rem 1rem" display="flex" justifyContent="space-between">
                            <Text fontSize="1rem" fontWeight="500">Patient time</Text>
                            <Text fontSize="0.875rem" fontWeight="500" color="#6A707D">No data available</Text>
                        </Box>

                        <Box>
                            <Image w="100%" borderRadius="0.625rem" src='/assets/imgs/no-data-chart.png' />
                        </Box>
                    </Box>
                </Box>
                  )}
                  {/* Appointments Chart */}
                  {data && data.resultappointments.length > 0 ? (
                    <Box w="100%">
                        <Box p="1rem" bg="white" border="1px solid #E6E7E9" borderRadius="0.625rem">
                            <Box p="1.25rem 1rem" display="flex" justifyContent="space-between">
                                <Text fontSize="1rem" fontWeight="500">Appointments</Text>
                            </Box>

                            <AppointmentsChart data={data.resultappointments} height={"344px"}/>
                        </Box>
                    </Box>
                  ):(
                    <Box w="100%">
                        <Box bg="white" border="1px solid #E6E7E9" borderRadius="0.625rem">
                            <Box p="1.25rem 1rem" display="flex" justifyContent="space-between">
                                <Text fontSize="1rem" fontWeight="500">Appointments</Text>
                                <Text fontSize="0.875rem" fontWeight="500" color="#6A707D">No data available</Text>
                            </Box>

                            <Box>
                                <Image w="100%" borderRadius="0.625rem" src='/assets/imgs/no-data-chart.png' />
                            </Box>
                        </Box>
                    </Box>
                  )}
                </Flex>
                {/* charts section end */}
            </Box>
            {/* footer section start */}
            <Box display="flex" mb="2rem" alignItems="center" w="100%" justifyContent="center" gap="10px">
                <Text fontSize="0.625rem" fontWeight="400">
                    A PRODUCT OF
                </Text>
                <Image src="/assets/svgs/tci-logo.svg" />
            </Box>
            {/* footer section end */}
        </Box>
        {/* Main container end */}
      </Box>
      )}

        <DynamicModal
            ref={downloadReport}
            modalHeader="Download report"
            modalWidth="31rem"
            modalBody={
            <>
            {downloadExcel && <ExcelDownload data={reportdata} onDownloadComplete={handleDownloadComplete}/>}
                {/* filter by days start */}
                <Box display="flex" gap="0.5rem" flexWrap="wrap">
                    <Box as='button' onClick={() => setActiveTabExcel('day')} bg={activeTabexcel == "day" ? "#0B0B0D": "white"} color={activeTabexcel == "day" ? "white" : "#0B0B0D"} p="0.5rem 1rem"  border="1px solid #E6E7E9" borderRadius="full">Today</Box>
                    <Box as='button' onClick={() => setActiveTabExcel('week')} bg={activeTabexcel == "week" ? "#0B0B0D": "white"} color={activeTabexcel == "week" ? "white" : "#0B0B0D"} p="0.5rem 1rem" border="1px solid #E6E7E9"  borderRadius="full">This week</Box>
                    <Box as='button' onClick={() => setActiveTabExcel('month')} bg={activeTabexcel == "month" ? "#0B0B0D": "white"} color={activeTabexcel == "month" ? "white" : "#0B0B0D"} p="0.5rem 1rem"  border="1px solid #E6E7E9" borderRadius="full">This month</Box>
                    <Box as='button' onClick={() => setActiveTabExcel('lastmonth')} bg={activeTabexcel == "lastmonth" ? "#0B0B0D": "white"} color={activeTabexcel == "lastmonth" ? "white" : "#0B0B0D"} p="0.5rem 1rem"  border="1px solid #E6E7E9" borderRadius="full">Last month</Box>
                    <Box as='button' onClick={() => setActiveTabExcel('thisyear')} bg={activeTabexcel == "thisyear" ? "#0B0B0D": "white"} color={activeTabexcel == "thisyear" ? "white" : "#0B0B0D"} p="0.5rem 1rem"  border="1px solid #E6E7E9" borderRadius="full">This year</Box>
                    <Box as='button' onClick={() => setActiveTabExcel('lastyear')} bg={activeTabexcel == "lastyear" ? "#0B0B0D": "white"} color={activeTabexcel == "lastyear" ? "white" : "#0B0B0D"} p="0.5rem 1rem"  border="1px solid #E6E7E9" borderRadius="full">Last year</Box>
                </Box>
                {/* filter by days end */}

                {/* download progress start */}
                {/* uploading section start */}
                {/* <Flex flexDir="column" gap="15px" w="100%">
                    <Flex bg="white" flexDir="column" w="100%" p="16px" border="1px solid #E6E7E9" borderRadius="12px">
                        <Box display="flex" gap="15px" w="100%">
                            <Image src="/assets/svgs/pdf-icon.svg" />
                            <Box w="100%">
                                <Box display="flex" justifyContent="space-between" h="auto" w="100%">
                                    <Text fontSize="14px" fontWeight="500">degree_A0587.pdf</Text>
                                    <Box as="button"><Image w="13px" h="13px" src="/assets/imgs/close.png" /></Box>
                                </Box>
                                <Box display="flex" gap="3px">
                                    <Text fontSize="12px" fontWeight="400" color="#6A707D">60 KB of 120 KB</Text>
                                    <Image w="20px" h="auto" src="/assets/svgs/file-upload-spinner.svg" />
                                    <Text fontSize="12px" fontWeight="400" color="#384052">Generating...</Text>
                                </Box>
                            </Box>
                        </Box>
                        <Box h="6px" mt="15px" w="100%" borderRadius="full" bg="#E6E7E9">
                            <Box bg="#1C4EC5" w="35%" h="100%" borderRadius="full"></Box>
                        </Box>
                    </Flex>
                </Flex> */}
                {/* uploading section end */}

                {/* uploaded section start */}
                {/* <Flex flexDir="column" gap="15px" w="100%">
                    <Flex bg="white" flexDir="column" w="100%" p="16px" border="1px solid #E6E7E9" borderRadius="12px">
                        <Box display="flex" gap="15px" w="100%">
                            <Image src="/assets/svgs/pdf-icon.svg" />
                            <Box w="100%">
                                <Box display="flex" justifyContent="space-between" h="auto" w="100%">
                                    <Text fontSize="14px" fontWeight="500">degree_A0587.pdf</Text>
                                    <Box as="button"><Image w="16px" h="18px" src="/assets/imgs/delete.png" /></Box>
                                </Box>
                                <Box display="flex" gap="5px">
                                    <Text fontSize="12px" fontWeight="400" color="#6A707D">120 KB</Text>
                                    <Image w="16px" h="auto" ml="5px" src="/assets/svgs/uploaded-success-icon-green.svg" />
                                    <Text fontSize="12px" fontWeight="400" color="#384052">Ready to download</Text>
                                </Box>
                            </Box>
                        </Box>
                    </Flex>
                </Flex> */}
                {/* uploaded section end */}
                {/* download progress end */}

                {/* <Box display="flex" gap="0.5rem" mt="1rem">
                    <Image src='/assets/svgs/info-icon.svg' />
                    <Text fontSize="0.75rem" fontWeight="500">We'll notify you when your report is ready, you can close the popup.</Text>
                    
                    <Image src='/assets/svgs/uploaded-failed-icon-red.svg' />
                    <Text fontSize="0.75rem" fontWeight="500" color="#CA3A31">Report generation failed, please try again</Text>
                </Box> */}
            </>
            }
            modalFooterBtn={
            <>
                <PrimaryButton buttonText="Download" onClick={handleClick}/>
            </>
            }
        />
    </>
  )
}

export default ReportIndex