import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle  } from "react";
import {
  Box,
  Text,
  RadioGroup,
  Stack,
  Heading,
  Checkbox,
  Divider,
} from "@chakra-ui/react";
import ModalRight from "../../modal-right";
import SearchBar from "../../search-bar";
import PrimaryButton from "../../primary-button";
import PrimaryInput from "../../primary-input";
import DatePicker from "../../date-picker";
import {
  addMedicalConditions,
  getmedicalcondition,
} from "../../../middleware/services/messages";
import { format } from "date-fns";
import { actionAccessMain } from "../../../auth";

const NoMedicalConditionDataScreen = forwardRef(
  (
    {
      width,
      height,
      patientId,
      showErrorToast,
      showSuccessToast,
      showLoader,
      dismissLoader,
      fetchPatientHealthData,
    },
    ref
  ) => {


    const openFromStartVisitOverview = () => {
      console.log("Function called!");
      openAddConditionModal()
    };
  
    useImperativeHandle(ref, () => ({
      openFromStartVisitOverview
    }));


    const [actionAccessPatients, setActionAccessPatients] = useState(null);
    useEffect(() => {
      setActionAccessPatients(actionAccessMain(4));
    }, []);



    const [searchValue, setSearchValue] = useState("");

    var [conditionsList, setConditionsList] = useState(null);
    const [selectedConditions, setSelectedConditions] = useState([]);
    const [selectedDates, setSelectedDates] = useState({});
    const [typedCondition, setTypedCondition] = useState("");

    const addCondition = React.useRef();
    const addCondition2 = React.useRef();

    const fetchMedicalConditionList = async () => {
      try {
        const result = await getmedicalcondition({
          type: "healthcondition",
          patientid: patientId,
        });
        if (result.output.result == "success") {
          setConditionsList(result.output.data);
        } else {
          showErrorToast("Something went wrong!");
        }
      } catch (error) {
        console.error("Error", error);
      }
    };

    const handleCheckboxChange = (condition, category) => {
      const isSelected = selectedConditions.some(
        (item) => item.condition_cd === condition.condition_cd
      );

      if (isSelected) {
        setSelectedConditions((prevConditions) =>
          prevConditions.filter(
            (item) => item.condition_cd !== condition.condition_cd
          )
        );
      } else {
        setSelectedConditions((prevConditions) => [
          ...prevConditions,
          { ...condition, category },
        ]);
      }
    };

    const handleDateChange = (conditionCd, date) => {
      const formattedDate = format(date, "yyyy-MM-dd");
      setSelectedDates((prevDates) => ({
        ...prevDates,
        [conditionCd]: formattedDate,
      }));
    };

    const handleCondition = (e) => {
      const inputText = e.target.value;
      const maxLength = 80;

      if (inputText.length <= maxLength) {
        setTypedCondition(inputText);
      }
    };

    const handleAlphaNumericDotAndSpace = (value) => {
      return value.replace(/[^0-9a-zA-Z\s.]/g, "");
    };

    const openAddConditionModal = () => {
      // if (conditionsList !== null) {
      fetchMedicalConditionList();
      // }
      setSearchValue("");
      setSelectedConditions([]);
      setSelectedDates({});
      setTypedCondition("");
      addCondition.current.openRight();
    };

    //-------------saving data
    var inputForAdd = {
      medicalcondition: selectedConditions.map((condition) => ({
        conditiontype_cd: condition?.category?.conditiontype_cd,
        condition_cd: condition.condition_cd,
        nomenclatureid: condition.condition_cd,
        conditionidentifieddate: selectedDates[condition.condition_cd] || "",
        condition: condition.condition,
        conditiontype: condition.category.conditiontype,
      })),
      patientid: patientId,
    };

    const AddMedicalConditions = () => {
      if (selectedConditions.length === 0 && typedCondition == "") {
        showErrorToast("Please Select a Condition to Continue");
      } else {
        if (typedCondition !== "") {
          const newSelectedCondition = {
            category: {
              conditiontype: "other",
              conditiontype_cd: null,
            },
            condition: typedCondition,
            condition_cd: null,
          };

          setSelectedConditions((prevConditions) => {
            // Filter out any existing condition with the same type
            const filteredConditions = prevConditions.filter(
              (item) => item.category.conditiontype !== "other"
            );
            // Add the new condition
            return [...filteredConditions, newSelectedCondition];
          });
        }

        setSelectedDates({});
        addCondition2.current.openRight();
      }
    };
    const AddMedicalConditionsCall = () => {
      const addConditions = async () => {
        showLoader();
        try {
          console.log(inputForAdd, " inputForAdd");
          const result = await addMedicalConditions(inputForAdd);
          console.log(result);
          if (result.output.result == "success") {
            showSuccessToast("Condition added Successfully!");
            setTimeout(() => {
              if (addCondition2?.current) {
                addCondition2.current.closeRight();
              }
              if (addCondition?.current) {
                addCondition.current.closeRight();
              }
              dismissLoader();
            }, 700);
            fetchPatientHealthData();
          } else {
            showErrorToast("Something went wrong!");
          }
        } catch (error) {
          console.error("Error in DashboardIndex:", error);
        }
      };
      const isDateMissing = selectedConditions.some(
        (condition) => !selectedDates[condition.condition_cd]
      );

      if (isDateMissing) {
        showErrorToast("Please Select the Date");
        dismissLoader();
        return false;
      } else {
        addConditions();
      }
    };

    return (
      <Box>
        <Box
          bg="#fcfcfd"
          width={width}
          height={height}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <PrimaryButton
            fontSize="14px"
            variant="mdBtn"
            btnIcon="/assets/imgs/add.png"
            buttonText="Add Condition"
            onClick={openAddConditionModal}
            isDisabled={actionAccessPatients?.create ? false : true}
          />
          <Text textAlign="center" position="absolute" top="50%" left="40%">
            No Medical Condition Data Available
          </Text>
        </Box>
        <>
          <ModalRight
            ref={addCondition}
            modalWidth="360px"
            header="Add condition"
            body={
              <>
                <SearchBar
                  passInputPlace="Search condition"
                  onChange={(e) =>
                    setSearchValue(
                      handleAlphaNumericDotAndSpace(e.target.value)
                    )
                  }
                  searchValue={searchValue}
                />
                <RadioGroup>
                  <Stack direction="column" gap="0">
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      borderBottom="1px solid #e2e8f0"
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        w="100%"
                      >
                        <Stack direction="column" w="100%" gap="0">
                          {conditionsList && conditionsList.length > 0 ? (
                            <>
                              {conditionsList.map((category) => {
                                const filteredConditions = category.data.filter(
                                  (condition) =>
                                    condition.condition
                                      .toLowerCase()
                                      .includes(searchValue.toLowerCase())
                                );

                                return (
                                  <React.Fragment
                                    key={category.conditiontype_cd}
                                  >
                                    {filteredConditions.length > 0 && (
                                      <>
                                        <Heading
                                          as="h2"
                                          fontSize="12px"
                                          pl="5px"
                                          textTransform="uppercase"
                                          fontWeight="600"
                                          my="10px"
                                        >
                                          {category.conditiontype}
                                        </Heading>
                                        {filteredConditions.map((condition) => (
                                          <label key={condition.condition_cd}>
                                            <Box
                                              key={condition.condition_cd}
                                              display="flex"
                                              justifyContent="space-between"
                                              borderBottom="1px solid #e2e8f0"
                                              p="15px"
                                              pl="5px"
                                              minH="50px"
                                              width="100%"
                                              sx={{
                                                _hover: {
                                                  border: "2px solid #384052",
                                                  borderRadius: "10px",
                                                },
                                              }}
                                            >
                                              <Box
                                                display="flex"
                                                alignItems="center"
                                              >
                                                <Text
                                                  fontSize="16px"
                                                  lineHeight="normal"
                                                  fontWeight="600"
                                                >
                                                  {condition.condition}
                                                </Text>
                                              </Box>
                                              <Box
                                                display="flex"
                                                justifyContent="end"
                                              >
                                                <Checkbox
                                                  colorScheme="green"
                                                  sx={{
                                                    ".chakra-checkbox__control":
                                                      {
                                                        width: "20px",
                                                        height: "20px",
                                                        borderRadius: "5px",
                                                      },
                                                  }}
                                                  onChange={() =>
                                                    handleCheckboxChange(
                                                      condition,
                                                      category
                                                    )
                                                  }
                                                  isChecked={selectedConditions.some(
                                                    (item) =>
                                                      item.condition_cd ===
                                                      condition.condition_cd
                                                  )}
                                                ></Checkbox>
                                              </Box>
                                            </Box>
                                          </label>
                                        ))}
                                      </>
                                    )}
                                  </React.Fragment>
                                );
                              })}
                              {/* Display "No matching conditions found" only if no conditions were found in any category */}
                              {conditionsList.every((category) =>
                                category.data.every(
                                  (condition) =>
                                    !condition.condition
                                      .toLowerCase()
                                      .includes(searchValue.toLowerCase())
                                )
                              ) && (
                                <Box
                                  p="15px"
                                  fontSize="16px"
                                  fontWeight="600"
                                  textAlign="center"
                                >
                                  No matching conditions found!
                                </Box>
                              )}
                              <Divider />
                              <br />
                              <Heading
                                as="h2"
                                fontSize="12px"
                                pl="5px"
                                textTransform="uppercase"
                                fontWeight="600"
                                my="10px"
                              >
                                Other Condition
                              </Heading>
                              <PrimaryInput
                                inputPlace="Condition"
                                variant="bothSide"
                                onChange={handleCondition}
                                inputValue={typedCondition}
                              />
                              <br />
                            </>
                          ) : (
                            <p>Loading...</p>
                          )}
                        </Stack>
                      </Box>
                    </Box>
                  </Stack>
                </RadioGroup>
                <ModalRight
                  ref={addCondition2}
                  header="Add condition"
                  body={
                    <>
                      <Box display="flex" flexDir="column" gap="20px">
                        {selectedConditions.map((condition, index) => (
                          <Box key={index}>
                            <Text fontSize="16px" py="10px" fontWeight="600">
                              {condition.condition}
                            </Text>

                            <DatePicker
                              disableFutureDates={true}
                              onDateChange={(e) =>
                                handleDateChange(condition.condition_cd, e)
                              }
                              placeholderProp="Select a Date*"
                              calendarStlye={{
                                transform: "scale(0.9)",
                                right: "-20px",
                                top: "30px",
                              }}
                            />
                          </Box>
                        ))}
                      </Box>
                    </>
                  }
                  footerActions={
                    <>
                      <Box
                        w="100%"
                        display="flex"
                        flexDirection="row"
                        gap="15px"
                      >
                        <PrimaryButton
                          buttonText="Add Condition"
                          onClick={AddMedicalConditionsCall}
                          isDisabled={
                            actionAccessPatients?.create ? false : true
                          }
                        />
                      </Box>
                    </>
                  }
                />
              </>
            }
            footerActions={
              <>
                <Box w="100%" display="flex" flexDirection="row" gap="15px">
                  <PrimaryButton
                    buttonText="Continue"
                    isDisabled={false}
                    onClick={AddMedicalConditions}
                  />
                </Box>
              </>
            }
          />
        </>
      </Box>
    );
  }
);

export default NoMedicalConditionDataScreen;
