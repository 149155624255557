import React, { useRef, useEffect, useState } from "react";
import Calendar from "../../components/calendar";
import {
  mapAppointmentDetails,
  enhanceAndSortAppointmentDetails,
  getCurrentMonthDates,
  parseDateString,
  getStartAndEndDate,
} from "../../components/calendar/helper";
import MiniSideBar from "../../components/mini-sidebar";
import {
  Box,
  Divider,
  Flex,
  Image,
  Spacer,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import SecondaryNav from "../../components/seconday-nav";
import SearchBar from "../../components/search-bar";
import TabSection from "../../components/tabs";
import PrimaryButton from "../../components/primary-button";
import PrimaryButtonIcon from "../../components/primary-button-icon";
import { COLORS } from "../../components/styles/colors";
import { getPatientAppointmentListForDoctor } from "../../middleware/services/visits";
import PatientVisitCard from "../../components/patient-visit-card";
import Loader from "../../components/loader/loader";
import useLoader from "../../components/loader/loaderstates";
import { useScreenType } from "../../auth";
import MobileNavbar from "../../components/mobile-navbar";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";

const VisitsIndex = () => {
  const { isLoading, showLoader, dismissLoader } = useLoader();

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleTabClick = (index) => {
    setSelectedTabIndex(index);
  };

  // To be used by side bar
  const [upcomingAppointments, setUpcomingAppointments] = useState([]);
  const [pastAppointments, setPastAppointments] = useState([]);
  const [completedAppointments, setCompletedAppointments] = useState([]);
  const [totalUpcomingAppointments, setTotalUpcomingAppointments] = useState(
    []
  );
  const [search, setSearch] = useState("");

  // console.log("Start Date:", formattedStartDate);
  // console.log("End Date:", formattedEndDate);

  const [formattedStartDate, formattedEndDate] = getCurrentMonthDates(); // format of YYYY-MM-DD
  // to be used by calendar
  const [calendarAppointments, setCalendarAppointments] = useState([]);
  const [calendarStartDate, setCalendarStartDate] =
    useState(formattedStartDate);
  const [calendarEndDate, setCalendarEndDate] = useState(formattedEndDate);
  const screenType = useScreenType();
  const handleCalendarTitleChange = (title) => {
    // console.log(title);
    // console.log(parseDateString(title));
    const { calendarStart, calendarEnd } = getStartAndEndDate(title);

    // Update state for calendar start date
    setCalendarStartDate(calendarStart);

    // Update state for calendar end date
    setCalendarEndDate(calendarEnd);
  };

  // for calendar
  const fetchCalendarData = async (start, end) => {
    // console.log("Calendar Data Fetched");
    // console.log(start, end);
    showLoader();
    try {
      const upcomingCalendarResult = await getPatientAppointmentListForDoctor({
        appointmentstatus: 1,
        startdate: start,
        enddate: end,
      });

      const pastCalendarResult = await getPatientAppointmentListForDoctor({
        appointmentstatus: 2,
        startdate: start,
        enddate: end,
      });

      const combinedAppointments = mapAppointmentDetails([
        ...(upcomingCalendarResult || []),
        ...(pastCalendarResult || []),
      ]);

      setCalendarAppointments(combinedAppointments);
    } catch (error) {
      console.error("Error fetching calendar data:", error);
    } finally {
      dismissLoader();
    }
  };

  const fetchSidePanelData = async (start, end) => {
    // console.log("Side Panel Data Fetched");
    showLoader();
    try {
      const upcomingResult = await getPatientAppointmentListForDoctor({
        appointmentstatus: 1,
        startdate: start,
        enddate: end,
      });
      setUpcomingAppointments(upcomingResult || []);

      const pastResult = await getPatientAppointmentListForDoctor({
        appointmentstatus: 2,
        startdate: start,
        enddate: end,
      });
      setPastAppointments(pastResult || []);
    } catch (error) {
      console.error("Error fetching side panel data:", error);
    } finally {
      dismissLoader();
    }
  };

  useEffect(() => {
    fetchCalendarData(calendarStartDate, calendarEndDate);
  }, [calendarStartDate, calendarEndDate]);

  useEffect(() => {
    fetchSidePanelData(calendarStartDate, calendarEndDate);
  }, [calendarStartDate, calendarEndDate]);

  const refreshPageContent = () => {
    fetchCalendarData(calendarStartDate, calendarEndDate);
    fetchSidePanelData(calendarStartDate, calendarEndDate);
  };

  // useEffect(() => {
  //   // Check if cancelledAppointments array is empty
  //   if (pastAppointments.length === 0) {
  //     setTotalUpcomingAppointments(upcomingAppointments);
  //   } else {
  //     const combinedAppointments = upcomingAppointments.concat(
  //       pastAppointments
  //     );
  //     setTotalUpcomingAppointments(combinedAppointments);
  //   }
  // }, [upcomingAppointments, pastAppointments]);

  return (
    <>
      {isLoading && <Loader />}
      {screenType == "web" ? (
        <Box w="100%">
          <Flex>
            <Box w="6rem">
              <MiniSideBar />
            </Box>
            <Box w="100%">
              <SecondaryNav customHeader="Visits" />

              {/* Main container start */}
              <Flex w="calc(100vw - 6rem)">
                <Box
                  // p="1.5rem 8.25rem"
                  display="flex"
                  h="calc(100vh - 4rem)"
                  // h="100vh"
                  overflow="hidden auto"
                  overflowY={"hidden"}
                  w="100%"
                >
                  {/* Left area start */}

                  <Box
                    w="23rem"
                    h="calc(100vh - 6rem)"
                    borderRight="1px solid #E6E7E9"
                  >
                    <Box p="1.5rem" display="flex" flexDir="column" gap="1rem">
                      <SearchBar
                        passInputPlace="Search"
                        onChange={(event) => setSearch(event.target.value)}
                      />
                      <TabSection
                        tabs={["Upcoming", "Past"]}
                        onClickTab={handleTabClick}
                        defaultIndex={selectedTabIndex}
                      />
                    </Box>

                    <Box
                      bg="#f9f9fa"
                      h="calc(100vh - 14rem)"
                      overflow="hidden auto"
                      p="1.5rem"
                    >
                      {selectedTabIndex === 0 && (
                        // Render content for the "Upcoming" tab
                        <Box>
                          {enhanceAndSortAppointmentDetails(
                            upcomingAppointments
                          ).some((appointment) => appointment.today) && (
                            <Box>
                              <Text
                                fontSize="0.75rem"
                                mb="1rem"
                                letterSpacing="0.1rem"
                                fontWeight="600"
                                textTransform="uppercase"
                                color="#061027"
                              >
                                Today
                              </Text>
                              {/* Render patient cards for today */}
                              <Box display="flex" flexDir="column" gap="1rem">
                                {enhanceAndSortAppointmentDetails(
                                  upcomingAppointments
                                )
                                  .filter((appointment) => appointment.today)
                                  .filter((appointment) =>
                                    appointment.patientname
                                      .toLowerCase()
                                      .includes(search.toLowerCase())
                                  ).length === 0 && search.trim() !== "" ? (
                                  <Box textAlign="center" padding="1rem">
                                    No appointments available
                                  </Box>
                                ) : (
                                  enhanceAndSortAppointmentDetails(
                                    upcomingAppointments
                                  )
                                    .filter((appointment) => appointment.today)
                                    .filter((appointment) =>
                                      appointment.patientname
                                        .toLowerCase()
                                        .includes(search.toLowerCase())
                                    )
                                    .map((appointment) => (
                                      <PatientVisitCard
                                        key={appointment.appointmentid}
                                        userImage={
                                          appointment.patientimage ? 
                                (appointment.patientimage.startsWith('http') ? appointment.patientimage : appointment.imagepath + appointment.patientimage) 
                                : '/assets/imgs/no-image.png'
                                        }
                                        // userImage={
                                        //   appointment.imagepath &&
                                        //   appointment.patientimage
                                        //     ? appointment.imagepath +
                                        //       appointment.patientimage
                                        //     : null
                                        // }
                                        isUpcoming={true}
                                        userName={appointment.patientname}
                                        visitDate={appointment.visitDate}
                                        visitTime={appointment.visitTime}
                                        issue={appointment.chiefcomplaint}
                                        borderColor={appointment.color}
                                        patientId={appointment.patientid}
                                        refreshPageContent={refreshPageContent}
                                        appointmentId={
                                          appointment.appointmentid
                                        }
                                        appointmentStatus={
                                          appointment.appointmentstatus
                                        }
                                        visitStatus={
                                          appointment.appointmentcurrentstatus
                                        }
                                        statusName={
                                          appointment.appointmentstatusname
                                        }
                                      />
                                    ))
                                )}
                              </Box>
                            </Box>
                          )}
                          {enhanceAndSortAppointmentDetails(
                            upcomingAppointments
                          ).some((appointment) => appointment.tomorrow) && (
                            <Box>
                              <Text
                                fontSize="0.75rem"
                                my="1rem"
                                letterSpacing="0.1rem"
                                fontWeight="600"
                                textTransform="uppercase"
                                color="#061027"
                              >
                                Tomorrow
                              </Text>
                              {/* Render patient cards for tomorrow */}
                              <Box display="flex" flexDir="column" gap="1rem">
                                {enhanceAndSortAppointmentDetails(
                                  upcomingAppointments
                                )
                                  .filter((appointment) => appointment.tomorrow)
                                  .filter((appointment) =>
                                    appointment.patientname
                                      .toLowerCase()
                                      .includes(search.toLowerCase())
                                  ).length === 0 && search.trim() !== "" ? (
                                  <Box textAlign="center" padding="1rem">
                                    No appointments available
                                  </Box>
                                ) : (
                                  enhanceAndSortAppointmentDetails(
                                    upcomingAppointments
                                  )
                                    .filter(
                                      (appointment) => appointment.tomorrow
                                    )
                                    .filter((appointment) =>
                                      appointment.patientname
                                        .toLowerCase()
                                        .includes(search.toLowerCase())
                                    )
                                    .map((appointment) => (
                                      <PatientVisitCard
                                        key={appointment.appointmentid}
                                        userImage={
                                          appointment.imagepath &&
                                          appointment.patientimage
                                            ? appointment.imagepath +
                                              appointment.patientimage
                                            : null
                                        }
                                        userName={appointment.patientname}
                                        visitDate={appointment.visitDate}
                                        visitTime={appointment.visitTime}
                                        issue={appointment.chiefcomplaint}
                                        borderColor={appointment.color}
                                        appointmentId={
                                          appointment.appointmentid
                                        }
                                        patientId={appointment.patientid}
                                        refreshPageContent={refreshPageContent}
                                        appointmentStatus={
                                          appointment.appointmentstatus
                                        }
                                        visitStatus={
                                          appointment.appointmentcurrentstatus
                                        }
                                        statusName={
                                          appointment.appointmentstatusname
                                        }
                                      />
                                    ))
                                )}
                              </Box>
                            </Box>
                          )}
                          {/* Render patient cards for this month */}
                          <Box>
                            <Text
                              fontSize="0.75rem"
                              my="1rem"
                              letterSpacing="0.1rem"
                              fontWeight="600"
                              textTransform="uppercase"
                              color="#061027"
                            >
                              This Month
                            </Text>
                            <Box display="flex" flexDir="column" gap="1rem">
                              {enhanceAndSortAppointmentDetails(
                                upcomingAppointments
                              )
                                .filter(
                                  (appointment) =>
                                    !appointment.today && !appointment.tomorrow
                                )
                                .filter((appointment) =>
                                  appointment.patientname
                                    .toLowerCase()
                                    .includes(search.toLowerCase())
                                ).length === 0 && search.trim() !== "" ? (
                                <Box textAlign="center" padding="1rem">
                                  No appointments available
                                </Box>
                              ) : (
                                enhanceAndSortAppointmentDetails(
                                  upcomingAppointments
                                )
                                  .filter(
                                    (appointment) =>
                                      !appointment.today &&
                                      !appointment.tomorrow
                                  )
                                  .filter((appointment) =>
                                    appointment.patientname
                                      .toLowerCase()
                                      .includes(search.toLowerCase())
                                  )
                                  .map((appointment) => (
                                    <PatientVisitCard
                                      key={appointment.appointmentid}
                                      userImage={
                                        appointment.imagepath &&
                                        appointment.patientimage
                                          ? appointment.imagepath +
                                            appointment.patientimage
                                          : null
                                      }
                                      userName={appointment.patientname}
                                      visitDate={appointment.visitDate}
                                      visitTime={appointment.visitTime}
                                      issue={appointment.chiefcomplaint}
                                      borderColor={appointment.color}
                                      appointmentId={appointment.appointmentid}
                                      patientId={appointment.patientid}
                                      refreshPageContent={refreshPageContent}
                                      appointmentStatus={
                                        appointment.appointmentstatus
                                      }
                                      visitStatus={
                                        appointment.appointmentcurrentstatus
                                      }
                                      statusName={
                                        appointment.appointmentstatusname
                                      }
                                    />
                                  ))
                              )}
                            </Box>
                          </Box>
                        </Box>
                      )}

                      {/* Render content for the "Completed" tab */}
                      {selectedTabIndex === 1 && (
                        <Box>
                          <Box>
                            {/* <Text
                            fontSize="0.75rem"
                            mb="1rem"
                            letterSpacing="0.1rem"
                            fontWeight="600"
                            textTransform="uppercase"
                            color="#061027"
                          >
                            Completed
                          </Text> */}
                            {/* Render completed patient cards */}
                            <Box display="flex" flexDir="column" gap="1rem">
                              {enhanceAndSortAppointmentDetails(
                                pastAppointments,
                                true
                              ) // reverse sort
                                .filter((appointment) =>
                                  appointment.patientname
                                    .toLowerCase()
                                    .includes(search.toLowerCase())
                                ).length === 0 && search.trim() !== "" ? (
                                <Box textAlign="center" padding="1rem">
                                  No appointments available
                                </Box>
                              ) : (
                                enhanceAndSortAppointmentDetails(
                                  pastAppointments,
                                  true
                                ) // reverse sort
                                  .filter((appointment) =>
                                    appointment.patientname
                                      .toLowerCase()
                                      .includes(search.toLowerCase())
                                  )
                                  .map((appointment) => (
                                    <PatientVisitCard
                                      key={appointment.appointmentid}
                                      userImage={
                                        appointment.imagepath &&
                                        appointment.patientimage
                                          ? appointment.imagepath +
                                            appointment.patientimage
                                          : null
                                      }
                                      userName={appointment.patientname}
                                      visitDate={appointment.visitDate}
                                      visitTime={appointment.visitTime}
                                      issue={appointment.chiefcomplaint}
                                      borderColor={appointment.color}
                                      appointmentId={appointment.appointmentid}
                                      patientId={appointment.patientid}
                                      // fetchCalendarData={fetchCalendarData}
                                      // fetchSidePanelData={fetchSidePanelData}
                                      refreshPageContent={refreshPageContent}
                                      appointmentStatus={
                                        appointment.appointmentstatus
                                      }
                                      visitStatus={
                                        appointment.appointmentcurrentstatus
                                      }
                                      statusName={
                                        appointment.appointmentstatusname
                                      }
                                    />
                                  ))
                              )}
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>

                  {/* Left area end */}

                  {/* Main container start */}

                  <Box w="85%" bg="#ffffff" pb="0" overflowX="auto">
                    <Calendar
                      eventsArray={calendarAppointments}
                      setCalendarStartDate={setCalendarStartDate}
                      setCalendarEndDate={setCalendarEndDate}
                      onCalendarTitleChange={handleCalendarTitleChange}
                      refreshPageContent={refreshPageContent}
                    />
                  </Box>
                  {/* Main container end */}
                </Box>
              </Flex>
              {/* Main container end */}
            </Box>
          </Flex>
        </Box>
      ) : (
        <>
          {isLoading && <Loader />}
          {screenType == "web" ? (
            <Box w="100%">
              <Flex>
                <Box w="6rem">
                  <MiniSideBar />
                </Box>
                <Box w="100%">
                  <SecondaryNav customHeader="Visits" />

                  {/* Main container start */}
                  <Flex w="calc(100vw - 6rem)">
                    <Box
                      // p="1.5rem 8.25rem"
                      display="flex"
                      h="calc(100vh - 4rem)"
                      // h="100vh"
                      overflow="hidden auto"
                      overflowY={"hidden"}
                      w="100%"
                    >
                      {/* Left area start */}

                      <Box
                        w="23rem"
                        h="calc(100vh - 6rem)"
                        borderRight="1px solid #E6E7E9"
                      >
                        <Box
                          p="1.5rem"
                          display="flex"
                          flexDir="column"
                          gap="1rem"
                        >
                          <SearchBar
                            passInputPlace="Search"
                            onChange={(event) => setSearch(event.target.value)}
                          />
                          <TabSection
                            tabs={["Upcoming", "Past"]}
                            onClickTab={handleTabClick}
                            defaultIndex={selectedTabIndex}
                          />
                        </Box>

                        <Box
                          bg="#f9f9fa"
                          h="calc(100vh - 14rem)"
                          overflow="hidden auto"
                          p="1.5rem"
                        >
                          {selectedTabIndex === 0 && (
                            // Render content for the "Upcoming" tab
                            <Box>
                              {enhanceAndSortAppointmentDetails(
                                upcomingAppointments
                              ).some((appointment) => appointment.today) && (
                                <Box>
                                  <Text
                                    fontSize="0.75rem"
                                    mb="1rem"
                                    letterSpacing="0.1rem"
                                    fontWeight="600"
                                    textTransform="uppercase"
                                    color="#061027"
                                  >
                                    Today
                                  </Text>
                                  {/* Render patient cards for today */}
                                  <Box
                                    display="flex"
                                    flexDir="column"
                                    gap="1rem"
                                  >
                                    {enhanceAndSortAppointmentDetails(
                                      upcomingAppointments
                                    )
                                      .filter(
                                        (appointment) => appointment.today
                                      )
                                      .filter((appointment) =>
                                        appointment.patientname
                                          .toLowerCase()
                                          .includes(search.toLowerCase())
                                      )
                                      .map((appointment) => (
                                        <PatientVisitCard
                                          key={appointment.appointmentid}
                                          userImage={
                                            appointment.imagepath &&
                                            appointment.patientimage
                                              ? appointment.imagepath +
                                                appointment.patientimage
                                              : null
                                          }
                                          userName={appointment.patientname}
                                          visitDate={appointment.visitDate}
                                          visitTime={appointment.visitTime}
                                          issue={appointment.chiefcomplaint}
                                          borderColor={appointment.color}
                                          patientId={appointment.patientid}
                                          // fetchCalendarData={fetchCalendarData}
                                          // fetchSidePanelData={fetchSidePanelData}
                                          refreshPageContent={
                                            refreshPageContent
                                          }
                                          appointmentId={
                                            appointment.appointmentid
                                          }
                                          appointmentStatus={
                                            appointment.appointmentstatus
                                          }
                                          visitStatus={
                                            appointment.appointmentcurrentstatus
                                          }
                                        />
                                      ))}
                                  </Box>
                                </Box>
                              )}
                              {enhanceAndSortAppointmentDetails(
                                upcomingAppointments
                              ).some((appointment) => appointment.tomorrow) && (
                                <Box>
                                  <Text
                                    fontSize="0.75rem"
                                    my="1rem"
                                    letterSpacing="0.1rem"
                                    fontWeight="600"
                                    textTransform="uppercase"
                                    color="#061027"
                                  >
                                    Tomorrow
                                  </Text>
                                  {/* Render patient cards for tomorrow */}
                                  <Box
                                    display="flex"
                                    flexDir="column"
                                    gap="1rem"
                                  >
                                    {enhanceAndSortAppointmentDetails(
                                      upcomingAppointments
                                    )
                                      .filter(
                                        (appointment) => appointment.tomorrow
                                      )
                                      .filter((appointment) =>
                                        appointment.patientname
                                          .toLowerCase()
                                          .includes(search.toLowerCase())
                                      )
                                      .map((appointment) => (
                                        <PatientVisitCard
                                          key={appointment.appointmentid}
                                          userImage={
                                            appointment.imagepath &&
                                            appointment.patientimage
                                              ? appointment.imagepath +
                                                appointment.patientimage
                                              : null
                                          }
                                          userName={appointment.patientname}
                                          visitDate={appointment.visitDate}
                                          visitTime={appointment.visitTime}
                                          issue={appointment.chiefcomplaint}
                                          borderColor={appointment.color}
                                          appointmentId={
                                            appointment.appointmentid
                                          }
                                          patientId={appointment.patientid}
                                          // fetchCalendarData={fetchCalendarData}
                                          // fetchSidePanelData={fetchSidePanelData}
                                          refreshPageContent={
                                            refreshPageContent
                                          }
                                          appointmentStatus={
                                            appointment.appointmentstatus
                                          }
                                          visitStatus={
                                            appointment.appointmentcurrentstatus
                                          }
                                        />
                                      ))}
                                  </Box>
                                </Box>
                              )}
                              {/* Render patient cards for this month */}
                              <Box>
                                <Text
                                  fontSize="0.75rem"
                                  my="1rem"
                                  letterSpacing="0.1rem"
                                  fontWeight="600"
                                  textTransform="uppercase"
                                  color="#061027"
                                >
                                  This Month
                                </Text>
                                <Box display="flex" flexDir="column" gap="1rem">
                                  {enhanceAndSortAppointmentDetails(
                                    upcomingAppointments
                                  )
                                    .filter(
                                      (appointment) =>
                                        !appointment.today &&
                                        !appointment.tomorrow
                                    )
                                    .filter((appointment) =>
                                      appointment.patientname
                                        .toLowerCase()
                                        .includes(search.toLowerCase())
                                    )
                                    .map((appointment) => (
                                      <PatientVisitCard
                                        key={appointment.appointmentid}
                                        userImage={
                                          appointment.imagepath &&
                                          appointment.patientimage
                                            ? appointment.imagepath +
                                              appointment.patientimage
                                            : null
                                        }
                                        userName={appointment.patientname}
                                        visitDate={appointment.visitDate}
                                        visitTime={appointment.visitTime}
                                        issue={appointment.chiefcomplaint}
                                        borderColor={appointment.color}
                                        appointmentId={
                                          appointment.appointmentid
                                        }
                                        patientId={appointment.patientid}
                                        // fetchCalendarData={fetchCalendarData}
                                        // fetchSidePanelData={fetchSidePanelData}
                                        refreshPageContent={refreshPageContent}
                                        appointmentStatus={
                                          appointment.appointmentstatus
                                        }
                                        visitStatus={
                                          appointment.appointmentcurrentstatus
                                        }
                                      />
                                    ))}
                                </Box>
                              </Box>
                            </Box>
                          )}

                          {/* Render content for the "Completed" tab */}
                          {selectedTabIndex === 1 && (
                            <Box>
                              <Box>
                                {/* <Text
                              fontSize="0.75rem"
                              mb="1rem"
                              letterSpacing="0.1rem"
                              fontWeight="600"
                              textTransform="uppercase"
                              color="#061027"
                            >
                              Completed
                            </Text> */}
                                {/* Render completed patient cards */}
                                <Box display="flex" flexDir="column" gap="1rem">
                                  {enhanceAndSortAppointmentDetails(
                                    pastAppointments,
                                    true // reverse sort
                                  )
                                    .filter((appointment) =>
                                      appointment.patientname
                                        .toLowerCase()
                                        .includes(search.toLowerCase())
                                    )
                                    .map((appointment) => (
                                      <PatientVisitCard
                                        key={appointment.appointmentid}
                                        userImage={
                                          appointment.imagepath &&
                                          appointment.patientimage
                                            ? appointment.imagepath +
                                              appointment.patientimage
                                            : null
                                        }
                                        userName={appointment.patientname}
                                        visitDate={appointment.visitDate}
                                        visitTime={appointment.visitTime}
                                        issue={appointment.chiefcomplaint}
                                        borderColor={appointment.color}
                                        appointmentId={
                                          appointment.appointmentid
                                        }
                                        patientId={appointment.patientid}
                                        // fetchCalendarData={fetchCalendarData}
                                        // fetchSidePanelData={fetchSidePanelData}
                                        refreshPageContent={refreshPageContent}
                                        appointmentStatus={
                                          appointment.appointmentstatus
                                        }
                                        visitStatus={
                                          appointment.appointmentcurrentstatus
                                        }
                                      />
                                    ))}
                                </Box>
                              </Box>
                            </Box>
                          )}
                        </Box>
                      </Box>

                      {/* Left area end */}

                      {/* Main container start */}

                      <Box w="85%" bg="#ffffff" pb="0" overflowX="auto">
                        <Calendar
                          eventsArray={calendarAppointments}
                          setCalendarStartDate={setCalendarStartDate}
                          setCalendarEndDate={setCalendarEndDate}
                          onCalendarTitleChange={handleCalendarTitleChange}
                          refreshPageContent={refreshPageContent}
                        />
                      </Box>
                      {/* Main container end */}
                    </Box>
                  </Flex>
                  {/* Main container end */}
                </Box>
              </Flex>
            </Box>
          ) : (
            //Mobile responsive
            <>
              <MobileNavbar />
              <MobileSecondaryNav header="Visits" handlebackarrow={false} />

              <Box w="100%" h="calc(100vh - 12rem)" overflow="hidden auto">
                {/* Calendar start */}
                <Box w="100%">
                  <Calendar
                    eventsArray={calendarAppointments}
                    setCalendarStartDate={setCalendarStartDate}
                    setCalendarEndDate={setCalendarEndDate}
                    onCalendarTitleChange={handleCalendarTitleChange}
                    refreshPageContent={refreshPageContent}
                  />
                </Box>

                {/* Past and Upcoming visits */}
                <Box w="100%">
                  <Box
                    p="1rem"
                    pt="2rem"
                    display="flex"
                    flexDir="column"
                    gap="1rem"
                  >
                    <SearchBar
                      passInputPlace="Search"
                      onChange={(event) => setSearch(event.target.value)}
                    />
                    <TabSection
                      tabs={["Upcoming", "Past"]}
                      onClickTab={handleTabClick}
                      defaultIndex={selectedTabIndex}
                    />
                  </Box>

                  <Box bg="#f9f9fa" overflow="hidden auto" p="1rem">
                    {selectedTabIndex === 0 && (
                      // Render content for the "Upcoming" tab
                      <Box>
                        {enhanceAndSortAppointmentDetails(
                          upcomingAppointments
                        ).some((appointment) => appointment.today) && (
                          <Box>
                            <Text
                              fontSize="0.75rem"
                              mb="1rem"
                              letterSpacing="0.1rem"
                              fontWeight="600"
                              textTransform="uppercase"
                              color="#061027"
                            >
                              Today
                            </Text>
                            {/* Render patient cards for today */}
                            <Box display="flex" flexDir="column" gap="1rem">
                              {enhanceAndSortAppointmentDetails(
                                upcomingAppointments
                              )
                                .filter((appointment) => appointment.today)
                                .filter((appointment) =>
                                  appointment.patientname
                                    .toLowerCase()
                                    .includes(search.toLowerCase())
                                )
                                .map((appointment) => (
                                  <PatientVisitCard
                                    key={appointment.appointmentid}
                                    userImage={
                                      appointment.imagepath &&
                                      appointment.patientimage
                                        ? appointment.imagepath +
                                          appointment.patientimage
                                        : null
                                    }
                                    userName={appointment.patientname}
                                    visitDate={appointment.visitDate}
                                    visitTime={appointment.visitTime}
                                    issue={appointment.chiefcomplaint}
                                    borderColor={appointment.color}
                                    patientId={appointment.patientid}
                                    // fetchCalendarData={fetchCalendarData}
                                    // fetchSidePanelData={fetchSidePanelData}
                                    refreshPageContent={refreshPageContent}
                                    appointmentId={appointment.appointmentid}
                                    appointmentStatus={
                                      appointment.appointmentstatus
                                    }
                                    visitStatus={
                                      appointment.appointmentcurrentstatus
                                    }
                                  />
                                ))}
                            </Box>
                          </Box>
                        )}
                        {enhanceAndSortAppointmentDetails(
                          upcomingAppointments
                        ).some((appointment) => appointment.tomorrow) && (
                          <Box>
                            <Text
                              fontSize="0.75rem"
                              my="1rem"
                              letterSpacing="0.1rem"
                              fontWeight="600"
                              textTransform="uppercase"
                              color="#061027"
                            >
                              Tomorrow
                            </Text>
                            {/* Render patient cards for tomorrow */}
                            <Box display="flex" flexDir="column" gap="1rem">
                              {enhanceAndSortAppointmentDetails(
                                upcomingAppointments
                              )
                                .filter((appointment) => appointment.tomorrow)
                                .filter((appointment) =>
                                  appointment.patientname
                                    .toLowerCase()
                                    .includes(search.toLowerCase())
                                )
                                .map((appointment) => (
                                  <PatientVisitCard
                                    key={appointment.appointmentid}
                                    userImage={
                                      appointment.imagepath &&
                                      appointment.patientimage
                                        ? appointment.imagepath +
                                          appointment.patientimage
                                        : null
                                    }
                                    userName={appointment.patientname}
                                    visitDate={appointment.visitDate}
                                    visitTime={appointment.visitTime}
                                    issue={appointment.chiefcomplaint}
                                    borderColor={appointment.color}
                                    appointmentId={appointment.appointmentid}
                                    patientId={appointment.patientid}
                                    // fetchCalendarData={fetchCalendarData}
                                    // fetchSidePanelData={fetchSidePanelData}
                                    refreshPageContent={refreshPageContent}
                                    appointmentStatus={
                                      appointment.appointmentstatus
                                    }
                                    visitStatus={
                                      appointment.appointmentcurrentstatus
                                    }
                                  />
                                ))}
                            </Box>
                          </Box>
                        )}
                        {/* Render patient cards for this month */}
                        <Box>
                          <Text
                            fontSize="0.75rem"
                            my="1rem"
                            letterSpacing="0.1rem"
                            fontWeight="600"
                            textTransform="uppercase"
                            color="#061027"
                          >
                            This Month
                          </Text>
                          <Box display="flex" flexDir="column" gap="1rem">
                            {enhanceAndSortAppointmentDetails(
                              upcomingAppointments
                            )
                              .filter(
                                (appointment) =>
                                  !appointment.today && !appointment.tomorrow
                              )
                              .filter((appointment) =>
                                appointment.patientname
                                  .toLowerCase()
                                  .includes(search.toLowerCase())
                              )
                              .map((appointment) => (
                                <PatientVisitCard
                                  key={appointment.appointmentid}
                                  userImage={
                                    appointment.imagepath &&
                                    appointment.patientimage
                                      ? appointment.imagepath +
                                        appointment.patientimage
                                      : null
                                  }
                                  userName={appointment.patientname}
                                  visitDate={appointment.visitDate}
                                  visitTime={appointment.visitTime}
                                  issue={appointment.chiefcomplaint}
                                  borderColor={appointment.color}
                                  appointmentId={appointment.appointmentid}
                                  patientId={appointment.patientid}
                                  // fetchCalendarData={fetchCalendarData}
                                  // fetchSidePanelData={fetchSidePanelData}
                                  refreshPageContent={refreshPageContent}
                                  appointmentStatus={
                                    appointment.appointmentstatus
                                  }
                                  visitStatus={
                                    appointment.appointmentcurrentstatus
                                  }
                                />
                              ))}
                          </Box>
                        </Box>
                      </Box>
                    )}

                    {/* Render content for the "Completed" tab */}
                    {selectedTabIndex === 1 && (
                      <Box>
                        <Box>
                          {/* <Text
                        fontSize="0.75rem"
                        mb="1rem"
                        letterSpacing="0.1rem"
                        fontWeight="600"
                        textTransform="uppercase"
                        color="#061027"
                      >
                        Completed
                      </Text> */}
                          {/* Render completed patient cards */}
                          <Box display="flex" flexDir="column" gap="1rem">
                            {enhanceAndSortAppointmentDetails(
                              pastAppointments,
                              true // reverse sort
                            )
                              .filter((appointment) =>
                                appointment.patientname
                                  .toLowerCase()
                                  .includes(search.toLowerCase())
                              )
                              .map((appointment) => (
                                <PatientVisitCard
                                  key={appointment.appointmentid}
                                  userImage={
                                    appointment.imagepath &&
                                    appointment.patientimage
                                      ? appointment.imagepath +
                                        appointment.patientimage
                                      : null
                                  }
                                  userName={appointment.patientname}
                                  visitDate={appointment.visitDate}
                                  visitTime={appointment.visitTime}
                                  issue={appointment.chiefcomplaint}
                                  borderColor={appointment.color}
                                  appointmentId={appointment.appointmentid}
                                  patientId={appointment.patientid}
                                  // fetchCalendarData={fetchCalendarData}
                                  // fetchSidePanelData={fetchSidePanelData}
                                  refreshPageContent={refreshPageContent}
                                  appointmentStatus={
                                    appointment.appointmentstatus
                                  }
                                  visitStatus={
                                    appointment.appointmentcurrentstatus
                                  }
                                />
                              ))}
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
};

export default VisitsIndex;
