import moment from "moment";

export default function formAPIInventoryLogDetail({
  inventoryid,
  productcodeid,
  productname,
  producttype,
  batchnumber,
  chemicalcomposition,
  dosage,
  receiveddate,
  receivedby,
  manufacturername,
  manufactureddate,
  suppliername,
  supplierdate,
  expirydate,
  costperunit,
  costperstrip,
  costperbox,
  cgst,
  sgst,
  prescriptionrequirement,
  otcrequirement,
  stockin,
  stockout,
  numberofboxes,
  numberofcontainers,
  numberofstrips,
  numberofsyrups,
  quantityineachstrip,
  createddate,
  createdby,
  modifieddate,
  modifiedby,
  isactive,

  stripsperbox,            // Added
  remainingtablets,        // Added
  remainingquantity,       // Added

//   storagecondition,
//   location,
//   category,
//   reorderlevel,
//   regulatoryinformation,
//   usageinstructions,
//   stocklocation,
//   supplierleadtime,



}) {
  return Object.freeze({
    inventoryid,
    productcodeid,
    productname,
    producttype,
    batchnumber,
    chemicalcomposition,
    dosage,
    receiveddate,
    receivedby,
    manufacturername,
    manufactureddate,
    suppliername,
    supplierdate,
    expirydate,
    costperunit,
    costperstrip,
    costperbox,
    cgst,
    sgst,
    prescriptionrequirement,
    otcrequirement,
    stockin,
    stockout,
    numberofboxes,
    numberofcontainers,
    numberofstrips,
    numberofsyrups,
    quantityineachstrip,
    createddate,
    createdby,
    modifieddate,
    modifiedby,
    isactive,


    
    stripsperbox,            // Added
    remainingtablets,        // Added
    remainingquantity,       // Added


    // storagecondition,
    // location,
    // category,
    // reorderlevel,
    // regulatoryinformation,
    // usageinstructions,
    // stocklocation,
    // supplierleadtime,



  });
}
