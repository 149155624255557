import React, { useEffect, useRef } from 'react';
import { Column } from '@antv/g2plot';

const MedicineChart = ({data,height}) => {
  const chartRef = useRef(null);

  useEffect(() => {
      // const data = [
      //   { time: '1:00', medicines: 10, patients: 5 },
      //   { time: '2:00', medicines: 15, patients: 20 },
      //   { time: '3:00', medicines: 10, patients: 10 },
      //   { time: '4:00', medicines: 12, patients: 15 },
      //   { time: '5:00', medicines: 15, patients: 18 },
      //   { time: '6:00', medicines: 10, patients: 12 },
      //   { time: '7:00', medicines: 14, patients: 18 },
      //   { time: '8:00', medicines: 10, patients: 8 },
      //   { time: '9:00', medicines: 16, patients: 15 },
      // ];
      // console.log('Medicine data: ', data)

    // Combine data by day and sum up medicines and patients
    const combinedData = data.reduce((acc, curr) => {
      const day = curr.time;
      if (!acc[day]) {
        acc[day] = { time: day, medicines: 0, patients: 0 };
      }
      acc[day].medicines += curr.medicines;
      acc[day].patients += curr.patients;
      return acc;
    }, {});

    // Convert combined data object to an array
    const mergedData = Object.values(combinedData);

    // Format the data for plotting
    const formattedData = mergedData.flatMap(item => [
      { time: `${item.time}`, value: item.medicines, type: 'Medicines' },
      { time: `${item.time}`, value: item.patients, type: 'Patients' },
    ]);

    // Create the chart
    const columnPlot = new Column(chartRef.current, {
      data: formattedData,
      xField: 'time',
      yField: 'value',
      seriesField: 'type',
      isGroup: true,
      legend: {
        position: 'top-right',
      },
      yAxis: {
        title: {
          text: 'No. of medicines / No. of patients',
        },
      },
      columnWidthRatio: 0.6,
      marginRatio: 0.3,
      xAxis: {
        title: {
          text: 'Time',
        },
        label: {
          // Rotate the labels to be slanted
          rotate: Math.PI / 4, // Rotate by 45 degrees
          style: {
            fontSize: 12,
          },
          offset: 40,
          formatter: text => text,
        },
      },
      label: {
        position: 'middle',
        layout: [
          {
            type: 'interval-adjust-position',
          },
          {
            type: 'interval-hide-overlap',
          },
          {
            type: 'adjust-color',
          },
        ],
      },
      color: ({ type }) => {
        return type === 'Medicines' ? '#1C4EC5' : '#14C585';
      },
    });

    // Render the chart
    columnPlot.render();

    // Cleanup on component unmount
    return () => {
      columnPlot.destroy();
    };
  }, [data]);

  return <div ref={chartRef} style={{ height }} />;
};

export default MedicineChart;
