import React from 'react';
import { Box, Flex, Text, VStack } from '@chakra-ui/react';

const VirtualChatDisplay = ({ chatMessages, patientName }) => {
  return (
    <Box w="full" maxW="800px" mx="auto" p={4} bg="gray.50" borderRadius="lg" shadow="md" height="60vh" overflowY="auto">
      <VStack spacing={4} align="stretch">
        {chatMessages.map((message, index) => {
          return (
            <React.Fragment key={index}>
              {/* Question Section */}
              <Flex justify="flex-end">
                <Box
                  bg="green.100"
                  color="green.800"
                  px={4}
                  py={2}
                  borderRadius="lg"
                  maxW="70%"
                  textAlign="left"
                  ml="auto"
                >
                  <Text fontSize="xs" color="green.500">AIRA</Text>
                  <Text fontSize="sm">{message.question}</Text>
                </Box>
              </Flex>

              {/* Answer Section */}
              <Flex justify="flex-start">
                <Box
                  bg="blue.100"
                  color="blue.800"
                  px={4}
                  py={2}
                  borderRadius="lg"
                  maxW="70%"
                  textAlign="left"
                  mr="auto"
                >
                  <Text fontSize="xs" color="blue.500">{patientName}</Text>
                  <Text fontSize="sm">{message.answer}</Text>
                </Box>
              </Flex>
            </React.Fragment>
          );
        })}
      </VStack>
    </Box>
  );
};

export default VirtualChatDisplay;
