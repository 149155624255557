import { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  VStack,
  useColorMode,
} from "@chakra-ui/react";
import { AudioDisplay } from "./utils/audio-display";
import Progress from "./utils/progress";
import { CPUNotesTranscriberAudioManager } from "./utils/cpu-chunks-audio-manager";
import { useTranscriber } from "./utils/cpu-useTranscriber";

import { useLayoutEffect } from "react"; // Import useLayoutEffect
function TranscribeNotes() {
  //-----------------------CPU Inference
  const transcriber = useTranscriber();

  // const [voiceInput, setVoiceInput] = useState("");

  // useEffect(() => {
  //   // console.log("triggered");
  //   // if (transcriber?.output?.text) setInputValue(transcriber.output.text);
  //   if (!transcriber?.output?.isBusy) {
  //     console.log(transcriber.output);
  //     if (transcriber?.output?.text) {
  //       setVoiceInput(
  //         (prevCombinedText) => prevCombinedText + transcriber?.output?.text
  //       );
  //     }
  //     //   if (transcriber?.output?.text) setInputValue(transcriber.output.text);
  //   }
  // }, [transcriber?.output]);

  const [voiceInput, setVoiceInput] = useState([]);

  // useEffect(() => {
  //   if (!transcriber?.output?.isBusy) {
  //     console.log(transcriber?.output);
  //     if (transcriber?.output?.chunks) {
  //       console.log("text out");
  //       setVoiceInput((prevChunks) => [
  //         ...prevChunks,
  //         ...transcriber.output.chunks,
  //       ]);
  //     }
  //   }
  // }, [transcriber?.output]);
  const startTimeRef = useRef(0);

  useEffect(() => {
    if (!transcriber?.output?.isBusy) {
      if (transcriber?.output?.text) {
        // Create chunks with timestamps at 10-second intervals

        const text = transcriber.output.text;
        const startTime = startTimeRef.current;
        const endTime = startTime + 10;

        const newChunks = [
          {
            timestamp: [startTime, endTime],
            text: text,
          },
        ];

        startTimeRef.current = endTime;

        // Update voiceInput state with new chunks
        setVoiceInput((prevChunks) => [...prevChunks, ...newChunks]);
      }
    }
  }, [transcriber?.output]);

  //-----------------------GPU Inference
  const worker = useRef(null);
  const recorderRef = useRef(null);

  const [status, setStatus] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [progressItems, setProgressItems] = useState([]);
  const [text, setText] = useState("");
  const [combinedText, setCombinedText] = useState("");
  const [tps, setTps] = useState(null);
  const [language, setLanguage] = useState("en");
  const [recording, setRecording] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [chunks, setChunks] = useState([]);
  const [stream, setStream] = useState(null);
  // const audioContextRef = useRef(null);

  useEffect(() => {
    if (recorderRef.current) return;

    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          setStream(stream);

          recorderRef.current = new MediaRecorder(stream);
          // audioContextRef.current = new AudioContext({
          //   sampleRate: WHISPER_SAMPLING_RATE,
          // });

          recorderRef.current.onstart = () => {
            setRecording(true);
            setChunks([]);
          };
          recorderRef.current.ondataavailable = (e) => {
            if (e.data.size > 0) {
              setChunks((prev) => [...prev, e.data]);
            } else {
              setTimeout(() => {
                recorderRef.current.requestData();
              }, 25);
            }
          };

          recorderRef.current.onstop = () => {
            setRecording(false);
          };
        })
        .catch((err) => console.error("The following error occurred: ", err));
    } else {
      console.error("getUserMedia not supported on your browser!");
    }

    return () => {
      recorderRef.current?.stop();
      recorderRef.current = null;
    };
  }, []);

  
  const containerRef = useRef(null); // Ref for the container to scroll
  // Auto-scrolling logic
  useLayoutEffect(() => {
    if (containerRef.current) {
      // Ensure it scrolls after DOM update
      setTimeout(() => {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }, 0); // 0 delay to execute after the DOM update
    }
  }, [voiceInput]); // Trigger auto-scrolling when voiceInput updates

  // Helper function to format time
  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60); // Remove decimals
    return minutes > 0 ? `${minutes}m ${seconds}s` : `${seconds}s`;
  };

  const { colorMode } = useColorMode();
  const textColor = colorMode === "light" ? "gray.800" : "gray.200";
  const bgColor = colorMode === "light" ? "white" : "gray.900";

  return (
    <Flex
      w="full"
      h="full"
      justify="center"
      align="center"
      textAlign="center"
      zIndex={9999}
    >
      <Stack spacing={4}>
        <Heading as="h1" fontWeight="bold" size="2xl" mb={1}>
          AIRA - Notes
        </Heading>
        <Text>
          Hi Doctor, AIRA is always ready to assist. Just hit the start button,
          and the assistant will start listening to your conversation.
        </Text>
        <Flex justify="center" align="center">
          <CPUNotesTranscriberAudioManager transcriber={transcriber} />
        </Flex>

        <Flex justify="center" align="center">
          <AudioDisplay
            // maxW="600px"
            // w="full"
            mx="auto"
            borderRadius="lg"
            stream={stream}
          />
        </Flex>

        <Box
        ref={containerRef} // Attach ref for auto-scrolling
          position="relative"
          w="full"
          h="240px"
          overflowY="auto"
          border="1px"
          borderRadius="lg"
          p={2}
        >
          {voiceInput.map((chunk, index) => (
            <Box
              key={index}
              bg="white"
              // boxShadow="md"
              borderRadius="lg"
              p={4}
              mb={2}
              // Apply neon blue border only to the last card
              border={
                index === voiceInput.length - 1 ? "2px solid #00FFFF" : "1px"
              }
              borderColor={
                index === voiceInput.length - 1 ? "#00FFFF" : "gray.200"
              }
              boxShadow={
                index === voiceInput.length - 1 ? "0 0 8px #00FFFF" : "md"
              } // Neon glow
            >
              <Flex>
                {chunk.timestamp && chunk.timestamp.length === 2 ? (
                  <Text fontWeight="bold" color="gray.500" w="150px">
                    {formatTime(chunk.timestamp[0])} -{" "}
                    {formatTime(chunk.timestamp[1])}
                  </Text>
                ) : (
                  <Text fontWeight="bold" color="gray.500" w="120px">
                    No timestamp
                  </Text>
                )}
                <Text
                  ml={4}
                  textAlign="left"
                  whiteSpace="normal" // Allows wrapping of long text
                  wordBreak="break-word" // Breaks words if necessary
                >
                  {chunk.text || "No text available"}
                </Text>
              </Flex>
            </Box>
          ))}
        </Box>
      </Stack>
    </Flex>
  );
}

export default TranscribeNotes;
