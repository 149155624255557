import FetchData from "../../../client";

const updateCondition = async (inputData) => {
  try {
    const result = await FetchData("midoc_updatevisitbydoctor", inputData);
    // console.log(result, "updated");
    return result;
  } catch (error) {
    console.error("Error in editCondition:", error);
    throw error;
  }
};

export { updateCondition };
