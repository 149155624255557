import formapimodalprescriptions from "./prescriptions";

export default function formapimodalprescriptionsPagination({
    data,
    pageno,
    pagesize,
    count
  })
  {
  return Object.freeze({
    
    data:data !== null ? formapimodalprescriptions(data) : [],
    pageno,
    pagesize,
    count,
  });
  }