import formAPIMedicinesView from "./medicineView";

var formattedOutput = null;

const formapimodalmedicinesview = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIMedicinesView(item));


  return formattedOutput
};

export default formapimodalmedicinesview