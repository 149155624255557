import formAPIInventory from "./inventory";


var formattedOutput = null;

const formAPIModelinventory = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIInventory(item));


  return formattedOutput
};

export default formAPIModelinventory