export default function processMedicalHistoryData(medicalHistory, visitDate) {
  if (!Array.isArray(medicalHistory)) {
    return [];
  }

  const filteredData = medicalHistory.filter((entry) => {
    if (visitDate) {
      const conditionDate = new Date(entry.conditionidentifieddate);
      const visitDateObj = new Date(visitDate);
      return conditionDate < visitDateObj;
    }
    return true;
  });

  const processedData = filteredData.map((entry) => {
    return {
      id: entry.id,
      conditiontype_cd: entry.conditiontype_cd,
      condition_cd: entry.condition_cd,
      conditiontype: entry.conditiontype,
      condition: entry.condition,
      conditionidentifieddate: entry.conditionidentifieddate,
    };
  });

  return processedData.sort(
    (a, b) =>
      new Date(b.conditionidentifieddate) - new Date(a.conditionidentifieddate)
  );
}
