import moment from "moment";

//---------------General Funcitons

function getDob() {
  const jsonData = localStorage.getItem("patientDetails");
  const data = JSON.parse(jsonData);
  const dob = data ? data.dob : null;
  return dob;
}
function getusername() {
  const jsonData = localStorage.getItem("patientDetails");
  const data = JSON.parse(jsonData);
  const patientname = data ? data.firstname : null;

  return patientname;
}
function getgender() {
  const jsonData = localStorage.getItem("patientDetails");
  const data = JSON.parse(jsonData);
  const gender = data ? data.gender : 2;

  return gender;
}

//-------------Demographic
function formAPIModeldemogrpich({
  bmi,
  height,
  weight,
  weightunit,
  heightunit,
}) {
  return Object.freeze({
    bmi,
    height,
    weight,
    weightunit,
    heightunit,
  });
}

//----------------Past Medical History

function formAPIpastmedicalhistory({
  condition,
  conditionidentifieddate,
  conditiontype,
  // conditiontype_cd,
  // condition_cd,
}) {
  return Object.freeze({
    condition,
    conditionidentifieddate: conditionidentifieddate
      ? moment(conditionidentifieddate).format("YYYY-MM-DD")
      : "-",
    conditiontype,
    // conditiontype_cd,
    // condition_cd,
  });
}

var formattedOutputMedicalHistory = null;

const formAPIModelpastmedicalhistory = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutputMedicalHistory = data.map((item) =>
    formAPIpastmedicalhistory(item)
  );
  //console.log(formattedOutputMedicalHistory, "Medicalhistory formatted");

  return formattedOutputMedicalHistory;
};

//---------------Past Surgical History

function formAPIpastsurgicalhistory({
  procedure,
  proceduredate,
  proceduretitle,
  proceduretype,
  // proceduretype_cd,
  // procedure_cd,
}) {
  return Object.freeze({
    procedure,
    proceduredate: proceduredate
      ? moment(proceduredate).format("YYYY-MM-DD")
      : "-",
    proceduretitle,
    proceduretype,
    // proceduretype_cd,
    // procedure_cd,
  });
}
var formattedOutputPastSurgicalHistory = null;

const formAPIModelpastsurgicalhistory = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutputPastSurgicalHistory = data.map((item) =>
    formAPIpastsurgicalhistory(item)
  );

  return formattedOutputPastSurgicalHistory;
};

//---------------Socaial History

function formAPIsocialhistory({
  //duration,
  //durationtype,
  frequency,
  frequencytype,
  socialhistorytype,
  iscurrentstatus,
  fromdate,
  todate,
  smokelesstype,

  iscurrent,
}) {
  return Object.freeze({
    //duration,
    //durationtype,
    frequency: frequency,
    frequencytype,
    socialhistorytype,
    iscurrentstatus:
      iscurrentstatus === 1 ? "Yes" : iscurrentstatus === 0 ? "Never" : "Quit",
    fromdate,
    todate,
    smokelesstype,
    //iscurrent:iscurrentstatus ? iscurrentstatus : iscurrent,
    iscurrent: iscurrentstatus,
  });
}

var formattedOutputSocialHistory = null;

const formAPIModelsocialhistory = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutputSocialHistory = data.map((item) => formAPIsocialhistory(item));

  return formattedOutputSocialHistory;
};

//---------------Current Medications
function formAPIcurrentmedications({
  //medicineid,
  medicinename,
  dosage,
  chemicalcomposition,
  medicinetype,
}) {
  return Object.freeze({
    //medicineid,
    medicinename,
    dosage,
    chemicalcomposition,
    medicinetype,
  });
}
var formattedOutputCurrentMedications = null;

const formAPIModelcurrentmedications = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutputCurrentMedications = data.map((item) =>
    formAPIcurrentmedications(item)
  );

  return formattedOutputCurrentMedications;
};

//---------------Allergies
function formAPIModelreaction({ reaction, reaction_cd }) {
  return Object.freeze({
    reaction,
    reaction_cd,
  });
}
var formattedOutputAllergyReaction = null;
//var formattedArray = [];
const formAPIreaction = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutputAllergyReaction = data.map((item) =>
    formAPIModelreaction(item)
  );

  return formattedOutputAllergyReaction;
};

function formAPIAllergies(dataArray) {
  // Assuming dataArray is an array of objects
  return dataArray.map(
    ({
      allergyidentifieddate,
      allergytype,
      allergytype_cd,
      documentcount,
      documents,
      patientallergyid,
      reaction,
    }) => {
      return Object.freeze({
        // allergyidentifieddate : moment(allergyidentifieddate).format("Do MMM YYYY"),
        allergyidentifieddate,
        allergytype,
        allergytype_cd,
        documentcount,
        documents,
        patientallergyid,
        reaction: reaction !== null ? formAPIreaction(reaction) : [],
      });
    }
  );
}

//---------------Investigations
function formAPIInvestigations({
  eventtitle,
  verifieddate,
  resultuom,
  actualresultvalue,
  STATUS,
}) {
  return Object.freeze({
    eventtitle,
    verifieddate,
    resultuom,
    actualresultvalue,
    STATUS,
  });
}
var formattedOutputInvestigations = null;

const formAPIModelInvestigations = (data) => {
  // Apply formAPIModel to each item in the data array
  // formattedOutputInvestigations = data.map((item) =>
  //   formAPIInvestigations(item)
  // );
  // Filter data where STATUS is not 'normal'
  const filteredData = data.filter((item) => item.STATUS === 'normal');

  // Apply formAPIInvestigations to the filtered data
  formattedOutputInvestigations = filteredData.map((item) =>
    formAPIInvestigations(item)
  );

  return formattedOutputInvestigations;
};

//---------------Vitals
function formAPIVitals({
  vitalname,
  vitalvalue,
  vitalunit,
  vitaladdeddate,
  STATUS,
}) {
  return Object.freeze({
    vitalname,
    vitalvalue,
    vitalunit,
    vitaladdeddate,
    STATUS,
  });
}
var formattedOutputVitals = null;

const formAPIModelVitals = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutputVitals = data.map((item) => formAPIVitals(item));

  return formattedOutputVitals;
};

//---------------Family History
function formAPIFamilyHistory({
  relation,
  isadopted,
  isdeceased,
  dob,
  updateddate,
  medicalcondition = [], // Default to empty array if null
  allergies = [], // Default to empty array if null
}) {
  // Handle medical condition and allergies, joining them with commas
  medicalcondition = medicalcondition ? medicalcondition : []
  allergies = allergies ? allergies : []
  const formattedMedicalConditions = medicalcondition
    .map(
      (condition) => `${condition.condition} (${condition.conditiontype})`
    )
    .join(', ') || null; // Null if no conditions

  const formattedAllergies = allergies
    .map((allergy) => `${allergy.allergytype}`)
    .join(', ') || null; // Null if no allergies

  return Object.freeze({
    relation,
    isadopted,
    isdeceased,
    dob,
    updateddate,
    medicalconditions: formattedMedicalConditions,
    allergies: formattedAllergies,
  });
}

var formattedOutputFamilyHistory = null;

const formAPIModelFamilyHistory = (data) => {
  // Apply formAPIModel to each item in the data array
  // formattedOutputFamilyHistory = data.map((item) => formAPIFamilyHistory(item));
    // Filter data where relation is either 'Father' or 'Mother'
    const filteredData = data.filter(
      (item) => item.relation === 'Father' || item.relation === 'Mother'
    );
  
    // Apply formAPIFamilyHistory to the filtered data
    formattedOutputFamilyHistory = filteredData.map((item) =>
      formAPIFamilyHistory(item)
    );
  

  return formattedOutputFamilyHistory;
};

export default function formapimodalaidotor({
  demographics,
  pastmedicalhistory,
  pastsurgicalhistory,
  socialhistory,
  currentmedications,
  patient_info,
  allergies,
  investigations,
  vitals,
  familyhistory
}) {
  const patientname = getusername();
  const dob = getDob();
  let patientgender = getgender();
  let gender = patientgender;
  if (gender === 1) {
    gender = "Female";
  } else if (gender === 2) {
    gender = "Male";
  } else if (gender === 3) {
    gender = "Others";
  }
  function calculateAge(dateOfBirth) {
    const today = new Date();
    const dob = new Date(dateOfBirth);
    let age = today.getFullYear() - dob.getFullYear();
    const monthDiff = today.getMonth() - dob.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
      age--;
    }
    return age;
  }
  const age = calculateAge(dob);
  const moment = require("moment");
  const today = moment().format("YYYY-MM-DD");

  patient_info = {
    name: patientname,
    dateofbirth: dob,
    age: age,
    gender: gender,
    dateofrequest: today,
  };
  return Object.freeze({
    patient_info,
    demographics:
      demographics !== null ? formAPIModeldemogrpich(demographics) : {},
    pastmedicalhistory:
      pastmedicalhistory !== null
        ? formAPIModelpastmedicalhistory(pastmedicalhistory)
        : [],
    pastsurgicalhistory:
      pastsurgicalhistory !== null
        ? formAPIModelpastsurgicalhistory(pastsurgicalhistory)
        : [],
    socialhistory:
      socialhistory !== null ? formAPIModelsocialhistory(socialhistory) : [],
    currentmedications:
      currentmedications !== null
        ? formAPIModelcurrentmedications(currentmedications)
        : [],
    allergies: allergies !== null ? formAPIAllergies(allergies) : [],
    investigations: investigations !== null ? formAPIModelInvestigations(investigations) : [],
    vitals: vitals !== null ? formAPIModelVitals(vitals) : [],
    familyhistory: familyhistory !== null ? formAPIModelFamilyHistory(familyhistory) : [],
  });
}
