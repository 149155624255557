import React from 'react'
import '../../src/App.css';

function Navbar() {
  return (
    <div style={{ background: 'red',height:"900px",  display: 'flex', flexDirection: 'column' }}>
    <div style={{ background: 'wheat', padding: '10px', height:"100%" }}>
        <h1>miDoc</h1> 
        <div className='tabs'>
        <a href="/dashboard">Dashboard</a>   
        
        </div>
        <div className='tabs'>
        <a href="/appointments">Appointments</a>   
        
        </div>
        <div className='tabs'>
        <a href="/notes">Notes</a>   
        
        </div>
        <div className='tabs'>
        <a href="/encounters">Encounters</a>   
        
        </div>

        <div className='tabs'>
        <a href="/profile">Profile</a>           
        
        </div>
        
        <div className='tabs'>

        <a href="/login">Sign out</a>            
        </div>
      </div>
    </div>
  )
}

export default Navbar