import React, { useState, useRef, useEffect } from "react";
import { Box, Text, Image, Circle, Center } from "@chakra-ui/react";
import moment from "moment";
import ModalRight from "../../../components/modal-right";

import DynamicModal from "../../../components/dynamic-modal";

import {
  updateOrderDoctorNotes,
  GetDoctorNotes,
  DeleteOrder,
  updateDoctorNotes,
} from "../../../middleware/services/visits/patient-overview/updatedoctornotes";

import { PrimaryButton, Toaster, PrimaryInput } from "../../../components";

const formatDate = (dateString) => {
  if (!dateString) return "-";
  return moment(dateString).format("Do MMM, YYYY");
};

const CurrentOrderView = ({
  orderId,
  entityType,
  orderName,
  orderDate,
  doctorId,
  patientId,
  appointmentId,
  fetchDoctorNotes,
  showLoader,
  dismissLoader,
  showSuccessToast,
  showErrorToast,
  createdBy,
  scaleHeight = "100px",
  componentBg = "#fcfcfd",
  cardBg = "white",
  fetchDoctorNotesEmptyIndication
}) => {
  const dynamicModalDelteOrder = useRef();
  const editModalOrder = useRef();

  const [doctorNotesOrderId, setDoctorNotesOrderId] = useState(orderId);
  const [doctorNotesOrderType, setdoctorNotesOrderType] = useState(entityType);
  const [doctorNotesOrderName, setdoctorNotesOrderName] = useState(orderName);
  const [doctorNotesOrderDate, setdoctorNotesOrderDate] = useState(orderDate);

  // ---------- EDIT FUNCTIONALITY ----------------

  const openEditModal = (orderId, entityType, orderName, orderDate) => {
    setDoctorNotesOrderId(orderId);
    setdoctorNotesOrderType(entityType);
    setdoctorNotesOrderName(orderName);
    const formattedOrderDate = moment(orderDate, "YYYY-MM-DD").format(
      "DD-MM-YYYY"
    );
    setdoctorNotesOrderDate(formattedOrderDate);

    if (editModalOrder.current) {
      editModalOrder.current.openRight();
    }
  };

  const UpdateOrder = async () => {
    const hasEmptyOrderName = doctorNotesOrderName === "";

    if (hasEmptyOrderName) {
      showErrorToast(`${doctorNotesOrderType} name cannot be empty.`);
      return;
    }
    const inputForEdit = {
      input: {
        id: doctorNotesOrderId,
        ordername: doctorNotesOrderName,
        // orderdate: doctorNotesOrderDate,
        isactive: 1,
      },
    };

    // console.log("Input send", inputForEdit.input);

    // if (
    //   moment(
    //     inputForEdit.input.conditionidentifieddate,
    //     "DD-MM-YYYY",
    //     true
    //   ).isValid()
    // ) {
    //   inputForEdit.input.conditionidentifieddate = moment(
    //     inputForEdit.input.conditionidentifieddate,
    //     "DD-MM-YYYY"
    //   ).format("YYYY-MM-DD");
    // }

    // CHANGE IF NO DATE IS REQUIRED AND
    // if (inputForEdit.input.conditionidentifieddate === "") {
    //   showErrorToast("Please select any date");
    //   return false;
    // } else {
    //   showLoader(); }
    try {
      const result = await updateOrderDoctorNotes(inputForEdit.input);
      if (result) {
        showSuccessToast(`${doctorNotesOrderType} updated successfully`);
        fetchDoctorNotesEmptyIndication()
        fetchDoctorNotes();
        editModalOrder.current.closeRight();
      }
    } catch (error) {
      console.error("Error in UpdateCondition:", error);
    }
  };

  const handleChangeOrderName = (event) => {
    setdoctorNotesOrderName(event.target.value);
  };

  // ----------- DELETE FUNCTIONALITY ----------

  const openDeleteModal = (orderId, entityType, orderName, orderDate) => {
    setDoctorNotesOrderId(orderId);
    setdoctorNotesOrderType(entityType);
    setdoctorNotesOrderName(orderName);
    const formattedOrderDate = moment(orderDate, "YYYY-MM-DD").format(
      "DD-MM-YYYY"
    );
    setdoctorNotesOrderDate(formattedOrderDate);

    if (dynamicModalDelteOrder.current) {
      dynamicModalDelteOrder.current.openModal();
    }
  };

  const deleteSelectedOrder = async () => {
    const inputForDelete = {
      input: {
        id: doctorNotesOrderId,
        ordername: doctorNotesOrderName,
        // orderdate: doctorNotesOrderDate,
        isactive: 0,
      },
    };

    const deleteOrder = async () => {
      try {
        // console.log(inputForDelete.input);
        const result = await updateOrderDoctorNotes(inputForDelete.input);
        // console.log(result);
        if (result.output.message === "Successfully Updated") {
          showSuccessToast(`${doctorNotesOrderType}: 
              ${doctorNotesOrderName} has been deleted`);
          fetchDoctorNotes();
          fetchDoctorNotesEmptyIndication()
          dynamicModalDelteOrder.current.closeModal();
        } else {
          console.log(result.output.message);
          showErrorToast("Something went wrong!");
        }
      } catch (error) {
        console.error("Error in deleteMedicalCondition:", error);
      }
    };
    deleteOrder();
  };

  return (
    <Box
      display="flex"
      flexDir="column"
      alignItems="center"
      h={scaleHeight}
      w="100%"
      bg={componentBg}
    >
      <Box display="flex" w="100%" mt="10px">
        <Box
          p="20px"
          w="100%"
          h="50px"
          ml={{base: 0, md: "10px"}}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          border="1px solid #E6E7E9"
          borderRadius="12px"
          backgroundColor={cardBg}
        >
          <Box display="flex" alignItems="center">
            {/* <Box
              as="p"
              fontSize="10px"
              color="#384052"
              bg="#E6E7E966"
              borderRadius="full"
              border="1px solid #E6E7E9"
              p="5px 10px"
            >
              {type}
            </Box> */}
            <Box
              ml={{base: 0, md: "8px"}}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              maxW="350px"
            >
              <Text
                title={orderName}
                fontSize="16px"
                textAlign="left"
                fontWeight="600"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                w={{base: "100px", md: "230px"}}
              >
                {orderName}
              </Text>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box
              as="button"
              border="1px solid #E6E7E9"
              borderRadius="full"
              w="36px"
              h="auto"
              p="10px"
              onClick={() =>
                openEditModal(orderId, entityType, orderName, orderDate)
              }
            >
              <Image w="13px" src="/assets/svgs/edit-gray.svg" />
            </Box>
            <Box
              as="button"
              border="1px solid #E6E7E9"
              borderRadius="full"
              w="36px"
              h="auto"
              p="10px"
              ml="10px"
              onClick={() =>
                openDeleteModal(orderId, entityType, orderName, orderDate)
              }
            >
              <Image w="13px" src="/assets/imgs/delete.png" />
            </Box>
          </Box>
        </Box>
      </Box>

      {/* DynamicModal for deletion */}
      <DynamicModal
        ref={dynamicModalDelteOrder}
        modalHeader={`Delete ${doctorNotesOrderType}: ${doctorNotesOrderName}?`}
        modalBody={
          <>
            <Text fontSize="14px" fontWeight="600">
              Are you sure you want to delete {doctorNotesOrderType}: "
              {doctorNotesOrderName}"?
            </Text>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="deleteBtn"
              buttonText="Delete"
              onClick={deleteSelectedOrder}
            />
          </>
        }
      />
      {/* Edit Functionality  */}
      <ModalRight
        ref={editModalOrder}
        header={`Edit ${doctorNotesOrderType}`}
        body={
          <>
            <Box display="flex" flexDir="column" gap="20px">
              <Box>
                {/* <DatePicker
                  disableFutureDates={true}
                  defaultDate={procedureDate}
                  onDateChange={(selectedDateValue) =>
                    handleChangeDate(selectedDateValue)
                  }
                  placeholderProp=" Date"
                /> */}

                <PrimaryInput
                  variant="bothSide"
                  inputType="text"
                  inputPlace={`${doctorNotesOrderType} name*`}
                  defaultValue={doctorNotesOrderName}
                  onChange={handleChangeOrderName}
                />
              </Box>
            </Box>
          </>
        }
        footerActions={
          <>
            <Box w="100%" display="flex" flexDirection="row" gap="15px">
              <PrimaryButton
                buttonText={`Update ${doctorNotesOrderType}`}
                isDisabled={false}
                onClick={UpdateOrder}
              />
            </Box>
          </>
        }
      />
    </Box>
  );
};

export default CurrentOrderView;
