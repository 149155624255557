import React, { useRef,useState, useEffect } from "react";
import useLoader from "../../components/loader/loaderstates";
import MiniSideBar from "../../components/mini-sidebar";
import Loader from "../../components/loader/loader";
import SecondaryNav from "../../components/seconday-nav";
import PrimaryButton from "../../components/primary-button";
import {
  Box,
  Divider,
  Flex,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import SimpleTable from "../../components/simple-table";
import { PrimaryInput } from "../../components";
import { COLORS } from "../../components/styles/colors";
import { useLocation, useNavigate } from "react-router-dom";
import { FetchOrderDetails, SaveGenerateInvoice } from "../../middleware/services/billing/index";
import { uploadImageInProfile } from "../../middleware/services/profile";
import moment from "moment";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import DynamicModal from "../../components/dynamic-modal";
import { getAddressInfo, getCurrency, getDoctorName, getMobile, getEmail, getLogo, getHospitalName } from "../../auth";
import { useReactToPrint } from "react-to-print";


const EditAppointmentOrder = () => {
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const location = useLocation();
  const { entityid, entitytype, patientid, appointmentid } = location.state || {};
  const [doctorid, setDoctorid] = useState();
  const [data, setData] = useState(null);
  const [patientdata, setPatientData] = useState(null);
  const [TotalAmount, setTotalAmount] = useState("");
  const today = moment().format("YYYY-MM-DD HH:mm:ss.SSS");
  const [isprintclicked, setIsPrintClicked] = useState("false");
  const navigate = useNavigate()
  const inputRef = useRef(null);
  const generatepopup = useRef();
  const [currency, setCurrency] = useState("");
  const [Address, setAddressDetails] = useState([]);
  const [doctorname, SetDoctorName] = useState(getDoctorName());
  const printRef = useRef();
  const [hospitallogo, setHospitalLogo] = useState("");
  const [hospitalname, setHospitalName] = useState("");


    useEffect(() => {
    if (inputRef.current) {
        inputRef.current.focus(); // Focus the input field when the component mounts
    }
    }, []);
  useEffect(() => {
    if (entityid) {
      fetchData();
    }
    const currency = getCurrency();
    if(currency == "USD"){
      const uscurrency = '$'
      setCurrency(uscurrency);
    }else{
    setCurrency(currency);
    }
    const add = getAddressInfo();
    setAddressDetails(add);
    var hospitallogo = getLogo();
    //var imagepath = "https://midocfileserver.blob.core.windows.net/midocdev/";
    if(hospitallogo){
      setHospitalLogo(hospitallogo)
    }
    var hospitalname = getHospitalName();
    if(hospitalname){
      setHospitalName(hospitalname)
    }
  }, []);
  const fetchData = async () => {
    showLoader();
    try {
      const result = await FetchOrderDetails({
        doctorid,
        entityid: entityid,
        entitytype: entitytype,
        patientid: patientid,
        appointmentid: appointmentid,
      });
      if (result.data != null) {
        setData(result.data);
        setTotalAmount(result.data[0].totalamount)
        setPatientData(result.patientdetail);        
      }
    } catch (error) {
      console.error("Error in home:", error);
    }
    dismissLoader();
  };
  const formatDate = (dateString) => {
    if (!dateString) return "-";
    return moment(dateString).format("ddd, D MMM YYYY");
  };
  const handleTotalAmountChange = (amount) => {
    setTotalAmount(amount)
  };
  const calculateTotalAmountSum = () => {
    return parseFloat(TotalAmount || 0).toFixed(2);
  };
  const calculateTotalAmountSumForDisplay = () => {
    const total = parseFloat(TotalAmount || 0);
    return `${currency} ${total.toFixed(2)}`;
  };
  const prepareInvoiceInput = async () => {
    showLoader();
    let invoiceInput = {
      loginid: "",
      invoiceurl: "",
      subtotal: calculateTotalAmountSum(),
      tax: "0.00",
      totalamount: calculateTotalAmountSum(),
      saletype: "Doctor sale",
      subtype:"Appointment",
      patientid: patientid,
      doctorid: "",
      invoicenumber: patientdata && patientdata.invoicenumber,
      appointmentid: data && data[0].appointmentid,
      patientname: patientdata && patientdata.patientname,
      patientmobile: patientdata && patientdata.mobileno,
      "currency":getCurrency(),
    };
  
    const generatePNG = async () => {
      const input = printRef.current;
        // Apply manual styles directly to the `input` or wrap in a container with these styles
      input.style.padding = '10mm'; // Simulating margin as @page is not supported
      input.style.width = '210mm'; // A4 page width in mm
      input.style.height = '297mm'; 
      const canvas = await html2canvas(input, { scale: 4, useCORS: true });
      const imgData = canvas.toDataURL("image/jpeg", 4);
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  
      pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
  
      const pdfBlob = pdf.output("blob");
      // Upload the PNG image
      const result = await uploadImageInProfile(pdfBlob);
      invoiceInput.invoiceurl = result.name;

      return imgData; // Return the base64 image data
    };

    const saveAndPrint = async (imgData) => {
      const response = await SaveGenerateInvoice(invoiceInput);
      if (response.output.result === "success") {
        dismissLoader();
        handlePrint();
        navigate("/billing");
      }

    };

    generatePNG()
      .then(imgData => saveAndPrint(imgData))
      .catch(error => console.error("Error generating invoice:", error));
  };
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: `
      @page {
        size: A4;
        margin: 5mm;
      }
    `,
  });
  const gobackfunction = ()=>{
    window.history.back();
   }
   const middleSectionHeaderWidth = 20;
   const scaleOut = false;
  return (
    <>
      <Flex>
        <Box w="6rem" className="no-print">
          <MiniSideBar />
        </Box>
        {isLoading && <Loader />}
        <Box w="calc(100vw - 6.25rem)" display="flex" flexDir="column" id="print-content">
          <SecondaryNav withBack="Edit appointment order" goBack={gobackfunction} className="no-print" />

          <Flex w="100%" p="1rem 1.5rem" id="printableDiv1">
            <Box w="30%" display="flex">
              <Flex w="100%" flexDir="column">
                <Text fontSize="0.875rem">Patient name:</Text>
                <Text fontSize="0.875rem">Patient Mobile no:</Text>
                <Text fontSize="0.875rem">Patient email:</Text>
              </Flex>

              <Flex w="100%" flexDir="column">
                <Text fontSize="0.875rem" fontWeight="600">
                  {patientdata && patientdata.patientname ? patientdata.patientname:"-"}
                </Text>
                <Text fontSize="0.875rem" fontWeight="600">
                {patientdata && patientdata.mobileno?patientdata.mobileno:"-"}
                </Text>
                <Text fontSize="0.875rem" fontWeight="600">
                {patientdata && patientdata.email?patientdata.email:"-"}
                </Text>
              </Flex>
            </Box>

            <Spacer />

            <Box w="30%" display="flex">
              <Flex w="100%" flexDir="column">
                <Text fontSize="0.875rem">Invoice:</Text>
                <Text fontSize="0.875rem">Date</Text>
                <Text fontSize="0.875rem">Preferred by:</Text>
              </Flex>

              <Flex w="100%" flexDir="column">
                <Text fontSize="0.875rem" fontWeight="600">
                {patientdata && patientdata.invoicenumber ? "#"+patientdata.invoicenumber : "-"}
                </Text>
                <Text fontSize="0.875rem" fontWeight="600">
                  {formatDate(today)}
                </Text>
                <Text fontSize="0.875rem" fontWeight="600">
                {patientdata && patientdata.doctorname?patientdata.doctorname:"-"}
                </Text>
              </Flex>
            </Box>
          </Flex>

          <Divider />

          <Box
          id="printableDiv2"
            w="100%"
            p="1rem 1.5rem"
            minH="calc(100vh - 280px)"
            maxH="calc(100vh - 280px)"
            overflow="hidden auto"
          >
            <SimpleTable
              isTh={false}
              // headers={TableHeader}
              isDataPresent={true}
              ThStyle={{ textAlign: "center", bg: "#FAFAFA" }}
              footerStyle={{
                pos: "sticky",
                bottom: "-1px",
                boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
              }}
              tableStyle={{
                tableLayout: "fixed",
                width: "100%",
                border: "1px solid #E6E7E9",
                borderBottom: "none",
              }}
              tableHeaders={
                <Tr>
                  <Th fontSize="0.87rem" fontWeight="500" pl="10px">
                    Patient Name
                  </Th>
                 
                  <Th fontSize="0.87rem" fontWeight="500" pl="10px">
                    Appointment Date
                  </Th>
                  <Th fontSize="0.87rem" fontWeight="500" pl="10px">
                    Appointment Time
                  </Th>
                  <Th fontSize="0.87rem" fontWeight="500" pl="10px">
                    Payment Type
                  </Th>
                  <Th fontSize="0.87rem" fontWeight="500" pl="10px">
                    Total Amount ({currency})
                  </Th>
                 
                </Tr>
              }
              tableBody={
                <>
                  {data && data.map((row, index) => (
                    <Tr w="100%" key={index}>
                      <Td>
                      <Text
                          w="100%"
                          fontSize="0.875rem"
                          pos="relative"
                          cursor="pointer"
                        >
                          {row.patientname?row.patientname:"-"}
                        </Text>
                      </Td>
                      <Td>
                      <Text
                          w="100%"
                          fontSize="0.875rem"
                          pos="relative"
                          cursor="pointer"
                        >
                          {formatDate(row.appointmentdate)}
                        </Text>
                      </Td>
                      <Td>
                      <Text
                          w="100%"
                          fontSize="0.875rem"
                          pos="relative"
                          cursor="pointer"
                        >
                          {row.appointmenttime?row.appointmenttime:"-"}
                        </Text>
                      </Td>
                      <Td>
                      <Text
                          w="100%"
                          fontSize="0.875rem"
                          pos="relative"
                          cursor="pointer"
                        >
                          {row.paymenttype?row.paymenttype:"-"}
                        </Text>
                      </Td>
                      <Td p="0">
                        <PrimaryInput
                          variant="bothSide"
                          inputPlace={`Total Amount (${currency})`}
                          inputValue={TotalAmount}
                          onChange={(e) => {
                            const value = e.target.value;                          
                            if (!isNaN(value) && /^[0-9]*$/.test(value)) {
                              handleTotalAmountChange(value);
                            }
                          }}
                          ref={inputRef}
                          autofocus={true}
                          maxLength={7}
                          inputCssStyle={{_hover:{border: "1px solid", borderRadius: "10px"}}}
                        />
                      </Td>
                    </Tr>
                  ))}
                </>
              }
            />
          </Box>

          <Box
            w="100%"
            px="1.5rem"
            boxShadow="rgba(0, 0, 0, 0.15) 0px -10px 11px -8px"
            zIndex="99"
          >
            {/* Footer area start */}
            <Box w="100%" display="flex" pt="1rem">
              <Spacer />

              <Box w="19%" display="flex" gap="2rem" id="printableDiv3">
                <Flex w="75px" flexDir="column" alignItems="start">
                  <Text fontSize="0.875rem" className="textOverflow">
                    Total Amount
                  </Text>
                  {/* <Text fontSize="0.875rem">Tax</Text> */}
                  {/* <Text fontSize="0.875rem">Total:</Text> */}
                </Flex>

                <Flex w="75px" flexDir="column" alignItems="start">
                  <Text fontSize="0.875rem" fontWeight="600" whiteSpace='nowrap'>
                    {calculateTotalAmountSumForDisplay()}
                  </Text>
                  {/* <Text fontSize="0.875rem" fontWeight="600">
                    0
                  </Text> */}
                  {/* <Text fontSize="0.875rem" fontWeight="600">
                    {calculateTotalAmountSumForDisplay()}
                  </Text> */}
                </Flex>
              </Box>
            </Box>
            {/* Footer area end */}

            {/* Generate invoice buttons start */}
            <Flex w="100%" mt="1rem" className="no-print">
              <Spacer />

              <Box w="30%" display="flex" gap="1rem" justifyContent="end">
                <PrimaryButton
                  buttonText="Proforma invoice"
                  variant="grayBtn"
                  btnStyle={{ color: COLORS.PRIMARY_COLOR }}
                  onClick={handlePrint}
                />
                <PrimaryButton buttonText="Generate invoice" variant="mdBtn" onClick={()=> generatepopup.current.openModal()} />
              </Box>
            </Flex>
            {/* Generate invoice buttons end */}
          </Box>
        </Box>
      </Flex>
      {/* For Print */}
      {isprintclicked && 
      <Flex width="215mm" height="297mm" id="printinvoice" ref={printRef} direction="column">
      {/* Blue Strip */}
     <Box
       mt={"0px"}
       width={"100%"}
       top={0}
       left={0}
       right={0}
       height="8px"
       bg="blue.600"
       zIndex={1}
     />
     
     <Box w="100%" display="flex" flexDir="column" height="100%">
     <Box bg={"#fafafa"} p='0.75rem' display='flex' justifyContent='' gap='1.5rem' w='100%'>
       {/* Header */}
       <Box w='130%'>
       <HStack justify="space-between" pt={3} px={4}>
         <VStack align="flex-start" spacing={1}>
           <Text fontWeight="bold" fontSize="xl">
           {hospitalname ?hospitalname : "-"}
           </Text>
         </VStack>
           
       </HStack>

       {/* Contact Information */}
       <HStack justify="flex-start" mt={1} px={4}>
         <HStack>
           <Image src="assets/svgs/call.svg" alt="Call" />
           <Text fontSize={"xs"}>
           {getMobile()}
           </Text>
         </HStack>
         <HStack>
           <Image src="assets/svgs/smallLinePhoneMail.svg" alt="Divider" />
           <Image src="assets/svgs/mail-gray.svg" alt="Mail" />
           <Text fontSize={"xs"}>{getEmail()}</Text>
         </HStack>
       </HStack>

       {/* Hospital Address */}
       <HStack justify="flex-start" mt={1} px={4}>
         <Image src="assets/svgs/hospital-location-on.svg" alt="Location" />
         <Text fontSize={"xs"}>
         {Address && Address.length > 0 ? 
              (Address[0].address1 ? Address[0].address1 : "") + ", " +
              (Address[0].address2 ? Address[0].address2 : "") + ", " +
              (Address[0].city ? Address[0].city : "") + ", " +
              (Address[0].state ? Address[0].state : "") + ", " +
              (Address[0].postalcode ? Address[0].postalcode : "") + ", " +
              (Address[0].country ? Address[0].country : "") 
              : "-"
            }         </Text>
       </HStack>
       </Box>

       <Box w='30%' mt='-1rem'>
       {hospitallogo != "" && (
          <Image
            src={hospitallogo}
            height="auto"
            w='100px'
            mr={"36px"}
          />
        )}
       </Box>
     </Box>
     <Divider my={scaleOut ? 1 : 4} />
       {/* Header Section */}
       <Box w="100%" display="flex" alignItems="flex-start" justifyContent="center">
         <Box w="100%" p="1rem">
           <Text fontSize="1.5rem" textAlign="center" fontWeight="700">
             Invoice
           </Text>
         </Box>
       </Box>
       <VStack align="flex-start" mt={scaleOut ? "1px" : "4px"}>

       <Flex w="100%" p="1rem" id="printableDiv1">
            <Box w="50%" gap='1rem' display="flex" justifyContent='normal'>
              <Flex flexDir="column">
                <Text fontSize="0.875rem">Patient Name:</Text>
                <Text fontSize="0.875rem">Patient Mobile:</Text>
                <Text fontSize="0.875rem">Doctor Name:</Text>
              </Flex>

              <Flex flexDir="column">
                <Text fontSize="0.875rem" fontWeight="600">
                {patientdata && patientdata.patientname ?patientdata.patientname:"-"}
                </Text>
                <Text fontSize="0.875rem" fontWeight="600">
                {patientdata && patientdata.mobileno?patientdata.mobileno:"-"}
                </Text>
                <Text fontSize="0.875rem" fontWeight="600">
                {patientdata && patientdata.doctorname?patientdata.doctorname:"-"}
                </Text>
              </Flex>
            </Box>

            {/* <Spacer /> */}

            <Box w="50%" gap='1rem' display="flex">
              <Flex flexDir="column">
                <Text fontSize="0.875rem">Invoice Number:</Text>
                <Text fontSize="0.875rem">Date</Text>
                {/* <Text fontSize="0.875rem">Preferred by:</Text> */}
              </Flex>

              <Flex flexDir="column">
                <Text fontSize="0.875rem" fontWeight="600">
                {patientdata && patientdata.invoicenumber ? "#"+patientdata.invoicenumber : "-"}
                </Text>
                <Text fontSize="0.875rem" fontWeight="600">
                  {formatDate(today)}
                </Text>
                {/* <Text fontSize="0.875rem" fontWeight="600">

                </Text> */}
              </Flex>
            </Box>
        </Flex>


        <Divider my={scaleOut ? 1 : 4} />
      </VStack>
   
       {/* Content Section (Table) */}
       <Box w="93%" p="0.5rem 0.5rem" flex="1 0 auto" border="1px solid #E6E7E9" borderRadius="4px">
       
              <SimpleTable
                isTh={false}
                // headers={TableHeader}
                isDataPresent={true}
                ThStyle={{ textAlign: "center", bg: "#FAFAFA",
                  border: "1px solid #E6E7E9",
              padding: "4px 8px", // Adjusted padding for smaller header rows
              fontSize: "0.8rem", // Reduced font size for headers
                 }}
                footerStyle={{
                  pos: "sticky",
                  bottom: "-1px",
                  boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
                }}
                tableStyle={{
                  tableLayout: "fixed",
                  width: "100%",
                  border: "1px solid #E6E7E9",
                  borderBottom: "collapse",
                }}
                tableHeaders={
                  <Tr>
                    <Th fontSize="0.8rem" fontWeight="500" pl="8px" pr="8px">
                    Patient Name
                    </Th>
                    <Th fontSize="0.8rem" fontWeight="500" pl="8px" pr="8px">
                    Appointment Date
                    </Th>
                    <Th fontSize="0.8rem" fontWeight="500" pl="8px" pr="8px">
                    Appointment Time
                    </Th>
                    <Th fontSize="0.8rem" fontWeight="500" pl="8px" pr="8px">
                    Payment Type
                    </Th>

                    <Th fontSize="0.8rem" fontWeight="500" pl="8px" pr="8px">
                      Total Amount  ({currency})
                    </Th>
                  </Tr>
                }
                tableBody={
                  <>
                    {data && data.map((row, index) => (
                      <Tr w="100%" key={index}>
                        <Td border="1px solid #E6E7E9" padding="4px 8px">
                          <Text
                            w="100%"
                            fontSize="0.8rem"
                            pos="relative"
                            cursor="pointer"
                          >
                            {row.patientname?row.patientname:"-"}
                          </Text>
                        </Td>
                        <Td border="1px solid #E6E7E9" padding="4px 8px">
                          <Text
                            w="100%"
                            fontSize="0.8rem"
                            pos="relative"
                            cursor="pointer"
                          >
                            {formatDate(row.appointmentdate)}
                          </Text>
                        </Td>
                        <Td border="1px solid #E6E7E9" padding="4px 8px">
                          <Text
                            w="100%"
                            fontSize="0.8rem"
                            pos="relative"
                            cursor="pointer"
                          >
                            {row.appointmenttime? row.appointmenttime:"-"}
                          </Text>
                        </Td>
                        <Td border="1px solid #E6E7E9" padding="4px 8px">
                          <Text
                            w="100%"
                            fontSize="0.8rem"
                            pos="relative"
                            cursor="pointer"
                          >
                            {row.paymenttype? row.paymenttype:"-"}
                          </Text>
                        </Td>
                        <Td border="1px solid #E6E7E9" padding="4px 8px">
                          <Text
                            w="100%"
                            fontSize="0.8rem"
                            pos="relative"
                            cursor="pointer"
                          >
                            {TotalAmount?currency+ " " +TotalAmount:"0"}
                          </Text>
                        </Td>
                      </Tr>
                    ))}
                  </>
                }
              />
      </Box>

     
       {/* Footer Section */}
       <Divider />
       
       <Box w="100%" display="flex" p='3rem 5rem' justifyContent='end'>
              <Box w="100%%" display="flex" gap="2rem" justifyContent='end'>
                <Flex w="75px" flexDir="column" alignItems="start">
                  <Text fontSize="0.875rem" className="textOverflow">
                    Total Amount
                  </Text>
                  {/* <Text fontSize="0.875rem">Tax</Text> */}
                  {/* <Text fontSize="0.875rem">Total:</Text> */}
                </Flex>

                <Flex w="75px" flexDir="column" alignItems="start">
                  <Text fontSize="0.875rem" fontWeight="600">
                  {calculateTotalAmountSumForDisplay()}
                  </Text>
                  {/* <Text fontSize="0.875rem" fontWeight="600">
                    0
                  </Text> */}
                  {/* <Text fontSize="0.875rem" fontWeight="600">
                  {calculateTotalAmountSumForDisplay()}
                  </Text> */}
                </Flex>
              </Box>
            </Box>
     </Box>
   </Flex>
      }
      <DynamicModal
        ref={generatepopup}
        modalHeader="Generate Invoice"
        modalBody={
          <>
            <Text fontSize="14px" fontWeight="600">
              Are you sure you want to generate invoice?
            </Text>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              // variant="deleteBtn"
              buttonText="Generate"
              onClick={prepareInvoiceInput}
            />
          </>
        }
      />
    </>
  );
};

export default EditAppointmentOrder;
