import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Divider,
  Box,
  Image,
  Input,
} from '@chakra-ui/react';



const VitalsModal = forwardRef(({ placement, header, body, footerActions,modalHeaderIcon, modalSize,subHeader,secondaryHeader, id }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const bodyRef = useRef(document.body);

   useImperativeHandle(ref, () => ({
    openVitals: () => setIsOpen(true),
    closeVitals: () => setIsOpen(false),
  }));
  const handlebackarrow = ()=>{
    setIsOpen(false)
  }

  useEffect(() => {
    if (isOpen) {
      bodyRef.current.style.overflow = 'hidden';
      bodyRef.current.style.touchAction = 'none';
    } else {
      bodyRef.current.style.overflow = '';
      bodyRef.current.style.touchAction = '';
    }
  }, [isOpen]);

  return (
    <>
      <Drawer id={id} isOpen={isOpen} size="md" placement="right" onClose={() => setIsOpen(false)} blockScrollOnMount={false}>
        <DrawerOverlay sx={{ backdropFilter: "blur(10px)"}} />
        <DrawerContent sx={modalSize}>
            <Box>
                <Box as="button" onClick={handlebackarrow}  pos="absolute" top="23px" left="25px"><Image src="/assets/imgs/left-arrow-icon.png" /></Box>
                <DrawerCloseButton top="15px" />
            </Box>
          {header && (
            <Box>
              <DrawerHeader  display="flex" justifyContent="center" alignItems="center"><Image mr="10px" src={modalHeaderIcon} />{header}</DrawerHeader>
            </Box>
          )}

          {subHeader && (
            <Box>
              <DrawerHeader>{secondaryHeader}<br/>
                <Box as="span" mt="-5px" fontSize="12px" fontWeight="500" color="#384052">{subHeader}</Box>
              </DrawerHeader>
            </Box>
          )}
          <Divider />
          <DrawerBody bg="#FCFCFD">{body}</DrawerBody>
          {footerActions && <DrawerFooter>{footerActions}</DrawerFooter>}
        </DrawerContent>
       
      </Drawer>
    </>
    
  );
});

export default VitalsModal;
