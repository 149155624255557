import { Box, Card, CardBody, Checkbox, Divider, Heading, Image, Menu, MenuButton, Radio, RadioGroup, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import React, { useEffect, useState, useRef } from "react";
import { AddFamilyHistory, DeleteFamilyHistory, EditFamilyHistory, FetchFamilyHistoryDetails, getmartialstatus, getmedicalcondition, getrelations } from "../../middleware/services/messages";
import moment from "moment";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import { useLocation, useNavigate } from 'react-router-dom';
import MobileSecondaryNav from '../../components/mobile-secondary-navbar';
import { DropdownInput, PrimaryButton, PrimaryInput, PrimaryInputDropdown, SearchBar, Toaster } from '../../components';
import ModalRight from '../../components/modal-right';
import DropdownInputForVitals from '../../components/dropdown-input-forVitals/index (3)';
import { format } from 'date-fns';
import { actionAccessMain, cmToFtConverter, ftToCmConverter, kgToLbConverter, lbToKgConverter, useScreenType, validationMobileNumber } from '../../auth';
import DatePicker from "../../components/date-picker";
import DynamicModal from "../../components/dynamic-modal";


const FamilyHistory = ({Id, backFunction}) => {

  // const Medicalconditionsmodel = useRef();

  const location = useLocation();
  const [patientid, setPatientid] = useState("");
  const [familyData, setFamilyData] = useState(null);
  // const { isLoading, showLoader, dismissLoader } = useLoader();
  // const { showSuccessToast, showErrorToast } = Toaster();

  
  let patientidFromLocal = localStorage.getItem("patientid")
  
  const [actionAccessPatients , setActionAccessPatients] = useState(null);

  useEffect(() => {
    
    setActionAccessPatients(actionAccessMain(4))
    const currentUrl = window.location.href;
    const searchParams = new URLSearchParams(currentUrl.split('?') [1]);
    const id = searchParams.get('id');
    setPatientid(id);
    fetchfamilydata()   
  }, []);
  
  const fetchfamilydata = async () => {
    showLoader()
        try {
          const result = await FetchFamilyHistoryDetails({
            // patientid: id,    
        "patientid":patientidFromLocal,
          });

          if (result != null) {
            console.log(result)
            setFamilyData(result);
          } else {
            setFamilyData('')
          }
          dismissLoader()
      } catch (error) {
          console.error("Error in home:", error);
        }
      };

      const formatDate = (dateString) => {
        if (!dateString) return "-";
        return moment(dateString).format("Do MMM, YYYY");
      };


// ------------------------------------------------------------

// -----------------------------------------------------------------------------------------
var [data, setData] = useState(null);
  var [medicaldata, setMedicalData] = useState([]);
  var [relationdata, setRelationData] = useState([]);
  var [martialstatusdata, setMartialstatusData] = useState([]);
  var [martialstatuscomplete, setMartialstatusCompleteData] = useState([]);
  var [relationscomplete, setRelationsComplete] = useState([]);
  // var [patientid, setPatientid] = useState();
  const dynamicModal = React.useRef();
  const AddFamily = React.useRef();
  const EditFamily = React.useRef();
  const Medicalconditionsmodel = useRef();
  const [searchValue, setSearchValue] = useState("");
  const [dateofbirth, setDate] = useState(null);
  const [relationship, setRelationship] = useState(null);
  const [relationship_cd, setRelationshipID] = useState(null);
  const [name, setName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [maritalstatus, setMaritalstatus] = useState("");
  const [maritalstatus_cd, setMaritalstatusID] = useState(null);
  const [adopted, setAdoptedcheckbox] = useState(false);
  const [deceased, setdeceasedcheckbox] = useState(false);
  const [id, setID] = useState('');
  const [title, setTitle] = useState('');
  const [gender, setGender] = useState("");
  const [gender_cd, setGenderCD] = useState("");
  const [height, setHeight] = useState(null)
  const [weight, setWeight] = useState(null)
  const [heightunit_cd, setHeightunit_cd] = useState(151)
  const [weightunit_cd, setWeightunit_cd] = useState(154)
  const [familyId, setFamilyId] = useState('')
  
  const [email, setEmail] = useState("")
  const [mobileno, setMobileno] = useState("")
  const [bloodtype_cd, setBloodtype_cd] = useState("")
  var [allergiesdata, setAllergiesData] = useState(null);
  
  const [selectedAllergies, setSelectedAllergies] = useState([]);

  const [medicationname, setMedicationname] = useState("");
  const { showSuccessToast, showErrorToast } = Toaster();
  const {isLoading, showLoader, dismissLoader } = useLoader();
  
  const allergiesModal = useRef();

  //BMI states 

  const [vitalunitheight, setVitalUnitHeight] = useState("");
  const [vitalunitheight_cd, setVitalUnitHeight_cd] = useState("");
  const [BMICalculatorType, setBMICalculatorType] = useState('standard');

  const [vitalunitweight, setVitalUnitWeight] = useState("");
  
  const [feetValue , setFeetValue] = useState(null)
  const [inchValue , setInchValue] = useState(null)
  
  const [inchToCalculate , setInchToCalculate] = useState("")
  
  const [heightOptionsInBMI, setHeightOptionsInBMI] = useState([]);
  const [weightOptionsInBMI, setWeightOptionsInBMI] = useState([]);
  
  const [ weightUnitBMI , setWeightUnitBMI ]= useState("")
  const [ heightUnitBMI , setHeightUnitBMI ]= useState("")
  const [showInchesInput , setShowInchesInput] = useState(false)
  const [feetOptions, setFeetOptions] = useState([]);
  const [inchesOptions, setInchesOptions] = useState([]);
  const navigate = useNavigate();
  const screenType = useScreenType();


  // State to manage the selected medical conditions
  const [selectedConditions, setSelectedConditions] = useState([]);
  
  const [typedCondition , setTypedCondition] = useState("")
  const [typedAllergy , setTypedAllergy] = useState("")
  const [allergiesContinueBtnDisabled, setAllergiesContinueBtnDisabled] = useState(true)
  const [isAdd , setIsAdd] = useState(true)
  
const [defaultCountryCode ,setDefaultCountryCode ] = useState("")
  // Function to add a medical condition
  const addCondition4 = (condition) => {
    setSelectedConditions([...selectedConditions, condition]);
  };

  // Function to remove a medical condition
  const removeTag = (index) => {
    const updatedConditions = selectedConditions.filter((_, i) => i !== index);
    setSelectedConditions(updatedConditions);
  };

  useEffect(() => {
   
    fetchfamilydata();

     // BMI States on initial page load -
     setVitalUnitHeight("ft");
     setVitalUnitWeight("lbs");
 
     setBMICalculatorType("standard")
     const tempOptions = [];
     const tempOptionsInches = [];
     const tempOptionsHeight = [];
     const tempOptionsWeightLB = [];
     for (let i = 1; i <= 10; i++) {
       tempOptions.push(i);
     }
     for (let i = 1; i <= 11; i++) {
       tempOptionsInches.push(i);
     }
     for (let i = 1; i <= 10; i++) {
       tempOptionsHeight.push(i);
        for (let j = 1; j <= 11; j++) {
         tempOptionsHeight.push((`${i}.${j}`));
             }
     }
     for (let i = 1; i <= 479; i++) {
       tempOptionsWeightLB.push(i);
     }
     setFeetOptions(tempOptions)
     setInchesOptions(tempOptionsInches)
     setHeightOptionsInBMI(tempOptionsHeight)
     setWeightOptionsInBMI(tempOptionsWeightLB)

  }, []);
  // const fetchData = async () => {
  //   showLoader();
  //   setData("");
  //   try {
  //     const result = await FetchFamilyHistoryData({
  //       patientid,
  //     });
  //     if (result != null) {
  //       setData(result);
  //     } else {
  //       //alert("No data");
  //     }
  //     dismissLoader(); 
  //   } catch (error) {
  //     console.error("Error in DashboardIndex:", error);
  //   }
  // };
  const addfamilymodel = async () => {
    clearState();
    setSearchValue('')
    setSelectedConditions([]);
    setDate(null);
    setMaritalstatusID(null)
    setHeightunit_cd(152)
    setWeightunit_cd(155)
    

    AddFamily.current.openRight();
    try {
      const result = await getmedicalcondition({
        type: "healthcondition",
        
        "patientid":patientid
      });
      //console.log(result);
      if (result != null) {       
        setMedicalData(result.output.data);
      }
    } catch (error) {
      console.error("Error", error);
    }
    //Relations
    try {
      const result = await getrelations({
        type: "relation",
      });
      //console.log(result,"relations");
      if (result != null) {
        const formattedOptions = result.output.data[0].data.map(option => (
          [option.condition]     
        ));
        setRelationData(formattedOptions);
      }
      setRelationsComplete(result.output.data[0].data);
    } catch (error) {
      console.error("Error", error);
    }
    //Martial status
    try {
      const result = await getmartialstatus({
        type: "maritalstatus",
      });
      //console.log(result,"status");
      if (result != null) {
        const formattedOptions = result.output.data[0].data.map(option => (
          [option.condition]     
        ));
        setMartialstatusData(formattedOptions);
      }
      setMartialstatusCompleteData(result.output.data[0].data);
    } catch (error) {
      console.error("Error", error);
    }
  };


  // Medical Conditions checkbox selection
  const handleCheckboxChange = (condition, category) => {
    const isSelected = selectedConditions.some(
      (item) => item.condition_cd === condition.condition_cd
    );

    if (isSelected) {
      // If already selected, remove it from the array
      setSelectedConditions((prevConditions) =>
        prevConditions.filter(
          (item) => item.condition_cd !== condition.condition_cd
        )
      );
    } else {
      // If not selected, add it to the array
      // setSelectedConditions([...selectedConditions, condition]);
      setSelectedConditions((prevConditions) => [
        ...prevConditions,
        { ...condition, category },
      ]);
    }
  };
const handleRelationshipInput= (selectedOption) =>{
  var foundObject = relationscomplete && relationscomplete.find(x => x.condition == selectedOption);
  
  if (foundObject) {
    setRelationshipID(foundObject.condition_cd)
  } 
}
const handleMartitalstatusinput= (selectedOption) =>{
  var foundObject = martialstatuscomplete && martialstatuscomplete.find(x => x.condition == selectedOption);
  
  if (foundObject) {
    setMaritalstatusID(foundObject.condition_cd)
  } 
}
const handleAdoptedCheckboxChange= (e) =>{
  setAdoptedcheckbox(e.target.checked);
}
const handledeceasedCheckboxChange= (e) =>{
  setdeceasedcheckbox(e.target.checked);
}

const SubmitAddForm = async ()=>{
  let AddInput = {
    "patientid":patientidFromLocal,
    "fullname": name + " " + lastName,   
    "firstname": name,
    "lastname":  lastName,
    "dob": dateofbirth,   
    "relation_cd": relationship_cd,
    "maritalstatus_cd": maritalstatus_cd,
    "isadopted": adopted === false ? 0 : 1,
    "isdeceased": deceased === false ? 0 : 1,
    gender,
    "height":null,
    heightunit_cd,
    weight,
    weightunit_cd,
    mobileno,
    email,
    bloodtype_cd,
    "medicalcondition": selectedConditions.map((condition, index) => ({
      "conditiontype_cd":  condition?.category?.conditiontype_cd ,
      "condition_cd": condition.condition_cd,
      "nomenclatureid": condition.condition_cd, // You may adjust this value accordingly
      "conditionidentifieddate": new Date().toISOString(), // Current date as an example
      
      "condition":condition.condition,
      "conditiontype":condition.category.conditiontype
    })),
    allergies: selectedAllergies?.map((allergy, index) => ({
      allergytype_cd: allergy.condition_cd, 
      allergy_cd: allergy.condition_cd,
      nomenclatureid: allergy.condition_cd, // You may adjust this value accordingly
      allergyidentifieddate: new Date().toISOString(), // Current date as an example
      
      "condition":allergy.condition,
      ...(allergy.conditiontype && { conditiontype: allergy.conditiontype }),
    })),
}

var countryCode = localStorage.getItem("countrycode")
var countryId = localStorage.getItem("countryid")

AddInput.countrycode = countryCode
AddInput.countryid = countryId

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if(name == "" || name == null){
    showErrorToast("Please enter first name")
    return false;
  }else if(lastName == ""|| lastName == null){
    showErrorToast("Please enter last name")
    return false;
  }else if(dateofbirth == null){
    showErrorToast("Please select date of birth")
    return false;
  }else if(mobileno == ''){
    showErrorToast("Please enter phone number");
    return false;
  }else if(relationship_cd == ""|| relationship_cd == null){
    showErrorToast("Please select relationship")
    return false;
  }else if (BMICalculatorType == "standard" &&  (inchValue && ( inchValue < 0 || inchValue > 12)) ) {
    showErrorToast("Please enter a valid inch value");
    return false;
  }else  if (email !== "" && !emailRegex.test(email)) {
    showErrorToast("Enter a valid Email");
}
  else{
  try {
    showLoader();
    if(height){
      AddInput.height = height;
    }else if (feetValue || inchValue){
      
      AddInput.height= feetValue && inchValue ? feetValue + "." + inchValue : feetValue;
    }
    const result = await AddFamilyHistory(AddInput);
   if(result){
    showSuccessToast("Family history added successfully");
    fetchfamilydata();
    AddFamily.current.closeRight();
   }
   dismissLoader(); 
  } catch (error) {
    console.error("Error", error);
  }
  }
}
const SubmitEditForm = async ()=>{
 
  let EditInput = {
    "patientid":patientidFromLocal,  
    "familyid": id,
    "fullname": name,   
    "firstname": name, 
    "lastname":  lastName,
    gender,
    "height":"",
    heightunit_cd,
    weight,
    weightunit_cd,
    mobileno,
    email,
    bloodtype_cd,
    "dob": dateofbirth,   
    "relation_cd": relationship_cd,
    "maritalstatus_cd": maritalstatus_cd,
    "isadopted": adopted === false ? 0 : 1,
    "isdeceased": deceased === false ? 0 : 1,
    "medicalcondition": selectedConditions.map((condition, index) => ({
      "conditiontype_cd": index + 1, // Assuming index + 1 is the desired value
      "condition_cd": condition.condition_cd,
      "nomenclatureid": condition.condition_cd, // You may adjust this value accordingly
      "conditionidentifieddate": new Date().toISOString(), // Current date as an example

      "condition":condition.condition,
      "conditiontype":condition?.category?.conditiontype
    })),
    allergies: selectedAllergies?.map((allergy, index) => ({
      allergytype_cd: allergy.condition_cd, 
      allergy_cd: allergy.condition_cd,
      nomenclatureid: allergy.condition_cd, // You may adjust this value accordingly
      allergyidentifieddate: new Date().toISOString(), // Current date as an example
      condition_type: allergy.condition_type ,
      
      "condition":allergy.condition,
      ...(allergy.conditiontype && { conditiontype: allergy.conditiontype }),
    })),
}

var countryCode = localStorage.getItem("countrycode")
var countryId = localStorage.getItem("countryid")

EditInput.countrycode = countryCode
EditInput.countryid = countryId

if (moment(EditInput.dob, 'DD-MM-YYYY', true).isValid()) {
  EditInput.dob = moment(EditInput.dob, 'DD-MM-YYYY').format('YYYY-MM-DD');
}

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if(name == ""){
    showErrorToast("Please enter first name")
    return false;
  }else if(lastName == ""|| lastName == null){
    showErrorToast("Please enter last name")
    return false;
  }else if(mobileno == ''){
    showErrorToast("Please enter phone number");
    return false;
  }else if(relationship == ""){
    showErrorToast("Please select any relation")
    return false;
  }else if (BMICalculatorType == "standard" &&  (inchValue && ( inchValue < 0 || inchValue > 12)) ) {
    showErrorToast("Please enter a valid inch value");
    return false;
  }else if (email !== "" && !emailRegex.test(email)) {
    showErrorToast("Enter a valid Email");
}
  else{

    showLoader();
    if( BMICalculatorType == "standard"){
      
      EditInput.height = feetValue && inchValue ? feetValue + "." + inchValue : feetValue;
    }else if ( BMICalculatorType == "metric"){
      EditInput.height = height;
    }
    const response = await EditFamilyHistory(EditInput);
    
   if(response){
    showSuccessToast("Family history updated successfully");
    
    var patientDetails = localStorage.getItem("patientDetails")
    if(patientDetails){
      
    var formateddata = JSON.parse(patientDetails)
     var activeUserId = formateddata.activeuserid;
     if(activeUserId != null && activeUserId == familyId){
      localStorage.setItem("username" , name +" "+ lastName)
    }

    }

    fetchfamilydata();
    EditFamily.current.closeRight();
   
 //   window.location.reload();
   }   
  else{

  }
  dismissLoader(); 
  }
}
const handleFamilyDelete = (familyid, name) =>{
  // console.log('familyid: ', familyid)  
  setID(familyid);
  setTitle(name)
  dynamicModal.current.openModal();
}
const deletefamilyhistory = async()=>{
  const result = await DeleteFamilyHistory({
    patientid,
    familyid: id,
  });
  if(result){
    fetchfamilydata();
    dynamicModal.current.closeModal();
  }
}
function clearState() {
  setID("");
  setName("");
  setLastName("");
  setRelationship("");
  setRelationshipID(null)
  setMaritalstatus("");
  setDate("");
  setSelectedConditions([]);
  setdeceasedcheckbox(false);
  setAdoptedcheckbox(false);
  setHeight(null)
  setWeight(null)
  setMobileno("")
  setSelectedAllergies([])
  setFamilyId("")
  setFeetValue(null)
  setInchValue(null)
  setInchToCalculate(null)
  setTypedCondition("")
  setTypedAllergy("")
}
const handleFamilyEdit = async (item) =>{
  console.log('item: ', item)
  clearState();
  setIsAdd(false)
 //console.log(item);
 setDefaultCountryCode(item.countryid)
  setID(item.familyid);
  setGenderCD(item.gender_cd);
  setName(item.firstname);
  setLastName(item.lastname);
  setRelationship(item.relation);
  setRelationshipID(item.relation_cd);
  setMaritalstatus(item.maritalstatus);
  setMaritalstatusID(item.maritalstatus_cd);
  setFamilyId(item.familyid);
  if(item.dob !== null){
    const formattedDate =  moment(item.dob, 'YYYY-MM-DD').format('DD-MM-yyyy');
    setDate(formattedDate);
  }else{
    setDate(null)
  }
   setSelectedConditions(item.medicalcondition);
  // const newSelectedConditions = [{
  //   conditiontype_cd: conditiontype_cd,
  //   condition: condition,
  //    category: "" // Assuming you want to store the category of the condition as well
  // }];
  // setSelectedConditions(newSelectedConditions);
 // console.log(item , " item on edit ")
 if(item.allergies !== null){

   setSelectedAllergies(item.allergies)
 }
 // console.log(item.allergies  , " item.allergies")
  setdeceasedcheckbox(item.isdeceased === 0 ? false : true);
  setAdoptedcheckbox(item.isadopted === 0 ? false:true);
  setEmail(item.email)
  setMobileno(item.mobileno)
  setBloodtype_cd(item.bloodtype_cd)
  setHeight(item.height)
  setWeight(item.weight)
  setGender(item.gender_cd)
  // handleRelationshipInput(item.relation);
  // handleMartitalstatusinput(item.maritalstatus);

//  setHeightunit_cd(item.heightunit_cd)
//  setWeightunit_cd(item.weightunit_cd)
 
 setHeightunit_cd(152)
 setWeightunit_cd(155)
 
  setVitalUnitHeight("ft");
  setVitalUnitWeight("lbs");

  if(item.weightunit_cd && item.weightunit_cd == 154){
   
    var converterValue = kgToLbConverter(item.weight)
    setWeight(converterValue.value)
  }

  if(item.heightunit_cd && item.heightunit_cd == 151){
   
    var converterValue = cmToFtConverter(item.height)
    setFeetValue(converterValue.feet)
    setInchValue(converterValue.inches)
    
    setInchToCalculate(converterValue.inchesToCalculate)
  }else if(item.heightunit_cd && item.heightunit_cd == 152){
  if(item.height){
    let parts = item.height.toString().split('.');
    setFeetValue(parts[0])
    setInchValue(parts[1])

    setInchToCalculate(parts[1])
  }
  }
  setBMICalculatorType("standard")

  try {
    const result = await getmedicalcondition({
      type: "healthcondition",
      
      "patientid":patientid
    });
    //console.log(result);
    if (result != null) {       
      setMedicalData(result.output.data);
    }
  } catch (error) {
    console.error("Error", error);
  }
  //Relations
  try {
    const result = await getrelations({
      type: "relation",
    });
    //console.log(result,"relations");
    if (result != null) {
      const formattedOptions = result.output.data[0].data.map(option => (
        [option.condition]     
      ));
      setRelationData(formattedOptions);
    }
    setRelationsComplete(result.output.data[0].data);
  } catch (error) {
    console.error("Error", error);
  }
  //Martial status
  try {
    const result = await getmartialstatus({
      type: "maritalstatus",
    });
    //console.log(result,"status");
    if (result != null) {
      const formattedOptions = result.output.data[0].data.map(option => (
        [option.condition]     
      ));
      setMartialstatusData(formattedOptions);
    }
    setMartialstatusCompleteData(result.output.data[0].data);
  } catch (error) {
    console.error("Error", error);
  }
  EditFamily.current.openRight();
  
}
const openAddConditionModal = () =>{
  setSearchValue('')
 // setSelectedConditions([]);
 setTypedCondition("")
 setTypedAllergy("")
  Medicalconditionsmodel.current.openRight()
}
const handleDateChange = (selecteddate) => {
  const formattedDate = format(selecteddate, "yyyy-MM-dd");
  setDate(formattedDate);
};
const selectGender = (selectedOption) => {
  if (selectedOption == "Male") {
    setGender("2")
  } else if (selectedOption == "Female") {
    setGender("1")
  } else if (selectedOption == "Other") {
    setGender("3")
  }
}

const handleSetHeight = (event) => {


  let cleanedValue = event.target.value.replace(/[^0-9.]/g, '');

  if (cleanedValue.match(/[0-9]{4,}/)) {
    cleanedValue = cleanedValue.slice(0, -1);
  }
  const decimalIndex = cleanedValue.indexOf('.');
  if (decimalIndex !== -1) {
    const parts = cleanedValue.split('.');
    cleanedValue = parts[0] + '.' + parts[1].slice(0, 2);
  }

  setHeight(cleanedValue);

};

const handleChangeHeightUnit = (selectedOption) => {
  setHeightunit_cd(selectedOption.value);
};

const handleSetWeight = (event) => {


  let cleanedValue = event.target.value.replace(/[^0-9.]/g, '');

  if (cleanedValue.match(/[0-9]{4,}/)) {
    cleanedValue = cleanedValue.slice(0, -1);
  }
  const decimalIndex = cleanedValue.indexOf('.');
  if (decimalIndex !== -1) {
    const parts = cleanedValue.split('.');
    cleanedValue = parts[0] + '.' + parts[1].slice(0, 2);
  }

  setWeight(cleanedValue);
};
const handleChangeWeightUnit = (selectedOption) => {
  setWeightunit_cd(selectedOption.value);
};
const handleEmail = (e) => {
  setEmail(e.target.value)
}
const handleMobile = (e) => {
  const inputText = e.target.value;
  const maxLength = 10;
 let valid =  validationMobileNumber(inputText, maxLength)
 setMobileno(valid);

  // if (inputText.length <= maxLength) {
  //   setMobileno(inputText);
  // }

}
const selectBloodType = (selectedOption) => {

  if (selectedOption == "A+") {
    setBloodtype_cd("158")
  } else if (selectedOption == "A-") {
    setBloodtype_cd("159")
  } else if (selectedOption == "B+") {
    setBloodtype_cd("160")
  } else if (selectedOption == "B-") {
    setBloodtype_cd("161")
  } else if (selectedOption == "AB+") {
    setBloodtype_cd("162")
  } else if (selectedOption == "AB-") {
    setBloodtype_cd("163")
  } else if (selectedOption == "O+") {
    setBloodtype_cd("164")
  } else if (selectedOption == "O-") {
    setBloodtype_cd("165")
  }
}
const medicationsHandleChange = (event) => {
  setMedicationname(event.target.value);
};
const fetchAllergiesData = async () => {
  try {
    const response = await getmedicalcondition({
      type: "allergies",
      
      "patientid":patientid
    });
    if (response.output.result == "success") {
      setAllergiesData(response.output.data);
    } else {
      showErrorToast("Something went wrong!")
    }
  } catch (error) {
    console.error("Error", error);
  }
};
const openAllergiesModal = () => {
  // if (allergiesdata == null) {
    fetchAllergiesData();
  // }
  setTypedAllergy("")
  allergiesModal.current.openRight()
}
 //Allergies
 const handleAllergiesCheckboxChange = (condition) => {
  const isSelected = selectedAllergies?.some(
    (item) => item.condition_cd === condition.condition_cd
  );

  if (isSelected) {
    setSelectedAllergies((prevConditions) =>
      prevConditions.filter(
        (item) => item.condition_cd !== condition.condition_cd
      )
    );
  } else {
   setSelectedAllergies((prevConditions = []) => [
    ...prevConditions,
    condition
  ]);
   
    
  }
};
const removeTagAllergiesFromEdit = (condition) => {
  setSelectedAllergies(prevTags => prevTags.filter(tag => tag.condition_cd !== condition));
};
const removeTagAllergiesFromAdd = (condition_cd) => {
  setSelectedAllergies(prevTags => prevTags.filter(tag => tag.condition_cd !== condition_cd));
};
const handleFirstName = (e)=>{
  setName(e.target.value.replace(/[^a-zA-Z\s.]/g, "").slice(0,50))
}
const handleLastName = (e)=>{
  setLastName(e.target.value.replace(/[^a-zA-Z\s.]/g, "").slice(0,50))
}


  //BMI functions 

  useEffect(()=>{
    if(vitalunitheight == "ft"){
      setShowInchesInput(true)
     // alert(vitalunitheight)
    }else{
      setShowInchesInput(false)
    }
  }, [vitalunitheight])
const [ weightToCalculate ,setWeightToCalculate] = useState("")
  const switchBMICalculation = (value) => {
    const tempOptionsWeightLB = [];
    const tempOptionsHeight = [];
    setBMICalculatorType(value);
    
    if (value == "metric") {
     // setWeight("")
      setVitalUnitHeight("cms");
      setHeightunit_cd(151)

      setVitalUnitWeight("kg");
      setWeightunit_cd(154)

      if(weight){
        var converterValue = lbToKgConverter(weight)
        setWeight(converterValue.value)
        setWeightToCalculate(converterValue.valueToCalculate)
      }
      if(feetValue || inchValue){
       // if(height.includes(".")){
        
      var heightValueOfFeet = feetValue && inchValue ? feetValue + "." + inchValue : feetValue;
          var converterValue = ftToCmConverter(feetValue ,inchToCalculate )
          setHeight(converterValue)
       // }
      }
      
      for (let i = 1; i <= 250; i++) {
        tempOptionsWeightLB.push(i);
      }
      for (let i = 30; i <= 303; i++) {
        tempOptionsHeight.push(i);
      }
    setHeightOptionsInBMI(tempOptionsHeight)
    setWeightOptionsInBMI(tempOptionsWeightLB)
    } else if (value == "standard") {
    //  setWeight("")
      setVitalUnitHeight("ft");
      setHeightunit_cd(152)

      setVitalUnitWeight("lbs");
      setWeightunit_cd(155)


      if(weight){
        var converterValue = kgToLbConverter(weightToCalculate)
        setWeight(converterValue.value)

      }

      if(height){

          var converterValue = cmToFtConverter(height)
          setFeetValue(converterValue.feet)
          setInchValue(converterValue.inches)
      }

      for (let i = 1; i <= 479; i++) {
        tempOptionsWeightLB.push(i);
      }
      for (let i = 1; i <= 10; i++) {
        tempOptionsHeight.push(i);
        for (let j = 1; j <= 9; j++) {
          tempOptionsHeight.push((`${i}.${j}`));
        }
      }
    setHeightOptionsInBMI(tempOptionsHeight)
    setWeightOptionsInBMI(tempOptionsWeightLB)
    } 
  };
  const setInchValueInDropDown = (value) =>{
    const stringValue = String(value); // Convert value to string
    setInchValue(stringValue)
    setInchToCalculate(stringValue)
  }
  const handleBMIinchValue = (e)=>{
    /// alert(e.target.value)
     var value = e.target.value;
     const numbersRegex = /^[0-9]+$/;
     if (numbersRegex.test(value) || value === "") { // Also allow empty value
      setInchValue(value)
      setInchToCalculate(value)
     }
     
   }
   const setFeetValueInDropDown = (value) =>{
    const stringValue = String(value); // Convert value to string
    setFeetValue(stringValue)
  }
  const handleBMIFeetValue = (e)=>{
    var value = e.target.value;
    const numbersRegex = /^[0-9]+$/;
    if (numbersRegex.test(value) || value === "") { 
      setFeetValue(value)
    }
    
  }

   // THis is BMI on change event
   const handlebmivitalvaluechange = (e) => {
    const { id, value } = e.target;
    const maxLength = 6;
    switch (id) {
      case "weight":
        let inputValue = value;
       const inputText = e.target.value;
        if (inputText.length <= maxLength) {
          
        let cleanedValue = inputText.replace(/[^0-9.]/g, '');
        if(cleanedValue.startsWith(".")){
          cleanedValue = cleanedValue.substring(1)
        }
        if (cleanedValue.match(/[0-9]{4,}/)) {
          cleanedValue = cleanedValue.slice(0, -1);
        }
        const decimalIndex = cleanedValue.indexOf('.');
        if (decimalIndex !== -1) {
          const parts = cleanedValue.split('.');
          cleanedValue = parts[0] + '.' + parts[1].slice(0, 2);
        }
        setWeight(cleanedValue);
        }
        break;
      case "height":
        const input = e.target.value;
      
        if (input.length <= maxLength) {
          
        let cleanedValue = input.replace(/[^0-9.]/g, '');
        if(cleanedValue.startsWith(".")){
          cleanedValue = cleanedValue.substring(1)
        }
        if (cleanedValue.match(/[0-9]{4,}/)) {
          cleanedValue = cleanedValue.slice(0, -1);
        }
        const decimalIndex = cleanedValue.indexOf('.');
        if (decimalIndex !== -1) {
          const parts = cleanedValue.split('.');
          cleanedValue = parts[0] + '.' + parts[1].slice(0, 2);
        }
        setHeight(cleanedValue);
        }
        break;
       
      default:
        break;
    }
  };
  const setHeightValueInBMIDropDown = (value) =>{
    const stringValue = String(value); // Convert value to string
    setHeight(stringValue)
  }
  const setWeightvalueInBMIDropdown = (value) =>{
    const stringValue = String(value); // Convert value to string
    setWeight(stringValue)
  }

  const css = `
    .StandardMetric {
      border-top: none;
      border-bottom: none;
    }
  `

  const handleCondition = (e) => {
    const inputText = e.target.value;
    const maxLength = 80;
  
    if (inputText.length <= maxLength) {
      setTypedCondition(inputText);

     
    }
  
  }

  const AddMedicalConditions = () => {
   
    if (typedCondition !== "") {
      const newSelectedCondition = {
        category: {
          conditiontype: "other",
          conditiontype_cd: null,
        },
        condition: typedCondition,
        condition_cd: null,
      };
      if(isAdd){
        
      setSelectedConditions((prevConditions) => {
        // Filter out any existing condition with the same type
        const filteredConditions = prevConditions.filter(
          (item) => item.category.conditiontype !== "other"
        );
        // Add the new condition
        return [...filteredConditions, newSelectedCondition];
      });
      
    }

    if(!isAdd){
      
      setSelectedConditions((prevConditions) => {
        // Filter out any existing condition with the same type
        const filteredConditions = prevConditions.filter(
          (item) => item.conditiontype !== "other"
        );
        // Add the new condition
        return [...filteredConditions, newSelectedCondition];
      });
    }
    
  }
  Medicalconditionsmodel.current.closeRight()
    }

    const handleAllergy = (e) => {
      const inputText = e.target.value;
      const maxLength = 80;
    
      if (inputText.length <= maxLength) {
        setTypedAllergy(inputText);
      }
      if(e.target.value){
        setAllergiesContinueBtnDisabled(false)
      }else{
        setAllergiesContinueBtnDisabled(true)
      }
    
    }

    const closeAddAllergyModal =()=>{
        // enter manually 
        if (typedAllergy !== "") {
          const newSelectedCondition = {
            condition:typedAllergy,
            condition_type:typedAllergy,
            condition_cd:null,
            conditiontype:"other",
           // familyid,
          };
      
          setSelectedAllergies((prevConditions) => {
            // Filter out any existing condition with the same type
            const filteredConditions = prevConditions.filter(
              (item) => item?.conditiontype !== "other"
            );
            // Add the new condition
            return [...filteredConditions, newSelectedCondition];
          });
        }
      allergiesModal.current.closeRight()
    }
    useEffect(()=>{
      if(selectedAllergies){

        if(selectedAllergies.length > 0 || typedAllergy){
          setAllergiesContinueBtnDisabled(false)
        }else if(selectedAllergies.length < 0 || !typedAllergy){
          
          setAllergiesContinueBtnDisabled(true)
        }
      }
    },[selectedAllergies])
    // useEffect(() => {
    //   console.log('selectedAllergies: ', selectedAllergies)
    // }, [selectedAllergies])
  return (
    <>
    {isLoading && <Loader />}
        <Box w="100%" display={{base: "none", lg: "block"}}>
        <Box pos='sticky' p='1rem 1.5rem' alignItems='center' top='0' zIndex='999' bg='white' borderBottom='1px solid #E6E7E9' display="flex" justifyContent="space-between" mb="10px">
            <Text fontWeight='600'>Family History</Text>
            <PrimaryButton
              fontSize="14px"
              variant="mdBtn"
              btnIcon="/assets/imgs/add.png"
              buttonText="Add Member"
              onClick={addfamilymodel}
              
              isDisabled={actionAccessPatients?.create ? false : true}
            />
          </Box>
        {familyData && familyData.length>0 ? (
        <SimpleGrid columns={2} spacing="1rem" maxH='calc(100vh - 160px)' overflow='hidden auto' p='1.5rem 1rem'>
            {familyData.map((family, index) => (
            <Card border="1px solid #E6E7E9" borderRadius="0.875rem" boxShadow="none" key={index}>
                <CardBody>
                <Box display='flex'  gap='0.5rem'  justifyContent='space-between' alignItems='center'>
                  <Box>
                    <Text fontSize="0.625rem" w="max-content" fontWeight="600" color="#384052" p="0.25rem 0.5rem" bg="#E6E7E966" border="1px solid #E6E7E9" borderRadius="full">
                        {
                          family.isdeceased == 0 ? (
                            <Text color='rgb(0,151,0)'> Alive</Text>
                          ) : (
                            <Text color='rgb(254, 51, 51)'> Deceased</Text>
                          )
                        }
                    </Text> 
                  </Box>
                  <Box display='flex' gap='0.5rem'>

                            <Box
                              as="button"
                              border="1px solid #E6E7E9"
                              borderRadius="full"
                              w="36px"
                              h="36px"
                              p="10px"
                              onClick={() => handleFamilyEdit(family)}
                              disabled={actionAccessPatients?.update ? false : true}
                                   opacity={actionAccessPatients?.update ? 1 : 0.5}
                            >
                              <Image w="13px" src="/assets/imgs/edit-gray.png" />
                            </Box>
                            <Box
                              as="button"
                              border="1px solid #E6E7E9"
                              borderRadius="full"
                              w="36px"
                              h="36px"
                              p="10px"
                              onClick={() => handleFamilyDelete(family.familyid,family.fullname)}
                              disabled={actionAccessPatients?.delete ? false : true}
                                   opacity={actionAccessPatients?.delete ? 1 : 0.5}
                            >
                              <Image w="13px" src="/assets/imgs/delete.png" />
                            </Box>
                  </Box>
                      </Box>
                    <Text fontSize="1rem" fontWeight="600" color="#061027" mt="0.5rem">
                        {/* Mohammed Uddin Abraar */}
                        {family.firstname + " " + family.lastname}
                    </Text>
                    <Text fontSize="0.75rem" fontWeight="500" color="#384052">
                        {/* Father  •  28th Jan, 1980  */}
                        {family.relation}  •  {formatDate(family.dob)} 
                        </Text>
                    <Divider my="0.75rem" />
                    <Box display='flex' flexWrap='wrap' gap='5px' maxH='100px' overflow='hidden auto'>
                    {family.medicalcondition && family.medicalcondition.length> 0 ? ( 
                        family.medicalcondition.map((item, subIndex) => (
                            <Text fontSize="0.875rem" fontWeight="500" color="#384052" key={subIndex}>
                            {/* Cancer and Diabetes */}
                            {item.condition}
                            {subIndex !== family.medicalcondition.length - 1 && <span>,</span>}
                            </Text>
                        ))
                    ) : ('-')}
                    </Box>
                </CardBody>
            </Card>

            ))}
        </SimpleGrid>

        ) : (
          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            gap="10px"
                            alignItems="center"
                            h="calc(100vh - 100px)"
                            w="100%"
                          >
                            <Box textAlign="left" fontSize="16px" fontWeight="600">
                              <Image w="26px" opacity="0.5" src="/assets/imgs/family-history.png"/>
                            </Box>
                            <Text textAlign="left" fontSize="12px" fontWeight="500">
                              No Data available
                            </Text>
                          </Box>
        )}
        </Box>

        {/* Mobile responsive */}
        <Box w="100%" display={{base: "block", lg: "none"}}>
          <MobileSecondaryNav
            header="Family history"
            handlebackarrow={backFunction}
          />
          {familyData && familyData.length>0 ? (
            <Box p="1rem" h="calc(100vh - 120px)" overflow="hidden auto">
              <SimpleGrid columns={1} spacing="1rem">
                  {familyData.map((family, index) => (
                  <Card border="1px solid #E6E7E9" borderRadius="0.875rem" boxShadow="none" key={index}>
                      <CardBody>
                          <Text fontSize="0.625rem" w="max-content" fontWeight="600" color="#384052" p="0.25rem 0.5rem" bg="#E6E7E966" border="1px solid #E6E7E9" borderRadius="full">
                              {
                                family.isdeceased == 0 ? (
                                  <Text color='rgb(0,151,0)'> Alive</Text>
                                ) : (
                                  <Text color='rgb(254, 51, 51)'> Deceased</Text>
                                )
                              }
                          </Text> 
                          <Text fontSize="1rem" fontWeight="600" color="#061027" mt="0.5rem">
                              {/* Mohammed Uddin Abraar */}
                              {family.fullname}
                          </Text>
                          <Text fontSize="0.75rem" fontWeight="500" color="#384052">
                              {/* Father  •  28th Jan, 1980  */}
                              {family.relation}  •  {formatDate(family.dob)} 
                              </Text>
                          <Divider my="0.75rem" />
                          {family.medicalcondition && family.medicalcondition.length> 0 ? ( 
                              family.medicalcondition.map((item, subIndex) => (
                                  <Text fontSize="0.875rem" fontWeight="500" color="#384052" key={subIndex}>
                                  {/* Cancer and Diabetes */}
                                  {item.condition}
                                  </Text>
                              ))
                          ) : ('-')}
                      </CardBody>
                  </Card>

                  ))}
              </SimpleGrid>
            </Box>
            ) : (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                gap="10px"
                alignItems="center"
                h="calc(100vh - 100px)"
                w="100%"
              >
                <Box textAlign="left" fontSize="16px" fontWeight="600">
                  <Image w="26px" opacity="0.5" src="/assets/imgs/family-history.png"/>
                </Box>
                <Text textAlign="left" fontSize="12px" fontWeight="500">
                  No Data available
                </Text>
              </Box>
        )}
        </Box>


        <ModalRight
          ref={AddFamily}
          bodyStyle={{ p: "24px 32px" }}
          modalWidth={"360px"}
          header="Add member"
          body={
            <>
              <Box display="flex" flexDir="column">
                <PrimaryInput
                  variant="defaultSide"
                  inputType="text"
                  inputPlace="First Name*"
                  onChange={handleFirstName}
                  inputValue={name}
                />
                <PrimaryInput
                  variant="bothSide"
                  inputType="text"
                  inputPlace="Last Name*"
                  onChange={handleLastName}
                  inputValue={lastName}
                />
                  <DropdownInput
                variant="bothSide"
                dropDownPlace="Gender"
                options={["Male", "Female", "Other"]}
                onClick={selectGender}
              />
               {/* <Flex w="100%">
                <MiniDropdownInput
                  variant="bothSide"
                  options={[
                    { label: "cm", value: "151" },
                    { label: "feet", value: "152" }
                  ]}
                  placeholderProp="Height"
                  id="heightUnits"
                  onSelect={handleChangeHeightUnit}
                  inputVal={height}
                  handleInputChange={handleSetHeight}
                  maxLength="6"
                />
                <MiniDropdownInput
                  variant="bothSide"
                  dropdownStyle={{ pl: "0" }}
                  placeholderProp="Weight"
                  options={[
                    { label: "kg", value: "154" },
                    { label: "Pound", value: "155" }
                  ]}
                  id="weightUnits"
                  onSelect={handleChangeWeightUnit}
                  inputVal={weight}
                  handleInputChange={handleSetWeight}
                  maxLength="6"
                />
              </Flex> */}


                {/* <Divider /> */}
                <RadioGroup 
                      onChange={switchBMICalculation}
                      value={BMICalculatorType}
                      w="100%"
                      bg="#FFFFFF"
                      border="1px solid #e2e8f0"
                      pl="10px"
                    >
                    <Box display="flex" justifyContent="start" gap="1.5rem" py="10px" w="100%">
                      <Radio value="standard" colorScheme="green">
                        Standard
                      </Radio>
                    
                      <Radio value="metric" colorScheme="green">
                        Metric
                      </Radio>

                    </Box>
                  </RadioGroup>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  // py="2 0px"
                  pl="10px"
                  w="100%"
                  bg="#FFFFFF"
                  border="1px solid #e2e8f0"
                >
                  <Box fontSize="14px" fontWeight="600" w="20%">
                      Weight
                      <Box
                        as="span"
                        color="#384052"
                        fontSize="12px"
                        fontWeight="500"
                      >
  
                      </Box>
                      <>
                          <Menu>
                            <MenuButton
                              sx={{
                                color: "#1C4EC5",
                                display: "flex",
                                alignItems: "center",
                                cursor:"default"
                              }}
                            >
                              {vitalunitweight}{" "}
                              {/* <Image
                                ml="5px"
                                pos="relative"
                                bottom="2px"
                                display="inline-block"
                                src="/assets/imgs/arrow-down-gray.png"
                              /> */}
                            </MenuButton>
                           
                          </Menu>
                      </>
                  </Box>
                     <DropdownInputForVitals
                        variant="bothSide"
                        dropDownPlace="Enter Value"
                        options={weightOptionsInBMI}
                        onClick={setWeightvalueInBMIDropdown}
                        onChange={handlebmivitalvaluechange}
                        readOnly=""
                        inputStyleCss={{w: "80%"}}
                        className="StandardMetric"
                        id="weight"
                        //  defaultOption={gender == "2" ? "Male" : gender == "1" ? "Female" : gender == "3" ? "Other" : ""}
                        inputValueProp={weight}
                      />
                </Box>

                {/* <Divider /> */}
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  // py="20px"
                  pl="10px"
                  w="100%"
                  bg="#FFFFFF"
                  border="1px solid #e2e8f0"
                >
                  <Box fontSize="14px" fontWeight="600" w="20%">
                      Height
                      <Box
                        as="span"
                        color="#384052"
                        fontSize="12px"
                        fontWeight="500"
                      >
  
                      </Box>
                      <>
                      <Menu>
                            <MenuButton
                              sx={{
                                color: "#1C4EC5",
                                display: "flex",
                                alignItems: "center",
                                cursor:"default"
                              }}
                            >
                              {vitalunitheight}{" "}
                              {/* <Image
                                ml="5px"
                                pos="relative"
                                bottom="2px"
                                display="inline-block"
                                src="/assets/imgs/arrow-down-gray.png"
                              /> */}
                            </MenuButton>
                          </Menu>
                      </>
                  </Box>
                  {!showInchesInput && 
              
                  <DropdownInputForVitals
                    variant="bothSide"
                    dropDownPlace="Enter value"
                    options={heightOptionsInBMI}
                    onClick={setHeightValueInBMIDropDown}
                    onChange={handlebmivitalvaluechange}
                    readOnly=""
                    inputStyleCss={{w: "80%"}}
                    className="StandardMetric"
                    id="height"
                    // defaultOption={gender == "2" ? "Male" : gender == "1" ? "Female" : gender == "3" ? "Other" : ""}
                    inputValueProp={height}
                  />
                  }
                  
                  {showInchesInput && 
                  <>
                   <Box w="80%" display="flex">
                      <DropdownInputForVitals
                        variant="bothSide"
                        dropDownPlace="Feet"
                        options={feetOptions}
                        onClick={setFeetValueInDropDown}
                        onChange={handleBMIFeetValue}
                        readOnly=""
                        inputStyleCss={{w: "50%"}}
                        className="StandardMetric"
                        id="height"
                        inputValueProp={feetValue}
                        maxLength={1}
                      />

                
                      <DropdownInputForVitals
                        variant="bothSide"
                        dropDownPlace="Inches"
                        options={inchesOptions}
                        onClick={setInchValueInDropDown}
                        onChange={handleBMIinchValue}
                        readOnly=""
                        inputStyleCss={{w: "50%"}}
                        className="StandardMetric"
                        id="height"
                        inputValueProp={inchValue}
                        maxLength={2}
                      />
                    </Box>
                  </>
                  }
                </Box>

                {/* <Divider /> */}

                {/* <PrimaryInput
                  variant="bothSide"
                  inputType="date"
                  inputPlace="Date of birth"
                  onChange={(e) => setDate(e.target.value)}
                /> */}
                <DatePicker inputStyle={{borderRadius: "0!important"}} disableFutureDates={true} onDateChange={handleDateChange} placeholderProp={"Date of birth*"} />
                
                <DropdownInput
                  variant="bothSide"
                  dropDownPlace="Relationship*"
                  options={relationdata}
                  onClick={handleRelationshipInput}
                />
                 {/* <PrimaryInput inputType="number" variant="bothSide" inputPlace="Mobile Number"
                onChange={handleMobile} inputValue={mobileno} /> */}
                   <PrimaryInputDropdown
              onChange={(e) => handleMobile(e)}
              inputValue={mobileno}
              //defaultValue={mobileno}
              id="mobile"
              variant="bothSide"
            />
              <PrimaryInput inputType="email" variant="bothSide" inputPlace="Email Address"
                onChange={handleEmail} />
                <DropdownInput
                variant="bothSide"
                dropDownPlace="Blood Type"
                options={["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"]}
                onClick={selectBloodType}
              />
                <DropdownInput
                  variant="downSide"
                  dropDownPlace="Marital status"
                  options={martialstatusdata}
                  onClick={handleMartitalstatusinput}
                />
             <Box mt="15px">
                <Box
                  as="div"
                  role="button"
                  aria-haspopup="dialog"
                  onClick={openAddConditionModal}
                  tabIndex={0}
                  bg="white"
                  borderRadius="10px 10px 0 0"
                  border="1px solid #E6E7E9"
                  p={selectedConditions.length === 0 ? "8px 20px 8px 8px" : "2"}
                  w="100%"
                  display="flex"
                  alignItems="center"
                  gap="2"
                  height="auto"
                  minHeight="48px"
                  flexWrap="wrap"
                  justifyContent={selectedConditions.length > 0 ? "flex-start": "space-between"}
                >
                  {selectedConditions && selectedConditions.length === 0 && (
                    <Text pl="1" color="gray.400">
                      Medical Condition
                    </Text>
                  )}
                  {selectedConditions && selectedConditions.length === 0 && (
                  <Image src="/assets/imgs/right-icon.png" />
                  )}
                  {selectedConditions.map((tag, index) => (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      gap="1"
                      bg="gray.100"
                      borderRadius="full"
                      p="1"
                    >
                      <Text fontSize="sm" px="5px">
                        {tag.condition}
                      </Text>
                      <Box
                        as="button"
                        p="5px"
                        onClick={(e) => {
                          e.stopPropagation();
                          removeTag(index);
                        }}
                        aria-label="Remove condition"
                      >
                        <Image src="/assets/imgs/close.png" />
                      </Box>
                    </Box>
                  ))}
                </Box>
                {/* <Input
                id="medications"
                placeholder="Medications"
                formLabel="Medical Conditions"
                onChange={medicationsHandleChange}
                height="48px"
                borderRadius="0"
                border="1px solid #CDCFD4"
              /> */}

              {/* Allergies */}
              <Box
                as="div"
                role="button"
                aria-haspopup="dialog"
                onClick={openAllergiesModal}
                tabIndex={0}
                bg="white"
                borderRadius="0 0 10px 10px"
                border="1px solid #E6E7E9"
                
                p={selectedAllergies.length === 0 ? "8px 20px 8px 8px" : "2"}
                w="100%"
                display="flex"
                alignItems="center"
                gap="2"
                height="auto"
                minHeight="48px"
                flexWrap="wrap"
                justifyContent={selectedAllergies.length > 0 ? "flex-start" : "space-between"}
              >
                {selectedAllergies?.length === 0 && (
                  <>
                  <Text pl="1" color="gray.400">
                    Allergies
                  </Text>
                  
                  <Image src="/assets/imgs/right-icon.png" />
                  </>
                )}
                {selectedAllergies?.map((tag, index) => (
                  <Box
                    key={index}
                    display="flex"
                    alignItems="center"
                    gap="1"
                    bg="gray.100"
                    borderRadius="full"
                    p="1"
                  >
                    <Text fontSize="sm" px="5px">
                      
                    {tag.condition ? tag.condition : tag.condition_type}
                    </Text>
                    <Box
                      as="button"
                      p="5px"
                      onClick={(e) => {
                        e.stopPropagation();
                        removeTagAllergiesFromAdd(tag.condition_cd);
                      }}
                      aria-label="Remove condition"
                    >
                      <Image src="/assets/imgs/close.png" />
                    </Box>
                  </Box>
                ))}
              </Box>
                {/* <Box
                as="div"
                role="button"
                aria-haspopup="dialog"
                onClick={() => Medicalconditionsmodel.current.openRight()}
                tabIndex={0}
                border="1px solid #E6E7E9"
                p="2"
                w="100%"
                display="flex"
                alignItems="center"
                gap="2"
                height="auto" 
                minHeight="48px" 
                flexWrap="wrap"
              >
                 {selectedConditions.length === 0 && (
                  <Text pl="1" color="gray.400">Custom Condition</Text>
                )}
                {selectedConditions.map((tag, index) => (
                  <Box key={index} display="flex" alignItems="center" gap="1" bg="gray.100" borderRadius="full" p="1">
                    <Text fontSize="sm" px="5px">{tag.name}</Text>
                    <Box as="button" p="5px" onClick={(e) => { e.stopPropagation(); removeCondition(tag.id); }} aria-label="Remove condition">
                      <Image src="/assets/imgs/close.png" />
                    </Box>
                  </Box>
                ))}
              </Box> */}
              </Box>
              </Box>
              <Box py="20px">
                <Divider border="1px solid #E6E7E9" />
              </Box>
              <Box display="flex" gap="30px">
                <Checkbox
                  colorScheme="green"
                  sx={{
                    ".chakra-checkbox__control": {
                      width: "20px",
                      height: "20px",
                      borderRadius: "5px",
                    },
                  }}
                  onChange={handleAdoptedCheckboxChange}
                >
                  Adopted
                </Checkbox>
                <Checkbox
                  colorScheme="green"
                  sx={{
                    ".chakra-checkbox__control": {
                      width: "20px",
                      height: "20px",
                      borderRadius: "5px",
                    },
                  }}
                  onChange={handledeceasedCheckboxChange}
                >
                  
                  Deceased
                </Checkbox>
              </Box>
            
            </>
          }
          footerActions={
            <Box w="100%" display="flex" flexDirection="row" gap="15px">
              <PrimaryButton buttonText="Add Member" onClick={SubmitAddForm} 
                       isDisabled={actionAccessPatients?.create ? false : true} />
            </Box>
          }
        />

<ModalRight
          ref={EditFamily}
          bodyStyle={{ p: "24px 32px" }}
          modalWidth={"360px"}
          header="Edit Member"
          body={
            <>
              <Box display="flex" flexDir="column">
                <PrimaryInput
                  variant="defaultSide"
                  inputType="text"
                  inputPlace="First Name*"
                  defaultValue={name}
                  //onChange={(e) => setName(e.target.value)}
                  onChange={handleFirstName}
                  inputValue={name}
                />
                <PrimaryInput
                  variant="bothSide"
                  inputType="text"
                  inputPlace="Last Name*"
                  defaultValue={lastName}
                  //onChange={(e) => setLastName(e.target.value)}
                  onChange={handleLastName}
                  inputValue={lastName}
                />
                 <DropdownInput
                variant="bothSide"
                dropDownPlace="Gender"
                options={["Male", "Female", "Other"]}
                onClick={selectGender}
                defaultOption={gender_cd == "2" ? "Male" : gender_cd == "1" ? "Female" : gender_cd == "3" ? "Other" : ""}
                inputValue={gender}
              />
              {/* <Flex w="100%">
                
                <MiniDropdownInput
                  variant="bothSide"
                  options={[
                    { label: "cm", value: 151 },
                    { label: "ft", value: 152 }
                  ]}
                  placeholderProp="Height"
                  id="heightUnits"
                  defaultOptionProp={heightunit_cd}
                  onSelect={handleChangeHeightUnit}
                  defaultValue={height}
                  inputVal={height}
                  handleInputChange={handleSetHeight}
                  maxLength="6"
                />
               
                <MiniDropdownInput
                  variant="bothSide"
                  dropdownStyle={{ pl: "0" }}
                  placeholderProp="Weight"
                  options={[
                    { label: "kg", value: 154 },
                    { label: "lb", value: 155 }
                  ]}
                  id="weightUnits"
                  onSelect={handleChangeWeightUnit}
                  defaultOptionProp={weightunit_cd}
                  inputVal={weight}
                  defaultValue={weight}
                  handleInputChange={handleSetWeight}
                  maxLength="6"
                />
              </Flex> */}

              
                {/* <Divider /> */}
                <RadioGroup 
                      onChange={switchBMICalculation}
                      value={BMICalculatorType}
                      w="100%"
                      bg="#FFFFFF"
                      border="1px solid #e2e8f0"
                      pl="10px"
                    >
                    <Box display="flex" justifyContent="start" gap="1.5rem" py="10px" w="100%">
                      <Radio value="standard" colorScheme="green">
                        Standard
                      </Radio>
                    
                      <Radio value="metric" colorScheme="green">
                        Metric
                      </Radio>

                    </Box>
                  </RadioGroup>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  // py="2 0px"
                  pl="10px"
                  w="100%"
                  bg="#FFFFFF"
                  border="1px solid #e2e8f0"
                >
                  <Box fontSize="14px" fontWeight="600" w="20%">
                      Weight
                      <Box
                        as="span"
                        color="#384052"
                        fontSize="12px"
                        fontWeight="500"
                      >
  
                      </Box>
                      <>
                          <Menu>
                            <MenuButton
                              sx={{
                                color: "#1C4EC5",
                                display: "flex",
                                alignItems: "center",
                                cursor:"default"
                              }}
                            >
                              {vitalunitweight}{" "}
                              {/* <Image
                                ml="5px"
                                pos="relative"
                                bottom="2px"
                                display="inline-block"
                                src="/assets/imgs/arrow-down-gray.png"
                              /> */}
                            </MenuButton>
                           
                          </Menu>
                      </>
                  </Box>
                     <DropdownInputForVitals
                        variant="bothSide"
                        dropDownPlace="Enter Value"
                        options={weightOptionsInBMI}
                        onClick={setWeightvalueInBMIDropdown}
                        onChange={handlebmivitalvaluechange}
                        readOnly=""
                        inputStyleCss={{w: "80%"}}
                        className="StandardMetric"
                        id="weight"
                        //  defaultOption={gender == "2" ? "Male" : gender == "1" ? "Female" : gender == "3" ? "Other" : ""}
                        inputValueProp={weight}
                      />
                </Box>

                {/* <Divider /> */}
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  // py="20px"
                  pl="10px"
                  w="100%"
                  bg="#FFFFFF"
                  border="1px solid #e2e8f0"
                >
                  <Box fontSize="14px" fontWeight="600" w="20%">
                      Height
                      <Box
                        as="span"
                        color="#384052"
                        fontSize="12px"
                        fontWeight="500"
                      >
  
                      </Box>
                      <>
                      <Menu>
                            <MenuButton
                              sx={{
                                color: "#1C4EC5",
                                display: "flex",
                                alignItems: "center",
                                cursor:"default"
                              }}
                            >
                              {vitalunitheight}{" "}
                              {/* <Image
                                ml="5px"
                                pos="relative"
                                bottom="2px"
                                display="inline-block"
                                src="/assets/imgs/arrow-down-gray.png"
                              /> */}
                            </MenuButton>
                          </Menu>
                      </>
                  </Box>
                  {!showInchesInput && 
              
                  <DropdownInputForVitals
                    variant="bothSide"
                    dropDownPlace="Enter value"
                    options={heightOptionsInBMI}
                    onClick={setHeightValueInBMIDropDown}
                    onChange={handlebmivitalvaluechange}
                    readOnly=""
                    inputStyleCss={{w: "80%"}}
                    className="StandardMetric"
                    id="height"
                    // defaultOption={gender == "2" ? "Male" : gender == "1" ? "Female" : gender == "3" ? "Other" : ""}
                    inputValueProp={height}
                  />
                  }
                  
                  {showInchesInput && 
                  <>
                   <Box w="80%" display="flex">
                      <DropdownInputForVitals
                        variant="bothSide"
                        dropDownPlace="Feet"
                        options={feetOptions}
                        onClick={setFeetValueInDropDown}
                        onChange={handleBMIFeetValue}
                        readOnly=""
                        inputStyleCss={{w: "50%"}}
                        className="StandardMetric"
                        id="height"
                        inputValueProp={feetValue}
                        maxLength={1}
                      />

                
                      <DropdownInputForVitals
                        variant="bothSide"
                        dropDownPlace="Inches"
                        options={inchesOptions}
                        onClick={setInchValueInDropDown}
                        onChange={handleBMIinchValue}
                        readOnly=""
                        inputStyleCss={{w: "50%"}}
                        className="StandardMetric"
                        id="height"
                        inputValueProp={inchValue}
                        maxLength={2}
                      />
                    </Box>
                  </>
                  }
                </Box>

                {/* <Divider /> */}

                {/* <PrimaryInput
                  variant="bothSide"
                  inputType="date"
                  inputPlace="Date of birth"
                  onChange={(e) => setDate(e.target.value)}
                /> */}
                <DatePicker 
                inputStyle={{borderRadius: "0!important"}}
                disableFutureDates={true}
                defaultDate={dateofbirth} 
                onDateChange={(selectedDateValue) =>
                  handleDateChange(selectedDateValue)
                }
                placeholderProp={"Date of birth*"} />
                <DropdownInput
                  variant="bothSide"
                  dropDownPlace="Relationship*"
                  options={relationdata}
                  defaultOption={relationship}
                  onClick={handleRelationshipInput}
                />
                  {/* <PrimaryInput inputType="number" variant="bothSide" inputPlace="Mobile Number"
                onChange={handleMobile} defaultValue={mobileno} inputValue={mobileno} /> */}

                <PrimaryInputDropdown
              onChange={(e) => handleMobile(e)}
              inputValue={mobileno}
              defaultValue={mobileno}
              defaultCountryCode={defaultCountryCode}
              id="mobile"
              variant="bothSide"
            />
              <PrimaryInput inputType="email" variant="bothSide" inputPlace="Email Address"
                onChange={handleEmail} defaultValue={email} />
              <DropdownInput
                variant="bothSide"
                dropDownPlace="Blood Type"
                options={["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"]}
                onClick={selectBloodType}
                defaultOption={
                  bloodtype_cd == "158" ? "A+" :
                    bloodtype_cd == "159" ? "A-" :
                      bloodtype_cd == "160" ? "B+" :
                        bloodtype_cd == "161" ? "B-" :
                          bloodtype_cd == "162" ? "AB+" :
                            bloodtype_cd == "163" ? "AB-" :
                              bloodtype_cd == "164" ? "O+" :
                                bloodtype_cd == "165" ? "O-" :
                                  ""
                }

              />
                <DropdownInput
                  variant="downSide"
                  dropDownPlace="Marital status"
                  options={martialstatusdata}
                  defaultOption={maritalstatus}
                  onClick={handleMartitalstatusinput}
                />
                
                <Box mt="15px">
                <Box
                  as="div"
                  role="button"
                  aria-haspopup="dialog"
                  onClick={openAddConditionModal}
                  tabIndex={0}
                  bg="white"
                  borderRadius="10px 10px 0 0"
                  border="1px solid #E6E7E9"
                  
                  p={selectedConditions.length === 0 ? "8px 20px 8px 8px" : "2"}
                  w="100%"
                  display="flex"
                  alignItems="center"
                  gap="2"
                  height="auto"
                  minHeight="48px"
                  flexWrap="wrap"
                  justifyContent={selectedConditions.length > 0 ? "flex-start": "space-between"}
                >
                  {selectedConditions?.length === 0 && (
                    <>
                    <Text pl="1" color="gray.400">
                      Medical Condition
                    </Text>
                    
                  <Image src="/assets/imgs/right-icon.png" />
                  
                  </>)}
                  {selectedConditions.map((tag, index) => (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      gap="1"
                      bg="gray.100"
                      borderRadius="full"
                      p="1"
                    >
                      <Text fontSize="sm" px="5px">
                        {tag.condition}
                      </Text>
                      <Box
                        as="button"
                        p="5px"
                        onClick={(e) => {
                          e.stopPropagation();
                          removeTag(index);
                        }}
                        aria-label="Remove condition"
                      >
                        <Image src="/assets/imgs/close.png" />
                      </Box>
                    </Box>
                  ))}
                </Box>
                {/* Allergies */}
              <Box
                as="div"
                role="button"
                aria-haspopup="dialog"
                onClick={openAllergiesModal}
                tabIndex={0}
                bg="white"
                borderRadius="0 0 10px 10px"
                border="1px solid #E6E7E9"
                
                p={selectedAllergies.length === 0 ? "8px 20px 8px 8px" : "2"}
                w="100%"
                display="flex"
                alignItems="center"
                gap="2"
                height="auto"
                minHeight="48px"
                flexWrap="wrap"
                justifyContent={selectedAllergies.length > 0 ? "flex-start" : "space-between"}
                
              >
              {selectedAllergies?.length === 0 && (
                <>
                  <Text pl="1" color="gray.400">
                    Allergies
                  </Text>
                  
                  <Image src="/assets/imgs/right-icon.png" />
                  </>
                )}
                {selectedAllergies?.map((tag, index) => (
                  <Box
                    key={index}
                    display="flex"
                    alignItems="center"
                    gap="1"
                    bg="gray.100"
                    borderRadius="full"
                    p="1"
                    className='textOverflow' maxWidth="250px"
                  >
                    <Text fontSize="sm" px="5px">
                    {tag.condition ? tag.condition : tag.condition_type} 
                    </Text>
                    <Box
                      as="button"
                      p="5px"

                      onClick={(e) => {
                        e.stopPropagation();
                        removeTagAllergiesFromEdit(tag.condition_cd);
                      }}
                      aria-label="Remove condition"
                    >
                      <Image src="/assets/imgs/close.png" />
                    </Box>
                  </Box>
                ))}
              </Box>
                </Box>

                {/* <Box
                as="div"
                role="button"
                aria-haspopup="dialog"
                onClick={() => Medicalconditionsmodel.current.openRight()}
                tabIndex={0}
                border="1px solid #E6E7E9"
                p="2"
                w="100%"
                display="flex"
                alignItems="center"
                gap="2"
                height="auto" 
                minHeight="48px" 
                flexWrap="wrap"
              >
                 {selectedConditions.length === 0 && (
                  <Text pl="1" color="gray.400">Custom Condition</Text>
                )}
                {selectedConditions.map((tag, index) => (
                  <Box key={index} display="flex" alignItems="center" gap="1" bg="gray.100" borderRadius="full" p="1">
                    <Text fontSize="sm" px="5px">{tag.name}</Text>
                    <Box as="button" p="5px" onClick={(e) => { e.stopPropagation(); removeCondition(tag.id); }} aria-label="Remove condition">
                      <Image src="/assets/imgs/close.png" />
                    </Box>
                  </Box>
                ))}
              </Box> */}
              </Box>
              <Box py="20px">
                <Divider border="1px solid #E6E7E9" />
              </Box>
              <Box display="flex" gap="30px">
                <Checkbox
                  colorScheme="green"
                  sx={{
                    ".chakra-checkbox__control": {
                      width: "20px",
                      height: "20px",
                      borderRadius: "5px",
                    },
                  }}
                  defaultChecked={adopted === true}
                  onChange={handleAdoptedCheckboxChange}
                >
                  Adopted
                </Checkbox>
                <Checkbox
                  colorScheme="green"
                  sx={{
                    ".chakra-checkbox__control": {
                      width: "20px",
                      height: "20px",
                      borderRadius: "5px",
                    },
                  }}
                  defaultChecked={deceased === true}
                  onChange={handledeceasedCheckboxChange}
                >
                  
                  Deceased
                </Checkbox>
              </Box>

           

        
            </>
          }
          footerActions={
            <Box w="100%" display="flex" flexDirection="row" gap="15px">
              <PrimaryButton buttonText="Update Member" onClick={SubmitEditForm}/>
            </Box>
          }
        />

<ModalRight
        ref={allergiesModal}
        header="Select Allergies"
        body={
          <>
            <SearchBar
              passInputPlace="Search allergies"
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <RadioGroup>
              <Stack direction="column" gap="0">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  borderBottom="1px solid #e2e8f0"
                  py="15px"
                >
                  <Box display="flex" justifyContent="end" w="100%">
                    <Stack direction="column" gap="0" w="100%">
                      {allergiesdata && allergiesdata.length > 0 ? (
                        allergiesdata.map((category) =>
                          category.data
                            .filter((condition) =>
                              condition.condition
                                .toLowerCase()
                                .includes(searchValue.toLowerCase())
                            )
                            .map((condition) => (
                              <label key={condition.condition_cd}>
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                borderBottom="1px solid #e2e8f0"
                                // borderRadius="10px"
                                p="15px"
                                height="50px"
                                width="100%"
                                sx={{
                                  _hover: {
                                    border: "2px solid #384052",
                                  },
                                }}
                              >
                                <Box
                                  key={condition.condition_cd}
                                  display="flex"
                                  alignItems="center"
                                >
                                  <Text
                                    fontSize="16px"
                                    fontWeight="600"
                                  >
                                    {condition.condition}
                                    <br />
                                    <Box
                                      as="span"
                                      fontSize="12px"
                                      fontWeight="500"
                                      color="#6A707D"
                                    ></Box>
                                  </Text>
                                </Box>
                                <Box
                                  display="flex"
                                  justifyContent="end"
                                >
                                  <Checkbox
                                    colorScheme="green"
                                    sx={{
                                      ".chakra-checkbox__control": {
                                        width: "20px",
                                        height: "20px",
                                        borderRadius: "5px",
                                      },
                                    }}
                                    onChange={() =>
                                      handleAllergiesCheckboxChange(
                                        condition
                                      )
                                    }
                                    isChecked={selectedAllergies?.some(
                                      (item) =>
                                        item.condition_cd ===
                                        condition.condition_cd
                                    )}
                                  ></Checkbox>
                                </Box>
                              </Box>
                              </label>
                            ))
                        )
                      ) : (
                        <p>Loading...</p>
                      )}
                       <Divider/>
          <br/>
          <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
                   Other Allergy
                  </Heading>
        <PrimaryInput
         inputPlace="Allergy"
         variant="bothSide"
                onChange={handleAllergy} inputValue={typedAllergy}
                />
                <br/>
                    </Stack>
                  </Box>
                </Box>
              </Stack>
            </RadioGroup>
          </>
        }
        footerActions={
          <>
            <Box w="100%" display="flex" flexDirection="row" gap="15px">
              <PrimaryButton
                buttonText="Continue"
                isDisabled={allergiesContinueBtnDisabled}
                onClick={closeAddAllergyModal}
              />
            </Box>
          </>
        }
      />

<ModalRight
          ref={Medicalconditionsmodel}
          header="Select Condition"
          modalWidth="360px"
          body={
            <>
              <SearchBar
                passInputPlace="Search condition"
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <RadioGroup>
                <Stack direction="column" gap="0">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    borderBottom="1px solid #e2e8f0"
                    py="15px"
                  >
                   <Box display="flex" justifyContent="end" w="100%">
  <Stack direction="column" gap="0" w="100%">
    {medicaldata && medicaldata.length > 0 ? (
      medicaldata.some(category =>
        category.data.some(condition =>
          condition.condition.toLowerCase().includes(searchValue.toLowerCase())
        )
      ) ? (
        medicaldata.map(category => (
          <React.Fragment key={category.category_id}>
            {category.data
              .filter(condition =>
                condition.condition.toLowerCase().includes(searchValue.toLowerCase())
              )
              .map(condition => (
                <label key={condition.condition_cd}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    borderBottom="1px solid #e2e8f0"
                                  // borderRadius="10px"
                    p="15px"
                    pl="5px"
                    minH="50px"
                    width="100%"
                    sx={{
                                    _hover: { border: "2px solid #384052", borderRadius: "10px" },
                    }}
                  >
                    <Box
                      key={condition.condition_cd}
                      display="flex"
                      alignItems="center"
                    >
                      <Text fontSize="16px" lineHeight="normal" fontWeight="600">
                        {condition.condition}
                        <br />
                        <Box
                          as="span"
                          fontSize="12px"
                          fontWeight="500"
                          color="#6A707D"
                        ></Box>
                      </Text>
                    </Box>
                    <Box display="flex" justifyContent="end">
                      <Checkbox
                        colorScheme="green"
                        sx={{
                          ".chakra-checkbox__control": {
                            width: "20px",
                            height: "20px",
                            borderRadius: "5px",
                          },
                        }}
                                      onChange={() =>
                                        handleCheckboxChange(condition, category)
                                      }
                        isChecked={selectedConditions && selectedConditions.some(
                                        (item) =>
                                          item.condition_cd ===
                                          condition.condition_cd
                        )}
                      ></Checkbox>
                    </Box>
                  </Box>
                </label>
              ))}
          </React.Fragment>
        ))
      ) : (
        <Box p="15px" fontSize="16px" fontWeight="600" textAlign="center">
         No matching conditions found!
        </Box>
      )
    ) : (
      <p>Loading...</p>
    )}
     <Divider/>
          <br/>
          <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
                   Other Condition
                  </Heading>
        <PrimaryInput
         inputPlace="Condition"
         variant="bothSide"
                onChange={handleCondition} inputValue={typedCondition}
                />
                <br/>
  </Stack>
</Box>
                  </Box>
                </Stack>
              </RadioGroup>
            </>
          }
          footerActions={<>
            <Box
              w="100%"
              display="flex"
              flexDirection="row"
              gap="15px">

              <PrimaryButton
                buttonText="Continue"
                isDisabled={false}
                onClick={AddMedicalConditions}
              />
            </Box>
          </>}
        />

<DynamicModal
          ref={dynamicModal}
          modalHeader="Delete Family History?"
          modalBody={
            <>
              <Text fontSize="14px" fontWeight="600">
                Are you sure you want to delete<br/>
                {title ? title : "-"} 
              </Text>
            </>
          }
          modalFooterBtn={
            <>
              <PrimaryButton
                variant="deleteBtn"
                buttonText="Delete"
                onClick={deletefamilyhistory}
              />
            </>
          }
        />

    </>
  )
}

export default FamilyHistory