import moment from "moment";
export default function formAPIRoleDescriptions({
    name,
    description,
    isactive,
    id
   
    
   

})
{
  return Object.freeze({
    name,
    description,
    isactive,
    id
  });
}