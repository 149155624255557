import React, {useState,useEffect} from 'react'
import {
    Box,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Checkbox,
    CheckboxGroup,
    Divider,
    Flex,
    HStack,
    Image,
    Input,
    Menu,
    MenuButton,
    MenuList,
    PinInput,
    PinInputField,
    Radio,
    RadioGroup,
    Spacer,
    Stack,
    Switch,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Tr,
    VStack,
  } from "@chakra-ui/react";
  import Dropdown from '../../components/dropdown/index';
import { PrimaryButton, Toaster } from '../../components';
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import {SubmitAvailbility} from '../../middleware/services/finalsteps';
import FloatDropdown from '../../components/float-dropdown';


const ScheduleComponent = ({ AvailabilityData, type, onSubmit, clinicVisitCheckbox, doctorVisitCheckbox }) => {

  const { showSuccessToast, showErrorToast } = Toaster();

    const [clinicalSchedule, setClinicalSchedule] = useState({});
    const [videoCallSchedule, setVideoCallSchedule] = useState({});
    const [clinicVisit, setClinicVisit] = useState(clinicVisitCheckbox);
    const [doctorVisit, setDoctorVisit] = useState(doctorVisitCheckbox);
    const [availabilityData, setAvailabilityData] = useState(AvailabilityData);
 
    const hours = Array.from({ length: 24 }, (_, i) => `${String(i).padStart(2, '0')}:00`); // Generates hours from 00:00 to 23:00
    const { isLoading, showLoader, dismissLoader } = useLoader();
  
        useEffect(() => {
        if (AvailabilityData && AvailabilityData.availtimings && AvailabilityData.availtimings.availablity) {
            const initialSchedule = createInitialSetup(AvailabilityData.availtimings.availablity);
            setClinicalSchedule(initialSchedule.clinicalvisits);
            setVideoCallSchedule(initialSchedule.videovisits);
        }
        }, [AvailabilityData]);

        useEffect(() => {
          setClinicVisit(clinicVisitCheckbox);
          setDoctorVisit(doctorVisitCheckbox);
        }, [clinicVisitCheckbox, doctorVisitCheckbox]);

      //   useEffect(() => {
      //     setVideoCallSchedule((prev) => {
      //         const updatedSchedule = { ...prev };
      //         ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].forEach(day => {
      //             if (doctorVisitCheckbox) {
      //                 updatedSchedule[day] = {
      //                     isEnabled: true,
      //                     times: updatedSchedule[day]?.times.length === 0 || updatedSchedule[day]?.times.some(time => time.from === null || time.to === null)
      //                         ? [{ from: '09:00', to: '18:00' }]
      //                         : updatedSchedule[day].times
      //                 };
      //             } else {
      //                 updatedSchedule[day] = {
      //                     isEnabled: false,
      //                     times: []
      //                 };
      //             }
      //         });
      //         ['Saturday', 'Sunday'].forEach(day => {
      //             updatedSchedule[day] = {
      //                 isEnabled: false,
      //                 times: []
      //             };
      //         });
      //         return updatedSchedule;
      //     });
      // }, [doctorVisitCheckbox]);
      
      useEffect(() => {
        setClinicalSchedule((prev) => {
            const updatedSchedule = { ...prev };
    
            // Handle Monday - Friday
            ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].forEach(day => {
                if (!updatedSchedule[day]) {
                    updatedSchedule[day] = { isEnabled: true, times: [{ from: '09:00', to: '18:00' }] };
                } else {
                    updatedSchedule[day] = {
                        ...updatedSchedule[day],
                        isEnabled: true,
                        times: updatedSchedule[day].times.length === 0 ? [{ from: '09:00', to: '18:00' }] : updatedSchedule[day].times
                    };
                }
            });
    
            // Handle Saturday and Sunday
            ['Saturday', 'Sunday'].forEach(day => {
                if (clinicVisitCheckbox) {
                    if (!updatedSchedule[day]) {
                        updatedSchedule[day] = { isEnabled: false, times: [] };
                    } else {
                        updatedSchedule[day] = {
                            ...updatedSchedule[day],
                            isEnabled: updatedSchedule[day].isEnabled || false,
                            times: updatedSchedule[day].times || []
                            // isEnabled: true,
                        // times: updatedSchedule[day].times.length === 0 ? [{ from: '09:00', to: '18:00' }] : updatedSchedule[day].times
                        };
                    }
                } else {
                    // updatedSchedule[day] = { isEnabled: false, times: [] };
                    setClinicalSchedule((prev) => {
                      const updatedSchedule = { ...prev };
                      ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].forEach(day => {
                          updatedSchedule[day] = {
                              isEnabled: false,
                              times: []
                          };
                      });
                      return updatedSchedule;
                  });
                }
            });
    
            return updatedSchedule;
        });
    }, [clinicVisitCheckbox]);

      useEffect(() => {
        setVideoCallSchedule((prev) => {
            const updatedSchedule = { ...prev };
    
            // Handle Monday - Friday
            ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].forEach(day => {
                if (!updatedSchedule[day]) {
                    updatedSchedule[day] = { isEnabled: true, times: [{ from: '09:00', to: '18:00' }] };
                } else {
                    updatedSchedule[day] = {
                        ...updatedSchedule[day],
                        isEnabled: true,
                        times: updatedSchedule[day].times.length === 0 ? [{ from: '09:00', to: '18:00' }] : updatedSchedule[day].times
                    };
                }
            });
    
            // Handle Saturday and Sunday
            ['Saturday', 'Sunday'].forEach(day => {
                if (doctorVisitCheckbox) {
                    if (!updatedSchedule[day]) {
                        updatedSchedule[day] = { isEnabled: false, times: [] };
                    } else {
                        updatedSchedule[day] = {
                            ...updatedSchedule[day],
                            isEnabled: updatedSchedule[day].isEnabled || false,
                            times: updatedSchedule[day].times || []
                            // isEnabled: true,
                        // times: updatedSchedule[day].times.length === 0 ? [{ from: '09:00', to: '18:00' }] : updatedSchedule[day].times
                        };
                    }
                } else {
                    // updatedSchedule[day] = { isEnabled: false, times: [] };
                    setVideoCallSchedule((prev) => {
                      const updatedSchedule = { ...prev };
                      ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].forEach(day => {
                          updatedSchedule[day] = {
                              isEnabled: false,
                              times: []
                          };
                      });
                      return updatedSchedule;
                  });
                }
            });
    
            return updatedSchedule;
        });
    }, [doctorVisitCheckbox]);
    
    
    // useEffect(() => {
    //     if (doctorVisitCheckbox) {
    //         setVideoCallSchedule((prev) => {
    //             const updatedSchedule = { ...prev };
    //             ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].forEach(day => {
    //                 if (!updatedSchedule[day]) {
    //                     updatedSchedule[day] = { isEnabled: true, times: [{ from: '09:00', to: '18:00' }] };
    //                 } else {
    //                     updatedSchedule[day] = {
    //                         ...updatedSchedule[day],
    //                         isEnabled: true,
    //                         times: updatedSchedule[day].times.length === 0 ? [{ from: '09:00', to: '18:00' }] : updatedSchedule[day].times
    //                     };
    //                 }
    //             });
    //             ['Saturday', 'Sunday'].forEach(day => {
    //                 updatedSchedule[day] = {
    //                     isEnabled: false,
    //                     times: []
    //                 };
    //             });
    //             return updatedSchedule;
    //         });
    //     } else {
    //         setVideoCallSchedule((prev) => {
    //             const updatedSchedule = { ...prev };
    //             ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].forEach(day => {
    //                 updatedSchedule[day] = {
    //                     isEnabled: false,
    //                     times: []
    //                 };
    //             });
    //             return updatedSchedule;
    //         });
    //     }
    // }, [doctorVisitCheckbox]);
    
    

        function createInitialSetup(availablity) {
        const initialSetup = {
            clinicalvisits: {},
            videovisits: {},
        };

        function processVisits(visits, type) {
            visits.forEach(visit => {
            const day = visit.day;
            // initialSetup[type][day] = {
            //     isEnabled: visit.isavailable === 1,
            //     times: visit.timings.map(time => ({
            //     from: time.fromtime,
            //     to: time.totime,
            //     id: time.id,
            //     })),
            initialSetup[type][day] = {
              isEnabled: visit.isavailable === 1,
              times: visit.timings.length > 0 ? visit.timings.map(time => ({
                  from: time.fromtime || "09:00",
                  to: time.totime || "18:00",
                  id: time.id,
              })) : [],
            };
            });
        }

        if (availablity.clinicalvisits) {
            processVisits(availablity.clinicalvisits, 'clinicalvisits');
        }

        if (availablity.videovisit) {
            processVisits(availablity.videovisit, 'videovisits');
        }

        return initialSetup;
        }
        const handleDropdownChange = (scheduleType, value, day, index, field) => {
          const timeFormatToNumber = (time) => {
            const [hours, minutes] = time.split(":");
            return parseInt(hours) * 60 + parseInt(minutes); // Convert time to minutes
          };
        
          const checkForDuplicateTimes = (updatedTimes) => {
            return updatedTimes.some((time, i) => 
              i !== index && time.from === updatedTimes[index].from && time.to === updatedTimes[index].to
            );
          };
        
          if (scheduleType === 'clinical') {
            setClinicalSchedule((prev) => {
              const updatedTimes = prev[day].times.map((time, i) => {
                if (i === index) {
                  // Ensure "from" is less than "to" and "to" is greater than "from"
                  if (field === 'from' && timeFormatToNumber(value) >= timeFormatToNumber(time.to)) {
                    showErrorToast("From time should be less than To time.");
                    return time;
                  }
                  if (field === 'to' && timeFormatToNumber(value) <= timeFormatToNumber(time.from)) {
                    showErrorToast("To time should be greater than From time.");
                    return time;
                  }
                  return { ...time, [field]: value };
                }
                return time;
              });
        
              if (checkForDuplicateTimes(updatedTimes)) {
                showErrorToast("This time slot already exists. Please select a different time.");
                return prev; // Prevent updating if a duplicate is found
              }
        
              return {
                ...prev,
                [day]: {
                  ...prev[day],
                  times: updatedTimes,
                },
              };
            });
          } else {
            setVideoCallSchedule((prev) => {
              const updatedTimes = prev[day].times.map((time, i) => {
                if (i === index) {
                  if (field === 'from' && timeFormatToNumber(value) >= timeFormatToNumber(time.to)) {
                    showErrorToast("From time should be less than To time.");
                    return time;
                  }
                  if (field === 'to' && timeFormatToNumber(value) <= timeFormatToNumber(time.from)) {
                    showErrorToast("To time should be greater than From time.");
                    return time;
                  }
                  return { ...time, [field]: value };
                }
                return time;
              });
        
              if (checkForDuplicateTimes(updatedTimes)) {
                showErrorToast("This time slot already exists. Please select a different time.");
                return prev; // Prevent updating if a duplicate is found
              }
        
              return {
                ...prev,
                [day]: {
                  ...prev[day],
                  times: updatedTimes,
                },
              };
            });
          }
        };
        
        const addTimeSlot = (scheduleType, day) => {
          const checkForDuplicateTimes = (schedule) => {
            return schedule[day].times.some((time) => time.from === "09:00" && time.to === "18:00");
          };
        
          if (scheduleType === 'clinical') {
            setClinicalSchedule((prev) => {
              if (checkForDuplicateTimes(prev)) {
                showErrorToast("This time slot already exists. Please select a different time.");
                return prev;
              }
        
              return {
                ...prev,
                [day]: {
                  ...prev[day],
                  times: [...prev[day].times, { from: "09:00", to: "18:00" }]
                }
              };
            });
          } else {
            setVideoCallSchedule((prev) => {
              if (checkForDuplicateTimes(prev)) {
                alert("This time slot already exists. Please select a different time.");
                return prev;
              }
        
              return {
                ...prev,
                [day]: {
                  ...prev[day],
                  times: [...prev[day].times, { from: "09:00", to: "18:00" }]
                }
              };
            });
          }
        };
        
    // -------------------------------------------------
        // don't delete this
        // Previous code for dropdown
        // const handleDropdownChange = (scheduleType, value, day, index, field) => {
        //   if (scheduleType === 'clinical') {
        //     setClinicalSchedule((prev) => ({
        //       ...prev,
        //       [day]: {
        //         ...prev[day],
        //         times: prev[day].times.map((time, i) =>
        //           i === index ? { ...time, [field]: value } : time
        //         )
        //       }
        //     }));
        //   } else {
        //     setVideoCallSchedule((prev) => ({
        //       ...prev,
        //       [day]: {
        //         ...prev[day],
        //         times: prev[day].times.map((time, i) =>
        //           i === index ? { ...time, [field]: value } : time
        //         )
        //       }
        //     }));
        //   }
        // };
    // ---------------------------------------
      //   const handleDropdownChange = (scheduleType, value, day, index, field) => {
      //     const timeFormatToNumber = (time) => {
      //       const [hours, minutes] = time.split(":");
      //       return parseInt(hours) * 60 + parseInt(minutes); // Convert time to minutes
      //     };
        
      //     if (scheduleType === 'clinical') {
      //       setClinicalSchedule((prev) => {
      //         const updatedTimes = prev[day].times.map((time, i) => {
      //           if (i === index) {
      //             if (field === 'from' && timeFormatToNumber(value) >= timeFormatToNumber(time.to)) {
      //               showErrorToast("From time should be less than To time.");
      //               return time;
      //             }
      //             if (field === 'to' && timeFormatToNumber(value) <= timeFormatToNumber(time.from)) {
      //               showErrorToast("To time should be greater than From time.");
      //               return time;
      //             }
      //             return { ...time, [field]: value };
      //           }
      //           return time;
      //         });
        
      //         return {
      //           ...prev,
      //           [day]: {
      //             ...prev[day],
      //             times: updatedTimes,
      //           },
      //         };
      //       });
      //     } else {
      //       setVideoCallSchedule((prev) => {
      //         const updatedTimes = prev[day].times.map((time, i) => {
      //           if (i === index) {
      //             // Compare "from" and "to" times when updating
      //             if (field === 'from' && timeFormatToNumber(value) >= timeFormatToNumber(time.to)) {
      //               alert("From time should be less than To time.");
      //               return time;
      //             }
      //             if (field === 'to' && timeFormatToNumber(value) <= timeFormatToNumber(time.from)) {
      //               alert("To time should be greater than From time."); 
      //               return time;
      //             }
      //             return { ...time, [field]: value };
      //           }
      //           return time;
      //         });
        
      //         return {
      //           ...prev,
      //           [day]: {
      //             ...prev[day],
      //             times: updatedTimes,
      //           },
      //         };
      //       });
      //     }
      //   };
        

      // const addTimeSlot = (scheduleType, day) => {
      //   if (scheduleType === 'clinical') {
      //     setClinicalSchedule((prev) => ({
      //       ...prev,
      //       [day]: {
      //         ...prev[day],
      //         times: [...prev[day].times, { from: "09:00", to: "18:00" }]
      //       }
      //     }));
      //   } else {
      //     setVideoCallSchedule((prev) => ({
      //       ...prev,
      //       [day]: {
      //         ...prev[day],
      //         times: [...prev[day].times, { from: "09:00", to: "18:00" }]
      //       }
      //     }));
      //   }
      // };
      // const removeTimeSlot = (scheduleType, day, index) => {
      //   if (scheduleType === 'clinical') {
      //     setClinicalSchedule((prev) => ({
      //       ...prev,
      //       [day]: {
      //         ...prev[day],
      //         times: prev[day].times.find((_, i) => _.isdelete = 1)
      //       }
      //     }));
      //   } else {
      //     setVideoCallSchedule((prev) => ({
      //       ...prev,
      //       [day]: {
      //         ...prev[day],
      //         times: prev[day].times.find((_, i) => _.isdelete = 1)
      //       }
      //     }));
      //   }
      // };
      const removeTimeSlot = (scheduleType, day, index) => {
        if (scheduleType === 'clinical') {
          setClinicalSchedule((prev) => {
            const updatedTimes = [...prev[day].times];
            console.log("updatedTimes:", updatedTimes)
            if (updatedTimes[index].id) {
              updatedTimes[index].isdelete = 1;
            } else {
              updatedTimes.splice(index, 1);
            }
      
            return {
              ...prev,
              [day]: {
                ...prev[day],
                times: updatedTimes,
              },
            };
          });
        } else {
          setVideoCallSchedule((prev) => {
            const updatedTimes = [...prev[day].times];
            // console.log("updatedTimesBefore:", updatedTimes)
            
            if (updatedTimes[index].id) {
              updatedTimes[index].isdelete = 1;
            } else {
              updatedTimes.splice(index, 1);
            }
            
            // console.log("updatedTimesAfter:", updatedTimes)
            return {
              ...prev,
              [day]: {
                ...prev[day],
                times: updatedTimes,
              },
            };
          });
        }
      };
      
      const switchClicked = (scheduleType, day) => {
        if (scheduleType === 'clinical') {
          setClinicalSchedule((prev) => ({
            ...prev,
            [day]: {
              ...prev[day],
              isEnabled: !prev[day].isEnabled,
               times: prev[day].isEnabled ? [] : prev[day].times.length === 0 ? [{ from: "09:00", to: "18:00" }] : prev[day].times
              // times: !prev[day].isEnabled
              //       ? [{ from: "09:00", to: "18:00" }]
              //       : []
            }
          }));
        } else {
          setVideoCallSchedule((prev) => ({
            ...prev,
            [day]: {
              ...prev[day],
              isEnabled: !prev[day].isEnabled,
              times: prev[day].isEnabled ? [] : prev[day].times.length === 0 ? [{ from: "09:00", to: "18:00" }] : prev[day].times
              // times: !prev[day].isEnabled && (day === 'Saturday' || day === 'Sunday') ? [{ from: "09:00", to: "18:00" }] :[]
              // times: !prev[day].isEnabled ? (day === 'Saturday' || day === 'Sunday' ? [] : [{ from: "09:00", to: "18:00" }]) : []
              // times: !prev[day].isEnabled
              //       ? [{ from: "09:00", to: "18:00" }]
              //       : []
            }
          }));
        }
      };
      const renderSchedule = (scheduleType, schedule, isEnabled) => (
        <Box
          display="flex"
          w="100%"
          gap="1rem"
          flexDir="column"
          border="1px solid #E6E7E9"
          borderRadius="0.75rem"
          p="1rem 1.25rem"
        >
          <Text
            fontSize="0.75rem"
            fontWeight="600"
            textTransform="uppercase"
            textAlign="center"
          >
            {scheduleType === "clinical" ? "For Clinic visits" : "For video calls"}
          </Text>
          {Object.keys(schedule).map((day) => (
            <React.Fragment key={day}>
              <Box
                key={day}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" gap="0.75rem" alignItems="center">
                  <Switch
                    colorScheme="green"
                    size="lg"
                    isChecked={isEnabled && schedule[day].isEnabled}
                    onChange={() => switchClicked(scheduleType, day)}
                  />
                  <Text fontSize="0.875rem" fontWeight="600">
                    {day}
                  </Text>
                  {/* {day === "Monday" && renderApplyButton(scheduleType)} */}
                </Box>
                {isEnabled && schedule[day].isEnabled ? (
                  <Box w="55%" display="flex" flexDir="column" gap="0.5rem">
                    {schedule[day].times
                      .filter((time) => time.isdelete == undefined)
                      .map((time, index) => (
                        <Box
                          key={index}
                          display="flex"
                          alignItems="center"
                          gap="0.5rem"
                        >
                          <Dropdown
                            options={hours}
                            placeholder="From"
                            value={time.from}
                            onClick={(value) =>
                              handleDropdownChange(
                                scheduleType,
                                value,
                                day,
                                index,
                                "from"
                              )
                            }
                          />
                          <Text fontSize="1.75rem">-</Text>
                          <Dropdown
                            options={hours}
                            placeholder="To"
                            value={time.to}
                            onClick={(value) =>
                              handleDropdownChange(
                                scheduleType,
                                value,
                                day,
                                index,
                                "to"
                              )
                            }
                          />
                          {index === 0 ? (
                            <Box
                              w="1.75rem"
                              ml="0.5rem"
                              as="button"
                              onClick={() => addTimeSlot(scheduleType, day)}
                            >
                              <Image w="100%" src="/assets/svgs/add.svg" />
                            </Box>
                          ) : (
                            <Box
                              w="1.95rem"
                              ml="0.5rem"
                              as="button"
                              onClick={() =>
                                removeTimeSlot(scheduleType, day, index)
                              }
                            >
                              <Image w="100%" src="/assets/svgs/delete.svg" />
                            </Box>
                          )}
                          {/* Add FloatDropdown here */}
                          <Menu>
                            <MenuButton as="button" cursor="pointer">
                              <Image
                                w="1.5rem"
                                src="/assets/imgs/copy.png"
                                alt="Copy Times To"
                              />
                            </MenuButton>
                            <MenuList>
                              <FloatDropdown
                                day={day}
                                scheduleType={scheduleType}
                                schedule={schedule}
                                updateTimesForDays={updateTimesForDays}
                              />
                            </MenuList>
                          </Menu>
                        </Box>
                      ))}
                  </Box>
                ) : (
                  <Box w="55%">
                    <Text fontSize="1rem">&nbsp;</Text>
                  </Box>
                )}
              </Box>
            </React.Fragment>
          ))}
        </Box>
      );
      
    
    const applyMondayToAll = (scheduleType) => {
      const setSchedule = scheduleType === "clinical" ? setClinicalSchedule : setVideoCallSchedule;
      setSchedule(prev => {
        // const mondayTimes = prev["Monday"]?.times || [];
        const mondayTimes = prev["Monday"]?.times.map(time => ({ ...time })) || [];
        const updatedSchedule = { ...prev };
        ["Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].forEach(day => {
          updatedSchedule[day] = {
            ...updatedSchedule[day],
            times: mondayTimes.map(time => ({ ...time, id: updatedSchedule[day].times?.find(t => t.id)?.id })),
          };
        });
        return updatedSchedule;
      });
    };
    const updateTimesForDays = (sourceDay, targetDays, scheduleType) => {
      const setSchedule = scheduleType === "clinical" ? setClinicalSchedule : setVideoCallSchedule;
      const schedule = scheduleType === "clinical" ? clinicalSchedule : videoCallSchedule;
    
      // Get times from the source day
      const sourceTimes = schedule[sourceDay]?.times.map((time) => ({ ...time })) || [];
    
      // Update times for the selected days
      const updatedSchedule = { ...schedule };
      targetDays.forEach((targetDay) => {
        updatedSchedule[targetDay] = {
          ...updatedSchedule[targetDay],
          isEnabled: true, // Enable the day if it’s disabled
          times: sourceTimes.map((time) => ({ ...time, id: updatedSchedule[targetDay].times?.find((t) => t.id)?.id })),
        };
      });
    
      // Update the state
      setSchedule(updatedSchedule);
      // setHasChangesMade(true);
    };
    
    const renderApplyButton = (scheduleType) => {

      const shouldDisplayButton =
        (scheduleType === 'clinical' && clinicVisit === 1) ||
        (scheduleType === 'video' && doctorVisit === 1);

        console.log('shouldDispalyBugtton: ', shouldDisplayButton)
    
      return shouldDisplayButton ? (
        <Box as="button" onClick={() => applyMondayToAll(scheduleType)}>
          <Text
            fontSize="0.65rem"
            fontWeight="600"
            textDecor="underline"
            letterSpacing="0.10rem"
          >
            Apply for All
          </Text>
        </Box>
      ) : null;
    };

      // const handleSubmitClinic = async () => {
      //   showLoader()
      //   const clinicalResult = {
      //     data: Object.keys(clinicalSchedule).map((day) => ({
      //       day: day,
      //       appointmenttype_cd: 693, // Appointment type code for clinical visit
      //       appointmenttype: "clinical visit",
      //       isavailable: clinicalSchedule[day].isEnabled ? 1 : 0,
      //       timings: clinicalSchedule[day].times.map((time) => ({
      //         fromtime: time.from,
      //         totime: time.to,
      //         id: time.id
      //       }))
      //     })),
      //   };
    
      //   let input = {
      //       "doctorid": "",
      //       "timezone_cd": "",
      //       "type":"update",
      //       clinicalvisits: clinicalResult.data,
      //     };
      //   try {
      //       const result = await SubmitAvailbility(input);
      //       if (result) {
      //         console.log(result);
      //         setTimeout(() => {
      //           const newActiveComponent = "videotab";
      //           onSubmit(newActiveComponent);
      //         }, 1000);
      //       }
      //     dismissLoader();
      //   } catch (error) {
      //     console.log(error, "Something went wrong in doctor profile")
      //   }
      // };

      const handleSubmitVideo = async () => {

    showLoader()

        const clinicalResult = {
          data: Object.keys(clinicalSchedule).map((day) => ({
            day: day,
            appointmenttype_cd: 693, // Appointment type code for clinical visit
            appointmenttype: "clinical visit",
            isavailable: clinicalSchedule[day].isEnabled ? 1 : 0,
            timings: clinicalSchedule[day].times.map((time) => {
              const timing = {
                fromtime: time.from,
                totime: time.to,
                id: time.id,
              };
              if (time.isdelete) {
                timing.isdelete = 1;
              }
              return timing;
            })
          })),
        };
        const videoCallResult = {
          data: Object.keys(videoCallSchedule).map((day) => ({
            day: day,
            appointmenttype_cd: 694, // Appointment type code for video call visit
            appointmenttype: "video call",       
            isavailable: videoCallSchedule[day].isEnabled ? 1 : 0,
            timings: videoCallSchedule[day].times.map((time) => {
              const timing = {
                fromtime: time.from,
                totime: time.to,
                id: time.id,
              };
              if (time.isdelete) {
                timing.isdelete = 1;
              }
              return timing;
            })
          })),
        };
        let input = {
            "doctorid": "",
            "timezone_cd": "",
            "type":"update",
            videovisit: videoCallResult.data,
            clinicalvisits: clinicalResult.data,
          };
          // console.log('Input object:', JSON.stringify(input, null, 2));
        try {
            const result = await SubmitAvailbility(input);
            if (result) {
              console.log(result);
              setTimeout(() => {
                const newActiveComponent = "updatecompleted";
                onSubmit(newActiveComponent);
              }, 1000);
            }
          dismissLoader();
        } catch (error) {
          console.log(error, "Something went wrong in doctor profile")
        }
      };
    return (
<>
<Box display='flex' flexDir="column" gap="1rem">
        {isLoading && <Loader />}
        <Box display="flex" justifyContent="space-between" w="100%" gap="1.5rem">
        {renderSchedule('clinical', clinicalSchedule,clinicVisit)}
        {/* <Divider orientation="vertical" h="auto"  /> */}
        {renderSchedule('video', videoCallSchedule, doctorVisit)}
        {/* {type === "clinic" &&<PrimaryButton btnStyle={{mt: "1rem"}} buttonText="Save clinic visit hours"onClick={handleSubmitClinic}/>} */}
        </Box>
        <Box>
        <PrimaryButton btnStyle={{mt: "1rem"}} buttonText="Save availability"onClick={handleSubmitVideo}/>

        </Box>
      </Box>
        </>
    );
  };

export default ScheduleComponent 
