
import formAPIpendingvisits from "./pendingvisit";


var formattedOutput = null;

const formAPIModelpendingvisits = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIpendingvisits(item));


  return formattedOutput
};

export default formAPIModelpendingvisits