import moment from "moment";
export default function formapiorderdetails({
    prescribedmedicineid,
    patientname,
    patientimage,
    appointmentid,
    patientid,
    bookingid,
    imagepath,
    prescribeddate,
    prescriptionurl,
    medicinename,
    duration,
    quantity,
    costperunit,
    costperstrip,
    costperbox,
    finalprice,
    prescriptionid,
    ordername,
    orderid,
    inventoryid,
    totalamount,
    appointmenttime,
    appointmentdate,
    paymenttype,
    doctorname,
    appointmenttype,
    appointmentstatusname,
    totalSgst,
    totalCgst,
    cgst,
    sgst,
    medicationstartdate,
    medicationenddate,
    frequency,
    morning,
    evening,
    night,
    quantityineachstrip,
    batchnumber,
    remainingquantity

})
{
  return Object.freeze({
    prescribedmedicineid,
    patientname,
    patientimage,
    appointmentid,
    patientid,
    bookingid,
    imagepath,
    prescribeddate,
    prescriptionurl,
    medicinename,
    duration,
    quantity,
    costperunit,
    costperstrip,
    costperbox,
    finalprice,
    prescriptionid,
    ordername,
    orderid,
    inventoryid,
    totalamount,
    appointmenttime,
    appointmentdate,
    paymenttype,
    doctorname,
    appointmenttype,
    appointmentstatusname,
    totalSgst,
    totalCgst,
    cgst,
    sgst,
    medicationstartdate,
    medicationenddate,
    frequency,
    morning,
    evening,
    night,
    quantityineachstrip,   
    batchnumber,
    remainingquantity
  });
}