export default function processInvestigationsData(investigations, visitDate) {
  if (!Array.isArray(investigations)) {
    return [];
  }

  const filteredData = visitDate
    ? investigations.filter((investigation) => {
        const investigationDate = investigation.verifieddate.split("T")[0]; // Extract date part
        const visitDateOnly = visitDate.split("T")[0]; // Extract date part
        return investigationDate === visitDateOnly;
      })
    : investigations;

  const processedData = filteredData.map((investigation) => ({
    name: investigation.eventtitle,
    value: investigation.actualresultvalue
      ? investigation.actualresultvalue
      : "",
    unit: investigation.resultuom,
    date: investigation.verifieddate,
    status: investigation.STATUS,
  }));

  // Sort the processed data by date in reverse order (descending)
  processedData.sort((a, b) => new Date(b.date) - new Date(a.date));

  return processedData;
}
