import React, { useEffect, useRef } from 'react';
import { Line } from '@antv/g2plot';

const AppointmentsChart = ({ data, height }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const linePlot = new Line(chartRef.current, {
      data,  
      xField: 'label',  
      yField: 'value',  
      seriesField: 'type',  
      color: ({ type }) => {
        if (type === 'Completed') return '#1C4EC5';  
        if (type === 'Cancelled') return '#14C585';  
      },
      lineStyle: {
        lineWidth: 2,  
      },
      legend: {
        position: 'top-right',  
        // items: {
        //   marker: {
        //     symbol: 'circle',  // Change the legend marker to a circle
        //     style: {
        //       r: 6,  // Radius of the circle marker
        //     },
        //   },
        // },
        custom: true,
        items: [
          { value: 'Completed', name: 'Completed', marker: { style: { fill: '#1C4EC5' } } },
          { value: 'Cancelled', name: 'Cancelled', marker: { style: { fill: '#14C585' } } },
        ],
      },
      tooltip: {
        shared: true,
        showMarkers: false,  // Optional: hide markers
      },
      yAxis: {
        title: {
          text: 'No. of bookings',  // Label for Y-axis
        },
      },
    });

    linePlot.render();  

    return () => {
      linePlot.destroy(); 
    };
  }, [data]);

  return <div ref={chartRef} style={{ height }}></div>;
};

export default AppointmentsChart;
