import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Stack,
  StackDivider,
  Box,
  Image,
  Spacer,
} from "@chakra-ui/react";

function GrayCard({
  hasFooter = true,
  cardHeader,
  hasHeader = true,
  cardHeaderIcon,
  cardBody,
  cardFooterText,
  bodyStyle,
  onClick,
  noIcon = false,
  withIcon = true,
  cardFooterClickEvent,
  simpleFooter = false,
  footerArea,
  headerBg = "#F9FAFB",
  cardMinHeight = "180px",
  cardMaxHeight,
  ...props
}) {
  return (
    <Card
      borderRadius="12px"
      minH={cardMinHeight}
      maxH={cardMaxHeight}
      w="100%"
      border="1px solid #E6E7E9"
      boxShadow="0px 8px 20px 0px #E6E7E966"
      {...props}
    >
      {hasHeader &&
        <CardHeader
          display="flex"
          justifyContent="space-between"
          borderRadius="10px 10px 0 0"
          h="44px"
          p="15px"
          bg={headerBg}
          borderBottom="1px solid #E6E7E9"
          letterSpacing="1px"
        >
          <Box>
            <Heading textTransform="uppercase" fontSize="12px" fontWeight="600">
              {cardHeader}
            </Heading>
          </Box>
          <Box>
            {noIcon && noIcon}

            {withIcon && <Image src={cardHeaderIcon} />}
          </Box>
        </CardHeader>
      }
      <CardBody {...bodyStyle}>{cardBody}</CardBody>

      {hasFooter && (
        <CardFooter
          h="50px"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          p="15px"
          borderTop="1px solid #E6E7E9"
          onClick={cardFooterClickEvent}
        >
          <Box
            as="button"
            onClick={onClick}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <Box color="#1C4EC5" mr="8px" fontSize="14px" fontWeight="600">
              {cardFooterText}
            </Box>
            <Box>
              <Image src="/assets/imgs/right-blue-arrow.png" />
            </Box>
          </Box>
        </CardFooter>
      )}

      {simpleFooter && <CardFooter w="100%">{footerArea}</CardFooter>}
    </Card>
  );
}

export default GrayCard;
