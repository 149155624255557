import React, { useState } from "react";
import { Box, Checkbox, Button, Text } from "@chakra-ui/react";
import PrimaryButton from "../primary-button";

const FloatDropdown = ({ day, scheduleType, schedule, updateTimesForDays }) => {
  const [selectedDays, setSelectedDays] = useState([]);

  const handleCheckboxChange = (selectedDay) => {
    setSelectedDays((prev) =>
      prev.includes(selectedDay)
        ? prev.filter((day) => day !== selectedDay)
        : [...prev, selectedDay]
    );
  };

  const handleApply = () => {
    updateTimesForDays(day, selectedDays, scheduleType);
    setSelectedDays([]); // Clear selection after applying
  };

  return (
    <Box p="1rem" boxShadow="md" borderRadius="md" bg="white">
      <Text fontWeight="600" mb="0.5rem">Copy times to:</Text>
      {Object.keys(schedule).map((scheduleDay) => (
        <Box key={scheduleDay}>
          <Checkbox
            isChecked={selectedDays.includes(scheduleDay)}
            isDisabled={scheduleDay === day} // Disable the current day
            onChange={() => handleCheckboxChange(scheduleDay)}
          >
            {scheduleDay}
          </Checkbox>
        </Box>
      ))}
      {/* <Button
        mt="1rem"
        w="100%"
        colorScheme="blue"
        isDisabled={selectedDays.length === 0}
        onClick={handleApply}
      >
        Apply
      </Button> */}
      <PrimaryButton buttonText="Apply" btnStyle={{ w: "full" , h: "40px" }} mt={4} isDisabled={selectedDays.length === 0} onClick={handleApply} />
    </Box>
  );
};

export default FloatDropdown;
