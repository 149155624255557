import React from "react";
import {
  Box,
  Text,
  Image,
  Circle,
  Center,
  Link,
  Divider,
} from "@chakra-ui/react";
import moment from "moment";

const formatDate = (dateString) => {
  if (!dateString) return "-";
  return moment(dateString).format("Do MMM, YYYY");
};

const fileTypeMappings = {
  pdf: "/assets/svgs/pdf-icon.svg",
  png: "/assets/imgs/png-icon.png",
  jpg: "/assets/imgs/jpg-icon.png",
  jpeg: "/assets/imgs/jpg-icon.png",
};

const getFileIcon = (fileName) => {
  const extension = fileName.split(".").pop().toLowerCase();
  return fileTypeMappings[extension] || "/assets/svgs/file-icon.svg"; // fallback image
};

const PastTreatmentPlan = ({
  diagnosis,
  plan,
  scaleHeight = "auto",
  componentBg = "#fcfcfd",
  cardBg = "white",
}) => {
  return (
    <Box
      display="flex"
      flexDir="column"
      alignItems="center"
      w="100%"
      bg={componentBg}
    >
      <Box display="flex" w="100%" mt="10px">
        <Box w="100%">
          <Box
            p="20px"
            w="100%"
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="space-between"
            border="1px solid #E6E7E9"
            borderRadius="12px"
            backgroundColor={cardBg}
          >
            <Box display="flex" alignItems="center" w="100%">
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                flex="1"
                overflow="hidden" // Hide overflowing content
              >
                <Text
                  title={diagnosis}
                  fontSize="16px"
                  textAlign="left"
                  fontWeight="600"
                  whiteSpace="normal" // Allow text to wrap
                  overflow="hidden"
                  textOverflow="clip" // Remove text overflow indication (e.g., ellipsis)
                >
                  {diagnosis}
                </Text>
              </Box>
            </Box>
            <Box display="flex" alignItems="center" w="100%">
              <Box
                mt={"3px"}
                display="flex"
                alignItems="center"
                backgroundColor={"#f9fafb"}
                borderLeft={"3px solid #14c585"}
                w="100%"
              >
                <Text
                  fontSize="14px"
                  fontWeight="500"
                  color="#384052"
                  whiteSpace="normal" // Allow text to wrap
                  overflow="hidden"
                  textOverflow="clip" // Remove text overflow indication (e.g., ellipsis)
                  pl={"4px"}
                >
                  {plan}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PastTreatmentPlan;
