import React, { useEffect, useRef, useState } from "react";
import { Flex, VStack, Box, Image, Center, Input, Text, Button } from "@chakra-ui/react";
import {
  DropdownInput,
  PasswordInput,
  PrimaryButton,
  PrimaryInput,
  PrimaryInputDropdown,
  Toaster,
} from "../../components";
import MiniDropdownInput from "../../components/mini-dropdown";
import DatePicker from "../../components/date-picker";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import useLoader from "../../components/loader/loaderstates";
import { signUpPersonalDetails } from "../../middleware/services/signup";
import Loader from "../../components/loader/loader";
import moment from "moment";
import { updatePatientProfileOnChange, uploadImageInProfile } from "../../middleware/services/profile";

import ImageCropper from '../profile/ImageCropper';
import { getCountryCodeFromCountriesList, getCroppedImg } from "../../auth";
import DynamicModal from "../../components/dynamic-modal";

const PersonalDetails = ({ onSubmit }) => {

  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [gender, setGender] = useState(null)
  const [mobileno, setMobileno] = useState('')
  const [email, setEmail] = useState('')
  const [countryid, setCountryid] = useState('')
  const [countrycode, setCountrycode] = useState('')
  const [dob, setDob] = useState(null)
  const [defaultGender, setDefaultGender] = useState(null)
  const [password, setPassword] = useState("")

  const [doctorImg, setDoctorImg] = useState("");
  const [doctorImgInput, setDoctorImgInput] = useState("");
  const [imgUrl, setImgUrl] = useState('');
  const [showCropper, setShowCropper] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const inputRef = useRef(null);
  const editPhoto = useRef(null);

  const { isLoading, showLoader, dismissLoader } = useLoader();
  const { showSuccessToast, showErrorToast } = Toaster();


  const navigate = useNavigate();

  useEffect(() => {
    var storedPersonalDetails = localStorage.getItem("personalDetails");

    // Parse the string back into an object
    var detailsFromLocal = JSON.parse(storedPersonalDetails);
    if (detailsFromLocal) {
      setFirstname(detailsFromLocal.firstname)
      setLastname(detailsFromLocal.lastname)
      setMobileno(detailsFromLocal.mobileno)
      setEmail(detailsFromLocal.email)
      setPassword(detailsFromLocal.password)
      setDoctorImgInput(detailsFromLocal.doctorImg)
      // setImgUrl(detailsFromLocal.doctorImg);
      // setFinalImage(detailsFromLocal.finalImage)
      // var formattedDate = moment(detailsFromLocal.dob).format("DD-MM-YYYY")
      // setDob(formattedDate)
      if (detailsFromLocal.gender == "2") {
        setDefaultGender("Male")
      } else if (detailsFromLocal.gender == "1") {
        setDefaultGender("Female")
      } else if (detailsFromLocal.gender == "3") {
        setDefaultGender("Others")
      }

    }


    var countryid = localStorage.getItem("countrycode")

    getCountryCodeFromCountriesList(countryid).then(countryCode => {
    setCountrycode(countryCode)
    setCountryid(countryid)
    })

    // if (countrycode == "+1") {
    //   setCountrycode("+1")
    //   setCountryid("229")
    // } else if (countrycode == "+93") {
    //   setCountrycode("+93")
    //   setCountryid("1")
    // } else if (countrycode == "+91") {
    //   setCountrycode("+91")
    //   setCountryid("95")
    // }

  }, [])
  
  const handleChangeFirstName = (event) => {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[^A-Za-z\s]/g, '').slice(0, 50);
    event.target.value = inputValue;
    setFirstname(event.target.value);
  };
  const handleLastName = (event) => {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[^A-Za-z\s]/g, '').slice(0, 50);
    event.target.value = inputValue;
    setLastname(event.target.value);
  };
  const selectGender = (selectedOption) => {
    if (selectedOption == "Male") {
      setGender("2")
    } else if (selectedOption == "Female") {
      setGender("1")
    } else if (selectedOption == "Others") {
      setGender("3")
    }
  };
  const handleDateChange = (date) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    setDob(formattedDate);
  };
  const handleMobileChange = (e) => {
    var countryCode = localStorage.getItem("countrycode")
    var countryId = localStorage.getItem("countryid")
    
    setCountryid(countryCode)
    setCountrycode(countryId)

    // var countrycode = localStorage.getItem("countryid")
    // if (countrycode == "+1") {
    //   setCountrycode("+1")
    //   setCountryid("229")
    // } else if (countrycode == "+93") {
    //   setCountrycode("+93")
    //   setCountryid("1")
    // } else if (countrycode == "+91") {
    //   setCountrycode("+91")
    //   setCountryid("95")
    // }

    setMobileno(e.target.value.replace(/[^0-9]/g, "").slice(0, 10));

  }
  const handleChangeEmail = (event) => {
    const sanitizedValue = event.target.value.replace(/[^\x00-\x7F]/g, '')
    setEmail(sanitizedValue);
  };
  const handleChangePassword = (event) => {
    setPassword( event.target.value);
  };
  const handleEmailpassKeyPress = (e) => {
    if (e.key === "Enter") {
      SubmitFunction();
    }
  };

  var inputForSignUpPersonalDetails = {
    firstname,
    lastname,
    gender,
    dob,
    mobileno,
    email,
    "countryid": countryid ? countryid : "229",
    "countrycode": countrycode ? countrycode : "+1",
    password,
    doctorphoto: doctorImgInput


    // "fullname": "John Doe",
    // "usercountryid": 1,
    // "password": "password123",
    // "doctorphoto": "https://example.com/doctorphoto.jpg",
    // "idtoken": "some_id_token",
    // "type": "social_type",
    // "id": "social_id"
  }

  const SubmitFunction = async () => {

    if (firstname == "") {
      showErrorToast("Please Enter Name")
      return false;
    } else if (mobileno == "") {
      showErrorToast("Please Enter Mobile Number ")
      return false;
    } else if (email == "") {
      showErrorToast("Please Enter Email Address ")
      return false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      showErrorToast("Please Enter a Valid Email Address");
      return false;
    }else if (password == "") {
      showErrorToast("Please Enter Password ")
      return false;
    }else if (password.length < 6) {
      showErrorToast("Password should contain atleast 6 characters")
      return false;
    }
    else {
      showLoader();
      try {
        var countryCode = localStorage.getItem("countrycode")
        var countryId = localStorage.getItem("countryid")
        
        inputForSignUpPersonalDetails.countrycode = countryId
        inputForSignUpPersonalDetails.countryid = countryCode

        localStorage.setItem("personalDetails", JSON.stringify(inputForSignUpPersonalDetails));
       console.log(inputForSignUpPersonalDetails, " inputForSignUpPersonalDetails")
        const result = await signUpPersonalDetails(inputForSignUpPersonalDetails);
        console.log(result, " result personal details")
        if (result && result.output && result.output.id) {
          localStorage.setItem("doctorid", result.output.id)
        }
        if (result.output.message == "Successfully Registered") {

          showSuccessToast("Successfully Registered")
          setTimeout(() => {

            const newActiveComponent = "professional";
            onSubmit(newActiveComponent);
          }, 1000);

        } else if (result.output.message == "Email already exists") {

          showErrorToast("Email already exists")
          dismissLoader();
          return false;

        } else if (result.output.message == "Mobile number already exists") {

          showErrorToast("Mobile Number already exists.")

          dismissLoader();
          return false;

        } else {
          console.error(result, "Something went wrong in Submit.")

          dismissLoader();
          showErrorToast("Something went wrong!")
        }

      } catch (error) {
        console.error('Error in SubmitFunction:', error);
      }
      dismissLoader();
    }
  };

  const changeImage = () => {
    if (inputRef.current) {
      inputRef.current.click(); 
    }
  };

  const handleImageSelection = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    console.log(file, "fileee")
  
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg","image/webp"];
    if (!allowedTypes.includes(file.type)) {

      showErrorToast("Invalid file type. Please select a PNG, JPG or JPEG file.");
      return false;
    }

   
    

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setDoctorImg(reader.result);
        setShowCropper(true);
        editPhoto.current.openModal(); // Open the modal when image is selected
      };
      reader.readAsDataURL(file);
    e.target.value = '';
    }
  };

  const handleCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleCrop = async () => {
    showLoader()
    try {
      const croppedBlob = await getCroppedImg(doctorImg, croppedAreaPixels);
      if (!croppedBlob) {
        console.error('Cropped image is null or undefined');
        return;
      }
      const croppedUrl = URL.createObjectURL(croppedBlob);
      setImgUrl(croppedUrl);

      const result = await uploadImageInProfile(croppedBlob, 'cropped.jpg');
      const srcInResult = result.name;
      setDoctorImgInput(srcInResult);

      const imageUpload = await updatePatientProfileOnChange({
        profilepictureurl: srcInResult,
      });

      // if (imageUpload.output.success === 1) {
      //   window.location.reload();
      // }
      if (imageUpload.output.success === 1) {
        setDoctorImgInput(srcInResult);
        setImgUrl(croppedUrl);
        showSuccessToast("Image uploaded successfully");
      }

      setShowCropper(false);
      editPhoto.current.closeModal();
    } catch (error) {
      console.error('Error handling cropped image:', error);
    }
    dismissLoader()
  };


  return (
    <>
      <Flex pt={{md: "5rem",lg: "10rem", xl: "5rem"}} display={{base: "none", lg: "flex"}}>
        {isLoading && <Loader />}
        <Box w="100%" p={4}>
          {/* <Center> */}
            <VStack spacing={0} w="450px">
              <Box as="p" fontSize="22px" textAlign="center" fontWeight="600" mb={5}>
                Let’s start with your <br /> personal details
              </Box>

<Box>
        <Image border="1px solid #E6E7E9" w="85px" h="85px" borderRadius="full" src={imgUrl || "/assets/imgs/no-image.png"} />
        <Box as="Button" pos="relative" bottom="25px" left="58px" bg="#121316" p="6px" border="2px solid white" borderRadius="full" onClick={changeImage}>
          <input type="file" ref={inputRef} style={{ display: 'none' }} onChange={handleImageSelection} />
          <Image w="11px" h="auto" src='/assets/svgs/edit-white.svg' />
        </Box>
      </Box>

              <PrimaryInput
                variant="defaultSide"
                inputPlace="First Name*"
                id="firstName"
                onChange={handleChangeFirstName}
                inputValue={firstname}
              />
              <PrimaryInput
                variant="bothSide"
                inputPlace="Last Name"
                id="lastName"
                onChange={handleLastName}
                inputValue={lastname}
              />

              <DropdownInput
                variant="bothSide"
                dropDownPlace="Gender"
                options={["Male", "Female", "Others"]}
                readOnly={true}
                id="gender"
                onClick={selectGender}
                defaultOption={defaultGender}
                menuList={{pos: "absolute", right: "-45px", minW: "115px"}}
              />


              <DatePicker
                disableFutureDates={true}
                placeholderProp="Date of Birth"
                leftPos={{ left: "5px" }}
                datePickerInputStyle={{ borderRadius: "0 0 10px 10px!important" }}
                onDateChange={(e) => handleDateChange(e)}
                //defaultDate={dob}
              />
              <Box w="100%" mt="20px">
                <PrimaryInputDropdown

                  onChange={(e) => handleMobileChange(e)}
                  inputValue={mobileno}
                  id="mobile" variant="defaultSide" />

                <PrimaryInput
                  variant="bothSide"
                  inputType="Email"
                  inputPlace="Enter Email Address*"
                  id="Email"
                  onChange={handleChangeEmail}
                  onKeyPress={handleEmailpassKeyPress}

                  inputValue={email}
                />
                <PasswordInput
                  passInputPlace="Enter Your Password*"                 
                  variant="downSide"
                  onChange={(e) => handleChangePassword(e)}
                  onKeyPress={handleEmailpassKeyPress}
                />
              </Box>

              <Box
                display="flex"
                gap="8px"
                my={5}
                flexDirection="row"
                alignItems="center"
              >
                <Box as="span">
                  <Image w="18px" src="/assets/imgs/info-safe.png" />
                </Box>
                <Box as="p" lineHeight="normal" fontSize="12px">
                  Your information is safe with us.
                </Box>
              </Box>
              <Box w="100%">
                <PrimaryButton buttonText="Continue" id="submitButton" onClick={SubmitFunction} />
              </Box>
            </VStack>
          {/* </Center> */}
        </Box>
      </Flex>


      {/* Mobile responsive start */}
      <Flex flexDir="column" display={{base: "flex", lg: "none"}}>
        <Box w="100%" bg="black" h="6px" borderRadius="full" my="1rem">
          <Box as="span" w="25%" bg='#14C585' h="100%" borderRadius="full" display="flex"></Box>
        </Box>
        <Box w="100%" mb={5}>
          <Box as="p" fontSize="1.25rem" lineHeight="normal" letterSpacing="1px" mt="0.75rem" textAlign="center" fontWeight="600" mb={2}>
            Let’s start with your personal details
          </Box>

          <Box w="100%" display="flex" justifyContent="center" alignItems="center" flexDir="column">
            <Image border="1px solid #E6E7E9" w="85px" h="85px" borderRadius="full" src={imgUrl || "/assets/imgs/no-image.png"} />
            <Box as="Button" pos="relative" bottom="25px" left="30px" bg="#121316" p="6px" border="2px solid white" borderRadius="full" onClick={changeImage}>
              <input type="file" ref={inputRef} style={{ display: 'none' }} onChange={handleImageSelection} />
              <Image w="11px" h="auto" src='/assets/svgs/edit-white.svg' />
            </Box>
          </Box>

          <PrimaryInput
            variant="defaultSide"
            inputPlace="First Name*"
            id="firstName"
            onChange={handleChangeFirstName}
            inputValue={firstname}
          />
          <PrimaryInput
            variant="bothSide"
            inputPlace="Last Name"
            id="lastName"
            onChange={handleLastName}
            inputValue={lastname}
          />

          <DropdownInput
            variant="bothSide"
            dropDownPlace="Gender"
            options={["Male", "Female", "Others"]}
            readOnly={true}
            id="gender"
            onClick={selectGender}
            defaultOption={defaultGender}
            menuList={{pos: "absolute", right: "-45px", minW: "115px"}}
          />


          <DatePicker
            disableFutureDates={true}
            placeholderProp="Date of Birth"
            leftPos={{ left: "5px" }}
            datePickerInputStyle={{ borderRadius: "0 0 10px 10px!important" }}
            onDateChange={(e) => handleDateChange(e)}
            //defaultDate={dob}
          />
          <Box w="100%" mt="20px">
            <PrimaryInputDropdown

              onChange={(e) => handleMobileChange(e)}
              inputValue={mobileno}
              id="mobile" variant="defaultSide" />

            <PrimaryInput
              variant="bothSide"
              inputType="Email"
              inputPlace="Enter Email Address*"
              id="Email"
              onChange={handleChangeEmail}
              onKeyPress={handleEmailpassKeyPress}

              inputValue={email}
            />
            <PasswordInput
              passInputPlace="Enter Your Password*"                 
              variant="downSide"
              onChange={(e) => handleChangePassword(e)}
              onKeyPress={handleEmailpassKeyPress}
            />
          </Box>

          <Box
            display="flex"
            gap="8px"
            my={5}
            flexDirection="row"
            alignItems="center"
            w="100%"
            justifyContent="center"
          >
            <Box as="span">
              <Image w="18px" src="/assets/imgs/info-safe.png" />
            </Box>
            <Box as="p" lineHeight="normal" fontSize="12px">
              Your information is safe with us.
            </Box>
          </Box>
          <Box w="100%">
            <PrimaryButton buttonText="Continue" id="submitButton" onClick={SubmitFunction} />
          </Box>
        </Box>
      </Flex>

      <DynamicModal
        ref={editPhoto}
        modalHeader="Upload Photo"
        modalBody={
          <>
            {showCropper && doctorImg &&  (
              <Box display="flex" justifyContent="center" alignItems="center" h='400px' w='400px'>
              <ImageCropper
                src={doctorImg}
                onCropComplete={handleCropComplete}
              />
              </Box>
            )}
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton 
            buttonText="Upload" 
            mr={3}
            onClick={() => {
              handleCrop();
              editPhoto.current.closeModal();
            }} 
             />
            <PrimaryButton buttonText="Cancel" onClick={() => editPhoto.current.closeModal() } />
          </>
        }
        size="lg"
      />
    </>

  );
};

export default PersonalDetails;
