import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Text,
  Image,
  Circle,
  Center,
  Link,
  Divider,
  Flex,
  Stack,
  Input,
  InputGroup,
  Radio,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  RadioGroup,
  Checkbox,
  CheckboxGroup,
  chakra,
} from "@chakra-ui/react";
import moment from "moment";


import DynamicModal from "../../../components/dynamic-modal";

import MiniDropdownInput from "../../../components/mini-dropdown";
import {
  DropdownInput,
  PrimaryButton,
  PrimaryButtonIcon,
  PrimaryInput,
  SearchBar,
  Toaster,
} from "../../../components";
import { COLORS } from "../../../components/styles/colors";
import DatePicker from "../../../components/date-picker";
import ModalRight from "../../../components/modal-right";
import { updateCondition } from "../../../middleware/services/visits/patient-overview/updatehealth";
import {
  GetAIMedicineComposition,
  updateDoctorNotes,
} from "../../../middleware/services/visits/patient-overview/updatedoctornotes";
import AiModal from "../../ai-modal";
import TypingLoader from "../../typing-loader";
import {
  FetchMedicineAIAnswer,
  FetchMedicineAIPrompts,
  FetchMyHealthAIData,
} from "../../../middleware/services/visits/aicalls";
import { FetchMedicineList } from "../../../middleware/services/billing";

const formatDate = (dateString) => {
  if (!dateString) return "-";
  return moment(dateString).format("Do MMM, YYYY");
};

const fileTypeMappings = {
  pdf: "/assets/svgs/pdf-icon.svg",
  png: "/assets/imgs/png-icon.png",
  jpg: "/assets/imgs/jpg-icon.png",
  jpeg: "/assets/imgs/jpg-icon.png",
};

const getFileIcon = (fileName) => {
  const extension = fileName.split(".").pop().toLowerCase();
  return fileTypeMappings[extension] || "/assets/svgs/file-icon.svg"; // fallback image
};

const CurrentOrderMedications = ({
  date,
  medicines,
  attachments,
  appointmentId,
  patientId,
  patientname,
  patientgender,
  patientage,
  doctorId,
  id,
  fetchDoctorNotes,
  showLoader,
  dismissLoader,
  showSuccessToast,
  showErrorToast,
  scaleHeight = "auto",
  componentBg = "#fcfcfd",
  cardBg = "white",
  fetchDoctorNotesEmptyIndication
}) => {
  // ------------------- AI Insights

  const chatContainerRef = useRef(null);
  const [aianswers, setAIAnswers] = useState([]);
  const [remainingquestions, setRemainingQuestions] = useState([]);
  const [aiquestion, setAIAnalysis] = useState(null);
  const [AiInput, setAiInput] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  const [animate, setAnimate] = useState(false);
  const [showAIModal, setShowAIModal] = useState(true);

  const AIChatModel = useRef();

  useEffect(() => {
    if (animate) {
      const timer = setTimeout(() => {
        setAnimate(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [animate]);

  useEffect(() => {
    // Scroll to bottom whenever aianswers changes
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [aianswers]);
  const fetchAiDoctorPrompts = async (medicinename) => {
    showLoader();
    setRemainingQuestions([]);
    setAiInput(null);
    setAIAnswers([]);
    setInitialRender(true);
    const result = await FetchMyHealthAIData(
      {},
      patientId,
      patientname,
      patientgender,
      patientage
    );
    // console.log(result);
    let Aiprompts = { ...result, medicinename };
    setAiInput(Aiprompts);
    const response = await FetchMedicineAIPrompts(Aiprompts);
    if (response.generated_question) {
      setAIAnalysis(response);
      setRemainingQuestions(response.generated_question);
      AIChatModel.current.openRight();
    }
    dismissLoader();
  };

  const GetAIAnswer = async (prompt) => {
    // Display question immediately
    const newQuestion = { question: prompt, answer: "" };
    setAIAnswers((prevState) => [...prevState, newQuestion]);

    // Remove the question from remaining questions immediately
    setRemainingQuestions((prevState) =>
      prevState.filter((question) => question.value !== prompt)
    );

    // Trigger animation
    setAnimate(true);

    // Set initialRender to false after the first question is clicked
    setInitialRender(false);
    let AichatInput = { ...AiInput, query: prompt };

    const response = await FetchMedicineAIAnswer(AichatInput);

    if (response) {
      // Replace "typing" with actual answer
      setAIAnswers((prevState) =>
        prevState.map((item) =>
          item.question === prompt && item.answer === ""
            ? { question: item.question, answer: response.answer }
            : item
        )
      );
    }
  };

  //-------------------------------------------------

  const editmedications = useRef();

  const [idToUpdate, setIdToUpdate] = useState(null);
  const [medicinename, setMedicineName] = useState("");
  const [medicineComposition, setMedicineComposition] = useState("");
  const [medicinetype, setMedicineType] = useState("Tablet");
  const [dosage, setMedicineDosage] = useState(null);
  const [dosageunit, setMedicineDosageUnit] = useState("mg");
  const [frequency, setMedicineDosageFrequency] = useState("");
  const [fromdate, setFromDate] = useState(moment().format("DD-MM-yyyy"));
  const [todate, setToDate] = useState(moment().format("DD-MM-yyyy"));
  const [morning, setMorning] = useState("0");
  const [setevening, setEvening] = useState("0");
  const [setnight, setNight] = useState("0");
  const [beforeFOOD, setBeforeFood] = useState("0");
  const [afterFOOD, setAfterFood] = useState("0");
  const [prescribedby, setPrescribedBy] = useState(null);
  const [hospitalname, setHospitalname] = useState(null);

  const [compositionEditKey, setCompositionEditKey] = useState(0); // to rerender the composition input box
  const fetchComposition = async (medicineName) => {
    if (!medicineName) {
      showErrorToast("Please enter medicine name first to fetch composition");
    } else {
      const inputData = {
        input: {
          name: medicineName,
        },
      };

      // console.log(inputData.input);

      try {
        // showLoader();
        // console.log(inputData);
        const result = await GetAIMedicineComposition(inputData.input);
        // console.log(result);
        if (result) {
          showSuccessToast("Fetched Medicine composition successfully");
          setMedicineComposition(result.chemical_composition);
          setCompositionEditKey((prevKey) => prevKey + 1);
        }
      } catch (error) {
        console.error("Error Fetching Medicine composition:", error);
        showErrorToast("An error occurred while Fetching Medicine composition");
      } finally {
        // dismissLoader();
      }

      // fetchDoctorNotes();

      // console.log("Clicked");
    }
  };

  const editmedicine = async () => {
    let input = {
      patientid: patientId,
      doctorid: doctorId,
      appointmentid: appointmentId,
      type: "medications",
      prescribedmedicineid: idToUpdate,
      medicinename: medicinename,
      medicinetype: medicinetype,
      dosage: dosage,
      startdate: fromdate,
      enddate: todate,
      frequency: frequency,
      morning: morning,
      evening: setevening,
      night: setnight,
      beforefood: beforeFOOD,
      afterfood: afterFOOD,
      prescribedby: prescribedby,
      hospitalname: hospitalname,
      dosageunit: dosageunit,
      chemical_composition: medicineComposition,
      medicineactive: 1,
    };
    console.log(input);
    if (moment(input.startdate, "DD-MM-YYYY", true).isValid()) {
      input.startdate = moment(input.startdate, "DD-MM-YYYY").format(
        "YYYY-MM-DD"
      );
    }
    if (moment(input.enddate, "DD-MM-YYYY", true).isValid()) {
      input.enddate = moment(input.enddate, "DD-MM-YYYY").format("YYYY-MM-DD");
    }

    if (medicinename == "") {
      showErrorToast("Please specify or upload any medicine");
      return false;
    } else {
      showLoader();
      const result = await updateCondition(input);

      if (result) {
        showSuccessToast("Medicine updated Successfully");
        fetchDoctorNotesEmptyIndication()
        editmedications.current.closeRight();
        fetchDoctorNotes();
      }
      dismissLoader();
    }
  };

  const handleeditmedication = (item) => {
    setIdToUpdate(item.id);
    setMedicineName(item.name);
    setMedicineType(item.type);
    setMedicineDosage(item.dosage);
    setMedicineDosageUnit(item.dosageunit);
    setMedicineDosageFrequency(item.frequency);
    const stratdate = moment(item.startdate, "DD-MM-YYYY").format("DD-MM-YYYY");
    const enddate = moment(item.enddate, "DD-MM-YYYY").format("DD-MM-YYYY");
    setFromDate(stratdate);
    setToDate(enddate);
    setMorning(item.inMorning);
    setEvening(item.inEvening);
    setNight(item.inNight);
    setBeforeFood(item.beforefood);
    setAfterFood(item.afterfood);
    setPrescribedBy(item.prescribedby);
    setHospitalname(item.prescribedhospitalname);
    setMedicineComposition(item.chemicalcomposition);
    editmedications.current.openRight();
  };

  const handleChangeDosageUnit = (selectedOption) => {
    setMedicineDosageUnit(selectedOption.label);
  };

  const handlemedicinename = (event) => {
    setMedicineName(event.target.value);
  };


  const handlechemicalcomposition = (event) => {
    setMedicineComposition(event.target.value);
  };

  const handlemedicinetypechange = (event) => {
    setMedicineType(event);
    if (event == "Tablet") {
      setMedicineDosageUnit("mg");
    } else if (event == "Syrup") {
      setMedicineDosageUnit("ml");
    } else if (event == "Capsule") {
      setMedicineDosageUnit("mg");
    } else if (event == "Injection") {
      setMedicineDosageUnit("ml");
    } else if (event == "Ointment") {
      setMedicineDosageUnit("grms");
    }
  };

  const handleFromDateChange = (selecteddate) => {
    const formattedDate = moment(selecteddate).format("YYYY-MM-DD");
    setFromDate(formattedDate);
  };

  const handleToDateChange = (selecteddate) => {
    const formattedDate = moment(selecteddate).format("YYYY-MM-DD");
    setToDate(formattedDate);
  };

  const handleconsumptionfrequencychange = (selectedOption) => {
    if (selectedOption == "1") {
      setMedicineDosageFrequency("1");
    } else if (selectedOption == "2") {
      setMedicineDosageFrequency("2");
    } else if (selectedOption == "3") {
      setMedicineDosageFrequency("3");
    } else if (selectedOption == "4") {
      setMedicineDosageFrequency("4");
    }
  };

  const [medicineList, setMedicineList] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [showMedicineNamesDropdown, setShowMedicineNamesDropdown] =
      useState(-1);
    const [boolValForSuggestions, setBoolValForSuggestions] = useState(true);
    const [focusedItemIndex, setFocusedItemIndex] = useState(-1);
    const suggestionsMenuRef = useRef(null);

  const [QuantityInStrip, setQuantityInStrip] = useState(0);
    useEffect(() => {
      fetchMedicines();
    }, []);
    const fetchMedicines = async () => {
      try {
        const result = await FetchMedicineList({ doctorId });
        if (result.output.data.length > 0) {
          setMedicineList(result.output.data);
        }
        dismissLoader();
      } catch (error) {
        console.error("Error in home:", error);
      }
    };
    const filterMedicineNames = (medicine) => {
      const filtered = medicineList.filter((obj) =>
        obj.productname?.toLowerCase().includes(medicine.toLowerCase())
      );
      setSuggestions(filtered);
      setBoolValForSuggestions(!boolValForSuggestions);
    };

  const handleEditSelect = (suggestion, index) => {
    setMedicineName(suggestion.productname);
    // handlemedicinename(e);
    setSuggestions([]);
    setBoolValForSuggestions(!boolValForSuggestions);
    setShowMedicineNamesDropdown(-1);
  };

  const handleEditKeyDown = (e, index) => {
    if (e.key === "ArrowDown") {
      setFocusedItemIndex(
        (prevIndex) => (prevIndex + 1) % suggestions.length
      );
      const itemHeight =
        suggestionsMenuRef.current?.children[0]?.clientHeight || 0;
      if (suggestionsMenuRef.current) {
        suggestionsMenuRef.current.scrollTop = focusedItemIndex * itemHeight;
      }
    } else if (e.key === "ArrowUp") {
      setFocusedItemIndex((prevIndex) =>
        prevIndex === 0 ? suggestions.length - 1 : prevIndex - 1
      );
      const itemHeight =
        suggestionsMenuRef.current?.children[0]?.clientHeight || 0;
      if (suggestionsMenuRef.current) {
        suggestionsMenuRef.current.scrollTop = focusedItemIndex * itemHeight;
      }
    } else if (e.key === "Enter" && focusedItemIndex !== -1) {
      const selectedItem = suggestions[focusedItemIndex];
      handleEditSelect(selectedItem, index);
    }
  };


  //-------------------------------------------------
  // Delete Functionality

  const [medicationNameToDelete, setMedicationNameToDelete] = useState("");
  const [idToDelete, setidToDelete] = useState(null);
  const dynamicModal = React.useRef();

  const openDeleteModal = (id, medicationName) => {
    // console.log(id, medicationName);
    setidToDelete(id);
    setMedicationNameToDelete(medicationName);
    if (dynamicModal.current) {
      dynamicModal.current.openModal();
    }
  };

  const deleteMedication = async () => {
    const inputForDelete = {
      input: {
        doctorid: doctorId,
        patientid: patientId,
        appointmentid: appointmentId,
        type: "medications",
        prescribedmedicineid: idToDelete,
        medicineactive: 0,
      },
    };
    // console.log(inputForDelete);

    try {
      showLoader();
      const result = await updateCondition(inputForDelete.input);
      if (result.output.result === "success") {
        showSuccessToast(`${medicationNameToDelete} has been deleted`);
        fetchDoctorNotesEmptyIndication()
        fetchDoctorNotes();
        dynamicModal.current.closeModal();
      } else {
        showErrorToast("Something went wrong!");
      }
    } catch (error) {
      console.error("Error deleting medication:", error);
      showErrorToast("An error occurred while deleting medication");
    } finally {
      dismissLoader();
    }
  };

  return (
    <Box
      display="flex"
      flexDir="column"
      alignItems="center"
      w="100%"
      bg={componentBg}
    >
      <Box w="100%">
        {medicines.map((medicine, index) => (
          <Box
            key={index}
            p="20px"
            w="100%"
            // ml="10px"
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="space-between"
            border="1px solid #E6E7E9"
            borderRadius="12px"
            backgroundColor={cardBg}
            mb="10px"
          >
            <Box display="flex" alignItems="center" w="100%" gap="10px">
              <Box
                fontSize="10px"
                fontWeight="600"
                bg="#E6E7E966"
                border="1px solid #E6E7E9"
                p="5px 10px"
                borderRadius="full"
                w="fit-content"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {medicine.type ? medicine.type : "-"}
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                flex="1"
              >
                <Text
                  title={medicine.name}
                  fontSize="16px"
                  textAlign="left"
                  fontWeight="600"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  w={{ base: "100px", md: "230px" }}
                >
                  {medicine.name}
                </Text>
              </Box>
              <Box display="flex" alignItems="center">
                <Box
                  as="button"
                  // onClick={}
                  border="1px solid #E6E7E9"
                  borderRadius="full"
                  w="36px"
                  h="auto"
                  p="10px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => fetchAiDoctorPrompts(medicine.name)}
                >
                  <Image w="26px" src="/assets/svgs/ai-star-outline.svg" />
                </Box>
                <Box
                  as="button"
                  border="1px solid #E6E7E9"
                  borderRadius="full"
                  w="36px"
                  h="auto"
                  p="10px"
                  ml="10px"
                  onClick={() => handleeditmedication(medicine)}
                >
                  <Image w="13px" src="/assets/svgs/edit-gray.svg" />
                </Box>
                <Box
                  as="button"
                  border="1px solid #E6E7E9"
                  borderRadius="full"
                  w="36px"
                  h="auto"
                  p="10px"
                  ml="10px"
                  onClick={() => openDeleteModal(medicine.id, medicine.name)}
                >
                  <Image w="13px" src="/assets/imgs/delete.png" />
                </Box>
              </Box>
            </Box>

            <Divider my="10px" />
            <Box display="flex" alignItems="center" w="100%">
              <Box display="flex" alignItems="center">
                <Image
                  src={
                    medicine.type === "Tablet"
                      ? "/assets/svgs/pill-blue.svg"
                      : "/assets/svgs/syrup.svg"
                  }
                  w="16px"
                  mr="5px"
                />
                <Text
                  fontSize="14px"
                  fontWeight="500"
                  color="#384052"
                  whiteSpace="nowrap"
                >
                  {/* {medicine.frequency ? medicine.frequency : "-"}x per day */}
                  {medicine.frequency ? medicine.frequency : "-"}
                </Text>
                <Box display="flex" gap="5px" ml="5px">
                  <Box
                    as="span"
                    bg={medicine.inMorning === 1 ? "#384052" : "#E6E7E9"}
                    borderRadius="full"
                    w="8px"
                    h="8px"
                    p="4px"
                  ></Box>
                  {/* <Box
                        as="span"
                        bg={medicine.inAfternoon === 1 ? "#384052" : "#E6E7E9"}
                        borderRadius="full"
                        w="8px"
                        h="8px"
                        p="4px"
                      ></Box> */}
                  <Box
                    as="span"
                    bg={medicine.inEvening === 1 ? "#384052" : "#E6E7E9"}
                    borderRadius="full"
                    w="8px"
                    h="8px"
                    p="4px"
                  ></Box>
                  <Box
                    as="span"
                    bg={medicine.inNight === 1 ? "#384052" : "#E6E7E9"}
                    borderRadius="full"
                    w="8px"
                    h="8px"
                    p="4px"
                  ></Box>
                </Box>
              </Box>
              <Box flex="1" />
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                w="100%"
              >
                <Image
                  src="/assets/svgs/calendar_month.svg"
                  w="16px"
                  mr="5px"
                />
                <Text fontSize="14px" fontWeight="500" color="#384052">
                  {medicine.duration}
                </Text>
              </Box>
            </Box>
          </Box>
        ))}

        <DynamicModal
          ref={dynamicModal}
          modalHeader={"Delete Medicine?"}
          modalBody={
            <>
              <Text fontSize="14px" fontWeight="600">
                Are you sure you want to delete{" "}
                {medicationNameToDelete ? medicationNameToDelete : "-"}
              </Text>
            </>
          }
          modalFooterBtn={
            <>
              <PrimaryButton
                variant="deleteBtn"
                buttonText="Delete"
                onClick={deleteMedication}
              />
            </>
          }
        />

        <ModalRight
          ref={editmedications}
          header="Edit Medication"
          bodyStyle={{ p: "24px 32px" }}
          modalWidth="360px"
          body={
            <>
              <Box display="flex" flexDir="column" gap="20px">
                <Stack spacing={4}>
                  <Box display={"flex"} flexDir="column">
                    {/* <PrimaryInput
                      pr="4.5rem" // Padding right to make room for the icon
                      type="text"
                      variant={"bothSide"}
                      placeholder="Medicine name*"
                      inputPlace="Medicine name"
                      defaultValue={medicinename}
                      sx={{
                        height: "48px",
                        borderRadius: "10px",
                        _hover: {
                          border: "2px solid",
                          borderColor: COLORS.INPUT_FOCUS_DARK,
                          outline: "none",
                          bg: "none",
                          _focus: {
                            borderRadius: "10px",
                            borderColor: COLORS.INPUT_FOCUS_DARK,
                            outline: "none",
                          },
                          _focusVisible: { borderColor: "none" },
                        },
                      }}
                      onChange={handlemedicinename}
                    /> */}
                    
                    <Box 
                    // width={{ base: "100%", md: "45%" }}
                    >
                    <Box w="100%" pos="relative" cursor="pointer">
                    <PrimaryInput
                      pr="4.5rem" // Padding right to make room for the icon
                      type="text"
                      variant="bothSide"
                      inputPlace="Medicine name"
                      onChange={(e) => {
                        handlemedicinename(e);
                        filterMedicineNames(e.target.value);
                        setShowMedicineNamesDropdown(1);
                        setBoolValForSuggestions((prev) => !prev);
                      }}
                      
                      inputIdProp={"MedicineNameInAdd" + 0}
                      boolProp={boolValForSuggestions}
                      onKeyDown={(e) => handleEditKeyDown(e, 0)}
                      autoFocus={showMedicineNamesDropdown === 0}
                      autocomplete="off"
                      inputValue={medicinename}
                      sx={{
                        height: "48px",
                        borderRadius: "10px",
                        _hover: {
                          border: "2px solid",
                          borderColor: COLORS.INPUT_FOCUS_DARK,
                          outline: "none",
                          bg: "none",
                          _focus: {
                            borderRadius: "10px",
                            borderColor: COLORS.INPUT_FOCUS_DARK,
                            outline: "none",
                          },
                          _focusVisible: { borderColor: "none" },
                        },
                      }}
                    />
                    <Image
                      top="33%"
                      right="6.5%"
                      w="15px"
                      pos="absolute"
                      src="/assets/imgs/double-arrow.png"
                    />
                  </Box>
                  {showMedicineNamesDropdown === 1 &&
                          suggestions.length > 0 && (
                            <Menu isOpen={true}>
                              <MenuButton
                                as={Box}
                                w="50%"
                                display="block"
                                pos="relative"
                                zIndex="999"
                              >
                                <Box
                                  // pos="absolute"
                                  top="100%"
                                  // left="0"
                                  w="50%"
                                  bg="white"
                                  borderTop="none"
                                  border="1px solid #E6E7E9"
                                  borderRadius="0 0 12px 12px"
                                />
                              </MenuButton>
                              <MenuList
                                w="50%"
                                borderRadius="0 0 12px 12px"
                                zIndex="999"
                                bg="white"
                                maxH="150px"
                                overflow="hidden auto"
                                ref={suggestionsMenuRef}
                              >
                                {suggestions.map((suggestion, sIndex) => (
                                  <MenuItem
                                    key={sIndex}
                                    onClick={() => handleEditSelect(suggestion, 1)}
                                    cursor="pointer"
                                    p="1rem"
                                    py="0.5rem"
                                    borderBottom="1px solid #E6E7E9"
                                    bg={
                                      focusedItemIndex === sIndex
                                        ? "#f0f0f0"
                                        : "white"
                                    }
                                  >
                                    <Text fontSize="0.875rem" fontWeight="500">
                                      {suggestion.productname +
                                        " (" +
                                        suggestion.remainingquantity +
                                        " Qty)"}
                                    </Text>
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </Menu>
                          )}
                          </Box>
                    {/* <PrimaryInput
                      type="text"
                      variant={"bothSide"}
                      placeholder="Medicine Composition*"
                      inputPlace="Medicine Composition"
                      defaultValue={medicineComposition}
                      sx={{
                        height: "48px",
                        borderRadius: "10px",
                        _hover: {
                          border: "2px solid",
                          borderColor: COLORS.INPUT_FOCUS_DARK,
                          outline: "none",
                          bg: "none",
                          _focus: {
                            borderRadius: "10px",
                            borderColor: COLORS.INPUT_FOCUS_DARK,
                            outline: "none",
                          },
                          _focusVisible: { borderColor: "none" },
                        },
                      }}
                      onChange={handlechemicalcomposition}
                    /> */}
                    <InputGroup>
                      <PrimaryInput
                        key={compositionEditKey}
                        variant="bothSide"
                        defaultValue={medicineComposition}
                        onChange={(event) =>
                          setMedicineComposition(event.target.value)
                        }
                        inputPlace="Chemical Composition"
                      />
                      <PrimaryButton
                        buttonText="Fetch"
                        verticalAlign="bottom"
                        variant="grayBtn"
                        btnStyle={{
                          w: "15%",
                          color: COLORS.PRIMARY_COLOR,
                          verticalAlign: "bottom",
                          ml: "4px",
                          mb: "0px",
                          h: "2.8rem",
                          m: "0",
                        }}
                        onClick={() => fetchComposition(medicinename)}
                        isDisabled={false}
                      />
                    </InputGroup>
                  </Box>
                  <RadioGroup
                    defaultValue={medicinetype}
                    onChange={handlemedicinetypechange}
                  >
                    <Stack direction="row" gap="20px" flexWrap="wrap">
                      <Radio value="Tablet">Tablet</Radio>
                      <Radio value="Syrup">Syrup</Radio>
                      <Radio value="Capsule">Capsule</Radio>
                      <Radio value="Injection">Injection</Radio>
                      <Radio value="Ointment">Ointment</Radio>
                    </Stack>
                  </RadioGroup>
                  <Divider />
                  <Box>
                    <MiniDropdownInput
                      variant="bothside"
                      options={[
                        { label: "mg", value: "mg" },
                        { label: "ml", value: "ml" },
                        { label: "grms", value: "grms" },
                      ]}
                      placeholderProp="Dosage"
                      defaultValue={dosage}
                      defaultOptionProp={dosageunit}
                      onSelect={handleChangeDosageUnit}
                      handleInputChange={(event) =>
                        setMedicineDosage(event.target.value)
                      }
                      maxLength="6"
                    />

                    <Box display="flex" flexDir="row">
                      <DatePicker
                        // disableFutureDates={true}
                        minDate={fromdate}
                        defaultDate={fromdate}
                        onDateChange={(selectedDateValue) =>
                          handleFromDateChange(selectedDateValue)
                        }
                        placeholderProp={"Start Date"}
                        calendarStlye={{ left: "-18px", right: "unset" }}
                      />
                      <DatePicker
                        defaultDate={todate}
                        minDate={fromdate}
                        onDateChange={(selectedDateValue) =>
                          handleToDateChange(selectedDateValue)
                        }
                        placeholderProp={"End Date"}
                        calendarStlye={{
                          transform: "scale(0.9)",
                          right: "-22px",
                          top: "30px",
                        }}
                      />
                    </Box>
                    <DropdownInput
                      variant="downSide"
                      dropDownPlace="Consumption frequency"
                      options={["1", "2", "3", "4"]}
                      onClick={handleconsumptionfrequencychange}
                      defaultOption={frequency}
                    />
                    <CheckboxGroup
                      colorScheme="green"
                      //onChange={handlemedicinetime}
                    >
                      <Stack spacing={2} pt="20px" direction="row">
                        <Checkbox
                          isChecked={morning === 1}
                          onChange={() => setMorning(morning === 1 ? 0 : 1)}
                        >
                          Morning
                        </Checkbox>
                        <Checkbox
                          isChecked={setevening === 1}
                          onChange={() => setEvening(setevening === 1 ? 0 : 1)}
                        >
                          Evening
                        </Checkbox>
                        <Checkbox
                          isChecked={setnight === 1}
                          onChange={() => setNight(setnight === 1 ? 0 : 1)}
                        >
                          Night
                        </Checkbox>
                      </Stack>
                    </CheckboxGroup>
                    <CheckboxGroup
                      colorScheme="green"
                      //onChange={handlemedicinetime}
                    >
                      <Stack spacing={2} pt="20px" direction="row">
                        <Checkbox
                          isChecked={beforeFOOD === 1}
                          onChange={() =>
                            setBeforeFood(beforeFOOD === 1 ? 0 : 1)
                          }
                        >
                          Before Food
                        </Checkbox>
                        <Checkbox
                          isChecked={afterFOOD === 1}
                          onChange={() => setAfterFood(afterFOOD === 1 ? 0 : 1)}
                        >
                          After Food
                        </Checkbox>
                      </Stack>
                    </CheckboxGroup>
                  </Box>
                  <Divider />
                  {/* <Box>
                    <PrimaryInput
                      inputPlace="Prescribed by"
                      variant="defaultSide"
                      onChange={(event) => setPrescribedBy(event.target.value)}
                      defaultValue={prescribedby}
                    />
                    <PrimaryInput
                      inputPlace="Hospital name"
                      variant="downSide"
                      onChange={(event) => setHospitalname(event.target.value)}
                      defaultValue={hospitalname}
                    />
                  </Box> */}
                </Stack>
              </Box>
            </>
          }
          footerActions={
            <>
              <Box w="100%" display="flex" flexDirection="row" gap="15px">
                <PrimaryButton
                  buttonText="Update Medication"
                  onClick={editmedicine}
                  isDisabled={false}
                />
              </Box>
            </>
          }
        />
        <AiModal
          ref={AIChatModel}
          modalSize="420px"
          //icon="/assets/imgs/ai-doctor.png"
          header={
            <>
              <Box display="flex" flexDir="column">
                <Text
                  fontSize="1rem"
                  fontWeight="700"
                  backgroundImage="linear-gradient(to right top, #0d7e54, #007774, #006d94, #005ea7, #1146a0)"
                  color="transparent"
                  backgroundClip="text"
                >
                  Aira - AI
                </Text>
                <Text fontSize="0.75rem" fontWeight="400">
                  Chat with your AI health guide
                </Text>
              </Box>
            </>
          }
          headerStyle={{ p: "24px 32px", lineHeight: "normal" }}
          closeBtnStyle={{ top: "18px" }}
          footerStyle={{
            maxH: "35%",
            overflow: "hidden auto",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          }}
          withoutBg={true}
          backDropBlur="10px"
          body={
            <>
              <Flex flexDirection="column" height="100%">
                <Box
                  p="24px 32px"
                  ref={chatContainerRef}
                  className="chat-container"
                  style={{
                    overflowY: "auto",
                    maxHeight: "calc(100vh - 160px)",
                  }}
                >
                  {initialRender && remainingquestions.length > 0 && (
                    <Box>
                      {remainingquestions.map((step, index) => (
                        <Box
                          key={index}
                          as="button"
                          w="100%"
                          p="16px"
                          mb="16px"
                          fontSize="14px"
                          fontWeight="600"
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="space-between"
                          borderRadius="10px"
                          border="1px solid #7795DC"
                          bg="#EAEDF5"
                          color="#163E9E"
                          onClick={() => GetAIAnswer(step.value)}
                          className={`question-box ${
                            animate ? "slide-in" : ""
                          }`}
                        >
                          {step.value ? step.value : "-"}
                          <Image src="/assets/imgs/right-icon.png" />
                        </Box>
                      ))}
                    </Box>
                  )}
                  {aianswers.map((step, index) => (
                    <React.Fragment key={index}>
                      <Box
                        as="div"
                        bg="#061027"
                        p="12px 16px"
                        w="fit-content"
                        float="right"
                        borderRadius="10px 10px 1px 10px"
                        className={`answer-box ${animate ? "slide-in" : ""}`}
                      >
                        <Text fontSize="14px" fontWeight="500" color="white">
                          {step.question ? step.question : "-"}
                        </Text>
                      </Box>

                      <Box
                        as="div"
                        my="20px"
                        w="fit-content"
                        display="flex"
                        alignItems="end"
                        float="left"
                        className={`answer-box ${animate ? "slide-in" : ""}`}
                      >
                        <Image
                          bg="#061027"
                          p="4px"
                          mr="8px"
                          borderRadius="6px"
                          src="/assets/imgs/ai-chat-logo.png"
                        />
                        <Text
                          bg="#E6E7E980"
                          p="12px 16px"
                          fontSize="14px"
                          fontWeight="500"
                          color="#061027"
                          borderRadius="10px 10px 10px 1px"
                        >
                          {step.answer ? step.answer : <TypingLoader />}
                        </Text>
                      </Box>
                    </React.Fragment>
                  ))}
                </Box>
              </Flex>
            </>
          }
          footerActions={
            !initialRender && (
              <Box
                p="24px 32px"
                display="flex"
                w="100%"
                h="100%"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Box w="100%">
                  {remainingquestions.map((step, index) => (
                    <Box
                      key={index}
                      as="button"
                      w="100%"
                      p="16px"
                      mb="16px"
                      fontSize="14px"
                      fontWeight="600"
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                      borderRadius="10px"
                      border="1px solid #7795DC"
                      bg="#EAEDF5"
                      color="#163E9E"
                      onClick={() => GetAIAnswer(step.value)}
                      className={`question-box ${animate ? "slide-in" : ""}`}
                    >
                      {step.value ? step.value : "-"}
                      <Image src="/assets/imgs/right-icon.png" />
                    </Box>
                  ))}
                </Box>
              </Box>
            )
          }
        />
      </Box>
    </Box>
  );
};

export default CurrentOrderMedications;
