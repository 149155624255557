import formapimodaltotalrevenueview from "./revenueViews";

export default function formapimodaltotalrevenuePagination({
    data,
    pageno,
    pagesize,
    count
  })
  {
  return Object.freeze({
    
    data:data !== null ? formapimodaltotalrevenueview(data) : [],
    pageno,
    pagesize,
    count,
  });
  }