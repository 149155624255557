export default function formAPIPrescriptions({
    id,
    doctorname,
    specialization,
    doctorimage,
    appointmentid,
    bookingid,
    prescribeddate,
    documents,
    imagepath,
    patientimage,
    patientname
    
  }) {
   
  
    return Object.freeze({
        id,
        doctorname,
        specialization,
        doctorimage,
        appointmentid,
        bookingid,
        prescribeddate,
        documents,
        imagepath,
        patientimage,
    patientname
    });
  }
  