import moment from "moment";
export default function formAPIappointments({
    appointmentdate,
    appointmenttime,
    completed,
    canceled,
   

}, type, activeTab, selecteddate)
{ 
  // console.log("Selected Date in formAPIappointments:", selecteddate);
  // console.log('type: ', type)
  // console.log('activeTab: ', activeTab)
  let dateFormat = "DD-MMM";
let formattedDate = "";

if (activeTab) {
  if (activeTab === 'Month') {
    dateFormat = "DD-MMM";
  } else if (activeTab === 'Year') {
    dateFormat = "DD MMM, YY";
  } else if (activeTab === 'Week') {
    dateFormat = (selecteddate && selecteddate.startDate === selecteddate.endDate) ? "HH:mm" : "ddd";
  } else if (activeTab === 'Today') {
    dateFormat = "HH:mm";
  }
}

if (type) {
  if (type === 'Month') {
    dateFormat = "DD-MMM";
  } else if (type === 'Year') {
    dateFormat = "DD MMM, YY";
  } else if (type === 'Week') {
    dateFormat = (selecteddate && selecteddate.startDate === selecteddate.endDate) ? "HH:mm" : "ddd";
  } else if (type === 'Today') {
    dateFormat = "HH:mm";
  }
}




 
  
  if (type==='Today') {
    formattedDate = moment(appointmenttime, "HH:mm").format(dateFormat);
  }
  else if (activeTab === "Week" && type === 'date') {
    // let dateFormat = "ddd";
    if(dateFormat === 'ddd'){

      formattedDate = moment(appointmentdate, "YYYY-MM-DD").format(dateFormat);
    }else{
      formattedDate = moment(appointmenttime, "HH:mm").format(dateFormat);

    }
    // let dateFormat = "HH:mm";
    // formattedDate = moment(appointmenttime, "HH:mm").format(dateFormat);
  } else if (activeTab === 'Week'){
    // let dateFormat = "ddd";
    formattedDate = moment(appointmentdate, "YYYY-MM-DD").format(dateFormat);
  }
  else {
      formattedDate = moment(appointmentdate,"YYYY-MM-DD").format(dateFormat);
  }
  return Object.freeze({
    label: formattedDate ? formattedDate : '',
    completed: completed,
    canceled: canceled,
   
  });
}