
import formAPIMedication from "../messages/messageorders/medication";

var formattedOutput = null;

const formAPIModelMedications = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIMedication(item));


  return formattedOutput
};

export default formAPIModelMedications