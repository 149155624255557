import moment from "moment";
export default function formAPIimaging({
  verifieddate,
  reportname,
  reportdescription,
  documents,
  longtext,
  reportid,
  documenturl,
  imagepath,
  reportinterpretation,
  normalresults,
  abnormalresults
    
   

})
{
  return Object.freeze({
    verifieddate,
  reportname,
  reportdescription,
  documents,
  longtext,
  reportid,
  documenturl,
  imagepath,
  reportinterpretation,
  normalresults,
  abnormalresults
  });
}