import { Box, Flex, Text, Switch, Image, useToast, Checkbox, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { DropdownInput, PrimaryButton } from '../../components';
import Dropdown from '../../components/dropdown/index';
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import { FetchTimezones } from '../../middleware/services/finalsteps';
import DropdownWithID from '../../components/dropdown-id';
import Toaster from "../../components/toaster";
import { useIsIOS } from '../../auth';
import SearchableDropdown from '../../components/searchable-dropdown';
import FloatDropdown from '../../components/float-dropdown';


const SetAvailability = ({ onSubmit }) => {
  const { isLoading, showLoader, dismissLoader } = useLoader();
  var [timezones, setTimeZoneData] = useState([]);
  var [selectedtimezoneid, setSelectedTimeZone] = useState("");
  var [selectedTimezoneValue, setSelectedTimeZoneValue] = useState("");

  const clinic = localStorage.getItem("clinicvisit")
  const video = localStorage.getItem("videovisit")
  const isIos = useIsIOS()

  const initialScheduleClinic = {
    Monday: { isEnabled: clinic==0 ? false : true, times: clinic==0 ? [] : [{ from: "09:00", to: "18:00" }] },
    Tuesday: { isEnabled: clinic==0 ? false : true, times: clinic==0 ? [] : [{ from: "09:00", to: "18:00" }] },
    Wednesday: { isEnabled: clinic==0 ? false : true, times: clinic==0 ? [] : [{ from: "09:00", to: "18:00" }] },
    Thursday: { isEnabled: clinic==0 ? false : true, times: clinic==0 ? [] : [{ from: "09:00", to: "18:00" }] },
    Friday: { isEnabled: clinic==0 ? false : true, times: clinic==0 ? [] : [{ from: "09:00", to: "18:00" }] },
    Saturday: { isEnabled: clinic==0 ? false : false, times: clinic==0 ? [] : [] },
    Sunday: { isEnabled: clinic==0 ? false : false, times: clinic==0 ? [] : [] }
    // Saturday: { isEnabled: clinic==0 ? false : false, times: clinic==0 ? [{from: null, to: null}] : [{from: "09:00", to: "18:00"}] },
    // Sunday: { isEnabled: clinic==0 ? false : false, times: clinic==0 ? [] : [{from: null, to: null}] }
  };
  
  const initialScheduleVideo = {
    Monday: { isEnabled: video==0 ? false : true, times: video==0 ? [] : [{ from: "09:00", to: "18:00" }] },
    Tuesday: { isEnabled: video==0 ? false : true, times: video==0 ? [] : [{ from: "09:00", to: "18:00" }] },
    Wednesday: { isEnabled: video==0 ? false : true, times: video==0 ? [] : [{ from: "09:00", to: "18:00" }] },
    Thursday: { isEnabled: video==0 ? false : true, times: video==0 ? [] : [{ from: "09:00", to: "18:00" }] },
    Friday: { isEnabled: video==0 ? false : true, times: video==0 ? [] : [{ from: "09:00", to: "18:00" }] },
    Saturday: { isEnabled: video==0 ? false : false, times: video==0 ? [] : [] },
    Sunday: { isEnabled: video==0 ? false : false, times: video==0 ? [] : [] }
  };
  
  const [clinicalSchedule, setClinicalSchedule] = useState(initialScheduleClinic);
  const [videoCallSchedule, setVideoCallSchedule] = useState(initialScheduleVideo);
  const [hasChangesMade, setHasChangesMade] = useState(false);
  const { showSuccessToast, showErrorToast } = Toaster();
  const toast = useToast();
  const hours = Array.from({ length: 24 }, (_, i) => `${String(i).padStart(2, '0')}:00`); // Generates hours from 00:00 to 23:00
  useEffect(() => {
    const storedavailabilitydetails = localStorage.getItem("availabilitydetails");
    const storedtimezonedetails = JSON.parse(localStorage.getItem("selectedTimezone"));
    // console.log('storedtimezonedetails: ', storedtimezonedetails)
    if(storedtimezonedetails!= null){
      setSelectedTimeZoneValue(storedtimezonedetails)
    }
    if (storedavailabilitydetails) {
      const availabilityData = JSON.parse(storedavailabilitydetails);

      const parseSchedule = (visits) => {
        return visits.reduce((acc, visit) => {
          acc[visit.day] = {
            isEnabled: visit.isavailable === 1,
            times: visit.timings.map((time) => ({
              from: time.fromtime,
              to: time.totime
            }))
          };
          return acc;
        }, {});
      };

      setClinicalSchedule(parseSchedule(availabilityData.clinicalvisits));
      setVideoCallSchedule(parseSchedule(availabilityData.videovisit));
    }
    fetchtimezones();
  }, []);
  const fetchtimezones = async () => {
    try {
      const result = await FetchTimezones({
        "type":"timezone",
      });
      if (result != null) {
        //timezonesdisplay = result && `[${result.map(item => `"${item.GMTOffset}"`).join(", ")}]`;
        //console.log(timezonesdisplay)
        setTimeZoneData(result)

      } else {
      }
    } catch (error) {
      console.error("Error in home:", error);
    }
  };
  const switchClicked = (scheduleType, day) => {
    if (scheduleType === 'clinical') {
      setClinicalSchedule((prev) => ({
        ...prev,
        [day]: {
          ...prev[day],
          isEnabled: !prev[day].isEnabled,
          times: prev[day].isEnabled ? [] : prev[day].times.length === 0 ? [{ from: "09:00", to: "18:00" }] : []
        }
      }));
    } else {
      setVideoCallSchedule((prev) => ({
        ...prev,
        [day]: {
          ...prev[day],
          isEnabled: !prev[day].isEnabled,
          times: prev[day].isEnabled ? [] : prev[day].times.length === 0 ? [{ from: "09:00", to: "18:00" }] : []
        }
      }));
    }
    setHasChangesMade(true);
  };

  const addTimeSlot = (scheduleType, day) => {
    if (scheduleType === 'clinical') {
      setClinicalSchedule((prev) => ({
        ...prev,
        [day]: {
          ...prev[day],
          times: [...prev[day].times, { from: "", to: "" }]
        }
      }));
    } else {
      setVideoCallSchedule((prev) => ({
        ...prev,
        [day]: {
          ...prev[day],
          times: [...prev[day].times, { from: "", to: "" }]
        }
      }));
    }
    setHasChangesMade(true);
  };

  const removeTimeSlot = (scheduleType, day, index) => {
    if (scheduleType === 'clinical') {
      setClinicalSchedule((prev) => ({
        ...prev,
        [day]: {
          ...prev[day],
          times: prev[day].times.filter((_, i) => i !== index)
        }
      }));
    } else {
      setVideoCallSchedule((prev) => ({
        ...prev,
        [day]: {
          ...prev[day],
          times: prev[day].times.filter((_, i) => i !== index)
        }
      }));
    }
    setHasChangesMade(true);
  };

  const handleDropdownChange = (scheduleType, value, day, idx, type) => {
    const schedule = scheduleType === 'clinical' ? clinicalSchedule : videoCallSchedule;
    const updatedTimes = [...schedule[day].times];
  
    // Set the new time for 'from' or 'to' based on the type
    updatedTimes[idx] = { ...updatedTimes[idx], [type]: value };
  
    // Check for duplicate time slots on the same day
    const isDuplicate = updatedTimes.some((time, index) => {
      if (index === idx) return false; // Skip the current slot being edited
      return time.from === updatedTimes[idx].from && time.to === updatedTimes[idx].to;
    });
  
    if (isDuplicate) {
      showErrorToast("This time slot already exists. Please select a different time.");
      return;
    }
  
    // Update the appropriate schedule based on the type
    if (scheduleType === 'clinical') {
      setClinicalSchedule((prev) => ({
        ...prev,
        [day]: {
          ...prev[day],
          times: updatedTimes,
        }
      }));
    } else {
      setVideoCallSchedule((prev) => ({
        ...prev,
        [day]: {
          ...prev[day],
          times: updatedTimes,
        }
      }));
    }
  };

  // don't delete this function
  // const handleDropdownChange = (scheduleType, value, day, index, field) => {
  //   if (scheduleType === 'clinical') {
  //     setClinicalSchedule((prev) => ({
  //       ...prev,
  //       [day]: {
  //         ...prev[day],
  //         times: prev[day].times.map((time, i) =>
  //           i === index ? { ...time, [field]: value } : time
  //         )
  //       }
  //     }));
  //   } else {
  //     setVideoCallSchedule((prev) => ({
  //       ...prev,
  //       [day]: {
  //         ...prev[day],
  //         times: prev[day].times.map((time, i) =>
  //           i === index ? { ...time, [field]: value } : time
  //         )
  //       }
  //     }));
  //   }
  // };

  
  var dropZone = timezones.map((item)=>(item.TimeZone));
  var dropId = timezones.map((item)=>(item.GMTOffset));
  var dropVal = timezones.map((item)=>(item.id))
  var mergedValue = dropZone.map((zone,index) => `${zone} (${dropId[index]})`)
  const data = dropVal.map((id, index) => ({
    id: id,
    // value: dropId[index],
    value: mergedValue[index],
  }));
  const handleSelectTimezone = (object) => {
    // console.log('object: ', object)
    // console.log("Selected Value:", timezones.value);
    //  console.log("Selected ID:", timezones.id);
    // setSelectedTimeZone(timezones.id)
    localStorage.setItem("selectedTimezone", JSON.stringify(object.value));
    setSelectedTimeZoneValue(object.value)
    setSelectedTimeZone(object.id)
  };
  const handleSubmit = async () => {

  const allDisabledClinic = Object.keys(clinicalSchedule).every(day => !clinicalSchedule[day].isEnabled);
  const allDisabledVideo = Object.keys(videoCallSchedule).every(day => !videoCallSchedule[day].isEnabled);

  if (allDisabledClinic && allDisabledVideo) {
    showErrorToast("Please select your availability.");
    return;
  }
  // if(selectedTimezoneValue == '') {
  //   showErrorToast('Please select timezone.')
  //   return;
  // }
    // if (!hasChangesMade) {
    //   showErrorToast("Please select your availability.");
    //   return;
    // }
    const clinicalResult = {
      data: Object.keys(clinicalSchedule).map((day) => ({
        day: day,
        appointmenttype_cd: 693, // Appointment type code for clinical visit
        appointmenttype: "clinical visit",
        isavailable: clinicalSchedule[day].isEnabled ? 1 : 0,
        // timings: clinicalSchedule[day].times.map((time) => ({
        //   fromtime: time.from,
        //   totime: time.to,
        //   id: null
        // }))
        timings: clinicalSchedule[day].isEnabled ? clinicalSchedule[day].times.map((time) => ({
          fromtime: time.from,
          totime: time.to,
          id: null
        })) : []
      })),
    };

    const videoCallResult = {
      data: Object.keys(videoCallSchedule).map((day) => ({
        day: day,
        appointmenttype_cd: 694, // Appointment type code for video call visit
        appointmenttype: "video call",       
        isavailable: videoCallSchedule[day].isEnabled ? 1 : 0,
        // timings: videoCallSchedule[day].times.map((time) => ({
        //   fromtime: time.from,
        //   totime: time.to,
        //   id: null
        // }))
        timings: videoCallSchedule[day].isEnabled ? videoCallSchedule[day].times.map((time) => ({
          fromtime: time.from,
          totime: time.to,
          id: null
        })) : []
      })),
    };

    // console.log("Clinical Visits Submitted Data:", clinicalResult);
    // console.log("Video Call Visits Submitted Data:", videoCallResult);

    let input = {
      "doctorid": "",
      "timezone_cd": selectedtimezoneid,
      "type":"insert",
      clinicalvisits: clinicalResult.data,
      videovisit: videoCallResult.data
    };
    localStorage.setItem("availabilitydetails", JSON.stringify(input));
    setTimeout(() => {
        const newActiveComponent = "slots";
        onSubmit(newActiveComponent);
      }, 1000);
  };

  const renderSchedule = (scheduleType, schedule) => (
    <Box display="flex" gap="1rem" flexDir="column">
      {Object.keys(schedule).map((day, index) => (
        <React.Fragment key={day}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" gap="0.5rem" alignItems={day === "Monday" ? 'start' : "center"} flexDir='column'>
              <Box display='flex' gap='0.75rem' alignItems="center">
                <Switch
                  colorScheme="green"
                  size={{ base: "md", lg: "lg" }}
                  isChecked={schedule[day].isEnabled}
                  onChange={() => switchClicked(scheduleType, day)}
                />
                <Text fontSize="0.875rem" fontWeight="600">
                  {day}
                </Text>
              </Box>
              {/* {day == 'Monday' && (
                // <Checkbox>
                <Box as='button' onClick={() => applyMondayToAll(scheduleType)}>
                  <Text
                    fontSize="0.65rem"
                    fontWeight="600"
                    // color='blue'
                    // letterSpacing='normal'
                    textDecor='underline'
                    letterSpacing="0.10rem"
                    >
                    Apply for All
                  </Text>
                </Box>
              )} */}

{/* {
  day === 'Monday' && renderApplyButton(scheduleType)
} */}
            </Box>
            {schedule[day].isEnabled && (
              <Box w="55%">
                {schedule[day].times.map((time, idx) => (
                  <Box
                    key={idx}
                    display="flex"
                    alignItems="center"
                    gap={{ base: "0.25rem", lg: "0.5rem" }}
                    mb="0.5rem"
                  >
                    <Dropdown
                      options={hours}
                      inputStyleCss={{ fontSize: { base: "12px", lg: "14px" }, h: { base: "40px", lg: "48px" } }}
                      placeholder="From"
                      value={time.from}
                      onClick={(value) => handleDropdownChange(scheduleType, value, day, idx, "from")}
                    />
                    <Text fontSize={{ base: "1rem", lg: "1.75rem" }}>-</Text>
                    <Dropdown
                      options={hours}
                      inputStyleCss={{ fontSize: { base: "12px", lg: "14px" }, h: { base: "40px", lg: "48px" } }}
                      placeholder="To"
                      value={time.to}
                      onClick={(value) => handleDropdownChange(scheduleType, value, day, idx, "to")}
                    />
                    {idx === 0 && (
                      <>
                      
                      <Box
                        w={{ base: "2rem", lg: "1.75rem" }}
                        ml="0.5rem"
                        as="button"
                        onClick={() => addTimeSlot(scheduleType, day)}
                      >
                        <Image w="100%" src="/assets/svgs/add.svg" alt="Add Time Slot" />
                      </Box>

                      <Menu>
  <MenuButton as="button" cursor="pointer">
    <Image
      w="1.5rem"
      src="/assets/imgs/copy.png"
      alt="Copy Times To"
    />
  </MenuButton>
  <MenuList>
    <FloatDropdown
      day={day}
      scheduleType={scheduleType}
      schedule={schedule}
      updateTimesForDays={updateTimesForDays}
    />
  </MenuList>
</Menu>
                      </>
                    )}
                    {idx !== 0 && (
                      <Box
                        w={{ base: "2rem", lg: "1.75rem" }}
                        ml="0.5rem"
                        as="button"
                        onClick={() => removeTimeSlot(scheduleType, day, idx)}
                      >
                        <Image w="100%" src="/assets/svgs/delete.svg" alt="Delete Time Slot" />
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
            )}
          </Box>
          
          {/* Insert 'Apply for All' button after Monday */}
          {/* {day === 'Monday' && (
            <Box ml={{ base: "3rem", lg: "3rem" }} mt="0.5rem">
              <PrimaryButton
                buttonText="Apply for All"
                btnStyle={{ size: { base: "sm", lg: "md" }, w: { base: "150px", lg: "200px" } }}
                onClick={() => applyMondayToAll(scheduleType)}
              />
            </Box>
          )} */}
        </React.Fragment>
      ))}
    </Box>
  );

  const applyMondayToAll = (scheduleType) => {
    const schedule = scheduleType === 'clinical' ? clinicalSchedule : videoCallSchedule;
    const mondaySchedule = schedule['Monday'];

    const updatedSchedule = Object.keys(schedule).reduce((acc, day) => {
        if (day === 'Monday') {
            // Leave Monday's schedule as it is
            acc[day] = mondaySchedule;
        } else if (schedule[day].isEnabled) {
            // Apply Monday's schedule only to enabled days
            acc[day] = {
                isEnabled: schedule[day].isEnabled,
                times: mondaySchedule.isEnabled ? [...mondaySchedule.times] : []
            };
        } else {
            // Leave disabled days as they are
            acc[day] = schedule[day];
        }
        return acc;
    }, {});

    // Update the appropriate schedule state
    if (scheduleType === 'clinical') {
        setClinicalSchedule(updatedSchedule);
    } else if (scheduleType === 'videoCall') {
        setVideoCallSchedule(updatedSchedule);
    }

    setHasChangesMade(true);
    showSuccessToast("Monday's schedule has been applied to all enabled days.");
};

const updateTimesForDays = (sourceDay, targetDays, scheduleType) => {
  const schedule = scheduleType === "clinical" ? clinicalSchedule : videoCallSchedule;

  // Get times from the source day
  const sourceTimes = schedule[sourceDay]?.times || [];

  // Update times for selected days
  const updatedSchedule = { ...schedule };
  targetDays.forEach((targetDay) => {
    updatedSchedule[targetDay] = {
      ...updatedSchedule[targetDay],
      isEnabled: true, // Enable the day if it's disabled
      times: [...sourceTimes], // Copy source times
    };
  });

  // Update the state
  if (scheduleType === "clinical") {
    setClinicalSchedule(updatedSchedule);
  } else {
    setVideoCallSchedule(updatedSchedule);
  }

  setHasChangesMade(true);
};

const renderApplyButton = (scheduleType) => {
// alert(scheduleType)
  const shouldDisplayButton =
    (scheduleType === 'clinical' && clinic === "1") ||
    (scheduleType === 'videoCall' && video === "1");

  return shouldDisplayButton ? (
    <Box as="button" onClick={() => applyMondayToAll(scheduleType)}>
      <Text
        fontSize="0.65rem"
        fontWeight="600"
        textDecor="underline"
        letterSpacing="0.10rem"
      >
        Apply for All
      </Text>
    </Box>
  ) : null;
};

  return (
    <>
      <Flex w="100%" display={{base: "none", lg: "flex"}}>
        {isLoading && <Loader />}
        <Box w="100%" p="2rem" pr="0">
          <Box w="100%">
          <Flex w="100%" my="1rem" justifyContent="space-between" alignItems="center">
              <Text fontSize="22px" w="70%" fontWeight="600">
                Set your availability
              </Text>

              <Box w="50%" mr="2rem">
                {/* <DropdownWithID
                  variant="fullSide"
                  placeholder="Timezone"
                  // dataIds={
                  //   timezones && timezones.map((item)=>(
                  //     item.id
                  //   ))
                  // }
                  dropDownPlace="Select Timezone"
                  options={data}
                  // onChange={(e)=>selectedtimezone(e)}
                  onClick={handleSelectTimezone}
                /> */}
                       <SearchableDropdown
                  options={data}
                  labelKey="value" // Specify key for display label
                  idKey="id" // Specify key for unique identifier
                  placeholder="Timezone"
                //  defaultValue={selectedCurrency}
                 defaultValue={selectedTimezoneValue}
                            onClick={handleSelectTimezone}
                />
              </Box>
            </Flex>

            <Flex w="100%" gap="1.25rem" overflow="hidden auto" h="calc(100vh - 225px)" pr="1.5rem" pb="1.5rem">
              {/* Clinic visit start */}
              <Box w="100%" h="fit-content" border="1px solid #E6E7E9" borderRadius="10px">
                <Box p="1.5rem" borderBottom="1px solid #E6E7E9">
                  <Text
                    fontSize="0.75rem"
                    fontWeight="600"
                    textTransform="uppercase"
                    letterSpacing="0.10rem"
                  >
                    For Clinic visits
                  </Text>
                </Box>
                <Box p="1.25rem 1.5rem" display="flex" flexDir="column" gap="1rem">
                  {renderSchedule('clinical', clinicalSchedule)}
                </Box>
              </Box>
              {/* Clinic visit end */}

              {/* Video call start */}
              <Box w="100%" h="fit-content" border="1px solid #E6E7E9" borderRadius="10px">
                <Box p="1.5rem" borderBottom="1px solid #E6E7E9">
                  <Text
                    fontSize="0.75rem"
                    fontWeight="600"
                    textTransform="uppercase"
                    letterSpacing="0.10rem"
                  >
                    For Video Calls
                  </Text>
                </Box>
                <Box p="1.25rem 1.5rem" display="flex" flexDir="column" >
                  {renderSchedule('videoCall', videoCallSchedule)}
                </Box>
              </Box>
              {/* Video call end */}
            </Flex>
          </Box>
          <Box p="1.5rem 2rem" display="flex" justifyContent="end" w="100%" borderTop="1px solid #E6E7E9">
            <PrimaryButton buttonText="Continue" btnStyle={{ w: "30%" }} onClick={handleSubmit} />
          </Box>
        </Box>
      </Flex>

      {/* Mobile responsive start */}
      <Flex flexDir="column" display={{base: "flex", lg: "none"}}>
      {isLoading && <Loader />}
        <Box w="100%">
          <Box w="100%">
          <Flex w="100%" p="1rem" gap="1.25rem" flexWrap="wrap" overflow="hidden auto" h="calc(100vh - 180px)" >
          <Flex w="100%" justifyContent="space-between" alignItems="center">
              <Text fontSize="1rem" fontWeight="600">
                Set your availability
              </Text>

              <Box w="50%">
                {/* <DropdownWithID
                  variant="fullSide"
                  placeholder="Timezone"
                  // dataIds={
                  //   timezones && timezones.map((item)=>(
                  //     item.id
                  //   ))
                  // }
                  menuList={{pos: "absolute", right: "4px", minW: "150px", overflow:"hidden auto"}}
                  dropDownPlace="Select Timezone"
                  options={data}
                  // onChange={(e)=>selectedtimezone(e)}
                  onClick={handleSelectTimezone}
                /> */}
                 <SearchableDropdown
                  isMatchWidth={true}
                  options={data}
                  menuList={{minW: "150px"}}
                  labelKey="value" // Specify key for display label
                  idKey="id" // Specify key for unique identifier
                  placeholder="Timezone"
              //    defaultValue={selectedCurrency}
                            onClick={handleSelectTimezone}
                />
              </Box>
            </Flex>

              {/* Clinic visit start */}
              <Box w="100%" h="fit-content" border="1px solid #E6E7E9" borderRadius="10px">
                <Box p="1.5rem" borderBottom="1px solid #E6E7E9">
                  <Text
                    fontSize="0.75rem"
                    fontWeight="600"
                    textTransform="uppercase"
                    letterSpacing="0.10rem"
                  >
                    For Clinic visits
                  </Text>
                </Box>
                <Box p="1rem" display="flex" flexDir="column" gap="1rem">
                  {renderSchedule('clinical', clinicalSchedule)}
                </Box>
              </Box>
              {/* Clinic visit end */}

              {/* Video call start */}
              <Box w="100%" h="fit-content" border="1px solid #E6E7E9" borderRadius="10px">
                <Box p="1.5rem" borderBottom="1px solid #E6E7E9">
                  <Text
                    fontSize="0.75rem"
                    fontWeight="600"
                    textTransform="uppercase"
                    letterSpacing="0.10rem"
                  >
                    For Video Calls
                  </Text>
                </Box>
                <Box p="1rem" display="flex" flexDir="column" >
                  {renderSchedule('videoCall', videoCallSchedule)}
                </Box>
              </Box>
              {/* Video call end */}
              <Box p="0" display="flex" justifyContent="end" w="100%">
                <PrimaryButton buttonText="Continue" onClick={handleSubmit} />
              </Box>
            </Flex>
          </Box>
        </Box>
      </Flex>
    </>
  );
};

export default SetAvailability; 