import formAPIlabs from "./lab";


var formattedOutput = null;

const formAPIModellabs = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIlabs(item));


  return formattedOutput
};

export default formAPIModellabs