export default function processTreatmentPlanData(treatmentPlan, visitDate) {
  if (!Array.isArray(treatmentPlan)) {
    return [];
  }

  const filteredData = treatmentPlan.filter((treatment) => {
    if (visitDate) {
      const treatmentDate = treatment.date.split("T")[0]; // Extract date part
      const visitDatePart = visitDate.split("T")[0]; // Extract date part of visitDate
      return treatmentDate === visitDatePart;
    }
    return true;
  });

  const processedData = filteredData.map((treatment) => ({
    diagnosis: treatment.diagnosis,
    plan: treatment.plan,
    date: treatment.date,
    id: treatment.id,
  }));

  return processedData.sort((a, b) => new Date(b.date) - new Date(a.date));
}
