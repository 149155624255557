import React, { useState, useEffect } from "react";
import {
  Flex,
  Box,
  Image,
  Radio,
  RadioGroup,
  Stack,
  InputGroup,
  InputRightElement,
  Input,
  Text
} from "@chakra-ui/react";
import {
  DropdownInput,
  PrimaryButton,
  PrimaryInput,
  Toaster,
} from "../../components";
import SuccessUploadIndex from "./success";
import { useLocation } from "react-router-dom";
import useLoader from "../../components/loader/loaderstates";
import { useNavigate } from "react-router-dom";
import DatePicker from "../../components/date-picker";
import { formatDate_DD_MM_YYYY, getDoctorId, getLoginID, getPatientId, handleAlphaDotAndSpace, useScreenType } from "../../../src/auth";
import moment from "moment";
import { MessageToaster } from "../../components/manualmessage";
import { AddMedicalRecord, lablearnMore } from "../../middleware/services/messages/uploaddocument";
// import Navbar from "../../components/navbar";
import SecondaryNav from "../../components/seconday-nav";
import Loader from "../../components/loader/loader";
import DynamicModal from "../../components/dynamic-modal";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";

function UploadPDFDocument({ pdfdata,actualdata, previewimage, pdfurl }) {
  const location = useLocation();
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [pdfData, setPDFData] = useState(pdfdata);
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [doctorName, setDoctorName] = useState("");
  const [reportDate, setReportDate] = useState("");
  const [reportName, setReportName] = useState("");
  const [selectedImage, setSelectedImage] = useState(previewimage);
  const [showPatientDetails, setShowPatientDetails] = useState(true);
  const [showPreview, setShowPreview] = useState(true);
  const [activeTestIndex, setActiveTestIndex] = useState(0);
  const [activeReportIndex, setActiveReportIndex] = useState(0);
  const [showCustomInput, setShowCustomInput] = useState(false);
  const [showEditTestName, setShowEditTestName] = useState(false);
  const [selectedRadioValues, setSelectedRadioValues] = useState([]);
  const [manualInput, setManualInput] = useState("");
  const [testNameInput, setTestNameInput] = useState("");
  const [abnormalTests, setAbnormalTests] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const { showSuccessToast, showErrorToast } = Toaster();
  const { showMessageToast, closeMessageToast } = MessageToaster();
  const [abnormalData, setAbnormalData] = useState([]);
  const [testName, setTestName] = useState("");
  const [file, setFile] = useState(location.state.file);
  const [cleanedValue, setCleanedValue] = useState("");
  const [nameConfirmClick, setNameConfirmClick] = useState(false);
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const learnMoreModal = React.useRef();
  const ageInYears = Array.from({ length: 100 }, (_, i) => String(i + 1));
  const [learnmore, setLearnMoreData] = useState("")
  const screenType = useScreenType();
  let storedData = localStorage.getItem("dobAndGenderData");
  let parsedData = storedData ? JSON.parse(storedData) : {};
  let PatientDOB = parsedData.dob || "";
  
  useEffect(() => {
    const nameConfirm = document.getElementById("testNameConfirm");
    if (nameConfirm) {
      nameConfirm.click();
    }
  }, [nameConfirmClick]);

  useEffect(() => {
    if (pdfData && pdfData.length > 0) {
      const currentReport = pdfData[activeReportIndex];
      processFetchedData(currentReport);
    }
  }, [pdfData, activeReportIndex]);

  const processFetchedData = (result) => {
      if (result &&  result.test_list) {
            const filteredTestList = result.test_list.filter(test => {
              const suggestion = test.suggestions[0];
              const lowerCaseSuggestion = suggestion.toLowerCase();
              const isSingleSpecialChar = /^[^\w\s]$/.test(suggestion);
              const containsSpecificSubstrings = lowerCaseSuggestion.includes('%6') || lowerCaseSuggestion.includes('mg/dl');
              return !isSingleSpecialChar && !containsSpecificSubstrings;
            });
            setData({ ...result, test_list: filteredTestList });
          }
    //setData(result);
    setName(result.patient_info.name);
    //setAge(result.patient_info.age);
    const age = result && result.patient_info && result.patient_info.age;
    // Regular expression to check for valid numeric age
    const isValidAge = /^\d+$/.test(age);
    const ageNumber = Number(age);

    // Check if age is valid and within the specified range
    if (isValidAge && ageNumber >= 1 && ageNumber <= 200) {
      setAge(ageNumber); // Set state to the valid age
    } else {
      setAge(null); // Set state to null if age is invalid
    }
    setDoctorName(result.patient_info.doctor_name);
    const dateOfReport = result.patient_info.date_of_report;
    if (dateOfReport) {
      const formattedDate = formatDate_DD_MM_YYYY(dateOfReport);
      if (formattedDate === '-') {
        const today = new Date();
        setReportDate(today);
      } else {
        setReportDate(formattedDate);
      }
    } else {
      const today = new Date();
      setReportDate(today);
    }
    //setReportDate(dateOfReport ? formatDate_DD_MM_YYYY(dateOfReport) : moment().format('YYYY-MM-DD'));
    setReportName(result.report_info.name);
    setShowPreview(false);
    dismissLoader();
    closeMessageToast();
  };

  const handlePatientDetailsConfirm = () => {
    setShowPatientDetails(false);
  };

  const handleRadioChange = (event, testIndex) => {
    if (event === "manual") {
      setShowCustomInput(true);
    } else {
      setShowCustomInput(false);
    }
    setSelectedRadioValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[testIndex] = event;
      return updatedValues;
    });
  };

  const handleEditTestName = (index) => {
    var currentTestName = data.test_list[index].test_name;
    setTestNameInput(currentTestName);
    setShowEditTestName(true);
  };

  const handleManualInputChange = (event, index , isInput) => {
    
    const { value } = event.target;
    
    let value2 = value;
    if(isInput){
    let cleanedValue = event.target.value.replace(/[^0-9a-zA-Z.\s]/g, ''); 
    const decimalIndex = cleanedValue.indexOf('.');
    if (decimalIndex !== -1) {
      const parts = cleanedValue.split('.');
      cleanedValue = parts[0] + '.' + parts[1];
    }
    value2 = cleanedValue;
    setCleanedValue(value2);
  }

    if (value === 'manual') {
      setManualInput((prevInputs) => ({
        ...prevInputs,
        [index]: '',
      }));
    } else {
      setManualInput((prevInputs) => ({
        ...prevInputs,
        [index]: value2,
      }));
    }
  };


  const backFunction = () => {
    window.history.back();
  };

  const handleTestNameChange = (event) => {
    setTestNameInput(event.target.value);
  };

  const handleTestNameUpdate = (index) => {
    setShowEditTestName(false);
    if (testNameInput.trim() !== "") {
      setData((prevData) => {
        const updatedData = { ...prevData };
        updatedData.test_list[index].test_name = testNameInput;
        return updatedData;
      });
    }
  };

  const handleNameChange = (event) => {
    let { value } = event.target;
    value  = handleAlphaDotAndSpace(value, 50);
    setName(value);
    
    setData((prevData) => ({
      ...prevData,
      patient_info: {
        ...prevData.patient_info,
        name: value,
      },
    }));
  };

  const handleDoctorNameChange = (event) => {
    let { value } = event.target;
    value  = handleAlphaDotAndSpace(value, 50);
    setDoctorName(value);

    setDoctorName(value);
    setData((prevData) => ({
      ...prevData,
      patient_info: {
        ...prevData.patient_info,
        doctor_name: value,
      },
    }));
  };

  const handleReportNameChange = (event) => {
    const { value } = event.target;
    setReportName(value);
    setData((prevData) => ({
      ...prevData,
      report_info: {
        ...prevData.report_info,
        name: value,
      },
    }));
  };

  const handleDOBChange = (selectedOption) => {
    setAge(selectedOption);
    setData((prevData) => ({
      ...prevData,
      patient_info: {
        ...prevData.patient_info,
        age: selectedOption,
      },
    }));
  };

  const handleDateChange = (selectedDate) => {
    const formattedDate = moment(selectedDate).format('YYYY-MM-DD');
    setReportDate(formattedDate);
    setData((prevData) => ({
      ...prevData,
      patient_info: {
        ...prevData.patient_info,
        date_of_report: formattedDate,
      },
    }));
  };

  const today = moment().format("YYYY-MM-DD");

  function isValidDateFormat(dateString) {
    const dateFormat1 = /^\d{4}-\d{2}-\d{2}$/;
    const dateFormat2 = /^\d{2}-\d{4}-\d{2}$/;
    return dateFormat1.test(dateString) || dateFormat2.test(dateString);
  }

  const handleConfirm = async () => {
    setNameConfirmClick(!nameConfirmClick);
    setShowCustomInput(false);
  
    const updatedData = {
      ...data,
      patient_info: {
        ...data.patient_info,
        patientid: getPatientId(),
        // activeuserid: getDoctorId(),
        "doctorid":getLoginID(),
        activeuserid: getDoctorId(),
        documenturl: pdfurl,
        date_of_report: isValidDateFormat(data.patient_info.date_of_report)
          ? data.patient_info.date_of_report
          : today,
      },
    };
  
    const newAbnormalTests = [];
    const updatedTestList = [...data.test_list];
  
    updatedData.test_list.forEach((test, index) => {
      let selectedValue = selectedRadioValues[index];
  
      // Default to the first suggestion if no value is selected
      if (!selectedValue) {
        selectedValue = test.suggestions[0];
      }
  
      if (selectedValue === "manual") {
        selectedValue = manualInput[index];
        test.resultvalue = selectedValue;
        test.ismanuallyadded = "1";
        const value = parseFloat(selectedValue);
        const lowerBound = parseFloat(test.lower_bound);
        const upperBound = parseFloat(test.upper_bound);
        if (isNaN(value) || value < lowerBound || value > upperBound) {
          newAbnormalTests.push(test);
        }
      } else {
        test.resultvalue = selectedValue;
        test.ismanuallyadded = "0";
        const lowerBound = parseFloat(test.lower_bound);
        const upperBound = parseFloat(test.upper_bound);
        if (
          (typeof selectedValue === "number" &&
            (selectedValue < lowerBound || selectedValue > upperBound)) ||
          (typeof selectedValue === "string" &&
            (parseFloat(selectedValue) < lowerBound ||
              parseFloat(selectedValue) > upperBound))
        ) {
          newAbnormalTests.push(test);
        }
      }
    });
  
    setAbnormalTests((prevAbnormalTests) => [
      ...prevAbnormalTests,
      ...newAbnormalTests,
    ]);
  
    setAbnormalData((prevAbnormalData) => [
      ...prevAbnormalData,
      ...newAbnormalTests,
    ]);
    setTestName(updatedData.report_info.name);
  
    // Update the test list in the state
    setData((prevData) => ({
      ...prevData,
      test_list: updatedTestList,
    }));
    const FinalInput = { ...updatedData, actualinput: actualdata };
    // If it's the last test of the current report, make the API call
    if (activeTestIndex === data.test_list.length - 1) {
      await AddMedicalRecord(FinalInput);
  
      // If the current report index is not the last one, move to the next report
      if (activeReportIndex < pdfData.length - 1) {
        resetPatientInfo();
        setActiveReportIndex((prevIndex) => prevIndex + 1);
        setActiveTestIndex(0);
        setShowPatientDetails(true);
        processFetchedData(pdfData[activeReportIndex + 1]);
      } else {
        // Otherwise, show the success page
        setShowSuccess(true);
        dismissLoader();
      }
    } else {
      // If the current test index is not the last one, move to the next test
      setActiveTestIndex((prevIndex) => prevIndex + 1);
    }
  };
  

  const resetPatientInfo = () => {
    setName("");
    setAge("");
    setDoctorName("");
    setReportDate("");
    setReportName("");
    setSelectedRadioValues([]);
    setManualInput("");
    setTestNameInput("");
  };

  useEffect(() => {
    if (data) {
      setName(data.patient_info.name);
      setAge(data.patient_info.age);
      setDoctorName(data.patient_info.doctor_name);
      setReportDate(data.patient_info.date_of_report);
      setReportName(data.report_info.name);
    }
  }, [data]);
  const openLearnMoreModal = async (labdetails)=>{
    showLoader();
     const result = await lablearnMore({
       name: labdetails.test_name
     })
     if(result.information){
       setLearnMoreData(result)   
       learnMoreModal.current.openModal();
     }
     dismissLoader();
   }
  return (
    <>
      {!showSuccess ? (
        <>
        {screenType == 'web' ? (
          <Box w="100%">
            <Box top="0">
              {/* <Navbar /> */}
              {isLoading && <Loader />}
              <SecondaryNav withBack="Upload document" goBack={backFunction} />
            </Box>
            {!showPreview && (
              <Flex overflowY="auto" height="100vh" direction="row">
                <Box flex="1" overflowY="scroll" p="32px" bg="#E6E7E9">
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    h="100vh"
                  >
                    <Image w="calc(100vh - 25%)" src={selectedImage} />
                  </Box>
                </Box>

                <Box
                  w="500px"
                  h="100%"
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  overflowY="auto"
                  height="calc(100vh - 140px)"
                >
                  {showPatientDetails && data && data.patient_info && (
                    <>
                      <Box overflowY="auto" p="24px 32px" h="100%">
                        <Box as="h1" fontSize="18px" pb="20px" fontWeight="600">
                          Please verify the details below
                        </Box>
                        <PrimaryInput
                          inputPlace="Name"
                          onChange={handleNameChange}
                          defaultValue={name && name ? name : ""}
                          variant="defaultSide"
                          inputValue={name}
                        />
                        <DropdownInput
                          variant="bothSide"
                          onClick={handleDOBChange}
                          defaultOption={age}
                          dropDownPlace="Age"
                          options={ageInYears}
                        />
                        <PrimaryInput
                          inputPlace="Doctor name"
                          onChange={handleDoctorNameChange}
                          defaultValue={doctorName}
                          variant="bothSide"
                          inputValue={doctorName}
                        />

                        <DatePicker
                          disableFutureDates={true}
                          defaultDate={reportDate}
                          patientDOB={PatientDOB}
                            CUSTOM_DISABLE={true}
                          onDateChange={(selectedDateValue) =>
                            handleDateChange(selectedDateValue)
                          }
                          isEdit={true}
                          placeholderProp={"Date of document"}
                          leftPos={{ left: "3px" }}
                        />
                        <PrimaryInput
                          inputPlace="Document name"
                          onChange={handleReportNameChange}
                          defaultValue={reportName}
                          variant="downSide"
                        />
                      </Box>

                      <Box
                        w="100%"
                        display="flex"
                        alignItems="center"
                        p="24px 32px"
                        gap="20px"
                      >
                        <PrimaryButton
                          onClick={handlePatientDetailsConfirm}
                          buttonText="Confirm"
                        />
                      </Box>
                    </>
                  )}
                  {!showPatientDetails && (
                    <>
                      {data &&
                        data.test_list &&
                        data.test_list.map(
                          (step, index) =>
                            index === activeTestIndex && (
                              <Box overflowY="auto" key={index}>
                                <Box
                                  bg="#061027"
                                  minH={{ sm: "140px", xl: "180px" }}
                                  p="18px 28px"
                                  display="flex"
                                  flexDir="column"
                                  gap="8px"
                                >
                                  <Box display="flex" gap="8px">
                                    {data.test_list.map((_, i) => (
                                      <Box
                                        as="div"
                                        key={i}
                                        p="0"
                                        borderTop={`3px solid ${i === activeTestIndex ? "#14C585" : "#212b40"}`}
                                        w={`${100 / data.test_list.length}%`}
                                        h="20px"
                                      ></Box>
                                    ))}
                                  </Box>
                                  <Box>
                                    <Box
                                      display="flex"
                                      flexDir="row"
                                      justifyContent="space-between"
                                      gap="10px"
                                      alignItems="center"
                                    >
                                      <Box display="flex" alignItems="center" gap="10px">
                                      {activeTestIndex > 0 && (
                                      <Box as="button" onClick={() => setActiveTestIndex((prevIndex) => prevIndex - 1)} display="flex" flexDirection="row" alignItems="center" gap="10px" sx={{bg: "none", _hover: {bg:"none"}}}>
                                        <Image w="15px" mr="5px" src="/assets/imgs/arrow_back.png"/>
                                    </Box>
                                    )}
                                      <Box
                                        fontSize="20px"
                                        fontWeight="600"
                                        color="#E6E7E9"
                                      >
                                        {!showEditTestName && (
                                          <>
                                            {step.test_name ? step.test_name : "-"}
                                            <Image
                                              w="13px"
                                              ml="10px"
                                              onClick={() => handleEditTestName(index)}
                                              h="13px"
                                              display="inline-block"
                                              src="/assets/imgs/edit-white.png"
                                            />
                                          </>
                                        )}
                                        {showEditTestName && (
                                          <>
                                            <Box>
                                              <Box
                                                display="flex"
                                                flexDir="row"
                                                justifyContent="space-between"
                                                gap="5px"
                                                alignItems="center"
                                              >
                                                <InputGroup>
                                                  <Input
                                                    type="text"
                                                    defaultValue={step.test_name}
                                                    color="white"
                                                    fontSize="16px"
                                                    fontWeight="600"
                                                    border="2px solid white"
                                                    borderRadius="10px"
                                                    height="48px"
                                                    pr="48px"
                                                    minW="50px"
                                                    onChange={handleTestNameChange}
                                                  />
                                                  <InputRightElement top="4px">
                                                    <Box
                                                      as="button"
                                                      bg="white"
                                                      display="flex"
                                                      justifyContent="center"
                                                      alignItems="center"
                                                      height="48px"
                                                      minW="50px"
                                                      borderRadius="0px 10px 10px 0"
                                                      id="testNameConfirm"
                                                      onClick={() => handleTestNameUpdate(index)}
                                                    >
                                                      <Image src="/assets/imgs/check-black.png" />
                                                    </Box>
                                                  </InputRightElement>
                                                </InputGroup>
                                              </Box>
                                            </Box>
                                          </>
                                        )}
                                      </Box>
                                      </Box>
                                      <Box
                                      as="button"
                                      border="1px solid #0B1F4F"
                                      color="white"
                                      fontSize="12px"
                                      fontWeight="600"
                                      borderRadius="full"
                                      p="5px 10px"
                                      onClick={()=>openLearnMoreModal(step)}
                                    >
                                      Learn more
                                    </Box>
                                    </Box>
                                    <Box
                                      as="p"
                                      fontSize="12px"
                                      fontWeight="600"
                                      color="white"
                                    >
                                      NL: {step.lower_bound ? step.lower_bound : ""}{" "}
                                      to {step.upper_bound ? step.upper_bound : ""}{" "}
                                      {step.test_unit !== "None"
                                        ? step.test_unit
                                        : ""}
                                    </Box>
                                    <Box
                                      as="p"
                                      pt="15px"
                                      fontSize="14px"
                                      fontWeight="400"
                                      lineHeight="normal"
                                      color="white"
                                    >
                                      {step.test_description
                                        ? step.test_description
                                        : "-"}
                                    </Box>
                                  </Box>
                                </Box>
                                <Box overflowY="auto" p="24px 32px">
                                  <Box
                                    as="h1"
                                    fontSize="18px"
                                    pb="20px"
                                    fontWeight="600"
                                  >
                                    Please verify the measurement
                                  </Box>
                                  <Box
                                    border="1px solid #E6E7E9"
                                    borderRadius="10px"
                                  >
                                    <RadioGroup
                                      defaultValue={step.suggestions[0]}
                                      onChange={(event) =>
                                        handleRadioChange(event, activeTestIndex)
                                      }
                                    >
                                      <Stack direction="column">
                                        {step.suggestions &&
                                          step.suggestions.map((item, index) => (
                                            <Box
                                              key={index}
                                              as="button"
                                              cursor="pointer"
                                              borderBottom="1px solid #E6E7E9"
                                              p="16px"
                                              fontSize="14px"
                                              fontWeight="600"
                                            >
                                              <Flex
                                                as="label"
                                                justifyContent="space-between"
                                                alignItems="center"
                                              >
                                                <span>{item}</span>
                                                <Radio
                                                  size="lg"
                                                  value={item}
                                                />
                                              </Flex>
                                            </Box>
                                          ))}
                                        <Box
                                          key="manual"
                                          as="button"
                                          cursor="pointer"
                                          borderBottom="1px solid #E6E7E9"
                                          p="16px"
                                          fontSize="14px"
                                          fontWeight="600"
                                        >
                                          <Flex
                                            as="label"
                                            justifyContent="space-between"
                                            alignItems="center"
                                          >
                                            <span>Enter manually</span>
                                            <Radio
                                              size="lg"
                                              value={"manual"}
                                              id="manualentry"
                                              onChange={(event) =>
                                                handleManualInputChange(
                                                  event,
                                                  index, false
                                                )
                                              }
                                            />
                                          </Flex>
                                        </Box>
                                        {showCustomInput && (
                                          <PrimaryInput
                                            inputPlace="Value"
                                            variant="defaultSide"
                                            value={manualInput[index]}
                                            inputValue={cleanedValue}
                                            onChange={(event) =>
                                              handleManualInputChange(event, index, true)
                                            }
                                          />
                                        )}
                                      </Stack>
                                    </RadioGroup>
                                  </Box>
                                </Box>
                              </Box>
                            )
                        )}
                      <Box w="100%" p="18px 28px" mt="auto">
                        <PrimaryButton
                          buttonText={
                            activeTestIndex === data.test_list.length - 1 &&
                            activeReportIndex === pdfData.length - 1
                              ? "Submit"
                              : "Continue"
                          }
                          btnStyle={{ my: "0" }}
                          onClick={handleConfirm}
                        />
                      </Box>
                    </>
                  )}
                </Box>
              </Flex>
            )}
          </Box>
          ) : (
          //Mobile responsive
          <Box w="100%">
          {isLoading && <Loader />}
            {!showPreview && (
              <Flex overflowY="auto" height="100vh" direction="row">
                {/* <Box flex="1" overflowY="scroll" p="32px" bg="#E6E7E9">
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    h="100vh"
                  >
                    <Image w="calc(100vh - 25%)" src={selectedImage} />
                  </Box>
                </Box> */}

                <Box
                  w="100%"
                  h="100%"
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  overflowY="auto"
                  height="calc(100vh - 140px)"
                >
                  {showPatientDetails && data && data.patient_info && (
                    <>
                      <Box overflowY="auto" p="24px 32px" h="100%">
                        <Box as="h1" fontSize="18px" pb="20px" fontWeight="600">
                          Please verify the details below
                        </Box>
                        <PrimaryInput
                          inputPlace="Name"
                          onChange={handleNameChange}
                          defaultValue={name && name ? name : ""}
                          variant="defaultSide"
                          inputValue={name}
                        />
                        <DropdownInput
                          variant="bothSide"
                          onClick={handleDOBChange}
                          defaultOption={age}
                          dropDownPlace="Age"
                          options={ageInYears}
                        />
                        <PrimaryInput
                          inputPlace="Doctor name"
                          onChange={handleDoctorNameChange}
                          defaultValue={doctorName}
                          variant="bothSide"
                          inputValue={doctorName}
                        />

                        <DatePicker
                          disableFutureDates={true}
                          defaultDate={reportDate}
                          patientDOB={PatientDOB}
                            CUSTOM_DISABLE={true}
                          onDateChange={(selectedDateValue) =>
                            handleDateChange(selectedDateValue)
                          }
                          isEdit={true}
                          placeholderProp={"Date of document"}
                          leftPos={{ left: "3px" }}
                        />
                        <PrimaryInput
                          inputPlace="Document name"
                          onChange={handleReportNameChange}
                          defaultValue={reportName}
                          variant="downSide"
                        />
                      </Box>

                      <Box
                        w="100%"
                        display="flex"
                        alignItems="center"
                        p="24px 32px"
                        gap="20px"
                      >
                        <PrimaryButton
                          onClick={handlePatientDetailsConfirm}
                          buttonText="Confirm"
                        />
                      </Box>
                    </>
                  )}
                  {!showPatientDetails && (
                    <>
                      {data &&
                        data.test_list &&
                        data.test_list.map(
                          (step, index) =>
                            index === activeTestIndex && (
                              <Box overflowY="auto" key={index}>
                                <Box
                                  bg="#061027"
                                  minH={{ sm: "140px", xl: "180px" }}
                                  p="18px 28px"
                                  display="flex"
                                  flexDir="column"
                                  gap="8px"
                                >
                                  <Box display="flex" gap="8px">
                                    {data.test_list.map((_, i) => (
                                      <Box
                                        as="div"
                                        key={i}
                                        p="0"
                                        borderTop={`3px solid ${i === activeTestIndex ? "#14C585" : "#212b40"}`}
                                        w={`${100 / data.test_list.length}%`}
                                        h="20px"
                                      ></Box>
                                    ))}
                                  </Box>
                                  <Box>
                                    <Box
                                      display="flex"
                                      flexDir="row"
                                      justifyContent="space-between"
                                      gap="10px"
                                      alignItems="center"
                                    >
                                      <Box
                                        fontSize="20px"
                                        fontWeight="600"
                                        color="#E6E7E9"
                                      >
                                        {!showEditTestName && (
                                          <>
                                            {step.test_name ? step.test_name : "-"}
                                            <Image
                                              w="13px"
                                              ml="10px"
                                              onClick={() => handleEditTestName(index)}
                                              h="13px"
                                              display="inline-block"
                                              src="/assets/imgs/edit-white.png"
                                            />
                                          </>
                                        )}
                                        {showEditTestName && (
                                          <>
                                            <Box>
                                              <Box
                                                display="flex"
                                                flexDir="row"
                                                justifyContent="space-between"
                                                gap="5px"
                                                alignItems="center"
                                              >
                                                <InputGroup>
                                                  <Input
                                                    type="text"
                                                    defaultValue={step.test_name}
                                                    color="white"
                                                    fontSize="16px"
                                                    fontWeight="600"
                                                    border="2px solid white"
                                                    borderRadius="10px"
                                                    height="48px"
                                                    pr="48px"
                                                    minW="50px"
                                                    onChange={handleTestNameChange}
                                                  />
                                                  <InputRightElement top="4px">
                                                    <Box
                                                      as="button"
                                                      bg="white"
                                                      display="flex"
                                                      justifyContent="center"
                                                      alignItems="center"
                                                      height="48px"
                                                      minW="50px"
                                                      borderRadius="0px 10px 10px 0"
                                                      id="testNameConfirm"
                                                      onClick={() => handleTestNameUpdate(index)}
                                                    >
                                                      <Image src="/assets/imgs/check-black.png" />
                                                    </Box>
                                                  </InputRightElement>
                                                </InputGroup>
                                              </Box>
                                            </Box>
                                          </>
                                        )}
                                      </Box>
                                      <Box
                                      as="button"
                                      border="1px solid #0B1F4F"
                                      color="white"
                                      fontSize="12px"
                                      fontWeight="600"
                                      borderRadius="full"
                                      p="5px 10px"
                                      onClick={()=>openLearnMoreModal(step)}
                                    >
                                      Learn more
                                    </Box>
                                    </Box>
                                    <Box
                                      as="p"
                                      fontSize="12px"
                                      fontWeight="600"
                                      color="white"
                                    >
                                      NL: {step.lower_bound ? step.lower_bound : ""}{" "}
                                      to {step.upper_bound ? step.upper_bound : ""}{" "}
                                      {step.test_unit !== "None"
                                        ? step.test_unit
                                        : ""}
                                    </Box>
                                    <Box
                                      as="p"
                                      pt="15px"
                                      fontSize="14px"
                                      fontWeight="400"
                                      lineHeight="normal"
                                      color="white"
                                    >
                                      {step.test_description
                                        ? step.test_description
                                        : "-"}
                                    </Box>
                                  </Box>
                                </Box>
                                <Box overflowY="auto" p="24px 32px">
                                  <Box
                                    as="h1"
                                    fontSize="18px"
                                    pb="20px"
                                    fontWeight="600"
                                  >
                                    Please verify the measurement
                                  </Box>
                                  <Box
                                    border="1px solid #E6E7E9"
                                    borderRadius="10px"
                                  >
                                    <RadioGroup
                                      defaultValue={step.suggestions[0]}
                                      onChange={(event) =>
                                        handleRadioChange(event, activeTestIndex)
                                      }
                                    >
                                      <Stack direction="column">
                                        {step.suggestions &&
                                          step.suggestions.map((item, index) => (
                                            <Box
                                              key={index}
                                              as="button"
                                              cursor="pointer"
                                              borderBottom="1px solid #E6E7E9"
                                              p="16px"
                                              fontSize="14px"
                                              fontWeight="600"
                                            >
                                              <Flex
                                                as="label"
                                                justifyContent="space-between"
                                                alignItems="center"
                                              >
                                                <span>{item}</span>
                                                <Radio
                                                  size="lg"
                                                  value={item}
                                                />
                                              </Flex>
                                            </Box>
                                          ))}
                                        <Box
                                          key="manual"
                                          as="button"
                                          cursor="pointer"
                                          borderBottom="1px solid #E6E7E9"
                                          p="16px"
                                          fontSize="14px"
                                          fontWeight="600"
                                        >
                                          <Flex
                                            as="label"
                                            justifyContent="space-between"
                                            alignItems="center"
                                          >
                                            <span>Enter manually</span>
                                            <Radio
                                              size="lg"
                                              value={"manual"}
                                              id="manualentry"
                                              onChange={(event) =>
                                                handleManualInputChange(
                                                  event,
                                                  index, false
                                                )
                                              }
                                            />
                                          </Flex>
                                        </Box>
                                        {showCustomInput && (
                                          <PrimaryInput
                                            inputPlace="Value"
                                            variant="defaultSide"
                                            value={manualInput[index]}
                                            inputValue={cleanedValue}
                                            onChange={(event) =>
                                              handleManualInputChange(event, index, true)
                                            }
                                          />
                                        )}
                                      </Stack>
                                    </RadioGroup>
                                  </Box>
                                </Box>
                              </Box>
                            )
                        )}
                      <Box w="100%" p="18px 28px" mt="auto">
                        <PrimaryButton
                          buttonText={
                            activeTestIndex === data.test_list.length - 1 &&
                            activeReportIndex === pdfData.length - 1
                              ? "Submit"
                              : "Continue"
                          }
                          btnStyle={{ my: "0" }}
                          onClick={handleConfirm}
                        />
                      </Box>
                    </>
                  )}
                </Box>
              </Flex>
            )}
          </Box>
          )}
          <DynamicModal
          ref={learnMoreModal}
          modalHeader={learnmore.measure_name ? learnmore.measure_name +"("+learnmore.measure_unit+")" :""}

          modalStyle={{maxWidth: "calc(100vw - 45%)",maxHeight: "calc(100vh - 30%)", overflow:"hidden auto", w: "100%"}}
          modalBody={
            <>
            
            <Box mb="10px" >
              <Text fontSize="14px" fontWeight="600">
                Information  
              </Text>
              <Box as="span" >{learnmore.information ? learnmore.information : ''}</Box>
              </Box>
              
              <Box mb="10px" >
              <Text fontSize="14px" fontWeight="600">
                Upper Bound 
              </Text>
              <Box as="span"  > {learnmore.upper_bound && learnmore.upper_bound !== "NA" ? learnmore.upper_bound : '-'}</Box>
              </Box>
              
            <Box mb="10px" >
              <Text fontSize="14px" fontWeight="600">
                  Lower Bound
              </Text>
              <Box as="span" mb="10px">{learnmore.lower_bound && learnmore.lower_bound !== "NA" ? learnmore.lower_bound : "-" }</Box>
              </Box>
            </>
          }
        />
        </>
        
      ) : (
        <SuccessUploadIndex data={abnormalData} testname={testName} />
      )}
    </>
  );
}

export default UploadPDFDocument;
