import  FetchData, { } from '../../client';
import formapimodalhome from '../../domains/home/home';
import {getLoginID}  from '../../../auth';
import formAPIModelMentalhealthscreen from '../../domains/home/mentalhealthscreen/mentalhealthscreens';

//reac the file

const FetchMentalScreenQuestions = async (inputData) => {
  try {
    // inputData.doctorid = getLoginID();
    const result = await FetchData("midoc_getvisitformdetail", inputData);
    // console.log(result,"Questions db response")
    if (result.output.data) {
        var finalOutput = formAPIModelMentalhealthscreen(result.output.data)
    }
    // console.log(finalOutput,"finalOutput")

    return finalOutput;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};

export const addQuestions = async (inputData) => {
    try {
    //   inputData.doctorid = getLoginID();
      const result = await FetchData("midoc_addvisitformdetail", inputData);
      
      return result;
  
    } catch (error) {
      console.error('Error in addQuestions :', error);
      throw error;
    }
  };

// const updatedoctoravailability = async (inputData) => {
//   try {
//     inputData.doctorid = getLoginID();
//     const result = await FetchData("midoc_updatedoctorprofile", inputData);
    
//     return result;

//   } catch (error) {
//     console.error('Error fetching data in forms.js:', error);
//     throw error;
//   }
// };

export {FetchMentalScreenQuestions};
