import {
    Box,
    Checkbox,
    Divider,
    Heading,
    Image,
    RadioGroup,
    Stack,
    Step,
    StepDescription,
    StepIndicator,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    Text,
  } from "@chakra-ui/react";
import React, { useEffect, useState, useRef } from "react";
import { addMedicalConditions, deleteMedicalConditions, EditCondition, FetchMedicalHistoryDetails, getmedicalcondition } from "../../middleware/services/messages";
import moment from "moment";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import { useLocation, useNavigate } from "react-router-dom";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import { actionAccessMain, handleAlphaNumericDotAndSpace, useScreenType } from "../../auth";
import { PrimaryButton, PrimaryInput, SearchBar, Toaster } from "../../components";
import { format } from 'date-fns';
import ModalRight from "../../components/modal-right";
import DynamicModal from "../../components/dynamic-modal";
import DatePicker from "../../components/date-picker";
import DatePickerCombined from "../../components/date-picker-new";


const MedicalHistory = ({Id,backFunction}) => {

  const location = useLocation();
  const [patientid, setPatientid] = useState("");
  const [medicalData, setMedicalData] = useState(null);
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const navigate = useNavigate()
  const screenType = useScreenType()
  
  let patientidFromLocal = localStorage.getItem("patientid")
  
  const [actionAccessPatients , setActionAccessPatients] = useState(null);
  useEffect(() => {
    
    setActionAccessPatients(actionAccessMain(4))
    const currentUrl = window.location.href;
    const searchParams = new URLSearchParams(currentUrl.split('?') [1]);
    const id = searchParams.get('id');
    setPatientid(id);
    fetchmedicaldata()   
  }, []);
  
  const fetchmedicaldata = async () => {
    showLoader()
        try {
          const result = await FetchMedicalHistoryDetails({
            // patientid: id,   
        "patientid":patientidFromLocal,
          });

          if (result != null) {
            console.log(result)
            setMedicalData(result);
          } else {
            setMedicalData('')
          }
          dismissLoader()
      } catch (error) {
          console.error("Error in home:", error);
        }
      };
      const formatDate = (dateString) => {
        if (!dateString) return "-";
        return moment(dateString).format("Do MMM, YYYY");
      };

  // ----------------------------------------------------------------------------
  const dynamicModal = React.useRef();
  const addCondition = React.useRef();
  const editCondition = React.useRef();
  const addCondition2 = React.useRef();
  const addConditionModalInsideEdit = React.useRef();
  const { showSuccessToast, showErrorToast } = Toaster();

  const [data, setData] = useState('')
  var [conditionsList, setConditionsList] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [selectedConditions, setSelectedConditions] = useState([]);
  const [selectedDates, setSelectedDates] = useState({});
  const [id, setId] = useState('')
  const [condition, setCondition] = useState('')
  const [ConditionName, setConditionName] = useState('')
  const [ConditionDate, setConditionDate] = useState('')
  const [ConditionID, setConditionID] = useState('')
  const [ConditionTypeID, setConditionTypeID] = useState('')
  
  const [typedCondition , setTypedCondition] = useState("")
  const chatContainerRef = useRef(null);
  const [aianswers, setAIAnswers] = useState([]);
  const [remainingquestions, setRemainingQuestions] = useState([]);
  const [aiquestion, setAIAnalysis] = useState(null);
  const [AiInput, setAiInput] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  const [animate, setAnimate] = useState(false);
  const AIChatModel = useRef();
  let storedData = localStorage.getItem("dobAndGenderData");
  let parsedData = storedData ? JSON.parse(storedData) : {};
  let PatientDOB = parsedData.dob || "";

  // useEffect(() => {
  //   fetchData();
  // }, []);

  var inputForGet = {

  }
  // const fetchData = async () => {
  //   showLoader();
  //   try {
  //     const result = await getMedicalConditions(inputForGet);
  //     if(result && result.output && result.output.result == "failure"){
  //       setData(null)
  //     }else if (result) {
  //       const sortedData = result.map(item => ({
  //         ...item,
  //         contiondata: item.contiondata.sort((a, b) => parseInt(b.id) - parseInt(a.id))
  //       }));
  //       setData(sortedData)
  //     } else {
  //       showErrorToast("Something went wrong!")
  //     }


  //   } catch (error) {
  //     console.error("Error in DashboardIndex:", error);
  //   }
  //   dismissLoader();
  // };

  const fetchMedicalConditionList = async () => {
    try {
      const result = await getmedicalcondition({
        type: "healthcondition",
        "patientid":patientidFromLocal,
      });
      if (result.output.result == "success") {
        setConditionsList(result.output.data);
      } else {
        showErrorToast("Something went wrong!")
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  const handleCheckboxChange = (condition, category) => {
    const isSelected = selectedConditions.some(
      (item) => item.condition_cd === condition.condition_cd
    );

    if (isSelected) {
      setSelectedConditions((prevConditions) =>
        prevConditions.filter(
          (item) => item.condition_cd !== condition.condition_cd
        )
      );
    } else {
      setSelectedConditions((prevConditions) => [
        ...prevConditions,
        { ...condition, category },
      ]);
    }
  };

  const handleCheckboxChangeForEdit = (selectedCondition, category) => {
    setSelectedConditions([])
    setConditionName(selectedCondition.condition)
    setConditionType(category.conditiontype)
    // Create a new array with only the selected condition
    const newSelectedConditions = [{
      condition_cd: selectedCondition.condition_cd,
      condition: selectedCondition.condition,
      category: category // Assuming you want to store the category of the condition as well
    }];
    setSelectedConditions(newSelectedConditions);
  };
  

  const handleDateChange = (conditionCd, date) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    setSelectedDates((prevDates) => ({
      ...prevDates,
      [conditionCd]: formattedDate,
    }));
  };

  var inputForAdd = {
    medicalcondition: selectedConditions.map((condition) => ({
      conditiontype_cd: condition?.category?.conditiontype_cd ,
      condition_cd: condition.condition_cd,
      nomenclatureid: condition.condition_cd,
      conditionidentifieddate: selectedDates[condition.condition_cd] || "",
      "condition":condition.condition,
      "conditiontype":condition.category.conditiontype
    })),
    "patientid":patientidFromLocal,
  };


  const AddMedicalConditions = () => {
    if (selectedConditions.length === 0 && typedCondition == "") {
      showErrorToast("Please Select a Condition to Continue")
    } else {
      if (typedCondition !== "") {
        const newSelectedCondition = {
          category: {
            conditiontype: "other",
            conditiontype_cd: null,
          },
          condition: typedCondition,
          condition_cd: null,
        };
    
        setSelectedConditions((prevConditions) => {
          // Filter out any existing condition with the same type
          const filteredConditions = prevConditions.filter(
            (item) => item.category.conditiontype !== "other"
          );
          // Add the new condition
          return [...filteredConditions, newSelectedCondition];
        });
      }
     
      setSelectedDates({})
      addCondition2.current.openRight()
    }

  }
  const AddMedicalConditionsCall = () => {

    const addConditions = async () => {
      showLoader();
      try {
        console.log(inputForAdd ," inputForAdd")
        const result = await addMedicalConditions(inputForAdd);
        if (result.output.result == "success") {
          showSuccessToast("Condition added Successfully!")
          setTimeout(() => {

            addCondition2.current.closeRight();
            addCondition.current.closeRight();
          //  dismissLoader();
          }, 700);
          fetchmedicaldata();
        } else {
          showErrorToast("Something went wrong!")
        }
      } catch (error) {
        console.error("Error in DashboardIndex:", error);
      }
    };
    const isDateMissing = selectedConditions.some(
      (condition) => !selectedDates[condition.condition_cd]
    );

    if (isDateMissing) {

      showErrorToast("Please Select the Date")
      dismissLoader();
      return false;
    } else {

      addConditions();


    }
  }

  var inputForDelete = {
    id,
    "patientid":patientidFromLocal,
  }
  const deleteMedicalCondition = async () => {

    const deleteCondition = async () => {
      showLoader();
      try {
        const result = await deleteMedicalConditions(inputForDelete);
        if (result.output.result == "successfully deleted") {
          
          if(condition.length > 30){
            showErrorToast(
              <span>
                <Box maxWidth="200px">
                <Text className="textOverflow" wordBreak="break-all">{condition}</Text> has been deleted! 
                </Box>
              </span>
            );
          }else{
            showErrorToast(condition + "  has been deleted!")
          }
     
          fetchmedicaldata();
          dynamicModal.current.closeModal();
        } else {
          showErrorToast("Somethnig went wrong!")
        }

      } catch (error) {
        console.error("Error in delete:", error);
      }
      dismissLoader();
    };
    deleteCondition();
  }

  const openDeleteModal = (id, condition) => {
    setId(id)
    setCondition(condition)

    dynamicModal.current.openModal();

  }

  const openAddConditionModal = () => {
    // if (conditionsList !== null) {
      fetchMedicalConditionList();
    // }
    setSearchValue('')
    setSelectedConditions([]);
    setSelectedDates({})
    setTypedCondition("")
    addCondition.current.openRight()
  }
  const handleDateChangeEditModel = (date) => {
    setConditionDate(date)
  };
  const [conditionType  , setConditionType] = useState("")
  const openEditModal = (condition, conditionid, date, id, conditiontype_cd , type , type_cd) => {
    console.log({condition, conditionid, date, id, conditiontype_cd, type , type_cd
    })
    
    fetchMedicalConditionList();
    setConditionType(type)
    setTypedCondition("")
    // if(type == "Other"){
    //   setTypedCondition(condition)
    // }
    setId(id)
    setConditionTypeID(conditiontype_cd)
    setConditionName(condition)
    setConditionID(conditionid)
    const conditiondate =  moment(date, 'YYYY-MM-DD').format('DD-MM-yyyy');
    setConditionDate(conditiondate)
    const newSelectedConditions = [{
      conditiontype_cd: conditiontype_cd,
      condition: condition,
       category: "" // Assuming you want to store the category of the condition as well
    }];
    setSelectedConditions(newSelectedConditions);
    editCondition.current.openRight()
  }
  var inputForEdit = {
    
    condition_cd: ConditionID ? ConditionID : selectedConditions[0]?.condition_cd,
    conditiontype_cd: ConditionTypeID ? ConditionTypeID : selectedConditions[0]?.category.conditiontype_cd,

      conditionidentifieddate: ConditionDate,
      id: id,
      // patientid: getLoginInfo(),
      // activeuserid: getUserID(),
      
      "patientid":patientidFromLocal,

      "condition":ConditionName,
    "conditiontype":conditionType
  };
  const UpdateCondition = async()=>{
    if (moment(inputForEdit.conditionidentifieddate, 'DD-MM-YYYY', true).isValid()) {
      inputForEdit.conditionidentifieddate = moment(inputForEdit.conditionidentifieddate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    }
    if (inputForEdit.conditionidentifieddate == "") {
      showErrorToast("Please select any date");
      return false;
    } else {
     
      showLoader();
      const result = await EditCondition(inputForEdit);
      
      if (result) {
        showSuccessToast("Condition updated Successfully");
        fetchmedicaldata();
        editCondition.current.closeRight()
      }
      dismissLoader();
    }
  }
  const editConditionName = () =>{
   if (conditionsList == null) {
    fetchMedicalConditionList();
  }
  setSearchValue('')
 // setSelectedConditions([]);
  setSelectedDates({})
  
   addConditionModalInsideEdit.current.openRight();
   setConditionID("")
   setConditionTypeID("")
  }
  const handleCondition = (e) => {
    const inputText = e.target.value;
    const maxLength = 80;
  
    if (inputText.length <= maxLength) {
      setTypedCondition(inputText);
    }
  
  }

  const selectConditionInEdit = ()=>{
      if(typedCondition){
        setConditionType("other")
        setConditionName(typedCondition)
      }
    addConditionModalInsideEdit.current.closeRight()
  }
  useEffect(() => {
    if (animate) {
      const timer = setTimeout(() => {
        setAnimate(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [animate]);

      return (
        <>
        {isLoading && <Loader />}
            {medicalData && medicalData!= null && medicalData.length>0 ? (
              <Box w="100%">
                {screenType == 'web' ? (
                <Box>
                  <Box pos='sticky' p='1rem 1.5rem' alignItems='center' top='0' zIndex='999' bg='white' borderBottom='1px solid #E6E7E9' display="flex" justifyContent="space-between" mb="10px">
                    <Text fontWeight='600'>Medical History</Text>
                    <PrimaryButton
                      fontSize="14px"
                      variant="mdBtn"
                      btnIcon="/assets/imgs/add.png"
                      buttonText="Add Condition"
                      onClick={openAddConditionModal}
                       isDisabled={actionAccessPatients?.create ? false : true}
                    />
                  </Box>
                  <Box h='calc(100vh - 140px)' overflow='hidden auto' padding='1rem 1.5rem'>
                  <Stepper
                    orientation="vertical"
                    // height="100%"
                    size="xs"
                    colorScheme="#CDCFD4"
                    gap="0"
                    w="100%"
                    // h='calc(100vh - 360px)' 
                    >
                    {medicalData && medicalData.map((medical, index) => (
                        medical.contiondata && medical.contiondata.map((item,subIndex) => (
                      <Step w="100%" key={index} >
                        <StepIndicator color="#E6E7E9" />
                        <Box flexShrink="0" w="95%" key={subIndex}>
                          <StepTitle fontSize="0.75rem" fontWeight="500" color="#384052" mb="0.5rem">
                            <Box display="flex" gap="1.5rem" mr='1rem' justifyContent='space-between'>
                            {/* 10th Jan, 2024 */}
                            <Box>

                            {item.conditionidentifieddate!= null ?  formatDate(item.conditionidentifieddate) : '-'}
                            </Box>
                            <Box display='flex' gap='1.5rem' flexDir='row' cursor='pointer' >
                                   <Box as="button" disabled={actionAccessPatients?.update ? false : true}
                                   opacity={actionAccessPatients?.update ? 1 : 0.5} >
                                    <Image 
                                    src="/assets/svgs/edit.svg" 
                                    w='1rem'
                                    h='auto'
                                    // onClick={() => openEditModal(surgical)}
                                    onClick={() => openEditModal(item.condition, item.condition_cd, item.conditionidentifieddate, item.id, medical.conditiontype_cd , medical.conditiontype , medical.conditiontype_cd)} 
                                    />
                                    </Box>
                                    <Box as="button" disabled={actionAccessPatients?.delete ? false : true}
                                     opacity={actionAccessPatients?.delete ? 1 : 0.5}>
                                    <Image 
                                    src="/assets/svgs/delete.svg"
                                    w='14px'
                                    h='auto'
                                    // onClick={() => openDeleteModal(surgical.id, surgical.proceduretitle)}
                                    onClick={() => openDeleteModal(item.id, item.condition)}
                                    />
                                    </Box>
                            </Box>
                                </Box>
                          </StepTitle>
                          <StepDescription
                            bg="white"
                            w="100%"
                            border="1px solid #E6E7E9"
                            borderRadius="0.65rem"
                            p="0.75rem"
                            mb="1.5rem"
                            boxShadow="0px 8px 20px 0px #E6E7E966"
                          >
                            <Box w="fit-content" className="textOverflow">
                              <Text fontSize="0.875rem" color="#061027">
                              {/* A-fib (CMS/HCC) */}
                              {item.condition != null ? item.condition : '-'}
                              </Text>
                            </Box>
                          </StepDescription>
                        </Box>
                              
          
      <StepSeparator colorScheme="red" />
                      </Step>
                        ))
                  ))}
                  </Stepper>
                  </Box>
                </Box>
                ) : (
                ///Mobile responsive
                <Box w="100%">
                  <MobileSecondaryNav
                    header="Medical history"
                    handlebackarrow={backFunction}
                  />
                  <Box h="calc(100vh - 120px)" overflow="hidden auto" p="1rem">
                  <Stepper
                    orientation="vertical"
                    height="100%"
                    size="xs"
                    colorScheme="#CDCFD4"
                    gap="0"
                    w="100%"
                    >
                    {medicalData&& medicalData.map((medical, index) => (
                        medical.contiondata.map((item,subIndex) => (
                      <Step w="100%" key={index} >
                        <StepIndicator color="#E6E7E9" />
                        <Box flexShrink="0" w="95%" key={subIndex}>
                          <StepTitle fontSize="0.75rem" fontWeight="500" color="#384052" mb="0.5rem">
                            {/* 10th Jan, 2024 */}
                            {item.conditionidentifieddate!==null?  formatDate(item.conditionidentifieddate): '-'}
                          </StepTitle>
                          <StepDescription
                            bg="white"
                            w="100%"
                            border="1px solid #E6E7E9"
                            borderRadius="0.65rem"
                            p="0.75rem"
                            mb="2rem"
                            boxShadow="0px 8px 20px 0px #E6E7E966"
                          >
                            <Box w="fit-content">
                              <Text fontSize="0.875rem" color="#061027">
                              {/* A-fib (CMS/HCC) */}
                              {item.condition!==null ? item.condition : '-'}
                              </Text>
                            </Box>
                          </StepDescription>
                        </Box>
                              
          
      <StepSeparator colorScheme="red" />
                      </Step>
                        ))
                  ))}
                  </Stepper>
                  </Box>
                </Box>
                )}
                </Box>
              ) : (
                <Box w='100%'>
<Box pos='sticky' p='1rem 1.5rem' alignItems='center' top='0' zIndex='999' bg='white' borderBottom='1px solid #E6E7E9' display="flex" justifyContent="space-between" mb="10px">
                    <Text fontWeight='600'>Medical History</Text>
                    <PrimaryButton
                      fontSize="14px"
                      variant="mdBtn"
                      btnIcon="/assets/imgs/add.png"
                      buttonText="Add Condition"
                      onClick={openAddConditionModal}
                    isDisabled={actionAccessPatients?.create ? false : true}
                    />
                  </Box>

                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  gap="10px"
                  alignItems="center"
                  h="calc(100vh - 100px)"
                  w="100%"
                >
                  <Box textAlign="left" fontSize="16px" fontWeight="600">
                    <Image
                      w="26px"
                      opacity="0.5"
                      src="/assets/imgs/medical-history.png"
                    />
                  </Box>
                  <Text textAlign="left" fontSize="12px" fontWeight="500">
                    No Data available
                  </Text>
                </Box>
                </Box>
              )}

<DynamicModal
          ref={dynamicModal}
          modalHeader="Delete condition?"
          modalBody={
            <>
              <Text fontSize="14px" fontWeight="600">
                Are you sure you want to delete "{condition}"
              </Text>
            </>
          }
          modalFooterBtn={
            <>
              <PrimaryButton
                variant="deleteBtn"
                buttonText="Delete"
                onClick={deleteMedicalCondition}
              />
            </>
          }
        />

        <ModalRight
          ref={addCondition}
          modalWidth="360px"
          header="Add condition"
          body={
            <>
              <SearchBar passInputPlace="Search condition" 
              onChange={(e) => setSearchValue(handleAlphaNumericDotAndSpace(e.target.value))}
              searchValue={searchValue} />
              <RadioGroup>
                <Stack direction="column" gap="0">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    borderBottom="1px solid #e2e8f0"
                  >
                  <Box display="flex" justifyContent="space-between" w="100%">
  <Stack direction="column" w="100%" gap="0">
    {conditionsList && conditionsList.length > 0 ? (
      <>
        {conditionsList.map((category) => {
          const filteredConditions = category.data.filter((condition) =>
            condition.condition.toLowerCase().includes(searchValue.toLowerCase())
          );

          return (
            <React.Fragment key={category.conditiontype_cd}>
              {filteredConditions.length > 0 && (
                <>
                  <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
                    {category.conditiontype}
                  </Heading>
                  {filteredConditions.map((condition) => (
                    <label key={condition.condition_cd}>
                      <Box
                        key={condition.condition_cd}
                        display="flex"
                        justifyContent="space-between"
                        borderBottom="1px solid #e2e8f0"
                        p="15px"
                        pl="5px"
                        minH="50px"
                        width="100%"
                        sx={{
                          _hover: { border: "2px solid #384052", borderRadius: "10px" },
                        }}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                        >
                          <Text fontSize="16px" lineHeight="normal" fontWeight="600">
                            {condition.condition}
                          </Text>
                        </Box>
                        <Box display="flex" justifyContent="end">
                          <Checkbox
                            colorScheme="green"
                            sx={{
                              ".chakra-checkbox__control": {
                                width: "20px",
                                height: "20px",
                                borderRadius: "5px",
                              },
                            }}
                            onChange={() => handleCheckboxChange(condition, category)}
                            isChecked={selectedConditions.some(
                              (item) => item.condition_cd === condition.condition_cd,
                            )}
                          ></Checkbox>
                        </Box>
                      </Box>
                    </label>
                  ))}
                </>
              )}
            </React.Fragment>
          );
        })}
        {/* Display "No matching conditions found" only if no conditions were found in any category */}
        {conditionsList.every((category) =>
          category.data.every((condition) =>
            !condition.condition.toLowerCase().includes(searchValue.toLowerCase())
          )
        ) && (
          <Box p="15px" fontSize="16px" fontWeight="600" textAlign="center">
          No matching conditions found!
        </Box>
        )}
        <Divider/>
          <br/>
          <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
                   Other Condition
                  </Heading>
        <PrimaryInput
         inputPlace="Condition"
         variant="bothSide"
                onChange={handleCondition} inputValue={typedCondition}
                />
                <br/>
      </>
      
    ) : (
      <p>Loading...</p>
    )}
  </Stack>
</Box>

                  </Box>
                </Stack>
              </RadioGroup>
              <ModalRight
                ref={addCondition2}
                header="Add condition"
                body={
                  <>
                    <Box display="flex" flexDir="column" gap="20px">
                      {selectedConditions.map((condition, index) => (
                        <Box key={index}>
                          <Text fontSize="16px" py="10px" fontWeight="600">
                            {condition.condition}
                          </Text>


                          <DatePickerCombined
                            disableFutureDates={true}
                            patientDOB={PatientDOB}
                            CUSTOM_DISABLE={true}
                            onDateChange={(e) => handleDateChange(condition.condition_cd, e)}
                            placeholderProp="Select a Date*"
                            calendarStlye={{ transform: "scale(0.9)", right: "-20px", top: "30px" }}
                          />
                        </Box>
                      ))}
                    </Box>
                  </>
                }
                footerActions={<>
                  <Box
                    w="100%"
                    display="flex"
                    flexDirection="row"
                    gap="15px">

                    <PrimaryButton
                      buttonText="Add Condition"
                      onClick={AddMedicalConditionsCall}
                      isDisabled={actionAccessPatients?.create ? false : true}
                    />
                  </Box>
                </>}
              />
                 
            </>
            
          }
          footerActions={<>
            <Box
              w="100%"
              display="flex"
              flexDirection="row"
              gap="15px">

              <PrimaryButton
                buttonText="Continue"
                isDisabled={false}
                onClick={AddMedicalConditions}
              />
            </Box>
          </>}
        />
        <ModalRight
          ref={editCondition}
          header="Edit condition"
          body={
            <>
              <Box display="flex" flexDir="column" gap="20px">
                  <Box>
                    <Box  onClick={editConditionName} alignItems="center" py="10px" borderBottom="1px solid var(--chakra-colors-chakra-border-color);" mb="10px" display="flex" justifyContent="space-between" cursor="pointer">
                    <Text title={ConditionName} w='230px' fontSize="16px" className="textOverflow" fontWeight="600" onClick={editConditionName}>
                      {ConditionName}
                    </Text>
                    <Image src="/assets/imgs/right-icon.png" />
                    </Box>


                    <DatePickerCombined
                      disableFutureDates={true}
                      patientDOB={PatientDOB}
                            CUSTOM_DISABLE={true}
                      defaultDate={ConditionDate}
                      onDateChange={(e) => handleDateChangeEditModel(e)}
                      placeholderProp="Select a Date*"
                      calendarStlye={{ transform: "scale(0.9)", right: "-20px", top: "30px" }}
                    />
                  </Box>
                
              </Box>

              

              <ModalRight
    ref={addConditionModalInsideEdit}
    modalWidth="360px"
    header="Update condition"
    body={
      <>
        <SearchBar passInputPlace="Search condition" onChange={(e) => setSearchValue(e.target.value)} />
        <RadioGroup>
          <Stack direction="column" gap="0">
            <Box
              display="flex"
              justifyContent="space-between"
              borderBottom="1px solid #e2e8f0"
            >
            <Box display="flex" justifyContent="space-between" w="100%">
<Stack direction="column" w="100%" gap="0">
{conditionsList && conditionsList.length > 0 ? (
<>
  {conditionsList.map((category) => {
    const filteredConditions = category.data.filter((condition) =>
      condition.condition.toLowerCase().includes(searchValue.toLowerCase())
    );

    return (
      <React.Fragment key={category.conditiontype_cd}>
        {filteredConditions.length > 0 && (
          <>
            <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
              {category.conditiontype}
            </Heading>
            {filteredConditions.map((condition) => (
              <label key={condition.condition_cd}>
                <Box
                  key={condition.condition_cd}
                  display="flex"
                  justifyContent="space-between"
                  borderBottom="1px solid #e2e8f0"
                  p="15px"
                  pl="5px"
                  minH="50px"
                  width="100%"
                  sx={{
                    _hover: { border: "2px solid #384052", borderRadius: "10px" },
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                  >
                    <Text fontSize="16px" lineHeight="normal" fontWeight="600">
                      {condition.condition}
                    </Text>
                  </Box>
                  <Box display="flex" justifyContent="end">
                    <Checkbox
                      colorScheme="green"
                      sx={{
                        ".chakra-checkbox__control": {
                          width: "20px",
                          height: "20px",
                          borderRadius: "5px",
                        },
                      }}
                      onChange={() => handleCheckboxChangeForEdit(condition, category)}
                      isChecked={selectedConditions.some(
                        (item) => item.condition == condition.condition,
                      )}
                    ></Checkbox>
                  </Box>
                </Box>
              </label>
            ))}
          </>
        )}
      </React.Fragment>
    );
  })}
  {/* Display "No matching conditions found" only if no conditions were found in any category */}
  {conditionsList.every((category) =>
    category.data.every((condition) =>
      !condition.condition.toLowerCase().includes(searchValue.toLowerCase())
    )
  ) && (
    <Box p="15px" fontSize="16px" fontWeight="600" textAlign="center">
    No matching conditions found!
  </Box>
  )}
</>
) : (
<p>Loading...</p>
)}
<Divider/>
    <br/>
    <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
              Other Condition
            </Heading>
  <PrimaryInput
    inputPlace="Condition"
    variant="bothSide"
          onChange={handleCondition} inputValue={typedCondition}
          />
          <br/>
</Stack>
</Box>

            </Box>
          </Stack>
        </RadioGroup>
        
            
      </>
      
    }
    footerActions={<>
      <Box
        w="100%"
        display="flex"
        flexDirection="row"
        gap="15px">

        <PrimaryButton
          buttonText="Continue"
          isDisabled={false}
          onClick={selectConditionInEdit}
        />
      </Box>
    </>}
  />
            </>
          }
          footerActions={<>
            <Box
              w="100%"
              display="flex"
              flexDirection="row"
              gap="15px">

              <PrimaryButton
                buttonText="Update Condition"
                isDisabled={false}
                onClick={UpdateCondition}
              />
            </Box>
          </>}
        />


        </>
      );
}

export default MedicalHistory;