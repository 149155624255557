import React, { useEffect } from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Toaster } from "../../../components";
import moment from 'moment';

const ExcelDownloadMonthly1 = ({ data, onDownloadComplete }) => {
  const { showSuccessToast, showErrorToast } = Toaster();
  const currentDate = new Date(); // Get the current date
const options = { month: 'long', year: 'numeric' }; // Define formatting options
const currentMonthYear = currentDate.toLocaleDateString('en-US', options); // Format as "Month Year"


  useEffect(() => {
    handleDownload(data?.table, data?.ageGroup, data?.averagePatientFlow);
   // console.log(data , " data to Download month 1")
  }, [data]);

  const handleDownload = (exceldata, ageGroupData, avgPatientFlow) => {
    if (!exceldata || Object.keys(exceldata).length === 0) {
      console.error("No data to download.");
      showErrorToast("No Data Available!");
      onDownloadComplete();
      return;
    }
  
    const workbook = XLSX.utils.book_new();
  
    const prepareSheetData = (data) => {
      const sheetData = [];
      // const allBranches = new Set();
      const merges = [];
  
      // data?.forEach((diagnose) => {
      //   diagnose?.subdiagnoses.forEach((sub) => {
      //     sub?.branches?.forEach((branch) => {
      //       allBranches.add(branch?.branchname);
      //     });
      //   });
      // });
      const allBranches = []; // Use an array instead of a Set

if (data?.length > 0) {
  const firstDiagnose = data[0]; // Get the first diagnose object
  const firstSubdiagnose = firstDiagnose?.subdiagnoses[0]; // Access the first subdiagnose object

  // Loop through the branches of the first subdiagnose object
  if (firstSubdiagnose?.branches?.length > 0) {
    firstSubdiagnose?.branches.forEach((branch) => {
      // Check if the branch name already exists, if not, add it
      // if (!allBranches.includes(branch?.branchname)) {
        allBranches.push(branch?.branchname);
      // }
    });
  }
}


      const branchColumns = Array.from(allBranches).sort((a, b) => {
        const numA = parseInt(a.replace("CHC", ""));
        const numB = parseInt(b.replace("CHC", ""));
        return numA - numB;
      });
  

      const reportName = [`Monthly Disease & Gender wise Consolidated Report for ${currentMonthYear}`];
      const emptyRow = [""];
      sheetData.push(reportName);
      merges.push({ s: { r: 0, c: 0 }, e: { r: 0, c: 6 } });
      
      
      sheetData.push(emptyRow)
      merges.push({ s: { r: 1, c: 0 }, e: { r: 1, c: 6 } });

      const header = ["Diagnose", "Sub-Diagnose", ...allBranches, "Total"];
      sheetData.push(header);
  
      const columnTotals = Array(allBranches.length).fill(0);
      let grandTotal = 0;
  
      data?.forEach((diagnose, diagnoseIndex) => {
        const startRow = sheetData.length;
        diagnose?.subdiagnoses?.forEach((sub) => {
          const row = [diagnose.diagnose, sub.name];
          let rowTotal = 0;
  
          allBranches.forEach((branch, index) => {
            const branchData = sub.branches.find((b) => b.branchname === branch);
            const branchValue = branchData ? branchData.value : 0;
            rowTotal += branchValue;
            columnTotals[index] += branchValue;
            row.push(branchValue);
          });
  
          row.push(rowTotal);
          grandTotal += rowTotal;
          sheetData.push(row);
        });
  
        const endRow = sheetData.length - 1;
        if (endRow > startRow) {
          merges.push({ s: { r: startRow, c: 0 }, e: { r: endRow, c: 0 } });
        }
      });
  
      const totalsRow = ["Total Patients Covered", "", ...columnTotals, grandTotal];
      sheetData.push(totalsRow);
      merges.push({ s: { r: sheetData.length - 1, c: 0 }, e: { r: sheetData.length - 1, c: 1 } });
  
      return { sheetData, merges };
    };
  
    // Helper to prepare age group data
const prepareAgeGroupData = (ageGroupData) => {
  const sheetData = [];
  const merges = [];
  
  // Add header row with only "AGE GROUP" in the first two cells, rest blank
  const totalColumns = ageGroupData[0].male.length; // Number of branches
  sheetData.push(["AGE GROUP", "", ...Array(totalColumns).fill(""), ""]);

  // Add data rows for each age group
  ageGroupData?.forEach((group) => {
    const startRow = sheetData.length; // Start row for merging
    
    // Male row
    const maleRow = [group.group, "Male"];
    let maleTotal = 0;
    group?.male?.forEach((branch) => {
      maleRow.push(branch.value);
      maleTotal += branch.value;
    });

    // Add the total for the male row
    maleRow.push(maleTotal);
    sheetData.push(maleRow);

    // Female row
    const femaleRow = ["", "Female"];
    let femaleTotal = 0;
    group?.female?.forEach((branch) => {
      femaleRow.push(branch.value);
      femaleTotal += branch.value;
    });

    // Add the total for the female row
    femaleRow.push(femaleTotal);
    sheetData.push(femaleRow);

    // Merge the "AGE GROUP" cell across male and female rows
    const endRow = sheetData.length - 1;
    merges.push({ s: { r: startRow, c: 0 }, e: { r: endRow, c: 0 } }); // Merge "AGE GROUP" column
  });

  return { sheetData, merges };
};

  
const prepareAvgPatientFlowData = (avgPatientFlow) => {
  const sheetData = [];

  // Extract the "Average Patient Flow Per Day" row
  avgPatientFlow.forEach((entry) => {
    const branchValues = entry?.branches?.map((branch) => branch.value);
    sheetData.push(["Average Patient Flow Per Day", "", ...branchValues]);
  });

  return sheetData;
};

  
    const { sheetData: mainData, merges: mainMerges } = prepareSheetData(exceldata);
    const { sheetData: ageData, merges: ageMerges } = prepareAgeGroupData(ageGroupData);
    const avgPatientFlowData = prepareAvgPatientFlowData(avgPatientFlow);
  
    const finalSheetData = [...mainData, [], ...ageData, [], ...avgPatientFlowData];
    const finalMerges = [
      ...mainMerges,
      ...ageMerges.map((merge) => ({
        s: { r: merge.s.r + mainData.length + 1, c: merge.s.c },
        e: { r: merge.e.r + mainData.length + 1, c: merge.e.c },
      })),
    ];
  
    const worksheet = XLSX.utils.aoa_to_sheet(finalSheetData);
    worksheet["!merges"] = finalMerges;

    // Adjust column widths to fit content
    const colWidths = [];
    finalSheetData?.forEach((row) => {
      row?.forEach((cell, colIndex) => {
        const cellValue = cell ? cell.toString() : '';
        const currentWidth = colWidths[colIndex] || 0;
        colWidths[colIndex] = Math.max(currentWidth, cellValue.length);
      });
    });

    worksheet["!cols"] = colWidths.map(width => ({ wch: width }));

    XLSX.utils.book_append_sheet(workbook, worksheet);

    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(dataBlob, `Monthly Disease & Gender wise Consolidated Report for ${currentMonthYear}.xlsx`);
  
    showSuccessToast("Download successful!");
    onDownloadComplete();
  };

  return null;
};

export default ExcelDownloadMonthly1;
