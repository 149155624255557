import React, { useState } from "react";
import {
  Box,
  VStack,
  HStack,
  Divider,
  Icon,
  Text,
  Progress as ChakraProgress,
  Image,
} from "@chakra-ui/react";
import AudioRecorder from "./chatAudioRecorder";
import gpuConstants from "./gpuConstants";
import Progress from "./progress";
import { BiMicrophone, BiSolidMicrophoneOff } from "react-icons/bi";
import { BiMicrophoneOff } from "react-icons/bi";
import DotElasticAnimation from "../../dot-listening";

export const AudioSource = {
  URL: "URL",
  FILE: "FILE",
  RECORDING: "RECORDING",
};

export function GPUChatAudioManager(props) {
  const [isRecording, setIsRecording] = useState(false);
  const [audioData, setAudioData] = useState(undefined);
  const [progress, setProgress] = useState(undefined);

  const isAudioLoading = progress !== undefined;

  const resetAudio = () => {
    setAudioData(undefined);
  };

  const setAudioFromRecording = async (data) => {
    resetAudio();
    setProgress(0);
    const blobUrl = URL.createObjectURL(data);
    const fileReader = new FileReader();
    fileReader.onprogress = (event) => {
      setProgress(event.loaded / event.total || 0);
    };
    fileReader.onloadend = async () => {
      const audioCTX = new AudioContext({
        sampleRate: gpuConstants.SAMPLING_RATE,
      });
      const arrayBuffer = fileReader.result;
      const decoded = await audioCTX.decodeAudioData(arrayBuffer);
      setProgress(undefined);
      setAudioData({
        buffer: decoded,
        url: blobUrl,
        source: AudioSource.RECORDING,
        mimeType: data.type,
      });
      // Start transcription automatically after recording stops
      props.transcriber.start(decoded);
    };
    fileReader.readAsArrayBuffer(data);
  };

  const handleMicClick = () => {
    setIsRecording((prevIsRecording) => !prevIsRecording);
    isRecording
      ? props.setPlaceholder("Processing ...")
      : props.setPlaceholder("Listening ...");
    props.setTyping(true); // it is disabled later when transcription appears
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      //   borderRadius="lg"
      //   bg="white"
      //   boxShadow="xl"
      //   p={4}
    >
      <HStack 
      // spacing={2} 
      // py={2} 
      w="full" 
      // px={2}
      >
        {isRecording ? (
          // <Icon
          //   as={BiMicrophone}
          //   onClick={handleMicClick}
          //   h={"36px"}
          //   w={"36px"}
          //   bg={"#1a202c"}
          //   color={"green"}
          //   cursor="pointer"
          //   border={"2px solid"}
          //   borderColor={"blue"}
          //   borderRadius={"full"}
          // />
          <Box onClick={handleMicClick} cursor='pointer'>
            <DotElasticAnimation />
          </Box>
          // <Image 
          //   onClick={handleMicClick}
          //   boxSize='30px'
          //   cursor='pointer'
          //   src='/assets/svgs/mute.svg'
          //   filter="invert(97%) sepia(70%) saturate(1%) hue-rotate(171deg) brightness(102%) contrast(101%)"
          // />
        ) : (
          //   <MicOffIcon w={6} h={6} color="blue.500" onClick={handleMicClick} />
          // <Icon
          //   as={BiSolidMicrophoneOff}
          //   onClick={handleMicClick}
          //   h={"36px"}
          //   w={"36px"}
          //   bg={"#1a202c"}
          //   color={"red"}
          //   cursor="pointer"
          // />
          <Image 
            onClick={handleMicClick}
            boxSize='30px'
            cursor='pointer'
            src='/assets/svgs/un-mute.svg'
            // filter="invert(97%) sepia(70%) saturate(1%) hue-rotate(171deg) brightness(102%) contrast(101%)"
          />
          //   <MicIcon w={6} h={6} onClick={handleMicClick} />
        )}
        {/* <VerticalBar /> */}
      </HStack>
      {audioData && (
        <>
          {/* <AudioPlayer audioUrl={audioData.url} mimeType={audioData.mimeType} /> */}
          {props.transcriber.progressItems.length > 0 && (
            <Box position="relative" zIndex={10} p={4} w="full">
              <Text mb={2}>Loading AIRA...</Text>
              <VStack spacing={2}>
                {props.transcriber.progressItems.map((data) => (
                  <Box key={data.file} w="full">
                    <Progress text={data.file} percentage={data.progress} />
                  </Box>
                ))}
              </VStack>
            </Box>
          )}
        </>
      )}
      {isAudioLoading && <AudioDataBar progress={progress} />}
      {/* Pass isRecording state to AudioRecorder */}
      <AudioRecorder
        recording={isRecording} // Pass the recording state as a prop
        onRecordingComplete={setAudioFromRecording}
      />
    </Box>
  );
}

function VerticalBar() {
  return <Divider orientation="vertical" bg="gray.200" height="24px" />;
}

function AudioDataBar(props) {
  return <ProgressBar progress={`${Math.round(props.progress * 100)}%`} />;
}

function ProgressBar(props) {
  return (
    <ChakraProgress
      value={props.progress}
      size="xs"
      colorScheme="blue"
      borderRadius="full"
      w="full"
      transition="all 0.1s ease"
    />
  );
}
