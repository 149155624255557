
import formAPIInventoryLogDetail from "./logdetail";


var formattedOutput = null;

const formAPIModelinventoryLogDetails = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIInventoryLogDetail(item));


  return formattedOutput
};

export default formAPIModelinventoryLogDetails