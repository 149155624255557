import React from "react";
import { Box, Text } from "@chakra-ui/react";

export default function Progress({ text, percentage }) {
  percentage = percentage ?? 0;

  return (
    <Box
      mt="1"
      w="100%"
      position="relative"
      fontSize="sm"
      color="white"
      bg="gray.200"
      borderWidth="1px"
      borderColor="gray.400"
      borderRadius="lg"
      textAlign="left"
      overflow="hidden"
    >
      <Box
        position="absolute"
        top="0"
        height="100%"
        bg="blue.500"
        whiteSpace="nowrap"
        px="2"
        style={{ width: `${percentage}%` }}
      >
        <Text>
          {text} ({`${percentage.toFixed(2)}%`})
        </Text>
      </Box>
    </Box>
  );
}
