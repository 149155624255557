import { getLoginID } from "../../../auth"
import FetchData from "../../client";
import formapimodalInvoicesPagination from "../../domains/invoices";
import formapimodalInvoices from "../../domains/invoices/invoices";

const FetchInvoices = async (inputData) => {
    try{
        inputData.doctorid = getLoginID();
        const result = await FetchData("midoc_getdoctorbillinginvoice", inputData);
        console.log(result, "Invoice db response")
        if(result.output){
            var finalOutput = formapimodalInvoicesPagination(result.output)
        }
        return finalOutput;
    } catch (error) {
        console.error('Error fetching data in forms.js', error);
        throw error;
    }
};

const FetchFilteredInvoices = async (inputData) => {
    try {
      // inputData.loginid = getLoginID();
      const result = await FetchData("midoc_getdoctorbillinginvoice", inputData);
    //   console.log(result, "Doctors View Result")
      if (result.output.data) {
        var data = formapimodalInvoices(result.output.data);

        let count = result?.output?.count;
        let pageno = result?.output?.pageno;
        let pagesize = result?.output?.pagesize;

        var finalOutput = {
          data,
          count,
          pageno, 
          pagesize
        }

        // console.log(finalOutput, "finalOUTPUT")
      return finalOutput;
      }
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };

export {FetchInvoices, FetchFilteredInvoices};