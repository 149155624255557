const CONFIG = {
  "APIBaseUrl":  process.env.REACT_APP_API_BASE_URL || "https://midoc-api-dev.azurewebsites.net/api/",
  "StripePublicKey": process.env.REACT_APP_STRIPE_PUBLIC_KEY || "pk_test_51OTcjkIpkkS0IXSHPzEVIeKXOmoOHiP4W0SPIjcboHjz6u7hNiW35joXqCgwhliRYbtJDxDFeOMJ0EfSuanV9yGs00Q0VlsMNZ",
  "PatientUrl":  process.env.REACT_APP_PATIENT_URL || "https://midoc-patientapp-dev.azurewebsites.net/"
}





export {CONFIG}