import formAPIPreviousvisits from "./previousvisit";


var formattedOutput = null;

const formAPIModelPreviousvisits = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIPreviousvisits(item));


  return formattedOutput
};

export default formAPIModelPreviousvisits