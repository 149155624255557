import { Box, Divider, Flex, Image, ListItem, Radio, RadioGroup, Slider, Text, UnorderedList,
  Stack,
  Checkbox,
  Heading
 } from '@chakra-ui/react'
import React, { useEffect, useState, useRef } from 'react'
import useLoader from '../../components/loader/loaderstates';
import { addVisitFormDetail, getVisitFormDetail } from '../../middleware/services/home/childscreening';
import { formatDate_DD_MM_YYYY, getLoginID, handleAlphaNumericDotAndSpace, validationOfNumberWithOnly1Decimal } from '../../auth';
import { useNavigate } from 'react-router-dom';
import { addAllergies, uploadImageInProfile,
  getmedicalcondition,getVaccineList,addVaccination,
  deleteAllergies, deleteVacinations, editVaccinations,editAllergies} from '../../middleware/services/home/newvisitdetails'
  import { format, setDate } from "date-fns";
  import ModalRight from "../../components/modal-right";
  import DatePicker from "../../components/date-picker";
  import { DropdownInput, PrimaryButton, PrimaryInput, Toaster, SearchBar, PrimaryButtonIcon, PrimaryInputDropdown } from "../../components";
import ReportViewCard from '../../components/report-card';
import DynamicModal from "../../components/dynamic-modal";
import moment from 'moment';

const ChildScreening = ({patientid,appointmentid,patientData}) => {
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const {showSuccessToast, showErrorToast} = Toaster();
  const [refresh , setRefresh] = useState(false);
  const [data , setData] = useState([]);
  const [AllergiesDataToBind , setAllergiesDataToBind] = useState([]);
  const [VaccinationDataToBind , setVaccinationDataToBind] = useState([]);

  const [growthHeight, setGrowthHeight] = useState(null);
  const [growthWeight, setGrowthWeight] = useState(null);
  const [zScoreWAZ, setZScoreWAZ] = useState("");
  const [zScoreHAZ, setZScoreHAZ] = useState("");
  const [zScoreWHZ, setZScoreWHZ] = useState("");
  const [stunting, setStunting] = useState("");
  const [wasting, setWasting] = useState("");
  const [underweight, setUnderweight] = useState("");

  const [growthConcerns, setGrowthConcerns] = useState("");
  const [growthConcernsDesc, setGrowthConcernsDesc] = useState("");
  const [growthMonitored, setGrowthMonitored] = useState("");
  const [weightChange, setWeightChange] = useState("");
  const [eatingDifficulty, setEatingDifficulty] = useState("");
  const [eatingDifficultyDesc, setEatingDifficultyDetailsDesc] = useState("");
  const [malnutritionTreatment, setMalnutritionTreatment] = useState("");
  const [malnutritionTreatmentDesc, setMalnutritionTreatmentDesc] = useState("");
  
  

  
  const [knownAllergies, setKnownAllergies] = useState("");
  const [knownAllergyDesc, setKnownAllergyDesc] = useState("");
  const [majorIllness, setMajorIllness] = useState("");
  const [majorIllnessDetails, setMajorIllnessDetails] = useState("");
  const [vaccinationsUpToDate, setVaccinationsUpToDate] = useState("");
  
  // Add states for remaining fields as needed
  // Example for Nutrition and Family History
  const [balancedDiet, setBalancedDiet] = useState("");
  const [fruitsFrequency, setFruitsFrequency] = useState("");
  const [vegetablesFrequency, setVegetablesFrequency] = useState("");
  const [meatFrequency, setMeatFrequency] = useState("");
  const [dairyFrequency, setDairyFrequency] = useState("");

  // Family Medical History States
  const [familyHistoryDiabetes, setFamilyHistoryDiabetes] = useState("");
  const [familyHistoryHypertension, setFamilyHistoryHypertension] = useState("");
  const [familyHistoryAsthma, setFamilyHistoryAsthma] = useState("");
  const [familyHistoryHeartDisease, setFamilyHistoryHeartDisease] = useState("");

  // School Performance States
  const [schoolAttendance, setSchoolAttendance] = useState("");
  const [schoolPerformance, setSchoolPerformance] = useState("");
  const [schoolPerformanceConcerns, setSchoolPerformanceConcerns] = useState("");

    // Symptom Screening States
    const [coldState, setColdState] = useState("");
    const [feverState, setFeverState] = useState("");
    const [breathState, setBreathState] = useState("");
    const [vomitState, setVomitState] = useState("");
    const [fatigueState, setFatigueState] = useState("");
  

    

    useEffect(() => {
      showLoader();
      var input = {
        "visitid":appointmentid,
        "patientid":patientid
      }
      var getChildScreening = async () => {
        try{
          var result = await getVisitFormDetail(input);
          if(result?.output?.data != null){
            setVaccinationDataToBind(result?.output?.data?.patientvaccination)
            setAllergiesDataToBind(result?.output?.data?.patientallergies)
            }
          if(result?.output?.data?.section_three){
            setData(result?.output?.data?.section_three)
            // setGrowthHeight(result?.output?.data?.section_three.find(item => item.subquestion == "Height (in cm):")?.value);
            // setGrowthWeight(result?.output?.data?.section_three.find(item => item.subquestion ==  "Weight (in kg):")?.value);
           // setGrowthWeight(result?.output?.data?.section_three.find(item => item.subquestion ==  "Z-Scores (WHO Growth Standards):")?.value);
          }
        } catch (error) {
          console.error("Error in home:", error);
        }
        dismissLoader();
      };  
      getChildScreening();
    }, [refresh])

    const getPatientHealth = async (question) => {
      var input = {
        "visitid":appointmentid,
        "patientid":patientid
      }
      showLoader()
      try{
        var result = await getVisitFormDetail(input);
        if(result?.output?.data != null){
          setVaccinationDataToBind(result?.output?.data?.patientvaccination)
          setAllergiesDataToBind(result?.output?.data?.patientallergies)
          }
          if(result?.output?.data?.patientvaccination == null && question.includes("vaccine")){
            setVaccinationsUpToDate("No");
            saveToLocalStorage("vaccinationsUpToDate", "No");
          }
          if(result?.output?.data?.patientallergies == null  && question.includes("Allergy")){
            setKnownAllergies("No");
            saveToLocalStorage("knownAllergies", "No");
          }
      } catch (error) {
        console.error("Error in home:", error);
      }
      dismissLoader();
    };  
    
      let inputForAdd = {
      "patientid": patientid,
      "visitid": appointmentid,

      "visitformdata": [

        //Growth and Development Start
        {
          "sectionid": 3,
          "sectionname": "Child Health Screening",
          "subsectionid": 3.1,
          "subsectionname": "Growth and Development",
          "question": "According to the WHO Growth Standards",
          "subquestion": "Height (in cm)",
          "nestedquestion": null,
          "value": growthHeight ? growthHeight: null,
          "description": null,
          "unit":"cms" 
        },
        {
          "sectionid": 3,
          "sectionname": "Child Health Screening",
          "subsectionid": 3.1,
          "subsectionname": "Growth and Development",
          "question": "According to the WHO Growth Standards",
          "subquestion": "Weight (in kg)",
          "nestedquestion": null,
          "value": growthWeight?growthWeight:null,
          "description": null,
          "unit":"kg"
        },
        {
          "sectionid": 3,
          "sectionname": "Child Health Screening",
          "subsectionid": 3.1,
          "subsectionname": "Growth and Development",
          "question": "According to the WHO Growth Standards",
          "subquestion": "Z-Scores (WHO Growth Standards)",
          "nestedquestion": "Weight-for-Age (WAZ)",
          "value": zScoreWAZ,
          "description": null
        },
        {
          "sectionid": 3,
          "sectionname": "Child Health Screening",
          "subsectionid": 3.1,
          "subsectionname": "Growth and Development",
          "question": "According to the WHO Growth Standards",
          "subquestion": "Z-Scores (WHO Growth Standards)",
          "nestedquestion": "Height-for-Age (HAZ)",
          "value": zScoreHAZ,
          "description": null
        },
        {
          "sectionid": 3,
          "sectionname": "Child Health Screening",
          "subsectionid": 3.1,
          "subsectionname": "Growth and Development",
          "question": "According to the WHO Growth Standards",
          "subquestion": "Z-Scores (WHO Growth Standards)",
          "nestedquestion": "Weight-for-Height (WHZ)",
          "value": zScoreWHZ,
          "description": null
        },
        {
          "sectionid": 3,
          "sectionname": "Child Health Screening",
          "subsectionid": 3.1,
          "subsectionname": "Growth and Development",
          "question": "According to the WHO Growth Standards",
          "subquestion": "Stunting (HAZ < -2)",
          "nestedquestion": null,
          "value": stunting,
          "description": null
        },
        {
          "sectionid": 3,
          "sectionname": "Child Health Screening",
          "subsectionid": 3.1,
          "subsectionname": "Growth and Development",
          "question": "According to the WHO Growth Standards",
          "subquestion": "Wasting (WHZ < -2)",
          "nestedquestion": null,
          "value": wasting,
          "description": null
        },
        {
          "sectionid": 3,
          "sectionname": "Child Health Screening",
          "subsectionid": 3.1,
          "subsectionname": "Growth and Development",
          "question": "According to the WHO Growth Standards",
          "subquestion": "Underweight (WAZ < -2)",
          "nestedquestion": null,
          "value": underweight,
          "description": null
        },//Growth and Development End

        //WHO Start
        {
          "sectionid": 3,
          "sectionname": "Child Health Screening",
          "subsectionid": 3.2,
          "subsectionname": "WHO Growth Questions",
          "question": "Has your child experienced any growth-related concerns (e.g., delayed growth, slow weight gain, or being smaller for age)?",
          "subquestion": null,
          "nestedquestion": null,
          "value": growthConcerns,
          "description": growthConcernsDesc
        },
        {
          "sectionid": 3,
          "sectionname": "Child Health Screening",
          "subsectionid": 3.2,
          "subsectionname": "WHO Growth Questions",
          "question": "Is your child’s growth monitored regularly (e.g., through routine health check-ups)?",
          "subquestion": null,
          "nestedquestion": null,
          "value": growthMonitored,
          "description": null
        },
        {
          "sectionid": 3,
          "sectionname": "Child Health Screening",
          "subsectionid": 3.2,
          "subsectionname": "WHO Growth Questions",
          "question": "Has your child’s weight increased or decreased unexpectedly in the last 6 months?",
          "subquestion": null,
          "nestedquestion": null,
          "value": weightChange,
          "description": null
        },
        {
          "sectionid": 3,
          "sectionname": "Child Health Screening",
          "subsectionid": 3.2,
          "subsectionname": "WHO Growth Questions",
          "question": "Does your child have difficulty in eating or have a reduced appetite?",
          "subquestion": null,
          "nestedquestion": null,
          "value": eatingDifficulty,
          "description": eatingDifficultyDesc
        },
        {
          "sectionid": 3,
          "sectionname": "Child Health Screening",
          "subsectionid": 3.2,
          "subsectionname": "WHO Growth Questions",
          "question": "Has your child been hospitalized or received treatment for any malnutrition-related conditions?",
          "subquestion": null,
          "nestedquestion": null,
          "value": malnutritionTreatment ,
          "description": malnutritionTreatmentDesc
        },//WHO End
        
        //General Health  Start
        {
          "sectionid": 3,
          "sectionname": "Child Health Screening",
          "subsectionid": 3.2,
          "subsectionname": "General Health Information",
          "question": "Does your child have any known allergies?",
          "subquestion": null,
          "nestedquestion": null,
          "value": knownAllergies,
          "description": knownAllergyDesc
        },
        {
          "sectionid": 3,
          "sectionname": "Child Health Screening",
          "subsectionid": 3.2,
          "subsectionname": "General Health Information",
          "question": "Has your child experienced any major illnesses?",
          "subquestion": null,
          "nestedquestion": null,
          "value": majorIllness,
          "description": majorIllnessDetails
        }, {
          "sectionid": 3,
          "sectionname": "Child Health Screening",
          "subsectionid": 3.2,
          "subsectionname": "General Health Information",
          "question": "Is your child up to date with vaccinations? ",
          "subquestion": null,
          "nestedquestion": null,
          "value": vaccinationsUpToDate,
          "description": null
        },
        //General Health  End

        //Symptom Screening Start --
        {
          "sectionid": 3,
          "sectionname": "Child Health Screening",
          "subsectionid": 3.3,
          "subsectionname": "Symptom Screening",
          "question": "Has your child experienced any of the following symptoms recently?",
          "subquestion": "Frequent Colds or Infections",
          "nestedquestion": null,
          "value": coldState,
          "description": null
        },
        {
          "sectionid": 3,
          "sectionname": "Child Health Screening",
          "subsectionid": 3.3,
          "subsectionname": "Symptom Screening",
          "question": "Has your child experienced any of the following symptoms recently?",
          "subquestion": "Fever",
          "nestedquestion": null,
          "value": feverState,
          "description": null
        },
        {
          "sectionid": 3,
          "sectionname": "Child Health Screening",
          "subsectionid": 3.3,
          "subsectionname": "Symptom Screening",
          "question": "Has your child experienced any of the following symptoms recently?",
          "subquestion": "Shortness of Breath",
          "nestedquestion": null,
          "value": breathState,
          "description": null
        },
        {
          "sectionid": 3,
          "sectionname": "Child Health Screening",
          "subsectionid": 3.3,
          "subsectionname": "Symptom Screening",
          "question": "Has your child experienced any of the following symptoms recently?",
          "subquestion": "Vomiting or Diarrhea",
          "nestedquestion": null,
          "value": vomitState,
          "description": null
        },
        {
          "sectionid": 3,
          "sectionname": "Child Health Screening",
          "subsectionid": 3.3,
          "subsectionname": "Symptom Screening",
          "question": "Has your child experienced any of the following symptoms recently?",
          "subquestion": "Fatigue or Lethargy",
          "nestedquestion": null,
          "value": fatigueState,
          "description": null
        },     //Symptom Screening End
        
        //Nutrition Start 
        {
          "sectionid": 3,
          "sectionname": "Child Health Screening",
          "subsectionid": 3.4,
          "subsectionname": "Nutrition and Eating Habits",
          "question": "Does your child have a balanced diet?",
          "subquestion": null,
          "nestedquestion": null,
          "value": balancedDiet,
          "description": null
        }, {
          "sectionid": 3,
          "sectionname": "Child Health Screening",
          "subsectionid": 3.4,
          "subsectionname": "Nutrition and Eating Habits",
          "question": "How often does your child consume the following?",
          "subquestion": "Fruits",
          "nestedquestion": null,
          "value": fruitsFrequency,
          "description": null
        },{
          "sectionid": 3,
          "sectionname": "Child Health Screening",
          "subsectionid": 3.4,
          "subsectionname": "Nutrition and Eating Habits",
          "question": "How often does your child consume the following?",
          "subquestion": "Vegetables",
          "nestedquestion": null,
          "value": vegetablesFrequency,
          "description": null
        },{
          "sectionid": 3,
          "sectionname": "Child Health Screening",
          "subsectionid": 3.4,
          "subsectionname": "Nutrition and Eating Habits",
          "question": "How often does your child consume the following?",
          "subquestion": "Meat or Proteins",
          "nestedquestion": null,
          "value": meatFrequency,
          "description": null
        },{
          "sectionid": 3,
          "sectionname": "Child Health Screening",
          "subsectionid": 3.4,
          "subsectionname": "Nutrition and Eating Habits",
          "question": "How often does your child consume the following?",
          "subquestion": "Dairy Products",
          "nestedquestion": null,
          "value": dairyFrequency,
          "description": null
        },// nutrituon End

      // Family Start 
      {
        "sectionid": 3,
        "sectionname": "Child Health Screening",
        "subsectionid": 3.5,
        "subsectionname": "Family Medical History",
        "question": "Is there any family history of the following conditions?",
        "subquestion": "Diabetes",
        "nestedquestion": null,
        "value": familyHistoryDiabetes,
      
        "description": null
      },{
        "sectionid": 3,
        "sectionname": "Child Health Screening",
        "subsectionid": 3.5,
        "subsectionname": "Family Medical History",
        "question": "Is there any family history of the following conditions?",
        "subquestion": "Hypertension",
        "nestedquestion": null,
        "value": familyHistoryHypertension,
      
        "description": null
      },{
        "sectionid": 3,
        "sectionname": "Child Health Screening",
        "subsectionid": 3.5,
        "subsectionname": "Family Medical History",
        "question": "Is there any family history of the following conditions?",
        "subquestion": "Asthma",
        "nestedquestion": null,
        "value": familyHistoryAsthma,
      
        "description": null
      },{
        "sectionid": 3,
        "sectionname": "Child Health Screening",
        "subsectionid": 3.5,
        "subsectionname": "Family Medical History",
        "question": "Is there any family history of the following conditions?",
        "subquestion": "Heart Disease",
        "nestedquestion": null,
        "value": familyHistoryHeartDisease,
        "description": null
      },// Family End

      // Scool Start 
      {
        "sectionid": 3,
        "sectionname": "Child Health Screening",
        "subsectionid": 3.6,
        "subsectionname": "School Performance",
        "question": "Does your child attend school regularly?",
        "subquestion": null,
        "nestedquestion": null,
        "value": schoolAttendance,
        "description": null
      }, {
        "sectionid": 3,
        "sectionname": "Child Health Screening",
        "subsectionid": 3.6,
        "subsectionname": "School Performance",
        "question": "Are there any concerns regarding your child’s school performance?",
        "subquestion": null,
        "nestedquestion": null,
        "value": schoolPerformance,
        "description": schoolPerformanceConcerns
      }
      
      // Scool End

    ]

      }

      let navigate = useNavigate();
      function createNew (){

        var addChildScreening = async () => {
          showLoader();
          try{
            inputForAdd.visitformdata = inputForAdd.visitformdata.map((item, index) => ({
              ...item,
              questionnumber: index + 1, // Adding question number starting from 1
          }));
            var result = await addVisitFormDetail(inputForAdd);
            if(result.output.message == "Successfully Inserted"){
              //showSuccessToast("Details added Successfully!");
              setRefresh(!refresh)
            setTimeout(() => {
              // const newActiveComponent = "child-screening";
              // onSubmit(newActiveComponent);
              
              let doctorid = getLoginID();
              if(patientData?.type === "startvisit"){
                navigate(`/startvisit?doctorId=${doctorid}&patientId=${patientid}&appointmentId=${appointmentid}`)
              }else{
              navigate("/patientprofile?id="+ patientid+"&page=overview")
              }
            }, 1000);
            }
              console.log(result , "result getVisitFormDetail")
          
          } catch (error) {
            console.error("Error in home:", error);
          }
          dismissLoader();
        };

        addChildScreening();
      }

    const questionnaire = [
        {
          section: "Growth and Development",
          questions: [
            {
              question: "According to the WHO Growth Standards",
              subquestions: [
                { question: "Height (in cm)", unit: "cm", answer: growthHeight },
                { question: "Weight (in kg)", unit: "kg", answer: growthWeight },
                {  question: "Z-Scores (WHO Growth Standards):",     
                  subquestions: [
                   { question: "Weight-for-Age (WAZ)", answer: zScoreWAZ },
                  //  { question: "Weight-for-Age (WAZ)", answer: zScoreHAZ },
                   { question: "Height-for-Age (HAZ)", answer: zScoreHAZ },
                   { question: "Weight-for-Height (WHZ)", answer: zScoreWHZ },
                   { question: "Stunting (HAZ < -2)", answer: stunting, options: ["Normal", "Stunted"],},
                   { question: "Wasting (WHZ < -2)", answer: wasting, options: ["Normal", "Wasted"],},
                   { question: "Underweight (WAZ < -2)", answer: underweight, options: ["Normal", "Underweight"],  },
                 ],
                },
              
              ],
            },
            {
              question: "WHO Growth Questions",
              subquestions: [
                {
                  question: "Has your child experienced any growth-related concerns (e.g., delayed growth, slow weight gain, or being smaller for age)?",
                  answer: growthConcerns,
                  options: ["Yes", "No"],
                  moreinfo:{ question: "If yes, please specify", answer: growthConcernsDesc },
                },
                
                {
                  question: "Is your child’s growth monitored regularly (e.g., through routine health check-ups)?",
                  answer: growthMonitored,
                  options: ["Yes", "No"],
                },
                {
                  question: "Has your child’s weight increased or decreased unexpectedly in the last 6 months?",
                  answer: weightChange,
                  options: ["Increased", "Decreased", "No Change"],
                },
                {
                  question: "Does your child have difficulty in eating or have a reduced appetite?",
                  answer: eatingDifficulty,
                  options: ["Yes", "No"],
                  moreinfo:{ question: "If yes, please specify", answer: eatingDifficultyDesc },
                },
                {
                  question: "Has your child been hospitalized or received treatment for any malnutrition-related conditions?",
                  answer: malnutritionTreatment,
                  options: ["Yes", "No"],
                  moreinfo:{ question: "If yes, please specify", answer: malnutritionTreatmentDesc },
                },
               
              ],
            },
            {
              question: "General Health Information",
              subquestions: [
                {
                  question: "Does your child have any known allergies? ",
                  answer: knownAllergies,
                  options: ["Yes", "No"],
                  moreinfo:{ question: "If yes, please specify", answer: knownAllergyDesc },
                },
                {
                  question: "Has your child experienced any major illnesses? ",
                  answer: majorIllness,
                  options: ["Yes", "No"],
                  moreinfo:{ question: "If yes, please specify", answer: majorIllnessDetails },
                },
                {
                  question: "Is your child up to date with vaccinations?",
                  answer: vaccinationsUpToDate,
                  options: ["Yes", "No"],
                  moreinfo:{ question: "If yes, please specify", answer:null},
                },
              ]
            },
            {
              question: "Has your child experienced any of the following symptoms recently?",
              subquestions: [
                { question: "Frequent Colds or Infections", answer: coldState, options: ["Yes", "No"] },
                { question: "Fever", answer: feverState, options: ["Yes", "No"] },
                { question: "Shortness of Breath", answer: breathState, options: ["Yes", "No"] },
                { question: "Vomiting or Diarrhea", answer: vomitState, options: ["Yes", "No"] },
                { question: "Fatigue or Lethargy", answer: fatigueState, options: ["Yes", "No"] },
              ],
            },
          ],
        },
        {
          section: "Nutrition and Eating Habits",
          questions: [
            {
              question: "Does your child have a balanced diet?",
              answer: balancedDiet,
              options: ["Yes", "No"],
            },
            {
              question: "How often does your child consume the following?",
              subquestions: [
                { question: "Fruits", answer: fruitsFrequency, options: ["Daily", "Sometimes", "Rarely"] },
                { question: "Vegetables", answer: vegetablesFrequency, options: ["Daily", "Sometimes", "Rarely"] },
                { question: "Meat or Protein", answer: meatFrequency, options: ["Daily", "Sometimes", "Rarely"] },
                { question: "Dairy Products", answer: dairyFrequency, options: ["Daily", "Sometimes", "Rarely"] },
              ],
            },
          ],
        },
        {
          section: "Family Medical History",
          questions: [
            {
              question: "Is there any family history of the following conditions?",
              subquestions: [
                { question: "Diabetes", answer: familyHistoryDiabetes, options: ["Yes", "No"] },
                { question: "Hypertension", answer: familyHistoryHypertension, options: ["Yes", "No"] },
                { question: "Asthma", answer: familyHistoryAsthma, options: ["Yes", "No"] },
                { question: "Heart Disease", answer: familyHistoryHeartDisease, options: ["Yes", "No"] },
              ],
            },
          ],
        },
        {
          section: "School Performance",
          questions: [
            {
              question: "Does your child attend school regularly?",
              answer: schoolAttendance,
              options: ["Yes", "No"],
            },
            {
              question: "Are there any concerns regarding your child’s school performance?",
              answer: schoolPerformance,
              options: ["Yes", "No"],
              moreinfo:{ question: "If yes, please specify", answer: schoolPerformanceConcerns },
            },
          ],
        },
      ];
      function handleHeightWeightBasic(unit, e) {
        let value = validationOfNumberWithOnly1Decimal(e.target.value , 6)
        if (unit.includes("cm")) {
          setGrowthHeight(value);
          saveToLocalStorage("growthHeight", value);
        } else if (unit.includes("kg")) {
          setGrowthWeight(value);
          saveToLocalStorage("growthWeight", value);
        }
      }
      
      function handleHeightWeightZScores(unit, e) {
        let value = validationOfNumberWithOnly1Decimal(e.target.value , 6)
        console.log(unit, "unit");
        if (unit.includes("WAZ")) {
          setZScoreWAZ(value);
          saveToLocalStorage("zScoreWAZ", value);
        } else if (unit.includes("HAZ")) {
          setZScoreHAZ(value);
          saveToLocalStorage("zScoreHAZ", value);
        } else if (unit.includes("WHZ")) {
          setZScoreWHZ(value);
          saveToLocalStorage("zScoreWHZ", value);
        }
      }
      
      function handleMoreInfo(unit, e) {
        const value = e.target.value;
        if (unit.includes("Has your child experienced any growth-related concerns")) {
          setGrowthConcernsDesc(value);
          saveToLocalStorage("growthConcernsDesc", value);
        } else if (unit.includes("Does your child have difficulty in eating")) {
          setEatingDifficultyDetailsDesc(value);
          saveToLocalStorage("eatingDifficultyDetailsDesc", value);
        } else if (unit.includes("Has your child been hospitalized or received treatment")) {
          setMalnutritionTreatmentDesc(value);
          saveToLocalStorage("malnutritionTreatmentDesc", value);
        } else if (unit.includes("Are there any concerns regarding your child’s school performance")) {
          setSchoolPerformanceConcerns(value);
          saveToLocalStorage("schoolPerformanceConcerns", value);
        }

        else if (unit.includes("Does your child have any known allergies")) {
          setKnownAllergyDesc(value);
          saveToLocalStorage("knownAllergiesDesc", value);
        } 
        else if (unit.includes("Has your child experienced any major illnesses")) {
          setMajorIllnessDetails(value);
          saveToLocalStorage("majorIllnessDesc", value);
        } 
      }
      
      function handleChangeRadio(value, question) {
        console.log(value, "value");
        console.log(question, "question");
      
        if (question.includes("Stunting")) {
          setStunting(value);
          saveToLocalStorage("stunting", value);
        } else if (question.includes("Wasting")) {
          setWasting(value);
          saveToLocalStorage("wasting", value);
        } else if (question.includes("Underweight")) {
          setUnderweight(value);
          saveToLocalStorage("underweight", value);
        } else if (question.includes("growth-related concerns")) {
          setGrowthConcerns(value);
          saveToLocalStorage("growthConcerns", value);
        } else if (question.includes("Is your child’s growth monitored regularly")) {
          setGrowthMonitored(value);
          saveToLocalStorage("growthMonitored", value);
        } else if (question.includes("Has your child’s weight increased or decreased")) {
          setWeightChange(value);
          saveToLocalStorage("weightChange", value);
        } else if (question.includes("Does your child have difficulty in eating")) {
          setEatingDifficulty(value);
          saveToLocalStorage("eatingDifficulty", value);
        } else if (question.includes("malnutrition-related conditions")) {
          setMalnutritionTreatment(value);
          saveToLocalStorage("malnutritionTreatment", value);
        }
        
        else if (question.includes("Does your child have any known allergies")) {
          setKnownAllergies(value);
          saveToLocalStorage("knownAllergies", value);
          if(value === "Yes"){
            openAllergiesModal();
          }
          
        } 
        else if (question.includes("Has your child experienced any major illnesses")) {
          setMajorIllness(value);
          saveToLocalStorage("majorIllness", value);
        } 
        
        
        else if (question.includes("Is your child up to date with vaccinations")) {
          setVaccinationsUpToDate(value);
          saveToLocalStorage("vaccinationsUpToDate", value);
          if(value === "Yes"){
            openVaccinationsModal();
          }
          
        }
        
        else if (question.includes("Frequent Colds or Infections")) {
          setColdState(value);
          saveToLocalStorage("coldState", value);
        } else if (question.includes("Fever")) {
          setFeverState(value);
          saveToLocalStorage("feverState", value);
        } else if (question.includes("Shortness of Breath")) {
          setBreathState(value);
          saveToLocalStorage("breathState", value);
        } else if (question.includes("Vomiting or Diarrhea")) {
          setVomitState(value);
          saveToLocalStorage("vomitState", value);
        } else if (question.includes("Fatigue or Lethargy")) {
          setFatigueState(value);
          saveToLocalStorage("fatigueState", value);
        } else if (question.includes("Does your child have a balanced diet?")) {
          setBalancedDiet(value);
          saveToLocalStorage("balancedDiet", value);
        } else if (question.includes("Fruits")) {
          setFruitsFrequency(value);
          saveToLocalStorage("fruitsFrequency", value);
        } else if (question.includes("Vegetables")) {
          setVegetablesFrequency(value);
          saveToLocalStorage("vegetablesFrequency", value);
        } else if (question.includes("Meat or Protein")) {
          setMeatFrequency(value);
          saveToLocalStorage("meatFrequency", value);
        } else if (question.includes("Dairy Products")) {
          setDairyFrequency(value);
          saveToLocalStorage("dairyFrequency", value);
        } else if (question.includes("Diabetes")) {
          setFamilyHistoryDiabetes(value);
          saveToLocalStorage("familyHistoryDiabetes", value);
        } else if (question.includes("Hypertension")) {
          setFamilyHistoryHypertension(value);
          saveToLocalStorage("familyHistoryHypertension", value);
        } else if (question.includes("Asthma")) {
          setFamilyHistoryAsthma(value);
          saveToLocalStorage("familyHistoryAsthma", value);
        } else if (question.includes("Heart Disease")) {
          setFamilyHistoryHeartDisease(value);
          saveToLocalStorage("familyHistoryHeartDisease", value);
        } else if (question.includes("Does your child attend school regularly")) {
          setSchoolAttendance(value);
          saveToLocalStorage("schoolAttendance", value);
        } else if (question.includes("Are there any concerns regarding your child’s school performance")) {
          setSchoolPerformance(value);
          saveToLocalStorage("schoolPerformance", value);
        }
      }
      
      // Helper function to save data to localStorage
      function saveToLocalStorage(key, value) {
        const storedData = JSON.parse(localStorage.getItem("formData")) || {};
        storedData[key] = value;
        localStorage.setItem("formData", JSON.stringify(storedData));
      }
      
      useEffect(() => {
        const storedData = JSON.parse(localStorage.getItem("formData"));
      
        if (storedData) {

          setKnownAllergies(storedData.knownAllergies || "");
          setMajorIllness(storedData.majorIllness || "");

          setKnownAllergyDesc(storedData.knownAllergiesDesc || "");
          setMajorIllnessDetails(storedData.majorIllnessDesc || "");

          setGrowthHeight(storedData.growthHeight || "");
          setGrowthWeight(storedData.growthWeight || "");
          setZScoreWAZ(storedData.zScoreWAZ || "");
          setZScoreHAZ(storedData.zScoreHAZ || "");
          setZScoreWHZ(storedData.zScoreWHZ || "");
          setGrowthConcernsDesc(storedData.growthConcernsDesc || "");
          setEatingDifficultyDetailsDesc(storedData.eatingDifficultyDetailsDesc || "");
          setMalnutritionTreatmentDesc(storedData.malnutritionTreatmentDesc || "");
          setSchoolPerformanceConcerns(storedData.schoolPerformanceConcerns || "");
          setStunting(storedData.stunting || "");
          setWasting(storedData.wasting || "");
          setUnderweight(storedData.underweight || "");
          setGrowthConcerns(storedData.growthConcerns || "");
          setGrowthMonitored(storedData.growthMonitored || "");
          setWeightChange(storedData.weightChange || "");
          setEatingDifficulty(storedData.eatingDifficulty || "");
          setMalnutritionTreatment(storedData.malnutritionTreatment || "");
          setVaccinationsUpToDate(storedData.vaccinationsUpToDate || "");
          setColdState(storedData.coldState || "");
          setFeverState(storedData.feverState || "");
          setBreathState(storedData.breathState || "");
          setVomitState(storedData.vomitState || "");
          setFatigueState(storedData.fatigueState || "");
          setBalancedDiet(storedData.balancedDiet || "");
          setFruitsFrequency(storedData.fruitsFrequency || "");
          setVegetablesFrequency(storedData.vegetablesFrequency || "");
          setMeatFrequency(storedData.meatFrequency || "");
          setDairyFrequency(storedData.dairyFrequency || "");
          setFamilyHistoryDiabetes(storedData.familyHistoryDiabetes || "");
          setFamilyHistoryHypertension(storedData.familyHistoryHypertension || "");
          setFamilyHistoryAsthma(storedData.familyHistoryAsthma || "");
          setFamilyHistoryHeartDisease(storedData.familyHistoryHeartDisease || "");
          setSchoolAttendance(storedData.schoolAttendance || "");
          setSchoolPerformance(storedData.schoolPerformance || "");
        }
      }, []);
      //Allergies
      const addCondition = React.useRef();
      const addCondition2 = React.useRef();
      const addCondition3 = React.useRef();
      var [conditionsList, setConditionsList] = useState(null);
      var [reactionList, setReactionList] = useState(null);
      const [selectedConditions, setSelectedConditions] = useState([]);
      const [selectedReactions, setSelectedReactions] = useState([]);
      const [uploadedDocuments, setUploadedDocuments] = useState([]);
      const [selectedDates, setSelectedDates] = useState({});
      const [allergiesContinueBtnDisabled, setAllergiesContinueBtnDisabled] = useState(true)
      const [reactionsContinueBtnDisabled, setReactionsContinueBtnDisabled] = useState(true)
      const [reactionsContinueBtnDisabledEdit, setReactionsContinueBtnDisabledEdit] = useState(true)
      const [reactionsContinueBtnDisabled2, setReactionsContinueBtnDisabled2] = useState(true)
      const [patientallergyid, setPatientallergyid] = useState("");
      const [allergytype, setAllergytype] = useState("");
      const [allergyID, setAllergieID] = useState("");
      const [identifiedData, setIdentifiedData] = useState("")
      const [documentURLs, setDocumentURLs] = useState([]);
      const [allergytype_cd , setAllergytype_cd ] = useState("")
      const [patientAllergyId , setPatientAllergyId ] = useState("")
      const [typedCondition , setTypedCondition] = useState("")
      const [typedReaction , setTypedReaction] = useState("")
      const fileInputRef = useRef(null);
      const [reasonsArray, setReasonsArray] = useState([]);
      const [searchValue, setSearchValue] = useState("");
      const floatLabel = { color: "#384052", fontSize: "0.775rem", position: "relative", top: "-4px", left: "-4px", opacity: "0.5", width: "100%" };


            //Allergies
            const openAllergiesModal = () => {
              fetchAllergiesList();
               setReasonsArray([])
               setSearchValue("");
               setSelectedConditions([]);
               setSelectedReactions([]);
               setUploadedDocuments([])
               setDocumentURLs([])
               setTypedCondition("")
               setTypedReaction("")
               addCondition.current.openRight();
             };
             useEffect(() => {
         
              if (selectedConditions.length === 0) {
                setAllergiesContinueBtnDisabled(true);
              } else {
                setAllergiesContinueBtnDisabled(false);
              }
            }, [selectedConditions]);
            useEffect(() => {
              let filteredReactions = reasonsArray.filter(item => item.allergy_id == allergyID)
              if(filteredReactions.length > 0 ){
                setReactionsContinueBtnDisabled2(false)
              }else{
                setReactionsContinueBtnDisabled2(true)
              }
             }, [reasonsArray]);
             const getUniqueObjects = (arr, key) => {
              return arr.reduce((acc, current) => {
                if (!acc.some(item => item[key] === current[key])) {
                  acc.push(current);
                }
                return acc;
              }, []);
            };
             useEffect(() => {
              const uniqueObjects = getUniqueObjects(reasonsArray, 'allergy_id');
              if(reasonsArray.length > 0 && uniqueObjects.length > 0){
                if(selectedConditions.length == uniqueObjects.length ){
                  setReactionsContinueBtnDisabled(false);
                }else {
                  setReactionsContinueBtnDisabled(true);
                }
              }
            }, [reasonsArray]);
            const handleCheckboxChange = (condition) => {
              const isSelected = selectedConditions.some(
                (item) => item.condition_cd === condition.condition_cd
              );
          
              if (isSelected) {
                setSelectedConditions((prevConditions) =>
                  prevConditions.filter(
                    (item) => item.condition_cd !== condition.condition_cd
                  )
                );
              } else {
                setSelectedConditions([...selectedConditions, condition]);
              }
            };
            const handleReactionCheckboxChange = (condition, allergyid) => {
              const isSelected = reasonsArray.some(
                (item) =>
                  item.allergy_id === allergyid &&
                  item.condition_cd === condition.condition_cd
              );
          
              if (isSelected) {
                // If already selected, remove the item from reasonsArray
                setReasonsArray((prevConditions) =>
                  prevConditions.filter(
                    (item) =>
                      item.allergy_id !== allergyid ||
                      item.condition_cd !== condition.condition_cd
                  )
                );
              } else {
                // If not selected, add the item to reasonsArray
                setReasonsArray([...reasonsArray, { ...condition, allergy_id: allergyid }]);
              }
            };
            const handleCondition = (e) => {
              const inputText = e.target.value;
              const maxLength = 80;
            
              if (inputText.length <= maxLength) {
                setTypedCondition(inputText);
              }
              if(e.target.value){
                setAllergiesContinueBtnDisabled(false)
              }else{
                setAllergiesContinueBtnDisabled(true)
              }
            
            }
            const handleReaction = (e) => {
              const inputText = e.target.value;
              const maxLength = 80;
              
              if (inputText.length <= maxLength) {
                setTypedReaction(inputText);
              }
              if(e.target.value){
                setReactionsContinueBtnDisabled2(false)
                setReactionsContinueBtnDisabledEdit(false)
              }else{
                setReactionsContinueBtnDisabled2(true)
                setReactionsContinueBtnDisabledEdit(true)
              }
            
            }
            const AddReactionsInInput = (allergyid) => {
              // enter manually 
            
              if (typedReaction !== "") {
               const newSelectedCondition = {
                 condition:typedReaction,
                 allergy_id:allergyid,
                 condition_cd:null,
                 conditiontype:"other"
               };
           
               setReasonsArray((prevConditions) => {
                 // Filter out any existing condition with the same type
                 // const filteredConditions = prevConditions.filter(
                 //   (item) => item?.conditiontype !== "other"
                 // );
                 // Add the new condition
                 return [...prevConditions, newSelectedCondition];
               });
             }
         
             //--------------------------------
         
             addCondition3.current.closeRight();
            // const selectedReactionsArray = Object.values(selectedReactions);
            // handleReactionChange(allergyid, selectedReactionsArray);
           };
           const handleDateChange = (conditionCd, date) => {
      
              const formattedDate = format(date, "yyyy-MM-dd");
              setSelectedDates((prevDates) => ({
                ...prevDates,
                [conditionCd]: formattedDate,
              }));
            };
            const showReactionList = (allergyid) => {
              let filteredReactions = reasonsArray.filter(item => item.allergy_id == allergyid)
            if(filteredReactions.length > 0 ){
               setReactionsContinueBtnDisabled2(false)
             }else{
               setReactionsContinueBtnDisabled2(true)
             }
             // if (reactionList == null) {
                fetchReactionsList();
             // }
             setTypedCondition("")
             setTypedReaction("")
              setSearchValue("")
              setAllergieID(allergyid);
              addCondition3.current.openRight();
            };
            const removeReaction = (allergyId, reactionId) => {
              setReasonsArray((prev) =>
                prev.filter(
                  (tag) => !(tag.allergy_id === allergyId && tag.condition_cd === reactionId)
                )
              );
            };
            const handleDelete = (index) => {
              const updatedUrls = [...uploadedDocuments];
              updatedUrls.splice(index, 1);
              setUploadedDocuments(updatedUrls);
            };
            const addSurgeriesUploadDocument = (condition_cd) => {
      
       
      
              if(condition_cd){
                
                fileInputRef.current.setAttribute('data-surgery-cd', condition_cd);
                fileInputRef.current.click();
                
              }else{
                
                fileInputRef.current.setAttribute('data-surgery-cd', null);
                fileInputRef.current.setAttribute('data-surgery',  "other");
                fileInputRef.current.click();
              }
          
            }
            const handleAllergiesUpload = async (e) => {
              e.preventDefault();
          
              let condition_cd = e.target.getAttribute('data-surgery-cd');
              if(condition_cd === "null"){
                condition_cd = null
               }
              showLoader();
          
              const file2 = e.target.files[0];
              const reader = new FileReader();
              reader.readAsDataURL(file2);
              const result = await uploadImageInProfile(file2, file2.name);
              const filename = result.name;
              setUploadedDocuments((prevDocuments) => [
                ...prevDocuments,
                { condition_cd, filename },
              ]);
              showSuccessToast("Document Uploaded Successfully.");
              e.target.value = '';
              dismissLoader();
          
            };
            const AddMedicalConditionsCall = () => {
              const addConditions = async () => {
                showLoader();
                try {
                  const result = await addAllergies(inputForAddAllergy);
                  if (result.output.result == "success") {
                    showSuccessToast("Successfully Added")
                    getPatientHealth();
                    addCondition2.current.closeRight();
                    addCondition.current.closeRight();
                    setAllergieID("")
                  } else {
                    showErrorToast("Something went wrong!")
                  }
                } catch (error) {
                  console.error("Error in allergies:", error);
                }
                dismissLoader();
              };
          
              const isDateMissing = selectedConditions.some(
                (condition) => !selectedDates[condition.condition_cd]
              );
          
              const isReactionMissing = selectedConditions.some(
                (condition) =>
                  !reasonsArray.some((reason) => reason.allergy_id === condition.condition_cd)
              );
              if (isDateMissing) {
                showErrorToast("Please Select the Date");
                return false;
              }else if (isReactionMissing){
                showErrorToast("Please Select the reaction");
                return false;
              } else {
                addConditions();
          
              }
            };
            var inputForAddAllergy = {
              patientid: patientid,
              visitid: appointmentid,
              allergy: selectedConditions.map((condition, index) => ({
                condition: condition.condition,
                condition_cd: condition.condition_cd,
                allergytype_cd: condition.condition_cd,
                allergyidentifieddate: selectedDates[condition.condition_cd] || "",
                ...(condition.conditiontype && { conditiontype: condition.conditiontype }),
                reactions:
                  reasonsArray
                    .filter((reaction) => reaction.allergy_id === condition.condition_cd)
                    .map((filteredReaction) => ({
                      reaction_cd: filteredReaction.condition_cd,
                      reaction: filteredReaction.condition,
                    })) || [],
                documents: uploadedDocuments
                  .filter((doc) => doc.condition_cd == condition.condition_cd)
                  .map(({ condition_cd, filename }) => ({
                    condition_cd,
                    filename,
                  })),
              })),
            };
            const AddMedicalConditions = () => {
              if (selectedConditions.length === 0 && typedCondition == "") {
                showErrorToast("Please Select a Allergy to Continue");
              } else {
                  // enter manually 
                  if (typedCondition !== "") {
                    const newSelectedCondition = {
                      condition:typedCondition,
                      condition_cd:null,
                      conditiontype:"other"
                    };
                
                    setSelectedConditions((prevConditions) => {
                      // Filter out any existing condition with the same type
                      const filteredConditions = prevConditions.filter(
                        (item) => item?.conditiontype !== "other"
                      );
                      // Add the new condition
                      return [...filteredConditions, newSelectedCondition];
                    });
                  }
            
                  //--------------------------------
                setSelectedDates({})
                addCondition2.current.openRight();
              }
            };
            const fetchReactionsList = async () => {
              try {
                const result = await getmedicalcondition({
                  type: "reaction",
                  patientid: patientid
                });
                if (result.output.result == "success") {
                  setReactionList(result.output.data);
                } else {
                  showErrorToast("Something went wrong!")
                }
              } catch (error) {
                console.error("Error", error);
              }
            };
            const fetchAllergiesList = async () => {
              try {
                const result = await getmedicalcondition({
                  type: "allergies",
                  patientid: patientid
                });
                if (result.output.result == "success") {
                  setConditionsList(result.output.data);
                } else {
                  showErrorToast("Something went wrong!")
                }
              } catch (error) {
                console.error("Error", error);
              }
            };
            const dynamicModalAllergy = React.useRef();
            const openDeleteModal = (id, allergy) => {
              setPatientallergyid(id);
              setAllergytype(allergy);
          
              dynamicModalAllergy.current.openModal();
            };
           
            const deleteAllergy = async () => {
              var inputForDelete = {
                patientallergyid,
                "patientid":patientid
              };
                showLoader();
                try {
                  const result = await deleteAllergies(inputForDelete);
                  if (result.output.result == "success") {
                    //showErrorToast(allergytype + " has been Deleted");
                    getPatientHealth("Allergy");
                    dynamicModalAllergy.current.closeModal();
                  } else {
                    showErrorToast("Something went Wrong!")
                  }
                } catch (error) {
                  console.error("Error in DashboardIndex:", error);
                }
            };
            //Edit Allergy
            const editConditionModal = React.useRef();
            const reactionListModal = React.useRef();
            const [selectedConditionsInEdit, setSelectedConditionsInEdit] = useState([]);
            const [dateInEdit , setDateInEdit] = useState("")
            const openEditAllergyModal = (  patientallergyid, allergytype, allergyidentifieddate,
              reaction,
              allergytype_cd)=>{
                setSelectedConditionsInEdit([])
                setAllergytype_cd(allergytype_cd)
                setPatientAllergyId(patientallergyid)
              var formatedDateInEdit =  moment(allergyidentifieddate).format("DD-MM-YYYY")
              setDateInEdit(formatedDateInEdit)
              setReasonsArray([])
              
          
              reaction.forEach((item) => {
                // Assuming each drug has a "name" property
                const newReason = {
                  reaction: item.reaction,
                  reaction_cd: item.reaction_cd,
                };
                setReasonsArray((prevArray) => [...prevArray, newReason]);
              });
                setSelectedConditionsInEdit(allergytype)
          
              editConditionModal.current.openRight();
            }
            const handleDateChangeInEdit = ( date) => {
              setDateInEdit(date)
            };
            const showReactionListInEdit = () => {
              // if (reactionList == null) {
                 fetchReactionsList();
             //  }
               setSearchValue("")
               reactionListModal.current.openRight();
             };
             const handleReactionCheckboxChangeInEdit = (reaction) => {
              // Check if the reaction object has condition and condition_cd attributes
              if (reaction.hasOwnProperty('condition') && reaction.hasOwnProperty('condition_cd')) {
                // Convert to reaction and reaction_cd attributes
                reaction = {
                  reaction: reaction.condition,
                  reaction_cd: reaction.condition_cd
                };
              }
            
              const isSelected = reasonsArray.some((item) => item.reaction === reaction.reaction);
            
              if (isSelected) {
                // If already selected, remove the item from reasonsArray
                setReasonsArray((prevConditions) =>
                  prevConditions.filter((item) => item.reaction !== reaction.reaction)
                );
              } else {
                // If not selected, add the item to reasonsArray
                setReasonsArray([...reasonsArray, reaction]);
              }
            };
            
            const editReactions = ()=>{
             
                // enter manually 
                if (typedReaction !== "") {
                  const newSelectedCondition = {
                    reaction:typedReaction,
                    reaction_cd:null,
                    conditiontype:"other"
                  };
              
                  setReasonsArray((prevConditions) => {
                    // Filter out any existing condition with the same type
                    const filteredConditions = prevConditions.filter(
                      (item) => item?.conditiontype !== "other"
                    );
                    // Add the new condition
                    return [...filteredConditions, newSelectedCondition];
                  });
                }
            
                //--------------------------------
            
              reactionListModal.current.closeRight()
            }
            const EditMedicalConditionsCall = () => {
    
              let formatedproceduredate = moment(dateInEdit, 'DD-MM-YYYY').format('YYYY-MM-DD');
              var inputForEdit = {
                "patientid":patientid,
                allergytype_cd,
                "allergyidentifieddate": formatedproceduredate,
                "patientallergyid": patientAllergyId,
                  reactions: reasonsArray,
            
                  documents: uploadedDocuments
                    .map(({ condition_cd, filename }) => ({
                      condition_cd,
                      filename,
                    })),
              };
          
              const editConditions = async () => {
                showLoader();
                try {
                 const result = await editAllergies(inputForEdit);
                  if (result.output.result == "success") {
                    //showSuccessToast("Successfully Updated")
                    getPatientHealth();
                    reactionListModal.current.closeRight();
                    editConditionModal.current.closeRight();
                    setAllergieID("")
                  } else {
                    showErrorToast("Something went wrong!")
                  }
                } catch (error) {
                  console.error("Error in allergies:", error);
                }
                dismissLoader();
              };
          
                editConditions();
          
            };
            const removeTag = (index) => {
              const updatedReasonsArray = [...reasonsArray];
              updatedReasonsArray.splice(index, 1);
              setReasonsArray(updatedReasonsArray);
            };
            useEffect(() => {
              if (reasonsArray.length === 0) {
                setReactionsContinueBtnDisabledEdit(true);
              } else {
                setReactionsContinueBtnDisabledEdit(false);
              }
            }, [reasonsArray]);
            //Vaccinations 
            
            const addvaccination = React.useRef();
            const addvaccinedates = React.useRef();
            var [vaccineList, setVaccineList] = useState(null);
            const [selectedVaccines, setSelectedVaccines] = useState([]);
            const [selectedvaccineDates, setSelectedVaccineDates] = useState({});
            const [selectedDueDates, setSelectedDueDates] = useState({});
            const [condition, setCondition] = useState('')
            const [vaccineid, setVaccineid] = useState('')
            const [id, setId] = useState('')
            const [vaccineName, setVaccineName] = useState("")
            const [adminDate, setAdminDate] = useState("")
            const [dueDate, setDueDate] = useState("")
            const scrollDiv = useRef(null);
            const [typedvaccineCondition , setTypedVaccineCondition] = useState("")
            

            const handleVaccineCheckboxChange = (condition) => {
              const isSelected = selectedVaccines.some(
                (item) => item.condition_cd === condition.condition_cd
              );
          
              if (isSelected) {
                setSelectedVaccines((prevConditions) =>
                  prevConditions.filter(
                    (item) => item.condition_cd !== condition.condition_cd
                  )
                );
              } else {
                setSelectedVaccines([...selectedVaccines, condition]);
              }
            };
            const [minDateState , setMinDateState] = useState('')
            const [maxDateState , setMaxDateState] = useState('')
            const handleVaccineDateChange = (conditionCd, date) => {
              const formattedDate = format(date, "yyyy-MM-dd");
              setMinDateState(date)
              setSelectedVaccineDates((prevDates) => ({
                ...prevDates,
                [conditionCd]: formattedDate,
              }));
            };
            const handleDueDateChange = (conditionCd, date) => {
              setMaxDateState(date)
              const formattedDate = format(date, "yyyy-MM-dd");
              setSelectedDueDates((prevDates) => ({
                ...prevDates,
                [conditionCd]: formattedDate,
              }));
            };
          
            var inputForAddVaccine = {
              "patientid": patientid,
              "visitid": appointmentid,
              "vaccination": selectedVaccines.map((condition, index) => ({
                "vaccine_cd": condition.condition_cd,
                "vaccinename": condition.condition,
                "admindate": selectedvaccineDates[condition.condition_cd] || null,
                "duedate": selectedDueDates[condition.condition_cd] || null,
          
              })),
            }
          
            const AddVaccine = () => {
              if (selectedVaccines.length === 0 && typedvaccineCondition == "") {
                showErrorToast("Please Select a Vaccine to Continue")
              } else {
          
                // enter manually 
                if (typedvaccineCondition !== "") {
                  const newSelectedCondition = {
                    condition:typedvaccineCondition,
                    condition_cd:null,
                    conditiontype:"other"
                  };
              
                  setSelectedVaccines((prevConditions) => {
                    // Filter out any existing condition with the same type
                    const filteredConditions = prevConditions.filter(
                      (item) => item?.conditiontype !== "other"
                    );
                    // Add the new condition
                    return [...filteredConditions, newSelectedCondition];
                  });
                }
          
                //--------------------------------
                setSelectedDates({})
                setSelectedDueDates({})
                addvaccinedates.current.openRight()
              }
          
            }
          
          
            const AddVaccineCall = () => {
              const addConditions = async () => {
                showLoader();
                try {
                  const result = await addVaccination(inputForAddVaccine);
                  if (result.output.result == "success") {
                    showSuccessToast("Successfully Added")
                    if (scrollDiv.current) {
                     scrollDiv.current.scrollTop = 0;
                   }
                   getPatientHealth()
          
                    addvaccination.current.closeRight();
                    addvaccinedates.current.closeRight();
                  } else {
                    showErrorToast("Something went wrong!")
                  }
                } catch (error) {
                  console.error("Error in DashboardIndex:", error);
                }
                dismissLoader();
              };
          
              const isDateMissing = selectedVaccines.some(
                // (condition) => !selectedDates[condition.condition_cd] || !selectedDueDates[condition.condition_cd]
                (condition) => !selectedvaccineDates[condition.condition_cd] 
              );
          
              if (isDateMissing) {
                showErrorToast("Please select admin date");
                return false;
              } else {
                addConditions();
              }
          
            }
            
            const handleVaccineCondition = (e) => {
              const inputText = e.target.value;
              const maxLength = 80;
            
              if (inputText.length <= maxLength) {
                setTypedVaccineCondition(inputText);
              }
            
            }
            const fetchMedicalConditionList = async () => {
              try {
                const result = await getVaccineList({
                  type: "vaccine",
                  "patientid":patientid,
                });
                if (result.output.result == "success") {
                  setVaccineList(result.output.data);
                } else {
                  showErrorToast("Something went wrong!")
                }
              } catch (error) {
                console.error("Error", error);
              }
            };
            const openVaccinationsModal = () => {
              // if (conditionsList == null) {
                 fetchMedicalConditionList();
              // }
               setSearchValue('')
               setSelectedVaccines([])
               setSelectedDueDates({})
               setTypedVaccineCondition("")
               addvaccination.current.openRight()
             }
            const deleteVaccine = async () => {
              var inputForDelete = {
                vaccineid,
                "patientid":patientid
              }
                showLoader();
                try {
                  const result = await deleteVacinations(inputForDelete);
                  if (result.output.result == "success") {
                    //showErrorToast(condition + " has been Deleted")
                    getPatientHealth("vaccine");
                    dynamicModalVaccine.current.closeModal();
                  } else {
                    showErrorToast("Something went wrong!")
                  }
                  dismissLoader();
                } catch (error) {
                  console.error("Error in vaciations:", error);
                }
            }
            const dynamicModalVaccine = React.useRef();
            const openDeleteVaccineModal = (id, condition) => {
              setVaccineid(id)
              setCondition(condition)
              dynamicModalVaccine.current.openModal();
            }
            //Edit Vaccine
            const editvaccination = React.useRef();
            const openEditModal = (id, vaccinename, admindate, duedate) => {
              var formatedAdminDate = moment(admindate).format("DD-MM-yyyy");
              if(duedate){
                var formatedDueDate = moment(duedate).format("DD-MM-yyyy");
              setDueDate(formatedDueDate);
              }
              setId(id);
              setVaccineName(vaccinename);
              setAdminDate(formatedAdminDate);
          
              editvaccination.current.openRight()
          
            }
            const EditVaccineDates = async () => {
                let formatedAdminDate = moment(adminDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
                let formatedDueDate = moment(dueDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
                //  alert(adminDate)
          
                var inputForEdit = {
                  "patientid": patientid,
                  "vaccine": [
                    {
                      "vaccineid": id,
                      "admindate": formatedAdminDate,
                      "duedate": formatedDueDate,
                    },
                  ]
                }
                try {
                  showLoader();
                  const result = await editVaccinations(inputForEdit);
                  if (result.output.result == "success") {
                    //showSuccessToast("Updated Successfully")
                    getPatientHealth();
                    editvaccination.current.closeRight()
                  } else {
                    showErrorToast("Something went wrong!")
                  }
                } catch (error) {
                  console.error("Error in editVaccineDatesFunction:", error);
                }
          
            }
            const handleEditAdminDate = (selectedDate) => {
              //  const formattedDate = format(selectedDate, "yyyy-MM-dd");
              setAdminDate(selectedDate)
            }
            const handleEditDueDate = (selectedDate) => {
          
              //  const formattedDate = format(selectedDate, "yyyy-MM-dd");
              setDueDate(selectedDate)
            }
      // Mouse dragging logic
      const scrollRef = useRef(null);
      const isDragging = useRef(false);
      const startX = useRef(0);
      const scrollLeft = useRef(0);

      const handleMouseDown = (event) => {
        isDragging.current = true;
        startX.current = event.pageX - scrollRef.current.offsetLeft;
        scrollLeft.current = scrollRef.current.scrollLeft;
      };

      const handleMouseLeave = () => {
        isDragging.current = false;
      };

      const handleMouseUp = () => {
        isDragging.current = false;
      };

      const handleMouseMove = (event) => {
        if (!isDragging.current) return;
        event.preventDefault();
        const x = event.pageX - scrollRef.current.offsetLeft;
        const walk = (x - startX.current) * 1.5; // The multiplier increases the drag speed
        scrollRef.current.scrollLeft = scrollLeft.current - walk;
      };
  return (
    <>
        <Flex gap='1rem' flexDir='column'>
            {/* Growth and Development start */}

            {questionnaire?.[0] && (
                <>
                
                <Text fontSize='1rem' fontWeight='600' mb='0.5rem'> {questionnaire[0].section}</Text>

             <Box w='100%'>
                <>
                <Text fontSize='0.875rem' fontWeight='500' color='#7c7c7c' mb='0.5rem'>{questionnaire[0].questions[0].question                }</Text>
                <Flex flexDir="column" gap="0.75rem">
                {questionnaire[0]?.questions[0]?.subquestions.map((sub, idx) => (
                  <Box key={idx}>
                    {/* Check if nested subquestions exist */}
                    {sub.subquestions ? (
                      <Box>
                        <Text fontSize="0.875rem" fontWeight="600">{sub.question}</Text>
                        <UnorderedList styleType="disc" pl="1.5rem" spacing="0.75rem">
                          {sub.subquestions.map((nestedSub, nestedIdx) => (
                            <ListItem key={nestedIdx} m='0'>
                              {nestedSub.options ? (
                                <Flex justifyContent="space-between" alignItems="center">
                                  <Text fontSize="0.875rem">{nestedSub.question}:</Text>
                                  <RadioGroup  colorScheme='green' defaultChecked={nestedSub.answer} value={nestedSub.answer}  onChange={(value) => handleChangeRadio(value, nestedSub.question)}  display="flex" gap="1rem">
                                    {nestedSub.options.map((option, optionIdx) => (
                                      <Radio  value={option} key={optionIdx}>
                                        <Text fontSize="0.875rem">{option}</Text>
                                      </Radio>
                                    ))}
                                  </RadioGroup>
                                </Flex>
                              ) : (
                                <Flex justifyContent="space-between" alignItems="center">
                                  <Text fontSize="0.875rem">{nestedSub.question}:</Text>
                                  <PrimaryInput
                                    inputPlace="Enter value"
                                    variant="fullRound"
                                    inputStyleCss={{ width: "130px" }}
                                    inputValue={nestedSub.answer} // bind value if needed
                                    //  defaultValue={nestedSub.answer}
                                    onChange={(e)=>handleHeightWeightZScores(nestedSub.question, e)}
                                  />
                                </Flex>
                              )}
                            </ListItem>
                          ))}
                        </UnorderedList>
                      </Box>
                    ) : (
                      <>
                        <Flex justifyContent="space-between" alignItems="center" gap="0.75rem" key={idx}>
                          <Text fontSize="0.875rem">{sub.question}:</Text>
                          <PrimaryInput
                            inputPlace={`Enter value (${sub.unit || ""})`}
                            variant="fullRound"
                            inputStyleCss={{ width: "150px" }}
                            inputValue={sub.answer} // bind value if needed
                            // defaultValue={sub.answer}
                            onChange={(e)=>handleHeightWeightBasic(sub.unit, e)}
                            />
                        </Flex>
                        <Divider mt='0.75rem'/>
                      </>
                    )}
                  </Box>
                ))}
              </Flex>
                </>
                
             </Box>
            {/* Growth and Development end */}

            <Divider w='100%' />

            {/* WHO Growth Questions start */}
            <Box w='100%'>
                <Text fontSize='1rem' fontWeight='600'>{questionnaire[0].questions[1].question }</Text>
                {questionnaire[0].questions[1].subquestions.map((sub, idx) => (
    <Box key={idx}>
      {/* Main question with options (Radio buttons) */}
      <Flex gap="1rem"  mt='0.875rem' justifyContent="space-between" alignItems="center" flexWrap='wrap'>
        <Text fontSize="0.875rem">{sub.question}</Text>
        <RadioGroup   colorScheme='green'  display="flex" gap="1rem"  value={sub.answer}  onChange={(value) => handleChangeRadio(value, sub.question)} >
          {sub.options.map((option, optionIdx) => (
            <Radio value={option} key={optionIdx}>
              <Text fontSize="0.875rem">{option}</Text>
            </Radio>
          ))}
        </RadioGroup>
      </Flex>

      {/* If moreinfo exists, render follow-up input */}
      {/* && sub.answer == "Yes" &&  */}
      {sub.moreinfo  && sub.answer === "Yes" && (
        <Box mt="1rem">
          <Flex justifyContent="space-between" alignItems="center">
            <PrimaryInput
              variant="fullRound"
              inputPlace={sub.moreinfo.question}
              // inputStyleCss={{ marginTop: "0.875rem" }}
              inputValue ={sub.moreinfo.answer} // Bind value if needed
              
            onChange={(e)=>handleMoreInfo(sub.question, e)}
            maxLength={250}
            />
          </Flex>
        </Box>
      )}
    </Box>
  ))}

            </Box>
            {/* WHO Growth Questions end */}
            {/* General INfo  start */}
            <Box w='100%'>
                <Text fontSize='1rem' fontWeight='600'>{questionnaire[0].questions[2].question }</Text>
                {questionnaire[0].questions[2].subquestions.map((sub, idx) => (
                  <>
                    <Box display='flex' flexDirection='column'>
                        <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                            <Text fontSize='0.875rem'>{sub.question}</Text>
                            <RadioGroup   colorScheme='green'  display='flex' gap='1rem'  value={sub.answer}   onChange={(value) => handleChangeRadio(value, sub.question)}>

                                {sub.options.map((option, optionIdx) => (
                                      <Radio value={option} key={optionIdx}>
                                        <Text fontSize="0.875rem">{option}</Text>
                                      </Radio>
                                  ))}
                            </RadioGroup>
                            
                        </Flex>
                    {sub.question.includes("allergies") && AllergiesDataToBind && AllergiesDataToBind.length > 0 &&  sub.moreinfo && sub.answer === "Yes" &&(
                    <Box
                      className="buttonContainer"
                      py="10px"
                      mt="0.5rem"
                      w="100%"
                      overflowX="auto"
                      overflowY="hidden"
                      whiteSpace="nowrap"
                      display="flex"
                      gap="0.75rem"
                      ref={scrollRef}
                      onMouseDown={handleMouseDown}
                      onMouseLeave={handleMouseLeave}
                      onMouseUp={handleMouseUp}
                      onMouseMove={handleMouseMove}
                    >
                      {sub.question.includes("allergies") && AllergiesDataToBind && AllergiesDataToBind.length > 0 &&
                    <Box title='Add new allergy' cursor='pointer' onClick={()=>openAllergiesModal()} h='auto' display='flex' justifyContent='center' alignItems='center'>
                        <Flex  alignItems='center' justifyContent='center' boxSize='50px' border='1px dashed #E6E7E9' borderRadius='10px'>
                          <Image src='/assets/svgs/add.svg' />
                        </Flex>
                    </Box>
                    }
                      {AllergiesDataToBind &&
                        AllergiesDataToBind?.map((step, i) => (
                          <Box
                          borderRadius="12px"
                          minH={"100px"}
                          // maxH={"150px"}
                          minW={"300px"}
                          w="100%"
                          maxW={"300px"}
                          border="1px solid #E6E7E9"
                          boxShadow="0px 8px 20px 0px #E6E7E966"
                          p='0.75rem'
                          display='flex'
                          flexDir='column'
                          // gap='0.5rem'
                        >
                          <Flex w='100%' alignItems='center' justifyContent='space-between'>
                            <Text fontSize="15px" fontWeight="600" isTruncated title={step.allergytype}>
                            {step.allergytype}
                            </Text>

                             <Box display="flex" gap="10px">
                              <Box
                                as="button"
                                onClick={() =>
                                  openEditAllergyModal(
                                    step.patientallergyid,
                                    step.allergytype,
                                    step.allergyidentifieddate,
                                    step.reaction,
                                    step.allergytype_cd
                                  )}
                                border="1px solid #E6E7E9"
                                borderRadius="full"
                                w="36px"
                                h="auto"
                                p="10px"
                              >
                                <Image w="13px" src="/assets/imgs/edit-gray.png" />
                              </Box>
                              <Box
                                as="button"
                                onClick={() =>
                                  openDeleteModal(step.patientallergyid, step.allergytype)}
                                border="1px solid #E6E7E9"
                                borderRadius="full"
                                w="36px"
                                h="auto"
                                p="10px"
                              >
                                <Image w="13px" src="/assets/imgs/delete.png" />
                              </Box>
                            </Box>
                          </Flex>
                          <Box display='flex' flexDir='column'>
                            <Text fontWeight='600' fontSize='0.75rem'>
                              Date: 
                              <span style={{ fontWeight: "500" }}>
                              {step.allergyidentifieddate? formatDate_DD_MM_YYYY(step.allergyidentifieddate):"-"}
                              </span>
                            </Text>
                          </Box>
                          <Box display='flex' flexDir='column'>
                            <Text fontWeight='600' fontSize='0.75rem'>
                              Reactions:
                            </Text>
                            <Flex flexWrap='wrap'>
                            {step?.reaction?.map((reaction, index) => (
                                  <Box as='span' lineHeight='normal' fontWeight='500' display='inline-flex' fontSize='0.65rem' key={index} title={step?.reaction?.map(reaction => reaction?.reaction).join(", ")}>
                                    {reaction.reaction}
                                    {index < step.reaction.length - 1 && ", "}
                                  </Box>
                                ))}
                            </Flex>
                          </Box>
                        </Box>
                        ))}
                    </Box>
                    )}
                     {sub.question.includes("vaccinations") && VaccinationDataToBind && VaccinationDataToBind.length > 0 &&  sub.moreinfo && sub.answer === "Yes" &&(
                    <Box
                      className="buttonContainer"
                      py="10px"
                      mt="0.5rem"
                      w="100%"
                      overflowX="auto"
                      overflowY="hidden"
                      whiteSpace="nowrap"
                      display="flex"
                      gap="0.75rem"
                      ref={scrollRef}
                      onMouseDown={handleMouseDown}
                      onMouseLeave={handleMouseLeave}
                      onMouseUp={handleMouseUp}
                      onMouseMove={handleMouseMove}
                    >
                      {sub.question.includes("vaccinations") && VaccinationDataToBind && VaccinationDataToBind.length > 0 &&
                    <Box title='Add new surgery' cursor='pointer' onClick={()=>openVaccinationsModal()} h='auto' display='flex' justifyContent='center' alignItems='center'>
                        <Flex  alignItems='center' justifyContent='center' boxSize='50px' border='1px dashed #E6E7E9' borderRadius='10px'>
                          <Image src='/assets/svgs/add.svg' />
                        </Flex>
                    </Box>
                    }
                      {VaccinationDataToBind &&
                        VaccinationDataToBind?.map((step, i) => (
                          <ReportViewCard
                            key={i}
                            //cardStyle={{ h: "205px" }}
                            cardHeight='auto'
                            minCardWidth="300px"
                            maxCardWidth="300px"
                            cardHeaderStyle={{maxW:'170px'}}
                            cardHeader={step.vaccinename}
                            cardSecondary={
                              <>
                              <Text fontWeight="bold">
                                {"Admin Date: "}
                                <span style={{ fontWeight: "500" }}>
                                  {step.admindate?formatDate_DD_MM_YYYY(step.admindate):"-"}
                                </span>
                              </Text>
                              <Text fontWeight="bold">
                              {"Due Date: "}
                              <span style={{ fontWeight: "500" }}>
                                {step.duedate?formatDate_DD_MM_YYYY(step.duedate):"-"}
                              </span>
                            </Text>
                            </>
                            }
                            cardTextStyle={{ mt: "3.5px", fontSize: "12px" }}
                            cardBtn={false}
                            deleteBtn={false}
                            cardBtnStyle={{ flexWrap: "wrap", whiteSpace: "wrap" }}
                            editDeleteBtn={() => openDeleteVaccineModal(step.id, step.vaccinename)}
                            editBtn={() => openEditModal(step.id, step.vaccinename , step.admindate , step.duedate)}
                          />
                        ))}
                    </Box>
                    )}
                    </Box>
                    
                    
{/* {sub.moreinfo  && sub.answer === "Yes" && (
  <Box mt="1rem">
    <Flex justifyContent="space-between" alignItems="center">
      <PrimaryInput
        variant="fullRound"
        inputPlace={sub.moreinfo.question}
        // inputStyleCss={{ marginTop: "0.875rem" }}
        inputValue ={sub.moreinfo.answer} // Bind value if needed
        
      onChange={(e)=>handleMoreInfo(sub.question, e)}
      />
    </Flex>
  </Box>
)} */}
</>
                    
                      ))}

            </Box>
            {/* Symptom Screening end */}
            <Divider w='100%' />

            {/* Symptom Screening start */}
            <Box w='100%'>
                <Text fontSize='1rem' fontWeight='600'>{questionnaire[0].questions[3].question }</Text>
                {questionnaire[0].questions[3].subquestions.map((sub, idx) => (
                    <Box display='flex' flexDirection='column'>
                        <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                            <Text fontSize='0.875rem'>{sub.question}</Text>
                            <RadioGroup   colorScheme='green'  display='flex' gap='1rem'  value={sub.answer}   onChange={(value) => handleChangeRadio(value, sub.question)}>

                                {sub.options.map((option, optionIdx) => (
                                      <Radio value={option} key={optionIdx}>
                                        <Text fontSize="0.875rem">{option}</Text>
                                      </Radio>
                                  ))}
                            </RadioGroup>
                            
                        </Flex>
                    </Box>
                      ))}
            </Box>
            {/* Symptom Screening end */}
            </>
        )}
            <Divider w='100%' />

            {/* Nutrition and Eating Habits start */}
            <Box w='100%'>
                <Text fontSize='1rem' fontWeight='600'>{questionnaire[1].section}</Text>

                {questionnaire[1].questions.map((question, idx) => (
    <Box key={idx} display="flex" flexDirection="column">
      {/* Check if it's a simple question or has subquestions */}
      {question.subquestions ? (
        // Handling "How often does your child consume the following?" question with subquestions
        <Box display="flex" flexDirection="column">
          <Flex gap="1rem" mt="0.875rem" flexDir="column" justifyContent="space-between">
            <Text fontSize="0.875rem">{question.question}</Text>
            <UnorderedList>
              {question.subquestions.map((sub, subIdx) => (
                <ListItem key={subIdx} m="0">
                  <Flex gap="1rem" justifyContent="space-between">
                    <Text fontSize="0.875rem">{sub.question}:</Text>
                    <RadioGroup   colorScheme='green'  display="flex" gap="1rem" value={sub.answer}  onChange={(value) => handleChangeRadio(value, sub.question)}>
                      {sub.options.map((option, optionIdx) => (
                        <Radio value={option} key={optionIdx}>
                          <Text fontSize="0.875rem">{option}</Text>
                        </Radio>
                      ))}
                    </RadioGroup>
                  </Flex>
                </ListItem>
              ))}
            </UnorderedList>
          </Flex>
        </Box>
      ) : (
        // Handling single question with options
        <Flex gap="1rem" mt="0.875rem" justifyContent="space-between">
          <Text fontSize="0.875rem">{question.question}</Text>
          <RadioGroup   colorScheme='green'  display="flex" gap="1rem" value={question.answer}  onChange={(value) => handleChangeRadio(value, question.question)}>
            {question.options.map((option, optionIdx) => (
              <Radio  value={option}  key={optionIdx}>
                <Text fontSize="0.875rem">{option}</Text>
              </Radio>
            ))}
          </RadioGroup>
        </Flex>
      )}
    </Box>
  ))}
                    {/* <Box display='flex' flexDirection='column'>
                        <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                            <Text fontSize='0.875rem'>Does your child have a balanced diet?</Text>
                            <RadioGroup display='flex' gap='1rem'>
                                <Radio><Text fontSize='0.875rem'>Yes</Text></Radio>
                                <Radio><Text fontSize='0.875rem'>No</Text></Radio>
                            </RadioGroup>
                        </Flex>
                    </Box>
                    <Box display='flex' flexDirection='column'>
                        <Flex gap='1rem' mt='0.875rem' flexDir='column' justifyContent='space-between'>
                            <Text fontSize='0.875rem'>How often does your child consume the following?</Text>
            <UnorderedList>
                                <ListItem m='0'>
                                    <Flex gap='1rem' justifyContent='space-between'>
                                        <Text fontSize='0.875rem'>Fruits:</Text>
                                        <RadioGroup display='flex' gap='1rem'>
                                            <Radio><Text fontSize='0.875rem'>Daily </Text></Radio>
                                            <Radio><Text fontSize='0.875rem'>Sometimes </Text></Radio>
                                            <Radio><Text fontSize='0.875rem'>Rarely </Text></Radio>
                    </RadioGroup>
                  </Flex>
                </ListItem>
                                <ListItem m='0'>
                                    <Flex gap='1rem' justifyContent='space-between'>
                                        <Text fontSize='0.875rem'>Vegetables:</Text>
                                        <RadioGroup display='flex' gap='1rem'>
                                            <Radio><Text fontSize='0.875rem'>Daily </Text></Radio>
                                            <Radio><Text fontSize='0.875rem'>Sometimes </Text></Radio>
                                            <Radio><Text fontSize='0.875rem'>Rarely </Text></Radio>
                                        </RadioGroup>
                                    </Flex>
                                </ListItem>
                                <ListItem m='0'>
                                    <Flex gap='1rem' justifyContent='space-between'>
                                        <Text fontSize='0.875rem'>Meat or Protein:</Text>
                                        <RadioGroup display='flex' gap='1rem'>
                                            <Radio><Text fontSize='0.875rem'>Daily </Text></Radio>
                                            <Radio><Text fontSize='0.875rem'>Sometimes </Text></Radio>
                                            <Radio><Text fontSize='0.875rem'>Rarely </Text></Radio>
                                        </RadioGroup>
                                    </Flex>
                                </ListItem>
                                <ListItem m='0'>
                                    <Flex gap='1rem' justifyContent='space-between'>
                                        <Text fontSize='0.875rem'>Dairy Products:</Text>
                                        <RadioGroup display='flex' gap='1rem'>
                                            <Radio><Text fontSize='0.875rem'>Daily </Text></Radio>
                                            <Radio><Text fontSize='0.875rem'>Sometimes </Text></Radio>
                                            <Radio><Text fontSize='0.875rem'>Rarely </Text></Radio>
                                        </RadioGroup>
                                    </Flex>
                                </ListItem>
            </UnorderedList>
          </Flex>
        </Box> */}
            </Box>
            {/* Nutrition and Eating Habits end */}

            <Divider w='100%' />

            {/* Family Medical History start */}
            <Box w='100%'>
                <Text fontSize='1rem' fontWeight='600'>{questionnaire[2].section}</Text>
                    <Box display='flex' flexDirection='column'>
                      {questionnaire[2].questions.map((item, index)=>(
                        
                        <Flex gap='1rem' mt='0.875rem' flexDir='column' justifyContent='space-between'>
                            <Text fontSize='0.875rem'>{item.question}</Text>
                            <UnorderedList>
                              {item.subquestions.map((item, index)=>(
                                
                                <ListItem m='0'>
                                    <Flex gap='1rem' justifyContent='space-between'>
                                        <Text fontSize='0.875rem'>{item.question}:</Text>
                                        <RadioGroup   colorScheme='green'  display='flex' gap='1rem' value={item.answer} onChange={(value) => handleChangeRadio(value, item.question)}>
                                        {item.options.map((option, optionIdx) => (
              <Radio  value={option}  key={optionIdx}>
                <Text fontSize="0.875rem">{option}</Text>
              </Radio>
            ))}
                                        </RadioGroup>
                                    </Flex>
                                </ListItem>
                                
                              ))}
                                
                            </UnorderedList>
                        </Flex>
                      ))}
                    </Box>
            </Box>
            {/* Family Medical History end */}

            <Divider w='100%' />

            {/* School Performance start */}
            <Box w='100%'>
                <Text fontSize='1rem' fontWeight='600'>{questionnaire[3].section}</Text>
         

{questionnaire[3].questions.map((item, idx) => (
                  <Box display='flex' flexDirection='column'>
                        <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                            <Text fontSize='0.875rem'>{item.question}</Text>
                            <Flex gap='1rem' justifyContent='space-between'>
                                <RadioGroup colorScheme='green' display='flex' gap='1rem' value={item.answer}  onChange={(value) => handleChangeRadio(value, item.question)}>
                                {item.options.map((option, optionIdx) => (
              <Radio  value={option}  key={optionIdx}>
                <Text fontSize="0.875rem">{option}</Text>
              </Radio>
            ))}
                                </RadioGroup>
                            </Flex>
                         
                        </Flex>

                        {item.moreinfo  && item.answer === "Yes" &&  (
        <Box mt="1rem">
          <Flex justifyContent="space-between" alignItems="center">
            <PrimaryInput
              variant="fullRound"
              inputPlace={item.moreinfo.question}
              // inputStyleCss={{ marginTop: "0.875rem" }}
              inputValue={item.moreinfo.answer} // Bind value if needed
              
            onChange={(e)=>handleMoreInfo(item.question, e)}
            maxLength={250}
            />
          </Flex>
        </Box>
      )}
                    </Box>
))}
                       {/*
                    <Box display='flex' flexDirection='column'>
                        <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                            <Text fontSize='0.875rem'>Are there any concerns regarding your child’s school performance?</Text>
                            <Flex gap='1rem' justifyContent='space-between'>
                                <RadioGroup display='flex' gap='1rem'>
                                    <Radio><Text fontSize='0.875rem'>Yes </Text></Radio>
                                    <Radio><Text fontSize='0.875rem'>No </Text></Radio>
                                </RadioGroup>
                            </Flex>
                        </Flex>
                        <PrimaryInput variant='fullRound' inputStyleCss={{mt:'0.5rem'}} inputPlace='If yes, please describe' />
                    </Box> */}
            </Box>
            {/* School Performance end */}
                        {/* <Box width="30%">

                        <PrimaryButton
                        buttonText="Add Child Screening"
                        // isDisabled={reactionsContinueBtnDisabled2}
                        onClick={() => createNew()}
                      />
                        </Box> */}
        </Flex>
        <Box zIndex="999" pos='fixed' w='calc(100% - 6rem)' left='5.75rem' bottom='0' p='1rem' bg='white' boxShadow='rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px'>
          <PrimaryButton buttonText='Save'    onClick={() => createNew()} btnStyle={{w:'25%', float:'right'}} />
        </Box>
         {/* Allergies */}
         <ModalRight
          ref={addCondition}
          header="Add allergy"
          body={
            <>
              <SearchBar
                passInputPlace="Search Allergy"
                onChange={(e) => setSearchValue(handleAlphaNumericDotAndSpace(e.target.value))}
                searchValue={searchValue}
              />
              <RadioGroup>
                <Stack direction="column" gap="0">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    borderBottom="1px solid #e2e8f0"
                    py="15px"
                  >
                    <Box display="flex" justifyContent="end" w="100%">
                    <Stack direction="column" gap="0" w="100%">
                      {conditionsList && conditionsList.length > 0 ? (
                        conditionsList.some(category =>
                          category.data.some(condition =>
                            condition.condition.toLowerCase().includes(searchValue.toLowerCase())
                          )
                        ) ? (
                          conditionsList.map(category => (
                            <React.Fragment key={category.category_id}>
                              {category.data
                                .filter(condition =>
                                  condition.condition.toLowerCase().includes(searchValue.toLowerCase())
                                )
                                .map(condition => (
                                  <label key={condition.condition_cd}>
                                    <Box
                                      display="flex"
                                      justifyContent="space-between"
                                      borderBottom="1px solid #e2e8f0"
                                      p="15px"
                                      height="50px"
                                      width="100%"
                                      sx={{
                                        _hover: {
                                          border: "2px solid #384052",
                                          borderRadius: "10px",
                                        },
                                      }}
                                    >
                                      <Box
                                        key={condition.condition_cd}
                                        display="flex"
                                        alignItems="center"
                                      >
                                        <Text fontSize="16px" fontWeight="600">
                                          {condition.condition}
                                          <br />
                                          <Box
                                            as="span"
                                            fontSize="12px"
                                            fontWeight="500"
                                            color="#6A707D"
                                          ></Box>
                                        </Text>
                                      </Box>
                                      <Box display="flex" justifyContent="end">
                                        <Checkbox
                                          colorScheme="green"
                                          sx={{
                                            ".chakra-checkbox__control": {
                                              width: "20px",
                                              height: "20px",
                                              borderRadius: "5px",
                                            },
                                          }}
                                                          onChange={() =>
                                                            handleCheckboxChange(condition)
                                                          }
                                          isChecked={selectedConditions.some(
                                                            (item) =>
                                                              item.condition_cd ===
                                                              condition.condition_cd
                                          )}
                                        ></Checkbox>
                                      </Box>
                                    </Box>


                                  </label>
                                ))}
                            </React.Fragment>
                          ))
                        ) : (
                          <Box p="15px" fontSize="16px" fontWeight="600" textAlign="center">
                            No matching allergies found!
                          </Box>
                        )
                      ) : (
                        <p>Loading...</p>
                      )}
                      <Divider/>
                            <br/>
                            <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
                                    Other Allergy
                                    </Heading>
                          <PrimaryInput
                          inputPlace="Allergy"
                          variant="bothSide"
                                  onChange={handleCondition} inputValue={typedCondition}
                                  />
                                  <br/>
                    </Stack>
                  </Box>
                  </Box>
                </Stack>
              </RadioGroup>
              <ModalRight
                ref={addCondition3}
                handlebackarrow={() => addCondition3.current.closeRight()}
                withBackBtn={true}
                withBackHeader="Reaction"
                body={
                  <>
                    <SearchBar
                      passInputPlace="Search Reaction"
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                    <RadioGroup>
                      <Stack direction="column" gap="0">
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          borderBottom="1px solid #e2e8f0"
                          py="15px"
                        >
                          <Box display="flex" justifyContent="end" w="100%">
                            <Stack direction="column" gap="0" w="100%">
                              {reactionList && reactionList.length > 0 ? (
                                reactionList.map((category) =>
                                  category.data
                                    .filter((condition) =>
                                      condition.condition
                                        .toLowerCase()
                                        .includes(searchValue.toLowerCase())
                                    )
                                    .map((condition) => (
                                      <label key={condition.condition_cd} >

                                        <Box
                                          display="flex"
                                          justifyContent="space-between"
                                          borderBottom="1px solid #e2e8f0"
                                          p="15px"
                                          height="50px"
                                          width="100%"
                                          sx={{
                                            _hover: {
                                              border: "2px solid #384052",
                                              borderRadius: "10px",
                                            },
                                          }}
                                        >
                                          <Box
                                            key={condition.condition_cd}
                                            display="flex"
                                            alignItems="center"
                                          >
                                            <Text fontSize="16px" fontWeight="600">
                                              {condition.condition}
                                              <br />
                                              <Box
                                                as="span"
                                                fontSize="12px"
                                                fontWeight="500"
                                                color="#6A707D"
                                              ></Box>
                                            </Text>
                                          </Box>
                                          <Box display="flex" justifyContent="end">
                                            <Checkbox
                                              colorScheme="green"
                                              sx={{
                                                ".chakra-checkbox__control": {
                                                  width: "20px",
                                                  height: "20px",
                                                  borderRadius: "5px",
                                                },
                                              }}
                                              onChange={() =>
                                                handleReactionCheckboxChange(
                                                  condition, 
                                                  allergyID
                                                )
                                              }
                                              isChecked={reasonsArray.some(
                                                (item) =>
                                                  item.allergy_id === allergyID &&
                                                  item.condition_cd ===
                                                  condition.condition_cd
                                              )}
                                            ></Checkbox>
                                          </Box>
                                        </Box>

                                      </label>
                                    ))
                                )
                              ) : (
                                <p>Loading...</p>
                              )}
                               <Divider/>
                                <br/>
                                <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
                                         Other Reactions
                                        </Heading>
                              <PrimaryInput
                               inputPlace="Reactions"
                               variant="bothSide"
                                      onChange={handleReaction} inputValue={typedReaction}
                               />
                               <br/>
                            </Stack>
                          </Box>
                        </Box>
                      </Stack>
                    </RadioGroup>
                  </>
                }
                footerActions={
                  <>
                    <Box w="100%" display="flex" flexDirection="row" gap="15px">
                      <PrimaryButton
                        buttonText="Add reactions"
                        isDisabled={reactionsContinueBtnDisabled2}
                        onClick={() => AddReactionsInInput(allergyID)}
                      />
                    </Box>
                  </>
                }
              />

              <ModalRight
                ref={addCondition2}
                handlebackarrow={() => addCondition2.current.closeRight()}
                withBackBtn={true}
                withBackHeader="Add allergy"
                body={
                  <>
                    <Box display="flex" flexDir="column" gap="20px">
                      {selectedConditions.map((condition, index) => (
                        <Box key={index}>
                          <Text fontSize="16px" py="10px" fontWeight="600">
                            {condition.condition}
                          </Text>
                          <DatePicker
                            disableFutureDates={true}
                            onDateChange={(selectedDateValue) => handleDateChange(condition.condition_cd, selectedDateValue)}
                            placeholderProp="Date*" />
                        
                          <Flex w="100%" alignItems="center" gap="2">
                            <Box
                              as="div"
                              role="button"
                              aria-haspopup="dialog"
                              display="flex"
                              alignItems="center"
                              tabIndex={0}
                              border="1px solid #e2e8f0"
                              borderRadius="0 0 10px 10px"
                              p="10px"
                              height="auto"
                              width="100%"
                              sx={{
                                _hover: { border: "2px solid #384052" },
                              }}
                              onClick={() => showReactionList(condition.condition_cd)}
                              minHeight="48px"
                              flexWrap="wrap"
                            >
                              <Box
                                key={condition.condition_cd}
                                display="flex"
                                alignItems="center"
                                flexWrap="wrap"
                                gap="5px"
                                w="100%"
                                justifyContent={reasonsArray.length === 0 ? "space-between" : "start"}>
                                {reasonsArray.length === 0 ? (
                                  <Text pl="2" color="gray.400">
                                    Reaction*
                                  </Text>
                                ) : reasonsArray.some(
                                  (item) => item.allergy_id === condition.condition_cd
                                ) ? (
                                  <Text pl="2" color="gray.400" sx={{ ...reasonsArray.length === 0 ? {} : floatLabel }} >Reaction*</Text>
                                ) : (
                                  <Text pl="2" color="gray.400">
                                    Reaction*
                                  </Text>
                                )}
                                {reasonsArray.length === 0 && (
                                  <Image src="/assets/imgs/right-icon.png" />
                                )}

                                {reasonsArray
                                  .filter(
                                    (tag) => tag.allergy_id == condition.condition_cd
                                  )
                                  .map((tag, index) => (
                                    <Box
                                      key={index}
                                      display="flex"
                                      alignItems="center"
                                      gap="5px"
                                      bg="gray.100"
                                      borderRadius="full"
                                      wordBreak="break-all"
                                      p="1"
                                    >
                                      <Text fontSize="sm" px="5px">
                                        {tag.condition}
                                      </Text>
                                      <Box
                                        as="button"
                                        p="5px"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          // removeTag(index);
                                          removeReaction(condition.condition_cd, tag.condition_cd);
                                        }}
                                        aria-label="Remove condition"
                                      >
                                        <Image src="/assets/imgs/close.png" />
                                      </Box>
                                    </Box>
                                  ))}
                              </Box>
                              <Box display="flex" justifyContent="end"></Box>
                            </Box>
                          </Flex>
                          {/* {documentURLs?.map((document, index) => {
                              if (document.condition_cd == condition.condition_cd || (document.condition_cd == null && condition.condition_cd == null)) {
                            if (!indexMap[document.condition_cd]) {
                                indexMap[document.condition_cd] = 1;
                              }
                              const docIndex = indexMap[document.condition_cd];
                              indexMap[document.condition_cd]++; // Increment index for next document with same condition_cd

                              return (
                                <Box key={index} mb="2" mt="2">
                                  <Button
                                    as="a"
                                    rel="noopener noreferrer"
                                    w="100%"
                                    h="48px"
                                    borderRadius="10px"
                                    border="1px solid #E6E7E9"
                                    display="flex"
                                    justifyContent="space-between"
                                    bg="white"
                                    color="#1C4EC5"
                                    sx={{ _hover: { bg: "none" } }}
                                    style={{
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      fontSize: "12px",
                                    }}
                                  >
                                    Document {docIndex}
                                    <Box as="span" onClick={() => handleDelete(index)}>
                                      <Image src="/assets/imgs/delete.png" />
                                    </Box>
                                  </Button>
                                </Box>
                              );
                            }
                          })}
                          <Box onClick={() => addSurgeriesUploadDocument(condition.condition_cd)} as="button" display="flex" alignItems="center" gap="10px" mt="5px" fontSize="14px" fontWeight="600" color="#1C4EC5" textAlign="left">
                            <Image w="11px" src="/assets/imgs/add-blue.png" />
                            Add Document
                          </Box>
                          <input
                            type='file'
                            id='file-upload'
                            onChange={(e) => handleAllergiesUpload(e)}
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                          /> */}
                          {/* <Divider /> */}
                        </Box>
                      ))}
                    </Box>
                  </>
                }
                footerActions={
                  <>
                    <Box w="100%" display="flex" flexDirection="row" gap="15px">
                      <PrimaryButton
                        buttonText="Add Allergy"
                        isDisabled={reactionsContinueBtnDisabled}
                        onClick={AddMedicalConditionsCall}
                      />
                    </Box>
                  </>
                }
              />

            </>
          }
          footerActions={
            <>
              <Box w="100%" display="flex" flexDirection="row" gap="15px">
                <PrimaryButton
                  buttonText="Continue"
                  isDisabled={allergiesContinueBtnDisabled}
                  onClick={AddMedicalConditions}
                />
              </Box>
            </>
          }
        />
        <ModalRight
                ref={editConditionModal}
                handlebackarrow={() => editConditionModal.current.closeRight()}
                withBackBtn={true}
                withBackHeader="Edit allergy"
                body={
                  <>
                    <Box display="flex" flexDir="column" gap="20px">
                        <Box>
                          <Text fontSize="16px" py="10px" fontWeight="600">
                            {selectedConditionsInEdit}
                          </Text>
                          <DatePicker
                            disableFutureDates={true}
                            defaultDate={dateInEdit}
                            onDateChange={(selectedDateValue) => handleDateChangeInEdit(selectedDateValue)}
                            placeholderProp="Date*" />
                        
                          <Flex w="100%" alignItems="center" gap="2">
                            <Box
                              as="div"
                              role="button"
                              aria-haspopup="dialog"
                              display="flex"
                              alignItems="center"
                              tabIndex={0}
                              border="1px solid #e2e8f0"
                              borderRadius="0 0 10px 10px"
                              p="10px"
                              height="auto"
                              width="100%"
                              sx={{
                                _hover: { border: "2px solid #384052" },
                              }}
                              onClick={() => showReactionListInEdit()}
                              minHeight="48px"
                              flexWrap="wrap"
                            >
                              <Box
                                display="flex"
                                alignItems="center"
                                flexWrap="wrap"
                                gap="5px"
                                w="100%"
                                justifyContent={reasonsArray.length === 0 ? "space-between" : "start"}>
                                {reasonsArray.length === 0 ? (
                                  <Text pl="2" color="gray.400">
                                    Reaction*
                                  </Text>
                                ) : reasonsArray.some(
                                  (item) => item.reaction === item.reaction,
                                ) ? (
                                  <Text pl="2" color="gray.400" sx={{ ...reasonsArray.length === 0 ? {} : floatLabel }} >Reaction*</Text>
                                ) : (
                                  <Text pl="2" color="gray.400">
                                    Reaction*
                                  </Text>
                                )}
                                {reasonsArray.length === 0 && (
                                  <Image src="/assets/imgs/right-icon.png" />
                                )}

                                {reasonsArray
                                  .filter(
                                    (tag) => tag.reaction === tag.reaction,
                                  )
                                  .map((tag, index) => (
                                    <Box
                                      key={index}
                                      display="flex"
                                      alignItems="center"
                                      gap="5px"
                                      bg="gray.100"
                                      borderRadius="full"
                                      p="1"
                                       wordBreak="break-all"
                                    >
                                      <Text fontSize="sm" px="5px">
                                        {tag.reaction}
                                      </Text>
                                      <Box
                                        as="button"
                                        p="5px"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                           removeTag(index);
                                        }}
                                        aria-label="Remove condition"
                                      >
                                        <Image src="/assets/imgs/close.png" />
                                      </Box>
                                    </Box>
                                  ))}
                              </Box>
                              <Box display="flex" justifyContent="end"></Box>
                            </Box>
                          </Flex>
                        </Box>
                 
                    </Box>
                    <ModalRight
                ref={reactionListModal}
                handlebackarrow={() => reactionListModal.current.closeRight()}
                withBackBtn={true}
                withBackHeader="Reaction"
                body={
                  <>
                    <SearchBar
                      passInputPlace="Search Reaction"
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                    <RadioGroup>
                      <Stack direction="column" gap="0">
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          borderBottom="1px solid #e2e8f0"
                          py="15px"
                        >
                          <Box display="flex" justifyContent="end" w="100%">
                            <Stack direction="column" gap="0" w="100%">
                              {reactionList && reactionList.length > 0 ? (
                                reactionList.map((category) =>
                                  category.data
                                    .filter((condition) =>
                                      condition.condition
                                        .toLowerCase()
                                        .includes(searchValue.toLowerCase())
                                    )
                                    .map((condition) => (
                                      <label key={condition.condition_cd} >

                                        <Box
                                          display="flex"
                                          justifyContent="space-between"
                                          borderBottom="1px solid #e2e8f0"
                                          p="15px"
                                          height="50px"
                                          width="100%"
                                          sx={{
                                            _hover: {
                                              border: "2px solid #384052",
                                              borderRadius: "10px",
                                            },
                                          }}
                                        >
                                          <Box
                                            key={condition.condition_cd}
                                            display="flex"
                                            alignItems="center"
                                          >
                                            <Text fontSize="16px" fontWeight="600">
                                              {condition.condition}
                                              <br />
                                              <Box
                                                as="span"
                                                fontSize="12px"
                                                fontWeight="500"
                                                color="#6A707D"
                                              ></Box>
                                            </Text>
                                          </Box>
                                          <Box display="flex" justifyContent="end">
                                            <Checkbox
                                              colorScheme="green"
                                              sx={{
                                                ".chakra-checkbox__control": {
                                                  width: "20px",
                                                  height: "20px",
                                                  borderRadius: "5px",
                                                },
                                              }}
                                              onChange={() =>
                                                handleReactionCheckboxChangeInEdit(
                                                  condition
                                                )
                                              }
                                              isChecked={reasonsArray.some(
                                                (item) =>
                                                  item.reaction ===
                                                  condition.condition
          
                                              )}
                                            ></Checkbox>
                                          </Box>
                                        </Box>

                                      </label>
                                    ))
                                )
                              ) : (
                                <p>Loading...</p>
                              )}
                               <Divider/>
                                <br/>
                                <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
                                         Other Reactions
                                        </Heading>
                              <PrimaryInput
                               inputPlace="Reactions"
                               variant="bothSide"
                                      onChange={handleReaction} inputValue={typedReaction}
                               />
                               <br/>
                            </Stack>
                          </Box>
                        </Box>
                      </Stack>
                    </RadioGroup>
                  </>
                }
                footerActions={
                  <>
                    <Box w="100%" display="flex" flexDirection="row" gap="15px">
                      <PrimaryButton
                        buttonText="Edit reactions"
                        isDisabled={reactionsContinueBtnDisabledEdit}
                        onClick={editReactions}
                      />
                    </Box>
                  </>
                }
              />
                  </>
                }
                
                footerActions={
                  <>
                    <Box w="100%" display="flex" flexDirection="row" gap="15px">
                      <PrimaryButton
                        buttonText="Update Allergy"
                        isDisabled={reactionsContinueBtnDisabledEdit}
                        onClick={EditMedicalConditionsCall}
                      />
                    </Box>
                  </>
                }
              />
        <DynamicModal
          ref={dynamicModalAllergy}
          modalHeader="Delete Allergy?"
          modalBody={
            <>
              <Text fontSize="14px" fontWeight="600">
                Are you sure you want to delete  <strong>"{allergytype}"</strong>
              </Text>
            </>
          }
          modalFooterBtn={
            <>
              <PrimaryButton
                variant="deleteBtn"
                buttonText="Delete"
                onClick={deleteAllergy}
              />
            </>
          }
        />
        {/* Vaccinations */}
        <ModalRight
          ref={addvaccination}
          header="Add vaccine"
          body={
            <>
                  <SearchBar passInputPlace="Search condition" onChange={(e) => setSearchValue(e.target.value)} />
                  <RadioGroup>
                    <Stack direction="column" gap="0">
                    <Box display="flex" justifyContent="end" w="100%">
                      <Stack direction="column" gap="0" w="100%">
                        {vaccineList && vaccineList.length > 0 ? (
                          vaccineList.some(category =>
                            category.data.some(condition =>
                              condition.condition.toLowerCase().includes(searchValue.toLowerCase())
                            )
                          ) ? (
                            vaccineList.map(category => (
                              <React.Fragment key={category.category_id}>
                                {category.data
                                  .filter(condition =>
                                    condition.condition.toLowerCase().includes(searchValue.toLowerCase())
                                  )
                                  .map(condition => (
                                    <label key={condition.condition_cd}>
                                      <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        borderBottom="1px solid #e2e8f0"
                                        p="15px"
                                                        pl="5px"
                                                        minH="50px"
                                        width="100%"
                                        sx={{
                                                          _hover: { border: "2px solid #384052", borderRadius: "10px" },
                                        }}
                                      >
                                        <Box
                                          key={condition.condition_cd}
                                          display="flex"
                                          alignItems="center"
                                        >
                                          <Text fontSize="16px" lineHeight="normal" fontWeight="600">
                                            {condition.condition}
                                          </Text>
                                        </Box>
                                        <Box display="flex" justifyContent="end">
                                          <Checkbox
                                            colorScheme="green"
                                            sx={{
                                              ".chakra-checkbox__control": {
                                                width: "20px",
                                                height: "20px",
                                                borderRadius: "5px",
                                              },
                                            }}
                                                            onChange={() =>
                                                              handleVaccineCheckboxChange(condition)
                                                            }
                                            isChecked={selectedVaccines.some(
                                                              (item) =>
                                                                item.condition_cd ===
                                                                condition.condition_cd
                                            )}
                                          ></Checkbox>
                                        </Box>
                                      </Box>
                                    </label>
                                  ))}
                              </React.Fragment>
                            ))
                          ) : (
                            <Box p="15px" fontSize="16px" fontWeight="600" textAlign="center">
                            No matching vaccinations found!
                            </Box>
                          )
                        ) : (
                          <p>Loading...</p>
                        )}
                        <Divider/>
                              <br/>
                              <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
                                      Other Vaccination
                                      </Heading>
                            <PrimaryInput
                            inputPlace="Vaccination"
                            variant="bothSide"
                                    onChange={handleVaccineCondition} inputValue={typedvaccineCondition}
                                    />
                                    <br/>
                      </Stack>
                    </Box>
                    </Stack>
                  </RadioGroup>


                  <ModalRight
          ref={addvaccinedates}
          header="Add Vaccination"
          body={
            <>
            <Box display="flex" flexDir="column" gap="20px">
              {selectedVaccines.map((condition, index) => (
                <Box key={index}>
                  <Text fontSize="16px" py="10px" fontWeight="600">
                    {condition.condition}
                  </Text>

                    <DatePicker 
                        disableFutureDates={true}
                        //minDate={null} 
                        maxDate={maxDateState} 
                        onDateChange={(selectedDateValue) => handleVaccineDateChange(condition.condition_cd, selectedDateValue)}
                        placeholderProp="Admin Date*" /> 
                    
                    <DatePicker 
                    disablePastDates={true}
                        //minDate={minDateState}
                        onDateChange={(selectedDateValue) => handleDueDateChange(condition.condition_cd, selectedDateValue)}
                        placeholderProp="Due Date" />  
                </Box>
              ))}
            </Box>
          </>
          }
          footerActions={<>
             <Box
                w="100%"
                display="flex"
                flexDirection="row"
                gap="15px">

                <PrimaryButton
                buttonText="Add Vaccine"
                isDisabled={false}
                onClick={AddVaccineCall}
                />
            </Box>
          </>}
        />
                </>
          }
          footerActions={<>
             <Box
                w="100%"
                display="flex"
                flexDirection="row"
                gap="15px">

                <PrimaryButton
                buttonText="Continue"
                isDisabled={false}
                onClick={AddVaccine}
                />
            </Box>
          </>}
        />
        <ModalRight
          ref={editvaccination}
          header="Edit Vaccination "
          body={
            <>
              <Box display="flex" flexDir="column" gap="20px">
                  <Box >
                    <Text fontSize="16px" py="10px" fontWeight="600">
                      {vaccineName}
                    </Text>
                  
                        <DatePicker 
                          disableFutureDates={true}
                          minDate={null} 
                          maxDate={new Date().toISOString()} 
                          defaultDate={adminDate}
                          onDateChange={(selectedDateValue) => handleEditAdminDate( selectedDateValue)}
                          placeholderProp="Admin Date*"
                          inputStyle={{borderRadius: "10px 10px 0 0"}} /> 

                        <DatePicker 
                          defaultDate={dueDate}
                          minDate={new Date(new Date().setHours(0, 0, 0, 0))}
                          onDateChange={(selectedDateValue) => handleEditDueDate( selectedDateValue)}
                          placeholderProp="Due Date"
                          inputStyle={{borderRadius: "0 0 10px 10px"}} />  

                  

                      

                  </Box>
              
              </Box>
            </>
          }
          footerActions={<>
             <Box
                w="100%"
                display="flex"
                flexDirection="row"
                gap="15px">

                <PrimaryButton
                buttonText="Update Vaccine"
                isDisabled={false}
                onClick={EditVaccineDates}
                />
            </Box>
          </>}
        />
        <DynamicModal
          ref={dynamicModalVaccine}
          modalHeader="Delete Vaccination?"
          modalBody={
            <>
              <Text fontSize="14px" fontWeight="600">
                Are you sure you want to delete "{condition}"
              </Text>
            </>
          }
          modalFooterBtn={
            <>
              <PrimaryButton
                variant="deleteBtn"
                buttonText="Delete"
                  onClick={deleteVaccine}
              />
            </>
          }
        />
    </>
  )
}

export default ChildScreening