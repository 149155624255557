import moment from "moment";
export default function formAPIModalSubscriptionDescription({
    name

})
{
  return Object.freeze({
    name
   
  });
}