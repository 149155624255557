
import formAPIModelSubscription from "./subscription";


var formattedOutput = null;
//var formattedArray = [];
const formAPISubscriptions = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIModelSubscription(item));

  return formattedOutput
};

export default formAPISubscriptions


