import React, { useRef, useState, useEffect } from "react";
import useLoader from "../../components/loader/loaderstates";
import MiniSideBar from "../../components/mini-sidebar";
import Loader from "../../components/loader/loader";
import SecondaryNav from "../../components/seconday-nav";
import PrimaryButton from "../../components/primary-button";
import {
  Box,
  Divider,
  Flex,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import SimpleTable from "../../components/simple-table";
import { PrimaryInput, Toaster } from "../../components";
import { COLORS } from "../../components/styles/colors";
import DynamicModal from "../../components/dynamic-modal";
import { useLocation, useNavigate } from "react-router-dom";
import { FetchOrderDetails, GetMasterData, SaveGenerateInvoice } from "../../middleware/services/billing/index";
import { uploadImageInProfile } from "../../middleware/services/profile";
import moment from "moment";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { FaCloudShowersWater } from "react-icons/fa6";
import { getAddressInfo, getCurrency, getDoctorName,getMobile, getEmail, getLogo, getHospitalName } from "../../auth";
import { useReactToPrint } from "react-to-print";


const initialRow = {
  labname: '',
  totalamount: '',
  inventoryid: '',
  prescriptionid: '',
  totalCgst:'',
  totalSgst:'',
  sgst:'',
  cgst:'',
};

const mapDataToRows = (data) => {
  return data.map(item => ({
    labname: item.ordername || '',
    totalamount: item.totalamount || '',
    inventoryid: item.orderid || '',
    totalSgst: item.sgst || '',
    totalCgst: item.cgst || '',
    sgst: item.sgst || '',
    cgst: item.cgst || '',
  }));
};
const EditLabOrder = () => {
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const location = useLocation();
  const { entityid, entitytype, patientid, appointmentid } = location.state || {};
  const [doctorid, setDoctorid] = useState();
  const [data, setData] = useState(null);
  const [patientdata, setPatientData] = useState(null);
  const [labList, setLabList] = useState([]);
  const today = moment().format("YYYY-MM-DD HH:mm:ss.SSS");
  const [suggestions, setSuggestions] = useState([]);
  const [showLabNamesDropdown, setShowLabNamesDropdown] = useState(-1);
  const deleteRowModal = useRef();
  const generatepopup = useRef();
  const [rows, setRows] = useState([initialRow]);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [isprintclicked, setIsPrintClicked] = useState("false");
  const [boolValForSuggestions, setBoolValForSuggestions] = useState(true);
  const [focusedItemIndex, setFocusedItemIndex] = useState(-1);
  const suggestionsMenuRef = useRef(null);
  const navigate = useNavigate();
  const { showSuccessToast, showErrorToast } = Toaster();
  const [ inputId , setInputId ] = useState("")
  const [currency, setCurrency] = useState("");
  const [Address, setAddressDetails] = useState([]);
  const [doctorname, SetDoctorName] = useState(getDoctorName());
  const printRef = useRef();
  const [hospitallogo, setHospitalLogo] = useState("");
  const [hospitalname, setHospitalName] = useState("");


  useEffect(() => {
    if (entityid) {
      fetchData();
    }
    fetchLabs();
    const currency = getCurrency();
    if(currency == "USD"){
      const uscurrency = '$'
      setCurrency(uscurrency);
    }else{
    setCurrency(currency);
    }
    const add = getAddressInfo();
    setAddressDetails(add);
    var hospitallogo = getLogo();
    //var imagepath = "https://midocfileserver.blob.core.windows.net/midocdev/";
    if(hospitallogo){
      setHospitalLogo(hospitallogo)
    }
    var hospitalname = getHospitalName();
    if(hospitalname){
      setHospitalName(hospitalname)
    }
  }, []);

  const fetchData = async () => {
    showLoader();
    try {
      const result = await FetchOrderDetails({
        doctorid,
        entityid: entityid,
        entitytype: entitytype,
        patientid: patientid,
        appointmentid: appointmentid,
      });
      if (result != null) {
        setData(result.data);
        setPatientData(result.patientdetail);
        setRows(mapDataToRows(result.data));
        
      }else{
        setRows([initialRow]);
      }
    } catch (error) {
      console.error("Error in home:", error);
    }
    dismissLoader();
  };

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    return moment(dateString).format("ddd, D MMM YYYY");
  };

  const fetchLabs = async () => {
    showLoader();
    try {
      const labResult = await GetMasterData({ type: "labs" });
      const imagingResult = await GetMasterData({ type: "imaging" });
  
      // Create an empty array to hold the merged data
      const mergedData = [];
  
      // Check if lab data exists and push it to mergedData
      if (labResult.output.data.length > 0) {
        mergedData.push(...labResult.output.data);
      }
  
      // Check if imaging data exists and push it to mergedData
      if (imagingResult.output.data.length > 0) {
        mergedData.push(...imagingResult.output.data);
      }
  
      // Set the merged data to the state
      setLabList(mergedData);
  
      dismissLoader();
    } catch (error) {
      console.error("Error in home:", error);
      dismissLoader(); // Ensure loader is dismissed in case of an error
    }
  };

  const filterLabNames = (medicine) => {
    const filtered = labList.filter((obj) =>
      obj.subtype?.toLowerCase().includes(medicine.toLowerCase())
    );
    setSuggestions(filtered);
    setBoolValForSuggestions(!boolValForSuggestions);
  };
  const handleLabNameChange = (id, name, index) => {
    setInputId(index)

    const newRows = [...rows];
    newRows[index].labname = name;
    if (!name) {
      newRows[index] = { ...initialRow }; // Reset the entire row if medicine name is cleared
    }
    setRows(newRows);
    if (name.length >= 3) {
      filterLabNames(name);
      setShowLabNamesDropdown(index);
      setBoolValForSuggestions(!boolValForSuggestions);
    } else {
      setSuggestions([]);
      setBoolValForSuggestions(!boolValForSuggestions);
      setShowLabNamesDropdown(-1);
    }
  };
  const handleTotalAmountChange = (amount, index) => {
    const newRows = [...rows];
    newRows[index].totalamount = amount;
    setRows(newRows);
  };
  const handleSelect = (suggestion, index) => {
    const newRows = [...rows];
    newRows[index].labname = suggestion.subtype;
    newRows[index].totalamount = suggestion.totalamount;
    newRows[index].inventoryid = suggestion.id;
    newRows[index].totalSgst = suggestion.sgst;
    newRows[index].totalCgst = suggestion.cgst;
    newRows[index].sgst = suggestion.sgst;
    newRows[index].cgst = suggestion.cgst;
    setRows(newRows);
    setSuggestions([]);
    setBoolValForSuggestions(!boolValForSuggestions);
    setShowLabNamesDropdown(-1);
  };

  const AddAnotherRow = () => {
    const initialRow = {
      labname: '',
      totalamount: '',
      inventoryid: '',
      prescriptionid: ''
    };
    setRows((prevRows) => [...prevRows, { ...initialRow }]);
  };

  const deleteRowHandler = () => {
    const newRows = rows.filter((_, i) => i !== rowToDelete);
    setRows(newRows);
    deleteRowModal.current.closeModal();
  };

  const confirmDeleteRow = (index) => {
    setRowToDelete(index);
    deleteRowModal.current.openModal();
  };
  const calculateTotalAmountSum = () => {
    const total = rows.reduce((total, row) => total + parseFloat(row.totalamount || 0), 0);
    return total.toFixed(2); 
  };
  const calculateTotalCgstSum = () => {
    const totalCgst = rows.reduce((total, row) => {
      const totalAmount = parseFloat(row.totalamount || 0);
      const cgstPercentage = parseFloat(row.cgst || 0);
      const cgstValue = (cgstPercentage / 100) * totalAmount;
      return total + cgstValue;
    }, 0);
    return totalCgst.toFixed(2);
  };
  
  const calculateTotalSgstSum = () => {
    const totalSgst = rows.reduce((total, row) => {
      const totalAmount = parseFloat(row.totalamount || 0);
      const sgstPercentage = parseFloat(row.sgst || 0);
      const sgstValue = (sgstPercentage / 100) * totalAmount;
      return total + sgstValue;
    }, 0);
    return totalSgst.toFixed(2);
  };
  
  const calculateGrandTotal = () => {
    const grandTotal = rows.reduce((total, row) => {
      const totalAmount = parseFloat(row.totalamount || 0);
      const totalSgst = parseFloat(row.sgst || 0);
      const totalCgst = parseFloat(row.cgst || 0);
  
      // Calculate SGST and CGST as percentages of the total amount
      const sgstAmount = (totalSgst / 100) * totalAmount;
      const cgstAmount = (totalCgst / 100) * totalAmount;
  
      // Add totalAmount and calculated GST values
      return total + totalAmount + sgstAmount + cgstAmount;
    }, 0);

    return grandTotal.toFixed(2);
  };
  // This is to calculate and display in UI
  const calculateTotalAmountSumForDisplay = () => {
    const total = rows.reduce((total, row) => total + parseFloat(row.totalamount || 0), 0);
    return `${currency} ${total.toFixed(2)}`;
  };
  const calculateTotalSgstSumForDisplay = () => {
    //const totalSgst = rows.reduce((total, row) => total + parseFloat(row.totalSgst || 0), 0);
    const totalSgst = rows.reduce((total, row) => {
      const totalAmount = parseFloat(row.totalamount || 0);
      const sgstPercentage = parseFloat(row.sgst || 0);
      const sgstValue = (sgstPercentage / 100) * totalAmount;
      return total + sgstValue;
    }, 0);
    return `${currency} ${totalSgst.toFixed(2)}`;
  };

  const calculateTotalCgstSumForDisplay = () => {
    //const totalCgst = rows.reduce((total, row) => total + parseFloat(row.totalCgst || 0), 0);
    const totalCgst = rows.reduce((total, row) => {
      const totalAmount = parseFloat(row.totalamount || 0);
      const cgstPercentage = parseFloat(row.cgst || 0);
      const cgstValue = (cgstPercentage / 100) * totalAmount;
      return total + cgstValue;
    }, 0);
    return `${currency} ${totalCgst.toFixed(2)}`;
  };
  const calculateGrandTotalForDisplay = () => {
    const grandTotal = rows.reduce((total, row) => {
      const totalAmount = parseFloat(row.totalamount || 0);
      const totalSgst = parseFloat(row.sgst || 0);
      const totalCgst = parseFloat(row.cgst || 0);
  
      // Calculate SGST and CGST as percentages of the total amount
      const sgstAmount = (totalSgst / 100) * totalAmount;
      const cgstAmount = (totalCgst / 100) * totalAmount;
  
      // Add totalAmount and calculated GST values
      return total + totalAmount + sgstAmount + cgstAmount;
    }, 0);
  
    return `${currency} ${grandTotal.toFixed(2)}`;
  };
  const prepareInvoiceInput = async () => {
    showLoader();
    const isValidRows = (rows, fieldNames) => {
      for (const row of rows) {
        for (const fieldName of fieldNames) {
          if (!row[fieldName]) {
            dismissLoader();
            // Assuming you have a function to show toast messages
            showErrorToast(`Please fill in the ${fieldName}`);
            generatepopup.current.closeModal()
            return false;
          }
        }
      }
      return true;
    };
    if (!isValidRows(rows, ['labname','totalamount'])) return;
    let invoiceInput = {
      loginid: "",
      invoiceurl: "",
      subtotal: calculateTotalAmountSum(),
      tax: "0.00",
      cgst:calculateTotalCgstSum(),
      sgst:calculateTotalSgstSum(),
      totalamount: calculateGrandTotal(),
      saletype: "Doctor sale",
      subtype:"lab",
      patientid: patientid,
      doctorid: "",
      invoicenumber: patientdata && patientdata.invoicenumber,
      appointmentid: appointmentid,
      details: rows.map((row) => ({
        inventoryid: row.inventoryid,
        productname: row.labname,
        finalprice: parseFloat(row.totalamount || 0).toFixed(2),
      })),
      "currency":getCurrency(),
    };
  
    const generatePNG = async () => {
      const input = printRef.current;
        // Apply manual styles directly to the `input` or wrap in a container with these styles
      input.style.padding = '10mm'; // Simulating margin as @page is not supported
      input.style.width = '210mm'; // A4 page width in mm
      input.style.height = '297mm'; 
      const canvas = await html2canvas(input, { scale: 4, useCORS: true });
      const imgData = canvas.toDataURL("image/jpeg", 4);
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  
      pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
  
      const pdfBlob = pdf.output("blob");
      // Upload the PNG image
      const result = await uploadImageInProfile(pdfBlob);
      invoiceInput.invoiceurl = result.name;

      return imgData; // Return the base64 image data
    };

    const saveAndPrint = async (imgData) => {
      const response = await SaveGenerateInvoice(invoiceInput);
      if (response.output.result === "success") {
        dismissLoader();
        handlePrint();
        navigate("/billing");
      }

    };

    generatePNG()
      .then(imgData => saveAndPrint(imgData))
      .catch(error => console.error("Error generating invoice:", error));
  };
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: `
      @page {
        size: A4;
        margin: 5mm;
      }
    `,
  });
  const handleKeyDown = (e, index) => {
    if (e.key === 'ArrowDown') {
      setFocusedItemIndex((prevIndex) => (prevIndex + 1) % suggestions.length);
      const itemHeight = suggestionsMenuRef.current?.children[0]?.clientHeight || 0;
      if (suggestionsMenuRef.current) {
        suggestionsMenuRef.current.scrollTop = focusedItemIndex * itemHeight;
      }
    } else if (e.key === 'ArrowUp') {
      setFocusedItemIndex((prevIndex) =>
        prevIndex === 0 ? suggestions.length - 1 : prevIndex - 1
      );
      const itemHeight = suggestionsMenuRef.current?.children[0]?.clientHeight || 0;
      if (suggestionsMenuRef.current) {
        suggestionsMenuRef.current.scrollTop = focusedItemIndex * itemHeight;
      }
    } else if (e.key === 'Enter' && focusedItemIndex !== -1) {
      const selectedItem = suggestions[focusedItemIndex];
      handleSelect(selectedItem, index);
    }
  };
  const gobackfunction = ()=>{
    window.history.back();
   }
   const middleSectionHeaderWidth = 20;
   const scaleOut = rows.length > 5 ?  true:false;
  return (
    <>
      <Flex>
        <Box w="6rem" className="no-print">
          <MiniSideBar />
        </Box>
        {isLoading && <Loader />}
        <Box w="calc(100vw - 6.25rem)" display="flex" flexDir="column" id="print-content">
          <SecondaryNav withBack="Edit lab order" goBack={gobackfunction} className="no-print" />

          <Flex w="100%" p="1rem 1.5rem" id="printableDiv1">
            <Box w="30%" display="flex">
              <Flex w="100%" flexDir="column">
                <Text fontSize="0.875rem">Patient name:</Text>
                <Text fontSize="0.875rem">Patient Mobile no:</Text>
                <Text fontSize="0.875rem">Patient email:</Text>
              </Flex>

              <Flex w="100%" flexDir="column">
                <Text fontSize="0.875rem" fontWeight="600">
                  {patientdata && patientdata.patientname?patientdata.patientname:"-"}
                </Text>
                <Text fontSize="0.875rem" fontWeight="600">
                {patientdata && patientdata.mobileno?patientdata.mobileno:"-"}
                </Text>
                <Text fontSize="0.875rem" fontWeight="600">
                {patientdata && patientdata.email?patientdata.email:"-"}
                </Text>
              </Flex>
            </Box>

            <Spacer />

            <Box w="30%" display="flex">
              <Flex w="100%"  flexDir="column">
                <Text fontSize="0.875rem">Invoice:</Text>
                <Text fontSize="0.875rem">Date</Text>
                <Text fontSize="0.875rem">Preferred by:</Text>
              </Flex>

              <Flex w="100%" flexDir="column">
                <Text fontSize="0.875rem" fontWeight="600">
                {patientdata && patientdata.invoicenumber ? "#"+patientdata.invoicenumber : "-"}
                </Text>
                <Text fontSize="0.875rem" fontWeight="600">
                  {formatDate(today)}
                </Text>
                <Text fontSize="0.875rem" fontWeight="600">
                {patientdata && patientdata.doctorname?patientdata.doctorname:"-"}
                </Text>
              </Flex>
            </Box>
          </Flex>

          <Divider />

          <Box
          id="printableDiv2"
            w="100%"
            p="1rem 1.5rem"
            minH="calc(100vh - 340px)"
            maxH="calc(100vh - 340px)"
            overflow="hidden auto"
          >
            <SimpleTable
              isTh={false}
              // headers={TableHeader}
              isDataPresent={true}
              ThStyle={{ textAlign: "center", bg: "#FAFAFA" }}
              footerStyle={{
                pos: "sticky",
                bottom: "-1px",
                boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
              }}
              tableStyle={{
                tableLayout: "fixed",
                width: "100%",
                border: "1px solid #E6E7E9",
                borderBottom: "none",
              }}
              tableHeaders={
                <Tr>
                  <Th fontSize="0.87rem" fontWeight="500" pl="10px">
                    Lab name
                  </Th>
                 
                  <Th fontSize="0.87rem" fontWeight="500" pl="10px">
                    Amount ({currency})
                  </Th>
                  <Th fontSize="0.87rem" fontWeight="500" pl="10px" w='80px'>
                    <Flex w="100%" justifyContent="center" alignItems="center">
                      <PrimaryButton
                        btnIcon="/assets/svgs/add-white.svg"
                        iconSrc={true}
                        btnStyle={{ w: "25px", h: "25px", p: "0" }}
                        iconSrcStyle={{ mr: "0", w: "10px" }}
                        onClick={AddAnotherRow}
                      />
                    </Flex>
                  </Th>
                </Tr>
              }
              tableBody={
                <>
                  {rows.map((row, index) => (
                    <Tr w="100%" key={index}>
                      <Td p="0">
                        <Box w="100%" pos="relative" cursor="pointer">
                          <PrimaryInput
                            variant="bothSide"
                            inputPlace="Lab name*"
                            inputValue={row.labname}
                            onChange={(e) =>
                             handleLabNameChange(row.inventoryid,e.target.value, index)
                            }
                            //inputIdProp={"MedicineNameInAdd" + index}
                            inputIdProp={inputId == index ? "Active" : null}
                            boolProp={boolValForSuggestions}
                             onKeyDown={(e) => handleKeyDown(e, index)}
                            autocomplete="off"
                            inputCssStyle={{_hover:{border: "1px solid", borderRadius: "10px"}}}
                            maxLength={50}
                          />
                          <Image
                            top="33%"
                            right="6.5%"
                            w="15px"
                            pos="absolute"
                            src="/assets/imgs/search.png"
                          />
                        </Box>
                        {showLabNamesDropdown === index && suggestions.length > 0 && (
                          <Menu isOpen={true}>
                            <MenuButton
                              as={Box}
                              w="100%"
                              display="block"
                              pos="relative"
                              zIndex="999"
                            >
                              <Box
                                pos="absolute"
                                top="100%"
                                left="0"
                                w="100%"
                                bg="white"
                                borderTop="none"
                                border="1px solid #E6E7E9"
                                borderRadius="0 0 12px 12px"
                              />
                            </MenuButton>
                            <MenuList
                              w="100%"
                              borderRadius="0 0 12px 12px"
                              zIndex="999"
                              bg="white"
                              maxH="150px"
                              overflow="hidden auto"
                              ref={suggestionsMenuRef}
                            >
                              {suggestions.map((suggestion, sIndex) => (
                                <MenuItem
                                  key={sIndex}
                                  onClick={() => handleSelect(suggestion, index)}
                                  cursor="pointer"
                                  p="1rem"
                                  py="0.5rem"
                                  borderBottom="1px solid #E6E7E9"
                                  bg={focusedItemIndex === sIndex ? "#f0f0f0" : "white"}
                                >
                                  <Text fontSize="0.875rem" fontWeight="500">
                                    {suggestion.subtype}
                                  </Text>
                                </MenuItem>
                              ))}
                            </MenuList>
                          </Menu>
                        )}
                      </Td>
                      <Td p="0">
                        <PrimaryInput
                          variant="bothSide"
                          inputPlace={`Total Amount (${currency})`}
                          inputValue={row.totalamount}
                          onChange={(e) =>
                            handleTotalAmountChange(e.target.value, index)
                          }
                          maxLength={7}
                          inputCssStyle={{_hover:{border: "1px solid", borderRadius: "10px"}}}
                          isDisabled={true}
                        />
                      </Td>

                      <Td p="0">
                        <Box
                          as="button"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          w="100%"
                          onClick={() => confirmDeleteRow(index)}
                        >
                          <Image src="/assets/svgs/delete.svg" opacity="0.7" />
                        </Box>
                      </Td>
                    </Tr>
                  ))}
                </>
              }
            />
          </Box>

          <Box
            w="100%"
            px="1.5rem"
            boxShadow="rgba(0, 0, 0, 0.15) 0px -10px 11px -8px"
            zIndex="99"
          >
            {/* Footer area start */}
            <Box w="100%" display="flex" pt="1rem">
              <Spacer />

              <Box w="19%" display="flex" gap="2rem" id="printableDiv3">
                <Flex w="75px" flexDir="column" alignItems="start">
                  <Text fontSize="0.875rem" className="textOverflow">
                    Total Amount
                  </Text>
                  <Text fontSize="0.875rem">SGST</Text>
                  <Text fontSize="0.875rem">CGST</Text>
                  <Text fontSize="0.875rem">Total:</Text>
                </Flex>

                <Flex w="75px" flexDir="column" alignItems="start">
                  <Text fontSize="0.875rem" fontWeight="600" whiteSpace='nowrap'>
                    {calculateTotalAmountSumForDisplay()}
                  </Text>
                  <Text fontSize="0.875rem" fontWeight="600" whiteSpace='nowrap'>
                    {calculateTotalSgstSumForDisplay()}
                  </Text>
                  <Text fontSize="0.875rem" fontWeight="600" whiteSpace='nowrap'>
                    {calculateTotalCgstSumForDisplay()}
                  </Text>
                  <Text fontSize="0.875rem" fontWeight="600" whiteSpace='nowrap'>
                    {calculateGrandTotalForDisplay()}
                  </Text>
                </Flex>
              </Box>
            </Box>
            {/* Footer area end */}

            {/* Generate invoice buttons start */}
            <Flex w="100%" mt="1rem" className="no-print">
              <Spacer />

              <Box w="30%" display="flex" gap="1rem" justifyContent="end">
                <PrimaryButton
                  buttonText="Proforma invoice"
                  variant="grayBtn"
                  btnStyle={{ color: COLORS.PRIMARY_COLOR }}
                  onClick={handlePrint}
                />
                <PrimaryButton buttonText="Generate invoice" variant="mdBtn" onClick={()=> generatepopup.current.openModal()} />
              </Box>
            </Flex>
            {/* Generate invoice buttons end */}
          </Box>
        </Box>
      </Flex>
      {/* For Print */}
      {isprintclicked && 
      <Flex width="215mm" height="297mm" id="printinvoice" ref={printRef} direction="column">
      {/* Blue Strip */}
     <Box
       mt={"0px"}
       width={"100%"}
       top={0}
       left={0}
       right={0}
       height="8px"
       bg="blue.600"
       zIndex={1}
     />
     
     <Box w="100%" display="flex" flexDir="column" height="100%">
     <Box bg={"#fafafa"} p='0.75rem' display='flex' justifyContent='' gap='1.5rem' w='100%'>
       {/* Header */}
       <Box w='130%'>
       <HStack>
         <VStack align="flex-start" spacing={1}>
           <Text fontWeight="bold" fontSize="xl">
           {hospitalname ? hospitalname: "-"}
           </Text>
         </VStack>
            
       </HStack>

       {/* Contact Information */}
       <HStack justify="flex-start" mt={1}>
         <HStack>
           <Image src="assets/svgs/call.svg" alt="Call" />
           <Text fontSize={"xs"}>
           {getMobile()}
           </Text>
         </HStack>
         <HStack>
           <Image src="assets/svgs/smallLinePhoneMail.svg" alt="Divider" />
           <Image src="assets/svgs/mail-gray.svg" alt="Mail" />
           <Text fontSize={"xs"}>{getEmail()}</Text>
         </HStack>
       </HStack>

       {/* Hospital Address */}
       <HStack justify="flex-start" mt={1}>
         <Image src="assets/svgs/hospital-location-on.svg" alt="Location" />
         <Text fontSize={"xs"}>
         {Address && Address.length > 0 ? 
              (Address[0].address1 ? Address[0].address1 : "") + ", " +
              (Address[0].address2 ? Address[0].address2 : "") + ", " +
              (Address[0].city ? Address[0].city : "") + ", " +
              (Address[0].state ? Address[0].state : "") + ", " +
              (Address[0].postalcode ? Address[0].postalcode : "") + ", " +
              (Address[0].country ? Address[0].country : "") 
              : "-"
            }         </Text>
       </HStack>
       </Box>

       <Box w='30%' mt='-1rem'>
       {hospitallogo != "" && (
          <Image
            src={hospitallogo}
            height="auto"
            w='100px'
            mr={"36px"}
          />
        )}
       </Box>
     </Box>
     <Divider my={scaleOut ? 1 : 4} />
       {/* Header Section */}
       <Box w="100%" display="flex" alignItems="flex-start" justifyContent="center">
         <Box w="100%" p="1rem">
           <Text fontSize="1.5rem" textAlign="center" fontWeight="700">
             Invoice
           </Text>
         </Box>
       </Box>
       <VStack align="flex-start" mt={scaleOut ? "1px" : "4px"}>

       <Flex w="100%" p="1rem">
            <Box w="50%" gap='1rem' display="flex" justifyContent='normal'>
              <Flex flexDir="column">
                <Text fontSize="0.875rem">Patient Name:</Text>
                <Text fontSize="0.875rem">Patient Mobile:</Text>
                <Text fontSize="0.875rem">Doctor Name:</Text>
              </Flex>

              <Flex flexDir="column">
                <Text fontSize="0.875rem" fontWeight="600">
                {patientdata && patientdata.patientname?patientdata.patientname:"-"}
                </Text>
                <Text fontSize="0.875rem" fontWeight="600">
                {patientdata && patientdata.mobileno?patientdata.mobileno:"-"}
                </Text>
                <Text fontSize="0.875rem" fontWeight="600">
                {patientdata && patientdata.doctorname?patientdata.doctorname:"-"}
                </Text>
              </Flex>
            </Box>

            {/* <Spacer /> */}

            <Box w="50%" gap='1rem' display="flex">
              <Flex flexDir="column">
                <Text fontSize="0.875rem">Invoice Number:</Text>
                <Text fontSize="0.875rem">Date</Text>
                {/* <Text fontSize="0.875rem">Preferred by:</Text> */}
              </Flex>

              <Flex flexDir="column">
                <Text fontSize="0.875rem" fontWeight="600">
                {patientdata && patientdata.invoicenumber ? "#"+patientdata.invoicenumber : "-"}
                </Text>
                <Text fontSize="0.875rem" fontWeight="600">
                  {formatDate(today)}
                </Text>
                {/* <Text fontSize="0.875rem" fontWeight="600">

                </Text> */}
              </Flex>
            </Box>
        </Flex>


        <Divider my={scaleOut ? 1 : 4} />
      </VStack>
   
       {/* Content Section (Table) */}
       <Box w="93%" p="0.5rem 0.5rem" flex="1 0 auto" border="1px solid #E6E7E9" borderRadius="4px">
        
              <SimpleTable
                isTh={false}
                // headers={TableHeader}
                isDataPresent={true}
                ThStyle={{ textAlign: "center",
                   bg: "#FAFAFA" ,
                  border: "1px solid #E6E7E9",
              padding: "4px 8px", // Adjusted padding for smaller header rows
              fontSize: "0.8rem", // Reduced font size for headers
                }}
                footerStyle={{
                  pos: "sticky",
                  bottom: "-1px",
                  boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
                }}
                tableStyle={{
                  tableLayout: "fixed",
                  width: "100%",
                  border: "1px solid #E6E7E9",
                  borderBottom: "collapse",
                }}
                tableHeaders={
                  <Tr>
                    <Th fontSize="0.8rem" fontWeight="500" pl="8px" pr="8px">
                      Lab name
                    </Th>

                    <Th fontSize="0.8rem" fontWeight="500" pl="8px" pr="8px">
                      Amount  ({currency})
                    </Th>
                  </Tr>
                }
                tableBody={
                  <>
                    {rows.map((row, index) => (
                      <Tr w="100%" key={index}>
                        <Td border="1px solid #E6E7E9" padding="4px 8px">
                          <Text
                            w="100%"
                            fontSize="0.8rem"
                            pos="relative"
                            cursor="pointer"
                          >
                            {row.labname ? row.labname : "-"}
                          </Text>
                        </Td>
                        <Td border="1px solid #E6E7E9" padding="4px 8px">
                          <Text
                            w="100%"
                            fontSize="0.8rem"
                            pos="relative"
                            cursor="pointer"
                          >
                            {row.totalamount ?currency+ " " + row.totalamount : "-"}
                          </Text>
                        </Td>
                      </Tr>
                    ))}
                  </>
                }
              />  
      </Box>

     
       {/* Footer Section */}
       <Divider />
       
       <Box w="100%" display="flex" p="3rem 5rem" justifyContent="end">
         <Box w="100%" display="flex" gap="2rem" justifyContent="end">
           <Flex w="75px" flexDir="column" alignItems="start">
             <Text fontSize="0.875rem" className="textOverflow">Total Amount</Text>
             <Text fontSize="0.875rem">SGST</Text>
             <Text fontSize="0.875rem">CGST</Text>
             <Text fontSize="0.875rem">Total:</Text>
           </Flex>
   
           <Flex w="75px" flexDir="column" alignItems="start">
             <Text fontSize="0.875rem" fontWeight="600" whiteSpace="nowrap">
               {calculateTotalAmountSumForDisplay()}
             </Text>
             <Text fontSize="0.875rem" fontWeight="600" whiteSpace="nowrap">
               {calculateTotalSgstSumForDisplay()}
             </Text>
             <Text fontSize="0.875rem" fontWeight="600" whiteSpace="nowrap">
               {calculateTotalCgstSumForDisplay()}
             </Text>
             <Text fontSize="0.875rem" fontWeight="600" whiteSpace="nowrap">
               {calculateGrandTotalForDisplay()}
             </Text>
           </Flex>
         </Box>
       </Box>
     </Box>
   </Flex>
      }
      <DynamicModal
        ref={deleteRowModal}
        modalHeader="Delete row"
        modalBody={
          <>
            <Text fontSize="14px" fontWeight="600">
              Are you sure you want to delete?
            </Text>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="deleteBtn"
              buttonText="Delete"
              onClick={deleteRowHandler}
            />
          </>
        }
      />
       <DynamicModal
        ref={generatepopup}
        modalHeader="Generate Invoice"
        modalBody={
          <>
            <Text fontSize="14px" fontWeight="600">
              Are you sure you want to generate invoice?
            </Text>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              // variant="deleteBtn"
              buttonText="Generate"
              onClick={prepareInvoiceInput}
            />
          </>
        }
      />
    </>
  );
};

export default EditLabOrder;
