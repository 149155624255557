import formAPIpatientturnup from "./patientturnup";

const formAPIModelpatientturnup = (data, type, activeTab,selecteddate) => {
  const groupedData = {};

  data.forEach(item => {
    const formattedItem = formAPIpatientturnup(item, type, activeTab,selecteddate);
    const { label, patients } = formattedItem;

    if (!groupedData[label]) {
      groupedData[label] = 0;
    }

    groupedData[label] += patients;
  });

  const formattedOutput = Object.keys(groupedData).map(label => ({
    label,
    patients: groupedData[label],
  }));

  return formattedOutput;
};

export default formAPIModelpatientturnup;
