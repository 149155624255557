import FetchData from "../../../client";

const updateDoctorNotes = async (inputData) => {
  try {
    const result = await FetchData("midoc_updatedoctornotes", inputData);
    // console.log(result, "updated");
    return result;
  } catch (error) {
    console.error("Error in Update Doctor Notes:", error);
    throw error;
  }
};

const updateAIRAChatNotes = async (inputData) => {
  try {
    const result = await FetchData("midoc_savedoctorandpatientchat", inputData);
    // console.log(result, "updated");
    return result;
  } catch (error) {
    console.error("Error in Update AIRA Chat Doctor Notes:", error);
    throw error;
  }
};


const doctorUpdatePatientMedicine = async (inputData) => {
  try {
    const result = await FetchData("midoc_updatepatientmedicinebydoctor", inputData);
    // console.log(result, "updated");
    return result;
  } catch (error) {
    console.error("Error in Update Doctor Notes:", error);
    throw error;
  }
};

const GetDoctorNotes = async (inputData) => {
  try {
    const result = await FetchData("midoc_getdoctornotes", inputData);
    // console.log(result, "updated");
    return result;
  } catch (error) {
    console.error("Error in Get Doctor Notes:", error);
    throw error;
  }
};

const updateTranscriptNotes = async (inputData) => {
  try {
    const result = await FetchData("midoc_patientupdateappointment", inputData);
    // console.log(result, "updated");
    return result;
  } catch (error) {
    console.error("Error in Get Doctor Notes:", error);
    throw error;
  }
};

const GetDoctorMasterData = async (inputData) => {
  try {
    //inputData.doctorid = getLoginID();
    const result = await FetchData("midoc_getdefaultdata", inputData);
    // console.log(inputData);
    // console.log(result, "db response");

    let finalOutput = null;
    if (result.output.data) {
      finalOutput = result.output.data;
    }
    return finalOutput;
  } catch (error) {
    console.error("Error fetching data in forms.js:", error);
    throw error;
  }
};

const updateOrderDoctorNotes = async (inputData) => {
  try {
    const result = await FetchData("midoc_updateorderforpatient", inputData);
    // console.log(result, "updated");
    return result;
  } catch (error) {
    console.error("Error in Update Order - Doctor Notes:", error);
    throw error;
  }
};

const DeleteOrder = async (inputData) => {
  try {
    //inputData.doctorid = getLoginID();
    const result = await FetchData("midoc_deleteorderofpatient", inputData);
    // console.log(inputData);
    // console.log(result, "db response");

    return result;
  } catch (error) {
    console.error("Error fetching data in forms.js:", error);
    throw error;
  }
};

const GetDoctorFavMedicine = async (inputData) => {
  try {
    //inputData.doctorid = getLoginID();
    const result = await FetchData("midoc_getdoctorfavmedicine", inputData);
    // console.log(inputData);
    // console.log(result, "db response");
    return result;
  } catch (error) {
    console.error("Error fetching data in forms.js:", error);
    throw error;
  }
};

const GetAIMedicineComposition = async (inputData) => {
  try {
    //inputData.doctorid = getLoginID();
    const result = await FetchData("midoc_ai_medicinebyname", inputData);
    // console.log(inputData);
    // console.log(result, "db response");
    return result;
  } catch (error) {
    console.error("Error fetching data in Fetch composition:", error);
    throw error;
  }
};

const GetDoctorSlots = async (inputData) => {
  try {
    //inputData.doctorid = getLoginID();
    const result = await FetchData("midoc_getdoctorslot", inputData);
    // console.log(inputData);
    // console.log(result, "db response");
    return result;
  } catch (error) {
    console.error("Error fetching data for doctor slots:", error);
    throw error;
  }
};

const CreateNewVisit = async (inputData) => {
  try {
    //inputData.doctorid = getLoginID();
    const result = await FetchData("midoc_adddoctornewvisit", inputData);
    // console.log(inputData);
    // console.log(result, "db response");
    return result;
  } catch (error) {
    console.error("Error saving data for create new visit:", error);
    throw error;
  }
};

const GetPatientStatus = async (inputData) => {
  try {
    //inputData.doctorid = getLoginID();
    const result = await FetchData("midoc_getpatientdetails", inputData);
    // console.log(inputData);
    // console.log(result, "db response");
    return result;
  } catch (error) {
    console.error("Error fetching data for patient status:", error);
    throw error;
  }
};

const UploadPrescription = async (inputData) => {
  try {
    //inputData.doctorid = getLoginID();
    const result = await FetchData("midoc_doctoruploadprescription", inputData);
    // console.log(inputData);
    // console.log(result, "db response");
    return result;
  } catch (error) {
    console.error("Error saving prescriptions:", error);
    throw error;
  }
};

export {
  updateDoctorNotes,
  doctorUpdatePatientMedicine,
  GetDoctorMasterData,
  GetDoctorNotes,
  DeleteOrder,
  updateOrderDoctorNotes,
  GetDoctorFavMedicine,
  GetAIMedicineComposition,
  GetDoctorSlots,
  CreateNewVisit,
  UploadPrescription,
  GetPatientStatus,
  updateTranscriptNotes,
  updateAIRAChatNotes
};
