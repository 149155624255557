export default function processAllergyData(allergies, visitDate) {
  if (!Array.isArray(allergies)) {
    return [];
  }

  const filteredData = visitDate
    ? allergies.filter(
        (allergy) =>
          allergy.allergyidentifieddate.split("T")[0] ===
          visitDate.split("T")[0]
      )
    : allergies;

  const processedData = filteredData.map((allergy) => {
    let attachmentCounter = 1;

    return {
      patientid: allergy.Patientid,
      allergyFrom: allergy.allergytype,
      allergytype: allergy.allergytype,
      allergytype_cd: allergy.allergytype_cd,
      problems: allergy.reaction
        ? allergy.reaction.map((reaction) => reaction.reaction).join(", ")
        : " ",
      reaction: allergy.reaction || [],
      onSet: allergy.allergyidentifieddate,
      allergyidentifieddate: allergy.allergyidentifieddate,
      id: allergy.patientallergyid,
      patientallergyid: allergy.patientallergyid,
      attachments: (allergy.documents || []).map((document) => {
        const extension = document?.documenturl?.split(".").pop();
        const name = `Allergy-${attachmentCounter++}.${extension}`;

        return {
          link: document?.imagepath + document?.documenturl,
          name: name,
        };
      }),
    };
  });

  // Sort the processed data by onSet date in reverse order (descending)
  processedData.sort((a, b) => new Date(b.onSet) - new Date(a.onSet));

  return processedData;
}
