import moment from "moment";
export default function formAPIlabs({
  verifieddate,
  reportname,
  reportdescription,
  documenturl,
  imagepath,
  documents,
  reportid
})
{
  return Object.freeze({
    reportname,
  reportdescription,
  documenturl,
    verifieddate : verifieddate,
    imagepath,
    documents,
    
  reportid
   
  });
}