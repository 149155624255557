import moment from "moment";
export default function formAPIphysicalexam({
    DATE,
    physicalexam,
    type,
    specialization,
    bookingid,
    doctorname

})
{
  return Object.freeze({
    // Date: Date,
    DATE,
    physicalexam,
    type,
    specialization,
    bookingid,
    doctorname
   
  });
}