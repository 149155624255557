const isMobileOrTablet = false;
export default {
  SAMPLING_RATE: 16000,
  DEFAULT_AUDIO_URL: `https://huggingface.co/datasets/Xenova/transformers.js-docs/resolve/main/${
    isMobileOrTablet ? "jfk" : "ted_60_16k"
  }.wav`,
  //   DEFAULT_MODEL: "Xenova/whisper-tiny",
  DEFAULT_MODEL: isMobileOrTablet
    ? "onnx-community/whisper-tiny"
    : "onnx-community/whisper-base",
  DEFAULT_SUBTASK: "transcribe",
  DEFAULT_LANGUAGE: "english",
  //   DEFAULT_QUANTIZED: isMobileOrTablet, // opted with whisper-tiny
  DEFAULT_QUANTIZED: true,
  DEFAULT_MULTILINGUAL: false,
};
