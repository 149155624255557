import moment from "moment";
export default function formAPIPreviousvisits({
  specality,
  chiefcomplaint,
  appointmentid,
  appointmentdate,
  bookingid,
  doctorname
})
{
  return Object.freeze({
    specality,
    chiefcomplaint,
    appointmentid,
    appointmentdate,
    bookingid,
    doctorname
   
  });
}