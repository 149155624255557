import {
    Box,
    Divider,
    Flex,
    Image,
    SimpleGrid,
    Step,
    StepDescription,
    StepIndicator,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    Text,
  } from "@chakra-ui/react";
  import React, { useEffect, useState } from "react";
  import { FetchOrderDetails } from "../../middleware/services/messages";
  import moment from "moment";
  import useLoader from "../../components/loader/loaderstates";
  import Loader from "../../components/loader/loader";
  import { Navigate, useLocation, useNavigate } from "react-router-dom";
  import DoctorCard from "../../components/doctor-card";
import { Global, css } from "@emotion/react";

  
import { downloadDocument, formatDate_Do_MMM_YYYY, useScreenType } from "../../auth";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
  
  
  const OrdersIndex = ({id, backFunction}) => {
  
    const location = useLocation();
    const [patientid, setPatientid] = useState("");
    const [ordersData, setOrdersData] = useState(null);
    const { isLoading, showLoader, dismissLoader } = useLoader();
    const [activeTab, setActiveTab] = useState("prescriptions");
    const [showNoData, setShowNoData] = useState(false);
    const screenType = useScreenType()

  let navigate = useNavigate();


  
  
    useEffect(() => {
      const currentUrl = window.location.href;
      const searchParams = new URLSearchParams(currentUrl.split('?') [1]);
      const id = searchParams.get('id');
      setPatientid(id);
      
      fetchordersdata(id)   
    }, []);
    
    const fetchordersdata = async (id) => {
      showLoader()
          try {
            const result = await FetchOrderDetails({
              patientid: id,
            });
  
            if (result != null) {
              setOrdersData(result);
            } else {
            }
            dismissLoader()
        } catch (error) {
            console.error("Error in home:", error);
          }
    };

    // useEffect(() => {
    //     fetchordersdata().then(data => {
    //       setOrdersData(data);
    //     }).catch(error => {
    //       console.error("Error fetching orders data:", error);
    //       setOrdersData([]); 
    //     });
    //   }, []);
      
  

        const formatDate = (dateString) => {
          if (!dateString) return "-";
          return moment(dateString).format("Do MMM, YYYY");
        };
  
        // console.log(patientid, "ordersData IDDDDD")
        // console.log(ordersData, "ordersData Dataaaaaaa")


        useEffect(() => {
          if (ordersData) {
            switch (activeTab) {
              case "prescriptions":
                setShowNoData(!ordersData.medication || ordersData.medication.length === 0);
                break;
              case "labs":
                setShowNoData(!ordersData.labs || ordersData.labs.length === 0);
                break;
                case "imaging":
                  setShowNoData(!ordersData.imaging || ordersData.imaging.length === 0);
                  break;
              case "procedures":
                setShowNoData(!ordersData.procedures || ordersData.procedures.length === 0);
                break;
              case "consultations":
                setShowNoData(!ordersData.consultations || ordersData.consultations.length === 0);
                break;
              case "followup":
                setShowNoData(!ordersData.followup || ordersData.followup.length === 0);
                break;
              default:
                setShowNoData(true);
            }
          } else {
            setShowNoData(true);
          }
        }, [activeTab, ordersData]);
          
    return (
      <>
      <Global
        styles={css`
          .activeTab .whiteBorder::after {
            content: '';
            position: absolute;
            left: 0%;
            transform: translateX(-20%);
            transform: translateY(440%);
            border-radius: 5px 5px 0 0;
            top: 120%;
            background: #0B1F4F;
            height: 3px;
            width: 100%;
            z-index: 1;
          }
        `}
      />
        {isLoading && <Loader />}
        {screenType == 'web' ? (
          <Box w="100%">
              <Box h="calc(100vh - 140px)" overflow="hidden auto">
              <Flex>
              <Flex bg="#fcfcfd" flexDirection="column" w="100%">
                {/* Tab buttons start */}
                <Box display='flex' gap="1rem" borderBottom="1px solid #E6E7E9" bg="white" p="1.25rem 1.5rem">
                  <Box as="button" display="flex" justifyContent="center" alignItems="center" pos="relative" className={activeTab == "prescriptions" ? "activeTab" : ""} fontSize="0.87rem" fontWeight="600" onClick={() => setActiveTab('prescriptions')} color={activeTab == "prescriptions" ? "#061027" : "#898D97"}><Text>Prescriptions</Text><Box as="span" className="whiteBorder"/></Box>
                  <Box as="button" display="flex" justifyContent="center" alignItems="center" pos="relative" className={activeTab == "labs" ? "activeTab" : ""} fontSize="0.87rem" fontWeight="600" onClick={() => setActiveTab('labs')} color={activeTab == "labs" ? "#061027" : "#898D97"}><Text>Labs</Text><Box as="span" className="whiteBorder"/></Box>
                  <Box as="button" display="flex" justifyContent="center" alignItems="center" pos="relative" className={activeTab == "imaging" ? "activeTab" : ""} fontSize="0.87rem" fontWeight="600" onClick={() => setActiveTab('imaging')} color={activeTab == "imaging" ? "#061027" : "#898D97"}><Text>Imaging</Text><Box as="span" className="whiteBorder"/></Box>
                  <Box as="button" display="flex" justifyContent="center" alignItems="center" pos="relative" className={activeTab == "procedures" ? "activeTab" : ""} fontSize="0.87rem" fontWeight="600" onClick={() => setActiveTab('procedures')} color={activeTab == "procedures" ? "#061027" : "#898D97"}><Text>Procedures</Text><Box as="span" className="whiteBorder"/></Box>
                  <Box as="button" display="flex" justifyContent="center" alignItems="center" pos="relative" className={activeTab == "consultations" ? "activeTab" : ""} fontSize="0.87rem" fontWeight="600" onClick={() => setActiveTab('consultations')} color={activeTab == "consultations" ? "#061027" : "#898D97"}><Text>Consultations</Text><Box as="span" className="whiteBorder"/></Box>
                  <Box as="button" display="flex" justifyContent="center" alignItems="center" pos="relative" className={activeTab == "followup" ? "activeTab" : ""} fontSize="0.87rem" fontWeight="600" onClick={() => setActiveTab('followup')} color={activeTab == "followup" ? "#061027" : "#898D97"}><Text>Followups</Text><Box as="span" className="whiteBorder"/></Box>
                </Box>
                {/* Tabs button end */}

                <Box w="100%" p="1.5rem">
                  <SimpleGrid columns={{ sm: 1,md: 1, lg: 2, xl: 2, '2xl': 3 }} spacing="0.5rem">
                    {activeTab == "prescriptions" && (
                      ordersData && ordersData.medication ?(  
                        <>
                        { ordersData.medication.map((item , index)=>
                        <DoctorCard
                        key={index}
                        hasFooter={false}
                        cardBody={
                          <>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              w="100%"
                              alignItems="flex-start"
                            >
                              <Box display="flex" gap="0.75rem">
                                <Box>
                                  <Text
                                  className="textOverflow" w={{md: "80px", lg: "130px"}}
                                    fontSize="0.625rem"
                                    fontWeight="500"
                                    color="#384052"
                                    title={item.specialization}
                                  >
                                    {item.specialization}
                                  </Text>
                                  <Text
                                    fontSize="0.875rem"
                                    fontWeight="600"
                                    color="#061027"
                                    title={item.doctorname}
                                  >
                                    {item.doctorname}
                                  </Text>
                                </Box>
                              </Box>
                              <Text
                                fontSize="0.75rem"
                                fontWeight="500"
                                color="#384052"
                                textAlign='right'
                              >
                                {formatDate_Do_MMM_YYYY(item.prescribeddate)}
                              </Text>
                            </Box>
  
                            <Divider my="0.75rem" />
                            <Flex gap="8px" align="center">
                              <Text fontSize="12px" fontWeight="500">
                                {item.bookingid ? "#"+item.bookingid : "-"}
                              </Text>
                            </Flex>
  
                            <Box w="100%">
                              <Box
                                as="button"
                                onClick={()=>downloadDocument(item.imagepath + item.documents, "Order")}

                                w="100%"
                                border="1px solid #E6E7E9"
                                p="10px"
                                borderRadius="10px"
                                mt="15px"
                                height="50px"
                                display="flex"
                                alignItems="center"
                                justifyContent="center">
                                <Box
                                  display="flex"
                                  fontSize="12px"
                                  flexDirection="column"
                                  justifyContent="center"
                                  fontWeight="500"
                                >
                                  Print Order
                                
                                </Box>
                                {/* <Box borderRadius="full" p="10px">
                                  <Image w="8px" src="/assets/imgs/right-icon.png" />
                                </Box> */}
                              </Box>
                            </Box>
                          </>
                        }
                      />
                        )}
                        </>
                      
                      ):(
                        null
                      )
                        
                      
                    )}
                    {activeTab == "labs" && (
                      ordersData && ordersData.labs ? (
                          ordersData.labs.map((item , index)=>
                          <DoctorCard
                        hasFooter={false}
                        cardBody={
                          <>
                            <Box
                            key={index}
                              display="flex"
                              justifyContent="space-between"
                              w="100%"
                              alignItems="flex-start"
                            >
                              <Box display="flex" gap="0.75rem">
                                <Box>
                                  <Text
                                    fontSize="0.875rem"
                                    fontWeight="600"
                                    color="#061027"
                                  >
                                    {item.ordername}
                                  </Text>
                                  <Text
                                    fontSize="0.625rem"
                                    fontWeight="500"
                                    color="#384052"
                                  >
                                  {formatDate_Do_MMM_YYYY(item.orderdate)}
                                  </Text>
                                </Box>
                              </Box>
                            </Box>

                            <Divider my="0.75rem" />

                            <Box display='flex' flexDir="column">
                                  <Box display="flex" alignItems="center" gap="0.5rem">
                                    <Image w="12px" h="13px" src="/assets/svgs/stethoscope.svg" />
                                    <Text fontSize="0.75rem" fontWeight="500">{item.doctorname}</Text>
                                  </Box>
                                  <Box display="flex" alignItems="center" gap="0.5rem">
                                    <Image w="13px" h="10px" src="/assets/svgs/hospital.svg" />
                                    <Text fontSize="0.75rem" fontWeight="500">{item.hospital}</Text>
                                  </Box>
                                  <Box display="flex" alignItems="center" gap="0.5rem">
                                    <Image w="13px" h="10px" src="/assets/svgs/location-blue.svg" /> 
                                    <Text fontSize="0.75rem" fontWeight="500">{(item.address === null || item.address === '') && (item.city === null || item.city === '') && (item.country === null || item.country === '') ? '-' : `${item.address || ''}${item.address && item.city ? ', ' : ''}${item.city || ''}${(item.city && item.country) || (item.address && item.country) ? ', ' : ''}${item.country || ''}`}</Text>


                                  </Box>
                              </Box>

                            <Box w="100%">
                              {/* <Box
                                as="button"
                                // onClick={onClick}
                                w="100%"
                                border="1px solid #E6E7E9"
                                p="10px"
                                borderRadius="10px"
                                mt="15px"
                                height="50px"
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between">
                                <Box
                                  display="flex"
                                  fontSize="12px"
                                  flexDirection="column"
                                  justifyContent="center"
                                  fontWeight="500"
                                >
                                2 documents
                                </Box>
                                <Box borderRadius="full" p="10px">
                                  <Image w="8px" src="/assets/imgs/right-icon.png" />
                                </Box>
                              </Box> */}
                            </Box>
                          </>
                        }
                      />
                        )
                      ):(
                        null
                      )
                    
                    )}
                  {activeTab == "imaging" && (
                    ordersData && ordersData.imaging ? (
                      ordersData.imaging.map((item , index)=>
                        <DoctorCard
                      hasFooter={false}
                      cardBody={
                        <>
                          <Box
                          key={index}
                            display="flex"
                            justifyContent="space-between"
                            w="100%"
                            alignItems="flex-start"
                          >
                            <Box display="flex" gap="0.75rem">
                              <Box>
                                <Text
                                  fontSize="0.875rem"
                                  fontWeight="600"
                                  color="#061027"
                                >
                                  {item.ordername}
                                </Text>
                                <Text
                                  fontSize="0.625rem"
                                  fontWeight="500"
                                  color="#384052"
                                >
                                 {formatDate_Do_MMM_YYYY(item.orderdate)}
                                </Text>
                              </Box>
                            </Box>
                          </Box>

                          <Divider my="0.75rem" />

                          <Box display='flex' flexDir="column">
                                <Box display="flex" alignItems="center" gap="0.5rem">
                                  <Image w="12px" h="13px" src="/assets/svgs/stethoscope.svg" />
                                  <Text fontSize="0.75rem" fontWeight="500">{item.doctorname}</Text>
                                </Box>
                                <Box display="flex" alignItems="center" gap="0.5rem">
                                  <Image w="13px" h="10px" src="/assets/svgs/hospital.svg" />
                                  <Text fontSize="0.75rem" fontWeight="500">{item.hospital}</Text>
                                </Box>
                                <Box display="flex" alignItems="center" gap="0.5rem">
                                  <Image w="13px" h="10px" src="/assets/svgs/location-blue.svg" /> 
                                  <Text fontSize="0.75rem" fontWeight="500">{(item.address === null || item.address === '') && (item.city === null || item.city === '') && (item.country === null || item.country === '') ? '-' : `${item.address || ''}${item.address && item.city ? ', ' : ''}${item.city || ''}${(item.city && item.country) || (item.address && item.country) ? ', ' : ''}${item.country || ''}`}</Text>


                                </Box>
                            </Box>

                          <Box w="100%">
                            
                          </Box>
                        </>
                      }
                    />
                      )
                    ):(
                      null
                    )
                   
                  )}

                    {activeTab == "procedures" && (
                      ordersData && ordersData.procedures ? (
                          ordersData.procedures.map((item , index)=>
                          <DoctorCard
                        hasFooter={false}
                        cardBody={
                          <>
                            <Box
                            key={index}
                              display="flex"
                              justifyContent="space-between"
                              w="100%"
                              alignItems="flex-start"
                            >
                              <Box display="flex" gap="0.75rem">
                                <Box>
                                  <Text
                                    fontSize="0.875rem"
                                    fontWeight="600"
                                    color="#061027"
                                  >
                                    {item.ordername}
                                  </Text>
                                  <Text
                                    fontSize="0.625rem"
                                    fontWeight="500"
                                    color="#384052"
                                  >
                                    {formatDate_Do_MMM_YYYY(item.orderdate)}
                                  </Text>
                                </Box>
                              </Box>
                            </Box>

                            <Divider my="0.75rem" />

                            <Box display='flex' flexDir="column">
                                  <Box display="flex" alignItems="center" gap="0.5rem">
                                    <Image w="12px" h="13px" src="/assets/svgs/stethoscope.svg" />
                                    <Text fontSize="0.75rem" fontWeight="500">{item.doctorname}</Text>
                                  </Box>
                                  <Box display="flex" alignItems="center" gap="0.5rem">
                                    <Image w="13px" h="10px" src="/assets/svgs/hospital.svg" />
                                    <Text fontSize="0.75rem" fontWeight="500">{item.hospital}</Text>
                                  </Box>
                                  <Box display="flex" alignItems="center" gap="0.5rem">
                                    <Image w="13px" h="10px" src="/assets/svgs/location-blue.svg" />
                                    <Text fontSize="0.75rem" fontWeight="500">
                                    {(item.address === null || item.address === '') && (item.city === null || item.city === '') && (item.country === null || item.country === '') ? '-' : `${item.address || ''}${item.address && item.city ? ', ' : ''}${item.city || ''}${(item.city && item.country) || (item.address && item.country) ? ', ' : ''}${item.country || ''}`}


                                    </Text>
                                  </Box>
                            </Box>

                            <Box w="100%">
                              {/* <Box
                                as="button"
                                // onClick={onClick}
                                w="100%"
                                border="1px solid #E6E7E9"
                                p="10px"
                                borderRadius="10px"
                                mt="15px"
                                height="50px"
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between">
                                <Box
                                  display="flex"
                                  fontSize="12px"
                                  flexDirection="column"
                                  justifyContent="center"
                                  fontWeight="500"
                                >
                                2 documents
                                </Box>
                                <Box borderRadius="full" p="10px">
                                  <Image w="8px" src="/assets/imgs/right-icon.png" />
                                </Box>
                              </Box> */}
                            </Box>
                          </>
                        }
                      />
                        )
                      ):(
                        null
                      )
                    )}
                    {activeTab == "consultations" && (
                      ordersData && ordersData.consultations ? (
                          ordersData.consultations.map((item, index)=>
                          <DoctorCard
                        hasFooter={false}
                        cardBody={
                          <>
                            <Box
                            key={index}
                              display="flex"
                              justifyContent="space-between"
                              w="100%"
                              alignItems="flex-start"
                            >
                              <Box display="flex" gap="0.75rem">
                                <Box>
                                  <Text
                                  className="textOverflow" w={{md: "80px", lg: "130px"}}
                                    fontSize="0.625rem"
                                    fontWeight="500"
                                    color="#384052"
                                    title={item.specality}
                                  >
                                    {item.specality}
                                  </Text>
                                  <Text
                                    fontSize="0.875rem"
                                    fontWeight="600"
                                    color="#061027"
                                    title={item.doctorname}
                                  >
                                    {item.doctorname}
                                  </Text>
                                </Box>
                              </Box>
                              <Text
                                fontSize="0.75rem"
                                fontWeight="500"
                                color="#384052"
                                textAlign='right'
                              >
                                {formatDate_Do_MMM_YYYY(item.appointmentdate)}
                              </Text>
                            </Box>

                            <Divider my="0.75rem" />

                            <Box display='flex' flexDir="column">
                              <Box display="flex" alignItems="center" gap="0.5rem">
                                <Image w="12px" h="13px" src="/assets/svgs/stethoscope.svg" />
                                <Text fontSize="0.75rem" fontWeight="500">{item.doctorname}</Text>
                              </Box>
                              <Box display="flex" alignItems="center" gap="0.5rem">
                                <Image w="13px" h="10px" src="/assets/svgs/hospital.svg" />
                                <Text fontSize="0.75rem" fontWeight="500">{item.hospital}</Text>
                              </Box>
                            </Box>

                            <Box w="100%">
                              {/* <Box
                                as="button"
                                // onClick={onClick}
                                w="100%"
                                border="1px solid #E6E7E9"
                                p="10px"
                                borderRadius="10px"
                                mt="15px"
                                height="50px"
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between">
                                <Box
                                  display="flex"
                                  fontSize="12px"
                                  flexDirection="column"
                                  justifyContent="center"
                                  fontWeight="500"
                                >
                                2 documents
                                </Box>
                                <Box borderRadius="full" p="10px">
                                  <Image w="8px" src="/assets/imgs/right-icon.png" />
                                </Box>
                              </Box> */}
                            </Box>
                          </>
                        }
                      />
                        )
                      ):(
                        null
                      )
                      
                    )}
                    {activeTab == "followup" && (
                      ordersData && ordersData.followup ? (
                          ordersData.followup.map((item, index)=>
                          <DoctorCard 
                        // onClick={()=>navigate("/appointmentdetails", { state: { appointmentid: item.appointmentid }})}
                        onClick={()=> navigate(`/visitdetails?patientId=${patientid}&appointmentId=${item.appointmentid}`)}
                        cardFooterText="View appointment"
                        bodyStyle={{padding: {lg: "1rem",xl: "1.25rem"}}}
                        cardBody={<>
                          <Box key={index} display="flex" justifyContent="space-between" w="100%" alignItems="flex-start">
                            <Box display='flex' gap="0.75rem">
                              <Box boxSize={{lg: "2rem", xl: "2.375rem"}} borderRadius='full' overflow="hidden">
                                <Image src={item.doctorimage ? item.imagepath + item.doctorimage : "/assets/imgs/no-image.png"} />
                              </Box>
                              <Box>
                                <Text className="textOverflow" w={{md: "80px", lg: "100px"}} fontSize="0.625rem" fontWeight="500" color="#384052" title={item.specality}>{item.specality}</Text>
                                <Text fontSize={{lg: "0.65rem", xl: "0.875rem"}} fontWeight="600" color="#061027" title={item.doctorname}>{item.doctorname}</Text>
                              </Box>
                            </Box>
                            <Text fontSize={{lg: "0.65rem", xl: "0.75rem"}} fontWeight="500" color="#384052">
                              {formatDate_Do_MMM_YYYY(item.followupdate)}
                              </Text>
                          </Box>
                        </>} 
                      />
                        )
                      ):(
                        null
                      )
                  
                    )}
                    
                  </SimpleGrid>
                </Box>

                {showNoData  && (
                  <>
                  <Box
                  w="100%"
                  h="100vh"
                  display="flex"
                  flexDirection="column"
                  gap="5px"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Image w="60px" opacity="0.5" src="/assets/svgs/clinical_notes.svg" /> 
                  <Box as="p">No data available</Box>
                  
                    </Box>
                  </>

                )}

              </Flex>
              </Flex>
          </Box>
          </Box>
        ) : (   
        //Mobile responsive
          <Box w="100%">
            <MobileSecondaryNav
              header="Orders"
              handlebackarrow={backFunction}
            />

            {/* Tab buttons start */}
            <Box display='flex' gap="0.5rem" borderBottom="1px solid #E6E7E9" bg="white" p="1.25rem 1rem" justifyContent='space-between'>
              <Box as="button" display="flex" justifyContent="center" alignItems="center" pos="relative" className={activeTab == "prescriptions" ? "activeTab" : ""} fontSize="0.7rem" fontWeight="600" onClick={() => setActiveTab('prescriptions')} color={activeTab == "prescriptions" ? "#061027" : "#898D97"}><Text>Prescriptions</Text><Box as="span" className="whiteBorder"/></Box>
              <Box as="button" display="flex" justifyContent="center" alignItems="center" pos="relative" className={activeTab == "labs" ? "activeTab" : ""} fontSize="0.7rem" fontWeight="600" onClick={() => setActiveTab('labs')} color={activeTab == "labs" ? "#061027" : "#898D97"}><Text>Labs</Text><Box as="span" className="whiteBorder"/></Box>
              <Box as="button" display="flex" justifyContent="center" alignItems="center" pos="relative" className={activeTab == "imaging" ? "activeTab" : ""} fontSize="0.87rem" fontWeight="600" onClick={() => setActiveTab('imaging')} color={activeTab == "imaging" ? "#061027" : "#898D97"}><Text>Imaging</Text><Box as="span" className="whiteBorder"/></Box>
              <Box as="button" display="flex" justifyContent="center" alignItems="center" pos="relative" className={activeTab == "procedures" ? "activeTab" : ""} fontSize="0.7rem" fontWeight="600" onClick={() => setActiveTab('procedures')} color={activeTab == "procedures" ? "#061027" : "#898D97"}><Text>Procedures</Text><Box as="span" className="whiteBorder"/></Box>
              <Box as="button" display="flex" justifyContent="center" alignItems="center" pos="relative" className={activeTab == "consultations" ? "activeTab" : ""} fontSize="0.7rem" fontWeight="600" onClick={() => setActiveTab('consultations')} color={activeTab == "consultations" ? "#061027" : "#898D97"}><Text>Consultations</Text><Box as="span" className="whiteBorder"/></Box>
              <Box as="button" display="flex" justifyContent="center" alignItems="center" pos="relative" className={activeTab == "followup" ? "activeTab" : ""} fontSize="0.7rem" fontWeight="600" onClick={() => setActiveTab('followup')} color={activeTab == "followup" ? "#061027" : "#898D97"}><Text>Followups</Text><Box as="span" className="whiteBorder"/></Box>
            </Box>
            {/* Tabs button end */}

            <Box h="calc(100vh - 190px)" overflow="hidden auto">
              <Flex>
              <Flex bg="#fcfcfd" w="100%">

                <Box w="100%" p="1rem">
                  <SimpleGrid columns={1} spacing="1rem">
                    {activeTab == "prescriptions" && (
                      ordersData && ordersData.medication ?(  
                        <>
                        { ordersData.medication.map((item , index)=>
                        <DoctorCard
                        key={index}
                        hasFooter={false}
                        cardBody={
                          <>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              w="100%"
                              alignItems="flex-start"
                            >
                              <Box display="flex" gap="0.75rem">
                                <Box>
                                  <Text
                                  className="textOverflow" w="167px"
                                    fontSize="0.625rem"
                                    fontWeight="500"
                                    color="#384052"
                                    title={item.specialization}
                                  >
                                    {item.specialization}
                                  </Text>
                                  <Text
                                    fontSize="0.875rem"
                                    fontWeight="600"
                                    color="#061027"
                                    title={item.doctorname}
                                  >
                                    {item.doctorname}
                                  </Text>
                                </Box>
                              </Box>
                              <Text
                                fontSize="0.75rem"
                                fontWeight="500"
                                color="#384052"
                              >
                                {formatDate_Do_MMM_YYYY(item.prescribeddate)}
                              </Text>
                            </Box>
  
                            <Divider my="0.75rem" />
                            <Flex gap="8px" align="center">
                              <Text fontSize="12px" fontWeight="500">
                                {item.bookingid ? "#"+item.bookingid : "-"}
                              </Text>
                            </Flex>
  
                            <Box w="100%">
                              <Box
                                as="button"
                                onClick={()=>downloadDocument(item.imagepath + item.documents, "Order")}

                                w="100%"
                                border="1px solid #E6E7E9"
                                p="10px"
                                borderRadius="10px"
                                mt="15px"
                                height="50px"
                                display="flex"
                                alignItems="center"
                                justifyContent="center">
                                <Box
                                  display="flex"
                                  fontSize="12px"
                                  flexDirection="column"
                                  justifyContent="center"
                                  fontWeight="500"
                                >
                                  Print Order
                                
                                </Box>
                                {/* <Box borderRadius="full" p="10px">
                                  <Image w="8px" src="/assets/imgs/right-icon.png" />
                                </Box> */}
                              </Box>
                            </Box>
                          </>
                        }
                      />
                        )}
                        </>
                      
                      ):(
                        null
                      )
                        
                      
                    )}
                    {activeTab == "labs" && (
                      ordersData && ordersData.labs ? (
                          ordersData.labs.map((item , index)=>
                          <DoctorCard
                        hasFooter={false}
                        cardBody={
                          <>
                            <Box
                            key={index}
                              display="flex"
                              justifyContent="space-between"
                              w="100%"
                              alignItems="flex-start"
                            >
                              <Box display="flex" gap="0.75rem">
                                <Box>
                                  <Text
                                    fontSize="0.875rem"
                                    fontWeight="600"
                                    color="#061027"
                                  >
                                    {item.ordername}
                                  </Text>
                                  <Text
                                    fontSize="0.625rem"
                                    fontWeight="500"
                                    color="#384052"
                                  >
                                  {formatDate_Do_MMM_YYYY(item.orderdate)}
                                  </Text>
                                </Box>
                              </Box>
                            </Box>

                            <Divider my="0.75rem" />

                            <Box display='flex' flexDir="column">
                                  <Box display="flex" alignItems="center" gap="0.5rem">
                                    <Image w="12px" h="13px" src="/assets/svgs/stethoscope.svg" />
                                    <Text fontSize="0.75rem" fontWeight="500">{item.doctorname}</Text>
                                  </Box>
                                  <Box display="flex" alignItems="center" gap="0.5rem">
                                    <Image w="13px" h="10px" src="/assets/svgs/hospital.svg" />
                                    <Text fontSize="0.75rem" fontWeight="500">{item.hospital}</Text>
                                  </Box>
                                  <Box display="flex" alignItems="center" gap="0.5rem">
                                    <Image w="13px" h="10px" src="/assets/svgs/location-blue.svg" /> 
                                    <Text fontSize="0.75rem" fontWeight="500">{(item.address === null || item.address === '') && (item.city === null || item.city === '') && (item.country === null || item.country === '') ? '-' : `${item.address || ''}${item.address && item.city ? ', ' : ''}${item.city || ''}${(item.city && item.country) || (item.address && item.country) ? ', ' : ''}${item.country || ''}`}</Text>


                                  </Box>
                              </Box>

                            <Box w="100%">
                              {/* <Box
                                as="button"
                                // onClick={onClick}
                                w="100%"
                                border="1px solid #E6E7E9"
                                p="10px"
                                borderRadius="10px"
                                mt="15px"
                                height="50px"
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between">
                                <Box
                                  display="flex"
                                  fontSize="12px"
                                  flexDirection="column"
                                  justifyContent="center"
                                  fontWeight="500"
                                >
                                2 documents
                                </Box>
                                <Box borderRadius="full" p="10px">
                                  <Image w="8px" src="/assets/imgs/right-icon.png" />
                                </Box>
                              </Box> */}
                            </Box>
                          </>
                        }
                      />
                        )
                      ):(
                        null
                      )
                    
                    )}
                      {activeTab == "imaging" && (
                    ordersData && ordersData.imaging ? (
                      ordersData.imaging.map((item , index)=>
                        <DoctorCard
                      hasFooter={false}
                      cardBody={
                        <>
                          <Box
                          key={index}
                            display="flex"
                            justifyContent="space-between"
                            w="100%"
                            alignItems="flex-start"
                          >
                            <Box display="flex" gap="0.75rem">
                              <Box>
                                <Text
                                  fontSize="0.875rem"
                                  fontWeight="600"
                                  color="#061027"
                                >
                                  {item.ordername}
                                </Text>
                                <Text
                                  fontSize="0.625rem"
                                  fontWeight="500"
                                  color="#384052"
                                >
                                 {formatDate_Do_MMM_YYYY(item.orderdate)}
                                </Text>
                              </Box>
                            </Box>
                          </Box>

                          <Divider my="0.75rem" />

                          <Box display='flex' flexDir="column">
                                <Box display="flex" alignItems="center" gap="0.5rem">
                                  <Image w="12px" h="13px" src="/assets/svgs/stethoscope.svg" />
                                  <Text fontSize="0.75rem" fontWeight="500">{item.doctorname}</Text>
                                </Box>
                                <Box display="flex" alignItems="center" gap="0.5rem">
                                  <Image w="13px" h="10px" src="/assets/svgs/hospital.svg" />
                                  <Text fontSize="0.75rem" fontWeight="500">{item.hospital}</Text>
                                </Box>
                                <Box display="flex" alignItems="center" gap="0.5rem">
                                  <Image w="13px" h="10px" src="/assets/svgs/location-blue.svg" /> 
                                  <Text fontSize="0.75rem" fontWeight="500">{(item.address === null || item.address === '') && (item.city === null || item.city === '') && (item.country === null || item.country === '') ? '-' : `${item.address || ''}${item.address && item.city ? ', ' : ''}${item.city || ''}${(item.city && item.country) || (item.address && item.country) ? ', ' : ''}${item.country || ''}`}</Text>


                                </Box>
                            </Box>

                          <Box w="100%">
                            {/* <Box
                              as="button"
                              // onClick={onClick}
                              w="100%"
                              border="1px solid #E6E7E9"
                              p="10px"
                              borderRadius="10px"
                              mt="15px"
                              height="50px"
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between">
                              <Box
                                display="flex"
                                fontSize="12px"
                                flexDirection="column"
                                justifyContent="center"
                                fontWeight="500"
                              >
                              2 documents
                              </Box>
                              <Box borderRadius="full" p="10px">
                                <Image w="8px" src="/assets/imgs/right-icon.png" />
                              </Box>
                            </Box> */}
                          </Box>
                        </>
                      }
                    />
                      )
                    ):(
                      null
                    )
                   
                  )}

                    {activeTab == "procedures" && (
                      ordersData && ordersData.procedures ? (
                          ordersData.procedures.map((item , index)=>
                          <DoctorCard
                        hasFooter={false}
                        cardBody={
                          <>
                            <Box
                            key={index}
                              display="flex"
                              justifyContent="space-between"
                              w="100%"
                              alignItems="flex-start"
                            >
                              <Box display="flex" gap="0.75rem">
                                <Box>
                                  <Text
                                    fontSize="0.875rem"
                                    fontWeight="600"
                                    color="#061027"
                                  >
                                    {item.ordername}
                                  </Text>
                                  <Text
                                    fontSize="0.625rem"
                                    fontWeight="500"
                                    color="#384052"
                                  >
                                    {formatDate_Do_MMM_YYYY(item.orderdate)}
                                  </Text>
                                </Box>
                              </Box>
                            </Box>

                            <Divider my="0.75rem" />

                            <Box display='flex' flexDir="column">
                                  <Box display="flex" alignItems="center" gap="0.5rem">
                                    <Image w="12px" h="13px" src="/assets/svgs/stethoscope.svg" />
                                    <Text fontSize="0.75rem" fontWeight="500">{item.doctorname}</Text>
                                  </Box>
                                  <Box display="flex" alignItems="center" gap="0.5rem">
                                    <Image w="13px" h="10px" src="/assets/svgs/hospital.svg" />
                                    <Text fontSize="0.75rem" fontWeight="500">{item.hospital}</Text>
                                  </Box>
                                  <Box display="flex" alignItems="center" gap="0.5rem">
                                    <Image w="13px" h="10px" src="/assets/svgs/location-blue.svg" />
                                    <Text fontSize="0.75rem" fontWeight="500">
                                    {(item.address === null || item.address === '') && (item.city === null || item.city === '') && (item.country === null || item.country === '') ? '-' : `${item.address || ''}${item.address && item.city ? ', ' : ''}${item.city || ''}${(item.city && item.country) || (item.address && item.country) ? ', ' : ''}${item.country || ''}`}


                                    </Text>
                                  </Box>
                            </Box>

                            <Box w="100%">
                              {/* <Box
                                as="button"
                                // onClick={onClick}
                                w="100%"
                                border="1px solid #E6E7E9"
                                p="10px"
                                borderRadius="10px"
                                mt="15px"
                                height="50px"
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between">
                                <Box
                                  display="flex"
                                  fontSize="12px"
                                  flexDirection="column"
                                  justifyContent="center"
                                  fontWeight="500"
                                >
                                2 documents
                                </Box>
                                <Box borderRadius="full" p="10px">
                                  <Image w="8px" src="/assets/imgs/right-icon.png" />
                                </Box>
                              </Box> */}
                            </Box>
                          </>
                        }
                      />
                        )
                      ):(
                        null
                      )
                    )}
                    {activeTab == "consultations" && (
                      ordersData && ordersData.consultations ? (
                          ordersData.consultations.map((item, index)=>
                          <DoctorCard
                        hasFooter={false}
                        cardBody={
                          <>
                            <Box
                            key={index}
                              display="flex"
                              justifyContent="space-between"
                              w="100%"
                              alignItems="flex-start"
                            >
                              <Box display="flex" gap="0.75rem">
                                <Box>
                                  <Text
                                  className="textOverflow" w="167px"
                                    fontSize="0.625rem"
                                    fontWeight="500"
                                    color="#384052"
                                    title={item.specality}
                                  >
                                    {item.specality}
                                  </Text>
                                  <Text
                                    fontSize="0.875rem"
                                    fontWeight="600"
                                    color="#061027"
                                    title={item.doctorname}
                                  >
                                    {item.doctorname}
                                  </Text>
                                </Box>
                              </Box>
                              <Text
                                fontSize="0.75rem"
                                fontWeight="500"
                                color="#384052"
                              >
                                {formatDate_Do_MMM_YYYY(item.appointmentdate)}
                              </Text>
                            </Box>

                            <Divider my="0.75rem" />

                            <Box display='flex' flexDir="column">
                              <Box display="flex" alignItems="center" gap="0.5rem">
                                <Image w="12px" h="13px" src="/assets/svgs/stethoscope.svg" />
                                <Text fontSize="0.75rem" fontWeight="500">{item.doctorname}</Text>
                              </Box>
                              <Box display="flex" alignItems="center" gap="0.5rem">
                                <Image w="13px" h="10px" src="/assets/svgs/hospital.svg" />
                                <Text fontSize="0.75rem" fontWeight="500">{item.hospital}</Text>
                              </Box>
                            </Box>

                            <Box w="100%">
                              {/* <Box
                                as="button"
                                // onClick={onClick}
                                w="100%"
                                border="1px solid #E6E7E9"
                                p="10px"
                                borderRadius="10px"
                                mt="15px"
                                height="50px"
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between">
                                <Box
                                  display="flex"
                                  fontSize="12px"
                                  flexDirection="column"
                                  justifyContent="center"
                                  fontWeight="500"
                                >
                                2 documents
                                </Box>
                                <Box borderRadius="full" p="10px">
                                  <Image w="8px" src="/assets/imgs/right-icon.png" />
                                </Box>
                              </Box> */}
                            </Box>
                          </>
                        }
                      />
                        )
                      ):(
                        null
                      )
                      
                    )}
                    {activeTab == "followup" && (
                      ordersData && ordersData.followup ? (
                          ordersData.followup.map((item, index)=>
                          <DoctorCard 
                          onClick={()=> navigate(`/visitdetails?patientId=${patientid}&appointmentId=${item.appointmentid}`)}
                        cardFooterText="View appointment"
                        cardBody={<>
                          <Box key={index} display="flex" justifyContent="space-between" w="100%" alignItems="flex-start">
                            <Box display='flex' gap="0.75rem">
                              <Box boxSize="2.375rem" borderRadius='full' overflow="hidden">
                                <Image src={item.doctorimage ? item.imagepath + item.doctorimage : "/assets/imgs/no-image.png"} />
                              </Box>
                              <Box>
                                <Text className="textOverflow" w="100px" fontSize="0.625rem" fontWeight="500" color="#384052" title={item.specality}>{item.specality}</Text>
                                <Text fontSize="0.875rem" fontWeight="600" color="#061027" title={item.doctorname}>{item.doctorname}</Text>
                              </Box>
                            </Box>
                            <Text fontSize="0.75rem" fontWeight="500" color="#384052">
                              {formatDate_Do_MMM_YYYY(item.followupdate)}
                              </Text>
                          </Box>
                        </>} 
                      />
                        )
                      ):(
                        null
                      )
                  
                    )}
                    
                  </SimpleGrid>
                </Box>

                {showNoData  && (
                  <>
                  <Box
                  w="100%"
                  h="100vh"
                  display="flex"
                  flexDirection="column"
                  gap="5px"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Image w="60px" opacity="0.5" src="/assets/svgs/clinical_notes.svg" /> 
                  <Box as="p">No data available</Box>
                  
                    </Box>
                  </>

                )}

              </Flex>
              </Flex>
          </Box>
          </Box>
        )}
      </>
    );
  };
  
  export default OrdersIndex;
  