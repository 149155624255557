import React, { useState, useRef, useEffect } from "react";
import { formatDate } from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import "./style.css";
import PrimaryButton from "../primary-button";
import PrimaryButtonIcon from "../primary-button-icon";
import { COLORS } from "../styles/colors";
import { parseDateString } from "./helper.js";
import {
  Box,
  Divider,
  Flex,
  Image,
  Spacer,
  Stack,
  Text,
  VStack,
  InputGroup,
  Input,
  RadioGroup,
  Radio,
  CheckboxGroup,
  Button,
  SimpleGrid,
  IconButton,
  InputLeftAddon,
  Textarea,
} from "@chakra-ui/react";

import DynamicModal from "../../components/dynamic-modal";
import PrimaryInput from "../primary-input/index.js";
import DropdownInput from "../dropdown-input/index.js";
import DatePicker from "../date-picker/index.js";
import PrimaryInputDropdown from "../primary-input-dropdown/index.js";
import {
  CreateNewVisit,
  GetDoctorSlots,
  GetPatientStatus,
} from "../../middleware/services/visits/patient-overview/updatedoctornotes.js";
import {
  actionAccessMain,
  getCountryCodeFromCountriesList,
  formatToDecimal,getCurrency,getUserName,
  getIsDoctor,
  getLoginID,
  useScreenType,
} from "../../auth.js";
import moment from "moment";
import Toaster from "../toaster/index.js";


import { useNavigate, useLocation } from "react-router-dom";
import Loader from "../loader/loader.js";
import useLoader from "../loader/loaderstates.js";
import { UpdateAppointmentStatus } from "../../middleware/services/visits/patient-overview/index.js";
import { FetchDoctorsByUser } from "../../middleware/services/home/index.js";


export default function Calendar({
  eventsArray,
  setCalendarStartDate,
  setCalendarEndDate,
  onCalendarTitleChange,
  refreshPageContent,
}) {
  const { isLoading, showLoader, dismissLoader } = useLoader();

  const doctorId = getLoginID();

  const { showSuccessToast, showErrorToast } = Toaster();

  const [currentEvents, setCurrentEvents] = useState([]);
  const [calendarTitle, setCalendarTitle] = useState("");

  const [currentView, setCurrentView] = useState("dayGridMonth");
  const calendarRef = useRef(null);
  
  const screenType = useScreenType();
  
  const [actionAccess, setActionAccess] = useState(null);
  
  const [actionAccessAppointments, setActionAccessAppointments] = useState(null);

  // const newVisit = useRef();
  // const newVisitPatientStatus = useRef();

  // const [selectedCountry, setSelectedCountry] = useState("+1");
  // const [selectedCountryId, setSelectedCountryId] = useState("");
  // const handleCountryChange = (country) => {
  //   console.log(country.countryid);
  //   console.log(country.code);
  //   setSelectedCountry(country.code);
  //   setSelectedCountryId(country.countryid);
  // };

  // const [newVisitData, setNewVisitData] = useState({
  //   firstname: "",
  //   lastname: "",
  //   age: null,
  //   gender: 2,
  //   dob: "",
  //   mobileno: "",
  //   email: "",
  //   countryid: "",
  //   countrycode: "",
  //   appointmentfor: "",
  //   appointmenttype_cd: 0,
  //   schedule_later: "Schedule Now",
  //   appointment_date: "",
  //   appointment_time: "",
  // });

  // const [availableSlots, setAvailableSlots] = useState([]);


  const newVisit = useRef();
const newVisitPatientStatus = useRef();
const newVisitPatientDetails = useRef();
const [availableSlots, setAvailableSlots] = useState([]);
var [DoctorList, setDoctorList] = useState([]);
var [DoctorListForDropdown, setDoctorListForDropdown] = useState([]);
let doctorcountrycode =  localStorage.getItem("countryid");
let doctorcountryid = localStorage.getItem("countrycode");
const [selectedCountry, setSelectedCountry] = useState(doctorcountrycode);
const [selectedCountryId, setSelectedCountryId] = useState(doctorcountryid);
const [SelectedCountryForEmergency, setSelectedCountryForEmergency] = useState(doctorcountrycode);
const [SelectedCountryIdForEmergency, setSelectedCountryIdForEmergency] = useState(doctorcountryid);
const [timePeriod, setTimePeriod] = useState("Morning");
const handleCountryChange = (country) => {
  setSelectedCountry(country.code);
  setSelectedCountryId(country.countryid);
};
const handleCountryChangeForEmergency = (country) => {
  setSelectedCountryForEmergency(country.code);
  setSelectedCountryIdForEmergency(country.countryid);
};
var [DoctorFees, setDoctorFees] = useState(null);
var [Doctorid, setDoctorid] = useState(null);
var [DefaultDoctor, setDefaultDoctor] = useState(null);
const [newVisitData, setNewVisitData] = useState({
  firstname: "",
  lastname: "",
  age: null,
  gender: 2,
  dob: "",
  mobileno: "",
  email: "",
  countryid: doctorcountryid,
  countrycode: doctorcountrycode,
  emergencycontactcountryid:doctorcountryid,
  emergencycontactcountrycode:doctorcountrycode,
  appointmentfor: "",
  appointmenttype_cd: 0,
  schedule_later: "Schedule Now",
  appointment_date: "",
  appointment_time: "",
  createdby :"",
  fees:"",
  isfreevisit: "0",
  address:"",
  emergencycontactname:"",
  emergencycontactphone:"",
});


const handleNewVisitChange = (field, value) => {
  setNewVisitData((prev) => ({ ...prev, [field]: value }));
  // if (field === 'mobileno' && value.length === 10) {
  //   handleFetchPatientStatus(field, value); // Call immediately after 10 digits
  // }
  // if (field === 'email' && value.includes('@')) {
  //   handleFetchPatientStatus(field, value); // Call immediately after entering '@'
  // }
};

  const fetchDoctorSlots = async () => {
    const inputToFetch = {
      input: {
        doctorid: Doctorid,
        date: newVisitData.appointment_date
          ? moment(newVisitData.appointment_date, "DD-MM-YYYY").format(
              "YYYY-MM-DD"
            )
          : null,
      },
    };
    //(inputToFetch);
    showLoader();
    try {
      const data = await GetDoctorSlots(inputToFetch.input);
      const slots = data?.output?.data || [];
  
      const currentDate = moment().format("DD-MM-YYYY");
      function isDateInCorrectFormat(dateStr) {
        const regex = /^\d{2}-\d{2}-\d{4}$/;
        return regex.test(dateStr);
      }
  
      const formattedDate = isDateInCorrectFormat(newVisitData.appointment_date)
        ? newVisitData.appointment_date
        : moment(newVisitData.appointment_date).format("DD-MM-YYYY");
      if (newVisitData.appointment_date && formattedDate === currentDate) {
        // console.log(newVisitData.appointment_date);
        // console.log(currentDate);
        const currentTime = new Date();
        const currentHours = currentTime.getHours();
        const currentMinutes = currentTime.getMinutes();
        const filteredSlots = slots.filter((slot) => {
          const [slotHours, slotMinutes] = slot.starttime
            .split(":")
            .map(Number);
          return (
            slotHours > currentHours ||
            (slotHours === currentHours && slotMinutes >= currentMinutes)
          );
        });
        // console.log(filteredSlots.length);
        // console.log(slots.length);
        setAvailableSlots(slots);
      }
      // console.log("New Slots: ", slots);
      else {
        // console.log("Other case");
        setAvailableSlots(slots);
      }
    } catch (error) {
      console.error("Error fetching Doctor Slots data:", error);
    } finally {
      dismissLoader();
    }
  };

  const openNewVisitForm = () => {
    setAvailableSlots([]);
    setNewVisitData({
      createdby: "",
    });
    fetchDoctors();
    newVisit.current.openModal();
  };

  // useEffect(() => {
  //   setActionAccess(actionAccessMain(2))
  //   setActionAccessAppointments(actionAccessMain(15))
  //   fetchDoctorSlots();
  // }, [newVisitData.appointment_date]);


  const NewSlotSelector = ({ availableSlots }) => {
    const [selectedSlot, setSelectedSlot] = useState(null);
    const currentDate = moment().format("DD-MM-YYYY");
    const currentTime = new Date();
  
    const isSlotDisabled = (slot) => {
      const slotTime = new Date(`1970-01-01T${slot.starttime}`);
      const slotDate = moment(newVisitData.appointment_date).format(
        "DD-MM-YYYY"
      );
  
      function isDateInCorrectFormat(dateStr) {
        const regex = /^\d{2}-\d{2}-\d{4}$/;
        return regex.test(dateStr);
      }
  
      const formattedRescheduleDate = isDateInCorrectFormat(
        newVisitData.appointment_date
      )
        ? newVisitData.appointment_date
        : moment(newVisitData.appointment_date).format("DD-MM-YYYY");
      if (
        newVisitData.appointment_date &&
        formattedRescheduleDate === currentDate
      ) {
        // console.log("Same day events")
  
        // If the slot is for today and its time has passed, disable it
  
        const currentHours = currentTime.getHours();
        const currentMinutes = currentTime.getMinutes();
        const [slotHours, slotMinutes] = slot.starttime.split(":").map(Number);
  
        return (
          slotHours < currentHours ||
          (slotHours === currentHours && slotMinutes < currentMinutes)
        );
      }
  
      // If it's not today, don't disable
      return false;
    };
  
    const timePeriods = ["Morning", "Afternoon", "Evening", "Night"];
    const timePeriodSlots = {
      Morning: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
            new Date("1970-01-01T09:00:00") &&
          new Date(`1970-01-01T${slot.starttime}`) <
            new Date("1970-01-01T12:00:00")
      ),
      Afternoon: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
            new Date("1970-01-01T12:00:00") &&
          new Date(`1970-01-01T${slot.starttime}`) <
            new Date("1970-01-01T18:00:00")
      ),
      Evening: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
            new Date("1970-01-01T18:00:00") &&
          new Date(`1970-01-01T${slot.starttime}`) <
            new Date("1970-01-01T21:00:00")
      ),
      Night: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
          new Date("1970-01-01T21:00:00")
      ),
    };
  
    const handleTimePeriodChange = (direction) => {
      const currentIndex = timePeriods.indexOf(timePeriod);
      const newIndex =
        (currentIndex + direction + timePeriods.length) % timePeriods.length;
      setTimePeriod(timePeriods[newIndex]);
    };
  
    const handleSlotSelection = (slot) => {
      if (!isSlotDisabled(slot)) {
        // console.log("Selection made")
        setSelectedSlot(slot.starttime);
        handleNewVisitChange("appointment_time", slot.starttime);
        const selectedTime = new Date(`1970-01-01T${slot.starttime}`);
        if (
          selectedTime >= new Date("1970-01-01T09:00:00") &&
          selectedTime < new Date("1970-01-01T12:00:00")
        ) {
          setTimePeriod("Morning");
        } else if (
          selectedTime >= new Date("1970-01-01T12:00:00") &&
          selectedTime < new Date("1970-01-01T18:00:00")
        ) {
          setTimePeriod("Afternoon");
        } else if (
          selectedTime >= new Date("1970-01-01T18:00:00") &&
          selectedTime < new Date("1970-01-01T21:00:00")
        ) {
          setTimePeriod("Evening");
        } else {
          setTimePeriod("Night");
        }
        // console.log(timePeriod);
      }
    };
  
    return (
      <Box>
        {isLoading && <Loader miniLoader={true} />}
        {!isLoading && (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={4}
            >
              <Box as="button" onClick={() => handleTimePeriodChange(-1)}>
                <Image src="/assets/svgs/left-tab-icon.svg" />
              </Box>
              <Text fontSize="xl" fontWeight="bold">
                {timePeriod}
                <Text fontSize="md" color="gray" display={"inline"} ml={"8px"}>
                  {timePeriodSlots[timePeriod].length} slots
                </Text>
              </Text>
              <Box as="button" onClick={() => handleTimePeriodChange(1)}>
                <Image src="/assets/svgs/right-tab-icon.svg" />
              </Box>
            </Box>
  
            <SimpleGrid columns={{base:4, md:5}} spacing={4}>
              {timePeriodSlots[timePeriod].map((slot) => (
                <Box
                  key={slot.starttime}
                  as="button"
                  onClick={() => handleSlotSelection(slot)}
                  borderWidth="1px"
                  borderRadius="md"
                  p={2}
                  textAlign="center"
                  bg={
                    newVisitData.appointment_time === slot.starttime
                      ? "blue.500"
                      : isSlotDisabled(slot)
                      ? "gray.300"
                      : "white"
                  }
                  color={
                    newVisitData.appointment_time === slot.starttime
                      ? "white"
                      : "black"
                  }
                  borderColor={
                    newVisitData.appointment_time === slot.starttime
                      ? "blue.500"
                      : "gray.300"
                  }
                  _hover={{
                    bg:
                      newVisitData.appointment_time === slot.starttime
                        ? "blue.600"
                        : isSlotDisabled(slot)
                        ? "gray.300"
                        : "gray.200",
                  }}
                  disabled={isSlotDisabled(slot)}
                >
                  {slot.starttime.substring(0, 5)}
                </Box>
              ))}
            </SimpleGrid>
          </>
        )}
      </Box>
    );
  };

  useEffect(() => {
    if(newVisitData.appointment_date != ""){
      fetchDoctorSlots();
    }
  }, [newVisitData.appointment_date, Doctorid]);


  const [currency, setCurrency] = useState("");

    let rolename = localStorage.getItem("rolename");

    useEffect(() => {
      // Get the current local time
  const currentTime = moment();

  // Determine the time of day
  const currentHour = currentTime.hour();
  let greeting;
  let icon ;
  if (currentHour >= 5 && currentHour < 12) {
      greeting = "Good morning";
      icon = "/assets/svgs/good-morning.svg"
  } else if (currentHour >= 12 && currentHour < 18) {
      greeting = "Good afternoon";
      icon = "/assets/svgs/good-evening.svg"
  } else if (currentHour >= 18 && currentHour < 21) {
    greeting = "Good evening";
    icon = "/assets/svgs/good-evening.svg"
}
  else {
      greeting = "Good night";
      icon = "/assets/svgs/good-night.svg"
  }
  // setGreetings(greeting);
  // setGreetingsIcon(icon)
    // fetchData();
    // getDoctorProfile();
    const currency = getCurrency();
    if(currency == "USD"){
      const uscurrency = '$'
      setCurrency(uscurrency);
    }else{
    setCurrency(currency);
    }
    
    // getDoctorAvailability();
    // getDoctorUnique();
  }, []);

  const fetchDoctors = async () => {
    showLoader();
    try {
      const result = await FetchDoctorsByUser({
        doctorId,
      });
      if (result.output.result == "success") {
        setDoctorList(result.output.data);
        const Doctors = result.output.data && result.output.data.map((step) => [
          step.fullname
        ]);
        setDoctorListForDropdown(Doctors);
        const currentdoctor = getIsDoctor();
        const loginid = getLoginID()
        let obj = {}
        if(currentdoctor == 1 && currentdoctor != null){
          obj = result.output.data && result.output.data.filter(doctor => doctor.doctorid == loginid);
          if(obj[0]){
            setDefaultDoctor(obj[0].fullname)
            setDoctorFees(formatToDecimal(obj[0].fee));
            setDoctorid(obj[0].doctorid);
            setNewVisitData({
              firstname: "",
              lastname: "",
              age: null,
              gender: 2,
              dob: "",
              mobileno: "",
              email: "",
              countryid: doctorcountryid,
              countrycode: doctorcountrycode,
              emergencycontactcountryid:doctorcountryid,
            emergencycontactcountrycode:doctorcountrycode,
              appointmentfor: "",
              appointmenttype_cd: 0,
              schedule_later: "Schedule Now",
              appointment_date: moment().format("DD-MM-YYYY"),
              appointment_time: new Date().toTimeString().substring(0, 5),
              createdby: obj[0].doctorid,
              fees:obj[0].fee,
              isfreevisit: "0",
            });
          }
        }else{
          setDefaultDoctor("");
          setNewVisitData({
            firstname: "",
            lastname: "",
            age: null,
            gender: 2,
            dob: "",
            mobileno: "",
            email: "",
            countryid: doctorcountryid,
            countrycode: doctorcountrycode,
            emergencycontactcountryid:doctorcountryid,
            emergencycontactcountrycode:doctorcountrycode,
            appointmentfor: "",
            appointmenttype_cd: 0,
            schedule_later: "Schedule Now",
            appointment_date: moment().format("DD-MM-YYYY"),
            appointment_time: new Date().toTimeString().substring(0, 5),
            createdby: "",
            fees:"",
            isfreevisit: "0",
          });
        }
      }else {
        setDoctorList([]);
        dismissLoader();
      }
      dismissLoader();
    } catch (error) {
      console.error("Error", error);
    }
  };
  const HandleEditDoctor = (selectedOption)=>{
    setDoctorFees(null)
    let obj = DoctorList.filter(doctor => doctor.fullname == selectedOption);
    setDoctorFees(formatToDecimal(obj[0].fee));
    setDoctorid(obj[0].doctorid)
    setNewVisitData({
      firstname: newVisitData.firstname,
          lastname: newVisitData.lastname,
          age: newVisitData.age,
          gender: newVisitData.gender,
          dob: newVisitData.dob,
          mobileno: newVisitData.mobileno,
          email: newVisitData.email,
          countryid: newVisitData.countryid,
          countrycode: newVisitData.countrycode,
          emergencycontactcountryid:newVisitData.emergencycontactcountryid,
          emergencycontactcountrycode:newVisitData.emergencycontactcountrycode,
          appointmentfor: "",
          appointmenttype_cd: 0,
          schedule_later: "Schedule Now",
          appointment_date: moment().format("DD-MM-YYYY"),
          appointment_time: new Date().toTimeString().substring(0, 5),
      createdby: obj[0].doctorid,
      fees:obj[0].fee,
    });
  }
  

  const navigate = useNavigate();

  const handleGenderChange = (value) => {
    let numericValue;
    switch (value) {
      case "Male":
        numericValue = 2;
        break;
      case "Female":
        numericValue = 1;
        break;
      case "Other":
        numericValue = 3;
        break;
      default:
        numericValue = null;
    }
    // console.log("Setting gender: ", value, numericValue);
    handleNewVisitChange("gender", numericValue);
  };

  function calculateAge(dob) {
    const dobDate = new Date(dob);
    const today = new Date();
  
    let age = today.getFullYear() - dobDate.getFullYear();
    const monthDifference = today.getMonth() - dobDate.getMonth();
    const dayDifference = today.getDate() - dobDate.getDate();
  
    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }
  
    return age;
  }


  function getGender(value) {
    switch (value) {
      case 1:
        return "Female";
      case 2:
        return "Male";
      case 3:
        return "Other";
      default:
        return "Unknown"; // In case an unexpected value is passed
    }
  }

  const handleFetchPatientStatus = async (field, value) => {
    let inputToSave = "";
    if(field == "email"){
      inputToSave = {
        input: {
          mobileno: newVisitData.mobileno,
          email: value,
          type: "getuser",
        },
      };
    }
    else if(field == "mobileno"){
      inputToSave = {
        input: {
          mobileno: value,
          email: newVisitData.email,
          type: "getuser",
        },
      };
    }else{
      inputToSave = {
        input: {
          mobileno: newVisitData.mobileno || null,
          email: newVisitData.email || null,
          type: "getuser",
        },
      };
    }
  
    showLoader()
    try {
      const result = await GetPatientStatus(inputToSave.input);
      if (result?.output?.result === "failure") {
        // console.log("true");
        showSuccessToast("No existing record found. Please create a new patient profile.")
        setNewVisitData({
          firstname: "",
          lastname: "",
          age: null,
          gender: 2,
          dob: "",
          mobileno: inputToSave.input.mobileno,
          email: inputToSave.input.email,
          countryid: doctorcountryid,
        countrycode: doctorcountrycode,
        emergencycontactcountryid : doctorcountryid,
        emergencycontactcountrycode: doctorcountrycode,
          appointmentfor: "",
          appointmenttype_cd: 0,
          schedule_later: "Schedule Now",
          appointment_date: moment().format("DD-MM-YYYY"),
          appointment_time: new Date().toTimeString().substring(0, 5),
          createdby: newVisitData.createdby,
          fees:DoctorFees,
          isfreevisit: "0",
          address:"",
        emergencycontactname:"",
        emergencycontactphone:"",
        });
        // newVisitPatientDetails.current.closeModal();
        // newVisit.current.openModal();
      } else {
        showSuccessToast("Existing patient details found. Proceed to visit setup.")
        const patientInfo = result.output.data;
        setNewVisitData({
          firstname: patientInfo.firstname,
          lastname: patientInfo.lastname,
          age: patientInfo?.dob ? calculateAge(patientInfo.dob): null,
          gender: patientInfo.gender,
          dob: moment(patientInfo.dob, "YYYY-MM-DD").format("DD-MM-yyyy"),
          mobileno: patientInfo.mobileno,
          email: patientInfo.email,
          countryid: patientInfo.countryid,
          countrycode: patientInfo.countrycode,
          emergencycontactcountryid : patientInfo.emergencycontactcountryid? patientInfo.emergencycontactcountryid: patientInfo.countryid,
        emergencycontactcountrycode: patientInfo.emergencycontactcountrycode ?patientInfo.emergencycontactcountrycode: patientInfo.countrycode,
          appointmentfor: "",
          appointmenttype_cd: 0,
          schedule_later: "Schedule Now",
          appointment_date: moment().format("DD-MM-YYYY"),
          appointment_time: new Date().toTimeString().substring(0, 5),
          createdby: newVisitData.createdby,
          fees:DoctorFees,
          isfreevisit: "0",
          address:patientInfo.patientaddress,
          emergencycontactname:patientInfo.emergencycontactname,
          emergencycontactphone:patientInfo.emergencycontactphone,
        });
  
        // set default country code here as well
        if (
          patientInfo.countrycode !== null &&
          patientInfo.countrycode !== undefined
        ) {
          setSelectedCountry(patientInfo.countrycode);
          setSelectedCountryId(patientInfo.countryid);
        }
        if (
          patientInfo.emergencycontactcountrycode !== null &&
          patientInfo.emergencycontactcountrycode !== undefined 
        ) {
          setSelectedCountryForEmergency(patientInfo.emergencycontactcountrycode);
          setSelectedCountryIdForEmergency(patientInfo.emergencycontactcountryid);
        }
  
        // newVisitPatientStatus.current.closeModal();
        // newVisit.current.openModal();
      }
      dismissLoader();
    } catch (error) {
      console.error("Error in create visit:", error);
      showErrorToast("Error Happened While Saving.");
    }
  };

  function isAgeInRange(dob, age) {
    const birthDate = new Date(dob);
    const today = new Date();
  
    // Calculate the age based on the DOB
    let calculatedAge = today.getFullYear() - birthDate.getFullYear();
  
    // Adjust if the birthday hasn't occurred yet this year
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      calculatedAge--;
    }
  
    // Check if the calculated age is within the range of ±1 year of the provided age
    return Math.abs(calculatedAge - age) <= 1;
  }

  const formatDOBDate = (date) => {
    // Check if the date is already in YYYY-MM-DD format
    if (moment(date, "YYYY-MM-DD", true).isValid()) {
      return date;
    }
    // If not, assume it's in DD-MM-YYYY format and convert it to YYYY-MM-DD
    return moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
  };

  const handleCreateVisitClick = async (IsAdvance) => {
    const typeofschedule = newVisitData.schedule_later;
    const currentdoctor = getIsDoctor();
    let type = null;
    if(currentdoctor == 1 && currentdoctor != null){
      if(typeofschedule === "Schedule Now"){
        type = "startvisit"
      }
    }
    const inputToSave = {
      input: {
        firstname: newVisitData.firstname,
        lastname: newVisitData.lastname,
        fullname: newVisitData.firstname + " " + newVisitData.lastname,
        gender: newVisitData.gender,
        dob: newVisitData.dob ? formatDOBDate(newVisitData.dob) : null,
        age: newVisitData.age,
        mobileno: newVisitData.mobileno,
        email: newVisitData.email,
        countryid: selectedCountryId ? selectedCountryId :"",
        countrycode: selectedCountry ? selectedCountry : "",
        emergencycontactcountryid: SelectedCountryIdForEmergency ? SelectedCountryIdForEmergency :"",
      emergencycontactcountrycode: SelectedCountryForEmergency ? SelectedCountryForEmergency : "",
        patientimage: null,
        doctorid: Doctorid,
        appointmentfor: null,
        appointmenttype_cd: 693,
        appointmentdate: moment(
          newVisitData.appointment_date,
          "DD-MM-YYYY"
        ).format("YYYY-MM-DD"),
        appointmenttime: newVisitData.appointment_time,
        createdby: newVisitData.createdby,
        fees:newVisitData.fees,
        isfreevisit: newVisitData.isfreevisit,
        paymenttype:"cash",
        type: type,
        address: newVisitData.address,
        emergencycontactname: newVisitData.emergencycontactname,
        emergencycontactphone: newVisitData.emergencycontactphone,
      },
    };
    // Validation checks
    if (inputToSave.input.createdby === "") {
      showErrorToast("Please select any doctor to proceed");
      return;
    }else if (
      inputToSave.input.mobileno === ""
    ) {
      showErrorToast("Please enter mobile number.");
      return;
    }else if (
      inputToSave.input.email === "" || inputToSave.input.email === null
    ) {
      showErrorToast("Please enter email.");
      return;
    }else if (
      inputToSave.input.firstname === ""
    ) {
      showErrorToast("Please enter Patient First Name");
      return;
    }  else if (
      inputToSave.input.mobileno !== "" &&
      inputToSave.input.mobileno !== null &&
      inputToSave.input.mobileno !== undefined
    ) {
      // Validate mobile number (numbers only and exactly 10 digits)
      const mobileRegex = /^[0-9]{10}$/;
      if (!mobileRegex.test(inputToSave.input.mobileno)) {
        showErrorToast(
          "Invalid mobile number. Please enter a 10-digit number."
        );
        // handleNewVisitChange("mobileno", "");
        return;
      }
    } else if (
      inputToSave.input.email !== "" &&
      inputToSave.input.email !== null &&
      inputToSave.input.email !== undefined
    ) {
      // Validate email address if provided
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(inputToSave.input.email)) {
        showErrorToast("Invalid email address. Please enter a valid email.");
        // handleNewVisitChange("email", "");
        return;
      }
    }
    // console.log(inputToSave.input);
    // console.log(inputToSave.input.dob);
  
    if (
      inputToSave.input.dob !== "" &&
      inputToSave.input.dob !== null &&
      inputToSave.input.dob !== undefined &&
      inputToSave.input.age !== "" &&
      inputToSave.input.age !== null &&
      inputToSave.input.age !== undefined
    ) {
      // console.log("Checking");
      if (!isAgeInRange(inputToSave.input.dob, inputToSave.input.age)) {
        showErrorToast("Age and DOB do not match.");
        return;
      }
    }
    
    try {
      //console.log(inputToSave);
      const result = await CreateNewVisit(inputToSave.input);
      if (result.output.result == "success") {
        if(IsAdvance === "true"){
          localStorage.removeItem('formState');
          localStorage.removeItem('saveStates');
          localStorage.removeItem('formData');
          localStorage.removeItem('answers')
          localStorage.removeItem('followUps')
          navigate("/advancedetails", { state: { input: inputToSave.input, patientid: result.output.patientid, appointmentid: result.output.appointmentid }})
        }else if(IsAdvance === "false"){
        const typeofschedule = newVisitData.schedule_later;
        if(currentdoctor == 1 && currentdoctor != null && result.output.patientid){
          if(typeofschedule === "Schedule Later"){
            // navigate(
            //   `/patientprofile?id=${result.output.patientid}&page=overview`
            // );
            navigate(`/visits`)
          }else{
            navigate(`/startvisit?doctorId=${result.output.doctorid}&patientId=${result.output.patientid}&appointmentId=${result.output.appointmentid}`)
          }
        }
        else if(result.output.patientid){
          navigate(
            `/patientprofile?id=${result.output.patientid}&page=overview`
          );
        }
      }
        showSuccessToast("Visit scheduled successfully");
        newVisit.current.closeModal();
      }else{
        newVisit.current.closeModal();
      }
    } catch (error) {
      console.error("Error in create visit:", error);
      showErrorToast("Error Happened While Saving.");
    }
  };

  // --------------- calendar code

  useEffect(() => {
    if (calendarRef.current) {
      updateCalendarTitle();
    }
  }, [currentView]);

  useEffect(() => {
    if (calendarRef.current) {
      updateCalendarTitle();
    }
  }, []);

  const handleViewChange = (selectedOption) => {
    setCurrentView(selectedOption.value);
    const calendarApi = calendarRef.current.getApi();
    calendarRef.current.getApi().changeView(selectedOption.value);
    setCalendarTitle(calendarApi.view.title); // Update title when view changes
    if (typeof onCalendarTitleChange === "function") {
      onCalendarTitleChange(calendarApi.view.title);
    }
  };

  const viewOptions = [
    { value: "dayGridMonth", label: "Month" },
    { value: "timeGridWeek", label: "Week" },
    { value: "timeGridDay", label: "Day" },
  ];

  function handleTodayButtonClick() {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.today();
    calendarRef.current.getApi().changeView("timeGridDay"); // to set view to day-view when today is selected
    setCurrentView("timeGridDay");
    updateCalendarTitle();
    if (typeof onCalendarTitleChange === "function") {
      onCalendarTitleChange(calendarApi.view.title);
    }
  }

  function handleNextButtonClick() {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.next();
    updateCalendarTitle();
    if (typeof onCalendarTitleChange === "function") {
      onCalendarTitleChange(calendarApi.view.title);
    }
  }

  function handlePrevButtonClick() {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.prev();
    updateCalendarTitle();
    if (typeof onCalendarTitleChange === "function") {
      onCalendarTitleChange(calendarApi.view.title);
    }
  }

  const updateCalendarTitle = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      setCalendarTitle(calendarApi.view.title);
      if (typeof onCalendarTitleChange === "function") {
        onCalendarTitleChange(calendarApi.view.title);
      }
    }
  };

  function handleEventClick(clickInfo) {
    const patientId = clickInfo.event.extendedProps.patientid;
    const appointmentId = clickInfo.event.extendedProps.appointmentid;
    // console.log(patientId, appointmentId);

    localStorage.setItem("CurrentVisitAppointmentId", appointmentId); // for current visit button on visit details page

    // Redirect to the details page with the patient ID and other details
    window.location.href = `/visitdetails?patientId=${patientId}&appointmentId=${appointmentId}`;
  }

  function handleEvents(events) {
    setCurrentEvents(events);
  }

  return (
    <div className="calendar-app">
      <div className="calendar-app-main">
        {screenType == "web" ? (
          <>
            <Flex w="100%" justifyContent="space-between" h={"0.5rem"}>
              <Box display="flex" gap="1rem" alignItems="center" pl={"16px"}>
                <Image
                  src="assets/imgs/left-icon.png"
                  onClick={handlePrevButtonClick}
                  cursor="pointer"
                />
                <Image
                  src="/assets/imgs/right-icon.png"
                  onClick={handleNextButtonClick}
                  cursor="pointer"
                />
                <Text fontSize="1.125rem" fontWeight="600">
                  {calendarTitle}
                </Text>
              </Box>

              <Box display="flex" gap="1rem" alignItems="center" pr={"16px"}>
                <PrimaryButton
                  buttonText="Today"
                  variant="grayBtn"
                  btnStyle={{ color: COLORS.PRIMARY_COLOR }}
                  onClick={handleTodayButtonClick}
                />
                <select
                  value={currentView}
                  onChange={(e) =>
                    handleViewChange(
                      viewOptions.find(
                        (option) => option.value === e.target.value
                      )
                    )
                  }
                >
                  {viewOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <Divider orientation="vertical" h="100%" />
                <PrimaryButtonIcon
                  buttonText="New visit"
                  iconSrc="/assets/imgs/add.png"
                  btnStyle={{
                    h: "2.5rem",
                    w: "50%",
                    p: "0",
                    m: "0",
                    bg: COLORS.PRIMARY_GRADIENT,
                    color: COLORS.PRIMARY_WHITE,
                    _hover: { bg: COLORS.PRIMARY_COLOR },
                  }}
                  onClick={openNewVisitForm}
                  disabled={actionAccessAppointments && !actionAccessAppointments.create ? true : false}
                />
              </Box>

              {/* <h2>{calendarTitle}</h2> */}
              {/* <button onClick={handleTodayButtonClick}>Today</button> */}
              {/* <button onClick={handlePrevButtonClick}>Previous</button>
          <button onClick={handleNextButtonClick}>Next</button> */}
            </Flex>

            <FullCalendar
              ref={calendarRef}
              height={"calc(100vh - 7rem)"}
              // contentHeight={"auto"}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              headerToolbar={{
                left: "",
                center: "",
                right: "",
              }}
              views={{
                dayGrid: {
                  displayEventEnd: false, // Applies to dayGridMonth, dayGridWeek, and dayGridDay views
                },
                timeGrid: {
                  displayEventEnd: false, // Applies to timeGridWeek and timeGridDay views
                },
                week: {
                  displayEventEnd: false, // Applies to week-based views
                },
                day: {
                  displayEventEnd: false, // Applies to day-based views
                },
              }}
              initialView={currentView}
              defaultTimedEventDuration={"00:01"}
              eventMaxStack={1} // for timeGrid views
              editable={false}
              eventDurationEditable={false}
              selectable={true}
              selectMirror={true}
              dayMaxEvents={2} // after 3 events have more option
              events={eventsArray} // alternatively, use the events setting to fetch from a feed
              eventContent={renderEventContent} // custom render function
              eventClick={handleEventClick}
              eventsSet={handleEvents} // called after events are initialized/added/changed/removed
              datesSet={updateCalendarTitle} // Update title when dates change
              /* you can update a remote database when these fire:
        eventAdd={function(){}}
        eventChange={function(){}}
        eventRemove={function(){}}
      */
            />
          </>
        ) : (
          <>
            <Box w="100vw">
              <Box
                display="flex"
                gap="1rem"
                justifyContent="space-between"
                w="100%"
                alignItems="center"
                px="1rem"
              >
                <Box display="flex" gap="0.75rem">
                  <Image
                    src="assets/imgs/left-icon.png"
                    onClick={handlePrevButtonClick}
                    cursor="pointer"
                  />
                  <Image
                    src="/assets/imgs/right-icon.png"
                    onClick={handleNextButtonClick}
                    cursor="pointer"
                  />
                </Box>
                <Box>
                  <Text fontSize="1.125rem" fontWeight="600">
                    {calendarTitle}
                  </Text>
                </Box>
              </Box>
              <Box
                display="flex"
                mt="1rem"
                justifyContent="space-between"
                w="100%"
                gap="1rem"
                alignItems="center"
                px="1rem"
              >
                <Box display="flex" gap="0.75rem" w="50%">
                  <PrimaryButton
                    buttonText="Today"
                    variant="grayBtn"
                    btnStyle={{ color: COLORS.PRIMARY_COLOR }}
                    onClick={handleTodayButtonClick}
                  />
                  <select
                    value={currentView}
                    onChange={(e) =>
                      handleViewChange(
                        viewOptions.find(
                          (option) => option.value === e.target.value
                        )
                      )
                    }
                  >
                    {viewOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </Box>
                <Divider orientation="vertical" h="100%" />
                <PrimaryButtonIcon
                  buttonText="New visit"
                  iconSrc="/assets/imgs/add.png"
                  btnStyle={{
                    h: "2.5rem",
                    w: "50%",
                    p: "0",
                    m: "0",
                    bg: COLORS.PRIMARY_GRADIENT,
                    color: COLORS.PRIMARY_WHITE,
                    _hover: { bg: COLORS.PRIMARY_COLOR },
                  }}
                  onClick={openNewVisitForm}
                  disabled={actionAccessAppointments && !actionAccessAppointments.create ? true : false}
                />
              </Box>
            </Box>

            {/* Calendar start */}
            <Box w="100vw" overflow="auto">
              <FullCalendar
                ref={calendarRef}
                // contentHeight={"auto"}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                headerToolbar={{
                  left: "",
                  center: "",
                  right: "",
                }}
                initialView={currentView}
                defaultTimedEventDuration={"00:15"}
                eventMaxStack={1} // for timeGrid views
                editable={true}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={2} // after 3 events have more option
                events={eventsArray} // alternatively, use the events setting to fetch from a feed
                eventContent={renderEventContent} // custom render function
                eventClick={handleEventClick}
                eventsSet={handleEvents} // called after events are initialized/added/changed/removed
                datesSet={updateCalendarTitle} // Update title when dates change
                /* you can update a remote database when these fire:
              eventAdd={function(){}}
              eventChange={function(){}}
              eventRemove={function(){}}
              */
              />
            </Box>
          </>
        )}
      </div>

      {/* New Visit  */}
      {/* <DynamicModal
        ref={newVisit}
        modalHeader="New Visit"
        modalWidth={{base:"100%", md:"60%"}}
        modalBody={
          <>
            <Box bg={"#fcfcfd"}>
              <Box display="flex" flexDir="row">
                <PrimaryInput
                  type="text"
                  variant={"leftSide"}
                  inputPlace="First Name*"
                  inputValue={newVisitData.firstname}
                  onChange={(e) =>
                    handleNewVisitChange("firstname", e.target.value)
                  }
                  width={"30%"}
                />
                <Spacer />
                <PrimaryInput
                  type="text"
                  variant={"rightSide"}
                  inputPlace="Last Name*"
                  inputValue={newVisitData.lastname}
                  onChange={(e) =>
                    handleNewVisitChange("lastname", e.target.value)
                  }
                />
              </Box>

           
              <Box display="flex" flexDir={{base:"column", md:"row"}}>
                <PrimaryInputDropdown
                  variant="bothSide"
                  boxStyle={{borderRadius:'0'}}
                  inputStyle={{ borderRadius: "0" }}
                  onCountryChange={handleCountryChange} // for country codes
                  useLocalStorage={false} // Ensure this is set to false to use the callback
                  defaultCountryCode={newVisitData.countryid}
                  onChange={(e) =>
                    handleNewVisitChange(
                      "mobileno",
                      e.target.value.replace(/\D/g, "").slice(0, 10)
                    )
                  }
                  // onKeyPress={handleMobileKeyPress}
                  inputValue={newVisitData.mobileno}
                />
                <PrimaryInput
                  type="text"
                  mt={"8px"}
                  width={"80%"}
                  variant={"downSide"}
                  inputPlace="Email Address"
                  inputValue={newVisitData.email}
                  onChange={(e) =>
                    handleNewVisitChange("email", e.target.value)
                  }
                />
              </Box>

              <Box display="flex" flexDir="row" mt="12px">
                
                <DatePicker
                  defaultDate={newVisitData.dob}
                  disableFutureDates={true}
                  onDateChange={(selectedDateValue) => {
                    handleNewVisitChange(
                      "dob",
                      moment(selectedDateValue).format("YYYY-MM-DD")
                    )
                    handleNewVisitChange("age", calculateAge(moment(selectedDateValue).format("YYYY-MM-DD")))
                  }
                  }
                  placeholderProp={"Date of Birth"}
                  calendarStlye={{ left: "-18px", right: "unset" }}
                  datePickerInputStyle={{borderRadius:'10px 0 0 10px'}}
                />
                <PrimaryInput
                  type="number"
                  isDisabled={true}
                  variant={"bothSide"}
                  inputStyleCss={{ml:'0'}}
                  inputPlace="Age in Years"
                  inputValue={newVisitData.age}
                  onChange={(e) => handleNewVisitChange("age", e.target.value)}
                />
                <DropdownInput
                  variant="fullRight"
                  dropDownPlace="Gender"
                  defaultOption={getGender(newVisitData.gender)}
                  options={["Male", "Female", "Other"]}
                  onClick={handleGenderChange}
                />
              </Box>

              <RadioGroup
                defaultValue={"Schedule Now"}
                mt={"12px"}
                onChange={(value) =>
                  handleNewVisitChange("schedule_later", value)
                }
              >
                <Stack direction="row" gap={{base:'12px', md:"70px"}} mx={{base:'0', md:"20%"}}>
                  <Radio value={"Schedule Now"}>Schedule Now</Radio>
                  <Spacer />
                  <Radio value={"Schedule Later"}>Schedule Later</Radio>
                </Stack>
              </RadioGroup>
              {newVisitData.schedule_later === "Schedule Later" && (
                <Box mt={"8px"}>
                  <DatePicker
                    defaultDate={newVisitData.appointment_date}
                    minDate={newVisitData.appointment_date}
                    // disableFutureDates={false}
                    onDateChange={(selectedDateValue) =>
                      handleNewVisitChange(
                        "appointment_date",
                        selectedDateValue
                      )
                    }
                    placeholderProp={"Select Date"}
                    calendarStlye={{ left: "-18px", right: "unset" }}
                    datePickerInputStyle={{borderRadius:'10px'}}
                  />
                  <NewSlotSelector availableSlots={availableSlots} />
                </Box>
              )}
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="grayBtn"
              buttonText={
                newVisitData.schedule_later === "Schedule Now"
                  ? "Start Visit"
                  : "Create Visit"
              }
              btnStyle={{
                h: "2.5rem",
                w: "100%",
                p: "0",
                m: "0",
                bg: COLORS.PRIMARY_GRADIENT,
                color: COLORS.PRIMARY_WHITE,
                _hover: { bg: COLORS.PRIMARY_COLOR },
              }}
              // onClick={() => newVisit.current.closeModal()}
              onClick={handleCreateVisitClick}
            />
          </>
        }
      /> */}

      {/* New Visit  */}
      {/* <DynamicModal
        ref={newVisitPatientStatus}
        modalHeader="New Visit"
        modalBody={
          <>
            <Box bg={"#fcfcfd"}>
              <Box display="flex" flexDir="column">
                <PrimaryInputDropdown
                  variant="bothSide"
                  inputStyle={{ borderRadius: "0 0 10px 10px" }}
                  onCountryChange={handleCountryChange} // for country codes
                  useLocalStorage={false} // Ensure this is set to false to use the callback
                  onChange={(e) =>
                    handleNewVisitChange(
                      "mobileno",
                      e.target.value.replace(/\D/g, "").slice(0, 10)
                    )
                  }
                  // onKeyPress={handleMobileKeyPress}
                  inputValue={newVisitData.mobileno}
                />
                <PrimaryInput
                  type="text"
                  mt={"8px"}
                  width={"80%"}
                  variant={"bothSide"}
                  inputPlace="Email Address"
                  inputValue={newVisitData.email}
                  onChange={(e) =>
                    handleNewVisitChange("email", e.target.value)
                  }
                />
              </Box>
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="grayBtn"
              buttonText={"Continue"}
              btnStyle={{
                h: "2.5rem",
                w: "100%",
                p: "0",
                m: "0",
                bg: COLORS.PRIMARY_GRADIENT,
                color: COLORS.PRIMARY_WHITE,
                _hover: { bg: COLORS.PRIMARY_COLOR },
              }}
              // onClick={() => newVisit.current.closeModal()}
              onClick={handleFetchPatientStatus}
            />
          </>
        }
      /> */}


<DynamicModal
        ref={newVisit}
        modalHeader="New Visit"
        modalWidth={{base:"100%", md:"60%"}}
        modalBody={
          <>
            <Box bg={"#fcfcfd"}>
            <Box display="flex" flexDir="column">
              <DropdownInput
                 variant="fullRound"
                 dropDownPlace="Doctor Name*"
                  options={DoctorListForDropdown}
                  id="Doctor Name"
                  onClick={HandleEditDoctor}
                  defaultOption={DefaultDoctor}
                  menuList={{ pos: "absolute", right: "-41px", minW: "310px" }}
                  inputStyleCss={{w: "100%"}}
                 />
              </Box>
              
              <Box display="flex" flexDir={{base:"column", md:"row"}}>
                <PrimaryInputDropdown
                  variant="bothSide"
                  boxStyle={{borderRadius:'0'}}
                  inputStyle={{ borderRadius: "0" }}
                  onCountryChange={handleCountryChange} // for country codes
                  useLocalStorage={false} // Ensure this is set to false to use the callback
                  defaultCountryCode={parseInt(newVisitData.countryid)}
                  onChange={(e) =>
                    handleNewVisitChange(
                      "mobileno",
                      e.target.value.replace(/\D/g, "").slice(0, 10)
                    )
                  }
                  // onKeyPress={handleMobileKeyPress}
                  inputValue={newVisitData.mobileno}
                />
                {screenType == 'web' ? (
                <>
                <PrimaryInput
                  type="text"
                  mt={"8px"}
                  width={"80%"}

                  variant='rightDown'
                  inputPlace="Email Address*"

                  inputValue={newVisitData.email}
                  onChange={(e) =>
                    handleNewVisitChange("email", e.target.value)
                  }
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleFetchPatientStatus("email", e.target.value);
                    }
                  }}
                />
                <PrimaryButton
                  buttonText="Fetch"
                  verticalAlign="bottom"
                  variant="grayBtn"
                  btnStyle={{
                    w: "12%",
                    color: COLORS.PRIMARY_COLOR,
                    verticalAlign: "bottom",
                    ml: "4px",
                    mb: "0px",
                    h: "2.8rem",
                    m: "2px",
                    borderRadius: "0"
                  }}
                  onClick={() =>
                    handleFetchPatientStatus("both")
                  }
                  isDisabled={false}
                />
                </>
              ) : (
                <Box display='flex'>
                <PrimaryInput
                  type="text"
                  mt={"8px"}
                  // width={"80%"}
                  variant='bothSide'
                  inputPlace="Email Address"
                  inputValue={newVisitData.email}
                  onChange={(e) =>
                    handleNewVisitChange("email", e.target.value)
                  }
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleFetchPatientStatus("email", e.target.value);
                    }
                  }}
                />
                <PrimaryButton
                  buttonText="Fetch"
                  verticalAlign="bottom"
                  variant="grayBtn"
                  btnStyle={{
                    w: "12%",
                    color: COLORS.PRIMARY_COLOR,
                    verticalAlign: "bottom",
                    ml: "4px",
                    mb: "0px",
                    h: "2.8rem",
                    m: "2px",
                    borderRadius: "0"
                  }}
                  onClick={() =>
                    handleFetchPatientStatus("both")
                  }
                  isDisabled={false}
                />
                </Box>
              )}
              </Box>
              <Box display="flex" flexDir="row">
                <PrimaryInput
                  type="text"
                  variant='leftDown'
                  inputPlace="First Name*"
                  inputValue={newVisitData.firstname}
                  onChange={(e) =>
                    handleNewVisitChange("firstname", e.target.value)
                  }
                  width={"30%"}
                />
                <Spacer />
                <PrimaryInput
                  type="text"
                  variant='rightDown'
                  inputPlace="Last Name*"
                  inputValue={newVisitData.lastname}
                  onChange={(e) =>
                    handleNewVisitChange("lastname", e.target.value)
                  }
                />
              </Box>
              <Box display="flex" flexDir="row" mt="12px">
              <DatePicker
                  value={newVisitData.dob ? newVisitData.dob: null}
                  clearValue={true}
                  disableFutureDates={true}
                  onDateChange={(selectedDateValue) => {
                    const formattedDob = moment(selectedDateValue).format("DD-MM-yyyy");
                    const formattedAge = moment(selectedDateValue).format("YYYY-MM-DD");
                    const age = calculateAge(formattedAge);
                    handleNewVisitChange('dob', formattedDob); // Set DOB
                    handleNewVisitChange('age', age); // Set Age
                  }}
                  placeholderProp={"Date of Birth"}
                  calendarStlye={{ left: "-18px", right: "unset" }}
                  datePickerInputStyle={{borderRadius:'10px 0 0 10px'}}
                />
                <PrimaryInput
                  type="number"
                  variant={"bothSide"}
                  inputPlace="Age in Years"
                  inputValue={newVisitData.age}
                  isDisabled={true}
                  //onChange={(e) => handleNewVisitChange("age", e.target.value)}
                />
               
                <DropdownInput
                  variant="fullRight"
                  dropDownPlace="Gender"
                  defaultOption={getGender(newVisitData.gender)}
                  options={["Male", "Female", "Other"]}
                  onClick={handleGenderChange}
                />
              </Box>
              <Box display="flex" flexDir="column">
              <Textarea
                  borderRadius='0'
                  placeholder="Address"
                  defaultValue={newVisitData.address}
                  onChange={(e) =>
                    handleNewVisitChange("address", e.target.value)
                  }
                />
              </Box>
              <Box display="flex" flexDir="row">
                <PrimaryInput
                  type="text"
                  variant='leftDown'
                  inputPlace="Emergency Contact Name"
                  inputValue={newVisitData.emergencycontactname}
                  onChange={(e) =>
                    handleNewVisitChange("emergencycontactname", e.target.value)
                  }
                  width={"30%"}
                />
                <Spacer />
                <PrimaryInputDropdown
                  variant="bothSide"
                  boxStyle={{borderRadius:'0 0 10px 0', h:'48px'}}
                  inputStyle={{ borderRadius: "0" }}
                  containerStyle={{borderRadius:'0 0 10px 0', h:'48px'}}
                  placeholderProp="Emergency mobile"
                  onCountryChange={handleCountryChangeForEmergency} // for country codes
                  useLocalStorage={false} // Ensure this is set to false to use the callback
                  defaultCountryCode={parseInt(newVisitData.emergencycontactcountryid)}
                  onChange={(e) =>
                    handleNewVisitChange(
                      "emergencycontactphone",
                      e.target.value.replace(/\D/g, "").slice(0, 10)
                    )
                  }
                  // onKeyPress={handleMobileKeyPress}
                  inputValue={newVisitData.emergencycontactphone}
                />
              </Box>
              <RadioGroup
                defaultValue={"Schedule Now"}
                mt={"12px"}
                onChange={(value) =>
                  handleNewVisitChange("schedule_later", value)
                }
              >
                <Stack direction="row" gap={{base:'12px', md:"70px"}} mx={{base:'0', md:"20%"}}>
                  <Radio colorScheme="green" value={"Schedule Now"}>Schedule Now</Radio>
                  <Spacer />
                  <Radio colorScheme="green" value={"Schedule Later"}>Schedule Later</Radio>
                </Stack>
              </RadioGroup>
              {/* Space to Schedule visit */}
              {newVisitData.schedule_later === "Schedule Later" && (
                <Box mt={"8px"}>
                  <DatePicker
                    defaultDate={newVisitData.appointment_date}
                    minDate={newVisitData.appointment_date}
                    // disableFutureDates={false}
                    onDateChange={(selectedDateValue) =>
                      handleNewVisitChange(
                        "appointment_date",
                        selectedDateValue
                      )
                    }
                    placeholderProp={"Select Date"}
                    calendarStlye={{ left: "-18px", right: "unset" }}
                    datePickerInputStyle={{borderRadius:'10px'}}
                  />
                  <NewSlotSelector availableSlots={availableSlots} />
                
                </Box>
              )}
              <Box display="flex" alignItems='center' gap='1rem' mt="12px">
              <InputGroup>
              <InputLeftAddon height="48px">{currency}</InputLeftAddon>
              <PrimaryInput
                  type="number"
                  // inputStyleCss={{w:"50%"}}
                  variant={"bothSide"}
                  inputPlace="Fees"
                  inputValue={newVisitData.fees}
                  onChange={(e) => handleNewVisitChange("fees", e.target.value)}
                />
              </InputGroup>
                
                <RadioGroup
                defaultValue={"0"}
                mt={"12px"}
                w='100%'
                onChange={(value) => {
                  handleNewVisitChange("isfreevisit", value);
                  
                  // If value is '1', set fees to 0
                  if (value === "1") {
                    handleNewVisitChange("fees", "0.00"); // Set fees to 0
                  }else
                  handleNewVisitChange("fees", DoctorFees);
                }}
              >
                <Stack direction="row" gap="1rem" >
                  <Radio colorScheme="green" value={"0"}>Cash</Radio>
                  {/* <Spacer /> */}
                  <Radio colorScheme="green" value={"1"}>Free visit</Radio>
                </Stack>
              </RadioGroup>

              </Box>
            </Box>
          </>
        }
        modalFooterBtn={
          <>
          <Flex gap='1rem' w='100%'>
            <PrimaryButton
              variant="grayBtn"
              buttonText={
                newVisitData.schedule_later === "Schedule Now"
                  ? "Create Visit"
                  : "Create Visit"
              }
              btnStyle={{
                h: "2.5rem",
                w: "100%",
                p: "0",
                m: "0",
                bg: COLORS.PRIMARY_GRADIENT,
                color: COLORS.PRIMARY_WHITE,
                _hover: { bg: COLORS.PRIMARY_COLOR },
              }}
              // onClick={() => newVisit.current.closeModal()}
              onClick={()=>handleCreateVisitClick("false")}
            />
             <PrimaryButton
              variant="grayBtn"
              buttonText="Advanced Details"
              btnStyle={{
                // h: "2.5rem",
                w: "100%",
                p: "0",
                m: "0",
                color:'#000',
              }}
              onClick={()=>handleCreateVisitClick("true")}
            />
            </Flex>
          </>
        }
      />
    </div>
  );
}

function renderEventContent(eventInfo) {
  // console.log(eventInfo);
  const eventDate = new Date(eventInfo.event.start);
  const today = new Date();

  const eventTime = eventDate.toLocaleTimeString([], {
    hour: "numeric",
    minute: "2-digit",
  });

  let eventColor = "blue"; // default color for future events
  let darkStripColor = "#1c4ec5"; // default dark strip color
  let lighterBackgroundColor = "#eaedf5"; // default lighter background color
  let borderRadius = "4px"; // default border radius

  if (eventDate.toDateString() === today.toDateString()) {
    eventColor = "green"; // color for today's events
    darkStripColor = "#14c585";
    lighterBackgroundColor = "#d0f3e7";
  }

  if (eventInfo.event.extendedProps.completed) {
    eventColor = "gray"; // color for past events
    darkStripColor = "#cdcfd4";
    lighterBackgroundColor = "#fafafa";
  }

  if (eventInfo.event.extendedProps.cancelled) {
    eventColor = "red"; // color for cancelled events
    darkStripColor = "#E82B2B";
    lighterBackgroundColor = "#f8c3c3";
  }

  if (eventInfo.event.extendedProps.noshow) {
    eventColor = "amber"; // color for noshow events
    darkStripColor = "#f78003";
    lighterBackgroundColor = "#ffe59a";
  }

  const textColor = darkStripColor; // Text color matches the dark strip color

  return (
    <div
      className="event"
      style={{
        backgroundColor: lighterBackgroundColor,
        position: "relative", // to position the dark strip
        width: "100%", // expand the background all the way
        borderRadius: borderRadius, // add border radius
      }}
    >
      {/* Dark strip */}
      <div
        className="dark-strip"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "4px", // adjust the width of the strip
          backgroundColor: darkStripColor,
          borderTopLeftRadius: borderRadius, // match border radius
          borderBottomLeftRadius: borderRadius, // match border radius
        }}
      ></div>

      {/* Event content */}
      <div
        className="event-content"
        style={{
          paddingLeft: "8px", // spacing between the dark strip and text
          color: textColor,
          overflow: "hidden", // Ensure no overflow
          whiteSpace: "nowrap", // Prevent text wrapping
          textOverflow: "ellipsis", // Add ellipsis if text overflows
        }}
      >
        <b>{eventTime}</b>
        <b>{eventInfo.event.title}</b>
      </div>
    </div>
  );
}
