import formAPIRoleDescriptions from "./roledetail";


var formattedOutput = null;

const formAPIModelRoleDescriptions = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIRoleDescriptions(item));


  return formattedOutput
};

export default formAPIModelRoleDescriptions