import moment from "moment";
export default function formapibilling({
    appointmentid,
    bookingid,
    date,
    doctorid,
    entityid,
    entitytype,
    imagepath,
    labtestname,
    patientid,
    patientimage,
    patientname ,
    doctorname,
    patientmobile

})
{
  return Object.freeze({
    appointmentid,
    bookingid,
    date,
    doctorid,
    entityid,
    entitytype,
    imagepath,
    labtestname,
    patientid,
    patientimage,
    patientname ,
    doctorname,
    patientmobile
  });
}