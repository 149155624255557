import React, { useEffect } from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Toaster } from "../../../components";
import moment from 'moment';
import { formatDate_Do_MMM_YYYY } from '../../../auth';

const ExcelDownloadDaily = ({ data, onDownloadComplete }) => {
  const { showSuccessToast, showErrorToast } = Toaster();

  const currentDate = new Date();

  useEffect(() => {
     handleDownload(data);
    // console.log(data , " data to Download ")
  }, [data]);

  // code woing fine here , with total calculation
  const handleDownload = (exceldata) => {
    if (!exceldata || Object.keys(exceldata).length === 0) {
      console.error("No data to download.");
      showErrorToast("No Data Available!");
      onDownloadComplete();
      return;
    }
  
    const workbook = XLSX.utils.book_new();
    let hasData = false;
  
    // Format the data dynamically for the Excel sheet
    const prepareSheetData = (data) => {
      const sheetData = [];
      // const allBranches = new Set();
      const merges = []; // Array to hold merge ranges
  
      // Collect all unique branch names
    // Collect all unique branch names from the first diagnose object
const allBranches = []; // Use an array instead of a Set

if (data?.length > 0) {
  const firstDiagnose = data[0]; // Get the first diagnose object
  firstDiagnose?.subdiagnoses?.forEach((sub) => {
    sub?.branches?.forEach((branch) => {
      // if (!allBranches.includes(branch?.branchname)) {
        allBranches.push(branch?.branchname); // Add branchname if not already present
      // }
    });
  });
}

  
      // Convert branch names to a sorted array for column consistency (sort by number part of "CHC")
      // const branchColumns = Array.from(allBranches).sort((a, b) => {
      //   const numA = parseInt(a.replace("CHC", ""));
      //   const numB = parseInt(b.replace("CHC", ""));
      //   return numA - numB; // Sort numerically
      // });
  
      // Prepare the header row
      const reportName = ["Daily Consolidated Diagnosis Report"];
      const reportDate = [`Date: ${formatDate_Do_MMM_YYYY(currentDate)}`];
      const emptyRow = [""];
      sheetData.push(reportName);
      merges.push({ s: { r: 0, c: 0 }, e: { r: 0, c: 6 } });
      
      sheetData.push(reportDate)
      merges.push({ s: { r: 1, c: 0 }, e: { r: 1, c: 6 } });
      
      sheetData.push(emptyRow)
      merges.push({ s: { r: 2, c: 0 }, e: { r: 2, c: 6 } });
      
      const header = ["Diagnose", "Sub-Diagnose", ...allBranches, "Total"];
      sheetData.push(header);
  
      // Initialize column totals array
      const columnTotals = Array(allBranches.length).fill(0);
      let grandTotal = 0; // Total of all totals across rows
  
      // Prepare the rows
      data?.forEach((diagnose, diagnoseIndex) => {
        const startRow = sheetData.length; // Starting row
        diagnose?.subdiagnoses?.forEach((sub) => {
          const row = [diagnose?.diagnose, sub?.name];
          let rowTotal = 0; // Initialize the row total
  
          // Add branch values in the correct column order and calculate totals
          allBranches?.forEach((branch, index) => {
            const branchData = sub?.branches.find((b) => b.branchname === branch);
            const branchValue = branchData ? branchData.value : 0;
            rowTotal += branchValue; // Add to the row total
            columnTotals[index] += branchValue; // Add to the column total
            row.push(branchValue);
          });
  
          // Add the row total
          row.push(rowTotal);
          grandTotal += rowTotal; // Add to the grand total
          sheetData.push(row);
        });
  
        // Merge cells for the "Diagnose" column if there are multiple rows
        const endRow = sheetData.length - 1; // Ending row index
        if (endRow > startRow) {
          merges.push({ s: { r: startRow, c: 0 }, e: { r: endRow, c: 0 } }); // Merge "Diagnose"
        }
      });
  
      // Prepare the totals row
      const totalsRow = ["No of Total End Of The Day", "", ...columnTotals, grandTotal];
      sheetData.push(totalsRow);
  
      // Merge cells for the "Total" label in the first two columns
      merges.push({ s: { r: sheetData.length - 1, c: 0 }, e: { r: sheetData.length - 1, c: 1 } });
  
      return { sheetData, merges };
    };
  
    // Generate the data and merges for the sheet
    const { sheetData, merges } = prepareSheetData(exceldata);
  
    // Convert to worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
  
    // Apply merges to the worksheet
    worksheet["!merges"] = merges;
  
    // Set column widths dynamically
    worksheet["!cols"] = [
      { wch: 20 }, // First column width (Diagnose)
      { wch: 30 }, // Second column width (Sub-Diagnose)
       ...Array(sheetData[3].length - 2).fill({ wch: 15 }), // Branch columns and Total
    ];
  
    // Add worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet);
  
    hasData = true;
  
    if (!hasData) {
      showErrorToast("No Data Available!");
      onDownloadComplete();
      return;
    }
  
    // Write to Excel file
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(dataBlob, `Daily Consolidated Diagnosis Report - ${formatDate_Do_MMM_YYYY(currentDate)}.xlsx`);
  
    showSuccessToast("Download successful!");
    onDownloadComplete();
  };  
  
  
  
  
  
  

  return null;
};

export default ExcelDownloadDaily;
