import moment from "moment";
export default function formAPItotalrevenuechart({
    createddate,
    amount,
   
   

})
{ 
  const formattedDate = moment.utc(createddate); // Parse input as UTC
  formattedDate.local(); // Convert parsed UTC time to local time
  const formattedDateTime = moment(formattedDate).format('Do MMM, YYYY');  

  return Object.freeze({
    label: formattedDateTime,
    value: amount,
    
  });
}