export default function formAPIhistoryofpresentillness({
    appointmentid,
    details,
    doctorname,
    imagepath,
    patientid,
    profilepictureurl,
    specialization,
    type,
    bookingid,
    appointmentdate

})
{
  return Object.freeze({
    appointmentid,
    details,
    doctorname,
    imagepath,
    patientid,
    profilepictureurl,
    specialization,
    type,
    bookingid,
    appointmentdate
   
  });
}