import React from "react";
import {
  Box,
  Text,
  Image,
  Circle,
  Center,
  Link,
  Divider,
} from "@chakra-ui/react";
import moment from "moment";

const formatDate = (dateString) => {
  if (!dateString) return "-";
  return moment(dateString).format("Do MMM, YYYY");
};

const fileTypeMappings = {
  pdf: "/assets/svgs/pdf-icon.svg",
  png: "/assets/imgs/png-icon.png",
  jpg: "/assets/imgs/jpg-icon.png",
  jpeg: "/assets/imgs/jpg-icon.png",
};

const getFileIcon = (fileName) => {
  const extension = fileName.split(".").pop().toLowerCase();
  return fileTypeMappings[extension] || "/assets/svgs/file-icon.svg"; // fallback image
};

const PastMedications = ({
  date,
  medicines,
  attachments,
  id,
  onEdit,
  onDelete,
  scaleHeight = "auto",
  componentBg = "#fcfcfd",
  cardBg = "white",
}) => {
  return (
    <Box
      display="flex"
      flexDir="column"
      alignItems="center"
      w="100%"
      bg={componentBg}
    >
      <Box w="100%">
        {medicines.map((medicine, index) => (
          <Box
            key={index}
            p="20px"
            w="100%"
            // ml="10px"
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="space-between"
            border="1px solid #E6E7E9"
            borderRadius="12px"
            backgroundColor={cardBg}
            mb="10px"
          >
            <Box display="flex" alignItems="center" w="100%" gap="10px">
              <Box
                fontSize="10px"
                fontWeight="600"
                bg="#E6E7E966"
                border="1px solid #E6E7E9"
                p="5px 10px"
                borderRadius="full"
                w="fit-content"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {medicine.type ? medicine.type : "-"}
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                flex="1"
              >
                <Text
                  title={medicine.name}
                  fontSize="16px"
                  textAlign="left"
                  fontWeight="600"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {medicine.name}
                </Text>
              </Box>
            </Box>
            <Divider my="10px" />
            <Box display="flex" alignItems="center" w="100%">
              <Box display="flex" alignItems="center">
                <Image
                  src={
                    medicine.type === "Tablet"
                      ? "/assets/svgs/pill-blue.svg"
                      : "/assets/svgs/syrup.svg"
                  }
                  w="16px"
                  mr="5px"
                />
                <Text
                  fontSize="14px"
                  fontWeight="500"
                  color="#384052"
                  whiteSpace="nowrap"
                >
                  {medicine.frequency ? medicine.frequency : "-"}x per day
                </Text>
                <Box display="flex" gap="5px" ml="5px">
                  <Box
                    as="span"
                    bg={medicine.inMorning === 1 ? "#384052" : "#E6E7E9"}
                    borderRadius="full"
                    w="8px"
                    h="8px"
                    p="4px"
                  ></Box>
                  {/* <Box
                        as="span"
                        bg={medicine.inAfternoon === 1 ? "#384052" : "#E6E7E9"}
                        borderRadius="full"
                        w="8px"
                        h="8px"
                        p="4px"
                      ></Box> */}
                  <Box
                    as="span"
                    bg={medicine.inEvening === 1 ? "#384052" : "#E6E7E9"}
                    borderRadius="full"
                    w="8px"
                    h="8px"
                    p="4px"
                  ></Box>
                  <Box
                    as="span"
                    bg={medicine.inNight === 1 ? "#384052" : "#E6E7E9"}
                    borderRadius="full"
                    w="8px"
                    h="8px"
                    p="4px"
                  ></Box>
                </Box>
              </Box>
              <Box flex="1" />
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                w="100%"
              >
                <Image
                  src="/assets/svgs/calendar_month.svg"
                  w="16px"
                  mr="5px"
                />
                <Text fontSize="14px" fontWeight="500" color="#384052">
                  {medicine.duration}
                </Text>
              </Box>
            </Box>
          </Box>
        ))}
        <Box mt="4px" p="10px" w="100%">
          <Box display="flex" w="100%" flexWrap="wrap">
            {" "}
            {attachments.map((attachment, index) => (
              <Box
                key={index}
                display="flex"
                alignItems="center"
                border="1px solid #E6E7E9"
                borderRadius="8px"
                p="5px 10px"
                mr="10px"
                mb="10px"
                backgroundColor="white"
              >
                <Box bg="#f9f9f9" p="5px" borderRadius="8px">
                  <Image src={getFileIcon(attachment.name)} w="16px" />
                </Box>
                <Link
                  href={attachment.link}
                  isExternal
                  fontSize="14px"
                  color="#2B6CB0"
                  ml="5px"
                >
                  {attachment.name}
                </Link>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PastMedications;
