import formAPIdoctorassessment from "./doctorassessment";


var formattedOutput = null;

const formAPIModeldoctorassessment = (data) => {

  if (!Array.isArray(data)) {
    console.error("Invalid data: Expected an array, but received:", data);
    return [];
  }
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIdoctorassessment(item));


  return formattedOutput
};

export default formAPIModeldoctorassessment