import moment from "moment";
export default function formAPIScreenList({
    name,
    description,
    masterscreenid,
    screendetails
    
   

})
{
  return Object.freeze({
    name,
    description,
    masterscreenid,
    screendetails
   
  });
}