import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Box,
  Text,
  RadioGroup,
  Stack,
  Heading,
  Image,
  Button,
  Flex,
  Checkbox,
  Divider,
} from "@chakra-ui/react";
import ModalRight from "../../modal-right";
import SearchBar from "../../search-bar";
import PrimaryButton from "../../primary-button";
import PrimaryInput from "../../primary-input";
import DatePicker from "../../date-picker";
import {
  addAllergies,
  addMedicalConditions,
  FetchAllergyDetails,
  getmedicalcondition,
} from "../../../middleware/services/messages";
import { format } from "date-fns";
import {
  actionAccessMain,
  getLoginID,
  handleAlphaNumericDotAndSpace,
} from "../../../auth";
import { uploadImageInProfile } from "../../../middleware/services/profile";
import moment from "moment";

const NoAllergyDataScreen = forwardRef(
  (
    {
      width,
      height,
      patientId,
      showErrorToast,
      showSuccessToast,
      showLoader,
      dismissLoader,
      fetchPatientHealthData,
    },
    ref
  ) => {
    const openFromStartVisitOverview = () => {
      console.log("Function called!");
      openAllergiesModal();
    };

    useImperativeHandle(ref, () => ({
      openFromStartVisitOverview,
    }));

    const [actionAccessPatients, setActionAccessPatients] = useState(null);
    useEffect(() => {
      setActionAccessPatients(actionAccessMain(4));
    }, []);

    var [conditionsList, setConditionsList] = useState(null);
    var [reactionList, setReactionList] = useState(null);
    const [searchValue, setSearchValue] = useState("");
    const [selectedConditions, setSelectedConditions] = useState([]);
    const [selectedConditionsInEdit, setSelectedConditionsInEdit] = useState(
      []
    );
    const [selectedReactions, setSelectedReactions] = useState([]);
    const [uploadedDocuments, setUploadedDocuments] = useState([]);
    const [selectedDates, setSelectedDates] = useState({});
    const [reasonsArray, setReasonsArray] = useState([]);
    const [allergiesContinueBtnDisabled, setAllergiesContinueBtnDisabled] =
      useState(true);
    const [reactionsContinueBtnDisabled, setReactionsContinueBtnDisabled] =
      useState(true);
    const [
      reactionsContinueBtnDisabledEdit,
      setReactionsContinueBtnDisabledEdit,
    ] = useState(true);
    const [reactionsContinueBtnDisabled2, setReactionsContinueBtnDisabled2] =
      useState(true);
    const [patientallergyid, setPatientallergyid] = useState("");
    const [allergytype, setAllergytype] = useState("");
    const [allergyID, setAllergieID] = useState("");
    const [identifiedData, setIdentifiedData] = useState("");
    const [documentURLs, setDocumentURLs] = useState([]);
    const [allergytype_cd, setAllergytype_cd] = useState("");
    const [patientAllergyId, setPatientAllergyId] = useState("");

    const [typedCondition, setTypedCondition] = useState("");
    const [typedReaction, setTypedReaction] = useState("");
    const indexMap = {};

    const floatLabel = {
      color: "#384052",
      fontSize: "0.775rem",
      position: "relative",
      top: "-4px",
      left: "-4px",
      opacity: "0.5",
      width: "100%",
    };

    const addCondition = React.useRef();
    const addCondition2 = React.useRef();
    const addCondition3 = React.useRef();
    const addCondition4 = React.useRef();

    const reactionListModal = React.useRef();

    const fetchAllergiesList = async () => {
      let patientid = patientId;
      try {
        const result = await getmedicalcondition({
          type: "allergies",

          patientid: patientid,
        });
        if (result.output.result == "success") {
          setConditionsList(result.output.data);
        } else {
          showErrorToast("Something went wrong!");
        }
      } catch (error) {
        console.error("Error", error);
      }
    };

    const [allergyData, setAllergyData] = useState(null);

    const [patientid, setPatientid] = useState("");

    useEffect(() => {
      if (selectedConditions.length === 0) {
        setAllergiesContinueBtnDisabled(true);
      } else {
        setAllergiesContinueBtnDisabled(false);
      }
    }, [selectedConditions]);

    useEffect(() => {
      let filteredReactions = reasonsArray.filter(
        (item) => item.allergy_id == allergyID
      );
      console.log(filteredReactions, " filteredReactions");
      if (filteredReactions.length > 0) {
        setReactionsContinueBtnDisabled2(false);
      } else {
        setReactionsContinueBtnDisabled2(true);
      }
    }, [reasonsArray]);

    const getUniqueObjects = (arr, key) => {
      return arr.reduce((acc, current) => {
        if (!acc.some((item) => item[key] === current[key])) {
          acc.push(current);
        }
        return acc;
      }, []);
    };

    useEffect(() => {
      const uniqueObjects = getUniqueObjects(reasonsArray, "allergy_id");
      // console.log(uniqueArray.length , " uniqueArray.lengt")
      // console.log(selectedConditions.length , " selectedConditions.lengt")
      if (reasonsArray.length > 0 && uniqueObjects.length > 0) {
        if (selectedConditions.length == uniqueObjects.length) {
          setReactionsContinueBtnDisabled(false);
        } else {
          setReactionsContinueBtnDisabled(true);
        }
      }
    }, [reasonsArray]);

    useEffect(() => {
      if (reasonsArray.length === 0) {
        setReactionsContinueBtnDisabledEdit(true);
      } else {
        setReactionsContinueBtnDisabledEdit(false);
      }
    }, [reasonsArray]);

    useEffect(() => {
      setDocumentURLs(uploadedDocuments);
    }, [uploadedDocuments]);

    const fetchallergydata = async (id) => {
      showLoader();
      try {
        const result = await FetchAllergyDetails({
          patientid: id,
        });

        if (result != null) {
          console.log(result);
          setAllergyData(result);
        } else {
          setAllergyData(null);
        }
        dismissLoader();
      } catch (error) {
        console.error("Error in home:", error);
      }
    };

    useEffect(() => {
      setPatientid(patientId);

      fetchallergydata(patientId);
    }, []);

    const fetchReactionsList = async () => {
      let patientid = patientId;
      try {
        const result = await getmedicalcondition({
          type: "reaction",

          patientid: patientid,
        });
        if (result.output.result == "success") {
          setReactionList(result.output.data);
        } else {
          showErrorToast("Something went wrong!");
        }
      } catch (error) {
        console.error("Error", error);
      }
    };

    const AddReactionsInInput = (allergyid) => {
      // enter manually

      if (typedReaction !== "") {
        const newSelectedCondition = {
          condition: typedReaction,
          allergy_id: allergyid,
          condition_cd: null,
          conditiontype: "other",
        };

        setReasonsArray((prevConditions) => {
          // Filter out any existing condition with the same type
          // const filteredConditions = prevConditions.filter(
          //   (item) => item?.conditiontype !== "other"
          // );
          // Add the new condition
          return [...prevConditions, newSelectedCondition];
        });
      }

      //--------------------------------

      addCondition3.current.closeRight();
      // const selectedReactionsArray = Object.values(selectedReactions);
      // handleReactionChange(allergyid, selectedReactionsArray);
    };

    const handleCheckboxChange = (condition) => {
      const isSelected = selectedConditions.some(
        (item) => item.condition_cd === condition.condition_cd
      );

      if (isSelected) {
        setSelectedConditions((prevConditions) =>
          prevConditions.filter(
            (item) => item.condition_cd !== condition.condition_cd
          )
        );
      } else {
        setSelectedConditions([...selectedConditions, condition]);
      }
    };

    const handleReactionCheckboxChange = (condition, allergyid) => {
      const isSelected = reasonsArray.some(
        (item) =>
          item.allergy_id === allergyid &&
          item.condition_cd === condition.condition_cd
      );

      if (isSelected) {
        // If already selected, remove the item from reasonsArray
        setReasonsArray((prevConditions) =>
          prevConditions.filter(
            (item) =>
              item.allergy_id !== allergyid ||
              item.condition_cd !== condition.condition_cd
          )
        );
      } else {
        // If not selected, add the item to reasonsArray
        setReasonsArray([
          ...reasonsArray,
          { ...condition, allergy_id: allergyid },
        ]);
      }
    };

    const removeTag = (index) => {
      const updatedReasonsArray = [...reasonsArray];
      updatedReasonsArray.splice(index, 1);
      setReasonsArray(updatedReasonsArray);
    };
    // new code added
    const removeReaction = (allergyId, reactionId) => {
      setReasonsArray((prev) =>
        prev.filter(
          (tag) =>
            !(tag.allergy_id === allergyId && tag.condition_cd === reactionId)
        )
      );
    };

    const formatDateDo = (dateString) => {
      if (!dateString) return "-";
      return moment(dateString).format("Do MMM, YYYY");
    };
    const handleDelete = (index) => {
      const updatedUrls = [...uploadedDocuments];
      updatedUrls.splice(index, 1);
      setUploadedDocuments(updatedUrls);
    };
    const openAllergiesModal = () => {
      // if (conditionsList == null) {
      fetchAllergiesList();
      //  }
      setReasonsArray([]);
      setSearchValue("");
      setSelectedConditions([]);
      setSelectedReactions([]);
      setUploadedDocuments([]);
      setDocumentURLs([]);
      setTypedCondition("");
      setTypedReaction("");
      addCondition.current.openRight();
    };

    const fileInputRef = useRef(null);

    const addSurgeriesUploadDocument = (condition_cd) => {
      if (condition_cd) {
        fileInputRef.current.setAttribute("data-surgery-cd", condition_cd);
        fileInputRef.current.click();
      } else {
        fileInputRef.current.setAttribute("data-surgery-cd", null);
        fileInputRef.current.setAttribute("data-surgery", "other");
        fileInputRef.current.click();
      }
    };

    const handleAllergiesUpload = async (e) => {
      e.preventDefault();

      let condition_cd = e.target.getAttribute("data-surgery-cd");
      if (condition_cd === "null") {
        condition_cd = null;
      }
      showLoader();

      const file2 = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file2);
      const result = await uploadImageInProfile(file2, file2.name);
      const filename = result.name;
      setUploadedDocuments((prevDocuments) => [
        ...prevDocuments,
        { condition_cd, filename },
      ]);
      showSuccessToast("Document Uploaded Successfully.");
      e.target.value = "";
      dismissLoader();
    };

    const handleCondition = (e) => {
      const inputText = e.target.value;
      const maxLength = 80;

      if (inputText.length <= maxLength) {
        setTypedCondition(inputText);
      }
      if (e.target.value) {
        setAllergiesContinueBtnDisabled(false);
      } else {
        setAllergiesContinueBtnDisabled(true);
      }
    };

    const handleReaction = (e) => {
      const inputText = e.target.value;
      const maxLength = 80;

      if (inputText.length <= maxLength) {
        setTypedReaction(inputText);
      }
      if (e.target.value) {
        setReactionsContinueBtnDisabled2(false);
        setReactionsContinueBtnDisabledEdit(false);
      } else {
        setReactionsContinueBtnDisabled2(true);
        setReactionsContinueBtnDisabledEdit(true);
      }
    };

    const showReactionList = (allergyid) => {
      let filteredReactions = reasonsArray.filter(
        (item) => item.allergy_id == allergyid
      );
      if (filteredReactions.length > 0) {
        setReactionsContinueBtnDisabled2(false);
      } else {
        setReactionsContinueBtnDisabled2(true);
      }
      // if (reactionList == null) {
      fetchReactionsList();
      // }
      setTypedCondition("");
      setTypedReaction("");
      setSearchValue("");
      setAllergieID(allergyid);
      addCondition3.current.openRight();
    };

    const handleDateChange = (conditionCd, date) => {
      const formattedDate = format(date, "yyyy-MM-dd");
      setSelectedDates((prevDates) => ({
        ...prevDates,
        [conditionCd]: formattedDate,
      }));
    };

    const AddMedicalConditions = () => {
      if (selectedConditions.length === 0 && typedCondition == "") {
        showErrorToast("Please Select a Allergy to Continue");
      } else {
        // enter manually
        if (typedCondition !== "") {
          const newSelectedCondition = {
            condition: typedCondition,
            condition_cd: null,
            conditiontype: "other",
          };

          setSelectedConditions((prevConditions) => {
            // Filter out any existing condition with the same type
            const filteredConditions = prevConditions.filter(
              (item) => item?.conditiontype !== "other"
            );
            // Add the new condition
            return [...filteredConditions, newSelectedCondition];
          });
        }

        //--------------------------------
        setSelectedDates({});
        addCondition2.current.openRight();
      }
    };

    var inputForAdd = {
      patientid: patientId,
      doctorid: getLoginID(),
      allergy: selectedConditions.map((condition, index) => ({
        condition: condition.condition,
        condition_cd: condition.condition_cd,
        allergytype_cd: condition.condition_cd,
        allergyidentifieddate: selectedDates[condition.condition_cd] || "",
        ...(condition.conditiontype && {
          conditiontype: condition.conditiontype,
        }),
        reactions:
          reasonsArray
            .filter(
              (reaction) => reaction.allergy_id === condition.condition_cd
            )
            .map((filteredReaction) => ({
              reaction_cd: filteredReaction.condition_cd,
              reaction: filteredReaction.condition,
            })) || [],
        documents: uploadedDocuments
          .filter((doc) => doc.condition_cd == condition.condition_cd)
          .map(({ condition_cd, filename }) => ({
            condition_cd,
            filename,
          })),
      })),
    };

    const AddMedicalConditionsCall = () => {
      const addConditions = async () => {
        showLoader();
        try {
          const result = await addAllergies(inputForAdd);
          if (result.output.result == "success") {
            showSuccessToast("Successfully Added");
            addCondition2.current.closeRight();
            addCondition.current.closeRight();
            fetchPatientHealthData()
            setAllergieID("");
            fetchallergydata(patientId);
          } else {
            showErrorToast("Something went wrong!");
          }
        } catch (error) {
          console.error("Error in allergies:", error);
        }
        dismissLoader();
      };

      const isDateMissing = selectedConditions.some(
        (condition) => !selectedDates[condition.condition_cd]
      );

      const isReactionMissing = selectedConditions.some(
        (condition) =>
          !reasonsArray.some(
            (reason) => reason.allergy_id === condition.condition_cd
          )
      );
      if (isDateMissing) {
        showErrorToast("Please Select the Date");
        return false;
      } else if (isReactionMissing) {
        showErrorToast("Please Select the reaction");
        return false;
      } else {
        addConditions();
      }
    };

    return (
      <Box>
        <Box
          bg="#fcfcfd"
          width={width}
          height={height}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <PrimaryButton
            fontSize="14px"
            variant="mdBtn"
            btnIcon="/assets/imgs/add.png"
            buttonText="Add Allergy"
            onClick={openAllergiesModal}
            isDisabled={actionAccessPatients?.create ? false : true}
          />
          <Text textAlign="center" position="absolute" top="50%" left="40%">
            No Allergy Data Available
          </Text>
        </Box>
        {/* Add Modal Here  */}
        <ModalRight
          ref={addCondition}
          header="Add allergy"
          body={
            <>
              <SearchBar
                passInputPlace="Search Allergy"
                onChange={(e) =>
                  setSearchValue(handleAlphaNumericDotAndSpace(e.target.value))
                }
                searchValue={searchValue}
              />
              <RadioGroup>
                <Stack direction="column" gap="0">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    borderBottom="1px solid #e2e8f0"
                    py="15px"
                  >
                    <Box display="flex" justifyContent="end" w="100%">
                      <Stack direction="column" gap="0" w="100%">
                        {conditionsList && conditionsList.length > 0 ? (
                          conditionsList.some((category) =>
                            category.data.some((condition) =>
                              condition.condition
                                .toLowerCase()
                                .includes(searchValue.toLowerCase())
                            )
                          ) ? (
                            conditionsList.map((category) => (
                              <React.Fragment key={category.category_id}>
                                {category.data
                                  .filter((condition) =>
                                    condition.condition
                                      .toLowerCase()
                                      .includes(searchValue.toLowerCase())
                                  )
                                  .map((condition) => (
                                    <label key={condition.condition_cd}>
                                      <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        borderBottom="1px solid #e2e8f0"
                                        p="15px"
                                        height="50px"
                                        width="100%"
                                        sx={{
                                          _hover: {
                                            border: "2px solid #384052",
                                            borderRadius: "10px",
                                          },
                                        }}
                                      >
                                        <Box
                                          key={condition.condition_cd}
                                          display="flex"
                                          alignItems="center"
                                        >
                                          <Text
                                            fontSize="16px"
                                            fontWeight="600"
                                          >
                                            {condition.condition}
                                            <br />
                                            <Box
                                              as="span"
                                              fontSize="12px"
                                              fontWeight="500"
                                              color="#6A707D"
                                            ></Box>
                                          </Text>
                                        </Box>
                                        <Box
                                          display="flex"
                                          justifyContent="end"
                                        >
                                          <Checkbox
                                            colorScheme="green"
                                            sx={{
                                              ".chakra-checkbox__control": {
                                                width: "20px",
                                                height: "20px",
                                                borderRadius: "5px",
                                              },
                                            }}
                                            onChange={() =>
                                              handleCheckboxChange(condition)
                                            }
                                            isChecked={selectedConditions.some(
                                              (item) =>
                                                item.condition_cd ===
                                                condition.condition_cd
                                            )}
                                          ></Checkbox>
                                        </Box>
                                      </Box>
                                    </label>
                                  ))}
                              </React.Fragment>
                            ))
                          ) : (
                            <Box
                              p="15px"
                              fontSize="16px"
                              fontWeight="600"
                              textAlign="center"
                            >
                              No matching allergies found!
                            </Box>
                          )
                        ) : (
                          <p>Loading...</p>
                        )}
                        <Divider />
                        <br />
                        <Heading
                          as="h2"
                          fontSize="12px"
                          pl="5px"
                          textTransform="uppercase"
                          fontWeight="600"
                          my="10px"
                        >
                          Other Allergy
                        </Heading>
                        <PrimaryInput
                          inputPlace="Allergy"
                          variant="bothSide"
                          onChange={handleCondition}
                          inputValue={typedCondition}
                        />
                        <br />
                      </Stack>
                    </Box>
                  </Box>
                </Stack>
              </RadioGroup>
              <ModalRight
                ref={addCondition3}
                handlebackarrow={() => addCondition3.current.closeRight()}
                withBackBtn={true}
                withBackHeader="Reaction"
                body={
                  <>
                    <SearchBar
                      passInputPlace="Search Reaction"
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                    <RadioGroup>
                      <Stack direction="column" gap="0">
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          borderBottom="1px solid #e2e8f0"
                          py="15px"
                        >
                          <Box display="flex" justifyContent="end" w="100%">
                            <Stack direction="column" gap="0" w="100%">
                              {reactionList && reactionList.length > 0 ? (
                                reactionList.map((category) =>
                                  category.data
                                    .filter((condition) =>
                                      condition.condition
                                        .toLowerCase()
                                        .includes(searchValue.toLowerCase())
                                    )
                                    .map((condition) => (
                                      <label key={condition.condition_cd}>
                                        <Box
                                          display="flex"
                                          justifyContent="space-between"
                                          borderBottom="1px solid #e2e8f0"
                                          p="15px"
                                          height="50px"
                                          width="100%"
                                          sx={{
                                            _hover: {
                                              border: "2px solid #384052",
                                              borderRadius: "10px",
                                            },
                                          }}
                                        >
                                          <Box
                                            key={condition.condition_cd}
                                            display="flex"
                                            alignItems="center"
                                          >
                                            <Text
                                              fontSize="16px"
                                              fontWeight="600"
                                            >
                                              {condition.condition}
                                              <br />
                                              <Box
                                                as="span"
                                                fontSize="12px"
                                                fontWeight="500"
                                                color="#6A707D"
                                              ></Box>
                                            </Text>
                                          </Box>
                                          <Box
                                            display="flex"
                                            justifyContent="end"
                                          >
                                            <Checkbox
                                              colorScheme="green"
                                              sx={{
                                                ".chakra-checkbox__control": {
                                                  width: "20px",
                                                  height: "20px",
                                                  borderRadius: "5px",
                                                },
                                              }}
                                              onChange={() =>
                                                handleReactionCheckboxChange(
                                                  condition,
                                                  allergyID
                                                )
                                              }
                                              isChecked={reasonsArray.some(
                                                (item) =>
                                                  item.allergy_id ===
                                                    allergyID &&
                                                  item.condition_cd ===
                                                    condition.condition_cd
                                              )}
                                            ></Checkbox>
                                          </Box>
                                        </Box>
                                      </label>
                                    ))
                                )
                              ) : (
                                <p>Loading...</p>
                              )}
                              <Divider />
                              <br />
                              <Heading
                                as="h2"
                                fontSize="12px"
                                pl="5px"
                                textTransform="uppercase"
                                fontWeight="600"
                                my="10px"
                              >
                                Other Reactions
                              </Heading>
                              <PrimaryInput
                                inputPlace="Reactions"
                                variant="bothSide"
                                onChange={handleReaction}
                                inputValue={typedReaction}
                              />
                              <br />
                            </Stack>
                          </Box>
                        </Box>
                      </Stack>
                    </RadioGroup>
                  </>
                }
                footerActions={
                  <>
                    <Box w="100%" display="flex" flexDirection="row" gap="15px">
                      <PrimaryButton
                        buttonText="Add reactions"
                        isDisabled={reactionsContinueBtnDisabled2}
                        onClick={() => AddReactionsInInput(allergyID)}
                      />
                    </Box>
                  </>
                }
              />

              <ModalRight
                ref={addCondition2}
                handlebackarrow={() => addCondition2.current.closeRight()}
                withBackBtn={true}
                withBackHeader="Add allergy"
                body={
                  <>
                    <Box display="flex" flexDir="column" gap="20px">
                      {selectedConditions.map((condition, index) => (
                        <Box key={index}>
                          <Text fontSize="16px" py="10px" fontWeight="600">
                            {condition.condition}
                          </Text>
                          <DatePicker
                            disableFutureDates={true}
                            onDateChange={(selectedDateValue) =>
                              handleDateChange(
                                condition.condition_cd,
                                selectedDateValue
                              )
                            }
                            placeholderProp="Date*"
                          />

                          <Flex w="100%" alignItems="center" gap="2">
                            <Box
                              as="div"
                              role="button"
                              aria-haspopup="dialog"
                              display="flex"
                              alignItems="center"
                              tabIndex={0}
                              border="1px solid #e2e8f0"
                              borderRadius="0 0 10px 10px"
                              p="10px"
                              height="auto"
                              width="100%"
                              sx={{
                                _hover: { border: "2px solid #384052" },
                              }}
                              onClick={() =>
                                showReactionList(condition.condition_cd)
                              }
                              minHeight="48px"
                              flexWrap="wrap"
                            >
                              <Box
                                key={condition.condition_cd}
                                display="flex"
                                alignItems="center"
                                flexWrap="wrap"
                                gap="5px"
                                w="100%"
                                justifyContent={
                                  reasonsArray.length === 0
                                    ? "space-between"
                                    : "start"
                                }
                              >
                                {reasonsArray.length === 0 ? (
                                  <Text pl="2" color="gray.400">
                                    Reaction*
                                  </Text>
                                ) : reasonsArray.some(
                                    (item) =>
                                      item.allergy_id === condition.condition_cd
                                  ) ? (
                                  <Text
                                    pl="2"
                                    color="gray.400"
                                    sx={{
                                      ...(reasonsArray.length === 0
                                        ? {}
                                        : floatLabel),
                                    }}
                                  >
                                    Reaction*
                                  </Text>
                                ) : (
                                  <Text pl="2" color="gray.400">
                                    Reaction*
                                  </Text>
                                )}
                                {reasonsArray.length === 0 && (
                                  <Image src="/assets/imgs/right-icon.png" />
                                )}

                                {reasonsArray
                                  .filter(
                                    (tag) =>
                                      tag.allergy_id == condition.condition_cd
                                  )
                                  .map((tag, index) => (
                                    <Box
                                      key={index}
                                      display="flex"
                                      alignItems="center"
                                      gap="5px"
                                      bg="gray.100"
                                      borderRadius="full"
                                      wordBreak="break-all"
                                      p="1"
                                    >
                                      <Text fontSize="sm" px="5px">
                                        {tag.condition}
                                      </Text>
                                      <Box
                                        as="button"
                                        p="5px"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          // removeTag(index);
                                          removeReaction(
                                            condition.condition_cd,
                                            tag.condition_cd
                                          ); // Updated remove function
                                        }}
                                        aria-label="Remove condition"
                                      >
                                        <Image src="/assets/imgs/close.png" />
                                      </Box>
                                    </Box>
                                  ))}
                              </Box>
                              <Box display="flex" justifyContent="end"></Box>
                            </Box>
                          </Flex>
                          {documentURLs?.map((document, index) => {
                            if (
                              document.condition_cd == condition.condition_cd ||
                              (document.condition_cd == null &&
                                condition.condition_cd == null)
                            ) {
                              if (!indexMap[document.condition_cd]) {
                                indexMap[document.condition_cd] = 1;
                              }
                              const docIndex = indexMap[document.condition_cd];
                              indexMap[document.condition_cd]++; // Increment index for next document with same condition_cd

                              return (
                                <Box key={index} mb="2" mt="2">
                                  <Button
                                    as="a"
                                    rel="noopener noreferrer"
                                    w="100%"
                                    h="48px"
                                    borderRadius="10px"
                                    border="1px solid #E6E7E9"
                                    display="flex"
                                    justifyContent="space-between"
                                    bg="white"
                                    color="#1C4EC5"
                                    sx={{ _hover: { bg: "none" } }}
                                    style={{
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      fontSize: "12px",
                                    }}
                                  >
                                    Document {docIndex}
                                    <Box
                                      as="span"
                                      onClick={() => handleDelete(index)}
                                    >
                                      <Image src="/assets/imgs/delete.png" />
                                    </Box>
                                  </Button>
                                </Box>
                              );
                            }
                          })}
                          <Box
                            onClick={() =>
                              addSurgeriesUploadDocument(condition.condition_cd)
                            }
                            as="button"
                            display="flex"
                            alignItems="center"
                            gap="10px"
                            mt="5px"
                            fontSize="14px"
                            fontWeight="600"
                            color="#1C4EC5"
                            textAlign="left"
                          >
                            <Image w="11px" src="/assets/imgs/add-blue.png" />
                            Add Document
                          </Box>
                          <input
                            type="file"
                            id="file-upload"
                            onChange={(e) => handleAllergiesUpload(e)}
                            ref={fileInputRef}
                            style={{ display: "none" }}
                          />
                          {/* <Divider /> */}
                        </Box>
                      ))}
                    </Box>
                  </>
                }
                footerActions={
                  <>
                    <Box w="100%" display="flex" flexDirection="row" gap="15px">
                      <PrimaryButton
                        buttonText="Add Allergy"
                        isDisabled={reactionsContinueBtnDisabled}
                        onClick={AddMedicalConditionsCall}
                      />
                    </Box>
                  </>
                }
              />
            </>
          }
          footerActions={
            <>
              <Box w="100%" display="flex" flexDirection="row" gap="15px">
                <PrimaryButton
                  buttonText="Continue"
                  isDisabled={allergiesContinueBtnDisabled}
                  onClick={AddMedicalConditions}
                />
              </Box>
            </>
          }
        />
      </Box>
    );
  }
);

export default NoAllergyDataScreen;
