import { Box, Image, SimpleGrid, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FetchInvestigationDetails } from "../../middleware/services/messages";
import moment from "moment";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import { useLocation } from "react-router-dom";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";

const Investigations = ({id, backFunction}) => {
  const location = useLocation();
  const [patientid, setPatientid] = useState("");
  const [investigationData, setInvestigationData] = useState(null);
  const { isLoading, showLoader, dismissLoader } = useLoader();

  useEffect(() => {
    const currentUrl = window.location.href;
    const searchParams = new URLSearchParams(currentUrl.split('?') [1]);
    const id = searchParams.get('id');
    setPatientid(id);
    fetchinvestigationdata(id)   
  }, []);
  
  const fetchinvestigationdata = async (id) => {
    showLoader()
        try {
          const result = await FetchInvestigationDetails({
            patientid: id,
          });

          console.log(result)
          if (result != null) {
            setInvestigationData(result);
          } else {
            setInvestigationData('')
          }
          dismissLoader()
      } catch (error) {
          console.error("Error in home:", error);
        }
      };

      const formatDate = (dateString) => {
        if (!dateString) return "-";
        return moment(dateString).format("Do MMM, YYYY");
      };

      // console.log(patientid, "investigation IDDDDD")
      // console.log(investigationData, "investigation Dataaaaaaa")

  return (
    <>
    {isLoading && <Loader />}
    <Box display={{base: "none", lg: "block"}} width='100%'>
      {investigationData && investigationData.length > 0 ? (
        <SimpleGrid columns={2} spacing="1rem" w="100%">
          {investigationData.map((investigation,index) => (
          <Box
            border="1px solid #E6E7E9"
            bg="white"
            w="100%"
            borderRadius="0.75rem"
            p={4}
            key={index}
          >
            <Text fontSize="12px" fontWeight="500" color="#6A707D">
              {/* White blood cells */}
              {investigation.eventtitle}
            </Text>
            <Text display="inline-block" fontSize="22px" fontWeight="600">
              {/* 10 */}
              {investigation.resultvalue}
            </Text>
            <Text
              display="inline-block"
              fontSize="15px"
              pl={"5px"}
              fontWeight="400"
            >
              {/* 10^3/µl */}
              {investigation.resultuom}
            </Text>
            {/* <Text fontSize="12px" fontWeight="500" color="#6A707D">
              May 15, 2024
              {formatDate(investigation.verifieddate)}       
            </Text> */}

  <Box
                                              display="flex"
                                              flexDirection="row"
                                              justifyContent="space-between"
                                              >
                                              <Box>
                                                  <Text
                                                  fontSize="12px"
                                                  fontWeight="500"
                                                  color="#6A707D"
                                                  >
                                                  {/* May 15, 2024 */}
                                                  {formatDate(investigation.verifieddate)}
                                                  </Text>
                                              </Box>
                                              {investigation.actualresultvalue && (
                                        investigation.status === 'low' && investigation.trendColor === 'red' ? (
                                          <Box display="flex" alignItems="center" transform="scaleY(-1)">
                                            <Image w="12px" h="auto" src="/assets/imgs/red-chart.png" alt="Normal" />
                                            {/* <Text as="span" fontSize="12px" fontWeight="600" ml="2px">OK</Text> */}
                                          </Box>
                                        ) : investigation.status === 'high' && investigation.trendColor === 'red' ? (
                                          <Box display="flex" alignItems="center">
                                            <Image w="12px" h="auto" src="/assets/imgs/red-chart.png" alt="Normal" />
                                            {/* <Text as="span" fontSize="12px" fontWeight="600" ml="2px">OK</Text> */}
                                          </Box>
                                        ) : investigation.status === 'low' && investigation.trendColor === 'green' ? (
                                          <Box display="flex" alignItems="center">
                                            <Image w="12px" h="auto" src="/assets/imgs/green-chart.png" alt="Normal" />
                                            {/* <Text as="span" fontSize="12px" fontWeight="600" ml="2px">OK</Text> */}
                                          </Box>
                                        ) : investigation.status === 'high' && investigation.trendColor === 'green' ? (
                                          <Box display="flex" alignItems="center" transform="scaleY(-1)">
                                            <Image w="12px" h="auto" src="/assets/imgs/green-chart.png" alt="Normal" />
                                            {/* <Text as="span" fontSize="12px" fontWeight="600" ml="2px">OK</Text> */}
                                          </Box>
                                        ) : null
                                      )}
                                              </Box>
            
          </Box>

          ))}
        </SimpleGrid>

      ) : (
        <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                              gap="10px"
                              alignItems="center"
                              h="calc(100vh - 100px)"
                              w="100%"
                            >
                              <Box textAlign="left" fontSize="16px" fontWeight="600">
                                <Image
                                  w="26px"
                                  opacity="0.5"
                                  src="/assets/imgs/investigation.png"
                                />
                              </Box>
                              <Text textAlign="left" fontSize="12px" fontWeight="500">
                                No Data available
                              </Text>
                            </Box>
      )}
    </Box>

    <Box w="100%" display={{base: "block", lg: "none"}}>
      <MobileSecondaryNav
        header="Investigations"
        handlebackarrow={backFunction}
      />

      <Box h="calc(100vh - 120px)" overflow='hidden auto' p="1rem">
      {investigationData && investigationData.length > 0 ? (
        <SimpleGrid columns={2} spacing="1rem" w="100%">
          {investigationData.map((investigation,index) => (
          <Box
            border="1px solid #E6E7E9"
            bg="white"
            w="100%"
            borderRadius="0.75rem"
            p={4}
            key={index}
          >
            <Text fontSize="12px" fontWeight="500" color="#6A707D">
              {/* White blood cells */}
              {investigation.eventtitle}
            </Text>
            <Text display="inline-block" fontSize="22px" fontWeight="600">
              {/* 10 */}
              {investigation.resultvalue}
            </Text>
            <Text
              display="inline-block"
              fontSize="15px"
              pl={"5px"}
              fontWeight="400"
            >
              {/* 10^3/µl */}
              {investigation.resultuom}
            </Text>
            {/* <Text fontSize="12px" fontWeight="500" color="#6A707D">
              May 15, 2024
              {formatDate(investigation.verifieddate)}       
            </Text> */}

            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                >
                <Box>
                    <Text
                    fontSize="12px"
                    fontWeight="500"
                    color="#6A707D"
                    >
                    {/* May 15, 2024 */}
                    {formatDate(investigation.verifieddate)}
                    </Text>
                </Box>
                {investigation.status === 'low' && investigation.trendColor === 'red' ? (
          <Box display="flex" alignItems="center" transform="scaleY(-1)">
            <Image w="12px" h="auto" src="/assets/imgs/red-chart.png" alt="Normal" />

          </Box>
          ) : investigation.status === 'high' && investigation.trendColor === 'red' ? (
          <Box display="flex" alignItems="center">
            <Image w="12px" h="auto" src="/assets/imgs/red-chart.png" alt="Normal" />
            
          </Box>
          ) : investigation.status === 'low' && investigation.trendColor === 'green' ? (
          <Box display="flex" alignItems="center">
            <Image w="12px" h="auto" src="/assets/imgs/green-chart.png" alt="Normal" />

          </Box>
          ) : investigation.status === 'high' && investigation.trendColor === 'green' ? (
          <Box display="flex" alignItems="center" transform="scaleY(-1)">
            <Image w="12px" h="auto" src="/assets/imgs/green-chart.png" alt="Normal" />

          </Box>
          ) : null}
                </Box>
            
          </Box>

          ))}
        </SimpleGrid>

      ) : (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          gap="10px"
          alignItems="center"
          h="calc(100vh - 100px)"
          w="100%"
        >
          <Box textAlign="left" fontSize="16px" fontWeight="600">
            <Image
              w="26px"
              opacity="0.5"
              src="/assets/imgs/investigation.png"
            />
          </Box>
          <Text textAlign="left" fontSize="12px" fontWeight="500">
            No Data available
          </Text>
        </Box>
      )}
      </Box>
    </Box>
    </>
  );
};

export default Investigations;
