import formapimodalInvoices from "./invoices";

export default function formapimodalInvoicesPagination({
    data,
    pageno,
    pagesize,
    count
  })
  {
  return Object.freeze({
    
    data:data !== null ? formapimodalInvoices(data) : [],
    pageno,
    pagesize,
    count,
  });
  }