import { Box, Image, SimpleGrid, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FetchVitalsDetails } from "../../middleware/services/messages";
import moment from "moment";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import { useNavigate } from "react-router-dom";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";


const PatientVitalsIndex = ({id, backFunction}) => {

  const [patientid, setPatientid] = useState("");
  const [vitalsData, setVitalsData] = useState(null);
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const navigate = useNavigate()
  useEffect(() => {
    fetchvitaldata(id)   

  }, []);
  
  const fetchvitaldata = async (id) => {
    showLoader()
        try {
          const result = await FetchVitalsDetails({
            patientid: id,
          });

          if (result != null) {
            console.log(result)
            setVitalsData(result);
          } else {
          }
          dismissLoader()
      } catch (error) {
          console.error("Error in home:", error);
        }
      };

      const formatDate = (dateString) => {
        if (!dateString) return "-";
        return moment(dateString).format("Do MMM, YYYY");
      };

      // console.log(patientid, "allergy IDDDDD")
      // console.log(vitalsData, "allergy Dataaaaaaa")
  return (
    <>
    {isLoading && <Loader />}
      <Box w="100%" display={{base: "none", lg: "block"}}>
        {vitalsData && vitalsData.length>0 ? (
          
          <SimpleGrid  columns={2} gridGap="1rem">
          {vitalsData.map((vital,index) => (
          <Box border="1px solid #E6E7E9"  borderRadius="0.75rem" bg="white" p={4}>
            <Text fontSize="12px" fontWeight="500" color="#6A707D">
              {vital.vitalname}
            </Text>
            <Text display="inline-block" fontSize="22px" fontWeight="600">
              {/* 98.6 */}
              {vital.vitalvalue}
            </Text>
            <Text
              display="inline-block"
              fontSize="15px"
              pl={"5px"}
              fontWeight="400"
            >
              {/* °F */}
              {vital.vitalunit}
            </Text>
            <Box display="flex" justifyContent="space-between">
                <Box>
                <Text fontSize="12px" fontWeight="500" color="#6A707D">
                    {/* May 15, 2024 */}
                    {formatDate(vital.vitaladdeddate)}
                </Text>
                </Box>
                <Box display="flex" alignItems="center" mt="0.5rem" gap="0.25rem">
                  {
                    vital.status === 'High' ? (
                      <>
                      <Image w="12px" h="auto" color='#F04438' src="/assets/imgs/arrow_upward.png" />
                      <Text as="span" fontSize="12px" fontWeight="600" ml="2px" color='#F04438'>{vital.status}</Text>
                      </>
                    ) : vital.status === 'Normal' ? (
                      <>
                      <Image w="12px" h="auto" color='#6A707D' src="/assets/imgs/check.png" />
                      <Text as="span" fontSize="12px" fontWeight="600" ml="2px" color='#6A707D'>{vital.status}</Text>
                      </>
                    ) : vital.status === 'normal' ? (
                      <>
                      <Image w="12px" h="auto" color='#6A707D' src="/assets/imgs/check.png" />
                      <Text as="span" fontSize="12px" fontWeight="600" ml="2px" color='#6A707D'>{vital.status}</Text>
                      </>
                    ) : vital.status === 'Low' ? (
                      <>
                      <Image w="12px" h="auto" color='#D46F11' src="/assets/svgs/arrow_upward.svg" />
                      <Text as="span" fontSize="12px" fontWeight="600" ml="2px" color='#D46F11'>{vital.status}</Text>
                      </>
                    ): ('')
                  }
                </Box>
            </Box>
          </Box>
        ))}
        </SimpleGrid>
        ) : (
          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            gap="10px"
                            alignItems="center"
                            h="calc(100vh - 100px)"
                          >
                            <Box textAlign="left" fontSize="16px" fontWeight="600">
                              <Image
                                w="26px"
                                opacity="0.5"
                                src="/assets/imgs/heart.png"
                              />
                            </Box>
                            <Text textAlign="left" fontSize="12px" fontWeight="500">
                              No Data available
                            </Text>
                          </Box>
        )}
      </Box>

      {/* Mobile responsive */}
      <Box w="100%" display={{base: "block", lg: "none"}}>
        <MobileSecondaryNav
          header="Vitals"
          handlebackarrow={backFunction}
        />
        <Box h="calc(100vh - 120px)" overflow="hidden auto" p="1rem">
        {vitalsData && vitalsData.length>0 ? (
          
          <SimpleGrid  columns={2} gridGap="1rem">
          {vitalsData.map((vital,index) => (
          <Box border="1px solid #E6E7E9"  borderRadius="0.75rem" bg="white" p={4}>
            <Text fontSize="12px" fontWeight="500" color="#6A707D">
              {vital.vitalname}
            </Text>
            <Text display="inline-block" fontSize="22px" fontWeight="600">
              {/* 98.6 */}
              {vital.vitalvalue}
            </Text>
            <Text
              display="inline-block"
              fontSize="15px"
              pl={"5px"}
              fontWeight="400"
            >
              {/* °F */}
              {vital.vitalunit}
            </Text>
            <Box display="flex" justifyContent="space-between">
                <Box>
                <Text fontSize="12px" fontWeight="500" color="#6A707D">
                    {/* May 15, 2024 */}
                    {formatDate(vital.vitaladdeddate)}
                </Text>
                </Box>
                <Box display="flex" alignItems="center" mt="0.5rem" gap="0.25rem">
                  {
                    vital.status === 'High' ? (
                      <>
                      <Image w="12px" h="auto" color='#F04438' src="/assets/imgs/arrow_upward.png" />
                      <Text as="span" fontSize="12px" fontWeight="600" ml="2px" color='#F04438'>{vital.status}</Text>
                      </>
                    ) : vital.status === 'Normal' ? (
                      <>
                      <Image w="12px" h="auto" color='#6A707D' src="/assets/imgs/check.png" />
                      <Text as="span" fontSize="12px" fontWeight="600" ml="2px" color='#6A707D'>{vital.status}</Text>
                      </>
                    ) : vital.status === 'normal' ? (
                      <>
                      <Image w="12px" h="auto" color='#6A707D' src="/assets/imgs/check.png" />
                      <Text as="span" fontSize="12px" fontWeight="600" ml="2px" color='#6A707D'>{vital.status}</Text>
                      </>
                    ) : vital.status === 'Low' ? (
                      <>
                      <Image w="12px" h="auto" color='#D46F11' src="/assets/svgs/arrow_upward.svg" />
                      <Text as="span" fontSize="12px" fontWeight="600" ml="2px" color='#D46F11'>{vital.status}</Text>
                      </>
                    ): ('')
                  }
                </Box>
            </Box>
          </Box>
        ))}
        </SimpleGrid>
        ) : (
          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            gap="10px"
                            alignItems="center"
                            h="calc(100vh - 100px)"
                          >
                            <Box textAlign="left" fontSize="16px" fontWeight="600">
                              <Image
                                w="26px"
                                opacity="0.5"
                                src="/assets/imgs/heart.png"
                              />
                            </Box>
                            <Text textAlign="left" fontSize="12px" fontWeight="500">
                              No Data available
                            </Text>
                          </Box>
        )}
        </Box>
      </Box>
    </>
  );
};

export default PatientVitalsIndex;
