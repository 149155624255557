import { Box, Flex, Image, Switch, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { updatedoctoravailability } from '../../middleware/services/home';
import { getProfileforhome } from '../../middleware/services/profile';
import useLoader from '../loader/loaderstates';

const MobileSecondaryNav = ({handlebackarrow = true, header, isAvailable = true, bottomArea = false, headerStyle}) => {
const [DoctorAvailability, setDoctorAvailability] = useState("")
const { isLoading, showLoader, dismissLoader } = useLoader();

useEffect(() => {
getDoctorProfile();
}, []);

const switchclicked = async (e)=>{
const isChecked = e.target.checked;
let input = {doctorid:"", isavailable:""}
// Do something based on the checked status
if (isChecked) {
    // Switch is checked
    input.isavailable = "1"
} else {
    // Switch is not checked
    input.isavailable = "0"
}
const result = await updatedoctoravailability(input);
if(result.output.success === 1){
    getDoctorProfile();
}
}
var inputForGet = {
}
const getDoctorProfile = async () => {
showLoader()
try {
    var result = await getProfileforhome(inputForGet);
    // console.log('Mobile result: ', result.ProfileOutput.isavailable)
    if (result && result.ProfileOutput) {
    setDoctorAvailability(result.ProfileOutput)
    //here
    }
    dismissLoader();
} catch (error) {
    console.log(error, "Something went wrong in doctor profile")
}
}
    

    return (
    <>
        <Box w="100%" p="1.25rem" bg="white" borderBottom='1px solid #E6E7E9'>
            <Flex w="100%" justifyContent="space-between" alignItems="center" {...headerStyle}>
                {handlebackarrow && (
                    <Box as="button" onClick={handlebackarrow}>
                      <Image src="/assets/imgs/left-arrow-icon.png" />
                    </Box>  
                )}

                <Box>
                    <Text fontSize="1.25rem" fontWeight="600">{header}</Text>
                </Box>

                <Box>
                {isAvailable && (
                    <Box>
                        <Box as='button' display="flex" alignItems="center" bg="transparent" p="0.5rem" h="2rem" gap="0.625rem" border="1px solid #E6E7E9" borderRadius=".625rem">
                        {DoctorAvailability && DoctorAvailability.isavailable === 1 ? (
                                <>
                                <Image boxSize='15px' src='/assets/svgs/online-circle.svg'/>
                                {/* <Text fontSize="0.87rem" fontWeight="500" color="#384052">Available</Text> */}
                                <Switch size='sm' colorScheme="green" onChange={(e) => switchclicked(e)} defaultChecked />
                                </>
                                ) : (
                                <>
                                    <>
                                <Image boxSize='15px' mixBlendMode="luminosity" src='/assets/svgs/online-circle.svg'/>
                                {/* <Text fontSize="0.87rem" fontWeight="500" color="#384052">
                                    Unavailable
                                </Text> */}
                                <Switch
                                    colorScheme="green"
                                    onChange={(e) => switchclicked(e)}
                                />
                                </>
                                </>
                                )}
                            
                        </Box>
                    </Box>
                    )}
                </Box>
            </Flex>

            {bottomArea && (
                <Box mt="1rem">
                   {bottomArea} 
                </Box>
            )}
        </Box>
    </>
  )
}

export default MobileSecondaryNav