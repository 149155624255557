import moment from "moment";
export default function formAPIrole({
    rolename,
    roledescription,
    createddate,
    modifieddate,
   id,
   usertypeid,
   userdetail,
   usercount
    
   

})
{
  return Object.freeze({
    rolename,
    roledescription,
    createddate,
    modifieddate,
    id,
    usertypeid,
    userdetail,
    usercount
   
  });
}