import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Box,
  Image,
  Textarea,
} from "@chakra-ui/react";

import React, { useEffect, useState } from "react";
import { COLORS } from "../../styles/colors";

const PrimaryAssessmentInput = ({
  label,
  inputType,
  inputPlace,
  inputStyleCss,
  inputValue,
  onChange,
  onClick,
  onKeyDown,
  onKeyPress,
  onKeyUp,
  variant,
  defaultValue,
  inputCss,
  inputLabelCss,
  handleFileSelection,
  isDisabled,
  uploadText,
  iconImg,
  docBtnWidth = "100%",
  docBtnColor = "black",
  id,
  maxLength,
  wrap = false,
  boolProp = false,
  inputIdProp,
  autocomplete,
  autofocus,
  stepProp,
  textAreaHeight,
  ...props
}) => {
  //--------------styles

  const styles = {
    borderRadius: "10px 10px 0 0",
    background: COLORS.PRIMARY_WHITE,
    boxShadow: "none",
    fontSize: "0.87rem",
    fontWeight: "500",
    padding: "12px 10px 0 10px",
    // marginBottom: "-2px",
    height: "48px",
    width: "100%",
    _disabled: {
      bg: COLORS.BTN_DISABLE,
      color: COLORS.INPUT_FOCUS_DARK,
      cursor: "not-allowed",
    },
    _hover: {
      borderRadius: "10px",
      border: `2px solid`,
      borderColor: COLORS.INPUT_FOCUS_DARK,
      // zIndex: "22",
      outline: "none",
      bg: "none",
    },
    _focus: {
      borderRadius: "10px",
      borderColor: COLORS.INPUT_FOCUS_DARK,
      // zIndex: "22",
      outline: "none",
    },
    _focusVisible: {
      borderColor: "none",
    },
  };

  const variantStyles = {
    defaultSide: {
      borderRadius: "10px 10px 0 0",
    },
    rightSide: {
      borderRadius: "0 10px 0 0",
    },
    leftSide: {
      borderRadius: "10px 0 0 0px",
    },
    leftDown: {
      borderRadius: "0px 0 0 10px",
    },
    rightDown: {
      borderRadius: "0px 0 10px 0px",
    },
    bothSide: {
      borderRadius: "0",
    },
    downSide: {
      borderRadius: "0 0 10px 10px",
    },
    fullLeft: {
      borderRadius: "10px 0 0 10px",
    },
    fullRight: {
      borderRadius: "0 10px 10px 0",
    },
    fullRound: {
      borderRadius: "10px",
    },
  };

  const activeLabelStyles = {
    transform: "scale(0.85) translateY(-10px)",
    fontSize: "sm",
    color: COLORS.INPUT_FOCUS_DARK,
  };

  const activeLabelStylesForTextArea = {
    transform: "scale(0.65) translateY(-15px)",
    fontSize: "sm",
    color: COLORS.INPUT_FOCUS_DARK,
  };

  const inputDesign = {
    formStyles: {
      container: {
        position: "relative",
        paddingTop: "0",
      },
      label: {
        position: "absolute",
        top: "5px",
        left: "0",
        zIndex: 99,
        mx: 2.5,
        px: 0,
        my: 2,
        opacity: "50%",
        transformOrigin: "left top",
        "input:focus + &": {
          ...activeLabelStyles,
        },
        "input:not(:placeholder-shown) + &": {
          ...activeLabelStyles,
        },
        pointerEvents: "none",
      },
      input: {
        _focus: {
          "+ label": {
            ...activeLabelStyles,
          },
        },
        ":not(:placeholder-shown) + label": {
          ...activeLabelStyles,
        },
      },
    },
  };

  const inputDesignForTextArea = {
    formStyles: {
      container: {
        position: "relative",
        paddingTop: "0",
      },
      label: {
        position: "absolute",
        top: "5px",
        left: "0",
        zIndex: 99,
        mx: 2.5,
        px: 0,
        my: 2,
        opacity: "50%",
        transformOrigin: "left top",
        "input:focus + &": {
          ...activeLabelStylesForTextArea,
        },
        "input:not(:placeholder-shown) + &": {
          ...activeLabelStylesForTextArea,
        },
        pointerEvents: "none",
      },
      input: {
        _focus: {
          "+ label": {
            ...activeLabelStylesForTextArea,
          },
        },
        ":not(:placeholder-shown) + label": {
          ...activeLabelStylesForTextArea,
        },
      },
    },
  };

  //--------------component code
  const variantStyle = variantStyles[variant] || variantStyles.defaultSide;
  const isFileInput = inputType === "file";
  const [initialRender, setInitialRender] = useState(true);

  useEffect(() => {
    let element = document.getElementById(inputIdProp);
    if (!initialRender && element) {
      element.focus();
      setTimeout(() => {
        element.focus();
      }, 10);
    } else if (autofocus && element) {
      element.focus();
    }
    setInitialRender(false);
  }, [boolProp, autofocus, inputIdProp]);

  return (
    <FormControl sx={inputDesign.formStyles.container} {...inputStyleCss}>
      {isFileInput ? (
        <InputGroup
          boxShadow="0px 1px 3px #E6E7E9"
          borderRadius="10px"
          width={docBtnWidth}
          cursor="pointer"
        >
          <InputRightElement w="100%" h="100%">
            <Image src={iconImg} pos="relative" left="10px" pr="10px" />
            <Box
              as="button"
              fontWeight="600"
              h="100%"
              color={docBtnColor}
              width="100%"
              bg="none"
              onClick={() => document.getElementById(id).click()}
              sx={{ _hover: { bg: "none" } }}
              isDisabled={isDisabled}
            >
              {uploadText}
            </Box>
            <input
              type="file"
              id={id}
              style={{ display: "none" }}
              onChange={handleFileSelection}
            />
          </InputRightElement>
          <Input
            type="text"
            background="#FCFCFD"
            boxShadow="0px 1px 3px #E6E7E9"
            sx={{
              _hover: {
                backgroundImage:
                  "-webkit-linear-gradient(top, #FCFCFD, #E6E7E9)",
                backgroundImage: "-moz-linear-gradient(top, #FCFCFD, #E6E7E9)",
                backgroundImage: "-ms-linear-gradient(top, #FCFCFD, #E6E7E9)",
                backgroundImage: "-o-linear-gradient(top, #FCFCFD, #E6E7E9)",
                backgroundImage: "linear-gradient(to bottom, #FCFCFD, #E6E7E9)",
              },
              _disabled: {
                bg: COLORS.BTN_DISABLE,
                color: COLORS.BTN_DISABLE_TEXT,
                cursor: "not-allowed",
              },
            }}
            border="none"
            height="48px"
            borderRadius="10px"
            value={inputValue}
            placeholder={inputPlace}
            readOnly // Make the text field read-only for file type
          />
        </InputGroup>
      ) : wrap ? (
        <Textarea
          sx={{
            ...styles,
            ...variantStyle,
            ...inputDesignForTextArea.formStyles.input,
            whiteSpace: "pre-wrap",
            overflowWrap: "break-word",
            minHeight: textAreaHeight ? textAreaHeight : "none",
            // height: "100%", // Add height here based on the prop
          }}
          //   height={textAreaHeight || "auto"}
          value={inputValue}
          defaultValue={defaultValue}
          onChange={onChange}
          onScroll={(e) => {
            const labelElement = e.target.nextSibling; // Get the label element
            if (e.target.scrollTop > 0) {
              labelElement.style.opacity = "0"; // Hide label when not at the top
            } else {
              labelElement.style.opacity = "0.5"; // Show label when at the top
            }
          }}
          // onChange={(e) => {
          //   onChange(e);
          //   e.target.style.height = "auto"; // Reset the height to auto
          //   e.target.style.height = `${e.target.scrollHeight}px`; // Set the height to the scroll height
          // }}
          onClick={onClick}
          onKeyDown={onKeyDown}
          onKeyPress={onKeyPress}
          onKeyUp={onKeyUp}
          placeholder=" "
          errorBorderColor="red.300"
          isDisabled={isDisabled}
          maxLength={maxLength}
          {...inputCss}
        />
      ) : (
        <Input
          id={inputIdProp ? inputIdProp : null}
          type={inputType}
          // step is to accept decimals , when input tpe is number  - step should be "any"
          step={stepProp}
          sx={{ ...styles, ...variantStyle, ...inputDesign.formStyles.input }}
          value={inputValue}
          defaultValue={defaultValue}
          onChange={onChange}
          onClick={onClick}
          onKeyDown={onKeyDown}
          onKeyPress={onKeyPress}
          onKeyUp={onKeyUp}
          placeholder=" "
          errorBorderColor="red.300"
          isDisabled={isDisabled}
          maxLength={maxLength}
          autoComplete={autocomplete}
          autofocus={autofocus}
          {...inputCss}
        />
      )}
      <FormLabel sx={{ ...inputDesign.formStyles.label, ...inputLabelCss }}>
        {label || inputPlace}
      </FormLabel>
    </FormControl>
  );
};

export default PrimaryAssessmentInput;
