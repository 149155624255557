import { getDoctorId, getLoginID, getToken } from '../../../auth';
import  FetchData, { } from '../../client';
import axios from 'axios';
import { CONFIG } from '../../../appconfig/appconfig';
import {ProfileAPIModel, AddressProfileAPIModel, DocumentProfileAPIModel, SubscriptionProfileAPIModel, InvoiceProfileAPIModel } from '../../domains/profile/profile'
import { getDocumentOutput, getSubscriptionOutput, getInvoiceOutput, DoctorIdOutput, getSpecialityOutput, feeStrucutreAPIMODAL, resultbranchAPIMODAL } from '../../domains/profile/profiles';
import formAPIModelProfileAlerts from '../../domains/profilealerts/alerts';


//Get Profile and Get Profile Address Details
const getProfile = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();

  //  console.log(inputData, " profile inputData ");
    const result = await FetchData("midoc_getdoctorprofile", inputData);
  //  console.log(result, " DB response profile result ");

    let firstAddressDetail = null;
    if (result.output.addressdetail && result.output.addressdetail.length > 0) {
      firstAddressDetail = AddressProfileAPIModel(result.output.addressdetail[0]);
    } else {
      console.warn("No address details found");
    }

    let feeStructure = [];
    if (result.output.feestructure && result.output.feestructure.length > 0) {
      feeStructure = feeStrucutreAPIMODAL(result.output.feestructure);
    } else {
      console.warn("No feeStructure found!");
    }

    let resultbranch = [];
    if (result.output.resultbranch && result.output.resultbranch.length > 0) {
      resultbranch = resultbranchAPIMODAL(result.output.resultbranch);
    } else {
      console.warn("No resultbranch found!");
    }
    
    let ProfileOutput = null;
    if (result.output.data) {
      ProfileOutput = ProfileAPIModel(result.output.data);
    } else {
      console.warn("No profile data found");
    }
    
    let DoctorUniqueId = null;
    if (result.output.data) {
      DoctorUniqueId = DoctorIdOutput(result.output.data);
      // console.log(DoctorOutput, 'doctor from getProfile')
    } else {
      console.warn("No profile data found");
    }
    
    // ______________________________________________________________
    let DocumentOutput = null;
    if(result.output.document) {
      DocumentOutput = getDocumentOutput(result.output.document);
    } else {
      console.warn("No records found")
    }
    // console.log(DocumentOutput, "DcpoumentOutput")

    let SpecialityOutput = null;
    if(result.output.doctorspeciality) {
      SpecialityOutput = getSpecialityOutput(result.output.doctorspeciality);
    } else {
      console.warn("No records found")
    }
    // console.log(SpecialityOutput, "SpecialityOutput")


    let SubscriptionOutput = null;
    if(result.output.plan) {
      SubscriptionOutput = getSubscriptionOutput(result.output.plan)
    } else {
      console.warn("No Plan found")
    }
    // console.log(SubscriptionOutput, "subscriptOUTPUT")


    let InvoiceOutput = null;
    if(result.output.invoice) {
      InvoiceOutput = getInvoiceOutput(result.output.invoice)
    } else {
      console.warn("No Plan found")
    }
    // console.log(InvoiceOutput, "InvoiceOutput")
    // ______________________________________________________________
    let availabilitydata = null;
    if(result.output.doctoravailibily){
      availabilitydata = result.output.doctoravailibily;
    }
    let settingsdata = null;
    if(result.output.healthviewsetting){
      settingsdata = result.output.healthviewsetting;
    }


    return { ProfileOutput, firstAddressDetail,DocumentOutput,SpecialityOutput, 
            SubscriptionOutput, InvoiceOutput, availabilitydata,DoctorUniqueId, settingsdata, feeStructure,
            resultbranch };
    // return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};

//Fetching the Speciality List
const FetchDoctorSpecialities = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    const result = await FetchData("midoc_getdefaultdata", inputData);
    // if (result.output.data) {
    //   var finalOutput = formapimodalfinalsteps(result.output.data)
    //   }
    return result.output.data;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};


export const getDefaultData = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    const result = await FetchData("midoc_getdefaultdata", inputData);
    // if (result.output.data) {
    //   var finalOutput = formapimodalfinalsteps(result.output.data)
    //   }
    return result.output.data;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};


//Profile data update
const updateProfile = async (inputData) => {
  try {
    inputData.doctorid = localStorage.getItem("loginid");
    // inputData.loginid = localStorage.getItem("loginid");
    const result = await FetchData("midoc_updatedoctorprofile", inputData);
    console.log(result,"Updated Profile Deatils")

    
    //var ProfileOutput = ProfileAPIModel(result.output.data);
   // console.log(ProfileOutput,"Updated Profile");

    return result;

  } catch (error) {
    console.error('Error updating profile:', error);
    throw error;
  }
};


//Profile Update Address 
const UpdateAddressProfile = async (inputData) => {
  try {
    // Setting id and loginid from localStorage to the inputData object
    inputData.id = inputData.firstAddressDetail ? inputData.firstAddressDetail.doctoraddressid : undefined;
    inputData.loginid = localStorage.getItem("loginid");
    
    let result;
    
    // Check if the inputData has an existing address ID
    if (inputData.id) {
      // If an address ID is provided, make an API call to update the address profile
      result = await FetchData("midoc_updatedoctoraddress", inputData);
      // console.log(result, "Address Profile (Update)");
    } else {
      // If no address ID is provided, make an API call to add a new address profile
      result = await FetchData("midoc_adddoctoraddress", inputData);
      // console.log(result, "Address Profile (Add)");
    }
    

    // Processing the response data using ProfileAPIModel
   // var firstAddressDetail = ProfileAPIModel(result.output.data);
    //console.log("First Address Detail:", firstAddressDetail);

    return result; // Returning the processed data

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};


//Reset Password
const resetPassword = async (inputData) => {
  try {    
    inputData.loginid = localStorage.getItem("loginid");
    const result = await FetchData("midoc_doctorresetpassword", inputData);
    //console.log(result,"login");
   

    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};


//Deleted Updated Address Details
const DeleteAddress = async (inputData) => {
  try {    
    inputData.loginid = localStorage.getItem("loginid");
    const result = await FetchData("midoc_deletedoctoraddress", inputData);
    //console.log(result,"login");
   

    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};


const addUpdateAlerts = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    // to change this API in below line
    const result = await FetchData("midoc_adddoctornotificationpreference", inputData);
     return result;

  } catch (error) {
    console.error('Error  in updating patient profile :', error);
    throw error;
  }
};
const addUpdateSettings = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    // to change this API in below line
    const result = await FetchData("midoc_patienthealthviewsetting", inputData);
     return result;

  } catch (error) {
    console.error('Error  in updating patient profile :', error);
    throw error;
  }
};
const FetchAlerts = async (inputData) => {
  try {
    
    inputData.doctorid = getLoginID();
    
    const result = await FetchData("midoc_getdoctornotificationpreference", inputData);
    //console.log(result , " db response get alerts ")
     if (result.output.data) {
     var finalOutput = formAPIModelProfileAlerts(result.output.data)
     
     return finalOutput;
     }

  } catch (error) {
    console.error('Error Account fetching :', error);
    throw error;
  }
};

// add branch functions start ---
export const addClinic = async (inputData) => {
  try {
    
    inputData.loginid = getLoginID();
    const result = await FetchData("midoc_addclinic", inputData);
     
     return result;

  } catch (error) {
    console.error('Error Account fetching :', error);
    throw error;
  }
};

export const updateClinic = async (inputData) => {
  try {
    
    inputData.loginid = getLoginID();
    const result = await FetchData("midoc_updateclinic", inputData);
     
     return result;

  } catch (error) {
    console.error('Error Account fetching :', error);
    throw error;
  }
};



// add branch functions end ---
// Name data edit
const saveNameChanges = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();

    const result = await FetchData("midoc_updatedoctorprofile", inputData);
    console.log(result, 'api DAtaa')
    return result;

  } catch (error) {
    console.error('Error updating profile:', error); 
    throw error;
  }
};

const deleteDocumentCall = async (inputData) => {
  try {
    
    inputData.doctorid = getLoginID();
   // console.log(inputData ,  " input for delete")
    const result = await FetchData("midoc_deletedoctordocument", inputData);
    //console.log(result , " db response for delte")
    return result;

  } catch (error) {
    console.error('Error in deleted document in profile :', error);
    throw error;
  }
};

// Gander data edit
const saveGenderChanges = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();

    const result = await FetchData("midoc_updatedoctorprofile", inputData);
    console.log(result, 'api DAtaa')
    return result;

  } catch (error) {
    console.error('Error updating profile:', error);
    throw error;
  }
};

// Phone number data edit
const saveMobileChanges = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();

    const result = await FetchData("midoc_updatedoctorprofile", inputData);
    console.log(result, 'api DAtaa')
    return result;

  } catch (error) {
    console.error('Error updating profile:', error); throw error;
  }
};

const logOutCall = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    // to change this API in below line
    const result = await FetchData("midoc_doctorsignout", inputData);
     return result;

  } catch (error) {
    console.error('Error  in log out :', error);
    throw error;
  }
};
const updatePatientProfileOnChange = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();

    const result = await FetchData("midoc_updatedoctorprofile", inputData);

    return result;
    
  } catch (error) {
    console.error('Error updating profile:', error); 
    throw error;
  }
};
const updateLogoOnChange = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();

    const result = await FetchData("midoc_updatedoctorprofile", inputData);

    return result;
    
  } catch (error) {
    console.error('Error updating profile:', error); 
    throw error;
  }
};


const saveHospitalChanges = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();

    const result = await FetchData("midoc_updatedoctorprofile", inputData);
    // console.log(result, 'api DAtaa')
    return result;

  } catch (error) {
    console.error('Error updating profile:', error); 
    throw error;
  }
};

const saveAddressChanges = async (inputData) => {
  try{
    inputData.doctorid = getLoginID();

    const result = await FetchData("midoc_adddoctoraddress", inputData);
    // console.log(result, 'address Data')
    return result;
  } catch(error){
    console.error('Error updating address:', error);
    throw error;
  }
}

const saveDegreeChanges = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();

    const result = await FetchData("midoc_updatedoctorprofile", inputData);
    // console.log(result, 'api DAtaa')
    return result;

  } catch (error) {
    console.error('Error updating profile:', error); 
    throw error;
  }
};


const saveSpecialityChanges = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();

    const result = await FetchData("midoc_updatedoctorprofile", inputData);
    // console.log(result, 'api DAtaa')
    return result;

  } catch (error) {
    console.error('Error updating profile:', error); 
    throw error;
  }
};


const saveExperienceChanges = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();

    const result = await FetchData("midoc_updatedoctorprofile", inputData);
    console.log(result, 'api DAtaa')
    return result;

  } catch (error) {
    console.error('Error updating profile:', error); 
    throw error;
  }
};



const uploadImageInProfile = async (file, filename) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('filename', filename);

    const response = await axios.post(`${CONFIG.APIBaseUrl}midoc_documentupload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response.statusText === 'OK') {
      const result = await response.data;
      return result;
    } else {
      const error = 'failed';
      return error;
    }
  } catch (err) {
    console.log(err);
    const error = 'failed';
    return error;
  }
};
const getProfileforhome = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    const result = await FetchData("midoc_getdoctorprofile", inputData);
    let ProfileOutput = null;
    if (result.output.data) {
      ProfileOutput = ProfileAPIModel(result.output.data);
    } 
    return { ProfileOutput};
    // return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};

const addDoctorBadge = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();

    const result = await FetchData("midoc_adddoctorbadge", inputData);
    return result;
    
  } catch (error) {
    console.error('Error in adding doctor badge:', error); 
    throw error;
  }
};



const reGenerateBdgeTextCall = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    const token = getToken();
    const headers = {
      'Content-Type': 'application/json',
    };
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    const response = await axios.post(`${CONFIG.APIBaseUrl}midoc_ai_generatebadge`, 
      inputData,
    {
      headers: headers,
    });
    
    return response.data;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};

const badgeAIPromptCall = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    const token = getToken();
    const headers = {
      'Content-Type': 'application/json',
    };
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    const response = await axios.post(`${CONFIG.APIBaseUrl}midoc_ai_generatethoughts`, 
      inputData,
    {
      headers: headers,
    });
    
    return response.data;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};


export {getProfile,FetchDoctorSpecialities, updateProfile, UpdateAddressProfile,
   resetPassword, DeleteAddress, addUpdateAlerts, FetchAlerts, 
   saveNameChanges, saveGenderChanges, saveMobileChanges, saveHospitalChanges, saveAddressChanges, 
   saveDegreeChanges, saveSpecialityChanges, saveExperienceChanges,logOutCall, 
   deleteDocumentCall,uploadImageInProfile, updatePatientProfileOnChange, updateLogoOnChange, getProfileforhome,
   addDoctorBadge, reGenerateBdgeTextCall,
   badgeAIPromptCall ,addUpdateSettings};

