import { useCallback } from "react";
import { useToast, Box, CloseButton, Icon } from "@chakra-ui/react";
import { IoAlertCircleOutline } from "react-icons/io5";
import { COLORS } from "../styles/colors";
import { iconStyleAlert, iconBgStyleAlert } from "./style";

export const AlertToaster = () => {
  const toast = useToast();

  const showAlertToast = useCallback(
    (message) => {
      const toastStyle = {
        width: "345px",
        height: "64px",
        background: COLORS.TOASTR_BG_ALERT,
        color: "black",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
        padding: "12px",
        borderRadius: "35px",
        gap: "5px",
      };

      toast({
        duration: 6000,
        isClosable: true,
        position: "bottom",
        render: ({ onClose }) => (
          <Box style={toastStyle}>
            <Box sx={iconStyleAlert}>
              <Icon as={IoAlertCircleOutline} sx={iconBgStyleAlert} />
            </Box>
            <Box as="span" fontSize="12px">
              {message}
            </Box>
            <CloseButton onClick={onClose} />
          </Box>
        ),
      });
    },
    [toast]
  );

  return { showAlertToast };
};

export default AlertToaster;
