import moment from "moment";
export default function formAPIModelreaction({
  reaction,
  reaction_cd,
  documents,
  Patientid,
  allergyidentifieddate,
  allergytype,
  documentcount,
  patientallergyid,
   

})
{
  return Object.freeze({
    reaction,
  reaction_cd,
  documents,
  Patientid,
  allergyidentifieddate,
  allergytype,
  documentcount,
  patientallergyid,

   
  });
}