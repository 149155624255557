import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Image,
  Text,
  Spacer,
  Button,
  VStack,
  SimpleGrid,
} from "@chakra-ui/react";
import { actionAccessMain, getLoginID } from "../../auth";
import {
  UpdateAppointmentByDoctor,
  CreateAppointmentByDoctor,
} from "../../middleware/services/visits";
import DynamicModal from "../dynamic-modal";
import { useNavigate, useLocation } from "react-router-dom";
import { UpdateAppointmentStatus } from "../../middleware/services/visits/patient-overview";
import PrimaryButton from "../primary-button";
import moment from "moment";
import { GetDoctorSlots } from "../../middleware/services/visits/patient-overview/updatedoctornotes";
import DatePicker from "../date-picker";
import { COLORS } from "../styles/colors";
import Toaster from "../toaster";
import Loader from "../loader/loader";
import useLoader from "../loader/loaderstates";
import { AcceptVisit } from "../../middleware/services/home";

const PatientVisitCard = ({
  userImage,
  userName,
  visitDate,
  isUpcoming = false,
  visitTime,
  issue,
  borderColor,
  disableLeftBorder = false,
  patientId,
  appointmentId,
  appointmentStatus,
  // fetchCalendarData,
  // fetchSidePanelData,
  refreshPageContent,
  visitStatus,
  statusName,
}) => {
  const { showSuccessToast, showErrorToast } = Toaster();

  const { isLoading, showLoader, dismissLoader } = useLoader();
  const [showOptions, setShowOptions] = useState(false);
  const optionsRef = useRef();
  const svgRef = useRef();

  const doctorId = getLoginID();
  const navigate = useNavigate();

  const startVisit = useRef();
  const acceptVisit = useRef();
  const cancelVisit = useRef();
  const rescheduleVisit = useRef();
  const scheduleVisit = useRef();
  const noShowVisit = useRef();

  let isNotUpcoming = false;
  let isFinished = false;

  // console.log(appointmentStatus);

  if (appointmentStatus !== 1 && appointmentStatus !== 0) {
    isNotUpcoming = true;
  } else {
    isNotUpcoming = false;
  }

  if (appointmentStatus === 3) {
    isFinished = true;
  } else {
    isFinished = false;
  }

  const [appointmentRescheduleDate, setAppointmentRescheduleDate] = useState(
    new Date()
  );
  const [appointmentRescheduleTime, setAppointmentRescheduleTime] =
    useState(null);

  const [availableSlots, setAvailableSlots] = useState([]);

  const [actionAccess, setActionAccess] = useState(null);
  const [actionAccessAppointments, setActionAccessAppointments] = useState(null);

  const [actionAccessMessage, setActionAccessMessage] = useState(null);

  const fetchDoctorSlots = async (dateToReSchedule) => {
    // console.log(dateToReSchedule);
    const inputToFetch = {
      input: {
        doctorid: doctorId,
        date: dateToReSchedule
          ? moment(dateToReSchedule, "DD-MM-YYYY").format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
      },
    };
    // console.log(inputToFetch);
    // console.log("input send: ", patientId, dateToReSchedule);
    showLoader();
    try {
      const data = await GetDoctorSlots(inputToFetch.input);

      // console.log("Output", data);
      const slots = data?.output?.data || [];
      const currentDate = moment().format("DD-MM-YYYY");
      function isDateInCorrectFormat(dateStr) {
        const regex = /^\d{2}-\d{2}-\d{4}$/;
        return regex.test(dateStr);
      }

      const formattedRescheduleDate = isDateInCorrectFormat(dateToReSchedule)
        ? dateToReSchedule
        : moment(dateToReSchedule).format("DD-MM-YYYY");
      // console.log("Date to reschedule", dateToReSchedule);
      // console.log("Formated reschedule", formattedRescheduleDate);
      // console.log("current date", currentDate);
      if (dateToReSchedule && formattedRescheduleDate === currentDate) {
        // console.log(dateToReSchedule);
        // console.log(currentDate);
        const currentTime = new Date();
        const currentHours = currentTime.getHours();
        const currentMinutes = currentTime.getMinutes();
        const filteredSlots = slots.filter((slot) => {
          const [slotHours, slotMinutes] = slot.starttime
            .split(":")
            .map(Number);
          return (
            slotHours > currentHours ||
            (slotHours === currentHours && slotMinutes >= currentMinutes)
          );
        });
        // console.log(filteredSlots.length);
        // console.log(filteredSlots);
        // console.log(slots.length);
        setAvailableSlots(slots);
      }
      // console.log("New Slots: ", slots);
      else {
        // console.log("Other case");
        setAvailableSlots(slots);
      }
    } catch (error) {
      console.error("Error fetching Doctor Slots data:", error);
    } finally {
      dismissLoader();
    }
  };

  // useEffect(() => {
  //   console.log()
  //   fetchDoctorSlots();
  // }, [appointmentRescheduleDate]);

  const [timePeriod, setTimePeriod] = useState("Morning");

  const NewSlotSelector = ({ availableSlots }) => {
    const [selectedSlot, setSelectedSlot] = useState(null);
    const currentDate = moment().format("DD-MM-YYYY");
    const currentTime = new Date();

    const isSlotDisabled = (slot) => {
      const slotTime = new Date(`1970-01-01T${slot.starttime}`);
      const slotDate = moment(appointmentRescheduleDate).format("DD-MM-YYYY");

      function isDateInCorrectFormat(dateStr) {
        const regex = /^\d{2}-\d{2}-\d{4}$/;
        return regex.test(dateStr);
      }

      const formattedRescheduleDate = isDateInCorrectFormat(
        appointmentRescheduleDate
      )
        ? appointmentRescheduleDate
        : moment(appointmentRescheduleDate).format("DD-MM-YYYY");
      if (
        appointmentRescheduleDate &&
        formattedRescheduleDate === currentDate
      ) {
        // console.log("Same day events")

      // If the slot is for today and its time has passed, disable it
      
        const currentHours = currentTime.getHours();
        const currentMinutes = currentTime.getMinutes();
        const [slotHours, slotMinutes] = slot.starttime.split(":").map(Number);

        return (
          slotHours < currentHours ||
          (slotHours === currentHours && slotMinutes < currentMinutes)
        );
      }

      // If it's not today, don't disable
      return false;
    };

    const timePeriods = ["Morning", "Afternoon", "Evening", "Night"];
    const timePeriodSlots = {
      Morning: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
            new Date("1970-01-01T09:00:00") &&
          new Date(`1970-01-01T${slot.starttime}`) <
            new Date("1970-01-01T12:00:00")
      ),
      Afternoon: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
            new Date("1970-01-01T12:00:00") &&
          new Date(`1970-01-01T${slot.starttime}`) <
            new Date("1970-01-01T18:00:00")
      ),
      Evening: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
            new Date("1970-01-01T18:00:00") &&
          new Date(`1970-01-01T${slot.starttime}`) <
            new Date("1970-01-01T21:00:00")
      ),
      Night: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
          new Date("1970-01-01T21:00:00")
      ),
    };

    const handleTimePeriodChange = (direction) => {
      const currentIndex = timePeriods.indexOf(timePeriod);
      const newIndex =
        (currentIndex + direction + timePeriods.length) % timePeriods.length;
      setTimePeriod(timePeriods[newIndex]);
    };

    const handleSlotSelection = (slot) => {
      if (!isSlotDisabled(slot)) {
        // console.log("Selection made")
        setSelectedSlot(slot.starttime);
        setAppointmentRescheduleTime(slot.starttime);
        const selectedTime = new Date(`1970-01-01T${slot.starttime}`);
        if (
          selectedTime >= new Date("1970-01-01T09:00:00") &&
          selectedTime < new Date("1970-01-01T12:00:00")
        ) {
          setTimePeriod("Morning");
        } else if (
          selectedTime >= new Date("1970-01-01T12:00:00") &&
          selectedTime < new Date("1970-01-01T18:00:00")
        ) {
          setTimePeriod("Afternoon");
        } else if (
          selectedTime >= new Date("1970-01-01T18:00:00") &&
          selectedTime < new Date("1970-01-01T21:00:00")
        ) {
          setTimePeriod("Evening");
        } else {
          setTimePeriod("Night");
        }
        // console.log(timePeriod);
      }
    };

    return (
      <Box>
        {isLoading && <Loader miniLoader={true} />}
        {!isLoading && (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={4}
            >
              <Box as="button" onClick={() => handleTimePeriodChange(-1)}>
                <Image src="/assets/svgs/left-tab-icon.svg" />
              </Box>
              <Text fontSize="xl" fontWeight="bold">
                {timePeriod}
                <Text fontSize="md" color="gray" display={"inline"} ml={"8px"}>
                  {timePeriodSlots[timePeriod].length} slots
                </Text>
              </Text>
              <Box as="button" onClick={() => handleTimePeriodChange(1)}>
                <Image src="/assets/svgs/right-tab-icon.svg" />
              </Box>
            </Box>

            <SimpleGrid columns={5} spacing={4}>
              {timePeriodSlots[timePeriod].map((slot) => (
                <Box
                  key={slot.starttime}
                  as="button"
                  onClick={() => handleSlotSelection(slot)}
                  borderWidth="1px"
                  borderRadius="md"
                  p={2}
                  textAlign="center"
                  bg={
                    appointmentRescheduleTime === slot.starttime
                      ? "blue.500"
                      : isSlotDisabled(slot)
                      ? "gray.300"
                      : "white"
                  }
                  color={appointmentRescheduleTime === slot.starttime ? "white" : "black"}
                  borderColor={
                    appointmentRescheduleTime === slot.starttime ? "blue.500" : "gray.300"
                  }
                  _hover={{
                    bg:
                    appointmentRescheduleTime === slot.starttime
                        ? "blue.600"
                        : isSlotDisabled(slot)
                        ? "gray.300"
                        : "gray.200",
                  }}
                  disabled={isSlotDisabled(slot)}
                >
                  {slot.starttime.substring(0, 5)}
                </Box>
              ))}
            </SimpleGrid>
          </>
        )}
      </Box>
    );
  };

  const SlotSelector = ({ availableSlots }) => {
    // console.log(moment(new Date()).format("DD-MM-YYYY"));
    const [selectedSlot, setSelectedSlot] = useState(null);

    const timePeriods = ["Morning", "Afternoon", "Evening", "Night"];
    const timePeriodSlots = {
      Morning: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
            new Date("1970-01-01T09:00:00") &&
          new Date(`1970-01-01T${slot.starttime}`) <
            new Date("1970-01-01T12:00:00")
      ),
      Afternoon: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
            new Date("1970-01-01T12:00:00") &&
          new Date(`1970-01-01T${slot.starttime}`) <
            new Date("1970-01-01T18:00:00")
      ),
      Evening: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
            new Date("1970-01-01T18:00:00") &&
          new Date(`1970-01-01T${slot.starttime}`) <
            new Date("1970-01-01T21:00:00")
      ),
      Night: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
          new Date("1970-01-01T21:00:00")
      ),
    };

    const handleTimePeriodChange = (direction) => {
      const currentIndex = timePeriods.indexOf(timePeriod);
      const newIndex =
        (currentIndex + direction + timePeriods.length) % timePeriods.length;
      setTimePeriod(timePeriods[newIndex]);
    };

    const handleSlotSelection = (slot) => {
      setAppointmentRescheduleTime(slot.starttime);
      const selectedTime = new Date(`1970-01-01T${slot.starttime}`);
      if (
        selectedTime >= new Date("1970-01-01T09:00:00") &&
        selectedTime < new Date("1970-01-01T12:00:00")
      ) {
        setTimePeriod("Morning");
      } else if (
        selectedTime >= new Date("1970-01-01T12:00:00") &&
        selectedTime < new Date("1970-01-01T18:00:00")
      ) {
        setTimePeriod("Afternoon");
      } else if (
        selectedTime >= new Date("1970-01-01T18:00:00") &&
        selectedTime < new Date("1970-01-01T21:00:00")
      ) {
        setTimePeriod("Evening");
      } else {
        setTimePeriod("Night");
      }
      // console.log(timePeriod);
    };

    return (
      <Box>
        {isLoading && <Loader miniLoader={true} />}

        {!isLoading && (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={4}
            >
              <Box as="button" onClick={() => handleTimePeriodChange(-1)}>
                <Image src="/assets/svgs/left-tab-icon.svg" />
              </Box>
              <Text fontSize="xl" fontWeight="bold">
                {timePeriod}
                <Text fontSize="md" color="gray" display={"inline"} ml={"8px"}>
                  {timePeriodSlots[timePeriod].length} slots{" "}
                </Text>
              </Text>
              <Box as="button" onClick={() => handleTimePeriodChange(1)}>
                <Image src="/assets/svgs/right-tab-icon.svg" />
              </Box>
            </Box>

            <SimpleGrid columns={5} spacing={4}>
              {timePeriodSlots[timePeriod].map((slot) => (
                <Box
                  key={slot.starttime}
                  as="button"
                  onClick={() => handleSlotSelection(slot)}
                  borderWidth="1px"
                  borderRadius="md"
                  p={2}
                  textAlign="center"
                  bg={
                    appointmentRescheduleTime === slot.starttime
                      ? "blue.500"
                      : "white"
                  }
                  color={
                    appointmentRescheduleTime === slot.starttime
                      ? "white"
                      : "black"
                  }
                  borderColor={
                    appointmentRescheduleTime === slot.starttime
                      ? "blue.500"
                      : "gray.300"
                  }
                  _hover={{
                    bg:
                      appointmentRescheduleTime === slot.starttime
                        ? "blue.600"
                        : "gray.200",
                  }}
                >
                  {slot.starttime.substring(0, 5)}
                </Box>
              ))}
            </SimpleGrid>
          </>
        )}
      </Box>
    );
  };

  const [appointmentScheduleDate, setAppointmentScheduleDate] = useState(
    new Date()
  );
  const [appointmentScheduleTime, setAppointmentScheduleTime] = useState(null);

  const fetchDoctorScheduleSlots = async (dateToSchedule) => {
    // console.log(dateToSchedule);
    const inputToFetch = {
      input: {
        doctorid: doctorId,
        date: dateToSchedule
          ? moment(dateToSchedule, "DD-MM-YYYY").format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
      },
    };
    // console.log(inputToFetch);
    showLoader();
    try {
      // console.log("Request send for", patientId, dateToSchedule);
      const data = await GetDoctorSlots(inputToFetch.input);
      // console.log("response recieved", data);
      const slots = data?.output?.data || [];
      const currentDate = moment().format("DD-MM-YYYY");

      function isDateInCorrectFormat(dateStr) {
        const regex = /^\d{2}-\d{2}-\d{4}$/;
        return regex.test(dateStr);
      }

      const formattedScheduleDate = isDateInCorrectFormat(dateToSchedule)
        ? dateToSchedule
        : moment(dateToSchedule).format("DD-MM-YYYY");
      if (dateToSchedule && formattedScheduleDate === currentDate) {
        // console.log(appointmentScheduleDate);
        // console.log(currentDate);
        const currentTime = new Date();
        const currentHours = currentTime.getHours();
        const currentMinutes = currentTime.getMinutes();
        const filteredSlots = slots.filter((slot) => {
          const [slotHours, slotMinutes] = slot.starttime
            .split(":")
            .map(Number);
          return (
            slotHours > currentHours ||
            (slotHours === currentHours && slotMinutes >= currentMinutes)
          );
        });
        // console.log(filteredSlots.length);
        // console.log(slots.length);
        setAvailableSlots(slots);
      }
      // console.log("New Slots: ", slots);
      else {
        // console.log("Other case");
        setAvailableSlots(slots);
      }
    } catch (error) {
      console.error("Error fetching Doctor Slots data:", error);
    } finally {
      dismissLoader();
    }
  };

  // useEffect(() => {
  //   fetchDoctorScheduleSlots();
  // }, [appointmentScheduleDate, scheduleVisit.current.openModal]);

  const NewScheduleSlotSelector = ({ availableSlots }) => {
    const [selectedSlot, setSelectedSlot] = useState(null);
    const currentDate = moment().format("DD-MM-YYYY");
    const currentTime = new Date();

    const isSlotDisabled = (slot) => {
      const slotTime = new Date(`1970-01-01T${slot.starttime}`);
      const slotDate = moment(appointmentScheduleDate).format("DD-MM-YYYY");

      function isDateInCorrectFormat(dateStr) {
        const regex = /^\d{2}-\d{2}-\d{4}$/;
        return regex.test(dateStr);
      }

      const formattedScheduleDate = isDateInCorrectFormat(
        appointmentScheduleDate
      )
        ? appointmentScheduleDate
        : moment(appointmentScheduleDate).format("DD-MM-YYYY");
      if (
        appointmentScheduleDate &&
        formattedScheduleDate === currentDate
      ) {
        // console.log("Same day events")

      // If the slot is for today and its time has passed, disable it
      
        const currentHours = currentTime.getHours();
        const currentMinutes = currentTime.getMinutes();
        const [slotHours, slotMinutes] = slot.starttime.split(":").map(Number);

        return (
          slotHours < currentHours ||
          (slotHours === currentHours && slotMinutes < currentMinutes)
        );
      }

      // If it's not today, don't disable
      return false;
    };

    const timePeriods = ["Morning", "Afternoon", "Evening", "Night"];
    const timePeriodSlots = {
      Morning: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
            new Date("1970-01-01T09:00:00") &&
          new Date(`1970-01-01T${slot.starttime}`) <
            new Date("1970-01-01T12:00:00")
      ),
      Afternoon: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
            new Date("1970-01-01T12:00:00") &&
          new Date(`1970-01-01T${slot.starttime}`) <
            new Date("1970-01-01T18:00:00")
      ),
      Evening: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
            new Date("1970-01-01T18:00:00") &&
          new Date(`1970-01-01T${slot.starttime}`) <
            new Date("1970-01-01T21:00:00")
      ),
      Night: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
          new Date("1970-01-01T21:00:00")
      ),
    };

    const handleTimePeriodChange = (direction) => {
      const currentIndex = timePeriods.indexOf(timePeriod);
      const newIndex =
        (currentIndex + direction + timePeriods.length) % timePeriods.length;
      setTimePeriod(timePeriods[newIndex]);
    };

    const handleSlotSelection = (slot) => {
      if (!isSlotDisabled(slot)) {
        // console.log("Selection made")
        setSelectedSlot(slot.starttime);
        setAppointmentScheduleTime(slot.starttime);
        const selectedTime = new Date(`1970-01-01T${slot.starttime}`);
        if (
          selectedTime >= new Date("1970-01-01T09:00:00") &&
          selectedTime < new Date("1970-01-01T12:00:00")
        ) {
          setTimePeriod("Morning");
        } else if (
          selectedTime >= new Date("1970-01-01T12:00:00") &&
          selectedTime < new Date("1970-01-01T18:00:00")
        ) {
          setTimePeriod("Afternoon");
        } else if (
          selectedTime >= new Date("1970-01-01T18:00:00") &&
          selectedTime < new Date("1970-01-01T21:00:00")
        ) {
          setTimePeriod("Evening");
        } else {
          setTimePeriod("Night");
        }
        // console.log(timePeriod);
      }
    };

    return (
      <Box>
        {isLoading && <Loader miniLoader={true} />}
        {!isLoading && (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={4}
            >
              <Box as="button" onClick={() => handleTimePeriodChange(-1)}>
                <Image src="/assets/svgs/left-tab-icon.svg" />
              </Box>
              <Text fontSize="xl" fontWeight="bold">
                {timePeriod}
                <Text fontSize="md" color="gray" display={"inline"} ml={"8px"}>
                  {timePeriodSlots[timePeriod].length} slots
                </Text>
              </Text>
              <Box as="button" onClick={() => handleTimePeriodChange(1)}>
                <Image src="/assets/svgs/right-tab-icon.svg" />
              </Box>
            </Box>

            <SimpleGrid columns={5} spacing={4}>
              {timePeriodSlots[timePeriod].map((slot) => (
                <Box
                  key={slot.starttime}
                  as="button"
                  onClick={() => handleSlotSelection(slot)}
                  borderWidth="1px"
                  borderRadius="md"
                  p={2}
                  textAlign="center"
                  bg={
                    appointmentScheduleTime === slot.starttime
                      ? "blue.500"
                      : isSlotDisabled(slot)
                      ? "gray.300"
                      : "white"
                  }
                  color={appointmentScheduleTime === slot.starttime ? "white" : "black"}
                  borderColor={
                    appointmentScheduleTime === slot.starttime ? "blue.500" : "gray.300"
                  }
                  _hover={{
                    bg:
                    appointmentScheduleTime === slot.starttime
                        ? "blue.600"
                        : isSlotDisabled(slot)
                        ? "gray.300"
                        : "gray.200",
                  }}
                  disabled={isSlotDisabled(slot)}
                >
                  {slot.starttime.substring(0, 5)}
                </Box>
              ))}
            </SimpleGrid>
          </>
        )}
      </Box>
    );
  };

  const ScheduleSlotSelector = ({ availableSlots }) => {
    const [selectedSlot, setSelectedSlot] = useState(null);

    const timePeriods = ["Morning", "Afternoon", "Evening", "Night"];
    const timePeriodSlots = {
      Morning: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
            new Date("1970-01-01T09:00:00") &&
          new Date(`1970-01-01T${slot.starttime}`) <
            new Date("1970-01-01T12:00:00")
      ),
      Afternoon: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
            new Date("1970-01-01T12:00:00") &&
          new Date(`1970-01-01T${slot.starttime}`) <
            new Date("1970-01-01T18:00:00")
      ),
      Evening: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
            new Date("1970-01-01T18:00:00") &&
          new Date(`1970-01-01T${slot.starttime}`) <
            new Date("1970-01-01T21:00:00")
      ),
      Night: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
          new Date("1970-01-01T21:00:00")
      ),
    };

    const handleTimePeriodChange = (direction) => {
      const currentIndex = timePeriods.indexOf(timePeriod);
      const newIndex =
        (currentIndex + direction + timePeriods.length) % timePeriods.length;
      setTimePeriod(timePeriods[newIndex]);
    };

    const handleSlotSelection = (slot) => {
      // console.log("clicked");
      setAppointmentScheduleTime(slot.starttime);
      const selectedTime = new Date(`1970-01-01T${slot.starttime}`);
      if (
        selectedTime >= new Date("1970-01-01T09:00:00") &&
        selectedTime < new Date("1970-01-01T12:00:00")
      ) {
        setTimePeriod("Morning");
      } else if (
        selectedTime >= new Date("1970-01-01T12:00:00") &&
        selectedTime < new Date("1970-01-01T18:00:00")
      ) {
        setTimePeriod("Afternoon");
      } else if (
        selectedTime >= new Date("1970-01-01T18:00:00") &&
        selectedTime < new Date("1970-01-01T21:00:00")
      ) {
        setTimePeriod("Evening");
      } else {
        setTimePeriod("Night");
      }
      // console.log(timePeriod);
    };

    return (
      <Box>
        {isLoading && <Loader miniLoader={true} />}

        {!isLoading && (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={4}
            >
              <Box as="button" onClick={() => handleTimePeriodChange(-1)}>
                <Image src="/assets/svgs/left-tab-icon.svg" />
              </Box>
              <Text fontSize="xl" fontWeight="bold">
                {timePeriod}
                <Text fontSize="md" color="gray" display={"inline"} ml={"8px"}>
                  {timePeriodSlots[timePeriod].length} slots{" "}
                </Text>
              </Text>
              <Box as="button" onClick={() => handleTimePeriodChange(1)}>
                <Image src="/assets/svgs/right-tab-icon.svg" />
              </Box>
            </Box>

            <SimpleGrid columns={5} spacing={4}>
              {timePeriodSlots[timePeriod].map((slot) => (
                <Box
                  key={slot.starttime}
                  as="button"
                  onClick={() => handleSlotSelection(slot)}
                  borderWidth="1px"
                  borderRadius="md"
                  p={2}
                  textAlign="center"
                  bg={
                    appointmentScheduleTime === slot.starttime
                      ? "blue.500"
                      : "white"
                  }
                  color={
                    appointmentScheduleTime === slot.starttime
                      ? "white"
                      : "black"
                  }
                  borderColor={
                    appointmentScheduleTime === slot.starttime
                      ? "blue.500"
                      : "gray.300"
                  }
                  _hover={{
                    bg:
                      appointmentScheduleTime === slot.starttime
                        ? "blue.600"
                        : "gray.200",
                  }}
                >
                  {slot.starttime.substring(0, 5)}
                </Box>
              ))}
            </SimpleGrid>
          </>
        )}
      </Box>
    );
  };

  const openScheduleModal = async () => {
    // console.log("function called", availableSlots);
    setShowOptions(!showOptions); // Important to close the options panel before opening up the modal
    setAvailableSlots([]);
    setTimePeriod("Morning");
    setAppointmentScheduleTime(null);
    setAppointmentScheduleDate(new Date());
    fetchDoctorScheduleSlots(moment().format("DD-MM-YYYY"));
    scheduleVisit.current.openModal();
  };

  const handleScheduleClick = async () => {
    if (!appointmentScheduleTime) {
      showErrorToast("Please select a slot before scheduling");
    } else {
      const inputToSave = {
        input: {
          patientid: patientId,
          doctorid: doctorId,
          appointmentdate: moment(appointmentScheduleDate).format("YYYY-MM-DD"),
          appointmenttime: appointmentScheduleTime.substring(0, 5),
          // appointmentfor: "for general checkup",
          appointmenttype_cd: 693,
          client_secret: "",
          paymenttype: "cash",
          activeuserid: null,
          isdoctorcreated: 1,
        },
      };

      // console.log(inputToSave);
      try {
        const result = await CreateAppointmentByDoctor(inputToSave.input);
        console.log(result);
        if (result) {
          scheduleVisit.current.closeModal();
          refreshPageContent();
          showSuccessToast("Visit scheduled successfully");
          // navigate(`/visits`);
        }
      } catch (error) {
        console.error("Error in create visit:", error);
        showErrorToast("Error Happened While Saving.");
      }
    }
  };

  const openRescheduleModal = async () => {
    // console.log("function called", availableSlots);
    setShowOptions(!showOptions); // Important to close the options panel before opening up the modal
    setAvailableSlots([]);
    setTimePeriod("Morning");
    setAppointmentRescheduleTime(null);
    setAppointmentRescheduleDate(new Date());
    fetchDoctorSlots(moment().format("DD-MM-YYYY"));
    rescheduleVisit.current.openModal();
  };

  const handleRescheduleClick = async () => {
    if (!appointmentRescheduleTime) {
      showErrorToast("Please select a slot before rescheduling");
    } else {
      const inputToSave = {
        input: {
          patientid: patientId,
          doctorid: doctorId,
          appointmentdate: moment(appointmentRescheduleDate).format(
            "YYYY-MM-DD"
          ),
          appointmenttime: appointmentRescheduleTime.substring(0, 5),
          // appointmentfor: "Follow-up consultation",
          familyid: null,
          appointmentid: appointmentId,
          reason: "Scheduling conflict",
          type: "reschedule",
        },
      };

      try {
        const result = await UpdateAppointmentByDoctor(inputToSave.input);
        // console.log("output", result);
        if (result) {
          rescheduleVisit.current.closeModal();
          refreshPageContent();
          showSuccessToast("Visit rescheduled successfully");
          // navigate(`/visits`);
        }
      } catch (error) {
        console.error("Error in create visit:", error);
        showErrorToast("Error Happened While Saving.");
      }
    }
  };

  const handleConfirmStart = async () => {
    const inputForStart = {
      input: {
        doctorid: doctorId,
        appointmentid: appointmentId,
        type: "start",
        time: new Date().toISOString(),
      },
    };
    try {
      const result = await UpdateAppointmentStatus(inputForStart.input);
      startVisit.current.closeModal();
      navigate(
        `/startvisit?doctorId=${doctorId}&patientId=${patientId}&appointmentId=${appointmentId}`
      );
    } catch (error) {
      console.error("Error updating appointment status:", error);
    }
  };

  const handleConfirmCancel = async () => {
    const inputForStart = {
      input: {
        doctorid: doctorId,
        patientid: patientId,
        appointmentid: appointmentId,
        reason: "abc", // CHANGE IT -> TO an input box
        completed: "2",
      },
    };
    try {
      const result = await UpdateAppointmentByDoctor(inputForStart.input);
      cancelVisit.current.closeModal();
      refreshPageContent();
      navigate(`/visits`);
    } catch (error) {
      console.error("Error updating appointment status:", error);
    }
  };

  const handleConfirmAcceptVisit = async () => {
    let input = {
      doctorid: doctorId,
      patientid: patientId,
      appointmentid: appointmentId,
      completed: "1",
    };
    try {
      const result = await AcceptVisit(input);
      // console.log(result);
      // console.log(input);
      if (result != null) {
        acceptVisit.current.closeModal();
        refreshPageContent();
        showSuccessToast("Visit accepted successfully");
      } else {
      }
    } catch (error) {
      acceptVisit.current.closeModal();
      console.error("Error in Visit details accepting visit", error);
    }
  };

  const handleConfirmNoShow = async () => {
    const inputForStart = {
      input: {
        doctorid: doctorId,
        patientid: patientId,
        appointmentid: appointmentId,
        reason: "abc",
        completed: "4",
      },
    };
    try {
      const result = await UpdateAppointmentByDoctor(inputForStart.input);
      noShowVisit.current.closeModal();
      refreshPageContent();
      navigate(`/visits`);
    } catch (error) {
      console.error("Error updating appointment status:", error);
    }
  };

  const handleMessageClick = async () => {
    navigate("/messages", { state: { id: patientId } });
  };

  const handlePrintOrder = () => {
    const url = `/visitprescription?doctorId=${doctorId}&patientId=${patientId}&appointmentId=${appointmentId}`;
    window.open(url, "_blank");
  };

  const handleClick = () => {
    localStorage.setItem("CurrentVisitAppointmentId", appointmentId);
    window.location.href = `/visitdetails?patientId=${patientId}&appointmentId=${appointmentId}`;
  };

  const toggleOptions = (e) => {
    e.stopPropagation();
    setShowOptions(!showOptions);
  };

  useEffect(() => {
    
  setActionAccess(actionAccessMain(2))
  setActionAccessAppointments(actionAccessMain(15))
  setActionAccessMessage(actionAccessMain(3))
    const handleClickOutside = (event) => {
      if (
        optionsRef.current &&
        !optionsRef.current.contains(event.target) &&
        svgRef.current &&
        !svgRef.current.contains(event.target)
      ) {
        setShowOptions(false);
      }
    };

    if (showOptions) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showOptions]);

  if (borderColor === "green") borderColor = "#14C585";
  if (borderColor === "blue") borderColor = "#1C4EC5";
  if (borderColor === "gray") borderColor = "#CDCFD4";
  if (borderColor === "red") borderColor = "#E82B2B";

  return (
    <Box
      borderLeft={disableLeftBorder ? "none" : `4px solid ${borderColor}`}
      borderRadius={disableLeftBorder ? "none" : "0.3rem 0 0 0.3rem"}
      position="relative"
    >
      <Box
        bg="#ffffff"
        border="1px solid #E6E7E9"
        borderRadius="0 0.75rem 0.75rem 0"
        p="1.25rem"
        gap="0.625rem"
        boxShadow="0px 8px 20px 0px #E6E7E966"
        w="100%"
        onClick={handleClick}
        style={{ cursor: "pointer" }}
      >
        <Box display="flex" gap="0.5rem" w="100%" alignItems="center">
          {userImage ? (
            <Image
              borderRadius="full"
              w="2.75rem"
              h="2.75rem"
              src={userImage}
            />
          ) : (
            <Image
              borderRadius="full"
              w="2.75rem"
              h="2.75rem"
              src="/assets/imgs/no-image.png"
            />
          )}
          <Box>
            <Text fontSize="1rem" fontWeight="600" color="#061027">
              {userName}
            </Text>
            <Box display="flex" gap="0.25rem" alignItems="center">
              <Image src="/assets/svgs/home-calendar.svg" />
              <Text fontSize="0.75rem" fontWeight="500" color="#384052">
                {visitDate}, {visitTime}
              </Text>
            </Box>
          </Box>
          <Spacer />
          <Box
            ref={svgRef}
            position="relative"
            onClick={toggleOptions}
            height="2rem"
            display="flex"
            alignItems="center"
          >
            <Image src="/assets/svgs/more-options.svg" />
          </Box>
        </Box>
        {issue && (
          <Box mt="0.75rem">
            <Text fontSize="0.875rem" fontWeight="500" color="#384052">
              {issue}
            </Text>
          </Box>
        )}
      </Box>
      {showOptions && (
        <Box
          ref={optionsRef}
          position="absolute"
          top="3rem"
          right="1rem"
          bg="white"
          boxShadow="md"
          borderRadius="md"
          zIndex="10"
        >
          <VStack align="stretch" spacing="0">
            {isNotUpcoming ? (
              <>
                {isFinished && (
                  <Button
                    variant="ghost"
                    textAlign="left"
                    justifyContent="flex-start"
                    onClick={handlePrintOrder}
                  >
                    Print order
                  </Button>
                )}

                <Button
                  variant="ghost"
                  textAlign="left"
                  justifyContent="flex-start"
                  onClick={handleMessageClick}
                  isDisabled={
                    actionAccessMessage &&
                    (!actionAccessMessage.create || !actionAccessMessage.update)
                      ? true
                      : false
                  }
                >
                  Message patient
                </Button>
                <Button
                  variant="ghost"
                  textAlign="left"
                  justifyContent="flex-start"
                  onClick={openScheduleModal}
                  isDisabled={
                    actionAccess && !actionAccess.create ? true : false
                  }
                >
                  Schedule visit
                </Button>
              </>
            ) : (
              <>
                {statusName !== "Pending" ? (
                  <Button
                    variant="ghost"
                    textAlign="left"
                    justifyContent="flex-start"
                    onClick={() => startVisit.current.openModal()}
                    isDisabled={
                      actionAccessAppointments &&
                      (actionAccessAppointments.create || actionAccessAppointments.update)
                        ? false
                        : true
                    }
                  >
                    {visitStatus === "pause" ? "Resume visit" : "Start visit"}
                  </Button>
                ) : (
                  <Button
                    variant="ghost"
                    textAlign="left"
                    justifyContent="flex-start"
                    onClick={() => acceptVisit.current.openModal()}
                    
                    isDisabled={
                      actionAccess &&
                      (actionAccess.create)
                        ? false
                        : true
                    }
                  >
                    {"Accept Visit"}
                  </Button>
                )}
                <Button
                  variant="ghost"
                  textAlign="left"
                  justifyContent="flex-start"
                  onClick={handleMessageClick}
                  isDisabled={
                    actionAccessMessage &&
                    (!actionAccessMessage.create || !actionAccessMessage.update)
                      ? true
                      : false
                  }
                >
                  Message patient
                </Button>
                <Button
                  variant="ghost"
                  textAlign="left"
                  justifyContent="flex-start"
                  onClick={openRescheduleModal}
                  isDisabled={
                    actionAccess && !actionAccess.update ? true : false
                  }
                >
                  Reschedule visit
                </Button>
                <Button
                  variant="ghost"
                  textAlign="left"
                  justifyContent="flex-start"
                  onClick={() => cancelVisit.current.openModal()}
                  isDisabled={
                    actionAccess && !actionAccess.delete ? true : false
                  }
                >
                  Cancel visit
                </Button>
                {isUpcoming && (
                  <Button
                    variant="ghost"
                    textAlign="left"
                    justifyContent="flex-start"
                    onClick={() => noShowVisit.current.openModal()}
                    isDisabled={
                      actionAccess && !actionAccess.update ? true : false
                    }
                  >
                    Mark patient as no-show
                  </Button>
                )}
              </>
            )}
          </VStack>
        </Box>
      )}

      {/* Start Visit  */}
      <DynamicModal
        ref={startVisit}
        modalHeader={visitStatus === "pause" ? "Resume visit" : "Start visit"}
        modalBody={
          <>
            <Box>
              <Text fontSize="0.87rem" fontWeight="400">
                {visitStatus === "pause"
                  ? "Are you sure you want to resume this visit now?"
                  : "Are you sure you want to start this visit now?"}
              </Text>
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              buttonText={
                visitStatus === "pause" ? "Resume visit" : "Start visit"
              }
              onClick={handleConfirmStart}
            />
          </>
        }
      />

      {/* Start Visit  */}
      <DynamicModal
        ref={acceptVisit}
        modalHeader={"Accept Visit"}
        modalBody={
          <>
            <Box>
              <Text fontSize="0.87rem" fontWeight="400">
                Are you sure you want to accept this visit?
              </Text>
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              buttonText={"Accept Visit"}
              onClick={handleConfirmAcceptVisit}
            />
          </>
        }
      />

      {/* Schedule Visit  */}
      <DynamicModal
        ref={scheduleVisit}
        modalHeader="Schedule Visit"
        modalWidth="40%"
        modalBody={
          <>
            <Box>
              <Box mt={"8px"}>
                <DatePicker
                  defaultDate={moment(new Date()).format("DD-MM-YYYY")}
                  minDate={moment(new Date()).format("DD-MM-YYYY")}
                  // disableFutureDates={false}
                  onDateChange={(selectedDateValue) => {
                    setAppointmentScheduleDate(selectedDateValue);
                    fetchDoctorScheduleSlots(selectedDateValue);
                  }}
                  placeholderProp={"Select Date"}
                  calendarStlye={{ left: "-18px", right: "unset" }}
                />
                <NewScheduleSlotSelector availableSlots={availableSlots} />
              </Box>
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="grayBtn"
              buttonText="Schedule"
              // onClick={() => scheduleVisit.current.closeModal()}
              onClick={handleScheduleClick}
              btnStyle={{
                h: "2.5rem",
                w: "100%",
                bg: COLORS.PRIMARY_GRADIENT,
                color: COLORS.PRIMARY_WHITE,
                _hover: { bg: COLORS.PRIMARY_COLOR },
              }}
            />
          </>
        }
      />

      {/* Reschedule Visit  */}
      <DynamicModal
        ref={rescheduleVisit}
        modalHeader="Reschedule Visit"
        modalWidth="40%"
        modalBody={
          <>
            <Box>
              <Box mt={"8px"}>
                <DatePicker
                  defaultDate={moment(new Date()).format("DD-MM-YYYY")}
                  minDate={moment(new Date()).format("DD-MM-YYYY")}
                  // disableFutureDates={false}
                  onDateChange={(selectedDateValue) => {
                    setAppointmentRescheduleDate(selectedDateValue);
                    fetchDoctorSlots(selectedDateValue);
                  }}
                  placeholderProp={"Select Date"}
                  calendarStlye={{ left: "-18px", right: "unset" }}
                />
                <NewSlotSelector availableSlots={availableSlots} />
              </Box>
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="grayBtn"
              buttonText="Reschedule"
              // onClick={() => rescheduleVisit.current.closeModal()}
              onClick={handleRescheduleClick}
              btnStyle={{
                h: "2.5rem",
                w: "100%",
                bg: COLORS.PRIMARY_GRADIENT,
                color: COLORS.PRIMARY_WHITE,
                _hover: { bg: COLORS.PRIMARY_COLOR },
              }}
            />
          </>
        }
      />

      {/* Mark as No Show Visit  */}
      <DynamicModal
        ref={noShowVisit}
        modalHeader="Mark as No Show"
        modalBody={
          <>
            <Box>
              <Text fontSize="0.87rem" fontWeight="400">
                Are you sure you want to mark as no-show for your visit with{" "}
                <Box as="span" fontWeight="500">
                  {userName}
                </Box>{" "}
                scheduled on{" "}
                <Box as="span" fontWeight="500">
                  {visitDate} at {visitTime}?
                </Box>
              </Text>
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="deleteBtn"
              buttonText="Mark as no-show"
              onClick={handleConfirmNoShow}
            />
          </>
        }
      />

      {/* Mark as cancel Visit  */}
      <DynamicModal
        ref={cancelVisit}
        modalHeader="Cancel visit"
        modalBody={
          <>
            <Box>
              <Text fontSize="0.87rem" fontWeight="400">
                Are you sure you want to cancel your visit with{" "}
                <Box as="span" fontWeight="500">
                  {userName}
                </Box>{" "}
                scheduled on{" "}
                <Box as="span" fontWeight="500">
                  {visitDate} at {visitTime}?
                </Box>
              </Text>
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="deleteBtn"
              buttonText="Cancel visit"
              onClick={handleConfirmCancel}
            />
          </>
        }
      />
    </Box>
  );
};

export default PatientVisitCard;
