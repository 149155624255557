import { useState, useEffect, useRef } from "react";
import { webmFixDuration } from "./cpu-blobfix";

function getMimeType() {
  const types = [
    "audio/webm",
    "audio/mp4",
    "audio/ogg",
    "audio/wav",
    "audio/aac",
  ];
  for (let i = 0; i < types.length; i++) {
    if (MediaRecorder.isTypeSupported(types[i])) {
      return types[i];
    }
  }
  return undefined;
}

export default function CPUTranscribeAudioRecorder(props) {
  const { recording } = props;
  const [duration, setDuration] = useState(0);
  const [recordedBlob, setRecordedBlob] = useState(null);

  const streamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);

  const audioRef = useRef(null);

  const startRecording = async () => {
    setRecordedBlob(null);
    let startTime = Date.now();

    try {
      if (!streamRef.current) {
        streamRef.current = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
      }

      const mimeType = getMimeType();
      const mediaRecorder = new MediaRecorder(streamRef.current, { mimeType });
      mediaRecorderRef.current = mediaRecorder;

      mediaRecorder.addEventListener("dataavailable", async (event) => {
        if (event.data.size > 0) {
          chunksRef.current.push(event.data);
        }
        if (mediaRecorder.state === "inactive") {
          const duration = Date.now() - startTime;

          let blob = new Blob(chunksRef.current, { type: mimeType });

          if (mimeType === "audio/webm") {
            blob = await webmFixDuration(blob, duration, blob.type);
          }

          setRecordedBlob(blob);
          props.onRecordingComplete(blob);

          chunksRef.current = [];
        }
      });

      mediaRecorder.addEventListener("stop", () => {
        mediaRecorderRef.current = null;
      });

      mediaRecorder.start();
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "recording"
    ) {
      mediaRecorderRef.current.stop();
      setDuration(0);
    }
  };

  useEffect(() => {
    if (recording) {
      startRecording();
      const timer = setInterval(() => {
        setDuration((prevDuration) => prevDuration + 1);
      }, 1000);
      return () => {
        clearInterval(timer);
        stopRecording();
      };
    } else {
      stopRecording();
    }
    // Cleanup on component unmount
    return () => {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
        streamRef.current = null; // Reset the streamRef to null
      }
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current = null; // Reset the mediaRecorderRef to null
      }
    };
  }, [recording]);

  return (
    <div className="flex flex-col justify-center items-center">
      {/* {recordedBlob && (
        <audio  ref={audioRef} controls>
          <source
            src={URL.createObjectURL(recordedBlob)}
            type={recordedBlob.type}
          />
        </audio>
      )} */}
    </div>
  );
}
