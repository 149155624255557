import React from "react";
import { Box, Text, Image, Circle, Center } from "@chakra-ui/react";
import moment from "moment";

const formatDate = (dateString) => {
  if (!dateString) return "-";
  return moment(dateString).format("Do MMM, YYYY");
};

const PastPhysicalExam = ({
  type,
  condition,
  scaleHeight = "100px",
  componentBg = "#fcfcfd",
  cardBg = "white",
}) => {
  return (
    <Box
      display="flex"
      flexDir="column"
      alignItems="center"
      h={scaleHeight}
      w="100%"
      bg={componentBg}
    >
      <Box display="flex" w="100%" mt="10px">
        <Box
          p="20px"
          w="100%"
          h="50px"
          ml="10px"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          border="1px solid #E6E7E9"
          borderRadius="12px"
          backgroundColor={cardBg}
        >
          <Box display="flex" alignItems="center">
            <Box
              as="p"
              fontSize="10px"
              color="#384052"
              bg="#E6E7E966"
              borderRadius="full"
              border="1px solid #E6E7E9"
              p="5px 10px"
            >
              {type}
            </Box>
            <Box
              ml="8px"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              maxW="350px"
            >
              <Text
                title={condition}
                fontSize="16px"
                textAlign="left"
                fontWeight="600"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {condition}
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PastPhysicalExam;
