import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Text,
  Image,
  Circle,
  Center,
  Link,
  Stack,
  RadioGroup,
  Divider,
  Flex,
  Checkbox,
} from "@chakra-ui/react";
import {
  DropdownInput,
  PrimaryButton,
  PrimaryButtonIcon,
  PrimaryInput,
  SearchBar,
  Toaster,
} from "../../../components";
import moment from "moment";

import ReactMarkdown from "react-markdown";
import ModalRight from "../../../components/modal-right";
import { updateCondition } from "../../../middleware/services/visits/patient-overview/updatehealth";

import DynamicModal from "../../../components/dynamic-modal";
import DatePicker from "../../../components/date-picker";
import { format, parseISO } from "date-fns";
import { GetDoctorMasterData } from "../../../middleware/services/visits/patient-overview/updatedoctornotes";

import {
  FetchAllergiesAIPrompts,
  FetchAllergiesAIAnswer,
  FetchMyHealthAIData,
} from "../../../middleware/services/visits/aicalls";
import TypingLoader from "../../../components/typing-loader";
import AiModal from "../../../components/ai-modal/index";

const formatDate = (dateString) => {
  if (!dateString) return "-";
  return moment(dateString).format("Do MMM, YYYY");
};

const fileTypeMappings = {
  pdf: "/assets/svgs/pdf-icon.svg",
  png: "/assets/imgs/png-icon.png",
  jpg: "/assets/imgs/jpg-icon.png",
  jpeg: "/assets/imgs/jpg-icon.png",
};

const getFileIcon = (fileName) => {
  const extension = fileName.split(".").pop().toLowerCase();
  // console.log(fileTypeMappings[extension] || "/assets/svgs/jpg-icon.svg");
  return fileTypeMappings[extension] || "/assets/imgs/jpg-icon.png"; // fallback image
};

const Allergies = ({
  allergyFrom,
  problems,
  onSet,
  id,
  attachments,
  allergyidentifieddate,
  reaction,
  appointmentId,
  patientId,
  patientname,
  patientgender,
  patientage,
  doctorId,
  fetchPatientHealthData,
  showLoader,
  dismissLoader,
  showSuccessToast,
  showErrorToast,
  scaleHeight = "auto",
  componentBg = "#fcfcfd",
  cardBg = "white",
}) => {
  // console.log("Reaction", reaction);

  const dynamicModal = React.useRef();
  const editConditionModal = React.useRef();
  const reactionListModal = React.useRef();

  const [patientallergyid, setPatientallergyid] = useState("");
  const [allergytype, setAllergytype] = useState("");
  const [selectedConditionsInEdit, setSelectedConditionsInEdit] = useState([]);
  const [reasonsArray, setReasonsArray] = useState([]);

  const [allergytype_cd, setAllergytype_cd] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [reactionsContinueBtnDisabled, setReactionsContinueBtnDisabled] =
    useState(true);

  const [data, setData] = useState("");
  var [conditionsList, setConditionsList] = useState(null);
  var [reactionList, setReactionList] = useState(null);
  const [selectedConditions, setSelectedConditions] = useState([]);
  const [selectedReactions, setSelectedReactions] = useState([]);
  const [patientid, setPatientid] = useState("");
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [selectedDates, setSelectedDates] = useState({});
  const [allergiesContinueBtnDisabled, setAllergiesContinueBtnDisabled] =
    useState(true);
  const [allergyID, setAllergieID] = useState("");
  const [identifiedData, setIdentifiedData] = useState("");
  const [documentURLs, setDocumentURLs] = useState([]);
  const [patientAllergyId, setPatientAllergyId] = useState("");
  const indexMap = {};

  var inputForGet = {};
  const floatLabel = {
    color: "#384052",
    fontSize: "0.775rem",
    position: "relative",
    top: "-4px",
    left: "-4px",
    opacity: "0.5",
    width: "100%",
  };

  //-----------------------AI Insights

  const chatContainerRef = useRef(null);
  const [aianswers, setAIAnswers] = useState([]);
  const [remainingquestions, setRemainingQuestions] = useState([]);
  const [aiquestion, setAIAnalysis] = useState(null);
  const [AiInput, setAiInput] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  const [animate, setAnimate] = useState(false);
  const AIChatModel = useRef();

  useEffect(() => {
    if (animate) {
      const timer = setTimeout(() => {
        setAnimate(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [animate]);

  useEffect(() => {
    // Scroll to bottom whenever aianswers changes
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [aianswers]);
  const fetchAiDoctorPrompts = async (allergyname) => {
    showLoader();
    setRemainingQuestions([]);
    setAiInput(null);
    setAIAnswers([]);
    setInitialRender(true);
    const result = await FetchMyHealthAIData(
      {},
      patientId,
      patientname,
      patientgender,
      patientage
    );
    let Aiprompts = { ...result, allergyname };
    setAiInput(Aiprompts);
    const response = await FetchAllergiesAIPrompts(Aiprompts);
    if (response.generated_question) {
      setAIAnalysis(response);
      setRemainingQuestions(response.generated_question);
      AIChatModel.current.openRight();
    } else {
      showErrorToast("Ai tokens are not available");
      AIChatModel.current.closeRight();
    }
    dismissLoader();
  };

  const GetAIAnswer = async (prompt) => {
    // Display question immediately
    const newQuestion = { question: prompt, answer: "" };
    setAIAnswers((prevState) => [...prevState, newQuestion]);

    // Remove the question from remaining questions immediately
    setRemainingQuestions((prevState) =>
      prevState.filter((question) => question.value !== prompt)
    );

    // Trigger animation
    setAnimate(true);

    // Set initialRender to false after the first question is clicked
    setInitialRender(false);
    let AichatInput = { ...AiInput, query: prompt };

    const response = await FetchAllergiesAIAnswer(AichatInput);

    console.log(response);

    if (response) {
      // Replace "typing" with actual answer
      setAIAnswers((prevState) =>
        prevState.map((item) =>
          item.question === prompt && item.answer === ""
            ? { question: item.question, answer: response.answer }
            : item
        )
      );
    } else {
      showErrorToast("Ai tokens are not available");
      AIChatModel.current.closeRight();
    }
  };

  //-----------------------Other Functionalities

  const [dateInEdit, setDateInEdit] = useState("");
  const handleDateChangeInEdit = (date) => {
    setDateInEdit(date);
  };

  const handleReactionCheckboxChangeInEdit = (reaction) => {
    // Check if the reaction object has condition and condition_cd attributes
    if (
      reaction.hasOwnProperty("condition") &&
      reaction.hasOwnProperty("condition_cd")
    ) {
      // Convert to reaction and reaction_cd attributes
      reaction = {
        reaction: reaction.condition,
        reaction_cd: reaction.condition_cd,
      };
    }

    const isSelected = reasonsArray.some(
      (item) => item.reaction === reaction.reaction
    );

    if (isSelected) {
      // If already selected, remove the item from reasonsArray
      setReasonsArray((prevConditions) =>
        prevConditions.filter((item) => item.reaction !== reaction.reaction)
      );
    } else {
      // If not selected, add the item to reasonsArray
      setReasonsArray([...reasonsArray, reaction]);
    }
  };

  const fetchReactionsList = async () => {
    try {
      const result = await GetDoctorMasterData({
        type: "reaction",
      });
      if (result) {
        setReactionList(result);
      } else {
        showErrorToast("Something went wrong!");
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  const showReactionListInEdit = () => {
    if (reactionList == null) {
      fetchReactionsList();
    }
    setSearchValue("");
    reactionListModal.current.openRight();
  };

  const removeTag = (index) => {
    const updatedReasonsArray = [...reasonsArray];
    updatedReasonsArray.splice(index, 1);
    setReasonsArray(updatedReasonsArray);
  };

  useEffect(() => {
    if (reasonsArray.length === 0) {
      setReactionsContinueBtnDisabled(true);
    } else {
      setReactionsContinueBtnDisabled(false);
    }
  }, [reasonsArray]);

  const openEditModal = (
    patientallergyid,
    allergytype,
    allergyidentifieddate,
    reaction,
    allergytype_cd
  ) => {
    setSelectedConditionsInEdit([]);
    setAllergytype_cd(allergytype_cd);
    setPatientAllergyId(patientallergyid);
    var formatedDateInEdit = moment(allergyidentifieddate).format("DD-MM-YYYY");

    setReasonsArray([]);

    // console.log(reaction);
    reaction.forEach((item) => {
      // Assuming each drug has a "name" property
      const newReason = {
        reaction: item.reaction,
        reaction_cd: item.reaction_cd,
      };
      setReasonsArray((prevArray) => [...prevArray, newReason]);
    });
    setSelectedConditionsInEdit(allergytype);
    setDateInEdit(formatedDateInEdit);
    editConditionModal.current.openRight();
  };

  const EditMedicalConditionsCall = () => {
    let formatedproceduredate = moment(dateInEdit, "DD-MM-YYYY").format(
      "YYYY-MM-DD"
    );
    var inputForEdit = {
      doctorid: doctorId,
      patientid: patientId,
      appointmentid: appointmentId,
      type: "allergies",
      // allergytype_cd,
      allergyidentifieddate: formatedproceduredate,
      patientallergyid: id,
      reactions: reasonsArray,
      patientallergyactive: 1,
    };
    const editConditions = async () => {
      showLoader();
      try {
        // console.log(inputForEdit);
        const result = await updateCondition(inputForEdit);
        if (result) {
          showSuccessToast("Successfully Updated");
          fetchPatientHealthData();
          reactionListModal.current.closeRight();
          editConditionModal.current.closeRight();
          setAllergieID("");
        } else {
          showErrorToast("Something went wrong!");
        }
      } catch (error) {
        console.error("Error in allergies:", error);
      }
      dismissLoader();
    };

    editConditions();
  };

  //---------------------------------

  const openDeleteModal = (id, allergy) => {
    setPatientallergyid(id);
    setAllergytype(allergy);

    dynamicModal.current.openModal();
  };

  const deleteAllergy = async () => {
    const inputForDelete = {
      input: {
        doctorid: doctorId,
        patientid: patientId,
        type: "allergies",
        appointmentid: appointmentId,
        patientallergyid: patientallergyid,
        patientallergyactive: 0,
      },
    };

    try {
      showLoader();
      // console.log("input to delete", inputForDelete.input);
      const result = await updateCondition(inputForDelete.input);
      // console.log("delete result", result);
      if (result.output.result === "success") {
        showSuccessToast(`${allergytype} has been deleted`);
        fetchPatientHealthData();
        dynamicModal.current.closeModal();
      } else {
        showErrorToast("Something went wrong!");
      }
    } catch (error) {
      console.error("Error deleting Allergy:", error);
      showErrorToast("An error occurred while deleting Allergy");
    } finally {
      dismissLoader();
    }
  };

  return (
    <Box
      display="flex"
      flexDir="column"
      alignItems="center"
      w="100%"
      bg={componentBg}
    >
      <Box display="flex" w="100%" mt="10px">
        <Box w="100%">
          <Box
            p="20px"
            w="100%"
            ml="10px"
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="space-between"
            border="1px solid #E6E7E9"
            borderRadius="12px"
            backgroundColor={cardBg}
          >
            <Box display="flex" alignItems="center" w="100%">
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                flex="1"
              >
                <Text
                  title={allergyFrom}
                  fontSize="16px"
                  textAlign="left"
                  fontWeight="600"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {allergyFrom}
                </Text>
              </Box>
              <Box display="flex" alignItems="center">
                <Box
                  as="button"
                  // onClick={}
                  border="1px solid #E6E7E9"
                  borderRadius="full"
                  w="36px"
                  h="auto"
                  p="10px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => fetchAiDoctorPrompts(allergyFrom)}
                >
                  <Image w="26px" src="/assets/svgs/ai-star-outline.svg" />
                </Box>
                <Box
                  as="button"
                  border="1px solid #E6E7E9"
                  borderRadius="full"
                  w="36px"
                  h="auto"
                  p="10px"
                  ml="10px"
                  onClick={() =>
                    openEditModal(
                      patientallergyid,
                      allergytype,
                      allergyidentifieddate,
                      reaction,
                      allergytype_cd
                    )
                  }
                >
                  <Image w="13px" src="/assets/svgs/edit-gray.svg" />
                </Box>
                <Box
                  as="button"
                  border="1px solid #E6E7E9"
                  borderRadius="full"
                  w="36px"
                  h="auto"
                  p="10px"
                  ml="10px"
                  onClick={() => openDeleteModal(id, allergyFrom)}
                >
                  <Image w="13px" src="/assets/imgs/delete.png" />
                </Box>
              </Box>
            </Box>
            <Box display="flex" alignItems="center" w="100%">
              <Box
                mt={"3px"}
                display="flex"
                alignItems="center"
                backgroundColor={"#f9fafb"}
                borderLeft={"3px solid #14c585"}
                w="100%"
              >
                <Text
                  fontSize="14px"
                  fontWeight="500"
                  color="#384052"
                  pl={"4px"}
                >
                  {problems}
                </Text>
              </Box>
            </Box>
            <Text
              fontSize="12px"
              fontWeight="500"
              textAlign="left"
              color="#6a707d"
              // ml="0.5rem"
              mt="8px"
            >
              {formatDate(onSet)}
            </Text>
            <Divider my="10px" />
            <Box display="flex" w="100%" flexWrap="wrap">
              {attachments.map((attachment, index) => (
                <Box
                  key={index}
                  display="flex"
                  alignItems="center"
                  border="1px solid #E6E7E9"
                  borderRadius="8px"
                  p="5px 10px"
                  mr="10px"
                  mb="10px"
                  backgroundColor="white"
                >
                  <Box bg="#f9f9f9" p="5px" borderRadius="8px">
                    <Image src={getFileIcon(attachment.name)} w="16px" />
                  </Box>
                  <Link
                    href={attachment.link}
                    isExternal
                    fontSize="14px"
                    color="#2B6CB0"
                    ml="5px"
                  >
                    {attachment.name}
                  </Link>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Edit Functionality  */}
      <ModalRight
        ref={editConditionModal}
        handlebackarrow={() => editConditionModal.current.closeRight()}
        withBackBtn={false}
        // withBackHeader="Edit allergy"
        header="Edit allergy"
        body={
          <>
            <Box display="flex" flexDir="column" gap="20px">
              <Box>
                <Text fontSize="16px" py="10px" fontWeight="600">
                  {selectedConditionsInEdit}
                </Text>
                <DatePicker
                  disableFutureDates={true}
                  defaultDate={dateInEdit}
                  onDateChange={(selectedDateValue) =>
                    handleDateChangeInEdit(selectedDateValue)
                  }
                  placeholderProp="Date"
                  datePickerInputStyle={{ borderRadius: "10px 10px 0 0" }}
                />

                <Flex w="100%" alignItems="center" gap="2">
                  <Box
                    as="div"
                    role="button"
                    bg="white"
                    aria-haspopup="dialog"
                    display="flex"
                    alignItems="center"
                    tabIndex={0}
                    border="1px solid #e2e8f0"
                    borderRadius="0 0 10px 10px"
                    p="10px"
                    height="auto"
                    width="100%"
                    sx={{
                      _hover: { border: "2px solid #384052" },
                    }}
                    onClick={() => showReactionListInEdit()}
                    minHeight="48px"
                    flexWrap="wrap"
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      flexWrap="wrap"
                      gap="5px"
                      w="100%"
                      justifyContent={
                        reasonsArray.length === 0 ? "space-between" : "start"
                      }
                    >
                      {reasonsArray.length === 0 ? (
                        <Text pl="2" color="gray.400">
                          Reaction
                        </Text>
                      ) : reasonsArray.some(
                          (item) => item.reaction === item.reaction
                        ) ? (
                        <Text
                          pl="2"
                          color="gray.400"
                          sx={{
                            ...(reasonsArray.length === 0 ? {} : floatLabel),
                          }}
                        >
                          Reaction
                        </Text>
                      ) : (
                        <Text pl="2" color="gray.400">
                          Reaction
                        </Text>
                      )}
                      {reasonsArray.length === 0 && (
                        <Image src="/assets/imgs/right-icon.png" ml={"10px"} />
                      )}

                      {reasonsArray
                        .filter((tag) => tag.reaction === tag.reaction)
                        .map((tag, index) => (
                          <Box
                            key={index}
                            display="flex"
                            alignItems="center"
                            gap="5px"
                            bg="gray.100"
                            borderRadius="full"
                            p="1"
                          >
                            <Text fontSize="sm" px="5px">
                              {tag.reaction}
                            </Text>
                            <Box
                              as="button"
                              p="5px"
                              onClick={(e) => {
                                e.stopPropagation();
                                removeTag(index);
                              }}
                              aria-label="Remove condition"
                            >
                              <Image src="/assets/imgs/close.png" />
                            </Box>
                          </Box>
                        ))}
                    </Box>
                    <Box display="flex" justifyContent="end"></Box>
                  </Box>
                </Flex>
              </Box>
            </Box>
            <ModalRight
              ref={reactionListModal}
              handlebackarrow={() => reactionListModal.current.closeRight()}
              withBackBtn={true}
              withBackHeader="Reaction"
              body={
                <>
                  <SearchBar
                    passInputPlace="Search Reaction"
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                  <RadioGroup>
                    <Stack direction="column" gap="0">
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        borderBottom="1px solid #e2e8f0"
                        py="15px"
                      >
                        <Box display="flex" justifyContent="end" w="100%">
                          <Stack direction="column" gap="0" w="100%">
                            {reactionList && reactionList.length > 0 ? (
                              reactionList.map((category) =>
                                category.data
                                  .filter((condition) =>
                                    condition.condition
                                      .toLowerCase()
                                      .includes(searchValue.toLowerCase())
                                  )
                                  .map((condition) => (
                                    <label key={condition.condition_cd}>
                                      <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        borderBottom="1px solid #e2e8f0"
                                        p="15px"
                                        height="50px"
                                        width="100%"
                                        sx={{
                                          _hover: {
                                            border: "2px solid #384052",
                                            borderRadius: "10px",
                                          },
                                        }}
                                      >
                                        <Box
                                          key={condition.condition_cd}
                                          display="flex"
                                          alignItems="center"
                                        >
                                          <Text
                                            fontSize="16px"
                                            fontWeight="600"
                                          >
                                            {condition.condition}
                                            <br />
                                            <Box
                                              as="span"
                                              fontSize="12px"
                                              fontWeight="500"
                                              color="#6A707D"
                                            ></Box>
                                          </Text>
                                        </Box>
                                        <Box
                                          display="flex"
                                          justifyContent="end"
                                        >
                                          <Checkbox
                                            colorScheme="green"
                                            sx={{
                                              ".chakra-checkbox__control": {
                                                width: "20px",
                                                height: "20px",
                                                borderRadius: "5px",
                                              },
                                            }}
                                            onChange={() =>
                                              handleReactionCheckboxChangeInEdit(
                                                condition
                                              )
                                            }
                                            isChecked={reasonsArray.some(
                                              (item) =>
                                                item.reaction ===
                                                condition.condition
                                            )}
                                          ></Checkbox>
                                        </Box>
                                      </Box>
                                    </label>
                                  ))
                              )
                            ) : (
                              <p>Loading...</p>
                            )}
                          </Stack>
                        </Box>
                      </Box>
                    </Stack>
                  </RadioGroup>
                </>
              }
              footerActions={
                <>
                  <Box w="100%" display="flex" flexDirection="row" gap="15px">
                    <PrimaryButton
                      buttonText="Edit reactions"
                      isDisabled={reactionsContinueBtnDisabled}
                      onClick={() => reactionListModal.current.closeRight()}
                    />
                  </Box>
                </>
              }
            />
          </>
        }
        footerActions={
          <>
            <Box w="100%" display="flex" flexDirection="row" gap="15px">
              <PrimaryButton
                buttonText="Update Allergy"
                isDisabled={reactionsContinueBtnDisabled}
                onClick={EditMedicalConditionsCall}
              />
            </Box>
          </>
        }
      />

      {/* Delete Functionality  */}
      <DynamicModal
        ref={dynamicModal}
        modalHeader="Delete report?"
        modalBody={
          <>
            <Text fontSize="14px" fontWeight="600">
              Are you sure you want to delete "{allergytype}"
            </Text>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="deleteBtn"
              buttonText="Delete"
              onClick={deleteAllergy}
            />
          </>
        }
      />

      <AiModal
        ref={AIChatModel}
        modalSize="540px"
        //icon="/assets/imgs/ai-doctor.png"
        header={
          <>
            <Box display="flex" flexDir="column">
              <Text
                fontSize="1rem"
                fontWeight="700"
                backgroundImage="linear-gradient(to right top, #0d7e54, #007774, #006d94, #005ea7, #1146a0)"
                color="transparent"
                backgroundClip="text"
              >
                Aira - AI
              </Text>
              <Text fontSize="0.75rem" fontWeight="400">
                Chat with your AI health guide
              </Text>
            </Box>
          </>
        }
        headerStyle={{ p: "24px 32px", lineHeight: "normal" }}
        closeBtnStyle={{ top: "18px" }}
        footerStyle={{
          maxH: "35%",
          overflow: "hidden auto",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        }}
        withoutBg={true}
        backDropBlur="10px"
        body={
          <>
            <Flex flexDirection="column" height="100%">
              <Box
                p="24px 32px"
                ref={chatContainerRef}
                className="chat-container"
                style={{ overflowY: "auto", maxHeight: "calc(100vh - 160px)" }}
              >
                {initialRender && remainingquestions.length > 0 && (
                  <Box>
                    {remainingquestions.map((step, index) => (
                      <Box
                        key={index}
                        as="button"
                        w="100%"
                        p="16px"
                        mb="16px"
                        fontSize="14px"
                        fontWeight="600"
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        borderRadius="10px"
                        border="1px solid #7795DC"
                        bg="#EAEDF5"
                        color="#163E9E"
                        onClick={() => GetAIAnswer(step.value)}
                        className={`question-box ${animate ? "slide-in" : ""}`}
                      >
                        {step.value ? step.value : "-"}
                        <Image src="/assets/imgs/right-icon.png" />
                      </Box>
                    ))}
                  </Box>
                )}
                {aianswers.map((step, index) => (
                  <React.Fragment key={index}>
                    <Box
                      as="div"
                      bg="#061027"
                      p="12px 16px"
                      w="fit-content"
                      float="right"
                      borderRadius="10px 10px 1px 10px"
                      className={`answer-box ${animate ? "slide-in" : ""}`}
                    >
                      <Text fontSize="14px" fontWeight="500" color="white">
                        {step.question ? step.question : "-"}
                      </Text>
                    </Box>

                    <Box
                      as="div"
                      my="20px"
                      w="fit-content"
                      display="flex"
                      alignItems="end"
                      float="left"
                      className={`answer-box ${animate ? "slide-in" : ""}`}
                    >
                      <Image
                        bg="#061027"
                        p="4px"
                        mr="8px"
                        borderRadius="6px"
                        src="/assets/imgs/ai-chat-logo.png"
                      />
                      <Box
                        bg="#E6E7E980"
                        p="12px 16px"
                        // fontSize="14px"
                        // fontWeight="500"
                        // color="#061027"
                        borderRadius="10px 10px 10px 1px"
                      >
                        {/* {step.answer ? step.answer : <TypingLoader />} */}
                        {step.answer ? (
                          <ReactMarkdown>{step.answer}</ReactMarkdown>
                        ) : (
                          <TypingLoader />
                        )}
                      </Box>
                    </Box>
                  </React.Fragment>
                ))}
              </Box>
            </Flex>
          </>
        }
        footerActions={
          !initialRender && (
            <Box
              p="24px 32px"
              display="flex"
              w="100%"
              h="100%"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Box w="100%">
                {remainingquestions.map((step, index) => (
                  <Box
                    key={index}
                    as="button"
                    w="100%"
                    p="16px"
                    mb="16px"
                    fontSize="14px"
                    fontWeight="600"
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    borderRadius="10px"
                    border="1px solid #7795DC"
                    bg="#EAEDF5"
                    color="#163E9E"
                    onClick={() => GetAIAnswer(step.value)}
                    className={`question-box ${animate ? "slide-in" : ""}`}
                  >
                    {step.value ? step.value : "-"}
                    <Image src="/assets/imgs/right-icon.png" ml={"10px"} />
                  </Box>
                ))}
              </Box>
            </Box>
          )
        }
      />
    </Box>
  );
};

export default Allergies;
