import { getLoginID } from "../../../auth";
import FetchData from "../../client";
import formAPIModelinventoryLogDetails from "../../domains/inventorylogdetails/logdetails";
import formAPIModelinventoryLogs from "../../domains/inventorylogs/logs";



// with formatting output
export const getInventoryLogs = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    // to change API - getinventorylogs
    const result = await FetchData("midoc_getinventorylogs", inputData);
    //console.log(result,"Home db response")
    if(result?.output?.message == "No inventory logs found"){
      return result;
    }else if(result?.output?.data) {
    var data = formAPIModelinventoryLogs(result.output.data)
    //let pageData = {
      let count = result?.output?.count;
      let pageno  = result?.output?.pageno;
      let pagesize =  result?.output?.pagesize;
   // }
    var finalOutput = {
        data,
        count,
        pageno,
        pagesize
    }
    return finalOutput;
    }

  } catch (error) {
    console.error('Error in getInventory:', error);
    throw error;
  }
};


export const getInventoryLogDetail = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    // to change API - getinventorylogdetail
    const result = await FetchData("midoc_getinventorylogdetail", inputData);
    //console.log(result,"Home db response")
    if(result?.output?.message == "No inventory found"){
      return result
    }else if(result?.output?.data) {
    var finalOutput = formAPIModelinventoryLogDetails(result.output.data);
   
    return finalOutput;
    }

  } catch (error) {
    console.error('Error in getInventory:', error);
    throw error;
  }
};