import React, { useRef, useState,useEffect, useLayoutEffect } from "react";
import MiniSideBar from "../../components/mini-sidebar";
import {
  Box,
  Card,
  CardFooter,
  Center,
  Circle,
  Divider,
  Flex,
  Image,
  InputGroup,
  Menu,
  MenuButton,
  Spacer,
  Stack,
  Text,
  Textarea,
  VStack,
  MenuList,
  MenuItem,
  InputRightElement,
  Input,
  Td,
  Tr,
  Step,
  StepIndicator,
  StepTitle,
  StepDescription,
  Stepper,
  StepSeparator,
  UnorderedList,
  ListItem,
  CardBody,
  ToastProvider,
  CheckboxGroup,
  Checkbox,
  RadioGroup,
  Radio,
  useDisclosure,
  Collapse,
} from "@chakra-ui/react";
import SecondaryNav from "../../components/seconday-nav";
import PrimaryButton from "../../components/primary-button";
import MiniDropdownInput from "../../components/mini-dropdown";
import GrayCard from "../../components/card";
import SearchBar from "../../components/search-bar";
import TypingLoader from "../../components/typing-loader";
import {COLORS} from "../../components/styles/colors";
import ToggleTab from "../../components/accordion";
import DynamicModal from "../../components/dynamic-modal";
import ModalRight from "../../components/modal-right";
import { Link, NavLink, useLocation } from "react-router-dom";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import SimpleTable from "../../components/simple-table";
import moment from "moment";
import { DropdownInput, PrimaryInput, Toaster } from "../../components";

import {
    BrowserRouter as Router,
    generatePath,
    Routes,
    Route,
    useNavigate,
    useParams,
} from "react-router-dom";
import { actionAccessMain, downloadDocument, formatDate_YYYY_MM_DD, getLoginID, handleAlphaNumericDotAndSpace, useScreenType, validationOfNumberWithOnly1Decimal } from "../../auth";
import { AddMedicine, DeleteMedicine, EditMedicine, FetchFilteredMedicinesList, FetchMedicationsData, FetchMedicineAIAnswer, FetchMedicineAIPrompts, FetchMedicineViewDetails, FetchMyHealthAIData, GetMedicineName } from "../../middleware/services/medicines";
import MobileNavbar from "../../components/mobile-navbar";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import { Button } from "react-day-picker";
import { uploadImageInProfile } from "../../middleware/services/profile";
import { GetAIMedicineComposition } from "../../middleware/services/visits/patient-overview/updatedoctornotes";
import FetchData from "../../middleware/client";
import AiModal from '../../components/ai-modal/index';
import DatePicker from "../../components/date-picker";
import { format } from "date-fns";


const MedicineViewIndex = () => {
  var [doctorid, setDoctorid] = useState(getLoginID());
  var [medicineViewData, setMedicineViewData] = useState(null);
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const screenType = useScreenType();
  const { showSuccessToast, showErrorToast } = Toaster();

  const addmedications = useRef();
  const fileInputRef = useRef(null);
  const AIChatModel = useRef();
  const chatContainerRef = useRef(null);
  const learnMoreModal = React.useRef();
  const editmedications = React.useRef();
  const detailsmodel = React.useRef();
  const fileInputRefUpdate = useRef(null);
  const dynamicModal = React.useRef();

  // Pagination 
  const [name, setName] = useState("");
  // const [lastUsedDate, setLastUsedDate] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [totalItemCount, setTotalItemCount] = useState(0);
  
  let itemPerPageCommonValue = 50;

  let [pagesize, setPagesize] = useState(itemPerPageCommonValue);
  let [pageno, setPageno] = useState(1);


  // states for item count display
  let [countTo, setCountTo] = useState(itemPerPageCommonValue);
  let [countFrom, setCountFrom] = useState(1);
  let [totalExceedsPageCount, setTotalExceedsPageCount] = useState(false);

  const [showFilterCount, setShowFilterCount] = useState(false);
  const [noData, setNoData] = useState(false);


  // add medicine
  const [Composition, setComposition] = useState("");
  const [dosage, setMedicineDosage] = useState(null);
  const [hospitalname, setHospitalname] = useState(null);
  const [medicinename, setMedicineName] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [medicineexpirydate, setMedicineExpiryDate] = useState('');
  const [benifit, setBenifit] = useState('')
  const [sideEffects, setSideEffects] = useState('')
  const [dosageunit, setMedicineDosageUnit] = useState("mg");
  const [frequency, setMedicineDosageFrequency] = useState("");
  const [medicinetype, setMedicineType] = useState("Tablet");
  const [morning, setMorning] = useState("0");
  const [setevening, setEvening] = useState("0");
  const [setnight, setNight] = useState("0");
  const [beforefood, setBeforefood] = useState("0");
  const [afterfood, setAfterfood] = useState("0");
  const [prescribedby, setPrescribedBy] = useState(null);
  const [suggestions, setSuggestions] = useState({});
  const [treatmentteamdata, setTreatmentTeamData] = useState("");
  const [showDocNamesDropdown , setShowDocNamesDropdown] = useState(true)
  const [showAIModal , setShowAIModal] = useState(true)
  const [isChecked , setIsChecked] = useState(0)
  const [filenameArray, setFilenameArray] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [boolValForSuggestions , setBoolValForSuggestions] = useState(true)
  const [medicineTypeRadio, setMedicineTypeRadio] = useState("Tablet")
  const [medicinetypeFilter, setMedicineTypeFilter] = useState("All")
  const [aianswers, setAIAnswers] = useState([]);
  const [remainingquestions, setRemainingQuestions] = useState([]);
  const [aiquestion, setAIAnalysis] = useState(null);
  const [AiInput, setAiInput] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  const [animate, setAnimate] = useState(false);

  // Edit Medicine States
  var [id, setID] = useState();
  const [minDateStateEdit , setMinDateStateEdit] = useState('')
  const [maxDateStateEdit , setMaxDateStateEdit] = useState('')
  const [filenameforUpload, setFilenameforUpload] = useState([])
  const today = moment().format("YYYY-MM-DD HH:mm:ss.SSS");
  const [medicinedata, setMedicinedata] = useState(null);
  const [documentURLForEdit, setDocumentURLForEdit] = useState([]);
  var [title, setTitle] = useState();
  var [data, setData] = useState(null);

  const [ toDateMinMax , setToDateMinMax] = useState("")
  const [ fromDateMinMax , setFromDateMinMax] = useState("")

  const TableHeader = [
    { key: "medicine", label: "Medicine Name" },
    { key: "composition", label: "Composition" },
    { key: "dosage", label: "Dosage" },
    { key: "consumption", label: "Consumption" },
    { key: "lastused", label: "Last Used" },
    { key: "actions", label: "Actions" },
  ];

  const [actionAccess, setActionAccess] = useState(null);
  const { isOpen, onToggle } = useDisclosure()
  // useEffect(() => {
  //   fetchMedicinesView();       
  // }, []);
  // const fetchMedicinesView = async () => {
  //   showLoader()
  //   try {
  //     const result = await FetchMedicineViewDetails({
  //       doctorid,
  //     });
  //     if (result != null) {
  //       setMedicineViewData(result);
  //     } else {
  //       //setPatientData("");
  //       dismissLoader()
  //     }
  //     dismissLoader()
  //   } catch (error) {
  //     console.error("Error in home:", error);
  //   }
  // };

  // Pagination & Page load

  useEffect(() => {
    
  setActionAccess(actionAccessMain(6))
    showLoader();
    var input = {
      // "doctorid": getLoginID(),
      "pageno": 1,
      pagesize
    }
    var getMedicinesList = async () => {
      try{
        var result = await FetchMedicineViewDetails(input);

        if(result?.data){
          setMedicineViewData(result.data);
          let count = result?.count;
          let pageno = result?.pageno;
          let pagesize = result?.pagesize;
          setTotalItemCount(count)
          let totalPagesCalc = count / pagesize;
          let totalPagesCount = Math.ceil(totalPagesCalc);

          setTotalPages(totalPagesCount);

          setTotalItemCount(count)
          if(result && result.count  < itemPerPageCommonValue){
            setCountTo(result.count)
            setTotalExceedsPageCount(true)
          }else if(result.count > itemPerPageCommonValue && pageno == 1) {
            setCountTo(itemPerPageCommonValue);
            setTotalExceedsPageCount(false);
          }

        } else{
          setMedicineViewData([])
        }
      } catch (error) {
        console.error("Error in home:", error);
      }
      dismissLoader();
    };

    getMedicinesList();
  }, [refresh])

  const getFilteredResults = () => {
    // if(!name && !email && !specialization && (!status || status== '')){
    //       showErrorToast('Please enter the inputs to filter.')
    //       return;
    //     }
    const tableContainer = document.querySelector('.tableContainer');
    if (tableContainer) {
      tableContainer.scrollTop = 0;
    }
    showLoader();

    const trimmedName = name?.trim();
    var input = {
      "doctorid": getLoginID(),
        "searchtext": trimmedName,
        // 'medicinename': name,
        // 'modifieddate': lastUsedDate,
        'startdate': formatDate_YYYY_MM_DD(fromDate),
        'enddate': formatDate_YYYY_MM_DD(toDate),
        "pageno": pageno,
        pagesize
    };
    var getMedicinesList = async () => {
      try {
        var result = await FetchFilteredMedicinesList(input);

        if (result?.data) {
          setMedicineViewData(result.data);
          let count = result?.count;
          let pageno = result?.pageno;
          let pagesize = result?.pagesize;
          setTotalItemCount(count)
          let totalPagesCalc = count / pagesize;
          let totalPagesCount = Math.ceil(totalPagesCalc);
          // alert(totalPages)
          setTotalPages(totalPagesCount);
          // setFilteredData(result.data);
          setTotalItemCount(result.count)
          // if(result && result.count  < itemPerPageCommonValue){
          //   setCountTo(result.count)
          //   setTotalExceedsPageCount(true)
          // }
          if (count < itemPerPageCommonValue) {
            setCountTo(count);
            setTotalExceedsPageCount(true);
          }else if(count > itemPerPageCommonValue && pageno == 1) {
            setCountTo(itemPerPageCommonValue);
            setTotalExceedsPageCount(false);
          }
        }else{
          setMedicineViewData([])
        }
      } catch (error) {
        console.log(error, "error in getDoctorsList");
      }
      dismissLoader();
    };

    getMedicinesList();
  };

  const handleApplyFilters = () => {
    // if(!patientName && !email && !mobile && (!plan || plan== '')){
    //   showErrorToast('Please enter the inputs to filter.')
    //   return;
    // }
    setShowFilterCount(true);
    setPageno(1)
    setCurrentPage(1)
    getFilteredResults();
  };

  const resetFields = () => {
    setName('')
    setFromDate('')
    setToDate('')
    setFromDateMinMax(null)
    setToDateMinMax(null)
    setPageno(1);
    //clear states for correct item count
    setPagesize(itemPerPageCommonValue);
    setCountFrom(1)
    setCountTo(itemPerPageCommonValue)
    setCurrentPage(1)
    setShowFilterCount(false);

    setRefresh(!refresh);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      // console.log('Enter key pressed');
      setPageno(1);
      setPagesize(itemPerPageCommonValue);
      setCountFrom(1);
      setCountTo(itemPerPageCommonValue);
      setCurrentPage(1)

      handleApplyFilters();
    }
  };

  const handleChangeName = (event) => {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[^A-Za-z0-9\s@.]/g, '').slice(0, 50);
    event.target.value = inputValue;
    setName(event.target.value);
  };

   

  const handleFromDate = (selectedDate) => {
    const formattedDate = format(selectedDate, "dd-MM-yyyy");
    // console.log('formattedDAte: ', formattedDate)
    setFromDate(formattedDate);

    setFromDateMinMax(selectedDate);
  };
  const handleToDate = (selectedDate) => {
    const formattedDate = format(selectedDate, "dd-MM-yyyy");
    setToDate(formattedDate);

    setToDateMinMax(selectedDate);
  };

  // const handleLastUsedDate = (selectedDate) => {
  //   const formattedDate = format(selectedDate, "dd-MM-yyyy");
  //   // console.log('formattedDAte: ', formattedDate)
  //   setLastUsedDate(formattedDate);
  // };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // ApplyFunction();
    // handleApplyFilters()
  };



  // const ApplyFunction = async () => {
  //   if(!name &&!fromDate && !toDate){
  //     showErrorToast('Please enter the inputs to filter.')
  //     return;
  //   }
  //   showLoader();
  //   try {
  //     setPageno(1);
  //     setPagesize(itemPerPageCommonValue);
  //     setCountFrom(1);
  //     setCountTo(itemPerPageCommonValue);
  //     setCurrentPage(1)

  //     var inputForFilterDetails = {
  //       "doctorid": getLoginID(),
  //       "searchtext": name,
  //       // "modifieddate": formatDate_YYYY_MM_DD(lastUsedDate),
  //       "startdate": formatDate_YYYY_MM_DD(fromDate),
  //       "enddate": formatDate_YYYY_MM_DD(toDate),
  //       //"pageno":currentPage,
  //       "pageno":1,
  //       pagesize
  //     }

  //       const result = await FetchFilteredMedicinesList(inputForFilterDetails);
  //       // console.log(result, "APPly function result")
  //       if(result?.data){
  //         setMedicineViewData(result.data);
  //         let count = result?.count;
  //         let pageno = result?.pageno;
  //         let pagesize = result?.pagesize;

  //         let totalPagesCalc = count / pagesize;
  //         let totalPagesCount = Math.ceil(totalPagesCalc);
  //         setTotalPages(totalPagesCount)
          
  //         setTotalItemCount(result?.count)
  //         // if(result && result?.count  < itemPerPageCommonValue){
  //         //   setCountTo(result?.count)
  //         //   setTotalExceedsPageCount(true)
  //         // }
  //        // console.log(count, " count")
  //        // console.log(itemPerPageCommonValue, " itemPerPageCommonValue")
  //         if (count < itemPerPageCommonValue) {
  //           setCountTo(count);
  //           setTotalExceedsPageCount(true);
  //         }else if(count > itemPerPageCommonValue && pageno == 1) {
  //           setCountTo(itemPerPageCommonValue);
  //           setTotalExceedsPageCount(false);
  //         }
  //       } else{
  //         setMedicineViewData([])
  //       }
  //     } catch (error) {
  //       console.error('Error in ApplyFunction:', error);
  //     }
  //     dismissLoader();
  // };

  useEffect(()=>{
    if(pageno){
      
       getFilteredResults();
       }
  },[pageno])

  const handleNextClick = () => {
    let page = pageno +1 ;
  //  alert(page)
    setPageno(page);
    setCurrentPage(currentPage + 1);
   // getFilteredResults();
     
   // item count logic for next
   if(countTo + pagesize < totalItemCount){
    setCountTo(countTo + pagesize)
   }else{
     setCountTo(totalItemCount)
   }
   
   setCountFrom(countFrom + pagesize)
  };
  const handlePrevClick = () => {
    let page = pageno -1 ;
    //alert(page)
    setPageno(page);
   // setPageno(pageno - 1);
    setCurrentPage(currentPage - 1);
   // getFilteredResults();
    // item count logic for previous
    
    setCurrentPage(currentPage - 1);
    if(countTo == totalItemCount){
         //setCountTo(Math.floor(totalItemCount / 10) * 10)
       let updatedCountUnroundedValue  = totalItemCount - pagesize;
      setCountTo(Math.ceil(updatedCountUnroundedValue / itemPerPageCommonValue ) * itemPerPageCommonValue )

    }else{
      setCountTo(countTo - pagesize)
    }
    
    setCountFrom(countFrom - pagesize)
  };

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    return moment(dateString).format("Do MMM, YYYY");
  };

  // ------------------------------------------------------------
  // Add medication

  const handlemedicinename = (event) => {
    setMedicineName(event.target.value);
  };

  const handleimageselection = async (e) => {
    e.preventDefault();
    setMedicineName("")
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setSelectedImage(reader.result);
    };
    reader.readAsDataURL(file);
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg", "image/webp"];
    if (!allowedTypes.includes(file.type)) {
      showErrorToast("Invalid file type. Please select a PNG, JPG or JPEG file.");
    dismissLoader();
      return false;
    }
    showLoader();
    const result = await GetMedicineName(file, file.name, file.type);
    if(result?.output?.message  == "No credits are available"){
      showErrorToast("No sufficient tokens available")
    }else{
    setMedicineName(result.medicine_name);
    setComposition(result.chemical_composition)

     if(result.expiry_date !== "00-0000"){
      //const formatteddate = moment(result.expiry_date).format("DD-MM-yyyy")
      setMedicineExpiryDate(result.expiry_date);
     }
    setBenifit(result.benefit);
    setSideEffects(result.side_effects);
    // showmedicinename.current.openRight();
    }
    
    
  e.target.value = '';
    dismissLoader();
  };

  const handlemedicinetypechange = (event) => {

    setMedicineType(event);
    if(event == "Tablet" || event == "Capsule"){
      setMedicineDosageUnit("mg");
    }else if (event == "Syrup" || event == "Injection"){
      
      setMedicineDosageUnit("ml");
    }else if (event == "Ointment"){
      
      setMedicineDosageUnit("grms");
    }
  };

  const handleChangeDosageUnit = (selectedOption) => {
    setMedicineDosageUnit(selectedOption.label);
  };

  const fetchComposition = async (medicineName) => {
    const inputData = {
      input: {
        name: medicineName,
      },
    };
    if(medicineName){
    try {
       showLoader();
      const result = await GetAIMedicineComposition(inputData.input);
      // console.log(result);
      if (result) {
        showSuccessToast("Medicine composition fetched successfully");
        setComposition(result.chemical_composition);
        setMedicineExpiryDate(result.expiry_date);
        setBenifit(result.benefit);
    setSideEffects(result.side_effects);
      }
      dismissLoader()
    } catch (error) {
      console.error("Error Fetching Medicine composition:", error);
      showErrorToast("An error occurred while Fetching Medicine composition");
    } 
      
  }else{
    showErrorToast("Please provide medicine name or upload medicine photo")
  }
  };

  const handleconsumptionfrequencychange = (selectedOption) => {
    if(selectedOption == "1"){
      setMedicineDosageFrequency("1");
    }else  if(selectedOption == "2"){
      setMedicineDosageFrequency("2");
    }else  if(selectedOption == "3"){
      setMedicineDosageFrequency("3");
    }else  if(selectedOption == "4"){
      setMedicineDosageFrequency("4");
    }
  };

  const handleExpiryDateChange = (event) => {
    //const formattedDate = moment(selecteddate).format('YYYY-MM-DD');
    setMedicineExpiryDate(event);
  };

  const handlemedicinetime = (values) => {
    if (values.includes("morning")) {
      setMorning(1);
    } else {
      setMorning(0);
    }
  
    if (values.includes("evening")) {
      setEvening(1);
    } else {
      setEvening(0);
    }
  
    if (values.includes("night")) {
      setNight(1);
    } else {
      setNight(0);
    }
  };

  const handlemedicinetimebeforeafter = (values) => {
    if (values.includes("beforefood")) {
      setBeforefood(1);
    } else {
      setBeforefood(0);
    }
  
    if (values.includes("afterfood")) {
      setAfterfood(1);
    } else {
      setAfterfood(0);
    }
  };

  const handleSurgeonNameChange = (surgeonName) => {
    let formattedValue = surgeonName.replace(/[^0-9a-zA-Z\s.]/g, "")
    setPrescribedBy(formattedValue);

    // Filter surgeon names based on input
    if (formattedValue) {
      filterSurgeonNames(formattedValue);
    } else {
      setSuggestions([]);
    }
    setPrescribedBy(formattedValue)
  };

  const filterSurgeonNames = (surgeonName) => {
    const filtered = treatmentteamdata && treatmentteamdata.filter((surgeon) =>
      surgeon.doctorname?.toLowerCase().includes(surgeonName.toLowerCase())
    );
    setSuggestions(filtered);
  };

  const listOfSurgeonNames = ( isImage) => {
 
    const allSuggestions = treatmentteamdata && treatmentteamdata
  
    setSuggestions(allSuggestions);
    // setBoolValForSuggestions(!boolValForSuggestions) 
    if(isImage){
      setShowDocNamesDropdown(!showDocNamesDropdown)
  
    }
  };

  const handleSelect = (suggestion) => {
    setPrescribedBy(suggestion.doctorname);
    setSuggestions([]);
  };

  const handleIsCurrent = (e)=>{
    if(e.target.checked){
      setIsChecked(1)
    }else{
      setIsChecked(0)
    }
  }

  const handleDelete = (index) => {
    const newArray = [...filenameArray];
    newArray.splice(index, 1);
    setFilenameArray(newArray);
  };

  const addMedicationUploadDocument = () => {
    fileInputRef.current.click();
  }

  const handleMedicationUpload = async (e) => {
    e.preventDefault();
    showLoader();

    const file2 = e.target.files[0];
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg", "image/webp"];
    if (!allowedTypes.includes(file2.type)) {
      showErrorToast("Invalid file type. Please select a PNG, JPG or JPEG file.");
      setTimeout(() => {
        
        dismissLoader();
      }, 1000);
      return false;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file2);
    //console.log(file2 , "File")
    const result = await uploadImageInProfile(file2, file2.name);
    //console.log(result , "result.name ");
    const filename = result.name;
    setFilenameArray(prev => [...prev, filename]);
    
    e.target.value = ''; 
    dismissLoader();

  };

  const fetchAiDoctorPrompts = async (medicinename)=>{
    showLoader();
    setRemainingQuestions([])
    setAiInput(null);
    setAIAnswers([])
    setInitialRender(true)
    const result = await FetchMyHealthAIData({
      // patientid,
    });
    // console.log(result)
    let Aiprompts = { ...result, medicinename };
      setAiInput(Aiprompts);
      const response = await FetchMedicineAIPrompts(Aiprompts);
      if (response.generated_question) {
        setAIAnalysis(response);
        setRemainingQuestions(response.generated_question);
        AIChatModel.current.openRight();
      }
      dismissLoader();
  }

  let input = {
    doctorid: "",
    medicinedetail: [
      {
        medicinename: medicinename,
        chemical_composition: Composition,
        medicinetype: medicinetype,
        dosage: dosage,
        dosageunit:dosageunit,
        // startdate: fromdate,
        // enddate: todate,
        frequency: frequency,
        morning: morning,
        afternoon: "",
        evening: setevening,
        night: setnight,
        beforefood: beforefood,
        afterfood: afterfood,
        medicineexpirydate: medicineexpirydate,
        iscurrentmedicine: isChecked,
        longtext: "",
        documents: filenameArray.map(filename => ({ filename }))
      },
    ],
  };

  const addmedicine = async () => {
    showLoader();
    if (moment(input.medicinedetail[0].startdate, 'DD-MM-YYYY', true).isValid()) {
    input.medicinedetail[0].startdate = moment(input.medicinedetail[0].startdate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    }
    if (moment(input.medicinedetail[0].enddate, 'DD-MM-YYYY', true).isValid()) {
    input.medicinedetail[0].enddate = moment(input.medicinedetail[0].enddate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    }
    //console.log(input , " input to add medictiaon")
    if (medicinename == "") {
      showErrorToast("Please specify or upload any medicine");
      setTimeout(() => {
      dismissLoader();
      }, 1000);
      return false;
    } else {
      const result = await AddMedicine(input);
      
      if (result) {
        // if(showAIModal){
        //   await fetchAiDoctorPrompts(medicinename);
        // }
        showSuccessToast("Medicine created Successfully");
        // FetchData(medicinetypeFilter, activeTab === 1? "0": "1");
        addmedications.current.closeRight();
        // fetchMedicinesView();
        // getMedicinesList();
        getFilteredResults();
      }
    }
    dismissLoader();
  };

  const makeAicall = async ()=>{
    const inputData = {
      input: {
        name: medicinename,
      },
    };
    if(medicinename){
    try {
     showLoader();
    const result = await GetAIMedicineComposition(inputData.input);
    if(result){
        // setMedicineName(result.medicine_name);
        setComposition(result.chemical_composition)

        if(result.expiry_date !== "00-0000"){
          //const formatteddate = moment(result.expiry_date).format("DD-MM-yyyy")
          setMedicineExpiryDate(result.expiry_date);
        }
        setBenifit(result.benefit);
        setSideEffects(result.side_effects);
        learnMoreModal.current.openModal()
         dismissLoader();
    }
    }catch(error){

    }
  }
};

const addData = () => {
  setFilenameArray([]);
  setMedicineName("") 
  setComposition("") 
  // setFromDate(moment().format("DD-MM-yyyy"))
  // setToDate(moment().format("DD-MM-yyyy"))
  setHospitalname(null)
  setPrescribedBy(null)
  setMedicineType("Tablet")
  setMedicineDosageUnit("mg")
  setMedicineDosage(null)
  setMedicineDosageFrequency("")
  setMorning("0")
  setEvening("0")
  setNight("0")
  setComposition("")
  setMedicineExpiryDate("")
  setShowAIModal(true)
  addmedications.current.openRight()
  setShowDocNamesDropdown(false)
 
}

// --------------------------------------------
// AIChatModel

const GetAIAnswer = async (prompt) => {
  // Display question immediately
  const newQuestion = { question: prompt, answer: "" };
  setAIAnswers((prevState) => [...prevState, newQuestion]);

  // Remove the question from remaining questions immediately
  setRemainingQuestions((prevState) => prevState.filter((question) => question.value !== prompt));

  // Trigger animation
  setAnimate(true);

  // Set initialRender to false after the first question is clicked
  setInitialRender(false);
  let AichatInput = { ...AiInput, query:prompt };


  const response = await FetchMedicineAIAnswer(AichatInput);

  if (response) {
    // Replace "typing" with actual answer
    setAIAnswers((prevState) =>
      prevState.map((item) =>
        item.question === prompt && item.answer === ""
          ? { question: item.question, answer: response.answer }
          : item
      )
    );
  }
};

// ------------------------------------------------------------
  // Edit medication

  const handleeditmedication = (item)=>{
    
    setID(item.id);
    setMedicineName(item.medicinename);
    setComposition(item.chemicalcomposition);
    setMedicineType(item.medicinetype);
    setMedicineDosage(item.dosage);
    setMedicineDosageUnit(item.dosageunit);
    // const stratdate =  moment(item.medicationstartdate, 'YYYY-MM-DD').format('DD-MM-yyyy');
    // const enddate =  moment(item.medicationenddate, 'YYYY-MM-DD').format('DD-MM-yyyy');
    // setFromDate(stratdate)
    // setToDate(enddate);
    setMedicineDosageFrequency(item.frequency);
    setMorning(item.morning);
    setEvening(item.evening);
    setNight(item.night);
    setBeforefood(item.beforefood);
    setAfterfood(item.afterfood);
    setPrescribedBy(item.prescribedby)
    setHospitalname(item.prescribedhospitalname)
    setIsChecked(item.iscurrentmedicine)
    setMaxDateStateEdit("")
    setMinDateStateEdit("")
    setMedicineExpiryDate(item.medicineexpirydate)
    setShowDocNamesDropdown(false)
    setShowAIModal(true)
    editmedications.current.openRight();
  }

  // Update data
  const editmedicine = async () => {
    let input = {
      'doctorid': getLoginID(),
      "id": id,
      documenttype_cd: "",
      documentcategory_cd: "",
      documentname: "",
      documenturl: "",
      prescribedby: prescribedby,
      hospitalname: hospitalname,
      // patientname: patientname,
      // age: age,
      documentdate: today,
      medicinename: medicinename,
      chemical_composition: Composition,
      medicinetype: medicinetype,
      dosage: dosage,
      dosageunit:dosageunit,
      // startdate: fromdate,
      // enddate: todate,
      frequency: frequency,
      morning: morning,
      afternoon: "",
      evening: setevening,
      night: setnight,
      beforefood: beforefood,
      afterfood: afterfood,
      medicineexpirydate: medicineexpirydate,
      iscurrentmedicine: isChecked,
      longtext: "",

      documents: filenameforUpload.map(filename => ({ filename }))

    };
    if (moment(input.startdate, 'DD-MM-YYYY', true).isValid()) {
      input.startdate = moment(input.startdate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    }
    if (moment(input.enddate, 'DD-MM-YYYY', true).isValid()) {
      input.enddate = moment(input.enddate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    }
   
   //console.log(input,"Edit input")
    if (medicinename == "") {
      showErrorToast("Please specify or upload any medicine");
      return false;
    } else {
     
      showLoader();
      const result = await EditMedicine(input);
      
      if (result) {
        // if(showAIModal){
        // await fetchAiDoctorPrompts(input.medicinename);
        // }
        showSuccessToast("Medicine updated Successfully");
        fetchData(medicinetypeFilter, activeTab === 1? "0": "1");
        getFilteredResults()
        editmedications.current.closeRight();
        // detailsmodel.current.closeRight();
        
      }
      setTimeout(() => {
        
      dismissLoader();
      }, 2000);
    }
  };

  const updateMedicationDocuments = () => {

    fileInputRefUpdate.current.click();
  }

  const handleMedicationUploadUpdate = async (e) => {
    e.preventDefault();
    showLoader();

    const file2 = e.target.files[0];
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg", "image/webp"];
    if (!allowedTypes.includes(file2.type)) {
      showErrorToast("Invalid file type. Please select a PNG, JPG or JPEG file.");
      setTimeout(() => {
        
        dismissLoader();
      }, 1000);
      return false;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file2);
    
    const result = await uploadImageInProfile(file2, file2.name);
 
    const filename = result.name;
    // setMedicineData(prevData => [...prevData, filename]);
    setFilenameforUpload(prevFilenames => [...prevFilenames, filename]);
    //console.log(filenameforUpload, "filenameforUpload")

    // below we are setting the documents uploaded in edit, and we are checking if the prevFileNames is an array or not
    //  as when prevFileNames is empty it is giving error that iprevFileNames is not an array.
    setDocumentURLForEdit(prevFileNames => {
      if(!Array.isArray(prevFileNames)){
        return[{
          documentname: filename,
          documenturl: filename,
          imagepath: "https://midocfileserver.blob.core.windows.net/midocdev/"
        }]
      }else {
        return[
          ...prevFileNames, 
          {
            documentname: filename,
            documenturl: filename,
            imagepath: "https://midocfileserver.blob.core.windows.net/midocdev/"
       
          }
        ]
      }
    })
    showSuccessToast("Document Added Successfully.");
    e.target.value = '';
    dismissLoader();

  };

  const handleDeleteDocInEdit = (index) => {
    const updatedUrls = [...documentURLForEdit];
    updatedUrls.splice(index, 1);
   // setUploadedDocuments(updatedUrls);
    
    setDocumentURLForEdit(updatedUrls);

    if (updatedUrls != null) {

      setFilenameforUpload(updatedUrls?.map(doc => doc.documentname));
    }
  };

  const DeleteMedicineModel = (title, id) => {
    setID(id);
    setTitle(title);
    dynamicModal.current.openModal();
  };

  const deletemedicine = async () => {
    const result = await DeleteMedicine({
      // loginid,
      // id: id,
    });
    if (result) {
      showSuccessToast("Medicine deleted successfully");
      fetchData(medicinetypeFilter,activeTab === 1? "0": "1");
      dynamicModal.current.closeModal();
      detailsmodel.current.closeRight();
    }
  };

  const fetchData = async (type, tab) => {
    showLoader();
    setData("");
    try {
      const result = await FetchMedicationsData({
        // patientid,
        doctorid: getLoginID(),
        medicinetype: type,
        // iscurrent: tab,
      });
      //console.log(result, "modified medications res");
      if (result != null) {
        setData(result);
      } else {
        //alert("No data");
      }
      dismissLoader();
    } catch (error) {
      console.error("Error in DashboardIndex:", error);
    }
  };

  useEffect(()=>{
    if(!medicinename){
      setComposition("")
    }
  },[medicinename])

  return (
    <>
    {screenType == 'web' ? (
      <Box w="100%">
      <Flex>
        <Box w="6rem">
          <MiniSideBar />
        </Box>
        {isLoading && <Loader />}
        <Box w="100%">
          <SecondaryNav customHeader="Medicine View" />
          <Box gap='10px' mt='1rem' pl='2rem' w='90vw' display='flex' flexDirection='row' justifyContent='center' alignItems='center'>
              <PrimaryInput
                variant="fullRound"
                title="Search by name"
                inputPlace="Search"
                id="yourName"
                onKeyDown={handleKeyDown}
                onChange={handleChangeName}
                inputValue={name}
              />
              {/* <Box w='800px'>
              <DatePicker
                  disableFutureDates={true}
                  // defaultDate={procedureDate}
                  onDateChange={(selectedDateValue) =>
                    handleLastUsedDate(selectedDateValue)
                  }
                  placeholderProp="Last Used Date"
                  datePickerInputStyle={{ borderRadius: "10px 10px 10px 10px" }}
                  value={lastUsedDate} 
                  clearValue={true}
                />
              </Box> */}
              <Box w='800px'>
              <DatePicker
                  // defaultDate={procedureDate}
                  onDateChange={(selectedDateValue) =>
                    handleFromDate(selectedDateValue)
                  }
                  placeholderProp="From Date"
                  datePickerInputStyle={{ borderRadius: "10px 10px 10px 10px" }}
                  value={fromDate} 
                  clearValue={true}

                  CUSTOM_DISABLE={true}
                  disableFutureDates={true}
                  maxDate={toDateMinMax}
                />
              </Box>
                <Box w='800px'>
              <DatePicker
                  onDateChange={(selectedDateValue) =>
                    handleToDate(selectedDateValue)
                  }
                  placeholderProp="To Date"
                  datePickerInputStyle={{ borderRadius: "10px 10px 10px 10px " }}
                  value={toDate} 
                  clearValue={true}
                  
                CUSTOM_DISABLE={true}
                maxDate={new Date().toISOString()} 
                minDate={fromDateMinMax}
                />
                </Box>

              <Box gap='10px' pl='1rem' w='70vw' display='flex' flexDirection='row' justifyContent='center' alignItems='center'>
                <PrimaryButton buttonText="Filter" id="filterButton" onClick={handleApplyFilters}  btnStyle={{m:'0' }} />
                <PrimaryButton buttonText="Reset" id="resetButton" variant="grayBtnFull" btnStyle={{ color: "#AA2F27", m:'0' }} onClick={resetFields} />
              </Box>
              {noData && <p>No data available</p>}
              </Box>
              <Flex justifyContent='end' w='100%' pr='3rem'>
                <PrimaryButton
                  buttonText="Add"
                  onClick={addData}
                  variant="mdBtn"
                  btnStyle={{ w: "8%", my: "1rem" }}
                  w='100%'
                  isDisabled={ actionAccess && (!actionAccess.create) ? true : false} 
                />
              </Flex>
          {medicineViewData && medicineViewData.length > 0 ? (
                      <Box width='100%'>
                        <Box
                        h="calc(100vh - 90px)"
                        overflow='hidden auto'
                        border="1px solid #E6E7E9"
                        >
                          <SimpleTable
                            headers={TableHeader}
                            className='tableContainer'
                            isDataPresent={true}
                            currentPage={currentPage} onPageChange={handlePageChange}
                            tableHeader={{pos: "sticky", top: "0", bg: "white"}}
                            footerStyle={{pos: "fixed", bottom: "0",boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px", zIndex: "99",width: "100%",}}
                            tableStyle={{maxH:"calc(100vh - 280px)",overflowY: "auto", tableLayout: "fixed", width: "100%", h: "100%"}}
                            tableBody={
                              <>
                                {medicineViewData &&
                                  medicineViewData.map((medicine, index) => (
                                    <Tr w="100%" key={index}>
                                      <Td maxW="200px">
                                      <Box >
                                        <Box as="p" className="textOverflow" fontWeight={"semibold"} textAlign={"left"}>
                                          {medicine.medicinename} ({medicine.dosage}{" "}
                                          {medicine.dosageunit}){" "}
                                          <Box
                                            display="inline-block"
                                            verticalAlign="bottom"
                                            fontSize="10px"
                                            fontWeight="600"
                                            bg="#E6E7E966"
                                            border="1px solid #E6E7E9"
                                            p="5px 10px"
                                            borderRadius="full"
                                            w="fit-content"
                                            whiteSpace="nowrap"
                                            overflow="hidden"
                                            textOverflow="ellipsis"
                                            as="span"
                                          >
                                            {medicine.medicinetype
                                              ? medicine.medicinetype
                                              : "-"}
                                          </Box>
                                        </Box>
                                      </Box>
                                      </Td>

                                      <Td maxW="200px">
                                      <Box title={medicine.chemicalcomposition} whiteSpace="pre-line" overflow="hidden" fontSize={"s"} textAlign={"left"}>
                              {medicine.chemicalcomposition ? medicine.chemicalcomposition : "-"}
                            </Box>
                                        
                                      </Td>

                                      <Td maxW="150px">
                                      <Box width="15%" textAlign={"left"}>
                            {medicine.morning}-{medicine.evening}-{medicine.night}
                          </Box>
                                      </Td>

                                      <Td maxW="200px">
                                      <Box width="15%" textAlign={"left"}>
                            {medicine.beforefood === 1 &&
                              medicine.afterfood === 1 && (
                                <>Before & After Food</>
                              )}
                            {medicine.beforefood === 1 &&
                              (medicine.afterfood === 0 ||
                                medicine.afterfood === null) && <>Before Food</>}
                            {(medicine.beforefood === 0 ||
                              medicine.beforefood === null) &&
                              medicine.afterfood === 1 && <>After Food</>}{" "}
                            {(medicine.beforefood === 1 ||
                              medicine.afterfood === 1) &&
                              medicine.frequency &&
                              "-"}
                            {medicine.frequency &&
                              `${medicine.frequency} x per day`}
                          </Box>
                                      </Td>

                                      <Td>
                                      <Box width="15%" textAlign={"left"}>
                                        {medicine.modifieddate? formatDate(medicine.modifieddate) : '-'}
                                      </Box>
                                      </Td>


                                      <Td maxW="130px" pos='relative'>
                            <Menu>
                              <MenuButton as='button' zIndex={1}>
                                <Image w="1.5rem" src="/assets/svgs/more-options.svg" />
                              </MenuButton>
                              <MenuList zIndex={9} pos="relative" right="-33px" minW="125px">
                                <MenuItem onClick={() => handleeditmedication(medicine)} 
                                
                 isDisabled={ actionAccess && (!actionAccess.update) ? true : false} >
                                  Edit
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          </Td>
                                    </Tr>
                                  ))}
                              </>
                            }
                            footerSection={
                              <>
                                <Tr w='100%'>
                                  <Td colSpan={7} w="calc(100vw - 6.5rem)">
                                    <Box
                                      w="100%"
                                      display="flex"
                                      justifyContent="space-between"
                                      alignItems="center"
                                    >
                                       <Box 
                                          visibility={(totalItemCount < countTo && !totalExceedsPageCount)  ? "hidden" : "visible"}
                                          >
                                             <Text>Showing  <b>{countFrom}</b> to <b>{countTo}</b>  of {totalItemCount} results</Text>
                                             </Box>
                 
                 
                                           <Box  
                                           gap="2.5rem"
                                            display="flex"
                                           justifyContent="space-between"
                                           alignItems="center" >
                                      <Box as="button" onClick={handlePrevClick} disabled={currentPage === 1} visibility={currentPage === 1 ? 'hidden' : 'visible'} p="0.5rem 0.75rem" border="0.0625rem solid #E6E7E9" borderRadius="0.75rem" fontSize="1rem" fontWeight="500">
                                          Previous
                                        </Box>
                                      <Box>
                                        <Text textAlign="center" fontSize=".875rem" fontWeight="600">
                                        Page {currentPage} of {totalPages}
                                        </Text>
                                      </Box>
                                      {(totalItemCount >= countTo && !totalExceedsPageCount)  && 
                                        <Box as="button" p="0.5rem 0.75rem" mr='2.5rem' border="0.0625rem solid #E6E7E9" borderRadius="0.75rem" onClick={handleNextClick} disabled={currentPage === totalPages} visibility={currentPage === totalPages ? 'hidden' : 'visible'} fontSize="1rem" fontWeight="500">
                                          Next
                                        </Box>
                                        }   
                                        </Box>
                                    </Box>
                                  </Td>
                                </Tr>
                              </>
                            }
                          />
                        </Box>
                      </Box>
                    ) : (
                      <Box width='100%'>
                        <PrimaryButton
                buttonText="Add"
                onClick={addData}
                variant="mdBtn"
                btnStyle={{ w: "8%", my: "1rem" }}
                w='100%'
                ml='87%'
                 isDisabled={ actionAccess && (!actionAccess.create) ? true : false} 
              />
                      <SimpleTable
                        headers={TableHeader}
                        // isDataPresent={false}
                      />
                      </Box>
                    )}
                        </Box>
      </Flex>
      </Box>
    ) : (
      <>
          <MobileNavbar />
          <MobileSecondaryNav
            header="Medicine View"
            handlebackarrow={false}
          />

          <Box
            w="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p="1rem"
            gap='0.75rem'
          >
            <PrimaryInput
              variant="fullRound"
              title="Search name, email"
              inputPlace="Search name, email"
              id="yourName"
              onKeyDown={handleKeyDown}
              onChange={handleChangeName}
              inputValue={name}
            />
            <Box onClick={onToggle}>
              <Image
                w='50px'
                cursor='pointer'
                src={isOpen ? "assets/svgs/outline-f.svg" : "assets/svgs/filled-f.svg" }
              />
            </Box>
            <PrimaryButton
              buttonText="Add"
              onClick={addData}
              variant="mdBtn"
              isDisabled={actionAccess && !actionAccess.create ? true : false}
            />
          </Box>

          <Collapse in={isOpen} animateOpacity zIndex='9999' pos='relative' style={{overflow:'visible'}}>
            <Box w="100%" display="flex" p='1rem' pt='0' flexDir='column' zIndex='9999' pos='relative' gap="10px">
              <DatePicker
                // defaultDate={procedureDate}
                onDateChange={(selectedDateValue) =>
                  handleFromDate(selectedDateValue)
                }
                placeholderProp="From Date"
                datePickerInputStyle={{ borderRadius: "10px 10px 10px 10px" }}
                value={fromDate} 
                clearValue={true}

                CUSTOM_DISABLE={true}
                disableFutureDates={true}
                maxDate={toDateMinMax}
              />
              <DatePicker
                onDateChange={(selectedDateValue) =>
                  handleToDate(selectedDateValue)
                }
                placeholderProp="To Date"
                datePickerInputStyle={{ borderRadius: "10px 10px 10px 10px " }}
                value={toDate} 
                clearValue={true}
                
                
                CUSTOM_DISABLE={true}
                maxDate={new Date().toISOString()} 
                minDate={fromDateMinMax}
              />
            </Box>

            <Box w="100%" p='1rem' pt='0' display="flex" gap="10px">
              <PrimaryButton
                buttonText="Filter"
                btnStyle={{w:"100%"}}
                id="filterButton"
                variant="mdBtn"
                onClick={handleApplyFilters}
              />
              <PrimaryButton
                buttonText="Reset"
                id="resetButton"
                variant="grayBtn"
                btnStyle={{ color: "#AA2F27", w:"100%" }}
                onClick={resetFields}
              />
            </Box>
          </Collapse>


          <Box
          h="calc(100vh - 200px)"
          overflow='hidden auto'
          border="1px solid #E6E7E9"
          p='1rem'
          display='flex'
          gap='0.75rem'
          flexDir='column'
          >
          {medicineViewData &&
              medicineViewData.map((medicine, index) => (
          <Card
            borderRadius="12px"
            key={index}
            w="100%"
            border="1px solid #E6E7E9"
            boxShadow="0px 8px 20px 0px #E6E7E966"
          >
            <CardBody p={{base: "0.875rem",lg: "0.875rem", xl: "1.25rem"}}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb="0.75rem"
              >                  
                <Box cursor="pointer" display="flex" alignItems="center">
                  <Box display="flex" flexDirection="column" justifyContent="center">
                    <Text fontSize="15px" fontWeight="600" className="textOverflow" title={`${medicine.medicinename} (${medicine.dosage}${" "} ${" "}`} maxW={{base: "180px", xl:"110px"}}>
                    {medicine.medicinename} ({medicine.dosage}{" "}
                      {medicine.dosageunit}){" "}
                    </Text>
                  </Box>
                </Box>

                  <Box display="flex" gap="10px">
                    <Menu isLazy>
                      <MenuButton as='button' zIndex={1}>
                        <Image w="1rem" src="/assets/svgs/more-options.svg" transform='rotate(90deg)' />
                      </MenuButton>
                      <MenuList zIndex={9} pos="relative" right="-33px" minW="125px">
                        <MenuItem onClick={() => handleeditmedication(medicine)} 
                        
                        isDisabled={ actionAccess && (!actionAccess.update) ? true : false} >
                          Edit
                        </MenuItem>
                      </MenuList>
                    </Menu>  
                  </Box>
                  
              </Box>
              <Flex w='100%' mb="0.75rem" justifyContent='space-between'>
                <Box display='flex' flexDir='column' w={'40%'}>
                
                    <Text fontSize="10px" color="gray.500">
                       Medicine type
                    </Text>
                    <Text fontSize="12px" fontWeight="600">
                    {medicine.medicinetype
                      ? medicine.medicinetype
                      : "-"}
                    </Text>
                    
                  </Box>
                  <Box display='flex' flexDir='column' alignItems="end" w='60%'>
                
                <Text fontSize="10px" color="gray.500" >
                Composition
                </Text>
                <Box
                    as="button"
                    w='100%'
                  >
                    <Text fontSize="12px" fontWeight="600" isTruncated textAlign='right'>
                    {medicine.chemicalcomposition ? medicine.chemicalcomposition : "-"}
                </Text>
                  </Box>
                
              
              </Box>
              </Flex>
              
              <Flex w='100%'>
                <Box w='40%' display='flex' flexDir='column'>
                  <Text fontSize="10px" color="gray.500">
                  Dosage	
                  </Text>
                  <Text fontSize="12px" fontWeight="600">
                  {medicine.morning}-{medicine.evening}-{medicine.night}
                  </Text>
                  
                </Box>
                
                <Box w='60%' display='flex' flexDir='column'>
                  <Text fontSize="10px" color="gray.500">
                  Last Used
                  </Text>
                  <Text fontSize="12px" fontWeight="600">
                  {medicine.modifieddate? formatDate(medicine.modifieddate) : '-'}
                  </Text>
                  
                </Box>
                  
                  

                <Box w='100%' display='flex' flexDir='column' alignItems='flex-end'>
                    <Text fontSize="10px" color="gray.500">
                    Consumption
                    </Text>
                    <Box w='fit-content' fontSize="12px" fontWeight="600">
                    {medicine.beforefood === 1 &&
                      medicine.afterfood === 1 && (
                        <>Before & After Food</>
                      )}
                    {medicine.beforefood === 1 &&
                      (medicine.afterfood === 0 ||
                        medicine.afterfood === null) && <>Before Food</>}
                    {(medicine.beforefood === 0 ||
                      medicine.beforefood === null) &&
                      medicine.afterfood === 1 && <>After Food</>}{" "}
                    {(medicine.beforefood === 1 ||
                      medicine.afterfood === 1) &&
                      medicine.frequency &&
                      "-"}
                    {medicine.frequency &&
                      `${medicine.frequency} x per day`}
                      </Box>
                  </Box>
              </Flex>
            
            </CardBody>

          </Card>
          ))}
          </Box>
        </>
    )}

<ModalRight
        ref={addmedications}
        header="Add Medication"
        bodyStyle={{ p: "24px 32px" }}
        modalWidth="460px"
        body={
          <>
            <Box display="flex" flexDir="column" gap="20px">
              <Stack>
                <InputGroup>
                  <Input
                    pr="4.5rem" // Padding right to make room for the icon
                    type="text"
                    placeholder="Medicine name*"
                    value={medicinename}
                    sx={{
                      height: "48px",
                      borderRadius: "10px",
                      _hover: {
                        border: "2px solid",
                        borderColor: COLORS.INPUT_FOCUS_DARK,
                        outline: "none",
                        bg: "none",
                        _focus: {
                          borderRadius: "10px",
                          borderColor: COLORS.INPUT_FOCUS_DARK,
                          outline: "none",
                        },
                        _focusVisible: { borderColor: "none" },
                      },
                    }}
                    onChange={handlemedicinename}
                  />
                  <InputRightElement width="4.5rem">
                    <Box
                      as="button"
                      h="1.75rem"
                      bg="none"
                      pt="8px"
                      onClick={() =>
                        document.getElementById("file-upload").click()
                      }
                      sx={{ _hover: { bg: "none" } }}
                    >
                      <Image src="/assets/svgs/image-search-blue.svg" />
                    </Box>
                    <input
                      type="file"
                      id="file-upload"
                      style={{ display: "none" }}
                      //onChange={(e) => setFile(e.target.files[0])}
                      onChange={handleimageselection}
                    />
                  </InputRightElement>
                </InputGroup>
                <InputGroup>
                <PrimaryInput
                variant="fullLeft"
                  inputValue={Composition}
                  onChange={(event)=> setComposition(event.target.value)}
                  inputPlace="Medicine Composition"
                />
                  <PrimaryButton
                          buttonText="Fetch"
                          verticalAlign="bottom"
                          variant="grayBtn"
                          btnStyle={{
                            w: "15%",
                            color: COLORS.PRIMARY_COLOR,
                            verticalAlign: "bottom",
                            ml: "4px",
                            mb: "0px",
                            h: "48px",
                            m: "0",
                            borderRadius: "0 10px 10px 0"
                          }}
                          onClick={() =>
                            fetchComposition(medicinename)
                          }
                          isDisabled={false}
                        />
                        
                        </InputGroup>
                        {/* {medicinename != '' &&
                        <Box as="button" onClick={makeAicall} textAlign="right" fontSize="12px" textDecoration="underline">Learn more</Box>
                        } */}

                        <RadioGroup
                          mt='1rem'
                          defaultValue={medicineTypeRadio}
                          onChange={handlemedicinetypechange}
                        >
                <Divider />
                          <Stack direction="row" gap="20px"  wrap="wrap">
                            <Radio value="Tablet">Tablet</Radio>
                            <Radio value="Capsule">Capsule</Radio>
                            <Radio value="Syrup">Syrup</Radio>
                            <Radio value="Injection">Injection</Radio>
                            <Radio value="Ointment">Ointment</Radio>
                          </Stack>
                <Divider />
                        </RadioGroup>
                <Box>
                  {(medicinetype == "Tablet" || medicinetype == "Capsule")  &&
                    <MiniDropdownInput
                    variant="bothside"
                    options={ [
                    { label: "mg", value: "151" },
                    { label: "ml", value: "152" },
                    { label: "grms", value: "152" },
                      ] }
                    placeholderProp="Dosage"
                    onSelect={handleChangeDosageUnit}
                    handleInputChange={(e) =>
                      setMedicineDosage(validationOfNumberWithOnly1Decimal(e.target.value))
                    }
                    inputVal={dosage}
                    
                    maxLength="6"
                  />
                    }
                      {(medicinetype == "Syrup" || medicinetype == "Injection")  &&
                    <MiniDropdownInput
                    variant="bothside"
                    options={ [
                    { label: "ml", value: "152" },
                        { label: "mg", value: "151" },
                        { label: "grms", value: "151" },
                      ] }
                    placeholderProp="Dosage"
                    onSelect={handleChangeDosageUnit}
                    handleInputChange={(e) =>
                      setMedicineDosage(validationOfNumberWithOnly1Decimal(e.target.value))
                    }
                    
                    inputVal={dosage}
                    maxLength="6"
                  />
                    }
                    {(medicinetype == "Ointment")  &&
                    <MiniDropdownInput
                    variant="bothside"
                    options={ [
                      { label: "grms", value: "151" },
                    { label: "ml", value: "152" },
                        { label: "mg", value: "151" },
                      ] }
                    placeholderProp="Dosage"
                    onSelect={handleChangeDosageUnit}
                    handleInputChange={(e) =>
                      setMedicineDosage(validationOfNumberWithOnly1Decimal(e.target.value))
                    }
                    
                    inputVal={dosage}
                    maxLength="6"
                  />
                    }
                  

                  {/* <Box display="flex" flexDir="row">
                  
                      <PrimaryInput

                      variant="bothSide"
                      id="vitaldate"
                      inputType="date"
                      inputPlace="From"
                      inputStyleCss={{ w: "50%" }}
                      defaultValue={fromdate}
                      onChange={(e) => setFromDate(e.target.value)}
                    />
                    <PrimaryInput
                      variant="bothSide"
                      id="vitaldate"
                      inputType="date"
                      inputPlace="To"
                      inputStyleCss={{ w: "50%" }}
                      defaultValue={todate}
                      onChange={(e) => setToDate(e.target.value)}
                    />
                      <DatePicker 
                      disableFutureDates={true}
                      maxDate={maxDateState}
                      defaultDate={fromdate} 
                      onDateChange={(selectedDateValue) =>
                        handleFromDateChange(selectedDateValue)
                      }
                      placeholderProp={"Start Date"} 
                      //calendarStlye={{transform: "scale(0.9)", left:"-15px", top: "30px"}}
                      calendarStlye={{left: "-18px",  right: "unset"}}
                    />
                    <DatePicker 
                      disablePastDates={true}
                      minDate={minDateState}
                      defaultDate={todate} 
                      onDateChange={(selectedDateValue) =>
                        handleToDateChange(selectedDateValue)
                      }
                      placeholderProp={"End Date"} 
                      calendarStlye={{transform: "scale(0.9)", right: "-22px", top: "30px"}}
                    />
                  </Box> */}
                  <DropdownInput
                    variant="downSide"
                    dropDownPlace="Consumption frequency"
                    // options={["1x per day", "2x per day", "3x per day", "4x per day"]}
                    options={["1", "2", "3", "4"]}
                    onClick={handleconsumptionfrequencychange}
                  />
                    {/* <PrimaryInput
                    inputPlace="Medicine Expiry"
                    variant="defaultSide"
                    inputValue={medicineexpirydate}
                    onChange={(event) => handleExpiryDateChange(event.target.value)}
                  /> */}
                  <CheckboxGroup
                    colorScheme="green"
                    onChange={handlemedicinetime}
                  >
                    <Stack spacing={2} pt="20px" direction="row">
                      <Checkbox w='33.33%' value="morning">Morning</Checkbox>
                      <Checkbox w='33.33%' ml='6px' value="evening">Evening</Checkbox>
                      <Checkbox w='33.33%' value="night">Night</Checkbox>
                    </Stack>
                  </CheckboxGroup>
                  
                  <CheckboxGroup
                    colorScheme="green"
                    onChange={handlemedicinetimebeforeafter}
                  >
                    <Stack spacing={2} pt="20px" direction="row">
                      <Checkbox w='33.33%' value="beforefood">Before Food</Checkbox>
                      <Checkbox w='33.33%' value="afterfood">After Food</Checkbox>
                    </Stack>
                  </CheckboxGroup>
                  
                  
                </Box>
                <Divider mt='0.5rem' />
                {/* <Box>
                <Box w="100%" pos='relative' cursor="pointer">
                  <PrimaryInput
                    inputPlace="Prescribed by"
                    variant="defaultSide"
                    inputValue={prescribedby}
                    onChange={(e) =>
                      handleSurgeonNameChange(e.target.value)
                    }
                    onClick={() =>
                      listOfSurgeonNames(false)
                    }
                inputIdProp={"DoctorNameInAdd"}
                boolProp={boolValForSuggestions}
                  />
                    <Image top="33%" right="6.5%" w="15px" 
                          onClick={() =>
                            listOfSurgeonNames( true)
                          }
                            pos='absolute' src="/assets/imgs/double-arrow.png" />
                          
                  </Box>
                    {showDocNamesDropdown &&  suggestions.length > 0 && (
                <Menu isOpen={true}>
                <MenuButton as={Box} w="100%" display="block" pos="relative" zIndex="999">
                  <Box pos="absolute" top="100%" left="0" w="100%" bg="white" borderTop="none" border="1px solid #E6E7E9" borderRadius="0 0 12px 12px" />
                </MenuButton>
                <MenuList w="100%" borderRadius="0 0 12px 12px" zIndex="999" bg="white" maxH="150px" overflow="hidden auto">
                  {suggestions.map((suggestion, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => handleSelect(suggestion)}
                      cursor="pointer"
                      p="1rem"
                      py="0.5rem"
                      borderBottom="1px solid #E6E7E9"
                    >
                      <Text fontSize="0.875rem" fontWeight="500">{suggestion.doctorname}</Text>
                    </MenuItem>
                  ))}
                  </MenuList>
                </Menu>
              )}


                  <PrimaryInput
                    inputPlace="Hospital name"
                    variant="downSide"
                    onChange={(e) => setHospitalname(handleAlphaNumericDotAndSpace(e.target.value))}
                    inputValue={hospitalname}
                  />
                  <Box mt="15px">
                  <Checkbox
                colorScheme="green"
                sx={{
                  ".chakra-checkbox__control": {
                    width: "20px",
                    height: "20px",
                    borderRadius: "5px",
                  },
                }}
                onChange={handleIsCurrent}
              >
                  Current
              </Checkbox>
              </Box>
                </Box> */}
                {/* {filenameArray.map((filename, indexToDelete) => (
                  <Button fontSize="14px" fontWeight="500" w="100%" h="48px" borderRadius="10px" border="1px solid #E6E7E9" display="flex" justifyContent="space-between" bg="white" color="#1C4EC5" sx={{ _hover: { bg: "none" } }}>
                    Document {indexToDelete + 1}
                    <Box as="span" onClick={() => handleDelete(indexToDelete)}>
                      <Image src="/assets/imgs/delete.png" />
                    </Box>
                  </Button>
                ))}
                <Box onClick={addMedicationUploadDocument} as="button" display="flex" alignItems="center" gap="10px" fontSize="14px" fontWeight="600" color="#1C4EC5" textAlign="left">
                  <Image w="11px" src="/assets/imgs/add-blue.png" />
                  Add Document
                </Box>
                <input
                  type='file'
                  id='file-upload'
                  onChange={(e) => handleMedicationUpload(e)}
                  ref={fileInputRef} // Set the ref for the file input
                  style={{ display: 'none' }} // Hide the file input
                /> */}
              </Stack>
            </Box>
          </>
        }
        footerActions={
          <>
            <Box w="100%" display="flex" flexDirection="row" gap="15px">
              <PrimaryButton
                buttonText="Add Medication"
                onClick={addmedicine}
                isDisabled={false}
              />
            </Box>
          </>
        }
      />

<ModalRight
        ref={editmedications}
        header="Update Medication"
        bodyStyle={{ p: "24px 32px" }}
        modalWidth="460px"
        body={
          <>
            <Box display="flex" flexDir="column" gap="20px">
              <Stack spacing={4}>
                <InputGroup>
                  <Input
                    pr="4.5rem" // Padding right to make room for the icon
                    type="text"
                    placeholder="Medicine name*"
                    value={medicinename}
                    sx={{
                      height: "48px",
                      borderRadius: "10px",
                      _hover: {
                        border: "2px solid",
                        borderColor: COLORS.INPUT_FOCUS_DARK,
                        outline: "none",
                        bg: "none",
                        _focus: {
                          borderRadius: "10px",
                          borderColor: COLORS.INPUT_FOCUS_DARK,
                          outline: "none",
                        },
                        _focusVisible: { borderColor: "none" },
                      },
                    }}
                    onChange={handlemedicinename}
                  />
                  <InputRightElement width="4.5rem">
                    <Box
                      as="button"
                      h="1.75rem"
                      bg="none"
                      pt="8px"
                      onClick={() =>
                        document.getElementById("file-upload").click()
                      }
                      sx={{ _hover: { bg: "none" } }}
                    >
                      <Image src="/assets/svgs/image-search-blue.svg" />
                    </Box>
                    <input
                      type="file"
                      id="file-upload"
                      style={{ display: "none" }}
                      //onChange={(e) => setFile(e.target.files[0])}
                      onChange={handleimageselection}
                    />
                  </InputRightElement>
                </InputGroup>
                <InputGroup>
                <PrimaryInput
                variant="fullLeft"
                  inputValue={Composition}
                  onChange={(event)=> setComposition(event.target.value)}
                  inputPlace="Chemical Composition"
                />
                  <PrimaryButton
                          buttonText="Fetch"
                          verticalAlign="bottom"
                          variant="grayBtn"
                          btnStyle={{
                            w: "15%",
                            color: COLORS.PRIMARY_COLOR,
                            verticalAlign: "bottom",
                            ml: "4px",
                            mb: "0px",
                            h: "48px",
                            m: "0",
                            borderRadius: "0 10px 10px 0"
                          }}
                          onClick={() =>
                            fetchComposition(medicinename)
                          }
                          isDisabled={false}
                        />
                </InputGroup>
                {/* {medicinename != ''  &&
                        <Box as="button" onClick={makeAicall} textAlign="right" fontSize="12px" textDecoration="underline">Learn more</Box>
                        } */}
                <RadioGroup
                  mt='1rem'
                  defaultValue={medicinetype}
                  onChange={handlemedicinetypechange}
                >
                  <Stack direction="row" gap="20px" wrap="wrap">
                    
                  <Radio value="Tablet">Tablet</Radio>
                            <Radio value="Capsule">Capsule</Radio>
                            <Radio value="Syrup">Syrup</Radio>
                            <Radio value="Injection">Injection</Radio>
                            <Radio value="Ointment">Ointment</Radio>
                  </Stack>
                </RadioGroup>
                {/* <Divider /> */}
                <Box>
                  <MiniDropdownInput
                    variant="bothside"
                    options={[
                      { label: "mg", value: "mg" },
                      { label: "ml", value: "ml" },
                      { label: "grms", value: "grms" },
                    ]}
                    placeholderProp="Dosage"
                    defaultValue={dosage}
                    inputVal={dosage}
                    defaultOptionProp={dosageunit}
                    onSelect={handleChangeDosageUnit}
                    handleInputChange={(e) =>
                      setMedicineDosage(validationOfNumberWithOnly1Decimal(e.target.value))
                    }
                    maxLength="6"
                  />

                  <Box display="flex" flexDir="row">
                  {/* <PrimaryInput

                      variant="bothSide"
                      id="vitaldate"
                      inputType="date"
                      inputPlace="From"
                      inputStyleCss={{ w: "50%" }}
                      defaultValue={fromdate}
                      onChange={(e) => setFromDate(e.target.value)}
                    />
                    <PrimaryInput
                      variant="bothSide"
                      id="vitaldate"
                      inputType="date"
                      inputPlace="To"
                      inputStyleCss={{ w: "50%" }}
                      defaultValue={todate}
                      onChange={(e) => setToDate(e.target.value)}
                    /> */}
                      {/* <DatePicker 
                      disableFutureDates={true}
                      defaultDate={fromdate} 
                      onDateChange={(selectedDateValue) =>
                        handleFromDateChange(selectedDateValue)
                      }
                      placeholderProp={"Start Date"} 
                      calendarStlye={{left: "-18px",  right: "unset"}}
                      maxDate={maxDateStateEdit}
                    />
                    <DatePicker 
                      defaultDate={todate} 
                      onDateChange={(selectedDateValue) =>
                        handleToDateChange(selectedDateValue)
                      }
                      placeholderProp={"End Date"} 
                      calendarStlye={{transform: "scale(0.9)", right: "-22px", top: "30px"}}
                      
                      disablePastDates={true}
                      
                      minDate={minDateStateEdit}
                      
                    /> */}


                  </Box>
                  <DropdownInput
                    variant="downSide"
                    dropDownPlace="Consumption frequency"
                    options={["1", "2", "3", "4"]}
                    onClick={handleconsumptionfrequencychange}
                    defaultOption={frequency ? frequency : ""}
                  />
                  {/* <PrimaryInput
                    inputPlace="Medicine Expiry"
                    variant="defaultSide"
                    defaultValue={medicineexpirydate}
                    inputValue={medicineexpirydate}
                    onChange={(event) => handleExpiryDateChange(event.target.value)}
                  /> */}
                  <CheckboxGroup
                    colorScheme="green"
                  //onChange={handlemedicinetime}               
                  >
                    <Stack spacing={2} pt="20px" direction="row">
                      <Checkbox w='33.33%' isChecked={morning === 1} onChange={() => setMorning(morning === 1 ? 0 : 1)}>Morning</Checkbox>
                      <Checkbox w='33.33%' ml='6px' isChecked={setevening === 1} onChange={() => setEvening(setevening === 1 ? 0 : 1)}>Evening</Checkbox>
                      <Checkbox w='33.33%' isChecked={setnight === 1} onChange={() => setNight(setnight === 1 ? 0 : 1)}>Night</Checkbox>
                    </Stack>
                  </CheckboxGroup>
                  {/* <Divider /> */}
                  <CheckboxGroup
                    colorScheme="green"
                  >
                    <Stack spacing={2} pt="20px" direction="row">
                      <Checkbox w='33.33%' isChecked={beforefood === 1} onChange={() => setBeforefood(beforefood === 1 ? 0 : 1)}>Before Food</Checkbox>
                      <Checkbox w='33.33%' isChecked={afterfood === 1} onChange={() => setAfterfood(afterfood === 1 ? 0 : 1)}>After Food</Checkbox>
                    </Stack>
                  </CheckboxGroup>
                  
                </Box>
                <Divider mt='0.5rem' />
                {/* <Box>
                  
                <Box w="100%" pos='relative' cursor="pointer">
                  <PrimaryInput
                    inputPlace="Prescribed by"
                    variant="defaultSide"
                    onChange={(e) =>
                      handleSurgeonNameChange(e.target.value)
                    }
                    onClick={() =>
                      listOfSurgeonNames(false)
                    }
                    defaultValue={prescribedby}
                    inputValue={prescribedby}

                    
                inputIdProp={"DoctorNameInEdit"}
                boolProp={boolValForSuggestions}
                  />
                    <Image top="33%" right="6.5%" w="15px" 
                          onClick={() =>
                            listOfSurgeonNames( true)
                          }
                            pos='absolute' src="/assets/imgs/double-arrow.png" />

                  {showDocNamesDropdown &&  suggestions.length > 0 && (
                <Menu isOpen={true}>
                <MenuButton as={Box} w="100%" display="block" pos="relative" zIndex="999">
                  <Box pos="absolute" top="100%" left="0" w="100%" bg="white" borderTop="none" border="1px solid #E6E7E9" borderRadius="0 0 12px 12px" />
                </MenuButton>
                <MenuList w="100%" borderRadius="0 0 12px 12px" zIndex="999" bg="white" maxH="150px" overflow="hidden auto">
                  {suggestions.map((suggestion, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => handleSelect(suggestion)}
                      cursor="pointer"
                      p="1rem"
                      py="0.5rem"
                      borderBottom="1px solid #E6E7E9"
                    >
                      <Text fontSize="0.875rem" fontWeight="500">{suggestion.doctorname}</Text>
                    </MenuItem>
                  ))}
                  </MenuList>
                </Menu>
              )}
              </Box>
                  <PrimaryInput
                    inputPlace="Hospital name"
                    variant="downSide"
                    onChange={(e) => setHospitalname(handleAlphaNumericDotAndSpace(e.target.value))}
                    defaultValue={hospitalname}
                  />
                </Box> */}
                {/* <Box mt="15px">
                  <Checkbox
                colorScheme="green"
                sx={{
                  ".chakra-checkbox__control": {
                    width: "20px",
                    height: "20px",
                    borderRadius: "5px",
                  },
                }}
                isChecked={isChecked == 1}
                onChange={handleIsCurrent}
              >
                  Current
              </Checkbox>
              </Box> */}

                {/* {filenameforUpload && filenameforUpload.map((filename, index) => ( */}
                {/* {documentURLForEdit?.map((document, index) => (
              
                  <Button
                    key={index}
                    w="100%"
                    h="48px"
                    my="15px"
                    borderRadius="10px"
                    border="1px solid #E6E7E9"
                    display="flex"
                    justifyContent="space-between"
                    bg="white"
                    color="#1C4EC5"
                    sx={{ _hover: { bg: "none" } }}
                    // onClick={()=>downloadDocument(`https://midocfileserver.blob.core.windows.net/midocdev/${filename}`)}
                    onClick={() => handleDeleteDocInEdit(index)}
                    cursor="pointer"
                  >
                    Document {index + 1}
                    <Box as="span">
                      <Image src="/assets/imgs/delete.png" />
                    </Box>
                  </Button>
                ))} */}
                {/* <Button fontSize="14px" fontWeight="500" w="100%" h="48px" borderRadius="10px" border="1px solid #E6E7E9" display="flex" justifyContent="space-between" bg="white" color="#1C4EC5" sx={{_hover: {bg:"none"}}}>
                  12/01/24_Report.pdf
                  <Box as="span">
                      <Image src="/assets/imgs/delete.png" />
                  </Box>
                </Button>*/}
                {/* <Box onClick={updateMedicationDocuments} as="button" display="flex" alignItems="center" gap="10px" fontSize="14px" fontWeight="600" color="#1C4EC5" textAlign="left">
                  <Image w="11px" src="/assets/imgs/add-blue.png" />
                  Add Document
                </Box>
                <input
                  type='file'
                  id='file-upload'
                  onChange={(e) => handleMedicationUploadUpdate(e)}
                  ref={fileInputRefUpdate} // Set the ref for the file input
                  style={{ display: 'none' }} // Hide the file input
                /> */}
              </Stack>
            </Box>
          </>
        }
        footerActions={
          <>
            <Box w="100%" display="flex" flexDirection="row" gap="15px">
              <PrimaryButton
                buttonText="Update Medication"
                onClick={editmedicine}
                isDisabled={false}
                btnStyle={{my: "0"}}
              />
            </Box>
          </>
        }
      />

<ModalRight
        ref={detailsmodel}
        //header="Medicine details"
        bodyStyle={{ p: "24px 32px" }}
        modalWidth="360px"
        body={
          <>
            <Flex flexDir="column">
              <Box w="100%" mb="15px">
                <Box
                  fontSize="10px"
                  fontWeight="600"
                  bg="#E6E7E966"
                  border="1px solid #E6E7E9"
                  p="5px 10px"
                  borderRadius="full"
                  w="fit-content"
                >
                  {medicinedata && medicinedata.medicinetype
                    ? medicinedata.medicinetype
                    : ""}
                </Box>
                <Text fontSize="15px" fontWeight="600">
                  {medicinedata && medicinedata.medicinename
                    ? medicinedata.medicinename
                    : "-"}
                </Text>
                {/* <Text fontSize="12px" fontWeight="500" color="#384052"> */}
                  {/* {medicinedata && medicinedata.medicationenddate
                    ? medicinedata.medicationenddate
                    : "-"} */}
                  {/* {formatDate(medicinedata && medicinedata.medicationstartdate && medicinedata.medicationstartdate)}
                </Text> */}
                  
                <Text fontWeight="bold" fontSize="12px">
                Composition :
                <span style={{fontWeight:"500"}}>
                  {medicinedata && medicinedata.chemicalcomposition
                    ? medicinedata.chemicalcomposition
                    : "-"}
                    </span>
                </Text>
                <Text fontWeight="bold" fontSize="12px">
                Expiry :
                <span style={{fontWeight:"500"}}>
                  {medicinedata && medicinedata.medicineexpirydate
                    ? medicinedata.medicineexpirydate
                    : "-"}
                    </span>
                </Text>
              </Box>
              <Divider />
              <Box
                display="flex"
                flexDir="column"
                mt="15px"
                gap="12px"
                border="1px solid #E6E7E9"
                borderRadius="10px"
                p="20px"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="start"
                  gap="10px"
                >
                  <Image src="/assets/svgs/pill-blue.svg" />
                  <Text fontSize="14px" fontWeight="500">
                    {medicinedata && medicinedata.frequency
                      ? medicinedata.frequency
                      : "-"}
                    x per day
                  </Text>
                  <Box display="flex" gap="5px">
                    <Box
                      as="span"
                      bg={
                        medicinedata && medicinedata.morning === 1
                          ? "#384052"
                          : "#E6E7E9"
                      }
                      borderRadius="full"
                      w="8px"
                      h="8px"
                      p="4px"
                    ></Box>
                    <Box
                      as="span"
                      bg={
                        medicinedata && medicinedata.evening === 1
                          ? "#384052"
                          : "#E6E7E9"
                      }
                      borderRadius="full"
                      w="8px"
                      h="8px"
                      p="4px"
                    ></Box>
                    <Box
                      as="span"
                      bg={
                        medicinedata && medicinedata.night === 1
                          ? "#384052"
                          : "#E6E7E9"
                      }
                      borderRadius="full"
                      w="8px"
                      h="8px"
                      p="4px"
                    ></Box>
                  </Box>
                </Box>
                {/* <Box display="flex" gap="10px" pr="4px">
                  <Image src="/assets/svgs/calendar_month.svg" />
                  <Text fontSize="14px" fontWeight="500">
                    {daysDiffrence} 
                  </Text>
                </Box> */}
                <Box display="flex" gap="10px" pr="4px">
                  <Image src="/assets/svgs/stethoscope.svg" />
                  <Text fontSize="14px" fontWeight="500" className="textOverflow">
                    {medicinedata && medicinedata.prescribedby
                      ? medicinedata.prescribedby
                      : "-"}
                  </Text>
                </Box>
                <Box display="flex" gap="10px" pr="4px">
                  <Image src="/assets/svgs/hospital.svg" />
                  <Text fontSize="14px" fontWeight="500" className="textOverflow">
                    {medicinedata && medicinedata.prescribedhospitalname
                      ? medicinedata.prescribedhospitalname
                      : "-"}
                  </Text>
                </Box>
              </Box>
              {/* <Button w="100%" h="48px" my="15px" borderRadius="10px" border="1px solid #E6E7E9" display="flex" justifyContent="space-between" bg="white" color="#1C4EC5" sx={{_hover: {bg:"none"}}}>
                    12/01/24_Report.pdf
                    <Box as="span">
                        <Image src="/assets/imgs/download.png" />
                    </Box>
                </Button>  */}
              {medicinedata && medicinedata.documents && medicinedata.documents.map((document, index) => (
                <Button
                  key={index}
                  w="100%"
                  h="48px"
                  my="15px"
                  borderRadius="10px"
                  border="1px solid #E6E7E9"
                  display="flex"
                  justifyContent="space-between"
                  bg="white"
                  color="#1C4EC5"
                  sx={{ _hover: { bg: "none" } }}
                  onClick={()=>downloadDocument(document.imagepath + document.documenturl)}
                  cursor="pointer"
                >
                  Document {index + 1}
                  {document.documenturl != null && (
                    <Box as="span">
                      <Image src="/assets/imgs/download.png" />
                    </Box>
                  )}
                </Button>
              ))}
            </Flex>
          </>
        }
        footerActions={
          <>
            <Box w="100%" display="flex" flexDirection="row" gap="15px">
              <PrimaryButton onClick={() => handleeditmedication(medicinedata)} btnStyle={{fontSize:"14px", my: "0"}} variant="grayBtnFull" buttonText="Update Medication" isDisabled={false} />
              <PrimaryButton
                variant="grayBtnFull"
                color="#AA2E26"
                btnStyle={{ color: "#AA2F27", fontSize:"14px", my: "0" }}
                buttonText="Delete Medication"
                isDisabled={false}
                onClick={() =>
                  DeleteMedicineModel(
                    medicinedata && medicinedata.medicinename,
                    medicinedata && medicinedata.id
                  )
                }
              />
            </Box>
          </>
        }
      />

<AiModal
        ref={AIChatModel}
      modalSize="420px"
      //icon="/assets/imgs/ai-doctor.png"
      header={<>
        <Box display="flex" flexDir='column'>
          <Text fontSize="1rem" fontWeight="700" backgroundImage="linear-gradient(to right top, #0d7e54, #007774, #006d94, #005ea7, #1146a0)" color="transparent" backgroundClip="text">Aira - AI</Text>
          <Text fontSize="0.75rem" fontWeight="400">Chat with your AI health guide</Text>
        </Box>
      </>}
      headerStyle={{ p: "24px 32px", lineHeight: "normal" }}
      closeBtnStyle={{ top: "18px" }}
      footerStyle={{ maxH: "35%", overflow: "hidden auto", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      withoutBg={true}
      backDropBlur="10px"
      body={
        <>
          <Flex flexDirection="column" height="100%">
            <Box
              p="24px 32px"
              ref={chatContainerRef}
              className="chat-container"
              style={{ overflowY: "auto", maxHeight: 'calc(100vh - 160px)' }}
            >
              {initialRender && remainingquestions.length > 0 && (
                <Box>
                  {remainingquestions.map((step, index) => (
                    <Box
                      key={index}
                      as="button"
                      w="100%"
                      p="16px"
                      mb="16px"
                      fontSize="14px"
                      fontWeight="600"
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      textAlign='left'
                      gap='10px'
                      justifyContent="space-between"
                      borderRadius="10px"
                      border="1px solid #7795DC"
                      bg="#EAEDF5"
                      color="#163E9E"
                      onClick={() => GetAIAnswer(step.value)}
                      className={`question-box ${animate ? 'slide-in' : ''}`}
                    >
                      {step.value ? step.value : "-"}
                      <Image src="/assets/imgs/right-icon.png" />
                    </Box>
                  ))}
                </Box>
              )}
              {aianswers.map((step, index) => (
                <React.Fragment key={index}>
                  <Box
                    as="div"
                    bg="#061027"
                    p="12px 16px"
                    w="fit-content"
                    float="right"
                    borderRadius="10px 10px 1px 10px"
                    className={`answer-box ${animate ? 'slide-in' : ''}`}
                  >
                    <Text fontSize="14px" fontWeight="500" color="white">
                      {step.question ? step.question : "-"}
                    </Text>
                  </Box>

                  <Box
                    as="div"
                    my="20px"
                    w="fit-content"
                    display="flex"
                    alignItems="end"
                    float="left"
                    className={`answer-box ${animate ? 'slide-in' : ''}`}
                  >
                    <Image
                      bg="#061027"
                      p="4px"
                      mr="8px"
                      borderRadius="6px"
                      src="/assets/imgs/ai-chat-logo.png"
                    />
                    <Text
                      bg="#E6E7E980"
                      p="12px 16px"
                      fontSize="14px"
                      fontWeight="500"
                      color="#061027"
                      borderRadius="10px 10px 10px 1px"
                    >
                      {step.answer ? step.answer : <TypingLoader />}
                    </Text>
                  </Box>
                </React.Fragment>
              ))}
            </Box>
          </Flex>
        </>
      }
      footerActions={
        !initialRender && (
          <Box
            p="24px 32px"
            display="flex"
            w="100%"
            h="100%"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box w="100%">
              {remainingquestions.map((step, index) => (
                <Box
                  key={index}
                  as="button"
                  w="100%"
                  p="16px"
                  mb="16px"
                  fontSize="14px"
                  fontWeight="600"
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius="10px"
                  border="1px solid #7795DC"
                  bg="#EAEDF5"
                  color="#163E9E"
                  onClick={() => GetAIAnswer(step.value)}
                  className={`question-box ${animate ? 'slide-in' : ''}`}
                >
                  {step.value ? step.value : "-"}
                  <Image src="/assets/imgs/right-icon.png" />
                </Box>
              ))}
            </Box>
          </Box>
        )
      }
    />

<DynamicModal
        ref={dynamicModal}
        modalHeader="Delete Medicine?"
        modalBody={
          <>
            <Text fontSize="14px" fontWeight="600">
              Are you sure you want to delete <br />
              <strong>"{title ? title : "-"}"</strong>
            </Text>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="deleteBtn"
              buttonText="Delete"
              onClick={deletemedicine}
            />
          </>
        }
      />

    </>
  )
}

export default MedicineViewIndex;
