import axios from "axios";
import { getToken } from "../../../auth";
import { CONFIG } from "../../../appconfig/appconfig";
import FetchData from "../../client";
import { getDoctorId, getLoginID } from "../../../auth";
import formapimodalaidotor from "../../domains/aidoctor";

const FetchMyHealthAIData = async (
  inputData,
  patientid,
  patientname,
  patientgender,
  patientage
) => {
  try {
    inputData.patientid = patientid;
    inputData.activeuserid = null;
    const fullInput = { input: inputData };
    // console.log(fullInput.input);
    const result = await FetchData("midoc_getpatienthealth", fullInput.input);
    if (result.output.data) {
      // console.log("api response", result.output.data);
      var finalOutput = formapimodalaidotor(result.output.data);
      finalOutput.patient_info.name = patientname;
      finalOutput.patient_info.gender = patientgender;
      finalOutput.patient_info.age = patientage;
      // console.log(finalOutput);
      // console.log(patientname, patientgender, patientage);
    }
    return finalOutput;
  } catch (error) {
    console.error("Error fetching data in forms.js:", error);
    throw error;
  }
};

const FetchMedicineAIPrompts = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    inputData.type = "doctor";
    const token = getToken();
    const headers = {
      "Content-Type": "application/json",
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    const response = await axios.post(
      `${CONFIG.APIBaseUrl}midoc_ai_medicinegetprompts`,
      inputData,
      {
        headers: headers,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching data in forms.js:", error);
    throw error;
  }
};

const FetchMedicineAIAnswer = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    inputData.type = "doctor";
    const token = getToken();
    const headers = {
      "Content-Type": "application/json",
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    const response = await axios.post(
      `${CONFIG.APIBaseUrl}midoc_ai_medicinedescribeprompts`,
      inputData,
      {
        headers: headers,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching data in forms.js:", error);
    throw error;
  }
};

const FetchSurgeriesAIPrompts = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    inputData.type = "doctor";
    const token = getToken();
    const headers = {
      "Content-Type": "application/json",
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    const response = await axios.post(
      `${CONFIG.APIBaseUrl}midoc_ai_surgicalgetprompts`,
      inputData,
      {
        headers: headers,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching data in forms.js:", error);
    throw error;
  }
};
const FetchSurgeriesAIAnswer = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    inputData.type = "doctor";
    const token = getToken();
    const headers = {
      "Content-Type": "application/json",
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    const response = await axios.post(
      `${CONFIG.APIBaseUrl}midoc_ai_surgicaldescribeprompts`,
      inputData,
      {
        headers: headers,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching data in forms.js:", error);
    throw error;
  }
};

const FetchVaccinationAIPrompts = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    inputData.type = "doctor";
    const token = getToken();
    const headers = {
      "Content-Type": "application/json",
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    const response = await axios.post(
      `${CONFIG.APIBaseUrl}midoc_ai_vaccinationgetprompts`,
      inputData,
      {
        headers: headers,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching data in forms.js:", error);
    throw error;
  }
};
const FetchVaccinationAIAnswer = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    inputData.type = "doctor";
    const token = getToken();
    const headers = {
      "Content-Type": "application/json",
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    const response = await axios.post(
      `${CONFIG.APIBaseUrl}midoc_ai_vaccinationdescribeprompts`,
      inputData,
      {
        headers: headers,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching data in forms.js:", error);
    throw error;
  }
};

const FetchAllergiesAIPrompts = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    inputData.type = "doctor";
    const token = getToken();
    const headers = {
      "Content-Type": "application/json",
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    const response = await axios.post(
      `${CONFIG.APIBaseUrl}midoc_ai_allergiesgetprompts`,
      inputData,
      {
        headers: headers,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching data in forms.js:", error);
    throw error;
  }
};
const FetchAllergiesAIAnswer = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    inputData.type = "doctor";
    const token = getToken();
    const headers = {
      "Content-Type": "application/json",
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    const response = await axios.post(
      `${CONFIG.APIBaseUrl}midoc_ai_allergiesdescribeprompts`,
      inputData,
      {
        headers: headers,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching data in forms.js:", error);
    throw error;
  }
};

const FetchConditionAIPrompts = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    inputData.type = "doctor";
    // console.log(inputData);
    const token = getToken();
    const headers = {
      "Content-Type": "application/json",
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    const response = await axios.post(
      `${CONFIG.APIBaseUrl}midoc_ai_healthconditiongetprompts`,
      inputData,
      {
        headers: headers,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching data in forms.js:", error);
    throw error;
  }
};
const FetchConditionAIAnswer = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    inputData.type = "doctor";
    const token = getToken();
    const headers = {
      "Content-Type": "application/json",
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    const response = await axios.post(
      `${CONFIG.APIBaseUrl}midoc_ai_healthconditiondescribeprompts`,
      inputData,
      {
        headers: headers,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching data in forms.js:", error);
    throw error;
  }
};

const DetectDrugInteractions = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    inputData.type = "doctor";
    const token = getToken();
    const headers = {
      "Content-Type": "application/json",
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    const response = await axios.post(
      `${CONFIG.APIBaseUrl}midoc_ai_detectdruginteraction`,
      inputData,
      {
        headers: headers,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching data in forms.js:", error);
    throw error;
  }
};

const AiGenerateAssessmentPlan = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    // console.log(inputData)
    // inputData.type = "doctor";
    const token = getToken();
    const headers = {
      "Content-Type": "application/json",
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    const response = await axios.post(
      `${CONFIG.APIBaseUrl}midoc_ai_doctorassessmentplan`,
      inputData,
      {
        headers: headers,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching data in forms.js:", error);
    throw error;
  }
};

const AiRecommendMedicines = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    // inputData.type = "doctor";
    const token = getToken();
    const headers = {
      "Content-Type": "application/json",
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    const response = await axios.post(
      // `http://127.0.0.1:8000/recommend-medicines/generate`,
      `${CONFIG.APIBaseUrl}midoc_ai_doctorrecommendmedicines`,
      inputData,
      {
        headers: headers,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching data in forms.js:", error);
    throw error;
  }
};

const ExtractAiRecommendMedicines = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    // inputData.type = "doctor";
    const token = getToken();
    const headers = {
      "Content-Type": "application/json",
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    const response = await axios.post(
      // `http://127.0.0.1:8000/doctor-notes/extract-medicines`,
      `${CONFIG.APIBaseUrl}midoc_ai_extractmedicines`,
      inputData,
      {
        headers: headers,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching data in forms.js:", error);
    throw error;
  }
};

const ExtractAiPatientDataForAiraNotes = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    // inputData.type = "doctor";
    const token = getToken();
    const headers = {
      "Content-Type": "application/json",
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    const response = await axios.post(
      // `http://127.0.0.1:8000/doctor-notes/extract-patient-notes`,
      `${CONFIG.APIBaseUrl}midoc_ai_extractpatientnotes`,
      inputData,
      {
        headers: headers,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching data in forms.js:", error);
    throw error;
  }
};

const ExtractAiRecommendOrders = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    // inputData.type = "doctor";
    const token = getToken();
    const headers = {
      "Content-Type": "application/json",
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    const response = await axios.post(
      // `http://127.0.0.1:8000/doctor-notes/extract-orders`,
      `${CONFIG.APIBaseUrl}midoc_ai_extractorders`,
      inputData,
      {
        headers: headers,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching data in forms.js:", error);
    throw error;
  }
};

const ExtractAiRecommendROS = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    // inputData.type = "doctor";
    const token = getToken();
    const headers = {
      "Content-Type": "application/json",
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    const response = await axios.post(
      // `http://127.0.0.1:8000/doctor-notes/extract-ros`,
      `${CONFIG.APIBaseUrl}midoc_ai_extractros`,
      inputData,
      {
        headers: headers,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching data in forms.js:", error);
    throw error;
  }
};

const GenerateDoctorNotesFromTranscripts = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    // inputData.type = "doctor";
    const token = getToken();
    const headers = {
      "Content-Type": "application/json",
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    const response = await axios.post(
      // `http://127.0.0.1:8000/doctor-notes/generate`,
      `${CONFIG.APIBaseUrl}midoc_ai_doctornotestranscription`,
      inputData,
      {
        headers: headers,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching data in forms.js:", error);
    throw error;
  }
};

const GenerateDoctorChatsFromTranscripts = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    // inputData.type = "doctor";
    const token = getToken();
    const headers = {
      "Content-Type": "application/json",
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    const response = await axios.post(
      // `http://127.0.0.1:8000/doctor-notes/diarization`,
      `${CONFIG.APIBaseUrl}midoc_ai_doctornotesdiarization`,
      inputData,
      {
        headers: headers,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching data in forms.js:", error);
    throw error;
  }
};

const GenerateDoctorPatientHistoryNotesFromTranscripts = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    // inputData.type = "doctor";
    const token = getToken();
    const headers = {
      "Content-Type": "application/json",
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    const response = await axios.post(
      // `http://127.0.0.1:8000/doctor-notes/extract-patient-data`,
      `${CONFIG.APIBaseUrl}midoc_ai_extractpatientdata`,
      inputData,
      {
        headers: headers,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching data in forms.js:", error);
    throw error;
  }
};

const GenerateAskADoctorPrescriptionFromChats = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    // inputData.type = "doctor";
    const token = getToken();
    const headers = {
      "Content-Type": "application/json",
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    const response = await axios.post(
      // `http://127.0.0.1:8000/ask-doctor/generate-prescription`,
      `${CONFIG.APIBaseUrl}midoc_ai_askdoctorgeneratesprescription`,
      inputData,
      {
        headers: headers,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching data in forms.js:", error);
    throw error;
  }
};

const SubmitAiFeedback = async (inputData) => {
  try {
    //inputData.doctorid = getLoginID();
    const result = await FetchData("midoc_addaifeedback", inputData);
    // console.log(inputData);
    // console.log(result, "db response");

    return result;
  } catch (error) {
    console.error("Error fetching data in forms.js:", error);
    throw error;
  }
};

export {
  FetchMyHealthAIData,
  FetchMedicineAIAnswer,
  FetchMedicineAIPrompts,
  FetchSurgeriesAIPrompts,
  FetchSurgeriesAIAnswer,
  FetchVaccinationAIPrompts,
  FetchVaccinationAIAnswer,
  FetchAllergiesAIPrompts,
  FetchAllergiesAIAnswer,
  FetchConditionAIPrompts,
  FetchConditionAIAnswer,
  DetectDrugInteractions,
  AiGenerateAssessmentPlan,
  AiRecommendMedicines,
  GenerateDoctorNotesFromTranscripts,
  GenerateDoctorChatsFromTranscripts,
  GenerateDoctorPatientHistoryNotesFromTranscripts,
  SubmitAiFeedback,
  GenerateAskADoctorPrescriptionFromChats,
  ExtractAiRecommendMedicines,
  ExtractAiRecommendROS,
  ExtractAiRecommendOrders,
  ExtractAiPatientDataForAiraNotes
};
