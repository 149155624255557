import { useCallback, useMemo, useState } from "react";
import { useWorker } from "./gpuUseWorker";
import gpuConstants from "./gpuConstants";

export function useGPUTranscriber() {
  const [transcript, setTranscript] = useState(undefined);
  const [isBusy, setIsBusy] = useState(false);
  const [isModelLoading, setIsModelLoading] = useState(false);
  const [progressItems, setProgressItems] = useState([]);

  const webWorker = useWorker((event) => {
    const message = event.data;
    switch (message.status) {
      case "progress":
        setProgressItems((prev) =>
          prev.map((item) => {
            if (item.file === message.file) {
              return { ...item, progress: message.progress };
            }
            return item;
          })
        );
        break;
      case "update":
      case "complete":
        const busy = message.status === "update";
        const updateMessage = message;
        setTranscript({
          isBusy: busy,
          text: updateMessage.data.text,
          tps: updateMessage.data.tps,
          chunks: updateMessage.data.chunks,
        });
        // console.log(updateMessage);
        setIsBusy(busy);
        break;
      case "initiate":
        setIsModelLoading(true);
        setProgressItems((prev) => [...prev, message]);
        break;
      case "ready":
        setIsModelLoading(false);
        break;
      case "error":
        setIsBusy(false);
        alert(
          `An error occurred: "${message.data.message}". Please email us at info@thecloudintelligence.com.`
        );
        break;
      case "done":
        setProgressItems((prev) =>
          prev.filter((item) => item.file !== message.file)
        );
        break;
      default:
        break;
    }
  });

  // console.log(gpuConstants.DEFAULT_MODEL);
  // console.log(gpuConstants.DEFAULT_SUBTASK);
  // console.log(gpuConstants.DEFAULT_MULTILINGUAL);
  // console.log(gpuConstants.DEFAULT_LANGUAGE);

  const [model, setModel] = useState(gpuConstants.DEFAULT_MODEL);
  const [subtask, setSubtask] = useState(gpuConstants.DEFAULT_SUBTASK);
  const [multilingual, setMultilingual] = useState(
    gpuConstants.DEFAULT_MULTILINGUAL
  );
  const [language, setLanguage] = useState(gpuConstants.DEFAULT_LANGUAGE);

  const onInputChange = useCallback(() => {
    setTranscript(undefined);
  }, []);

  const postRequest = useCallback(
    async (audioData) => {
      if (audioData) {
        setTranscript(undefined);
        setIsBusy(true);

        let audio;
        if (audioData.numberOfChannels === 2) {
          const SCALING_FACTOR = Math.sqrt(2);

          let left = audioData.getChannelData(0);
          let right = audioData.getChannelData(1);

          audio = new Float32Array(left.length);
          for (let i = 0; i < audioData.length; ++i) {
            audio[i] = (SCALING_FACTOR * (left[i] + right[i])) / 2;
          }
        } else {
          audio = audioData.getChannelData(0);
        }

        webWorker.postMessage({
          audio,
          model,
          multilingual,
          subtask: multilingual ? subtask : null,
          language: multilingual && language !== "auto" ? language : null,
        });
      }
    },
    [webWorker, model, multilingual, subtask, language]
  );

  const transcriber = useMemo(() => {
    return {
      onInputChange,
      isBusy,
      isModelLoading,
      progressItems,
      start: postRequest,
      output: transcript,
      model,
      setModel,
      multilingual,
      setMultilingual,
      subtask,
      setSubtask,
      language,
      setLanguage,
    };
  }, [
    isBusy,
    isModelLoading,
    progressItems,
    postRequest,
    transcript,
    model,
    multilingual,
    subtask,
    language,
  ]);

  return transcriber;
}
