import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  VStack,
  HStack,
  Button,
  Text,
  Progress as ChakraProgress,
} from "@chakra-ui/react";
import CPUTranscribeAudioRecorder from "./cpu-audio-recorder";
import Constants from "./Constants";
import Progress from "./progress";

export const AudioSource = {
  URL: "URL",
  FILE: "FILE",
  RECORDING: "RECORDING",
};

export function CPUNotesTranscriberAudioManager(props) {
  const [isRecording, setIsRecording] = useState(false);
  const [audioQueue, setAudioQueue] = useState([]); // Queue for audio chunks
  const [isTranscribing, setIsTranscribing] = useState(false); // Track ongoing transcription
  const recorderRef = useRef(null);
  const intervalRef = useRef(null); // Interval reference for 10-second recording chunks

  // Handle recording toggle
  const handleMicClick = () => {
    setIsRecording(true);
  };

  // Set up interval for 10-second recording chunks
  useEffect(() => {
    if (isRecording) {
      intervalRef.current = setInterval(() => {
        console.log("chunking");
        // Trigger recording stop and start
        setIsRecording(false);
        setTimeout(() => {
          setIsRecording(true);
        }, 300); // 1000 milliseconds = 1 second
      }, 10000); // 10 seconds
    } else {
      // Clear interval when not recording
      clearInterval(intervalRef.current);
    }

    // Cleanup function
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [isRecording]);

  const handleOffMicClick = () => {
    setIsRecording(false);
  };

  // Handle completed recordings
  const setAudioFromRecording = async (data) => {
    const blobUrl = URL.createObjectURL(data);
    const fileReader = new FileReader();
    fileReader.onloadend = async () => {
      const audioCTX = new AudioContext({
        sampleRate: Constants.SAMPLING_RATE,
      });
      const arrayBuffer = fileReader.result;
      const decoded = await audioCTX.decodeAudioData(arrayBuffer);
      console.log(decoded);

      // Add decoded audio to queue
      setAudioQueue((prevQueue) => [
        ...prevQueue,
        {
          buffer: decoded,
          url: blobUrl,
          source: AudioSource.RECORDING,
          mimeType: data.type,
        },
      ]);
    };
    fileReader.readAsArrayBuffer(data);
  };

  // Process transcription queue
  useEffect(() => {
    if (
      !isTranscribing &&
      audioQueue.length > 0 &&
      !props.transcriber?.output?.isBusy
    ) {
      const nextAudio = audioQueue[0]; // Get the first chunk
      console.log("Performing transcription for a chunk");
      props.transcriber.start(nextAudio.buffer); // Start transcription for the chunk

      // Remove the chunk from the queue after starting transcription
      setAudioQueue((prevQueue) => prevQueue.slice(1));

      // Set transcribing state
      setIsTranscribing(true);
    }

    // If transcription finishes, allow the next chunk to be processed
    if (!props.transcriber?.output?.isBusy && isTranscribing) {
      setIsTranscribing(false);
    }
  }, [audioQueue, isTranscribing, props.transcriber?.output?.isBusy]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <HStack spacing={2} py={2} w="full" px={2}>
        <Button
          colorScheme="blue"
          onClick={isRecording ? handleOffMicClick : handleMicClick}
        >
          {isRecording ? "Stop" : "Start"}
        </Button>
      </HStack>

      {audioQueue.length > 0 && (
        <Box p={4} w="full">
          <Text mb={2}>Transcribing audio chunks...</Text>
          <VStack spacing={2}>
            {audioQueue.map((data, index) => (
              <Box key={index} w="full">
                <Progress
                  text={`Chunk ${index + 1}`}
                  percentage={data.progress}
                />
              </Box>
            ))}
          </VStack>
        </Box>
      )}

      <CPUTranscribeAudioRecorder
        // ref={recorderRef}
        recording={isRecording}
        onRecordingComplete={setAudioFromRecording}
      />
    </Box>
  );
}
