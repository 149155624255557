import {
    Box,
    Image,
    Step,
    StepDescription,
    StepIndicator,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    Text,
  } from "@chakra-ui/react";
  import React, { useEffect, useState } from "react";
import { FetchDoctorAssessmentDetails } from "../../middleware/services/messages";
import moment from "moment";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
  
  const DoctorAssessment = ({id,backFunction}) => {

  const [patientid, setPatientid] = useState("");
  const [doctorAssessmentData, setDoctorAssessmentData] = useState(null);
  const { isLoading, showLoader, dismissLoader } = useLoader();

  useEffect(() => {
    const currentUrl = window.location.href;
    const searchParams = new URLSearchParams(currentUrl.split('?') [1]);
    const id = searchParams.get('id');
    setPatientid(id);
    fetchdoctorassessmentdata(id)   
  }, []);
  
  const fetchdoctorassessmentdata = async (id) => {
    showLoader()
        try {
          const result = await FetchDoctorAssessmentDetails({
            patientid: id,
          });

          if (result != null) {
            console.log(result)
            setDoctorAssessmentData(result);
          } else {
          }
          dismissLoader()
      } catch (error) {
          console.error("Error in home:", error);
        }
      };

      const formatDate = (dateString) => {
        if (!dateString) return "-";
        return moment(dateString).format("Do MMM, YYYY");
      };

      // console.log(patientid, "treatment plan IDDDDD")
      // console.log(treatmentPlanData, "treatment plan Dataaaaaaa")

    return (
      <>
      {isLoading && <Loader />}
        <Box w="100%" display={{base: "none", lg: "block"}}>
          {doctorAssessmentData && doctorAssessmentData.length>0 ? (
          <Stepper
            orientation="vertical"
            height="400px"
            size="xs"
            colorScheme="#CDCFD4"
            gap="0"
            w="100%"
          >
              {doctorAssessmentData.map((assessment,index) => (
              <Step w="100%" pb="1rem" key={index}>
                <StepIndicator color="#E6E7E9" />
  
                <Box flexShrink="0" w="95%">
                  {/* <StepTitle fontSize="0.875rem" color="#384052" mb="0.5rem">
                    {formatDate(assessment.DATE)}
                  </StepTitle> */}
                  <StepTitle as="div">
                    <Text fontWeight='600' fontSize="0.875rem" color="#384052" mb="0.5rem">{formatDate(assessment.DATE)}</Text>
                    <Text fontWeight='600' fontSize="0.875rem" color="#384052" mb="0.5rem">{`${assessment.doctorname} , ${assessment.bookingid?assessment.bookingid : ""}`}</Text>
                    <Text fontSize="0.875rem" color="#384052" mb="0.5rem">{assessment.specialization}</Text>      
                  </StepTitle>
                  <StepDescription
                    bg="white"
                    w="100%"
                    border="1px solid #E6E7E9"
                    boxShadow="0px 8px 20px 0px #E6E7E966"
                    borderRadius="0.65rem"
                    p="1.25rem"
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      pb="0.5rem"
                    >
                      <Text fontSize="0.875rem" color="#061027">
                        {/* Heart failure with preserved ejection fraction, unspecified HF chronicity (CMS/HCC) */}
                        {/* {treatmentPlanData.diagnosis} */}
                        {assessment.diagnosis}
                      </Text>
                    </Box>
                    <Box bg="#F9FAFB" borderLeft="2px solid #14C585" p="0.5rem">
                      <Text fontSize="0.875rem" fontWeight="500">
                        {/* Plan: Basic metabolic panel, Magnesium */}
                        {/* {treatmentPlanData.plan? "Plan: "+treatmentPlanData.plan:""} */}
                        {assessment.plan}
                      </Text>
                    </Box>
                  </StepDescription>
                </Box>
  
                <StepSeparator />
              </Step>
              ))}
              {/* // )) */}
            
          </Stepper>
        ) : (
          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            gap="10px"
                            alignItems="center"
                            h="calc(100vh - 100px)"
                          >
                            <Box textAlign="left" fontSize="16px" fontWeight="600">
                              <Image
                                w="26px"
                                opacity="0.5"
                                src="/assets/imgs/treatment-plan.png"
                              />
                            </Box>
                            <Text textAlign="left" fontSize="12px" fontWeight="500">
                              No Data available
                            </Text>
                          </Box>
        )}
        </Box>

        {/* Mobile responsive */}
        <Box w="100%" display={{base: "block", lg: "none"}}>
          <MobileSecondaryNav
            header="Doctor assessment"
            handlebackarrow={backFunction}
          />

          <Box w="100%" h="calc(100vh - 120px)" overflow='hidden auto' p="1rem">
          {doctorAssessmentData && doctorAssessmentData.length>0 ? (
            <Stepper
              orientation="vertical"
              height="400px"
              size="xs"
              colorScheme="#CDCFD4"
              gap="0"
              w="100%"
            >
                {doctorAssessmentData.map((assessment,index) => (
                <Step w="100%" pb="1rem" key={index}>
                  <StepIndicator color="#E6E7E9" />
    
                  <Box flexShrink="0" w="95%">
                    {/* <StepTitle fontSize="0.875rem" color="#384052" mb="0.5rem">
                      {formatDate(assessment.DATE)}
                    </StepTitle> */}
                    <StepTitle as="div">
                      <Text fontWeight='600' fontSize="0.875rem" color="#384052" mb="0.5rem">{formatDate(assessment.DATE)}</Text>
                      <Text fontWeight='600' fontSize="0.875rem" color="#384052" mb="0.5rem">{`${assessment.doctorname} , ${assessment.bookingid?assessment.bookingid : ""}`}</Text>
                      <Text fontSize="0.875rem" color="#384052" mb="0.5rem">{assessment.specialization}</Text>      
                    </StepTitle>
                    <StepDescription
                      bg="white"
                      w="100%"
                      border="1px solid #E6E7E9"
                      boxShadow="0px 8px 20px 0px #E6E7E966"
                      borderRadius="0.65rem"
                      p="1.25rem"
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        pb="0.5rem"
                      >
                        <Text fontSize="0.875rem" color="#061027">
                          {/* Heart failure with preserved ejection fraction, unspecified HF chronicity (CMS/HCC) */}
                          {/* {treatmentPlanData.diagnosis} */}
                          {assessment.diagnosis}
                        </Text>
                      </Box>
                      <Box bg="#F9FAFB" borderLeft="2px solid #14C585" p="0.5rem">
                        <Text fontSize="0.875rem" fontWeight="500">
                          {/* Plan: Basic metabolic panel, Magnesium */}
                          {/* {treatmentPlanData.plan? "Plan: "+treatmentPlanData.plan:""} */}
                          {assessment.plan}
                        </Text>
                      </Box>
                    </StepDescription>
                  </Box>
    
                  <StepSeparator />
                </Step>
                ))}
                {/* // )) */}
              
            </Stepper>
            ) : (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                gap="10px"
                alignItems="center"
                h="calc(100vh - 100px)"
              >
                <Box textAlign="left" fontSize="16px" fontWeight="600">
                  <Image
                    w="26px"
                    opacity="0.5"
                    src="/assets/imgs/treatment-plan.png"
                  />
                </Box>
                <Text textAlign="left" fontSize="12px" fontWeight="500">
                  No Data available
                </Text>
              </Box>
          )}
          </Box>
        </Box>
      </>
    );
  };
  
  export default DoctorAssessment;
  