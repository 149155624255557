function extractDiagnosis(data) {
  if (data.length === 0) {
    return [];
  }
  const diagnoses = data.map((entry) => entry.diagnosis);
  return diagnoses;
}

function formatMedicalHistory(input) {
  if (input.length === 0) {
    return [];
  }
  return input.map((item) => item.condition);
}

function transformVitalData(vitals) {
  if (vitals.length === 0) {
    return [];
  }

  const vitalMap = {
    "Body temperature": "Temperature",
    "Respiratory rate": "Respiratory Rate",
    Pulse: "Pulse",
    "Oxygen Saturation": "SPO2",
    "Systolic Blood Pressure": "BP_systolic",
    "Diastolic Blood Pressure": "BP_diastolic",
  };

  const transformedVitals = [];
  let bpSystolic = null;
  let bpDiastolic = null;

  vitals.forEach((vital) => {
    const vitalType = vitalMap[vital.vitalname] || vital.vitalname;
    if (vitalType === "BP_systolic") {
      bpSystolic = vital.vitalvalue;
    } else if (vitalType === "BP_diastolic") {
      bpDiastolic = vital.vitalvalue;
    } else {
      transformedVitals.push({
        type: vitalType,
        value: vital.vitalvalue,
        unit: vital.vitalunit,
      });
    }
  });

  if (bpSystolic && bpDiastolic) {
    transformedVitals.push({
      type: "BP",
      value: `${bpSystolic}/${bpDiastolic}`,
      unit: "mmHg",
    });
  }

  return transformedVitals;
}

function calculateAge(dob) {
  if (!dob) {
    return 0;
  }

  const dobDate = new Date(dob);
  const today = new Date();

  let age = today.getFullYear() - dobDate.getFullYear();
  const monthDifference = today.getMonth() - dobDate.getMonth();
  const dayDifference = today.getDate() - dobDate.getDate();

  if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
    age--;
  }

  return age;
}

function calculateDateDifference(startDate, endDate) {
  if (!endDate) return -1;

  const start = new Date(startDate);
  const end = new Date(endDate);

  // Calculate the difference in milliseconds
  const diffInMilliseconds = end - start;

  // Convert milliseconds to days
  const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));

  console.log(diffInDays)

  return diffInDays;
}

function formatMedicines(input) {
  if (input.length === 0) {
    return [];
  }
  return input.map((item) => {
    return {
      name: item.medicinename,
      chemicalcomposition: item.chemicalcomposition || "",
      morning: item.morning ? "1" : "0",
      evening: item.evening ? "1" : "0",
      night: item.night ? "1" : "0",
      timing:
        item.beforefood && item.afterfood
          ? "Before & After Food"
          : item.beforefood
          ? "Before Food"
          : item.afterfood
          ? "After Food"
          : "",
      frequency: item.frequency || "",
      dosageforprescription:
        item?.dosage && item?.dosageunit ? item?.dosage + item?.dosageunit : "",
      duration: calculateDateDifference(item.medicationstartdate, item.medicationenddate)
    };
  });
}

function formatOrders(orders) {
  const result = [];
  for (const orderType in orders) {
    if (orders.hasOwnProperty(orderType) && orderType !== "medications") {
      const orderList = orders[orderType];
      if (Array.isArray(orderList)) {
        for (const order of orderList) {
          if (order && order.ordername) {
            const formattedOrder = `${
              order.entitytype.charAt(0).toUpperCase() +
              order.entitytype.slice(1)
            }: ${order.ordername}`;
            result.push(formattedOrder);
          }
        }
      }
    }
  }
  return result;
}

// used in overview screens and not on prescription
function mergeOrders(orderprocedure, orderimaging, orderlab) {
  // Initialize an empty array to store the merged result
  let mergedOrders = [];

  // Check if orderprocedure array exists and is not empty
  if (orderprocedure && Array.isArray(orderprocedure)) {
    // Iterate over orderprocedure array
    orderprocedure.forEach((order) => {
      // Push object with ordertype 'procedure' and ordername to mergedOrders
      mergedOrders.push({
        ordertype: "Procedure",
        ordername: order.ordername,
      });
    });
  }

  // Check if orderimaging array exists and is not empty
  if (orderimaging && Array.isArray(orderimaging)) {
    // Iterate over orderimaging array
    orderimaging.forEach((order) => {
      // Push object with ordertype 'imaging' and ordername to mergedOrders
      mergedOrders.push({
        ordertype: "Imaging",
        ordername: order.ordername,
      });
    });
  }

  // Check if orderlab array exists and is not empty
  if (orderlab && Array.isArray(orderlab)) {
    // Iterate over orderlab array
    orderlab.forEach((order) => {
      // Push object with ordertype 'lab' and ordername to mergedOrders
      mergedOrders.push({
        ordertype: "Lab",
        ordername: order.ordername,
      });
    });
  }

  // Return the merged array
  return mergedOrders;
}

export {
  extractDiagnosis,
  formatMedicalHistory,
  transformVitalData,
  calculateAge,
  formatMedicines,
  formatOrders,
  mergeOrders,
};
