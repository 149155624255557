export default function processVitalsData(vitals, visitDate) {
  if (!Array.isArray(vitals)) {
    return [];
  }

  const filteredData = visitDate
    ? vitals.filter((vital) => {
        const vitalDate = vital.vitaladdeddate.split("T")[0];
        return vitalDate === visitDate.split("T")[0];
      })
    : vitals;

  const processedData = filteredData.map((vital) => {
    let vitalStatus = "Ok";
    const value = parseFloat(vital.vitalvalue);

    if (vital.vitalname === "BP") {
      const systolicValue = parseFloat(vital.systolic);
      const diastolicValue = parseFloat(vital.diastolic);

      const lowerBoundsystolic = parseFloat(vital.lower_boundsystolic);
      const upperBoundsystolic = parseFloat(vital.upper_boundsystolic);
      const lowerBounddiastolic = parseFloat(vital.lower_bounddiastolic);
      const upperBounddiastolic = parseFloat(vital.upper_bounddiastolic);

      const actualSystolic = parseFloat(vital.systolic);
      const actualDiastolic = parseFloat(vital.diastolic);

      let systolicStatus = "";
      let diastolicStatus = "";

      // if (!isNaN(actualSystolic) && !isNaN(actualDiastolic)) {
      //   if (actualSystolic <= lowerBoundsystolic && actualSystolic < 90) {
      //     systolicStatus = "low";
      //   } else if (
      //     actualSystolic <= lowerBoundsystolic &&
      //     actualSystolic < 120
      //   ) {
      //     systolicStatus = "low";
      //   } else if (
      //     actualSystolic <= lowerBoundsystolic &&
      //     actualSystolic > 130
      //   ) {
      //     systolicStatus = "high";
      //   } else if (actualSystolic > upperBoundsystolic && actualSystolic < 90) {
      //     systolicStatus = "low";
      //   } else if (
      //     actualSystolic > upperBoundsystolic &&
      //     actualSystolic < 130
      //   ) {
      //     systolicStatus = "normal";
      //   } else if (
      //     actualSystolic > upperBoundsystolic &&
      //     actualSystolic > 130
      //   ) {
      //     systolicStatus = "high";
      //   } else if (
      //     actualSystolic >= lowerBoundsystolic &&
      //     actualSystolic <= upperBoundsystolic &&
      //     actualSystolic < 90
      //   ) {
      //     systolicStatus = "low";
      //   } else if (
      //     actualSystolic >= lowerBoundsystolic &&
      //     actualSystolic <= upperBoundsystolic &&
      //     actualSystolic < 130
      //   ) {
      //     systolicStatus = "normal";
      //   } else if (
      //     actualSystolic >= lowerBoundsystolic &&
      //     actualSystolic <= upperBoundsystolic &&
      //     actualSystolic > 130
      //   ) {
      //     systolicStatus = "high";
      //   }

      //   if (actualDiastolic < lowerBounddiastolic && actualDiastolic < 60) {
      //     diastolicStatus = "low";
      //   } else if (
      //     actualDiastolic < lowerBounddiastolic &&
      //     actualDiastolic < 120
      //   ) {
      //     diastolicStatus = "low";
      //   } else if (
      //     actualDiastolic > upperBounddiastolic &&
      //     actualDiastolic < 90
      //   ) {
      //     diastolicStatus = "low";
      //   } else if (
      //     actualDiastolic > upperBounddiastolic &&
      //     actualDiastolic < 130
      //   ) {
      //     diastolicStatus = "normal";
      //   } else if (
      //     actualDiastolic > upperBounddiastolic &&
      //     actualDiastolic > 130
      //   ) {
      //     diastolicStatus = "high";
      //   } else if (
      //     actualDiastolic >= lowerBounddiastolic &&
      //     actualDiastolic <= upperBounddiastolic &&
      //     actualDiastolic < 90
      //   ) {
      //     diastolicStatus = "low";
      //   } else if (
      //     actualDiastolic >= lowerBounddiastolic &&
      //     actualDiastolic <= upperBounddiastolic &&
      //     actualDiastolic < 130
      //   ) {
      //     diastolicStatus = "normal";
      //   } else if (
      //     actualDiastolic >= lowerBounddiastolic &&
      //     actualDiastolic <= upperBounddiastolic &&
      //     actualDiastolic > 130
      //   ) {
      //     diastolicStatus = "high";
      //   }

      //   // Low 	low
      //   // High high
      //   // Normal normaal

      //   // Low normal
      //   // normal High
      //   // high Low

      //   if (systolicStatus === "low" && diastolicStatus === "low") {
      //     vitalStatus = "Low";
      //   } else if (
      //     systolicStatus === "normal" &&
      //     diastolicStatus === "normal"
      //   ) {
      //     vitalStatus = "Ok";
      //   } else if (systolicStatus === "high" && diastolicStatus === "high") {
      //     vitalStatus = "High";
      //   } else if (systolicStatus === "low" && diastolicStatus === "high") {
      //     // Prioritize low over high for systolic low and diastolic high
      //     vitalStatus = "Low";
      //   } else if (systolicStatus === "normal" && diastolicStatus === "high") {
      //     // Prioritize high over normal for systolic normal and diastolic high
      //     vitalStatus = "High";
      //   } else if (systolicStatus === "normal" && diastolicStatus === "low") {
      //     // Prioritize high over low for systolic high and diastolic low
      //     vitalStatus = "Low";
      //   }
      //   //the below 3 conditions were not there previously , *******
      //   // may require to change in future according to requirment
      //   else if (systolicStatus === "low" && diastolicStatus === "normal") {
      //     // Prioritize high over low for systolic high and diastolic low
      //     vitalStatus = "Low";
      //   } else if (systolicStatus === "high" && diastolicStatus === "low") {
      //     // Prioritize high over low for systolic high and diastolic low
      //     vitalStatus = "High";
      //   } else if (systolicStatus === "high" && diastolicStatus === "normal") {
      //     // Prioritize high over low for systolic high and diastolic low
      //     vitalStatus = "High";
      //   }
      if (!isNaN(actualSystolic) && !isNaN(actualDiastolic)) {
        if (actualSystolic <= lowerBoundsystolic && actualSystolic < 90) {
          systolicStatus = 'low';
        } else if (actualSystolic <= lowerBoundsystolic && actualSystolic < 120) {
          systolicStatus = 'low';
        } else if (actualSystolic <= lowerBoundsystolic && actualSystolic > 130) {
          systolicStatus = 'high';
        } else if (actualSystolic > upperBoundsystolic && actualSystolic < 90) {
          systolicStatus = 'low';
        } else if (actualSystolic > upperBoundsystolic && actualSystolic < 130) {
          systolicStatus = 'normal';
        } else if (actualSystolic > upperBoundsystolic && actualSystolic > 130) {
          systolicStatus = 'high';
        } else if (actualSystolic >= lowerBoundsystolic && actualSystolic <= upperBoundsystolic && actualSystolic < 90) {
          systolicStatus = 'low';
        } else if (actualSystolic >= lowerBoundsystolic && actualSystolic <= upperBoundsystolic && actualSystolic < 130) {
          systolicStatus = 'normal';
        } else if (actualSystolic >= lowerBoundsystolic && actualSystolic <= upperBoundsystolic && actualSystolic > 130) {
          systolicStatus = 'high';
        }
  
        if (actualDiastolic < lowerBounddiastolic && actualDiastolic < 60) {
          diastolicStatus = 'low';
        } else if (actualDiastolic < lowerBounddiastolic && actualDiastolic < 120) {
          diastolicStatus = 'low';
        } else if (actualDiastolic > upperBounddiastolic && actualDiastolic < 90) {
          diastolicStatus = 'low';
        } else if (actualDiastolic > upperBounddiastolic && actualDiastolic < 120) {
          diastolicStatus = 'normal';
        } else if (actualDiastolic > upperBounddiastolic && actualDiastolic >= 120) {
          diastolicStatus = 'high';
        } else if (actualDiastolic >= lowerBounddiastolic && actualDiastolic <= upperBounddiastolic && actualDiastolic < 90) {
          diastolicStatus = 'low';
        } else if (actualDiastolic >= lowerBounddiastolic && actualDiastolic <= upperBounddiastolic && actualDiastolic < 130) {
          diastolicStatus = 'normal';
        } else if (actualDiastolic >= lowerBounddiastolic && actualDiastolic <= upperBounddiastolic && actualDiastolic > 130) {
          diastolicStatus = 'high';
        }
        
        // Low 	low  	
        // High high	
        // Normal normaal
  
        // Low normal	
        // normal High 
        // high Low 
  
        if (systolicStatus === 'low' && diastolicStatus === 'low') {
          vitalStatus = 'Low';
        } else if (systolicStatus === 'normal' && diastolicStatus === 'normal') {
          vitalStatus = 'Normal';
        } else if (systolicStatus === 'high' && diastolicStatus === 'high') {
          vitalStatus = 'High';
        } else if (systolicStatus === 'low' && diastolicStatus === 'high') {
          // Prioritize low over high for systolic low and diastolic high
          vitalStatus = 'Low';
        } else if (systolicStatus === 'normal' && diastolicStatus === 'high') {
          // Prioritize high over normal for systolic normal and diastolic high
          vitalStatus = 'High';
        } else if (systolicStatus === 'normal' && diastolicStatus === 'low') {
          // Prioritize high over low for systolic high and diastolic low
          vitalStatus = 'Normal';
        }
        //the below 3 conditions were not there previously , *******
        // may require to change in future according to requirment
        else if (systolicStatus === 'low' && diastolicStatus === 'normal') {
          // Prioritize high over low for systolic high and diastolic low
          vitalStatus = 'Normal';
        }else if (systolicStatus === 'high' && diastolicStatus === 'low') {
          // Prioritize high over low for systolic high and diastolic low
          vitalStatus = 'High';
        }else if (systolicStatus === 'high' && diastolicStatus === 'normal') {
          // Prioritize high over low for systolic high and diastolic low
          vitalStatus = 'High';
        }
      }

      if (isNaN(systolicValue) && isNaN(diastolicValue)) {
        vitalStatus = null;
      }
    } else {
      if (isNaN(value)) {
        vitalStatus = null;
      } else {
        if (value < parseFloat(vital.lower_bound)) {
          vitalStatus = "Low";
        }

        if (value > parseFloat(vital.upper_bound)) {
          vitalStatus = "High";
        }
      }
    }

    return {
      vitalName: vital.vitalname,
      vitalValue: vital.vitalvalue,
      vitalUnit: vital.vitalunit,
      vitalDate: vital.vitaladdeddate,
      vitalStatus: vitalStatus,
    };
  });

  return processedData;
}
