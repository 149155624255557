import  FetchData, { } from '../../client';
import formapimodalhome from '../../domains/home/home';
import {getLoginID}  from '../../../auth';
import formAPIModelrecentactivity from '../../domains/recentactivity/recentactivities';

//reac the file

const FetchHomeData = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    const result = await FetchData("midoc_getdoctorhomepage", inputData);
    //console.log(result,"Home db response")
    if (result.output) {
    var finalOutput = formapimodalhome(result.output)
    }
    return finalOutput;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};

const FetchRecentActivity = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    const result = await FetchData("midoc_getdoctoractivityreport", inputData);
    //console.log(result,"recent activity db response")
    if (result.output.data) {
      //console.log(result.output.data)
    var finalOutput = formAPIModelrecentactivity(result.output.data)
    }
    return finalOutput;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const updatedoctoravailability = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    const result = await FetchData("midoc_updatedoctorprofile", inputData);
    
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const AcceptVisit = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    const result = await FetchData("midoc_doctorupdateappointment", inputData);
    
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const FetchDoctorsByUser = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    const result = await FetchData("midoc_getdoctorlistbyuser", inputData);
    
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};

// Networked Care Calls
const FetchBranches = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    const result = await FetchData("midoc_getclinic", inputData);
    //console.log(result,"Home db response")
    // if (result.output) {
    // var finalOutput = formapimodalhome(result.output)
    // }
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};

// Report Calls
const FetchDailyReport = async (inputData) => {
  try {
    // inputData.doctorid = getLoginID();
    const result = await FetchData("midoc_getdailydiagnosereport", inputData);
    // console.log(result,"Daily Report db response")
    // if (result.output) {
    // var finalOutput = formapimodalhome(result.output)
    // }
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const FetchServicesProvisionedReport = async (inputData) => {
  try {
    // inputData.doctorid = getLoginID();
    const result = await FetchData("midoc_getmonthlydiagnosereport", inputData);
    // console.log(result,"Monthly Services Provisioned Report db response")
    // if (result.output) {
    // var finalOutput = formapimodalhome(result.output)
    // }
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const FetchMonthlyDiseaseWiseReport = async (inputData) => {
  try {
    // inputData.doctorid = getLoginID();
    const result = await FetchData("midoc_getmonthlydiseasesreport", inputData);
    // console.log(result,"Monthly Diseases Report db response")
    // if (result.output) {
    // var finalOutput = formapimodalhome(result.output)
    // }
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};

export {FetchHomeData, FetchRecentActivity, updatedoctoravailability, AcceptVisit, FetchDoctorsByUser, FetchBranches, FetchDailyReport, FetchServicesProvisionedReport, FetchMonthlyDiseaseWiseReport};
