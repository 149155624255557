import React, { useState } from "react";
import {
  Box,
  Text,
  Image,
  Circle,
  Center,
  Link,
  Divider,
} from "@chakra-ui/react";
import moment from "moment";
import TabSection from "../../tabs";
import ResultsTable from "./lab-tabular";

const formatDate = (dateString) => {
  if (!dateString) return "-";
  return moment(dateString).format("Do MMM, YYYY");
};

const fileTypeMappings = {
  pdf: "/assets/svgs/pdf-icon.svg",
  png: "/assets/imgs/png-icon.png",
  jpg: "/assets/imgs/jpg-icon.png",
  jpeg: "/assets/imgs/jpg-icon.png",
};

const getFileIcon = (fileName) => {
  const extension = fileName.split(".").pop().toLowerCase();
  return fileTypeMappings[extension] || "/assets/svgs/file-icon.svg"; // fallback image
};

const LabTests = ({
  tests,
  pateintId,
  doctorId,
  appointmentId,
  showLoader,
  dismissLoader,
  showErrorToast,
  showSuccessToast,
  onEdit,
  onDelete,
  scaleHeight = "auto",
  componentBg = "#fcfcfd",
  cardBg = "white",
}) => {
  const [activeViewTab, setActiveViewTab] = useState("Report View");

  const handleViewTabClick = (index) => {
    switch (index) {
      case 0:
        setActiveViewTab("Report View");
        break;
      case 1:
        setActiveViewTab("Data View");
        break;

      default:
        break;
    }
  };
  return (
    <>
      <Box display="flex" justifyContent="center" mx="auto" maxWidth="100%">
        <TabSection
          defaultIndex={0}
          tabs={["Report View", "Data View"]}
          onClickTab={handleViewTabClick}
          tabBtnStyle={{ minW: { lg: "120px", xl: "140px" } }}
        />
      </Box>
      {activeViewTab === "Report View" ? (
        <Box
          display="flex"
          flexDir="column"
          alignItems="center"
          w="100%"
          bg={componentBg}
        >
          {tests.map((test) => (
            <Box
              key={test.id}
              display="flex"
              flexDir="column"
              alignItems="center"
              w="100%"
              mt="4px"
            >
              <Box display="flex" alignItems="center" w="100%">
                <Circle
                  size="1rem"
                  bg="white"
                  border="2px solid #CDCFD4"
                  borderRadius="full"
                  color="black"
                />
                <Text
                  fontSize="12px"
                  fontWeight="500"
                  textAlign="left"
                  color="#384052"
                  ml="0.5rem"
                >
                  {formatDate(test.date)}
                </Text>
              </Box>
              <Box display="flex" w="100%" mt="10px">
                <Center
                  w="100%"
                  h="auto"
                  ml="0.45rem"
                  alignItems="start"
                  borderLeft="2px solid #E6E7E9"
                >
                  <Box w="100%">
                    <Box
                      p="20px"
                      w="100%"
                      ml="10px"
                      display="flex"
                      flexDirection="column"
                      alignItems="start"
                      justifyContent="space-between"
                      border="1px solid #E6E7E9"
                      borderRadius="12px"
                      backgroundColor={cardBg}
                    >
                      <Box display="flex" alignItems="center" w="100%">
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          flex="1"
                        >
                          <Text
                            title={test.name}
                            fontSize="16px"
                            textAlign="left"
                            fontWeight="600"
                            overflow="hidden"
                            textOverflow="ellipsis"
                          >
                            {test.name}
                          </Text>
                        </Box>
                      </Box>
                      <Box display="flex" alignItems="center" w="100%">
                        <Box
                          mt="3px"
                          display="flex"
                          alignItems="center"
                          backgroundColor="#f9fafb"
                          borderLeft="3px solid #14c585"
                          w="100%"
                        >
                          <Text
                            fontSize="14px"
                            fontWeight="500"
                            color="#384052"
                            pl="4px"
                          >
                            {test.summary}
                          </Text>
                        </Box>
                      </Box>
                      <Divider my="10px" />
                      <Box display="flex" w="100%" flexWrap="wrap">
                        {test.attachments.map((attachment, index) => (test?.documenturl &&
                          <Box
                            key={index}
                            display="flex"
                            alignItems="center"
                            border="1px solid #E6E7E9"
                            borderRadius="8px"
                            p="5px 10px"
                            mr="10px"
                            backgroundColor="white"
                          >
                            <Box bg="#f9f9f9" p="5px" borderRadius="8px">
                              <Image
                                src={getFileIcon(attachment.name)}
                                w="16px"
                              />
                            </Box>
                            <Link
                              href={attachment.link}
                              isExternal
                              fontSize="14px"
                              color="#2B6CB0"
                              ml="5px"
                            >
                              {attachment.name}
                            </Link>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                </Center>
              </Box>
            </Box>
          ))}
        </Box>
      ) : (
        <ResultsTable
          doctorId={doctorId}
          patientId={pateintId}
          appointmentId={appointmentId}
          showSuccessToast={showSuccessToast}
          showErrorToast={showErrorToast}
          showLoader={showLoader}
          dismissLoader={dismissLoader}
          maxHeight={"400px"}
        />
      )}
    </>
  );
};

export default LabTests;
