import React from "react";
import { Box, Text, Grid, Image, Divider } from "@chakra-ui/react";

const HPIViewCard = ({
  site,
  onset,
  chronicity,
  onsetQuality,
  frequency,
  ratingScale,
  associatedSymptoms,
  time,
  timeValue,
  editHPI,
  deleteHPI,
  otherOptions = {},
}) => {
  return (
    <Box border="1px solid #E6E7E9" borderRadius="8px" p="2" bg="white">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="2"
      >
        <Text fontSize="md" fontWeight="bold" whiteSpace="pre-wrap">
          {site.join(", ")}
        </Text>
        <Box display="flex" alignItems="center">
          <Box
            as="button"
            border="1px solid #E6E7E9"
            borderRadius="full"
            w="36px"
            h="auto"
            p="10px"
            onClick={editHPI}
          >
            <Image w="13px" src="/assets/svgs/edit-gray.svg" />
          </Box>
          <Box
            as="button"
            border="1px solid #E6E7E9"
            borderRadius="full"
            w="36px"
            h="auto"
            p="10px"
            ml="10px"
            onClick={deleteHPI}
          >
            <Image w="13px" src="/assets/imgs/delete.png" />
          </Box>
        </Box>
      </Box>
      <Divider />
      <Grid
        templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(4, 1fr)" }}
        gap={{ base: 3, md: 6 }}
        my="4px"
      >
        <Box>
          <Text color="gray.500" fontSize="xs">
            Onset
          </Text>
          <Text fontSize='15px' fontWeight='600'>{onset}</Text>
        </Box>
        <Box>
          <Text color="gray.500" fontSize="xs">
            Chronicity
          </Text>
          <Text fontSize='15px' fontWeight='600'>{chronicity}</Text>
        </Box>
        <Box>
          <Text color="gray.500" fontSize="xs">
            Onset Quality
          </Text>
          <Text fontSize='15px' fontWeight='600'>{onsetQuality}</Text>
        </Box>
        <Box>
          <Text color="gray.500" fontSize="xs">
            Frequency
          </Text>
          <Text fontSize='15px' fontWeight='600'>{frequency}</Text>
        </Box>
        <Box>
          <Text color="gray.500" fontSize="xs">
            Rating Scale
          </Text>
          <Text fontSize='15px' fontWeight='600'>{ratingScale}</Text>
        </Box>
        <Box>
          <Text color="gray.500" fontSize="xs">
            Associated Symptoms
          </Text>
          <Text whiteSpace="pre-wrap" fontSize='15px' fontWeight='600'>{associatedSymptoms.join(", ")}</Text>
        </Box>
        <Box>
          <Text color="gray.500" fontSize="xs">
            Time
          </Text>
          <Text fontSize='15px' fontWeight='600'>
            {timeValue} {time}
          </Text>
        </Box>

        {/* Dynamic rendering for optional features */}
        {Object.keys(otherOptions).map((key) => (
          <Box key={key}>
            <Text color="gray.500" fontSize="xs">
              {key}
            </Text>
            <Text whiteSpace="pre-wrap">{otherOptions[key].join(", ")}</Text>
          </Box>
        ))}
      </Grid>
    </Box>
  );
};

export default HPIViewCard;
