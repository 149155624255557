
import formAPIScreenList from "./screenlist";


var formattedOutput = null;

const formAPIModelScreensList = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIScreenList(item));


  return formattedOutput
};

export default formAPIModelScreensList