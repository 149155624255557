export default function formapipatients({
    patientname,
    chiefcomplaint,
    appointmentdate,
    countofunreadmessages,
    patientid,
    appointmenttime,
    patientimage,
    imagepath,

    
  }) {
   
  
    return Object.freeze({
        patientname: patientname,
        chiefcomplaint,
    appointmentdate,
    countofunreadmessages,
    patientid,
    appointmenttime,
    patientimage,
    imagepath,
    });
  }
  