import { tooltip } from "@antv/g2plot/lib/adaptor/common";
import moment from "moment";
export default function formAPItotalfollowupchart({
    createddate,
    totalfollowupvisits,
   
   

}, index)
{ 

  //let dateFormat = "YYYY-MM-DD";
  const formattedDate = moment.utc(createddate); // Parse input as UTC
  formattedDate.local(); // Convert parsed UTC time to local time
   const formattedDateTime = moment(formattedDate).format('Do MMM, YYYY');  
  // const formattedDateTimeDisp = moment(formattedDate).format('Do MMM, YYYY');
  return Object.freeze({
    label: formattedDateTime ? formattedDateTime : '',
    value: totalfollowupvisits,
    // tooltipLabel: formattedDateTimeDisp
    //index: index,
  });
}