import { getToken } from "@chakra-ui/react";
import { getLoginID } from "../../../auth"
import FetchData from "../../client";
import axios from "axios";
import { CONFIG } from "../../../appconfig/appconfig";
import formAPIModelMedications from "../../domains/medications/medications";


const FetchMedicationsData = async (inputData) => {
    try {
      
      inputData.doctorid = getLoginID();
    //   inputData.activeuserid = getUserID();
         // console.log(inputData,"Medications input")
      const result = await FetchData("midoc_getpatientprofilemedications", inputData);
      //console.log(result,"Medications db response")
      if (result.output.data) {
      var finalOutput = formAPIModelMedications(result.output.data)
      //console.log(finalOutput)
      }
      return finalOutput;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  // Add Medicine
  export const GetMedicineName = async (uri,name,type) => {
    // alert(uri)
     try {     
       const formData = new FormData();
       formData.append("file", uri);
       // form.append('image', {
       //   uri: uri,
       //   type: type ? 'image/jpeg' : 'image/pdf',
       //   name: name ? 'image.jpg' : 'image.pdf',
       // });
        let doctorid = getLoginID();
        formData.append("doctorid", doctorid);
       //console.log(formData)
       const response = await axios.post(`${CONFIG.APIBaseUrl}midoc_ai_doctormedicinename`,formData, {    
         headers: {
           'Content-Type': 'multipart/form-data', 
         },
       });
       //console.log('@@response',response)
       if (response.statusText === "OK") { 
         const result = await response.data;
         //console.log('@@result',result)
         return result
       } else {
         const error = "failed" ;
         return error
       }
     } catch (err) {
       console.log(err); 
       const error = "failed" ;
       return error
     }
   };

  export const AddMedicine = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
    //   inputData.patientid = getLoginInfo();
    // inputData.activeuserid = getUserID();
    //console.log(inputData , " input to add medication")
      const result = await FetchData("midoc_addpatientmedicine ", inputData);
     //console.log(result , " db response of add medicane")
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  export const GetAIMedicineComposition = async (inputData) => {
    try {
      //inputData.doctorid = getLoginID();
      const result = await FetchData("midoc_ai_medicinebyname", inputData);
      // console.log(inputData);
      // console.log(result, "db response");
      return result;
    } catch (error) {
      console.error("Error fetching data in Fetch composition:", error);
      throw error;
    }
  };
  export const FetchMedicineAIAnswer = async (inputData) => {
    try {
      // inputData.patientid = getLoginInfo()
      const token = getToken();
      const headers = {
        'Content-Type': 'application/json',
      };
      if (token) {
        headers['Authorization'] = `Bearer ${token}`;
      }
      const response = await axios.post(`${CONFIG.APIBaseUrl}midoc_ai_medicinedescribeprompts`, 
        inputData,
      {
        headers: headers,
      });
      
      return response.data;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };

  // export const FetchTreatmentTeam = async (inputData) => {
  //   try {
  //     // inputData.patientid = getLoginInfo();
  //     // inputData.activeuserid = getUserID();
  //     //console.log(inputData);
  //     const result = await FetchData("midoc_getpatienttreatmentteam", inputData);
  //     //console.log(result,"Home db response")
  //     if (result.output.data) {
  //       //console.log(result.output.data)
  //     var finalOutput = formAPIModeltreatmentteam(result.output.data)
  //     }
  //     return finalOutput;
  
  //   } catch (error) {
  //     console.error('Error fetching data in forms.js:', error);
  //     throw error;
  //   }
  // };

  export const EditMedicine = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
    
    //   inputData.patientid = getLoginInfo();
    // inputData.activeuserid = getUserID();
            const result = await FetchData("midoc_updatepatientmedicine", inputData);
     
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  export const DeleteMedicine = async (inputData) => {
    try {

      inputData.doctorid = getLoginID();
      // inputData.loginid = getLoginInfo();
      // inputData.activeuserid = getUserID();
      const result = await FetchData("midoc_deletepatientmedicine", inputData);
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };

  
const FetchMyHealthAIData = async (inputData) => {
    try {
      
      inputData.doctorid = getLoginID();
    //   inputData.activeuserid = getUserID();
      //console.log(inputData)
      const result = await FetchData("midoc_getpatienthealth", inputData);
    //   if (result.output.data) {
    //     //console.log(result.output.data)
    //   var finalOutput = formapimodalaidotor(result.output.data)
    //   }
    //   return finalOutput;
    return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };

  
const FetchMedicineAIPrompts = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      const token = getToken();
      const headers = {
        'Content-Type': 'application/json',
      };
      if (token) {
        headers['Authorization'] = `Bearer ${token}`;
      }
      const response = await axios.post(`${CONFIG.APIBaseUrl}midoc_ai_medicinegetprompts`, 
        inputData,
      {
        headers: headers,
      });
      
      return response.data;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };

export {FetchMedicationsData, FetchMyHealthAIData, FetchMedicineAIPrompts};
