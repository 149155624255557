import moment from "moment";
export default function formAPItotalvisitschart({
    appointmentdate,
    totalvisithours,
   
   

}, index)
{ 
    const formattedDate = moment.utc(appointmentdate); // Parse input as UTC
  formattedDate.local(); // Convert parsed UTC time to local time
  const formattedDateTime = moment(formattedDate).format('Do MMM, YYYY');  

  return Object.freeze({
    label: formattedDateTime ? formattedDateTime : '',
    value: totalvisithours,
    //index: index,
    
  });
}