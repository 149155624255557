import {
  Box,
  Flex,
  Image,
  Step,
  StepDescription,
  StepIndicator,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FetchHistoryOfPresentIllness } from "../../middleware/services/messages";
import moment from "moment";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import { useLocation, useNavigate } from "react-router-dom";
import GrayCard from "../../components/card";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";

const HistoryOfPresentIllness = ({ id, backFunction }) => {
  const location = useLocation();
  const [patientid, setPatientid] = useState("");
  const [historyIllnessData, setHistoryIllnessData] = useState(null);
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const navigate = useNavigate();

  useEffect(() => {
    const currentUrl = window.location.href;
    const searchParams = new URLSearchParams(currentUrl.split("?")[1]);
    const id = searchParams.get("id");
    setPatientid(id);

    fetchhistoryillnessdata(id);
  }, []);

  const fetchhistoryillnessdata = async (id) => {
    showLoader();
    try {
      const result = await FetchHistoryOfPresentIllness({
        patientid: id,
      });

      if (result != null) {
        console.log(result);
        setHistoryIllnessData(result);
      } else {
      }
      dismissLoader();
    } catch (error) {
      console.error("Error in home:", error);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    return moment(dateString).format("Do MMM, YYYY");
  };

  // console.log(patientid, "historyIllness IDDDDD")
  // console.log(historyIllnessData, "historyIllness Dataaaaaaa")

  function parseDateString(dateString) {
    const dateParts = dateString.replace(/[\.,]/g, "").split(" ");
    if (dateParts.length === 3) {
      const day = parseInt(dateParts[0]);
      const month = dateParts[1];
      const year = parseInt(dateParts[2]);

      const monthIndex = new Date(`${month} 1, ${year}`).getMonth() + 1;
      const formattedDate = `${year}-${
        monthIndex < 10 ? "0" + monthIndex : monthIndex
      }-${day < 10 ? "0" + day : day}`;
      return formattedDate;
    }
    return dateString;
  }

  function calculateAge(dateOfBirth) {
    const parsedDate = parseDateString(dateOfBirth);
    const today = new Date();
    const dob = new Date(parsedDate);

    if (isNaN(dob)) {
      console.error("Invalid date format:", parsedDate);
      return NaN;
    }

    let age = today.getFullYear() - dob.getFullYear();
    const monthDiff = today.getMonth() - dob.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
      age--;
    }
    return age;
  }

  const generateDynamicText = (item, gender, dob) => {
    let outputTexts = [];

    // Retrieve stored data from localStorage
    let storedData = localStorage.getItem("dobAndGenderData");
    let parsedData = storedData ? JSON.parse(storedData) : {};
    let genderFromStorage = parsedData.gender || "";
    let dobFromStorage = parsedData.dob || "";

    // Use provided gender and dob or fallback to localStorage data
    let genderToUse = gender || genderFromStorage;
    let dobToUse = dob || dobFromStorage;
    let age = calculateAge(dobToUse);

    let attributes = {};

    // Parse the item details to extract attributes
    if (item.details && Array.isArray(item.details)) {
      item.details.forEach((attribute) => {
        switch (attribute.attribute) {
          case "Site":
            attributes.site = attribute.values.map((v) => v.value).join(", ");
            break;
          case "Onset":
            attributes.onset = attribute.value;
            break;
          case "Chronicity":
            attributes.chronicity = attribute.value;
            break;
          case "Onset Quality":
            attributes.onsetQuality = attribute.value;
            break;
          case "Frequency":
            attributes.frequency = attribute.value;
            break;
          case "Associated Symptoms":
            attributes.symptoms = attribute.values
              .map((v) => v.value)
              .join(", ");
            break;
          case "Rating Scale":
            attributes.ratingScale = attribute.value;
            break;
          case "Time":
            attributes.time = attribute.value;
            break;
          case "Time Value":
            attributes.timeValue = attribute.value;
            break;
          default:
            break;
        }
      });
    }

    // Generate the patient introductory text
    let patientText = `The patient is a ${
      isNaN(age) ? "" : age
    }-year-old individual (${genderToUse}) `;
    let template = `${patientText}`;

    // Add each attribute's sentence only if the data is available
    if (attributes.site) {
      template += `presenting with ${attributes.site} ${item.type}. `;
    }

    if (attributes.onset) {
      template += `The ${item.type} started ${attributes.onset} and `;
      if (attributes.chronicity) {
        template += `is described as ${attributes.chronicity} in nature. `;
      }
    }

    if (attributes.onsetQuality) {
      template += `The onset of the ${item.type} was ${attributes.onsetQuality}. `;
    }

    if (attributes.frequency) {
      template += `The ${item.type} is ${attributes.frequency} and `;
    }

    if (attributes.ratingScale) {
      template += `is rated as ${attributes.ratingScale} out of 10 in intensity. `;
    }

    if (attributes.symptoms) {
      template += `Associated with ${attributes.symptoms}.`;
    }

    // Push the generated template to the outputTexts array
    outputTexts.push(template.trim());

    return outputTexts;
  };

  const groupByBookingId = (data) => {
    if (!Array.isArray(data)) {
      return [];
    }

    const grouped = data.reduce((acc, item) => {
      if (!acc[item.bookingid]) {
        acc[item.bookingid] = {
          doctorname: item.doctorname,
          bookingid: item.bookingid,
          specialization: item.specialization,
          appointmentdate: item.appointmentdate,
          details: [],
        };
      }
      acc[item.bookingid].details.push(item);
      return acc;
    }, {});

    return Object.values(grouped);
  };
  const groupedData = groupByBookingId(historyIllnessData);

  return (
    <>
      {isLoading && <Loader />}

      <Box w='100%' display={{ base: "none", lg: "block" }}>
      <Stepper
          orientation="vertical"
          height="400px"
          size="xs"
          colorScheme="#CDCFD4"
          gap="0"
          w="100%"
        >
        {groupedData && groupedData.length>0 ? (

        Object.values(groupedData).map((doctor, index) => (
           <Step w="100%" pb="1rem" key={index}>
           <StepIndicator color="#E6E7E9" />

           <Box flexShrink="0" w="95%">
             {/* <StepTitle fontSize="0.875rem" color="#384052" mb="0.5rem">
               {formatDate(visit.appointmentdate)}
             </StepTitle> */}
             <StepTitle as="div">
             <Box display='flex' gap='1rem'>
                 <Text fontWeight='600' fontSize="0.875rem" color="#384052" mb="0.5rem">{formatDate(doctor.appointmentdate)}</Text>
                 {/* <Box w='50px' h='25px' border='1px solid #CDCFD4' borderRadius='20px' textAlign='center'>
                 <Text 
                 as='button' 
                 fontWeight='500' 
                 fontSize="0.875rem" 
                 color="#384052"
                 onClick={() => handleClick(visit.appointmentid)}
                 >View</Text>
                 </Box> */}
               </Box>
             </StepTitle>
             <StepDescription
               bg="white"
               w="100%"
               border="1px solid #E6E7E9"
               borderRadius="0.65rem"
               p="0.75rem"
             >
               <Flex justifyContent='space-between' alignItems='center'>
                 <Text fontWeight='600' fontSize="0.875rem" color="#384052" mb="0.5rem">{doctor.doctorname} , {doctor.bookingid}</Text>
                 {/* <Image cursor='pointer' src="/assets/imgs/right-icon.png" onClick={() => handleClick(visit.appointmentid)} /> */}
               </Flex>
               <Box
                 display="flex"
                 // justifyContent="flex-end"
                 alignItems="center"
                 p="0.5rem"
                 pl="0"
               >
                 <Text fontSize="0.875rem" color="#384052" mb="0.5rem">{doctor.specialization}</Text>     
                 {/* <Text fontSize="0.875rem" color="#061027">
                   Visited general physician
                   Visited {visit.specality}
                 </Text> */}
               </Box>
               {doctor.details.map((detail, detailIndex) => (
               <Box bg="#F9FAFB" borderLeft="2px solid #14C585" key={detailIndex} p="0.5rem">
                 <Text fontSize="0.875rem" fontWeight="500">
                   {/* Reason: Headache, stomach ache, coughing and vomiting */}
                   {generateDynamicText(detail)[0]}
                 </Text>
               </Box>
               ))}
               {/* <Box display="flex" p="0.5rem" pl="0" alignItems="center">
                 <Image w="0.75rem" h="auto" src="/assets/svgs/update.svg" />
                 <Text
                   fontSize="0.875rem"
                   fontWeight="500"
                   color="#6A707D"
                   pl="0.5rem"
                 >
                   Reason: Headache, stomach ache, coughing and vomiting
                   {''}
                 </Text>
               </Box> */}
             </StepDescription>
           </Box>
       <StepSeparator />

         </Step>
        ))) : (
          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            gap="10px"
                            alignItems="center"
                            h="calc(100vh - 100px)"
                            w="100%"
                          >
                            <Box textAlign="left" fontSize="16px" fontWeight="600">
                              <Image
                                w="26px"
                                opacity="0.5"
                                src="/assets/svgs/coronavirus.svg"
                              />
                            </Box>
                            <Text textAlign="left" fontSize="12px" fontWeight="500">
                              No Data available
                            </Text>
                          </Box>
        
        )}
        </Stepper>
      </Box>

      {/* Mobile responsive */}
      <Box w="100%" display={{ base: "block", lg: "none" }}>
        <MobileSecondaryNav header="HPI" handlebackarrow={backFunction} />
        <Box h="calc(100vh - 120px)" overflow="hidden auto" p="1rem">
        <Stepper
          orientation="vertical"
          height="400px"
          size="xs"
          colorScheme="#CDCFD4"
          gap="0"
          w="100%"
        >
        {Object.values(groupedData).map((doctor, index) => (
           <Step w="100%" pb="1rem" key={index}>
           <StepIndicator color="#E6E7E9" />

           <Box flexShrink="0" w="95%">
             {/* <StepTitle fontSize="0.875rem" color="#384052" mb="0.5rem">
               {formatDate(visit.appointmentdate)}
             </StepTitle> */}
             <StepTitle as="div">
             <Box display='flex' gap='1rem'>
                 <Text fontWeight='600' fontSize="0.875rem" color="#384052" mb="0.5rem">{formatDate(doctor.appointmentdate)}</Text>
                 {/* <Box w='50px' h='25px' border='1px solid #CDCFD4' borderRadius='20px' textAlign='center'>
                 <Text 
                 as='button' 
                 fontWeight='500' 
                 fontSize="0.875rem" 
                 color="#384052"
                 onClick={() => handleClick(visit.appointmentid)}
                 >View</Text>
                 </Box> */}
               </Box>
             </StepTitle>
             <StepDescription
               bg="white"
               w="100%"
               border="1px solid #E6E7E9"
               borderRadius="0.65rem"
               p="0.75rem"
             >
               <Flex justifyContent='space-between' alignItems='center'>
                 <Text fontWeight='600' fontSize="0.875rem" color="#384052" mb="0.5rem">{doctor.doctorname} , {doctor.bookingid}</Text>
                 {/* <Image cursor='pointer' src="/assets/imgs/right-icon.png" onClick={() => handleClick(visit.appointmentid)} /> */}
               </Flex>
               <Box
                 display="flex"
                 // justifyContent="flex-end"
                 alignItems="center"
                 p="0.5rem"
                 pl="0"
               >
                 <Text fontSize="0.875rem" color="#384052" mb="0.5rem">{doctor.specialization}</Text>     
                 {/* <Text fontSize="0.875rem" color="#061027">
                   Visited general physician
                   Visited {visit.specality}
                 </Text> */}
               </Box>
               {doctor.details.map((detail, detailIndex) => (
               <Box bg="#F9FAFB" borderLeft="2px solid #14C585" key={detailIndex} p="0.5rem">
                 <Text fontSize="0.875rem" fontWeight="500">
                   {/* Reason: Headache, stomach ache, coughing and vomiting */}
                   {generateDynamicText(detail)[0]}
                 </Text>
               </Box>
               ))}
               {/* <Box display="flex" p="0.5rem" pl="0" alignItems="center">
                 <Image w="0.75rem" h="auto" src="/assets/svgs/update.svg" />
                 <Text
                   fontSize="0.875rem"
                   fontWeight="500"
                   color="#6A707D"
                   pl="0.5rem"
                 >
                   Reason: Headache, stomach ache, coughing and vomiting
                   {''}
                 </Text>
               </Box> */}
             </StepDescription>
           </Box>
       <StepSeparator />

         </Step>
        ))}
        </Stepper>
        </Box>
      </Box>
    </>
  );
};

export default HistoryOfPresentIllness;
