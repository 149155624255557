import React, { useRef, useState,useEffect, useLayoutEffect } from "react";
import MiniSideBar from "../../components/mini-sidebar";
import {
  Box,
  Card,
  CardFooter,
  Center,
  Circle,
  Divider,
  Flex,
  Image,
  InputGroup,
  Menu,
  MenuButton,
  Spacer,
  Stack,
  Text,
  Textarea,
  VStack,
  MenuList,
  MenuItem,
  InputRightElement,
  Input,
  Td,
  Tr,
  Step,
  StepIndicator,
  StepTitle,
  StepDescription,
  Stepper,
  StepSeparator,
  UnorderedList,
  ListItem,
  CardBody,
  Switch,
} from "@chakra-ui/react";
import SecondaryNav from "../../components/seconday-nav";
import PrimaryButton from "../../components/primary-button";
import GrayCard from "../../components/card";
import SearchBar from "../../components/search-bar";
import TypingLoader from "../../components/typing-loader";
import {COLORS} from "../../components/styles/colors";
import ToggleTab from "../../components/accordion";
import DynamicModal from "../../components/dynamic-modal";
import ModalRight from "../../components/modal-right";
import { Link, NavLink, useLocation } from "react-router-dom";
import { FetchPatientDetails, FetchPatients, FetchChatData, AddMessage, FetchPatientChatMedia , updatechatmessageread, requestPayment} from "../../middleware/services/messages";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import SimpleTable from "../../components/simple-table";
import moment from "moment";
import Toaster from "../../components/toaster";

import PatientVisitsIndex from "./patientvisits";
import { format, formatDistance } from "date-fns";
import PatientProfileIndex from "./patientprofile";
import {
    BrowserRouter as Router,
    generatePath,
    Routes,
    Route,
    useNavigate,
    useParams,
} from "react-router-dom";
import { actionAccessMain, downloadDocument,formatToDecimal,getLoginID,uploadImageCommonFunction } from "../../auth";
import { Global, css } from "@emotion/react";
import {
  UpdateAppointmentByDoctor,
} from "../../middleware/services/visits";
import MobileNavbar from "../../components/mobile-navbar";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";

const MessagesIndex = () => {
    const [activeTab, setActiveTab] = useState("images")
    const manageModel = useRef();
    const cancelVisit = useRef();
    const scheduleVisit = useRef();
    const closeChat = useRef();
    const startVisit = useRef();
    const doctorProfile = useRef();
    const requestpaymentmodal = useRef();
    const disablechatmodal = useRef();
    const navigate = useNavigate();
    const chatContainerRef = React.useRef(null);
    const { isLoading, showLoader, dismissLoader } = useLoader();
    var [doctorid, setDoctorid] = useState(getLoginID());
    var [patientid, setPatientID] = useState("");
    var [data, setData] = useState(null);
    var [patientsdata, setPatientData] = useState(null);
    var [chatsdata, setChatData] = useState([]);
    var [ChatMedia, setChatMedia] = useState(null);
    var [ChatHeader, setChatHeader] = useState(null);
    var [ChatEnableDetails, setChatEnableDetails] = useState(null);
    var [DoctorCurrency, setDoctorCurrency] = useState("");
    var [doctormessage, setDoctorMessage] = useState("");
    var [activechatbox, setActivechatbox] = useState("");
    const [search, setSearch] = useState("");
    const lastMessageRef = useRef(null);
    const { showSuccessToast, showErrorToast } = Toaster();
    const messageRef = useRef(null);
    const [isEmpty, setIsEmpty] = useState(true);
    const handlePageChange = () => {
      localStorage.setItem("previousPage" , "messages")
      navigate("/patientprofile?id="+ patientid+"&page=overview") 
  }
  const [isFocused, setIsFocused] = useState(false);
  const [chatInputHeight, setChatInputHeight] = useState(80);
  const location = useLocation();
  const {id} = location.state || {};
  const [isDoctorListVisible, setIsDoctorListVisible] = useState(true);
  const [screenType, setScreenType] = useState('web');

  const [actionAccess, setActionAccess] = useState(null);
  const [actionAccessVisits, setActionAccessVisits] = useState(null);
  const [actionAccessAppointment, setActionAccessAppointment] = useState(null);
  const [actionAccessPatients, setActionAccessPatients] = useState(null);
  const [messageValue, setMessageValue] = useState("")

  useEffect(() => {
    setActionAccess(actionAccessMain(3))
    setActionAccessVisits(actionAccessMain(2))
    setActionAccessAppointment(actionAccessMain(15))
    setActionAccessPatients(actionAccessMain(4))
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setScreenType('mobile');
      } else {
        setScreenType('web');
      }
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (chatContainerRef.current) {
        const chatSectionHeight = window.innerHeight - 73 - 91 - chatInputHeight;
        chatContainerRef.current.style.height = `${chatSectionHeight}px`;
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [chatInputHeight]);

  // useEffect(() => {
  //   if (messageRef.current) {
  //     setIsEmpty(messageRef.current.textContent.trim() === '');
  //   }
  // }, []);
  const handleInputChange = (e) => {
    if (messageRef.current) {
      //const value = messageRef.current.textContent;
      setChatInputHeight(Math.min(Math.max(e.target.scrollHeight, 80), 100));
      //setIsEmpty(value.trim() === '');
    }
  };

  const handleInput = (e)=> {
    setMessageValue(e.target.value)
  }

    // const chatsdata = [{ id: 1, text: "How will this impact my health?", ispatient: "0" },]
    // const messages = [
    //     { id: 1, text: "How will this impact my health?", sender: "me" },
    //     { id: 2, text: "Elevated body temperature can potentially affect your health by signaling a fever, which may be a symptom of infections or other health issues.", sender: "doctor" },
    //     { id: 3, text: "Is there anything I can do to lower the fever?", sender: "me" },
    //     { id: 4, text: "You should stay hydrated and rest. Over-the-counter fever reducers like acetaminophen or ibuprofen can also help.", sender: "doctor" },
    //     { id: 5, text: "Thank you, should I schedule a follow-up appointment?", sender: "me" },
    //     { id: 6, text: "Yes, it's best to monitor your symptoms and have a check-up if the fever persists or worsens.", sender: "doctor" },
    //     { id: 7, text: "How soon should I come in for the check-up?", sender: "me" },
    //     { id: 8, text: "I recommend coming in 48 hours if there's no improvement, or immediately if your condition worsens.", sender: "doctor" },
    //     { id: 9, text: "Okay, I will book an appointment accordingly. Thanks for the advice!", sender: "me" },
    //     { id: 10, text: "You're welcome! Take care and feel better soon.", sender: "doctor" },
    //     // { id: 9, text: "/assets/right-img.jpg", sender: "me", isImage: true },
    //     // { id: 10, text: "/assets/left-img.jpg", sender: "doctor", isImage: true },
    //     // { id: 9, text: "/assets/doc.jpg", sender: "me", isDoc: true },
    //     // { id: 10, text: "/assets/doc.jpg", sender: "doctor", isDoc: true },
    //   ];
    
    const images = [
        {image: "/assets/left-img.jpg"},
        {image: "/assets/left-img.jpg"},
        {image: "/assets/left-img.jpg"},
        {image: "/assets/left-img.jpg"},
        {image: "/assets/left-img.jpg"},
        {image: "/assets/left-img.jpg"},
    ]

    useEffect(() => {
      
      
        fetchPatients();       

      }, []);
      const fetchPatients = async () => {
        showLoader()
        try {
          const result = await FetchPatients({
            doctorid,
          });
          if (result != null) {
            
    //console.log(actionAccess ,"  actionAccess")
            setPatientData(result);
            if(screenType == 'web'){
              openchat(result[0])
            }
            // openchat(result[0], "initial")
          } else {
            //setPatientData("");
            dismissLoader()
          }
          dismissLoader()
        } catch (error) {
          console.error("Error in home:", error);
        }
      };

      // useEffect(() => {
      //   // Clean up function
      //   // return () => {
      //     if (messageRef.current) {
      //       messageRef.current.textContent = '';
      //       setIsEmpty(true);
      //       setChatInputHeight(80);
      //     }
      //   // };
      // }, []);

      // const openchat = async (patientdata, type) => {
      //   const patientId = type === "initial" ? patientdata.patientid : patientdata.patientid;
      
      //   fetchchatdata(patientId);
      //   fetchpatientdata(patientId);
      //   setActivechatbox(patientId);
      //   setPatientID(patientId);
      
      //   if (type !== "initial" && patientdata.countofunreadmessages > 0) {
      //     updatemsgsread(patientId);
      //   }
      // };
      const openchat = async (patientdata, type)=>{
        // if (messageRef.current) {
        //   messageRef.current.textContent = '';
        //   setIsEmpty(true);
        //   setChatInputHeight(80); 
        // }
        if(id && type !== "current"){
        fetchchatdata(id);
        fetchpatientdata(id);
        setActivechatbox(id);
        setPatientID(id);
        updatemsgsread(id)
        if(screenType === "mobile"){
          setIsDoctorListVisible(false);
        }
        setMessageValue("");
        //messageRef.current.value = '';
        if(messageRef.current != null){
          messageRef.current.value = '';
        }
      }else{
        fetchchatdata(patientdata.patientid);
        fetchpatientdata(patientdata.patientid);
        setActivechatbox(patientdata.patientid);
        setPatientID(patientdata.patientid);
        setMessageValue("");
        if(patientdata.countofunreadmessages > 0){
          updatemsgsread(patientdata.patientid)
        }
        //messageRef.current.value = '';
        if(messageRef.current != null){
          messageRef.current.value = '';
        }
      }

      if(screenType === "mobile"){
        setIsDoctorListVisible(false);
      }
      if(chatContainerRef.current){
        chatContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }
      }
      const fetchchatdata = async (id) => {
        setChatData("");
        
        //showLoader()
        try {
          const result = await FetchChatData({
            doctorid,
            patientid: id,
          });
          if (result != null) {
            setChatData(result.finalOutput);   
            setChatHeader(result.chatheaderoutput) 
            setChatEnableDetails(result.chatenablesettings) 
            if(result?.chatenablesettings?.currencyname == "USD"){
              setDoctorCurrency("$")    
            }else{
              setDoctorCurrency(result?.chatenablesettings?.currencyname)
            }            
          } else {
          }
          //dismissLoader()
        } catch (error) {
          console.error("Error in home:", error);
        }
      };
      const updatemsgsread = async (id) => {
        setChatData("");
        //showLoader()
        try {
  
            const updatemsgsread = await updatechatmessageread({
              doctorid,
              patientid: id,
              "isreadbydoctor":1,
              "ispatient":"0"
            });      
            if(updatemsgsread){
              const result = await FetchPatients({
                doctorid,
              });
              if (result != null) {
                setPatientData(result);
              }
            }
        } catch (error) {
          console.error("Error in home:", error);
        }
      };
      const fetchpatientdata = async (id) => {
        showLoader()
        try {
          const result = await FetchPatientDetails({
            patientid: id,
            "type":"messageview"
          });
          //console.log(result, " result patient details");
          if (result != null) {
            setData(result);
          } else {
          }
          dismissLoader()
        } catch (error) {
          console.error("Error in home:", error);
        }
      };
      const openchatmedia = async () => {
        showLoader()
        try {
          const result = await FetchPatientChatMedia({
            patientid,
            doctorid,
            type:"all",
          });
          if (result != null) {
            setChatMedia(result);
            const groupedImages = result.image.reduce((acc, img) => {
              const date = formatDate(img.createddate);
              if (!acc[date]) {
                acc[date] = [];
              }
              acc[date].push(img);
              return acc;
            }, {});
            doctorProfile.current.openRight();
          } else {
          }
          dismissLoader()
        } catch (error) {
          console.error("Error in home:", error);
        }
      };
      const handleClick = async (visit) => {
        // if (visit && visit.appointmentid) {
          navigate(
            `/visitdetails?patientId=${patientid}&appointmentId=${visit}`
          );
        // } else {
        //   console.error("Error: Visit details are incomplete.");
        // }
      };
      const formatDate = (dateString) => {
        if (!dateString) return "-";
        return moment(dateString).format("Do MMM, YYYY");
      };
      const formatTime = (time) => {
        if (!time) {
          return ""; 
        }

        const [hours, minutes] = time.split(':');
      
        const date = new Date();
        date.setHours(hours, minutes);
      
        const formattedTime = date.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });
      
        return formattedTime;
      };
      const appointmentTime = ChatHeader && ChatHeader.appointmenttime;
      const formattedAppointmentTime = formatTime(appointmentTime);

      const handlemessageKeyPress = (e) => {
        if (e.key === 'Enter') {
          if (e.altKey) {
            // Allow line break with Alt+Enter
            e.preventDefault();
            //console.log(e)
            document.execCommand('insertLineBreak');
          } else {
            // Prevent default behavior (line break) and send the message
            e.preventDefault();
            sendmessage();
          }
        }
      };

      const messagetext = (e) =>{
        setDoctorMessage(e.target.value); 
      }
      const sendmessage = async () =>{       
        try { 
          //showLoader()
          //const value = messageRef.current.textContent;
          const todayDateTimeUTC = moment.utc();
          const todayDateTimeLocal = todayDateTimeUTC.local();
          const formattedTime = todayDateTimeLocal.format('hh:mm A');
          const fullDateTime = todayDateTimeLocal.format();
          if(messageValue === ""){
            // showErrorToast("Please type something")
            showErrorToast("Please Enter a message")
         }else{
          const newMessage = {
            doctorid,
            patientid,
            chat: messageValue,
            ispatient: "0",
            chattype: "text",
            formattedtime: formattedTime,
            createddate: fullDateTime,
        };
         
            const result = await AddMessage(newMessage);
            //console.log(result)
            if (result) {
              // Update the chat state with the new message
              setChatData(prevChats => {
                const updatedChats = prevChats ? [...prevChats, newMessage] : [newMessage];
                return updatedChats;
              });
              // setDoctorMessage("");
              lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
              scrollToBottom();
              messageRef.current.value = ''; // Clear the input after sending
              setMessageValue("");
              if(messageRef.current.value == ''){
                messageRef.current.style.height = chatInputHeight;
              }
              
              //setIsEmpty(true); 
              // setIsFocused(false); // Reset focus state
             
          } else {
              console.error("Failed to send message. Result is null.");
          }
         }    
            //dismissLoader()
          } catch (error) {
            console.error("Error in home:", error);
          }
      }
      //console.log(messageValue,"...")
      const scrollToBottom = () => {
        if (chatContainerRef.current) {
          chatContainerRef.current.scrollTop =
            chatContainerRef.current.scrollHeight;
        }
      };
      useLayoutEffect(() => {
        scrollToBottom();
      }, [chatsdata, id]);

      const handleuploadImageAttachment = () => {
        document.getElementById("uploadimage").click();
      }
      const uploadimages = async (e) => {
        e.preventDefault();
        showLoader();
        if (e.target.files.length == 0) {
          dismissLoader();
          return;
        }    
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        const result = await uploadImageCommonFunction(file, file.name);        
        const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
        const maxSizeInMB = 5;
        const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
        const documenturl = result.name;
    
        if (result) {

          var fileSize = file.size / 1024;
          var docSize = fileSize.toFixed(2)
          if (file.size > maxSizeInBytes) {
            showErrorToast("File size exceeds 5MB. Please select a smaller file.");
          } else if (!allowedTypes.includes(file.type)) {
            showErrorToast("Invalid file type. Please select a PNG, JPG or JPEG");
          } else {
            
            try {
              const result = await AddMessage({
                doctorid,
                patientid:patientid,
                ispatient: "0",
                chattype:"image",
                chat: documenturl,
              });
              if (result != null) {
                fetchchatdata(patientid);
                setDoctorMessage("");
                lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
              } else {
              }
              //dismissLoader()
            } catch (error) {
              console.error("Error in home:", error);
            }

    
          }
        }
        e.target.value = '';
        dismissLoader();
    
      };

      const handleuploadDocumentAttachment = () => {
        document.getElementById("uploaddocument").click();
      }
      const uploaddocument = async (e) => {
        e.preventDefault();
        showLoader();
        if (e.target.files.length == 0) {
          dismissLoader();
          return;
        }    
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        const result = await uploadImageCommonFunction(file, file.name);        
        const allowedTypes = ["application/pdf"];
        const maxSizeInMB = 5;
        const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
        const documenturl = result.name;
    
        if (result) {

          var fileSize = file.size / 1024;
          var docSize = fileSize.toFixed(2)
          if (file.size > maxSizeInBytes) {
            showErrorToast("File size exceeds 5MB. Please select a smaller file.");
          } else if (!allowedTypes.includes(file.type)) {
            showErrorToast("Invalid file type. Please select a PNG, JPG or JPEG");
          } else {
            
            try {
              const result = await AddMessage({
                doctorid,
                patientid:patientid,
                ispatient: "0",
                chattype:"document",
                chat: documenturl,
              });
              if (result != null) {
                fetchchatdata(patientid);
                setDoctorMessage("");
                lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
              } else {
              }
              //dismissLoader()
            } catch (error) {
              console.error("Error in home:", error);
            }

    
          }
        }
        e.target.value = '';
        dismissLoader();
    
      };
      const handleFocus = () => {
        setIsFocused(true);
      };
    
      const handleBlur = () => {
        if (messageRef.current) {
          const value = messageRef.current.textContent;
          setIsEmpty(value.trim() === '');
        }
        setIsFocused(false);
      };
      const getIconForExtension = (filename) => {
        // const basename = filename.substring(filename.lastIndexOf('/') + 1);
        const basename = filename? filename.substring(filename.lastIndexOf('/') + 1) : '';
        const extension = basename.slice(basename.lastIndexOf('.') + 1).toLowerCase();
        
        switch (extension) {
          case 'png':
            return '/assets/imgs/png-icon.png';
          case 'jpg':
          case 'jpeg':
            return '/assets/imgs/jpg-icon.png';
          default:
            return '/assets/svgs/pdf-icon.svg'; 
        }
      } 
      
      const calculateYearsDifference = (start, end) => {
        const startDate = new Date(start);
        const endDate = new Date(end);
      
        const diffInMs = endDate - startDate;

        const msInYear = 1000 * 60 * 60 * 24 * 365.25;
        const diffInYears = diffInMs / msInYear;
      
        return diffInYears.toFixed(0);
      };

      const calculateDaysAgo = (dateString) => {
        const currentDate = new Date();
        const givenDate = new Date(dateString);
      
        const differenceInMilliseconds = currentDate - givenDate;
      
        const currentDayStart = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
        const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
      
        return differenceInDays;
      };
      
      const formatDay = (dateString) => {
        const daysAgo = calculateDaysAgo(dateString);
        // return `${daysAgo} days ago`;
        if (daysAgo === 0) {
          return 'Just now';
        } else {
          return `${daysAgo} days ago`;
        }
      };

      const formatDateTime = (dateTimeString) => {
        const date = new Date(dateTimeString);
      
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
      
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
      
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
      
        const formattedTime = hours + ':' + formattedMinutes + ' ' + ampm;
        return formattedTime;
      };


      const formatDateAndTime = (dateTimeString) => {
        const date = new Date(dateTimeString);
        
        const formattedTime = formatDateTime(dateTimeString);
        return ` ${formattedTime}`;
      };

      const handleConfirmCancel = async () => {
        // console.log("Visit Canceled");
        const inputForStart = {
          input: {
            doctorid,
            patientid: patientid,
            appointmentid: ChatHeader && ChatHeader.appointmentid,
            reason: "abc", // CHANGE IT -> TO an input box
            completed: "2",
          },
        };
        try {
          const result = await UpdateAppointmentByDoctor(inputForStart.input);
          cancelVisit.current.closeModal();
          manageModel.current.closeModal();
          fetchPatients();
        } catch (error) {
          console.error("Error updating appointment status:", error);
        }
      };
      const handleButtonClick = () => {
        // const url = `/startvisit?doctorId=${doctorid}&patientId=${patientid}&appointmentId=${ChatHeader && ChatHeader.appointmentid}`;
        const url = `/visitdetails?doctorId=${doctorid}&patientId=${patientid}&appointmentId=${ChatHeader && ChatHeader.appointmentid}`;
        window.location.href = url;
      };
      // const renderDate = (date) => {
      //   if (moment(date).isSame(moment(), 'day')) return "Today";
      //   if (moment(date).isSame(moment().subtract(1, 'days'), 'day')) return "Yesterday";
      //   return moment(date).format('DD/MM/YYYY');
      // };
      const renderDate = (date) => {
        const messageDate = moment(date);
        const currentDate = moment();
        
        if (messageDate.isSame(currentDate, 'day')) {
            return "Today";
        }

        if (messageDate.isSame(currentDate.subtract(1, 'days'), 'day')) {
            return "Yesterday";
        }
    
        return messageDate.format('DD/MM/YYYY');
    };
    

      function parseDateString(dateString) {
        let dateParts = "";
        if(dateString){
          dateParts = dateString.replace(/[\.,]/g, '').split(' ');
        }
        if (dateParts.length === 3) {
          const day = parseInt(dateParts[0]);
          const month = dateParts[1];
          const year = parseInt(dateParts[2]);
      
          const monthIndex = new Date(`${month} 1, ${year}`).getMonth() + 1;
          const formattedDate = `${year}-${monthIndex < 10 ? '0' + monthIndex : monthIndex}-${day < 10 ? '0' + day : day}`;
          return formattedDate;
        }
        return dateString;
      }
  
      function calculateAge(dateOfBirth) {
        const parsedDate = parseDateString(dateOfBirth);
          const today = new Date();
          const dob = new Date(parsedDate);
          
          if (isNaN(dob)) {
            console.error('Invalid date format:', parsedDate);
            return NaN;
          }
  
          let age = today.getFullYear() - dob.getFullYear();
          const monthDiff = today.getMonth() - dob.getMonth();
        
          if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
            age--;
          }
          return age;
      }
  
      const generateDynamicText = (item) => {
        let outputTexts = [];
        let genderToUse = data && data.patientinfo && data.patientinfo.gender;
        let age = calculateAge(data.patientinfo && data.patientinfo.dob);
      
        let attributes = {};
      
        // Parse the item details to extract attributes
        if (item.details && Array.isArray(item.details)) {
          item.details.forEach(attribute => {
            switch (attribute.attribute) {
              case "Site":
                attributes.site = attribute.values.map(v => v.value).join(', ');
                break;
              case "Onset":
                attributes.onset = attribute.value;
                break;
              case "Chronicity":
                attributes.chronicity = attribute.value;
                break;
              case "Onset Quality":
                attributes.onsetQuality = attribute.value;
                break;
              case "Frequency":
                attributes.frequency = attribute.value;
                break;
              case "Associated Symptoms":
                attributes.symptoms = attribute.values.map(v => v.value).join(', ');
                break;
              case "Rating Scale":
                attributes.ratingScale = attribute.value;
                break;
              case "Time":
                attributes.time = attribute.value;
                break;
              case "Time Value":
                attributes.timeValue = attribute.value;
                break;
              default:
                break;
            }
          });
        }
      
        // Generate the patient introductory text
        let patientText = `The patient is a ${isNaN(age) ? '' : age}-year-old individual (${genderToUse}) `;
        let template = `${patientText}`;
      
        // Add each attribute's sentence only if the data is available
        if (attributes.site) {
          template += `presenting with ${attributes.site} ${item.type}. `;
        }
      
        if (attributes.onset) {
          template += `The ${item.type} started ${attributes.onset} and `;
          if (attributes.chronicity) {
            template += `is described as ${attributes.chronicity} in nature. `;
          }
        }
      
        if (attributes.onsetQuality) {
          template += `The onset of the ${item.type} was ${attributes.onsetQuality}. `;
        }
      
        if (attributes.frequency) {
          template += `The ${item.type} is ${attributes.frequency} and `;
        }
      
        if (attributes.ratingScale) {
          template += `is rated as ${attributes.ratingScale} out of 10 in intensity. `;
        }
      
        if (attributes.symptoms) {
          template += `Associated with ${attributes.symptoms}.`;
        }
      
        // Push the generated template to the outputTexts array
        outputTexts.push(template.trim());
      
        return outputTexts;
      };
      
  
      const groupByBookingId = (data) => {
        if (!Array.isArray(data)) {
          return [];
        }
      
        const grouped = data.reduce((acc, item) => {
          if (!acc[item.bookingid]) {
            acc[item.bookingid] = {
              doctorname: item.doctorname,
              bookingid: item.bookingid,
              specialization: item.specialization,
              appointmentdate: item.appointmentdate,
              details: []
            };
          }
          acc[item.bookingid].details.push(item);
          return acc;
        }, {});
      
        return Object.values(grouped);
      };
      const historyOfPresentIllness = Array.isArray(data?.historyofpresentillness) ? data.historyofpresentillness : [];

      const groupedData = groupByBookingId(historyOfPresentIllness);
      // const groupedData = groupByBookingId(data.historyofpresentillness);
      
      const goBackToDoctorList = () => {
        setIsDoctorListVisible(true);
      }

      const formatDate_yyyy = (dateString) => {
        if (!dateString) return '-';
        return moment(dateString).format("yyyy");
      };
      const RequestpaymentForPatient = async () => {
        let input = {
          "patientid":patientid,
          "doctorid":"",
          "paymentrequested":"1",
          "sessionstatus":"0"
        }
        showLoader();
        try {
          const result = await requestPayment(input);   
          requestpaymentmodal.current.closeModal();   
          dismissLoader();
          fetchchatdata(patientid);
          
      } catch (error) {
        console.error("Error in home:", error);
      }
      };
      const DisableChat = async ()=>{
        let input = {
          "patientid":patientid,
          "doctorid":"",
          "paymentrequested":"0",
          "sessionstatus":"0"
        }
        showLoader();
        try {
          const result = await requestPayment(input);   
          disablechatmodal.current.closeModal();   
          dismissLoader();
          fetchchatdata(patientid);
          
        } catch (error) {
          console.error("Error in home:", error);
        }
      }

      const scrollRef = useRef(null);

      // Mouse dragging logic
      const isDragging = useRef(false);
      const startX = useRef(0);
      const scrollLeft = useRef(0);

      const handleMouseDown = (event) => {
        isDragging.current = true;
        startX.current = event.pageX - scrollRef.current.offsetLeft;
        scrollLeft.current = scrollRef.current.scrollLeft;
      };

      const handleMouseLeave = () => {
        isDragging.current = false;
      };

      const handleMouseUp = () => {
        isDragging.current = false;
      };

      const handleMouseMove = (event) => {
        if (!isDragging.current) return;
        event.preventDefault();
        const x = event.pageX - scrollRef.current.offsetLeft;
        const walk = (x - startX.current) * 1.5; // The multiplier increases the drag speed
        scrollRef.current.scrollLeft = scrollLeft.current - walk;
      };
  return (
    <>
     <Global
        styles={css`
          .activeTab .whiteBorder::after {
            content: '';
            position: absolute;
            left: 0%;
            transform: translateX(-20%);
            transform: translateY(440%);
            border-radius: 5px 5px 0 0;
            top: 70%;
            background: #fbfbfc;
            height: 3px;
            width: 100%;
            z-index: 1;
          }

          .mobileBtn {
            overflow: auto;
            white-space: nowrap;
            -ms-overflow-style: none;
            scrollbar-width: none;
          }

          .mobileBtn::-webkit-scrollbar {
            display: none;
          }

          .buttonContainer {
           white-space: nowrap; 
           scrollbar-width: none;
          }

          .buttonContainer::-webkit-scrollbar {
            display: none;
          }
        `}
      />
      <Box w="100%" display={{base: "none", lg: "block"}}>
        <Flex>
          <Box w="6rem">
            <MiniSideBar />
          </Box>
          {isLoading && <Loader />}
          <Box w="100%">
            <SecondaryNav customHeader="Messages" />
            {/* Main container start */}
            {patientsdata && patientsdata != null ? (
              <Flex w="calc(100vw - 6rem)">
                <Box
                  // p="1.5rem 8.25rem"
                  display="flex"
                  // h="calc(100vh - 50px)"
                  // overflow="hidden auto"
                  w="100%"
                >
                  {/* Left area start */}
                  <Box w={{lg: "15rem", xl: "20rem"}} h="100vh" borderRight="1px solid #E6E7E9">
                    <Box w="100%" p={{lg: "1rem", xl: "1.5rem"}} borderBottom="1px solid #E6E7E9">
                      <SearchBar
                        passInputPlace="Search"
                        onChange={(event) => setSearch(event.target.value)}
                      />
                    </Box>
                    <VStack
                      bg="#fcfcfd"
                      gap="0"
                      flexDir="column"
                      as="aside"
                      align="center"
                      h="calc(100vh - 160px)"
                      overflow="hidden auto"
                    >
                     {patientsdata && patientsdata.length > 0 ? (
                        patientsdata.filter(step => 
                            step.patientname.toLowerCase().includes(search.toLowerCase())
                        ).length > 0 ? (
                            patientsdata.filter(step => 
                                step.patientname.toLowerCase().includes(search.toLowerCase())
                            ).map((step, index) => (
                            <Flex
                              key={index}
                              w="100%"
                              bg={step.patientid == activechatbox? "#E6E7E9": "white"}
                              onClick={() => openchat(step, "current")}
                              as="button"
                              flexDir="column"
                              p="20px 32px"
                              borderBottom="1px solid #E6E7E9"
                            >
                              <Box display="flex" w="100%">
                                {/* <Image w="44px" h="44px" src={step.patientimage? step.imagepath + step.patientimage:"/assets/imgs/no-image.png"} borderRadius="full" /> */}
                                <Box boxSize="2.75rem">
                                  <Image
                                    boxSize="100%"
                                    h="40px"
                                    objectFit="cover"
                                    src={
                                      step.patientimage
                                        ? step.imagepath + step.patientimage
                                        : "/assets/imgs/no-image.png"
                                    }
                                    borderRadius="full"
                                  />
                                </Box>
                                <Flex
                                  gap="10px"
                                  justifyContent="space-between"
                                  w="100%"
                                  alignItems="start"
                                >
                                  <Box pl="10px" w="80%" textAlign="left">
                                    <Box
                                      as="h2"
                                      className="textOverflow"
                                      color="#061027"
                                      fontSize="1rem"
                                      fontWeight="600"
                                    >
                                      {step.patientname
                                        ? step.patientname
                                        : "-"}
                                    </Box>
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      gap="0.25rem"
                                    >
                                      <Image src="/assets/svgs/home-calendar.svg" />
                                      <Text
                                        color="#384052"
                                        fontSize="0.75rem"
                                        textAlign="left"
                                        fontWeight="500"
                                      >
                                        {formatDate(step.appointmentdate)}
                                      </Text>
                                    </Box>
                                  </Box>
                                  {step.countofunreadmessages > 0 && (
                                    <Box>
                                      <Text
                                        bg="#0B0B0D"
                                        p="3px 6px"
                                        w="30px"
                                        h="20px"
                                        color="white"
                                        textAlign="center"
                                        fontSize="10px"
                                        fontWeight="600"
                                        borderRadius="full"
                                      >
                                        {step.countofunreadmessages > 0
                                          ? step.countofunreadmessages
                                          : ""}
                                      </Text>
                                    </Box>
                                  )}
                                </Flex>
                              </Box>
                              <Box w="90%">
                                <Text
                                  pt="10px"
                                  fontSize="14px"
                                  fontWeight="500"
                                  textAlign="left"
                                  className="textOverflow"
                                  title={step.chiefcomplaint
                                    ? step.chiefcomplaint
                                    : "-"}
                                >
                                  {step.chiefcomplaint
                                    ? step.chiefcomplaint
                                    : "-"}
                                </Text>
                              </Box>
                            </Flex>
                        ))
                      ) : (
                          <>
                          <Box display="flex" justifyContent="center" alignItems="center" h="calc(100vh - 160px)" flexDir="column" gap="1rem">
                              <Image src="/assets/svgs/no-patient.svg" />
                              <Text fontSize="1rem" fontWeight="500" color="#6A707D">No patient found</Text>
                          </Box>
                          </>
                      )
                  ) : (
                      <>
                          <Image src="/assets/svgs/no-patient.svg" />
                          <Text fontSize="1rem" fontWeight="500" color="#6A707D">No patient found</Text>
                      </>
                  )}
                    </VStack>
                  </Box>
                  {/* Left area end */}

                  {/* Main container start */}
                  <Box w={{lg: "calc(100vw - 38rem)", xl: "calc(100vw - 46rem)"}} bg="#ffffff" overflow="hidden">
                    <Box
                      flexDir="column"
                      bg="#fcfcfd"
                      w="100%"
                      overflow="hidden"
                    >
                      <Flex flexDir="column" h="calc(100vh - 75px)">
                        <Box
                          pos="sticky"
                          top="0"
                          p="1.5rem"
                          display="flex"
                          justifyContent="center"
                          borderBottom="1px solid #E6E7E9"
                          bg="white"
                        >
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            w="100%"
                          >
                            <Box display="flex" flexDir="column">
                              <Box
                                as="h2"
                                cursor="pointer"
                                onClick={openchatmedia}
                                color="#061027"
                                fontSize="1rem"
                                fontWeight="600"
                              >
                                {ChatHeader && ChatHeader.patientname
                                  ? ChatHeader.patientname
                                  : "-"}
                              </Box>
                              <Box
                                display="flex"
                                alignItems="center"
                                gap="0.25rem"
                              >
                                <Image src="/assets/svgs/home-calendar.svg" />
                                <Text
                                  color="#384052"
                                  fontSize="0.75rem"
                                  textAlign="left"
                                  fontWeight="500"
                                >
                                  {/* {" "} */}
                                  {/* {formatDate(
                                    ChatHeader && ChatHeader.appointmentdate
                                  )} */}
                                  {/* {`${formatDate(ChatHeader && ChatHeader.appointmentdate)} at ${formatDateAndTime(ChatHeader && ChatHeader.appointmentdate)}`} */}
                                  {`${formatDate(ChatHeader && ChatHeader.appointmentdate)} at ${formattedAppointmentTime}`}
          
                                </Text>
                              </Box>
                            </Box>
                            <PrimaryButton
                              buttonText="Manage"
                              onClick={() => manageModel.current.openModal()}
                              variant="grayBtn"
                              // isDisabled={ChatHeader && (ChatHeader.appointmentstatus === 0 || ChatHeader.appointmentstatus === 1) || actionAccess && (!actionAccess.create || !actionAccess.update) ? true : false}
                              isDisabled={actionAccess && (!actionAccess.create || !actionAccess.update) ? true : false}
                              btnStyle={{ color: "#061027", w: "5.5rem" }}
                            />
                          </Box>
                        </Box>
                        <Box
                          ref={chatContainerRef}
                          overflowY="auto"
                          p="24px"
                          className="chat-section"
                        >
                          {chatsdata && chatsdata.map((msg, index)=> {
                            const createdDate = moment(msg.createddate);
                        const showDate = index === 0 || !moment(chatsdata[index - 1].createddate).isSame(msg.createddate, 'day');
        
                        return (
                          <React.Fragment key={index}>
                            {showDate && (
                              <Text borderRadius="full" p="4px 12px" w="90px" my="10px" textAlign='center' zIndex="9" mx="auto" pos="sticky"  top="0"  bg="white" fontSize="10px" fontWeight="600" border="1px solid #E6E7E9">
                                {renderDate(createdDate)}
                                </Text>
                              // <Text fontSize="12px" fontWeight="bold" color="#6A707D" textAlign="center" mb="4px">
                                
                              // </Text>
                            )}
                            <Stack direction="row" justifyContent={msg.ispatient && msg.ispatient === 1 ? "start" : "end"}>
                              <Box>
                                <Box 
                                  bg={msg.ispatient && msg.ispatient === 1 ? "#E6E7E980" : msg.chattype === "image" ? "" : "#061027"}
                                  maxW={msg.chattype === "image" ? "13.75rem" : "25rem"}
                                  maxH={msg.chattype === "image" ? "13.75rem" : " "}
                                  wordWrap="break-word"
                                  p={msg.chattype === "document" ? "2px" : "12px 16px"}
                                  w="fit-content"
                                  borderRadius={msg.ispatient && msg.ispatient === 1 ? "10px 10px 10px 1px" : "10px 10px 1px 10px"}
                                >
                                  {msg.chattype === "image" ? (
                                    <Card w="13rem" h="13rem" overflow="hidden" p="0" justifyContent="center">
                                      <Image src={`${msg.imagepath}${msg.chat}`} onClick={() => window.open(msg.imagepath + msg.chat, '_blank')} objectFit="cover" borderRadius={msg.ispatient && msg.ispatient === 1 ? "10px 10px 10px 1px" : "10px 10px 1px 10px"} />
                                    </Card>
                                  ) : msg.chattype === "document" ? (
                                    <Card w="285px" h="194px" overflow="hidden" p="0">
                                      <Box h="60%" mx="auto">
                                        <Image src="/assets/imgs/pdf-image.jpeg" alt="Document" onClick={() => window.open(msg.imagepath + msg.chat, '_blank')} cursor="pointer" w="140px" h="125px" />
                                      </Box>
                                      <CardFooter h="40%" justify="space-between" bg={msg.ispatient && msg.ispatient === 1 ? "#EBECED" : "#061027"} flexWrap="wrap">
                                        <Box display="flex" w="100%" justifyContent="space-between" alignItems="center">
                                          <Box>
                                            <Text fontSize="14px" fontWeight="600" color={msg.ispatient && msg.ispatient === 1 ? "#384052" : "#E6E7E9"}>Document.pdf</Text>

                                          </Box>
                                          <Box cursor="pointer" onClick={() => downloadDocument(msg.imagepath + msg.chat, "Document.pdf")} boxSize="36px" borderRadius="full" display="flex" justifyContent="center" alignItems="center" bg={msg.ispatient && msg.ispatient === 1 ? "white" : "#0B1F4F"}>

                                            <Image src={msg.ispatient && msg.ispatient === 1 ? "/assets/imgs/download.png" : "/assets/imgs/download-white.png"} />
                                          </Box>
                                        </Box>
                                      </CardFooter>
                                    </Card>
                                  ) : (
                                    <Text color={msg.ispatient === 1 ? "#061027" : "white"} fontSize="14px" fontWeight="500">
                                      {msg.chat ? msg.chat : ""}
                                    </Text>
                                  )}
                                </Box>
                                <Box as="span" fontSize="10px" my="3px" fontWeight="500" color="#6A707D" float={msg.ispatient && msg.ispatient === 1 ? "left" : "right"}>
                                  {msg.formattedtime ? msg.formattedtime : ""}
                                </Box>
                              </Box>
                            </Stack>
                          </React.Fragment>
                        );
                        })}
                          {/* Video Call Box */}
                          {/* <Box maxW="345px" boxShadow="0px 8px 20px 0px #E6E7E966" bg="white" border="1px solid #E6E7E9" borderRadius="12px" p="16px">
                            <Flex gap="15px">
                            <Box>
                                <Image borderRadius="full" w="40px" src="/assets/imgs/no-image.png" />
                            </Box>
                            <Box display="flex" flexDir="column" textAlign="left">
                                <Text fontSize="14px" fontWeight="500" color="#061027">Join video consulation with</Text>
                                <Text fontSize="14px" fontWeight="500" color="#061027">Dr. Samantha Raulf</Text>
                                <Text fontSize="12px" fontWeight="500" color="#384052">10m 30s</Text>
                            </Box>
                            </Flex>
                            <PrimaryButton btnStyle={{m: "0", mt: "10px", boxShadow:"0px 8px 20px 0px #E6E7E966"}}  btnIcon="/assets/imgs/videocam.png" buttonText="Join call"/>
                            <PrimaryButton isDisabled={true} btnStyle={{m: "0", mt: "10px", boxShadow:"0px 8px 20px 0px #E6E7E966", _disabled: {color: "#898D97", bg: COLORS.BTN_DISABLE,_hover: {bg: COLORS.BTN_DISABLE}}}}  btnIcon="/assets/imgs/videocam.png" btnDisabledIcon="/assets/imgs/videocam-gray.png" buttonText="Call ended"/>
                        </Box> */}
                        </Box>
                        {/* <TypingLoader /> */}
                        <Spacer />
                        {/* Questions */}
                        <Box className="buttonContainer" display="flex" gap="10px" bg="none" p="5px 24px" minH='55px' overflowX="auto" overflowY='hidden' whiteSpace='nowrap' ref={scrollRef} onMouseDown={handleMouseDown} onMouseLeave={handleMouseLeave} onMouseUp={handleMouseUp}  onMouseMove={handleMouseMove}>
                          {ChatHeader && ChatHeader.appointmentstatus != 2 && (
                            <Box 
                        onClick={() => navigate(`/visitdetails?patientId=${encodeURIComponent(patientid)}&appointmentId=${encodeURIComponent(ChatHeader?.appointmentid)}&openRescheduleModal=true`)}
                        disabled={ actionAccessVisits && ( !actionAccessVisits.update) ? true : false} 
                        opacity={actionAccessVisits && ( !actionAccessVisits.update) ? "0.5" : ""}
                        bg="#E6E7E9" as="button" border="1px solid #A5A5A6" borderRadius="full" p="8px 12px" whiteSpace="nowrap" color="#0B0B0D" fontSize="12px" fontWeight="600" h='35px'>Reschedule appointment</Box>
                          )}
                        
                        {/* <Box onClick={()=> navigate(`/visitdetails?patientId=${patientid}&appointmentId=${ChatHeader && ChatHeader.appointmentid}&openRescheduleModal=true`)} bg="#E6E7E9" as="button" border="1px solid #A5A5A6" borderRadius="full" p="8px 12px" whiteSpace="nowrap" color="#0B0B0D" fontSize="12px" fontWeight="600">Reschedule appointment</Box> */}
                       
                        <Box onClick={() => {navigate(`/visitdetails?patientId=${patientid}&appointmentId=${ChatHeader?.appointmentid}`);localStorage.setItem("CurrentVisitAppointmentId", ChatHeader?.appointmentid);  }} 
                        disabled={ actionAccessVisits && (!actionAccessVisits.read) ? true : false} 
                        opacity={actionAccessVisits && (!actionAccessVisits.read) ? "0.5" : ""}
                        bg="#E6E7E9" as="button" border="1px solid #A5A5A6" borderRadius="full" p="8px 12px" whiteSpace="nowrap" color="#0B0B0D" fontSize="12px" fontWeight="600" h='35px'>View booking details</Box>
                       
                        <NavLink to={`/patientprofile?id=${patientid} &page=overview`}>
                        <Box disabled={ actionAccessPatients && (!actionAccessPatients.read) ? true : false} 
                             opacity={actionAccessPatients && (!actionAccessPatients.read) ? "0.5" : ""}
                           bg="#E6E7E9" h='35px' as="button" border="1px solid #A5A5A6" borderRadius="full" p="8px 12px" whiteSpace="nowrap" color="#0B0B0D" fontSize="12px" fontWeight="600">
                          View patient profile
                          </Box></NavLink>
                        {ChatEnableDetails && ChatEnableDetails != null && ChatEnableDetails.paymentrequested != 1 &&
                          <Box onClick={()=> requestpaymentmodal.current.openModal()} bg="#E6E7E9" as="button" border="1px solid #A5A5A6" borderRadius="full" p="8px 12px" whiteSpace="nowrap" color="#0B0B0D" fontSize="12px" fontWeight="600" h='35px'>Request payment</Box>
                        }
                        {ChatEnableDetails && ChatEnableDetails != null && ChatEnableDetails.sessionstatus == 1 && ChatEnableDetails.paymentstatus == 1 &&
                          <Box onClick={()=> disablechatmodal.current.openModal()} bg="#E6E7E9" display='flex' alignItems='center' as="button" border="1px solid #A5A5A6" borderRadius="full" p="8px 12px" whiteSpace="nowrap" color="#0B0B0D" fontSize="12px" fontWeight="600" h='35px'>Disable Chat
                          <Switch pl="3" colorScheme="green" size="md" isChecked={true} onChange={()=> disablechatmodal.current.openModal()} /></Box>
                        }
                    </Box> 
                    {ChatEnableDetails && ChatEnableDetails != null && ChatEnableDetails.paymentrequested != 1 &&
                    <Box p="0px 0px 2px 20px">
                      <Text textAlign="left" fontSize="10px">*Patient chat is disabled. Please request the payment.</Text>
                    </Box>
                    } 
                    {ChatEnableDetails && ChatEnableDetails != null && ChatEnableDetails.paymentrequested == 1 && ChatEnableDetails.sessionstatus == 0 &&
                    <Box p="0px 0px 2px 24px">
                      <Text textAlign="left" fontSize="10px">*The payment is requested and pending for the patient to complete.</Text>
                    </Box>
                    } 
                        {/* Attachments */}
                        <Box
                          bg="white"
                          minH="80px"
                          maxH="7.5rem"
                          borderTop="1px solid #E6E7E9"
                        >
                          <Flex
                            w="100%"
                            p="1rem 1.25rem"
                            gap="15px"
                            justifyContent="center"
                            alignItems="center"
                            maxH="8rem"
                          >
                            <Menu>
                              {({ isOpen }) => (
                                <>
                                  <MenuButton as="button" isActive={isOpen } disabled={ actionAccess && (!actionAccess.create) ? true : false}>
                                    <Box
                                      as="button"
                                      p="12px 18px"
                                      w="3rem"
                                      h="3rem"
                                      bg="#E6E7E9"
                                      display="flex"
                                      alignItems="center"
                                      border="1px solid #A5A5A6"
                                      borderRadius="full"
                                    >
                                      <Image
                                        w="11px"
                                        opacity={actionAccess && (!actionAccess.create) ? "0.25" : ""}
                                        src={
                                          isOpen
                                            ? "/assets/svgs/close.svg"
                                            : "/assets/svgs/attach_file.svg"
                                        }
                                      />
                                    </Box>
                                  </MenuButton>
                                  <MenuList
                                    p="2px"
                                    minW="160px"
                                    mr="25px"
                                    zIndex="overlay"
                                    boxShadow="0px 8px 24px 0px #B4B4B452"
                                  >
                                    <MenuItem
                                      onClick={handleuploadDocumentAttachment}
                                      fontSize="0.875rem"
                                      fontWeight="500"
                                      color="#061027"
                                      sx={{ _hover: { bg: "#E4E4E7" } }}
                                    >
                                      <Input
                                        type="file"
                                        accept=".pdf,.txt"
                                        hidden
                                        id="uploaddocument"
                                        onChange={(e) => uploaddocument(e)}
                                      />
                                      <Box
                                        border="1px solid #E6E7E9"
                                        onClick={handleuploadDocumentAttachment}
                                        id="uploaddocument"
                                        w="1.75rem"
                                        h="1.75rem"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        mr="5px"
                                        borderRadius="full"
                                        p="5px"
                                      >
                                        <Image src="/assets/svgs/upload-document.svg" />
                                      </Box>
                                      Documents
                                    </MenuItem>

                                    <MenuItem
                                      onClick={handleuploadImageAttachment}
                                      fontSize="0.875rem"
                                      fontWeight="500"
                                      color="#061027"
                                      sx={{ _hover: { bg: "#E4E4E7" } }}
                                    >
                                      <Input
                                        type="file"
                                        accept=".png,.jpg,.jpeg"
                                        hidden
                                        id="uploadimage"
                                        onChange={(e) => uploadimages(e)}
                                      />
                                      <Box
                                        onClick={handleuploadImageAttachment}
                                        id="uploadimage"
                                        border="1px solid #E6E7E9"
                                        w="1.75rem"
                                        h="1.75rem"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        mr="5px"
                                        borderRadius="full"
                                        p="5px"
                                      >
                                        <Image src="/assets/svgs/upload-image.svg" />
                                      </Box>
                                      Images
                                    </MenuItem>
                                  </MenuList>
                                </>
                              )}
                            </Menu>
                            <Box w="100%">
                              {/* <InputGroup> */}
                      <Box tabIndex="-1" w="100%" bg="#FCFCFD" border="1px solid #E6E7E9" borderRadius="1rem" display="flex" alignItems="flex-end">
                          {/* <Box
                            key={activechatbox} // Use active chatbox ID as key
                            p={2}
                            bg="#FCFCFD"
                            borderRadius="1rem"
                            minHeight="3rem"
                            maxHeight="6.25rem"
                            overflow="hidden"
                            userSelect="text"
                            w="100%"
                            whiteSpace="pre-wrap"
                            wordBreak="break-word"
                            role="textbox"
                            contentEditable = {actionAccess && (!actionAccess.create) ? false : true}
                            spellCheck="true"
                            ref={messageRef}
                            aria-label="Type a message"
                            tabIndex={10}
                            display="flex"
                            flexDirection="column"
                            _focus={{ boxShadow: "none", outline: "none" }}
                            onKeyDown={handlemessageKeyPress}
                            onInput={handleInputChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            color={!isFocused && isEmpty ? '#888888' : '#061027'}

                          cursor={ actionAccess && (!actionAccess.create) ? "not-allowed" : "text"} // Change cursor if disabled
                           >
                             {!isFocused && isEmpty && (
                                <span style={{ pointerEvents: 'none', color: '#888888' }}>
                                  Type a message...
                                </span>
                              )}
                          </Box> */}
                          <Textarea
                            key={activechatbox}
                            ref={messageRef}
                            value={messageValue}
                            onKeyDown={handlemessageKeyPress}
                            onInput={handleInputChange}
                            className="chatInput"
                            onChange={handleInput}
                            // height={chatInputHeight}
                            minH="unset"
                            maxH="100px"
                            borderRadius="1rem"
                            overflowY="auto"
                            border="none"
                            resize="none"
                            // color={!isFocused && isEmpty ? '#888888' : '#061027'}
                            _focus={{ boxShadow: "none", outline: "none" }}
                            placeholder="Type a message..."
                            disabled={actionAccess && (!actionAccess.create ) ? true : false}
                          />
                          {/* <Box as="button"  disabled={actionAccess && (!actionAccess.create ) ? true : false} onClick={sendmessage}  
                          bg={actionAccess && (!actionAccess.create) ? "#E6E7E9" : "#0B0B0D"} w="2rem" h="2rem" borderRadius="full" 
                          position="unset" bottom="8px" right="-8px" p="10px" mr="10px" mb="8px" userSelect="none" contentEditable="false">
                            <Image src="/assets/svgs/send-button.svg" /> 
                          </Box> */}
                          <Box
                            as="button"
                            disabled={actionAccess && (!actionAccess.create ) ? true : false}
                            onClick={sendmessage}
                            bg={actionAccess && (!actionAccess.create) ? "#E6E7E9" : "#0B0B0D"}
                            w="2rem"
                            h="2rem"
                            borderRadius="full"
                            p="10px"
                            m="5px 10px 5px 5px"
                            userSelect="none"
                          >
                            <Image src="/assets/svgs/send-button.svg" />
                          </Box>
                        </Box>
                                {/* <Input
                                  value={doctormessage}
                                  onChange={(e) => messagetext(e)}
                                  onKeyPress={handlemessageKeyPress}
                                  placeholder="Message"
                                  pr="3.5rem"
                                  bg="#FCFCFD"
                                  h="48px"
                                  p="12px 18px"
                                  w="100%"
                                  borderRadius="full"
                                  sx={{
                                    _focus: { border: "1px solid #E6E7E9" },
                                  }}
                                /> */}
                                {/* <InputRightElement
                                  width="3rem"
                                  pr="1rem"
                                  h="100%"
                                > */}
                                  {/* <Box as='button' display="flex" justifyContent="center" alignItems="center">
                                <Image src="/assets/svgs/voice-icon.svg" />
                            </Box> */}

                                  {/* Send button start (for send button use this) */}
                                  {/* <Box
                                    as="button"
                                    bg="#0B0B0D"
                                    w="2rem"
                                    h="2rem"
                                    borderRadius="full"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    onClick={sendmessage}
                                  >
                                    <Image src="/assets/svgs/send-button.svg" />
                                  </Box> */}
                                  {/* Send button end */}
                                {/* </InputRightElement>
                              </InputGroup> */}
                            </Box>
                          </Flex>
                          {/* <Flex h="100%" justifyContent="center" alignItems="center" fontSize="14px" fontWeight="500" color="#898D97">
                            Chat closed
                        </Flex> */}
                        </Box>
                      </Flex>
                    </Box>
                  </Box>
                  {/* Main container end */}

                  {/* Right area start */}
                  {/* Recent Activity start */}
                  <Box w={{lg: "16rem", xl: "20rem"}} borderLeft="1px solid #E6E7E9">
                    <Box w="100%" mb="1.25rem">
                      <Box p={{lg: "1rem", xl: "1.5rem"}} borderBottom="1px solid #E6E7E9">
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          w="100%"
                        >
                          <Box display="flex" flexDir="column">
                            <Box
                              as="h2"
                              color="#061027"
                              fontSize="1rem"
                              fontWeight="600"
                            >
                              Health view
                            </Box>
                            <Box>
                              <Text
                                color="#384052"
                                fontSize="0.75rem"
                                textAlign="left"
                                fontWeight="500"
                              >
                                {/* Updated 12 days ago */}
                                Updated {data && data.patientinfo && data.patientinfo.lastupdatedate ? formatDay(data.patientinfo.lastupdatedate) : "-"}
                              </Text>
                            </Box>
                          </Box>
                          {/* <PrimaryButton buttonText="View profile" onClick={()=> doctorProfile.current.openRight()} variant="grayBtn" btnStyle={{color: "#061027", w: "6.5rem"}} /> */}

                          <PrimaryButton
                            buttonText="View profile"
                            // onClick={() => handlePageChange(patientid)}
                            onClick={handlePageChange}
                            variant="grayBtn"
                            btnStyle={{ color: "#061027", w: "6.5rem" }}
                            
                    isDisabled={actionAccessPatients && actionAccessPatients.read ? false : true }
                    opacity={actionAccessPatients && actionAccessPatients.read ? 1 : 0.5 }
                          />
                        </Box>
                      </Box>
                      <VStack
                        align="stretch"
                        overflow="hidden auto"
                        bg="#fcfcfd"
                        h="calc(100vh - 165px)"
                      >
                        <Stack
                          p={{lg: "1rem", xl: "1.5rem"}}
                          direction="column"
                          gap="0.75rem"
                          w="100%"
                        >
                          <Box display="flex" alignItems="center">
                            <Image src="/assets/svgs/name.svg" />
                            <Text
                              fontSize="0.875rem"
                              fontWeight="500"
                              ml="0.5rem"
                            >
                              {data &&
                              data.patientinfo &&
                              data.patientinfo.firstname
                                ? data.patientinfo.firstname +
                                  " " +
                                  data.patientinfo.lastname
                                : "-"}
                            </Text>
                          </Box>

                          <Box display="flex" alignItems="center">
                            <Image src="/assets/svgs/email.svg" />
                            <Text
                              fontSize="0.875rem"
                              fontWeight="500"
                              ml="0.5rem"
                            >
                              {data &&
                              data.patientinfo &&
                              data.patientinfo.email
                                ? data.patientinfo.email
                                : "-"}
                            </Text>
                          </Box>

                          <Box display="flex" alignItems="center">
                            <Image src="/assets/svgs/phone-number.svg" />
                            <Text
                              fontSize="0.875rem"
                              fontWeight="500"
                              ml="0.5rem"
                            >
                              {/* {data &&
                              data.patientinfo &&
                              data.patientinfo.mobileno
                                ? data.patientinfo.mobileno
                                : "-"} */}
                              {data &&
                              data.patientinfo &&
                              data.patientinfo.mobileno
                                ? (`${data.patientinfo.countrycode} ${"  "}${data.patientinfo.mobileno}`)
                                : "-"}
                            </Text>
                          </Box>

                          <Box display="flex" alignItems="center">
                            <Image src="/assets/svgs/gender.svg" />
                            <Text
                              fontSize="0.875rem"
                              fontWeight="500"
                              ml="0.5rem"
                            >
                              {data &&
                                data.patientinfo &&
                                data.patientinfo.gender ? data.patientinfo.gender : ('-')}
                            </Text>
                          </Box>

                          <Box display="flex" alignItems="center">
                            <Image src="/assets/svgs/height.svg" />
                            <Text
                              fontSize="0.875rem"
                              fontWeight="500"
                              ml="1rem"
                            >
                              {data &&
                              data.patientinfo &&
                              data.patientinfo.height
                                ? data.patientinfo.height +
                                  " " +
                                  data.patientinfo.heightunit
                                : "-"}
                            </Text>
                          </Box>

                          <Box display="flex" alignItems="center">
                            <Image src="/assets/svgs/weight.svg" />
                            <Text
                              fontSize="0.875rem"
                              fontWeight="500"
                              ml="0.5rem"
                            >
                              {data &&
                              data.patientinfo &&
                              data.patientinfo.weight
                                ? data.patientinfo.weight +
                                  " " +
                                  data.patientinfo.weightunit
                                : "-"}
                            </Text>
                          </Box>

                          <Box display="flex" alignItems="center">
                            <Image src="/assets/svgs/cake.svg" />
                            <Text
                              fontSize="0.875rem"
                              fontWeight="500"
                              ml="0.5rem"
                            >
                              {data && data.patientinfo && data.patientinfo.dob !=null
                                ? data.patientinfo.dob
                                : "-"}
                            </Text>
                          </Box>

                          <Box display="flex" alignItems="center">
                            <Image src="/assets/svgs/name.svg" />
                            <Text
                              fontSize="0.875rem"
                              fontWeight="500"
                              ml="0.5rem"
                            >
                              {data &&
                              data.patientinfo &&
                              data.patientinfo.uniqueid
                                ? data.patientinfo.uniqueid 
                                : "-"}
                            </Text>
                          </Box>
                        </Stack>

                        <Stack spacing={0}>
                          {/* previous code */}
                          {/* <ToggleTab title="Visits"
                                               body={<>
                                                  <Box w="100%" h="100%" p="0">
                                                    <Stepper
                                                      orientation="vertical"
                                                      height="400px"
                                                      size="xs"
                                                      colorScheme="#CDCFD4"
                                                      gap="0"
                                                      w="100%"
                                                      h="100%"
                                                    >
                                                        <Step w="100%" pb="1rem">
                                                          <StepIndicator color="#E6E7E9" />

                                                          <Box flexShrink="0" w="95%">
                                                            <StepTitle fontSize="0.875rem" color="#384052" mb="0.5rem">
                                                              10th Jan, 2024
                                                            </StepTitle>
                                                            <StepDescription
                                                              bg="white"
                                                              w="100%"
                                                              border="1px solid #E6E7E9"
                                                              borderRadius="0.65rem"
                                                              p="0.75rem"
                                                            >
                                                              <Box
                                                                display="flex"
                                                                justifyContent="space-between"
                                                                alignItems="center"
                                                                p="0.5rem"
                                                                pl="0"
                                                              >
                                                                <Text fontSize="0.875rem" color="#061027">
                                                                  Visited general physician
                                                                </Text>
                                                                <Image src="/assets/imgs/right-icon.png" />
                                                              </Box>
                                                              <Box bg="#F9FAFB" borderLeft="2px solid #14C585" p="0.5rem">
                                                                <Text fontSize="0.875rem" fontWeight="500">
                                                                  Reason: Headache, stomach ache, coughing and vomiting
                                                                </Text>
                                                              </Box>
                                                              <Box display="flex" p="0.5rem" pl="0" alignItems="center">
                                                                <Image w="0.75rem" h="auto" src="/assets/svgs/update.svg" />
                                                                <Text
                                                                  fontSize="0.875rem"
                                                                  fontWeight="500"
                                                                  color="#6A707D"
                                                                  pl="0.5rem"
                                                                >
                                                                  Updated: HPI, ROS, Vitals and Images
                                                                </Text>
                                                              </Box>
                                                            </StepDescription>
                                                          </Box>

                                                          <StepSeparator colorScheme="red" />
                                                        </Step>
                                                        <Step w="100%" pb="1rem">
                                                          <StepIndicator color="#E6E7E9" />

                                                          <Box flexShrink="0" w="95%">
                                                            <StepTitle fontSize="0.875rem" color="#384052" mb="0.5rem">
                                                              10th Jan, 2024
                                                            </StepTitle>
                                                            <StepDescription
                                                              bg="white"
                                                              w="100%"
                                                              border="1px solid #E6E7E9"
                                                              borderRadius="0.65rem"
                                                              p="0.75rem"
                                                            >
                                                              <Box
                                                                display="flex"
                                                                justifyContent="space-between"
                                                                alignItems="center"
                                                                p="0.5rem"
                                                                pl="0"
                                                              >
                                                                <Text fontSize="0.875rem" color="#061027">
                                                                  Visited general physician
                                                                </Text>
                                                                <Image src="/assets/imgs/right-icon.png" />
                                                              </Box>
                                                              <Box bg="#F9FAFB" borderLeft="2px solid #14C585" p="0.5rem">
                                                                <Text fontSize="0.875rem" fontWeight="500">
                                                                  Reason: Headache, stomach ache, coughing and vomiting
                                                                </Text>
                                                              </Box>
                                                              <Box display="flex" p="0.5rem" pl="0" alignItems="center">
                                                                <Image w="0.75rem" h="auto" src="/assets/svgs/update.svg" />
                                                                <Text
                                                                  fontSize="0.875rem"
                                                                  fontWeight="500"
                                                                  color="#6A707D"
                                                                  pl="0.5rem"
                                                                >
                                                                  Updated: HPI, ROS, Vitals and Images
                                                                </Text>
                                                              </Box>
                                                            </StepDescription>
                                                          </Box>

                                                          <StepSeparator colorScheme="red" />
                                                        </Step>
                                                    </Stepper>
                                                  </Box>
                                                    <PrimaryButton variant="grayBtnFull" btnStyle={{mt: "1rem", mb: "0.5rem"}} buttonText="View all" />
                                                </>}
                                            /> */}
                          <ToggleTab
                            title="Visits"
                            body={
                              <>
                                {data &&
                                data.Previousvisits &&
                                data.Previousvisits.length > 0 ? (
                                  <Box w="100%" h="100%" p="0">
                                    <Stepper
                                      orientation="vertical"
                                      height="400px"
                                      size="xs"
                                      colorScheme="#CDCFD4"
                                      gap="0"
                                      w="100%"
                                      h="100%"
                                    >
                                      {data.Previousvisits.map(
                                        (visit, index) => (
                                          <Step key={index} w="100%" pb="1rem">
                                            <StepIndicator color="#E6E7E9" />

                                            <Box flexShrink="0" w="95%">
                                              {/* <StepTitle
                                                fontSize="0.875rem"
                                                color="#384052"
                                                mb="0.5rem"
                                              >
                                                {formatDate(
                                                  visit.appointmentdate
                                                )}
                                              </StepTitle> */}
                                              <StepTitle fontSize='0.875rem' color='#384052' mb="0.5rem" >
                                              <Box display='flex' justifyContent='space-between'>
                    <Text fontWeight='600' fontSize="0.875rem" color="#384052" mb="0.5rem">{formatDate(visit.appointmentdate)}</Text>
                    <Box w='50px' h='25px' border='1px solid #CDCFD4' borderRadius='20px' textAlign='center'>
                    <Text 
                    as='button' 
                    fontWeight='600' 
                    fontSize="0.875rem" 
                    color="#384052"
                    onClick={() => handleClick(visit.appointmentid)}
                    disabled={actionAccessVisits && actionAccessVisits.read ? false : true }
                    opacity={actionAccessVisits && actionAccessVisits.read ? 1 : 0.5 }
                    >View</Text>
                    </Box>
                  </Box>
                    <Text fontWeight='600' fontSize="0.875rem" color="#384052" mb="0.5rem">{`${visit.doctorname ? visit.doctorname : ('-')} , ${visit.bookingid? visit.bookingid : ('-')}`}</Text>
                    <Text fontSize="0.875rem" color="#384052" mb="0.5rem">{visit.specality}</Text>
                    
                  </StepTitle>
                                              <StepDescription
                                                bg="white"
                                                w="100%"
                                                border="1px solid #E6E7E9"
                                                borderRadius="0.65rem"
                                                p="0.75rem"
                                              >
                                                {/* <Box
                                                  display="flex"
                                                  justifyContent="space-between"
                                                  alignItems="center"
                                                  p="0.5rem"
                                                  pl="0"
                                                >
                                                  <Text
                                                    fontSize="0.875rem"
                                                    color="#061027"
                                                  >
                                                    {visit.specality
                                                      ? `Visited ${visit.specality}`
                                                      : "Visited general physician"}
                                                  </Text>
                                                  <Image src="/assets/imgs/right-icon.png" />
                                                </Box> */}
                                                <Box
                                                  bg="#F9FAFB"
                                                  borderLeft="2px solid #14C585"
                                                  p="0.5rem"
                                                >
                                                  <Text
                                                    fontSize="0.875rem"
                                                    fontWeight="500"
                                                  >
                                                    Reason:{" "}
                                                    {visit.chiefcomplaint}
                                                  </Text>
                                                </Box>
                                                <Box
                                                  display="flex"
                                                  p="0.5rem"
                                                  pl="0"
                                                  alignItems="center"
                                                >
                                                  <Image
                                                    w="0.75rem"
                                                    h="auto"
                                                    src="/assets/svgs/update.svg"
                                                  />
                                                  <Text
                                                    fontSize="0.875rem"
                                                    fontWeight="500"
                                                    color="#6A707D"
                                                    pl="0.5rem"
                                                  >
                                                    Updated: {formatDate(visit.appointmentdate)}
                                                  </Text>
                                                </Box>
                                              </StepDescription>
                                            </Box>

                                            <StepSeparator colorScheme="red" />
                                          </Step>
                                        )
                                      )}
                                    </Stepper>
                                  </Box>
                                ) : (
                                  <Text>No records found</Text>
                                )}
                                {data && data.Previousvisits.length > 0 && (
                                  <PrimaryButton
                                    variant="grayBtnFull"
                                    btnStyle={{ my: "0.5rem" }}
                                    buttonText="View all"
                                    onClick={() => {
                                      navigate("/patientprofile?id="+ patientid + "&page=visits")
                                    }} 
                                    
                    isDisabled={actionAccessPatients && actionAccessPatients.read ? false : true }
                    opacity={actionAccessPatients && actionAccessPatients.read ? 1 : 0.5 }
                                  />
                                )}
                              </>
                            }
                          />

                          {/* <ToggleTab
                            title="chief complaint"
                            body={
                              <>
                                {data &&
                                data.chiefcomplaint &&
                                data.chiefcomplaint.length > 0 ? (
                                  data.chiefcomplaint.map((complaint,index) => (
                                  <Box key={index}>
                                    <Text
                                      fontSize="0.875rem"
                                      fontWeight="500"
                                      color="0.875rem"
                                    >
                                      {complaint.date ? (formatDate(complaint.date)) : ('-')}
                                    </Text>
                                    <Text
                                      fontSize="0.875rem"
                                      fontWeight="500"
                                      color="0.875rem"
                                    >
                                      {complaint.doctorname ? (complaint.doctorname) : ('-')}
                                    </Text>
                                    <Text
                                      fontSize="0.875rem"
                                      fontWeight="500"
                                      color="0.875rem"
                                    >
                                      {complaint.chiefcomplaint ? (complaint.chiefcomplaint) : ('-')}
                                    </Text>
                                  </Box>
                                  ))
                                ) : (
                                  "No records found"
                                )}
                                {data && data.chiefcomplaint.length > 0 && (
                                  <PrimaryButton
                                    variant="grayBtnFull"
                                    btnStyle={{ my: "0.5rem" }}
                                    buttonText="View all"
                                    onClick={() => {
                                      navigate("/patientprofile?id="+ patientid + "&page=chiefComplaint")
                                    }} 
                                  />
                                )}
                              </>
                            }
                          /> */}

<ToggleTab
                            title="chief complaint"
                            body={
                              <>
                                {data &&
                                data.chiefcomplaint &&
                                data.chiefcomplaint.length > 0 ? (
                                  <Box w="100%" h="100%" p="0">
                                    <Stepper
                                      orientation="vertical"
                                      height="400px"
                                      size="xs"
                                      colorScheme="#CDCFD4"
                                      gap="0"
                                      w="100%"
                                      h="100%"
                                    >
                                      {data.chiefcomplaint.map(
                                        (complaint, index) => (
                                          <Step key={index} w="100%" pb="1rem">
                                            <StepIndicator color="#E6E7E9" />

                                            <Box flexShrink="0" w="95%">
                                              <StepTitle
                                                fontSize="0.875rem"
                                                color="#384052"
                                                mb="0.5rem"
                                              >
                                                {/* {complaint.date ? (formatDate(complaint.date)) : ('-')} */}
                                                <Text fontWeight='600' fontSize="0.875rem" color="#384052" mb="0.5rem">{formatDate(complaint.date)}</Text>
                    <Text fontWeight='600' fontSize="0.875rem" color="#384052" mb="0.5rem">{`${complaint.doctorname} , ${complaint.bookingid}`}</Text>
                    <Text fontSize="0.875rem" color="#384052" mb="0.5rem">{complaint.specialization}</Text>
                                              </StepTitle>
                                              <StepDescription
                                                bg="white"
                                                w="100%"
                                                border="1px solid #E6E7E9"
                                                borderRadius="0.65rem"
                                                p="0.75rem"
                                              >
                                                {/* <Box
                                                  display="flex"
                                                  justifyContent="space-between"
                                                  alignItems="center"
                                                  p="0.5rem"
                                                  pl="0"
                                                >
                                                  <Text
                                                    fontSize="0.875rem"
                                                    color="#061027"
                                                  >
                                                    {complaint.doctorname ? (complaint.doctorname) : ('-')}
                                                  </Text>
                                                </Box> */}
                                                <Box
                                                  bg="#F9FAFB"
                                                  borderLeft="2px solid #14C585"
                                                  p="0.5rem"
                                                >
                                                  <Text
                                                    fontSize="0.875rem"
                                                    fontWeight="500"
                                                  >
                                                    {complaint.chiefcomplaint ? (complaint.chiefcomplaint) : ('-')}
                                                  </Text>
                                                </Box>
                                                
                                              </StepDescription>
                                            </Box>

                                            <StepSeparator colorScheme="red" />
                                          </Step>
                                        )
                                      )}
                                    </Stepper>
                                  </Box>
                                ) : (
                                  <Text>No records found</Text>
                                )}
                                {data && data.chiefcomplaint.length > 0 && (
                                  <PrimaryButton
                                    variant="grayBtnFull"
                                    btnStyle={{ my: "0.5rem" }}
                                    buttonText="View all"
                                    onClick={() => {
                                      navigate("/patientprofile?id="+ patientid + "&page=chiefComplaint")
                                    }} 
                                    
                    isDisabled={actionAccessPatients && actionAccessPatients.read ? false : true }
                    opacity={actionAccessPatients && actionAccessPatients.read ? 1 : 0.5 }
                                  />
                                )}
                              </>
                            }
                          />

<ToggleTab
  title="history of present illness (HPI)"
  body={
    <>
      {data && data.historyofpresentillness && data.historyofpresentillness.length > 0 ? (
        <Box w="100%" h="100%" p="0">
          {groupedData.map((doctor, index) => (
            <Box key={index} bg="#F9FAFB" borderLeft="2px solid #14C585" p="0.5rem" mb="1.25rem">
              <Text fontSize="1rem" fontWeight="600">
                {formatDate(doctor.appointmentdate)}
              </Text>
              <Text fontSize="1rem" fontWeight="600">
                {doctor.doctorname ? doctor.doctorname : '-'}, {doctor.bookingid ? doctor.bookingid : '-'}
              </Text>
              <Text fontSize="0.875rem" fontWeight="500">
                {doctor.specialization ? doctor.specialization : '-'}
              </Text>
              <br />

              {doctor.details.map((detail, detailIndex) => (
                <Box bg="white" padding="0.5rem" key={detailIndex}>
                  <React.Fragment>
                    {generateDynamicText(detail)[0]}
                    <br />
                    <br />
                  </React.Fragment>
                </Box>
              ))}
            </Box>
          ))}
          {data.historyofpresentillness.length > 0 && (
            <PrimaryButton
              variant="grayBtnFull"
              btnStyle={{ my: '0.5rem' }}
              buttonText="View all"
              onClick={() => {
                navigate('/patientprofile?id=' + patientid + '&page=historyOfPresentIllness');
              }}
              
              isDisabled={actionAccessPatients && actionAccessPatients.read ? false : true }
              opacity={actionAccessPatients && actionAccessPatients.read ? 1 : 0.5 }
            />
          )}
        </Box>
      ) : (
        <Text>{'No records found.'}</Text>
      )}
    </>
  }
/>

                          {/* previous code */}
                          {/* <ToggleTab title="Review systems (ros)" 
                                              body={<>
                                                 <Stepper
                                                    orientation="vertical"
                                                    height="100%"
                                                    size="xs"
                                                    colorScheme="#CDCFD4"
                                                    gap="0"
                                                    w="100%"
                                                  >
                                                      <Step w="100%" >
                                                        <StepIndicator color="#E6E7E9" />
                                                        <Box flexShrink="0" w="95%">
                                                          <StepTitle fontSize="0.75rem" fontWeight="500" color="#384052" mb="0.5rem">
                                                            10th Jan, 2024
                                                          </StepTitle>
                                                          <StepDescription
                                                            bg="white"
                                                            w="100%"
                                                            border="1px solid #E6E7E9"
                                                            borderRadius="0.65rem"
                                                            p="0.75rem"
                                                            mb="1.5rem"
                                                            boxShadow="0px 8px 20px 0px #E6E7E966"
                                                          >
                                                            <Box w="fit-content" display="flex" flexDir="column" gap="0.5rem" className="textOverflow">
                                                            <Text fontSize="0.625rem" w="max-content" fontWeight="600" color="#384052" p="0.25rem 0.5rem" bg="#E6E7E966" border="1px solid #E6E7E9" borderRadius="full">Alive</Text>
                                                              <Text fontSize="0.875rem" color="#061027">
                                                              A-fib (CMS/HCC)
                                                              </Text>
                                                            </Box>
                                                          </StepDescription>
                                                        </Box>
                                          
                                                        <StepSeparator colorScheme="red" />
                                                      </Step>
                                                      <Step w="100%" >
                                                        <StepIndicator color="#E6E7E9" />
                                                        <Box flexShrink="0" w="95%">
                                                          <StepTitle fontSize="0.75rem" fontWeight="500" color="#384052" mb="0.5rem">
                                                            10th Jan, 2024
                                                          </StepTitle>
                                                          <StepDescription
                                                            bg="white"
                                                            w="100%"
                                                            border="1px solid #E6E7E9"
                                                            borderRadius="0.65rem"
                                                            p="0.75rem"
                                                            mb="1.5rem"
                                                            boxShadow="0px 8px 20px 0px #E6E7E966"
                                                          >
                                                            <Box w="fit-content" display="flex" flexDir="column" gap="0.5rem" className="textOverflow">
                                                            <Text fontSize="0.625rem" w="max-content" fontWeight="600" color="#384052" p="0.25rem 0.5rem" bg="#E6E7E966" border="1px solid #E6E7E9" borderRadius="full">Alive</Text>
                                                              <Text fontSize="0.875rem" color="#061027">
                                                              A-fib (CMS/HCC)
                                                              </Text>
                                                            </Box>
                                                          </StepDescription>
                                                        </Box>
                                          
                                                        <StepSeparator colorScheme="red" />
                                                      </Step>
                                                  </Stepper>
                                                  <PrimaryButton variant="grayBtnFull" btnStyle={{my: "0.5rem"}} buttonText="View all" />
                                              </>}
                                            /> */}
                          <ToggleTab
                            title="Review systems (ros)"
                            body={
                              <>
                                <Stepper
                                  orientation="vertical"
                                  height="100%"
                                  size="xs"
                                  colorScheme="#CDCFD4"
                                  gap="0"
                                  w="100%"
                                >
                                  {data &&
                                  data.reviewofsystem &&
                                  data.reviewofsystem.length > 0 ? (
                                    data.reviewofsystem.map((ros, index) => (
                                      <Step key={index} w="100%">
                                        <StepIndicator color="#E6E7E9" />
                                        <Box flexShrink="0" w="95%">
                                          <StepTitle
                                            fontSize="0.75rem"
                                            fontWeight="500"
                                            color="#384052"
                                            mb="0.5rem"
                                          >
                                             {/* {ros.date ? formatDate(ros.date): ('')} */}
                                             <Text fontWeight='600' fontSize="0.875rem" color="#384052" mb="0.5rem">{ros.DATE?formatDate(ros.DATE): ('-')}</Text>
                    <Text fontWeight='600' fontSize="0.875rem" color="#384052" mb="0.5rem">{`${ros.doctorname?ros.doctorname: '-'} , ${ros.bookingid?ros.bookingid: ('-')}`}</Text>
                    <Text fontSize="0.875rem" color="#384052" mb="0.5rem">{ros.specialization?ros.specialization: ('-')}</Text>
                                          </StepTitle>
                                          <StepDescription
                                            bg="white"
                                            w="100%"
                                            border="1px solid #E6E7E9"
                                            borderRadius="0.65rem"
                                            p="0.75rem"
                                            mb="1.5rem"
                                            boxShadow="0px 8px 20px 0px #E6E7E966"
                                          >
                                             <Box w="fit-content" display="flex" flexDir="column" gap="0.5rem" className="textOverflow">
                                                <Text fontSize="0.625rem" w="max-content" fontWeight="600" color="#384052" p="0.25rem 0.5rem" bg="#E6E7E966" border="1px solid #E6E7E9" borderRadius="full">{ros.rosname}</Text>
                                            {ros.rosvalue.map(
                                              (val, valueIndex) => (
                                                
                                                  
                                                  <Text
                                                    fontSize="0.875rem"
                                                    color="#061027"
                                                    key={valueIndex}
                                                  >
                                                    {val.value}
                                                  </Text>
                                           
                                              )
                                            )}
                                            </Box>
                                          </StepDescription>
                                        </Box>
                                        <StepSeparator colorScheme="red" />
                                      </Step>
                                    ))
                                  ) : (
                                    <Text>No records found</Text>
                                  )}
                                </Stepper>
                                {data && data.reviewofsystem.length > 0 && (
                                  <PrimaryButton
                                    variant="grayBtnFull"
                                    btnStyle={{ my: "0.5rem" }}
                                    buttonText="View all"
                                    onClick={() => {
                                      navigate("/patientprofile?id="+ patientid + "&page=reviewSystem")
                                    }} 
                                    
                    isDisabled={actionAccessPatients && actionAccessPatients.read ? false : true }
                    opacity={actionAccessPatients && actionAccessPatients.read ? 1 : 0.5 }
                                  />
                                )}
                              </>
                            }
                          />

                          {/* Previous code */}
                          {/* <ToggleTab title="Physical exam"
                                              body={<>
                                                <Stepper
                                                   orientation="vertical"
                                                   height="100%"
                                                   size="xs"
                                                   colorScheme="#CDCFD4"
                                                   gap="0"
                                                   w="100%"
                                                 >
                                                     <Step w="100%">
                                                       <StepIndicator color="#E6E7E9" />
                                                       <Box flexShrink="0" w="95%">
                                                         <StepTitle fontSize="0.75rem" fontWeight="500" color="#384052" mb="0.5rem">
                                                           10th Jan, 2024
                                                         </StepTitle>

                                                         <StepDescription
                                                           bg="white"
                                                           w="100%"
                                                           border="1px solid #E6E7E9"
                                                           borderRadius="0.65rem"
                                                           p="0.75rem"
                                                           mb="1.5rem"
                                                           boxShadow="0px 8px 20px 0px #E6E7E966"
                                                           >
                                                           <Box w="fit-content" display="flex" flexDir="column" gap="0.5rem" className="textOverflow">

                                                           <Text fontSize="0.625rem" w="max-content" fontWeight="600" color="#384052" p="0.25rem 0.5rem" bg="#E6E7E966" border="1px solid #E6E7E9" borderRadius="full">
                                                            Alive
                                                            </Text>
                                                             <Text fontSize="0.875rem" color="#061027">
                                                             A-fib (CMS/HCC)
                                                             </Text>
                                                           </Box>
                                                         </StepDescription>
                                                       </Box>
                                         
                                                       <StepSeparator colorScheme="red" />
                                                     </Step>
                                                 
                                                    </Stepper>
                                                    {<PrimaryButton variant="grayBtnFull" btnStyle={{my: "0.5rem"}} buttonText="View all" />}
                                             </>}
                                            /> */}
                          <ToggleTab
                            title="Physical exam"
                            body={
                              <>
                                {data && data.physicalexam.length > 0 ? (
                                  <Stepper
                                    orientation="vertical"
                                    height="100%"
                                    size="xs"
                                    colorScheme="#CDCFD4"
                                    gap="0"
                                    w="100%"
                                  >
                                    {data.physicalexam.map((exam, index) => (
                                      <Step key={index} w="100%">
                                        <StepIndicator color="#E6E7E9" />
                                        <Box flexShrink="0" w="95%">
                                          <StepTitle
                                            fontSize="0.75rem"
                                            fontWeight="500"
                                            color="#384052"
                                            mb="0.5rem"
                                          >
                                            {/* {formatDate(exam.Date)} */}
                                            <Text fontWeight='600' fontSize="0.875rem" color="#384052" mb="0.5rem">{formatDate(exam.DATE)}</Text>
                    <Text fontWeight='600' fontSize="0.875rem" color="#384052" mb="0.5rem">{`${exam.doctorname? exam.doctorname: ('-')} , ${exam.bookingid? exam.bookingid: ('-')}`}</Text>
                    <Text fontSize="0.875rem" color="#384052" mb="0.5rem">{exam.specialization? exam.specialization : ('-')}</Text>
                                          </StepTitle>
                                          <StepDescription
                                            bg="white"
                                            w="100%"
                                            border="1px solid #E6E7E9"
                                            borderRadius="0.65rem"
                                            p="0.75rem"
                                            mb="1.5rem"
                                            boxShadow="0px 8px 20px 0px #E6E7E966"
                                          >
                                            <Box
                                              w="fit-content"
                                              display="flex"
                                              flexDir="column"
                                              gap="0.5rem"
                                              className="textOverflow"
                                            >
                                              <Text
                                                fontSize="0.625rem"
                                                w="max-content"
                                                fontWeight="600"
                                                color="#384052"
                                                p="0.25rem 0.5rem"
                                                bg="#E6E7E966"
                                                border="1px solid #E6E7E9"
                                                borderRadius="full"
                                              >
                                                {exam.type}
                                              </Text>
                                              {/* <Text fontSize="0.875rem" color="#061027">A-fib (CMS/HCC)</Text> */}
                                              {exam.physicalexam.map(
                                                (item, idx) => (
                                                  <Text
                                                    key={idx}
                                                    fontSize="0.875rem"
                                                    color="#061027"
                                                    w="100px"
                                                    className="textOverflow"
                                                  >
                                                    {item.examvalue}
                                                  </Text>
                                                )
                                              )}
                                            </Box>
                                          </StepDescription>
                                        </Box>
                                        <StepSeparator colorScheme="red" />
                                      </Step>
                                    ))}
                                  </Stepper>
                                ) : (
                                  <Text>No records found</Text>
                                )}
                                {data && data.physicalexam.length > 0 && (
                                  <PrimaryButton
                                    variant="grayBtnFull"
                                    btnStyle={{ my: "0.5rem" }}
                                    buttonText="View all"
                                    onClick={() => {
                                      navigate("/patientprofile?id="+ patientid + "&page=physicalExam")
                                    }} 
                                    
                    isDisabled={actionAccessPatients && actionAccessPatients.read ? false : true }
                    opacity={actionAccessPatients && actionAccessPatients.read ? 1 : 0.5 }
                                  />
                                )}
                              </>
                            }
                          />

                          {/* <ToggleTab
                            title="doctor assessments"
                            body={
                              <>
                                {data && data.doctorassessment ? (
                                  <Box>
                                    <UnorderedList ml="0">
                                      <ListItem
                                        fontSize="14px"
                                        my="0.5rem"
                                        fontWeight="600"
                                      >
                                        Detected cancer cells in your body.
                                        {data.doctorassessment.diagnosis}
                                      </ListItem>
                                      <ListItem
                                        fontSize="14px"
                                        my="0.5rem"
                                        fontWeight="600"
                                      >
                                        You need urgent attention.
                                        {data.doctorassessment.plan}
                                      </ListItem>
                                    </UnorderedList>
                                  </Box>
                                ) : (
                                  "No records found"
                                )}
                                {data && data.doctorassessment.length > 0 && (
                                  <PrimaryButton
                                    variant="grayBtnFull"
                                    btnStyle={{ my: "0.5rem" }}
                                    buttonText="View all"
                                    onClick={() => {
                                      navigate("/patientprofile?id="+ patientid + "&page=doctorAssessment")
                                    }} 
                                  />
                                )}
                              </>
                            }
                          /> */}

                          <ToggleTab
                            title="doctor assessments"
                            body={
                              <>
                                {/* {data && data.doctorsassessment.length>0 ? ( */}
      {data && Array.isArray(data.doctorsassessment)}
                                {data && Array.isArray(data.doctorsassessment) && data.doctorsassessment.length > 0 ? (
                                  <Stepper
                                    orientation="vertical"
                                    height="400px"
                                    size="xs"
                                    colorScheme="#CDCFD4"
                                    gap="0"
                                    w="100%"
                                    h="100%"
                                  >
                                  {data.doctorsassessment.map((item,index) => (
                                    <Step w="100%" pb="1rem">
                                      <StepIndicator color="#E6E7E9" />
                                      <Box flexShrink="0" w="95%">
                                        <StepTitle
                                          fontSize="0.875rem"
                                          color="#384052"
                                          mb="0.5rem"
                                        >
                                          {/* {item.date? (formatDate(item.date)): ("-")} */}
                                          <Text fontWeight='600' fontSize="0.875rem" color="#384052" mb="0.5rem">{formatDate(item.DATE)}</Text>
                    <Text fontWeight='600' fontSize="0.875rem" color="#384052" mb="0.5rem">{`${item.doctorname? item.doctorname: ('-')} , ${item.bookingid? item.bookingid: ('-')}`}</Text>
                    <Text fontSize="0.875rem" color="#384052" mb="0.5rem">{item.specialization? item.specialization : ('-')}</Text>
                                        </StepTitle>
                                        <StepDescription
                                          bg="white"
                                          w="100%"
                                          border="1px solid #E6E7E9"
                                          boxShadow="0px 8px 20px 0px #E6E7E966"
                                          borderRadius="0.65rem"
                                          p="1.25rem"
                                        >
                                          <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            pb="0.5rem"
                                          >
                                            <Text
                                              fontSize="0.875rem"
                                              color="#061027"
                                            >
                                              {item.diagnosis? item.diagnosis:""}
                                            </Text>
                                          </Box>
                                          <Box
                                            bg="#F9FAFB"
                                            borderLeft="2px solid #14C585"
                                            p="0.5rem"
                                          >
                                            <Text
                                              fontSize="0.875rem"
                                              fontWeight="500"
                                            >
                                              Plan: {item.plan?item.plan:""}
                                            </Text>
                                          </Box>
                                        </StepDescription>
                                      </Box>
                                      <StepSeparator />
                                    </Step>
                                  ))}
                                  </Stepper>
                                ) : (
                                  <Text>No records found</Text>
                                )}
                                {/* {data && data.doctorsassessment.length > 0 && ( */}
                                {data && Array.isArray(data.doctorsassessment) && data.doctorsassessment.length > 0 &&   (
                                  <PrimaryButton
                                    variant="grayBtnFull"
                                    btnStyle={{ my: "0.5rem" }}
                                    buttonText="View all"
                                    onClick={() => {
                                      navigate("/patientprofile?id="+ patientid + "&page=doctorAssessment")
                                    }} 
                                    
                    isDisabled={actionAccessPatients && actionAccessPatients.read ? false : true }
                    opacity={actionAccessPatients && actionAccessPatients.read ? 1 : 0.5 }
                                  />
                                )}
                              </>
                            }
                          />

                          {/* Previous code */}
                          {/* <ToggleTab title="treatment plans" 
                                              body={<>
                                                <Stepper
                                                  orientation="vertical"
                                                  height="400px"
                                                  size="xs"
                                                  colorScheme="#CDCFD4"
                                                  gap="0"
                                                  w="100%"
                                                  h="100%"
                                                >
                                                    <Step w="100%" pb="1rem">
                                                      <StepIndicator color="#E6E7E9" />
                                        
                                                      <Box flexShrink="0" w="95%">
                                                        <StepTitle fontSize="0.875rem" color="#384052" mb="0.5rem">
                                                          10th Jan, 2024
                                                        </StepTitle>
                                                        <StepDescription
                                                          bg="white"
                                                          w="100%"
                                                          border="1px solid #E6E7E9"
                                                          boxShadow="0px 8px 20px 0px #E6E7E966"
                                                          borderRadius="0.65rem"
                                                          p="1.25rem"
                                                        >
                                                          <Box
                                                            display="flex"
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                            pb="0.5rem"
                                                          >
                                                            <Text fontSize="0.875rem" color="#061027">
                                                              Heart failure with preserved ejection fraction, unspecified HF chronicity (CMS/HCC)
                                                            </Text>
                                                          </Box>
                                                          <Box bg="#F9FAFB" borderLeft="2px solid #14C585" p="0.5rem">
                                                            <Text fontSize="0.875rem" fontWeight="500">
                                                              Plan: Basic metabolic panel, Magnesium
                                                            </Text>
                                                          </Box>
                                                        </StepDescription>
                                                      </Box>
                                        
                                                      <StepSeparator />
                                                    </Step>
                                                    <Step w="100%" pb="1rem">
                                                      <StepIndicator color="#E6E7E9" />
                                        
                                                      <Box flexShrink="0" w="95%">
                                                        <StepTitle fontSize="0.875rem" color="#384052" mb="0.5rem">
                                                          10th Jan, 2024
                                                        </StepTitle>
                                                        <StepDescription
                                                          bg="white"
                                                          w="100%"
                                                          border="1px solid #E6E7E9"
                                                          boxShadow="0px 8px 20px 0px #E6E7E966"
                                                          borderRadius="0.65rem"
                                                          p="1.25rem"
                                                        >
                                                          <Box
                                                            display="flex"
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                            pb="0.5rem"
                                                          >
                                                            <Text fontSize="0.875rem" color="#061027">
                                                              Heart failure with preserved ejection fraction, unspecified HF chronicity (CMS/HCC)
                                                            </Text>
                                                          </Box>
                                                          <Box bg="#F9FAFB" borderLeft="2px solid #14C585" p="0.5rem">
                                                            <Text fontSize="0.875rem" fontWeight="500">
                                                              Plan: Basic metabolic panel, Magnesium
                                                            </Text>
                                                          </Box>
                                                        </StepDescription>
                                                      </Box>
                                        
                                                      <StepSeparator />
                                                    </Step>
                                                </Stepper>
                                                <PrimaryButton variant="grayBtnFull" btnStyle={{my: "0.5rem"}} buttonText="View all" />
                                              </>}
                                            /> */}
                          {/* <ToggleTab
                            title="treatment Plans"
                            body={
                              <>
                                {data && data.treatmentplan.length > 0 ? (
                                  <Stepper
                                    orientation="vertical"
                                    height="400px"
                                    size="xs"
                                    colorScheme="#CDCFD4"
                                    gap="0"
                                    w="100%"
                                    h="100%"
                                  >
                                  {data.treatmentplan.slice(0,3).map((item,index) => (
                                    <Step w="100%" pb="1rem">
                                      <StepIndicator color="#E6E7E9" />
                                      <Box flexShrink="0" w="95%">
                                        <StepTitle
                                          fontSize="0.875rem"
                                          color="#384052"
                                          mb="0.5rem"
                                        >
                                          {formatDate(item.date)}
                                        </StepTitle>
                                        <StepDescription
                                          bg="white"
                                          w="100%"
                                          border="1px solid #E6E7E9"
                                          boxShadow="0px 8px 20px 0px #E6E7E966"
                                          borderRadius="0.65rem"
                                          p="1.25rem"
                                        >
                                          <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            pb="0.5rem"
                                          >
                                            <Text
                                              fontSize="0.875rem"
                                              color="#061027"
                                            >
                                              {item.doctorname? item.doctorname:""}
                                            </Text>
                                          </Box>
                                          <Box
                                            bg="#F9FAFB"
                                            borderLeft="2px solid #14C585"
                                            p="0.5rem"
                                          >
                                            <Text
                                              fontSize="0.875rem"
                                              fontWeight="500"
                                            >
                                              Plan: {item.specality?item.specality:""}
                                            </Text>
                                          </Box>
                                        </StepDescription>
                                      </Box>
                                      <StepSeparator />
                                    </Step>
                                  ))}
                                  </Stepper>
                                ) : (
                                  <Text>No records found</Text>
                                )}
                                {data && data.treatmentplan.length > 0 && (
                                  <PrimaryButton
                                    variant="grayBtnFull"
                                    btnStyle={{ my: "0.5rem" }}
                                    buttonText="View all"
                                    onClick={() => {
                                      navigate("/patientprofile?id="+ patientid + "&page=treatmentPlan")
                                    }} 
                                  />
                                )}
                              </>
                            }
                          /> */}

                          <ToggleTab
                            title="treatment team"
                            body={
                              <>
                                {data && data.treatmentteam.length > 0
                                  ? data.treatmentteam.map((item, index) => (
                                    <Box>

                                      <Flex
                                        key={index}
                                        alignItems="center"
                                        gap="10px"
                                      >
                                        <Box display="flex">
                                          <Image
                                            w="2.25rem"
                                            borderRadius="full"
                                            src="/assets/imgs/no-image-icon.png"
                                            mr="3"
                                          />
                                          <Box>
                                            <Text
                                              fontSize="0.625rem"
                                              fontWeight="400"
                                            >
                                              {/* General physician */}
                                              {item.entitytype}
                                            </Text>
                                            <Text
                                              as="h1"
                                              fontSize="0.875rem"
                                              fontWeight="600"
                                            >
                                              {/* Dr. Mahroos Ali */}
                                              {item.doctorname}
                                            </Text>
                                          </Box>
                                        </Box>
                                        <Spacer />
                                        <Text
                                          fontSize="0.75rem"
                                          color="#384052"
                                        >
                                          {/* 10th Jan, 2024  */}
                                          {formatDate(item.date)}
                                        </Text>
                                      </Flex>
                                      <Divider my="0.75rem" />
                                    </Box>
                                    ))
                                    : "No records found"}
                                {/* {data && data.treatmentteam.length > 0 && (
                                  <PrimaryButton
                                    variant="grayBtnFull"
                                    btnStyle={{ my: "0.5rem" }}
                                    buttonText="View all"
                                  />
                                )} */}
                              </>
                            }
                          />

                          <ToggleTab
                            title="Past medical history (pmh)"
                            body={
                              <>
                                <Stepper
                                  orientation="vertical"
                                  height="100%"
                                  size="xs"
                                  colorScheme="#CDCFD4"
                                  gap="0"
                                  w="100%"
                                >
                                  {data && data.medicalhistory.length > 0
                                    ? data.medicalhistory.map((item, index) => (
                                        <Step w="100%">
                                          <StepIndicator color="#E6E7E9" />
                                          <Box flexShrink="0" w="95%">
                                            <StepTitle
                                              fontSize="0.75rem"
                                              fontWeight="500"
                                              color="#384052"
                                              mb="0.5rem"
                                            >
                                              {/* 10th Jan, 2024 */}
                                              {formatDate(
                                                item.conditionidentifieddate
                                              )}
                                            </StepTitle>
                                            <StepDescription
                                              bg="white"
                                              w="100%"
                                              border="1px solid #E6E7E9"
                                              borderRadius="0.65rem"
                                              p="0.75rem"
                                              mb="1.5rem"
                                              boxShadow="0px 8px 20px 0px #E6E7E966"
                                            >
                                              <Box
                                                // w="fit-content"
                                                maxW="100%"
                                                whiteSpace='wrap'
                                                className="textOverflow"
                                              >
                                                <Text
                                                  fontSize="0.875rem"
                                                  color="#061027"
                                                >
                                                  {/* A-fib (CMS/HCC) */}
                                                  {item.condition}
                                                </Text>
                                              </Box>
                                            </StepDescription>
                                          </Box>
                                          <StepSeparator colorScheme="red" />
                                        </Step>
                                      ))
                                    : "No records found"}
                                </Stepper>
                                {data && data.medicalhistory.length > 0 && (
                                  <PrimaryButton
                                    variant="grayBtnFull"
                                    btnStyle={{ my: "0.5rem" }}
                                    buttonText="View all"
                                    onClick={() => {
                                      navigate("/patientprofile?id="+ patientid + "&page=medicalHistory")
                                    }}
                                    
                    isDisabled={actionAccessPatients && actionAccessPatients.read ? false : true }
                    opacity={actionAccessPatients && actionAccessPatients.read ? 1 : 0.5 }
                                  />
                                )}
                              </>
                            }
                          />

                          <ToggleTab
                            title="Past Surgical History (PSH)"
                            body={
                              <>
                                <Stepper
                                  orientation="vertical"
                                  height="100%"
                                  size="xs"
                                  colorScheme="#CDCFD4"
                                  gap="0"
                                  w="100%"
                                >
                                  {data && data.surgicalhistory.length > 0
                                    ? data.surgicalhistory.map(
                                        (item, index) => (
                                          <Step w="100%" key={index}>
                                            <StepIndicator color="#E6E7E9" />
                                            <Box flexShrink="0" w="95%">
                                              <StepTitle
                                                fontSize="0.75rem"
                                                fontWeight="500"
                                                color="#384052"
                                                mb="0.5rem"
                                              >
                                                {/* 10th Jan, 2024 */}
                                                {formatDate(item.proceduredate)}
                                              </StepTitle>

                                              <StepDescription
                                                w="100%"
                                                mb="1.5rem"
                                              >
                                                <Box
                                                  bg="white"
                                                  border="1px solid #E6E7E9"
                                                  borderRadius="0.65rem"
                                                  p="0.75rem"
                                                  boxShadow="0px 8px 20px 0px #E6E7E966"
                                                >
                                                  <Box>
                                                    <Box
                                                      display="flex"
                                                      justifyContent="space-between"
                                                      w="100%"
                                                    >
                                                      <Text
                                                        fontSize="0.875rem"
                                                        fontWeight="500"
                                                      >
                                                        {/* Cardiac catheterization */}
                                                        {item.proceduretitle}
                                                      </Text>
                                                    </Box>
                                                    <Divider my="0.5rem" />
                                                    <Box
                                                      display="flex"
                                                      justifyContent="space-between"
                                                      flexDir="column"
                                                      w="70%"
                                                    >
                                                      <Box
                                                        display="flex"
                                                        gap="0.5rem"
                                                      >
                                                        <Image src="assets/svgs/stethoscope.svg" />
                                                        <Text
                                                          fontSize="0.75rem"
                                                          fontWeight="500"
                                                        >
                                                          {/* Mahroos Bu Ali */}
                                                          {item.surgeonname}
                                                        </Text>
                                                      </Box>
                                                      <Box
                                                        display="flex"
                                                        gap="0.5rem"
                                                      >
                                                        <Image src="assets/svgs/hospital.svg" />
                                                        <Text
                                                          fontSize="0.75rem"
                                                          fontWeight="500"
                                                        >
                                                          {/* Mahroos Bu Ali */}
                                                          {item.hospitalname}
                                                        </Text>
                                                      </Box>
                                                    </Box>
                                                  </Box>
                                                </Box>

                                                <Box
                                                  display="flex"
                                                  flexDir="column"
                                                  gap="0.75rem"
                                                  mt="0.5rem"
                                                  cursor="pointer"
                                                >
                                                  {item.documents &&
                                                  item.documents.length > 0
                                                    ? item.documents.map(
                                                        (elem, subIndex) => (
                                                          <Box
                                                            display="flex"
                                                            w="max-content"
                                                            gap="0.5rem"
                                                            p="0.5rem 1rem"
                                                            bg="white"
                                                            border="1px solid #E6E7E9"
                                                            borderRadius="0.65rem"
                                                            key={subIndex}
                                                            onClick={()=>downloadDocument(elem.imagepath + elem.documenturl, "report")}
                                                          >
                                                            <Image
                                                              w="1.125rem"
                                                              h="auto"
                                                              // src="/assets/svgs/pdf-icon.svg"
                                                              src={getIconForExtension(elem.documenturl)}
                                                            />
                                                            <Text
                                                              fontSize="0.75rem"
                                                              fontWeight="600"
                                                            >
                                                              {/* Prescription.pdf */}
                                                              {/* {`${elem.imagepath}${elem.documenturl}`} */}
                                                              {`Document- ${subIndex + 1}`}
                                                            </Text>
                                                          </Box>
                                                        )
                                                      )
                                                    : ""}
                                                  {/* <Box display="flex" w="max-content" gap="0.5rem" p="0.5rem 1rem" bg="white" border="1px solid #E6E7E9" borderRadius="0.65rem">
                                                                  <Image w="1.125rem" h="auto" src="/assets/svgs/pdf-icon.svg" />
                                                                  <Text fontSize="0.75rem" fontWeight="600">
                                                                    Prescription.pdf
                                                                    {item.documents}
                                                                    </Text>
                                                              </Box> */}
                                                </Box>
                                              </StepDescription>
                                            </Box>

                                            <StepSeparator colorScheme="red" />
                                          </Step>
                                        )
                                      )
                                    : "No records found"}
                                </Stepper>
                                {data && data.surgicalhistory.length > 0 && (
                                  <PrimaryButton
                                    variant="grayBtnFull"
                                    btnStyle={{ my: "0.5rem" }}
                                    buttonText="View all"
                                    onClick={() => {
                                      navigate("/patientprofile?id="+ patientid + "&page=surgicalHistory")
                                    }} 
                                    
                    isDisabled={actionAccessPatients && actionAccessPatients.read ? false : true }
                    opacity={actionAccessPatients && actionAccessPatients.read ? 1 : 0.5 }
                                  />
                                )}
                              </>
                            }
                          />

                          {/* Previous code */}
                          {/* <ToggleTab title="Past Family History" 
                                              body={<>
                                                <Flex flexDir="column" gap="1rem">
                                                  <Card border="1px solid #E6E7E9" borderRadius="0.875rem" boxShadow="none">
                                                        <CardBody>
                                                            <Text fontSize="0.625rem" w="max-content" fontWeight="600" color="#384052" p="0.25rem 0.5rem" bg="#E6E7E966" border="1px solid #E6E7E9" borderRadius="full">Alive</Text> 
                                                            <Text fontSize="1rem" fontWeight="600" color="#061027" mt="0.5rem">Mohammed Uddin Abraar</Text>
                                                            <Text fontSize="0.75rem" fontWeight="500" color="#384052">Father  •  28th Jan, 1980 </Text>
                                                            <Divider my="0.75rem" />
                                                            <Text fontSize="0.875rem" fontWeight="500" color="#384052">Cancer and Diabetes</Text>
                                                        </CardBody>
                                                  </Card>
                                                  <Card border="1px solid #E6E7E9" borderRadius="0.875rem" boxShadow="none">
                                                      <CardBody>
                                                          <Text fontSize="0.625rem" w="max-content" fontWeight="600" color="#384052" p="0.25rem 0.5rem" bg="#E6E7E966" border="1px solid #E6E7E9" borderRadius="full">Alive</Text> 
                                                          <Text fontSize="1rem" fontWeight="600" color="#061027" mt="0.5rem">Mohammed Uddin Abraar</Text>
                                                          <Text fontSize="0.75rem" fontWeight="500" color="#384052">Father  •  28th Jan, 1980 </Text>
                                                          <Divider my="0.75rem" />
                                                          <Text fontSize="0.875rem" fontWeight="500" color="#384052">Cancer and Diabetes</Text>
                                                      </CardBody>
                                                  </Card>
                                                </Flex>
                                                <PrimaryButton variant="grayBtnFull" btnStyle={{my: "1rem", mb: "0.5rem"}} buttonText="View all" />
                                              </>}
                                            /> */}
                          <ToggleTab
                            title="Past Family History"
                            body={
                              <>
                                {data && data.familyhistory.length > 0 ? (
                                  <Flex flexDir="column" gap="1rem">
                                    {data.familyhistory.map((member, index) => (
                                      <Card
                                        key={index}
                                        border="1px solid #E6E7E9"
                                        borderRadius="0.875rem"
                                        boxShadow="none"
                                      >
                                        <CardBody>
                                          <Text
                                            fontSize="0.625rem"
                                            w="max-content"
                                            fontWeight="600"
                                            color="#384052"
                                            p="0.25rem 0.5rem"
                                            bg="#E6E7E966"
                                            border="1px solid #E6E7E9"
                                            borderRadius="full"
                                          >
                                            {
                                              member.isdeceased == 0 ? (
                                                <Text color='rgb(0,151,0)'> Alive</Text>
                                              ) : (
                                                <Text color='rgb(254, 51, 51)'> Deceased</Text>
                                              )
                                            }
                                          </Text>
                                          <Text
                                            fontSize="1rem"
                                            fontWeight="600"
                                            color="#061027"
                                            mt="0.5rem"
                                          >
                                            {member.fullname?member.fullname:"-"}
                                          </Text>
                                          <Text
                                            fontSize="0.75rem"
                                            fontWeight="500"
                                            color="#384052"
                                          >
                                            {member.relation} •{" "}
                                            {formatDate(member.dob)}
                                          </Text>
                                          <Divider my="0.75rem" />
                                          {member.medicalcondition &&
                                            member.medicalcondition.length >
                                              0 &&
                                            member.medicalcondition.map(
                                              (condition, idx) => (
                                                <Text
                                                  key={idx}
                                                  fontSize="0.875rem"
                                                  fontWeight="500"
                                                  color="#384052"
                                                >
                                                  {condition.condition}
                                                </Text>
                                              )
                                            )}
                                          {member.allergies &&
                                            member.allergies.length > 0 &&
                                            member.allergies.map(
                                              (allergy, idx) => (
                                                <Text
                                                  key={idx}
                                                  fontSize="0.875rem"
                                                  fontWeight="500"
                                                  color="#384052"
                                                >
                                                  {allergy.allergytype} Allergy
                                                </Text>
                                              )
                                            )}
                                        </CardBody>
                                      </Card>
                                    ))}
                                  </Flex>
                                ) : (
                                  <Text>No records found</Text>
                                )}
                                {data && data.familyhistory.length > 0 && (
                                  <PrimaryButton
                                    variant="grayBtnFull"
                                    btnStyle={{ my: "0.5rem" }}
                                    buttonText="View all"
                                    onClick={() => {
                                      navigate("/patientprofile?id="+ patientid + "&page=familyHistory")
                                    }} 
                                    
                    isDisabled={actionAccessPatients && actionAccessPatients.read ? false : true }
                    opacity={actionAccessPatients && actionAccessPatients.read ? 1 : 0.5 }
                                  />
                                )}
                              </>
                            }
                          />

                          {/* Previous code */}
                          {/* <ToggleTab title="Social History" 
                                              body={<>
                                                <Flex flexDir="column" gap="1rem">
                                                  <Card border="1px solid #E6E7E9" borderRadius="0.875rem" boxShadow="none" >
                                                      <CardBody>
                                                          <Text fontSize="1rem" fontWeight="600" color="#061027" mt="0.5rem">Smoking</Text>
                                                          <Text fontSize="0.75rem" fontWeight="500" color="#384052">5 years</Text>
                                                          <Divider my="0.75rem" />
                                                          <Text fontSize="0.75rem" fontWeight="500" color="#384052">5x per day</Text>
                                                      </CardBody>
                                                  </Card>
                                                  <Card border="1px solid #E6E7E9" borderRadius="0.875rem" boxShadow="none" >
                                                      <CardBody>
                                                          <Text fontSize="1rem" fontWeight="600" color="#061027" mt="0.5rem">Smoking</Text>
                                                          <Text fontSize="0.75rem" fontWeight="500" color="#384052">5 years</Text>
                                                          <Divider my="0.75rem" />
                                                          <Text fontSize="0.75rem" fontWeight="500" color="#384052">5x per day</Text>
                                                      </CardBody>
                                                  </Card>
                                                </Flex>
                                                <PrimaryButton variant="grayBtnFull" btnStyle={{my: "1rem", mb: "0.5rem"}} buttonText="View all" />
                                              </>}
                                            /> */}
                          <ToggleTab
                            title="Social History"
                            body={
                              <>
                                {data && data.socialhistory.length > 0 ? (
                                  <Flex flexDir="column" gap="1rem">
                                    {data.socialhistory.map(
                                      (history, index) => {
                                        const totalYears = calculateYearsDifference(history.fromdate, history.todate);
                                        return(
                                        <Card
                                          key={index}
                                          border="1px solid #E6E7E9"
                                          borderRadius="0.875rem"
                                          boxShadow="none"
                                        >
                                          <CardBody>
                                            <Text
                                              fontSize="1rem"
                                              fontWeight="600"
                                              color="#061027"
                                              mt="0.5rem"
                                            >
                                              {history.socialhistory}
                                            </Text>
                                            <Box display='flex' flexDir='row'>
                                            <Text
                                              fontSize="0.75rem"
                                              fontWeight="bold"
                                              color="#384052"
                                            >Status: </Text>
                                            <Text
                                              fontSize="0.75rem"
                                              fontWeight="500"
                                              color="#384052"
                                            >
                                              {/* {history.fromdate} -{" "}
                                              {history.todate} */}
                                              {/* {`${totalYears} years`} */}
                                              {history.iscurrent == 1 ? " Current" : 
                                              history.iscurrent == 0 ? " Never" : 
                                              history.iscurrent == 2 ? " Former" :"" }
                                            </Text>
                                            </Box>
                                            <>
                                              <Divider my="0.75rem" />
                                              {/* <Text
                                                fontSize="0.75rem"
                                                fontWeight="500"
                                                color="#384052"
                                              >
                                                {history.frequency && history.frequency==null ? ('-') : history.frequency } { " "} {` ${history.frequency != null ? (`x per ${history.frequencytype}`) : ('-')}`}
                                              </Text> */}
                                              <Text fontSize="0.75rem" fontWeight="500" color="#384052">
                          {history.frequency && history.frequency !== 0 ? `${history.frequency} 
                              ${history.socialhistory && history.socialhistory == "Smoking" ? "Packs": 
                                                      history.socialhistory == "Alcohol" ? "Drinks" :
                                                      history.socialhistory == "Smokeless" ? "Cans" :
                                                      history.socialhistory == "Illicit Drugs" ? "Times" 
                                                      : "Packs"} 
                              Per ${history.frequencytype}   
                              x ${totalYears == 1 ? totalYears + " year"  :
                                  totalYears > 1 ? totalYears + " years" : 
                                                                  "less than a year"}  
                            ` 
                              : '-'}
                              {history.iscurrent == 2 && (
              <>
                {` Until ${history.todate ? formatDate_yyyy(history.todate) : ""}`}
              </>
            )}
                      </Text>
                                            </>
                                            {/* {history.drugnames && history.drugnames.length > 0 && (
                                                            <>
                                                              <Divider my="0.75rem" />
                                                              <Text fontSize="0.75rem" fontWeight="500" color="#384052">Drug names:</Text>
                                                              {history.drugnames.map((drug, idx) => (
                                                                <Text key={idx} fontSize="0.75rem" fontWeight="500" color="#384052">{drug.name}</Text>
                                                              ))}
                                                            </>
                                                          )} */}
                                          </CardBody>
                                        </Card>
                                      )
                            })}
                                  </Flex>
                                ) : (
                                  <Text>No records found</Text>
                                )}
                                {data && data.socialhistory.length > 0 && (
                                  <PrimaryButton
                                    variant="grayBtnFull"
                                    btnStyle={{ my: "0.5rem" }}
                                    buttonText="View all"
                                    onClick={() => {
                                      navigate("/patientprofile?id="+ patientid + "&page=socialHistory")
                                    }} 
                                    
                    isDisabled={actionAccessPatients && actionAccessPatients.read ? false : true }
                    opacity={actionAccessPatients && actionAccessPatients.read ? 1 : 0.5 }
                                  />
                                )}
                              </>
                            }
                          />

                          {/* Previous code */}
                          {/* <ToggleTab title="Current Medications" 
                                              body={<>
                                                <Stepper
                                                  orientation="vertical"
                                                  height="100%"
                                                  size="xs"
                                                  colorScheme="#CDCFD4"
                                                  gap="0"
                                                  w="100%"
                                                >
                                                  <Step w="100%" >
                                                      <StepIndicator color="#E6E7E9" />
                                                        <Box flexShrink="0" w="95%">
                                                          <StepTitle fontSize="0.75rem" fontWeight="500" color="#384052" mb="0.5rem">
                                                            10th Jan, 2024
                                                          </StepTitle>

                                                          <StepDescription w="100%" mb="1.5rem">
                                                            <Box bg="white" border="1px solid #E6E7E9" borderRadius="0.65rem" p="0.75rem" boxShadow="0px 8px 20px 0px #E6E7E966">
                                                              <Box>
                                                                  <Text fontSize="0.625rem" w="max-content" fontWeight="600" color="#384052" p="0.25rem 0.5rem" bg="#E6E7E966" border="1px solid #E6E7E9" borderRadius="full">Alive</Text> 
                                                                  <Box display="flex" justifyContent="space-between" w="100%">
                                                                      <Text fontSize="0.875rem" fontWeight="500">Cardiac catheterization</Text>
                                                                  </Box>
                                                                  <Divider my="0.5rem" />
                                                                  <Box display="flex" gap="0.5rem" flexDir="column">
                                                                    <Box
                                                                      display="flex"
                                                                      alignItems="center"
                                                                      justifyContent="start"
                                                                      gap="10px"
                                                                    >
                                                                      <Image src="/assets/svgs/pill-blue.svg" />
                                                                      <Text fontSize="12px" fontWeight="500">
                                                                        2x per day                                                                       day
                                                                      </Text>
                                                                      <Box display="flex" gap="5px">
                                                                        <Box
                                                                          as="span"
                                                                          bg="#E6E7E9"
                                                                          borderRadius="full"
                                                                          w="8px"
                                                                          h="8px"
                                                                          p="4px"
                                                                        ></Box>
                                                                        <Box
                                                                          as="span"
                                                                          bg="#384052"
                                                                          borderRadius="full"
                                                                          w="8px"
                                                                          h="8px"
                                                                          p="4px"
                                                                        ></Box>
                                                                        <Box
                                                                          as="span"
                                                                          bg="#E6E7E9"
                                                                          borderRadius="full"
                                                                          w="8px"
                                                                          h="8px"
                                                                          p="4px"
                                                                        ></Box>
                                                                      </Box>
                                                                    </Box>

                                                                    <Box display="flex" gap="10px">
                                                                      <Image w="0.875rem" h='1rem' src="/assets/imgs/calendar-blue.png" />
                                                                      <Text fontSize="12px" fontWeight="500">30 days</Text>
                                                                    </Box>
                                                                  </Box>
                                                              </Box>
                                                            </Box>

                                                            <Box display="flex" flexDir="column" gap="0.75rem" mt="0.5rem">
                                                              <Box display="flex" w="max-content" gap="0.5rem" p="0.5rem 1rem" bg="white" border="1px solid #E6E7E9" borderRadius="0.65rem">
                                                                  <Image w="1.125rem" h="auto" src="/assets/svgs/pdf-icon.svg" />
                                                                  <Text fontSize="0.75rem" fontWeight="600">Prescription.pdf</Text>
                                                              </Box>
                                                              <Box display="flex" w="max-content" gap="0.5rem" p="0.5rem 1rem" bg="white" border="1px solid #E6E7E9" borderRadius="0.65rem">
                                                                  <Image w="1.125rem" h="auto" src="/assets/svgs/pdf-icon.svg" />
                                                                  <Text fontSize="0.75rem" fontWeight="600">Prescription.pdf</Text>
                                                              </Box>
                                                            </Box>
                                                          </StepDescription>
                                                        </Box>
                                          
                                                        <StepSeparator colorScheme="red" />
                                                  </Step>
                                                  <Step w="100%" >
                                                      <StepIndicator color="#E6E7E9" />
                                                        <Box flexShrink="0" w="95%">
                                                          <StepTitle fontSize="0.75rem" fontWeight="500" color="#384052" mb="0.5rem">
                                                            10th Jan, 2024
                                                          </StepTitle>

                                                          <StepDescription w="100%" mb="1.5rem">
                                                            <Box bg="white" border="1px solid #E6E7E9" borderRadius="0.65rem" p="0.75rem" boxShadow="0px 8px 20px 0px #E6E7E966">
                                                              <Box>
                                                                  <Text fontSize="0.625rem" w="max-content" fontWeight="600" color="#384052" p="0.25rem 0.5rem" bg="#E6E7E966" border="1px solid #E6E7E9" borderRadius="full">Alive</Text> 
                                                                  <Box display="flex" justifyContent="space-between" w="100%">
                                                                      <Text fontSize="0.875rem" fontWeight="500">Cardiac catheterization</Text>
                                                                  </Box>
                                                                  <Divider my="0.5rem" />
                                                                  <Box display="flex" gap="0.5rem" flexDir="column">
                                                                    <Box
                                                                      display="flex"
                                                                      alignItems="center"
                                                                      justifyContent="start"
                                                                      gap="10px"
                                                                    >
                                                                      <Image src="/assets/svgs/pill-blue.svg" />
                                                                      <Text fontSize="12px" fontWeight="500">
                                                                        2x per day                                                                       day
                                                                      </Text>
                                                                      <Box display="flex" gap="5px">
                                                                        <Box
                                                                          as="span"
                                                                          bg="#E6E7E9"
                                                                          borderRadius="full"
                                                                          w="8px"
                                                                          h="8px"
                                                                          p="4px"
                                                                        ></Box>
                                                                        <Box
                                                                          as="span"
                                                                          bg="#384052"
                                                                          borderRadius="full"
                                                                          w="8px"
                                                                          h="8px"
                                                                          p="4px"
                                                                        ></Box>
                                                                        <Box
                                                                          as="span"
                                                                          bg="#E6E7E9"
                                                                          borderRadius="full"
                                                                          w="8px"
                                                                          h="8px"
                                                                          p="4px"
                                                                        ></Box>
                                                                      </Box>
                                                                    </Box>

                                                                    <Box display="flex" gap="10px">
                                                                      <Image w="0.875rem" h='1rem' src="/assets/imgs/calendar-blue.png" />
                                                                      <Text fontSize="12px" fontWeight="500">30 days</Text>
                                                                    </Box>
                                                                  </Box>
                                                              </Box>
                                                            </Box>

                                                            <Box display="flex" flexDir="column" gap="0.75rem" mt="0.5rem">
                                                              <Box display="flex" w="max-content" gap="0.5rem" p="0.5rem 1rem" bg="white" border="1px solid #E6E7E9" borderRadius="0.65rem">
                                                                  <Image w="1.125rem" h="auto" src="/assets/svgs/pdf-icon.svg" />
                                                                  <Text fontSize="0.75rem" fontWeight="600">Prescription.pdf</Text>
                                                              </Box>
                                                              <Box display="flex" w="max-content" gap="0.5rem" p="0.5rem 1rem" bg="white" border="1px solid #E6E7E9" borderRadius="0.65rem">
                                                                  <Image w="1.125rem" h="auto" src="/assets/svgs/pdf-icon.svg" />
                                                                  <Text fontSize="0.75rem" fontWeight="600">Prescription.pdf</Text>
                                                              </Box>
                                                            </Box>
                                                          </StepDescription>
                                                        </Box>
                                          
                                                        <StepSeparator colorScheme="red" />
                                                  </Step>
                                                </Stepper>
                                                <PrimaryButton variant="grayBtnFull" btnStyle={{my: "0.5rem"}} buttonText="View all" />
                                              </>}
                                            /> */}
                          <ToggleTab
                            title="Current Medications"
                            body={
                              <>
                                {data && data.medications.length > 0 ? (
                                  <Stepper
                                    orientation="vertical"
                                    height="100%"
                                    size="xs"
                                    colorScheme="#CDCFD4"
                                    gap="0"
                                    w="100%"
                                    cursor="pointer"
                                  >
                                    {data.medications.map(
                                      (medication, index) => (
                                        <Step key={index} w="100%">
                                          <StepIndicator color="#E6E7E9" />
                                          <Box flexShrink="0" w="95%">
                                            <StepTitle
                                              fontSize="0.75rem"
                                              fontWeight="500"
                                              color="#384052"
                                              mb="0.5rem"
                                            >
                                              {formatDate(
                                                medication.medicationstartdate
                                              )}
                                            </StepTitle>

                                            <StepDescription
                                              w="100%"
                                              mb="1.5rem"
                                            >
                                              <Box
                                                bg="white"
                                                border="1px solid #E6E7E9"
                                                borderRadius="0.65rem"
                                                p="0.75rem"
                                                boxShadow="0px 8px 20px 0px #E6E7E966"
                                              >
                                                <Box>
                                                  <Text
                                                    fontSize="0.625rem"
                                                    w="max-content"
                                                    fontWeight="600"
                                                    color="#384052"
                                                    p="0.25rem 0.5rem"
                                                    bg="#E6E7E966"
                                                    border="1px solid #E6E7E9"
                                                    borderRadius="full"
                                                  >
                                                    {medication.medicinetype}
                                                  </Text>
                                                  <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    w="100%"
                                                  >

                                                    <Text
                                                      fontSize="0.875rem"
                                                      fontWeight="500"
                                                    >
                                                      {medication.medicinename}
                                                    </Text>
                                                  </Box>
                                                  <Divider my="0.5rem" />
                                                  <Box
                                                    display="flex"
                                                    flexDir="column"
                                                  >
                                                    <Text
                                                      fontSize="0.75rem"
                                                      fontWeight="500"
                                                    >
                                                      {medication.frequency? medication.frequency+ "x per day":""}
                                                    </Text>
                                                  </Box>
                                                </Box>
                                              </Box>

                                              {medication.documents &&
                                                medication.documents.length >
                                                  0 && (
                                                  <Box
                                                    display="flex"
                                                    flexDir="column"
                                                    gap="0.75rem"
                                                    mt="0.5rem"
                                                    cursor="pointer"
                                                    onClick={() => downloadDocument(`${document.imagepath}${document.documenturl}`)}
                                                  >
                                                    {medication.documents.map(
                                                      (document, idx) => (
                                                        <Box
                                                          key={idx}
                                                          display="flex"
                                                          w="max-content"
                                                          gap="0.5rem"
                                                          p="0.5rem 1rem"
                                                          bg="white"
                                                          border="1px solid #E6E7E9"
                                                          borderRadius="0.65rem"
                                                        >
                                                          <Image
                                                            w="1.125rem"
                                                            h="auto"
                                                            src={getIconForExtension(document.documenturl)}
                                                          />
                                                          <Text
                                                            fontSize="0.75rem"
                                                            fontWeight="600"
                                                            cursor="pointer"
                                                          >
                                                              {`Document- ${idx + 1}`}
                                                          </Text>
                                                        </Box>
                                                      )
                                                    )}
                                                  </Box>
                                                )}
                                            </StepDescription>
                                          </Box>
                                          <StepSeparator colorScheme="red" />
                                        </Step>
                                      )
                                    )}
                                  </Stepper>
                                ) : (
                                  <Text>No records found</Text>
                                )}
                                {/* {data && data.medications.length > 0 && (
                                  <PrimaryButton
                                    variant="grayBtnFull"
                                    btnStyle={{ my: "0.5rem" }}
                                    buttonText="View all"
                                  />
                                )} */}
                              </>
                            }
                          />

                          {/* Previous code */}
                          {/* <ToggleTab title="Allergies" 
                                              body={<>
                                                <Box w="100%" display="flex" flexDir="column" gap="0.75rem">
                                                  <Box
                                                    bg="white"
                                                    border="1px solid #E6E7E9"
                                                    borderRadius="0.65rem"
                                                    p="1.25rem"
                                                    boxShadow="0px 8px 20px 0px #E6E7E966"
                                                  >
                                                    <Box
                                                      display="flex"
                                                      justifyContent="space-between"
                                                      alignItems="center"
                                                      pb="0.5rem"
                                                    >
                                                      <Text fontSize="0.875rem" color="#061027">
                                                        Spider bites
                                                      </Text>
                                                    </Box>
                                                    <Box bg="#F9FAFB" borderLeft="2px solid #14C585" p="0.5rem">
                                                      <Text fontSize="0.875rem" fontWeight="500" color="#384052">
                                                        Itching, Rash and Pain
                                                      </Text>
                                                    </Box>
                                                    <Text fontSize="0.75rem" color="#384052" mt="0.75rem">
                                                      10th Jan, 2024
                                                    </Text>

                                                    <Divider my="1rem" />
                                                  <Box display="flex" flexDir="column" gap="0.75rem" >
                                                    <Box
                                                      display="flex"
                                                      gap="0.5rem"
                                                      p="0.5rem 1rem"
                                                      bg="white"
                                                      border="1px solid #E6E7E9"
                                                      borderRadius="0.65rem"
                                                    >
                                                      <Image w="1.125rem" h="auto" src="/assets/svgs/pdf-icon.svg" />
                                                      <Text fontSize="0.75rem" fontWeight="600">
                                                        Prescription.pdf
                                                      </Text>
                                                    </Box>
                                                    <Box
                                                      display="flex"
                                                      gap="0.5rem"
                                                      p="0.5rem 1rem"
                                                      bg="white"
                                                      border="1px solid #E6E7E9"
                                                      borderRadius="0.65rem"
                                                    >
                                                      <Image w="1.125rem" h="auto" src="/assets/svgs/pdf-icon.svg" />
                                                      <Text fontSize="0.75rem" fontWeight="600">
                                                        Prescription.pdf
                                                      </Text>
                                                    </Box>
                                                  </Box>
                                                  </Box>
                                                  <Box
                                                    bg="white"
                                                    border="1px solid #E6E7E9"
                                                    borderRadius="0.65rem"
                                                    p="1.25rem"
                                                    boxShadow="0px 8px 20px 0px #E6E7E966"
                                                  >
                                                    <Box
                                                      display="flex"
                                                      justifyContent="space-between"
                                                      alignItems="center"
                                                      pb="0.5rem"
                                                    >
                                                      <Text fontSize="0.875rem" color="#061027">
                                                        Spider bites
                                                      </Text>
                                                    </Box>
                                                    <Box bg="#F9FAFB" borderLeft="2px solid #14C585" p="0.5rem">
                                                      <Text fontSize="0.875rem" fontWeight="500" color="#384052">
                                                        Itching, Rash and Pain
                                                      </Text>
                                                    </Box>
                                                    <Text fontSize="0.75rem" color="#384052" mt="0.75rem">
                                                      10th Jan, 2024
                                                    </Text>

                                                    <Divider my="1rem" />
                                                  <Box display="flex" flexDir="column" gap="0.75rem" >
                                                    <Box
                                                      display="flex"
                                                      gap="0.5rem"
                                                      p="0.5rem 1rem"
                                                      bg="white"
                                                      border="1px solid #E6E7E9"
                                                      borderRadius="0.65rem"
                                                    >
                                                      <Image w="1.125rem" h="auto" src="/assets/svgs/pdf-icon.svg" />
                                                      <Text fontSize="0.75rem" fontWeight="600">
                                                        Prescription.pdf
                                                      </Text>
                                                    </Box>
                                                    <Box
                                                      display="flex"
                                                      gap="0.5rem"
                                                      p="0.5rem 1rem"
                                                      bg="white"
                                                      border="1px solid #E6E7E9"
                                                      borderRadius="0.65rem"
                                                    >
                                                      <Image w="1.125rem" h="auto" src="/assets/svgs/pdf-icon.svg" />
                                                      <Text fontSize="0.75rem" fontWeight="600">
                                                        Prescription.pdf
                                                      </Text>
                                                    </Box>
                                                  </Box>
                                                  </Box>
                                                </Box>
                                                <PrimaryButton variant="grayBtnFull" btnStyle={{mt: "1rem", mb: "0.5rem"}} buttonText="View all" />
                                              </>}
                                            /> */}
                          <ToggleTab
                            title="Allergies"
                            body={
                              <>
                                {data && data.allergy.length > 0 ? (
                                  <Box
                                    w="100%"
                                    display="flex"
                                    flexDir="column"
                                    gap="0.75rem"
                                  >
                                    {data.allergy.map((allergyData, index) => (
                                      <Box
                                        key={index}
                                        bg="white"
                                        border="1px solid #E6E7E9"
                                        borderRadius="0.65rem"
                                        p="1.25rem"
                                        boxShadow="0px 8px 20px 0px #E6E7E966"
                                      >
                                        <Box
                                          display="flex"
                                          justifyContent="space-between"
                                          alignItems="center"
                                          pb="0.5rem"
                                        >
                                          <Text
                                            fontSize="0.875rem"
                                            color="#061027"
                                          >
                                            {allergyData.allergytype}
                                          </Text>
                                        </Box>
                                        <Box
                                          bg="#F9FAFB"
                                          borderLeft="2px solid #14C585"
                                          p="0.5rem"
                                        >
                                          {allergyData.reaction &&
                                            allergyData.reaction.length > 0 && (
                                              <Text
                                                fontSize="0.875rem"
                                                fontWeight="500"
                                                color="#384052"
                                              >
                                                {allergyData.reaction
                                                  .map(
                                                    (reaction) =>
                                                      reaction.reaction
                                                  )
                                                  .join(", ")}
                                              </Text>
                                            )}
                                        </Box>
                                        <Text
                                          fontSize="0.75rem"
                                          color="#384052"
                                          mt="0.75rem"
                                        >
                                          {formatDate(
                                            allergyData.allergyidentifieddate
                                          )}
                                        </Text>

                                        <Divider my="1rem" />

                                        {allergyData.documents &&
                                          allergyData.documents.length > 0 && (
                                            <Box
                                              display="flex"
                                              flexDir="column"
                                              gap="0.75rem"
                                            >
                                              {allergyData.documents.map(
                                                (document, idx) => (
                                                  <Box
                                                    key={idx}
                                                    display="flex"
                                                    gap="0.5rem"
                                                    p="0.5rem 1rem"
                                                    bg="white"
                                                    border="1px solid #E6E7E9"
                                                    borderRadius="0.65rem"
                                                    justifyContent='space-between'
                                                    // onClick={() =>
                                                    //   downloadDocument(
                                                    //     document.imagepath +
                                                    //       document.documenturl,
                                                    //     "report"
                                                    //   )
                                                    // }
                                                  >
                                                    <Box display='flex' gap='5px'>
                                                    <Image
                                                      w="1.125rem"
                                                      h="auto"
                                                      src={getIconForExtension(document.documenturl)}
                                                    />
                                                    <Text
                                                      fontSize="0.75rem"
                                                      fontWeight="600"
                                                    >
                                                         {`Document- ${idx + 1}`}
                                                    </Text>
                                                    </Box>
                                                    <Box display='flex' gap='3px' >
                                                    <Box as="span" onClick={() => window.open(document.imagepath + document.documenturl, '_blank')}
                                                    >
                                                      <Image h='16px' w='16px' src="/assets/imgs/show-pass.png" />
                                                    </Box>
                                                    <Box as="span" 
                                                    onClick={() =>
                                                      downloadDocument(
                                                        document.imagepath +
                                                          document.documenturl,
                                                        "report"
                                                      )
                                                    }
                                                    >
                                                      <Image h='15px' w='15px' src="/assets/svgs/pdf-outline.svg" />
                                                    </Box>
                                                  </Box>
                                                  </Box>
                                                )
                                              )}
                                            </Box>
                                          )}
                                      </Box>
                                    ))}
                                  </Box>
                                ) : (
                                  <Text>No records found</Text>
                                )}
                                {data && data.allergy.length > 0 && (
                                  <PrimaryButton
                                    variant="grayBtnFull"
                                    btnStyle={{ my: "0.5rem" }}
                                    buttonText="View all"
                                    onClick={() => {
                                      navigate("/patientprofile?id="+ patientid + "&page=allergies")
                                    }}
                                    
                    isDisabled={actionAccessPatients && actionAccessPatients.read ? false : true }
                    opacity={actionAccessPatients && actionAccessPatients.read ? 1 : 0.5 }
                                  />
                                )}
                              </>
                            }
                          />

                          {/* Previous code */}
                          {/* <ToggleTab title="vitals" 
                                              body={<>
                                                <Flex flexDir="column" gap="0.75rem">
                                                  <Box border="1px solid #E6E7E9"  borderRadius="0.75rem" bg="white" p={4}>
                                                    <Text fontSize="12px" fontWeight="500" color="#6A707D">
                                                      Body temperature
                                                    </Text>
                                                    <Text display="inline-block" fontSize="22px" fontWeight="600">
                                                      98.6
                                                    </Text>
                                                    <Text
                                                      display="inline-block"
                                                      fontSize="15px"
                                                      pl={"5px"}
                                                      fontWeight="400"
                                                    >
                                                      °F
                                                    </Text>
                                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                                        <Box>
                                                        <Text fontSize="12px" fontWeight="500" color="#6A707D">
                                                            May 15, 2024
                                                        </Text>
                                                        </Box>
                                                        <Box display="flex" alignItems="center" gap="0.25rem">
                                                        <Image w="12px" h="auto" src="/assets/imgs/check.png" />
                                                        <Text as="span" fontSize="12px" fontWeight="600" ml="2px">
                                                            OK
                                                        </Text>
                                                        </Box>
                                                    </Box>
                                                  </Box>
                                                  <Box border="1px solid #E6E7E9"  borderRadius="0.75rem" bg="white" p={4}>
                                                    <Text fontSize="12px" fontWeight="500" color="#6A707D">
                                                      Body temperature
                                                    </Text>
                                                    <Text display="inline-block" fontSize="22px" fontWeight="600">
                                                      98.6
                                                    </Text>
                                                    <Text
                                                      display="inline-block"
                                                      fontSize="15px"
                                                      pl={"5px"}
                                                      fontWeight="400"
                                                    >
                                                      °F
                                                    </Text>
                                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                                        <Box>
                                                        <Text fontSize="12px" fontWeight="500" color="#6A707D">
                                                            May 15, 2024
                                                        </Text>
                                                        </Box>
                                                        <Box display="flex" alignItems="center" gap="0.25rem">
                                                        <Image w="12px" h="auto" src="/assets/imgs/check.png" />
                                                        <Text as="span" fontSize="12px" fontWeight="600" ml="2px">
                                                            OK
                                                        </Text>
                                                        </Box>
                                                    </Box>
                                                  </Box>
                                                  <Box border="1px solid #E6E7E9"  borderRadius="0.75rem" bg="white" p={4}>
                                                    <Text fontSize="12px" fontWeight="500" color="#6A707D">
                                                      Body temperature
                                                    </Text>
                                                    <Text display="inline-block" fontSize="22px" fontWeight="600">
                                                      98.6
                                                    </Text>
                                                    <Text
                                                      display="inline-block"
                                                      fontSize="15px"
                                                      pl={"5px"}
                                                      fontWeight="400"
                                                    >
                                                      °F
                                                    </Text>
                                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                                        <Box>
                                                        <Text fontSize="12px" fontWeight="500" color="#6A707D">
                                                            May 15, 2024
                                                        </Text>
                                                        </Box>
                                                        <Box display="flex" alignItems="center" gap="0.25rem">
                                                        <Image w="12px" h="auto" src="/assets/imgs/check.png" />
                                                        <Text as="span" fontSize="12px" fontWeight="600" ml="2px">
                                                            OK
                                                        </Text>
                                                        </Box>
                                                    </Box>
                                                  </Box>
                                                  <Box border="1px solid #E6E7E9"  borderRadius="0.75rem" bg="white" p={4}>
                                                    <Text fontSize="12px" fontWeight="500" color="#6A707D">
                                                      Body temperature
                                                    </Text>
                                                    <Text display="inline-block" fontSize="22px" fontWeight="600">
                                                      98.6
                                                    </Text>
                                                    <Text
                                                      display="inline-block"
                                                      fontSize="15px"
                                                      pl={"5px"}
                                                      fontWeight="400"
                                                    >
                                                      °F
                                                    </Text>
                                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                                        <Box>
                                                        <Text fontSize="12px" fontWeight="500" color="#6A707D">
                                                            May 15, 2024
                                                        </Text>
                                                        </Box>
                                                        <Box display="flex" alignItems="center" gap="0.25rem">
                                                        <Image w="12px" h="auto" src="/assets/imgs/check.png" />
                                                        <Text as="span" fontSize="12px" fontWeight="600" ml="2px">
                                                            OK
                                                        </Text>
                                                        </Box>
                                                    </Box>
                                                  </Box>
                                                  <Box border="1px solid #E6E7E9"  borderRadius="0.75rem" bg="white" p={4}>
                                                    <Text fontSize="12px" fontWeight="500" color="#6A707D">
                                                      Body temperature
                                                    </Text>
                                                    <Text display="inline-block" fontSize="22px" fontWeight="600">
                                                      98.6
                                                    </Text>
                                                    <Text
                                                      display="inline-block"
                                                      fontSize="15px"
                                                      pl={"5px"}
                                                      fontWeight="400"
                                                    >
                                                      °F
                                                    </Text>
                                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                                        <Box>
                                                        <Text fontSize="12px" fontWeight="500" color="#6A707D">
                                                            May 15, 2024
                                                        </Text>
                                                        </Box>
                                                        <Box display="flex" alignItems="center" gap="0.25rem">
                                                        <Image w="12px" h="auto" src="/assets/imgs/check.png" />
                                                        <Text as="span" fontSize="12px" fontWeight="600" ml="2px">
                                                            OK
                                                        </Text>
                                                        </Box>
                                                    </Box>
                                                  </Box>
                                                </Flex>
                                              </>}
                                            /> */}
                          <ToggleTab
                            title="Vitals"
                            body={
                              <>
                                {data && data.vitals.length > 0 ? (
                                  <Flex flexDir="column" gap="0.75rem">
                                    {data.vitals.map((vital, index) => (
                                      <Box
                                        key={index}
                                        border="1px solid #E6E7E9"
                                        borderRadius="0.75rem"
                                        bg="white"
                                        p={4}
                                      >
                                        <Text
                                          fontSize="12px"
                                          fontWeight="500"
                                          color="#6A707D"
                                        >
                                          {vital.vitalname}
                                        </Text>
                                        <Text
                                          display="inline-block"
                                          fontSize="22px"
                                          fontWeight="600"
                                        >
                                          {vital.vitalvalue}
                                        </Text>
                                        <Text
                                          display="inline-block"
                                          fontSize="15px"
                                          pl={"5px"}
                                          fontWeight="400"
                                        >
                                          {vital.vitalunit}
                                        </Text>
                                        <Box
                                          display="flex"
                                          justifyContent="space-between"
                                          alignItems="center"
                                        >
                                          <Box>
                                            <Text
                                              fontSize="12px"
                                              fontWeight="500"
                                              color="#6A707D"
                                            >
                                              {formatDate(vital.vitaladdeddate)}
                                            </Text>
                                          </Box>
                                          <Box
                                            display="flex"
                                            alignItems="center"
                                            gap="0.25rem"
                                          >
                                            {
                    vital.status === 'High' ? (
                      <>
                      <Image w="12px" h="auto" color='#F04438' src="/assets/imgs/arrow_upward.png" />
                      <Text as="span" fontSize="12px" fontWeight="600" ml="2px" color='#F04438'>{vital.status}</Text>
                      </>
                    ) : vital.status === 'Normal' ? (
                      <>
                      <Image w="12px" h="auto" color='#6A707D' src="/assets/imgs/check.png" />
                      <Text as="span" fontSize="12px" fontWeight="600" ml="2px" color='#6A707D'>{vital.status}</Text>
                      </>
                    ) : vital.status === 'normal' ? (
                      <>
                      <Image w="12px" h="auto" color='#6A707D' src="/assets/imgs/check.png" />
                      <Text as="span" fontSize="12px" fontWeight="600" ml="2px" color='#6A707D'>{vital.status}</Text>
                      </>
                    ) : vital.status === 'Low' ? (
                      <>
                      <Image w="12px" h="auto" color='#D46F11' src="/assets/svgs/arrow_upward.svg" />
                      <Text as="span" fontSize="12px" fontWeight="600" ml="2px" color='#D46F11'>{vital.status}</Text>
                      </>
                    ): ('')
                  }
                                          </Box>
                                        </Box>
                                      </Box>
                                    ))}
                                  </Flex>
                                ) : (
                                  <Text>No records found</Text>
                                )}
                              </>
                            }
                          />

                          {/* Previous code */}
                          {/* <ToggleTab title="Investigations" 
                                              body={<>
                                                <Flex flexDir="column" gap="0.75rem">
                                                  <Box
                                                    border="1px solid #E6E7E9"
                                                    bg="white"
                                                    w="100%"
                                                    borderRadius="0.75rem"
                                                    p={4}
                                                  >
                                                    <Text fontSize="12px" fontWeight="500" color="#6A707D">
                                                      White blood cells
                                                    </Text>
                                                    <Text display="inline-block" fontSize="22px" fontWeight="600">
                                                      10
                                                    </Text>
                                                    <Text
                                                      display="inline-block"
                                                      fontSize="15px"
                                                      pl={"5px"}
                                                      fontWeight="400"
                                                    >
                                                      10^3/µl
                                                    </Text>
                                                    <Text fontSize="12px" fontWeight="500" color="#6A707D">
                                                      May 15, 2024
                                                    </Text>
                                                  </Box>
                                                  <Box
                                                    border="1px solid #E6E7E9"
                                                    bg="white"
                                                    w="100%"
                                                    borderRadius="0.75rem"
                                                    p={4}
                                                  >
                                                    <Text fontSize="12px" fontWeight="500" color="#6A707D">
                                                      White blood cells
                                                    </Text>
                                                    <Text display="inline-block" fontSize="22px" fontWeight="600">
                                                      10
                                                    </Text>
                                                    <Text
                                                      display="inline-block"
                                                      fontSize="15px"
                                                      pl={"5px"}
                                                      fontWeight="400"
                                                    >
                                                      10^3/µl
                                                    </Text>
                                                    <Text fontSize="12px" fontWeight="500" color="#6A707D">
                                                      May 15, 2024
                                                    </Text>
                                                  </Box>
                                                  <Box
                                                    border="1px solid #E6E7E9"
                                                    bg="white"
                                                    w="100%"
                                                    borderRadius="0.75rem"
                                                    p={4}
                                                  >
                                                    <Text fontSize="12px" fontWeight="500" color="#6A707D">
                                                      White blood cells
                                                    </Text>
                                                    <Text display="inline-block" fontSize="22px" fontWeight="600">
                                                      10
                                                    </Text>
                                                    <Text
                                                      display="inline-block"
                                                      fontSize="15px"
                                                      pl={"5px"}
                                                      fontWeight="400"
                                                    >
                                                      10^3/µl
                                                    </Text>
                                                    <Text fontSize="12px" fontWeight="500" color="#6A707D">
                                                      May 15, 2024
                                                    </Text>
                                                  </Box>
                                                  <Box
                                                    border="1px solid #E6E7E9"
                                                    bg="white"
                                                    w="100%"
                                                    borderRadius="0.75rem"
                                                    p={4}
                                                  >
                                                    <Text fontSize="12px" fontWeight="500" color="#6A707D">
                                                      White blood cells
                                                    </Text>
                                                    <Text display="inline-block" fontSize="22px" fontWeight="600">
                                                      10
                                                    </Text>
                                                    <Text
                                                      display="inline-block"
                                                      fontSize="15px"
                                                      pl={"5px"}
                                                      fontWeight="400"
                                                    >
                                                      10^3/µl
                                                    </Text>
                                                    <Text fontSize="12px" fontWeight="500" color="#6A707D">
                                                      May 15, 2024
                                                    </Text>
                                                  </Box>
                                                </Flex>
                                                <PrimaryButton variant="grayBtnFull" btnStyle={{mt: "1rem", mb: "0.5rem"}} buttonText="View all" />
                                              </>}
                                            /> */}
                          <ToggleTab
                            title="Investigations"
                            body={
                              <>
                                {data && data.investigations.length > 0 ? (
                                  <Flex flexDir="column" gap="0.75rem">
                                    {data.investigations.map(
                                      (investigation, index) => (
                                        <Box
                                          key={index}
                                          border="1px solid #E6E7E9"
                                          bg="white"
                                          w="100%"
                                          borderRadius="0.75rem"
                                          p={4}
                                        >
                                          <Text
                                            fontSize="12px"
                                            fontWeight="500"
                                            color="#6A707D"
                                          >
                                            {investigation.eventtitle}
                                          </Text>
                                          <Text
                                            display="inline-block"
                                            fontSize="22px"
                                            fontWeight="600"
                                          >
                                            {investigation.actualresultvalue}
                                          </Text>
                                          <Text
                                            display="inline-block"
                                            fontSize="15px"
                                            pl={"5px"}
                                            fontWeight="400"
                                          >
                                            {investigation.resultuom
                                              ? investigation.resultuom
                                              : "N/A"}
                                          </Text>
                                          <Text
                                            fontSize="12px"
                                            fontWeight="500"
                                            color="#6A707D"
                                          >
                                            {formatDate(
                                              investigation.verifieddate
                                            )}
                                          </Text>
                                        </Box>
                                      )
                                    )}
                                  </Flex>
                                ) : (
                                  <Text>No records found</Text>
                                )}
                                {data && data.investigations.length > 0 && (
                                  <PrimaryButton
                                    variant="grayBtnFull"
                                    btnStyle={{ my: "0.5rem" }}
                                    buttonText="View all"
                                    onClick={() => {
                                                      navigate("/patientprofile?id="+ patientid + "&page=investigations")
                                                    }}
                                                    
                    isDisabled={actionAccessPatients && actionAccessPatients.read ? false : true }
                    opacity={actionAccessPatients && actionAccessPatients.read ? 1 : 0.5 }
                                  />
                                )}
                              </>
                            }
                          />

                          {/* Previous code */}
                          {/* <ToggleTab title="Labs" 
                                              body={<>
                                                <Box
                                                  bg="white"
                                                  w="100%"
                                                  border="1px solid #E6E7E9"
                                                  borderRadius="0.65rem"
                                                  p="1rem"
                                                  mb="1.5rem"
                                                  boxShadow="0px 8px 20px 0px #E6E7E966"
                                                >
                                                  <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    pb="0.5rem"
                                                  >
                                                    <Text fontSize="0.875rem" color="#061027">
                                                      Heart failure with preserved ejection fraction,
                                                      unspecified HF chronicity (CMS/HCC)
                                                    </Text>
                                                  </Box>
                                                  <Box
                                                    bg="#F9FAFB"
                                                    borderLeft="2px solid #14C585"
                                                    p="0.5rem"
                                                  >
                                                    <Text
                                                      fontSize="0.875rem"
                                                      fontWeight="500"
                                                      color="#384052"
                                                    >
                                                      Plan: Basic metabolic panel, Magnesium
                                                    </Text>
                                                  </Box>

                                                  <Divider my="1rem" />
                                                  <Box display="flex" gap="0.75rem">
                                                    <Box
                                                      display="flex"
                                                      gap="0.5rem"
                                                      p="0.5rem 1rem"
                                                      bg="white"
                                                      border="1px solid #E6E7E9"
                                                      borderRadius="0.65rem"
                                                    >
                                                      <Image
                                                        w="1.125rem"
                                                        h="auto"
                                                        src="/assets/svgs/pdf-icon.svg"
                                                      />
                                                      <Text fontSize="0.75rem" fontWeight="600">
                                                        Prescription.pdf
                                                      </Text>
                                                    </Box>
                                                  </Box>
                                                </Box>
                                                <PrimaryButton variant="grayBtnFull" btnStyle={{mt: "1rem", mb: "0.5rem"}} buttonText="View all" />
                                              </>}
                                            /> */}
                          <ToggleTab
                            title="Labs"
                            body={
                              <>
                                {data && data.labs.length > 0 ? (
                                  <Box
                                    bg="white"
                                    w="100%"
                                    border="1px solid #E6E7E9"
                                    borderRadius="0.65rem"
                                    p="1rem"
                                    mb="1.5rem"
                                    boxShadow="0px 8px 20px 0px #E6E7E966"
                                  >
                                    {data.labs.map((lab, index) => (
                                      <Box key={index} mb="1rem">
                                        <Box
                                          display="flex"
                                          justifyContent="space-between"
                                          alignItems="center"
                                          pb="0.5rem"
                                        >
                                          <Text
                                            fontSize="0.875rem"
                                            color="#061027"
                                          >
                                            {lab.reportname}
                                          </Text>
                                        </Box>
                                        <Box
                                          bg="#F9FAFB"
                                          borderLeft="2px solid #14C585"
                                          p="0.5rem"
                                        >
                                          <Text
                                            fontSize="0.875rem"
                                            fontWeight="500"
                                            color="#384052"
                                          >
                                            {lab.reportdescription}
                                          </Text>
                                        </Box>
                                        <Divider my="1rem" />
                                        {lab.documents &&
                                          lab.documents.length > 0 && (
                                            <Box display="flex" gap="0.75rem" cursor="pointer">
                                              {lab.documents.map(
                                                (document, idx) => (
                                                  <>
                                                  <Box
                                                    display="flex"
                                                    gap="0.5rem"
                                                    p="0.5rem 1rem"
                                                    bg="white"
                                                    border="1px solid #E6E7E9"
                                                    // borderRadius="0.65rem"
                                                    borderRadius= '10px 0 0 10px'
                                                    onClick={() =>
                                                      downloadDocument(
                                                        document.imagepath +
                                                          document.documenturl, 'report'
                                                      )
                                                    }
                                                  >
                                                    <Image
                                                      w="1.125rem"
                                                      h="auto"
                                                      cursor="pointer"
                                                      // src="/assets/svgs/pdf-icon.svg"
                                                    src={getIconForExtension(document.documentname)}
                                                    
                                                    />
                                                    <Text
                                                      fontSize="0.75rem"
                                                      fontWeight="600"
                                                    >
                                                      {/* Prescription.pdf */}
                                                         {`Document- ${idx + 1}`}
                                                    </Text>
                                                  </Box>

<Box
display="flex"
gap="0.5rem"
p="0.5rem 0.5rem"
bg="white"
border="1px solid #E6E7E9"
// borderRadius="0.65rem"
cursor='pointer'
onClick={() => {window.open(
  document.imagepath + document.documenturl, '_blank')}
}
position= 'relative'
left= '-13px'
alignItems='center'
borderRadius= '0 10px 10px 0'
>
  <Image
  w="1.125rem"
  // h="auto"
  src="/assets/imgs/show-pass.png"
  />
</Box>
                                                  </>
                                                )
                                              )}
                                            </Box>
                                          )}
                                      </Box>
                                    ))}
                                  </Box>
                                ) : (
                                  <Text>No records found</Text>
                                )}
                                {data && data.labs.length > 0 && (
                                  <PrimaryButton
                                    variant="grayBtnFull"
                                    btnStyle={{ my: "0.5rem" }}
                                    buttonText="View all"
                                    onClick={() => {
                                                      navigate("/patientprofile?id="+ patientid + "&page=labTest")
                                                    }}
                                                    
                    isDisabled={actionAccessPatients && actionAccessPatients.read ? false : true }
                    opacity={actionAccessPatients && actionAccessPatients.read ? 1 : 0.5 }
                                  />
                                )}
                              </>
                            }
                          />

                          {/* Previous code */}
                          {/* <ToggleTab title="imaging" 
                                              body={<>
                                                <Box
                                                  bg="white"
                                                  w="100%"
                                                  border="1px solid #E6E7E9"
                                                  borderRadius="0.65rem"
                                                  p="1rem"
                                                  mb="1.5rem"
                                                  boxShadow="0px 8px 20px 0px #E6E7E966"
                                                >
                                                  <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    pb="0.5rem"
                                                  >
                                                    <Text fontSize="0.875rem" color="#061027">
                                                      Heart failure with preserved ejection fraction,
                                                      unspecified HF chronicity (CMS/HCC)
                                                    </Text>
                                                  </Box>
                                                  <Box
                                                    bg="#F9FAFB"
                                                    borderLeft="2px solid #14C585"
                                                    p="0.5rem"
                                                  >
                                                    <Text
                                                      fontSize="0.875rem"
                                                      fontWeight="500"
                                                      color="#384052"
                                                    >
                                                      Plan: Basic metabolic panel, Magnesium
                                                    </Text>
                                                  </Box>

                                                  <Divider my="1rem" />
                                                  <Box display="flex" gap="0.75rem">
                                                    <Box
                                                      display="flex"
                                                      gap="0.5rem"
                                                      p="0.5rem 1rem"
                                                      bg="white"
                                                      border="1px solid #E6E7E9"
                                                      borderRadius="0.65rem"
                                                    >
                                                      <Image
                                                        w="1.125rem"
                                                        h="auto"
                                                        src="/assets/svgs/pdf-icon.svg"
                                                      />
                                                      <Text fontSize="0.75rem" fontWeight="600">
                                                         x-ray.pdf
                                                      </Text>
                                                    </Box>
                                                    <Box
                                                      display="flex"
                                                      gap="0.5rem"
                                                      p="0.5rem 1rem"
                                                      bg="white"
                                                      border="1px solid #E6E7E9"
                                                      borderRadius="0.65rem"
                                                    >
                                                      <Image
                                                        w="1.125rem"
                                                        h="auto"
                                                        src="/assets/svgs/pdf-icon.svg"
                                                      />
                                                      <Text fontSize="0.75rem" fontWeight="600">
                                                         x-ray.pdf
                                                      </Text>
                                                    </Box>
                                                  </Box>
                                                </Box>
                                                <PrimaryButton variant="grayBtnFull" btnStyle={{mt: "1rem", mb: "0.5rem"}} buttonText="View all" />
                                              </>}
                                            /> */}
                          <ToggleTab
                            title="imaging"
                            body={
                              <>
                                {data && data.imaging.length > 0 ? (
                                  data.imaging.map((image, index) => (
                                  <Box
                                    bg="white"
                                    w="100%"
                                    border="1px solid #E6E7E9"
                                    borderRadius="0.65rem"
                                    p="1rem"
                                    mb="1.5rem"
                                    boxShadow="0px 8px 20px 0px #E6E7E966"
                                  >
                                      <Box key={index} mb="1rem">
                                        <Box
                                          display="flex"
                                          justifyContent="space-between"
                                          alignItems="center"
                                          pb="0.5rem"
                                        >
                                          <Text
                                            fontSize="0.875rem"
                                            color="#061027"
                                          >
                                            {image.reportname}
                                          </Text>
                                        </Box>
                                        <Box
                                          bg="#F9FAFB"
                                          borderLeft="2px solid #14C585"
                                          p="0.5rem"
                                        >
                                          <Text
                                            fontSize="0.875rem"
                                            fontWeight="500"
                                            color="#384052"
                                          >
                                            {image.reportdescription}
                                          </Text>
                                        </Box>
                                        <Box
                          // bg="#F9FAFB"
                          // borderLeft="2px solid #14C585"
                          p="0.5rem"
                        >
                          <Text
                            fontSize="1rem"
                            fontWeight="600"
                            color="#384052"
                          >
                            Normal Results
                          </Text>
                          <Text
                            fontSize="0.875rem"
                            fontWeight="500"
                            color="#384052"
                          >
                            {image.normalresults}
                          </Text>
                        </Box>

                        <Box
                          // bg="#F9FAFB"
                          // borderLeft="2px solid #14C585"
                          p="0.5rem"
                        >
                          <Text
                            fontSize="1rem"
                            fontWeight="600"
                            color="#384052"
                          >
                            Abnormal Results
                          </Text>
                          <Text
                            fontSize="0.875rem"
                            fontWeight="500"
                            color="#384052"
                          >
                            {image.abnormalresults}
                          </Text>
                        </Box>

                        <Box
                          // bg="#F9FAFB"
                          // borderLeft="2px solid #14C585"
                          p="0.5rem"
                        >
                          <Text
                            fontSize="1rem"
                            fontWeight="600"
                            color="#384052"
                          >
                            Report Interpretition
                          </Text>
                          <Text
                            fontSize="0.875rem"
                            fontWeight="500"
                            color="#384052"
                          >
                            {image.reportinterpretation}
                          </Text>
                        </Box>
                                        <Divider my="1rem" />
                                        {image.documents &&
                                          image.documents.length > 0 && (
                                            <Box display="flex" gap="0.75rem" cursor="pointer">
                                              {image.documents.map(
                                                (document, idx) => (
                                                  <>
                                                  <Box
                                                    display="flex"
                                                    gap="0.5rem"
                                                    p="0.5rem 1rem"
                                                    bg="white"
                                                    border="1px solid #E6E7E9"
                                                    // borderRadius="0.65rem"
                                                    borderRadius= '10px 0 0 10px'
                                                    onClick={() =>
                                                      downloadDocument(
                                                        document.imagepath +
                                                          document.documentname
                                                      )
                                                    }
                                                  >
                                                    <Image
                                                      w="1.125rem"
                                                      h="auto"
                                                      src={getIconForExtension(document.documentname)}
                                                      // src="/assets/svgs/pdf-icon.svg"
                                                    />
                                                    <Text
                                                      fontSize="0.75rem"
                                                      fontWeight="600"
                                                    >
                                                      {/* x-ray.pdf */}
                                                      {`Document- ${idx + 1}`}
                                                    </Text>
                                                  </Box>

                                                  <Box
display="flex"
gap="0.5rem"
p="0.5rem 0.5rem"
bg="white"
border="1px solid #E6E7E9"
// borderRadius="0.65rem"
cursor='pointer'
onClick={() => {window.open(
  document.imagepath + document.documenturl, '_blank')}
}
position= 'relative'
left= '-13px'
alignItems='center'
borderRadius= '0 10px 10px 0'
>
  <Image
  w="1.125rem"
  // h="auto"
  src="/assets/imgs/show-pass.png"
  />
</Box>
                                                  </>
                                                )
                                              )}
                                              {/* <Box display="flex" gap="0.5rem" p="0.5rem 1rem" bg="white" border="1px solid #E6E7E9" borderRadius="0.65rem">
                                                            <Image w="1.125rem" h="auto" src="/assets/svgs/pdf-icon.svg" />
                                                            <Text fontSize="0.75rem" fontWeight="600">{image.documenturl}</Text>
                                                          </Box> */}
                                            </Box>
                                          )}
                                      </Box>
                                  </Box>
                                    ))
                                ) : (
                                  <Text>No records found</Text>
                                )}
                                {data && data.imaging.length > 0 && (
                                  <PrimaryButton
                                    variant="grayBtnFull"
                                    btnStyle={{ my: "0.5rem" }}
                                    buttonText="View all"
                                    onClick={() => {
                                      navigate("/patientprofile?id="+ patientid + "&page=imaging")
                                    }}
                                    
                    isDisabled={actionAccessPatients && actionAccessPatients.read ? false : true }
                    opacity={actionAccessPatients && actionAccessPatients.read ? 1 : 0.5 }
                                  />
                                )}
                              </>
                            }
                          />
                        </Stack>
                      </VStack>
                    </Box>
                  </Box>
                  {/* Recent Activity end */}

                  {/* Right area end */}
                </Box>
                {/* No data section */}
                {/* <Box display="flex" justifyContent="center" flexDir="column" gap="10px" alignItems="center" h="100vh">
            <Image src="/assets/svgs/no-chats.svg"/>
            <Text>No chats</Text>
            </Box> * /}
            {/* Main container end */}
              </Flex>
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                flexDir="column"
                gap="10px"
                alignItems="center"
                h="100vh"
              >
                <Image src="/assets/svgs/no-chats.svg" />
                <Text>No chats</Text>
              </Box>
            )}
          </Box>
        </Flex>
      </Box>

      {/* Mobile responsive */}
      <Box w="100%" display={{base: "block", lg: "none"}}>
        <Flex flexDir='column'>
          {isLoading && <Loader />}
          {isDoctorListVisible ? (
          <>
          <MobileNavbar />
          
          <Box w="100%">
            {/* Main container start */}
            {patientsdata && patientsdata != null ? (
              <Flex w="100%">
                <Box
                  display="flex"
                  w="100%"
                >
                  {/* Left area start */}
                  <Box w="100%" h="100vh" borderRight="1px solid #E6E7E9">
                    <Box w="100%" p="1.5rem" borderBottom="1px solid #E6E7E9">
                      <SearchBar
                        passInputPlace="Search"
                        onChange={(event) => setSearch(event.target.value)}
                      />
                    </Box>

                    {/* Doctor list start */}
                    <Box h="calc(100vh - 200px)" overflow="hidden auto">
                     {patientsdata && patientsdata.length > 0 ? (
                        patientsdata.filter(step => 
                            step.patientname.toLowerCase().includes(search.toLowerCase())
                        ).length > 0 ? (
                            patientsdata.filter(step => 
                                step.patientname.toLowerCase().includes(search.toLowerCase())
                            ).map((step, index) => (
                            <Flex
                              key={index}
                              w="100%"
                              // bg={step.patientid == activechatbox? "#E6E7E9": "white"}
                              onClick={() => openchat(step, "current")}
                              as="button"
                              flexDir="column"
                              p="20px 32px"
                              borderBottom="1px solid #E6E7E9"
                            >
                              <Box display="flex" w="100%">
                                {/* <Image w="44px" h="44px" src={step.patientimage? step.imagepath + step.patientimage:"/assets/imgs/no-image.png"} borderRadius="full" /> */}
                                <Box boxSize="2.75rem">
                                  <Image
                                    boxSize="100%"
                                    h="40px"
                                    objectFit="cover"
                                    src={
                                      step.patientimage
                                        ? step.imagepath + step.patientimage
                                        : "/assets/imgs/no-image.png"
                                    }
                                    borderRadius="full"
                                  />
                                </Box>
                                <Flex
                                  gap="10px"
                                  justifyContent="space-between"
                                  w="100%"
                                  alignItems="start"
                                >
                                  <Box pl="10px" w="80%" textAlign="left">
                                    <Box
                                      as="h2"
                                      className="textOverflow"
                                      color="#061027"
                                      fontSize="1rem"
                                      fontWeight="600"
                                    >
                                      {step.patientname
                                        ? step.patientname
                                        : "-"}
                                    </Box>
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      gap="0.25rem"
                                    >
                                      <Image src="/assets/svgs/home-calendar.svg" />
                                      <Text
                                        color="#384052"
                                        fontSize="0.75rem"
                                        textAlign="left"
                                        fontWeight="500"
                                      >
                                        {formatDate(step.appointmentdate)}
                                      </Text>
                                    </Box>
                                  </Box>
                                  {step.countofunreadmessages > 0 && (
                                    <Box>
                                      <Text
                                        bg="#0B0B0D"
                                        p="3px 6px"
                                        w="30px"
                                        h="20px"
                                        color="white"
                                        textAlign="center"
                                        fontSize="10px"
                                        fontWeight="600"
                                        borderRadius="full"
                                      >
                                        {step.countofunreadmessages > 0
                                          ? step.countofunreadmessages
                                          : ""}
                                      </Text>
                                    </Box>
                                  )}
                                </Flex>
                              </Box>
                              <Box w="90%">
                                <Text
                                  pt="10px"
                                  fontSize="14px"
                                  fontWeight="500"
                                  textAlign="left"
                                  className="textOverflow"
                                  title={step.chiefcomplaint
                                    ? step.chiefcomplaint
                                    : "-"}
                                >
                                  {step.chiefcomplaint
                                    ? step.chiefcomplaint
                                    : "-"}
                                </Text>
                              </Box>
                            </Flex>
                        ))
                      ) : (
                          <>
                          <Box display="flex" justifyContent="center" alignItems="center" h="calc(100vh - 160px)" flexDir="column" gap="1rem">
                              <Image src="/assets/svgs/no-patient.svg" />
                              <Text fontSize="1rem" fontWeight="500" color="#6A707D">No patient found</Text>
                          </Box>
                          </>
                      )
                  ) : (
                      <>
                          <Image src="/assets/svgs/no-patient.svg" />
                          <Text fontSize="1rem" fontWeight="500" color="#6A707D">No patient found</Text>
                      </>
                  )}
                    </Box>
                  </Box>
                  {/* Left area end */}
                </Box>
              </Flex>
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                flexDir="column"
                gap="10px"
                alignItems="center"
                h="100vh"
              >
                <Image src="/assets/svgs/no-chats.svg" />
                <Text>No chats</Text>
              </Box>
            )}
          </Box>
          </>
          ) : (
            <>
              <MobileSecondaryNav 
                handlebackarrow={goBackToDoctorList}
                header={<>
                  <Box cursor="pointer"  onClick={() => manageModel.current.openModal()}>
                    <Box as="h2" color="black" fontSize="1rem" fontWeight="600">
                    {ChatHeader && ChatHeader.patientname ? ChatHeader.patientname : "-"}
                    </Box>
                    <Text color="black" fontSize="10px" fontWeight="500">
                      {`${formatDate(ChatHeader && ChatHeader.appointmentdate)} at ${formattedAppointmentTime}`}
                    </Text>
                  </Box>
                </>}

                rightArea={<>
                  <Box as="button" onClick={handlePageChange}>
                    <Image
                      w="40px"
                      h="40px"
                      src="/assets/svgs/patient.svg"
                      borderRadius="full"
                    />
                  </Box>
                </>}
              />
              <Flex flexDir="column" h="calc(100vh - 140px)">
                <Box
                  ref={chatContainerRef}
                  overflowY="auto"
                  p="1rem"
                  className="chat-section"
                >
                  {chatsdata && chatsdata.map((msg, index)=> {
                    const createdDate = moment(msg.createddate);
                const showDate = index === 0 || !moment(chatsdata[index - 1].createddate).isSame(msg.createddate, 'day');

                return (
                  <React.Fragment key={index}>
                    {showDate && (
                      <Text borderRadius="full" p="4px 12px" w="90px" my="10px" textAlign='center' zIndex="9" mx="auto" pos="sticky"  top="0"  bg="white" fontSize="10px" fontWeight="600" border="1px solid #E6E7E9">
                        {renderDate(createdDate)}
                        </Text>
                      // <Text fontSize="12px" fontWeight="bold" color="#6A707D" textAlign="center" mb="4px">
                        
                      // </Text>
                    )}
                    <Stack direction="row" justifyContent={msg.ispatient && msg.ispatient === 1 ? "start" : "end"} overflow='hidden auto'>
                      <Box>
                        <Box 
                          bg={msg.ispatient && msg.ispatient === 1 ? "#E6E7E980" : msg.chattype === "image" ? "" : "#061027"}
                          maxW={msg.chattype === "image" ? "13.75rem" : "15rem"}
                          maxH={msg.chattype === "image" ? "13.75rem" : " "}
                          wordWrap="break-word"
                          p={msg.chattype === "document" ? "2px" : "12px 16px"}
                          w="fit-content"
                          borderRadius={msg.ispatient && msg.ispatient === 1 ? "10px 10px 10px 1px" : "10px 10px 1px 10px"}
                        >
                          {msg.chattype === "image" ? (
                            <Card w="13rem" h="13rem" overflow="hidden" p="0" justifyContent="center">
                              <Image src={`${msg.imagepath}${msg.chat}`} onClick={() => window.open(msg.imagepath + msg.chat, '_blank')} objectFit="cover" borderRadius={msg.ispatient && msg.ispatient === 1 ? "10px 10px 10px 1px" : "10px 10px 1px 10px"} />
                            </Card>
                          ) : msg.chattype === "document" ? (
                            <Card w="285px" h="194px" overflow="hidden" p="0">
                              <Box h="60%" mx="auto">
                                <Image src="/assets/imgs/pdf-image.jpeg" alt="Document" onClick={() => window.open(msg.imagepath + msg.chat, '_blank')} cursor="pointer" w="140px" h="125px" />
                              </Box>
                              <CardFooter h="40%" justify="space-between" bg={msg.ispatient && msg.ispatient === 1 ? "#EBECED" : "#061027"} flexWrap="wrap">
                                <Box display="flex" w="100%" justifyContent="space-between" alignItems="center">
                                  <Box>
                                    <Text fontSize="14px" fontWeight="600" color={msg.ispatient && msg.ispatient === 1 ? "#384052" : "#E6E7E9"}>Document.pdf</Text>

                                  </Box>
                                  <Box cursor="pointer" onClick={() => downloadDocument(msg.imagepath + msg.chat, "Document.pdf")} boxSize="36px" borderRadius="full" display="flex" justifyContent="center" alignItems="center" bg={msg.ispatient && msg.ispatient === 1 ? "white" : "#0B1F4F"}>

                                    <Image src={msg.ispatient && msg.ispatient === 1 ? "/assets/imgs/download.png" : "/assets/imgs/download-white.png"} />
                                  </Box>
                                </Box>
                              </CardFooter>
                            </Card>
                          ) : (
                            <Text color={msg.ispatient === 1 ? "#061027" : "white"} fontSize="14px" fontWeight="500">
                              {msg.chat ? msg.chat : ""}
                            </Text>
                          )}
                        </Box>
                        <Box as="span" fontSize="10px" my="3px" fontWeight="500" color="#6A707D" float={msg.ispatient && msg.ispatient === 1 ? "left" : "right"}>
                          {msg.formattedtime ? msg.formattedtime : ""}
                        </Box>
                      </Box>
                    </Stack>
                  </React.Fragment>
                );
                })}
                  {/* Video Call Box */}
                  {/* <Box maxW="345px" boxShadow="0px 8px 20px 0px #E6E7E966" bg="white" border="1px solid #E6E7E9" borderRadius="12px" p="16px">
                    <Flex gap="15px">
                    <Box>
                        <Image borderRadius="full" w="40px" src="/assets/imgs/no-image.png" />
                    </Box>
                    <Box display="flex" flexDir="column" textAlign="left">
                        <Text fontSize="14px" fontWeight="500" color="#061027">Join video consulation with</Text>
                        <Text fontSize="14px" fontWeight="500" color="#061027">Dr. Samantha Raulf</Text>
                        <Text fontSize="12px" fontWeight="500" color="#384052">10m 30s</Text>
                    </Box>
                    </Flex>
                    <PrimaryButton btnStyle={{m: "0", mt: "10px", boxShadow:"0px 8px 20px 0px #E6E7E966"}}  btnIcon="/assets/imgs/videocam.png" buttonText="Join call"/>
                    <PrimaryButton isDisabled={true} btnStyle={{m: "0", mt: "10px", boxShadow:"0px 8px 20px 0px #E6E7E966", _disabled: {color: "#898D97", bg: COLORS.BTN_DISABLE,_hover: {bg: COLORS.BTN_DISABLE}}}}  btnIcon="/assets/imgs/videocam.png" btnDisabledIcon="/assets/imgs/videocam-gray.png" buttonText="Call ended"/>
                </Box> */}
                </Box>
                {/* <TypingLoader /> */}
                <Spacer />
                {/* Questions */}
                <Box display="flex" alignItems="center" gap="10px" bg="none" p="16px 24px" px="0.75rem" borderTop='1px solid #E6E7E9' className="mobileBtn">
                  <Box onClick={() => {navigate(`/visitdetails?patientId=${patientid}&appointmentId=${ChatHeader?.appointmentid}`);localStorage.setItem("CurrentVisitAppointmentId", ChatHeader?.appointmentid);  }}
                    disabled={ actionAccessVisits && (!actionAccessVisits.update) ? true : false} 
                    opacity={actionAccessVisits && (!actionAccessVisits.update) ? "0.5" : ""}
                     bg="#E6E7E9" as="button" border="1px solid #A5A5A6" borderRadius="full" p="8px 12px" whiteSpace="nowrap" color="#0B0B0D" fontSize="12px" fontWeight="600">Reschedule appointment</Box>
                  
                  <Box onClick={() => {navigate(`/visitdetails?patientId=${patientid}&appointmentId=${ChatHeader?.appointmentid}`);localStorage.setItem("CurrentVisitAppointmentId", ChatHeader?.appointmentid);  }} 
                  disabled={ actionAccessVisits && (!actionAccessVisits.read) ? true : false} 
                  opacity={actionAccessVisits && (!actionAccessVisits.read) ? "0.5" : ""}
                     bg="#E6E7E9" as="button" border="1px solid #A5A5A6" borderRadius="full" p="8px 12px" whiteSpace="nowrap" color="#0B0B0D" fontSize="12px" fontWeight="600">View booking details</Box>
                  
                  <NavLink to={`/patientprofile?id=${patientid} &page=overview`}>
                  <Box  disabled={ actionAccessPatients && (!actionAccessPatients.read) ? true : false} 
                             opacity={actionAccessPatients && (!actionAccessPatients.read) ? "0.5" : ""}
                   bg="#E6E7E9" as="button" border="1px solid #A5A5A6" borderRadius="full" p="8px 12px" whiteSpace="nowrap" color="#0B0B0D" fontSize="12px" fontWeight="600">
                    View patient profile</Box></NavLink>

                </Box> 
                {/* Attachments */}
                <Box
                  bg="white"
                  minH="80px"
                  maxH="7.5rem"
                  borderTop="1px solid #E6E7E9"
                >
                  <Flex
                    w="100%"
                    p="1rem 1.25rem"
                    gap="15px"
                    justifyContent="center"
                    alignItems="center"
                    maxH="8rem"
                  >
                    <Menu>
                      {({ isOpen }) => (
                        <>
                          <MenuButton as="button" isActive={isOpen}  disabled={ actionAccess && (!actionAccess.create || !actionAccess.update) ? true : false}>
                            <Box
                              as="button"
                              p="12px 18px"
                              w="3rem"
                              h="3rem"
                              bg="#E6E7E9"
                              display="flex"
                              alignItems="center"
                              border="1px solid #A5A5A6"
                              borderRadius="full"
                              opacity={actionAccess && (!actionAccess.create || !actionAccess.update) ? "0.25" : ""}
                                      
                            >
                              <Image
                                w="11px"
                                src={
                                  isOpen
                                    ? "/assets/svgs/close.svg"
                                    : "/assets/svgs/attach_file.svg"
                                }
                              />
                            </Box>
                          </MenuButton>
                          <MenuList
                            p="2px"
                            minW="160px"
                            mr="25px"
                            zIndex="overlay"
                            boxShadow="0px 8px 24px 0px #B4B4B452"
                          >
                            <MenuItem
                              onClick={handleuploadDocumentAttachment}
                              fontSize="0.875rem"
                              fontWeight="500"
                              color="#061027"
                              sx={{ _hover: { bg: "#E4E4E7" } }}
                            >
                              <Input
                                type="file"
                                accept=".pdf,.txt"
                                hidden
                                id="uploaddocument"
                                onChange={(e) => uploaddocument(e)}
                              />
                              <Box
                                border="1px solid #E6E7E9"
                                onClick={handleuploadDocumentAttachment}
                                id="uploaddocument"
                                w="1.75rem"
                                h="1.75rem"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                mr="5px"
                                borderRadius="full"
                                p="5px"
                              >
                                <Image src="/assets/svgs/upload-document.svg" />
                              </Box>
                              Documents
                            </MenuItem>

                            <MenuItem
                              onClick={handleuploadImageAttachment}
                              fontSize="0.875rem"
                              fontWeight="500"
                              color="#061027"
                              sx={{ _hover: { bg: "#E4E4E7" } }}
                            >
                              <Input
                                type="file"
                                accept=".png,.jpg,.jpeg"
                                hidden
                                id="uploadimage"
                                onChange={(e) => uploadimages(e)}
                              />
                              <Box
                                onClick={handleuploadImageAttachment}
                                id="uploadimage"
                                border="1px solid #E6E7E9"
                                w="1.75rem"
                                h="1.75rem"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                mr="5px"
                                borderRadius="full"
                                p="5px"
                              >
                                <Image src="/assets/svgs/upload-image.svg" />
                              </Box>
                              Images
                            </MenuItem>
                          </MenuList>
                        </>
                      )}
                    </Menu>
                    <Box w="100%">
                      {/* <InputGroup> */}
              <Box tabIndex="-1" w="100%" bg="#FCFCFD" border="1px solid #E6E7E9" borderRadius="1rem" display="flex" alignItems="flex-end">
                  {/* <Box
                    p={2}
                    bg="#FCFCFD"
                    borderRadius="1rem"
                    minHeight="3rem"
                    maxHeight="6.25rem"
                    overflow="hidden"
                    userSelect="text"
                    w="100%"
                    whiteSpace="pre-wrap"
                    wordBreak="break-word"
                    role="textbox"
                    contentEditable = {actionAccess && (!actionAccess.create || !actionAccess.update) ? false : true}
                         
                    spellCheck="true"
                    ref={messageRef}
                    aria-label="Type a message"
                    tabIndex={10}
                    display="flex"
                    flexDirection="column"
                    _focus={{ boxShadow: "none", outline: "none" }}
                    onKeyDown={handlemessageKeyPress}
                    onInput={handleInputChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    color={!isFocused && isEmpty ? '#888888' : '#061027'}
                    cursor={ actionAccess && (!actionAccess.create || !actionAccess.update) ? "not-allowed" : "text"} // Change cursor if disabled
                       
                  >
                      {!isFocused && isEmpty && (
                        <span style={{ pointerEvents: 'none', color: '#888888' }}>
                          Type a message...
                        </span>
                      )}
                  </Box> */}
                   <Textarea
                            key={activechatbox}
                            ref={messageRef}
                            //value={messageValue}
                            onKeyDown={handlemessageKeyPress}
                            onInput={handleInputChange}
                            className="chatInput"
                            onChange={handleInput}
                            // height={chatInputHeight}
                            minH="unset"
                            maxH="100px"
                            borderRadius="1rem"
                            overflowY="auto"
                            border="none"
                            resize="none"
                            // color={!isFocused && isEmpty ? '#888888' : '#061027'}
                            _focus={{ boxShadow: "none", outline: "none" }}
                            placeholder="Type a message..."
                            
                            disabled={actionAccess && (!actionAccess.create ) ? true : false}
                          />
                  {/* <Box as="button"  disabled={actionAccess && (!actionAccess.create || !actionAccess.update) ? true : false} onClick={sendmessage}  
                          bg={actionAccess && (!actionAccess.create || !actionAccess.update) ? "#E6E7E9" : "#0B0B0D"} w="2rem" h="2rem" borderRadius="full" 
                          position="unset" bottom="8px" right="-8px" p="10px" mr="10px" mb="8px" userSelect="none" contentEditable="false">
                            <Image src="/assets/svgs/send-button.svg" /> 
                          </Box> */}
                          <Box
                            as="button"
                            disabled={actionAccess && (!actionAccess.create ) ? true : false}
                            onClick={sendmessage}
                            bg={actionAccess && (!actionAccess.create) ? "#E6E7E9" : "#0B0B0D"}
                            w="2rem"
                            h="2rem"
                            borderRadius="full"
                            p="10px"
                            m="5px 10px 5px 5px"
                            userSelect="none"
                          >
                            <Image src="/assets/svgs/send-button.svg" />
                          </Box>
                </Box>
                    </Box>
                  </Flex>
                </Box>
              </Flex>
            </>
          )}
        </Flex>
      </Box>
      
      <DynamicModal
        ref={manageModel}
        modalHeader="Manage"
        modalBody={
          <>
          {ChatHeader && (
            <Box>
              <Box
                as="button"
                onClick={() => startVisit.current.openModal()}
                w="100%"
                pb="1rem"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom="1px solid #E6E7E9"
                isDisabled={
                  actionAccessAppointment && !actionAccessAppointment.create ? true : false
                }
                opacity={ actionAccessAppointment && (!actionAccessAppointment.create) ? 0.5 : 1}
              >
                <Text fontSize="1rem" fontWeight="500">
                  Start visit
                </Text>
                <Image src="/assets/imgs/right-icon.png" />
              </Box>
              <Box
                as="button"
                w="100%"
                onClick={() => scheduleVisit.current.openModal()}
                py="1rem"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom="1px solid #E6E7E9"
                disabled={
                  actionAccessVisits && !actionAccessVisits.update ? true : false
                }
                opacity={ actionAccessVisits && (!actionAccessVisits.update) ? 0.5 : 1}
              >
                <Text fontSize="1rem" fontWeight="500">
                  Reschedule visit
                </Text>
                <Image src="/assets/imgs/right-icon.png" />
              </Box>
              <Box
                as="button"
                w="100%"
                onClick={() => cancelVisit.current.openModal()}
                pt="1rem"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                color="#CA3A31"
                disabled={
                  actionAccessVisits && !actionAccessVisits.delete ? true : false
                }
                opacity={ actionAccessVisits && (!actionAccessVisits.delete) ? 0.5 : 1}
              >
                <Text fontSize="1rem" fontWeight="500">
                  Cancel appointment
                </Text>
                <Image src="/assets/imgs/right-icon-red.png" />
              </Box>
            </Box>

          )}
          </>
        }
        hasFooter={false}
      />

      <DynamicModal
        ref={scheduleVisit}
        modalHeader="Manage"
        modalBody={
          <>
            <Box>
              <Box
                as="button"
                w="100%"
                onClick={() => navigate(`/visitdetails?patientId=${encodeURIComponent(patientid)}&appointmentId=${encodeURIComponent(ChatHeader?.appointmentid)}&openRescheduleModal=true`)}
                pb="1rem"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom="1px solid #E6E7E9"
                disabled={
                  actionAccessVisits && !actionAccessVisits.create ? true : false
                }
                opacity={ actionAccessVisits && (!actionAccessVisits.create) ? 0.5 : 1}
              >
                <Text fontSize="1rem" fontWeight="500">
                  Schedule visit
                </Text>
                <Image src="/assets/imgs/right-icon.png" />
              </Box>
              <Box
                as="button"
                w="100%"
                onClick={() => closeChat.current.openModal()}
                pt="1rem"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                color="#CA3A31"
              >
                <Text fontSize="1rem" fontWeight="500">
                  Close chat
                </Text>
                <Image src="/assets/imgs/right-icon-red.png" />
              </Box>
            </Box>
          </>
        }
        hasFooter={false}
      />

      <DynamicModal
        ref={cancelVisit}
        modalHeader="Cancel visit"
        modalBody={
          <>
            <Box>
              <Text fontSize="0.87rem" fontWeight="400">
                Are you sure you want to cancel your visit with{" "}
                <Box as="span" fontWeight="500">
                {ChatHeader && ChatHeader.patientname
                                  ? ChatHeader.patientname
                                  : "-"}
                </Box>{" "}
                scheduled on{" "}
                <Box as="span" fontWeight="500">
                {`${formatDate(ChatHeader && ChatHeader.appointmentdate)} at ${formatDateAndTime(ChatHeader && ChatHeader.appointmentdate)}?`}
                </Box>
              </Text>
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton variant="deleteBtn" buttonText="Cancel visit" 
            onClick={handleConfirmCancel}/>
          </>
        }
      />

      <DynamicModal
        ref={closeChat}
        modalHeader="Close chat"
        modalBody={
          <>
            <Box>
              <Text fontSize="0.87rem" fontWeight="400">
                {/* Are you sure you want to close your chat with “Laura Perez”? You
                can still view this chat in your messages, but the patient won't
                be able to send new messages unless they schedule a new visit
                with you. */}
                Are you sure you want to close your chat with “{ChatHeader && ChatHeader.patientname}”? You
                can still view this chat in your messages, but the patient won't
                be able to send new messages unless they schedule a new visit
                with you.
              </Text>
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton variant="deleteBtn" buttonText="Close chat" />
          </>
        }
      />

      <DynamicModal
        ref={startVisit}
        modalHeader="Start visit"
        modalBody={
          <>
            <Box>
              <Text fontSize="0.87rem" fontWeight="400">
                Are you sure you want to start this visit now? 
                {/* Your visit with{" "} */}
                {/* <Box as="span" fontWeight="500">
                  “Adnan Jaweed”
                </Box>{" "}
                was initially scheduled for{" "}
                <Box as="span" fontWeight="500">
                  Jan 10, 2024 at 9:30 AM.
                </Box> */}
              </Text>
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton buttonText="Start visit" onClick={handleButtonClick}/>
          </>
        }
      />

      <ModalRight
        ref={doctorProfile}
        modalWidth="480px"
        isBg={true}
        bodyStyle={{ p: "0" }}
        body={
          <>
            <Box bg="#0B0B0D" p="24px" pb="0">
              <Box display="flex" alignItems="center" gap="10px">
                <Box>
                  <Image
                    w="80px"
                    h="80px"
                    borderRadius="full"
                    src={ChatMedia && ChatMedia.patientimage? ChatMedia.imagepath+ChatMedia.patientimage:"/assets/imgs/no-image.png"}
                  />
                </Box>
                <Box display="flex" flexDir="column">
                  <Text fontSize="20px" fontWeight="600" color="white">
                    {ChatMedia && ChatMedia.patientname
                      ? ChatMedia.patientname
                      : "-"}
                  </Text>
                  <Box display="flex" gap="0.5rem">
                    <Image src="/assets/svgs/calendar-white.svg" />
                    <Text fontSize="14px" fontWeight="400" color="#E6E7E9">
                      Upcoming:{" "}
                      {formatDate(ChatMedia && ChatMedia.appointmentdate)} at{" "}
                      {ChatMedia && ChatMedia.appointmenttime
                        ? ChatMedia.appointmenttime
                        : "-"}
                    </Text>
                  </Box>
                </Box>
              </Box>
              <Box display="flex" gap="1rem">
                <Box
                  as="button"
                  className={activeTab == "images" ? "activeTab" : ""}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  fontSize="0.87rem"
                  fontWeight="600"
                  py="1rem"
                  onClick={() => setActiveTab("images")}
                  pos="relative"
                  color={activeTab == "images" ? "white" : "#A5A5A6"}
                >
                  <Text>Images</Text>
                  <Box as="span" className="whiteBorder"></Box>
                </Box>
                <Box
                  as="button"
                  className={activeTab == "voice" ? "activeTab" : ""}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  fontSize="0.87rem"
                  fontWeight="600"
                  py="1rem"
                  onClick={() => setActiveTab("voice")}
                  pos="relative"
                  color={activeTab == "voice" ? "white" : "#A5A5A6"}
                >
                  Voice<Box as="span" className="whiteBorder"></Box>
                </Box>
                <Box
                  as="button"
                  className={activeTab == "links" ? "activeTab" : ""}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  fontSize="0.87rem"
                  fontWeight="600"
                  py="1rem"
                  onClick={() => setActiveTab("links")}
                  pos="relative"
                  color={activeTab == "links" ? "white" : "#A5A5A6"}
                >
                  <Text>Links</Text>
                  <Box as="span" className="whiteBorder"></Box>
                </Box>
                <Box
                  as="button"
                  className={activeTab == "documents" ? "activeTab" : ""}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  fontSize="0.87rem"
                  fontWeight="600"
                  py="1rem"
                  onClick={() => setActiveTab("documents")}
                  pos="relative"
                  color={activeTab == "documents" ? "white" : "#A5A5A6"}
                >
                  <Text>Documents</Text>
                  <Box as="span" className="whiteBorder"></Box>
                </Box>
              </Box>
            </Box>
            <Box bg="#fbfbfc">
              <VStack>
                <Flex flexDir="column" w="100%">
                  {activeTab == "images" && (
                    <>
                      {ChatMedia && ChatMedia.image.length > 0 ? (
                        <>
                          {ChatMedia.image.map((img, index) => (
                            <Box>
                              <Box key={index} p="1.5rem" w="100%">
                                <Text
                                  textTransform="uppercase"
                                  fontSize="0.75rem"
                                  fontWeight="600"
                                >
                                  {formatDate(img.createddate)}
                                </Text>
                              </Box>
                              <Box
                                display="flex"
                                flexWrap="wrap"
                                w="100%"
                                h="100%"
                              >
                                <Box
                                  flex="1 0 33.333%"
                                  border="1px solid white"
                                  height="33.333%"
                                >
                                 <Image src={img.chat ? img.imagepath + img.chat : ""} 
                                  objectFit="cover" width="33.33%" height="33.33%" 
                                  onClick={() => {window.open(img.imagepath + img.chat, '_blank')}} />
                                </Box>
                              </Box>
                            </Box>
                          ))}
                        </>
                      ) : (
                        <Box
                          display="flex"
                          flexDir="column"
                          justifyContent="center"
                          alignItems="center"
                          gap="1.25rem"
                          h="calc(100vh - 160px)"
                        >
                          <Image src="/assets/svgs/no-images.svg" />
                          <Text
                            fontSize="1rem"
                            fontWeight="500"
                            color="#6A707D"
                          >
                            No images
                          </Text>
                        </Box>
                      )}
                    </>
                  )}

                  {activeTab == "voice" && (
                    <>
                      <Box p="1.5rem" w="100%">
                        <Text
                          textTransform="uppercase"
                          fontSize="0.75rem"
                          fontWeight="600"
                        >
                          jan 2024
                        </Text>
                      </Box>
                      <Box
                        display="flex"
                        flexDir="column"
                        gap="0.5rem"
                        w="100%"
                        px="1.5rem"
                      >
                        <Box
                          display="flex"
                          gap="0.65rem"
                          w="100%"
                          border="1px solid #E6E7E9"
                          borderRadius="0.75rem"
                          p="1rem"
                          bg="white"
                        >
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            bg="#0B0B0D"
                            borderRadius="full"
                            w="2.5rem"
                            h="2.5rem"
                          >
                            <Image src="/assets/svgs/play.svg" />
                          </Box>
                          <Box>
                            <Text fontSize="0.875rem" fontWeight="600">
                              You
                            </Text>
                            <Text
                              fontSize="0.75rem"
                              fontWeight="400"
                              color="#384052"
                            >
                              0:19 • Jan 1, 2024 at 12:40 PM
                            </Text>
                          </Box>
                        </Box>

                        <Box
                          display="flex"
                          flexDir="column"
                          gap="0.65rem"
                          w="100%"
                          border="1px solid #E6E7E9"
                          borderRadius="0.75rem"
                          p="1rem"
                          bg="white"
                        >
                          <Box display="flex" gap="0.65rem" w="100%">
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              bg="#0B0B0D"
                              borderRadius="full"
                              w="2.5rem"
                              h="2.5rem"
                            >
                              <Image src="/assets/svgs/pause.svg" />
                            </Box>
                            <Box>
                              <Text fontSize="0.875rem" fontWeight="600">
                                You
                              </Text>
                              <Text
                                fontSize="0.75rem"
                                fontWeight="400"
                                color="#384052"
                              >
                                0:19 • Jan 1, 2024 at 12:40 PM
                              </Text>
                            </Box>
                          </Box>
                          <Box
                            h="4px"
                            mt="5px"
                            w="100%"
                            borderRadius="full"
                            bg="#E6E7E9"
                          >
                            <Box
                              bg="#121316"
                              w="35%"
                              h="100%"
                              borderRadius="full"
                            ></Box>
                          </Box>
                        </Box>
                      </Box>
                      {/* <Box display="flex" flexDir="column" justifyContent="center" alignItems="center" gap="1.25rem" h="calc(100vh - 160px)">
                            <Image src="/assets/svgs/no-voice.svg" />
                            <Text fontSize="1rem" fontWeight="500" color="#6A707D">No images</Text>
                        </Box> */}
                    </>
                  )}

                  {activeTab == "links" && (
                    <>
                      <Box p="1.5rem" w="100%">
                        <Text
                          textTransform="uppercase"
                          fontSize="0.75rem"
                          fontWeight="600"
                        >
                          jan 2024
                        </Text>
                      </Box>
                      <Box
                        display="flex"
                        flexDir="column"
                        gap="0.5rem"
                        w="100%"
                        px="1.5rem"
                      >
                        <Box
                          display="flex"
                          gap="0.65rem"
                          w="100%"
                          border="1px solid #E6E7E9"
                          borderRadius="0.75rem"
                          p="1rem"
                          bg="white"
                        >
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            borderRadius="0.75rem"
                            w="2.5rem"
                            h="2.5rem"
                          >
                            <Image src="/assets/svgs/supa.svg" />
                          </Box>
                          <Box>
                            <Text fontSize="0.875rem" fontWeight="600">
                              You
                            </Text>
                            <Text
                              fontSize="0.75rem"
                              fontWeight="400"
                              color="#384052"
                            >
                              https://www.google.com
                            </Text>
                          </Box>
                        </Box>
                      </Box>

                      {/* <Box display="flex" flexDir="column" justifyContent="center" alignItems="center" gap="1.25rem" h="calc(100vh - 160px)">
                            <Image src="/assets/svgs/no-link.svg" />
                            <Text fontSize="1rem" fontWeight="500" color="#6A707D">No images</Text>
                        </Box> */}
                    </>
                  )}

                  {activeTab === "documents" && (
                    <>
                      {ChatMedia && ChatMedia.document.length > 0 ? (
                        <>
                          {ChatMedia.document.map((img, index) => (
                            <Box key={index} p="1.5rem" w="100%">
                              <Text
                                textTransform="uppercase"
                                fontSize="0.75rem"
                                fontWeight="600"
                              >
                                {formatDate(img.createddate)}
                              </Text>
                              <Box
                                display="flex"
                                flexDir="column"
                                gap="0.5rem"
                                w="100%"
                                px=""
                              >
                                <Box
                                  display="flex"
                                  w="100%"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  border="1px solid #E6E7E9"
                                  borderRadius="0.75rem"
                                  p="1rem"
                                  bg="white"
                                  boxShadow="0px 8px 20px 0px #E6E7E966"
                                >
                                  <Box display="flex" w="100%" gap="0.65rem" onClick={() => {window.open(img.imagepath + img.chat, '_blank')}}>
                                    <Box
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                      objectFit="contain"
                                      borderRadius="0.75rem"
                                      w="2.5rem"
                                      h="2.5rem"
                                    >
                                      <Image
                                        w="2.5rem"
                                        h="2.5rem"
                                        src="/assets/svgs/pdf-icon.svg"
                                        borderRadius="0.75rem"
                                      />
                                    </Box>
                                    <Box>
                                      <Text
                                        fontSize="0.875rem"
                                        fontWeight="600"
                                      >
                                        {img.chattype + ".pdf"}
                                      </Text>
                                      {/* <Text fontSize="0.75rem" fontWeight="400" color="#384052">24MB  •  Jan 1, 2024 at 12:40 PM</Text> */}
                                    </Box>
                                  </Box>

                                  <Box
                                    onClick={() =>
                                      downloadDocument(
                                        img.imagepath + img.chat,
                                        "Invoice"
                                      )
                                    }
                                    as="button"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    border="1px solid #E6E7E9"
                                    w="2.25rem"
                                    h="2.25rem"
                                    borderRadius="full"
                                  >
                                    <Image src="/assets/imgs/download.png" />
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          ))}
                        </>
                      ) : (
                        <Box
                          display="flex"
                          flexDir="column"
                          justifyContent="center"
                          alignItems="center"
                          gap="1.25rem"
                          h="calc(100vh - 160px)"
                        >
                          <Image src="/assets/svgs/no-documents.svg" />
                          <Text
                            fontSize="1rem"
                            fontWeight="500"
                            color="#6A707D"
                          >
                            No documents
                          </Text>
                        </Box>
                      )}
                    </>
                  )}
                </Flex>
              </VStack>
            </Box>
          </>
        }
      />
       <DynamicModal
        ref={requestpaymentmodal}
        modalHeader="Request Payment"
        modalBody={
          <>
            <Box>
              <Text fontSize="0.87rem" fontWeight="400">
                Are you sure you want to request payment of&nbsp;
                <strong>{ChatEnableDetails && ChatEnableDetails.fee? "$"+ formatToDecimal(ChatEnableDetails.fee):""}</strong>
              </Text>
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton onClick={RequestpaymentForPatient} buttonText="Request" />
          </>
        }
      />
      <DynamicModal
        ref={disablechatmodal}
        modalHeader="Disable Chat"
        modalBody={
          <>
            <Box>
              <Text fontSize="0.87rem" fontWeight="400">
                {/* Are you sure you want to close your chat with “Laura Perez”? You
                can still view this chat in your messages, but the patient won't
                be able to send new messages unless they schedule a new visit
                with you. */}
                Are you sure you want to disable the chat? You will no longer be able to receive messages in this conversation.
              </Text>
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton onClick={DisableChat} buttonText="Disable" />
          </>
        }
      />
    </>
  );
};

export default MessagesIndex;
