import moment from "moment";
export default function formAPIpastsurgicalhistory({
  documentcount,
  documents,
  hospitalid,
  hospitalname,
  patientid,
  surgeonname,
  procedure,
  proceduredate,
  proceduretitle,
  proceduretype,
  id
  // proceduretype_cd,
  // procedure_cd,
 

})
{
return Object.freeze({
  documentcount,
  documents,
  hospitalid,
  hospitalname,
  patientid,
  surgeonname,
  procedure,
  proceduredate: proceduredate? moment(proceduredate).format("YYYY-MM-DD"):"-",
  proceduretitle,
  proceduretype,
  id
  // proceduretype_cd,
  // procedure_cd,

 
 
 
});
}