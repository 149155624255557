import React from "react";
import { Box, Table, Thead, Tbody, Tr, Th, Td, Text } from "@chakra-ui/react";

const FamilyHistoryTable = ({ familyHistory }) => {
  return (
    <Box overflowX="auto" css={{ "&::-webkit-scrollbar": { display: "none" } }}>
      <Table variant="simple" width="100%">
        <Thead bg="#fafafa">
          <Tr>
            <Th>Name</Th>
            <Th>Relation</Th>
            <Th>Alive</Th>
            <Th>Adopted</Th>
            <Th>Problem</Th>
          </Tr>
        </Thead>
        <Tbody>
          {familyHistory.map((member) => (
            <Tr key={member.familyid}>
              <Td>
                <Text fontWeight="bold">{`${member.firstname} ${member.lastname}`}</Text>
              </Td>
              <Td>{member.relation}</Td>
              <Td>
                {!member.isdeceased ? (
                  <img
                    src="/assets/svgs/ok.svg"
                    alt="Check"
                    width="16px"
                    height="16px"
                  />
                ) : (
                  "-"
                )}
              </Td>
              <Td>
                {member.isadopted ? (
                  <img
                    src="/assets/svgs/ok.svg"
                    alt="Check"
                    width="16px"
                    height="16px"
                  />
                ) : (
                  "-"
                )}
              </Td>
              <Td>{member.medicalconditionproblems}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default FamilyHistoryTable;
