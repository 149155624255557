import { Box, HStack } from "@chakra-ui/react";
import { keyframes } from "@emotion/react";

// Keyframes for vertical stretching (less elastic for 1st and 3rd dots)
const dotStretchLess = keyframes`
  0%, 80%, 100% {
    transform: scaleY(0.8); /* Stretch less */
  }
  40% {
    transform: scaleY(1.2); /* Slightly elongated */
  }
`;

// Keyframes for more vertical stretching (more elastic for 2nd dot)
const dotStretchMore = keyframes`
  0%, 80%, 100% {
    transform: scaleY(0.5); /* Stretch starts smaller */
  }
  40% {
    transform: scaleY(1.5); /* More dramatic stretch */
  }
`;

const DotElasticAnimation = () => {
  return (
    <HStack spacing="6px" justifyContent="center" alignItems="center" height="20px">
      {/* Dot 1: Less vertical stretch */}
      <Box
        as="span"
        display="inline-block"
        width="8px"
        height="8px"
        borderRadius="full"
        bg="black"
        animation={`${dotStretchLess} 1.5s infinite`}
        animationDelay="0s"
      />
      {/* Dot 2: More vertical stretch */}
      <Box
        as="span"
        display="inline-block"
        width="8px"
        height="8px"
        borderRadius="full"
        bg="black"
        animation={`${dotStretchMore} 1.5s infinite`}
        animationDelay="0.2s"
      />
      {/* Dot 3: Less vertical stretch */}
      <Box
        as="span"
        display="inline-block"
        width="8px"
        height="8px"
        borderRadius="full"
        bg="black"
        animation={`${dotStretchLess} 1.5s infinite`}
        animationDelay="0.4s"
      />
    </HStack>
  );
};

export default DotElasticAnimation;
