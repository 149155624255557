export default function formAPIdoctorassessment({
  plan,
  diagnosis,
  DATE,
  specality,
  doctorname,
  date,
  specialization,
  bookingid,
    

})
{

  return Object.freeze({
    plan,
  diagnosis,
  DATE,
  specality,
  doctorname,
  date,
  specialization,
  bookingid,
   
  });
}