import MiniSideBar from "../../components/mini-sidebar";
import {
  Box,
  Divider,
  Flex,
  Image,
  SimpleGrid,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import SecondaryNav from "../../components/seconday-nav";
import Footer from "../../components/footer";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import React, { useState, useEffect, useRef } from "react";
import { FetchBranches, FetchDailyReport, FetchMonthlyDiseaseWiseReport, FetchServicesProvisionedReport } from "../../middleware/services/home/index";
import { actionAccessMain, getLoginID, useScreenType } from "../../auth";
import MobileNavbar from "../../components/mobile-navbar";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../components";
import { FaCloudShowersWater } from "react-icons/fa6";
import moment from "moment";
import SimpleTable from "../../components/simple-table";

import ExcelDownloadDaily from "./exceldownloads/dailyreport";
import ExcelDownloadMonthly1 from "./exceldownloads/monthlyreport1";
import ExcelDownloadQuaterly from "./exceldownloads/quaterlyreport";
import ExcelDownloadMonthly3 from "./exceldownloads/monthlyreport3";
import ExcelDownloadMonthly2 from "./exceldownloads/monthlyreport2";
import { css, Global } from "@emotion/react";



const BranchesIndex = () => {
  var [doctorid, setDoctorid] = useState();
  var [data, setData] = useState(null);
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const screenType = useScreenType();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("branches");
  const [activeSubTab, setActiveSubTab] = useState(null);
  const [tableData, setTableData] = useState([]);


  var [dailyReportData, setDailyReportData] = useState([]);
  var [diseaseWiseReportData, setDiseaseWiseReportData] = useState([]);
  var [servicesProvisionedReportData, setServicesProvisionedReportData] = useState([]);
  var [medicineExpensesReportData, setMedicineExpensesReportData] = useState([]);
  var [ageGroupReportData, setAgeGroupReportData] = useState([]);

  const [dailyDataForExcel, setDailyDataForExcel] = useState([]);

  const [tableHeaders, setTableHeaders] = useState([]);
  const [medicineExpenseTableHeaders, setMedicineExpenseTableHeaders] = useState([]);
  const [ageGroupTableHeaders, setAgeGroupTableHeaders] = useState([]);

  const [dataForMonth1, setDataForMonth1] = useState(null);


  const tabChanged = (tab) => {
    if (tab === "branches") {
      setActiveTab("branches");
      fetchData()
    }
    else if (tab === "reports") {
      setActiveTab("reports");
      setActiveSubTab("Daily Consolidated Diagnosis Report");
      fetchDailyReportData()
    }
  };

  const subTabChanged = (subTab) => {
    setActiveSubTab(subTab);

    if (subTab === 'Daily Consolidated Diagnosis Report') {
      setActiveSubTab("Daily Consolidated Diagnosis Report")
      fetchDailyReportData()
    } else if (subTab === 'Monthly Disease & Gender wise Consolidated Report') {
      setActiveSubTab('Monthly Disease & Gender wise Consolidated Report')
      fetchMonthlyDiagnoseReports()
    } else if (subTab === 'Monthly Disease wise Consolidated Report') {
      setActiveSubTab('Monthly Disease wise Consolidated Report')
      fetchMonthlyDiseaseWiseReports()
    }
  };

  const currentDate = new Date();
  const formatDate = (dateString) => {
    if (!dateString) return "-";
    return moment(dateString).format("Do MMM, YYYY");
  };

  // Get the month name and year dynamically
  const monthName = currentDate.toLocaleString("default", { month: "long" });
  const year = currentDate.getFullYear();


  const fetchDailyReportData = async () => {
    showLoader();
    try {
      const result = await FetchDailyReport({
        doctorid: getLoginID(),
      });
      // console.log("Raw Table Data: ", result.output.data.table);
      setDailyDataForExcel(result?.output?.data?.table)

      if (result && result.output.data.table.length > 0) {
        const response = result.output.data.table;

        // Correct transformation
        const dailyData = response.map((diagnose) => ({
          diagnose: diagnose.diagnose,
          subDiagnoses: diagnose.subdiagnoses.map((sub) => ({
            name: sub.name,
            values: sub.branches.map((branch) => branch.value),
            total: sub.branches.reduce((sum, branch) => sum + branch.value, 0), // Sum of branch values
            // total: sub.total,
          })),
          total: diagnose.subdiagnoses.reduce(
            (sum, sub) =>
              sum +
              sub.branches.reduce((branchSum, branch) => branchSum + branch.value, 0),
            0
          ), // Total for diagnose
        }));

        // console.log("Transformed Table Data: ", dailyData);

        // Calculate the number of branches dynamically
        // const branchCount =
        //   response[0]?.subdiagnoses[0]?.branches.length || 0;
        const branchNames =
          response[0]?.subdiagnoses[0]?.branches.map((branch) => branch.branchname) || [];

        // Create headers dynamically
        const dynamicHeaders = [
          { key: "diagnose", label: "Diagnose" },
          { key: "subDiagnose", label: "Sub-Diagnose" },
          ...branchNames.map((branch, i) => ({ key: `chc${i + 1}`, label: branch })),
          { key: "total", label: "Total" },
        ];

        setTableHeaders(dynamicHeaders);
        setDailyReportData(dailyData);
      } else {
        console.log("No data available for daily report.");
        setDailyReportData([]);
      }
    } catch (error) {
      console.error("Error in fetchDailyReportData: ", error);
    }
    dismissLoader();
  };

  const fetchMonthlyDiseaseWiseReports = async () => {
    showLoader();
    try {
      const result = await FetchMonthlyDiseaseWiseReport({
        doctorid: getLoginID(),
      });
      // console.log("Raw Table Data: ", result.table);

      if (result && result.table.length > 0) {
        const response = result.table;

        // Correct transformation
        const dailyData = response.map((disease) => ({
          subdiagnose: disease.subdiagnose,
          subdiagnosis1: disease.subdiagnosis1.map((sub) => {
            const total = sub.branches.reduce((acc, branch) => acc + branch.value, 0);
            return {
              ...sub,
              total, // Dynamically calculated total
              branches: sub.branches.map((branch) => ({
                branchname: branch.branchname,
                value: branch.value,
              })),
            };
          }),
          total: disease.subdiagnosis1.reduce(
            (acc, sub) => acc + sub.branches.reduce((subAcc, branch) => subAcc + branch.value, 0),
            0
          ),
        }));

        console.log("Transformed Table Data: ", dailyData);

        // Extract branch names for dynamic headers
        const branchNames =
          response[0]?.subdiagnosis1[0]?.branches.map((branch) => branch.branchname) || [];

        // Create dynamic table headers
        const diseasewiseTableHeaders = [
          { key: "label", label: "SUB-DIAGNOSIS" },
          { key: "subLabel", label: "SUB-DIAGNOSIS 1" },
          ...branchNames.map((branch, i) => ({ key: `chc${i + 1}`, label: branch })),
          { key: "total", label: "TOTAL" },
          { key: "percentage", label: "Percentage" },
        ];


        setTableHeaders(diseasewiseTableHeaders);
        setDiseaseWiseReportData(dailyData);
      } else {
        console.log("No data available for daily report.");
        setDiseaseWiseReportData([]);
      }
    } catch (error) {
      console.error("Error in fetchDailyReportData: ", error);
    }
    dismissLoader();
  };

  // Grand Total Calculation
  const granddiseaseWiseTotal = diseaseWiseReportData.reduce((grandSum, diagnose) => {
    return (
      grandSum +
      diagnose.subdiagnosis1.reduce((subSum, subdiagnosis) => {
        return subSum + subdiagnosis.total; // Use the dynamically calculated total
      }, 0)
    );
  }, 0);

  const getBranchNames = (data) => {
    let branchNames = [];

    // Extract branches from the first object only
    if (data.length > 0) {
      data[0].branches.forEach((branch) => {
        branchNames.push(branch.branchname);
      });
    }

    return branchNames; // Return the array of branch names
  };

  const getServicesTableBranchNames = (data) => {
    const branchNames = [];

    // Loop through each service and its branches
    // data[0].forEach((service) => {
    data[0]?.branches?.forEach((branch) => {
      branchNames.push(branch.branchname); // Use a Set to avoid duplicates
    });
    // });

    // return Array.from(branchNames);
    return branchNames;
  };


  const fetchMonthlyDiagnoseReports = async () => {
    showLoader();
    try {
      const result = await FetchServicesProvisionedReport({
        doctorid: getLoginID(),
      });

      // Age Group Table
      if (result && result.output.data[0]?.diagnoswise) {
        const response = result.output.data[0].diagnoswise;

        // Check the structure of the response
        // console.log("Full Response: ", response);
        setDataForMonth1(response)


        let branchNames = [];

        // Extract branches from the first `diagnose` table only
        response.table[0].subdiagnoses.forEach((subdiagnose) => {
          subdiagnose.branches.forEach((branch) => {
            branchNames.push(branch.branchname); // Push each branch name without duplicates
          });
        });

        if (branchNames.length === 0) {
          console.warn("No branch names found. Headers for branches will not be added.");
        }

        // Construct table headers
        const ageGroupHeaders = [
          { key: "sno", label: "S.No" },
          { key: "diagnose", label: "Diagnose" },
          { key: "subDiagnose", label: "Sub-Diagnose" },
          ...branchNames.map((branch, i) => ({ key: `branch${i}`, label: branch })),
          { key: "total", label: "Total" },
        ];

        // console.log("Constructed Headers: ", ageGroupHeaders);

        setAgeGroupTableHeaders(ageGroupHeaders);

        // Format the data based on branch names length
        const formattedData = formatAgeGroupData(response, branchNames.length);
        console.log("Formatted Data: ", formattedData);

        setAgeGroupReportData(formattedData);
      } else {
        console.log("No data available.");
        setAgeGroupReportData({ diagnoseRows: [], ageGroupRows: [], averagePatientFlowRow: {} });
      }

      // Medicines Expenses Table
      if (result && result.output.data[0].medicinesexpenses.length > 0) {
        const response = result.output.data[0].medicinesexpenses;
        // console.log('response: ', response)

        const branchNames =
          response[0]?.result?.map((branch) => branch.branchname) || [];

        const medicineExpenseHeaders = [
          { key: "medicinesExpenses", label: "Medicines Expenses" },
          ...branchNames.map((branch, i) => ({ key: `chc${i + 1}`, label: branch })),
          { key: "total", label: "Total" },
        ];

        // Compute total for each row
        const updatedResponse = response.map((row) => ({
          ...row,
          total: row.result.reduce((sum, branch) => sum + (branch.value || 0), 0),
        }));

        setMedicineExpenseTableHeaders(medicineExpenseHeaders);
        setMedicineExpensesReportData(updatedResponse);
      } else {
        console.log("No data available for daily report.");
        setMedicineExpensesReportData([]);
      }

      // Services Provisioned Table
      if (result && result.output.data[0].servicesprovisioned.length > 0) {
        const response = result.output.data[0].servicesprovisioned;

        // Extract unique branch names from the response
        const branchTableNames = getServicesTableBranchNames(response);
        console.log("Branch Names: ", branchTableNames);

        // Create headers dynamically based on branch names
        const servicesTableHeaders = [
          { key: "label", label: "S. NO" },
          { key: "service", label: "Adjuvant Services provisioned" },
          ...branchTableNames.map((branch, i) => ({ key: `branch${i + 1}`, label: branch })),
          { key: "total", label: "Total" },
        ];

        setTableHeaders(servicesTableHeaders);
        setServicesProvisionedReportData(response);
      } else {
        console.log("No data available for daily report.");
        setServicesProvisionedReportData([]);
      }
    } catch (error) {
      console.error("Error in fetchDailyReportData: ", error);
    }
    dismissLoader();
  };
  const formatAgeGroupData = (data, branchCount) => {
    const diagnoseRows = data.table.map((diagnose, index) => ({
      sNo: index + 1,
      diagnose: diagnose.diagnose,
      subDiagnoses: diagnose.subdiagnoses.map((subDiagnose) => ({
        subDiagnose: subDiagnose.name,
        branches: Array(branchCount)
          .fill(0)
          .map((_, i) => subDiagnose.branches[i]?.value || 0),
        total: subDiagnose.branches.reduce((sum, branch) => sum + (branch.value || 0), 0),
      })),
    }));

    const ageGroupRows = data.ageGroup.map((ageGroup) => ({
      ageGroup: ageGroup.group,
      male: ageGroup.male.map((branch) => branch.value || 0),
      female: ageGroup.female.map((branch) => branch.value || 0),
      other: ageGroup.other.map((branch) => branch.value || 0),
      total: ageGroup.total || 0,
    }));

    // const averagePatientFlowRow = {
    //   averagePatientFlow: data.averagePatientFlow.map((branch) => branch.value || 0),
    //   overallAverage: data.averagePatientFlowOverall || 0,
    // };
    const averagePatientFlowRow = {
      // Map the `branches` array to extract `value` for each branch
      averagePatientFlow: data.averagePatientFlow[0]?.branches.map((branch) => branch.value || 0) || [],

      // Calculate the overall average: sum of branch values divided by number of branches
      overallAverage:
        data.averagePatientFlow[0]?.branches.reduce((sum, branch) => sum + (branch.value || 0), 0)
      // data.averagePatientFlow[0]?.branches.reduce((sum, branch) => sum + (branch.value || 0), 0) /
      //   (data.averagePatientFlow[0]?.branches.length || 1) || 0,
    };


    return { diagnoseRows, ageGroupRows, averagePatientFlowRow };
  };


  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    showLoader();
    try {
      const result = await FetchBranches({
        doctorid,
      });
      if (result.output.data.length > 0) {
        setData(result.output.data);
      } else {
        setData(null);
      }
    } catch (error) {
      console.error("Error in home:", error);
    }
    dismissLoader();
  };

  // Download Excel Functionality

  const [downloadExcel, setdownloadExcel] = useState(false);
  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  };
  const downloadDailyReport = debounce(() => {
    showLoader();
    // generateExcelReport();   // uncoment this when DB data is comming 
    setdownloadExcel(true);
    dismissLoader();
  }, 500);
  const handleDownloadComplete = () => {
    // Reset the download state after completion
    setdownloadExcel(false);
  };


  const [downloadExcelMonthly1, setdownloadExcelMonthly1] = useState(false);
  const [downloadExcelQuaterly, setdownloadExcelQuaterly] = useState(false);

  // monthly report code
  const downloadReportMonthly1 = debounce(() => {
    showLoader();
    // generateExcelReport();   // uncoment this when DB data is comming 
    setdownloadExcelMonthly1(true);
    dismissLoader();
  }, 500);
  const handleDownloadCompleteMonthly1 = () => {
    // Reset the download state after completion
    setdownloadExcelMonthly1(false);
  };

  //quaterly
  const downloadReportQuaterly = debounce(() => {
    showLoader();
    // generateExcelReport();   // uncoment this when DB data is comming 
    setdownloadExcelQuaterly(true);
    dismissLoader();
  }, 500);
  const handleDownloadQuaterly = () => {
    // Reset the download state after completion
    setdownloadExcelQuaterly(false);
  };
  const [downloadExcelMonthly2, setdownloadExcelMonthly2] = useState(false);
  const [downloadExcelMonthly3, setdownloadExcelMonthly3] = useState(false);

  // monthly report code
  const downloadReportMonthly2 = debounce(() => {
    showLoader();
    // generateExcelReport();   // uncoment this when DB data is comming 
    setdownloadExcelMonthly2(true);
    dismissLoader();
  }, 500);
  const handleDownloadCompleteMonthly2 = () => {
    // Reset the download state after completion
    setdownloadExcelMonthly2(false);
  };

  // monthly report code
  const downloadReportMonthly3 = debounce(() => {
    showLoader();
    // generateExcelReport();   // uncoment this when DB data is comming 
    setdownloadExcelMonthly3(true);
    dismissLoader();
  }, 500);
  const handleDownloadCompleteMonthly3 = () => {
    // Reset the download state after completion
    setdownloadExcelMonthly3(false);
  };
  const calculateBranchTotals = (diagnose) => {
    const branchTotals = [];

    // Loop through each subdiagnosis1
    diagnose?.subdiagnosis1?.forEach((subdiagnosis) => {
      subdiagnosis?.branches?.forEach((branch, index) => {
        // Dynamically accumulate the sum for each branch column
        if (!branchTotals[index]) {
          branchTotals[index] = 0;
        }
        branchTotals[index] += branch.value;
      });
    });

    return branchTotals;
  };

  const calculateTotalPercentage = (subdiagnosis1) => {
    return subdiagnosis1.reduce((sum, subdiagnosis) => sum + parseFloat(subdiagnosis.percentage || 0), 0).toFixed(1);
  };


  return (
    <>
      <Global
        styles={css`
          .activeTab .whiteBorder::after {
            content: '';
            position: absolute;
            left: 0%;
            transform: translateX(-20%);
            transform: translateY(440%);
            border-radius: 5px 5px 0 0;
            top: 120%;
            background: #0B1F4F;
            height: 3px;
            width: 100%;
            z-index: 1;
          }

          .activeSubTab .whiteBorder::after {
            content: '';
            position: absolute;
            left: 0%;
            transform: translateX(-20%);
            transform: translateY(440%);
            border-radius: 5px 5px 0 0;
            top: 100%;
            background: #0B1F4F;
            height: 3px;
            width: 100%;
            z-index: 1;
          }
        `}
      />
      {isLoading && <Loader />}
      <Box w="100%">
        <Flex w="100%" mb="1.25rem">
          <Box w="6rem">
            <MiniSideBar />
          </Box>
          <Box w="100%">
            <SecondaryNav customHeader="Networked Care" />
            {/* Main container */}
            <Flex>
              <Box w="100%">
                <Box w="100%" display="flex" flexDir="column">
                  {/* Tab buttons start */}
                  <Box display='flex' gap="1rem" borderBottom="1px solid #E6E7E9" bg="white"
                  // p="1.25rem 1.5rem"
                  >
                    <Box
                      display="flex"
                      gap="1rem"
                      borderBottom="1px solid #E6E7E9"
                      bg="white"
                      p="1.25rem 1.5rem"
                    >
                      <Box
                        as="button"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        pos="relative"
                        className={activeTab == "branches" ? "activeTab" : ""}
                        fontSize="0.87rem"
                        fontWeight="600"
                        onClick={() => tabChanged("branches")}
                        color={activeTab == "branches" ? "#061027" : "#898D97"}
                      >
                        <Text>Branch</Text>
                        <Box as="span" className="whiteBorder" />
                      </Box>
                      <Box
                        as="button"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        pos="relative"
                        className={activeTab == "reports" ? "activeTab" : ""}
                        fontSize="0.87rem"
                        fontWeight="600"
                        onClick={() => tabChanged("reports")}
                        color={activeTab == "reports" ? "#061027" : "#898D97"}
                      >
                        <Text>Reports</Text>
                        <Box as="span" className="whiteBorder" />
                      </Box>
                    </Box>
                  </Box>
                  {/* Tabs button end */}
                  {/* Sub-tabs for Reports start*/}
                  {activeTab === "reports" && (
                    <Box display="flex" gap="2rem" p="1rem" bg="white" border="1px solid #E6E7E9">
                      {["Daily Consolidated Diagnosis Report", "Monthly Disease & Gender wise Consolidated Report", "Monthly Disease wise Consolidated Report"].map((subTab) => (
                        <Box
                          as="button"
                          key={subTab}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          pos="relative"
                          className={activeSubTab === subTab ? "activeSubTab" : ""}
                          fontSize="0.87rem"
                          fontWeight="600"
                          onClick={() => subTabChanged(subTab)}
                          color={activeSubTab === subTab ? "#061027" : "#898D97"}
                        >
                          <Text>{subTab.charAt(0).toUpperCase() + subTab.slice(1)}</Text>
                          <Box as="span" className="whiteBorder" />
                        </Box>
                      ))}
                    </Box>
                  )}
                  {/* Sub-tabs for Reports end */}

                  {/* Fetch Cards start */}
                  {activeTab == "branches" && (
                    <Box display="flex" flexDir="column" gap="1rem">
                      {data && (
                        <Box w="100%" h="100%" p={4} overflow="hidden auto">
                          <SimpleGrid columns={{ base: 1, lg: 2, xl: 3 }} gap="20px">
                            {Array.isArray(data) &&
                              data.map((step, index) => (
                                <Box
                                  key={index}
                                  mb="20px"
                                  border="1px solid #E6E7E9"
                                  borderRadius="12px"
                                  display="flex"
                                  flexDirection="column"
                                  justifyContent="space-between"
                                  overflow="hidden"
                                >
                                  <Box
                                    p="16px"
                                    w="100%"
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                  >
                                    <Box display="flex" alignItems="flex-start">
                                      <Box>
                                        <Text fontSize="16px" fontWeight="600">
                                          {step.clinicname ? step.clinicname : ""}
                                        </Text>
                                        <Text
                                          fontSize="12px"
                                          fontWeight="500"
                                          color="#384052"
                                          mt="4px"
                                        >
                                          {step.address1 ||
                                            step.address2 ||
                                            step.city ||
                                            step.state ||
                                            step.pincode ||
                                            step.country ? (
                                            <>
                                              <Text fontWeight="bold">
                                                Address:{" "}
                                                <span style={{ fontWeight: "500" }}>
                                                  {step.address1
                                                    ? `${step.address1} `
                                                    : ""}
                                                  {step.address2
                                                    ? `${step.address2}, `
                                                    : ""}
                                                  {step.city ? `${step.city} ` : ""}
                                                  {step.state ? `${step.state} ` : ""}
                                                  {step.pincode
                                                    ? `${step.pincode} `
                                                    : ""}
                                                  {step.country ? `${step.country} ` : ""}
                                                </span>
                                              </Text>
                                            </>
                                          ) : (
                                            "-"
                                          )}
                                        </Text>
                                      </Box>
                                    </Box>
                                  </Box>
                                  <Divider />
                                  <Box display="flex" gap="1rem" p="16px">
                                    {/* Contact Details */}
                                    <Box>
                                      <Box
                                        display="flex"
                                        alignItems="center"
                                        gap="0.5rem"
                                      //color="blue"
                                      >
                                        <Image
                                          w="12px"
                                          h="13px"
                                          src="/assets/svgs/name.svg"
                                          alt="User Icon"
                                        />
                                        <Text fontSize="0.75rem" fontWeight="500">
                                          {(step.firstname || step.lastname)
                                            ? `${step.firstname || ""} ${step.lastname || ""}`.trim()
                                            : ""}
                                        </Text>
                                      </Box>
                                      <Box
                                        display="flex"
                                        alignItems="center"
                                        gap="0.5rem"
                                      //color="blue"
                                      >
                                        <Image
                                          w="13px"
                                          h="10px"
                                          src="/assets/svgs/email.svg"
                                          alt="Email Icon"
                                        />
                                        <Text fontSize="0.75rem" fontWeight="500">
                                          {step.email ? step.email : "-"}
                                        </Text>
                                      </Box>
                                    </Box>

                                    {/* Mobile Number */}
                                    <Box>
                                      <Box
                                        display="flex"
                                        alignItems="center"
                                        gap="0.5rem"
                                      //color="blue"
                                      >
                                        <Image
                                          w="13px"
                                          h="13px"
                                          src="/assets/svgs/phone-number.svg"
                                          alt="Phone Icon"
                                        />
                                        <Text fontSize="0.75rem" fontWeight="500">
                                          {step.mobileno
                                            ? `${step.countrycode ? step.countrycode + " " : ""}${step.mobileno}`
                                            : "-"}
                                        </Text>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              ))}
                          </SimpleGrid>
                        </Box>
                      )}
                      {data == null && (
                        <Box
                          w="100%"
                          mt="-100px"
                          h="100%"
                          display="flex"
                          flexDirection="column"
                          gap="5px"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Image
                            w="60px"
                            src="/assets/svgs/surgical-nodata.svg"
                            alt="No Data"
                          />
                          <Box as="p">No data available</Box>
                        </Box>
                      )}
                    </Box>
                  )}
                  {/* Fetch Cards end */}

                  {/* Daily Report start */}
                  {activeTab == 'reports' && activeSubTab == 'Daily Consolidated Diagnosis Report' && (
                    <Box h="calc(100vh - 205px)" overflow="hidden auto">
                      <Box width="100%">
                        <Box textAlign="center">
                          <Text fontSize="1.5rem" mt='1rem' fontWeight="600">
                            All Clinics Consolidated Day Report
                          </Text>
                        </Box>
                        <Flex justifyContent="space-between" pos='relative' alignItems="center" w="100%" padding="1rem">
                          <Text fontSize="1rem" fontWeight='600' flex="1" textAlign="center">
                            {/* Date: 24-November-2024 */}
                            Date: {formatDate(currentDate)}
                          </Text>
                          <Box pos='absolute' right='20px'>
                            <PrimaryButton
                              buttonText="Download Report"
                              variant='mdBtn'
                              btnIcon="/assets/svgs/download-white.svg"
                              btnStyle={{ width: '175px' }}
                              onClick={downloadDailyReport}
                            />
                            {downloadExcel &&
                              <ExcelDownloadDaily
                                data={dailyDataForExcel && dailyDataForExcel}
                                onDownloadComplete={handleDownloadComplete}
                              />
                            }
                          </Box>
                        </Flex>
                      </Box>
                      <Box
                        // h="calc(100vh - 90px)"
                        // overflow="hidden auto"
                        border="1px solid #E6E7E9"
                      >
                        <SimpleTable
                          headers={tableHeaders}
                          isDataPresent={dailyReportData && dailyReportData.length > 0}
                          tableStyle={{
                            // minH: "calc(100vh - 260px)",
                            // maxH: "calc(100vh - 260px)",
                            overflowY: "auto",
                            overflowX: "hidden",
                            tableLayout: "fixed",
                            width: "100%",
                          }}
                          tableBody={
                            <>
                              {dailyReportData &&
                                dailyReportData.map((category, index) => (
                                  <React.Fragment key={index}>
                                    {category.subDiagnoses.map((sub, subIndex) => (
                                      <Tr key={`${index}-${subIndex}`}>
                                        {subIndex === 0 && (
                                          <Td rowSpan={category.subDiagnoses.length}>
                                            <strong>{category.diagnose}</strong>
                                          </Td>
                                        )}
                                        <Td>{sub.name}</Td>
                                        {sub.values.map((value, i) => (
                                          <Td key={i}>{value}</Td>
                                        ))}
                                        <Td>{sub.total}</Td>
                                      </Tr>
                                    ))}
                                  </React.Fragment>
                                ))}
                              <Tr fontWeight='bold'>
                                <Td colSpan={2} textAlign="center">
                                  No Of Total End Of The Day
                                </Td>
                                {tableHeaders
                                  .filter((header) => header.key.startsWith("chc"))
                                  .map((_, i) => (
                                    <Td key={i}>
                                      {dailyReportData.reduce(
                                        (sum, category) =>
                                          sum +
                                          category.subDiagnoses.reduce(
                                            (subSum, sub) => subSum + (sub.values[i] || 0),
                                            0
                                          ),
                                        0
                                      )}
                                    </Td>
                                  ))}
                                <Td>
                                  {dailyReportData?.reduce(
                                    (sum, category) =>
                                      sum +
                                      category.subDiagnoses?.reduce(
                                        (subSum, sub) => subSum + (sub?.total || 0),
                                        0
                                      ),
                                    0
                                  ) || 0}
                                </Td>
                              </Tr>
                            </>
                          }
                        />

                      </Box>
                      <Footer footerStyle={{ m: 0, p: "1.5rem", bg: "white", zIndex: "999" }} />
                    </Box>

                  )}
                  {/* Daily Report end */}

                  {/* Monthly Report start */}
                  {activeTab == 'reports' && activeSubTab == 'Monthly Disease & Gender wise Consolidated Report' && (
                    <Box h="calc(100vh - 205px)" overflow="hidden auto" >

                      {/* 1st Table (Age Group) */}
                      <Box width="100%">
                        <Flex justifyContent="space-between" pos='relative' alignItems="center" w="100%" padding="1rem">
                          <Text fontSize="1.5rem" fontWeight="600" flex="1" textAlign="center">
                            {/* Monthly Report for all Clinics for the Month of November 2024 */}
                            Monthly Report for all Clinics for {monthName} {year}
                          </Text>
                          <Box pos='absolute' right='20px'>
                            <PrimaryButton
                              buttonText="Download Report"
                              variant='mdBtn'
                              btnIcon="/assets/svgs/download-white.svg"
                              btnStyle={{ width: '175px' }}
                              onClick={downloadReportMonthly1}
                            />
                            {downloadExcelMonthly1 &&
                              <ExcelDownloadMonthly1
                                data={dataForMonth1}
                                onDownloadComplete={handleDownloadCompleteMonthly1}
                              />
                            }
                          </Box>
                        </Flex>
                      </Box>
                      <Box
                        // h="calc(100vh - 90px)"
                        // overflow="hidden auto"
                        border="1px solid #E6E7E9"
                      >
                        <SimpleTable
                          headers={ageGroupTableHeaders}
                          isDataPresent={ageGroupReportData?.diagnoseRows?.length > 0}
                          tableStyle={{
                            // minH: "calc(100vh - 260px)",
                            // maxH: "calc(100vh - 260px)",
                            // overflowY: "auto",
                            tableLayout: "fixed",
                            width: "100%",
                          }}
                          tableBody={
                            <>
                              {/* Total Row */}
                              <Tr fontWeight="bold">
                                <Td></Td>
                                <Td></Td>
                                <Td>Total Patients Covered</Td>
                                {/* Calculate total for each branch */}
                                {ageGroupTableHeaders
                                  .filter((header) => header.key.startsWith("branch"))
                                  .map((branchHeader, branchIndex) => {
                                    const totalBranchValue = ageGroupReportData?.diagnoseRows?.reduce((sum, row) => {
                                      return (
                                        sum +
                                        row.subDiagnoses.reduce(
                                          (subSum, subRow) => subSum + (subRow.branches[branchIndex] || 0), // Safely access branch values
                                          0
                                        )
                                      );
                                    }, 0);
                                    return <Td key={branchIndex}>{totalBranchValue}</Td>;
                                  })}
                                {/* Calculate grand total */}
                                <Td>
                                  {ageGroupReportData?.diagnoseRows?.reduce(
                                    (sum, row) =>
                                      sum +
                                      row.subDiagnoses.reduce((subSum, subRow) => subSum + (subRow.total || 0), 0), // Safely access total values
                                    0
                                  )}
                                </Td>
                              </Tr>

                              {ageGroupReportData.diagnoseRows?.map((row, index) => (
                                <React.Fragment key={index}>
                                  {row.subDiagnoses.map((subRow, subIndex) => (
                                    <Tr key={subIndex}>
                                      {subIndex === 0 && <Td rowSpan={row.subDiagnoses.length}>{row.sNo}</Td>}
                                      {subIndex === 0 && <Td rowSpan={row.subDiagnoses.length}>{row.diagnose}</Td>}
                                      <Td>{subRow.subDiagnose}</Td>
                                      {subRow.branches.map((branchValue, branchIndex) => (
                                        <Td key={branchIndex}>{branchValue}</Td>
                                      ))}
                                      <Td>{subRow.total}</Td>
                                    </Tr>
                                  ))}
                                </React.Fragment>
                              ))}

                              <Tr>
                                <Td colSpan={ageGroupTableHeaders.length}>
                                  <strong>AGE GROUP</strong>
                                </Td>
                              </Tr>
                              {ageGroupReportData.ageGroupRows?.map((row, index) => (
                                <React.Fragment key={index}>
                                  <Tr>
                                    <Td>{index + 1}</Td>
                                    <Td>{row.ageGroup}</Td>
                                    <Td>Male</Td>
                                    {row.male.map((value, branchIndex) => (
                                      <Td key={branchIndex}>{value}</Td>
                                    ))}
                                  </Tr>
                                  <Tr>
                                    <Td></Td>
                                    <Td></Td>
                                    <Td>Female</Td>
                                    {row.female.map((value, branchIndex) => (
                                      <Td key={branchIndex}>{value}</Td>
                                    ))}
                                    <Td>{row.total}</Td>
                                  </Tr>
                                  <Tr>
                                    <Td></Td>
                                    <Td></Td>
                                    <Td>Other</Td>
                                    {row.other.map((value, branchIndex) => (
                                      <Td key={branchIndex}>{value}</Td>
                                    ))}
                                    {/* <Td>{row.total}</Td> */}
                                  </Tr>
                                </React.Fragment>
                              ))}

                              <Tr>
                                <Td colSpan="3" textAlign="center">
                                  <strong>Average Patient Flow Per Day</strong>
                                </Td>
                                {ageGroupReportData?.averagePatientFlowRow?.averagePatientFlow?.map((value, index) => (
                                  <Td key={index}>
                                    <strong>{value}</strong>
                                  </Td>
                                ))}
                                <Td fontWeight='bold'>{ageGroupReportData?.averagePatientFlowRow?.overallAverage}</Td>
                              </Tr>
                            </>
                          }
                        />

                      </Box>

                      {/* 2nd Table (Medicines Expenses) */}
                      <Divider mt='2rem' opacity='1' h='2px' />
                      <Box width="100%">
                        <Flex justifyContent="space-between" pos='relative' alignItems="center" w="100%" padding="1rem">
                          <Text fontSize="1.5rem" fontWeight="600" flex="1" textAlign="center">
                            Monthly Report for Medicines Prescribed for {monthName} {year}
                          </Text>
                          <PrimaryButton
                            buttonText="Download Report"
                            variant='mdBtn'
                            btnIcon="/assets/svgs/download-white.svg"
                            btnStyle={{ width: '175px', pos: 'absolute', right: '20px' }}
                            onClick={downloadReportMonthly2}
                          />
                          {downloadExcelMonthly2 &&
                            <ExcelDownloadMonthly2

                              data={medicineExpensesReportData}
                              onDownloadComplete={handleDownloadCompleteMonthly2}
                            />
                          }
                        </Flex>
                      </Box>
                      <Box
                        // h="calc(100vh - 90px)"
                        // overflow="hidden auto"
                        border="1px solid #E6E7E9"
                      >
                        <SimpleTable
                          headers={medicineExpenseTableHeaders}
                          isDataPresent={medicineExpensesReportData && medicineExpensesReportData.length > 0}
                          tableStyle={{
                            // minH: "calc(100vh - 260px)",
                            // maxH: "calc(100vh - 260px)",
                            // overflowY: "auto",
                            // overflowX: "hidden",
                            tableLayout: "fixed",
                            width: "100%",
                          }}
                          tableBody={
                            <>
                              {medicineExpensesReportData.map((row, index) => (
                                <Tr
                                  key={index}
                                // bg={row.isHighlighted ? "red.300" : "white"} 
                                >
                                  <Td fontWeight="bold">{row.name}</Td>
                                  {row.result.map((value, i) => (
                                    <Td key={i}>{value.value}</Td>
                                  ))}
                                  <Td fontWeight="bold">{row.total}</Td>
                                </Tr>
                              ))}
                            </>
                          }
                        />
                      </Box>

                      {/* 3rd Table (Services Provisioned) */}
                      <Divider mt='2rem' opacity='1' h='2px' />
                      <Box width="100%">
                        <Flex justifyContent="center" pos='relative' alignItems="center" w="100%" padding="1rem">
                          <Text fontSize="1.5rem" whiteSpace='wrap' fontWeight="600" w='50%' textAlign="center">
                            Monthly Report for Adjuvant Services provisioned for {monthName} {year}
                          </Text>
                          <PrimaryButton
                            buttonText="Download Report"
                            variant='mdBtn'
                            btnIcon="/assets/svgs/download-white.svg"
                            btnStyle={{ width: '175px', pos: 'absolute', right: '20px' }}
                            onClick={downloadReportMonthly3}
                          />
                          {downloadExcelMonthly3 &&
                            <ExcelDownloadMonthly3
                              data={servicesProvisionedReportData}
                              onDownloadComplete={handleDownloadCompleteMonthly3}
                            />
                          }
                        </Flex>
                      </Box>
                      <Box
                        // h="calc(100vh - 90px)"
                        // overflow="hidden auto"
                        border="1px solid #E6E7E9"
                      >
                        <SimpleTable
                          headers={tableHeaders}
                          isDataPresent={servicesProvisionedReportData && servicesProvisionedReportData.length > 0}
                          tableStyle={{
                            tableLayout: "fixed",
                            width: "100%",
                          }}
                          tableBody={
                            <>
                              {/* Total Row */}
                              <Tr fontWeight="bold">
                                <Td></Td>
                                <Td>Total Patients Services Provisioned</Td>
                                {/* Dynamically calculate total for each branch */}
                                {getServicesTableBranchNames(servicesProvisionedReportData).map((branch, branchIndex) => {
                                  const totalBranchValue = servicesProvisionedReportData.reduce((sum, service) => {
                                    const branchData = service.branches[branchIndex];
                                    return sum + (branchData ? branchData.value : 0); // Handle missing branch values
                                  }, 0);
                                  return <Td key={branchIndex}>{totalBranchValue}</Td>;
                                })}
                                {/* Calculate overall total */}
                                <Td>
                                  {servicesProvisionedReportData.reduce(
                                    (sum, service) => sum + (service.total || 0), // Safely access `service.total`
                                    0
                                  )}
                                </Td>
                              </Tr>
                              {/* Render individual rows */}
                              {servicesProvisionedReportData.map((row, index) => (
                                <Tr
                                  key={index}
                                // bg={row.isHighlighted ? "red.300" : "white"} 
                                >
                                  <Td>{index + 1}</Td>
                                  <Td fontWeight="bold">{row.name}</Td>
                                  {row.branches.map((value, i) => (
                                    <Td key={i}>{value.value}</Td>
                                  ))}
                                  <Td fontWeight="bold">{row.total}</Td>
                                </Tr>
                              ))}
                            </>
                          }
                        />

                      </Box>
                      <Footer footerStyle={{ m: 0, p: "1.5rem", bg: "white", zIndex: "999" }} />
                    </Box>

                  )}
                  {/* Monthly Report end */}

                  {/* Monthly Diagnose Report Dynamic start */}
                  {activeTab === 'reports' && activeSubTab === 'Monthly Disease wise Consolidated Report' && (
                    <Box h="calc(100vh - 205px)" overflow="hidden auto" >
                      <Box width="100%">
                        <Flex justifyContent="space-between" pos='relative' alignItems="center" w="100%" padding="1rem">
                          <Text fontSize="1.5rem" fontWeight="600" flex="1" textAlign="center">
                            All Clinics Diseases Wise Report for the {monthName} {year}
                          </Text>
                          <Box pos='absolute' right='20px'>
                            <PrimaryButton
                              buttonText="Download Report"
                              variant='mdBtn'
                              btnIcon="/assets/svgs/download-white.svg"
                              btnStyle={{ width: '175px' }}
                              onClick={downloadReportQuaterly}
                            />
                            {downloadExcelQuaterly &&
                              <ExcelDownloadQuaterly
                                data={diseaseWiseReportData}
                                onDownloadComplete={handleDownloadQuaterly}
                              />
                            }
                          </Box>
                        </Flex>
                      </Box>
                      <Box
                        // h="calc(100vh - 90px)"
                        // overflow="hidden auto"
                        border="1px solid #E6E7E9"
                      >
                        <SimpleTable
                          headers={tableHeaders}
                          isDataPresent={diseaseWiseReportData && diseaseWiseReportData.length > 0}
                          tableStyle={{
                            // minH: "calc(100vh - 260px)",
                            // maxH: "calc(100vh - 260px)",
                            // overflowY: "auto",
                            // overflowX: "hidden",
                            tableLayout: "fixed",
                            width: "100%",
                          }}
                          tableBody={
                            <>
                              {/* Total Diagnosis Covered Row */}
                              <Tr>
                                <Td colSpan={2} fontWeight="bold">Total Diagnosis Covered</Td>

                                {/* Dynamically calculate and render branch-wise totals */}
                                {Array.from({ length: diseaseWiseReportData[0]?.subdiagnosis1[0]?.branches.length }).map((_, branchIndex) => {
                                  const branchTotal = diseaseWiseReportData.reduce((total, diagnose) => {
                                    return (
                                      total +
                                      diagnose.subdiagnosis1.reduce(
                                        (subTotal, subdiagnosis) => subTotal + subdiagnosis.branches[branchIndex].value,
                                        0
                                      )
                                    );
                                  }, 0);

                                  return (
                                    <Td key={branchIndex} fontWeight="bold">
                                      {branchTotal}
                                    </Td>
                                  );
                                })}

                                {/* Total Diagnosis Covered Sum */}
                                <Td fontWeight="bold">
                                  {diseaseWiseReportData.reduce(
                                    (grandTotal, diagnose) =>
                                      grandTotal +
                                      diagnose.subdiagnosis1.reduce((subTotal, sub) => subTotal + sub.total, 0),
                                    0
                                  )}
                                </Td>
                                <Td>-</Td>
                              </Tr>

                              {/* Render Disease Data */}
                              {diseaseWiseReportData.map((diagnose, diagnoseIndex) => {
                                // Dynamically calculate branch totals for the current diagnose
                                const branchTotals = diagnose.subdiagnosis1.reduce((totals, subdiagnosis) => {
                                  subdiagnosis.branches.forEach((branch, index) => {
                                    if (!totals[index]) {
                                      totals[index] = 0;
                                    }
                                    totals[index] += branch.value;
                                  });
                                  return totals;
                                }, []);

                                // Attach percentage for each subdiagnosis
                                diagnose.subdiagnosis1 = diagnose.subdiagnosis1.map((subdiagnosis) => {
                                  const percentage = ((subdiagnosis.total / granddiseaseWiseTotal) * 100).toFixed(1);
                                  return {
                                    ...subdiagnosis,
                                    percentage,
                                  };
                                });

                                // Calculate Subdiagnose Total
                                const subdiagnoseTotal = diagnose.subdiagnosis1.reduce(
                                  (subdiagnoseSum, subdiagnosis) => subdiagnoseSum + subdiagnosis.total,
                                  0
                                );

                                // Function to calculate total percentage for a subdiagnose
                                const calculateTotalPercentage = (subdiagnosis1) =>
                                  // subdiagnosis1
                                  //   .reduce((sum, subdiagnosis) => sum + parseFloat(subdiagnosis.percentage || 0), 0)
                                  //   .toFixed(1);

                                  subdiagnosis1
                                    .reduce((sum, subdiagnosis) => {
                                      // Validate if the percentage is a valid number, else default to 0
                                      const percentage = parseFloat(subdiagnosis.percentage);
                                      return sum + (isNaN(percentage) ? 0 : percentage);
                                    }, 0)
                                    .toFixed(1);

                                return (
                                  <React.Fragment key={diagnoseIndex}>
                                    {/* Render Subdiagnosis1 Data */}
                                    {diagnose.subdiagnosis1 && diagnose.subdiagnosis1.length > 0 ? (
                                      diagnose.subdiagnosis1.map((subdiagnosis, subIndex) => (
                                        <Tr key={subIndex}>
                                          <Td fontWeight="bold">{subIndex === 0 ? diagnose.subdiagnose : ""}</Td>
                                          <Td>{subdiagnosis.name}</Td>

                                          {/* Render Branch Values */}
                                          {subdiagnosis.branches.map((branch, branchIndex) => (
                                            <Td key={branchIndex}>{branch.value || 0}</Td>
                                          ))}

                                          {/* Total and Percentage Columns */}
                                          <Td fontWeight="bold">{subdiagnosis.total}</Td>
                                          {/* <Td>{subdiagnosis.percentage}%</Td> */}
                                          <Td>{!isNaN(parseFloat(subdiagnosis.percentage)) ? `${subdiagnosis.percentage}%` : "0%"}</Td>
                                        </Tr>
                                      ))
                                    ) : (
                                      <Tr>
                                        <Td colSpan={tableHeaders.length} textAlign="center">
                                          No Subdiagnosis data available.
                                        </Td>
                                      </Tr>
                                    )}

                                    {/* Render the Total Row for Each Subdiagnose */}
                                    <Tr>
                                      <Td fontWeight="bold" colSpan={2}>
                                        No. of total in {diagnose.subdiagnose}
                                      </Td>
                                      {/* Dynamically render branch totals */}
                                      {branchTotals.map((total, index) => (
                                        <Td key={index} fontWeight="bold">
                                          {total}
                                        </Td>
                                      ))}
                                      {/* Placeholder for total and percentage */}
                                      <Td fontWeight="bold">{subdiagnoseTotal}</Td>
                                      <Td fontWeight="bold">{calculateTotalPercentage(diagnose.subdiagnosis1)}%</Td>
                                    </Tr>
                                  </React.Fragment>
                                );
                              })}

                              {/* Render the Grand Total Row */}
                              {/* <Tr>
                              <Td fontWeight="bold" colSpan={tableHeaders.length - 2}>
                                Grand Total
                              </Td>
                              <Td fontWeight="bold">
                                {diseaseWiseReportData.reduce(
                                  (grandTotal, diagnose) =>
                                    grandTotal +
                                    diagnose.subdiagnosis1.reduce(
                                      (subTotal, sub) =>
                                        subTotal +
                                        sub.branches.reduce((branchSum, branch) => branchSum + branch.value, 0),
                                      0
                                    ),
                                  0
                                )}
                              </Td>
                              <Td fontWeight="bold">
                                {diseaseWiseReportData.reduce((grandTotalPercentage, diagnose) => {
                                  const subdiagnosisPercentages = diagnose.subdiagnosis1.reduce(
                                    (subTotal, sub) => subTotal + parseFloat(sub.percentage || 0),
                                    0
                                  );
                                  return grandTotalPercentage + subdiagnosisPercentages;
                                }, 0).toFixed(1)}
                                %
                              </Td>
                            </Tr> */}
                            </>
                          }
                        />



                      </Box>
                      <Footer footerStyle={{ m: 0, p: "1.5rem", bg: "white", zIndex: "999" }} />
                    </Box>
                  )}
                  {/* Monthly Diagnose Report Dynamic end */}

                </Box>
              </Box>

            </Flex>
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default BranchesIndex;