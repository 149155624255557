import React, { useEffect, useState } from "react";
import { Flex, VStack, Box, Image, Center, Text, Input, Progress } from "@chakra-ui/react";
import { PrimaryButton, PrimaryInput, Toaster } from "../../components";
import useLoader from "../../components/loader/loaderstates";
import { uploadImageCommonFunction, useScreenType } from "../../auth";
import { signUpProfessionalDetails } from "../../middleware/services/signup";
import Loader from "../../components/loader/loader";
import { areIntervalsOverlapping } from "date-fns";

const UploadDocuments = ({ onSubmit }) => {

  const [documents, setDocuments] = useState([])
  const [documenttype, setDocumenttype] = useState("")
  const [isSubmitted, setIsSubmitted] = useState(false); // Track submission status

  const [doctorid, setDoctorid] = useState('')

  const [degreeUploadStage, setDegreeUploadStage] = useState("1")
  const [degreeDocName, setDegreeDocName] = useState("")
  const [degreeDocSize, setDegreeDocSize] = useState("")
  const [errorStyleForDegreeUpload, setErrorStyleForDegreeUpload] = useState(false)
  const [degreeUploadLoadWidth, setDegreeUploadLoadWidth] = useState("0")

  const [licenseUploadStage, setLicenseUploadStage] = useState("1")
  const [licenceDocName, setLicenceDocName] = useState("")
  const [licenseDocSize, setLicenseDocSize] = useState("")
  const [errorStyleForLicenseUpload, setErrorStyleForLicenseUpload] = useState(false)
  const [licenseUploadLoadWidth, setLicenseUploadLoadWidth] = useState("0")

  const [certificateUploadStage, setCertificateUploadStage] = useState("1")
  const [certificateDocName, setcertificateDocName] = useState("")
  const [certificateDocSize, setcertificateDocSize] = useState("")
  const [errorStyleForCertificateUpload, setErrorStyleForCertificateUpload] = useState(false)
  const [certificateUploadLoadWidth, setCertificateUploadLoadWidth] = useState("0")
  let screenType = useScreenType();
  const widths = ["20", "40", "60", "80", "100"];

  const { isLoading, showLoader, dismissLoader } = useLoader();
  const { showSuccessToast, showErrorToast } = Toaster();



  const formatSize = (size) => {
    if (size < 1024) {
      return `${size} B`;
    } else if (size < 1024 * 1024) {
      return `${(size / 1024).toFixed(2)} KB`;
    } else {
      return `${(size / (1024 * 1024)).toFixed(2)} MB`;
    }
  };
  const handleUploadDocument = async (e, docType) => {
    e.preventDefault();
    // showLoader();
    if (e.target.files.length == 0) {
      // dismissLoader();
      return;
    }


   // console.log(e.target.files[0], " fiels data")
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const result = await uploadImageCommonFunction(file, file.name);

    const allowedTypes = ["image/png", "image/jpeg", "image/jpg", "application/pdf"];
    const maxSizeInMB = 5;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
    const documenturl = result.name;

    if (result && (documenttype == "degree" || docType ==  "degree" )) {
      setDegreeUploadStage("2")
      setErrorStyleForDegreeUpload(false)
      setDegreeDocName(file.name)
      var fileSizeBytes = file.size;
      var fileSize = formatSize(fileSizeBytes)
      setDegreeDocSize(fileSize)


      if (file.size > maxSizeInBytes) {
        showErrorToast("File size exceeds 5MB. Please select a smaller file.");

        setErrorStyleForDegreeUpload(true)
      } else if (!allowedTypes.includes(file.type)) {
        setErrorStyleForDegreeUpload(true)
        showErrorToast("Invalid file type. Please select a PNG, JPG, JPEG, or PDF file.");
      } else {
        // setDocuments((prevDocuments) => [
        //   ...prevDocuments,
        //   { documenturl, "documentname": file.name, documenttype , "documentsize":fileSizeBytes },
        // ]);
        const newDocuments = [...documents, { documenturl, documentname: file.name, 
          "documenttype":documenttype ? documenttype : docType, 
          documentsize: fileSizeBytes }];
        setDocuments(newDocuments);
        localStorage.setItem('documents', JSON.stringify(newDocuments));
      }

      widths.forEach((width, index) => {
        setTimeout(() => {
          setDegreeUploadLoadWidth(width);
        }, (index + 1) * 300); 
      });

      setTimeout(() => {
        setDegreeUploadStage("3")
      }, 2000);

    }
    if (result && (documenttype == "license"  || docType ==  "license" )) {
      setLicenseUploadStage("2")
      setErrorStyleForLicenseUpload(false)
      setLicenceDocName(file.name);
      var fileSizeBytes = file.size;
      // var fileSize = file.size / 1024;
      // var docSize = fileSize.toFixed(2)
      // setLicenseDocSize(docSize)
      // setLicenseDocSize(fileSizeBytes)
      var fileSize = formatSize(fileSizeBytes)
      setLicenseDocSize(fileSize)

      if (file.size > maxSizeInBytes) {
        showErrorToast("File size exceeds 5MB. Please select a smaller file.");

        setErrorStyleForLicenseUpload(true)
      } else if (!allowedTypes.includes(file.type)) {
        setErrorStyleForLicenseUpload(true)
        showErrorToast("Invalid file type. Please select a PNG, JPG, JPEG, or PDF file.");
      } else {
        // setDocuments((prevDocuments) => [
        //   ...prevDocuments,
        //   { documenturl, "documentname": file.name , documenttype , "documentsize":fileSizeBytes },
        // ]);
        const newDocuments = [...documents, { documenturl, documentname: file.name, 
          "documenttype":documenttype ? documenttype : docType, 
          documentsize: fileSizeBytes }];
        setDocuments(newDocuments);
        localStorage.setItem('documents', JSON.stringify(newDocuments));
        
        widths.forEach((width, index) => {
          setTimeout(() => {
            setLicenseUploadLoadWidth(width);
          }, (index + 1) * 300); 
        });

      }
      setTimeout(() => {
        setLicenseUploadStage("3")
      }, 2000);
    }
    if (result && (documenttype == "Certificate"  || docType ==  "Certificate" )) {
      setCertificateUploadStage("2")
      setErrorStyleForCertificateUpload(false)
      setcertificateDocName(file.name);
      var fileSizeBytes = file.size;
      var fileSize = formatSize(fileSizeBytes)
      setcertificateDocSize(fileSize)

      if (file.size > maxSizeInBytes) {
        showErrorToast("File size exceeds 5MB. Please select a smaller file.");

        setErrorStyleForCertificateUpload(true)
      } else if (!allowedTypes.includes(file.type)) {
        setErrorStyleForCertificateUpload(true)

        showErrorToast("Invalid file type. Please select a PNG, JPG, JPEG, or PDF file.");
      } else {

        // setDocuments((prevDocuments) => [
        //   ...prevDocuments,
        //   { documenturl, "documentname": file.name , documenttype, "documentsize":fileSizeBytes},
        // ]);
        const newDocuments = [...documents, { documenturl, documentname: file.name, 
         "documenttype":documenttype ? documenttype : docType, 
           documentsize: fileSizeBytes }];
        setDocuments(newDocuments);
        localStorage.setItem('documents', JSON.stringify(newDocuments));
        
        widths.forEach((width, index) => {
          setTimeout(() => {
            setCertificateUploadLoadWidth(width);
          }, (index + 1) * 300); 
        });

      }
      setTimeout(() => {
        setCertificateUploadStage("3")
      }, 2000);
    }
   // console.log(result, " document upload result ")

    // buffer animation here
    // setDocuments((prevDocuments) => [
    //   ...prevDocuments,
    //   { documenturl, documenttype },
    // ]);

    e.target.value = '';
    dismissLoader();
    if(screenType == "mobile" || screenType == "tablet" ){
      localStorage.setItem("activeComponent", "documents")
      window.location.reload()
    }

  };
  // useEffect(() => {
  //  // console.log(documents, " dociments")
  // }, [documents])

  useEffect(() => {
    const storedDocuments = JSON.parse(localStorage.getItem('documents')) || []; 

    const degreeDocs = storedDocuments.filter(doc => doc.documenttype == 'degree')
    const licenseDocs = storedDocuments.filter(doc => doc.documenttype == 'license')
    const certificateDocs = storedDocuments.filter(doc => doc.documenttype == 'Certificate')

    if(degreeDocs.length > 0){
      setDocuments(prevDocuments => [...prevDocuments, ...degreeDocs])
      setDegreeUploadStage('4')
    }
    if(licenseDocs.length > 0){
      setDocuments(prevDocuments => [...prevDocuments, ...licenseDocs])
      setLicenseUploadStage('4')
    }
    if(certificateDocs.length > 0){
      setDocuments(prevDocuments => [...prevDocuments, ...certificateDocs])
      setCertificateUploadStage('4')
    }
    

    // if(storedDocuments){
    //   setDocuments(storedDocuments);
    //   setDegreeUploadStage('4')
    // }

    // console.log("Documents loaded from localStorage: ", storedDocuments);

    let doctorId = localStorage.getItem("doctorid")
    setDoctorid(doctorId)
    // console.log(doctorId, "doctorID")
  }, [])


  const handleUploadDegree = () => {
    setDocumenttype("degree")
    document.getElementById("uploadDegree").click();
  }
  const handleUploadLicense = () => {
    setDocumenttype("license")
    document.getElementById("uploadLicense").click();
  }
  const handleUploadCertificate = () => {
    setDocumenttype("Certificate")
    document.getElementById("uploadCertificate").click();
  }

  const getIconForExtension = (filename) => {
    const basename = filename.substring(filename.lastIndexOf('/') + 1);
    const extension = basename.slice(basename.lastIndexOf('.') + 1).toLowerCase();
    
    switch (extension) {
      case 'png':
        return '/assets/imgs/png-icon.png';
      case 'jpg':
      case 'jpeg':
        return '/assets/imgs/jpg-icon.png';
      default:
        return '/assets/svgs/pdf-icon.svg'; 
    }
  }
  const getFileExtension = (filename) => {
    const basename = filename.substring(filename.lastIndexOf('/') + 1);
    const extension = basename.slice(basename.lastIndexOf('.') + 1).toLowerCase();
    return extension;
  }

  var inputForSignUpProfessionalDetails = {
    doctorid,
    "type": "document",
    documents
  }
// previous code 
  // const SubmitFunction = async () => {
  //  // console.log(inputForSignUpProfessionalDetails, " inputForSignUpProfessionalDetails")
  //  const getBackButtonStatus = localStorage.getItem('SubscriptionBackClicked');
  //  if (getBackButtonStatus) {
  //   setTimeout(() => {

  //     const newActiveComponent = "subscription";
  //     onSubmit(newActiveComponent);
  //   }, 1000);
  //   return;
  // }
  //  if (documents.length == 0) {
  //     showErrorToast("Please upload any document")
  //     return false;
  //   } else if(documents.length == 1 || documents.length == 2 ){
  //     showErrorToast("Please upload all documents")
  //     return false;
  //   } 
  //   else {
  //     showLoader();
  //     try {
  //       //console.log(inputForSignUpProfessionalDetails , " inputForSignUpProfessionalDetails")
  //       const result = await signUpProfessionalDetails(inputForSignUpProfessionalDetails);
  //       // console.log(result , " result of upload document")

  //       if (result.output.result == "Successfully Inserted") {

  //         showSuccessToast("Uploaded Successfully")
  //         setTimeout(() => {

  //           const newActiveComponent = "subscription";
  //           onSubmit(newActiveComponent);
  //         }, 1000);


  //       } else {
  //         console.error(result, "Something went wrong in Submit.")
  //         showErrorToast("Something went wrong!")
  //       }

  //     } catch (error) {
  //       console.error('Error in SubmitFunction:', error);
  //     }

  //   }
  //   dismissLoader();

  // };

  const SubmitFunction = async () => {
   // console.log(inputForSignUpProfessionalDetails, " inputForSignUpProfessionalDetails")
   
  //  if (documents.length == 0) {
  //     showErrorToast("Please upload any document")
  //     // return false;
  //   } else if(documents.length == 1 || documents.length == 2 ){
  //     showErrorToast("Please upload all documents")
  //     // return false;
  //   } else if(documents.length ==3){

  //     setTimeout(() => {
  //       showSuccessToast('Uploaded Successfully')
  //       const newActiveComponent = "subscription";
  //       onSubmit(newActiveComponent);
  //     }, 1000);
  //   }
  
  setTimeout(() => {
        if(documents.length > 0){
        showSuccessToast('Uploaded Successfully')
      }
        const newActiveComponent = "subscription";
        onSubmit(newActiveComponent);
      }, 1000);
  };
  const handleSkipDocsUpload = async () => {
   
        setTimeout(() => {
         setDocuments([])
         setDegreeUploadStage("1")
         setLicenseUploadStage("1")
         setCertificateUploadStage("1")
         localStorage.removeItem("documents")
         const newActiveComponent = "subscription";
         onSubmit(newActiveComponent);
       }, 1000);
   };
  const handleDelete = (docType) => {

    if (docType == "degree") {
      setTimeout(() => {
        const filteredDocuments = documents.filter(doc => doc.documenttype !== "degree");
        setDocuments(filteredDocuments);
        localStorage.setItem('documents', JSON.stringify(filteredDocuments));
        setDegreeUploadStage("1")
      }, 100);

    }
    else if (docType == "license") {
      setTimeout(() => {
        const filteredDocuments = documents.filter(doc => doc.documenttype !== "license");
        setDocuments(filteredDocuments);
        localStorage.setItem('documents', JSON.stringify(filteredDocuments));
        setLicenseUploadStage("1")
      }, 100);

    }
    else if (docType == "Certificate") {
      setTimeout(() => {
        const filteredDocuments = documents.filter(doc => doc.documenttype !== "Certificate");
        setDocuments(filteredDocuments);
        localStorage.setItem('documents', JSON.stringify(filteredDocuments));
        setCertificateUploadStage("1")
      }, 500);

    }

  }
  return (
    <>
      <Flex pt={{md: "3rem",lg: "10rem", xl: "3rem"}} display={{base: "none", lg: "flex"}}>

        {isLoading && <Loader />}
        <Box w="100%" p={4}>
          <Center>
            <VStack spacing={0} w="450px">
              <Box
                as="p"
                fontSize="22px"
                textAlign="center"
                fontWeight="600"
                mb={5}
              >
                Tell us a bit about <br /> your profession
              </Box>
              {/* file upload section start */}
              <Flex flexDir="column" gap="15px" w="100%">
                <Input
                  type="file"
                  accept=".pdf,.png,.jpg"
                  hidden
                  id="uploadDegree"
                  required
                  onChange={(e) => handleUploadDocument(e, "degree")}
                />
                {degreeUploadStage == "1" &&
                  <Box as="button" onClick={handleUploadDegree} flexDir="column" justifyContent="center" alignItems="center" w="100%" p="20px" gap="8px" border="1px dashed #CDCFD4" borderRadius="12px">
                    <Box display='flex' justifyContent='center' alignItems='center' mb="10px">
                      <Image w="auto" h="auto" src="/assets/svgs/file-upload.svg" />
                      {/* <Text as="span" color="red" fontSize="20px" ml="2"> *</Text> */}
                    </Box>
                    <Text fontSize="14px" color="#163E9E" fontWeight="600">
                      Upload degree
                    </Text>
                    <Text fontWeight="400" fontSize="12px" color="#6A707D">
                      PDF, PNG, JPG (max. 5mb)
                    </Text>

                  </Box>
                }

                {degreeUploadStage == "2" &&
                  <Flex bg="white" flexDir="column" w="100%" p="16px" border="1px solid #E6E7E9" borderRadius="12px">
                    <Box display="flex" gap="15px" w="100%">
                      <Image boxSize="2.5rem" src={getIconForExtension(degreeDocName)} />
                      <Box w="100%">
                        <Box display="flex" justifyContent="space-between" h="auto" w="100%">
                          <Text fontSize="14px" fontWeight="500" mb="6px">Degree.{getFileExtension(degreeDocName)}</Text>
                          <Box as="button" onClick={() => handleDelete("degree")}>
                            <Image w="13px" h="13px" src="/assets/imgs/close.png" />
                          </Box>
                        </Box>
                        <Box display="flex" gap="3px">
                          <Text fontSize="12px" fontWeight="400" color="#6A707D">{degreeDocSize} </Text>
                          <Image w="20px" h="auto" src="/assets/svgs/file-upload-spinner.svg" />
                          <Text fontSize="12px" fontWeight="400" color="#384052">Uploading...</Text>
                        </Box>
                      </Box>
                    </Box>
                    <Box h="6px" mt="15px" w="100%" borderRadius="full" bg="#E6E7E9">
                      <Box bg="#1C4EC5" w={degreeUploadLoadWidth} h="100%" borderRadius="full"></Box>
                    </Box>
                  </Flex>
                }

                {degreeUploadStage == "3" &&
                  <Flex bg="white" flexDir="column" w="100%" p="16px" border={errorStyleForDegreeUpload == false ? "1px solid #E6E7E9" : "2px solid #CA3A31"} borderRadius="12px">
                    <Box display="flex" gap="15px" w="100%">
                    <Image boxSize="2.5rem" src={getIconForExtension(degreeDocName)} />
                      <Box w="100%">
                        <Box display="flex" justifyContent="space-between" h="auto" w="100%">
                          <Text fontSize="14px" fontWeight="500" mb="6px">Degree.{getFileExtension(degreeDocName)}</Text>
                          <Box as="button" onClick={() => handleDelete("degree")}>
                            <Image w="16px" h="18px" src="/assets/imgs/delete.png" />
                          </Box>
                        </Box>
                        <Box display="flex" gap="5px">
                          <Text fontSize="12px" fontWeight="400" color="#6A707D">{degreeDocSize}</Text>
                          {!errorStyleForDegreeUpload ? (
                            <>
                              <Image w="16px" h="auto" ml="5px" src="/assets/svgs/uploaded-success-icon-green.svg" />

                              <Text fontSize="12px" fontWeight="400" color="#384052">Uploaded</Text>
                            </>
                          ) : (
                            <>
                              <Image w="16px" h="auto" ml="5px" src="/assets/svgs/uploaded-failed-icon-red.svg" />
                              <Text fontSize="12px" fontWeight="400" color="#384052">Failed</Text>
                            </>

                          )}
                        </Box>
                      </Box>
                    </Box>
                    {errorStyleForDegreeUpload &&
                      <Box as="button" pt="10px" onClick={handleUploadDegree}>
                        <Text fontSize="14px" textAlign="left" fontWeight="700" textDecor="underline" >Try again</Text>

                      </Box>
                    }
                  </Flex>
                }

 {documents
    .filter(doc => doc.documenttype === "degree")
    .map((doc, index) => ( degreeUploadStage == '4' &&
      <Box display="flex" gap="15px" w="100%" p="16px" border= "1px solid #E6E7E9" borderRadius="12px">
          <Image boxSize="2.5rem" src={getIconForExtension(doc.documentname)} />
          <Box w="100%">
            <Box display="flex" justifyContent="space-between" h="auto" w="100%">
              <Text fontSize="14px" fontWeight="500" mb="6px">Degree.{getFileExtension(doc.documentname)}</Text>
              <Box as="button" onClick={() => handleDelete(doc.documenttype)}>
                <Image w="16px" h="18px" src="/assets/imgs/delete.png" />
              </Box>
            </Box>
            <Box display="flex" gap="3px">
              <Text fontSize="12px" fontWeight="400" color="#6A707D">{formatSize(doc.documentsize)} </Text>
              <Image w="16px" h="auto" ml="5px" src="/assets/svgs/uploaded-success-icon-green.svg" />
              <Text fontSize="12px" fontWeight="400" color="#384052">Uploaded</Text>
            </Box>
          </Box>
        </Box>
    ))}

              </Flex>
              {/* file upload section end */}

              {/* uploading section start */}
              <Flex flexDir="column" gap="15px" w="100%">
                <Input
                  type="file"
                  accept=".pdf,.png,.jpg"
                  hidden
                  id="uploadLicense"
                  required
                  onChange={(e) => handleUploadDocument(e, "license")}
                />
                {licenseUploadStage == "1" &&
                  <Box as="button" onClick={handleUploadLicense} flexDir="column" justifyContent="center" alignItems="center" w="100%" p="20px" gap="8px" border="1px dashed #CDCFD4" borderRadius="12px">
                    {/* <Image w="20px" m="auto" mb="10px" h="auto" src="/assets/svgs/file-upload.svg" /> */}
                    <Box display='flex' justifyContent='center' alignItems='center' mb="10px">
                      <Image w="auto" h="auto" src="/assets/svgs/file-upload.svg" />
                      {/* <Text as="span" color="red" fontSize="20px" ml="2"> *</Text> */}
                    </Box>
                    <Text fontSize="14px" color="#163E9E" fontWeight="600">
                      Upload license
                    </Text>
                    <Text fontWeight="400" fontSize="12px" color="#6A707D">
                      PDF, PNG, JPG (max. 5mb)
                    </Text>

                  </Box>
                }

                {licenseUploadStage == "2" &&
                  <Flex bg="white" flexDir="column" w="100%" p="16px" border="1px solid #E6E7E9" borderRadius="12px">
                    <Box display="flex" gap="15px" w="100%">
                    <Image boxSize="2.5rem" src={getIconForExtension(licenceDocName)} />
                      <Box w="100%">
                        <Box display="flex" justifyContent="space-between" h="auto" w="100%">
                          <Text fontSize="14px" fontWeight="500" mb="6px">Licence.{getFileExtension(licenceDocName)}</Text>
                          <Box as="button" onClick={() => handleDelete("license")}>
                            <Image w="13px" h="13px" src="/assets/imgs/close.png" />
                          </Box>
                        </Box>
                        <Box display="flex" gap="3px">
                          <Text fontSize="12px" fontWeight="400" color="#6A707D">{licenseDocSize}</Text>
                          <Image w="20px" h="auto" src="/assets/svgs/file-upload-spinner.svg" />
                          <Text fontSize="12px" fontWeight="400" color="#384052">Uploading...</Text>
                        </Box>
                      </Box>
                    </Box>
                    <Box h="6px" mt="15px" w="100%" borderRadius="full" bg="#E6E7E9">
                      <Box bg="#1C4EC5" w={licenseUploadLoadWidth} h="100%" borderRadius="full"></Box>
                    </Box>
                  </Flex>
                }

                {licenseUploadStage == "3" &&
                  <Flex bg="white" flexDir="column" w="100%" p="16px" border={errorStyleForLicenseUpload == false ? "1px solid #E6E7E9" : "2px solid #CA3A31"} borderRadius="12px">
                    <Box display="flex" gap="15px" w="100%">
                    <Image boxSize="2.5rem" src={getIconForExtension(licenceDocName)} />
                      <Box w="100%">
                        <Box display="flex" justifyContent="space-between" h="auto" w="100%">
                          <Text fontSize="14px" fontWeight="500" mb="6px">Licence.{getFileExtension(licenceDocName)}</Text>
                          <Box as="button" onClick={() => handleDelete("license")}>
                            <Image w="16px" h="18px" src="/assets/imgs/delete.png" />
                          </Box>
                        </Box>
                        <Box display="flex" gap="5px">
                          <Text fontSize="12px" fontWeight="400" color="#6A707D">{licenseDocSize}</Text>
                          {!errorStyleForLicenseUpload ? (
                            <>
                              <Image w="16px" h="auto" ml="5px" src="/assets/svgs/uploaded-success-icon-green.svg" />

                              <Text fontSize="12px" fontWeight="400" color="#384052">Uploaded</Text>
                            </>
                          ) : (
                            <>
                              <Image w="16px" h="auto" ml="5px" src="/assets/svgs/uploaded-failed-icon-red.svg" />
                              <Text fontSize="12px" fontWeight="400" color="#384052">Failed</Text>
                            </>

                          )}
                        </Box>
                      </Box>
                    </Box>
                    {errorStyleForLicenseUpload &&
                      <Box as="button" pt="10px" onClick={handleUploadLicense}>
                        <Text fontSize="14px" textAlign="left" fontWeight="700" textDecor="underline">Try again</Text>
                      </Box>
                    }
                  </Flex>
                }

{documents
    .filter(doc => doc.documenttype === "license")
    .map((doc, index) => ( licenseUploadStage == '4' &&
      <Box display="flex" gap="15px" w="100%" p="16px" border= "1px solid #E6E7E9" borderRadius="12px">
          <Image boxSize="2.5rem" src={getIconForExtension(doc.documentname)} />
          <Box w="100%">
            <Box display="flex" justifyContent="space-between" h="auto" w="100%">
              <Text fontSize="14px" fontWeight="500" mb="6px">License.{getFileExtension(doc.documentname)}</Text>
              <Box as="button" onClick={() => handleDelete(doc.documenttype)}>
                <Image w="16px" h="18px" src="/assets/imgs/delete.png" />
              </Box>
            </Box>
            <Box display="flex" gap="3px">
              <Text fontSize="12px" fontWeight="400" color="#6A707D">{formatSize(doc.documentsize)} </Text>
              <Image w="16px" h="auto" ml="5px" src="/assets/svgs/uploaded-success-icon-green.svg" />
              <Text fontSize="12px" fontWeight="400" color="#384052">Uploaded</Text>
            </Box>
          </Box>
        </Box>
    ))}

              </Flex>
              {/* uploading section end */}

              {/* uploaded section start */}
              <Flex flexDir="column" gap="15px" w="100%">
  <Input
    type="file"
    accept=".pdf,.png,.jpg"
    hidden
    id="uploadCertificate"
    onChange={(e) => handleUploadDocument(e, "Certificate")}
  />

  {certificateUploadStage === "1" && (
    <Box as="button" onClick={handleUploadCertificate} flexDir="column" justifyContent="center" alignItems="center" w="100%" p="20px" gap="8px" border="1px dashed #CDCFD4" borderRadius="12px">
      <Box display='flex' justifyContent='center' alignItems='center' mb="10px">
        <Image w="auto" h="auto" src="/assets/svgs/file-upload.svg" />
        {/* <Text as="span" color="red" fontSize="20px" ml="2"> *</Text> */}
      </Box>
      <Text fontSize="14px" color="#163E9E" fontWeight="600">
        Upload qualification certificate
      </Text>
      <Text fontWeight="400" fontSize="12px" color="#6A707D">
        PDF, PNG, JPG (max. 5mb)
      </Text>
    </Box>
  )}

  {certificateUploadStage === "2" && (
    <Flex bg="white" flexDir="column" w="100%" p="16px" border="1px solid #E6E7E9" borderRadius="12px">
      <Box display="flex" gap="15px" w="100%">
        <Image boxSize="2.5rem" src={getIconForExtension(certificateDocName)} />
        <Box w="100%">
          <Box display="flex" justifyContent="space-between" h="auto" w="100%">
            <Text fontSize="14px" fontWeight="500" mb="6px">Certificate.{getFileExtension(certificateDocName)}</Text>
            <Box as="button" onClick={() => handleDelete("Certificate")}>
              <Image w="13px" h="13px" src="/assets/imgs/close.png" />
            </Box>
          </Box>
          <Box display="flex" gap="3px">
            <Text fontSize="12px" fontWeight="400" color="#6A707D">{certificateDocSize}</Text>
            <Image w="20px" h="auto" src="/assets/svgs/file-upload-spinner.svg" />
            <Text fontSize="12px" fontWeight="400" color="#384052">Uploading...</Text>
          </Box>
        </Box>
      </Box>
      <Box h="6px" mt="15px" w="100%" borderRadius="full" bg="#E6E7E9">
        <Box bg="#1C4EC5" w={certificateUploadLoadWidth} h="100%" borderRadius="full"></Box>
      </Box>
    </Flex>
  )}

  {certificateUploadStage === "3" && (
    <Flex bg="white" flexDir="column" w="100%" p="16px" border={errorStyleForCertificateUpload === false ? "1px solid #E6E7E9" : "2px solid #CA3A31"} borderRadius="12px">
      <Box display="flex" gap="15px" w="100%">
        <Image boxSize="2.5rem" src={getIconForExtension(certificateDocName)} />
        <Box w="100%">
          <Box display="flex" justifyContent="space-between" h="auto" w="100%">
            <Text fontSize="14px" fontWeight="500" mb="6px">Certificate.{getFileExtension(certificateDocName)}</Text>
            <Box as="button" onClick={() => handleDelete("Certificate")}>
              <Image w="16px" h="18px" src="/assets/imgs/delete.png" />
            </Box>
          </Box>
          <Box display="flex" gap="5px">
            <Text fontSize="12px" fontWeight="400" color="#6A707D">{certificateDocSize}</Text>
            {!errorStyleForCertificateUpload ? (
              <>
                <Image w="16px" h="auto" ml="5px" src="/assets/svgs/uploaded-success-icon-green.svg" />
                <Text fontSize="12px" fontWeight="400" color="#384052">Uploaded</Text>
              </>
            ) : (
              <>
                <Image w="16px" h="auto" ml="5px" src="/assets/svgs/uploaded-failed-icon-red.svg" />
                <Text fontSize="12px" fontWeight="400" color="#384052">Failed</Text>
              </>
            )}
          </Box>
        </Box>
      </Box>
      {errorStyleForCertificateUpload && (
        <Box as="button" pt="10px" onClick={handleUploadCertificate}>
          <Text fontSize="14px" textAlign="left" fontWeight="700" textDecor="underline">Try again</Text>
        </Box>
      )}
    </Flex>
  )}

  {/* Render stored certificate documents */}
  {certificateUploadStage === '4' && documents
    .filter(doc => doc.documenttype === "Certificate")
    .map((doc, index) => (
      <Box key={index} display="flex" gap="15px" w="100%" p="16px" border="1px solid #E6E7E9" borderRadius="12px">
        <Image boxSize="2.5rem" src={getIconForExtension(doc.documentname)} />
        <Box w="100%">
          <Box display="flex" justifyContent="space-between" h="auto" w="100%">
            <Text fontSize="14px" fontWeight="500" mb="6px">Certificate.{getFileExtension(doc.documentname)}</Text>
            <Box as="button" onClick={() => handleDelete(doc.documenttype)}>
              <Image w="16px" h="18px" src="/assets/imgs/delete.png" />
            </Box>
          </Box>
          <Box display="flex" gap="3px">
            <Text fontSize="12px" fontWeight="400" color="#6A707D">{formatSize(doc.documentsize)} </Text>
            <Image w="16px" h="auto" ml="5px" src="/assets/svgs/uploaded-success-icon-green.svg" />
            <Text fontSize="12px" fontWeight="400" color="#384052">Uploaded</Text>
          </Box>
        </Box>
      </Box>
    ))}
</Flex>

              {/* uploaded section end */}
              <Box
                display="flex"
                gap="8px"
                my={5}
                flexDirection="row"
                alignItems="center"
              >
                <Box as="span">
                  <Image w="18px" src="/assets/imgs/info-safe.png" />
                </Box>
                <Box as="p" lineHeight="normal" fontSize="12px">
                  Your information is safe with us.
                </Box>
              </Box>
              <Box w="100%" display="flex" flexDirection="row" gap="1rem">
              <PrimaryButton buttonText="Skip" id="skipButton" 
                variant="grayBtnFull" onClick={handleSkipDocsUpload} />
              <PrimaryButton buttonText="Continue" id="submitButton" onClick={SubmitFunction} />
              </Box>
            </VStack>
          </Center>
        </Box>
      </Flex>

      {/* Mobile responsive start */}
      <Flex flexDir="column" display={{base: "flex", lg: "none"}}>
      {isLoading && <Loader />}
        <Box w="100%" bg="black" h="6px" borderRadius="full" my="1rem">
          <Box as="span" w="75%" bg='#14C585' h="100%" borderRadius="full" display="flex"></Box>
        </Box>
        <Box w="100%" mb={5}>
          <Box as="p" fontSize="1.25rem" lineHeight="normal" letterSpacing="1px" mt="0.75rem" textAlign="center" fontWeight="600" mb={2}>
            Tell us a bit about your profession
          </Box>

          {/* file upload section start */}
          <Flex flexDir="column" gap="15px" w="100%">
                <Input
                  type="file"
                  accept=".pdf,.png,.jpg"
                  hidden
                  id="uploadDegree"
                  required
                  onChange={(e) => handleUploadDocument(e, "degree")}
                />
                {degreeUploadStage == "1" &&
                  <Box as="button" onClick={handleUploadDegree} flexDir="column" justifyContent="center" alignItems="center" w="100%" p="20px" gap="8px" border="1px dashed #CDCFD4" borderRadius="12px">
                    <Box display='flex' justifyContent='center' alignItems='center' mb="10px">
                      <Image w="auto" h="auto" src="/assets/svgs/file-upload.svg" />
                      {/* <Text as="span" color="red" fontSize="20px" ml="2"> *</Text> */}
                    </Box>
                    <Text fontSize="14px" color="#163E9E" fontWeight="600">
                      Upload degree
                    </Text>
                    <Text fontWeight="400" fontSize="12px" color="#6A707D">
                      PDF, PNG, JPG (max. 5mb)
                    </Text>

                  </Box>
                }

                {degreeUploadStage == "2" &&
                  <Flex bg="white" flexDir="column" w="100%" p="16px" border="1px solid #E6E7E9" borderRadius="12px">
                    <Box display="flex" gap="15px" w="100%">
                      <Image boxSize="2.5rem" src={getIconForExtension(degreeDocName)} />
                      <Box w="100%">
                        <Box display="flex" justifyContent="space-between" h="auto" w="100%">
                          <Text fontSize="14px" fontWeight="500" mb="6px">Degree.{getFileExtension(degreeDocName)}</Text>
                          <Box as="button" onClick={() => handleDelete("degree")}>
                            <Image w="13px" h="13px" src="/assets/imgs/close.png" />
                          </Box>
                        </Box>
                        <Box display="flex" gap="3px">
                          <Text fontSize="12px" fontWeight="400" color="#6A707D">{degreeDocSize} </Text>
                          <Image w="20px" h="auto" src="/assets/svgs/file-upload-spinner.svg" />
                          <Text fontSize="12px" fontWeight="400" color="#384052">Uploading...</Text>
                        </Box>
                      </Box>
                    </Box>
                    <Box h="6px" mt="15px" w="100%" borderRadius="full" bg="#E6E7E9">
                      <Box bg="#1C4EC5" w={degreeUploadLoadWidth} h="100%" borderRadius="full"></Box>
                    </Box>
                  </Flex>
                }

                {degreeUploadStage == "3" &&
                  <Flex bg="white" flexDir="column" w="100%" p="16px" border={errorStyleForDegreeUpload == false ? "1px solid #E6E7E9" : "2px solid #CA3A31"} borderRadius="12px">
                    <Box display="flex" gap="15px" w="100%">
                    <Image boxSize="2.5rem" src={getIconForExtension(degreeDocName)} />
                      <Box w="100%">
                        <Box display="flex" justifyContent="space-between" h="auto" w="100%">
                          <Text fontSize="14px" fontWeight="500" mb="6px">Degree.{getFileExtension(degreeDocName)}</Text>
                          <Box as="button" onClick={() => handleDelete("degree")}>
                            <Image w="16px" h="18px" src="/assets/imgs/delete.png" />
                          </Box>
                        </Box>
                        <Box display="flex" gap="5px">
                          <Text fontSize="12px" fontWeight="400" color="#6A707D">{degreeDocSize}</Text>
                          {!errorStyleForDegreeUpload ? (
                            <>
                              <Image w="16px" h="auto" ml="5px" src="/assets/svgs/uploaded-success-icon-green.svg" />

                              <Text fontSize="12px" fontWeight="400" color="#384052">Uploaded</Text>
                            </>
                          ) : (
                            <>
                              <Image w="16px" h="auto" ml="5px" src="/assets/svgs/uploaded-failed-icon-red.svg" />
                              <Text fontSize="12px" fontWeight="400" color="#384052">Failed</Text>
                            </>

                          )}
                        </Box>
                      </Box>
                    </Box>
                    {errorStyleForDegreeUpload &&
                      <Box as="button" pt="10px" onClick={handleUploadDegree}>
                        <Text fontSize="14px" textAlign="left" fontWeight="700" textDecor="underline" >Try again</Text>

                      </Box>
                    }
                  </Flex>
                }

              {documents
                  .filter(doc => doc.documenttype === "degree")
                  .map((doc, index) => ( degreeUploadStage == '4' &&
                    <Box display="flex" gap="15px" w="100%" p="16px" border= "1px solid #E6E7E9" borderRadius="12px">
                        <Image boxSize="2.5rem" src={getIconForExtension(doc.documentname)} />
                        <Box w="100%">
                          <Box display="flex" justifyContent="space-between" h="auto" w="100%">
                            <Text fontSize="14px" fontWeight="500" mb="6px">Degree.{getFileExtension(doc.documentname)}</Text>
                            <Box as="button" onClick={() => handleDelete(doc.documenttype)}>
                              <Image w="16px" h="18px" src="/assets/imgs/delete.png" />
                            </Box>
                          </Box>
                          <Box display="flex" gap="3px">
                            <Text fontSize="12px" fontWeight="400" color="#6A707D">{formatSize(doc.documentsize)} </Text>
                            <Image w="16px" h="auto" ml="5px" src="/assets/svgs/uploaded-success-icon-green.svg" />
                            <Text fontSize="12px" fontWeight="400" color="#384052">Uploaded</Text>
                          </Box>
                        </Box>
                      </Box>
                  ))}

              </Flex>
              {/* file upload section end */}

              {/* uploading section start */}
              <Flex flexDir="column" gap="15px" w="100%">
                <Input
                  type="file"
                  accept=".pdf,.png,.jpg"
                  hidden
                  id="uploadLicense"
                  required
                  onChange={(e) => handleUploadDocument(e, "license")}
                />
                {licenseUploadStage == "1" &&
                  <Box as="button" onClick={handleUploadLicense} flexDir="column" justifyContent="center" alignItems="center" w="100%" p="20px" gap="8px" border="1px dashed #CDCFD4" borderRadius="12px">
                    {/* <Image w="20px" m="auto" mb="10px" h="auto" src="/assets/svgs/file-upload.svg" /> */}
                    <Box display='flex' justifyContent='center' alignItems='center' mb="10px">
                      <Image w="auto" h="auto" src="/assets/svgs/file-upload.svg" />
                      {/* <Text as="span" color="red" fontSize="20px" ml="2"> *</Text> */}
                    </Box>
                    <Text fontSize="14px" color="#163E9E" fontWeight="600">
                      Upload license
                    </Text>
                    <Text fontWeight="400" fontSize="12px" color="#6A707D">
                      PDF, PNG, JPG (max. 5mb)
                    </Text>

                  </Box>
                }

                {licenseUploadStage == "2" &&
                  <Flex bg="white" flexDir="column" w="100%" p="16px" border="1px solid #E6E7E9" borderRadius="12px">
                    <Box display="flex" gap="15px" w="100%">
                    <Image boxSize="2.5rem" src={getIconForExtension(licenceDocName)} />
                      <Box w="100%">
                        <Box display="flex" justifyContent="space-between" h="auto" w="100%">
                          <Text fontSize="14px" fontWeight="500" mb="6px">Licence.{getFileExtension(licenceDocName)}</Text>
                          <Box as="button" onClick={() => handleDelete("license")}>
                            <Image w="13px" h="13px" src="/assets/imgs/close.png" />
                          </Box>
                        </Box>
                        <Box display="flex" gap="3px">
                          <Text fontSize="12px" fontWeight="400" color="#6A707D">{licenseDocSize}</Text>
                          <Image w="20px" h="auto" src="/assets/svgs/file-upload-spinner.svg" />
                          <Text fontSize="12px" fontWeight="400" color="#384052">Uploading...</Text>
                        </Box>
                      </Box>
                    </Box>
                    <Box h="6px" mt="15px" w="100%" borderRadius="full" bg="#E6E7E9">
                      <Box bg="#1C4EC5" w={licenseUploadLoadWidth} h="100%" borderRadius="full"></Box>
                    </Box>
                  </Flex>
                }

                {licenseUploadStage == "3" &&
                  <Flex bg="white" flexDir="column" w="100%" p="16px" border={errorStyleForLicenseUpload == false ? "1px solid #E6E7E9" : "2px solid #CA3A31"} borderRadius="12px">
                    <Box display="flex" gap="15px" w="100%">
                    <Image boxSize="2.5rem" src={getIconForExtension(licenceDocName)} />
                      <Box w="100%">
                        <Box display="flex" justifyContent="space-between" h="auto" w="100%">
                          <Text fontSize="14px" fontWeight="500" mb="6px">Licence.{getFileExtension(licenceDocName)}</Text>
                          <Box as="button" onClick={() => handleDelete("license")}>
                            <Image w="16px" h="18px" src="/assets/imgs/delete.png" />
                          </Box>
                        </Box>
                        <Box display="flex" gap="5px">
                          <Text fontSize="12px" fontWeight="400" color="#6A707D">{licenseDocSize}</Text>
                          {!errorStyleForLicenseUpload ? (
                            <>
                              <Image w="16px" h="auto" ml="5px" src="/assets/svgs/uploaded-success-icon-green.svg" />

                              <Text fontSize="12px" fontWeight="400" color="#384052">Uploaded</Text>
                            </>
                          ) : (
                            <>
                              <Image w="16px" h="auto" ml="5px" src="/assets/svgs/uploaded-failed-icon-red.svg" />
                              <Text fontSize="12px" fontWeight="400" color="#384052">Failed</Text>
                            </>

                          )}
                        </Box>
                      </Box>
                    </Box>
                    {errorStyleForLicenseUpload &&
                      <Box as="button" pt="10px" onClick={handleUploadLicense}>
                        <Text fontSize="14px" textAlign="left" fontWeight="700" textDecor="underline">Try again</Text>
                      </Box>
                    }
                  </Flex>
                }

              {documents
                  .filter(doc => doc.documenttype === "license")
                  .map((doc, index) => ( licenseUploadStage == '4' &&
                    <Box display="flex" gap="15px" w="100%" p="16px" border= "1px solid #E6E7E9" borderRadius="12px">
                        <Image boxSize="2.5rem" src={getIconForExtension(doc.documentname)} />
                        <Box w="100%">
                          <Box display="flex" justifyContent="space-between" h="auto" w="100%">
                            <Text fontSize="14px" fontWeight="500" mb="6px">License.{getFileExtension(doc.documentname)}</Text>
                            <Box as="button" onClick={() => handleDelete(doc.documenttype)}>
                              <Image w="16px" h="18px" src="/assets/imgs/delete.png" />
                            </Box>
                          </Box>
                          <Box display="flex" gap="3px">
                            <Text fontSize="12px" fontWeight="400" color="#6A707D">{formatSize(doc.documentsize)} </Text>
                            <Image w="16px" h="auto" ml="5px" src="/assets/svgs/uploaded-success-icon-green.svg" />
                            <Text fontSize="12px" fontWeight="400" color="#384052">Uploaded</Text>
                          </Box>
                        </Box>
                      </Box>
                  ))}

              </Flex>
              {/* uploading section end */}

              {/* uploaded section start */}
              <Flex flexDir="column" gap="15px" w="100%">
                <Input
                  type="file"
                  accept=".pdf,.png,.jpg"
                  hidden
                  id="uploadCertificate"
                  onChange={(e) => handleUploadDocument(e, "Certificate")}
                />

                {certificateUploadStage === "1" && (
                  <Box as="button" onClick={handleUploadCertificate} flexDir="column" justifyContent="center" alignItems="center" w="100%" p="20px" gap="8px" border="1px dashed #CDCFD4" borderRadius="12px">
                    <Box display='flex' justifyContent='center' alignItems='center' mb="10px">
                      <Image w="auto" h="auto" src="/assets/svgs/file-upload.svg" />
                      {/* <Text as="span" color="red" fontSize="20px" ml="2"> *</Text> */}
                    </Box>
                    <Text fontSize="14px" color="#163E9E" fontWeight="600">
                      Upload qualification certificate
                    </Text>
                    <Text fontWeight="400" fontSize="12px" color="#6A707D">
                      PDF, PNG, JPG (max. 5mb)
                    </Text>
                  </Box>
                )}

                {certificateUploadStage === "2" && (
                  <Flex bg="white" flexDir="column" w="100%" p="16px" border="1px solid #E6E7E9" borderRadius="12px">
                    <Box display="flex" gap="15px" w="100%">
                      <Image boxSize="2.5rem" src={getIconForExtension(certificateDocName)} />
                      <Box w="100%">
                        <Box display="flex" justifyContent="space-between" h="auto" w="100%">
                          <Text fontSize="14px" fontWeight="500" mb="6px">Certificate.{getFileExtension(certificateDocName)}</Text>
                          <Box as="button" onClick={() => handleDelete("Certificate")}>
                            <Image w="13px" h="13px" src="/assets/imgs/close.png" />
                          </Box>
                        </Box>
                        <Box display="flex" gap="3px">
                          <Text fontSize="12px" fontWeight="400" color="#6A707D">{certificateDocSize}</Text>
                          <Image w="20px" h="auto" src="/assets/svgs/file-upload-spinner.svg" />
                          <Text fontSize="12px" fontWeight="400" color="#384052">Uploading...</Text>
                        </Box>
                      </Box>
                    </Box>
                    <Box h="6px" mt="15px" w="100%" borderRadius="full" bg="#E6E7E9">
                      <Box bg="#1C4EC5" w={certificateUploadLoadWidth} h="100%" borderRadius="full"></Box>
                    </Box>
                  </Flex>
                )}

                {certificateUploadStage === "3" && (
                  <Flex bg="white" flexDir="column" w="100%" p="16px" border={errorStyleForCertificateUpload === false ? "1px solid #E6E7E9" : "2px solid #CA3A31"} borderRadius="12px">
                    <Box display="flex" gap="15px" w="100%">
                      <Image boxSize="2.5rem" src={getIconForExtension(certificateDocName)} />
                      <Box w="100%">
                        <Box display="flex" justifyContent="space-between" h="auto" w="100%">
                          <Text fontSize="14px" fontWeight="500" mb="6px">Certificate.{getFileExtension(certificateDocName)}</Text>
                          <Box as="button" onClick={() => handleDelete("Certificate")}>
                            <Image w="16px" h="18px" src="/assets/imgs/delete.png" />
                          </Box>
                        </Box>
                        <Box display="flex" gap="5px">
                          <Text fontSize="12px" fontWeight="400" color="#6A707D">{certificateDocSize}</Text>
                          {!errorStyleForCertificateUpload ? (
                            <>
                              <Image w="16px" h="auto" ml="5px" src="/assets/svgs/uploaded-success-icon-green.svg" />
                              <Text fontSize="12px" fontWeight="400" color="#384052">Uploaded</Text>
                            </>
                          ) : (
                            <>
                              <Image w="16px" h="auto" ml="5px" src="/assets/svgs/uploaded-failed-icon-red.svg" />
                              <Text fontSize="12px" fontWeight="400" color="#384052">Failed</Text>
                            </>
                          )}
                        </Box>
                      </Box>
                    </Box>
                    {errorStyleForCertificateUpload && (
                      <Box as="button" pt="10px" onClick={handleUploadCertificate}>
                        <Text fontSize="14px" textAlign="left" fontWeight="700" textDecor="underline">Try again</Text>
                      </Box>
                    )}
                  </Flex>
                )}

                {/* Render stored certificate documents */}
                {certificateUploadStage === '4' && documents
                  .filter(doc => doc.documenttype === "Certificate")
                  .map((doc, index) => (
                    <Box key={index} display="flex" gap="15px" w="100%" p="16px" border="1px solid #E6E7E9" borderRadius="12px">
                      <Image boxSize="2.5rem" src={getIconForExtension(doc.documentname)} />
                      <Box w="100%">
                        <Box display="flex" justifyContent="space-between" h="auto" w="100%">
                          <Text fontSize="14px" fontWeight="500" mb="6px">Certificate.{getFileExtension(doc.documentname)}</Text>
                          <Box as="button" onClick={() => handleDelete(doc.documenttype)}>
                            <Image w="16px" h="18px" src="/assets/imgs/delete.png" />
                          </Box>
                        </Box>
                        <Box display="flex" gap="3px">
                          <Text fontSize="12px" fontWeight="400" color="#6A707D">{formatSize(doc.documentsize)} </Text>
                          <Image w="16px" h="auto" ml="5px" src="/assets/svgs/uploaded-success-icon-green.svg" />
                          <Text fontSize="12px" fontWeight="400" color="#384052">Uploaded</Text>
                        </Box>
                      </Box>
                    </Box>
                  ))}
              </Flex>

              {/* uploaded section end */}
              <Box
                display="flex"
                gap="8px"
                my={5}
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
              >
                <Box as="span">
                  <Image w="18px" src="/assets/imgs/info-safe.png" />
                </Box>
                <Box as="p" lineHeight="normal" fontSize="12px">
                  Your information is safe with us.
                </Box>
              </Box>
              <Box w="100%" display="flex" flexDirection="row" gap="1rem">
              <PrimaryButton buttonText="Skip" id="skipButton" 
                variant="grayBtnFull" onClick={handleSkipDocsUpload} />
              <PrimaryButton buttonText="Continue" id="submitButton" onClick={SubmitFunction} />
              </Box>
        </Box>
      </Flex>
    </>
  );
};

export default UploadDocuments;
