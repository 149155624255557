import React, { useState, useRef, useEffect } from "react";
import { Box, Text, IconButton, Button, Input } from "@chakra-ui/react";
import { FaRegThumbsDown, FaRegThumbsUp } from "react-icons/fa";
import DynamicModal from "../../../dynamic-modal";
import { COLORS } from "../../../styles/colors";
import PrimaryButton from "../../../primary-button";
import { GPUChatAudioManager } from "../cheif-complaint-audio/gpuChatVoiceManager";
import { useGPUTranscriber } from "../cheif-complaint-audio/useGPUTranscriber";
import useLoader from "../../../loader/loaderstates";
import Toaster from "../../../toaster";
import Loader from "../../../loader/loader";
import { SubmitAiFeedback } from "../../../../middleware/services/visits/aicalls";


const AIFeedbackFooter = ({
  width = "100%",
  height = "auto",
  disclaimer,
  doctorId,
  patientId,
  appointmentId,
  aiInput,
  aiOutput,
  featureType,
  containerStyle
}) => {
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const { showSuccessToast, showErrorToast } = Toaster();

  const feedbackModal = useRef();
  const [feedbackText, setFeedbackText] = useState("");

  const activeTranscriber = useGPUTranscriber();

  const [typing, setTyping] = useState(false); // AI Typing indicator state
  const [placeholder, setPlaceholder] = useState(
    "Please enter your feedback here...."
  );

  const [thumbsUpColor, setThumbsUpColor] = useState("gray");
  const [thumbsDownColor, setThumbsDownColor] = useState("gray");
  const [showFeedbackButtons, setShowFeedbackButtons] = useState(true);

  useEffect(() => {
    // console.log("triggered");
    if (activeTranscriber?.output?.text) {
      setFeedbackText(activeTranscriber.output.text);

      setPlaceholder("Please enter your feedback here....");
      setTyping(false);
    }
  }, [activeTranscriber?.output]);

  const handleThumbsUpClick = () => {
    setThumbsUpColor((prevColor) =>
      prevColor === "gray" ? "green.400" : "gray"
    );
    if (thumbsDownColor === "red.400") setThumbsDownColor("gray");
    
    handleFeedbackSubmit("1");
  };

  const handleThumbsDownClick = () => {
    setThumbsDownColor((prevColor) =>
      prevColor === "gray" ? "red.400" : "gray"
    );
    if (thumbsUpColor === "green.400") setThumbsUpColor("gray");
    feedbackModal.current.openModal();
  };

  const handleFeedbackSubmit = async (feedbackType) => {
    const inputToSave = {
      input: {
        doctorid: doctorId,
        patientid: patientId,
        appointmentid: appointmentId,
        input: aiInput,
        output: aiOutput,
        type: featureType,
        feedback: feedbackType,
        feedbackcomment: feedbackText,
        usertype: "doctor"
      },
    };
    console.log(inputToSave);
    // showLoader();
    try {
      const result = await SubmitAiFeedback(inputToSave.input);
      console.log(result)
      if (result) {
        if (inputToSave.input.feedback === "like") {
          showSuccessToast("Thanks for using AIRA.");
        } else {
          showSuccessToast("Thanks for helping us improve.");
        }
        setShowFeedbackButtons(false);
      }
    } catch (error) {
      console.error("Error in Update Doctor Notes:", error);
      showErrorToast("Error Happened While Saving.");
    } finally {
      //   dismissLoader();
    }
  };

  return (
    <Box
      bg="#fcfcfd"
      width={width}
      height={height}
      p={4}
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      sx={{...containerStyle}}
    >
      <Text mb={2} fontWeight="semibold">{disclaimer}</Text>

      {showFeedbackButtons && (
        <Box display="flex" alignItems="center" mb={2}>
          <IconButton
            aria-label="Thumbs Up"
            icon={<FaRegThumbsUp />}
            color={thumbsUpColor}
            variant="ghost"
            onClick={handleThumbsUpClick}
            _hover={{ color: "green.400", transform: "scale(1.1)" }}
            _active={{ transform: "scale(0.9)" }}
            mr={2}
          />
          <IconButton
            aria-label="Thumbs Down"
            icon={<FaRegThumbsDown />}
            color={thumbsDownColor}
            variant="ghost"
            onClick={handleThumbsDownClick}
            _hover={{ color: "red.400", transform: "scale(1.1)" }}
            _active={{ transform: "scale(0.9)" }}
          />
        </Box>
      )}

      {/* <Box display="flex" justifyContent="flex-end" width="100%">
        <Button colorScheme="blue" variant="ghost" mr={2}>
          Button 1
        </Button>
        <Button colorScheme="blue" variant="ghost">
          Button 2
        </Button>
      </Box> */}

      <DynamicModal
        ref={feedbackModal}
        modalHeader={`Aira - AI: Help AIRA Improve`}
        modalBody={
          <>
            <Box
              display="flex"
              justifyContent="center"
              mx="auto"
              maxWidth="100%"
            >
              <GPUChatAudioManager
                transcriber={activeTranscriber}
                setPlaceholder={setPlaceholder}
                setTyping={setTyping}
              />
            </Box>

            <Input
              onChange={(e) => setFeedbackText(e.target.value)}
              height="auto"
              padding="2"
              value={feedbackText}
              placeholder={placeholder}
              //   resize="none"
              as="textarea"
              disabled={typing} // Disable when AI typing is true
              style={{ overflowWrap: "break-word", whiteSpace: "pre-wrap" }}
              mt="2rem"
            />
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              buttonText={feedbackText ? "Submit Feedback" : "Skip"}
              variant="grayBtn"
              btnStyle={{
                w: "100%",
                color: COLORS.PRIMARY_COLOR,
                h: "2.5rem",
              }}
              isDisabled={typing}
              onClick={() => {
                feedbackModal.current.closeModal();
                handleFeedbackSubmit("0");
              }}
            />
          </>
        }
      />
    </Box>
  );
};

export default AIFeedbackFooter;
