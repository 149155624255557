export default function formAPIreviewofsystem({
    rosname,
    DATE,
    date,
    doctorid,
    id,
    rosvalue,
    patientid,
    specialization,
    bookingid,
    doctorname

})
{
 
  return Object.freeze({
    rosname,
    DATE,
    date,
    doctorid,
    id,
    rosvalue,
    patientid,
    specialization,
    bookingid,
    doctorname
  });
}