import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import LoginIndex from "../pages/login";
import SignUpIndex from "../pages/signup";
import Profile from "../pages/profile";
import RequestSubmit from "../pages/signup/request-submit";
import HomeIndex from "../pages/home";
import DoctorProfileIndex from "../pages/profile/doctorprofile";
// import SubscriptionIndex from "../pages/profile/subscription";
// import InvoiceIndex from "../pages/profile/invoices";
import VisitsIndex from "../pages/visits";
import VisitDetailsIndex from "../pages/visits/visitdetails";
import ReportIndex from "../pages/reports";
import MessagesIndex from "../pages/messages";
import PatientProfileIndex from "../pages/messages/patientprofile";
import VerificationSuccess from "../pages/finalsteps/verification-success";
import FinalStepsIndex from "../pages/finalsteps/finalsteps";
import StartVisitIndex from "../pages/visits/startvisit";
import VisitPrescription from "../pages/visits/visitprescription";

import { getToken } from "../auth";
import PatientVisitsIndex from "../pages/messages/patientvisits";
import OverviewIndex from "../pages/messages/overview";
import NotificationsIndex from "../pages/notifications";
import DoctorWebsite from "../pages/home/doctorwebsite";
import PatientViewIndex from "../pages/patients";
import PrescriptionViewIndex from "../pages/prescriptions";
import MedicineViewIndex from "../pages/medicines";
import MoreIndex from "../pages/profile";
import RolesIndex from "../pages/roles";
import UsersIndex from "../pages/users";
import InventoryIndex from "../pages/inventory";
import EditOrder from "../pages/billing/editorder";
import BillingIndex from "../pages/billing";
import OTCSale from "../pages/billing/otcsale";
import EditLabOrder from "../pages/billing/editorderforlab";
import EditAppointmentOrder from "../pages/billing/editorderforappointment";
import InvoicesIndex from "../pages/invoices";
import LabPricingIndex from "../pages/billing/labpricing";
import InventoryLogsIndex from "../pages/inventory/inventorylogs";
import ImagePricingIndex from "../pages/billing/imagepricing";

import UploadDocument from "../pages/messages/uploaddocument";
import SuccessUploadIndex from "../pages/messages/success";

import TotalRevenueIndex from "../pages/totalrevenue";
import ImagingUpload from "../pages/messages/imagingupload";
import GenericUpload from "../pages/messages/genericupload";
import PrescriptionUpload from "../pages/messages/prescriptionupload";
import VisitSummaryDocument from "../pages/visits/visitSummary";
import BranchesIndex from "../pages/home/branches";
import AdvanceDetails from "../pages/home/advancedetails";



function Routing() {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  
  let pages = JSON.parse(localStorage.getItem("pagesAccess"));
  const [firstTruePage, setFirstTruePage] = useState(null);
  
  const [actionAccess, setActionAccess] = useState(pages);
  const checkUserToken = () => {
    const userToken = getToken();
    if (!userToken || userToken == "undefined") {
      setIsLoggedIn(false);
      setTimeout(() => {
        setIsLoggedIn(true);
      }, 300);
    } else if (userToken) {
      setIsLoggedIn(true);
    }
  };
  useEffect(() => {
    checkUserToken();
  }, []);

  
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={isLoggedIn ? <Navigate to="/home" /> : <LoginIndex />} />
          <Route path="/login" element={<LoginIndex />} />
          <Route path="/signup" element={<SignUpIndex />} />
          <Route path="/request-submit" element={<RequestSubmit />} />
          <Route path="/requestsubmit" element={<RequestSubmit />} />
          <Route
            path="/verification-success"
            element={<VerificationSuccess />}
          />
          <Route path="/finalsteps" element={<FinalStepsIndex />} />
          <Route path="/doctorwebsite" element={<DoctorWebsite />} />

          <Route
            path="/profile"
            element={!isLoggedIn ? <Navigate to="/login" /> : <MoreIndex />}
          />
          <Route
            path="/home"
           element={!isLoggedIn ? <Navigate to="/login" /> : <HomeIndex />}
            // element={
            //   !isLoggedIn ? (
            //     <Navigate to="/login" />
            //   ) : actionAccess?.disablehome ? (
            //     <Navigate to="/home" />
            //   ) : (
            //     <HomeIndex />
            //   )
            // }
          />
          <Route
            path="/doctorprofile"
            element={
              !isLoggedIn ? <Navigate to="/login" /> : <DoctorProfileIndex />
            }
          />
          {/* <Route path="/subscription" element={!isLoggedIn ? <Navigate to="/login" /> : <SubscriptionIndex />} />
          <Route path="/invoices" element={!isLoggedIn ? <Navigate to="/login" /> : <InvoiceIndex />} /> */}
          <Route
            path="/visits"
            element={
              !isLoggedIn ? (
                <Navigate to="/login" />
              ) : actionAccess?.disablevisits ? (
                <Navigate to="/home" />
              ) : (
                <VisitsIndex />
              )
            }
          />
          <Route
            path="/visitdetails"
            element={
              !isLoggedIn ? (
                <Navigate to="/login" />
              ) : actionAccess?.disablevisits ? (
                <Navigate to="/home" />
              ) : (
                <VisitDetailsIndex />
              )
            }
          />
          <Route
            path="/reports"
            element={
              !isLoggedIn ? (
                <Navigate to="/login" />
              ) : actionAccess?.disablereports ? (
                <Navigate to="/home" />
              ) : (
                <ReportIndex />
              )
            }
          />
          <Route
            path="/messages"
            element={
              !isLoggedIn ? (
                <Navigate to="/login" />
              ) : actionAccess?.disablemessages ? (
                <Navigate to="/home" />
              ) : (
                <MessagesIndex />
              )
            }
          />
          <Route
            path="/patientprofile"
            element={
              !isLoggedIn ? <Navigate to="/login" /> : <PatientProfileIndex />
            }
          />
          <Route
            path="/overview"
            element={!isLoggedIn ? <Navigate to="/login" /> : <OverviewIndex />}
          />
          <Route
            path="/patientvisits"
            element={
              !isLoggedIn ? <Navigate to="/login" /> : <PatientVisitsIndex />
            }
          />
          <Route
            path="/notifications"
            element={
              !isLoggedIn ? (
                <Navigate to="/login" />
              ) : actionAccess?.disablenotifications ? (
                <Navigate to="/home" />
              ) : (
                <NotificationsIndex />
              )
            }
          />
          <Route
            path="/totalrevenue"
            element={
              !isLoggedIn ? (
                <Navigate to="/login" />
              ) : actionAccess?.disabletotalrevenue ? (
                <Navigate to="/home" />
              ) : (
                <TotalRevenueIndex />
              )
            }
          />

          <Route
            path="/profile"
            element={!isLoggedIn ? <Navigate to="/login" /> : <HomeIndex />}
          />
          <Route
            path="/home"
            element={
              !isLoggedIn ? (
                <Navigate to="/login" />
              )  : (
                <Profile />
              )
            }

          />

          <Route
            path="/doctorprofile"
            element={
              !isLoggedIn ? <Navigate to="/login" /> : <DoctorProfileIndex />
            }
          />
          {/* <Route
            path="/subscription"
            element={
              !isLoggedIn ? <Navigate to="/login" /> : <SubscriptionIndex />
            }
          />
          <Route
            path="/invoices"
            element={!isLoggedIn ? <Navigate to="/login" /> : <InvoiceIndex />}
          /> */}
          {/* <Route
            path="/visits"
            element={!isLoggedIn ? <Navigate to="/login" /> : <VisitsIndex />}
          /> */}
          <Route
            path="/visitdetails"
            element={
              !isLoggedIn ? (
                <Navigate to="/login" />
              ) : actionAccess?.disablevisits ? (
                <Navigate to="/home" />
              ) : (
                <VisitDetailsIndex />
              )
            }
          />
          <Route
            path="/reports"
            element={
              !isLoggedIn ? (
                <Navigate to="/login" />
              ) : actionAccess?.disablereports ? (
                <Navigate to="/home" />
              ) : (
                <ReportIndex />
              )
            }
          />
          <Route
            path="/messages"
            element={
              !isLoggedIn ? (
                <Navigate to="/login" />
              ) : actionAccess?.disablemessages ? (
                <Navigate to="/home" />
              ) : (
                <MessagesIndex />
              )
            }
          />
          <Route
            path="/patients"
            element={
              !isLoggedIn ? (
                <Navigate to="/login" />
              ) : actionAccess?.disablepatients ? (
                <Navigate to="/home" />
              ) : (
                <PatientViewIndex />
              )
            }
          />
          <Route
            path="/prescriptions"
            element={
              !isLoggedIn ? (
                <Navigate to="/login" />
              ) : actionAccess?.disableprescriptions ? (
                <Navigate to="/home" />
              ) : (
                <PrescriptionViewIndex />
              )
            }
          />
          <Route
            path="/medicines"
            element={
              !isLoggedIn ? (
                <Navigate to="/login" />
              ) : actionAccess?.disablemedicines ? (
                <Navigate to="/home" />
              ) : (
                <MedicineViewIndex />
              )
            }
          />
          <Route
            path="/invoices"
            element={
              !isLoggedIn ? (
                <Navigate to='/login' />
              ) : actionAccess?.disableinvoices ? (
                <Navigate to="/home" />
              ) : (
                <InvoicesIndex />
              )
            }
          />
          <Route
            path="/patientprofile"
            element={
              !isLoggedIn ? (
                <Navigate to="/login" />
              ) : actionAccess?.disablepatients ? (
                <Navigate to="/home" />
              ) : (
                <PatientProfileIndex />
              )
            }
          />

<Route
            path="/uploaddocument"
            element={
              !isLoggedIn ? (
                <Navigate to="/login" />
              ) : actionAccess?.disablepatients ? (
                <Navigate to="/home" />
              ) : (
                <UploadDocument />
              )
            }
          />

          
<Route
            path="/imagingupload"
            element={
              !isLoggedIn ? (
                <Navigate to="/login" />
              ) : actionAccess?.disablepatients ? (
                <Navigate to="/home" />
              ) : (
                <ImagingUpload />
              )
            }
          />

<Route
            path="/successupload"
            element={
              !isLoggedIn ? (
                <Navigate to="/login" />
              ) : actionAccess?.disablepatients ? (
                <Navigate to="/home" />
              ) : (
                <SuccessUploadIndex />
              )
            }
          />


          
<Route
            path="/docupload"
            element={
              !isLoggedIn ? (
                <Navigate to="/login" />
              ) : actionAccess?.disablepatients ? (
                <Navigate to="/home" />
              ) : (
                <GenericUpload />
              )
            }
          />

<Route
            path="/prescriptionupload"
            element={
              !isLoggedIn ? (
                <Navigate to="/login" />
              ) : actionAccess?.disablepatients ? (
                <Navigate to="/home" />
              ) : (
                <PrescriptionUpload />
              )
            }
          />



        
      
     

          <Route
            path="/startvisit"
            element={
              !isLoggedIn ? (
                <Navigate to="/login" />
              ) : actionAccess?.disablevisits ? (
                <Navigate to="/home" />
              ) : (
                <StartVisitIndex />
              )
            }
          />
          <Route
            path="/visitprescription"
            element={
              !isLoggedIn ? <Navigate to="/login" /> : <VisitPrescription />
            }
          />
          <Route
            path="/visitsummary"
            element={
              !isLoggedIn ? <Navigate to="/login" /> : <VisitSummaryDocument />
            }
          />
          <Route
            path="/visitprescription"
            element={
              !isLoggedIn ? <Navigate to="/login" /> : <VisitPrescription />
            }
          />
          <Route
            path="/roles"
            element={
              !isLoggedIn ? (
                <Navigate to="/login" />
              ) : actionAccess?.disableroles ? (
                <Navigate to="/home" />
              ) : (
                <RolesIndex />
              )
            }
          />
          <Route
            path="/users"
            element={
              !isLoggedIn ? (
                <Navigate to="/login" />
              ) : actionAccess?.disableusers ? (
                <Navigate to="/home" />
              ) : (
                <UsersIndex />
              )
            }
          />
          <Route
            path="/inventory"
            element={
              !isLoggedIn ? (
                <Navigate to="/login" />
              ) : actionAccess?.disableinventory  ? (
                <Navigate to="/home" />
              ) : (
                <InventoryIndex />
              )
            }
          />
           <Route
            path="/inventorylogs"
            element={
              !isLoggedIn ? (
                <Navigate to="/login" />
              ) : actionAccess?.disableinventory  ? (
                <Navigate to="/home" />
              ) : (
                <InventoryLogsIndex />
              )
            }
          />
          
          <Route
            path="/billing"
            element={
              !isLoggedIn ? (
                <Navigate to="/login" />
              ) : actionAccess?.disablefinance ? (
                <Navigate to="/home" />
              ) : (
                <BillingIndex />
              )
            }
          />
          <Route
            path="/editorder"
            element={
              !isLoggedIn ? (
                <Navigate to="/login" />
              ) : actionAccess?.disablefinance ? (
                <Navigate to="/home" />
              ) : (
                <EditOrder />
              )
            }
          />
           <Route
            path="/laborder"
            element={
              !isLoggedIn ? (
                <Navigate to="/login" />
              ) : actionAccess?.disablefinance ? (
                <Navigate to="/home" />
              ) : (
                <EditLabOrder />
              )
            }
          />
          <Route
            path="/otcsale"

            element={
              !isLoggedIn ? (
                <Navigate to="/login" />
              ) : actionAccess?.disablefinance ? (
                <Navigate to="/home" />
              ) : (
                <OTCSale />
              )
            }
          />
           <Route
            path="/appointmentorder"

            element={
              !isLoggedIn ? (
                <Navigate to="/login" />
              ) : actionAccess?.disablefinance ? (
                <Navigate to="/home" />
              ) : (
                <EditAppointmentOrder />
              )
            }
          />
           <Route
            path="/labpricing"

            element={
              !isLoggedIn ? (
                <Navigate to="/login" />
              ) : actionAccess?.disablefinance ? (
                <Navigate to="/home" />
              ) : (
                <LabPricingIndex />
              )
            }
          />
          <Route
            path="/imagepricing"

            element={
              !isLoggedIn ? (
                <Navigate to="/login" />
              ) : actionAccess?.disablefinance ? (
                <Navigate to="/home" />
              ) : (
                <ImagePricingIndex />
              )
            }
          />
           <Route
            path="/branches"

            element={
              !isLoggedIn ? (
                <Navigate to="/login" />
              ) : actionAccess?.disablefinance ? (
                <Navigate to="/home" />
              ) : (
                <BranchesIndex />
              )
            }
          />
           <Route
            path="/advancedetails"

            element={
              !isLoggedIn ? (
                <Navigate to="/login" />
              ) : actionAccess?.disablefinance ? (
                <Navigate to="/home" />
              ) : (
                <AdvanceDetails />
              )
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default Routing;
