import React, { useState, useRef } from "react";
import { Box, Text, Image, Circle, Center } from "@chakra-ui/react";
import moment from "moment";
import { PrimaryButton, Toaster, PrimaryInput } from "../../../components";
import DynamicModal from "../../../components/dynamic-modal";
import DatePicker from "../../../components/date-picker";
import ModalRight from "../../../components/modal-right";
import { updateCondition } from "../../../middleware/services/visits/patient-overview/updatehealth";

const formatDate = (dateString) => {
  if (!dateString) return "-";
  return moment(dateString).format("Do MMM, YYYY");
};

const PastMedicalHistory = ({
  condition,
  date,
  id,
  scaleHeight = "100px",
  componentBg = "#fcfcfd",
  cardBg = "white",
}) => {
  return (
    <Box
      display="flex"
      flexDir="column"
      alignItems="center"
      h={scaleHeight}
      w="100%"
      bg={componentBg}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        w="100%"
      >
        <Box display="flex" alignItems="center" w="100%">
          <Circle
            size="1rem"
            bg="white"
            border="2px solid #CDCFD4"
            borderRadius="full"
            color="black"
          />
          <Text
            fontSize="12px"
            fontWeight="500"
            textAlign="left"
            color="#384052"
          >
            {formatDate(date)}
          </Text>
        </Box>
      </Box>
      <Box display="flex" w="100%" mt="10px">
        <Center
          w="100%"
          h="6.125rem"
          ml="0.45rem"
          alignItems="start"
          borderLeft="2px solid #E6E7E9"
        >
          <Box
            p="20px"
            w="100%"
            h="50px"
            ml="10px"
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            border="1px solid #E6E7E9"
            borderRadius="12px"
            backgroundColor={cardBg}
          >
            <Box display="flex" alignItems="center">
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                maxW="350px"
              >
                <Text
                  title={condition}
                  fontSize="16px"
                  textAlign="left"
                  fontWeight="600"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {condition}
                </Text>
              </Box>
            </Box>
          </Box>
        </Center>
      </Box>
    </Box>
  );
};

export default PastMedicalHistory;
