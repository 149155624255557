import formAPIuser from "./doctoruser";


var formattedOutput = null;

const formAPIModeluser = (data) => {
  // Apply formAPIModel to each item in the data array
  formattedOutput = data.map(item => formAPIuser(item));


  return formattedOutput
};

export default formAPIModeluser