import moment from "moment";
export default function formAPIInventory({
    inventoryid,
    productcodeid,
    productname,
    producttype,
    batchnumber,
    chemicalcomposition,
    dosage,
    receiveddate,
    receivedby,
    storagecondition,
    manufacturername ,
    manufactureddate ,
    suppliername ,
    supplierdate ,
    expirydate ,
    costperunit ,
    costperstrip ,
    costperbox ,
    cgst ,
    sgst ,
    location ,
    category ,
    reorderlevel ,
    prescriptionrequirement ,
    otcrequirement ,
    regulatoryinformation ,
    usageinstructions ,
    stocklocation ,
    supplierleadtime ,
    stockin ,
    stockout ,
    numberofboxes ,
    numberofcontainers ,
    numberofstrips ,
    numberofsyrups ,
    quantityineachstrip ,
    createddate ,
    createdby ,
    modifieddate ,
    modifiedby ,
    isactive,
    remainingquantity,
    imagepath,
    inventoryimages,
    
   

})
{
  return Object.freeze({
    inventoryid,
    productcodeid,
    productname,
    producttype,
    batchnumber,
    chemicalcomposition,
    dosage,
    receiveddate,
    receivedby,
    storagecondition,
    manufacturername ,
    manufactureddate ,
    suppliername ,
    supplierdate ,
    expirydate ,
    costperunit ,
    costperstrip ,
    costperbox ,
    cgst ,
    sgst ,
    location ,
    category ,
    reorderlevel ,
    prescriptionrequirement ,
    otcrequirement ,
    regulatoryinformation ,
    usageinstructions ,
    stocklocation ,
    supplierleadtime ,
    stockin ,
    stockout ,
    numberofboxes ,
    numberofcontainers ,
    numberofstrips ,
    numberofsyrups ,
    quantityineachstrip ,
    createddate ,
    createdby ,
    modifieddate ,
    modifiedby ,
    isactive,
    remainingquantity,
    imagepath,
    inventoryimages
   
  });
}