import { useEffect, useRef, useState, useCallback } from "react";
import { FetchMyHealthAIData } from "../../middleware/services/visits/aicalls";
import { GetDoctorNotes } from "../../middleware/services/visits/patient-overview/updatedoctornotes";


export const useWebSocket = (
  url,
  onClose,
  patientId,
  doctorId,
  appointmentId,
  patientname,
  patientgender,
  patientage,
  setAiInputForFeedback
) => {
  const fetchPatientData = async () => {
    const result = await FetchMyHealthAIData(
      {},
      patientId,
      patientname,
      patientgender,
      patientage
    );
    return result;
  };

  const fetchDoctorNotes = async () => {
    const inputToFetch = {
      input: {
        patientid: patientId,
        doctorid: doctorId,
        appointmentid: appointmentId,
      },
    };

    try {
      const data = await GetDoctorNotes(inputToFetch.input);
      const result = data?.output?.data
      // console.log(inputToFetch)
      // console.log(result)
      return result      
    } catch (error) {
      console.error("Error fetching Doctor Notes data:", error);
    } finally {
    }
  };

  const [messages, setMessages] = useState([]);
  const [llmCalls, setLlmCalls] = useState(0);
  const websocketRef = useRef(null);

  // Function to initialize the WebSocket connection
  const initializeWebSocket = useCallback(async () => {
    // if (websocketRef.current) return; // If WebSocket is already initialized, do nothing

    setMessages([]);
    if (websocketRef.current) {
      websocketRef.current.close();
    } // If WebSocket is already initialized, close that first

    // Fetch patient data before opening the WebSocket connection
    const patientData = await fetchPatientData();
    const doctorNotes = await fetchDoctorNotes(); // send only for doctor web app when type is doctor

    websocketRef.current = new WebSocket(url);

    websocketRef.current.onopen = () => {
      console.log("Connection opened");
      websocketRef.current.send(
        JSON.stringify({ patient_data: { ...patientData, type: "doctor"}, doctor_notes: {...doctorNotes}})
      );
      setAiInputForFeedback({ patient_data: { ...patientData, type: "doctor"}, doctor_notes: {...doctorNotes}})
    };

    websocketRef.current.onmessage = (event) => {
      const message = JSON.parse(event.data);
      console.log(message);
      if (message.llm_calls !== undefined && message.history !== undefined) {
        setLlmCalls(message.llm_calls);
        setMessages(message.history);
        // console.log(message.llm_calls);
        // console.log(message.history);
      } else {
        // console.log(message);
        // setMessages((prevMessages) => [...prevMessages, message?.answer]);
        setMessages((prevMessages) => [
          ...prevMessages,
          { ...message, question: null }, // set question to null otherwise it will be redisplayed in ui
        ]);
      }
    };

    websocketRef.current.onclose = () => {
      console.log("WebSocket connection closed");
      websocketRef.current = null; // Reset the WebSocket reference on close
      if (onClose) onClose();
    };
  }, [url, onClose]);

  // Function to send a message through the WebSocket
  const sendMessage = (message) => {
    if (websocketRef.current) {
      setMessages((prevMessages) => [...prevMessages, message]); // show question message in ui
      websocketRef.current.send(JSON.stringify(message));
    }
  };

  // Function to close the WebSocket connection
  // const closeConnection = () => {
  //   if (websocketRef.current) {
  //     websocketRef.current.send(
  //       JSON.stringify({ type: "close", reason: "User initiated close" })
  //     );
  //     //   websocketRef.current.close();
  //     setMessages([]); // Clear messages when connection is closed
  //   }
  // };

  const closeConnection = () => {
    if (
      websocketRef.current &&
      websocketRef.current.readyState === WebSocket.OPEN
    ) {
      // console.log("requesting close");
      websocketRef.current.send(
        JSON.stringify({ type: "close", reason: "User initiated close" })
      );
    }

    if (websocketRef.current) {
      // websocketRef.current.close();
      // console.log("calling close");
      setMessages([]); // Clear messages when connection is closed
    }
  };

  // Clean up WebSocket connection when the component unmounts
  useEffect(() => {
    return () => {
      if (websocketRef.current) {
        websocketRef.current.send(
          JSON.stringify({ type: "close", reason: "User initiated close" })
        );

        // Wait for 3 seconds before closing the connection
        // setTimeout(() => {
        //   websocketRef.current.close();
        // }, 3000);
      }
    };
  }, []);

  return {
    messages,
    llmCalls,
    sendMessage,
    closeConnection,
    initializeWebSocket,
  };
};
