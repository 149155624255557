import {
  Box,
  Divider,
  Flex,
  Text,
  Tr,
  Td,
  Menu,
  MenuItem,
  MenuList,
  MenuButton,
  Image,
  Spacer,
  FormLabel,
  Input,
  Card,
  CardBody,
  Button,
  Th,
  useDisclosure,
  Collapse,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import MiniSideBar from "../../components/mini-sidebar";
import Loader from "../../components/loader/loader";
import SecondaryNav from "../../components/seconday-nav";
import useLoader from "../../components/loader/loaderstates";
import {
  DropdownInput,
  PrimaryButton,
  PrimaryInput,
  SearchBar,
  Toaster,
} from "../../components";
import SimpleTable from "../../components/simple-table";
import ModalRight from "../../components/modal-right";
import { getDoctorRole } from "../../middleware/services/roles";
import {
  addInventory,
  deleteInventory,
  GetAIMedicineComposition,
  getInventory,
  GetMedicineName,
  updateInventory,
} from "../../middleware/services/inventory";
import {
  actionAccessMain,
  customDateValidation,
  formatDate_Do_MMM_YYYY,
  handleAlphaNumericDotAndSpace,
  handleNumericDotAndSpace,
  imagePath,
  useScreenType,
  validationOfNumberWithOnly1Decimal,
} from "../../auth";
import DatePicker from "../../components/date-picker";
import { format, isValid, parse } from "date-fns";
import DynamicModal from "../../components/dynamic-modal";
import moment from "moment";
import MiniDropdownInput from "../../components/mini-dropdown";
import MonthYearPicker from "../../components/date-monthyear";
import { uploadImageInProfile } from "../../middleware/services/profile";
import { COLORS } from "../../components/styles/colors";
import { useLocation, useNavigate } from "react-router-dom";
import { getInventoryLogDetail } from "../../middleware/services/inventorylogs";
import MobileNavbar from "../../components/mobile-navbar";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";

const InventoryIndex = () => {
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const { showSuccessToast, showErrorToast } = Toaster();
  const addInventoryModal = useRef();
  const editInventoryModal = useRef();
  const logInventoryModal = useRef();
  const updateStockModal = useRef();
  const deleteInventoryModal = useRef();
  const TableHeader = [
    { key: "product-name", label: "Product Name" },
    { key: "product-type", label: "Product Type" },
    { key: "batch-no", label: "Batch no." },
    { key: "Manufacturer-Name", label: "Mfg Name" },
    { key: "Supplier-Name", label: "Supplier Name" },
    { key: "stock-location", label: "Stock location" },
    { key: "expiry-date", label: "Expiry Date" },
    { key: "quantity", label: "Quantity" },
    // { key: "created", label: "Created date" },
    { key: "Stock-In", label: "Stock-In" },
    // { key: "Stock-Out", label: "Stock-Out" },
    { key: "actions", label: "Actions" },
  ];
  const [storageConditions, setStorageConditions] = useState([
    { id: 1, name: "Room Temperature" },
    { id: 2, name: "Cool" },
    { id: 3, name: "Refrigerated" },
    { id: 4, name: "Freezer" },
    { id: 5, name: "Deep Freezer" },
    { id: 6, name: "Controlled Room Temperature" },
    { id: 7, name: "Protection from Light" },
    { id: 8, name: "Protection from Moisture" },
    { id: 8, name: "Controlled Humidity Storage" },
    { id: 8, name: "Flammable Storage" },
  ]);

  const locationState = useLocation();
  const openModal = locationState.state;

  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [search, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  // filteration states --------------------------
  const [batchnumberFilter, setBatchnumberFilter] = useState("");
  const [productnameFilter, setProductnameFilter] = useState("");
  const [producttypeFilter, setProducttypeFilter] = useState("");
  const [manufacturernameFilter, setManufacturernameFilter] = useState("");
  const [suppliernameFilter, setSuppliernameFilter] = useState("");

  const [productcodeid, setProductCodeId] = useState(null);
  const [batchnumber, setBatchnumber] = useState(null);
  const [productname, setProductname] = useState(null);
  const [producttype, setProducttype] = useState(null);
  const [manufacturername, setManufacturername] = useState(null);
  const [suppliername, setSuppliername] = useState(null);
  let itemPerPageCommonValue = 50;

  let [pageno, setPageno] = useState(1);
  let [pagesize, setPagesize] = useState(itemPerPageCommonValue);

  // states for item count display
  let [countTo, setCountTo] = useState(itemPerPageCommonValue);
  let [countFrom, setCountFrom] = useState(1);
  let [totalExceedsPageCount, setTotalExceedsPageCount] = useState(false);

  let [currentPage, setCurrentPage] = useState(1);
  let [totalPages, setTotalPages] = useState(1);

  const [showFilterCount, setShowFilterCount] = useState(false);

  // aditional states for add -
  const [dosage, setDosage] = useState(null);
  const [receivedby, setReceivedby] = useState(null);
  const [receiveddate, setReceiveddate] = useState(null);
  const [manufactureddate, setManufactureddate] = useState(null);
  const [supplierdate, setSupplierdate] = useState(null);
  const [expirydate, setExpirydate] = useState(null);
  const [costperunit, setCostperunit] = useState(null);
  const [costperstrip, setCostperstrip] = useState(null);
  const [costperbox, setCostperbox] = useState(null);
  const [cgst, setCgst] = useState(null);
  const [sgst, setSgst] = useState(null);
  const [location, setLocation] = useState(null);
  const [category, setCategory] = useState(null);
  const [reorderlevel, setReorderlevel] = useState(null);
  const [prescriptionrequirement, setPrescriptionrequirement] = useState(null);
  const [otcrequirement, setOtcrequirement] = useState(null);
  const [regulatoryinformation, setRegulatoryinformation] = useState(null);
  const [usageinstructions, setUsageinstructions] = useState(null);
  const [stocklocation, setStocklocation] = useState(null);
  const [supplierleadtime, setSupplierleadtime] = useState(null);
  const [stockin, setStockin] = useState(null);
  const [stockout, setStockout] = useState(null);
  const [numberofboxes, setNumberofboxes] = useState(null);
  const [numberofcontainers, setNumberofcontainers] = useState(null);
  const [numberofstrips, setNumberofstrips] = useState(null);
  const [numberofsyrups, setNumberofsyrups] = useState(null);
  const [quantityineachstrip, setQuantityineachstrip] = useState(null);
  const [storagecondition, setStorageCondition] = useState(null);
  const [totalItemCount, setTotalItemCount] = useState(0);
  //let chemical_compositionLocal = localStorage.getItem("chemicalcomposition")
  const [chemicalcomposition, setChemicalComposition] = useState("");

  const [initialRender, setInitialRender] = useState(true);
  const [filterOpen, setFilterOpen] = useState(false);
  const { isOpen, onToggle } = useDisclosure()

  const [actionAccess, setActionAccess] = useState(null);
  const [pageLoad, setPageLoad] = useState(true);
  const screenType = useScreenType()
  const navigate = useNavigate();
  const regexPattern = /^(0[1-9]|1[0-2])-(\d{4})$/;

  const clearState = () => {
    setBatchnumber(null);
    setProductname(null);
    setProducttype(null);
    setManufacturername(null);
    setSuppliername(null);
    setPageno(1);
    setCurrentPage(1);
    setShowFilterCount(false);

    setStorageCondition(null);
    setNumberofcontainers(null);
    setNumberofboxes(null);
    setStockout(null);
    setStockin(null);
    setStocklocation(null);
    setUsageinstructions(null);
    setOtcrequirement(null);
    setPrescriptionrequirement(null);
    setManufactureddate(null);
    setExpirydate(null);

    setReceiveddate(null);
    setReceivedby(null);
    setDosage(null);

    setProductCodeId(null);
    setChemicalComposition("");
    setSupplierdate(null);
    setCostperunit(null);
    setCostperstrip(null);
    setCostperbox(null);
    setCgst(null);
    setSgst(null);
    setLocation("");
    setCategory("");
    setReorderlevel("");
    setRegulatoryinformation("");
    setSupplierleadtime("");
    setNumberofstrips("");
    setNumberofsyrups("");
    setQuantityineachstrip("");

    setBatchnumberFilter("");
    setProductnameFilter("");
    setProducttypeFilter("");
    setManufacturernameFilter("");
    setSuppliernameFilter("");

    setDocumentURLs([]);
    setUploadedDocuments([]);
    setinventoryimages([])
  };

  // ---------------- Page Load - Functionality Start ---------------------
  useEffect(() => {
    
    setActionAccess(actionAccessMain(11))
    showLoader();
    //  clearState();
    var input = {
      batchnumber: null,
      productname: null,
      producttype: null,
      manufacturername: null,
      suppliername: null,
      pageno: 1,
      pagesize,
    };
    var getInventoryFunction = async () => {
      try {
        var result = await getInventory(input);

        if (result?.data) {
          setData(result.data);
          let count = result?.count;
          let pageno = result?.pageno;
          let pagesize = result?.pagesize;
          setTotalItemCount(count);
          let totalPagesCalc = count / pagesize;
          let totalPagesCount = Math.ceil(totalPagesCalc);
          //alert(totalPages)
          setTotalPages(totalPagesCount);
          // const filtered = data.filter(item =>
          //   item.productname?.toLowerCase().includes(searchValue.toLowerCase())
          // );
          setFilteredData(result.data);
          if (count < itemPerPageCommonValue) {
            setCountTo(count);
            setTotalExceedsPageCount(true);
          }else if (count > itemPerPageCommonValue && pageno == 1) {
            setCountTo(itemPerPageCommonValue);
            setTotalExceedsPageCount(false);
          }
          const openModal = localStorage.getItem("openModal");
          if (openModal) {

            addInventoryModal.current.openRight();
            let firstname = localStorage.getItem("firstname");
            let lastname = localStorage.getItem("lastname");
            let fullName = firstname + " " + lastname;
            setReceivedby(fullName);
            const formattedDate = moment().format("DD-MM-YYYY");
            //  alert(formattedDate)
            setReceiveddate(formattedDate);
            localStorage.removeItem("openModal");
          }
        } else if (result?.output?.message == "No inventory found") {
          setData([]);
          const openModal = localStorage.getItem("openModal");
          if (openModal) {

            addInventoryModal.current.openRight();
            let firstname = localStorage.getItem("firstname");
            let lastname = localStorage.getItem("lastname");
            let fullName = firstname + " " + lastname;
            setReceivedby(fullName);
            const formattedDate = moment().format("DD-MM-YYYY");
            //  alert(formattedDate)
            setReceiveddate(formattedDate);
            localStorage.removeItem("openModal");
            //here
          }
        } else {
          console.log("Something went wrong");
        }
        // else if (result && result.output && result.output.message == "No roles are available for this doctorid") {
        //   setData("")
        //   //  showErrorToast("Something went wrong!")
        // }
        // else {
        //   //setData("")
        //   //  showErrorToast("Something went wrong!")
        // }
      } catch (error) {
        console.log(error, "error in getInventoryFunction");
      }
      dismissLoader();
    };

    getInventoryFunction();
  }, [refresh]);

  const getFilteredResults = (pagenoAttribute) => {
    const tableContainer = document.querySelector(".inventoryTable");
    if (tableContainer) {
      tableContainer.scrollTop = 0;
      tableContainer.scrollLeft = 0;
      // console.log(tableContainer , 'tableContainer event ')
    }
    showLoader();
    var input = {
      batchnumber: batchnumberFilter,
      productname: productnameFilter,
      producttype: producttypeFilter,
      manufacturername: manufacturernameFilter,
      suppliername: suppliernameFilter,
      "pageno": pagenoAttribute ? pagenoAttribute : pageno,
      pagesize,
    };
    var getInventoryFunction = async () => {
      try {
        var result = await getInventory(input);

        if (result?.data) {
          setData(result.data);
          let count = result?.count;
          let pageno = result?.pageno;
          let pagesize = result?.pagesize;
          setTotalItemCount(count);
          let totalPagesCalc = count / pagesize;
          let totalPagesCount = Math.ceil(totalPagesCalc);
          // alert(totalPages)
          setTotalPages(totalPagesCount);
          // const filtered = data.filter(item =>
          //   item.productname?.toLowerCase().includes(searchValue.toLowerCase())
          // );
          if (count < itemPerPageCommonValue) {
            setCountTo(count);
            setTotalExceedsPageCount(true);
          } else if (count > itemPerPageCommonValue && pageno == 1) {
            setCountTo(itemPerPageCommonValue);
            setTotalExceedsPageCount(false);
          }
          setFilteredData(result.data);
        } else if (result?.output?.message == "No inventory found") {
          setData([]);
          setTotalItemCount(0);
          showErrorToast("No inventory found!");
        } else {
          console.log("Something went wrong");
        }
        // else if (result && result.output && result.output.message == "No roles are available for this doctorid") {
        //   setData("")
        //   //  showErrorToast("Something went wrong!")
        // }
        // else {
        //   //setData("")
        //   //  showErrorToast("Something went wrong!")
        // }
      } catch (error) {
        console.log(error, "error in getInventoryFunction");
      }
      dismissLoader();
    };

    getInventoryFunction();
  };

  const handleApplyFilters = () => {
    setShowFilterCount(true);
    // setPageno(1);
    setPagesize(itemPerPageCommonValue);
    setCountFrom(1);
    setCountTo(itemPerPageCommonValue);
    setCurrentPage(1)
    let pageno = 1;
    getFilteredResults(pageno);
    
    if (isOpen) {
      onToggle(); // This will close the filter section if it's currently open
    }
  };

  const handleResetFields = () => {
    clearState();
    //clear states for correct item count
    setPagesize(itemPerPageCommonValue);
    setCountFrom(1);
    setCountTo(itemPerPageCommonValue);
    setProducttypeFilter("")
    setRefresh(!refresh);
    
    if (isOpen) {
      onToggle(); // This will close the filter section if it's currently open
    }
  };
  const handleKeyDown = (e) => {
    if (e.key == "Enter") {
      handleApplyFilters();
    }
  };
  useEffect(() => {
    // if(pageno  && !initialRender){

    // getFilteredResults();
    // setInitialRender(false)
    // }
    if (pageno && !pageLoad) {

      getFilteredResults();
    }
    setPageLoad(false);
  }, [pageno]);

  const handleNextClick = () => {
    let page = pageno + 1;
    //  alert(page)
    setPageno(page);
    setCurrentPage(currentPage + 1);
    // getFilteredResults();
    // item count logic for next
    if (countTo + pagesize < totalItemCount) {
      setCountTo(countTo + pagesize);
    } else {
      setCountTo(totalItemCount);
    }

    setCountFrom(countFrom + pagesize);
  };
  const handlePrevClick = () => {
    let page = pageno - 1;
    //alert(page)
    setPageno(page);
    // setPageno(pageno - 1);
    setCurrentPage(currentPage - 1);
    // getFilteredResults();
    // item count logic for previous
    setCurrentPage(currentPage - 1);
    if (countTo == totalItemCount) {
      //setCountTo(Math.floor(totalItemCount / 10) * 10)
      let updatedCountUnroundedValue = totalItemCount - pagesize;
      setCountTo(Math.ceil(updatedCountUnroundedValue / itemPerPageCommonValue) * itemPerPageCommonValue);
    } else {
      setCountTo(countTo - pagesize);
    }

    setCountFrom(countFrom - pagesize);
  };

  // ---------------- Create Inventory Functionality Start ---------------------

  const [supplierdateMinMax, setSupplierdateMinMax] = useState("");
  const [receiveddateMinMax, setReceiveddateMinMax] = useState("");

  const handleReceivedDateChange = (date) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    setReceiveddate(formattedDate);

    //onst formattedDateForPopulate = format(date, "dd-MM-yyyy");
    setReceiveddateMinMax(date);
  };
  const handleMfgDateChange = (date) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    setManufactureddate(formattedDate);
  };
  const handleExpiryDateChange = (date) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    setExpirydate(formattedDate);
  };
  const handleSupplierDateChange = (date) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    setSupplierdate(formattedDate);

    //const formattedDateForPopulate = format(date, "dd-MM-yyyy");
    setSupplierdateMinMax(date);
  };
  const handleOTCRequired = (value) => {
    setOtcrequirement(value);
  };
  const handleStorageCondition = (value) => {
    setStorageCondition(value);
  };
  const handleReorderLevel = (value) => {
    setReorderlevel(value);
  };
  const handlePrescriptionRequired = (value) => {
    setPrescriptionrequirement(value);
  };

  const openAddModal = () => {
    clearState();

    localStorage.removeItem("defaultMonthDatePicker");

    let firstname = localStorage.getItem("firstname");
    let lastname = localStorage.getItem("lastname");
    let fullName = firstname + " " + lastname;
    setReceivedby(fullName);
    const formattedDate = moment().format("DD-MM-YYYY");
    //  alert(formattedDate)
    setReceiveddate(formattedDate);
    addInventoryModal.current.openRight();
  };

  const validationOfWholeNumber = (value) => {
    // This will remove any non-digit characters and ensure the value is a whole number
    let filteredValue = value.replace(/[^0-9]/, "");

    return filteredValue;
  };
  const maxLength_5 = 5;
  const maxLength_8 = 8;
  const handleProductCodeId = (e) => {
    setProductCodeId(handleAlphaNumericDotAndSpace(e.target.value));
  };
  const handleNoOfBoxes = (e) => {
    setNumberofboxes(
      validationOfNumberWithOnly1Decimal(e.target.value, maxLength_5)
    );
  };

  const handleNoOfContainers = (e) => {
    setNumberofcontainers(
      validationOfNumberWithOnly1Decimal(e.target.value, maxLength_5)
    );
  };
  const handleNoOfStrips = (e) => {
    setNumberofstrips(
      validationOfNumberWithOnly1Decimal(e.target.value, maxLength_5)
    );
  };
  const handleNoOfSyrups = (e) => {
    let inputValue = e.target.value;
    let filteredValue = inputValue.replace(/[^0-9]/, "");
    if (filteredValue.length <= maxLength_5) setNumberofsyrups(filteredValue);
  };
  const handleQuantityInEachStrip = (e) => {
    setQuantityineachstrip(
      validationOfNumberWithOnly1Decimal(e.target.value, maxLength_5)
    );
  };
  const handleStockIn = (e) => {
    setStockin(validationOfNumberWithOnly1Decimal(e.target.value, maxLength_5));
  };
  const handleStockOut = (e) => {
    setStockout(
      validationOfNumberWithOnly1Decimal(e.target.value, maxLength_5)
    );
  };

  /// cost validation
  const handleCostPerUnit = (e) => {
    setCostperunit(
      validationOfNumberWithOnly1Decimal(e.target.value, maxLength_8)
    );
  };
  const handleCostPerStrip = (e) => {
    setCostperstrip(
      validationOfNumberWithOnly1Decimal(e.target.value, maxLength_8)
    );
  };
  const handleCostPerBox = (e) => {
    setCostperbox(
      validationOfNumberWithOnly1Decimal(e.target.value, maxLength_8)
    );
  };

  const handleCgst = (e) => {
    setCgst(validationOfNumberWithOnly1Decimal(e.target.value, maxLength_8));
  };
  const handleSgst = (e) => {
    setSgst(validationOfNumberWithOnly1Decimal(e.target.value, maxLength_8));
  };

  const expirydateFunction = (e) => {
    const newValue = e.target.value;
    const validValue = customDateValidation(newValue);
    //alert(validValue)
    setExpirydate(validValue);
    //setExpirydate(customDateValidation(e.target.value))
  };
  const mfgDateFunction = (e) => {
    const newValue = e.target.value;
    const validValue = customDateValidation(newValue);
    //alert(validValue)
    setManufactureddate(validValue);
    //setExpirydate(customDateValidation(e.target.value))
  };

  const maxlength_50 = (value) => {
    if (value.length <= maxLength_5) return value;
  };

  const handleKeyDownAdd = (e) => {
    if (e.key == "Enter") {
      addInventoryFunction();
    }
  };

  const [dosageunit, setMedicineDosageUnit] = useState("mg");
  const productTypeChange = (value) => {
    setProducttype(value);
  };
  const productTypeFilterChange = (value) => {
    setProducttypeFilter(value);
  };
  const handleChangeDosageUnit = (selectedOption) => {
    setMedicineDosageUnit(selectedOption.label);
  };

  useEffect(() => {
    if (producttype == "Ointment") {
      //  alert("Ointment")
      setMedicineDosageUnit("grms");
    } else if (producttype == "Tablet" || producttype == "Capsule") {
      setMedicineDosageUnit("mg");
    } else if (producttype == "Syrup" || producttype == "Injection") {
      setMedicineDosageUnit("ml");
    }
  }, [producttype]);

  const [inventoryimages, setinventoryimages] = useState([]);
  const addInventoryFunction = async () => {
    let formatedReceivedDate = parseAndFormatDate(receiveddate);

    const inputForAddInventory = {
      productcodeid,
      productname,
      producttype,
      batchnumber,
      dosage: dosage + " " + dosageunit,
      receivedby,
      receiveddate: formatedReceivedDate,
      manufacturername,
      manufactureddate,
      expirydate,

      numberofboxes,
      numberofcontainers,
      otcrequirement,
      prescriptionrequirement,
      stockin,
      stockout,
      stocklocation,
      storagecondition,
      usageinstructions,
      suppliername,

      chemicalcomposition,
      supplierdate,
      expirydate,
      // costperunit,
      costperstrip,
      costperbox,
      cgst,
      sgst,
      location,
      category,

      reorderlevel,
      regulatoryinformation,
      supplierleadtime,
      numberofstrips,
      // numberofsyrups,
      quantityineachstrip,
      inventoryimages,
    };

    // const currentMonthIndex = moment().month();
    // const selectedMonth = manufactureddate.getMonth();
    // alert(currentMonthIndex + "  " + selectedMonth)
    const parsedDate = moment(manufactureddate, "MM-yyyy");
    const mfgMonth = parsedDate.month() + 1;
    const mfgYear = parsedDate.year();
    const currentMonth = moment().month() + 1;
    const currentYear = moment().year();

    const parsedDateExp = moment(expirydate, "MM-yyyy");
    const expMonth = parsedDateExp.month() + 1;
    const expYear = parsedDateExp.year();

    let [manufacturedMonth, manufacturedYear] = [null, null];
    if(manufactureddate){

       [manufacturedMonth, manufacturedYear] = manufactureddate?.split('-').map(Number);
    }

    // Parse the received date
    const receivedDate = moment(receiveddate);
    const receivedYear = receivedDate.year();
    const receivedMonth = receivedDate.month() + 1; // month() returns 0-indexed month


    if (!productname) {
      showErrorToast("Please Enter Product Name");
      return false;
    } else if (!expirydate) {
      showErrorToast("Please Select Expiry Date");
      return false;
    } else if (expirydate && expYear == currentYear && expMonth < currentMonth) {
      showErrorToast("Exp month cannot be less than current Month!");
      return false;
    } else if (!producttype) {
      showErrorToast("Please Enter Product Type");
      return false;
    } else if (!batchnumber) {
      showErrorToast("Please Enter Batch Number");
      return false;
    } else if (!dosage) {
      showErrorToast("Please Enter Dosage");
      return false;
    } else if (!receiveddate) {
      showErrorToast("Please Select Received Date");
      return false;
    } else if (!manufacturername) {
      showErrorToast("Please enter Mfg name");
      return false;
    } else if (!manufactureddate) {
      showErrorToast("Please Select Mfg Date");
      return false;
    } else if (
      manufactureddate &&
      mfgYear == currentYear &&
      mfgMonth > currentMonth
    ) {
      showErrorToast("Mfg Month cannot be greater than current Month!");
      return false;
    } else if (!receiveddate) {
      showErrorToast("Please Select Received Date");
      return false;
    }
    /// can update for expiry here

    // else if (!regexPattern.test(expirydate)) {
    //   showErrorToast("Enter a Valid Expiry Date, such as 12-2026!");
    //   return false;
    // }
    // else if (manufactureddate && !regexPattern.test(manufactureddate)) {
    //   showErrorToast("Enter a Valid Manufacture Date, such as 12-2024!");
    //   return false;
    // }
    // else if (!numberofboxes && numberofboxes !== 0) {
    //   showErrorToast("Please Enter Number of Boxes");
    //   return false;
    // } else if (!numberofcontainers && numberofcontainers !== 0) {
    //   showErrorToast("Please Enter Number of Containers");
    //   return false;
    // }
    else if (!numberofstrips && numberofstrips !== 0) {
      showErrorToast("Please Enter Number of Units");
      return false;
    } else if (!stockin) {
      showErrorToast("Please Enter Stock In");
      return false;
    } else if (stockin == 0) {
      showErrorToast("Stock-In cannot be 0!");
      return false;
    } else if (!quantityineachstrip && quantityineachstrip !== 0) {
      showErrorToast("Please Enter Quantity in each Unit");
      return false;
    } else if (numberofstrips == 0) {
      showErrorToast("Number of units cannot be 0!");
      return false;
    } else if (quantityineachstrip == 0) {
      showErrorToast("Quantity In Each Strip cannot be 0!");
      return false;
    }
    // else if (!costperunit && costperunit !== 0) {
    //   showErrorToast("Please Enter Cost Per each Unit");
    //   return false;
    // }
    else if (!costperstrip && costperstrip !== 0) {
      showErrorToast("Please Enter Cost Per Unit");
      return false;
    }
    else if (!otcrequirement) {
      showErrorToast("Please select OTC requirement!");
      return false;
    }
    else if (!prescriptionrequirement) {
      showErrorToast("Please select prescription requirement!");
      return false;
    }
    else  if  (
      manufacturedYear !== null &&
      (receivedYear < manufacturedYear || (receivedYear === manufacturedYear && receivedMonth < manufacturedMonth))
  ) {
      showErrorToast("Received date cannot be earlier than the manufactured date!");
      return false;
  } 
   
     
    
  //   else if (receivedYear < manufacturedYear || (receivedYear === manufacturedYear && receivedMonth < manufacturedMonth)) {
  //     showErrorToast("Received date cannot be earlier than the manufactured date!");
  // } 
    // else if (!costperbox && costperbox !== 0) {
    //   showErrorToast("Please Enter Cost Per Box");
    //   return false;
    // }

    //  else if (!stockout) {
    //   showErrorToast("Please Enter Stock Out");
    //   return false;
    // }
    try {
      // Create a new object with the validation logic applied
      const validatedInput = Object.fromEntries(
        Object.entries(inputForAddInventory).map(([key, value]) => [
          key,
          value === "" ? null : value,
        ])
      );
      // Now `validatedInput` will have `null` for any empty string values, and the original value otherwise.

      showLoader();
      const result = await addInventory(validatedInput);
      if (
        result?.output?.message ==
        "This product already exists in the inventory."
      ) {
        showErrorToast("This product already exists in the inventory!");
        dismissLoader();
        return false;
      }
      if (result.output.result == "Successfully Inserted") {
        setRefresh(!refresh);
        clearState();
        showSuccessToast("Inventory Added Successfully!");
        addInventoryModal.current.closeRight();
      } else {
        showErrorToast("Something went wrong!");
      }
    } catch (error) {
      console.log(error, "error addInventoryFunction");
    }

    dismissLoader();
  };

  // ---------------- Edit Inventor Start ------------------------

  const [editId, setEditId] = useState("");

  const editDetailsSetState = (item) => {
    setEditId(item.inventoryid);

    console.log(item, "item edit");
    setProductname(item.productname);
    setProducttype(item.producttype);
    setBatchnumber(item.batchnumber);
    if (item.dosage) {
      const dosageValue = item.dosage.replace(/\D/g, "");
      setDosage(dosageValue);
    }
    setReceivedby(item.receivedby);
    if (item.receiveddate) {
      const formattedDate = format(item.receiveddate, "dd-MM-yyyy");
      setReceiveddate(formattedDate);
    }
    setManufacturername(item.manufacturername);
    // if(item.manufactureddate){
    //   const formattedDate = format(item.manufactureddate, "dd-MM-yyyy");
    //   setManufactureddate(formattedDate);
    //   }
    // if(item.expirydate){
    //   const formattedDate = format(item.expirydate, "dd-MM-yyyy");
    //   setExpirydate(formattedDate);
    //   }
    setExpirydate(item.expirydate);
    setManufactureddate(item.manufactureddate);
    setNumberofboxes(item.numberofboxes);
    setNumberofcontainers(item.numberofcontainers);
    setOtcrequirement(item.otcrequirement);
    setPrescriptionrequirement(item.prescriptionrequirement);
    setStockin(item.stockin);
    setStockout(item.stockout);
    setStocklocation(item.stocklocation);
    setStorageCondition(item.storagecondition);
    setUsageinstructions(item.usageinstructions);
    setSuppliername(item.suppliername);

    setProductCodeId(item.productcodeid);
    setCategory(item.category);
    setSupplierleadtime(item.supplierleadtime);
    if (item.supplierdate) {
      const formattedDate = format(item.supplierdate, "dd-MM-yyyy");
      setSupplierdate(formattedDate);
    }
    setSuppliername(item.suppliername);
    setReorderlevel(item.reorderlevel);
    setChemicalComposition(item.chemicalcomposition);
    setNumberofstrips(item.numberofstrips);
    setQuantityineachstrip(item.quantityineachstrip);
    setNumberofsyrups(item.numberofsyrups);
    setRegulatoryinformation(item.regulatoryinformation);
    setCostperunit(item.costperunit);
    setCostperstrip(item.costperstrip);
    setCostperbox(item.costperbox);
    setCgst(item.cgst);
    setSgst(item.sgst);
    setLocation(item.location);
  };

  const [documentURLsEdit, setDocumentURLsEdit] = useState([]);
  const openEditModal = (item) => {
    // ********* to uncomment below code , once DB is deployed *******************************
    setDocumentURLsEdit([])
    const imagesFromDB = item?.inventoryimages?.map((image) => ({
      imageurl: image.imageurl,
      imagepath: item.imagepath,
    }));
    if (imagesFromDB) {
      setDocumentURLsEdit(imagesFromDB);
    }
    console.log(item?.inventoryimages, " item?.inventoryimages");

    // dummy test code --------------------
    // const imagePath = "https://midocfileserver.blob.core.windows.net/midocdev/"
    // const dummyImagesTest = [
    //   {
    //     "imageurl": "185a5ce8-9b77-4551-9bf8-2e357552866c.jpeg"
    //   },
    //   {
    //     "imageurl": "8d5789ab-faf3-475b-b59d-16066788c6f3.webp"
    //   }
    // ]
    // const imagesFromDB = dummyImagesTest.map((image)=>({
    //   "imageurl":image.imageurl,
    //   "imagepath": imagePath,
    // }))
    // setDocumentURLsEdit(imagesFromDB);
    // dummy test code --------------------

    editDetailsSetState(item);
    setinventoryimages([]);
    setDocumentURLs([]);
    editInventoryModal.current.openRight();
  };

  const [historyData, setHistoryData] = useState([]);
  const openLogModal = (item) => {
    showLoader();
    var input = {
      inventoryid: item.inventoryid,
    };
    var getInventoryFunction = async () => {
      try {
        var result = await getInventoryLogDetail(input);

        if (result) {
          setHistoryData(result);
          console.log(result, "getting details data");
        } else if (result?.output?.message == "No inventory found") {
          setData([]);
          setTotalItemCount(0);
          showErrorToast("No inventory found!");
        } else {
          console.log("Something went wrong");
        }
        // else if (result && result.output && result.output.message == "No roles are available for this doctorid") {
        //   setData("")
        //   //  showErrorToast("Something went wrong!")
        // }
        // else {
        //   //setData("")
        //   //  showErrorToast("Something went wrong!")
        // }
      } catch (error) {
        console.log(error, "error in getInventoryFunction");
      }
      dismissLoader();
    };

    getInventoryFunction();

    logInventoryModal.current.openRight();
  };

  const handleKeyDownEdit = (e) => {
    if (e.key == "Enter") {
      editInventoryFunction();
    }
  };
  const parseAndFormatDate = (date) => {
    if (!date) return null;

    const parsedDate_ddMMyyyy = parse(date, "dd-MM-yyyy", new Date());
    if (isValid(parsedDate_ddMMyyyy)) {
      return format(parsedDate_ddMMyyyy, "yyyy-MM-dd");
    }

    const parsedDate_yyyyMMdd = parse(date, "yyyy-MM-dd", new Date());
    if (isValid(parsedDate_yyyyMMdd)) {
      return date; // Already in the correct format
    }

    return null; // Invalid date
  };

  const editInventoryFunction = async (typeOfUpdate) => {
    showLoader();
    let formatedReceivedDate = parseAndFormatDate(receiveddate);
    let formatedSupplierDate = parseAndFormatDate(supplierdate);
    // let formatedExpiryDate = parseAndFormatDate(expirydate);
    // let formatedMfgDate = parseAndFormatDate(manufactureddate);

    const inputForUpdaInventoryItem = {
      inventoryid: editId,
      productcodeid,
      productname,
      producttype,
      batchnumber,
      dosage: dosage + " " + dosageunit,
      receivedby,
      receiveddate: formatedReceivedDate,
      supplierdate: formatedSupplierDate,

      manufacturername,
      //"manufactureddate": formatedMfgDate,
      //"expirydate": formatedExpiryDate,
      manufactureddate,
      expirydate,

      numberofboxes,
      numberofcontainers,
      otcrequirement,
      prescriptionrequirement,
      stockin,
      stockout,
      stocklocation,
      storagecondition,
      usageinstructions,
      suppliername,

      chemicalcomposition,

      expirydate,
      // costperunit,
      costperstrip,
      costperbox,
      cgst,
      sgst,
      location,
      category,

      reorderlevel,
      regulatoryinformation,
      supplierleadtime,
      numberofstrips,
      //numberofsyrups,
      quantityineachstrip,
      inventoryimages,
    };
    let errormessage = "";

    try {
      showLoader();
      // Create a new object with the validation logic applied
      const validatedInput = Object.fromEntries(
        Object.entries(inputForUpdaInventoryItem).map(([key, value]) => [
          key,
          value === "" ? null : value,
        ])
      );
      // Now `validatedInput` will have `null` for any empty string values, and the original value otherwise.


      const parsedDate = moment(manufactureddate, "MM-yyyy");
      const mfgMonth = parsedDate.month() + 1;
      const mfgYear = parsedDate.year();
      const currentMonth = moment().month() + 1;
      const currentYear = moment().year();

      const parsedDateExp = moment(expirydate, "MM-yyyy");
      const expMonth = parsedDateExp.month() + 1;
      const expYear = parsedDateExp.year();

      // Parse the manufactured date
const [manufacturedMonth, manufacturedYear] = manufactureddate.split('-').map(Number);

// Parse the received date
const receivedDate = moment(receiveddate);
const receivedYear = receivedDate.year();
const receivedMonth = receivedDate.month() + 1; // month() returns 0-indexed month



      if (typeOfUpdate == "edit") {
        showLoader();
        if (!productname) {
          errormessage = "Please Enter Product Name"
        } else if (!expirydate) {
          errormessage = "Please Select Expiry Date"
        } else if (expirydate && expYear == currentYear && expMonth < currentMonth) {
          errormessage = "Exp month cannot be less than current Month!"
        } else if (!producttype) {
          errormessage = "Please Enter Product Type"
        } else if (!batchnumber) {
          errormessage = "Please Enter Batch Number";
        } else if (!dosage) {
          errormessage = "Please Enter Dosage";
        } else if (!receiveddate) {
          errormessage = "Please Select Received Date";
        } else if (!manufacturername) {
          errormessage = "Please enter Mfg name";
        } else if (!manufactureddate) {
          errormessage = "Please Select Mfg Date";
        } else if (manufactureddate && mfgYear == currentYear && mfgMonth > currentMonth) {
          errormessage = "Mfg Month cannot be greater than current Month!";
        } else if (!numberofstrips && numberofstrips !== 0) {
          errormessage = "Please Enter Number of Units";
        } else if (!quantityineachstrip && quantityineachstrip !== 0) {
          errormessage = "Please Enter Quantity in each Unit";
        } else if (quantityineachstrip == 0) {
          errormessage = "Quantity In Each Unit cannot be 0!";
        } else if (!stockin) {
          errormessage = "Please Enter Stock In";
        } else if (!costperstrip && costperstrip !== 0) {
          errormessage = "Please Enter Cost Per Unit";
        }
        else if (!otcrequirement) {
          errormessage = "Please select OTC requirement!";
        }
        else if (!prescriptionrequirement) {
          errormessage = "Please select prescription requirement!";
        }
        else if (receivedYear < manufacturedYear || (receivedYear === manufacturedYear && receivedMonth < manufacturedMonth)) {
          errormessage = "Received date cannot be earlier than the manufactured date!";
      }  else {
          showLoader();
          const result = await updateInventory(validatedInput);
          // if (result?.output?.message == "The inventory name already exists") {
          //   showErrorToast("The inventory name already exists")
          //   dismissLoader();
          //   return false;
          // }
          if (result?.output?.result == "Successfully Updated") {
            setRefresh(!refresh);
            showSuccessToast("Inventory Item Updated Successfully!");
            editInventoryModal.current.closeRight();
          } else {
            showErrorToast("Something went wrong!");
          }
        }

        if (errormessage) {
          showErrorToast(errormessage);
          dismissLoader(); // Only call dismissLoader once when there's an error
          return false;
        }
      }
      if (typeOfUpdate == "updateStock") {
        showLoader();
         if (!batchnumber) {
          errormessage = "Please Enter Batch Number";
        } else if (!expirydate) {
          errormessage = "Please Select Expiry Date";
        } else if (!stockin) {
          errormessage = "Please Enter Stock In";
        } else if (!numberofstrips && numberofstrips !== 0) {
          errormessage = "Please Enter Number of Units";
        } else if (!quantityineachstrip && quantityineachstrip !== 0) {
          errormessage = "Please Enter Quantity in each Unit";
        } else if (quantityineachstrip == 0) {
          errormessage = "Quantity In Each Strip cannot be 0!";
        } else if (!costperstrip && costperstrip !== 0) {
          errormessage = "Please Enter Cost Per Unit";
        }  else {
          showLoader();
          const result = await updateInventory(validatedInput);
          // if (result?.output?.message == "The inventory name already exists") {
          //   showErrorToast("The inventory name already exists")
          //   dismissLoader();
          //   return false;
          // }
          if (result?.output?.result == "Successfully Updated") {
            setRefresh(!refresh);
            showSuccessToast("Stock Updated Successfully!");
            updateStockModal.current.closeRight();
          } else {
            showErrorToast("Something went wrong!");
          }
        }
        if (errormessage) {
          showErrorToast(errormessage);
          dismissLoader(); // Only call dismissLoader once when there's an error
          return false;
        }
      }
    } catch (error) {
      console.log(error, "error updateDoctorRoleFunction");
    }
    setTimeout(() => {
      dismissLoader();
    }, 100);
  };

  //                             Update only stock code      - -----------           -------

  const openUpdateStockModal = (item) => {
    editDetailsSetState(item);

    updateStockModal.current.openRight();
  };

  //------------Delete functionlity start --------------

  const [deleteId, setDeleteId] = useState("");
  const [deleteProductName, setDeleteteProductName] = useState("");

  const openDeleteModal = (item) => {
    setDeleteId(item.inventoryid);
    setDeleteteProductName(item.productname);
    deleteInventoryModal.current.openModal();
  };

  const deleteInventoryFunction = async () => {
    let inputForDeleteInventoryItem = {
      id: deleteId,
    };
    try {
      showLoader();
      const result = await deleteInventory(inputForDeleteInventoryItem);
      if (result.output) {
        setRefresh(!refresh);
        deleteInventoryModal.current.closeModal();
        showErrorToast("Inventory Item Deleted Successfully");
      } else {
        showErrorToast("Something went wrong!");
      }
    } catch (error) {
      console.log(error, "error deleteDoctorRoleFunction");
    }
    dismissLoader();
  };

  function expirydateFunction2(e) {
    let input = e.target.value;
    input = input.replace(/[^0-9]/g, "");
    if (input.length == 1) {
      if (input >= 2 || input <= 9) {
        input = "0" + input;
      }
    }
    if (input.length > 2) {
      input = input.slice(0, 2) + " / " + input.slice(2, 4);
    }
    e.target.value = input;
  }

  const [expYear, setexpYear] = useState(2060);
  const handleMonthYearChange = (date) => {
    // alert(date + "  alert from index")
    setexpYear(date.getFullYear());
    setExpirydate(moment(date).format("MM-yyyy"));
  };
  const [mfgYear, setmfgYear] = useState(null);
  const handleMonthYearChangeMfg = (date) => {
    setmfgYear(date.getFullYear());
    setManufactureddate(moment(date).format("MM-yyyy"));
  };
  const gobackfunction = () => {
    window.history.back();
  };

  // upload document functionality --------------
  const fileInputRef = useRef(null);
  const fileInputRefInEdit = useRef(null);

  const [documentURLs, setDocumentURLs] = useState([]);

  const [uploadedDocuments, setUploadedDocuments] = useState([]);

  const handleInventoryUpload = async (e) => {
    e.preventDefault();
    if (e.target.files.length > 3) {
      showErrorToast("Maximum 3 Images can be uploaded!");
      return false;
    }
    // let condition_cd = e.target.getAttribute('data-surgery-cd');
    // if(condition_cd === "null"){
    //   condition_cd = null
    //  }
    showLoader();
    for (var i = 0; i < e.target.files.length; i++) {
      const file2 = e.target.files[i];
      const allowedTypes = ["image/png", "image/jpeg", "image/jpg", "image/webp"];
      if (!allowedTypes.includes(file2.type)) {
        showErrorToast("Invalid file type. Please select a PNG, JPG or JPEG file.");
        dismissLoader();
        return false;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file2);
      const result = await uploadImageInProfile(file2, file2.name);
      const file = result;
      const filename = result.name;
      //  const filesize = result.size;
      //  const filetype = result.type;

      setUploadedDocuments((prevDocuments) => [
        ...prevDocuments,
        {
          filename: filename,
          fileObj: file2,
          //"filetype":filetype ,
        },
      ]);
      setinventoryimages((prevDocuments) => [
        ...prevDocuments,
        { imageurl: filename },
      ]);
    }
    showSuccessToast("Image Uploaded Successfully!");
    e.target.value = "";
    dismissLoader();
  };

  const InventoryUploadDocument = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (uploadedDocuments.length >= 0) {
      setDocumentURLs(uploadedDocuments);
    }
  }, [uploadedDocuments]);

  const handleDelete = (index) => {
    const updatedUrls = [...uploadedDocuments];
    updatedUrls.splice(index, 1);
    console.log(updatedUrls, " updatedUrls");
    setUploadedDocuments(updatedUrls);

    const updatedDocuments = [...uploadedDocuments];
    const deletedDocument = updatedDocuments.splice(index, 1)[0];
    setinventoryimages((prevImages) =>
      prevImages.filter((image) => image.imageurl !== deletedDocument.filename)
    );
  };

  // Fetch Composition from AI ----------  functiionality start-----

  const fetchComposition = async (medicineName) => {
    const inputData = {
      input: {
        name: medicineName,
      },
    };
    if (medicineName) {
      try {
        showLoader();
        const result = await GetAIMedicineComposition(inputData.input);
        // console.log(result);
        if (result) {
          showSuccessToast("Medicine composition fetched successfully");
          console.log(result, " result of AI fetch by name");
          setChemicalComposition(result.chemical_composition);
          //alert(result.expiry_date)
         // setExpirydate(result.expiry_date);
          //     setBenifit(result.benefit);
          // setSideEffects(result.side_effects);
        }
        dismissLoader();
      } catch (error) {
        console.error("Error Fetching Medicine composition:", error);
        showErrorToast("An error occurred while Fetching Medicine composition");
      }
    } else {
      showErrorToast("Please provide medicine name or upload medicine photo");
    }
  };

  const fetchCompositionByImage = async () => {
    if (documentURLs) {
      showLoader();
      console.log(documentURLs, " documentURLs");
      console.log(documentURLs[0].fileObj, " documentURLs[0].fileObj");

      for (var i = 0; i < documentURLs.length; i++) {
        const result = await GetMedicineName(documentURLs[i].fileObj);
        if (result?.output?.message == "No credits are available") {
          showErrorToast("No sufficient tokens available");
          dismissLoader();
          return false;
        } else if (result) {
          const invalidValues = ["not available", "unspecified", "unknown", "n/a", "unavailable"]; // All in lowercase

          if (result.medicine_name && !invalidValues.includes(result.medicine_name.toLowerCase())) {
            setProductname(result.medicine_name);
          }

          if (result.chemical_composition && !invalidValues.includes(result.chemical_composition.toLowerCase())) {
            setChemicalComposition(result.chemical_composition);
          }

          if (result.expiry_date && !invalidValues.includes(result.expiry_date.toLowerCase())) {
            if (result.expiry_date !== "00-0000") {
              setExpirydate(result.expiry_date);
            }
          }


          // setBenifit(result.benefit);
          // setSideEffects(result.side_effects);
        } else if (!result) {
          setProductname("");
          setChemicalComposition("");
          setExpirydate("");
        }
      }
      dismissLoader();
    }
  };

  useEffect(()=>{
    if(!productname){
      setChemicalComposition("")
    }
  },[productname])

  return (
    <>
    {screenType == "web" ? (
      <Box w="100%">
      <Flex>
        <Box w="6rem">
          <MiniSideBar />
        </Box>
        {isLoading && <Loader />}
        <Box w="calc(100vw - 6.25rem)" display="flex" flexDir="column">
          <SecondaryNav withBack="Inventory" goBack={gobackfunction} />
          <Box
            gap="10px"
            my="1rem"
            px="1rem"
            w="100%"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <Box w="80%" display="flex" gap="10px">
              <PrimaryInput
                variant="fullRound"
                inputPlace="Product name"
                id="productname"
                onChange={(e) => setProductnameFilter(e.target.value)}
                inputValue={productnameFilter}
                onKeyDown={handleKeyDown}
                maxLength={50}
              />
              <DropdownInput
                variant="fullSide"
                dropDownPlace="Product type"
                options={[
                  "Tablet",
                  "Capsule",
                  "Syrup",
                  "Injection",
                  "Ointment",
                ]}
                readOnly={true}
                id="Producttype"
                onClick={productTypeFilterChange}
                value={producttypeFilter}
                menuList={{ pos: "absolute", right: "-45px", minW: "115px" }}
              />
              <PrimaryInput
                variant="fullRound"
                inputPlace="Batch number"
                id="batchnumber"
                onChange={(e) => setBatchnumberFilter(e.target.value)}
                inputValue={batchnumberFilter}
                onKeyDown={handleKeyDown}
                maxLength={35}
              />

              <PrimaryInput
                variant="fullRound"
                inputPlace="Manufacturer name"
                id="manufacturername"
                onChange={(e) => setManufacturernameFilter(e.target.value)}
                inputValue={manufacturernameFilter}
                onKeyDown={handleKeyDown}
                maxLength={50}
              />
              <PrimaryInput
                variant="fullRound"
                inputPlace="Supplier name"
                id="suppliername"
                onChange={(e) => setSuppliernameFilter(e.target.value)}
                inputValue={suppliernameFilter}
                onKeyDown={handleKeyDown}
                maxLength={50}
              />
            </Box>

            <Box w="20%" display="flex" gap="10px">
              <PrimaryButton
                buttonText="Filter"
                id="filterButton"
                variant="mdBtn"
                onClick={handleApplyFilters}
              />
              <PrimaryButton
                buttonText="Reset"
                id="resetButton"
                variant="grayBtn"
                btnStyle={{ color: "#AA2F27" }}
                onClick={handleResetFields}
              />
            </Box>
          </Box>

          {/* <Divider />
          {showFilterCount && (
            <Box
              w="100%"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p="1rem"
            >
              <Text fontSize="0.875rem" fontWeight="600">
                {totalItemCount > 1
                  ? totalItemCount + " Items found"
                  : totalItemCount == 1
                  ? totalItemCount + " Item found"
                  : "No Items found!"}
              </Text>
              <Text
                fontSize="0.875rem"
                color="red"
                fontWeight="600"
                onClick={handleResetFields}
                cursor="pointer"
              >
                Clear Filters
              </Text>
            </Box>
          )} */}
          <Divider />
          <Flex w="100%" pr="1rem">
            <Spacer />
            <Box display='flex' gap='20px'>
            <PrimaryButton
              onClick={()=>navigate('/inventorylogs')}
              variant="mdBtn"
              btnStyle={{ w:'150px', my: "1rem" }}
              buttonText="Inventory Logs"
              btnIcon='/assets/svgs/new-tab.svg'
              iconSrc={true}
              iconSrcStyle={{w:'14px'}}
              isDisabled={ actionAccess && (!actionAccess.read) ? true : false} 
            />
            <PrimaryButton
              buttonText="Add"
              onClick={openAddModal}
              variant="mdBtn"
              btnStyle={{ w: "120px", my: "1rem" }}
              isDisabled={actionAccess && !actionAccess.create ? true : false}
            />
            </Box>
          </Flex>
          <Box>
            <Box border="1px solid #E6E7E9">
              <SimpleTable
                headers={TableHeader}
                className="inventoryTable"
                isDataPresent={true}
                footerStyle={{
                  pos: "fixed",
                  bottom: "0",
                  boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
                  width: "100%",
                  zIndex: "99",
                }}
                tableStyle={{
                  minH: "calc(100vh - 300px)",
                  maxH: "calc(100vh - 300px)",
                  overflowY: "auto",
                  overflowX: {md:"auto",xl:"hidden"},
                  tableLayout: "fixed",
                  width: "100%",
                }}
                isThCss={{ pl: "1rem", pr: "0.5rem" }}
                tableBody={
                  <>
                    {data && data.length > 0 ? (
                      data.map((item, index) => (
                        <Tr w="100%" key={index}>
                          <Td w="200px" pl='1rem' pr='0.5rem'>
                            <Box
                              w="100%"
                              display="flex"
                              gap="0.5rem"
                              alignItems="center"
                            >
                              <Box w="80%" maxW="8rem" isTruncated>
                                <Text
                                  className="textOverflow"
                                  fontSize=".875rem"
                                  fontWeight="600"
                                >
                                  {item.productname ? item.productname : "-"}
                                </Text>
                              </Box>
                            </Box>
                          </Td>

                          <Td w="170px" pl='1rem' pr='0.5rem'>
                            <Box
                              w="100%"
                              display="flex"
                              gap="0.5rem"
                              alignItems="center"
                            >
                              <Box w="80%" maxW="8rem" isTruncated>
                                <Text
                                  className="textOverflow"
                                  fontSize=".875rem"
                                  fontWeight="600"
                                >
                                  {item.producttype ? item.producttype : "-"}
                                </Text>
                              </Box>
                            </Box>
                          </Td>

                          <Td pl='1rem' pr='0.5rem' maxW="150px">
                            <Text fontSize=".875rem" fontWeight="500" className="textOverflow" title={item?.batchnumber}>
                              {item.batchnumber ? item.batchnumber : "-"}
                            </Text>
                          </Td>
                          <Td pl='1rem' pr='0.5rem'>
                            <Text fontSize=".875rem" fontWeight="500">
                              {item.manufacturername
                                ? item.manufacturername
                                : "-"}
                            </Text>
                          </Td>

                          <Td pl='1rem' pr='0.5rem'>
                            <Text fontSize=".875rem" fontWeight="500">
                              {item.suppliername ? item.suppliername : "-"}
                            </Text>
                          </Td>
                          <Td pl='1rem' pr='0.5rem'>
                            <Text fontSize=".875rem" fontWeight="500">
                              {item.stocklocation ? item.stocklocation : "-"}
                            </Text>
                          </Td>

                          <Td pl='1rem' pr='0.5rem'>
                            <Text fontSize=".875rem" fontWeight="500">
                              {item.expirydate ? item.expirydate : "-"}
                            </Text>
                          </Td>

                          <Td pl='1rem' pr='0.5rem'>
                            <Box p=".25rem .5rem" borderRadius="full">
                              <Text fontSize=".75rem" fontWeight="600">
                                {item.remainingquantity
                                  ? item.remainingquantity
                                  : "-"}
                              </Text>
                            </Box>
                          </Td>

                          {/* <Td>
                            <Text fontSize=".875rem" fontWeight="500">
                              {item.createddate
                                ? formatDate_Do_MMM_YYYY(item.createddate)
                                : "-"}
                            </Text>
                          </Td> */}

                          <Td pl='1rem' pr='0.5rem'>
                            <Text fontSize=".875rem" fontWeight="500">
                              {item.stockin ? item.stockin : "-"}
                            </Text>
                          </Td>

                          {/* <Td pl='1rem' pr='0.5rem'>
                            <Text fontSize=".875rem" fontWeight="500">
                              {item.stockout ? item.stockout : "-"}
                            </Text>
                          </Td> */}
                          <Td pl='1rem' pr='0.5rem' pos='relative'>
                            <Menu isLazy>
                              <MenuButton as="button" zIndex={1}>
                                <Image
                                  w="1.5rem"
                                  src="/assets/svgs/more-options.svg"
                                />
                              </MenuButton>
                              <MenuList
                                zIndex={9}
                                pos="relative"
                                right="-33px"
                                minW="125px"
                              >
                                <MenuItem
                                  onClick={() => openEditModal(item)}
                                  isDisabled={
                                    actionAccess && !actionAccess.update
                                      ? true
                                      : false
                                  }
                                >
                                  View/Edit
                                </MenuItem>

                                <MenuItem
                                  onClick={() => openUpdateStockModal(item)}
                                  isDisabled={
                                    actionAccess && !actionAccess.update
                                      ? true
                                      : false
                                  }
                                >
                                  Update Stock
                                </MenuItem>

                                <MenuItem
                                  onClick={() => openLogModal(item)}
                                  isDisabled={
                                    actionAccess && !actionAccess.read
                                      ? true
                                      : false
                                  }
                                >
                                  View History
                                </MenuItem>

                                <MenuItem
                                  onClick={() => openDeleteModal(item)}
                                  isDisabled={
                                    actionAccess && !actionAccess.delete
                                      ? true
                                      : false
                                  }
                                >
                                  Delete
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          </Td>
                        </Tr>
                      ))
                    ) : (
                      // <Tr>

                      // <Td
                      //   display="flex"
                      //   justifyContent="center"
                      //   flexDir="column"
                      //   gap="10px"
                      //   alignItems="center"
                      //   h="100%"
                      //   colSpan={7}
                      //   textAlign="center"
                      // >
                      //   <Image src="/assets/svgs/inventory.svg" opacity="0.5"/>
                      //   <Text>No Data Available</Text>
                      // </Td>

                      // </Tr>
                      <Tr>
                        <Td colSpan={10} h="290px" textAlign="center">
                          No Data Available!
                        </Td>
                      </Tr>
                    )}
                  </>
                }
                footerSection={
                  <>
                    {data && data.length > 0 && (
                      <Tr w="100%">
                        <Td colSpan={11} w="calc(100vw - 6.5rem)">
                          <Box
                            w="100%"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Box
                              visibility={
                                totalItemCount < countTo &&
                                  !totalExceedsPageCount
                                  ? "hidden"
                                  : "visible"
                              }
                            >
                              <Text>
                                Showing <b>{countFrom}</b> to <b>{countTo}</b>{" "}
                                of {totalItemCount} results
                              </Text>
                            </Box>

                            <Box
                              gap="2.5rem"
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Box
                                as="button"
                                onClick={handlePrevClick}
                                //  disabled={currentPage == 1}
                                visibility={
                                  currentPage == 1 ? "hidden" : "visible"
                                }
                                p="0.5rem 0.75rem"
                                border="0.0625rem solid #E6E7E9"
                                borderRadius="0.75rem"
                                fontSize=".875rem"
                                fontWeight="500"
                              >
                                Previous
                              </Box>
                              <Box>
                                <Text
                                  textAlign="center"
                                  fontSize=".875rem"
                                  fontWeight="600"
                                >
                                  Page {currentPage} of {totalPages}
                                </Text>
                              </Box>
                              {totalItemCount >= countTo &&
                                !totalExceedsPageCount && (
                                  <Box
                                    as="button"
                                    p="0.5rem 0.75rem"
                                    mr="2.5rem"
                                    border="0.0625rem solid #E6E7E9"
                                    borderRadius="0.75rem"
                                    onClick={handleNextClick}
                                    // disabled={currentPage == totalPages}
                                    visibility={
                                      currentPage == totalPages
                                        ? "hidden"
                                        : "visible"
                                    }
                                    fontSize=".875rem"
                                    fontWeight="500"
                                  >
                                    Next
                                  </Box>
                                )}
                            </Box>
                          </Box>
                        </Td>
                      </Tr>
                    )}
                  </>
                }
              />
            </Box>
          </Box>
        </Box>
      </Flex>
    </Box>
    ) : (
      <>
       <MobileNavbar />
          <MobileSecondaryNav
            header="Inventory"
            handlebackarrow={() => navigate(`/profile`)}
          />
        <Box
          w="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p="1rem"
          gap='0.75rem'
        >
          <SearchBar
            passInputPlace="Product name"
            handleClick={handleApplyFilters}
          />
          <Box onClick={onToggle}>
            <Image
              w='50px'
              cursor='pointer'
              src={isOpen ? "assets/svgs/outline-f.svg" : "assets/svgs/filled-f.svg" }
            />
          </Box>
          <PrimaryButton
            buttonText="Add"
            onClick={openAddModal}
            variant="mdBtn"
            isDisabled={actionAccess && !actionAccess.create ? true : false}
          />
        </Box>
        {/* {filterOpen && (
          <> */}
          <Collapse in={isOpen} animateOpacity style={{overflow:'visible'}}>
            <Box w="100%" display="flex" p='1rem' pt='0' flexDir='column' gap="10px">
              <DropdownInput
                variant="fullSide"
                dropDownPlace="Product type"
                options={[
                  "Tablet",
                  "Capsule",
                  "Syrup",
                  "Injection",
                  "Ointment",
                ]}
                readOnly={true}
                id="Producttype"
                onClick={productTypeFilterChange}
                value={producttypeFilter}
                menuList={{ pos: "absolute", right: "-45px", minW: "115px" }}
              />
              <PrimaryInput
                variant="fullRound"
                inputPlace="Batch number"
                id="batchnumber"
                onChange={(e) => setBatchnumberFilter(e.target.value)}
                inputValue={batchnumberFilter}
                onKeyDown={handleKeyDown}
                maxLength={35}
              />

              <PrimaryInput
                variant="fullRound"
                inputPlace="Manufacturer name"
                id="manufacturername"
                onChange={(e) => setManufacturernameFilter(e.target.value)}
                inputValue={manufacturernameFilter}
                onKeyDown={handleKeyDown}
                maxLength={50}
              />
              <PrimaryInput
                variant="fullRound"
                inputPlace="Supplier name"
                id="suppliername"
                onChange={(e) => setSuppliernameFilter(e.target.value)}
                inputValue={suppliernameFilter}
                onKeyDown={handleKeyDown}
                maxLength={50}
              />
            </Box>
            <Box w="100%" p='1rem' pt='0' display="flex" gap="10px">
              <PrimaryButton
                buttonText="Filter"
                btnStyle={{w:"100%"}}
                id="filterButton"
                variant="mdBtn"
                onClick={handleApplyFilters}
              />
              <PrimaryButton
                buttonText="Reset"
                id="resetButton"
                variant="grayBtn"
                btnStyle={{ color: "#AA2F27", w:"100%" }}
                onClick={handleResetFields}
              />
            </Box>
          </Collapse>
          {/* </>
        )} */}
        <Divider />
        <Flex w='100%' gap='1rem' p='1rem' flexDir='column' maxH="calc(100vh - 275px)" overflow='hidden auto'>
        {data && data.length > 0 ? (
          data.map((item, index) => (
          <Card
            borderRadius="12px"
            key={index}
            w="100%"
            border="1px solid #E6E7E9"
            boxShadow="0px 8px 20px 0px #E6E7E966"
          >
            <CardBody p={{base: "0.875rem",lg: "0.875rem", xl: "1.25rem"}}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb="0.75rem"
              >                  
                <Box cursor="pointer" display="flex" alignItems="center">
                  <Box display="flex" flexDirection="column" justifyContent="center">
                    <Text fontSize="15px" fontWeight="600" className="textOverflow" title={item.productname ? item.productname : "-"} maxW={{base: "180px", xl:"110px"}}>
                    {item.productname ? item.productname : "-"}
                    </Text>
                  </Box>
                </Box>

                  <Box display="flex" gap="10px">
                    <Menu isLazy>
                      <MenuButton as="button" zIndex={1}>
                        <Image
                          w="1rem"
                          src="/assets/svgs/more-options.svg"
                          transform='rotate(90deg)'
                        />
                      </MenuButton>
                      <MenuList
                        zIndex={9}
                        pos="relative"
                        right="0"
                        minW="125px"
                      >
                        <MenuItem
                          onClick={() => openEditModal(item)}
                          isDisabled={
                            actionAccess && !actionAccess.update
                              ? true
                              : false
                          }
                        >
                          View/Edit
                        </MenuItem>

                        <MenuItem
                          onClick={() => openUpdateStockModal(item)}
                          isDisabled={
                            actionAccess && !actionAccess.update
                              ? true
                              : false
                          }
                        >
                          Update Stock
                        </MenuItem>

                        <MenuItem
                          onClick={() => openLogModal(item)}
                          isDisabled={
                            actionAccess && !actionAccess.read
                              ? true
                              : false
                          }
                        >
                          View History
                        </MenuItem>

                        <MenuItem
                          onClick={() => openDeleteModal(item)}
                          isDisabled={
                            actionAccess && !actionAccess.delete
                              ? true
                              : false
                          }
                        >
                          Delete
                        </MenuItem>
                      </MenuList>
                    </Menu>    
                  </Box>
                  
              </Box>
              <Flex w='100%' mb="0.75rem" justifyContent='space-between'>
                <Box display='flex' flexDir='column'>
                
                    <Text fontSize="10px" color="gray.500">
                      Product type
                    </Text>
                    <Text fontSize="12px" fontWeight="600">
                      {item.producttype ? item.producttype : "-"}
                    </Text>
                    
                  </Box>
                  <Box display='flex' flexDir='column' alignItems="center">
                
                <Text fontSize="10px" color="gray.500">
                  Batch no.
                </Text>
                <Box
                    as="button"
                  >
                    <Text fontSize="12px" fontWeight="600">
                    {item.batchnumber ? item.batchnumber : "-"}
                </Text>
                  </Box>
                
              
              </Box>
              </Flex>
              
              <Flex w='100%'>
                <Box w='100%' display='flex' flexDir='column'>
                
                    <Text fontSize="10px" color="gray.500">
                      Expiry date
                    </Text>
                    <Text fontSize="12px" fontWeight="600">
                      {item.expirydate ? item.expirydate : "-"}
                    </Text>
                    
                  </Box>
                  <Box w='100%' display='flex' flexDir='column' alignItems="center">
                
                <Text fontSize="10px" color="gray.500">
                  Stock-In
                </Text>
                <Box
                    as="button"
                  >
                    <Text fontSize="12px" fontWeight="600">
                    {item.stockin ? item.stockin : "-"}
                </Text>
                  </Box>
                
              
              </Box>
                  

                <Box w='100%' display='flex' flexDir='column' alignItems='flex-end'>
                    <Text fontSize="10px" color="gray.500">
                      Quantity
                    </Text>
                    <Text fontSize="12px" fontWeight="600">
                      {item.remainingquantity ? item.remainingquantity : "-"}
                    </Text>
                  </Box>
              </Flex>
            
            </CardBody>

          </Card>
        ))) : (
          <Text>No data available</Text>
        )}
        </Flex>
      </>
    )}
      

      <ModalRight
        ref={addInventoryModal}
        header="Add Inventory"
        modalWidth={{ base: "100%", lg: "calc(100vw - 10%)" }}
        body={
          <>
            <Box w="100%" mt="1rem">
              <Box display="flex" my="10px">
                <Text fontSize="0.875rem" fontWeight="600">
                  Product Details:
                </Text>
              </Box>

              <Box w="100%" mt="1rem">
                <Box w="100%" display="flex" gap="2rem" flexDir={{base:"column", md:"row"}}>
                  {/* {documentURLs?.length < 3 && */}
                  <>
                    <Box
                      as="button"
                      flexDir="column"
                      justifyContent="center"
                      alignItems="center"
                      w={{base:"100%",md:"calc(100% - 60%)"}}
                      maxW={{base:"100%", md:"375px"}}
                      p="20px"
                      gap="8px"
                      border="1px dashed #CDCFD4"
                      borderRadius="12px"
                      onClick={() => InventoryUploadDocument()}
                    >
                      <Image
                        w="20px"
                        m="auto"
                        mb="10px"
                        h="auto"
                        src="/assets/imgs/add-blue.png"
                      />
                      <Text fontSize="14px" color="#163E9E" fontWeight="600">
                        Upload Product Images
                      </Text>
                      <Text fontWeight="400" fontSize="12px" color="#6A707D">
                        *Upload up to 3 images to automatically capture the
                        Product Name, Expiry Date, and Chemical Composition!
                      </Text>
                    </Box>

                    <Box w="100%" display="flex" alignItems='flex-start' flexDir={{base:"column", md:"row"}}>
                      <Box display='flex' gap='16px' w={{base:'100%', md:"unset"}} justifyContent='space-between'>
                      {documentURLs?.length > 0 &&
                        documentURLs.map((document, index) => (
                          <Flex key={index}>
                            <Box
                              cursor="pointer"
                              p="10px"
                              borderRadius="10px"
                              boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                            >
                              <Box
                                as="span"
                                display="flex"
                                justifyContent="end"
                                pos="relative"
                                cursor="pointer"
                                title="Remove Image!"
                                onClick={() => handleDelete(index)}
                              >
                                <Image
                                  src="/assets/imgs/close.png"
                                  border="1px solid"
                                  padding="2px"
                                  borderRadius="100%"
                                  zIndex="22"
                                  pos="absolute"
                                  top="-16px"
                                  right="-16px"
                                />
                              </Box>
                              <Box
                                as="a"
                                href={imagePath + document.filename}
                                target="/"
                                title="Preview Image!"
                              >
                                <Image
                                  w={{base:"85px", md:"110px"}}
                                  h="auto"
                                  src={imagePath + document.filename}
                                />
                              </Box>
                            </Box>
                          </Flex>
                        ))}
                      </Box>
                      <Spacer mb={{base:"20px", md:"0"}} />
                      <PrimaryButton
                        buttonText="Upload"
                        verticalAlign="bottom"
                        variant="grayBtn"
                        btnStyle={{
                          w: {base:"100%", md:"15%"},
                          color: COLORS.PRIMARY_COLOR,
                          verticalAlign: "bottom",
                          ml: "4px",
                          mb: "0px",
                          h: "2.8rem",
                          m: "0",
                        }}
                        onClick={() => fetchCompositionByImage()}
                        isDisabled={documentURLs?.length >= 1 ? false : true}
                      />
                      <input
                        type="file"
                        id="file-upload"
                        onChange={(e) => handleInventoryUpload(e)}
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        multiple
                      />
                    </Box>
                  </>
                  {/* /} */}
                </Box>
              </Box>

              <Box w="100%" mt="1rem">
                <Box w="100%" display="flex" gap="1.5rem" flexDir={{base:"column", md:"row"}}>
                  <Box w={{base:"100%",md:"50%"}} display="flex">
                    <PrimaryInput
                      variant="fullLeft"
                      inputPlace="Product name*"
                      onChange={(e) => setProductname(e.target.value)}
                      inputValue={productname}
                      maxLength={100}
                    />

                    <PrimaryButton
                      buttonText="Fetch"
                      verticalAlign="bottom"
                      variant="grayBtn"
                      btnStyle={{
                        w: "15%",
                        color: COLORS.PRIMARY_COLOR,
                        verticalAlign: "bottom",
                        ml: "4px",
                        mb: "0px",
                        h: "2.8rem",
                        m: "0",
                        borderRadius: "0 10px 10px 0",
                      }}
                      onClick={() => fetchComposition(productname)}
                      isDisabled={productname ? false : true}
                    />
                  </Box>
                  <Box w={{base:"100%",md:"50%"}} display="flex" flexDir={{base:"column", md:'row'}}>
                    <PrimaryInput
                      variant={screenType == "web" ? "fullLeft" : "defaultSide"}
                      inputPlace="Chemical Composition"
                      onChange={(e) => setChemicalComposition(e.target.value)}
                      inputValue={chemicalcomposition}
                      maxLength={100}
                    />
                    <MonthYearPicker
                      placeholderProp="Expiry Date*"
                      value={expirydate}
                      onDateChange={handleMonthYearChange}
                      datePickerInputStyle={{ borderRadius: {base:"0",md:"0 10px 10px 0"} }}
                      minYear={mfgYear ? mfgYear : moment().year()}
                    />
                  </Box>
                </Box>

                <Box w="100%" mt={{base:'0', md:"1rem"}} display="flex" flexDir={{base:"column", md:"row"}}>
                  <DropdownInput
                    variant={screenType == 'web' ? "leftSide" : "bothSide"}
                    dropDownPlace="Product type*"
                    options={[
                      "Tablet",
                      "Capsule",
                      "Syrup",
                      "Injection",
                      "Ointment",
                    ]}
                    readOnly={true}
                    id="Producttype"
                    onClick={productTypeChange}
                    // defaultOption={defaultGender}
                    menuList={{ pos: "absolute", right: "-45px", minW: "115px" }}
                  />
                  <PrimaryInput
                    variant="bothSide"
                    inputPlace="Product Code Id"
                    onChange={handleProductCodeId}
                    inputValue={productcodeid}
                    maxLength={15}
                  // inputType="number"
                  />
                  <PrimaryInput
                    variant="bothSide"
                    inputPlace="Category"
                    onChange={(e) => setCategory(e.target.value)}
                    inputValue={category}
                    maxLength={100}
                  />
                  <PrimaryInput
                    variant="bothSide"
                    inputPlace="Batch no.*"
                    onChange={(e) => setBatchnumber(e.target.value)}
                    inputValue={batchnumber}
                    maxLength={35}
                  />

                  <MiniDropdownInput
                    variant={screenType == 'web' ? "rightSide" : "bothSide"}
                    options={[
                      { label: "mg", value: "mg" },
                      { label: "ml", value: "ml" },
                      { label: "grms", value: "grms" },
                    ]}
                    placeholderProp="Dosage*"
                    onSelect={handleChangeDosageUnit}
                    defaultOptionProp={dosageunit}
                    //  defaultValue={step.medicine_quantity ? step.medicine_quantity : ""}
                    handleInputChange={(e) =>
                      setDosage(handleNumericDotAndSpace(e.target.value))
                    }
                    inputVal={dosage}
                    maxLength="6"
                  />
                </Box>

                <Box w={{base:'100%', md:"40%"}} display="flex" flexDir={{base:"column", md: "row"}}>
                  <PrimaryInput
                    variant={screenType == 'web' ? "leftDown" : 'bothSide'}
                    inputPlace="Manufacturer name*"
                    onChange={(e) => setManufacturername(e.target.value)}
                    inputValue={manufacturername}
                    maxLength={100}
                  />

                  <MonthYearPicker
                    placeholderProp="Manufactured Date*"
                    value={manufactureddate}
                    onDateChange={handleMonthYearChangeMfg}
                    datePickerInputStyle={{ borderRadius:{base:"0 0 10px 10px", md: "0 0 10px 0"} }}
                    //  maxYear={2027}
                    minYear={1901}
                    maxYear={moment().year()}
                  />
                </Box>

                {/* 
          <Box display="flex" mt="20px" mb="10px">
              <Text fontSize="0.875rem" fontWeight="500">
            Additional Product Details:
          </Text>
          </Box> */}

                <Box display="flex" mt="20px" mb="10px">
                  <Text fontSize="0.875rem" fontWeight="600">
                    Delivery Details:
                  </Text>
                </Box>
                <Box w="100%" display="flex" flexDir={{base:"column", md:"row"}}>
                  <PrimaryInput
                    variant={screenType == "web" ? "fullLeft" : "defaultSide"}
                    inputPlace="Received by"
                    onChange={(e) => setReceivedby(e.target.value)}
                    inputValue={receivedby}
                    maxLength={100}
                  />

                  <DatePicker
                    variant="rightSide"
                    placeholderProp="Received Date*"
                    leftPos={{ left: "5px" }}
                    datePickerInputStyle={{ borderRadius: "0!important" }}
                    onDateChange={(e) => handleReceivedDateChange(e)}
                    defaultDate={receiveddate}

                    CUSTOM_DISABLE={true}
                   maxDate={new Date().toISOString()} 
                   minDate={supplierdateMinMax}
                  />
                  {/* <PrimaryInput
                  variant="bothSide"
                  inputPlace="Supplier Lead Time"
                  onChange={(e) => setSupplierleadtime(e.target.value)}
                  inputValue={supplierleadtime}
                  maxLength={100}
                />

                <DatePicker
                  disableFutureDates={true}
                  placeholderProp="Supplier Date"
                  leftPos={{ left: "5px" }}
                  datePickerInputStyle={{ borderRadius: "0!important" }}
                  onDateChange={(e) => handleSupplierDateChange(e)}
                  //defaultDate={dob}
                  maxDate={receiveddateMinMax}
                /> */}

                  <PrimaryInput
                    variant="bothSide"
                    inputPlace="Supplier name"
                    onChange={(e) => setSuppliername(e.target.value)}
                    inputValue={suppliername}
                    onKeyDown={handleKeyDownAdd}
                    maxLength={100}
                  />

                  <DropdownInput
                    variant={screenType == 'web' ? "fullRight" : 'downSide'}
                    dropDownPlace="Reorder level"
                    options={["High", "Medium", "Low"]}
                    readOnly={true}
                    id="reorderLevel"
                    onClick={handleReorderLevel}
                    // defaultOption={defaultGender}
                    menuList={{ pos: "absolute", right: "-45px", minW: "115px" }}
                  />
                </Box>

                <Box display="flex" mt="20px" mb="10px">
                  <Text fontSize="0.875rem" fontWeight="600">
                    Quantity Specifications:
                  </Text>
                </Box>

                <Box w="100%" display="flex" flexDir={{base:'column', md:'row'}}>
                  <PrimaryInput
                    variant={screenType == 'web' ? "fullLeft" : "defaultSide"}
                    inputPlace="Number of Units*"
                    onChange={handleNoOfStrips}
                    inputValue={numberofstrips}
                    maxLength={15}
                  //  inputType="number"
                  />
                  <PrimaryInput
                    variant="bothSide"
                    inputPlace="Quantity in each Unit*"
                    onChange={handleQuantityInEachStrip}
                    inputValue={quantityineachstrip}
                    maxLength={15}
                 //   inputType="number"
                  />
                  <PrimaryInput
                    variant={screenType == "web" ? "fullRight" : "downSide"}
                    inputPlace="Stock in*"
                    onChange={handleStockIn}
                    inputValue={stockin}
                    maxLength={100}
                  />
                  {/* <PrimaryInput
                  variant="fullRight"
                  inputPlace="Stock out"
                  onChange={handleStockOut}
                  inputValue={stockout}
                  maxLength={100}
                /> */}
                </Box>

                <Box display="flex" mt="20px" mb="10px">
                  <Text fontSize="0.875rem" fontWeight="600">
                    Cost Details:
                  </Text>
                </Box>

                <Box w="100%" display="flex" flexDir={{base:"column", md:"row"}}>
                  <PrimaryInput
                    variant={screenType == "web" ? "fullLeft" : "defaultSide"}
                    inputPlace="Cost Per Unit*"
                    onChange={handleCostPerStrip}
                    inputValue={costperstrip}
                    maxLength={15}
                   // inputType="number"
                  />
                  {/* <PrimaryInput
                  variant="bothSide"
                  inputPlace="Cost Per Box*"
                  onChange={handleCostPerBox}
                  inputValue={costperbox}
                  maxLength={15}
                  inputType="number"
                /> */}
                  <PrimaryInput
                    variant="bothSide"
                    inputPlace="CGST(%)"
                    onChange={handleCgst}
                    inputValue={cgst}
                    maxLength={15}
                  //  inputType="number"
                  />
                  <PrimaryInput
                    variant={screenType == "web" ? "fullRight" : "downSide"}
                    inputPlace="SGST(%)"
                    onChange={handleSgst}
                    inputValue={sgst}
                    maxLength={15}
                   // inputType="number"
                  />
                </Box>

                <Box display="flex" mt="20px" mb="10px">
                  <Text fontSize="0.875rem" fontWeight="600">
                    Additional Details:
                  </Text>
                </Box>

                <Box w="100%" display="flex" flexDir={{base:"column", md:"row"}}>
                  <DropdownInput
                    variant={screenType == "web" ? "leftSide" : "defaultSide"}
                    dropDownPlace="OTC Requirement*"
                    options={["Yes", "No"]}
                    readOnly={true}
                    id="gender"
                    onClick={handleOTCRequired}
                    // defaultOption={defaultGender}
                    menuList={{ pos: "absolute", right: "-45px", minW: "115px" }}
                  />
                  <DropdownInput
                    variant="bothSide"
                    dropDownPlace="Prescription Requirement*"
                    options={["Yes", "No"]}
                    readOnly={true}
                    id="gender"
                    onClick={handlePrescriptionRequired}
                    // defaultOption={defaultGender}
                    menuList={{ pos: "absolute", right: "-45px", minW: "115px" }}
                  />
                  <DropdownInput
                    variant="bothSide"
                    dropDownPlace="Storage condition"
                    options={storageConditions.map((item) => item.name)}
                    readOnly={true}
                    id="Storagecondition"
                    onClick={handleStorageCondition}
                    // defaultOption={defaultGender}
                    menuList={{ pos: "relative", right: "78%", minW: "115px", maxW: "200px" }}
                  />
                  <PrimaryInput
                    variant="bothSide"
                    inputPlace="Usage instructions"
                    onChange={(e) => setUsageinstructions(e.target.value)}
                    inputValue={usageinstructions}
                    maxLength={200}
                  />
                  <PrimaryInput
                    variant={screenType == "web" ? "rightSide" : "bothSide"}
                    inputPlace="Location"
                    onChange={(e) => setLocation(e.target.value)}
                    inputValue={location}
                    maxLength={100}
                  />
                </Box>

                <Box w={{base:"100%", md:"40%"}} display="flex" flexDir={{base:'column', md:'row'}}>
                  <PrimaryInput
                    variant={screenType == "web" ? "leftDown" : "bothSide"}
                    inputPlace="Stock location"
                    onChange={(e) => setStocklocation(e.target.value)}
                    inputValue={stocklocation}
                    maxLength={100}
                  />
                  <PrimaryInput
                    variant={screenType == "web" ? "rightDown" : "downSide"}
                    inputPlace="Regulatory Information"
                    onChange={(e) => setRegulatoryinformation(e.target.value)}
                    inputValue={regulatoryinformation}
                    maxLength={100}
                  />
                </Box>
              </Box>
            </Box>

            <Divider my="1rem" />
          </>
        }
        footerActions={
          <>
            <Box w="100%" display="flex" gap="1rem">
              <PrimaryButton
                buttonText="Cancel"
                variant="grayBtnFull"
                btnStyle={{ color: "#AA2F27" }}
                onClick={() => addInventoryModal.current.closeRight()}
              />

              <PrimaryButton buttonText="Save" onClick={addInventoryFunction} />
            </Box>
          </>
        }
      />
      {/* edit inventory */}
      <ModalRight
        ref={editInventoryModal}
        header="Edit Inventory"
        modalWidth={{ base: "100%", lg: "calc(100vw - 10%)" }}
        body={
          <>
            <Box w="100%" mt="1rem">
              <Box display="flex" mt="20px" mb="10px">
                <Text fontSize="0.875rem" fontWeight="500">
                  Product Details:
                </Text>
              </Box>

              <Box w="100%" mt="1rem">
                <Box w="100%" display="flex" gap="2rem" flexDir={{base:"column", md:"row"}}>
                  {/* {documentURLs?.length < 3 && */}
                  <>
                    <Box
                      as="button"
                      flexDir="column"
                      justifyContent="center"
                      alignItems="center"
                      w={{base:"100%",md:"calc(100% - 60%)"}}
                      maxW={{base:"100%", md:"375px"}}
                      p="20px"
                      gap="8px"
                      border="1px dashed #CDCFD4"
                      borderRadius="12px"
                      onClick={() => InventoryUploadDocument()}
                    >
                      <Image
                        w="20px"
                        m="auto"
                        mb="10px"
                        h="auto"
                        src="/assets/imgs/add-blue.png"
                      />
                      <Text fontSize="14px" color="#163E9E" fontWeight="600">
                        Upload Product Images
                      </Text>
                      <Text fontWeight="400" fontSize="12px" color="#6A707D">
                        *Upload up to 3 images to automatically capture the
                        Product Name, Expiry Date, and Chemical Composition!
                      </Text>
                    </Box>


                  </>
                  {/* /} */}


                  <Box w="100%" display="flex" flexDir={{base:"column", md:"row"}}>
                  <Box display='flex' gap='16px' w={{base:'100%', md:"unset"}} justifyContent='space-between'>
                    {documentURLsEdit?.length > 0 &&
                      documentURLsEdit.map((document, index) => (
                        <Flex key={index}>
                          <Box
                            mb="2"
                            mt="2"
                            mx="5px"
                            cursor="pointer"
                            title="Preview Image!"
                            as="a"
                            href={document.imagepath + document.imageurl}
                            target="/"
                          >

                            <Image
                              boxSize="70px"
                              src={document.imagepath + document.imageurl}
                            />
                          </Box>
                        </Flex>
                      ))}
                  </Box>  
                    {documentURLs?.length > 0 &&
                      documentURLs.map((document, index) => (
                        <Box
                          key={index}
                          cursor="pointer"
                          p="10px"
                          mr='16px'
                          borderRadius="10px"
                          boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                        >
                          <Box
                            as="span"
                            display="flex"
                            justifyContent="end"
                            pos="relative"
                            cursor="pointer"
                            title="Remove Image!"
                            onClick={() => handleDelete(index)}
                          >
                            <Image
                              src="/assets/imgs/close.png"
                              border="1px solid"
                              padding="2px"
                              borderRadius="100%"
                              zIndex="22"
                              pos="absolute"
                              top="-16px"
                              right="-16px"
                            />
                          </Box>
                          <Box
                            as="a"
                            href={imagePath + document.filename}
                            target="/"
                            title="Preview Image!"
                          >
                            <Image
                              w={{base:"85px", md:"110px"}}
                              h="auto"
                              src={imagePath + document.filename}
                            />
                          </Box>
                        </Box>
                      ))}

                    <Spacer mb={{base:"20px", md:"0"}} />
                    <PrimaryButton
                      buttonText="Upload"
                      verticalAlign="bottom"
                      variant="grayBtn"
                      btnStyle={{
                        w: {base:"100%", md:"15%"},
                        color: COLORS.PRIMARY_COLOR,
                        verticalAlign: "bottom",
                        ml: "4px",
                        mb: "0px",
                        h: "2.8rem",
                        m: "0",
                      }}
                      onClick={() => fetchCompositionByImage()}
                      isDisabled={
                        documentURLs?.length >= 1
                          ? false
                          : true
                      }
                    />
                    <input
                      type="file"
                      id="file-upload"
                      onChange={(e) => handleInventoryUpload(e)}
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      multiple
                    />
                  </Box>
                </Box>
              </Box>
              {/* start */}
              <Box w="100%" mt="1rem">
              <Box w="100%" display="flex" gap="1.5rem" flexDir={{base:"column", md:"row"}}>
                <Box w={{base:"100%",md:"50%"}} display="flex">

                    <PrimaryInput
                      variant="fullLeft"
                      inputPlace="Product name*"
                      onChange={(e) => setProductname(e.target.value)}
                      inputValue={productname}
                      defaultValue={productname}
                      maxLength={100}
                    />

                    <PrimaryButton
                      buttonText="Fetch"
                      verticalAlign="bottom"
                      variant="grayBtn"
                      btnStyle={{
                        w: "15%",
                        color: COLORS.PRIMARY_COLOR,
                        verticalAlign: "bottom",
                        ml: "4px",
                        mb: "0px",
                        h: "2.8rem",
                        m: "0",
                        borderRadius: "0 10px 10px 0",
                      }}
                      onClick={() => fetchComposition(productname)}
                      isDisabled={productname ? false : true}
                    />
                  </Box>

                  <Box w={{base:"100%",md:"50%"}} display="flex" flexDir={{base:"column", md:'row'}}>
                    <PrimaryInput
                      variant={screenType == "web" ? "fullLeft" : "defaultSide"}
                      inputPlace="Chemical Composition"
                      onChange={(e) => setChemicalComposition(e.target.value)}
                      inputValue={chemicalcomposition}
                      defaultValue={chemicalcomposition}
                      maxLength={100}
                    />

                    <MonthYearPicker
                      placeholderProp="Expiry Date*"
                      value={expirydate}
                      onDateChange={handleMonthYearChange}
                      datePickerInputStyle={{ borderRadius: {base:"0",md:"0 10px 10px 0"} }}
                      minYear={mfgYear ? mfgYear : moment().year()}
                      defaultDate={expirydate}
                    />
                  </Box>
                </Box>






                <Box w="100%" mt={{base:'0', md:"1rem"}} display="flex" flexDir={{base:"column", md:"row"}}>
                  <DropdownInput
                    variant={screenType == 'web' ? "leftSide" : "bothSide"}
                    dropDownPlace="Product type*"
                    options={[
                      "Tablet",
                      "Capsule",
                      "Syrup",
                      "Injection",
                      "Ointment",
                    ]}
                    readOnly={true}
                    id="Producttype"
                    onClick={productTypeChange}
                    defaultOption={producttype}
                    menuList={{ pos: "absolute", right: "-45px", minW: "115px" }}
                  />

                  <PrimaryInput
                    variant="bothSide"
                    inputPlace="Product Code Id"
                    onChange={handleProductCodeId}
                    inputValue={productcodeid}
                    defaultValue={productcodeid}
                    maxLength={15}

                  //inputType="number"
                  />
                  <PrimaryInput
                    variant="bothSide"
                    inputPlace="Category"
                    onChange={(e) => setCategory(e.target.value)}
                    inputValue={category}
                    defaultValue={category}
                    maxLength={100}
                  />

                  <PrimaryInput
                    variant="bothSide"
                    inputPlace="Batch no.*"
                    onChange={(e) => setBatchnumber(e.target.value)}
                    inputValue={batchnumber}
                    defaultValue={batchnumber}
                    maxLength={35}
                  />

                  <MiniDropdownInput
                    variant={screenType == 'web' ? "rightSide" : "bothSide"}
                    options={[
                      { label: "mg", value: "mg" },
                      { label: "ml", value: "ml" },
                      { label: "grms", value: "grms" },
                    ]}
                    placeholderProp="Dosage*"
                    onSelect={handleChangeDosageUnit}
                    defaultOptionProp={dosageunit}
                    defaultValue={dosage}
                    handleInputChange={(e) =>
                      setDosage(handleNumericDotAndSpace(e.target.value))
                    }
                    inputVal={dosage}
                    maxLength="6"
                  />
                </Box>

                <Box w={{base:'100%', md:"40%"}} display="flex" flexDir={{base:"column", md: "row"}}>
                  <PrimaryInput
                    variant={screenType == 'web' ? "leftDown" : 'bothSide'}
                    inputPlace="Manufacturer name*"
                    onChange={(e) => setManufacturername(e.target.value)}
                    inputValue={manufacturername}
                    defaultValue={manufacturername}
                    maxLength={100}
                  />

                  <MonthYearPicker
                    placeholderProp="Manufactured Date*"
                    value={manufactureddate}
                    onDateChange={handleMonthYearChangeMfg}
                    datePickerInputStyle={{ borderRadius:{base:"0 0 10px 10px", md: "0 0 10px 0"} }}
                    //  maxYear={2027}
                    minYear={1901}
                    maxYear={moment().year()}
                    defaultDate={manufactureddate}
                  />
                </Box>

                <Box display="flex" mt="20px" mb="10px">
                  <Text fontSize="0.875rem" fontWeight="500">
                    Delivery Details:
                  </Text>
                </Box>

                <Box w="100%" display="flex" flexDir={{base:"column", md:"row"}}>
                  <PrimaryInput
                    variant={screenType == "web" ? "fullLeft" : "defaultSide"}
                    inputPlace="Received by"
                    onChange={(e) => setReceivedby(e.target.value)}
                    inputValue={receivedby}
                    defaultValue={receivedby}
                    maxLength={100}
                  />

                  <DatePicker
                    variant="rightSide"
                    placeholderProp="Received Date*"
                    leftPos={{ left: "5px" }}
                    datePickerInputStyle={{ borderRadius: "0!important" }}
                    onDateChange={(e) => handleReceivedDateChange(e)}
                    defaultDate={receiveddate}

                    CUSTOM_DISABLE={true}
                   maxDate={new Date().toISOString()} 
                  />

                  {/* <PrimaryInput
                  variant="bothSide"
                  inputPlace="Supplier Lead Time"
                  onChange={(e) => setSupplierleadtime(e.target.value)}
                  inputValue={supplierleadtime}
                  maxLength={100}
                />

                <DatePicker
                  disableFutureDates={true}
                  placeholderProp="Supplier Date"
                  leftPos={{ left: "5px" }}
                  datePickerInputStyle={{ borderRadius: "0!important" }}
                  onDateChange={(e) => handleSupplierDateChange(e)}
                  defaultDate={supplierdate}
                /> */}

                  <PrimaryInput
                    variant="bothSide"
                    inputPlace="Supplier name"
                    onChange={(e) => setSuppliername(e.target.value)}
                    inputValue={suppliername}
                    defaultValue={suppliername}
                    onKeyDown={handleKeyDownAdd}
                    maxLength={100}
                  />

                  {/* <PrimaryInput
                  variant="bothSide"
                  inputPlace="Reorder level"
                //  onChange={handleNoOfContainers}
                  onChange={(e) => setReorderlevel(e.target.value)}
                  inputValue={reorderlevel}
                  defaultValue={reorderlevel}
                  maxLength={100}
                /> */}
                  <DropdownInput
                    variant={screenType == 'web' ? "fullRight" : 'downSide'}
                    dropDownPlace="Reorder level"
                    options={["High", "Medium", "Low"]}
                    readOnly={true}
                    id="reorderLevel"
                    onClick={handleReorderLevel}
                    defaultOption={reorderlevel}
                    menuList={{ pos: "absolute", right: "-45px", minW: "115px" }}
                  />
                </Box>

                <Box display="flex" mt="20px" mb="10px">
                  <Text fontSize="0.875rem" fontWeight="500">
                    Quantity Specifications:
                  </Text>
                </Box>

                {/* <Box w="100%" display="flex">
                <PrimaryInput
                  variant="leftSide"
                  inputPlace="No. of boxes*"
                  onChange={handleNoOfBoxes}
                  inputValue={numberofboxes}
                  defaultValue={numberofboxes}
                  maxLength={15}
                  inputType="number"
                  
                />
                <PrimaryInput
                  variant="rightSide"
                  inputPlace="No. of containers*"
                  onChange={handleNoOfContainers}
                  inputValue={numberofcontainers}
                  defaultValue={numberofcontainers}
                  maxLength={15}
                  inputType="number"
                  
                />
              </Box> */}

                <Box w="100%" display="flex" flexDir={{base:'column', md:'row'}}>
                  <PrimaryInput
                    variant={screenType == 'web' ? "fullLeft" : "defaultSide"}
                    inputPlace="Number of Units*"
                    onChange={handleNoOfStrips}
                    inputValue={numberofstrips}
                    defaultValue={numberofstrips}
                    maxLength={15}
                  //  inputType="number"
                  />
                  <PrimaryInput
                    variant="bothSide"
                    inputPlace="Quantity in each Unit*"
                    onChange={handleQuantityInEachStrip}
                    inputValue={quantityineachstrip}
                    defaultValue={quantityineachstrip}
                    maxLength={15}
                   // inputType="number"
                  />

                  <PrimaryInput
                    variant={screenType == "web" ? "fullRight" : "downSide"}
                    inputPlace="Stock in*"
                    onChange={handleStockIn}
                    inputValue={stockin}
                    defaultValue={stockin}
                    maxLength={100}
                  />
                </Box>

                <Box display="flex" mt="20px" mb="10px">
                  <Text fontSize="0.875rem" fontWeight="500">
                    Cost Details:
                  </Text>
                </Box>
                <Box w="100%" display="flex" flexDir={{base:"column", md:"row"}}>
                  <PrimaryInput
                    variant={screenType == "web" ? "fullLeft" : "defaultSide"}
                    inputPlace="Cost Per Unit*"
                    onChange={handleCostPerStrip}
                    inputValue={costperstrip}
                    defaultValue={costperstrip}
                    maxLength={15}
                   // inputType="number"
                  />

                  <PrimaryInput
                    variant="bothSide"
                    inputPlace="CGST(%)"
                    onChange={handleCgst}
                    inputValue={cgst}
                    defaultValue={cgst}
                    maxLength={15}
                   // inputType="number"
                  />
                  <PrimaryInput
                    variant={screenType == "web" ? "fullRight" : "downSide"}
                    inputPlace="SGST(%)"
                    onChange={handleSgst}
                    inputValue={sgst}
                    defaultValue={sgst}
                    maxLength={15}
                   // inputType="number"
                  />
                  {/* <PrimaryInput
                  variant="rightSide"
                  inputPlace="Cost Per Box*"
                  onChange={handleCostPerBox}
                  inputValue={costperbox}
                  defaultValue={costperbox}
                  maxLength={15}
                  inputType="number"
                /> */}
                </Box>

                <Box display="flex" mt="20px" mb="10px">
                  <Text fontSize="0.875rem" fontWeight="500">
                    Additional Details:
                  </Text>
                </Box>
                <Box w="100%" display="flex" flexDir={{base:"column", md:"row"}}>
                  <DropdownInput
                    variant={screenType == "web" ? "leftSide" : "defaultSide"}
                    dropDownPlace="OTC Requirement*"
                    options={["Yes", "No"]}
                    readOnly={true}
                    id="gender"
                    onClick={handleOTCRequired}
                    defaultOption={otcrequirement}
                    menuList={{ pos: "absolute", right: "-45px", minW: "115px" }}
                  />
                  <DropdownInput
                    variant="bothSide"
                    dropDownPlace="Prescription Requirement*"
                    options={["Yes", "No"]}
                    readOnly={true}
                    id="gender"
                    onClick={handlePrescriptionRequired}
                    defaultOption={prescriptionrequirement}
                    menuList={{ pos: "absolute", right: "-45px", minW: "115px" }}
                  />

                  <DropdownInput
                    variant="bothSide"
                    dropDownPlace="Storage condition"
                    options={storageConditions.map((item) => item.name)}
                    readOnly={true}
                    id="Storagecondition"
                    onClick={handleStorageCondition}
                    defaultOption={storagecondition}
                    menuList={{ pos: "relative", right: "78%", minW: "115px", maxW: "200px" }}
                  />
                  <PrimaryInput
                    variant="bothSide"
                    inputPlace="Usage instructions"
                    onChange={(e) => setUsageinstructions(e.target.value)}
                    inputValue={usageinstructions}
                    defaultValue={usageinstructions}
                    maxLength={200}
                  />
                  <PrimaryInput
                    variant={screenType == "web" ? "rightSide" : "bothSide"}
                    inputPlace="Location"
                    onChange={(e) => setLocation(e.target.value)}
                    inputValue={location}
                    defaultValue={location}
                    maxLength={100}
                  />
                </Box>

                <Box w={{base:"100%", md:"40%"}} display="flex" flexDir={{base:'column', md:'row'}}>
                  <PrimaryInput
                    variant={screenType == "web" ? "leftDown" : "bothSide"}
                    inputPlace="Stock location"
                    onChange={(e) => setStocklocation(e.target.value)}
                    inputValue={stocklocation}
                    defaultValue={stocklocation}
                    maxLength={100}
                  />
                  <PrimaryInput
                    variant={screenType == "web" ? "rightDown" : "downSide"}
                    inputPlace="Regulatory Information"
                    onChange={(e) => setRegulatoryinformation(e.target.value)}
                    inputValue={regulatoryinformation}
                    defaultValue={regulatoryinformation}
                    maxLength={100}
                  />
                </Box>

              </Box>
            </Box>
            {/* end */}
            <Divider my="1rem" />
          </>
        }
        footerActions={
          <>
            <Box w="100%" display="flex" gap="1rem">
              <PrimaryButton
                buttonText="Cancel"
                variant="grayBtnFull"
                btnStyle={{ color: "#AA2F27" }}
                onClick={() => editInventoryModal.current.closeRight()}
              />

              <PrimaryButton
                buttonText="Save"
                onClick={() => editInventoryFunction("edit")}
              />
            </Box>
          </>
        }
      />

      <ModalRight
        // ref={""}
        header="Edit Inventory"
        modalWidth={{ base: "100%", lg: "calc(100vw - 60%)" }}
        body={
          <>
            <Box w="100%" mt="1rem">
              <Box display="flex" mt="20px" mb="10px">
                <Text fontSize="0.875rem" fontWeight="500">
                  Product Details:
                </Text>
              </Box>

              <Box w="100%" mt="1rem">
                <Box w="100%" display="flex" flexDir="column">
                  {/* {documentURLs?.length < 3 && */}
                  <>
                    <Box
                      onClick={() => InventoryUploadDocument()}
                      as="button"
                      display="flex"
                      alignItems="center"
                      gap="10px"
                      mt="5px"
                      fontSize="14px"
                      fontWeight="600"
                      color="#1C4EC5"
                      textAlign="left"
                    >
                      <Image w="11px" src="/assets/imgs/add-blue.png" />
                      Upload Product Images
                    </Box>
                    <Text fontSize="12px" pl="20px">
                      *Upload up to 3 images to automatically capture the
                      Product Name, Expiry Date, and Chemical Composition!
                    </Text>

                    <PrimaryButton
                      buttonText="Upload"
                      verticalAlign="bottom"
                      variant="grayBtn"
                      btnStyle={{
                        w: "15%",
                        color: COLORS.PRIMARY_COLOR,
                        verticalAlign: "bottom",
                        ml: "4px",
                        mb: "0px",
                        h: "2.8rem",
                        m: "0",
                      }}
                      onClick={() => fetchCompositionByImage()}
                      isDisabled={
                        documentURLs?.length >= 1
                          ? false
                          : true
                      }
                    />
                    <input
                      type="file"
                      id="file-upload"
                      onChange={(e) => handleInventoryUpload(e)}
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      multiple
                    />
                  </>
                  {/* /} */}
                </Box>

                <Box w="100%" display="flex" flexDir="row">
                  {documentURLsEdit?.length > 0 &&
                    documentURLsEdit.map((document, index) => (
                      <Box
                        key={index}
                        mb="2"
                        mt="2"
                        mx="5px"
                        cursor="pointer"
                        title="Preview Image!"
                        as="a"
                        href={document.imagepath + document.imageurl}
                        target="/"
                      >
                        {/* <Box as="span" display="flex" justifyContent="end" cursor="pointer" title="Remove Image!"
                                    onClick={() => handleDelete(index)}
                                    >
                                      <Image 
                                      src="/assets/imgs/close.png" 
                                      border="1px solid" 
                                      padding="2px" 
                                      borderRadius="100%"
                                      zIndex="22"
                                       />
                                    </Box>  */}
                        <Image
                          boxSize="70px"
                          src={document.imagepath + document.imageurl}
                        />
                      </Box>
                    ))}

                  {documentURLs?.length > 0 &&
                    documentURLs.map((document, index) => (
                      <Box key={index} mb="2" mt="2" mx="5px" cursor="pointer">
                        <Box
                          as="span"
                          display="flex"
                          justifyContent="end"
                          cursor="pointer"
                          title="Remove Image!"
                          onClick={() => handleDelete(index)}
                        >
                          <Image
                            src="/assets/imgs/close.png"
                            border="1px solid"
                            padding="2px"
                            borderRadius="100%"
                            zIndex="22"
                            mb="-15px"
                          />
                        </Box>
                        <Box
                          as="a"
                          href={imagePath + document.filename}
                          target="/"
                          title="Preview Image!"
                        >
                          <Image
                            boxSize="70px"
                            src={imagePath + document.filename}
                          />
                        </Box>
                      </Box>
                    ))}
                </Box>
              </Box>

              <Box w="100%" display="flex" mt="1rem">
                <PrimaryInput
                  variant="leftSide"
                  inputPlace="Product name*"
                  onChange={(e) => setProductname(e.target.value)}
                  inputValue={productname}
                  defaultValue={productname}
                  maxLength={100}
                />

                <PrimaryButton
                  buttonText="Fetch"
                  verticalAlign="bottom"
                  variant="grayBtn"
                  btnStyle={{
                    w: "15%",
                    color: COLORS.PRIMARY_COLOR,
                    verticalAlign: "bottom",
                    ml: "4px",
                    mb: "0px",
                    h: "2.8rem",
                    m: "0",
                  }}
                  onClick={() => fetchComposition(productname)}
                  isDisabled={productname ? false : true}
                />
              </Box>

              <Box w="100%" display="flex">
                <PrimaryInput
                  variant="rightDown"
                  inputPlace="Chemical Composition"
                  onChange={(e) => setChemicalComposition(e.target.value)}
                  inputValue={chemicalcomposition}
                  defaultValue={chemicalcomposition}
                  maxLength={100}
                />
              </Box>

              <Box w="100%" display="flex">
                <MonthYearPicker
                  placeholderProp="Expiry Date*"
                  value={expirydate}
                  onDateChange={handleMonthYearChange}
                  datePickerInputStyle={{ borderRadius: "0!important" }}
                  minYear={mfgYear ? mfgYear : moment().year()}
                  defaultDate={expirydate}
                />
              </Box>

              <Box w="100%" display="flex" mt="1rem">
                {/* <PrimaryInput
                  variant="rightSide"
                  inputPlace="Product type*"
                  onChange={(e) => setProducttype(e.target.value)}
                  inputValue={producttype}
                  defaultValue={producttype}
                  maxLength={100}
                /> */}
                <DropdownInput
                  variant="leftSide"
                  dropDownPlace="Product type*"
                  options={[
                    "Tablet",
                    "Capsule",
                    "Syrup",
                    "Injection",
                    "Ointment",
                  ]}
                  readOnly={true}
                  id="Producttype"
                  onClick={productTypeChange}
                  defaultOption={producttype}
                  menuList={{ pos: "absolute", right: "-45px", minW: "115px" }}
                />
              </Box>

              <Box w="100%" display="flex">
                <PrimaryInput
                  variant="bothSide"
                  inputPlace="Product Code Id"
                  onChange={handleProductCodeId}
                  inputValue={productcodeid}
                  defaultValue={productcodeid}
                  maxLength={15}

                //inputType="number"
                />
                <PrimaryInput
                  variant="bothSide"
                  inputPlace="Category"
                  onChange={(e) => setCategory(e.target.value)}
                  inputValue={category}
                  defaultValue={category}
                  maxLength={100}
                />
              </Box>

              <Box w="100%" display="flex">
                <PrimaryInput
                  variant="bothSide"
                  inputPlace="Batch no.*"
                  onChange={(e) => setBatchnumber(e.target.value)}
                  inputValue={batchnumber}
                  defaultValue={batchnumber}
                  maxLength={35}
                />
                {/* <PrimaryInput
                  variant="bothSide"
                  inputPlace="Dosage"
                  onChange={(e) => setDosage(e.target.value)}
                  inputValue={dosage}
                  defaultValue={dosage}
                  maxLength={100}
                /> */}
                <MiniDropdownInput
                  variant="bothside"
                  options={[
                    { label: "mg", value: "mg" },
                    { label: "ml", value: "ml" },
                    { label: "grms", value: "grms" },
                  ]}
                  placeholderProp="Dosage*"
                  onSelect={handleChangeDosageUnit}
                  defaultOptionProp={dosageunit}
                  defaultValue={dosage}
                  handleInputChange={(e) =>
                    setDosage(handleNumericDotAndSpace(e.target.value))
                  }
                  inputVal={dosage}
                  maxLength="6"
                />
              </Box>

              <Box w="100%" display="flex">
                <PrimaryInput
                  variant="bothSide"
                  inputPlace="Manufacturer name*"
                  onChange={(e) => setManufacturername(e.target.value)}
                  inputValue={manufacturername}
                  defaultValue={manufacturername}
                  maxLength={100}
                />
                {/* <DatePicker
                  disableFutureDates={true}
                  placeholderProp="Manufactured Date"
                  leftPos={{ left: "5px" }}
                  datePickerInputStyle={{ borderRadius: "0!important" }}
                  onDateChange={(e) => handleMfgDateChange(e)}
                  defaultDate={manufactureddate}
                /> */}
                {/* <PrimaryInput
                  variant="bothSide"
                  inputPlace="Manufactured Date"
                  onChange={mfgDateFunction}
                  inputValue={manufactureddate}
                  defaultValue={manufactureddate}
                  maxLength={7}
                /> */}
                <MonthYearPicker
                  placeholderProp="Manufactured Date*"
                  value={manufactureddate}
                  onDateChange={handleMonthYearChangeMfg}
                  datePickerInputStyle={{ borderRadius: "0!important" }}
                  //  maxYear={2027}
                  minYear={1901}
                  maxYear={moment().year()}
                  defaultDate={manufactureddate}
                />
              </Box>

              <Box w="100%" display="flex">
                {/* <DatePicker
                 // disableFutureDates={true}
                 minDate={moment(new Date()).format("DD-MM-YYYY")}
                  placeholderProp="Expiry Date*"
                  leftPos={{ left: "5px" }}
                  datePickerInputStyle={{ borderRadius: "0!important" }}
                  onDateChange={(e) => handleExpiryDateChange(e)}
                  defaultDate={expirydate}
                /> */}
                {/* <PrimaryInput
                  variant="leftDown"
                  inputPlace="Expiry Date*"
                  onChange={expirydateFunction}
                  inputValue={expirydate}
                  defaultValue={expirydate}
                  maxLength={20}
                /> */}
                {/* <MonthYearPicker
        placeholderProp="Expiry Date*"
        value={expirydate}
        onDateChange={handleMonthYearChange}
        datePickerInputStyle={{ borderRadius: "0!important" }}
        minYear={mfgYear ? mfgYear : moment().year()}
        defaultDate={expirydate}

      />
                 <PrimaryInput
                  variant="rightDown"
                  inputPlace="Chemical Composition"
                  onChange={(e) => setChemicalComposition(e.target.value)}
                  inputValue={chemicalcomposition}
                  defaultValue={chemicalcomposition}
                  maxLength={100}
                /> */}
              </Box>

              <Box display="flex" mt="20px" mb="10px">
                <Text fontSize="0.875rem" fontWeight="500">
                  Delivery Details:
                </Text>
              </Box>

              <Box w="100%" display="flex">
                <PrimaryInput
                  variant="leftSide"
                  inputPlace="Received by"
                  onChange={(e) => setReceivedby(e.target.value)}
                  inputValue={receivedby}
                  defaultValue={receivedby}
                  maxLength={100}
                />

                <DatePicker
                  variant="rightSide"
                  placeholderProp="Received Date*"
                  leftPos={{ left: "5px" }}
                  datePickerInputStyle={{ borderRadius: "0!important" }}
                  onDateChange={(e) => handleReceivedDateChange(e)}
                  defaultDate={receiveddate}

                  CUSTOM_DISABLE={true}
                  maxDate={new Date().toISOString()} 
                />
              </Box>

              {/* <Box w="100%" display="flex">
                <PrimaryInput
                  variant="bothSide"
                  inputPlace="Supplier Lead Time"
                  onChange={(e) => setSupplierleadtime(e.target.value)}
                  inputValue={supplierleadtime}
                  maxLength={100}
                />

                <DatePicker
                  disableFutureDates={true}
                  placeholderProp="Supplier Date"
                  leftPos={{ left: "5px" }}
                  datePickerInputStyle={{ borderRadius: "0!important" }}
                  onDateChange={(e) => handleSupplierDateChange(e)}
                  defaultDate={supplierdate}
                />
              </Box> */}

              <Box w="100%" display="flex">
                <PrimaryInput
                  variant="leftDown"
                  inputPlace="Supplier name"
                  onChange={(e) => setSuppliername(e.target.value)}
                  inputValue={suppliername}
                  defaultValue={suppliername}
                  onKeyDown={handleKeyDownAdd}
                  maxLength={100}
                />

                {/* <PrimaryInput
                  variant="bothSide"
                  inputPlace="Reorder level"
                //  onChange={handleNoOfContainers}
                  onChange={(e) => setReorderlevel(e.target.value)}
                  inputValue={reorderlevel}
                  defaultValue={reorderlevel}
                  maxLength={100}
                /> */}
                <DropdownInput
                  variant="rightDown"
                  dropDownPlace="Reorder level"
                  options={["High", "Medium", "Low"]}
                  readOnly={true}
                  id="reorderLevel"
                  onClick={handleReorderLevel}
                  defaultOption={reorderlevel}
                  menuList={{ pos: "absolute", right: "-45px", minW: "115px" }}
                />
              </Box>

              <Box display="flex" mt="20px" mb="10px">
                <Text fontSize="0.875rem" fontWeight="500">
                  Quantity Specifications:
                </Text>
              </Box>

              {/* <Box w="100%" display="flex">
                <PrimaryInput
                  variant="leftSide"
                  inputPlace="No. of boxes*"
                  onChange={handleNoOfBoxes}
                  inputValue={numberofboxes}
                  defaultValue={numberofboxes}
                  maxLength={15}
                  inputType="number"
                  
                />
                <PrimaryInput
                  variant="rightSide"
                  inputPlace="No. of containers*"
                  onChange={handleNoOfContainers}
                  inputValue={numberofcontainers}
                  defaultValue={numberofcontainers}
                  maxLength={15}
                  inputType="number"
                  
                />
              </Box> */}

              <Box w="100%" display="flex">
                <PrimaryInput
                  variant="leftSide"
                  inputPlace="Number of Units*"
                  onChange={handleNoOfStrips}
                  inputValue={numberofstrips}
                  defaultValue={numberofstrips}
                  maxLength={15}
                 // inputType="number"
                />
                <PrimaryInput
                  variant="rightSide"
                  inputPlace="Quantity in each Unit*"
                  onChange={handleQuantityInEachStrip}
                  inputValue={quantityineachstrip}
                  defaultValue={quantityineachstrip}
                  maxLength={15}
                 // inputType="number"
                />
              </Box>

              <Box w="100%" display="flex">
                {/* <PrimaryInput
                  variant="bothSide"
                  inputPlace="Number of Syrup*"
                  onChange={handleNoOfSyrups}
                  inputValue={numberofsyrups}
                  defaultValue={numberofsyrups}
                  maxLength={15}
                /> */}

                {/* <PrimaryInput
                  variant="bothSide"
                  inputPlace="Cost Per each Unit*"
                  onChange={handleCostPerUnit}
                  inputValue={costperunit}
                  defaultValue={costperunit}
                  maxLength={15}
                /> */}
              </Box>
              <Box w="100%" display="flex">
                <PrimaryInput
                  variant="leftDown"
                  inputPlace="Stock in*"
                  onChange={handleStockIn}
                  inputValue={stockin}
                  defaultValue={stockin}
                  maxLength={100}
                />
                {/* <PrimaryInput
                  variant="rightDown"
                  inputPlace="Stock out"
                  onChange={handleStockOut}
                  inputValue={stockout}
                  defaultValue={stockout}
                  maxLength={100}
                /> */}
              </Box>

              <Box display="flex" mt="20px" mb="10px">
                <Text fontSize="0.875rem" fontWeight="500">
                  Cost Details:
                </Text>
              </Box>
              <Box w="100%" display="flex">
                <PrimaryInput
                  variant="leftSide"
                  inputPlace="Cost Per Unit*"
                  onChange={handleCostPerStrip}
                  inputValue={costperstrip}
                  defaultValue={costperstrip}
                  maxLength={15}
                //  inputType="number"
                />
                {/* <PrimaryInput
                  variant="rightSide"
                  inputPlace="Cost Per Box*"
                  onChange={handleCostPerBox}
                  inputValue={costperbox}
                  defaultValue={costperbox}
                  maxLength={15}
                  inputType="number"
                /> */}
              </Box>

              <Box w="100%" display="flex">
                <PrimaryInput
                  variant="leftDown"
                  inputPlace="CGST(%)"
                  onChange={handleCgst}
                  inputValue={cgst}
                  defaultValue={cgst}
                  maxLength={15}
                //  inputType="number"
                />
                <PrimaryInput
                  variant="rightDown"
                  inputPlace="SGST(%)"
                  onChange={handleSgst}
                  inputValue={sgst}
                  defaultValue={sgst}
                  maxLength={15}
                 // inputType="number"
                />
              </Box>

              <Box display="flex" mt="20px" mb="10px">
                <Text fontSize="0.875rem" fontWeight="500">
                  Additional Details:
                </Text>
              </Box>
              <Box w="100%" display="flex">
                <DropdownInput
                  variant="leftSide"
                  dropDownPlace="OTC Requirement*"
                  options={["Yes", "No"]}
                  readOnly={true}
                  id="gender"
                  onClick={handleOTCRequired}
                  defaultOption={otcrequirement}
                  menuList={{ pos: "absolute", right: "-45px", minW: "115px" }}
                />
                <DropdownInput
                  variant="bothSide"
                  dropDownPlace="Prescription Requirement*"
                  options={["Yes", "No"]}
                  readOnly={true}
                  id="gender"
                  onClick={handlePrescriptionRequired}
                  defaultOption={prescriptionrequirement}
                  menuList={{ pos: "absolute", right: "-45px", minW: "115px" }}
                />
              </Box>

              <Box w="100%" display="flex">
                {/* <PrimaryInput
                  variant="bothSide"
                  inputPlace="Storage condition"
                  onChange={(e) => setStorageCondition(e.target.value)}
                  inputValue={storagecondition}
                  defaultValue={storagecondition}
                  maxLength={200}
                /> */}
                <DropdownInput
                  variant="leftSide"
                  dropDownPlace="Storage condition"
                  options={storageConditions.map((item) => item.name)}
                  readOnly={true}
                  id="Storagecondition"
                  onClick={handleStorageCondition}
                  defaultOption={storagecondition}
                  menuList={{ pos: "relative", right: "78%", minW: "115px", maxW: "200px" }}
                />
                <PrimaryInput
                  variant="bothSide"
                  inputPlace="Usage instructions"
                  onChange={(e) => setUsageinstructions(e.target.value)}
                  inputValue={usageinstructions}
                  defaultValue={usageinstructions}
                  maxLength={200}
                />
              </Box>

              <Box w="100%" display="flex">
                <PrimaryInput
                  variant="bothSide"
                  inputPlace="Location"
                  onChange={(e) => setLocation(e.target.value)}
                  inputValue={location}
                  defaultValue={location}
                  maxLength={100}
                />
                <PrimaryInput
                  variant="rightDown"
                  inputPlace="Stock location"
                  onChange={(e) => setStocklocation(e.target.value)}
                  inputValue={stocklocation}
                  defaultValue={stocklocation}
                  maxLength={100}
                />
              </Box>

              <Box w="50%" display="flex">
                <PrimaryInput
                  variant="downSide"
                  inputPlace="Regulatory Information"
                  onChange={(e) => setRegulatoryinformation(e.target.value)}
                  inputValue={regulatoryinformation}
                  defaultValue={regulatoryinformation}
                  maxLength={100}
                />
              </Box>
            </Box>

            <Divider my="1rem" />
          </>
        }
        footerActions={
          <>
            <Box w="100%" display="flex" gap="1rem">
              <PrimaryButton
                buttonText="Cancel"
                variant="grayBtnFull"
                btnStyle={{ color: "#AA2F27" }}
                onClick={() => editInventoryModal.current.closeRight()}
              />

              <PrimaryButton
                buttonText="Save"
                onClick={() => editInventoryFunction("edit")}
              />
            </Box>
          </>
        }
      />

      <ModalRight
        ref={updateStockModal}
        header="Update Stock"
        modalWidth={{ base: "100%", lg: "calc(100vw - 60%)" }}
        body={
          <>
            <Box w="100%" mt="1rem">
              <Box display="flex" mt="20px" mb="10px">
                <Text fontSize="0.875rem" fontWeight="500">
                  Product Details:
                </Text>
              </Box>
              <Box w="100%" display="flex">
                <PrimaryInput
                  variant="leftSide"
                  inputPlace="Product name*"
                  onChange={(e) => setProductname(e.target.value)}
                  inputValue={productname}
                  defaultValue={productname}
                  isDisabled={true}
                  maxLength={100}
                />

                <DropdownInput
                  variant="rightSide"
                  dropDownPlace="Product type*"
                  options={[
                    "Tablet",
                    "Capsule",
                    "Syrup",
                    "Injection",
                    "Ointment",
                  ]}
                  readOnly={true}
                  id="Producttype"
                  onClick={productTypeChange}
                  defaultOption={producttype}
                  menuList={{ pos: "absolute", right: "-45px", minW: "115px" }}
                  isDisabled={true}
                />
              </Box>
              <Box w="100%" display="flex">
                <PrimaryInput
                  variant="downSide"
                  inputPlace="Chemical Composition"
                  onChange={(e) => setChemicalComposition(e.target.value)}
                  inputValue={chemicalcomposition}
                  defaultValue={chemicalcomposition}
                  maxLength={100}
                  isDisabled={true}
                />
                {/* <MiniDropdownInput
                      variant="bothside"
                      options={ [
                        { label: "mg", value: "mg" },
                        { label: "ml", value: "ml" },
                          { label: "grms", value: "grms" },
                        ] }
                        placeholderProp="Dosage*"
                        onSelect={handleChangeDosageUnit}
                        defaultOptionProp={dosageunit}
                      //  defaultValue={step.medicine_quantity ? step.medicine_quantity : ""}
                        handleInputChange={(e) =>
                          setDosage(handleNumericDotAndSpace(e.target.value))
                        }
                        inputVal={dosage}
                    maxLength="6"
                    isDisabled={true} 
                    
                    />*/}
              </Box>

              <Box display="flex" mt="20px" mb="10px">
                <Text fontSize="0.875rem" fontWeight="500">
                  Stock:
                </Text>
              </Box>
              <Box w="100%" display="flex">
                <PrimaryInput
                  variant="leftSide"
                  inputPlace="Batch no.*"
                  onChange={(e) => setBatchnumber(e.target.value)}
                  inputValue={batchnumber}
                  defaultValue={batchnumber}
                  //isDisabled={true}
                  maxLength={35}
                />

                <MonthYearPicker
                  placeholderProp="Expiry Date*"
                  value={expirydate}
                  onDateChange={handleMonthYearChange}
                  datePickerInputStyle={{ borderRadius: "0!important" }}
                  minYear={mfgYear ? mfgYear : moment().year()}
                  defaultDate={expirydate}
                />
              </Box>

              <Box w="100%" display="flex">

                <PrimaryInput
                  variant="bothSide"
                  inputPlace="Stock in*"
                  onChange={(e) => setStockin(e.target.value)}
                  inputValue={stockin}
                  defaultValue={stockin}
                  maxLength={100}
                />

                <PrimaryInput
                  variant="bothSide"
                  inputPlace="Stock out"
                  onChange={handleStockOut}
                  inputValue={stockout}
                  defaultValue={stockout}
                  maxLength={100}
                />
              </Box>

              <Box w="100%" display="flex">
                <PrimaryInput
                  variant="bothSide"
                  inputPlace="Number of Units*"
                  onChange={handleNoOfStrips}
                  inputValue={numberofstrips}
                  defaultValue={numberofstrips}
                  maxLength={15}
                />
                <PrimaryInput
                  variant="rightDown"
                  inputPlace="Quantity in each Unit*"
                  onChange={handleQuantityInEachStrip}
                  inputValue={quantityineachstrip}
                  defaultValue={quantityineachstrip}
                  maxLength={15}
                />
              </Box>

              <Box w="50%" display="flex">
                {/* <DatePicker
                // disableFutureDates={true}
                 minDate={moment(new Date()).format("DD-MM-YYYY")}
                  placeholderProp="Expiry Date*"
                  leftPos={{ left: "5px" }}
                  datePickerInputStyle={{ borderRadius: "0!important" }}
                  onDateChange={(e) => handleExpiryDateChange(e)}
                 defaultDate={expirydate}
                />  */}
                {/* <PrimaryInput
                  variant="leftSide"
                  inputPlace="Expiry Date*"
                  onChange={(e) => setExpirydate(e.target.value)}
                  inputValue={expirydate}
                  defaultValue={expirydate}
                  maxLength={20}
                /> */}

                <PrimaryInput
                  variant="downSide"
                  inputPlace="Cost Per Unit*"
                  onChange={handleCostPerStrip}
                  inputValue={costperstrip}
                  defaultValue={costperstrip}
                  // isDisabled={true}
                  maxLength={15}
                />

              </Box>

              <Box w="100%" display="flex">
                {/* <DatePicker
                  disableFutureDates={true}
                  placeholderProp="Manufactured Date"
                  leftPos={{ left: "5px" }}
                  datePickerInputStyle={{ borderRadius: "0!important" }}
                  onDateChange={(e) => handleMfgDateChange(e)}
                  defaultDate={manufactureddate}
                /> */}
              </Box>

              {/* <Box w="100%" display="flex">
                <PrimaryInput
                  variant="bothSide"
                  inputPlace="No. of boxes*"
                  onChange={handleNoOfBoxes}
                  inputValue={numberofboxes}
                  defaultValue={numberofboxes}
                  maxLength={15}
                />
                <PrimaryInput
                  variant="bothSide"
                  inputPlace="No. of containers*"
                  onChange={handleNoOfContainers}
                  inputValue={numberofcontainers}
                  defaultValue={numberofcontainers}
                  maxLength={15}
                />
              </Box> */}

              <Box w="50%" display="flex">
                {/* <PrimaryInput
                variant="bothSide"
                inputPlace="Cost Per each Unit*"
                onChange={handleCostPerUnit}
                inputValue={costperunit}
                defaultValue={costperunit}
                isDisabled={true}
                maxLength={15}
              /> */}

                {/* <PrimaryInput
                  variant="downSide"
                  inputPlace="Cost Per Box*"
                  onChange={handleCostPerBox}
                  inputValue={costperbox}
                  defaultValue={costperbox}
                  //  isDisabled={true}
                  maxLength={15}
                /> */}
              </Box>

              <Box w="100%" display="flex">
                {/* <PrimaryInput
                  variant="bothSide"
                  inputPlace="Number of Syrup*"
                  onChange={handleNoOfSyrups}
                  inputValue={numberofsyrups}
                  defaultValue={numberofsyrups}
                  maxLength={15}
                /> */}
              </Box>
            </Box>
          </>
        }
        footerActions={
          <>
            <Box w="100%" display="flex" gap="1rem">
              <PrimaryButton
                buttonText="Cancel"
                variant="grayBtnFull"
                btnStyle={{ color: "#AA2F27" }}
                onClick={() => updateStockModal.current.closeRight()}
              />

              <PrimaryButton
                buttonText="Save"
                onClick={() => editInventoryFunction("updateStock")}
              />
            </Box>
          </>
        }
      />

      <DynamicModal
        ref={deleteInventoryModal}
        modalHeader="Delete Inventory Item"
        modalBody={
          <>
            <Text fontSize="14px" fontWeight="600">
              Are you sure you want to delete <b> "{deleteProductName}"</b>?
            </Text>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="deleteBtn"
              buttonText="Delete"
              onClick={deleteInventoryFunction}
            />
          </>
        }
      />

      <ModalRight
        ref={logInventoryModal}
        header="View Inventory Log  "
        modalWidth={{ base: "100%", lg: "calc(100vw - 20%)" }}
        body={
          <>
            <Text fontSize="14px" fontWeight="600">
              {historyData[0]?.productname} - {historyData[0]?.producttype}
            </Text>
            <Text fontSize="13px">{historyData[0]?.chemicalcomposition}</Text>

            <Flex
              w="100%"
              flexDir="column"
              gap="1rem"
              mt="1rem"
            // h="calc(100vh - 70px)"
            >
              <SimpleTable
                isTh={false}
                tableHeaders={
                  <>
                    <Tr>
                      <Th fontSize="0.87rem" fontWeight="500">
                        Received Date
                      </Th>
                      <Th fontSize="0.87rem" fontWeight="500">
                        Modified Date
                      </Th>
                      <Th fontSize="0.87rem" fontWeight="500">
                        Stock In
                      </Th>
                      <Th fontSize="0.87rem" fontWeight="500">
                        Stock Out
                      </Th>
                      <Th fontSize="0.87rem" fontWeight="500">
                        Remaining Quantity
                      </Th>
                    </Tr>
                  </>
                }
                // headers={["Name", "Mobile", "Status"]}
                isDataPresent={true}
                ThStyle={{
                  bg: "#FAFAFA",
                }}
                tableStyle={{
                  maxH: "calc(100vh - 170px)",
                  overflowY: "auto",
                  tableLayout: "fixed",
                  width: "100%",
                  border: "1px solid #E6E7E9",
                }}
                tableBody={
                  <>
                    {historyData.map((user, index) => (
                      <Tr key={index}>
                        <Td fontSize="14px">
                          {formatDate_Do_MMM_YYYY(user.receiveddate) || "-"}
                        </Td>
                        <Td fontSize="14px">
                          {formatDate_Do_MMM_YYYY(user.modifieddate) || "-"}
                        </Td>
                        <Td fontSize="14px">{user.stockin || "-"}</Td>
                        <Td fontSize="14px">{user.stockout || "-"}</Td>
                        <Td fontSize="14px">{user.remainingquantity || "-"}</Td>
                        {/* <Td fontSize='14px'>{user.mobileno}</Td> */}
                      </Tr>
                    ))}
                  </>
                }
              />
            </Flex>
          </>
        }
      // footerActions={
      //   <>
      //     <Box w="100%" display="flex" gap="1rem">
      //       <PrimaryButton
      //         buttonText="Cancel"
      //         variant="grayBtnFull"
      //         btnStyle={{ color: "#AA2F27" }}

      //         onClick={() => logInventoryModal.current.closeRight()}
      //       />

      //     </Box>
      //   </>
      // }
      />
    </>
  );
};

export default InventoryIndex;
