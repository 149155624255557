import { Box, Image, InputGroup, Text, VStack } from "@chakra-ui/react";
import React,{useState} from "react";
import PrimaryInput from "../../components/primary-input";
import PrimaryButton from "../../components/primary-button";
import {
  Toaster,
} from "../../components";
import {
 
  InputRightElement,

} from "@chakra-ui/react";
import { generateOtp } from "../../middleware/services/login";
import OtpIndexForgotPassword from "./otp";


const ForgotPassword = ({doctoremail}) => {
  const { showSuccessToast, showErrorToast } = Toaster();
  const [isError, setIsError] = useState(false);
  let emailFromLocal = localStorage.getItem("email")
  const [email, setEmail] = useState(emailFromLocal ? emailFromLocal : "");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [GeneratedOTP, setGeneratedOTP] = useState("");
  const [ShowForgotPasswordOTP, setShowForgotPasswordOTP] = useState(false);
  const emailRegex = /^\s*[^\s@]+@[^\s@]+\.[^\s@]+\s*$/;



  const handleEmailChange = (e) => {
    const sanitizedValue = e.target.value.replace(/[^\x00-\x7F]/g, '')
    setIsError(false);
      setEmail(sanitizedValue.slice(0, 50));

    const newEmail = e.target.value.replace(/[^\x00-\x7F]/g, '');
    setIsButtonDisabled(newEmail.trim() === "");
    if (newEmail.trim() === "") {
      setIsError(false);
    }
  };
  const handleEmailpassKeyPress = (e) => {
    if (e.key === "Enter") {
      handleLoginSubmit();
    }
  };

  const handleLoginSubmit = async () => {
    if (email === "") {
      showErrorToast("Email cannot be empty");
      setIsError(true);
      return;
    } else if (!emailRegex.test(email)) {
      showErrorToast("Please Enter Valid Email Address");
      setIsError(true);
      return;
    }
    else{
    try {
      const response = await generateOtp({
        email:email.trim(),
       // otp: "1234",
        "countrycode":"1",
        "type":"forgotpassword",
        "language":"en",
        "countryid":1,
        "ispatient":0
      });
      if (response.output.result === "success") {
        if(response.output.otp != null){
          setGeneratedOTP(response.output.otp);
          setEmail(response.output.email);
        }
        setShowForgotPasswordOTP(true);
      } else if(response.output.result === "failure" && response.output.message === "user do not exists") {
        showErrorToast("The email you entered doesn't exists.")
      }else{
        showErrorToast("something went wrong")
      }
    } catch (error) {
      console.error("Login failed:", error.response.data);
    }
  }
  };
  return (
    <>
    {!ShowForgotPasswordOTP &&
      <VStack
        // spacing={4}
        p={{base: 4, lg: 8}}
        m={{base: 2, lg: 4}}
        // mt={{base: "-105px", lg: 4}}
        width={{base: "100%", lg: "400px"}}
        gap="0"
      >
        <Box mb="20px">
          <Image
            src="/assets/imgs/forgot-pass.png"
            w="58px"
            h="auto"
            borderRadius="10px"
          />
        </Box>
        <Box as="h1" mb="10px">Forgot Password</Box>

        <Box mb="30px">
          <Text textAlign="center" lineHeight="normal">
            Enter your email, and we'll send a code to verify your account.
          </Text>
        </Box>

        <InputGroup mb="20px">
          <PrimaryInput
           variant="fullRound"
            inputPlace="Enter Email Address"
            defaultValue={doctoremail} 
            inputValue={email}
            onChange={(e) => handleEmailChange(e)}  
            onKeyPress={handleEmailpassKeyPress}
            />
            {isError && (
                  <InputRightElement h="65%" mr="5px" bg="white" top="10px">
                    <Image src="/assets/imgs/error.png" w="18px" h="auto" />
                  </InputRightElement>
                )}
        </InputGroup>
        <PrimaryButton 
        colorScheme="blue"
         width="full" 
         buttonText="Login" 
         onClick={handleLoginSubmit}
         />
      </VStack>
     }
       {/* OTP component start*/}
       {ShowForgotPasswordOTP && <OtpIndexForgotPassword otp={GeneratedOTP} mobile={email}/>}
            {/* OTP component end */}
    </>
  );
};

export default ForgotPassword;
