import { Box, Center, Checkbox, Flex, Image, Text, VStack } from '@chakra-ui/react'
import React,{useState,useEffect} from 'react'
import { PrimaryButton } from '../../components'
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import { SubmitServicetype } from '../../middleware/services/finalsteps';
import Toaster from "../../components/toaster";


const ServicesProvide = ({onSubmit}) => {
  const [clinicvisit, setclinicVisitCheckbox] = useState('0');
  const [doctorvisit, setDoctorVisitCheckbox] = useState('0');
  const { isLoading, showLoader, dismissLoader } = useLoader();
  // const [errorMessage, setErrorMessage] = useState(''); 
  const { showSuccessToast, showErrorToast } = Toaster();


  useEffect(() => {
    // const prevclinicvisit = localStorage.getItem("clinicvisit");
    // const prevvideovisit = localStorage.getItem("videovisit");
    setclinicVisitCheckbox(localStorage.getItem("clinicvisit") || '0');
    setDoctorVisitCheckbox(localStorage.getItem("videovisit")  || '0');

    
  }, [])
  
  const checkedservice = (e, type) => {
    const checked = e.target.checked;
    if (type === "clinicvisit") {
      setclinicVisitCheckbox(checked ? "1" : "0");
    } else if (type === "videocall") {
      setDoctorVisitCheckbox(checked ? "1" : "0");
    }
  };
let input = {"doctorid":"","isvideocall":doctorvisit,"isclinicalvisit":clinicvisit,}
  const SubmitFunction = async () =>{
    if (clinicvisit === "0" && doctorvisit === "0") {
      showErrorToast('Please select at least one service type.');
      return;
    }
    showLoader()
    try {
      var result = await SubmitServicetype(input);
      if (result) {
        console.log(result);
        localStorage.setItem("clinicvisit", clinicvisit)
        localStorage.setItem("videovisit", doctorvisit)
        setTimeout(() => {
          const newActiveComponent = "workinghours";
          onSubmit(newActiveComponent);
        }, 1000);
      }
      dismissLoader();
    } catch (error) {
      console.log(error, "Something went wrong in doctor profile")
    }
  }
  return (
    <>
    <Flex>
        {isLoading && <Loader />}
        <Box w="100%" p={4}>
          <Center>
            <VStack spacing={0} w={{base: "100%", lg: "450px"}}>
              <Box as="p" w='70%' fontSize={{base: "1.25rem", lg: "1.375rem"}} textAlign='center' fontWeight="600" mb={5}>
                What type of services do you wish to provide  
              </Box>
              
              <Box w="100%">
                <Box mb="1rem" display="flex" gap="1rem" flexDir="column">
                    <Box as='label' cursor="pointer" htmlFor='clinicVisit' p="1.25rem" border={clinicvisit === "1" ? "2px solid #0C7650" : "1px solid #E4E4E7"} borderRadius="0.75rem" display="flex" flexDir="column" gap="0.5rem">
                        <Box display="flex" justifyContent="space-between" w="100%">
                            <Image src="/assets/svgs/hospital-black.svg" />
                            <Checkbox colorScheme="green" id="clinicVisit" isChecked={clinicvisit === "1"} onChange={(e)=> checkedservice(e, "clinicvisit")}/>
                        </Box>

                        <Box>
                            <Text fontSize="0.875rem" fontWeight="600" color="#010308">Clinic visit</Text>
                            <Text fontSize="0.75rem" fontWeight="400" color="#384052">Meet your patients face-to-face at your clinic or hospital.</Text>
                        </Box>
                    </Box>

                    <Box as='label' cursor="pointer" htmlFor='doctorVisit' p="1.25rem" border={doctorvisit === "1" ? "2px solid #0C7650" : "1px solid #E4E4E7"} borderRadius="0.75rem" display="flex" flexDir="column" gap="0.5rem">
                        <Box display="flex" justifyContent="space-between" w="100%">
                            <Image src="/assets/svgs/videocam.svg" />
                            <Checkbox colorScheme="green" id="doctorVisit" isChecked={doctorvisit === "1"} onChange={(e)=>checkedservice(e,"videocall")}/>
                        </Box>

                        <Box>
                            <Text fontSize="0.875rem" fontWeight="600" color="#010308">Video call</Text>
                            <Text fontSize="0.75rem" fontWeight="400" color="#384052">Engage with your patients through scheduled video calls using Zoom.</Text>
                        </Box>
                    </Box>
                </Box>
                <PrimaryButton buttonText="Continue" id="submitButton" 
                 onClick={SubmitFunction}
                 />
              </Box>
            </VStack>
          </Center>
        </Box>
      </Flex>
    </>
  )
}

export default ServicesProvide